
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SelectorProperty } from "./properties/selectorproperty";
import Konva from "konva";
import { Combobox } from "./combobox";
import { Value } from '../value';


export class Menubox extends Combobox{

    animation: boolean
    expandedtype: number
    //flagclick = false
    

    public drawObject():void{
		super.initObject();
    
    if(this.expandedtype != 0) this.expandedtype = 1
    let selproperty

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)


		let fillcolor = '0xffffff00';
        
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
        
            let ranges=[]
            let value = 0
            const selprop = this.properties['selector']
        if(selprop!=null){
             selproperty = Object.assign(new SelectorProperty(), selprop)
            if(selproperty!=null){
                ranges = selproperty.textrange
                value = selproperty.getCurrentValue(this.tagnames);
            }
        }

        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, color2)
              gradient.addColorStop(1, ColorUtils.darkColor(color2,0.5))

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10,
            strokeWidth: this.height/20,
            stroke: this.type3d==0? gradient : color2
          });
          if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0});
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
            rect.fillLinearGradientColorStops([0, fillcolor2,
                0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            rect.fill(fillcolor2)
          }
          

            let rectText = ""
            ranges.forEach((textview)=>{
                if(textview.value==value) rectText= textview.text
            })

        const Text = new Konva.Text({
            x: 0,
            y: 0, 
            height: this.height,
            width: this.width,
            text: rectText,
            fontSize: this.height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2
        })
        if(this.type3d==0){
            Text.shadowColor('black'),
            Text.shadowBlur(0),
            Text.shadowOffset({ x: 1, y: 1 })
          }
        

        
          const node1 = new Konva.Group();
          const height = this.height
          const width = this.width
          const tagnames = this.tagnames
          const type3d = this.type3d
          const expandedtype = this.expandedtype
          const layer = this.layer
          const animation = this.animation
          let flagclick = false
          
          this.node.add(node1)
          this.node.add(rect)
          this.node.add(Text)
          

            this.node.on('click touchend', function(){
              flagclick = !flagclick;
              if (flagclick){
                    if(expandedtype==0){
                        drawMenuVertical(node1, height, width, tagnames, type3d, animation, layer)
                    }else{
                        drawMenuHorizontal(node1, height, width, tagnames, type3d, animation, layer)
                    }
              }else{   
                node1.removeChildren()
              }
          })

          function drawMenuVertical( node:Konva.Group, height, width, tagnames, type3d, animation, layer){
            if(ranges.length != 0){
            ranges.forEach((textview)=>{
    
              const rectMenu = new Konva.Rect({
                x: 0,
                y: animation?0:(height+height/4)*(ranges.indexOf(textview)+1),
                width: width,
                height: height,
                cornerRadius: height/10,
                strokeWidth: height/20,
                stroke: type3d==0? gradient : color2
              });
              if(type3d==0){
                rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
                rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
                rectMenu.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                rectMenu.fill(fillcolor2)
              }
              node.add(rectMenu)
        
        const textMenu = new Konva.Text({
            x: 0,
            y: animation?0:(height+height/4)*(ranges.indexOf(textview)+1),
            width: width,
            height: height,
            text: textview.text,
            fontSize: height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2,
            })

        textMenu.on('click touchstart', function(){
          selproperty.sendTagValue(tagnames, textview.value)
        })
          node.add(textMenu)
          const dist = (height+height/4)*(ranges.indexOf(textview)+1);
          const animTime = 500
        
            if(animation){
            const anim = new Konva.Animation(function (frame) {
                    textMenu.y(dist * frame.time/animTime);
                    rectMenu.y(dist * frame.time/animTime);
                    if (textMenu.attrs.y>=dist){
                    anim.stop()
                    textMenu.attrs.y=dist
                    rectMenu.attrs.y = dist
            }
        }, layer);
        anim.start();
        }
        })
      }
    }
        function drawMenuHorizontal( node:Konva.Group, height, width, tagnames, type3d, animation, layer){
            if(ranges.length != 0){
        ranges.forEach((textview)=>{

          const rectMenu = new Konva.Rect({
            x: animation?0:(width+width/8)*(ranges.indexOf(textview)+1),
            y: 0,
            width: width,
            height: height,
            cornerRadius: height/10,
            strokeWidth: height/20,
            stroke: type3d==0? gradient : color2
          });
          if(type3d==0){
            rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
            rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
            rectMenu.fillLinearGradientColorStops([0, fillcolor2,
                0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            rectMenu.fill(fillcolor2)
          }
          node.add(rectMenu)
    
        const textMenu = new Konva.Text({
            x: animation?0:(width+width/8)*(ranges.indexOf(textview)+1),
            y: 0,
            width: width,
            height: height,
            text: textview.text,
            fontSize: height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2,
            })

        textMenu.on('click touchstart', function(){
         selproperty.sendTagValue(tagnames, textview.value)
        })
        node.add(textMenu)
       
        const dist = (width+width/8)*(ranges.indexOf(textview)+1);
        const animTime = 500
        
        if(animation){
            
        const anim = new Konva.Animation(function (frame) {
            textMenu.x(dist * frame.time/animTime);
            rectMenu.x(dist * frame.time/animTime);
                if (textMenu.attrs.x>=dist){
                anim.stop()
                textMenu.attrs.x=dist
                rectMenu.attrs.x = dist
                
                }
        }, layer);
        

        anim.start();
        }

        })
        }
    }
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "expandedtype": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "animation": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "expandedtype": {value.datatype=1; value.value = this[field];break}
          case "animation": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }
}
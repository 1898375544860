import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { StringUtils } from "../utils/stringutils";
import { format } from 'date-fns'
import { Value } from "../value";



export class DateView extends ObjectView{

    fonttype:number;
    textcolor:string;
    format:string;

    public drawObject():void{
        this.setUpdate(true)
       // setTimeout(()=>{this.drawObject()}, 1000);
		super.initObject();
        if(this.format==null || this.format=='') this.format = "dd MMMM yyyy";
        const time = format(Date.now(), this.format).toString()
       // console.log('timeclock', time, this.format)

          let textcolor = this.textcolor;
            const textcolorprop = this.properties==null?null:this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }

            const Text = new Konva.Text({
                x: 0,
                y: this.height*2/3-this.height/2.7,
                verticalAlign:"middle",
                width: this.width,
                height: this.height/2,
                text: time,
                fontSize: this.height/2,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                align:"center",
                fill: ColorUtils.convertformat(textcolor)
              });
              
              this.node.add(Text);
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "textcolor": 
            case "format": {this[field] = value.value;this.rotatednode=null;break;}
            case "fonttype": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        }
        this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "textcolor": 
            case "format": {value.datatype=7; value.value = this[field];break}
            case "fonttype": {value.datatype=3; value.value = this[field];break}
        }
        return value;
    }
}
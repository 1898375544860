import { SVGObject } from "../svgobject";

export class SVGWater42 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 43.805 112.5" enable-background="new 0 0 43.805 112.5" xml:space="preserve">
        <g id="Group_Group1">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M20.495,108.106h2.59v-1.915h-2.59V108.106z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M35.472,98.084v-2.027h-1.126v2.027H35.472z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M19.819,102.25h3.94v4.617h-3.94V102.25z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M31.08,98.535v-2.928h3.49v2.928H31.08z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M20.945,107.093v1.464h-1.126v-1.464H20.945z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M20.945,107.093v1.464h1.914v-1.464H20.945z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M23.76,107.093v1.464h-0.9v-1.464H23.76z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M35.021,97.634h1.014v0.901h-1.014V97.634z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M35.021,97.634h1.014v-1.352h-1.014V97.634z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M35.021,95.607h1.014v0.675h-1.014V95.607z" />
        </g>
        <g id="Group_Filter">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="10.3608" y1="100.6133" x2="33.2197" y2="100.6133">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M33.22,96.426c0,4.625-5.117,8.375-11.43,8.375   s-11.429-3.75-11.429-8.375" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="10.3599" y1="65.2021" x2="33.2197" y2="65.2021">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#EBEBEB" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.36,96.732V33.672h22.86v63.061" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="8.2202" y1="30.4063" x2="35.6973" y2="30.4063">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.248,33.897H33.22l2.478-6.981H8.22L10.248,33.897z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="8.2202" y1="19.2583" x2="35.8096" y2="19.2583">
                <stop offset="0.01" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#EBEBEB" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.22,27.028H35.81v-15.54H8.22V27.028z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.1" d="M10.36,40.091h22.86v7.207H10.36V40.091z" />
        </g>
        <g id="Group_FilterTop">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0" y1="9.1797" x2="43.8047" y2="9.1797">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,6.871h43.805v4.617H0V6.871z" />
        </g>
        <g id="Group_Pipe">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="4.3354" y1="21.8477" x2="4.3354" y2="14.3037">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.221,14.304H0.45v7.544h7.771V14.304" />
        </g>
        <g id="Group_Stand">
            <path fill="#4D4D4D" d="M12.837,36.938l-1.351,1.352v73.759l1.351,0.226V36.938z" />
            <path fill="#4D4D4D" d="M30.404,36.938l1.689,1.352v73.759l-1.689,0.226V36.938z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M11.036,111.71H13.4v0.788h-2.365V111.71z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M30.18,111.71h2.364v0.788H30.18V111.71z" />
        </g>
        <g id="Group_Holders">
            <polygon fill="#F0F0F0" points="38.287,5.745 38.287,6.871 36.485,6.871 36.485,5.745 36.035,5.52 35.584,5.294 35.246,4.957    35.021,4.619 34.684,4.168 34.57,3.831 34.346,3.38 34.346,2.93 34.459,2.367 34.57,1.804 34.908,1.241 35.246,0.79 35.697,0.452    36.26,0.227 36.823,0.002 37.387,0.002 37.949,0.002 38.512,0.227 38.963,0.452 39.413,0.79 39.751,1.241 39.977,1.804    40.089,2.367 40.201,2.93 40.089,3.38 39.977,3.831 39.863,4.168 39.639,4.619 39.413,4.957 39.075,5.294 38.737,5.52  " />
            <polygon fill="#F0F0F0" points="37.387,0.903 37.725,0.903 38.062,1.016 38.399,1.241 38.625,1.466 38.85,1.804 39.075,2.142    39.188,2.479 39.188,2.93 39.188,3.268 39.075,3.605 38.85,3.943 38.625,4.168 38.399,4.394 38.062,4.619 37.725,4.731    37.387,4.731 36.936,4.731 36.598,4.619 36.26,4.394 35.922,4.168 35.697,3.943 35.472,3.605 35.359,3.268 35.246,2.93    35.359,2.479 35.472,2.142 35.697,1.804 35.922,1.466 36.26,1.241 36.598,1.016 36.936,0.903  " />
            <polygon fill="#F0F0F0" points="20.72,1.579 20.607,1.579 20.382,1.691 20.27,1.917 20.157,2.142 20.044,2.367 19.932,2.705    19.819,3.042 19.819,3.38 19.819,3.718 19.932,4.056 20.044,4.281 20.157,4.506 20.27,4.731 20.382,4.957 20.607,4.957    20.72,5.069 21.058,4.957 21.283,4.957 21.396,4.731 21.621,4.506 21.733,4.281 21.846,4.056 21.846,3.718 21.958,3.38    21.846,3.042 21.846,2.705 21.733,2.367 21.621,2.142 21.396,1.917 21.283,1.691 21.058,1.579  " />
            <polygon fill="#F0F0F0" points="21.621,5.52 21.621,6.871 19.819,6.871 19.819,5.52 19.707,5.294 19.481,5.069 19.369,4.844    19.144,4.619 19.031,4.394 19.031,4.056 18.918,3.718 18.918,3.38 18.918,2.817 19.031,2.254 19.256,1.804 19.481,1.466    19.819,1.128 20.044,0.79 20.382,0.678 20.72,0.678 21.17,0.678 21.508,0.79 21.846,1.128 22.071,1.466 22.296,1.804 22.521,2.254    22.521,2.817 22.635,3.38 22.635,3.718 22.521,4.056 22.521,4.394 22.409,4.731 22.296,4.957 22.071,5.182 21.958,5.407  " />
            <polygon fill="#F0F0F0" points="10.135,0.903 9.91,0.903 9.685,1.016 9.459,1.241 9.234,1.466 9.121,1.804 9.009,2.142    9.009,2.479 9.009,2.93 9.009,3.268 9.009,3.605 9.121,3.943 9.234,4.168 9.459,4.394 9.685,4.619 9.91,4.731 10.135,4.731    10.248,4.731 10.473,4.619 10.698,4.394 10.923,4.168 11.036,3.943 11.148,3.605 11.261,3.268 11.261,2.93 11.261,2.479    11.148,2.142 11.036,1.804 10.923,1.466 10.698,1.241 10.473,1.016 10.248,0.903  " />
            <polygon fill="#F0F0F0" points="11.036,5.52 11.036,6.871 9.009,6.871 9.009,5.52 8.671,5.182 8.558,4.957 8.333,4.619 8.22,4.281    8.108,3.943 8.108,3.605 7.995,3.268 7.995,2.93 8.108,2.367 8.22,1.804 8.446,1.241 8.671,0.79 9.009,0.452 9.347,0.227    9.685,0.002 10.135,0.002 10.585,0.002 10.923,0.227 11.261,0.452 11.599,0.79 11.824,1.241 12.049,1.804 12.162,2.367    12.162,2.93 12.162,3.268 12.162,3.718 12.049,4.056 11.937,4.394 11.711,4.731 11.486,5.069 11.261,5.294  " />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="38.287,5.745 38.287,6.871 36.485,6.871 36.485,5.745    35.246,4.957 34.57,3.831 34.346,2.93 34.57,1.804 35.246,0.79 36.26,0.227 37.387,0.002 38.512,0.227 39.413,0.79 39.977,1.804    40.201,2.93 39.863,4.168 39.075,5.294 38.287,5.745  " />
            <circle fill="#FFFFFF" cx="37.16" cy="2.817" r="1.914" />
            <circle fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="37.16" cy="2.817" r="1.914" />
            <polyline fill="#FFFFFF" points="20.72,1.579 20.607,1.579 20.044,2.367 19.819,3.38 20.157,4.506 20.72,5.069 21.283,4.957    21.846,4.056 21.958,3.38 21.621,2.142 21.058,1.579 20.72,1.579  " />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="20.72,1.579 20.607,1.579 20.044,2.367 19.819,3.38    20.157,4.506 20.72,5.069 21.283,4.957 21.846,4.056 21.958,3.38 21.621,2.142 21.058,1.579 20.72,1.579  " />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="21.621,5.52 21.621,6.871 19.819,6.871 19.819,5.52    19.144,4.619 18.918,3.38 19.031,2.254 19.819,1.128 20.72,0.678 21.846,1.128 22.521,2.254 22.635,3.38 22.409,4.731 21.621,5.52     " />
            <ellipse fill="#FFFFFF" cx="10.135" cy="2.817" rx="1.126" ry="1.914" />
            <ellipse fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="10.135" cy="2.817" rx="1.126" ry="1.914" />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="11.036,5.52 11.036,6.871 9.009,6.871 9.009,5.52 8.333,4.619    8.108,3.605 7.995,3.268 8.22,1.804 8.671,0.79 9.685,0.002 10.135,0.002 11.261,0.452 12.049,1.804 12.162,2.93 12.049,4.056    11.486,5.069 11.036,5.52  " />
            <rect x="36.711" y="11.375" fill="#F0F0F0" width="1.125" height="0.901" />
            <rect x="36.711" y="11.375" fill="none" stroke="#4C4C4C" stroke-width="0.25" width="1.125" height="0.901" />
            <rect x="9.459" y="11.375" fill="#F0F0F0" width="1.126" height="0.901" />
            <rect x="9.459" y="11.375" fill="none" stroke="#4C4C4C" stroke-width="0.25" width="1.126" height="0.901" />
            <polygon fill="#F0F0F0" points="34.121,12.276 38.625,12.276 38.625,16.668 34.121,18.357  " />
            <polygon fill="none" stroke="#4C4C4C" stroke-width="0.25" points="34.121,12.276 38.625,12.276 38.625,16.668 34.121,18.357  " />
            <polygon fill="#F0F0F0" points="11.374,12.276 8.671,12.276 8.671,16.668 11.374,18.357  " />
            <polygon fill="none" stroke="#4C4C4C" stroke-width="0.25" points="11.374,12.276 8.671,12.276 8.671,16.668 11.374,18.357  " />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="10.473,13.965 9.685,13.965 9.685,15.204 10.473,15.767    10.473,13.965  " />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.25" points="36.373,13.965 37.725,13.965 37.725,15.429 36.373,15.767    36.373,13.965  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.8958333333333334, 0.0, 0.5, 0.16666666666666666, 212.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 1.0, 0.0, 0.5, 0.16666666666666666, 212.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.5989583333333334, 0.0, 1.0, 0.5989583333333334, 0.0, 2.0, 0.125, 223.0, 3.0, 0.125, 223.0]);
		this.fillGradient(this.fills,"Group_Holders",[0.0, 0.125, 223.0, 1.0, 0.125, 223.0, 2.0, 0.125, 223.0, 3.0, 0.125, 223.0, 4.0, 0.125, 223.0, 5.0, 0.125, 223.0, 6.0, 0.0, 255.0, 7.0, 0.0, 255.0, 8.0, 0.0, 255.0, 9.0, 0.125, 223.0, 10.0, 0.125, 223.0, 11.0, 0.125, 223.0, 12.0, 0.125, 223.0]);

        super.drawObject();
    }
}
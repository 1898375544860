export class StringUtils{
    static getFontFamily(fonttype:number):string{
        let fontfamily="roboto";
        switch(fonttype)
		{
			case 0:
            case 1:
            case 2:
            case 3:
                fontfamily="Roboto"
				break;
        
			case 4:
            case 5:
            case 6:
            case 7:
                fontfamily="DS-Digital"
                break;
			case 8:fontfamily="System-ui"
				break;
			case 9:fontfamily="Arial";
				break;
			case 10:fontfamily="AmericanTypewriter";
				break;
			case 11:fontfamily="academy engraved let"
				break;
			case 12:fontfamily="Avenir-Medium";
				break;
			case 13:fontfamily="Cochin";
				break;
			case 14:fontfamily="COURIER";
				break;
			case 15:fontfamily="Helvetica";
				break;
			case 16:fontfamily="Menlo-Regular";
				break;
			case 17:fontfamily="OPTIMA";
				break;
			case 18:fontfamily="Times New Roman";
				break;
			case 19:fontfamily="Verdana";
				break;
			case 20:fontfamily="Geom Graphic Light";
				break;
			case 21:fontfamily="Geom Graphic Bold";
				break;
			case 22:fontfamily="DS-Digital";
				break;
            case 23:fontfamily="Lato";
				break;
            case 24:fontfamily="Lato";
				break;
            case 25:fontfamily="Lato";
				break;
            case 26: fontfamily="Corbel";
                break; 
            case 27: fontfamily="Corbel";
                break;
			default:break;
		}
        return fontfamily;
    }
    static getFontStyle(fonttype:number):string{
        let fontstyle="normal";
        switch(fonttype)
		{
		
            case 1:
            case 5:
            case 14:
            case 21:
            case 24:
            case 27:
                fontstyle="bold"
                break;
            case 2:
            case 6:
                fontstyle="italic"
                break;
            case 3:
            case 7:
                fontstyle="italic bold"
				break;
            
    }
    return fontstyle;
}
    static getTextPlacement(textplacement:number):string{
        let placement="left"
        switch(textplacement){
            case 0:placement="left";
                break;
            case 1:placement="center";
                break;
            case 2:placement="right";
                break;
        }
        return placement;
    }
    
}

import { SVGObject } from "../svgobject";

export class SVGFlex4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 111.938 112.5" enable-background="new 0 0 111.938 112.5" xml:space="preserve">
        <g id="Group_90_Bend">
            
                <radialGradient id="SVGID_1_" cx="154.2202" cy="320.0928" r="31.1386" gradientTransform="matrix(0.2497 -3.3446 3.3925 0.244 -1020.8235 542.4209)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.42" style="stop-color:#646566" />
                <stop offset="0.52" style="stop-color:#959595" />
                <stop offset="0.68" style="stop-color:#E0E0E0" />
                <stop offset="0.92" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M107.539,58.271c0.764,0,1.468,0.079,2.202,0.108L109.582,5.65   C38.959,5.65,3.299,59.237,3.299,110.252c0,0,28.726,0.295,55.17,0.236C57.706,84.686,74.025,58.271,107.539,58.271z" />
        </g>
        <g id="Group_Fins">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="30.4063" y1="112.5" x2="30.4063" y2="107.9961">
                <stop offset="0" style="stop-color:#D1D1D1" />
                <stop offset="0.56" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.5" d="M0.001,107.996v4.504h60.81v-4.504H0.001z" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="88.8608" y1="120.6113" x2="88.8608" y2="116.2188" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.2486" d="M108.014,0.533l4.393-0.004l0.054,60.332l-4.391,0.004   L108.014,0.533z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="20.1763" y1="39.061" x2="14.7628" y2="38.3963" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.2486" d="M61.261,102.314l-0.447,4.229l-60.815-6.178l0.446-4.452   L61.261,102.314z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="28.5127" y1="40.7061" x2="23.0699" y2="39.4495" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.2486" d="M62.38,97.192l-0.896,4.231L1.452,89.012l1.01-4.343   L62.38,97.192z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="36.3096" y1="42.9663" x2="31.1932" y2="41.2046" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.2486" d="M63.728,92.517l-1.348,4.008L4.257,77.989l1.348-4.231   L63.728,92.517z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="43.5288" y1="46.0605" x2="39.4491" y2="44.2441" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.2486" d="M65.865,87.84l-1.686,4.008L8.077,67.3l1.798-4.009   L65.865,87.84z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="51.3906" y1="50.3711" x2="46.6879" y2="47.5454" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.2486" d="M68.337,83.273l-2.023,3.898L13.247,56.943l2.024-3.787   L68.337,83.273z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="58.2056" y1="55.1514" x2="53.6501" y2="51.7186" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.2486" d="M71.488,79.263l-2.813,3.565L19.094,47.365l2.7-3.564   L71.488,79.263z" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="64.4126" y1="60.4473" x2="60.6101" y2="56.9014" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.2486" d="M74.636,75.477l-2.699,3.119L26.405,38.12l2.7-3.12   L74.636,75.477z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="69.7632" y1="66.0811" x2="66.971" y2="62.98" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.2486" d="M78.462,72.133l-3.15,2.675L34.281,29.874l3.15-2.673   L78.462,72.133z" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="74.9575" y1="72.6582" x2="72.4325" y2="69.1827" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.2486" d="M82.513,69.013l-3.377,2.452L43.171,22.742l3.263-2.785   L82.513,69.013z" />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="79.5601" y1="80.4043" x2="76.9069" y2="75.6178" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.2486" d="M87.015,66.226l-3.826,2.341L52.625,16.167l3.826-2.229   L87.015,66.226z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="82.9829" y1="87.7744" x2="80.9068" y2="82.8833" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.2486" d="M91.519,64.219l-3.828,1.896L62.754,10.815l3.826-1.785   L91.519,64.219z" />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="85.7534" y1="95.8027" x2="84.1499" y2="90.558" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.2486" d="M96.585,62.656l-4.279,1.339L73.448,6.575l4.053-1.34   L96.585,62.656z" />
            
                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="87.5737" y1="103.3379" x2="86.6629" y2="99.0528" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.2486" d="M101.539,61.649l-4.279,0.783L84.483,3.225l4.275-0.894   L101.539,61.649z" />
            
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="88.6978" y1="111.5371" x2="88.238" y2="107.1628" gradientTransform="matrix(-9.000000e-004 -0.9885 1 -9.000000e-004 -8.0973 118.6426)">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.2486" d="M106.492,60.865l-4.277,0.561l-6.36-60.214l4.277-0.449   L106.492,60.865z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.42, 0.7864583333333334, 0.0, 0.52, 0.8385416666666666, 41.0, 0.68, 0.25, 191.0, 0.92, 0.8958333333333334, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3645833333333333, 161.0, 0.56, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);


        super.drawObject();
    }
}
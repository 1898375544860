export class CatmullRomSpline {
    p0: number;
    p1: number;
    p2: number;
    p3: number;

    constructor(P0:number,P1:number, P2:number, P3:number){
        this.p0 = P0;
        this.p1 = P1;
        this.p2 = P2;
        this.p3 = P3;
    }

     public q(T:number) {
        return 0.5 * ((2 * this.p1) + (this.p2 - this.p0) * T + (2 * this.p0 - 5 * this.p1 + 4 * this.p2 - this.p3)
         * T * T + (3 * this.p1 -this.p0 - 3 * this.p2 + this.p3) * T * T * T);
     }
   
}
import { createStore } from 'vuex'
import 'reflect-metadata';
import 'es6-shim'
//import auth from "./auth.module";

export default createStore({
 /* modules: {
    auth
  }*/
})

import { SVGObject } from "../svgobject";

export class SVGFinish25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 93.672 112.5" enable-background="new 0 0 93.672 112.5" xml:space="preserve">
        <g id="Group_Rear">
            <path d="M2.254,26.266h89.164v-0.451H2.254V26.266z" />
            <path d="M2.254,31h89.164v-0.451H2.254V31z" />
            <path d="M2.254,49.825h89.164v-0.676H2.254V49.825z" />
            <path d="M2.254,68.537h89.164v-0.451H2.254V68.537z" />
            <path d="M2.254,87.361h89.164v-0.676H2.254V87.361z" />
            <path d="M2.254,106.073h89.164v-0.677H2.254V106.073z" />
            <path d="M91.418,112.498V23.335h-0.677v89.163H91.418z" />
            <path d="M2.931,112.498V23.335H2.254v89.163H2.931z" />
        </g>
        <g id="Group_Rack_Stand">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="91.418" y1="67.916" x2="93.6719" y2="67.916">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.418,23.335h2.254v89.163h-2.254V23.335z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="67.916" x2="2.2544" y2="67.916">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,23.335h2.254v89.163H0V23.335z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="104.2695" x2="91.418" y2="104.2695">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,103.143h89.164v2.254H2.254V103.143z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="85.5586" x2="91.418" y2="85.5586">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,84.431h89.164v2.255H2.254V84.431z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="66.8457" x2="91.418" y2="66.8457">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,65.605h89.164v2.48H2.254V65.605z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="29.3096" x2="91.418" y2="29.3096">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,28.069h89.164v2.48H2.254V28.069z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="48.0215" x2="91.418" y2="48.0215">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,46.894h89.164v2.254H2.254V46.894z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="2.2544" y1="24.5752" x2="91.418" y2="24.5752">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.49" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.254,23.335h89.164v2.48H2.254V23.335z" />
        </g>
        <g id="Group_Design">
            <ellipse fill="#999999" cx="46.78" cy="29.31" rx="43.849" ry="0.563" />
            <path fill="#999999" d="M2.931,47.345h87.811v1.353h-1.128v-1.353h-1.352v1.353h-1.127v-1.353h-1.128v1.353H84.88v-1.353h-1.128   v1.353H82.4v-1.353h-1.128v1.353h-1.127v-1.353h-1.015v1.353h-1.24v-1.353h-1.353v1.353h-1.015v-1.353h-1.127v1.353h-1.24v-1.353   h-1.24v1.353h-1.127v-1.353h-1.127v1.353h-1.127v-1.353h-1.354v1.353h-1.127v-1.353h-1.127v1.353h-1.127v-1.353h-1.127v1.353H61.32   v-1.353h-1.127v1.353h-1.127v-1.353h-1.127v1.353h-1.127v-1.353h-1.354v1.353h-1.127v-1.353h-1.127v1.353h-1.127v-1.353H50.95   v1.353h-1.353v-1.353h-1.127v1.353h-1.127v-1.353h-1.128v1.353h-1.353v-1.353h-1.127v1.353h-1.015v-1.353h-1.24v1.353h-1.127   v-1.353h-1.24v1.353h-1.127v-1.353h-1.24v1.353h-1.015v-1.353H34.38v1.353h-1.24v-1.353h-1.014v1.353h-1.127v-1.353h-1.127v1.353   h-1.353v-1.353h-1.127v1.353h-1.127v-1.353h-1.127v1.353h-1.353v-1.353h-1.127v1.353H21.53v-1.353h-1.127v1.353h-1.127v-1.353   h-1.353v1.353h-1.127v-1.353h-1.127v1.353h-1.127v-1.353h-1.127v1.353h-1.353v-1.353h-1.127v1.353H9.807v-1.353H8.68v1.353H7.552   v-1.353H6.2v1.353H5.072v-1.353H3.945v1.353H2.931V47.345z" />
            <ellipse fill="#999999" cx="46.78" cy="66.846" rx="43.849" ry="0.564" />
            <ellipse fill="#999999" cx="46.78" cy="85.445" rx="43.849" ry="0.564" />
            <ellipse fill="#999999" cx="46.78" cy="104.382" rx="43.849" ry="0.563" />
            <ellipse fill="#999999" cx="0.902" cy="67.973" rx="0.563" ry="43.961" />
            <ellipse fill="#999999" cx="92.432" cy="67.973" rx="0.564" ry="43.961" />
        </g>
        <g id="Group_Tri_Rod">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M24.01,23.335l22.206-12.85V0.002h1.128v10.483l22.318,12.85h-1.803   L46.667,11.161L25.588,23.335H24.01z" />
        </g>
        <g id="Group_Fixure">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M45.54,9.809h2.254v0.676H45.54V9.809z" />
        </g>
        <g id="Group_Hangers">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M47.939,51.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.201,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M54.439,51.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.201,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M60.814,51.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.201,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M67.439,51.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.201,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M74.064,51.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M80.064,51.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M9.189,51.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M15.689,51.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M22.064,51.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M28.689,51.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M35.114,51.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M41.314,51.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M47.814,32.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M54.314,32.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M60.689,32.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M67.314,32.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M73.939,32.992l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.939,32.992l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M9.064,32.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M15.564,32.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M21.939,32.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M28.564,32.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M34.989,32.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M41.189,32.867l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M47.814,70.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M54.314,70.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M60.689,70.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M67.314,70.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M73.939,70.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.939,70.742l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M9.064,70.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M15.564,70.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M21.939,70.367l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M28.564,70.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M34.989,70.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M41.189,70.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M47.939,89.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M54.439,89.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M60.814,89.367l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M67.439,89.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M74.064,89.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M80.064,89.617l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M9.189,89.367l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M15.689,89.367l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M22.064,89.242l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M28.689,89.367l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M35.114,89.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M41.314,89.492l0.042-0.402c0,0,0.459-1.771,1.229-1.569   c0.771,0.2,0.813,1.569,0.813,1.569v6.963c0,0,0.395,1.407,0.791,1.488c0.396,0.08,0.938-0.079,1.042-0.522   c0.104-0.444,0.229-0.666,0.229-1.53" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
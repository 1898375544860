import { SVGObject } from "../svgobject";

export class SVGWater39 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 78.494" enable-background="new 0 0 112.5 78.494" xml:space="preserve">
        <g id="Group_ExplosionProof">
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,17.569L18.694,8.11H112.5l-18.807,9.459H0z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M13.964,15.204h75V5.745h-75V15.204z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M13.964,5.745l11.824-5.743h75L88.964,5.745H13.964z" />
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M88.964,15.204l11.824-5.855V0.002L88.964,5.745V15.204z" />
            <path fill="#669999" d="M84.347,78.493l18.694-9.348V22.187l-18.694,9.459V78.493z" />
            <path fill="#4C7F7F" d="M84.347,45.61l18.694-9.347V22.187l-18.694,9.459V45.61z" />
            <path fill="#99CCCC" d="M9.347,31.646h75v46.847h-75V31.646z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M0,24.551h93.693v7.095H0V24.551z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,17.569h93.693v6.982H0V17.569z" />
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M93.693,24.551l18.807-9.347V8.11l-18.807,9.459V24.551z" />
            <path fill="#4C7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M93.693,31.646l18.807-9.459v-6.982l-18.807,9.347V31.646z" />
            <path fill="#7FB2B2" d="M9.347,31.646h75V45.61h-75V31.646z" />
        </g>
        <g id="Group_Fasteners">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M100.788,9.349h4.617v-4.73h-4.617" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M100.788,3.493h6.982l-2.365,1.126h-4.617" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M105.405,4.619v4.73l2.365-1.239V3.493L105.405,4.619z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,16.331h7.095v-4.617H5.743V16.331z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,11.713l2.365-1.239h7.095l-2.365,1.239H5.743z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M12.838,11.713v4.617l2.365-1.126v-4.73L12.838,11.713z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M44.482,16.331h7.095v-4.617h-7.095V16.331z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M44.482,11.713l2.365-1.239h6.982l-2.252,1.239H44.482z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M51.577,11.713v4.617l2.252-1.126v-4.73L51.577,11.713z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,16.331h6.982v-4.617h-6.982V16.331z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,11.713l2.365-1.239h6.982l-2.365,1.239H25.788z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,11.713v4.617l2.365-1.126v-4.73L32.771,11.713z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M65.541,16.331h7.094v-4.617h-7.094V16.331z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M65.541,11.713l2.364-1.239H75l-2.365,1.239H65.541z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,11.713v4.617L75,15.205v-4.73L72.635,11.713z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M92.567,12.839h6.981V8.223h-6.981V12.839z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M92.567,8.223l2.365-1.239h6.981l-2.365,1.239H92.567z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M99.549,8.223v4.617l2.365-1.126V6.984L99.549,8.223z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,16.331h6.981v-4.617h-6.981V16.331z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,11.713l2.365-1.239h6.981l-2.365,1.239H84.347z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M91.328,11.713v4.617l2.365-1.126v-4.73L91.328,11.713z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.fills,"Group_ExplosionProof",[0.0, 0.34375, 167.0, 1.0, 0.5364583333333334, 117.0, 2.0, 0.34375, 167.0, 3.0, 0.7447916666666666, 65.0, 4.0, 0.9375, 15.0, 5.0, 0.8541666666666666, 0.0, 6.0, 0.5364583333333334, 117.0, 7.0, 0.9375, 15.0, 8.0, 0.5364583333333334, 117.0, 9.0, 0.7447916666666666, 65.0, 10.0, 0.8541666666666666, 0.0, 11.0, 0.7447916666666666, 65.0]);
		this.fillGradient(this.fills,"Group_Fasteners",[0.0, 0.609375, 99.0, 1.0, 0.11458333333333333, 226.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.609375, 99.0, 4.0, 0.11458333333333333, 226.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.609375, 99.0, 7.0, 0.11458333333333333, 226.0, 8.0, 0.8072916666666666, 49.0, 9.0, 0.609375, 99.0, 10.0, 0.11458333333333333, 226.0, 11.0, 0.8072916666666666, 49.0, 12.0, 0.609375, 99.0, 13.0, 0.11458333333333333, 226.0, 14.0, 0.8072916666666666, 49.0, 15.0, 0.609375, 99.0, 16.0, 0.11458333333333333, 226.0, 17.0, 0.8072916666666666, 49.0, 18.0, 0.609375, 99.0, 19.0, 0.11458333333333333, 226.0, 20.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
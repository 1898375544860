import { SVGObject } from "../svgobject";

export class SVGConveyor1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 13.961" enable-background="new 0 0 112.5 13.961" xml:space="preserve">
        <g id="Group_Inner_Area">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M91.32,13.961l1.802-0.563l0.563-1.802l-0.563-1.688L91.32,9.345   H2.265L0.576,9.908l-0.563,1.688l0.563,1.802l1.689,0.563H91.32z" />
        </g>
        <g id="Group_Wheel">
            <radialGradient id="SVGID_1_" cx="2.3208" cy="11.6523" r="2.3083" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.576,13.285l-0.563-1.576l0.563-1.688l1.801-0.676   l1.576,0.676l0.676,1.688l-0.676,1.576l-1.576,0.676L0.576,13.285z" />
            <radialGradient id="SVGID_2_" cx="6.9932" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.304,13.285l-0.675-1.576l0.675-1.688l1.689-0.676   l1.689,0.676l0.675,1.688l-0.675,1.576l-1.689,0.676L5.304,13.285z" />
            <radialGradient id="SVGID_3_" cx="11.6655" cy="11.6523" r="2.3083" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.033,13.285l-0.676-1.576l0.676-1.688l1.688-0.676   l1.576,0.676l0.676,1.688l-0.676,1.576l-1.576,0.676L10.033,13.285z" />
            <radialGradient id="SVGID_4_" cx="16.3379" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M14.649,13.285l-0.675-1.576l0.675-1.688l1.689-0.676   l1.689,0.676l0.675,1.688l-0.675,1.576l-1.689,0.676L14.649,13.285z" />
            <radialGradient id="SVGID_5_" cx="21.0664" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M19.378,13.285l-0.676-1.576l0.676-1.688l1.688-0.676   l1.689,0.676l0.675,1.688l-0.675,1.576l-1.689,0.676L19.378,13.285z" />
            <radialGradient id="SVGID_6_" cx="25.7388" cy="11.6523" r="2.3083" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M24.106,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.576,0.676l0.675,1.688l-0.675,1.576l-1.576,0.676L24.106,13.285z" />
            <radialGradient id="SVGID_7_" cx="30.4111" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M28.723,13.285l-0.676-1.576l0.676-1.688l1.688-0.676   l1.689,0.676l0.675,1.688L32.1,13.285l-1.689,0.676L28.723,13.285z" />
            <radialGradient id="SVGID_8_" cx="35.1396" cy="11.6523" r="2.3368" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M33.451,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.688,0.676l0.676,1.688l-0.676,1.576l-1.688,0.676L33.451,13.285z" />
            <radialGradient id="SVGID_9_" cx="39.8125" cy="11.6523" r="2.3081" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M38.067,13.285l-0.563-1.576l0.563-1.688l1.688-0.676   l1.689,0.676l0.675,1.688l-0.675,1.576l-1.689,0.676L38.067,13.285z" />
            <radialGradient id="SVGID_10_" cx="44.4844" cy="11.6523" r="2.3368" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M42.796,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.688,0.676l0.676,1.688l-0.676,1.576l-1.688,0.676L42.796,13.285z" />
            <radialGradient id="SVGID_11_" cx="49.1572" cy="11.6523" r="2.3081" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M47.524,13.285l-0.675-1.576l0.675-1.688l1.689-0.676   l1.576,0.676l0.675,1.688l-0.675,1.576l-1.576,0.676L47.524,13.285z" />
            <radialGradient id="SVGID_12_" cx="53.8291" cy="11.6523" r="2.3368" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M52.141,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.688,0.676l0.676,1.688l-0.676,1.576l-1.688,0.676L52.141,13.285z" />
            <radialGradient id="SVGID_13_" cx="58.5576" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.869,13.285l-0.675-1.576l0.675-1.688l1.689-0.676   l1.688,0.676l0.675,1.688l-0.675,1.576l-1.688,0.676L56.869,13.285z" />
            <radialGradient id="SVGID_14_" cx="63.2305" cy="11.6523" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M61.598,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.576,0.676l0.676,1.688l-0.676,1.576l-1.576,0.676L61.598,13.285z" />
            <radialGradient id="SVGID_15_" cx="67.9033" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M66.214,13.285l-0.675-1.576l0.675-1.688l1.688-0.676   l1.689,0.676l0.676,1.688l-0.676,1.576l-1.689,0.676L66.214,13.285z" />
            <radialGradient id="SVGID_16_" cx="72.6318" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M70.942,13.285l-0.675-1.576l0.675-1.688l1.688-0.676   l1.689,0.676l0.676,1.688l-0.676,1.576l-1.689,0.676L70.942,13.285z" />
            <radialGradient id="SVGID_17_" cx="77.3037" cy="11.6523" r="2.3081" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M75.559,13.285l-0.563-1.576l0.563-1.688l1.689-0.676   l1.688,0.676l0.675,1.688l-0.675,1.576l-1.688,0.676L75.559,13.285z" />
            <radialGradient id="SVGID_18_" cx="81.9756" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M80.287,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.688,0.676l0.675,1.688l-0.675,1.576l-1.688,0.676L80.287,13.285z" />
            <radialGradient id="SVGID_19_" cx="86.6484" cy="11.6523" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.016,13.285l-0.676-1.576l0.676-1.688l1.689-0.676   l1.576,0.676l0.676,1.688l-0.676,1.576l-1.576,0.676L85.016,13.285z" />
            <radialGradient id="SVGID_20_" cx="91.3213" cy="11.6523" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M89.632,13.285l-0.675-1.576l0.675-1.688l1.688-0.676   l1.689,0.676l0.676,1.576l-0.676,1.688l-1.689,0.676L89.632,13.285z" />
        </g>
        <g id="Group_Axial">
            <radialGradient id="SVGID_21_" cx="2.3208" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M1.477,12.497l-0.338-0.788l0.338-0.9l0.788-0.338l0.9,0.338l0.338,0.9l-0.338,0.788l-0.9,0.338   L1.477,12.497z" />
            <radialGradient id="SVGID_22_" cx="6.937" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_22_)" d="M6.092,12.497l-0.337-0.788l0.337-0.9l0.901-0.338l0.788,0.338l0.338,0.9l-0.338,0.788   l-0.788,0.338L6.092,12.497z" />
            <radialGradient id="SVGID_23_" cx="11.6655" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_23_)" d="M10.821,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.788,0.338l0.338,0.9l-0.338,0.788l-0.788,0.338   L10.821,12.497z" />
            <radialGradient id="SVGID_24_" cx="16.394" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M15.55,12.497l-0.338-0.788l0.338-0.9l0.788-0.338l0.901,0.338l0.337,0.9l-0.337,0.788   l-0.901,0.338L15.55,12.497z" />
            <radialGradient id="SVGID_25_" cx="21.0103" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_25_)" d="M20.166,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.788,0.338l0.338,0.9l-0.338,0.788l-0.788,0.338   L20.166,12.497z" />
            <radialGradient id="SVGID_26_" cx="25.7388" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_26_)" d="M24.895,12.497l-0.338-0.788l0.338-0.9l0.901-0.338l0.788,0.338l0.337,0.9l-0.337,0.788   l-0.788,0.338L24.895,12.497z" />
            <radialGradient id="SVGID_27_" cx="30.4678" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_27_)" d="M29.623,12.497l-0.338-0.788l0.338-0.9l0.788-0.338l0.901,0.338l0.338,0.9l-0.338,0.788   l-0.901,0.338L29.623,12.497z" />
            <radialGradient id="SVGID_28_" cx="35.0835" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_28_)" d="M34.239,12.497l-0.338-0.788l0.338-0.9l0.901-0.338l0.788,0.338l0.337,0.9l-0.337,0.788   l-0.788,0.338L34.239,12.497z" />
            <radialGradient id="SVGID_29_" cx="39.8125" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_29_)" d="M38.968,12.497l-0.338-0.788l0.338-0.9l0.788-0.338l0.901,0.338l0.338,0.9l-0.338,0.788   l-0.901,0.338L38.968,12.497z" />
            <radialGradient id="SVGID_30_" cx="44.4282" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_30_)" d="M43.584,12.497l-0.338-0.788l0.338-0.9l0.901-0.338l0.788,0.338l0.337,0.9l-0.337,0.788   l-0.788,0.338L43.584,12.497z" />
            <radialGradient id="SVGID_31_" cx="49.1572" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_31_)" d="M48.313,12.497l-0.338-0.788l0.338-0.9l0.901-0.338l0.788,0.338l0.338,0.9l-0.338,0.788   l-0.788,0.338L48.313,12.497z" />
            <radialGradient id="SVGID_32_" cx="53.8857" cy="11.6523" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_32_)" d="M53.042,12.497l-0.338-0.788l0.338-0.9l0.788-0.338l0.9,0.338l0.338,0.9l-0.338,0.788l-0.9,0.338   L53.042,12.497z" />
            <radialGradient id="SVGID_33_" cx="58.502" cy="11.6523" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_33_)" d="M57.657,12.497l-0.338-0.788l0.338-0.9l0.901-0.338l0.787,0.338l0.338,0.9l-0.338,0.788   l-0.787,0.338L57.657,12.497z" />
            <radialGradient id="SVGID_34_" cx="63.2305" cy="11.6523" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_34_)" d="M62.387,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.787,0.338l0.338,0.9l-0.338,0.788l-0.787,0.338   L62.387,12.497z" />
            <radialGradient id="SVGID_35_" cx="67.959" cy="11.6523" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_35_)" d="M67.115,12.497l-0.338-0.788l0.338-0.9l0.787-0.338l0.9,0.338l0.338,0.9l-0.338,0.788l-0.9,0.338   L67.115,12.497z" />
            <radialGradient id="SVGID_36_" cx="72.5752" cy="11.6523" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_36_)" d="M71.73,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.789,0.338l0.338,0.9l-0.338,0.788l-0.789,0.338   L71.73,12.497z" />
            <radialGradient id="SVGID_37_" cx="77.3037" cy="11.6523" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_37_)" d="M76.459,12.497l-0.338-0.788l0.338-0.9l0.789-0.338l0.9,0.338l0.338,0.9l-0.338,0.788l-0.9,0.338   L76.459,12.497z" />
            <radialGradient id="SVGID_38_" cx="81.9199" cy="11.6523" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_38_)" d="M81.076,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.787,0.338l0.338,0.9l-0.338,0.788l-0.787,0.338   L81.076,12.497z" />
            <radialGradient id="SVGID_39_" cx="86.6484" cy="11.6523" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_39_)" d="M85.805,12.497l-0.338-0.788l0.338-0.9l0.9-0.338l0.788,0.338l0.337,0.9l-0.337,0.788l-0.788,0.338   L85.805,12.497z" />
            <radialGradient id="SVGID_40_" cx="91.377" cy="11.6523" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_40_)" d="M90.533,12.497l-0.338-0.788l0.338-0.9l0.787-0.338l0.901,0.338l0.338,0.9l-0.338,0.788   l-0.901,0.338L90.533,12.497z" />
        </g>
        <g id="Group_Conveyor_Belt">
            <path fill="#B2B2B2" d="M2.265,9.345L21.066,0h89.057l1.688,0.563L93.122,9.908L91.32,9.345H2.265z" />
            <path fill="#666666" d="M93.686,11.597l-0.563,1.802l18.689-9.345l0.676-1.801L93.686,11.597z" />
            <path fill="#8C8C8C" d="M93.686,11.597l-0.563-1.688l18.689-9.345l0.676,1.689L93.686,11.597z" />
            <path fill="#999999" d="M2.265,9.345L0.576,9.908l18.689-9.345L21.066,0L2.265,9.345z" />
            <path fill="#A6A6A6" stroke="#7F7F7F" stroke-width="0.25" d="M63.85,9.345L82.539,0h9.345L73.194,9.345H63.85z" />
            <path fill="#A6A6A6" stroke="#7F7F7F" stroke-width="0.25" d="M22.192,9.345L40.995,0h9.345L31.65,9.345H22.192z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M93.122,13.398l0.563-1.802l-0.563-1.688L91.32,9.345H2.265L0.576,9.908   l18.689-9.345L21.066,0h89.057l1.688,0.563l0.676,1.689l-0.676,1.801L93.122,13.398" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Inner_Area",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Conveyor_Belt",[0.0, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.90625, 23.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.703125, 75.0, 5.0, 0.703125, 75.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGChemical15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.959 112.5" enable-background="new 0 0 74.959 112.5" xml:space="preserve">
        <g id="Group_Pipe2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="37.48" y1="20.3374" x2="37.48" y2="2.8569">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M9.356,21.194V2.257h56.248v18.937H9.356z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.356,21.194V2.257h56.248v18.937H9.356" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="70.2813" y1="23.7075" x2="70.2813" y2="-0.2304">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M74.959,23.448V0.002h-9.355v23.446H74.959z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.959,23.448V0.002h-9.355v23.446H74.959" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.6216" y1="23.5146" x2="4.6216" y2="0.1553">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M9.243,23.448V0.002H0v23.446H9.243z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.243,23.448V0.002H0v23.446H9.243" />
        </g>
        <g id="Group_Column">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="4.6211" y1="51.5752" x2="70.3447" y2="51.5752">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M70.338,32.804c0,0,1.366-32.804-35.282-32.796   C4.178,0.014,4.622,32.804,4.622,32.804v56.248c0,0,0.184,7.591,4.358,11.073c3.75,3.129,11.423,3.017,11.423,3.017h35.845   c0,0,8.499,0.112,11.735-5.241c2.265-3.746,2.355-10.539,2.355-10.539V32.804" />
        </g>
        <g id="Group_Pipe1">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="25.7002" y1="107.876" x2="49.1455" y2="107.876">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M25.7,103.254h23.445v9.244H25.7V103.254z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.7,103.254h23.445v9.244H25.7V103.254" />
        </g>
        <g id="Group_Main">
            <path fill="#7F4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M27.391,28.07h20.176l-1.689-11.723H29.082L27.391,28.07z" />
            <path fill="#990000" stroke="#4C4C4C" stroke-width="0.25" d="M29.082,26.492h16.796l-1.24-8.454H30.322L29.082,26.492z" />
        </g>
        <g id="Group_Circle">
            <circle fill="#CCCCCC" stroke="#FFFFFF" stroke-width="0.25" cx="37.423" cy="39.793" r="6.989" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M42.342,34.828c2.742,2.716,2.763,7.141,0.047,9.883   c-2.717,2.743-7.142,2.764-9.884,0.047" />
            <circle fill="#A6A6A6" stroke="#4C4C4C" stroke-width="0.25" cx="37.423" cy="42.16" r="2.367" />
        </g>
        <g id="Group_Divider">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="0" y1="56.3066" x2="74.959" y2="56.3066">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M0,49.261h74.959v14.09H0V49.261z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,49.261h74.959v14.09H0V49.261z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,56.25h74.959" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M61.546,49.261h7.552v-2.931h-7.552V49.261z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M5.749,49.261h7.665v-2.931H5.749V49.261z" />
            <path fill="#BFBFBF" stroke="#4C4C4C" stroke-width="0.25" d="M44.524,49.261h7.665v-2.931h-7.665V49.261z" />
            <path fill="#BFBFBF" stroke="#4C4C4C" stroke-width="0.25" d="M22.882,49.261h7.552v-2.931h-7.552V49.261z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Main",[0.0, 0.7239583333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Circle",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.703125, 75.0]);
		this.fillGradient(this.fills,"Group_Divider",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.5052083333333334, 125.0, 3.0, 0.5052083333333334, 125.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGHeating25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.771 112.5" enable-background="new 0 0 74.771 112.5" xml:space="preserve">
        <g id="Group_U_Tube_Area">
            
                <radialGradient id="SVGID_1_" cx="-137.5144" cy="-118.6133" r="19.2999" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 149.949 232.9704)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.08" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.41" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M29.079,93.059c-0.13-0.341-0.236-0.737-0.31-1.224L12.148,91.88   c0,13.037,10.052,19.62,19.611,19.62c0,0,0.101-9.107,0.007-14.432C30.135,96.328,29.242,94.736,29.079,93.059z" />
            
                <radialGradient id="SVGID_2_" cx="-138.0076" cy="-118.6138" r="19.3041" gradientTransform="matrix(4.340428e-008 1.0179 -0.9836 4.407553e-008 -72.9663 233.4729)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.08" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.41" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M45.618,91.835c-0.115,0.782-0.305,1.354-0.563,1.78c-0.271,1.4-1.06,2.673-2.366,3.356   c-0.097,5.309,0.004,14.528,0.004,14.528c9.354,0,19.191-6.583,19.191-19.62L45.618,91.835z" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-179.0525" y1="97.3594" x2="-167.9949" y2="97.3594" gradientTransform="matrix(-0.0203 -1.5018 1.4144 -0.0191 -103.6919 -155.5886)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.07" style="stop-color:#787878" />
                <stop offset="0.47" style="stop-color:#E0E0E0" />
                <stop offset="0.84" style="stop-color:#8A8A8A" />
                <stop offset="1" style="stop-color:#636363" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M43.716,96.249c-0.796,0.762-1.904,1.251-3.33,1.251c-0.058,0-0.11-0.007-0.166-0.008   c-0.029,0-0.055,0.008-0.084,0.008c-0.346,0-0.663-0.04-0.97-0.097c-0.324,0.061-0.665,0.097-1.03,0.097   c-0.172,0-0.336-0.011-0.499-0.024c-0.885,0.079-1.78,0.035-2.673-0.079c-0.337,0.065-0.696,0.104-1.079,0.104   c-1.087,0-1.986-0.289-2.707-0.762l0.2,14.799l12.541-0.17L43.716,96.249z" />
        </g>
        <g id="Group_Coil_Area">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-131.7156" y1="76.668" x2="-115.4109" y2="76.668" gradientTransform="matrix(1 0 0 -1 177.2959 166.1289)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.07" style="stop-color:#757575" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.92" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#5E5E5E" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M45.58,93.443h16.305v-7.965H45.58V93.443z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-132.2161" y1="76.668" x2="-115.49" y2="76.668" gradientTransform="matrix(-1 0 0 -1 -103.3423 166.1289)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.07" style="stop-color:#757575" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.92" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#5E5E5E" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M28.874,93.443H12.148v-7.965h16.726V93.443z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="45.3811" y1="26.1836" x2="62.8352" y2="26.1836">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.381,31.532V20.834h17.454v10.698H45.381z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="12.0491" y1="26.1836" x2="29.5032" y2="26.1836">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.07" style="stop-color:#666666" />
                <stop offset="0.2" style="stop-color:#888888" />
                <stop offset="0.38" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#F0F0F0" />
                <stop offset="0.63" style="stop-color:#D2D2D2" />
                <stop offset="0.85" style="stop-color:#888888" />
                <stop offset="1" style="stop-color:#545454" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.049,31.532V20.834h17.454v10.698H12.049z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M45.381,30.406h17.454V78.49H45.381V30.406z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M12.049,30.406h17.454V78.49H12.049V30.406z" />
            <path fill="#333333" d="M13.175,31.532h0.45v45.832h-0.45V31.532z" />
            <path fill="#333333" d="M45.945,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M15.427,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M48.197,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M17.68,31.532h0.563v45.832H17.68V31.532z" />
            <path fill="#333333" d="M50.448,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M19.932,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M52.7,31.532h0.563v45.832H52.7V31.532z" />
            <path fill="#333333" d="M22.747,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M55.628,31.532h0.451v45.832h-0.451V31.532z" />
            <path fill="#333333" d="M25.562,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M58.444,31.532h0.45v45.832h-0.45V31.532z" />
            <path fill="#333333" d="M28.377,31.532h0.563v45.832h-0.563V31.532z" />
            <path fill="#333333" d="M61.259,31.532h0.563v45.832h-0.563V31.532z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="12.0491" y1="83.8398" x2="29.5032" y2="83.8398">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.07" style="stop-color:#666666" />
                <stop offset="0.2" style="stop-color:#888888" />
                <stop offset="0.38" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#F0F0F0" />
                <stop offset="0.63" style="stop-color:#D2D2D2" />
                <stop offset="0.85" style="stop-color:#888888" />
                <stop offset="1" style="stop-color:#545454" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.049,89.188V78.49h17.454v10.698H12.049z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="45.1702" y1="83.6836" x2="62.6243" y2="83.6836">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.17,89.032V78.334h17.454v10.698H45.17z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="66.6399" y1="18.5825" x2="66.6399" y2="3.2676">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.08" style="stop-color:#595959" />
                <stop offset="0.23" style="stop-color:#7B7B7B" />
                <stop offset="0.43" style="stop-color:#B1B1B1" />
                <stop offset="0.52" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#AFAFAF" />
                <stop offset="0.92" style="stop-color:#646464" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M64.137,3.268h5.005v15.315h-5.005V3.268z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,4.844l-1.802-0.451v-2.59l1.802-0.45V4.844z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,12.727l-1.802-0.45v-2.59l1.802-0.451V12.727z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,20.497l-1.802-0.338v-2.703l1.802-0.338V20.497z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,4.168l-1.802-0.225v-2.14l1.802-0.45V4.168z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,12.051l-1.802-0.225v-2.14l1.802-0.451V12.051z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M69.029,19.934l-1.802-0.225v-2.252l1.802-0.338V19.934z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M67.227,2.254V1.804l1.802-0.45v0.676L67.227,2.254z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M67.227,10.137v-0.45l1.802-0.451v0.676L67.227,10.137z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M67.227,17.907v-0.45l1.802-0.338v0.563L67.227,17.907z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="71.6184" y1="21.3975" x2="71.6184" y2="0.4526">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.1" d="M69.029,0.453h5.181v20.945h-5.181V0.453" />
            
                <radialGradient id="SVGID_12_" cx="106.512" cy="32.8906" r="12.8639" gradientTransform="matrix(-1.3903 0.0981 -0.1014 -1.3327 214.8675 53.2655)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.08" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.41" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" d="M64.401,3.61c-8.756,0-17.962,5.788-17.962,17.25L61.661,20.9c0.319-2.014,1.187-2.5,2.688-2.633   C64.525,14.424,64.401,3.61,64.401,3.61z" />
        </g>
        <g id="Group_Inlet">
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="65.1399" y1="18.832" x2="65.1399" y2="3.5176" gradientTransform="matrix(-1 0 0 1 73.5806 0)">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.08" style="stop-color:#595959" />
                <stop offset="0.23" style="stop-color:#7B7B7B" />
                <stop offset="0.43" style="stop-color:#B1B1B1" />
                <stop offset="0.52" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#AFAFAF" />
                <stop offset="0.92" style="stop-color:#646464" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M10.944,18.832H5.939V3.518h5.005V18.832z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,4.844l1.688-0.451v-2.59l-1.688-0.45V4.844z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,12.727l1.688-0.45v-2.59L5.743,9.236V12.727z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,20.497l1.688-0.338v-2.703l-1.688-0.338V20.497z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,4.168l1.688-0.225v-2.14l-1.688-0.45V4.168z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,12.051l1.688-0.225v-2.14L5.743,9.236V12.051z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,19.934l1.688-0.225v-2.252l-1.688-0.338V19.934z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M7.432,2.254V1.804l-1.688-0.45v0.676L7.432,2.254z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M7.432,10.137v-0.45L5.743,9.236v0.676L7.432,10.137z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M7.432,17.907v-0.45l-1.688-0.338v0.563L7.432,17.907z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="3.0969" y1="21.3975" x2="3.0969" y2="0.4526">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.1" d="M5.743,0.453H0.45v20.945h5.293V0.453" />
            
                <radialGradient id="SVGID_15_" cx="105.4368" cy="33.0762" r="12.8683" gradientTransform="matrix(1.3903 0.0981 0.1014 -1.3327 -138.3121 53.8673)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.08" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.41" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" d="M10.68,3.859c8.756,0,17.962,5.788,17.962,17.25L13.419,21.15c-0.319-2.014-1.187-2.5-2.688-2.633   C10.556,14.674,10.68,3.859,10.68,3.859z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.08, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.41, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.08, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.41, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.07, 0.9322916666666666, 0.0, 0.47, 0.25, 191.0, 0.84, 0.921875, 19.0, 1.0, 0.7708333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.07, 0.9114583333333334, 0.0, 0.5, 0.25, 191.0, 0.92, 0.9947916666666666, 1.0, 1.0, 0.7291666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.07, 0.9114583333333334, 0.0, 0.5, 0.25, 191.0, 0.92, 0.9947916666666666, 1.0, 1.0, 0.7291666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6927083333333334, 0.0, 0.07, 0.7916666666666666, 0.0, 0.2, 0.9375, 15.0, 0.38, 0.515625, 123.0, 0.52, 0.125, 223.0, 0.63, 0.359375, 163.0, 0.85, 0.9375, 15.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6927083333333334, 0.0, 0.07, 0.7916666666666666, 0.0, 0.2, 0.9375, 15.0, 0.38, 0.515625, 123.0, 0.52, 0.125, 223.0, 0.63, 0.359375, 163.0, 0.85, 0.9375, 15.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.5989583333333334, 0.0, 0.08, 0.6927083333333334, 0.0, 0.23, 0.9583333333333334, 0.0, 0.43, 0.6197916666666666, 97.0, 0.52, 0.40625, 151.0, 0.65, 0.6354166666666666, 93.0, 0.92, 0.7760416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.6927083333333334, 0.0, 0.08, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.41, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.5989583333333334, 0.0, 0.08, 0.6927083333333334, 0.0, 0.23, 0.9583333333333334, 0.0, 0.43, 0.6197916666666666, 97.0, 0.52, 0.40625, 151.0, 0.65, 0.6354166666666666, 93.0, 0.92, 0.7760416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.6927083333333334, 0.0, 0.08, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.41, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Coil_Area",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.3958333333333333, 0.0, 4.0, 0.3958333333333333, 0.0, 5.0, 0.3958333333333333, 0.0, 6.0, 0.3958333333333333, 0.0, 7.0, 0.3958333333333333, 0.0, 8.0, 0.3958333333333333, 0.0, 9.0, 0.3958333333333333, 0.0, 10.0, 0.3958333333333333, 0.0, 11.0, 0.3958333333333333, 0.0, 12.0, 0.3958333333333333, 0.0, 13.0, 0.3958333333333333, 0.0, 14.0, 0.3958333333333333, 0.0, 15.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFinish14 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 108.223 112.5" enable-background="new 0 0 108.223 112.5" xml:space="preserve">
        <g id="Group_Bottom_Support_Wheel">
            <radialGradient id="SVGID_1_" cx="14.866" cy="109.2344" r="3.0408" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#9E9E9E" />
                <stop offset="0.65" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" cx="14.866" cy="109.234" r="3.041" />
            <radialGradient id="SVGID_2_" cx="91.7805" cy="109.2344" r="3.1528" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#9E9E9E" />
                <stop offset="0.65" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" cx="91.781" cy="109.234" r="3.153" />
            <path fill="#666666" d="M6.646,94.933h93.243l8.333,11.148H0.001L6.646,94.933z" />
            <path fill="#333333" d="M0.677,109.234h107.545v-3.153H0.001L0.677,109.234z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M6.646,94.933h93.243l8.333,11.148l-0.226,3.153H0.001v-3.153   L6.646,94.933" />
        </g>
        <g id="Group_Cylinder_Base">
            <path fill="#333333" d="M64.979,76.238h23.198v22.41H64.979V76.238z" />
        </g>
        <g id="Group_Box">
            <path fill="#D9CCB3" stroke="#4C4C4C" stroke-width="0.25" d="M11.825,44.257h30.631v52.702H11.825V44.257z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M9.236,44.257h35.698v-3.604H9.236V44.257z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.2" d="M9.236,72.41h35.698v-3.604H9.236V72.41z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M9.236,99.438h35.698v-3.83H9.236V99.438z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="27.9724" y1="52.1748" x2="23.5565" y2="40.0423">
                <stop offset="0" style="stop-color:#DBCEB5" />
                <stop offset="0.47" style="stop-color:#CCBFA6" />
                <stop offset="1" style="stop-color:#CCBFA6" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M11.979,44.257h30.324v2.703l-30.324,8.559V44.257z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="27.9734" y1="80.4287" x2="23.5572" y2="68.2953">
                <stop offset="0" style="stop-color:#DBCEB5" />
                <stop offset="0.33" style="stop-color:#CCBFA6" />
                <stop offset="1" style="stop-color:#C4B5A6" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M11.979,72.51h30.324v2.703l-30.324,8.558V72.51z" />
        </g>
        <g id="Group_Connectors">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="67.4563" y1="83.4453" x2="70.6096" y2="83.4453">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M70.61,75.563v15.767h-3.153V75.563H70.61" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="84.3479" y1="83.4453" x2="87.5012" y2="83.4453">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M87.501,75.563v15.767h-3.153V75.563H87.501" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="74.8889" y1="83.4453" x2="78.0422" y2="83.4453">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M78.042,75.563v15.767h-3.153V75.563H78.042" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="12.9514" y1="67.793" x2="16.1047" y2="67.793">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M16.105,38.626v58.333h-3.153V38.626H16.105" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="75.2268" y1="91.3291" x2="75.2268" y2="88.1758">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M98.087,91.329H52.366v-3.153h45.721V91.329" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="26.2395" y1="100.6758" x2="29.1682" y2="100.6758">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M29.168,95.607v10.136H26.24V95.607H29.168" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="34.3479" y1="100.6758" x2="37.2756" y2="100.6758">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M37.276,95.607v10.136h-2.928V95.607H37.276" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="37.783" y1="100.1133" x2="37.783" y2="96.959">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M61.825,100.113H13.74v-3.154h48.086V100.113" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="78.3792" y1="101.2383" x2="78.3792" y2="95.6074">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.32" style="stop-color:#999999" />
                <stop offset="0.68" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M61.825,101.238h33.108v-5.631H61.825V101.238" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="50.7898" y1="94.2568" x2="50.7898" y2="84.3467">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M49.213,84.347h3.153v9.91h-3.153V84.347" />
            <path fill="#333340" d="M20.61,82.996h28.604v12.611H20.61V82.996z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="32.0959" y1="95.1582" x2="32.0959" y2="83.6709">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M20.61,83.671h22.973v11.487H20.61V83.671z" />
        </g>
        <g id="Group_Pipes">
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="52.7039" y1="27.3647" x2="52.7039" y2="24.2119">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M57.996,27.365H47.411v-3.153h10.585V27.365" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="73.0872" y1="9.2344" x2="76.2395" y2="9.2344">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M76.24,2.365v13.739h-3.152V2.365H76.24" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="48.7629" y1="23.9863" x2="51.6907" y2="23.9863">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M51.691,7.32v33.333h-2.928V7.32H51.691" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="52.7039" y1="43.8062" x2="52.7039" y2="40.6533">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M57.996,43.806H47.411v-3.153h10.585V43.806" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="60.8118" y1="7.3198" x2="60.8118" y2="4.1665">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M74.213,7.32H47.411V4.167h26.802V7.32" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="68.8079" y1="1.1826" x2="81.1946" y2="1.1826">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M81.195,0v2.365H68.808V0H81.195" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M79.844,9.459h4.504V8.671h-4.504V9.459z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="78.968" y1="10.4731" x2="78.968" y2="7.3198">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M81.87,10.473h-5.805V7.32h5.805V10.473" />
            <radialGradient id="SVGID_23_" cx="84.1233" cy="8.8965" r="1.5764" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_23_)" cx="84.123" cy="8.896" r="1.577" />
        </g>
        <g id="Group_Filter_Unit">
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="58.0471" y1="73.4443" x2="94.9397" y2="73.4443">
                <stop offset="0" style="stop-color:#000D40" />
                <stop offset="0.19" style="stop-color:#192C5F" />
                <stop offset="0.5" style="stop-color:#324C7F" />
                <stop offset="0.87" style="stop-color:#16285B" />
                <stop offset="1" style="stop-color:#000D40" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" d="M58.047,68.396c0,2.126,0,4.252,0,6.377c3.4,2.381,10.481,4.019,18.673,4.019   c7.843,0,14.67-1.501,18.22-3.72c0-2.219,0-4.756,0-6.974" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="54.3928" y1="45.2705" x2="98.6497" y2="45.2705">
                <stop offset="0" style="stop-color:#000D40" />
                <stop offset="0.5" style="stop-color:#324C7F" />
                <stop offset="1" style="stop-color:#000D40" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M94.934,74.438V19.933h3.716v-3.829H54.393v3.829h3.604v54.505" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.996,19.933h36.938" />
            <path fill="#000033" d="M69.258,31.194h14.414v4.279H69.258V31.194z" />
            <path fill="#000033" d="M57.996,24.212l-3.604-4.279H98.65l-3.716,4.279H57.996z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M81.87,13.739h1.802v10.022H81.87V13.739z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M87.501,13.802h1.802v9.91h-1.802V13.802z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M92.456,13.739h1.802v10.022h-1.802V13.739z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M74.889,13.739h1.802v10.022h-1.802V13.739z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M66.781,13.739h1.802v10.022h-1.802V13.739z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M60.474,13.739h2.027v10.022h-2.027V13.739z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M56.87,13.739h1.802v10.022H56.87V13.739z" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
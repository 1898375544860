import { Tile } from "./tile";
import { FillColorProperty } from "../properties/fillcolorproperty";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tag from "@/model/tag";

export class PercantageTile extends Tile{
	fillcolor:string;
	unit:string;
    public drawObject():void{
		super.initObject();


		let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let fillcolor = '0xffffff00';         
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
      const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    let value = 0   
    let valueproperty
    const valueprop = this.properties['value'];
    if (valueprop!=null){	
      valueproperty =Object.assign(new ValueProperty(), valueprop);
      if (valueproperty!=null) {
        const tag:tag = valueproperty.getTag(this.tagnames)  
         if (tag!=null) value = Number(tag.tagvalue)     
     }      
    }

    let decimalpos=0;
    if (valueproperty!=null) decimalpos=valueproperty.decimalpos;

    let maxvalue;
    if(valueproperty==null){
      maxvalue = "N/A"
    }else{
      maxvalue = valueproperty.maximumvalue + this.unit
    }        

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const barRect = new Konva.Rect({
      x: 0,
      y: this.height - size * 0.035,
      width: this.width,
      height: size * 0.035,
      fill: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5)
    });
    group.add(barRect)

    const percent = valueproperty.getPercent(this.tagnames);
              
    const barRectFill = new Konva.Rect({
      x: 0,
      y: this.height - size * 0.035,
      width: percent*this.width,
      height: size * 0.035,
      fill: ColorUtils.convertformat(fillcolor),
    });
    group.add(barRectFill)


    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const unitTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign: "bottom",
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    }); 
    
    const unitText = new Konva.Text({
      x: this.width-unitTextTransparent.width()-size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: unitTextTransparent.width(),
      height: 0.24*size,
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });  

    const valueText = new Konva.Text({
      x: size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: this.width-size*0.05*2-unitTextTransparent.width(),
      height: 0.24*size,
      text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.24*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'right',
      offsetY:-size*0.024
    }); 

    group.add(valueText, unitText)

  const descriptionText = new Konva.Text({
    x: size*0.05,
    y: size*0.52-0.1*size,
    verticalAlign: "bottom",
    width: this.width-size*0.05*2,
    height: 0.1*size,
    text: this.description,
    fontSize: 0.1*size,
    fontFamily: StringUtils.getFontFamily(this.fonttype),
    fontStyle: StringUtils.getFontStyle(this.fonttype),
    fill: ColorUtils.convertformat(textcolor),
    align: 'right'
  });

  const percentageText = new Konva.Text({
    x: size*0.05,
    y: this.height-size*0.1-size*0.18,
    verticalAlign: "bottom",
    //width: this.width-size*0.05*2,
    height: 0.18*size,
    text: parseFloat((percent*100).toFixed(decimalpos)).toLocaleString(),
    fontSize: 0.18*size,
    fontFamily: StringUtils.getFontFamily(this.fonttype),
    fontStyle: StringUtils.getFontStyle(this.fonttype),
    fill: ColorUtils.convertformat(fillcolor),
    align: 'left',
    offsetY:-size*0.024
  });

const unitPerText = new Konva.Text({
  x: size*0.05+percentageText.width(),
  y: this.height-size*0.1-size*0.18,
  verticalAlign: "bottom",
 // width: size*0.05+percentageText.width(),
  height: 0.18*size,
  text: "%",
  fontSize: 0.09*size,
  fontFamily: StringUtils.getFontFamily(this.fonttype),
  fontStyle: StringUtils.getFontStyle(this.fonttype),
  fill: ColorUtils.convertformat(fillcolor),
  align: 'left'
});
group.add(descriptionText, percentageText,unitPerText)

const maxValueTextTransparent = new Konva.Text({
  x: 0,
  y: 0,
  verticalAlign: "bottom",
  text: maxvalue,
  fontSize: 0.08*size,
  fontFamily: StringUtils.getFontFamily(this.fonttype),
  fontStyle: StringUtils.getFontStyle(this.fonttype),
  fill: ColorUtils.convertformat(fillcolor),
  align: 'left'
});
const maxValueText = new Konva.Text({
  x: this.width - size * 0.075-maxValueTextTransparent.width(),
  y: this.height - size * 0.145-maxValueTextTransparent.height(),
  verticalAlign: "center",
 // width: size*0.05+percentageText.width(),
 // height: 0.18*size,
  text: maxvalue,
  fontSize: 0.08*size,
  fontFamily: StringUtils.getFontFamily(this.fonttype),
  fontStyle: StringUtils.getFontStyle(this.fonttype),
  fill: ColorUtils.convertformat(bgcolor),
  align: 'center'
});

  const maxValueRect = new Konva.Rect({
    x: this.width - size*0.075-maxValueTextTransparent.width()-size*0.03,
    y: this.height - size*0.145-maxValueTextTransparent.height()-0.01*size,
    width: maxValueTextTransparent.width()+ size*0.03*2,
    height: 0.08*size+0.01*size,
    fill: percent>=1? ColorUtils.convertformat(fillcolor): ColorUtils.darkColor(ColorUtils.convertformat(bgcolor), 3),
    cornerRadius: 0.025*size
  });
  group.add(maxValueRect, maxValueText)

  this.node.add(group)     
}
}
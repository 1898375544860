import { Property } from "./property";
import { DurationRange } from "./range/durationrange";
import tag from "@/model/tag";

export class FlashProperty extends Property{
    value:string;
    type:number;
    trueflashduration:number;
    falseflashduration:number;
    durationrange:DurationRange[];

    public CheckFlash(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return 0;
        let duration:number = this.falseflashduration;
      console.log('CheckFlash')
        switch (this.type)
          {
              case 0: if (+this.value==+tag.tagvalue)
                              duration = this.trueflashduration;
                      break;
              case 1: if (+tag.tagvalue>=+this.value)
                              duration = this.trueflashduration;
                      break;
              case 2:if (+tag.tagvalue<=+this.value)
                 duration = this.trueflashduration;
                      break;
              case 3: if (+tag.tagvalue>+this.value)
                duration = this.trueflashduration;
                      break;
              case 4: if (+tag.tagvalue<+this.value)
                duration = this.trueflashduration;
                      break;
              case 5: if (+tag.tagvalue!=+this.value)
               duration = this.trueflashduration;
                      break;
              case 6: for (const mrange of this.durationrange)
             {
             if (mrange.from<=+tag.tagvalue && +tag.tagvalue<=mrange.to){
               duration = mrange.duration;
               break;
            }
          }
          break;
              default: break;
          }
          return duration;
    }
}
import { SVGObject } from "../svgobject";

export class SVGConveyor7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 74.664" enable-background="new 0 0 112.5 74.664" xml:space="preserve">
        <g id="Group_Cross_Cables">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M77.928,69.82l11.712-9.234l-10.474-8.107l9.234-6.982l-8.107-6.869   l6.982-5.968l-5.744-5.743l4.505-3.604" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M89.64,69.82l-11.712-9.234L88.4,52.479l-9.234-6.982l8.109-6.869   l-6.982-5.968l5.743-5.743l-4.505-3.604" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.396,69.82l9.347-9.234l-8.108-4.504l7.207-6.081l-6.306-3.266   l5.067-4.843" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.743,69.82l-9.347-9.234l8.446-4.504l-7.207-6.081l5.968-3.266   l-5.067-4.843" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.077,72.299l9.234-5.744l-7.208-5.969l4.842-2.139" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M23.311,72.299l-9.234-5.744l6.869-5.969l-4.842-2.139" />
        </g>
        <g id="Group_Horizontal_Support">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M80.293,44.934h6.982v1.802h-6.982V44.934z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M47.635,54.844h7.207v1.801h-7.207V54.844z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M15.203,65.316h6.982v1.802h-6.982V65.316z" />
        </g>
        <g id="Group_Pillars">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M76.689,74.663l3.604-55.856h6.982l3.604,55.856h-1.803   l-3.378-54.279h-3.604l-3.604,54.279H76.689z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M44.369,74.663l3.266-37.162h7.207l3.265,37.162h-1.801   l-3.266-35.474h-3.604l-3.604,35.474H44.369z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,74.663l3.604-18.581h6.982l3.266,18.581h-1.464   l-3.604-16.779h-3.604l-3.378,16.779H11.599z" />
        </g>
        <g id="Group_Converyor_Cage">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M1.126,65.091L110.022,2.028l2.478,4.167L3.491,68.92L1.126,65.091z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M3.491,68.92l2.14-6.531l6.869,1.464l2.14-6.645l6.869,1.239   l2.478-6.645l6.87,1.576l2.14-6.644l6.869,1.238l2.027-6.644l6.982,1.464l2.027-6.532l6.982,1.239l2.365-6.644l6.869,1.464   l2.14-6.532l6.869,1.126l2.14-6.532l6.869,1.464l2.141-6.644l6.869,1.577l2.364-6.982l6.982,1.577l2.026-6.645l6.982,1.464" />
        </g>
        <g id="Group_Conveyor_Belt">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M1.689,65.992L110.698,2.929l1.126,2.14L2.928,68.02L1.689,65.992z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,62.951l108.896-62.95l1.126,2.027L1.126,65.091L0,62.951z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Horizontal_Support",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Pillars",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Converyor_Cage",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Conveyor_Belt",[0.0, 0.9895833333333334, 0.0, 1.0, 0.20833333333333334, 201.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { ObjectView } from "./object";
import { TagTextValueRange } from './properties/range/tagtextvaluerange';
import tagsModele from "@/store/tags.modele";
import { Value } from '../value';

export class Checkbox extends ObjectView{
    color:string;
    type3d:number;
    fillcolor:string;
    linewidth:number;
    ranges:TagTextValueRange[];

    public drawObject():void{
      super.initObject();
      this.ranges.forEach(range => {
        this.tagnames.push(range.path)
      });
   

      let color:string = this.color;
      const lineprop = this.properties['color'];
      if (lineprop!=null){	
        const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
        if (linecolorproperty!=null) 
          color = linecolorproperty.getColor(this.tagnames, this.color)
      }	
      const color2 = ColorUtils.convertformat(color)

      let fillcolor = '0xffffff00';
        
      fillcolor = this.fillcolor;
      const fillprop = this.properties['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
                  fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }	
      const fillcolor2 = ColorUtils.convertformat(fillcolor)

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
      gradient.addColorStop(0, color2)
      gradient.addColorStop(1, ColorUtils.darkColor(color2,0.5))

      const rect = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          cornerRadius: this.height/10,
          strokeWidth: this.linewidth==0? this.height/20 : this.linewidth,
          stroke: this.type3d==0? gradient : color2
      });
      if(this.type3d==0){
        rect.fillLinearGradientStartPoint({ x: 0, y: 0});
        rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
        rect.fillLinearGradientColorStops([0, fillcolor2,
            0.5, ColorUtils.darkColor(fillcolor2, 0.85),
            1, ColorUtils.darkColor(fillcolor2, 0.75)])
      }else{
        rect.fill(fillcolor2)
      }
      this.node.add(rect)

      const line = new Konva.Line({
        points: [this.width*4/5, 0, this.width*4/5,this.height],
        stroke: this.type3d==0? gradient : color2,    
      });
      if(this.linewidth != 0) line.strokeWidth(this.linewidth)
      this.node.add(line)

      const arrow = new Konva.Line({
          points: [this.width*17/20, this.height*4/10,
                   this.width*18/20,this.height*7/10,
                  this.width*19/20, this.height*3/10],
          stroke: this.type3d==0? gradient : color2
      });
      if(this.type3d==0){
          arrow.fillLinearGradientStartPoint({ x: 0, y: 0});
          arrow.fillLinearGradientEndPoint({ x: 0, y: this.height});
          arrow.fillLinearGradientColorStops([0, color2,
          1, ColorUtils.darkColor(color2, 0.5)])
      }else{
          arrow.fill(color2)
      }
      this.node.add(arrow)

      const  node1 = new Konva.Group();
      const height = this.height
      const width = this.width
      const ranges = this.ranges
      let flagclick = false
      this.node.add(node1)

      this.node.on('click touchstart', function(evt){
        flagclick = !flagclick;
        if (flagclick){
        drawMenu(node1, height, width)
        }else{
          node1.removeChildren()
        }
      })

      function drawMenu( node:Konva.Group, height, width){
        if(ranges.length != 0){
          ranges.forEach((textview)=>{
            const rectDropdown = new Konva.Rect({
              x: 0,
              y: height+height/40+(30*ranges.indexOf(textview)),
              width: width,
              height: 30,
              fill: 'white',
              strokeWidth: 0.05,
              stroke: 'black'
            })
            node.add(rectDropdown)
        
            const textDropDown = new Konva.Text({
            x: width/30,
            y: height+height/40+(30*ranges.indexOf(textview)), 
            height: 30,
            width: width*29/30,
            fontSize: 20,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'left',
            verticalAlign:"middle",
            })
            const tag =tagsModele.getTag(textview.path);
              if (+tag.tagvalue==textview.value){
                textDropDown.text('v '+ textview.text)
              }else{
                textDropDown.text('   '+ textview.text)
              }

            textDropDown.fill('black');
            textDropDown.on('mouseover mousemove mouseenter', function(){
              textDropDown.fill('white');
              rectDropdown.fill('blue')
            })
            textDropDown.on('mouseleave', function(){
              textDropDown.fill('black'); 
              rectDropdown.fill('white')
            })
       
            textDropDown.on('click', function(){
              if (+tag.tagvalue==textview.value){
                  tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.uncheckvalue.toString(), datetime:0});
              }else
                tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.value.toString(), datetime:0});
            })
            node.add(textDropDown)
          })
        }
      }
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color": {this[field] = value.value;this.rotatednode=null;break;}
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
     // this.drawObject();
  }

  public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color": {value.datatype=7; value.value = this[field];break}
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
          case "linewidth": {value.datatype=3; value.value = this[field];break}
      }
      return value;
  }
}
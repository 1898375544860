import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { IndicatorProperty } from './properties/indicatorproperty';
import { OvalLamp } from './ovallamp';
import Konva from "konva";

export class StackLamp extends OvalLamp{   
    

    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1
        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        
        const recttop = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height/5,
          });
          if (this.type3d==0){
            recttop.fillLinearGradientStartPoint({ x: 0, y: 0 });
            recttop.fillLinearGradientEndPoint({ x: this.width, y: 0});
            recttop.fillLinearGradientColorStops([0, '#444444',
            0.5, 'white', 
            1, '#444444'])
          }
          else{
            recttop.fill('#666666')
          }
          this.node.add(recttop)

          
        const rectbottom = new Konva.Rect({
            x: 0,
            y: this.height*4/5,
            width: this.width,
            height: this.height/5,
          });
          if (this.type3d==0){
            rectbottom.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectbottom.fillLinearGradientEndPoint({ x: this.width, y: 0});
            rectbottom.fillLinearGradientColorStops([0, '#444444',
            0.5, 'white', 
            1, '#444444'])
          }
          else{
            rectbottom.fill('#666666')
          }
          this.node.add(rectbottom)  
          
        const rectmiddle = new Konva.Rect({
            x: this.width/10,
            y: this.height/5,
            width: this.width*8/10,
            height: this.height*3/5,
          });
        if(indicator){
          rectmiddle.fill(ColorUtils.darkColor(fillcolor2,0.85))
        }else{
            if (this.type3d==0){
                rectmiddle.fillLinearGradientStartPoint({ x: 0, y: 0 });
                rectmiddle.fillLinearGradientEndPoint({ x: this.width*8/10, y: 0});
                rectmiddle.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.5),
                    0.5, 'white', 
                    1, ColorUtils.darkColor(fillcolor2,0.5)])
            }else{
                rectmiddle.fill(fillcolor2)
            }
        }
          this.node.add(rectmiddle) 

          if(indicator){
            const radius = this.height>this.width? this.width*3/10 : this.height*3/10
            const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: radius,
            })
            circle.fillRadialGradientStartPoint({x:0, y:0});
            circle.fillRadialGradientEndRadius(radius)
            circle.fillRadialGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2,0.85)]);
            circle.fillPriority('radial-graident')
            this.node.add(circle);
        }

        for(let i=1; i<10; i++){
            const rectsmall = new Konva.Rect({
                x: this.width*i/10,
                y: this.height/5,
                width: this.width/50,
                height: this.height*3/5,
              });
            if(indicator){
                    if(this.type3d==0){
                        rectsmall.fillLinearGradientStartPoint({ x: 0, y: 0 });
                        rectsmall.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                        rectsmall.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.9),
                                    0.5, 'white', 
                                    1, ColorUtils.darkColor(fillcolor2,0.9)])
                    }else{
                        rectsmall.fill('white')
                    }
            }else{
                    if(this.type3d==0){
                        rectsmall.fillLinearGradientStartPoint({ x: 0-this.width*i/10, y: 0});
                        rectsmall.fillLinearGradientEndPoint({ x: this.width-this.width*i/10, y: 0});
                        rectsmall.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.65),
                                    0.5, 'white', 
                                    1, ColorUtils.darkColor(fillcolor2,0.65)])
                    }else{
                        rectsmall.fill('white')
                    }
            }  
            this.node.add(rectsmall)  
        }

        
          
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
          
                const Text = new Konva.Text({
                    x: this.width/10,
                    y: 0, 
                    verticalAlign:"middle",
                    width: this.width*8/10,
                    height: this.height,
                    text: text,
                    fontSize: this.height*2/5,
                    fontFamily: 'Roboto',
                    fontStyle: "normal",
                    align:'center',
                    fill: ColorUtils.convertformat(textcolor),
                    
                  });

                  if(this.type3d==0){
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                             
    }
   
}
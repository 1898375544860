import { SVGObject } from "../svgobject";

export class SVGFood8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 71.957" enable-background="new 0 0 112.5 71.957" xml:space="preserve">
        <g id="Group_Pink_Box">
            <path fill="#F2E5CC" stroke="#998C73" stroke-width="0.25" d="M89.525,29.616h11.262v20.72H89.525V29.616z" />
            <path fill="none" stroke="#D9CCB3" stroke-width="0.25" d="M91.328,29.616v8.783" />
            <path fill="none" stroke="#D9CCB3" stroke-width="0.25" d="M94.932,29.616v8.783" />
            <path fill="none" stroke="#D9CCB3" stroke-width="0.25" d="M98.309,29.616v8.783" />
        </g>
        <g id="Group_Filling_Unit">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M33.222,15.652h56.529v34.684H33.222V15.652z" />
            <path fill="#B2B2B2" d="M34.573,16.779h26.351v32.206H34.573V16.779z" />
            <path fill="#B2B2B2" d="M62.049,37.273H88.4v11.711H62.049V37.273z" />
            <path fill="#B2B2B2" d="M62.049,16.779H88.4V28.04H62.049V16.779z" />
            <path fill="#B2B2B2" d="M62.049,29.166H88.4v6.982H62.049V29.166z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M33.222,12.049H20.384v38.287h12.837V12.049z" />
            <path fill="#B2B2B2" d="M21.51,13.175h10.585v35.809H21.51V13.175z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,36.147h20.382v14.188H0.002V36.147z" />
            <path fill="#B2B2B2" d="M1.128,37.273h18.13v11.711H1.128V37.273z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M89.525,38.399h16.441v11.937H89.525V38.399z" />
            <path fill="#B2B2B2" d="M90.877,39.751h13.963v9.233H90.877V39.751z" />
            <path fill="#7F7F7F" d="M34.573,46.057h26.351v2.928H34.573V46.057z" />
            <path fill="#7F7F7F" d="M62.049,46.057H88.4v2.928H62.049V46.057z" />
            <path fill="#999999" d="M46.734,22.634h14.189v24.098H46.734V22.634z" />
            <path fill="#999999" d="M62.049,37.273h13.964v9.459H62.049V37.273z" />
            <path fill="#999999" d="M62.049,29.166h8.108l0.226,2.928h5.63v4.054H62.049V29.166z" />
            <path fill="#999999" d="M62.049,22.634h8.108v5.405h-8.108V22.634z" />
            <path fill="#CCCCCC" d="M34.573,16.779h11.036v24.098H34.573V16.779z" />
            <path fill="#B2B2B2" d="M34.573,33.895h12.162v1.577H34.573V33.895z" />
            <path fill="#B2B2B2" d="M34.573,36.822h12.162v1.577H34.573V36.822z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M60.924,16.779v32.206H34.573" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M60.924,16.779H34.573v32.206" />
            <path fill="#8C8C8C" d="M41.555,39.751H57.32v7.657H41.555V39.751z" />
            <path fill="#8C8C8C" d="M66.779,44.48h19.818v2.928H66.779V44.48z" />
            <path fill="#CCCCCC" d="M77.364,37.273H88.4v3.604H77.364V37.273z" />
            <path fill="#CCCCCC" d="M77.364,29.166H88.4v6.982H77.364V29.166z" />
            <path fill="#CCCCCC" d="M77.364,16.779H88.4V28.04H77.364V16.779z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M88.4,16.779V28.04H62.049" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M88.4,16.779H62.049V28.04" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M88.4,29.166v6.982H62.049" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M88.4,29.166H62.049v6.982" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M88.4,37.273v11.711H62.049" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M88.4,37.273H62.049v11.711" />
            <path fill="#999999" d="M78.49,30.292h6.981v5.856H78.49V30.292z" />
            <path fill="#999999" d="M78.49,37.273h6.981v7.207H78.49V37.273z" />
            <path fill="#8C8C8C" d="M79.616,39.075h6.981v5.18h-6.981V39.075z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M42.681,37.949L52.815,23.76" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M47.861,34.796l4.279-5.63" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M42.681,34.346l5.855-8.108" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M71.508,47.858l7.433-10.585" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M75.337,45.606l3.153-4.279" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M71.508,45.381l4.279-6.306" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M76.914,36.147l4.504-6.531" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M79.166,34.796l2.027-2.703" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M76.914,34.571l2.477-3.829" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M74.211,27.364l6.531-9.459" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M77.59,25.337l2.702-3.829" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M74.211,25.112l3.828-5.63" />
            <path fill="#7F7F7F" d="M90.877,46.057h13.963v2.928H90.877V46.057z" />
            <path fill="#8C8C8C" d="M94.256,44.48h10.584v2.928H94.256V44.48z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M104.84,39.751v9.233H90.877" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M104.84,39.751H90.877v9.233" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M94.932,48.534l5.855-8.333" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M97.859,46.732l2.477-3.378" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M94.932,46.508l3.377-4.955" />
            <path fill="#CCCCCC" d="M26.24,13.175h5.856v32.881H26.24V13.175z" />
            <path fill="#999999" d="M21.51,32.093h7.207v7.658H21.51V32.093z" />
            <path fill="#999999" d="M27.366,48.534h1.352v-8.783h-1.352V48.534z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M22.186,37.949l9.234-12.837" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M26.916,35.247l3.829-5.405" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M22.186,34.796l5.405-7.657" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M32.096,13.4v35.584H21.51" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M32.096,13.4H21.51v35.584" />
            <path fill="#7F7F7F" d="M4.619,46.057h14.639v2.928H4.619V46.057z" />
            <path fill="#8C8C8C" d="M6.421,37.273h12.837v8.333H6.421V37.273z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M19.258,37.273v11.711H1.128" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M19.258,37.273H1.128v11.711" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M6.646,48.534l7.432-10.585" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M10.475,46.282l3.153-4.279" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M6.646,45.832l4.279-6.081" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M19.258,9.797h4.054v2.252h-4.054V9.797z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.114,9.797h4.054v2.252h-4.054V9.797z" />
            <path fill="#3D3D3D" d="M7.997,22.184h1.464v13.964H7.997V22.184z" />
            <path fill="#3D3D3D" d="M20.384,22.184v1.126H1.128v-1.126H20.384z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M6.871,22.184h1.126v13.964H6.871V22.184z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M20.384,20.833v1.351H0.565v-1.351H20.384z" />
        </g>
        <g id="Group_Blue_Indicator">
            <path fill="#98B2E5" d="M64.301,40.201h2.479v3.604h-2.479V40.201z" />
            <path fill="#98B2E5" d="M55.067,28.49l1.577-1.801l2.477,2.702l-1.576,1.577L55.067,28.49z" />
            <path fill="#98B2E5" d="M55.067,31.418l1.577-1.802l2.477,2.477l-1.576,1.802L55.067,31.418z" />
            <path fill="#7F99CC" stroke="#4C4C4C" stroke-width="0.25" d="M67.229,10.248h1.802v5.405h-1.802V10.248z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M67.904,10.473h0.676V0h-0.676V10.473z" />
        </g>
        <g id="Group_Grey_Box_Top">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="52.1177" y1="16.7134" x2="43.8296" y2="-1.0605">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" d="M41.555,0h12.837v15.652H41.555V0" />
        </g>
        <g id="Group_Measument_Analyser">
            <path fill="#CCCCCC" d="M106.98,36.147h1.463v26.351h-1.463V36.147z" />
            <path fill="#CCCCCC" d="M102.588,26.238h8.672v9.91h-8.672V26.238z" />
            <path fill="#B2B2B2" d="M111.26,26.238h1.238v9.91h-1.238V26.238z" />
            <path fill="#D9D9D9" d="M102.926,26.688h7.658v4.279h-7.658V26.688z" />
            <path fill="#BFCBE5" d="M103.715,27.364h4.054v1.802h-4.054V27.364z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M102.588,26.238h9.91v9.91h-9.91V26.238" />
        </g>
        <g id="Group_Stand">
            <path d="M5.182,68.354h7.094v-0.451H5.182V68.354z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M6.871,67.902h1.126v4.055H6.871V67.902z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M5.182,50.336h7.094v17.566H5.182V50.336z" />
        </g>
        <g id="Group_Bottom_Unit">
            <path d="M12.276,69.479h93.69v-0.45h-93.69V69.479z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M102.588,67.902h1.127v4.055h-1.127V67.902z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M83.445,67.902h1.125v4.055h-1.125V67.902z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M64.301,67.902h1.127v4.055h-1.127V67.902z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M45.158,67.902h1.126v4.055h-1.126V67.902z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M26.015,67.902h1.126v4.055h-1.126V67.902z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="62.1318" y1="76.7588" x2="56.1101" y2="42.6079">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M12.276,50.336h93.69v18.693h-93.69V50.336z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M33.222,69.029V50.336" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M33.897,69.029V50.336" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M61.6,69.029V50.336" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M62.049,69.029V50.336" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M89.751,69.029V50.336" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M90.201,69.029V50.336" />
            <path fill="#999999" d="M14.529,57.768h0.675v2.479h-0.675V57.768z" />
            <path fill="#999999" d="M30.294,57.768h0.675v2.479h-0.675V57.768z" />
            <path fill="#999999" d="M35.699,57.768h0.451v2.479h-0.451V57.768z" />
            <path fill="#999999" d="M58.445,57.768h0.676v2.479h-0.676V57.768z" />
            <path fill="#999999" d="M63.852,57.768h0.449v2.479h-0.449V57.768z" />
            <path fill="#999999" d="M86.598,57.768h0.676v2.479h-0.676V57.768z" />
            <path fill="#999999" d="M92.004,57.768h0.449v2.479h-0.449V57.768z" />
            <path fill="#999999" d="M102.926,57.768h0.789v2.479h-0.789V57.768z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.276,50.336h93.69v18.693h-93.69V50.336" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.fills,"Group_Filling_Unit",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.609375, 99.0, 5.0, 0.40625, 151.0, 6.0, 0.609375, 99.0, 7.0, 0.40625, 151.0, 8.0, 0.609375, 99.0, 9.0, 0.40625, 151.0, 10.0, 0.609375, 99.0, 11.0, 0.9895833333333334, 0.0, 12.0, 0.9895833333333334, 0.0, 13.0, 0.8072916666666666, 49.0, 14.0, 0.8072916666666666, 49.0, 15.0, 0.8072916666666666, 49.0, 16.0, 0.8072916666666666, 49.0, 17.0, 0.40625, 151.0, 18.0, 0.609375, 99.0, 19.0, 0.609375, 99.0, 20.0, 0.90625, 23.0, 21.0, 0.90625, 23.0, 22.0, 0.40625, 151.0, 23.0, 0.40625, 151.0, 24.0, 0.40625, 151.0, 25.0, 0.8072916666666666, 49.0, 26.0, 0.8072916666666666, 49.0, 27.0, 0.90625, 23.0, 28.0, 0.9895833333333334, 0.0, 29.0, 0.90625, 23.0, 30.0, 0.40625, 151.0, 31.0, 0.8072916666666666, 49.0, 32.0, 0.8072916666666666, 49.0, 33.0, 0.9895833333333334, 0.0, 34.0, 0.90625, 23.0, 35.0, 0.40625, 151.0, 36.0, 0.40625, 151.0, 37.0, 0.4739583333333333, 0.0, 38.0, 0.4739583333333333, 0.0, 39.0, 0.40625, 151.0, 40.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Blue_Indicator",[0.0, 0.546875, 115.0, 1.0, 0.546875, 115.0, 2.0, 0.546875, 115.0, 3.0, 0.7447916666666666, 65.0, 4.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Measument_Analyser",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.609375, 99.0, 3.0, 0.3020833333333333, 177.0, 4.0, 0.3802083333333333, 157.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.8072916666666666, 49.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Bottom_Unit",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0, 8.0, 0.8072916666666666, 49.0, 9.0, 0.8072916666666666, 49.0, 10.0, 0.8072916666666666, 49.0, 11.0, 0.8072916666666666, 49.0, 12.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPump1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 125.921 121.105" enable-background="new 0 0 125.921 121.105" xml:space="preserve">
        <g id="Group_OutletPort">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="119.3706" y1="-412.0227" x2="119.3706" y2="-361.6145" gradientTransform="matrix(1 0 0 -1 0 -360.895)">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.21" style="stop-color:#717171" />
                <stop offset="0.54" style="stop-color:#A6A6A6" />
                <stop offset="0.64" style="stop-color:#9F9F9F" />
                <stop offset="0.79" style="stop-color:#8C8C8C" />
                <stop offset="0.97" style="stop-color:#6D6D6D" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="112.821" y="0.719" fill="url(#SVGID_1_)" stroke="#B3B3B3" stroke-width="0.5" width="13.101" height="50.408" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="83.0708" y1="76.9197" x2="83.0708" y2="115.9207" gradientTransform="matrix(1 0 0 -1 0 122.105)">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.52" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <polyline fill="url(#SVGID_2_)" points="53.235,6.184 112.907,6.184 112.907,45.185 102.517,45.185  " />
        </g>
        <g id="Group_Legs">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="52.5283" y1="1094.1335" x2="52.5283" y2="1058.2527" gradientTransform="matrix(1 0 0 1 0 -973.75)">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#999999" stroke-width="0.396" d="M11.321,120.384l10.254-35.881h61.908l10.252,35.881H72.966   L52.462,89.497l-20.769,30.887H11.321z" />
        </g>
        <g id="Group_LegBase">
            <path fill="#999999" stroke="#B3B3B3" stroke-width="0.5" d="M0.76,115.851h37.533v5.254H0.76V115.851z" />
            <path fill="#999999" stroke="#B3B3B3" stroke-width="0.5" d="M66.603,115.851h37.929v5.254H66.603V115.851L66.603,115.851z" />
        </g>
        <g id="Group_PumpBody">
            
                <radialGradient id="SVGID_4_" cx="126.48" cy="837.1521" r="51.5659" gradientTransform="matrix(0.9999 0.0069 -0.0069 0.9999 -67.9976 -780.2224)" gradientUnits="userSpaceOnUse">
                <stop offset="0.18" style="stop-color:#333333" />
                <stop offset="0.26" style="stop-color:#383838" />
                <stop offset="0.35" style="stop-color:#454545" />
                <stop offset="0.44" style="stop-color:#5C5C5C" />
                <stop offset="0.54" style="stop-color:#7B7B7B" />
                <stop offset="0.64" style="stop-color:#A4A4A4" />
                <stop offset="0.72" style="stop-color:#CCCCCC" />
                <stop offset="0.81" style="stop-color:#B1B1B1" />
                <stop offset="0.99" style="stop-color:#6B6B6B" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M53.047,6.158c28.477,0.196,51.402,23.439,51.207,51.917c-0.195,28.475-23.438,51.398-51.914,51.204   C23.862,109.086,0.938,85.841,1.133,57.364C1.326,28.889,24.573,5.963,53.047,6.158z" />
        </g>
        <g id="Group_CenterShade">
            
                <radialGradient id="SVGID_5_" cx="52.4468" cy="-418.7268" r="27.2441" gradientTransform="matrix(1 0 0 -1 0 -360.895)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#000000" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M38.399,81.168C25.508,73.407,21.35,56.67,29.111,43.78c7.762-12.888,24.502-17.045,37.385-9.285   c12.891,7.761,17.047,24.498,9.289,37.393C68.021,84.769,51.286,88.928,38.399,81.168z" />
        </g>
        <g id="Group_Impeller">
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="101.9819" y1="125.5398" x2="106.1411" y2="125.5342" gradientTransform="matrix(0.9889 0.1484 0.1484 -0.9889 -69.6528 166.4384)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#B8B8B8" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M51.014,77.055c-0.642,4.14-2.024,7.351-3.166,7.187c-1.139-0.165-1.545-3.651-0.902-7.789   l0.014-0.081l5.874-37.983c0.64-4.139,1.986-7.314,3.125-7.149c1.142,0.163,1.507,3.623,0.866,7.76L51.014,77.055z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="-277.9653" y1="21.0618" x2="-273.8071" y2="21.0562" gradientTransform="matrix(0.6317 -0.7752 -0.7752 -0.6317 243.0495 -142.3917)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#B8B8B8" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M68.625,68.765c3.234,2.659,5.291,5.485,4.57,6.387c-0.724,0.895-3.931-0.533-7.162-3.193   l-0.063-0.053L36.284,47.493c-3.234-2.66-5.28-5.437-4.559-6.333c0.722-0.898,3.884,0.55,7.117,3.21L68.625,68.765z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="216.7651" y1="433.1179" x2="220.9233" y2="433.1123" gradientTransform="matrix(0.4225 0.9064 0.9064 -0.4225 -432.8737 42.0522)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#B8B8B8" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M35.58,67.368c-3.803,1.752-7.245,2.373-7.739,1.332c-0.491-1.041,2.193-3.306,5.994-5.057   l0.076-0.033L68.82,47.527c3.805-1.752,7.195-2.384,7.686-1.345c0.494,1.041-2.188,3.258-5.99,5.008L35.58,67.368z" />
            
                <radialGradient id="SVGID_9_" cx="51.9766" cy="64.4246" r="6.541" gradientTransform="matrix(1 0 0 -1 0 122.105)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#B8B8B8" />
                <stop offset="0.47" style="stop-color:#B6B6B6" />
                <stop offset="0.64" style="stop-color:#AFAFAF" />
                <stop offset="0.76" style="stop-color:#A3A3A3" />
                <stop offset="0.85" style="stop-color:#929292" />
                <stop offset="0.94" style="stop-color:#7D7D7D" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" d="M53.274,51.269c3.543,0.718,5.829,4.168,5.11,7.709c-0.717,3.544-4.167,5.83-7.71,5.113   c-3.536-0.719-5.824-4.171-5.105-7.711C46.284,52.844,49.739,50.552,53.274,51.269z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5989583333333334, 0.0, 0.21, 0.8802083333333334, 0.0, 0.54, 0.703125, 75.0, 0.64, 0.7604166666666666, 61.0, 0.79, 0.90625, 23.0, 0.97, 0.8489583333333334, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.18, 0.3958333333333333, 0.0, 0.26, 0.4322916666666667, 0.0, 0.35, 0.5364583333333334, 0.0, 0.44, 0.7135416666666666, 0.0, 0.54, 0.9583333333333334, 0.0, 0.64, 0.71875, 71.0, 0.72, 0.40625, 151.0, 0.81, 0.6197916666666666, 97.0, 0.99, 0.8333333333333334, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.5625, 111.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.5625, 111.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.5625, 111.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.5625, 111.0, 0.47, 0.578125, 107.0, 0.64, 0.6354166666666666, 93.0, 0.76, 0.7291666666666666, 69.0, 0.85, 0.859375, 35.0, 0.94, 0.9739583333333334, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_LegBase",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGChemical12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 80.855" enable-background="new 0 0 112.5 80.855" xml:space="preserve">
        <g id="Group_Column">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M105.969,80.293H15.428l0.563-2.929h89.302L105.969,80.293z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="104.4238" y1="78.7043" x2="16.8606" y2="21.8597">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#BABABA" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,77.365V23.198h89.302v54.166H15.991" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,25h89.302" />
        </g>
        <g id="Group_Layer">
            <path d="M102.928,13.626l2.365,4.729v4.843H19.595v-2.478h19.031v-4.729h21.509v4.729h4.73v-2.365h7.094v2.365h2.365v-2.365h11.938   v-4.729H102.928z" />
        </g>
        <g id="Group_Pipe2">
            <path fill="#666666" d="M79.166,18.356h19.032v4.843H79.166V18.356z" />
            <path fill="#666666" d="M31.532,18.356h11.824v4.843H31.532V18.356z" />
        </g>
        <g id="Group_Pipe1">
            <path fill="#E5E5E5" d="M105.631,13.176l-0.113,67.117h6.307V13.176c0,0-0.522-3.587-0.9-4.73c-0.4-1.21-1.688-3.491-2.59-4.392   c-0.902-0.901-3.2-2.14-4.393-2.59c-1.126-0.425-4.729-0.901-4.729-0.901H14.752c0,0-3.604,0.477-4.73,0.901   c-1.193,0.45-3.516,1.663-4.392,2.59S3.46,7.242,3.041,8.446c-0.398,1.142-1.014,4.73-1.014,4.73l1.014,4.729l5.968-2.365   l-0.563-2.364c0,0,0.229-1.805,0.451-2.365c0.232-0.588,1.351-2.14,1.351-2.14l2.027-1.352l2.478-0.45h84.459   c0,0,1.799,0.245,2.364,0.45c0.595,0.216,1.67,0.929,2.141,1.352c0.482,0.433,1.23,1.551,1.463,2.14   C105.4,11.371,105.631,13.176,105.631,13.176z" />
            <path fill="#B2B2B2" d="M105.631,13.176v67.117h2.14V13.176c0,0-0.017-4.083-2.39-6.54c-2.098-2.171-6.169-2.583-6.169-2.583   H14.752c0,0-2.669,0.436-3.491,0.788c-0.813,0.348-2.228,1.479-2.815,2.14c-0.587,0.66-1.55,2.194-1.802,3.041   c-0.226,0.76-0.389,2.362-0.338,3.153c0.052,0.799,0.563,3.153,0.563,3.153l2.14-0.789c0,0-0.53-1.758-0.563-2.364   c-0.032-0.601,0.229-1.805,0.451-2.365c0.232-0.588,0.896-1.701,1.351-2.14c0.438-0.423,1.456-1.14,2.027-1.352   c0.591-0.219,2.478-0.45,2.478-0.45h84.459c0,0,1.788,0.278,2.364,0.45c0.606,0.181,1.439,0.593,2.141,1.352   c0.44,0.476,1.214,1.542,1.463,2.14C105.411,11.366,105.631,13.176,105.631,13.176z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M105.631,13.176v67.117h6.193V13.176c0,0-0.522-3.587-0.9-4.73   c-0.4-1.21-1.688-3.491-2.59-4.392c-0.902-0.901-3.041-2.112-4.393-2.59c-1.135-0.401-4.729-0.901-4.729-0.901H14.752   c0,0-3.604,0.477-4.73,0.901c-1.193,0.45-3.516,1.663-4.392,2.59S3.46,7.242,3.041,8.446c-0.398,1.142-1.014,4.73-1.014,4.73   l1.014,4.729l5.968-2.365l-0.563-2.364c0,0,0.229-1.805,0.451-2.365c0.232-0.588,0.896-1.701,1.351-2.14   c0.438-0.423,1.456-1.14,2.027-1.352c0.591-0.219,2.478-0.45,2.478-0.45h84.459c0,0,1.805,0.229,2.364,0.45   c0.589,0.232,1.698,0.898,2.141,1.352c0.452,0.463,1.23,1.535,1.463,2.14C105.396,11.373,105.631,13.176,105.631,13.176z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M104.166,7.771l3.379-3.153" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M105.855,10.361l4.393-1.689" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,15.766h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,21.171h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,26.464h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,31.757h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,37.162h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,42.455h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,47.861h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,53.154h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,58.446h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,63.852h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,69.144h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M106.419,74.437h4.729" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M100.563,6.194l0.901-4.617" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M4.017,8.84l4.26,1.721" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M6.585,5.009L9.46,7.884" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M10.456,2.59l1.483,3.856" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M14.752,1.844v3.449" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M20.496,5.856V0.563" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M25.788,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M31.081,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M36.486,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M41.779,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M47.072,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M52.478,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M57.771,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M63.176,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M68.469,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M73.762,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M79.166,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M84.459,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M89.752,5.856V1.126" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M95.158,5.856V1.126" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M15.541,2.59h83.446" />
        </g>
        <g id="Group_Dividers">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M105.969,9.459H8.221l0.676-1.238h96.396L105.969,9.459z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M14.865,8.221H12.5V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M21.959,8.221h-2.365V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M29.167,8.221h-2.478V3.266h2.478V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M36.261,8.221h-2.365V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M43.468,8.221h-2.477V3.266h2.477V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M50.563,8.221h-2.365V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M57.771,8.221h-2.478V3.266h2.478V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M64.865,8.221H62.5V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M71.959,8.221h-2.364V3.266h2.364V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M79.166,8.221h-2.477V3.266h2.477V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M86.262,8.221h-2.365V3.266h2.365V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M93.469,8.221h-2.478V3.266h2.478V8.221z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M100.563,8.221h-2.364V3.266h2.364V8.221z" />
        </g>
        <g id="Group_Connector">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="9.6978" y1="47.2063" x2="20.0653" y2="43.3915">
                <stop offset="0" style="stop-color:#EDEDED" />
                <stop offset="0.73" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#EDEDED" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M15.991,76.69l-5.969-5.856L0.563,37.5l1.126-19.145l8.333-1.126l5.969,8.333V76.69z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="5.0171" y1="51.6897" x2="15.1674" y2="51.6897">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="7.658,31.531 0.563,37.5 10.022,70.833 15.991,76.69 15.991,26.69  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,76.69l-5.969-5.856L0.563,37.5l1.126-19.145l8.333-1.126   l5.969,8.333V76.69" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M0.563,37.5l7.095-5.969l8.333-4.842" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1.7285" y1="15.7756" x2="8.9691" y2="15.2198">
                <stop offset="0" style="stop-color:#EDEDED" />
                <stop offset="0.73" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#EDEDED" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#484848" stroke-width="0.25" d="M10.022,17.23l-1.577-4.054H2.027   c0,0-0.763,1.081-0.855,2.518c-0.078,1.225,0.517,2.662,0.517,2.662L10.022,17.23z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.14583333333333334, 217.0, 0.73, 0.0, 255.0, 1.0, 0.14583333333333334, 217.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.20833333333333334, 201.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.14583333333333334, 217.0, 0.73, 0.0, 255.0, 1.0, 0.14583333333333334, 217.0]);
		this.fillGradient(this.fills,"Group_Column",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Pipe2",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Pipe1",[0.0, 0.20833333333333334, 201.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Dividers",[0.0, 0.9895833333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0, 8.0, 0.40625, 151.0, 9.0, 0.40625, 151.0, 10.0, 0.40625, 151.0, 11.0, 0.40625, 151.0, 12.0, 0.40625, 151.0, 13.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Group_x5F_Pallet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 9.346" enable-background="new 0 0 112.5 9.346" xml:space="preserve">
        <g id="Group_Pallet">
        <path fill="#999999" d="M0.007,5.743h9.346v1.238h42.112V5.743h9.458v1.238h42.337V5.743h9.232v3.603H0.007V5.743z" />
        <path fill="#7F7F7F" d="M0.007,3.491h9.346L8.002,4.729l1.351,1.014H0.007l1.126-1.014L0.007,3.491z" />
        <path fill="none" d="M1.133,4.729h6.869" />
        <path fill="#7F7F7F" d="M51.465,3.491h9.458l-1.126,1.238l1.126,1.014h-9.458l1.126-1.014L51.465,3.491z" />
        <path fill="none" d="M52.591,4.729h7.206" />
        <path fill="#7F7F7F" d="M103.034,3.491h9.458l-1.125,1.238l1.125,1.014h-9.458l1.126-1.014L103.034,3.491z" />
        <path fill="none" d="M104.16,4.729h7.207" />
        <path fill="#999999" d="M112.492,3.491h-9.458V2.477H60.923v1.014h-9.458V2.477H9.241v1.014H0.007V0h112.485V3.491z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
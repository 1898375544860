import { SVGObject } from "../svgobject";

export class SVGHeating12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.539" enable-background="new 0 0 112.5 98.539" xml:space="preserve">
        <g id="Group_Inner_Coil">
            <path fill="#333333" d="M93.355,13.071V9.405H19.032v23.176h70.833v15.253H19.032v24.358h70.833v15.727H19.032v3.666h74.324V68.526   H22.522V51.5h70.833V28.915H22.522V13.071H93.355z" />
        </g>
        <g id="Group_Outer_Tube">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-256.7344" y1="41.335" x2="-251.29" y2="41.335" gradientTransform="matrix(-1 0 0 -1 -144.2344 50.7598)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M112.5,12.919h-5.444V5.93h5.444V12.919z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-175.3091" y1="-3.3271" x2="-170.1372" y2="-3.3271" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M5.855,18.581v-5.172h100.452v5.172H5.855z" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-162.0962" y1="-6.2861" x2="-156.729" y2="-6.2861" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0.041,5.368V0h106.164v5.367H0.041z" />
            
                <radialGradient id="SVGID_4_" cx="126.3545" cy="-57.4565" r="6.3422" gradientTransform="matrix(1.0179 0 0 0.9549 -22.1915 60.66)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M112.5,6.117c0-2.982-2.162-6.117-6.444-6.117l-0.013,5.185c0.751,0.109,0.933,0.403,0.983,0.916   C108.461,6.159,112.5,6.117,112.5,6.117z" />
            
                <radialGradient id="SVGID_5_" cx="-156.6953" cy="-57.4487" r="6.3381" gradientTransform="matrix(1.0179 0 0 -0.9549 265.9267 -42.1276)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M112.5,12.408c0,2.981-2.16,6.117-6.441,6.117l-0.014-5.185c0.752-0.108,0.934-0.403,0.983-0.916   C108.463,12.365,112.5,12.408,112.5,12.408z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-266.7773" y1="-24.2832" x2="-261.333" y2="-24.2832" gradientTransform="matrix(1 0 0 1 266.7773 46.7402)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M0,18.962h5.444v6.989H0V18.962z" />
            
                <radialGradient id="SVGID_7_" cx="195.4375" cy="-67.3218" r="6.3378" gradientTransform="matrix(-1.0179 0 0 -0.9549 205.0123 -38.1983)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M0,25.764c0,2.982,2.162,6.118,6.444,6.118l0.013-5.184c-0.751-0.109-0.933-0.404-0.983-0.916   C4.039,25.723,0,25.764,0,25.764z" />
            
                <radialGradient id="SVGID_8_" cx="-225.7778" cy="-67.3149" r="6.3381" gradientTransform="matrix(-1.0179 0 0 0.9549 -223.7456 83.4308)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_8_)" d="M0,19.474c0-2.98,2.16-6.117,6.441-6.117l0.014,5.186c-0.752,0.107-0.934,0.402-0.983,0.915   C4.037,19.517,0,19.474,0,19.474z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-256.7344" y1="14.8931" x2="-251.29" y2="14.8931" gradientTransform="matrix(-1 0 0 -1 -144.2344 50.7598)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M112.5,39.361h-5.444v-6.989h5.444V39.361z" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-201.751" y1="-3.3271" x2="-196.5791" y2="-3.3271" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M5.855,45.022v-5.172h100.452v5.172H5.855z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-188.5381" y1="-3.8276" x2="-183.1709" y2="-3.8276" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M4.957,31.81v-5.367h101.247v5.367H4.957z" />
            
                <radialGradient id="SVGID_12_" cx="153.7842" cy="-57.4565" r="6.3422" gradientTransform="matrix(1.0179 0 0 0.9549 -50.1122 87.1012)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" d="M112.5,32.559c0-2.982-2.162-6.117-6.444-6.117l-0.013,5.184c0.751,0.109,0.933,0.404,0.983,0.916   C108.461,32.601,112.5,32.559,112.5,32.559z" />
            
                <radialGradient id="SVGID_13_" cx="-184.1255" cy="-57.4487" r="6.3381" gradientTransform="matrix(1.0179 0 0 -0.9549 293.8479 -15.6859)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" d="M112.5,38.85c0,2.981-2.16,6.117-6.441,6.117l-0.014-5.186c0.752-0.107,0.934-0.403,0.983-0.915   C108.463,38.807,112.5,38.85,112.5,38.85z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-266.7773" y1="2.1582" x2="-261.333" y2="2.1582" gradientTransform="matrix(1 0 0 1 266.7773 46.7402)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M0,45.404h5.444v6.989H0V45.404z" />
            
                <radialGradient id="SVGID_15_" cx="168.0088" cy="-67.3218" r="6.3378" gradientTransform="matrix(-1.0179 0 0 -0.9549 177.0926 -11.758)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" d="M0,52.206c0,2.981,2.162,6.117,6.444,6.117l0.013-5.185c-0.751-0.109-0.933-0.404-0.983-0.916   C4.039,52.163,0,52.206,0,52.206z" />
            
                <radialGradient id="SVGID_16_" cx="-198.3477" cy="-67.3149" r="6.3381" gradientTransform="matrix(-1.0179 0 0 0.9549 -195.8245 109.8725)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" d="M0,45.916c0-2.981,2.16-6.117,6.441-6.117l0.014,5.186c-0.752,0.107-0.934,0.402-0.983,0.915   C4.037,45.959,0,45.916,0,45.916z" />
            
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-256.7344" y1="-11.5483" x2="-251.291" y2="-11.5483" gradientTransform="matrix(-1 0 0 -1 -144.2344 50.7598)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M112.5,65.803h-5.443v-6.989h5.443V65.803z" />
            
                <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-228.1934" y1="-3.3271" x2="-223.0195" y2="-3.3271" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M5.855,71.465v-5.174h100.452v5.174H5.855z" />
            
                <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="-214.9795" y1="-3.9102" x2="-209.6123" y2="-3.9102" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M4.791,58.251v-5.367h101.415v5.367H4.791z" />
            
                <radialGradient id="SVGID_20_" cx="181.2148" cy="-57.4565" r="6.334" gradientTransform="matrix(1.0179 0 0 0.9549 -78.0338 113.5434)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_20_)" d="M112.5,59c0-2.981-2.162-6.116-6.443-6.116l-0.014,5.185c0.752,0.108,0.934,0.402,0.984,0.914   C108.461,59.042,112.5,59,112.5,59z" />
            
                <radialGradient id="SVGID_21_" cx="-211.5557" cy="-57.4487" r="6.3422" gradientTransform="matrix(1.0179 0 0 -0.9549 321.7691 10.7559)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M112.5,65.291c0,2.98-2.16,6.118-6.441,6.118l-0.014-5.187c0.752-0.107,0.934-0.402,0.984-0.915   C108.463,65.248,112.5,65.291,112.5,65.291z" />
            
                <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="-266.7773" y1="28.5991" x2="-261.333" y2="28.5991" gradientTransform="matrix(1 0 0 1 266.7773 46.7402)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M0,71.845h5.444v6.989H0V71.845z" />
            
                <radialGradient id="SVGID_23_" cx="140.5791" cy="-67.3218" r="6.3378" gradientTransform="matrix(-1.0179 0 0 -0.9549 149.1719 14.6833)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_23_)" d="M0,78.647c0,2.981,2.162,6.117,6.444,6.117l0.013-5.185c-0.751-0.109-0.933-0.404-0.983-0.916   C4.039,78.604,0,78.647,0,78.647z" />
            
                <radialGradient id="SVGID_24_" cx="-170.9185" cy="-67.3149" r="6.3381" gradientTransform="matrix(-1.0179 0 0 0.9549 -167.9043 136.3133)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M0,72.357c0-2.982,2.16-6.118,6.441-6.118l0.014,5.187c-0.752,0.107-0.934,0.402-0.983,0.914   C4.037,72.399,0,72.357,0,72.357z" />
            
                <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="-241.4219" y1="-3.9941" x2="-236.0537" y2="-3.9941" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M4.624,84.693v-5.368h101.58v5.368H4.624z" />
            
                <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="-256.7344" y1="-38.1475" x2="-251.29" y2="-38.1475" gradientTransform="matrix(-1 0 0 -1 -144.2344 50.7598)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M112.5,92.401h-5.444v-6.988h5.444V92.401z" />
            
                <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="-254.792" y1="-6.2856" x2="-249.6191" y2="-6.2856" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M-0.062,98.063v-5.173h106.368v5.173H-0.062z" />
            
                <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="-241.5781" y1="-3.7441" x2="-236.2109" y2="-3.7441" gradientTransform="matrix(1.346898e-010 -1 1 1.346898e-010 59.4082 -156.7285)">
                <stop offset="0.01" style="stop-color:#00FFFF" />
                <stop offset="0.5" style="stop-color:#AAFFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" d="M5.124,84.85v-5.367h101.08v5.367H5.124z" />
            
                <radialGradient id="SVGID_29_" cx="208.8076" cy="-57.4565" r="6.3422" gradientTransform="matrix(1.0179 0 0 0.9549 -106.1205 140.1419)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_29_)" d="M112.5,85.6c0-2.981-2.162-6.117-6.444-6.117l-0.013,5.185c0.751,0.108,0.933,0.402,0.983,0.915   C108.461,85.642,112.5,85.6,112.5,85.6z" />
            
                <radialGradient id="SVGID_30_" cx="-239.1489" cy="-57.4487" r="6.3381" gradientTransform="matrix(1.0179 0 0 -0.9549 349.8563 37.3549)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#00FFFF" />
                <stop offset="0.06" style="stop-color:#0DFFFF" />
                <stop offset="0.18" style="stop-color:#2FFFFF" />
                <stop offset="0.35" style="stop-color:#65FFFF" />
                <stop offset="0.52" style="stop-color:#AAFFFF" />
                <stop offset="0.62" style="stop-color:#8DFFFF" />
                <stop offset="0.83" style="stop-color:#42FFFF" />
                <stop offset="1" style="stop-color:#00FFFF" />
            </radialGradient>
            <path fill="url(#SVGID_30_)" d="M112.5,91.891c0,2.979-2.16,6.117-6.441,6.117l-0.014-5.186c0.752-0.108,0.934-0.403,0.983-0.915   C108.463,91.847,112.5,91.891,112.5,91.891z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.01, 0.671875, 83.0, 0.5, 0.234375, 195.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.671875, 83.0, 0.06, 0.640625, 91.0, 0.18, 0.5572916666666666, 113.0, 0.35, 0.4114583333333333, 149.0, 0.52, 0.234375, 195.0, 0.62, 0.3020833333333333, 177.0, 0.83, 0.5, 127.0, 1.0, 0.671875, 83.0]);

        super.drawObject();
    }
}
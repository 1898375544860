import { SVGObject } from "../svgobject";

export class SVGFlex9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 58.781 112.5" enable-background="new 0 0 58.781 112.5" xml:space="preserve">
        <g id="Group_Pipe">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="29.5032" y1="108.1055" x2="29.5032" y2="4.2822">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M49.885,4.282v103.824H9.121V4.282H49.885z" />
        </g>
        <g id="Group_Fins">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-2.441406e-004" y1="56.25" x2="9.1208" y2="56.25">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M9.121,0.003v112.494H0V0.003H9.121z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="49.5466" y1="56.25" x2="58.781" y2="56.25">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M58.781,0.003v112.494h-9.234V0.003H58.781z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater46 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 104.508" enable-background="new 0 0 112.5 104.508" xml:space="preserve">
        <g id="Group_BackLegs">
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M8.671,94.033H112.5v2.14H8.671V94.033z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M8.671,65.992H112.5v2.365H8.671V65.992z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,36.713l1.577-1.013l-7.208,64.302l-1.802,2.027L8.896,36.713z   " />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M109.685,32.885h2.815v63.288h-2.815V32.885z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M100.676,43.02h2.815l9.009-7.657V33.11L100.676,43.02z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M100.676,103.83h2.815l9.009-7.657h-2.815L100.676,103.83z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M69.82,103.83h2.814l8.896-7.657h-2.703L69.82,103.83z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M31.644,103.83h2.703l9.009-7.657h-2.815L31.644,103.83z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M0,103.83h2.703l9.009-7.657H8.896L0,103.83z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M78.828,32.885h2.703v63.288h-2.703V32.885z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,32.885h2.478v63.288H8.896V32.885z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M110.924,36.713L112.5,35.7l-7.207,64.302l-2.027,2.027   L110.924,36.713z" />
        </g>
        <g id="Group_Wheels">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M72.185,94.033H51.802L34.347,83.109L24.212,63.966V42.006L34.347,23.2   l17.455-11.036h20.157L89.527,23.2l10.135,18.806l-0.225,21.959l-9.91,19.144L72.185,94.033z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M68.581,96.398H48.423L30.856,85.475L20.833,66.556V44.596   l10.022-19.144l17.567-10.923h20.158l17.343,10.923l10.248,19.144v21.959L85.924,85.475L68.581,96.398z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.212,50.903l21.96,15.653" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M70.158,43.02l26.014,1.577" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.279,38.515l14.302-23.761" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M70.158,68.133l-1.577,28.266" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.212,60.137l11.937,25.338" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M65.766,100.002H45.608L28.041,88.966L18.018,70.159V48.2   l10.022-19.145L45.608,18.02h20.158l17.342,11.036L93.355,48.2v21.959L83.334,88.966L65.766,100.002z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M71.396,54.506l21.959,15.653" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.342,46.624L93.355,48.2" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.342,71.623l-1.576,28.604" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M71.396,63.74l11.938,25.226" />
            <polygon fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.1" points="62.5,104.056 42.342,104.056 24.662,93.133 14.752,73.875    14.752,52.029 24.662,33.11 42.342,22.187 62.5,22.187 79.842,33.11 89.978,52.029 89.978,73.875 79.842,93.133  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.532,80.52h-8.559l-7.433-4.842l-4.392-7.883v-9.46l4.392-8.108   l7.433-4.617h8.559l7.432,4.617l4.392,8.108v9.46l-4.392,7.883L56.532,80.52" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.712,67.795L14.752,52.254" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.131,58.673l21.847,15.202" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,75.678l-26.013-1.465" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.964,50.677l26.014,1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.198,45.835L62.5,22.187" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.532,80.295l-14.189,23.761" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,50.452l1.577-28.266" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.964,75.678L62.5,104.056" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.198,80.295L25,93.133" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.532,45.835l23.31-12.725" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.712,58.673l-12.05-25.338" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.131,67.795l11.937,25.338" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.712,68.357l-22.523-14.64" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.131,57.885l22.41,14.752" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M41.329,76.24l-26.126-0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.514,50.227l26.014,0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M47.41,46.173l13.289-24.324" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.095,80.069l-13.289,24.437" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.203,50.903l0.338-28.266" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.527,75.227l-0.563,28.266" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.648,80.52L26.014,94.033" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.856,45.835l22.635-13.739" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.374,59.123L23.648,34.349" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.355,67.006l12.726,25.113" />
        </g>
        <g id="Group_TopCover">
            <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,40.768h103.491v2.027H0V40.768z" />
            <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,40.768h103.491L87.725,20.385L51.802,7.659L16.216,20.385   L0,40.768z" />
            <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M60.36,0.002L25,12.389l-8.784,7.996L51.802,7.659L60.36,0.002z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M60.36,0.002l36.261,12.725l-8.67,7.658L51.802,7.659L60.36,0.002z" />
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M96.621,12.727L112.5,33.11l-8.672,7.658L87.951,20.61L96.621,12.727   z" />
        </g>
        <g id="Group_FrontLegs">
            <g>
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,40.768h2.703v63.063H0V40.768z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M100.676,40.768h2.815v63.063h-2.815V40.768z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M70.158,40.768h2.477v63.063h-2.477V40.768z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M31.869,40.768h2.815v63.063h-2.815V40.768z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M34.347,45.159h35.812v2.252H34.347V45.159z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M34.347,80.52h35.812v2.14H34.347V80.52z" />
                <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,102.029h103.491v2.026H0V102.029z" />
            </g>
        </g>
        <g id="Group_Group1">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M69.595,57.885H38.964v8.896l4.054,7.657l7.207,4.617l1.014,3.604l14.302-8.221   l4.054-7.657V57.885z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M62.5,61.488H31.869v8.896l4.054,7.658l7.095,4.616h8.221   l7.095-4.167l4.166-8.107V61.488z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M58.334,78.492l4.166-8.107v-8.896l7.095-3.604v8.896l-4.054,7.657   L58.334,78.492z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M38.739,57.885l-7.095,3.604h7.095V57.885z" />
        </g>
        <g id="Group_Box">
            <path fill="#7F7F00" stroke="#4C4C4C" stroke-width="0.25" d="M32.32,26.015h41.892v11.712H32.32V26.015z" />
            <path fill="#BFBF00" stroke="#4C4C4C" stroke-width="0.25" d="M31.869,26.466h41.779v12.049H31.869V26.466z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.545,37.502h0.338v0.225v0.225h-0.338H32.32l-0.225-0.225   l0.225-0.225H32.545" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.545,37.164l0.338,0.338v0.225v0.225h-0.338H32.32v-0.225v-0.225   L32.545,37.164" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.545,27.029l0.338,0.225v0.226l-0.338,0.337l-0.225-0.337h-0.225   l0.225-0.226L32.545,27.029" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.545,27.029l0.338,0.225v0.226l-0.338,0.337l-0.225-0.337v-0.226   L32.545,27.029" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,37.502h0.226v0.225v0.225h-0.226H72.41l-0.226-0.225   l0.226-0.225H72.635" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,37.164l0.226,0.338v0.225v0.225h-0.226H72.41v-0.225v-0.225   L72.635,37.164" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,27.029l0.226,0.225v0.226l-0.226,0.337l-0.225-0.337h-0.226   l0.226-0.226L72.635,27.029" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,27.029l0.226,0.225v0.226l-0.226,0.337l-0.225-0.337v-0.226   L72.635,27.029" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.fills,"Group_BackLegs",[0.0, 0.6510416666666666, 0.0, 1.0, 0.6510416666666666, 0.0, 2.0, 0.6510416666666666, 0.0, 3.0, 0.6510416666666666, 0.0, 4.0, 0.6510416666666666, 0.0, 5.0, 0.6510416666666666, 0.0, 6.0, 0.6510416666666666, 0.0, 7.0, 0.6510416666666666, 0.0, 8.0, 0.6510416666666666, 0.0, 9.0, 0.6510416666666666, 0.0, 10.0, 0.6510416666666666, 0.0, 11.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Wheels",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.125, 223.0]);
		this.fillGradient(this.fills,"Group_TopCover",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.6510416666666666, 0.0, 4.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Group1",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0, 2.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Box",[0.0, 0.6510416666666666, 0.0, 1.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMedical7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.924 112.5" xml:space="preserve" enable-background="new 0 0 56.924 112.5">
        <g id="Group_Bed">
            <path d="M45.426,1.694v7.214H13.752V1.694H45.426z" fill="#E5E0D6" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M50.273,9.584v76.649H9.581V9.584H50.273z" fill="#333333" />
            <path d="M52.639,27.507v31.11H6.538v-31.11H52.639z" fill="#7F7F7F" />
            <path d="M29.871,88.037l18.599-0.677l0.676-1.804l-0.676-28.743   l0.676-27.503L48.469,8.344l-0.563-3.043l-1.804-1.127H35.619L25.7,3.498L13.752,4.174l-1.804,1.127l-0.563,3.043L10.709,29.31   l0.676,27.503l-0.676,28.743l0.676,1.804L29.871,88.037z" fill="#B2CCCC" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M3.494,1.694h52.753V0.003H3.494V1.694z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M26.94,70.678l1.239-10.82l-0.676-6.538L26.94,64.704V70.678z" fill="#98B2B2" />
            <path d="M32.914,32.354l-1.127,17.81l0.563,10.821l0.563-18.824V32.354z" fill="#98B2B2" />
        </g>
        <g id="Group_Pillow">
            <path d="M46.666,23.449l-0.563,2.818l-31.224,0.113l-0.676-0.451   l-1.24-4.171l0.226-5.636l-0.677-6.538l1.24-4.17l2.367-0.676l30.772,0.338l1.127,3.607l-0.676,4.734v5.749L46.666,23.449z" fill="#F7F7F7" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M43.735,23.899l1.127-10.708l-0.563-6.65l-0.563,11.384V23.899z" fill="#E5E5E5" />
            <path d="M22.769,23.899l3.607-2.931l1.803,1.127l2.931-1.127l1.24,2.367v-3.043l4.171-2.367l3.043,0.564   l-3.043-1.804l-1.24-2.367l1.804-3.607l-4.171,2.48l-3.606-1.24l-2.931,1.804l-1.24-1.24v1.24h-3.607l4.848,2.367l-3.607,1.24   l2.367,1.127l-1.24,1.24L22.769,23.899z" fill="#EDEDED" />
        </g>
        <g id="Group_Left">
            <path d="M56.247,60.42l0.677-8.904V29.874l-0.677-4.734h-4.734   l-0.676,4.734v21.642l0.676,8.904H56.247z" fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M54.443,67.071l1.24-1.24l0.563-27.503l-0.563-19.162l-1.24-1.24   h-1.127l-1.24,1.24l-0.563,19.162l0.563,27.503l1.24,1.24H54.443z" fill="#98B27F" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Right">
            <path d="M5.974,35.848v24.01H0v-24.01H5.974z" fill="#E5E0D6" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M8.342,60.42l0.563-8.904V29.874L8.342,25.14H3.494L2.93,29.874   v21.642l0.563,8.904H8.342z" fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M6.538,67.071l1.24-1.24l0.563-27.503L7.778,19.166l-1.24-1.24   h-1.24l-1.127,1.24L3.494,38.328l0.677,27.503l1.127,1.24H6.538z" fill="#98B27F" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_LegRest">
            <path d="M47.906,85.105v7.102H11.385v-7.102H47.906z" fill="#7F7F7F" />
            <path d="M29.871,112.497l18.599-0.563l0.676-1.804V92.771l-1.239-2.932   l-1.804-1.239H29.871H13.752l-1.804,1.239l-1.239,2.932v17.358l0.676,1.804L29.871,112.497z" fill="#B2CCCC" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPeople5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 34.574 112.5" enable-background="new 0 0 34.574 112.5" xml:space="preserve">
        <g id="Group_CollarBack">
            <path fill="#CCCC99" stroke="#4C4C4C" stroke-width="0.25" d="M22.411,18.018l-0.676-2.928l-7.77,0.112l-0.563,2.815H22.411z" />
        </g>
        <g id="Group_RightArm">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M5.181,21.059l-2.928,3.491L1.69,28.604L1.578,37.5l-0.563,5.856   l0.563,10.473l0.676,4.73l0.676,2.252l2.252,2.59l1.014-0.225L4.843,60.36l-0.45-2.591l0.676-0.787l0.676,3.716l0.675-0.676   l0.226-2.928l-1.464-3.829l1.126-12.275l0.563-5.292l0.676-7.658" />
        </g>
        <g id="Group_Neck">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M20.947,17.455l-1.127-2.252h-4.053l-1.239,2.928" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,27.478l0.564,8.784l-0.564,7.658l1.689,9.91l-0.9,2.365   l0.338,3.603l0.676,0.563l0.225-2.591l0.338-0.111l-0.788,6.418l0.788,0.901l2.252-4.955l0.451-3.266l-0.338-2.365l1.126-11.149   v-6.419l-0.676-8.446l-1.688-6.757l-2.928-1.689" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M18.695,63.176v10.022l1.125,11.148l-0.563,8.784l2.365,12.274   l-1.238,4.729l0.563,1.127l2.928,1.238h3.604l1.127-1.238l-2.929-5.293l1.126-14.641V86.6l0.676-21.622l-0.676-9.91" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M8.672,57.32L7.546,67.905l0.563,15.767L7.546,88.4l0.563,17.568   l-1.239,2.928l-1.689,1.802l0.563,1.126h4.729l2.928-2.252l-0.563-3.604l-0.676-2.928l2.928-19.934l1.802-19.932" />
        </g>
        <g id="Group_Shoes">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M28.83,111.824v-1.126l-1.689-1.802l-1.238-2.928h-3.491l-1.689-1.689l-0.112,3.49   l-0.563,1.803v1.126l0.563,1.126l2.365,0.676h5.293L28.83,111.824z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M4.28,111.824v-1.126l1.689-1.802l1.239-2.928h3.491l1.689-1.689l0.676,4.055   l0.563,1.238l-1.802,1.126l-1.126,1.126L8.334,112.5H4.843L4.28,111.824z" />
        </g>
        <g id="Group_Belt">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M7.771,50.901v-2.365h19.369v2.365H7.771z" />
        </g>
        <g id="Group_Face">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M22.299,11.148h-9.46l-1.126-1.013l-0.676-1.126V7.883l0.676-1.126   h11.824l0.676,1.126v1.126l-0.676,1.126L22.299,11.148z" />
            <ellipse fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" cx="17.569" cy="8.446" rx="5.63" ry="7.32" />
            <path fill="#E5B27F" d="M17.456,11.036l1.577,0.45h-3.154L17.456,11.036z" />
            <path fill="#E5B2B2" d="M15.879,13.063l-0.45-0.45h4.167l-0.563,0.45H15.879z" />
            <path fill="#663300" d="M20.496,3.378l0.451-2.252h0.225v1.802l-0.225-0.45L20.496,3.378z" />
            <path fill="#665940" d="M20.947,6.644l-2.815-0.45l0.226,0.45l2.814,0.226L20.947,6.644z" />
            <path fill="#665940" d="M13.965,6.644l2.815-0.45l-0.225,0.45L13.853,6.87L13.965,6.644z" />
            <path fill="#665940" d="M15.204,8.896h0.45l0.225-0.676l-0.225-0.676h-0.45l-0.226,0.676L15.204,8.896z" />
            <path fill="#665940" d="M19.371,8.896h0.449l0.227-0.676L19.82,7.545h-0.449l-0.226,0.676L19.371,8.896z" />
        </g>
        <g id="Group_Hair">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M12.163,7.996l-0.45-1.352l-0.676,1.352l0.676-5.068l1.577-1.802   l1.915-1.013L17.456,0l2.364,0.113l1.803,1.013l1.576,1.802l1.014,5.068l-0.788-1.352l-0.45,1.352l-0.901-3.716l-0.901-1.352   l-0.225-0.45l-0.451,0.901l-5.292,0.676h-2.14l-0.226,0.225L12.163,7.996z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#65657F" stroke="#4C4C4C" stroke-width="0.25" d="M13.064,107.207l-1.802-1.238H6.533L5.97,90.203l0.563-7.096   L5.97,67.905l1.126-12.838l0.676-4.729l6.419-0.563l7.095,0.563h5.743l0.676,11.148l0.563,8.221l-1.239,14.64l0.676,7.546   l-1.239,14.752l-0.563-0.676h-4.617l-0.676,0.676L18.92,93.131l-0.563-9.459l-1.239-13.965l-1.126,13.4l-2.365,14.641   L13.064,107.207z" />
            <path fill="#4C4C66" d="M17.118,69.145l-1.126,12.274l-2.365,15.766l-0.563,7.095l-0.676-5.293V88.964l3.041-12.274l0.563-5.855   v-4.055v-2.365l0.563-10.585v10.023l0.563,3.49l2.364-4.166l-1.125,5.293l1.125,5.855l0.564,14.64l0.563,10.585l1.802,4.73   l-1.802,0.563l-1.127-7.657l-1.125-15.202L17.118,69.145z" />
        </g>
        <g id="Group_Shirt">
            <path fill="#FFFFCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.997,48.536l0.113-2.59l-0.338-3.378l-0.113-3.604L7.434,31.87   l-0.225,4.167L6.308,42.68L5.407,53.378l-1.689,0.338L1.465,55.18L0.452,43.356l0.45-8.559l1.239-7.207l0.901-5.969l4.73-2.14   l6.194-1.689l3.941,1.577l0.113-1.239l3.716-0.788l9.234,2.703l1.577,2.365l0.787,3.942l0.338,8.558l0.226,14.752l-0.901,4.617   l-1.576-0.789l-2.14,0.226l-1.239-7.32v-6.982V37.5l-0.111-4.73l-0.226,3.604l-0.226,4.504l-0.45,4.729l-0.226,3.153L7.997,48.536z   " />
            <path fill="#FFFFCC" stroke="#4C4C4C" stroke-width="0.25" d="M18.244,20.383l-0.112-2.815l3.604-2.477l0.563,2.815L18.244,20.383z   " />
            <path fill="#FFFFCC" stroke="#4C4C4C" stroke-width="0.25" d="M17.906,20.496v-3.041l-3.941-2.252l-0.563,2.815L17.906,20.496z" />
        </g>
        <g id="Group_Blazer">
            <path fill="#E5E5B2" d="M28.492,41.779l0.676-1.689l2.252-0.338l-2.14-1.577l-0.563-6.757l0.563-1.352l-1.013-1.689l-1.352,2.365   l0.45,5.856l0.563-4.054l0.225,1.577v2.252v9.797L28.492,41.779z" />
            <path fill="#E5E5B2" d="M5.745,47.86l-0.789-6.194l-1.577-2.252l2.365-0.563l0.225-2.14l0.338-4.392l0.563-2.365l1.689,1.351   l-0.225,5.068l-0.788-4.73l-0.563,2.252l0.113,2.478L5.857,42.68L5.745,47.86z" />
            <path d="M25.677,51.352l1.126,6.982l-0.563-6.87L25.677,51.352z" />
            <path d="M8.672,51.352l-0.901,6.982l0.563-6.87L8.672,51.352z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M15.992,48.198l2.815,0.113v2.59l-2.928-0.113L15.992,48.198z" />
            <path d="M16.33,48.536l2.027,0.112v1.802h-2.14L16.33,48.536z" />
            <path fill="#990000" stroke="#4C4C4C" stroke-width="0.25" d="M18.02,17.905l-1.126,1.126l0.563,1.802l-1.014,26.013l1.802,1.464   l2.141-1.577l-1.803-25.789l0.451-2.027L18.02,17.905z" />
            <path fill="#191933" stroke="#4C4C4C" stroke-width="0.25" d="M22.299,17.23l9.684,2.928l1.127,1.802l1.464,19.82l-0.45,12.387   l-3.041-1.239l-2.14,0.338l-1.126-6.644l-0.225-9.459v9.347l1.014,18.581l-11.262,2.027l1.915-31.982L22.299,17.23z" />
            <path fill="#191933" stroke="#4C4C4C" stroke-width="0.25" d="M13.515,17.342L3.379,20.158l-1.126,1.689L0.001,42.117l0.451,12.95   l2.928-1.802l2.365-0.563l0.675-9.459l1.126-6.982L6.983,46.847L4.956,65.315l11.599,1.577l0.901-31.757L13.515,17.342z" />
            <path fill="#32324C" stroke="#4C4C4C" stroke-width="0.25" d="M19.258,35.135l3.041-17.793l3.378,6.081L21.06,35.135l1.126,3.491   l-2.703,6.982L19.258,35.135z" />
            <path fill="#32324C" stroke="#4C4C4C" stroke-width="0.25" d="M17.456,35.022L13.515,17.23l-2.928,6.193l5.067,11.599l-1.126,3.491   l2.59,7.094L17.456,35.022z" />
            <path fill="#32324C" d="M20.271,51.689l6.531,0.113l0.113,1.689l-6.645,0.563V51.689z" />
            <path fill="#32324C" d="M14.866,51.464l-7.208-0.338l-0.112,2.14l7.207,0.563L14.866,51.464z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
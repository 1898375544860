import { SVGObject } from "../svgobject";

export class SVGBoiler11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 48.313" enable-background="new 0 0 112.5 48.313" xml:space="preserve">
        <g id="Group_Pipe">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="11.1675" y1="17.0046" x2="11.1675" y2="13.6267">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,13.627h22.335v3.378H0V13.627z" />
        </g>
        <g id="Group_Antenna">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="30.0669" y1="22.1853" x2="30.0669" y2="7.9958">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M21.959,7.996h16.215v14.189H21.959V7.996z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="38.626" y1="26.0144" x2="38.626" y2="3.9426">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M37.049,7.996l3.153-4.054v22.072l-3.153-3.829V7.996z" />
            <path fill="#CC9933" d="M40.203,6.532H53.25v11.6H40.203V6.532z" />
            <path fill="#FFCC66" d="M40.203,3.942H53.25v2.59H40.203V3.942z" />
            <path fill="#996600" d="M40.203,18.132H53.25v7.883H40.203V18.132z" />
            <path fill="none" d="M21.959,7.996h15.09l3.153-4.054v22.072l-3.153-3.829h-15.09V7.996" />
            <rect x="26.331" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="27.893" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="29.487" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="30.795" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="32.42" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="33.979" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="23.393" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
            <rect x="24.737" y="7.996" fill="#4D4D4D" width="0.286" height="14.189" />
        </g>
        <g id="Group_ControlBody">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="82.3203" y1="-2.1707" x2="82.3203" y2="14.9221" gradientTransform="matrix(-1 0 0 -1 164.6406 14.8667)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M112.5,17.037H52.14V-0.055h60.36V17.037z" />
            <path fill="none" d="M52.14,0.001h60.36v48.311H52.14V0.001" />
            <path fill="#5C5C5C" d="M52.14,16.937h60.36v31.374H52.14V16.937z" />
        </g>
        <g id="Group_ControlScrew">
            <path fill="#191919" d="M82.32,48.311V42.23l0.9-1.126h5.18l0.901,1.126v6.081H82.32z" />
            <circle fill="#CCCCCC" cx="85.811" cy="44.821" r="3.041" />
            <path fill="#7F7F7F" d="M83.402,46.689l4.309-4.31l0.48,0.479l-4.312,4.31L83.402,46.689z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Antenna",[0.0, 0.9375, 15.0, 1.0, 0.5364583333333334, 117.0, 2.0, 0.6614583333333334, 0.0, 3.0, 0.5989583333333334, 0.0, 4.0, 0.5989583333333334, 0.0, 5.0, 0.5989583333333334, 0.0, 6.0, 0.5989583333333334, 0.0, 7.0, 0.5989583333333334, 0.0, 8.0, 0.5989583333333334, 0.0, 9.0, 0.5989583333333334, 0.0, 10.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_ControlBody",[0.0, 0.7135416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_ControlScrew",[0.0, 0.19270833333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
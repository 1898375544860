import { SVGObject } from "../svgobject";

export class SVGFood22 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 91.778 112.5" enable-background="new 0 0 91.778 112.5" xml:space="preserve">
        <g id="Group_Holder">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,1.352h88.85V112.5H0V1.352z" />
            <path fill="#B2B2B2" d="M2.703,4.055h83.445v105.629H2.703V4.055z" />
        </g>
        <g id="Group_Mesh">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.928,106.869l10.36,2.814h10.248l-20.608-5.518v-2.703l30.968,8.221   h10.585L2.928,98.535v-2.814l51.915,13.963h10.359L2.928,93.131v-2.929l71.959,19.481h11.261L2.928,87.5v-2.703l83.22,22.185   v-2.815L2.928,81.869v-2.703l83.22,22.297v-2.702L2.928,76.238v-2.702l83.22,22.185v-2.59L2.928,70.833v-2.702l83.22,22.297V87.5   L2.928,65.203V62.5l83.22,22.297v-2.703L2.703,59.572v-2.703l83.22,22.297v-2.702L2.703,54.167v-2.928l83.22,22.523v-2.929   L2.703,48.536v-2.703l83.22,22.297v-2.928L2.703,42.905v-2.702L85.922,62.5v-2.703L2.703,37.5v-2.928l83.22,22.297v-2.703   L2.703,31.87v-2.703l83.22,22.297v-2.928L2.703,26.239v-2.703l83.22,22.297v-2.703L2.703,20.608v-2.703l83.22,22.297V37.5   L2.703,15.203V12.5l83.22,22.297V31.87L2.703,9.572V6.87l83.22,22.297v-2.703L2.703,4.055H12.5l73.422,19.481v-2.703L23.536,4.055   h9.685l52.702,14.076v-2.928L44.481,4.055h9.685L85.922,12.5V9.572L64.526,4.055h10.361L85.922,6.87V4.28" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M85.922,106.869l-10.135,2.814H65.202l20.721-5.518v-2.703l-31.08,8.221   H44.481l41.441-11.148v-2.814l-51.801,13.963H23.536l62.387-16.553v-2.929l-72.071,19.481H2.928L85.922,87.5v-2.703L2.928,106.981   v-2.815l82.995-22.297v-2.703L2.928,101.463v-2.702l82.995-22.522v-2.702L2.928,95.721v-2.59l82.995-22.298v-2.702L2.928,90.428   V87.5l82.995-22.297V62.5L2.928,84.797v-2.703l83.22-22.521v-2.703L2.928,79.166v-2.702l83.22-22.297v-2.928L2.928,73.762v-2.929   l83.22-22.297v-2.703L2.928,68.131v-2.928l83.22-22.298v-2.702L2.928,62.5v-2.703L86.148,37.5v-2.928L2.928,56.869v-2.703   l83.22-22.297v-2.703L2.928,51.464v-2.928l83.22-22.297v-2.703L2.928,45.833v-2.703l83.22-22.522v-2.703L2.928,40.203V37.5   l83.22-22.297V12.5L2.928,34.797V31.87l83.22-22.297V6.87L2.928,29.167v-2.703l83.22-22.41h-9.685L2.928,23.536v-2.703   L65.202,4.055h-9.684L2.928,18.131v-2.928L44.481,4.055h-9.685L2.928,12.5V9.572l21.509-5.518H13.851L2.928,6.87V4.28" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M83.219,4.055v105.629h-2.702V4.055h-2.702v105.629h-2.928V4.055h-2.703   v105.629h-2.703V4.055h-2.928v105.629H63.85V4.055h-2.702v105.629H58.22V4.055h-2.702v105.629h-2.703V4.055h-2.928v105.629h-2.703   V4.055h-2.703v105.629h-2.928V4.055h-2.703v105.629h-2.703V4.055H33.22v105.629h-2.703V4.055H27.59v105.629h-2.703V4.055h-2.703   v105.629h-2.703V4.055h-2.815v105.629h-2.815V4.055h-2.928v105.629h-2.59V4.055H5.63v105.629" />
        </g>
        <g id="Group_Air_Filter">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M88.85,112.5l2.928-1.465V0L88.85,1.352V112.5z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M0,1.352L2.703,0h89.075L88.85,1.352H0z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Holder",[0.0, 0.20833333333333334, 201.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Air_Filter",[0.0, 0.40625, 151.0, 1.0, 0.11458333333333333, 226.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFlex3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 16.33" enable-background="new 0 0 112.5 16.33" xml:space="preserve">
        <g id="Group_Tube">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1382" y1="15.4285" x2="56.1382" y2="1.4651">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect y="1.465" fill="url(#SVGID_1_)" width="112.275" height="13.964" />
        </g>
        <g id="Group_Horizontal_Lines">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="84.3467" y1="1.4646" x2="112.2754" y2="1.4646">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,1.465h27.929" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="84.3467" y1="15.4285" x2="112.2754" y2="15.4285">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,15.428h27.929" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="111.374" y1="8.1648" x2="112.5" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M111.374,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="108.7832" y1="8.1648" x2="110.248" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M108.783,0.001h1.465v16.328h-1.465V0.001z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="106.5313" y1="8.1648" x2="107.6582" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M106.531,0.001h1.127v16.328h-1.127V0.001z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="104.3926" y1="8.1648" x2="105.5176" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.393,0.001h1.125v16.328h-1.125V0.001z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="101.8018" y1="8.1648" x2="103.2656" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M101.802,0.001h1.464v16.328h-1.464V0.001z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="99.6621" y1="8.1648" x2="100.6758" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.662,0.001h1.014v16.328h-1.014V0.001z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="97.4102" y1="8.1648" x2="98.5361" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M97.41,0.001h1.126v16.328H97.41V0.001z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="94.9326" y1="8.1648" x2="96.0586" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M94.933,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="92.6797" y1="8.1648" x2="93.8066" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M92.68,0.001h1.127v16.328H92.68V0.001z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="90.2031" y1="8.1648" x2="91.5547" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M90.203,0.001h1.352v16.328h-1.352V0.001z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="87.9512" y1="8.1648" x2="89.0762" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M87.951,0.001h1.125v16.328h-1.125V0.001z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="85.6982" y1="8.1648" x2="86.8242" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.698,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="1.4646" x2="84.1211" y2="1.4646">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,1.465h27.927" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="15.4285" x2="84.1211" y2="15.4285">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,15.428h27.927" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="83.2207" y1="8.1648" x2="84.3467" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="80.9688" y1="8.1648" x2="82.0947" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M80.969,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="78.4912" y1="8.1648" x2="79.6172" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M78.491,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="76.2383" y1="8.1648" x2="77.3652" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" stroke="#4C4C4C" stroke-width="0.25" d="M76.238,0.001h1.127v16.328h-1.127V0.001z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="73.7617" y1="8.1648" x2="75.1133" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" stroke="#4C4C4C" stroke-width="0.25" d="M73.762,0.001h1.352v16.328h-1.352V0.001z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="71.5088" y1="8.1648" x2="72.6348" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" stroke="#4C4C4C" stroke-width="0.25" d="M71.509,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="69.2568" y1="8.1648" x2="70.3828" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" stroke="#4C4C4C" stroke-width="0.25" d="M69.257,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="66.7793" y1="8.1648" x2="68.1309" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" stroke="#4C4C4C" stroke-width="0.25" d="M66.779,0.001h1.352v16.328h-1.352V0.001z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="64.5273" y1="8.1648" x2="65.6533" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" stroke="#4C4C4C" stroke-width="0.25" d="M64.527,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="62.2754" y1="8.1648" x2="63.4004" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" stroke="#4C4C4C" stroke-width="0.25" d="M62.275,0.001H63.4v16.328h-1.125V0.001z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="59.7969" y1="8.1648" x2="60.9238" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" stroke="#4C4C4C" stroke-width="0.25" d="M59.797,0.001h1.127v16.328h-1.127V0.001z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="57.5449" y1="8.1648" x2="58.6719" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" stroke="#4C4C4C" stroke-width="0.25" d="M57.545,0.001h1.127v16.328h-1.127V0.001z" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="28.0405" y1="1.4646" x2="55.9683" y2="1.4646">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,1.465h27.928" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="28.0405" y1="15.4285" x2="55.9683" y2="15.4285">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,15.428h27.928" />
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="55.0674" y1="8.1648" x2="56.1938" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" stroke="#4C4C4C" stroke-width="0.25" d="M55.067,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="52.5903" y1="8.1648" x2="53.9414" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" stroke="#4C4C4C" stroke-width="0.25" d="M52.59,0.001h1.351v16.328H52.59V0.001z" />
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="50.3379" y1="8.1648" x2="51.4639" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_34_)" stroke="#4C4C4C" stroke-width="0.25" d="M50.338,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="48.0854" y1="8.1648" x2="49.2119" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_35_)" stroke="#4C4C4C" stroke-width="0.25" d="M48.085,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="45.6079" y1="8.1648" x2="46.9595" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_36_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.608,0.001h1.352v16.328h-1.352V0.001z" />
            <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="43.356" y1="8.1648" x2="44.4819" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_37_)" stroke="#4C4C4C" stroke-width="0.25" d="M43.356,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="41.1035" y1="8.1648" x2="42.2295" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_38_)" stroke="#4C4C4C" stroke-width="0.25" d="M41.104,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="38.626" y1="8.1648" x2="39.7524" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_39_)" stroke="#4C4C4C" stroke-width="0.25" d="M38.626,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="36.374" y1="8.1648" x2="37.5" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_40_)" stroke="#4C4C4C" stroke-width="0.25" d="M36.374,0.001H37.5v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="33.8965" y1="8.1648" x2="35.2476" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_41_)" stroke="#4C4C4C" stroke-width="0.25" d="M33.896,0.001h1.351v16.328h-1.351V0.001z" />
            <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="31.644" y1="8.1648" x2="32.7705" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_42_)" stroke="#4C4C4C" stroke-width="0.25" d="M31.644,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="29.3921" y1="8.1648" x2="30.5181" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_43_)" stroke="#4C4C4C" stroke-width="0.25" d="M29.392,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="0" y1="1.4646" x2="27.8154" y2="1.4646">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_44_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,1.465h27.815" />
            <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="0" y1="15.4285" x2="27.8154" y2="15.4285">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_45_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,15.428h27.815" />
            <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="26.9146" y1="8.1648" x2="28.0405" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_46_)" stroke="#4C4C4C" stroke-width="0.25" d="M26.915,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="24.6621" y1="8.1648" x2="25.7881" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_47_)" stroke="#4C4C4C" stroke-width="0.25" d="M24.662,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="22.1846" y1="8.1648" x2="23.311" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_48_)" stroke="#4C4C4C" stroke-width="0.25" d="M22.185,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="19.9326" y1="8.1648" x2="21.0586" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_49_)" stroke="#4C4C4C" stroke-width="0.25" d="M19.933,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="17.4551" y1="8.1648" x2="18.8062" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_50_)" stroke="#4C4C4C" stroke-width="0.25" d="M17.455,0.001h1.351v16.328h-1.351V0.001z" />
            <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="15.2026" y1="8.1648" x2="16.3286" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_51_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.203,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="12.9507" y1="8.1648" x2="14.0767" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_52_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.951,0.001h1.126v16.328h-1.126V0.001z" />
            <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="10.5854" y1="8.1648" x2="11.8242" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_53_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.585,0.001h1.239v16.328h-1.239V0.001z" />
            <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="8.2207" y1="8.1648" x2="9.3467" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_54_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.221,0.001h1.126v16.328H8.221V0.001z" />
            <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="5.9683" y1="8.1648" x2="7.0947" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_55_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.968,0.001h1.126v16.328H5.968V0.001z" />
            <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="3.6035" y1="8.1648" x2="4.7295" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_56_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.604,0.001h1.126v16.328H3.604V0.001z" />
            <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="1.2388" y1="8.1648" x2="2.3647" y2="8.1648">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_57_)" stroke="#4C4C4C" stroke-width="0.25" d="M1.239,0.001h1.126v16.328H1.239V0.001z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_43_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_44_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_46_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_47_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_48_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_49_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_50_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_51_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_52_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_53_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_54_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_55_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_56_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_57_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);


        super.drawObject();
    }
}
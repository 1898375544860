import { createApp } from 'vue'
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import {createI18n, useI18n} from 'vue-i18n'
import {languages, defaultLocale} from './i18n'
//import {defaultLocale} from './i18n'

const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
  const ctx = self;
  tid && clearTimeout(tid);
  tid = setTimeout(() => {
  callback.apply(ctx, args);
  }, delay);
  };
  };
  
  const _ = (window as any).ResizeObserver;
  (window as any). ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
  callback = debounce (callback, 20);
  super(callback);
  }
  };

const localstoragelang = localStorage.getItem('lang')
const messages = Object.assign(languages)

const i18n = createI18n({
  legacy:false,
  locale:localstoragelang || defaultLocale,
  fallbackLocale:'en',
  messages
})

createApp(App,{ setup(){
     const {t} = useI18n()
     return {t}
  }
}).use(i18n).use(Quasar, 

  {
 /* config: {
    notify: {  }
  },*/
  plugins: {
    Notify
  }
  
})
.use(store)
.use(router)
//.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app')

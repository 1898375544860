import { SVGObject } from "../svgobject";

export class SVGCooling11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 101.801" enable-background="new 0 0 112.5 101.801" xml:space="preserve">
        <g id="Group_Explosion_Proof_Refrigerator">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,101.801V13.063h112.5v88.738h-3.829V96.17H3.717v5.631H0z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="103.0684" y1="100.1963" x2="10.3331" y2="7.461">
                <stop offset="0.03" style="stop-color:#A7A9AC" />
                <stop offset="0.23" style="stop-color:#B6B8BA" />
                <stop offset="0.38" style="stop-color:#C4C6C8" />
                <stop offset="0.61" style="stop-color:#B7B8B9" />
                <stop offset="0.77" style="stop-color:#ACACAC" />
                <stop offset="1" style="stop-color:#D1D3D4" />
                <stop offset="1" style="stop-color:#A7A9AC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.816,14.978h107.769V92.68H2.816V14.978z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.081,14.978v78.04" />
        </g>
        <g id="Group_Door_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="59.7969" y1="54.335" x2="61.7109" y2="54.335">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M59.797,14.076h1.914v80.518h-1.914V14.076z" />
        </g>
        <g id="Group_Door_Knob">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M62.049,49.099h7.208v2.815h-7.208V49.099z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="57.8828" y1="50.5625" x2="66.3281" y2="50.5625">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" d="M57.883,48.198h8.445v1.915h-1.801v-1.014h-2.479v2.815h4.279   v1.014h-8.445V48.198z" />
            <path fill="#595959" stroke="#4C4C4C" stroke-width="0.25" d="M69.257,48.761h7.095v3.491h-7.095V48.761z" />
        </g>
        <g id="Group_Clamp">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="56.9824" y1="87.3867" x2="64.1895" y2="87.3867">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M56.982,86.148h7.207v2.477h-7.207V86.148z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="56.9824" y1="19.707" x2="64.1895" y2="19.707">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M56.982,18.468h7.207v2.478h-7.207V18.468z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="56.9824" y1="54.7852" x2="64.1895" y2="54.7852">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M56.982,53.49h7.207v2.59h-7.207V53.49z" />
        </g>
        <g id="Group_Door_Holders">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="104.4365" y1="28.9595" x2="103.5169" y2="23.744">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.6" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M109.008,28.153l-10.697-1.915v-1.576l10.697-1.802V28.153z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M112.5,21.509h-3.829v7.207h3.829V21.509z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="110.5293" y1="29.7295" x2="110.5293" y2="20.6079">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.1" d="M109.572,20.608h1.914v9.122h-1.914V20.608z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="103.5176" y1="84.251" x2="104.437" y2="79.0365">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M109.008,85.135l-10.697-1.803v-1.576l10.697-1.914V85.135z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M112.5,78.604h-3.829v7.545h3.829V78.604z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="110.5293" y1="87.0488" x2="110.5293" y2="77.7021">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.1" d="M109.572,77.702h1.914v9.347h-1.914V77.702z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="8.9385" y1="78.7021" x2="9.8545" y2="83.8974">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M4.393,79.504l10.585,1.576v1.914L4.393,84.797V79.504z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M0.901,86.148h3.716v-7.545H0.901V86.148z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="2.7593" y1="87.0488" x2="2.7593" y2="77.7021">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.1" d="M3.717,87.049H1.802v-9.347h1.915V87.049z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="9.8638" y1="23.1968" x2="8.9668" y2="28.2838">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M4.393,22.522l10.585,1.576V25.9L4.393,27.477V22.522z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M0.901,28.716h3.716v-7.207H0.901V28.716z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="2.7593" y1="29.7295" x2="2.7593" y2="20.6079">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.1" d="M3.717,29.729H1.802v-9.122h1.915V29.729z" />
        </g>
        <g id="Group_Box1">
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="58.0928" y1="16.063" x2="47.0869" y2="-2.9997">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M41.892,0h21.396v13.063H41.892V0z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M43.807,1.802h17.567v11.261H43.807V1.802z" />
        </g>
        <g id="Group_Box2">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M35.023,13.063h-7.771V2.478h7.771V13.063z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M33.446,9.685h0.225l0.451,0.676l-0.451,1.126h-0.225l-0.563-0.112l-0.338-0.789   l0.563-0.901H33.446z" />
        </g>
        <g id="Group_Bottom_Piece">
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M20.946,96.396h15.09v3.829h-15.09V96.396z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M76.352,96.396h14.752v3.829H76.352V96.396z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.03, 0.6822916666666666, 81.0, 0.23, 0.5625, 111.0, 0.38, 0.453125, 139.0, 0.61, 0.5625, 111.0, 0.77, 0.65625, 87.0, 1.0, 0.359375, 163.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.6, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Explosion_Proof_Refrigerator",[0.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Door_Knob",[0.0, 0.40625, 151.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Box2",[0.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Bottom_Piece",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGValve25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 67.9 112.5" enable-background="new 0 0 67.9 112.5" xml:space="preserve">
        <g id="Group_Spiral">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="42.1152" y1="89.0195" x2="46.8457" y2="89.0195">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M42.115,75h4.73v28.039h-4.73V75z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="79.8975" x2="49.209" y2="79.8975">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M39.639,80.18l9.121-2.365l0.449,1.689l-9.121,2.477L39.639,80.18z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="84.627" x2="49.209" y2="84.627">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M39.639,85.021l9.121-2.478l0.449,1.689l-9.121,2.478L39.639,85.021z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="89.2441" x2="49.209" y2="89.2441">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M39.639,89.639l9.121-2.478l0.449,1.688l-9.121,2.479L39.639,89.639z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="93.9736" x2="49.209" y2="93.9736">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M39.639,94.256l9.121-2.365l0.449,1.689l-9.121,2.477L39.639,94.256z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="98.5908" x2="49.209" y2="98.5908">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M39.639,98.985l9.121-2.478l0.449,1.802l-9.121,2.364L39.639,98.985z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="103.3203" x2="49.209" y2="103.3203">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M39.639,103.715l9.121-2.478l0.449,1.688l-9.121,2.479L39.639,103.715z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="39.6387" y1="75.168" x2="49.209" y2="75.168">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M39.639,75.563l9.121-2.478l0.449,1.802l-9.121,2.365L39.639,75.563z" />
        </g>
        <g id="Group_Wheel">
            <path fill="#7F7F7F" d="M30.404,107.769l8.221-4.729h11.711l8.221,4.729h-4.73l-3.49-1.802H38.625l-3.492,1.802H30.404z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="21.0581" y1="110.1328" x2="67.9023" y2="110.1328">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M65.539,112.498H23.423l-2.365-2.365l2.365-2.364h42.116l2.363,2.364L65.539,112.498" />
            <path fill="#A6A6A6" d="M38.625,107.769v-4.729h11.711v4.729H38.625z" />
        </g>
        <g id="Group_Port2">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="37.498" y1="16.8867" x2="51.4629" y2="16.8867">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M37.498,29.154V4.619h13.965v24.536H37.498z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="25.6748" y1="2.3105" x2="63.1738" y2="2.3105">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M25.675,0.002h37.499v4.617H25.675V0.002" />
        </g>
        <g id="Group_Body">
            <path fill="#999999" d="M32.769,51.464V28.042h23.422v23.422H32.769z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.877,34.573h7.094v9.91h-7.094V34.573z" />
            <path fill="#CCCCCC" d="M42.791,34.719h3.379v9.764h-3.379V34.719z" />
            <path fill="#B2B2B2" d="M40.877,44.482v3.491l3.604,3.491l3.49-3.491v-3.491H40.877z" />
        </g>
        <g id="Group_Valve">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="37.498" y1="63.459" x2="51.4629" y2="63.459">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M37.498,74.834v-22.75h13.965v22.75H37.498z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.498,71.396h13.965" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="46.2256" y1="49.7192" x2="52.082" y2="55.5757">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M44.48,56.194v-4.73l3.49-3.491l8.221,3.491l-4.729,4.73H44.48z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="32.769" y1="39.7529" x2="40.877" y2="39.7529">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_14_)" points="32.769,28.042 40.877,28.042 40.877,47.973 32.769,51.464  " />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="49.0684" y1="39.7529" x2="57.1758" y2="39.7529" gradientTransform="matrix(-1 0 0 1 105.1484 0)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_15_)" points="56.08,28.042 47.973,28.042 47.973,47.973 56.08,51.464  " />
            <rect x="32.769" y="34.438" fill="#4C4C4C" width="23.422" height="0.281" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="36.8789" y1="55.5742" x2="42.735" y2="49.7181">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M37.498,56.194l-4.729-4.73l8.108-3.491l3.604,3.491v4.73H37.498z" />
        </g>
        <g id="Group_Bolt">
            <path fill="#666666" d="M51.463,18.132h3.49v4.054h-3.49V18.132z" />
            <path fill="#999999" d="M51.463,10.475h3.49v7.657h-3.49V10.475z" />
            <path fill="#B2B2B2" d="M54.953,15.204h2.365v4.617h-2.365V15.204z" />
            <path fill="#C9C9C7" d="M54.953,12.839h2.365v2.365h-2.365V12.839z" />
        </g>
        <g id="Group_1">
            <circle fill="#7F7F7E" cx="44.48" cy="67.905" r="2.365" />
            <circle cx="44.367" cy="67.905" r="1.126" />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="20.7368" y1="46.7939" x2="1.9712" y2="14.2909">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M4.054,13.177l18.693,32.431l-4.054,2.365L0,15.429L4.054,13.177" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="32.4644" y1="26.73" x2="25.394" y2="14.4838">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M37.498,23.824L16.892,35.699L9.91,23.424L37.498,7.532c0,0,6.537-0.491,6.537,4.551   C44.035,19.042,37.498,23.824,37.498,23.824z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
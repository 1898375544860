import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ColorProperty } from "./properties/colorproperty";
import { IndicatorProperty } from './properties/indicatorproperty';
import Konva from "konva";
import { TwoStateObject } from "./twostateobject";
import { Value } from '../value';


export class ValveBall extends TwoStateObject{
    indicatorcolor:string

    public drawObject():void{
      super.initObject();
      if(this.type3d != 0) this.type3d = 1

      let fillcolor = '0xffffff00'; 
      fillcolor = this.fillcolor;
      const fillprop = this.properties==null? null : this.properties['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
              fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }	
      const fillcolor2 = ColorUtils.convertformat(fillcolor)

      let color = this.indicatorcolor; 
    
      const colorprop = this.properties==null? null : this.properties['color'];
      if (colorprop!=null){	
          const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
          if (colorproperty!=null) 
              color = colorproperty.getColor(this.tagnames, this.fillcolor)     
      }	
      const color2 = ColorUtils.convertformat(color)     

      let indicator = false;
      const indicatorprop = this.properties==null? null : this.properties['indicator'];
      if (indicatorprop!=null){	
          const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
          if (indicatorproperty!=null) 
            indicator = indicatorproperty.CheckIndicator(this.tagnames);     
      }	
        
      const pipe = new Konva.Rect({
        x: 0,
        y:this.height/5,
        width: this.width/10,
        height: this.height*3/5,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1
      })

      const pipe2 = new Konva.Rect({
        x: this.width*9/10,
        y:this.height/5,
        width: this.width/10,
        height: this.height*3/5,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1
      })
      if(this.type3d==0){
        if(this.angle==0){
            pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.15, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.25)])
            pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.15, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.25)])
        }else if(this.angle==180){
            pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
            0.85, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
            0.85, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }else{
            pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }
      }else{
        pipe.fill(fillcolor2)
        pipe2.fill(fillcolor2)
      }
             // this.node.add(pipe, pipe2)

      const stripe = new Konva.Rect({
        x: 0,
        y: this.height/5,
        width: this.width/50,
        height: this.height*3/5,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1  
      })
      const stripe2 = new Konva.Rect({
          x: this.width*4/50,
          y: this.height/5,
          width: this.width/50,
          height: this.height*3/5,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1 
      })
      const stripe3 = new Konva.Rect({
          x: this.width*9/10,
          y:this.height/5,
          width: this.width/50,
          height: this.height*3/5,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
      })
      const stripe4 = new Konva.Rect({
          x: this.width*49/50,
          y:this.height/5,
          width: this.width/50,
          height: this.height*3/5,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
      })
      if(this.type3d==0){
          if(this.angle==0){
              stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe2.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe3.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe4.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else if(this.angle==180){
              stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              1, 'white'])
              stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              1, 'white'])
              stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              1, 'white'])
              stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              1, 'white'])
          }else{
              stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              0.5, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                  0.5, 'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                  0.5, 'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.5)])
              stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                  0.5, 'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }
        }else{
          stripe.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe2.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe3.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe4.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        }
          //this.node.add(stripe, stripe2, stripe3, stripe4)

        const pipe3 = new Konva.Rect({
          x: this.width/10,
          y: this.height*3/10,
          width: this.width*8/10,
          height: this.height*4/10,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
        })
        if(this.type3d==0){
          if(this.angle==0){
              pipe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
              pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
              pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              0.15, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.25)])
          }else if(this.angle==180){
              pipe3.fillLinearGradientStartPoint({ x: 0, y: 0});
              pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
              pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
              0.85, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else{
              pipe3.fillLinearGradientStartPoint({ x: 0, y: 0});
              pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
              pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              0.5, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }
        }else{
          pipe3.fill(fillcolor2)
        }
         // this.node.add(pipe3)
          
        const pipe5 = new Konva.Rect({
          x: this.width*9/20,
          y: 0,
          width: this.width/10,
          height: this.height*4/10,
          cornerRadius: this.width/40,
          stroke: ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:this.width/100
        })
        if(this.type3d==0){
              pipe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
              pipe5.fillLinearGradientEndPoint({ x: this.width/10, y: 0});
              pipe5.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1),
                  0.5, 'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.1)])
        }else{
          pipe5.fill(fillcolor2)
        }
       
        const indoval = new Konva.Ellipse({
          x: this.width*5/10,
          y: this.height*5/10,
          radiusX: this.width*2/10,
          radiusY: this.height*2/10,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth: 1
        });
        if(this.type3d==0){
          if(this.angle==0){
              indoval.fillLinearGradientStartPoint({ x: 0, y: -this.height*2/10 });
              indoval.fillLinearGradientEndPoint({ x: 0, y: this.height*2/10});
              indoval.fillLinearGradientColorStops([0, 'white',
                  1, 'black'])
          }else if(this.angle==90){
              indoval.fillLinearGradientStartPoint({ x: -this.width*2/10, y: 0 });
              indoval.fillLinearGradientEndPoint({ x: this.width*2/10, y: 0});
              indoval.fillLinearGradientColorStops([0, 'white',
                  1, 'black'])
          }else if(this.angle==180){
              indoval.fillLinearGradientStartPoint({ x: 0, y: this.height*2/10 });
              indoval.fillLinearGradientEndPoint({ x: 0, y: -this.height*2/10});
              indoval.fillLinearGradientColorStops([0, 'white',
                  1, 'black'])
          }else{
              indoval.fillLinearGradientStartPoint({ x: this.width*2/10, y: 0 });
              indoval.fillLinearGradientEndPoint({ x: -this.width*2/10, y: 0});
              indoval.fillLinearGradientColorStops([0, 'white',
                  1, 'black'])
          }
        }else{
          indoval.fill(fillcolor2)
        }
           
        const indoval2 = new Konva.Ellipse({
            x: this.width*5/10,
            y: this.height*5/10,
            radiusX: this.width*18/100,
            radiusY: this.height*18/100,
        });
        if(this.type3d==0){
          if(this.angle==0){
              indoval2.fillLinearGradientStartPoint({ x: 0, y: -this.height*18/100 });
              indoval2.fillLinearGradientEndPoint({ x: 0, y: this.height*18/100});
              indoval2.fillLinearGradientColorStops([0, fillcolor2,
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else if(this.angle==90){
              indoval2.fillLinearGradientStartPoint({ x: -this.height*18/100, y: 0 });
              indoval2.fillLinearGradientEndPoint({ x: this.height*18/100, y: 0});
              indoval2.fillLinearGradientColorStops([0, fillcolor2,
                  1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else if(this.angle==180){
              indoval2.fillLinearGradientStartPoint({ x: 0, y: this.height*18/100 });
              indoval2.fillLinearGradientEndPoint({ x: 0, y: -this.height*18/100});
              indoval2.fillLinearGradientColorStops([0, fillcolor2,
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else{
              indoval2.fillLinearGradientStartPoint({ x: this.height*18/100, y: 0 });
              indoval2.fillLinearGradientEndPoint({ x: -this.height*18/100, y: 0});
              indoval2.fillLinearGradientColorStops([0, fillcolor2,
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }
        }else{
          indoval2.fill(fillcolor2) 
        }
             
        const indoval3 = new Konva.Ellipse({
            x: this.width*5/10,
            y: this.height*5/10,
            radiusX: this.width*14/100,
            radiusY: this.height*14/100,
            fill: 'black'
          });
          const indoval4 = new Konva.Ellipse({
            x: this.width*5/10,
            y: this.height*5/10,
            radiusX: this.width*12/100,
            radiusY: this.height*12/100,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1
          });
          if(this.type3d==0){
            if(this.angle==0){
              indoval4.fillRadialGradientStartPoint({ x: 0, y: -this.height*5/100 });
              indoval4.fillRadialGradientEndRadius(this.height*0.15);
              indoval4.fillRadialGradientColorStops([0, 'white',
              1, color2])
            }else if(this.angle==90){
              indoval4.fillRadialGradientStartPoint({ x: -this.width*5/100, y: 0 });
              indoval4.fillRadialGradientEndRadius(this.height*0.15);
              indoval4.fillRadialGradientColorStops([0, 'white',
              1, color2])
            }else if(this.angle==180){
              indoval4.fillRadialGradientStartPoint({ x: 0, y: this.height*5/100 });
              indoval4.fillRadialGradientEndRadius(this.height*0.15);
              indoval4.fillRadialGradientColorStops([0, 'white',
              1, color2])
            }else{
              indoval4.fillRadialGradientStartPoint({ x: this.width*5/100, y: 0 });
              indoval4.fillRadialGradientEndRadius(this.height*0.15);
              indoval4.fillRadialGradientColorStops([0, 'white',
              1, color2])
            }
          }else{
            indoval4.fill(color2) 
          }

          if(indicator){
              this.node.add(pipe,stripe,stripe2,pipe2,pipe3,stripe3,stripe4,indoval,indoval2,indoval3,indoval4)
          }
          else{
              this.node.add(pipe,stripe,stripe2,pipe2,pipe5,pipe3,stripe3,stripe4,indoval,indoval2,indoval3,indoval4) 
          }        
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "indicatorcolor": {this[field] = value.value;this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "indicatorcolor": {value.datatype=7; value.value = this[field];break}
      }
      return value;
    }
}
import { SVGObject } from "../svgobject";

export class SVGDuct4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 78.264" enable-background="new 0 0 112.5 78.264" xml:space="preserve">
        <g id="Group_Base_Layer">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.5" d="M0.001,0h112.497v78.264H0.001V0z" />
        </g>
        <g id="Group_Side1">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="75.7864" x2="56.1938" y2="39.0759">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.53" style="stop-color:#D7D7D7" />
                <stop offset="1" style="stop-color:#E4E4E4" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M2.366,75.786h107.655L56.194,39.075L2.366,75.786z" />
        </g>
        <g id="Group_Side2">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.3662" y1="39.0754" x2="56.4189" y2="39.0754">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.78" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M56.419,39.075L2.366,75.786V2.365L56.419,39.075z" />
        </g>
        <g id="Group_Side3">
            <path fill="#B2B2B2" d="M2.366,2.365h107.655L56.194,39.075L2.366,2.365z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3020833333333333, 177.0, 0.53, 0.3177083333333333, 173.0, 1.0, 0.21875, 199.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.609375, 99.0, 0.78, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.fills,"Group_Base_Layer",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Side3",[0.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
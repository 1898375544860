import { SVGObject } from "../svgobject";

export class SVGPump10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 76.464" enable-background="new 0 0 112.5 76.464" xml:space="preserve">
        <g id="Group_Base">
            <polygon fill="#958100" points="99.155,72.973 97.635,64.289 86.262,64.514 86.262,70.271 85.022,70.271 85.022,72.973  " />
            <polygon fill="#958100" points="39.865,72.973 39.865,55.63 35.248,55.63 26.317,72.973  " />
            <path fill="#D5B700" d="M39.865,72.86H23.874v3.491h21.509V72.86h-2.478V55.517h-3.041V72.86z" />
            <path fill="#D5B700" d="M85.022,70.271h-3.153v2.702h-2.252v3.491H100.9v-3.491H85.022V70.271z" />
        </g>
        <g id="Group_ShaftCase">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="34.457" y1="55.6304" x2="34.457" y2="24.0996">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <rect x="17.68" y="24.099" fill="url(#SVGID_1_)" width="33.554" height="31.531" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="74.6504" y1="68.0181" x2="74.6504" y2="12.2754">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.08" style="stop-color:#C1A704" />
                <stop offset="0.15" style="stop-color:#C6AC0F" />
                <stop offset="0.22" style="stop-color:#CEB523" />
                <stop offset="0.3" style="stop-color:#DAC23E" />
                <stop offset="0.37" style="stop-color:#E9D261" />
                <stop offset="0.44" style="stop-color:#FBE68B" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.46" style="stop-color:#FEE990" />
                <stop offset="0.55" style="stop-color:#F1DA64" />
                <stop offset="0.64" style="stop-color:#E7CD40" />
                <stop offset="0.73" style="stop-color:#DFC424" />
                <stop offset="0.82" style="stop-color:#D9BD10" />
                <stop offset="0.91" style="stop-color:#D6B804" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="75.225,12.275 73.424,14.527 73.424,16.667 73.4,16.674 73.4,60.128 73.424,60.135    73.424,65.765 75.225,68.018 75.9,67.792 75.9,12.5  " />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="62.3164" y1="60.1284" x2="62.3164" y2="16.6743">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="73.4,16.674 51.464,24.099 51.234,24.099 51.234,55.63 56.757,55.63 73.4,60.128  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="16.8354" y1="55.6304" x2="16.8354" y2="24.0996">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.08" style="stop-color:#C1A704" />
                <stop offset="0.15" style="stop-color:#C6AC0F" />
                <stop offset="0.22" style="stop-color:#CEB523" />
                <stop offset="0.3" style="stop-color:#DAC23E" />
                <stop offset="0.37" style="stop-color:#E9D261" />
                <stop offset="0.44" style="stop-color:#FBE68B" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.46" style="stop-color:#FEE990" />
                <stop offset="0.55" style="stop-color:#F1DA64" />
                <stop offset="0.64" style="stop-color:#E7CD40" />
                <stop offset="0.73" style="stop-color:#DFC424" />
                <stop offset="0.82" style="stop-color:#D9BD10" />
                <stop offset="0.91" style="stop-color:#D6B804" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M17.68,24.099h-1.689V55.63h1.689V24.099" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="66.8926" y1="54.73" x2="66.8926" y2="23.6489">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.08" style="stop-color:#C1A704" />
                <stop offset="0.15" style="stop-color:#C6AC0F" />
                <stop offset="0.22" style="stop-color:#CEB523" />
                <stop offset="0.3" style="stop-color:#DAC23E" />
                <stop offset="0.37" style="stop-color:#E9D261" />
                <stop offset="0.44" style="stop-color:#FBE68B" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.46" style="stop-color:#FEE990" />
                <stop offset="0.55" style="stop-color:#F1DA64" />
                <stop offset="0.64" style="stop-color:#E7CD40" />
                <stop offset="0.73" style="stop-color:#DFC424" />
                <stop offset="0.82" style="stop-color:#D9BD10" />
                <stop offset="0.91" style="stop-color:#D6B804" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M73.424,23.648V54.73L60.36,51.239V28.041L73.424,23.648" />
            <path fill="#7F7F7F" d="M15.991,52.703h-1.014v2.027h1.014V52.703z" />
            <path fill="#7F7F7F" d="M15.991,25.338h-1.014v2.027h1.014V25.338z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="34.9102" y1="23.5923" x2="38.7388" y2="23.5923">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M38.739,24.099H34.91v-1.014h3.829V24.099z" />
            <path d="M74.212,11.036H75.9v4.167h-1.688v-0.676h-0.563V12.05h0.563V11.036z" />
            <path d="M74.212,25.338H75.9v3.941h-1.688v-0.676h-0.563v-2.478h0.563V25.338z" />
            <path d="M74.212,48.537H75.9v4.166h-1.688v-0.676h-0.563V49.55h0.563V48.537z" />
            <path d="M74.212,63.851H75.9v4.167h-1.688v-1.014h-0.563v-2.478h0.563V63.851z" />
            <radialGradient id="SVGID_7_" cx="33.6152" cy="45.2144" r="1.749" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#9B9B9B" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M34.459,46.847h-1.689l-1.014-1.465l1.014-1.801h1.689l1.014,1.801L34.459,46.847z" />
            <path fill="#F0F0F0" d="M74.212,66.779h1.464v-1.914h-1.464V66.779z" />
            <path fill="#F0F0F0" d="M74.212,51.464h1.464v-1.689h-1.464V51.464z" />
            <path fill="#F0F0F0" d="M74.212,28.266h1.464v-1.914h-1.464V28.266z" />
            <path fill="#F0F0F0" d="M74.212,13.964h1.464V12.05h-1.464V13.964z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="34.4595" y1="22.1284" x2="38.9639" y2="22.1284">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="34.459" y="21.171" fill="url(#SVGID_8_)" width="4.504" height="1.914" />
        </g>
        <g id="Group_Port">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="92.8496" y1="64.5659" x2="92.8496" y2="13.7773">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.08" style="stop-color:#C1A704" />
                <stop offset="0.15" style="stop-color:#C6AC0F" />
                <stop offset="0.22" style="stop-color:#CEB523" />
                <stop offset="0.3" style="stop-color:#DAC23E" />
                <stop offset="0.37" style="stop-color:#E9D261" />
                <stop offset="0.44" style="stop-color:#FBE68B" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.46" style="stop-color:#FEE990" />
                <stop offset="0.55" style="stop-color:#F1DA64" />
                <stop offset="0.64" style="stop-color:#E7CD40" />
                <stop offset="0.73" style="stop-color:#DFC424" />
                <stop offset="0.82" style="stop-color:#D9BD10" />
                <stop offset="0.91" style="stop-color:#D6B804" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M98.461,14.453c-1.074-0.618-2.064-0.676-2.064-0.676H86.262v50.789c1.185,0,2.393,0,3.051,0   c1.172,0,3.429,0,5.516,0c2.42,0,3.135-0.253,3.482-0.451c0.139-0.079,1.127-0.648,1.127-1.414V60.06V15.607   C99.438,15.607,99.219,14.888,98.461,14.453z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="81.0811" y1="70.271" x2="81.0811" y2="9.5728">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M75.9,9.572h10.361v60.699H75.9V9.572" />
        </g>
        <g id="Group_Port2">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="102.7598" y1="50.8003" x2="102.7598" y2="29.0674">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M106.081,29.067V50.8h-6.644V29.067H106.081" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="109.291" y1="61.5991" x2="109.291" y2="15.2031">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M106.081,15.203h6.419v46.396h-6.419V15.203" />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="77.1396" y1="2.1626" x2="106.0811" y2="2.1626">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.55" style="stop-color:#FAE482" />
                <stop offset="0.73" style="stop-color:#ECD351" />
                <stop offset="0.99" style="stop-color:#D6B803" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M77.14,4.392v-4.459h28.941v4.459H77.14" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="84.7969" y1="9.7876" x2="98.4238" y2="9.7876">
                <stop offset="0" style="stop-color:#BFA500" />
                <stop offset="0.08" style="stop-color:#C1A704" />
                <stop offset="0.15" style="stop-color:#C6AC0F" />
                <stop offset="0.22" style="stop-color:#CEB523" />
                <stop offset="0.3" style="stop-color:#DAC23E" />
                <stop offset="0.37" style="stop-color:#E9D261" />
                <stop offset="0.44" style="stop-color:#FBE68B" />
                <stop offset="0.45" style="stop-color:#FFEB95" />
                <stop offset="0.46" style="stop-color:#FEE990" />
                <stop offset="0.55" style="stop-color:#F1DA64" />
                <stop offset="0.64" style="stop-color:#E7CD40" />
                <stop offset="0.73" style="stop-color:#DFC424" />
                <stop offset="0.82" style="stop-color:#D9BD10" />
                <stop offset="0.91" style="stop-color:#D6B804" />
                <stop offset="1" style="stop-color:#D5B700" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M86.262,11.261v3.266h10.135c0,0,0.968,0.059,2.027,0.656V4.392H84.797v5.18h1.465V11.261z" />
        </g>
        <g id="Group_Shaft">
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="15.0903" y1="47.7476" x2="15.0903" y2="32.2075">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M14.189,32.207h1.802v15.541h-1.802V32.207z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="66.8926" y1="42.6802" x2="66.8926" y2="37.5005">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M60.36,37.5h13.063v5.18H60.36V37.5z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="7.0942" y1="42.6802" x2="7.0942" y2="37.5005">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M14.189,37.5H0v5.18h14.189V37.5z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9166666666666666, 0.0, 0.08, 0.9427083333333334, 0.0, 0.15, 1.0, 0.0, 0.22, 0.90625, 23.0, 0.3, 0.765625, 59.0, 0.37, 0.59375, 103.0, 0.44, 0.390625, 155.0, 0.45, 0.3333333333333333, 169.0, 0.46, 0.359375, 163.0, 0.55, 0.546875, 115.0, 0.64, 0.703125, 75.0, 0.73, 0.8229166666666666, 45.0, 0.82, 0.90625, 23.0, 0.91, 0.953125, 11.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.9166666666666666, 0.0, 0.08, 0.9427083333333334, 0.0, 0.15, 1.0, 0.0, 0.22, 0.90625, 23.0, 0.3, 0.765625, 59.0, 0.37, 0.59375, 103.0, 0.44, 0.390625, 155.0, 0.45, 0.3333333333333333, 169.0, 0.46, 0.359375, 163.0, 0.55, 0.546875, 115.0, 0.64, 0.703125, 75.0, 0.73, 0.8229166666666666, 45.0, 0.82, 0.90625, 23.0, 0.91, 0.953125, 11.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.9166666666666666, 0.0, 0.08, 0.9427083333333334, 0.0, 0.15, 1.0, 0.0, 0.22, 0.90625, 23.0, 0.3, 0.765625, 59.0, 0.37, 0.59375, 103.0, 0.44, 0.390625, 155.0, 0.45, 0.3333333333333333, 169.0, 0.46, 0.359375, 163.0, 0.55, 0.546875, 115.0, 0.64, 0.703125, 75.0, 0.73, 0.8229166666666666, 45.0, 0.82, 0.90625, 23.0, 0.91, 0.953125, 11.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 53.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.9166666666666666, 0.0, 0.08, 0.9427083333333334, 0.0, 0.15, 1.0, 0.0, 0.22, 0.90625, 23.0, 0.3, 0.765625, 59.0, 0.37, 0.59375, 103.0, 0.44, 0.390625, 155.0, 0.45, 0.3333333333333333, 169.0, 0.46, 0.359375, 163.0, 0.55, 0.546875, 115.0, 0.64, 0.703125, 75.0, 0.73, 0.8229166666666666, 45.0, 0.82, 0.90625, 23.0, 0.91, 0.953125, 11.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.9166666666666666, 0.0, 0.45, 0.3333333333333333, 169.0, 0.55, 0.421875, 147.0, 0.73, 0.625, 95.0, 0.99, 0.9635416666666666, 9.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.9166666666666666, 0.0, 0.08, 0.9427083333333334, 0.0, 0.15, 1.0, 0.0, 0.22, 0.90625, 23.0, 0.3, 0.765625, 59.0, 0.37, 0.59375, 103.0, 0.44, 0.390625, 155.0, 0.45, 0.3333333333333333, 169.0, 0.46, 0.359375, 163.0, 0.55, 0.546875, 115.0, 0.64, 0.703125, 75.0, 0.73, 0.8229166666666666, 45.0, 0.82, 0.90625, 23.0, 0.91, 0.953125, 11.0, 1.0, 0.96875, 7.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.7135416666666666, 0.0, 1.0, 0.7135416666666666, 0.0, 2.0, 0.96875, 7.0, 3.0, 0.96875, 7.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPeople6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 34.574 112.5" enable-background="new 0 0 34.574 112.5" xml:space="preserve">
        <g id="Group_BackCollar">
            <path fill="#98B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M22.299,17.905l-0.676-2.928l-7.658,0.225l-0.676,2.703H22.299z" />
        </g>
        <g id="Group_Neck">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M20.947,17.455l-1.127-2.252h-4.053l-1.352,2.928" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M27.93,27.365l0.676,8.784l-0.676,7.658l1.801,10.135l-0.9,2.252   l0.45,3.603l0.676,0.451V57.77h0.45l-0.676,6.307l0.676,0.901l1.127-2.026l1.125-2.929l0.451-3.153l-0.451-2.477l1.352-11.036   l-0.112-6.644l-0.676-8.221l-1.688-6.981l-2.928-1.577" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M18.695,63.176v10.135l1.125,11.036l-0.675,8.784l2.478,12.387   l-1.127,4.73l0.451,1.126l2.928,1.126h3.604l1.127-1.126l-2.929-5.405l1.126-14.641V86.6l0.676-21.622l-0.676-9.91" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M8.371,59.098l-0.937,8.808l0.676,15.767L7.434,88.4l0.676,17.568   l-1.126,2.928l-1.802,1.802l0.451,1.126h4.729l2.928-2.252l-0.451-3.604v-1.127l-0.676-1.801l2.928-19.82l1.802-20.045" />
        </g>
        <g id="Group_RightArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M5.181,21.059l-2.928,3.378l-0.676,4.054V37.5l-0.676,5.856   l0.676,10.585l0.676,4.73l0.676,2.026L5.181,63.4l1.126-0.225l-1.577-2.703L4.506,57.77l0.675-0.675l0.451,3.604l0.901-0.676   v-2.928l-1.352-3.829L6.42,40.991l0.563-5.292l0.451-7.658" />
        </g>
        <g id="Group_Shoes">
            <path d="M28.83,111.824v-1.126l-1.802-1.802l-1.126-2.928h-3.604l-1.577-1.576v3.377l-0.675,1.803v1.126l0.675,1.126l2.253,0.676   h5.404L28.83,111.824z" />
            <path d="M4.28,111.824v-1.126l1.802-1.802l1.126-2.928h3.378l1.802-1.576l0.676,4.053l0.45,1.127l-1.577,1.126l-1.351,1.126   L8.334,112.5H4.731L4.28,111.824z" />
        </g>
        <g id="Group_Belt">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M7.659,50.788v-2.252h19.37v2.252H7.659z" />
        </g>
        <g id="Group_Face">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M22.299,11.148h-9.46l-1.126-1.126l-0.676-0.901V7.77l0.676-1.126   h11.712L24.1,7.77v1.352l-0.675,0.901L22.299,11.148z" />
            <ellipse fill="#996633" stroke="#4C4C4C" stroke-width="0.25" cx="17.569" cy="8.446" rx="5.63" ry="7.432" />
            <path fill="#663333" d="M17.343,10.923l1.802,0.451h-3.378L17.343,10.923z" />
            <path fill="#B27F7F" d="M15.767,12.951L15.316,12.5h4.279l-0.45,0.451H15.767z" />
            <path d="M20.496,3.266l0.451-2.252h0.225v1.802l-0.225-0.451L20.496,3.266z" />
            <path d="M20.947,6.644l-2.703-0.45v0.45l2.928,0.226L20.947,6.644z" />
            <path d="M13.965,6.644l2.703-0.45l-0.226,0.45L13.74,6.87L13.965,6.644z" />
            <path d="M15.091,8.896h0.45l0.226-0.676l-0.226-0.676h-0.45l-0.225,0.676L15.091,8.896z" />
            <path d="M19.371,8.896h0.449l0.227-0.676L19.82,7.545h-0.449l-0.226,0.676L19.371,8.896z" />
        </g>
        <g id="Group_Hair">
            <path fill="#333333" d="M12.163,7.996l-0.45-1.352l-0.676,1.352l0.676-5.18l1.577-1.802l1.802-0.901L17.343,0l2.477,0.113   l1.803,0.901l1.576,1.802l0.9,5.18l-0.675-1.352l-0.45,1.352l-0.901-3.829l-0.901-1.351l-0.225-0.451l-0.451,0.901l-5.405,0.676   h-2.027l-0.226,0.225L12.163,7.996z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M13.064,107.32l-1.802-1.352H6.533L5.857,90.203l0.676-6.982   L5.857,67.905l1.352-12.838l0.45-4.729l6.532-0.451l6.981,0.451h5.856L27.704,61.6l0.45,8.107l-1.126,14.64l0.676,7.657   l-1.126,14.641l-0.676-0.676h-4.73l-0.45,0.676L18.92,93.131l-0.676-9.459l-1.126-13.965l-1.126,13.514l-2.478,14.64L13.064,107.32   z" />
            <path fill="#666666" d="M17.118,69.031l-1.126,12.388l-2.478,15.766l-0.45,7.208l-0.676-5.406v-9.91l2.928-12.387l0.676-5.855   v-4.055v-2.252l0.45-10.586v9.91l0.676,3.604l2.253-4.279l-1.127,5.405l1.127,5.856l0.676,14.639l0.675,10.586l1.577,4.73   l-1.577,0.449l-1.351-7.657l-1.127-15.09L17.118,69.031z" />
        </g>
        <g id="Group_Shirt">
            <path fill="#CBE5E5" stroke="#4C4C4C" stroke-width="0.25" d="M7.884,48.536l0.225-2.478l-0.451-3.604v-3.378L7.434,31.87   l-0.225,4.054L6.42,42.68L5.407,53.491L3.83,53.716l-2.252,1.577L0.452,43.356l0.45-8.559l1.126-7.207l1.126-6.081l4.73-2.027   l6.081-1.802l3.829,1.577l0.226-1.126l3.828-0.9l9.01,2.703l1.801,2.477l0.676,3.942l0.338,8.446l0.338,14.865l-1.125,4.504   l-1.352-0.676l-2.253,0.226l-1.351-7.433v-6.757l0.225-2.027l-0.225-4.73l-0.226,3.604l-0.226,4.504l-0.45,4.729l-0.226,3.153   L7.884,48.536z" />
            <path fill="#CBE5E5" stroke="#4C4C4C" stroke-width="0.25" d="M18.244,20.383L18.02,17.68l3.828-2.703l0.451,2.928L18.244,20.383z" />
            <path fill="#CBE5E5" stroke="#4C4C4C" stroke-width="0.25" d="M17.794,20.608l0.226-3.153l-4.054-2.252l-0.676,2.703L17.794,20.608   z" />
        </g>
        <g id="Group_Blazer">
            <path fill="#E5E5B2" d="M28.379,41.779l0.676-1.802l2.479-0.225l-2.253-1.577l-0.675-6.757l0.675-1.352l-0.901-1.802l-1.351,2.478   l0.45,5.856l0.451-4.054l0.225,1.577v2.252v9.685L28.379,41.779z" />
            <path fill="#E5E5B2" d="M5.632,47.86l-0.676-6.306l-1.577-2.252l2.252-0.45l0.225-2.252l0.451-4.279l0.675-2.478l1.577,1.352   l-0.225,5.18l-0.901-4.73l-0.451,2.252v2.478L5.745,42.68L5.632,47.86z" />
            <path d="M25.677,51.239l1.126,7.207l-0.676-6.981L25.677,51.239z" />
            <path d="M8.785,51.239l-1.126,7.207l0.676-6.981L8.785,51.239z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M15.992,48.311h2.703v2.477h-2.928L15.992,48.311z" />
            <path d="M16.442,48.536l1.802,0.225v1.802l-2.027-0.225L16.442,48.536z" />
            <path fill="#660099" stroke="#4C4C4C" stroke-width="0.25" d="M18.02,17.905l-1.126,1.126l0.676,1.802l-1.126,26.126l1.802,1.352   l2.027-1.577L18.47,21.059l0.676-2.252L18.02,17.905z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M22.299,17.23l9.684,2.928l1.127,1.802l1.464,19.82l-0.45,12.387   l-3.041-1.239l-2.14,0.338l-1.126-6.644l-0.225-9.459v9.347l1.014,18.581l-11.262,2.027l1.915-31.982L22.299,17.23z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M13.515,17.342L3.379,20.158l-1.126,1.689L0.001,42.117l0.451,12.95   l2.928-1.802l2.365-0.563l0.675-9.459l1.126-6.982L6.983,46.847L4.956,65.315l11.599,1.577l0.901-31.757L13.515,17.342z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M19.258,35.135l3.041-17.793l3.378,6.081L21.06,35.135l1.126,3.491   l-2.703,6.982L19.258,35.135z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M17.456,35.022L13.515,17.23l-2.928,6.193l5.067,11.599l-1.126,3.491   l2.59,7.094L17.456,35.022z" />
            <path fill="#4C4C4C" d="M20.271,51.689l6.531,0.113l0.113,1.689l-6.645,0.563V51.689z" />
            <path fill="#4C4C4C" d="M14.866,51.464l-7.208-0.338l-0.112,2.14l7.207,0.563L14.866,51.464z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
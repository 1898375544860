import { SVGObject } from "../svgobject";

export class SVGDuct8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Base_Layer">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="79.3359" y1="79.3359" x2="33.2207" y2="33.2207">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.32" style="stop-color:#D7D7D7" />
                <stop offset="0.55" style="stop-color:#E6E6E6" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,66.441L66.441,0L112.5,46.171L46.171,112.5L0,66.441z" />
        </g>
        <g id="Group_Left_Layer">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="24.4375" y1="88.0635" x2="56.2505" y2="56.2505">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.78" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M2.815,66.441l43.356,43.131l10.135-53.266L2.815,66.441z" />
        </g>
        <g id="Group_Top1">
            <path fill="#B2B2B2" d="M56.306,56.306L2.815,66.441L66.441,2.815L56.306,56.306z" />
        </g>
        <g id="Group_Top2">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="56.3062" y1="29.5605" x2="109.5723" y2="29.5605">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.22" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M66.441,2.815l43.131,43.355L56.306,56.306L66.441,2.815z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3020833333333333, 177.0, 0.32, 0.3177083333333333, 173.0, 0.55, 0.20833333333333334, 202.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.609375, 99.0, 0.78, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.40625, 151.0, 0.22, 0.453125, 139.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Top1",[0.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
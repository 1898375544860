import { SVGObject } from "../svgobject";

export class SVGValve2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 39.53 112.5" enable-background="new 0 0 39.53 112.5" xml:space="preserve">
        <g id="Group_BodyAndPort">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.6309" y1="96.5088" x2="5.6309" y2="86.5996">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M9.798,96.509H3.266l-1.802-1.802v-6.531L3.266,86.6h6.532V96.509" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="13.1758" y1="69.1855" x2="26.2393" y2="69.1855">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M13.176,67.455h13.063v3.462H13.176V67.455z" />
            <path fill="#4C4C4C" d="M10.924,82.875h17.342v1.543H10.924V82.875z" />
            <path fill="#263A59" d="M14.753,64.302h9.909v2.478h-9.909V64.302z" />
            <path fill="#00007F" d="M15.653,66.779h8.108v0.676h-8.108V66.779z" />
            <path fill="#D1D1D1" d="M9.798,84.121h19.819v14.865H9.798V84.121z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="13.1758" y1="106.5313" x2="26.2393" y2="106.5313">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M13.176,100.563h13.063V112.5H13.176V100.563z" />
            <path fill="#666666" d="M16.779,84.121h5.856v2.703h-5.856V84.121z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="9.7979" y1="108.7832" x2="29.6172" y2="108.7832">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M9.798,107.095h19.819v3.378H9.798V107.095z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="11.374" y1="99.7744" x2="28.041" y2="99.7744">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M11.374,98.986h16.667v1.576H11.374V98.986" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="13.1758" y1="76.8926" x2="26.2393" y2="76.8926">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M13.176,72.41h13.063v8.965H13.176V72.41" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="9.7979" y1="81.9824" x2="29.6172" y2="81.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M9.798,80.743h19.819v2.478H9.798V80.743" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="13.8516" y1="106.3066" x2="16.3291" y2="106.3066">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M13.852,105.518h2.478v1.577h-2.478V105.518z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="23.0859" y1="106.3066" x2="25.3379" y2="106.3066">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M23.086,105.518h2.252v1.577h-2.252V105.518z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="18.3564" y1="106.3066" x2="20.834" y2="106.3066">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M18.356,105.518h2.478v1.577h-2.478V105.518z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="26.6895" y1="106.3066" x2="29.167" y2="106.3066">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M26.689,105.518h2.478v1.577h-2.478V105.518z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="10.248" y1="106.3066" x2="12.7256" y2="106.3066">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M10.248,105.518h2.478v1.577h-2.478V105.518z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="13.8516" y1="79.9551" x2="16.3291" y2="79.9551">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M13.852,79.166h2.478v1.577h-2.478V79.166z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="23.0859" y1="79.9551" x2="25.3379" y2="79.9551">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M23.086,79.166h2.252v1.577h-2.252V79.166z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="18.3564" y1="79.9551" x2="20.834" y2="79.9551">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M18.356,79.166h2.478v1.577h-2.478V79.166z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="26.6895" y1="79.9551" x2="29.167" y2="79.9551">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M26.689,79.166h2.478v1.577h-2.478V79.166z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="10.248" y1="79.9551" x2="12.7256" y2="79.9551">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M10.248,79.166h2.478v1.577h-2.478V79.166z" />
            
                <radialGradient id="SVGID_18_" cx="2.3647" cy="91.5547" r="3.5605" gradientTransform="matrix(1 0 0 1.2 0 -18.3109)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" d="M3.266,96.509l-1.802-1.802v-6.531L3.266,86.6" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="2.3647" y1="96.5088" x2="2.3647" y2="86.5996">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M3.266,96.509l-1.802-1.802v-6.531L3.266,86.6" />
        </g>
        <g id="Group_Stem">
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="18.3564" y1="55.5732" x2="20.834" y2="55.5732">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M18.356,46.834h2.478v17.478h-2.478V46.834z" />
            <path fill="#FFFFFF" d="M19.257,47.635h0.901V63.4h-0.901V47.635z" />
            <path fill="#333333" d="M18.131,57.085h3.153v2.262h-3.153V57.085z" />
        </g>
        <g id="Group_Piston">
            <path fill="#263A59" d="M17.23,51.915h4.954v5.292H17.23V51.915z" />
            <path fill="#263A59" d="M15.653,56.869h8.108v0.676h-8.108V56.869z" />
        </g>
        <g id="Group_Yoke">
            
                <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="9.772" y1="55.0933" x2="29.5762" y2="55.0933" gradientTransform="matrix(1 -0.0066 0.0066 1 -0.2236 0.3506)">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.06" style="stop-color:#596D8C" />
                <stop offset="0.08" style="stop-color:#465A79" />
                <stop offset="0.12" style="stop-color:#2F4362" />
                <stop offset="0.15" style="stop-color:#263A59" />
                <stop offset="0.52" style="stop-color:#596D8C" />
                <stop offset="0.82" style="stop-color:#263A59" />
                <stop offset="0.94" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M10.084,40.836l3.204-2.619l0.378,2.416l-1.543,0.92l0.023,28.429l3.606,0.427l10.924-0.072   l0.897-0.456l-0.073-28.266l-1.584-1.003l-0.242-2.476l3.942,2.364l0.097,29.883l-0.547,1.18l-2.928,0.848l-12.95,0.043   l-2.708-0.884l-0.57-1.009L10.084,40.836z" />
        </g>
        <g id="Group_ActuatorCase">
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="9.7979" y1="26.9111" x2="29.6172" y2="26.9111">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M9.798,15.653l2.478,1.576L13.099,39.5l0.527,1.491l0.226,0.112l0,0l0,0h11.824l0,0l0.226-0.112   l0.338-1.464l0.9-22.297l2.478-1.576v-2.81L9.798,12.719V15.653z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="37.0498" y1="6.416" x2="38.626" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M37.05,8.891h1.576V3.941H37.05V8.891z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="3.2656" y1="2.6396" x2="36.1484" y2="2.6396">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" d="M36.148,1.596V5.28H3.266V1.596l0.451-1.105L4.617,0h1.239h28.716l1.126,0.491L36.148,1.596z" />
            <path fill="#263A59" d="M14.753,41.104h9.909v0.901h-9.909V41.104z" />
            <path fill="#263A59" d="M17.23,41.554h4.954v5.406H17.23V41.554z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="0.7881" y1="6.416" x2="2.3652" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M0.788,8.891h1.577V3.941H0.788V8.891z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="3.2656" y1="10.4629" x2="36.1484" y2="10.4629">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M3.266,11.203V7.75h32.883v3.453l-0.45,1.356l-0.788,0.617h-1.238H4.843l-1.126-0.617L3.266,11.203   z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="18.8066" y1="6.416" x2="20.6084" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M18.807,8.891h1.802V3.941h-1.802V8.891z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="6.4189" y1="6.416" x2="8.2207" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" d="M6.419,8.891h1.802V3.941H6.419V8.891z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="12.2754" y1="6.416" x2="13.8516" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" d="M12.275,8.891h1.576V3.941h-1.576V8.891z" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="25.3379" y1="6.416" x2="27.1396" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" d="M25.338,8.891h1.802V3.941h-1.802V8.891z" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="31.1943" y1="6.416" x2="32.9961" y2="6.416">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" d="M31.194,8.891h1.802V3.941h-1.802V8.891z" />
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="0" y1="5.6309" x2="39.5273" y2="5.6309">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" d="M0,4.842h39.527v1.577H0V4.842z" />
            <path fill="#263A59" d="M12.275,17.229l-2.478-1.576h19.819l-2.478,1.576H12.275z" />
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="0" y1="7.3198" x2="39.5273" y2="7.3198">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" d="M0,6.419h39.527v1.802H0V6.419z" />
            <path fill="#596D8C" d="M23.762,31.338c0-0.9-0.694-1.631-1.576-1.707V29.62c0,0-4.799-0.003-4.811-0.003   c-0.951,0-1.722,0.771-1.722,1.722c0,0.951,0.771,1.722,1.722,1.722c0.012,0,4.811-0.015,4.811-0.015   C23.067,32.97,23.762,32.239,23.762,31.338z" />
            <circle fill="#00007F" cx="22.185" cy="31.194" r="0.45" />
            <circle fill="#00007F" cx="17.23" cy="31.194" r="0.45" />
            <rect y="6.21" fill="#263A59" width="39.53" height="0.417" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.4739583333333333, 0.0, 0.06, 0.875, 0.0, 0.08, 0.7239583333333334, 0.0, 0.12, 0.5416666666666666, 0.0, 0.15, 0.4739583333333333, 0.0, 0.52, 0.875, 0.0, 0.82, 0.4739583333333333, 0.0, 0.94, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.fills,"Group_BodyAndPort",[0.0, 0.5885416666666666, 0.0, 1.0, 0.4739583333333333, 0.0, 2.0, 0.3229166666666667, 0.0, 3.0, 0.3645833333333333, 161.0, 4.0, 0.7916666666666666, 0.0]);

        super.drawObject();
    }
}
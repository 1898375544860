import { SVGObject } from "../svgobject";

export class SVGContainer8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 73.533 112.5" enable-background="new 0 0 73.533 112.5" xml:space="preserve">
        <g id="Group_Bottle">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.1663" y1="60.4727" x2="69.2542" y2="60.4727">
                <stop offset="0" style="stop-color:#E04700" />
                <stop offset="0.5" style="stop-color:#FF8A00" />
                <stop offset="1" style="stop-color:#E04700" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M4.166,8.448h65.088v104.05H4.166V8.448z" />
            <path fill="none" d="M4.166,8.448h65.088v104.05H4.166V8.448" />
            <path fill="none" d="M0,0.002h73.533v13.063H0V0.002" />
        </g>
        <g id="Group_Cap">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="6.5332" x2="73.5334" y2="6.5332">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,0.002h73.533v13.063H0V0.002z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import Konva from "konva";
import { StringUtils } from "../utils/stringutils";
import { Value } from "../value";



export class TextArea extends ObjectView{
    text:string;
    fonttype:number;
    fontsize:number;
    editable:boolean;

    public drawObject():void{
      super.initObject();

      const rect = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          fill: 'white',
      });
      this.node.add(rect)

      const Text = new Konva.Text({
          x: 0,
          y: 0,
          verticalAlign:"top",
          width: this.width,
          height: this.height,
          text: this.text,
          fontSize: this.fontsize,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
      });
      this.node.add(Text);
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "text": {this[field] = value.value;this.rotatednode=null;break;}

          case "fonttype":
          case "fontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

          /*case "editable": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}*/
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "text": {value.datatype=7; value.value = this[field];break}

          case "fonttype":
          case "fontsize": {value.datatype=3; value.value = this[field];break}

          //case "editable": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";
import { Fan } from './fan';
import { EndPipe } from './endpipe';

export class CentrifugalPump extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
		let fillcolor = '0xffffff00';
           
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            const pipe = new EndPipe().drawEndPipe(this.width, this.height, fillcolor2, this.type3d, 180)
            pipe.position({x: this.width, y:this.height*8/10})
            pipe.rotate(180)
            
            this.node.add(pipe)
        
            const circle = new Konva.Circle({
                x: this.width/2,
                y:this.height/2+this.height/20,
                radius: this.height*9/20,
            
              });
              if(this.type3d==0){
                circle.fillRadialGradientStartPoint({ x: 0, y: 0 });
                circle.fillRadialGradientEndRadius(this.height*9/20);
                circle.fillRadialGradientColorStops([0.8, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.9, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                circle.fill(fillcolor2)
                circle.stroke(ColorUtils.darkColor(fillcolor2, 0.1))
                circle.strokeWidth(1)
              }
              this.node.add(circle)
              

            const circle2 = new Konva.Circle({
                x: this.width/2,
                y:this.height/2+this.height/20,
                radius: this.height*0.36,
                fill: 'black'
              });
              this.node.add(circle2)

             
              for(let i=0; i<360; i+=45){
                const wing = new Fan().drawWing(this.node, this.height*16/25, this.height*16/25, fillcolor2, this.type3d)
                const x =
                  this.width/2+
                  (this.width/2-this.height*8/125-this.width/2)* Math.cos(i*Math.PI/180) -
                  (this.height*11/20-this.height*8/25-this.height/2-this.height/20)* Math.sin(i*Math.PI/180);
                  const y =
                  this.height/2+this.height/20+
                  (this.width/2-this.height*8/125-this.width/2)* Math.sin(i*Math.PI/180) +
                  (this.height*11/20-this.height*8/25-this.height/2-this.height/20)* Math.cos(i*Math.PI/180);
                wing.position({x:x, y: y})
                wing.rotation(i)
                this.node.add(wing)
              }
            
            const pipe2 = new EndPipe().drawEndPipe(this.width, this.height, fillcolor2, this.type3d, 0)
            
            pipe2.position({x: 0, y:this.height/20})
      
            this.node.add(pipe2)
              
            const circle3 = new Konva.Circle({
                x: this.width/2,
                y:this.height/2+this.height/20,
                radius: this.height*5/20,
            
              });
              if(this.type3d==0){
                circle3.fillRadialGradientStartPoint({ x: 0, y: 0 });
                circle3.fillRadialGradientEndRadius(this.height*5/20);
                circle3.fillRadialGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.1, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                circle3.fill(fillcolor2)
                circle3.stroke(ColorUtils.darkColor(fillcolor2, 0.1))
                circle3.strokeWidth(1)
              }
              this.node.add(circle3)
        }
}
import { SVGObject } from "../svgobject";

export class SVGTank27 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 54" enable-background="new 0 0 112.5 54" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.3931" y1="54" x2="56.3931" y2="9.0005">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.01" style="stop-color:#1C4F4F" />
                <stop offset="0.12" style="stop-color:#487B7B" />
                <stop offset="0.22" style="stop-color:#6B9E9E" />
                <stop offset="0.33" style="stop-color:#85B8B8" />
                <stop offset="0.42" style="stop-color:#94C7C7" />
                <stop offset="0.49" style="stop-color:#99CCCC" />
                <stop offset="0.57" style="stop-color:#94C7C7" />
                <stop offset="0.66" style="stop-color:#85B8B8" />
                <stop offset="0.77" style="stop-color:#6B9E9E" />
                <stop offset="0.88" style="stop-color:#487B7B" />
                <stop offset="0.99" style="stop-color:#1C4F4F" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M6.031,54L1.59,31.575L6.781,9l98.853,0.021l5.563,22.845L106.488,54H6.031z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-111.4336" y1="-18.5088" x2="-0.6558" y2="-18.5088" gradientTransform="matrix(0.0094 0.4063 -0.2397 -0.0054 104.0111 54.2138)">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.48" style="stop-color:#99CCCC" />
                <stop offset="0.58" style="stop-color:#86B9B9" />
                <stop offset="0.78" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M112.482,31.529C112.07,13.642,105.718,9,105.718,9s-2.77,4.77-2.364,22.32   c0.384,16.62,2.979,22.68,2.979,22.68S112.885,48.932,112.482,31.529z" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-111.2993" y1="-15.9268" x2="-0.5215" y2="-15.9268" gradientTransform="matrix(-0.0094 0.4063 0.2397 -0.0054 7.8717 54.1733)">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.48" style="stop-color:#99CCCC" />
                <stop offset="0.58" style="stop-color:#86B9B9" />
                <stop offset="0.78" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0.018,31.529C0.43,13.642,6.783,9,6.783,9s2.77,4.77,2.364,22.32C8.762,47.94,6.167,54,6.167,54   S-0.385,48.932,0.018,31.529z" />
        </g>
        <g id="Group_Port">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="38.4751" y1="5.8018" x2="57.1504" y2="5.8018">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.48" style="stop-color:#99CCCC" />
                <stop offset="0.58" style="stop-color:#86B9B9" />
                <stop offset="0.78" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M38.475,2.167H57.15v7.27H38.475V2.167z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="35.5498" y1="1.4624" x2="60.0752" y2="1.4624">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.48" style="stop-color:#99CCCC" />
                <stop offset="0.58" style="stop-color:#86B9B9" />
                <stop offset="0.78" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M35.55,0h24.525v2.925H35.55V0" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4583333333333333, 0.0, 0.01, 0.4791666666666667, 0.0, 0.12, 0.8229166666666666, 0.0, 0.22, 0.9010416666666666, 25.0, 0.33, 0.6979166666666666, 77.0, 0.42, 0.578125, 107.0, 0.49, 0.5364583333333334, 117.0, 0.57, 0.578125, 107.0, 0.66, 0.6979166666666666, 77.0, 0.77, 0.9010416666666666, 25.0, 0.88, 0.8229166666666666, 0.0, 0.99, 0.4791666666666667, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4583333333333333, 0.0, 0.48, 0.5364583333333334, 117.0, 0.58, 0.6875, 79.0, 0.78, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4583333333333333, 0.0, 0.48, 0.5364583333333334, 117.0, 0.58, 0.6875, 79.0, 0.78, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4583333333333333, 0.0, 0.48, 0.5364583333333334, 117.0, 0.58, 0.6875, 79.0, 0.78, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4583333333333333, 0.0, 0.48, 0.5364583333333334, 117.0, 0.58, 0.6875, 79.0, 0.78, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater28 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 65.542" enable-background="new 0 0 112.5 65.542" xml:space="preserve">
        <g id="Group_Pipe2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="101.959" y1="46.8467" x2="101.959" y2="42.1182">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M91.418,46.847v-4.729H112.5v4.729H91.418z" />
        </g>
        <g id="Group_Pipe1">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="10.5415" y1="46.8467" x2="10.5415" y2="42.1182">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,46.847v-4.729h21.083v4.729H0z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="5.7993" y1="47.9736" x2="5.7993" y2="40.9912">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="4.617" y="40.991" fill="url(#SVGID_3_)" width="2.365" height="6.982" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="9.9102" y1="48.3115" x2="11.0361" y2="48.3115">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="9.91" y="35.698" fill="url(#SVGID_4_)" width="1.126" height="25.226" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="10.5293" y1="47.9736" x2="10.5293" y2="40.9912">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="9.347" y="40.991" fill="url(#SVGID_5_)" width="2.365" height="6.982" />
        </g>
        <g id="Group_Tank">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="65.541" x2="56.1938" y2="37.501">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M84.348,37.501L84.348,37.501L84.348,37.501H28.04l0,0c-7.743,0-14.02,6.277-14.02,14.02   c0,7.744,6.276,14.021,14.02,14.021l0,0h56.307l0,0h0.001c7.742,0,14.02-6.276,14.02-14.021   C98.367,43.778,92.09,37.501,84.348,37.501z" />
        </g>
        <g id="Group_Group1">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="33.0313" y1="4.5142" x2="46.3657" y2="10.7321">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M35.135,14.771V0h8.108l1.239,0.595v14.175H35.135z" />
            <path fill="#4D4D4D" d="M44.482,14.771V0.596L43.243,0v14.77H44.482z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="43.6904" y1="11.8408" x2="78.1567" y2="27.9127">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M49.212,39.753V0h23.423v39.752H49.212z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="29.0483" y1="21.9473" x2="55.2976" y2="34.1875">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M32.771,42.118V13.964h18.693l1.239,0.676v25.113l-1.239,2.365H32.771z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="52.6904" y1="20.9463" x2="82.9172" y2="35.0413">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M55.631,14.64l0.563-0.676h23.423v28.154H56.194l-0.563-2.365V14.64z" />
            <path fill="#4D4D4D" d="M52.703,14.64v25.113l-1.239,2.365V13.964" />
            <path fill="#4D4D4D" d="M55.631,39.753V14.64l0.563-0.676v28.154L55.631,39.753z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M51.464,30.518H32.771" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,29.956h18.693" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.617,30.518H56.194" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,29.956h23.423" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,29.28h18.693" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,29.28h23.423" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 17.23" enable-background="new 0 0 112.5 17.23" xml:space="preserve">
        <g id="Group_Base">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M4.28,10.81h103.828v4.279H4.28V10.81z" />
            <path fill="#C2A385" stroke="#4C4C4C" stroke-width="0.25" d="M0,15.089h103.828v2.141H0V15.089z" />
        </g>
        <g id="Group_Legs">
            <path fill="#661900" stroke="#4C4C4C" stroke-width="0.25" d="M4.28,15.089l8.671-4.279V2.14L4.28,6.419V15.089z" />
            <path fill="#661900" stroke="#4C4C4C" stroke-width="0.25" d="M54.054,15.089l8.67-4.279V2.14l-8.67,4.279V15.089z" />
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M103.828,15.089l8.672-4.279V2.14l-8.672,4.279V15.089z" />
            <path fill="#C2A385" stroke="#4C4C4C" stroke-width="0.25" d="M99.437,6.419h4.392v8.67h-4.392V6.419z" />
            <path fill="#C2A385" stroke="#4C4C4C" stroke-width="0.25" d="M49.775,6.419h4.279v8.67h-4.279V6.419z" />
            <path fill="#C2A385" stroke="#4C4C4C" stroke-width="0.25" d="M0,6.419H4.28v8.67H0V6.419z" />
        </g>
        <g id="Group_Top">
            <path fill="#AD845B" stroke="#4C4C4C" stroke-width="0.25" d="M103.828,17.23l8.672-4.279V10.81l-8.672,4.279V17.23z" />
            <path fill="#AD845B" stroke="#4C4C4C" stroke-width="0.25" d="M103.828,6.419L112.5,2.14V0l-8.672,4.279V6.419z" />
            <path fill="#D6C2AD" stroke="#4C4C4C" stroke-width="0.25" d="M103.828,4.279L112.5,0H8.559L0,4.279H103.828z" />
            <path fill="#C2A385" stroke="#4C4C4C" stroke-width="0.25" d="M0,4.279h103.828v2.14H0V4.279z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
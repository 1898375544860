import { SVGObject } from "../svgobject";

export class SVGTank59 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 73.8 112.5" enable-background="new 0 0 73.8 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1.7998" y1="56.25" x2="71.9995" y2="56.25">
            <stop offset="0.09" style="stop-color:#666666" />
            <stop offset="0.16" style="stop-color:#777777" />
            <stop offset="0.38" style="stop-color:#A2A2A2" />
            <stop offset="0.5" style="stop-color:#B2B2B2" />
            <stop offset="0.65" style="stop-color:#939393" />
            <stop offset="0.94" style="stop-color:#4D4D4D" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M1.8,0H72v112.5H1.8V0" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="1.5957" x2="73.8003" y2="1.5957">
            <stop offset="0.09" style="stop-color:#666666" />
            <stop offset="0.24" style="stop-color:#919191" />
            <stop offset="0.41" style="stop-color:#BBBBBB" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="0.62" style="stop-color:#ACACAC" />
            <stop offset="0.9" style="stop-color:#5C5C5C" />
            <stop offset="0.94" style="stop-color:#4D4D4D" />
        </linearGradient>
        <polygon fill="url(#SVGID_2_)" points="0,0.007 0,1.596 0,3.185 73.8,3.185 73.8,1.596 73.8,0.007 " />
        <polygon fill="#666666" points="1.923,3.231 1.923,3.842 1.923,4.454 72.017,4.454 72.017,3.842 72.017,3.231 " />
        <polygon fill="#666666" points="1.798,39.247 1.798,39.859 1.798,40.471 71.994,40.471 71.994,39.859 71.994,39.247 " />
        <polygon fill="#666666" points="1.923,76.039 1.923,76.652 1.923,77.264 72.017,77.264 72.017,76.652 72.017,76.039 " />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="37.3105" x2="73.8003" y2="37.3105">
            <stop offset="0.09" style="stop-color:#666666" />
            <stop offset="0.24" style="stop-color:#919191" />
            <stop offset="0.41" style="stop-color:#BBBBBB" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="0.62" style="stop-color:#ACACAC" />
            <stop offset="0.9" style="stop-color:#5C5C5C" />
            <stop offset="0.94" style="stop-color:#4D4D4D" />
        </linearGradient>
        <polygon fill="url(#SVGID_3_)" points="0,35.432 0,37.311 0,39.189 73.8,39.189 73.8,37.311 73.8,35.432 " />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="0" y1="73.9854" x2="73.8003" y2="73.9854">
            <stop offset="0.09" style="stop-color:#666666" />
            <stop offset="0.24" style="stop-color:#919191" />
            <stop offset="0.41" style="stop-color:#BBBBBB" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="0.62" style="stop-color:#ACACAC" />
            <stop offset="0.9" style="stop-color:#5C5C5C" />
            <stop offset="0.94" style="stop-color:#4D4D4D" />
        </linearGradient>
        <polygon fill="url(#SVGID_4_)" points="0,72.106 0,73.985 0,75.864 73.8,75.864 73.8,73.985 73.8,72.106 " />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0" y1="111.252" x2="73.8003" y2="111.252">
            <stop offset="0.09" style="stop-color:#666666" />
            <stop offset="0.24" style="stop-color:#919191" />
            <stop offset="0.41" style="stop-color:#BBBBBB" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="0.62" style="stop-color:#ACACAC" />
            <stop offset="0.9" style="stop-color:#5C5C5C" />
            <stop offset="0.94" style="stop-color:#4D4D4D" />
        </linearGradient>
        <polygon fill="url(#SVGID_5_)" points="0,109.964 0,111.252 0,112.54 73.8,112.54 73.8,111.252 73.8,109.964 " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.09, 0.7916666666666666, 0.0, 0.16, 0.9270833333333334, 0.0, 0.38, 0.734375, 67.0, 0.5, 0.609375, 99.0, 0.65, 0.8541666666666666, 37.0, 0.94, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.09, 0.7916666666666666, 0.0, 0.24, 0.8697916666666666, 33.0, 0.41, 0.5364583333333334, 117.0, 0.5, 0.40625, 151.0, 0.62, 0.65625, 87.0, 0.9, 0.7135416666666666, 0.0, 0.94, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.09, 0.7916666666666666, 0.0, 0.24, 0.8697916666666666, 33.0, 0.41, 0.5364583333333334, 117.0, 0.5, 0.40625, 151.0, 0.62, 0.65625, 87.0, 0.9, 0.7135416666666666, 0.0, 0.94, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.09, 0.7916666666666666, 0.0, 0.24, 0.8697916666666666, 33.0, 0.41, 0.5364583333333334, 117.0, 0.5, 0.40625, 151.0, 0.62, 0.65625, 87.0, 0.9, 0.7135416666666666, 0.0, 0.94, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.09, 0.7916666666666666, 0.0, 0.24, 0.8697916666666666, 33.0, 0.41, 0.5364583333333334, 117.0, 0.5, 0.40625, 151.0, 0.62, 0.65625, 87.0, 0.9, 0.7135416666666666, 0.0, 0.94, 0.5989583333333334, 0.0]);		
        super.drawObject();
    }
}
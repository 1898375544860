
import { AnalogMeterHorizontal } from './analogmeterhor';


export class AnalogMeterHorizontalFill extends AnalogMeterHorizontal{

   
    public drawObject():void{
		super.initObject();
        this.drawObjectHorizontal(true);
    }

    
    
}
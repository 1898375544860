import { SVGObject } from "../svgobject";

export class SVGWater30 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 107.77 112.5" enable-background="new 0 0 107.77 112.5" xml:space="preserve">
        <g id="Group_Support">
            <path fill="#B2B29B" stroke="#4C4C4C" stroke-width="0.25" d="M86.373,106.85h-6.418V28.378h6.418V106.85z" />
            <path fill="#B2B29B" stroke="#4C4C4C" stroke-width="0.25" d="M5.18,104.955h81.756v6.981H5.18V104.955z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="93.3555" y1="31.8135" x2="102.5898" y2="31.8135">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.5" style="stop-color:#DBDBD1" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M95.607,28.378v4.617l-2.252,1.126v1.126h9.234v-1.126l-2.365-1.126v-4.617H95.607z" />
        </g>
        <g id="Group_Clarifier">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="75.9287" y1="64.2734" x2="8.6431" y2="-3.0122">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.18,0.451h102.027v27.928H79.392L51.576,60.811v27.815   L28.378,102.59L5.18,88.626V0.451z" />
        </g>
        <g id="Group_Group1">
            <path fill="#333300" d="M39.977,103.716H24.324v-9.797h15.653V103.716z" />
            <circle fill="#CCCCBD" stroke="#4C4C4C" stroke-width="0.25" cx="28.266" cy="97.86" r="10.36" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="3.5391" y1="89.7822" x2="35.505" y2="104.6882">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M27.863,88.553c-0.4,0-22.135,3.262-22.135,3.262c-2.93,0.48-5.452,3.208-5.452,6.2   c0,2.812,2.227,5.329,4.926,6.012c0,0,22.555,3.254,22.66,3.254c5.171,0,9.924-4.192,9.924-9.364   C37.787,92.745,33.034,88.553,27.863,88.553z" />
        </g>
        <g id="Group_Pipe1">
            <path fill="#666638" stroke="#4C4C4C" stroke-width="0.25" d="M81.193,31.194l0.563,1.802h2.252l0.676-1.802H81.193z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-71.2539" y1="128.7148" x2="-68.8896" y2="128.7148" gradientTransform="matrix(-1 0 0 1 12.8555 -39.3545)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M81.745,88.164v2.393l2.345-0.873l0.02-0.246v-1.273" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-69.6211" y1="131.2705" x2="-68.4301" y2="129.2076" gradientTransform="matrix(-1 0 0 1 12.8555 -39.3545)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M81.912,92.242l1.125-0.66l-1.292-1.994l-1.021,0.596" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-20.7988" y1="173.0039" x2="-20.5402" y2="170.5394" gradientTransform="matrix(-0.8893 0.4573 0.4573 0.8893 -14.3928 -52.9178)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M81.745,89.426l1.831,1.648c0,0-0.219,0.225-0.303,0.295c-0.079,0.068-0.349,0.246-0.349,0.246   l-0.118,0.072L81.745,89.426z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="67.4883" y1="195.2773" x2="67.9201" y2="192.9514" gradientTransform="matrix(-0.3859 0.9225 0.9225 0.3859 -70.0535 -47.6526)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M81.742,89.424l2.365,0.014c0,0-0.002,0.174-0.054,0.473c-0.056,0.32-0.108,0.432-0.108,0.432   l-0.05,0.129L81.742,89.424z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="36.5063" y1="194.5273" x2="36.7599" y2="192.1109" gradientTransform="matrix(-0.5994 0.8004 0.8004 0.5994 -50.0295 -55.0846)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M81.742,89.424l2.213,0.893c0,0-0.11,0.295-0.156,0.389c-0.049,0.096-0.104,0.203-0.254,0.4   L81.742,89.424z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-1.4194" y1="118.1543" x2="-1.1608" y2="115.6898" gradientTransform="matrix(-0.9575 0.2904 0.2882 0.965 46.2012 -21.2019)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M80.725,90.072l1.276,2.086c0,0-0.271,0.18-0.364,0.229c-0.098,0.051-0.209,0.105-0.449,0.176   L80.725,90.072" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="81.7754" y1="58.3457" x2="84.1484" y2="58.3457">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <rect x="81.775" y="28.441" fill="url(#SVGID_10_)" width="2.373" height="59.809" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="34.3906" y1="123.0557" x2="34.3906" y2="121.2236" gradientTransform="matrix(1.3349 -0.7806 0.6373 1.0899 -45.7879 -13.0871)">
                <stop offset="0" style="stop-color:#333300" />
                <stop offset="0.5" style="stop-color:#9A9A64" />
                <stop offset="1" style="stop-color:#333300" />
            </linearGradient>
            <polygon fill="url(#SVGID_11_)" points="82.104,92.104 74.984,96.268 73.815,94.271 80.936,90.107  " />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="81.1934" y1="29.2231" x2="84.6836" y2="29.2231">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.5" style="stop-color:#DBDBD1" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.2" d="M81.193,27.252v3.941h3.49v-3.941H81.193z" />
        </g>
        <g id="Group_Pipe2">
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="54.6074" y1="-27.4082" x2="57.5039" y2="-27.4082" gradientTransform="matrix(0 -1 1 0 110.4639 60.085)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M82.443,2.582c0.678,0,1.225,0.648,1.225,1.448s-0.547,1.448-1.225,1.448" />
            
                <radialGradient id="SVGID_14_" cx="16.8633" cy="14.6382" r="2.9768" gradientTransform="matrix(-0.0607 -0.8678 0.8244 -0.0633 70.6996 19.63)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.08" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.41" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_14_)" d="M84.074,4.207c0-1.265-0.828-2.595-2.471-2.595l-0.006,2.2c0.289,0.045,0.359,0.171,0.377,0.388   C82.525,4.226,84.074,4.207,84.074,4.207z" />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="56.2949" y1="-31.083" x2="58.4727" y2="-31.083" gradientTransform="matrix(0 -1 1 0 110.4639 60.085)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="77.123" y="1.612" fill="url(#SVGID_15_)" width="4.516" height="2.179" />
            
                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="54.8164" y1="-26.3706" x2="54.8164" y2="-28.4839" gradientTransform="matrix(0 -1 1 0 110.4639 60.085)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="81.98" y="4.194" fill="url(#SVGID_16_)" width="2.113" height="2.148" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="81.9785" y1="16.7974" x2="84.1074" y2="16.7974">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="81.979" y="6.342" fill="url(#SVGID_17_)" width="2.129" height="20.91" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="40.2358" y1="3.7847" x2="40.2358" y2="1.5688">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="2.915" y="1.569" fill="url(#SVGID_18_)" width="74.642" height="2.216" />
        </g>
        <g id="Group_Clamp">
            <path fill="#99997A" stroke="#4C4C4C" stroke-width="0.25" d="M77.702,0.451v4.73h1.689v-4.73H77.702z" />
            <path fill="#99997A" stroke="#4C4C4C" stroke-width="0.25" d="M55.631,0.451v4.73h1.688v-4.73H55.631z" />
            <path fill="#99997A" stroke="#4C4C4C" stroke-width="0.25" d="M30.067,0.451v4.73h1.802v-4.73H30.067z" />
            <path fill="#99997A" stroke="#4C4C4C" stroke-width="0.25" d="M6.306,0.451v4.73h1.689v-4.73H6.306z" />
        </g>
        <g id="Group_Funnel">
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="55.0664" y1="104.9551" x2="55.0664" y2="93.3555">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.5" style="stop-color:#DBDBD1" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M39.977,104.955v-11.6h2.252v1.127l27.928,3.49v2.252l-27.928,3.491v1.239H39.977z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.229,103.716v-9.233" />
        </g>
        <g id="Group_Pump">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M70.157,104.955l1.126-2.365v-11.6h5.855v1.127h-2.364v10.473   l1.126,2.365H70.157z" />
            <radialGradient id="SVGID_20_" cx="73.0859" cy="97.9727" r="4.6167" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <circle fill="url(#SVGID_20_)" cx="73.085" cy="97.973" r="4.617" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M71.396,99.662l-0.451-0.789l-0.112-0.9l0.112-0.788l0.451-0.788   l0.787-0.563l0.901-0.112l0.788,0.112l0.789,0.563" />
        </g>
        <g id="Group_Label">
            <path fill="#FFFFFF" d="M37.612,9.798h11.599v6.981H37.612V9.798z" />
            <path fill="#FFFFFF" d="M26.013,32.996h6.982v2.252h-6.982V32.996z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6666666666666666, 0.0, 0.5, 0.3177083333333333, 173.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.2604166666666667, 0.0, 0.5, 0.9375, 15.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.6666666666666666, 0.0, 0.5, 0.3177083333333333, 173.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.6927083333333334, 0.0, 0.08, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.41, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.6666666666666666, 0.0, 0.5, 0.3177083333333333, 173.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.40625, 151.0, 0.5, 0.40625, 151.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.671875, 83.0, 1.0, 0.671875, 83.0]);
		
        super.drawObject();
    }
}
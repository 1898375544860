import { SVGObject } from "../svgobject";

export class SVGFlex2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 32.543" enable-background="new 0 0 112.5 32.543" xml:space="preserve">
        <g id="Group_Tube">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1382" y1="30.7427" x2="56.1382" y2="2.9282">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect x="0.002" y="2.928" fill="url(#SVGID_1_)" width="112.271" height="27.815" />
        </g>
        <g id="Group_Horizontal_Lines">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="55.9683" y1="2.9277" x2="112.2734" y2="2.9277">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M55.968,2.928h56.305" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="55.9683" y1="30.7427" x2="112.2734" y2="30.7427">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M55.968,30.743h56.305" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="110.2461" y1="16.272" x2="112.498" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M110.246,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="105.5166" y1="16.272" x2="107.6563" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.517,0h2.14v32.543h-2.14V0z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="100.7871" y1="16.272" x2="103.0391" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.787,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="96.0566" y1="16.272" x2="98.5352" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M96.057,0h2.479v32.543h-2.479V0z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="91.5527" y1="16.272" x2="93.8047" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.553,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="86.8232" y1="16.272" x2="89.0762" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M86.823,0h2.253v32.543h-2.253V0z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="82.0938" y1="16.272" x2="84.3457" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M82.094,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="77.3643" y1="16.272" x2="79.8418" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M77.364,0h2.478v32.543h-2.478V0z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="72.6348" y1="16.272" x2="75.1113" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,0h2.477v32.543h-2.477V0z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="68.1309" y1="16.272" x2="70.3828" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M68.131,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="63.4004" y1="16.272" x2="65.6523" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M63.4,0h2.252v32.543H63.4V0z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="58.6709" y1="16.272" x2="61.1484" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M58.671,0h2.478v32.543h-2.478V0z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="0.002" y1="2.9277" x2="55.9683" y2="2.9277">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,2.928h55.966" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="0.002" y1="30.7427" x2="55.9683" y2="30.7427">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,30.743h55.966" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="53.9414" y1="16.272" x2="56.4189" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M53.941,0h2.478v32.543h-2.478V0z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="49.2119" y1="16.272" x2="51.6895" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M49.212,0h2.478v32.543h-2.478V0z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="44.7075" y1="16.272" x2="46.96" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.708,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="39.978" y1="16.272" x2="42.23" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" stroke="#4C4C4C" stroke-width="0.25" d="M39.978,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="35.2485" y1="16.272" x2="37.7256" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.249,0h2.477v32.543h-2.477V0z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="30.519" y1="16.272" x2="32.9961" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" stroke="#4C4C4C" stroke-width="0.25" d="M30.519,0h2.477v32.543h-2.477V0z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="26.0146" y1="16.272" x2="28.2666" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" stroke="#4C4C4C" stroke-width="0.25" d="M26.015,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="21.2847" y1="16.272" x2="23.5371" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" stroke="#4C4C4C" stroke-width="0.25" d="M21.285,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="16.5552" y1="16.272" x2="18.8076" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" stroke="#4C4C4C" stroke-width="0.25" d="M16.555,0h2.252v32.543h-2.252V0z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="11.9385" y1="16.272" x2="14.3032" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" stroke="#4C4C4C" stroke-width="0.25" d="M11.938,0h2.365v32.543h-2.365V0z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="7.3213" y1="16.272" x2="9.5737" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" stroke="#4C4C4C" stroke-width="0.25" d="M7.321,0h2.252v32.543H7.321V0z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="2.5918" y1="16.272" x2="4.9565" y2="16.272">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" stroke="#4C4C4C" stroke-width="0.25" d="M2.592,0h2.365v32.543H2.592V0z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);


        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import { TextView } from './text'
import { AnalogMeterRect } from './analogmeterrect';


export class AnalogMeterRound extends AnalogMeterRect{

    public drawObject():void{
		super.initObject();

	
		let fillcolor = '0xffffff00'; 
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

        let color = this.color; 
    
        const colorprop = this.properties['color'];
            if (colorprop!=null){	
                const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
                if (colorproperty!=null) 
                        color = colorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const color2 = ColorUtils.convertformat(color)    
       
        let value = 0   
        let valueproperty:ValueProperty
        const valueprop = this.properties['value'];
        if (valueprop!=null){	
            valueproperty =Object.assign(new ValueProperty(), valueprop);
            if (valueproperty!=null) 
                    value = valueproperty.getCurrent270degValue(this.tagnames)           
        }	

        const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradientrect = ctx.createLinearGradient(0, 0, 0, this.width/2);
          gradientrect.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.9))
          gradientrect.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

          const gradientrectInner = ctx.createLinearGradient(0, 0, 0, this.width*19/40);
          gradientrectInner.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.5))
          gradientrectInner.addColorStop(1, fillcolor2)

          const gradientrectSmall = ctx.createLinearGradient(0, 0, 0, this.height*2/20);
          gradientrectSmall.addColorStop(0, '#000000')
          gradientrectSmall.addColorStop(1, '#FFFFFF')


        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: this.width/2,
            fill: fillcolor2,
            stroke: gradientrect,
            strokeWidth: this.height/120
            });
          this.node.add(circle)

          const rect2 = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: this.width*19/40,
            cornerRadius: this.height/40,
            fill: 'white',
            stroke: gradientrectInner,
            strokeWidth: this.height/80
            });
          this.node.add(rect2)

        const radius = this.width/3;
		const innerradius =radius-this.width/40-this.width/100;
		const innerradius2 =radius-this.width/80-this.width/100;
		const degreestep = 270/this.interval;
		const degreestep2 = degreestep/5;
		let valuemin=0;
		let valuemax=240;
		let decimalpos=0;

        if (valueproperty!=null){
            valuemin = valueproperty.minimumvalue;
            valuemax = valueproperty.maximumvalue;
            decimalpos=valueproperty.decimalpos;
        }

        const valuestep = (valuemax-valuemin)/this.interval;

        for (let i=0;i<=this.interval;i++){
            const degrees=degreestep*i-45;
            const radians = degrees*(Math.PI/180);
            const x1=this.width/2-radius*Math.cos(radians);
            const y1=this.height/2-radius*Math.sin(radians);
            const x2=this.width/2-innerradius*Math.cos(radians);
            const y2=this.height/2-innerradius*Math.sin(radians);
            const line = new Konva.Line({
                points: [x1, y1, x2, y2],
                strokeWidth: this.width/100,
                stroke: ColorUtils.darkColor(fillcolor2,0.1)
            })
            this.node.add(line)
            for (let j=1; j<5; j++){
                if (i==this.interval) break;
                const degrees2 = degrees+degreestep2*j;
                const radians2 = degrees2*(Math.PI/180);
                const x12=this.width/2-radius*Math.cos(radians2);
                const y12=this.height/2-radius*Math.sin(radians2);
                const x22=this.width/2-innerradius2*Math.cos(radians2);
                const y22=this.height/2-innerradius2*Math.sin(radians2);
                const line2 = new Konva.Line({
                    points: [x12, y12, x22, y22],
                    strokeWidth: this.width/150,
                    stroke: ColorUtils.darkColor(fillcolor2,0.1)
                })
                this.node.add(line2)
            }
            
            const value = valuemin+valuestep*i;
				const valuetext = value.toFixed(decimalpos).toString()
                const textTob = new TextView()
                textTob.width = this.width/4
                textTob.height = this.height/15
                textTob.text = valuetext
                textTob.fontsize = this.height/15
                textTob.textplacement = 1
                textTob.textcolor = ColorUtils.darkColor(color2,0.1)
                textTob.useborder = false
                textTob.bordercolor = null
                textTob.linewidth = 1
                textTob.fill = false
                textTob.fillcolor = ColorUtils.darkColor(color2,0.1)
                textTob.underline = false

                if (degrees<0){
					textTob.posx = (x1-this.width/5-2*this.width/100*Math.cos(radians));
					textTob.posy = (y1-this.height/18-2*this.height/50*Math.sin(radians));
                }else if(degrees<88){
					textTob.posx = (x1-this.width/5-this.width/100*Math.cos(radians));
					textTob.posy = (y1-this.height/18-this.height/50*Math.sin(radians));
				}else if(degrees>180){
					textTob.posx = (x1-this.width/18-2*this.width/100*Math.cos(radians));
					textTob.posy = (y1-this.height/18-2*this.height/50*Math.sin(radians))
                }else if(degrees>92){
                    textTob.posx = (x1-this.width/18-this.width/100*Math.cos(radians));
					textTob.posy = (y1-this.height/18-this.height/50*Math.sin(radians))
                }else{
                    textTob.posx = (x1-this.width/8);
					textTob.posy = (y1-this.height/16-2*this.height/50)
                }

                textTob.initLayer(this.layer)
                textTob.drawObject()
                this.node.add(textTob.rotatednode)
        }
            
        const textUnit = new TextView()
                textUnit.width = this.width/4
                textUnit.height = this.height/10
                textUnit.text = this.unit
                textUnit.fontsize = this.height/10
                textUnit.posx = this.width/2-this.width/8
                textUnit.posy = this.height*9/20-this.height/10
                textUnit.textplacement = 1
                textUnit.textcolor = ColorUtils.darkColor(color2,0.1)
                textUnit.useborder = false
                textUnit.bordercolor = null
                textUnit.linewidth = 1
                textUnit.fill = false
                textUnit.fillcolor = ColorUtils.darkColor(color2,0.1)
                textUnit.underline = false
                textUnit.initLayer(this.layer)
                textUnit.drawObject()
                this.node.add(textUnit.rotatednode)
     
        let arrowWidth = this.width/60
        let arrowHeight = innerradius2
        if (arrowWidth<1)  arrowWidth = 1;
        if (arrowHeight<1) arrowHeight = 1;
        
        const arrow = new Konva.Line({
            points: [0, arrowHeight,
                    arrowWidth/2, 0,
                    arrowWidth, arrowHeight,
                    0, arrowHeight],
            closed: true,
            fill: color2,
          }); 
        this.node.add(arrow)

        const anglearr = value-135;
		const radians = anglearr*Math.PI/180;
       
        const posx = this.width/2 -arrowWidth/2;
        const posy = this.width/2 -arrowHeight/2

        const x =arrowWidth/2+posx-arrowWidth/2* Math.cos(radians) +(arrowHeight)* Math.sin(radians);
        const y = posy+arrowHeight/2 -(arrowWidth/2)* Math.sin(radians) -(arrowHeight)* Math.cos(radians);
    
        arrow.position({x:x,y:y})
        arrow.rotation(anglearr) 
        
       const circleBall = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: arrowWidth,
            fill: 'black'
            });
          this.node.add(circleBall)
          
         if (this.usedigital){
			const digrect = new Konva.Rect({
                x: this.width/3,
                y: this.height*30/40,
                width: this.width/3,
                height: this.height*2/20,
                cornerRadius: this.height/100,
                stroke: gradientrectSmall,
                strokeWidth: this.height/100,
            })
              digrect.fillLinearGradientStartPoint({ x: 0, y: 0 });
              digrect.fillLinearGradientEndPoint({ x: 0, y: this.height*3/20 });
              digrect.fillLinearGradientColorStops([0, 'white', 0.25, 'black'])
              this.node.add(digrect)

                const valueTextOb = new TextView()
                valueTextOb.width = this.width/3
                valueTextOb.height = this.height/15
                valueTextOb.text = valueproperty.getTagValue(this.tagnames).toFixed(decimalpos).toString();
                valueTextOb.fontsize = this.height/15
                valueTextOb.posx = this.width/2-this.width/6
                valueTextOb.posy = this.height*33/40-this.height/18
                valueTextOb.textplacement = 1
                valueTextOb.textcolor = 'white'
                valueTextOb.useborder = false
                valueTextOb.bordercolor = null
                valueTextOb.linewidth = 0
                valueTextOb.fill = false
                valueTextOb.fillcolor = 'white'
                valueTextOb.underline = false
                valueTextOb.initLayer(this.layer)
                valueTextOb.drawObject()
                this.node.add(valueTextOb.rotatednode)
            }
    
        const textLabel = new TextView()
        textLabel.width = this.width/2
        textLabel.height = this.height/15
        textLabel.text = this.text
        textLabel.fontsize = this.height/15
        textLabel.posx = this.width/2-this.width/4
        textLabel.posy = this.height*14/20-this.height/19
        textLabel.textplacement = 1
        textLabel.textcolor = 'black'
        textLabel.useborder = false
        textLabel.bordercolor = null
        textLabel.linewidth = 1
        textLabel.fill = false
        textLabel.fillcolor = 'black'
        textLabel.underline = false
        textLabel.initLayer(this.layer)
        textLabel.drawObject()
        this.node.add(textLabel.rotatednode) 
    }
}
import { SVGObject } from "../svgobject";

export class SVGVehicle13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20000303 Stylable//EN" "http://www.w3.org/TR/2000/03/WD-SVG-20000303/DTD/svg-20000303-stylable.dtd"[]><svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 996 999"><circle style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" cx="498" cy="499" r="498" /><circle style="fill:#333;stroke:#4c4c4c;stroke-width:2" cx="498" cy="499" r="479" /><circle style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" cx="497" cy="499" r="301" /><circle style="fill:#4c4c4c;stroke:#4c4c4c;stroke-width:2" cx="498" cy="499" r="270" /><circle style="fill:#ccc;stroke:#4c4c4c;stroke-width:2" cx="496" cy="499" r="239" /><circle style="fill:none;stroke:#4c4c4c;stroke-width:2" cx="496" cy="499" r="146" /><path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M677,375 l37,118 -49,17 -37,-118 49,-17z" /><path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M436,292 l123,0 0,51 -123,0 0,-51z" /><path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M282,493 l37,-118 49,17 -37,118 -49,-17z" /><path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M427,704 l-101,-74 30,-41 100,73 -29,42z" /><path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M670,630 l-101,74 -30,-42 101,-73 30,41z" /><circle style="fill:#4c4c4c;stroke:#4c4c4c;stroke-width:2" cx="497" cy="499" r="73" /><circle style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" cx="498" cy="499" r="32" /></svg>`
		super.fillGradients();
	
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Reducer">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.2505" y1="112.5" x2="56.2505" y2="4.882813e-004">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M0,112.5V0l112.5,21.15v70.2L0,112.5" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
import { FillColorProperty } from "../properties/fillcolorproperty";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { Tile } from "./tile";

export class LedTile extends Tile{
    fillcolor: string;
    text: string;
    
    public drawObject():void{
		super.initObject();

        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
        const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let fillcolor = '0xffffff00';         
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
        const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
        if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        } 

        const size = this.width < this.height ? this.width : this.height;
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
        clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
        },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)

        const textTitle = new Konva.Text({
            x: size*0.05,
            y: size*0.1-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.title,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(textTitle)

        const description = new Konva.Text({
        x: size*0.05,
        y: size*.20 - 0.14*size,
        verticalAlign:"top",
        width: this.width-size*0.05,
        height: 0.14*size,
        text: this.description,
        fontSize: 0.14*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'center'
        });  
        group.add(description)

        const text = new Konva.Text({
            x: size*0.05,
            y: this.height - size*0.05-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.text,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(text)

        const cir = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: size*.25,
            fill:  ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
        })
        group.add(cir)

        const led = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: size*.20,
            fill:  ColorUtils.convertformat(fillcolor),
            stroke: ColorUtils.convertformat(bgcolor),
        })
        group.add(led)

          
        this.node.add(group)     
    }
}
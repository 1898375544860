import { SVGObject } from "../svgobject";

export class SVGBlower2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 58.219" enable-background="new 0 0 112.5 58.219" xml:space="preserve">
        <g id="Group_Base_Support">
            <polyline fill="#7F7F7F" points="29.843,48.309 29.843,50.787 46.284,50.787 46.284,53.039 45.608,53.039 45.608,57.093    65.878,57.093 65.878,51.463 69.707,51.463 69.707,57.093 75.111,57.093 75.111,58.219 69.707,58.219 65.878,58.219 45.608,58.219    41.554,58.219 15.654,58.219 11.825,58.219 11.825,58.219 11.825,53.039 11.825,52.363 11.825,50.787 26.914,50.787 26.914,48.311     " />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="55.4058" y1="57.0928" x2="55.4058" y2="22.6353">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="1" style="stop-color:#F5F5F5" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M48.987,22.635h12.837v34.458H48.987V22.635z" />
        </g>
        <g id="Group_Label">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="15.6538" y1="55.0664" x2="41.5542" y2="55.0664">
                <stop offset="0.01" style="stop-color:#808080" />
                <stop offset="0.17" style="stop-color:#DADCDD" />
                <stop offset="0.85" style="stop-color:#E8E9E9" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M41.554,57.093h-25.9v-4.054h25.9V57.093" />
        </g>
        <g id="Group_Clamp">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="25.1128" y1="17.4541" x2="30.0688" y2="17.4541">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" points="27.141,18.356 27.141,21.508 28.042,21.508    28.042,19.932 28.042,18.356 29.618,17.455 30.069,15.879 29.393,14.077 27.591,13.401 26.014,14.077 25.113,15.879 25.789,17.455     " />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M27.739,14.301l-1.19,0.49l-0.476,1.222l0.476,0.977l1.19,0.49   l0.952-0.49l0.477-0.977l-0.477-1.222L27.739,14.301" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="28.9663" y1="48.3105" x2="28.9663" y2="20.1577">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <polyline fill="url(#SVGID_4_)" points="26.429,48.311 11.67,48.311 10.748,34.12 11.67,20.157 45.34,20.157 46.723,20.607    47.185,21.733 47.185,46.958 46.723,47.859 45.34,48.309 29.428,48.309  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="283.9629" y1="432.334" x2="283.9629" y2="463.9043" gradientTransform="matrix(-1 0 0 1 290.5879 -414)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M12.804,47.063c-1.066-3.858-1.47-8.506-1.44-12.503c0.034-4.617,1.448-9.07,1.387-13.701   c-0.012-0.896,0.176-1.663,0.5-2.31v-0.215l-8.328,0.033C1.631,18.367,0,25.426,0,34.135c0,8.71,1.631,15.77,4.922,15.77   l8.328-0.085v-1.632C13.07,47.851,12.918,47.479,12.804,47.063z" />
        </g>
        <g id="Group_Motor_Fins">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M12.501,19.256v2.703h32.882l0.901-0.676l-1.352-1.802l-0.676-0.226   L12.501,19.256L12.501,19.256z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M12.501,21.959l-0.225,2.478h33.783l0.226-0.451l-0.901-1.351   l-1.352-0.676H12.501z" />
            <path fill="#D1D1D1" stroke="#4C4C4C" stroke-width="0.25" d="M12.276,25.112l-0.225,1.802h34.008l0.226-0.225l-2.027-1.577H12.276   z" />
            <path fill="#D1D1D1" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,27.815l-0.225,2.027h34.233l0.226-0.451l-2.252-1.576H12.051   z" />
            <path fill="#D1D1D1" stroke="#4C4C4C" stroke-width="0.25" d="M11.826,30.742v1.576h34.008l0.451-0.45l-2.252-1.126H11.826z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M11.6,34.796V34.12v-0.45h32.657l2.252,1.126h-0.676H11.6z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M11.826,36.148v1.576h34.233l0.226-0.226l-2.252-1.353H11.826V36.148   z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,38.625v2.027h33.783l0.225-0.227l-2.027-1.802L12.051,38.625   L12.051,38.625z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,41.553l0.225,2.027h33.558l0.451-0.227l-2.252-1.802   L12.051,41.553L12.051,41.553z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M12.276,44.031l0.225,2.478h33.333l0.451-0.45l-2.252-2.026   L12.276,44.031L12.276,44.031z" />
        </g>
        <g id="Group_Shaft">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="48.0859" y1="38.1748" x2="48.0859" y2="30.292">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M47.185,38.175v-7.883h1.802v7.883H47.185z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="63.1758" y1="42.2285" x2="63.1758" y2="26.2397">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M61.824,42.229V26.239h2.703v15.989H61.824" />
        </g>
        <g id="Group_Pipe">
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="86.959" y1="30.0176" x2="97.04" y2="30.0176" gradientTransform="matrix(1 0 0 -1 0 58.2188)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="86.959" y="27.743" fill="url(#SVGID_8_)" width="10.081" height="0.915" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="85.1172" y1="19.1816" x2="85.1172" y2="28.3828" gradientTransform="matrix(1 0 0 -1 0 58.2188)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect x="84.761" y="29.836" fill="url(#SVGID_9_)" width="0.712" height="9.201" />
            
                <radialGradient id="SVGID_10_" cx="158.1738" cy="279.7012" r="3.9635" gradientTransform="matrix(2.9866 -0.2001 -0.2179 -2.719 -325.676 820.9706)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595A5A" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.53" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M85.154,39.037c5.795,0,11.886-3.638,11.886-10.843l-10.075-0.026   c-0.21,1.268-0.784,1.573-1.779,1.655C85.069,32.238,85.154,39.037,85.154,39.037z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="86.959" y1="40.3486" x2="97.04" y2="40.3486" gradientTransform="matrix(1 0 0 -1 0 58.2188)">
                <stop offset="0.01" style="stop-color:#676868" />
                <stop offset="0.18" style="stop-color:#939394" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.74" style="stop-color:#A9AAAA" />
                <stop offset="1" style="stop-color:#656667" />
            </linearGradient>
            <rect x="86.959" y="7.432" fill="url(#SVGID_11_)" width="10.081" height="20.876" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="80.167" y1="19.1914" x2="80.167" y2="28.3926" gradientTransform="matrix(1 0 0 -1 0 58.2188)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="0.75" style="stop-color:#A3A4A4" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect x="75.111" y="29.826" fill="url(#SVGID_12_)" width="10.111" height="9.201" />
        </g>
        <g id="Group_Blower_box">
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="70.0449" y1="51.9131" x2="70.0449" y2="16.5547">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M75.563,16.554H64.527v35.359h11.035V16.554" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="71.6211" y1="55.7412" x2="71.6211" y2="12.9507">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M72.41,12.95h-1.577v42.791h1.577V12.95" />
        </g>
        <g id="Group_Port">
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="72.1836" y1="3.4346" x2="110.9219" y2="3.4346">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M110.922,6.869H72.184V0h38.738V6.869z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M112.498,6.869H70.607v1.126h41.891V6.869z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="70.6074" y1="7.4321" x2="112.498" y2="7.4321">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M112.498,6.869H70.607v1.126h41.891V6.869z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5989583333333334, 0.0, 1.0, 0.08333333333333333, 233.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 1.0, 0.0, 0.17, 0.2864583333333333, 181.0, 0.85, 0.1875, 207.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.40625, 151.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.53, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.8020833333333334, 0.0, 0.18, 0.8541666666666666, 37.0, 0.5, 0.25, 191.0, 0.74, 0.6822916666666666, 81.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 0.75, 0.7291666666666666, 69.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Base_Support",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Clamp",[0.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Motor_Fins",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.3645833333333333, 161.0, 3.0, 0.3645833333333333, 161.0, 4.0, 0.3645833333333333, 161.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0, 9.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
import { VuexModule,getModule, Module, Action, Mutation } from 'vuex-module-decorators';
import ScreenService from '@/services/screen.service';
import getscreenrequest from '@/model/requests/getscreenrequest';
import store from './index'
import { plainToInstance } from 'class-transformer';
import {Screen} from '@/model/project/screen';
import screensModule from './screens.module';


@Module({ name:"PopupScreen", dynamic:true, store})
class PopupScreen extends VuexModule {

  public popupscreen:Screen;
  public popupenable:boolean;
  public screens:Screen[]=[]
  
  @Mutation
  public setPopupEnabled(value:boolean){
      this.popupenable = value;
      if (!this.popupenable){
        if (this.popupscreen!=null)
           this.popupscreen.deleteObjects()
        this.popupscreen=null;
        
      }
  }
  
  get getPopupEnable():boolean{
   return this.popupenable;
  }
@Mutation
public setPopupScreenMutation(screen:Screen){
       this.popupscreen = screen;
       const screenfind = this.screens.find(t=>t.name ===screen.name);
       if (screenfind==null)
          this.screens.push(screen)
  }
  get getPopupScreenByName(){return function (name:string){
    if (this.screens.length==0) return null
      this.screen = this.screens.find(t=>t.name ==name);
    return this.screen
  }}

@Action
public getPopupScreenAction(request:getscreenrequest):Promise<Screen>{
  request.id = screensModule.getScreenDeviceId
  return new Promise ((resolve)=>{
    ScreenService.getScreen(request,(data:string)=>{
        const screen:Screen = plainToInstance(Screen, JSON.parse(data));   
        console.log(screen);
        this.setPopupScreenMutation(screen);
        resolve(screen)
        
    },(data:string)=>{
      console.log(data);
    });
  })
}

    get getPopupScreen():Screen { 
        return this.popupscreen;
    }
}

export default getModule(PopupScreen);
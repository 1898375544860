import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-auto" }
const _hoisted_2 = { class: "col text-left text-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.useevents)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          "padding-right": "0",
          flat: "",
          round: "",
          icon: "notifications",
          size: "sm",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.events = true))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t('events.table')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_q_dialog, {
      modelValue: $setup.events,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.events) = $event)),
      maximized: $setup.maximizedToggle,
      onBeforeShow: $options.onBeforeShow,
      onBeforeHide: $options.onBeforeHide,
      "transition-show": "slide-up",
      "transition-hide": "slide-down"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { style: {"max-width":"1000px","width":"600px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_bar, {
              dark: "",
              class: "bg-primary text-white"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString($props.description) + " ", 1),
                  _createVNode(_component_q_btn, {
                    dense: "",
                    flat: "",
                    "icon-right": "archive",
                    "no-caps": "",
                    onClick: $options.exportTable
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, { class: "bg-white text-primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('tags.exporttocsv')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    dense: "",
                    flat: "",
                    "icon-right": "picture_as_pdf",
                    "no-caps": "",
                    onClick:  $options.exportTablePDF
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, { class: "bg-white text-primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('events.exporttopdf')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createVNode(_component_q_select, {
                  "bg-color": "transparent",
                  style: {width:'150px', fontSize: '12px' },
                  "label-color": "white",
                  color: "primary",
                  "transition-show": "flip-up",
                  "transition-hide": "flip-down",
                  dense: "",
                  flat: "",
                  filled: "",
                  modelValue: $setup.filterpriority,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filterpriority) = $event)),
                  options: $setup.typeoptions,
                  label: $setup.t('events.eventstype')
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      color: "white",
                      name: "notifications"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "label"]),
                _createVNode(_component_q_select, {
                  "bg-color": "transparent",
                  style: {width:'150px', fontSize: '12px' },
                  "label-color": "white",
                  color: "primary",
                  "onUpdate:modelValue": [
                    $options.updateTimeSelection,
                    _cache[2] || (_cache[2] = ($event: any) => (($setup.model) = $event))
                  ],
                  "transition-show": "flip-up",
                  "transition-hide": "flip-down",
                  dense: "",
                  flat: "",
                  filled: "",
                  modelValue: $setup.model,
                  options: $setup.options,
                  label: $setup.t('events.timeperiod')
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      color: "white",
                      name: "history"
                    })
                  ]),
                  _: 1
                }, 8, ["onUpdate:modelValue", "modelValue", "options", "label"]),
                _createVNode(_component_q_space),
                _createVNode(_component_q_btn, {
                  dense: "",
                  flat: "",
                  icon: "minimize",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.maximizedToggle = false)),
                  disable: !$setup.maximizedToggle
                }, {
                  default: _withCtx(() => [
                    ($setup.maximizedToggle)
                      ? (_openBlock(), _createBlock(_component_q_tooltip, {
                          key: 0,
                          class: "bg-white text-primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('dialog.minimize')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["disable"]),
                _createVNode(_component_q_btn, {
                  dense: "",
                  flat: "",
                  icon: "crop_square",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.maximizedToggle = true)),
                  disable: $setup.maximizedToggle
                }, {
                  default: _withCtx(() => [
                    (!$setup.maximizedToggle)
                      ? (_openBlock(), _createBlock(_component_q_tooltip, {
                          key: 0,
                          class: "bg-white text-primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('dialog.maximize')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["disable"]),
                _withDirectives(_createVNode(_component_q_btn, {
                  dense: "",
                  flat: "",
                  icon: "close"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, { class: "bg-white text-primary" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.t('dialog.close')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_table, {
              title: $setup.t('events.table'),
              rows: $setup.eventsdata,
              columns: $setup.columns,
              filter: $setup.filterpriority,
              "filter-method": $options.filterData,
              "no-results-label": $setup.t('events.notmatchevent'),
              "row-key": "name"
            }, {
              "body-cell": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  class: _normalizeClass((props.row.priority<100)?'text-red':(props.row.priority>=900)?'text-green':'text-blue')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props", "class"])
              ]),
              _: 1
            }, 8, ["title", "rows", "columns", "filter", "filter-method", "no-results-label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "maximized", "onBeforeShow", "onBeforeHide"])
  ]))
}
import { SVGObject } from "../svgobject";

export class SVGWater29 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 110.023" enable-background="new 0 0 112.5 110.023" xml:space="preserve">
        <g id="Group_Clarifier">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M61.712,78.041h23.085v4.279H61.712V78.041z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="45.2715" y1="34.25" x2="18.6937" y2="2.5759">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.35" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,4.843h31.982v27.14H15.991V4.843z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="73.0684" y1="66.1025" x2="33.6907" y2="38.53">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,64.415l31.982-46.284h36.824v13.852L61.712,64.415   V82.32H15.991V64.415z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M13.739,2.365h34.234v2.478H13.739V2.365z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="47.9731" y1="10.248" x2="110.0225" y2="10.248">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M47.973,2.365h62.049v15.766H47.973V2.365z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="46.2837" y1="1.1826" x2="112.5" y2="1.1826">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M46.284,0H112.5v2.365H46.284V0z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.441,9.122h31.532" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M47.973,6.757h62.613" />
        </g>
        <g id="Group_Support">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="82.3203" y1="108.7842" x2="101.0137" y2="108.7842">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M82.32,107.545h18.693v2.479H82.32V107.545z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="0" y1="108.7842" x2="18.356" y2="108.7842">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,107.545h18.356v2.479H0V107.545z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="84.7969" y1="62.8379" x2="98.3105" y2="62.8379">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M84.797,18.131h13.514v89.414H84.797V18.131z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="2.3647" y1="60.6426" x2="15.9912" y2="60.6426">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M2.365,22.973l13.626-9.234v93.806H2.365V22.973z" />
        </g>
        <g id="Group_Plate">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="48.7612" y1="83.4463" x2="28.9415" y2="63.6266">
                <stop offset="0.01" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M28.941,63.627h19.82v19.819h-19.82V63.627z" />
            <path fill="#3D3D3D" d="M30.293,64.752h1.126v1.127h-1.126V64.752z" />
            <path fill="#3D3D3D" d="M46.284,64.752h1.126v1.127h-1.126V64.752z" />
            <path fill="#3D3D3D" d="M38.401,64.752h1.126v1.127h-1.126V64.752z" />
            <path fill="#3D3D3D" d="M30.293,72.861h1.126v1.125h-1.126V72.861z" />
            <path fill="#3D3D3D" d="M47.41,81.758h-1.126v-1.014h1.126V81.758z" />
            <path fill="#3D3D3D" d="M31.419,81.758h-1.126v-1.014h1.126V81.758z" />
            <path fill="#3D3D3D" d="M39.527,81.758h-1.126v-1.014h1.126V81.758z" />
            <path fill="#3D3D3D" d="M47.41,73.986h-1.126v-1.125h1.126V73.986z" />
            <path fill="#3D3D3D" d="M36.261,70.946h5.18v4.843h-5.18V70.946z" />
        </g>
        <g id="Group_Label">
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M22.41,52.815l4.955-7.094h48.987l-5.406,7.094H22.41z" />
            <path fill="none" stroke="#FF0000" stroke-width="0.25" d="M28.153,49.775h41.779" />
        </g>
        <g id="Group_Pipe">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="101.0137" y1="20.5518" x2="107.5449" y2="20.5518">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M101.014,18.131h6.531v4.842h-6.531V18.131" />
        </g>
        <g id="Group_Cone">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="15.9912" y1="90.4844" x2="61.7119" y2="90.4844">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.991,82.32l18.355,16.328h9.234L61.712,82.32H15.991z" />
            <ellipse fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" cx="39.077" cy="100.901" rx="5.18" ry="4.955" />
            <radialGradient id="SVGID_12_" cx="39.0762" cy="100.7891" r="3.943" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <ellipse fill="url(#SVGID_12_)" cx="39.077" cy="100.789" rx="3.829" ry="4.054" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 1.0, 0.0, 0.35, 0.40625, 151.0, 0.65, 0.40625, 151.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 1.0, 0.0, 0.5, 0.5052083333333334, 125.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);

        super.drawObject();
    }
}

import { Text3DView } from "./text3d";
import { Border3d } from "./border3d"
import { Cylinder } from "./cylinder"
import { Rectangle} from "./rectangle"
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import { VerticalTank } from "./verticaltank";
import Konva from "konva";


export class ConeTank extends VerticalTank{
   

    public drawObject():void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)

		let fillcolor = '0xffffff00';
        
                let fillheight = this.height
                let fillingproperty:FillingProperty
                const fillingprop = this.properties['filling']
                if (fillingprop != null){
                    fillingproperty =Object.assign(new FillingProperty(), fillingprop);
                    fillheight = fillingproperty.getHeight(this.tagnames, this.height)
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }
            const fillcolor2 = ColorUtils.convertformat(fillcolor)



        const topEnd = new Konva.Ellipse({
            x: this.width/2,
            y: this.height/10,
            radiusX: this.width/2,
            radiusY: this.height/10
        })
        if(this.type3d==0){
            topEnd.fillLinearGradientStartPoint({ x: 0, y: -this.height/10 });
            topEnd.fillLinearGradientEndPoint({ x: 0, y: 0 });
            topEnd.fillLinearGradientColorStops([0, 'white', 
            1, ColorUtils.darkColor(color2, 0.85)])
        }else{
            topEnd.fill(color2)
        }
        this.node.add(topEnd)

        const rect = new Konva.Rect({
            x: 0,
            y: this.height/10,
            width: this.width,
            height: this.height/2-this.height/10
        })
        if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: this.width, y: 0 });
            rect.fillLinearGradientColorStops([0.1, color2, 
                0.5, 'white',
            0.9, ColorUtils.darkColor(color2, 0.85)])
        }else{
            rect.fill(color2)
        }
        this.node.add(rect)

        const cone = new Konva.Line({
            points: [0, this.height/2,
            this.width*7/20, this.height*19/20,
            this.width*13/20, this.height*19/20,
            this.width, this.height/2,
            this.height/2, this.width*7/20
            ],
            closed: true
        })
        if(this.type3d==0){
            cone.fillLinearGradientStartPoint({ x: 0, y: 0 });
            cone.fillLinearGradientEndPoint({ x: this.width, y: 0 });
            cone.fillLinearGradientColorStops([0.1, color2, 
                0.5, 'white',
            0.9, ColorUtils.darkColor(color2, 0.85)])
        }else{
            cone.fill(color2)
        }
        this.node.add(cone)

        const rect2 = new Konva.Rect({
            x: this.width*7/20,
            y: this.height*19/20,
            width: this.width*6/20,
            height: this.height/20
        })
        if(this.type3d==0){
            rect2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect2.fillLinearGradientEndPoint({ x: this.width*6/20, y: 0 });
            rect2.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.5), 
                0.5, 'white',
                1, ColorUtils.darkColor(color2, 0.5)])
        }else{
            rect2.fill(color2)
        }
        this.node.add(rect2)   
    
    const border1 = new Border3d() 
    border1.width = this.width*12/100
    border1.height = this.height*77/100
    border1.posx = this.width*34/100
    border1.posy  =this.height*14/100
    border1.cornerradius = this.height/50 ;
    border1.glass = this.type3d==0? true: false;
    border1.linewidth = this.height/100
    border1.color = ColorUtils.convertbackformat(
      ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
    border1.fillcolor = '0x000000'
    border1.fill = true;
    border1.initLayer(this.layer)
    border1.drawObject()
    this.node.add(border1.rotatednode)

    if(this.type3d==0){
      const cylinder = new Cylinder()
      cylinder.width = this.width/10
      cylinder.height = fillheight*3/4
      cylinder.posx = this.width/2-this.width*3/20
      cylinder.posy =  (this.height*3/4 - fillheight*3/4)+this.height*3/20
      cylinder.properties = new  Map<string, string>()
      cylinder.linewidth = 0
      cylinder.color = color2
      cylinder.fillcolor = ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(fillcolor2), 0.5))
      cylinder.fill = true
      cylinder.initLayer(this.layer)
      cylinder.drawObject()
      this.node.add(cylinder.rotatednode)
    }else{
      const cylinder = new Rectangle()
      cylinder.width = this.width/10
      cylinder.height = fillheight*3/4
      cylinder.posx = this.width/2-this.width*3/20
      cylinder.posy =  (this.height*3/4 - fillheight*3/4)+this.height*3/20
      cylinder.properties = new  Map<string, string>()
      cylinder.linewidth = 0
      cylinder.color = color2
      cylinder.fillcolor = fillcolor2
      cylinder.fill = true
      cylinder.initLayer(this.layer)
      cylinder.drawObject()
      this.node.add(cylinder.rotatednode)
    }     

    const valuetext =fillingproperty==null?"0": (fillingproperty.getPercent(this.tagnames)).toFixed(1) +"%"
    
    const labelValue = new Text3DView() 
    labelValue.width = this.width*8/20
    labelValue.height = this.height*3/24
    labelValue.text = valuetext
    labelValue.fonttype = null;
    labelValue.fontsize = this.height/10
    labelValue.textplacement = 1
    labelValue.textcolor = fillcolor2
    labelValue.useborder = false;
    labelValue.bordercolor = null
    labelValue.linewidth = 1
    labelValue.fill = true
    labelValue.fillcolor = "0x00000000";
    labelValue.underline = false
    labelValue.posx = this.width*10/20
    labelValue.posy = this.height*8/20

    labelValue.initLayer(this.layer)
    labelValue.drawObject()
    this.node.add(labelValue.rotatednode) 
    } 
}

export class Value{
    value:any;
    datatype:number;

    constructor(){
        this.value = null;
        this.datatype = null
    }
    private isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
    public setValue(value:any):void{
        if (this.datatype>0 && this.datatype<7){
            if (value==true) value = 1
            if (value==false) value = 0
            value=this.isNumeric(value)?Number(value): (isNaN(value)? 'NaN' : 0)
        }
        
            this.value = value;
        if (this.datatype==0){
            if (this.value==0 || (typeof(value) == 'string'? value.toLowerCase()=='false' : value == false))
                this.value=false;
            else
                this.value =true;
        }
        else if (this.datatype==1){
            this.value = this.value & 255;
            if (this.value>127) this.value-=256
            this.value = this.value.toString()
        }else if(this.datatype==2){
            this.value = this.value & 65535;
            if (this.value>32768) this.value-=65536
            this.value = this.value.toString()
        }else if(this.datatype==3){
            if (this.value>2147483648) this.value=2147483647
            if (this.value<-2147483648) this.value=-2147483647
            this.value = this.value.toString()
        }
       
    }
}
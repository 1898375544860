import { SVGObject } from "../svgobject";

export class SVGTank25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 46.8 112.5" enable-background="new 0 0 46.8 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="62.5938" x2="46.8003" y2="62.5938">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M46.8,18.675l-23.4-5.987L0,18.675V112.5h46.8V18.675" />
        <radialGradient id="SVGID_2_" cx="23.3999" cy="9.3374" r="17.8152" gradientTransform="matrix(1 0 0 1.3 0 -2.8012)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#ECECEC" />
            <stop offset="0.14" style="stop-color:#DDDDDD" />
            <stop offset="0.4" style="stop-color:#B8B8B8" />
            <stop offset="0.75" style="stop-color:#7C7C7C" />
            <stop offset="1" style="stop-color:#4D4D4D" />
        </radialGradient>
        <path fill="url(#SVGID_2_)" d="M23.4,0C1.612,0,0,18.675,0,18.675h46.8C46.8,18.675,45.741,0,23.4,0z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import Konva from "konva";
import { Polygon } from './polygon';

export class Polyline extends Polygon{
    public drawObject():void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)	
		}	
        const p=[];
        this.coordinates.forEach((coord=>{
            p.push(coord.coordx);
            p.push(coord.coordy)
        }));
        const poly = new Konva.Line({
            points:p,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,     
          });    
          this.node.add(poly)
    }
}
import { SVGObject } from "../svgobject";

export class SVGMotor12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 44.027" enable-background="new 0 0 112.5 44.027" xml:space="preserve">
        <g id="Group_Rear">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.5942" y1="791.8884" x2="2.5942" y2="747.9734" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.51" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M1.693,0h3.491v43.915H1.693l-1.689-1.688V1.689L1.693,0" />
        </g>
        <g id="Group_Shaft">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="106.3027" y1="771.6208" x2="106.3027" y2="768.2429" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.51" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.109,20.269h12.387v3.378h-12.387V20.269z" />
        </g>
        <g id="Group_Nozzel">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="96.0557" y1="777.8132" x2="96.0557" y2="761.9363" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.51" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.785,18.693v6.418l-4.73,4.729h-4.729V13.963h4.729   L100.785,18.693" />
        </g>
        <g id="Group_Motor_Bodyrear">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="11.6587" y1="788.9617" x2="11.6587" y2="750.7888" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.73" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M4.621,2.815h14.076v38.173H4.621V2.815" />
        </g>
        <g id="Group_Motor_Body">
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="55.687" y1="790.7634" x2="55.687" y2="748.8748" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.51" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.922,0.901h73.643l0.451,0.113l0.9,1.801v38.061   l-0.9,1.802l-0.451,0.112H18.922l-0.563-0.112l-0.901-1.802V2.815l0.901-1.801L18.922,0.901" />
        </g>
        <g id="Group_Base">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M52.252,44.028H88.96v-2.139H52.252V44.028z" />
        </g>
        <g id="Group_Fasteners">
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="53.9414" y1="756.4197" x2="53.9414" y2="754.9558" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.51" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.814,6.982h86.254v1.464H10.814V6.982z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="53.9414" y1="788.6238" x2="53.9414" y2="787.1599" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0" style="stop-color:#3D3D3D" />
                <stop offset="0.49" style="stop-color:#FFFFFF" />
                <stop offset="0.99" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.814,39.186h86.254v1.464H10.814V39.186z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="90.6504" y1="755.7429" x2="95.3789" y2="755.7429" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.73" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M90.65,5.856h4.729v3.828H90.65V5.856z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="90.6504" y1="787.947" x2="95.3789" y2="787.947" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0.01" style="stop-color:#3D3D3D" />
                <stop offset="0.73" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M90.65,38.06h4.729v3.829H90.65V38.06z" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="12.729" y1="755.7429" x2="17.458" y2="755.7429" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0" style="stop-color:#3D3D3D" />
                <stop offset="0.27" style="stop-color:#FFFFFF" />
                <stop offset="0.99" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.729,5.856h4.729v3.828h-4.729V5.856z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="12.729" y1="787.947" x2="17.458" y2="787.947" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0" style="stop-color:#3D3D3D" />
                <stop offset="0.27" style="stop-color:#FFFFFF" />
                <stop offset="0.99" style="stop-color:#3D3D3D" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.729,38.06h4.729v3.829h-4.729V38.06z" />
        </g>
        <g id="Group_Name_Plate">
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="5.1841" y1="773.8708" x2="47.0732" y2="773.8708" gradientTransform="matrix(1 0 0 1 0 -747.9727)">
                <stop offset="0" style="stop-color:#FFFFFF" />
                <stop offset="0.15" style="stop-color:#939393" />
                <stop offset="0.44" style="stop-color:#FFFFFF" />
                <stop offset="0.74" style="stop-color:#D6D6D6" />
                <stop offset="1" style="stop-color:#FFFFFF" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M6.761,14.864h38.623l1.689,1.688v18.692l-1.689,1.688H6.761   l-1.577-1.688V16.552L6.761,14.864z" />
        </g>
        <g id="Group_Bolts">
            <circle fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" cx="44.483" cy="17.116" r="1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M43.47,16.327l2.139,1.576" />
            <circle fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" cx="44.483" cy="34.344" r="1.238" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M43.47,33.556l2.139,1.688" />
            <circle fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" cx="8.083" cy="17.116" r="1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.07,16.327l2.139,1.576" />
            <circle fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" cx="8.083" cy="34.344" r="1.238" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.07,33.556l2.139,1.688" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.4739583333333333, 0.0, 0.51, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.4739583333333333, 0.0, 0.51, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.4739583333333333, 0.0, 0.51, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.4739583333333333, 0.0, 0.73, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.4739583333333333, 0.0, 0.51, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.4739583333333333, 0.0, 0.51, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4739583333333333, 0.0, 0.49, 0.0, 255.0, 0.99, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.4739583333333333, 0.0, 0.73, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.4739583333333333, 0.0, 0.73, 0.0, 255.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4739583333333333, 0.0, 0.27, 0.0, 255.0, 0.99, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4739583333333333, 0.0, 0.27, 0.0, 255.0, 0.99, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.0, 255.0, 0.15, 0.8541666666666666, 37.0, 0.44, 0.0, 255.0, 0.74, 0.328125, 171.0, 1.0, 0.0, 255.0]);

        super.drawObject();
    }
}
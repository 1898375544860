import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";

export class AirBlowerPump extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            const pipe = new Konva.Rect({
                x: this.width/2,
                y: 0,
                width: this.width/2,
                height: this.height*4/10,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth:1
              });
              if(this.type3d==0){
                    pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                    pipe.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
              }else{
                pipe.fill(fillcolor2)
              }
              this.node.add(pipe)
            
            const circle = new Konva.Circle({
                x: this.width/2,
                y:this.height/2,
                radius: this.height/2,
              });
              if(this.type3d==0){
                circle.fillRadialGradientStartPoint({ x: 0, y: 0 });
                circle.fillRadialGradientEndRadius(this.height/2);
                circle.fillRadialGradientColorStops([0.8, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.9, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                circle.fill(ColorUtils.darkColor(fillcolor2, 0.55))
              }
              this.node.add(circle)

            const circle2 = new Konva.Circle({
                x: this.width/2,
                y:this.height/2,
                radius: this.height*8/20,
              });
              if(this.type3d==0){
                circle2.fillRadialGradientStartPoint({ x: 0, y: 0 });
                circle2.fillRadialGradientEndRadius(this.width/2);
                circle2.fillRadialGradientColorStops([0.9, fillcolor2,
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
              }else{
                circle2.fill(fillcolor2)
              }
              this.node.add(circle2)
        }
}
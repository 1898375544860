import { Selector } from "./selector";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SelectorProperty } from "./properties/selectorproperty";
import Konva from "konva";


export class Combobox extends Selector{

    public drawObject():void{
		super.initObject();

    let selproperty

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)


		let fillcolor = '0xffffff00';
        
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
        
            let ranges=[]
            let value = 0
            const selprop = this.properties['selector']
        if(selprop!=null){
             selproperty = Object.assign(new SelectorProperty(), selprop)
            if(selproperty!=null){
                ranges = selproperty.textrange
                value = selproperty.getCurrentValue(this.tagnames);
            }
        }

        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, color2)
              gradient.addColorStop(1, ColorUtils.darkColor(color2,0.5))

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10,
            strokeWidth: this.height/20,
            stroke: this.type3d==0? gradient : color2
          });
          if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0});
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
            rect.fillLinearGradientColorStops([0, fillcolor2,
                0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            rect.fill(fillcolor2)
          }
          this.node.add(rect)

          const line = new Konva.Line({
            points: [this.width*4/5, 0, this.width*4/5,this.height],
            stroke: this.type3d==0? gradient : color2
        });
        this.node.add(line)

        const arrow = new Konva.Line({
        
            points: [this.width*17/20, this.height*3/10,
                     this.width*19/20,this.height*3/10,
                    this.width*18/20, this.height*7/10],
            closed: true,
            //stroke: this.type3d==0? gradient : color2
        });
            if(this.type3d==0){
                arrow.fillLinearGradientStartPoint({ x: 0, y: 0});
                arrow.fillLinearGradientEndPoint({ x: 0, y: this.height});
                arrow.fillLinearGradientColorStops([0, color2,
                1, ColorUtils.darkColor(color2, 0.5)])
            }else{
                arrow.fill(color2)
            }
        this.node.add(arrow)


            let rectText = ""
            ranges.forEach((textview)=>{
                if(textview.value==value) rectText= textview.text
            })

            
        const Text = new Konva.Text({
            x: 0,
            y: 0, 
            height: this.height,
            width: this.width*4/5,
            text: rectText,
            fontSize: this.height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2
        })
        if(this.type3d==0){
            Text.shadowColor('black'),
            Text.shadowBlur(0),
            Text.shadowOffset({ x: 1, y: 1 })
          }
        this.node.add(Text)

        
          const  node1 = new Konva.Group();
          const height = this.height
          const width = this.width
          const tagnames = this.tagnames
          let flagclick = false
          this.node.add(node1)

            this.node.on('click touchstart', function(evt){
              flagclick = !flagclick;
              if (flagclick){
              drawMenu(node1, height, width, tagnames)
              }else{
                node1.removeChildren()
              }
          })

          function drawMenu( node:Konva.Group, height, width, tagnames){
            if(ranges.length != 0){
            ranges.forEach((textview)=>{
            const rectDropdown = new Konva.Rect({
              x: 0,
              y: height+height/40+(30*ranges.indexOf(textview)),
              width: width,
              height: 30,
              fill: 'white',
              strokeWidth: 0.05,
              stroke: 'black'
            })
              node.add(rectDropdown)
        
        const textDropDown = new Konva.Text({
          x: width/30,
          y: height+height/40+(30*ranges.indexOf(textview)), 
          height: 30,
          width: width*29/30,
          text: textview.text,
          fontSize: 20,
          fontFamily: "Roboto",
          fontStyle: "normal",
          align: 'left',
          verticalAlign:"middle",
          })

          textDropDown.fill('black');
          textDropDown.on('mouseover mousemove mouseenter', function(){
            textDropDown.fill('white');
            rectDropdown.fill('blue')
          })
        textDropDown.on('mouseleave', function(){
          textDropDown.fill('black'); 
          rectDropdown.fill('white')
        })
       
        textDropDown.on('click touchstart', function(){
          selproperty.sendTagValue(tagnames, textview.value)
        })
          node.add(textDropDown)
        })
      }
      }

          

       




       
    }
}
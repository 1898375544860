import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import Konva from "konva";
import {Sector} from "./properties/range/sector"
import tagsModele from "@/store/tags.modele";
import { Value } from "../value";



export class WindChart extends ObjectView{
   sectors:Sector[]
   uselegends:boolean
   minimum:number
   maximum:number
   number:number
   legends:string
   vertically:number
   

    public drawObject():void{
		super.initObject();
        
        const sectorsReversed = this.sectors.reverse()

        sectorsReversed.forEach((sec)=>{
            if (sec.path==null || tagsModele.getTag(sec.path)==null) return;
            this.tagnames.push(sec.path)
            const tagvalue = this.getTagValue(sec.path)
            const tagvalueArray =  tagvalue.replace(/\[|\]/g, '').split(',').map(Number)
            sec.directions = tagvalueArray
            sec['amount'] = 0
        })

        let sum = 0

        sectorsReversed.forEach((sec, index)=>{
                for(let j=0; j<this.number; j++){
                    sec['amount'] = sec['amount']+ sec['directions'][j]
                    if(index!=0){
                    const nexttagvalueArray = sectorsReversed[index-1]['directions']
                     sec['directions'][j]+= nexttagvalueArray[j]
                    }
                }
                sum =sum+sec['amount']
            })

        const legends  =  this.legends.replace(/\[|\]/g, '').split(',').map(String)
        const legendstrimmed = legends.map(leg=>{
            return leg.trim()
        })
            
        this.sectors = sectorsReversed.reverse()

        const width2 = this.uselegends?this.width/2:this.width
        const width = width2>this.height? this.height : width2
        const sectors = this.sectors
        const minimum = this.minimum
        const maximum = this.maximum
        const uselegends = this.uselegends
        const vertically = this.vertically
        const number = this.number
    
        if(this.uselegends){
            const piechart = this.drawPieChart(width, number, sectors, minimum, maximum, vertically, 
                uselegends, legendstrimmed)
            this.node.add(piechart)
            const legends = this.drawLegends(width, sectors, sum)
            legends.position({x:this.width/2, y:0})
            this.node.add(legends)
        }else{
            const piechart = this.drawPieChart(width, number, sectors, minimum, maximum, vertically, 
                uselegends, legendstrimmed)
            this.node.add(piechart)
        }
    } 

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "legends": {this[field] = value.value;this.rotatednode=null;break;}

            case "minimum":
            case "maximum": {this[field] = value.value;this.rotatednode=null;break;}

            case "vertically":
            case "number":  {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "uselegends": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "legends": {value.datatype=7; value.value = this[field];break}
            case "minimum":
            case "maximum": {value.datatype=6; value.value = this[field];break}

            case "vertically":
            case "number":  {value.datatype=1; value.value = this[field];break}

            case "uselegends": {value.datatype=0; value.value = (this[field]);break}
            case "fillcolor": {value.datatype=7; value.value = this[field];break}
        }
        return value;
    }

    private drawPieChart(width:number, number:number, sectors:any, minimum:number, maximum:number, vertically:number, 
        uselegends:boolean, legendstrimmed:any ){
        const node1 = new Konva.Group()
        const centerx = uselegends? width/2: width*19/32;

        for (let j=1; j<=vertically; j++) {
            const circle = new Konva.Circle({
                x: centerx,
                y: width/2,
                radius: width*j*28/vertically/64-1,
                stroke: 'black',
                strokeWidth:1
            })
            node1.add(circle)
        }
        if (sectors.length==0){
            const sector = new Konva.Arc({
                x: centerx,
                y: width/2,
                innerRadius: 0,
                outerRadius: width*7/16,
                angle: 360,
                fill: 'red',
            })
            node1.add(sector) 
        }
        const stepangle = 360/number;
        let startangle = 90+stepangle/2;

        sectors.forEach((sec)=>{
            for (let i=0;i<number;i++){
                const sectpart = (sec['directions'][i]-minimum)/(maximum-minimum);
    
                const wedge = new Konva.Wedge({
                    x: centerx,
                    y: width/2,
                    radius: sectpart*(width*7/16),
                    angle: stepangle,
                    fill: ColorUtils.convertformat(sec.color),
                    rotation: -(startangle),
                });
                startangle-=stepangle;
                node1.add(wedge)
            }
        })

        let degrees = 90;
        legendstrimmed.forEach((leg)=>{
            const radians = degrees*(Math.PI/180);
            const x2=centerx-width*7/16*Math.cos(radians); 
            const y2=width/2-width*7/16*Math.sin(radians);
         
            const legendMark = new Konva.Text({
                verticalAlign:"middle",
                width: width/5,
                height: width/18,
                text: leg ,
                fontSize: width/18,
                fill: 'black',
            })
            if (degrees==90) {
                legendMark.position({x: x2-width/10, y: y2-width/18})
                legendMark.align('center')
            }
            else if (degrees>90 && degrees<180){
                legendMark.position({x: x2+width/25, y: y2-width/36})
                legendMark.align('left')
            }
            else if (degrees==180) {
                legendMark.position({x: x2, y: y2})
                legendMark.align('left')
            }
            else if (degrees>180 && degrees<270) {
                legendMark.position({x: x2+width/25, y: y2-width/36})
                legendMark.align('left')
            }
            else if (degrees==270) {
                legendMark.position({x: x2-width/10, y: y2})
                legendMark.align('center')
            }
            else if (degrees>270 && degrees<360) {
                legendMark.position({x: x2-width/4, y: y2-width/36})
                legendMark.align('right')
            }
            else if (degrees==360) {
                legendMark.position({x: x2-width/5, y: y2})
                legendMark.align('right')
            }
            else if (degrees>360 && degrees<450) {
                legendMark.position({x: x2-width/4, y: y2-width/36})
                legendMark.align('right')
            }
            else {
                legendMark.position({x: x2, y: y2})
            }
            node1.add(legendMark)	
            degrees+=stepangle;    
        })

        for (let k=0; k<=vertically; k++) {
            const line1 = new Konva.Line({
                points: [centerx, width/2-width*k*28/vertically/64+1, centerx+width/15, width/2-width*k*28/vertically/64+1],
                strokeWidth:1, 
                stroke: 'black'
            })
            node1.add(line1)

            const textmark = k==0? minimum.toString(): 
            (k==vertically? maximum.toString() : (minimum + k*(maximum-minimum)/vertically)).toString()
            const mark = new Konva.Text({
                x: centerx+width/15,
                y: k==vertically? width/2-width*k*28/vertically/64+1-width/60 :width/2-width*k*28/vertically/64+1-width/40,
                verticalAlign:"middle",
                width: width/10,
                height: width/20,
                text: textmark,
                fontSize: width/20,
                fill: 'black',
                align: 'left'
              });
            node1.add(mark)
        }
        return node1
    }

    protected getTagValue(tagname:string):string{
        const tag = tagsModele.getTag(tagname) 
        return tag.tagvalue
    }

    protected drawLegends(width:number, sectors:any, sum:number):Konva.Group{
        const node1 = new Konva.Group()

        const size =(sectors.length<7)? sectors.length*2:sectors.length;
        sectors.forEach((sec, index)=>{
            const circle = new Konva.Circle({
                    x: width*3/10,
                    y: width*(index+1)/size,
                    radius: width/30,
                    fill: ColorUtils.convertformat(sec.color)
            })

            const text1 = (sec.amount/sum*100).toFixed(2)+'%'
            
            const text = new Konva.Text({
                x: width*4/10,
                y: width*(index+1)/size-width/30,
                verticalAlign:"middle",
                width: width-width*2/10,
                height: width/15,
                text: sec.sectorname + ' (' + text1 + ')',
                fontSize: width/15,
                fill: ColorUtils.convertformat(sec.color),
            });
            node1.add(circle, text)
        })
        return node1
    }        
}
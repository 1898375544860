import { SVGObject } from "../svgobject";

export class SVGFood12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 39.527" enable-background="new 0 0 112.5 39.527" xml:space="preserve">
        <g id="Group_BackGround">
            <path fill="#3D3D3D" d="M0,30.068v0.676h111.374v-0.676H0z" />
        </g>
        <g id="Group_Fittings">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="83.0098" y1="40.3909" x2="65.4121" y2="-7.9583">
                <stop offset="0.0057" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M37.049,30.068V2.365h74.325v27.702H37.049z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M37.049,11.6h74.325" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M46.284,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M46.959,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M55.743,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M56.194,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M64.978,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M65.653,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M74.212,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M74.887,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M83.445,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M83.896,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M92.68,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M93.355,30.068V2.365" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M102.14,30.068V2.365" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M102.703,30.068V2.365" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,30.068V2.365h74.325v27.702H37.049" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,9.235h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,4.731h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.032,6.87h1.126l0.676-0.451l0.45,0.451l-0.45,0.675L45.158,6.87" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.518,9.235h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.518,4.731h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.5,6.87h1.352l0.45-0.451l0.676,0.451l-0.676,0.675l-0.45-0.675" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.212,9.235h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.212,4.731h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M81.193,6.87h1.127l0.676-0.451l0.449,0.451l-0.449,0.675L82.32,6.87" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M92.68,9.235h1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M92.68,4.731h1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M99.662,6.87h1.352l0.45-0.451l0.676,0.451l-0.676,0.675l-0.45-0.675" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,26.69h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.392,26.69h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,14.978h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.392,14.978h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.032,23.762h1.126l0.676-0.676l0.45,0.676l-0.45,0.676l-0.676-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.761,23.762H47.41l-0.45-0.676l-0.676,0.676l0.676,0.676l0.45-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M73.086,26.69h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M73.086,14.978h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.5,23.762h1.352l0.45-0.676l0.676,0.676l-0.676,0.676l-0.45-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.229,23.762h-1.126l-0.676-0.676l-0.45,0.676l0.45,0.676l0.676-0.676   " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M91.555,26.69h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M91.555,14.978h2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M81.193,23.762h1.127l0.676-0.676l0.449,0.676l-0.449,0.676   l-0.676-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M85.924,23.762h-1.352l-0.451-0.676l-0.676,0.676l0.676,0.676   l0.451-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M110.248,26.69h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M110.248,14.978h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M99.662,23.762h1.352l0.45-0.676l0.676,0.676l-0.676,0.676l-0.45-0.676" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.393,23.762h-1.239l-0.676-0.676l-0.338,0.676l0.338,0.676   l0.676-0.676" />
        </g>
        <g id="Group_Dehydration_Inlet">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="20.6626" y1="32.9573" x2="17.1574" y2="13.0784">
                <stop offset="0.0057" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,16.104h32.545V11.6h4.504v18.468H0V16.104z" />
        </g>
        <g id="Group_Display">
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M9.234,16.78v12.838H1.014" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M9.234,16.78H1.014v12.838" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M32.32,16.78v12.838h-8.108" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M32.32,16.78h-8.108v12.838" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M23.761,16.78v12.838H9.91" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M23.761,16.78H9.91v12.838" />
            <path fill="#B2B2B2" d="M4.729,28.491h24.888V26.69H4.729V28.491z" />
            <path fill="#E5E5E5" stroke="#7F7F7F" stroke-width="0.25" d="M4.729,23.762h24.888v2.928H4.729V23.762z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.234,16.104v-1.126h3.941v1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.635,16.104v-1.126h4.054v1.126" />
        </g>
        <g id="Group_Level_Monitors">
            <path fill="#666666" d="M48.761,2.816h4.504v6.419h-4.504V2.816z" />
            <path fill="#666666" d="M67.229,2.816h4.729v6.419h-4.729V2.816z" />
            <path fill="#666666" d="M85.924,2.816h4.504v6.419h-4.504V2.816z" />
            <path fill="#666666" d="M104.393,2.816h4.616v6.419h-4.616V2.816z" />
        </g>
        <g id="Group_Level_Indicators">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,13.852h4.054v2.252H1.014V13.852z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M49.887,1.014h2.252v6.982h-2.252V1.014z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M68.355,1.014h2.479v6.982h-2.479V1.014z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,1.014h2.253v6.982h-2.253V1.014z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M105.518,1.014h2.365v6.982h-2.365V1.014z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M41.554,0h4.279v2.365h-4.279V0z" />
        </g>
        <g id="Group_Support_Structure">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,30.068v8.108l-1.126,1.352h2.928l-1.126-1.352v-8.108H37.049   z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M55.518,30.068v8.108l-1.126,1.352h2.928l-1.126-1.352v-8.108H55.518   z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M74.212,30.068v8.108l-1.126,1.352h2.928l-1.127-1.352v-8.108H74.212   z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M92.68,30.068v8.108l-1.125,1.352h2.928l-1.127-1.352v-8.108H92.68z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M110.698,30.068v8.108l-1.126,1.352h2.928l-1.126-1.352v-8.108   H110.698z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M23.761,30.068v8.108l-1.352,1.352h2.928l-1.126-1.352v-8.108H23.761   z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M9.234,30.068v8.108l-1.239,1.352h2.928L9.91,38.176v-8.108H9.234z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0057, 0.7916666666666666, 0.0, 0.5, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0057, 0.7916666666666666, 0.0, 0.5, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_BackGround",[0.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Level_Monitors",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Level_Indicators",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Support_Structure",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
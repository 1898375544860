import { SVGObject } from "../svgobject";

export class SVGMaterial7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 56.873" enable-background="new 0 0 112.5 56.873" xml:space="preserve">
        <g id="Group_Dryer_Cooler_Lower">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M0,19.371h112.5v2.252h-4.842l-6.982,16.553H9.234L0,26.353V19.371z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M0,18.245h112.5v1.126H0V18.245z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M53.941,37.052H9.91L1.126,25.677v-5.18h52.815V37.052" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M55.067,37.052h44.481l7.658-16.555h-52.14V37.052" />
        </g>
        <g id="Group_Dryer_Cooler_Upper">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,18.245v-1.352h6.982l4.73-15.09h86.712l6.981,15.09h7.095v1.352H0   z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="17.5693" x2="56.1938" y2="2.2539">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.78" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#7F7F7F" stroke-width="0.25" d="M12.162,2.254L7.658,17.569h97.072L97.748,2.254H12.162z" />
            <path fill="#CC9933" stroke="#4C4C4C" stroke-width="0.25" d="M0,17.569h112.5v0.676H0V17.569z" />
        </g>
        <g id="Group_Dryer_SQuare">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1512" d="M86.036,0.002h4.729v1.729h-4.729V0.002z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1512" d="M41.554,0.002h4.729v1.729h-4.729V0.002z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1512" d="M64.302,0.002h4.729v1.729h-4.729V0.002z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1512" d="M18.694,0.002h4.729v1.729h-4.729V0.002z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M9.234,23.875h4.729v4.73H9.234V23.875z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M30.405,23.875h4.73v4.73h-4.73V23.875z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M58.445,23.875h4.73v4.73h-4.73V23.875z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M81.982,23.875h4.729v4.73h-4.729V23.875z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M18.694,11.713h4.729v4.73h-4.729V11.713z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M51.464,11.713h4.73v4.73h-4.73V11.713z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M88.964,11.713h4.729v4.73h-4.729V11.713z" />
        </g>
        <g id="Group_Dryer_Cooler_Base">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M40.428,38.177h45.608v14.641H40.428V38.177z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M35.135,38.177l2.365,5.856h6.982l2.252-5.856H35.135z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M79.729,38.177l2.253,5.856h6.981l2.478-5.856H79.729z" />
        </g>
        <g id="Group_Vent">
            <radialGradient id="SVGID_2_" cx="11.5986" cy="26.353" r="1.7465" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M11.712,24.551l1.126,0.563l0.45,0.901v0.338l-0.45,1.239l-1.126,0.563l-1.014-0.338l-0.676-0.788   L9.91,26.353l0.338-0.901l0.789-0.788L11.712,24.551z" />
            <radialGradient id="SVGID_3_" cx="32.7705" cy="26.353" r="1.7463" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M32.658,24.551l1.126,0.338l0.563,0.788l0.113,0.676l-0.451,1.239l-0.901,0.563h-0.45l-1.126-0.563   l-0.338-0.901l-0.113-0.338l0.451-1.239L32.658,24.551z" />
            <radialGradient id="SVGID_4_" cx="60.9229" cy="26.353" r="1.8018" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <circle fill="url(#SVGID_4_)" cx="60.924" cy="26.353" r="1.802" />
            <radialGradient id="SVGID_5_" cx="84.3477" cy="26.353" r="1.746" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M84.459,24.551l1.127,0.563l0.45,0.901v0.338l-0.45,1.239l-1.127,0.563l-1.125-0.338l-0.563-0.788   l-0.112-0.676l0.449-1.239l0.901-0.45L84.459,24.551z" />
            <radialGradient id="SVGID_6_" cx="21.0586" cy="14.0781" r="1.6892" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" d="M20.946,12.389l1.126,0.225l0.675,1.014v0.338l-0.225,1.013l-1.126,0.789h-0.451l-1.126-0.451   l-0.451-1.013v-0.338l0.451-1.126L20.946,12.389z" />
            <radialGradient id="SVGID_7_" cx="53.8291" cy="14.0781" r="1.6892" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M53.941,12.389l1.126,0.45l0.451,1.126l-0.225,1.013l-1.014,0.789h-0.338l-1.126-0.226l-0.676-1.238   v-0.338l0.451-1.126l0.901-0.45H53.941z" />
            <radialGradient id="SVGID_8_" cx="91.3281" cy="14.0781" r="1.6895" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.55" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <path fill="url(#SVGID_8_)" d="M91.441,12.389l1.126,0.45l0.45,1.126l-0.225,1.013l-1.014,0.789h-0.338l-1.126-0.226l-0.676-1.238   v-0.338l0.45-1.126l0.901-0.45H91.441z" />
        </g>
        <g id="Group_Dryer_Suspension">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,40.43h5.855v0.676H94.82V40.43z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,47.187l5.855-0.451v0.901l-5.855,0.45V47.187z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,45.61l5.855-0.451v0.676l-5.855,0.451V45.61z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,43.808l5.855-0.45v0.901l-5.855,0.45V43.808z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,42.007l5.855-0.451v0.9l-5.855,0.451V42.007z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,45.61l5.855,1.125v0.901l-5.855-1.126V45.61z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,43.808l5.855,1.352v0.676l-5.855-1.126V43.808z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,42.231l5.855,1.126v0.901l-5.855-1.352V42.231z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M94.82,40.43l5.855,1.126v0.9l-5.855-1.125V40.43z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="91.8926" y1="52.1416" x2="103.6035" y2="52.1416">
                <stop offset="0" style="stop-color:#B7B7B7" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#B7B7B7" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.893,56.871l2.477-9.46h6.982l2.252,9.46H91.893z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M93.018,47.411h9.46v1.127h-9.46V47.411z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="93.0176" y1="35.7568" x2="102.4775" y2="35.7568">
                <stop offset="0" style="stop-color:#B7B7B7" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#B7B7B7" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M93.018,40.43h9.46v-0.45h-1.126v-8.896h-4.73v1.576h-2.252   v7.32h-1.352V40.43z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,40.43h5.855v0.676h-5.855V40.43z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,47.187l5.855-0.451v0.901l-5.855,0.45V47.187z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,45.61l5.855-0.451v0.676l-5.855,0.451V45.61z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,43.808l5.855-0.45v0.901l-5.855,0.45V43.808z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,42.007l5.855-0.451v0.9l-5.855,0.451V42.007z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,45.61l5.855,1.125v0.901l-5.855-1.126V45.61z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,43.808l5.855,1.352v0.676l-5.855-1.126V43.808z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,42.231l5.855,1.126v0.901l-5.855-1.352V42.231z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M16.892,40.43l5.855,1.126v0.9l-5.855-1.125V40.43z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="13.9639" y1="52.1416" x2="25.6758" y2="52.1416">
                <stop offset="0" style="stop-color:#B7B7B7" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#B7B7B7" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M13.964,56.871l2.478-9.46h6.982l2.252,9.46H13.964z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M15.09,47.411h9.459v1.127H15.09V47.411z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="15.0903" y1="35.7568" x2="24.5493" y2="35.7568">
                <stop offset="0" style="stop-color:#B7B7B7" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#B7B7B7" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M15.09,40.43h9.459v-0.45h-1.126v-8.896h-4.729v1.576h-2.252   v7.32H15.09V40.43z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.78, 0.046875, 243.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.25, 191.0, 0.55, 0.25, 191.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.5729166666666666, 109.0, 0.5, 0.25, 191.0, 1.0, 0.5729166666666666, 109.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.5729166666666666, 109.0, 0.5, 0.25, 191.0, 1.0, 0.5729166666666666, 109.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.5729166666666666, 109.0, 0.5, 0.25, 191.0, 1.0, 0.5729166666666666, 109.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.5729166666666666, 109.0, 0.5, 0.25, 191.0, 1.0, 0.5729166666666666, 109.0]);
		this.fillGradient(this.fills,"Group_Dryer_Cooler_Lower",[0.0, 0.609375, 99.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Dryer_Cooler_Upper",[0.0, 0.40625, 151.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Dryer_SQuare",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0, 8.0, 0.8072916666666666, 49.0, 9.0, 0.8072916666666666, 49.0, 10.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Dryer_Cooler_Base",[0.0, 0.40625, 151.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Dryer_Suspension",[0.0, 0.9895833333333334, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.20833333333333334, 201.0, 6.0, 0.20833333333333334, 201.0, 7.0, 0.20833333333333334, 201.0, 8.0, 0.20833333333333334, 201.0, 9.0, 0.40625, 151.0, 10.0, 0.9895833333333334, 0.0, 11.0, 0.8072916666666666, 49.0, 12.0, 0.8072916666666666, 49.0, 13.0, 0.8072916666666666, 49.0, 14.0, 0.8072916666666666, 49.0, 15.0, 0.20833333333333334, 201.0, 16.0, 0.20833333333333334, 201.0, 17.0, 0.20833333333333334, 201.0, 18.0, 0.20833333333333334, 201.0, 19.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 33.559" enable-background="new 0 0 112.5 33.559" xml:space="preserve">
        <g id="Group_Clarifier">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.3896" y1="16.6487" x2="112.0459" y2="16.6487" gradientTransform="matrix(1 0 0 -1 0 33.5591)">
                <stop offset="0.01" style="stop-color:#808FA6" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#808FA6" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.2" d="M56.903,0.857c-24.792,0-46.228,3.649-56.514,8.95   c0,4.743,0,9.477,0,14.206c10.287,5.309,31.723,8.951,56.514,8.951c23.738,0,44.398-3.347,55.143-8.281c0-4.944,0-10.597,0-15.539   C101.3,4.205,80.642,0.857,56.903,0.857z" />
        </g>
        <g id="Group_InnerTank">
            <ellipse fill="#596D8C" cx="56.194" cy="9.685" rx="51.013" ry="8.446" />
            <path fill="#263A59" d="M5.405,10.698L5.18,10.023V9.798V9.347l3.041-2.478l3.041-1.126l4.054-1.014l3.153-0.676l3.378-0.563   l3.829-0.563l4.054-0.45l4.392-0.338l4.504-0.338l4.842-0.225l4.955-0.226h2.59l2.59-0.112h2.59l5.18,0.112l5.067,0.112   l4.955,0.226l4.617,0.225l4.504,0.451l4.167,0.338l3.941,0.563l3.604,0.45l3.267,0.676l4.279,1.014l3.49,1.126l2.928,1.577   l1.014,1.238v0.451v0.225l-0.112,0.45l-0.112,0.226v-0.45l-3.041-2.365L100.9,6.757l-4.054-1.014l-3.04-0.563l-3.491-0.563   L86.6,4.054l-4.055-0.45l-4.279-0.338l-4.617-0.338l-4.842-0.225L63.964,2.59l-5.181-0.112h-5.18l-5.18,0.112l-4.842,0.113   l-4.842,0.225l-4.617,0.338l-4.279,0.338l-4.054,0.45l-3.716,0.563l-3.491,0.563l-3.041,0.563l-4.054,1.014L8.446,7.883   L5.631,9.798L5.405,10.698z" />
        </g>
        <g id="Group_MiddleCircle">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="37.6128" y1="11.1487" x2="74.7754" y2="11.1487">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M74.775,10.135V9.009H37.613l0.112,1.464l3.153,1.465   l3.491,0.675l4.617,0.451l3.491,0.112l1.802,0.112h3.828l3.604-0.112l3.266-0.226l4.279-0.563l3.266-0.788l2.14-1.127   L74.775,10.135z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="37.6128" y1="9.0095" x2="74.7754" y2="9.0095">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <ellipse fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" cx="56.194" cy="9.01" rx="18.582" ry="3.041" />
        </g>
        <g id="Group_CrossRailsbase">
            <path fill="#7F4C19" d="M70.045,6.869l1.576,0.338L87.5,3.604V3.041L70.045,6.869z" />
            <path fill="#7F4C19" d="M42.455,6.869l-1.689,0.338L25,3.604V3.041L42.455,6.869z" />
            <path fill="#7F4C19" d="M5.18,9.798l0.113,0.563h32.77V9.798H5.18z" />
            <path fill="#7F4C19" d="M107.207,9.798l-0.112,0.563H74.324V9.798H107.207z" />
            <path fill="#7F4C19" d="M85.698,16.78l-1.915,0.112L64.189,12.5v-0.676L85.698,16.78z" />
            <path fill="#7F4C19" d="M26.802,16.78l1.915,0.112L48.198,12.5v-0.676L26.802,16.78z" />
            <path fill="#CCB299" d="M56.194,0.45c-30.786,0-55.743,4.135-55.743,9.234c0,5.101,24.957,9.234,55.743,9.234   c30.787,0,55.744-4.134,55.744-9.234C111.938,4.585,86.98,0.45,56.194,0.45z M56.194,18.131C28.02,18.131,5.18,14.35,5.18,9.685   c0-4.664,22.839-8.445,51.014-8.445c28.173,0,51.013,3.781,51.013,8.445C107.207,14.35,84.367,18.131,56.194,18.131z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,0.45c-30.786,0-55.743,4.135-55.743,9.234   c0,5.101,24.957,9.234,55.743,9.234c30.787,0,55.744-4.134,55.744-9.234C111.938,4.585,86.98,0.45,56.194,0.45z M56.194,18.131   C28.02,18.131,5.18,14.35,5.18,9.685c0-4.664,22.839-8.445,51.014-8.445c28.173,0,51.013,3.781,51.013,8.445   C107.207,14.35,84.367,18.131,56.194,18.131z" />
            <polygon fill="#CCB299" points="107.207,9.235 59.922,9.235 87.387,3.041 84.347,2.59 56.757,8.801 56.757,1.126 55.631,1.126    55.631,8.787 28.153,2.59 25,3.041 52.465,9.235 5.18,9.235 5.18,9.798 52.326,9.798 23.311,16.329 26.464,16.78 55.631,10.202    55.631,18.356 56.757,18.356 56.757,10.202 85.924,16.78 89.076,16.329 60.115,9.798 107.207,9.798  " />
            <ellipse fill="#CCB299" stroke="#7F4C19" stroke-width="0.2" cx="56.193" cy="9.01" rx="13.965" ry="2.252" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.8697916666666666, 33.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8697916666666666, 33.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_CrossRailsbase",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
import { Property } from "./property";
import tag from "@/model/tag";

export class RotationProperty extends Property{
    rotationanglemin:number;
    rotationanglemax:number;
    rotationvaluemin:number;
    rotationvaluemax:number;
    pivotx:number;
    pivoty:number;

    public getAngle(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return 0;
        const angle:number = +tag.tagvalue *
        (this.rotationanglemax-this.rotationanglemin)/
        (this.rotationvaluemax-this.rotationvaluemin);
       return angle;   
    }
    
}
import { SVGObject } from "../svgobject";

export class SVGMedical12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" id="Group_x5F_RedCross" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" xml:space="preserve" enable-background="new 0 0 112.5 112.5">
        <g id="Group_RedCross">
        <path d="M37.275,37.275V0h37.838v37.275H112.5v37.838H75.113V112.5H37.275V75.113H0V37.275H37.275z" fill="#CC0000" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
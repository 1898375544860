import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { TextView } from './text'
import { Digital4DigitMeter } from './digital4digitmeter';
import historyandeventsModule from '@/store/historyandevents.module';
import { Value } from '../value';

export class DigitalMeterHistoryAndEvent extends Digital4DigitMeter{

  public drawObject():void{
		super.initObject();

		let bordercolor:string = this.bordercolor;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					bordercolor = linecolorproperty.getColor(this.tagnames, this.bordercolor)
		}	
    const bordercolor2 = ColorUtils.convertformat(bordercolor)

    let textcolor:string = this.textcolor;
		const textcolorprop = this.properties['textcolor'];
		if (textcolorprop!=null){	
			const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
			if (textcolorproperty!=null) 
					textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
		}	
        const textcolor2 = ColorUtils.convertformat(textcolor)

    let value:number
    let valueproperty:ValueProperty
    let decimalpos = 0
		const valueprop = this.properties['value'];
		if (valueprop!=null){	
			valueproperty =Object.assign(new ValueProperty(), valueprop);
			if (valueproperty!=null) {
        value = valueproperty.getTagValue(this.tagnames)
        const tag = valueproperty.getTag(this.tagnames)
              if (tag!=null){
                this.node.on('mousedown touchstart', function () {
                    historyandeventsModule.setEventsAndHistoryTagname(tag.name)
                    historyandeventsModule.setEventsAndHistorygEnabled(true)
                });
              }
        decimalpos = valueproperty.decimalpos
        }
		}	
 
    let fillcolor = this.fillcolor;
    const fillprop = this.properties==null?null:this.properties['fillcolor'];
    if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }	
    const fillcolor2 = ColorUtils.convertformat(fillcolor)

    
    const polygon = new Konva.Line({
        points: [this.width/3, this.height/3,
                this.width/2, 0,
                this.width, 0,
                this.width, this.height/3],
        closed: true,
        strokeWidth: 1,
        stroke: bordercolor2
    })
    if(this.type3d==0){
        polygon.fillLinearGradientStartPoint({ x: 0, y: 0 });
        polygon.fillLinearGradientEndPoint({ x: 0, y: this.height/3 });
        polygon.fillLinearGradientColorStops([0, bordercolor2, 
          1, ColorUtils.darkColor(bordercolor2,0.75)])
    }else{
        polygon.fill(bordercolor2)
    }
    this.node.add(polygon)

    const rect3 = new Konva.Rect({
      x: 0,
      y: this.height/3-1,
      width: this.width,
      height: this.height*2/3,
      fill: fillcolor2
    });
  this.node.add(rect3)

    const labelTextOb = new TextView()
    labelTextOb.width = this.width*3/4-this.width/20
    labelTextOb.height = this.height/4
    labelTextOb.text = this.text;
    labelTextOb.fontsize = this.height/4
    labelTextOb.posx = this.width/3+this.width/20
    labelTextOb.posy = this.height/4-this.height/5
    labelTextOb.textplacement = 1
    labelTextOb.textcolor = fillcolor2
    labelTextOb.useborder = false
    labelTextOb.bordercolor = null
    labelTextOb.linewidth = 0
    labelTextOb.fill = false
    labelTextOb.fillcolor = fillcolor2
    labelTextOb.underline = false
    labelTextOb.initLayer(this.layer)
    labelTextOb.drawObject()
    this.node.add(labelTextOb.rotatednode)

    const node1:Konva.Group = new Konva.Group();
    if(value != null){
      const digits= this.drawDigits6(this.width, this.height*2/3, value, decimalpos, textcolor2)
          digits.absolutePosition({
            x: 0,
            y: this.height/3-1
          });
      node1.add(digits);
    }
    
    this.node.add(node1)
  }

  /*public setField(field:string, value:Value){
    super.setField(field, value)
    switch(field){
        case "side": {this[field] = value.value;this.rotatednode=null;break;}
    }
    this.drawObject();
    }

  public getField(field:string):Value{
    const value = super.getField(field)
    switch(field){
        case "side": {value.datatype=1; value.value = this[field];break}
    }
    return value;
  }*/

  private drawDigits6(width:number, height:number, value: number, decimalpos:number, color:string):Konva.Group{
    const digits = new Konva.Group()
    console.log('decimalpos', decimalpos)
    const numb = value.toFixed(decimalpos).toString().split('')
    let j=6;
    const length = numb.includes('.' ||',')? numb.length-1 : numb.length
    for(let i=numb.length-1; i>=length-6; i--){
     
      let chdigit = 'N'
      if(i>=0) chdigit =numb[i]
        if (i!=numb.length-1){
        const dot = new Konva.Rect({
          x: width*(50+60*(j-1))/350,
          y: height*9/10,
          width: width/50,
          height: height/10,
        })
       
        if(chdigit=='.' || chdigit==','){
          dot.fill(color)  
         i=i-1
         chdigit=numb[i]
        }else{
          dot.fill(ColorUtils.darkColor(color,0.15))  
        }
        digits.add(dot)
      }

        j--
      const dig = this.drawDigit(width*5/37, height, chdigit, color)
      dig.absolutePosition({
        x: width*6*j/35,
        y: 0
      })
      digits.add(dig)
    }
    return digits
  }
  
}
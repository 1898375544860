import { SVGObject } from "../svgobject";

export class SVGTank66 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 76.125" enable-background="new 0 0 112.5 76.125" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.2505" y1="66.6663" x2="56.2505" y2="10.4734">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M105.518,10.473c4.04,0,6.982,23.423,6.982,23.423v9.348c0,0-3.648,23.423-6.982,23.423H6.87   C1.985,63.009,0,43.243,0,43.243v-9.348c0,0,1.647-23.423,6.869-23.423" />
        </g>
        <g id="Group_Top">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="43.2432" y1="0.5071" x2="69.1445" y2="0.5071">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M43.243,0h25.901v1.014H43.243V0z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="42.1172" y1="7.3196" x2="70.7207" y2="7.3196">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M70.721,0.563v10.586c0,0-3.887,2.928-14.471,2.928c-10.625,0-14.133-2.928-14.133-2.928V0.563   H70.721z" />
        </g>
        <g id="Group_Fill">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="56.1934" y1="43.2434" x2="56.1934" y2="66.6663">
                <stop offset="0" style="stop-color:#99CCCC" />
                <stop offset="0.22" style="stop-color:#82B5B5" />
                <stop offset="0.68" style="stop-color:#467979" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M9.347,43.243h93.693v23.423H9.347V43.243z" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="22.1851" y1="47.9729" x2="22.1851" y2="10.4734">
                <stop offset="0" style="stop-color:#99CCCC" />
                <stop offset="0.22" style="stop-color:#82B5B5" />
                <stop offset="0.68" style="stop-color:#467979" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M18.582,10.473h7.207v37.5h-7.207V10.473" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="90.0898" y1="47.9729" x2="90.0898" y2="10.4734">
                <stop offset="0" style="stop-color:#99CCCC" />
                <stop offset="0.22" style="stop-color:#82B5B5" />
                <stop offset="0.68" style="stop-color:#467979" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M86.6,10.473h6.981v37.5H86.6V10.473" />
            <path fill="#669999" d="M18.582,47.973l-2.252,28.152h11.711l-2.252-28.152H18.582z" />
            <path fill="#669999" d="M86.6,47.973l-2.253,28.152h11.712l-2.478-28.152H86.6z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5364583333333334, 117.0, 0.22, 0.71875, 71.0, 0.68, 0.8072916666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.5364583333333334, 117.0, 0.22, 0.71875, 71.0, 0.68, 0.8072916666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.5364583333333334, 117.0, 0.22, 0.71875, 71.0, 0.68, 0.8072916666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_FrontLegs",[0.0, 0.9375, 15.0, 1.0, 0.9375, 15.0]);
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFinish18 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 108.893 112.5" enable-background="new 0 0 108.893 112.5" xml:space="preserve">
        <g id="Group_Cylinder2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="10.9229" y1="45.834" x2="15.9902" y2="45.834">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M15.99,37.951v15.765h-5.067V37.951" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="14.6392" y1="37.9512" x2="14.6392" y2="32.9961">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M10.923,37.951v-4.955h7.433v4.955H15.99" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="7.3193" y1="78.8281" x2="19.4814" y2="78.8281">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M7.319,103.939V53.716h12.162v50.223H7.319" />
        </g>
        <g id="Group_Cleaner">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="79.0459" y1="77.9434" x2="60.9973" y2="28.3553">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.32" style="stop-color:#6C6C6C" />
                <stop offset="0.46" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.776,24.438h-7.432v19.594H55.065v-9.91h-9.909v39.188   h46.62V24.438z" />
            <path fill="#666666" d="M86.821,34.122h2.478v39.188h-2.478V34.122z" />
            <path fill="#666666" d="M52.588,34.122h2.477v39.188h-2.477V34.122z" />
        </g>
        <g id="Group_Container">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M45.156,39.077h-2.478V4.844l2.478,2.252V39.077z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M44.03,34.122h48.872v-9.684H44.03V34.122z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M20.833,31.645h-2.477v58.782h2.477V31.645z" />
        </g>
        <g id="Group_Label">
            <path fill="#B2B27F" d="M60.021,29.167h17.117v-4.729H60.021V29.167z" />
        </g>
        <g id="Group_Base_Support">
            <path fill="#CCCCCC" d="M20.833,112.498h88.06v-4.955h-88.06V112.498z" />
            <path fill="#4C4C4C" d="M20.833,111.26h88.06v-2.365h-88.06V111.26z" />
        </g>
        <g id="Group_Part_Washer">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="39.5278" y1="40.2227" x2="26.2335" y2="3.6967">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="0.39" style="stop-color:#BDBDBD" />
                <stop offset="0.56" style="stop-color:#CACACA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.085,39.077h19.594V4.844H23.085V39.077z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="85.877" y1="115.0176" x2="55.5734" y2="31.7593">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="0.39" style="stop-color:#BDBDBD" />
                <stop offset="0.57" style="stop-color:#CACACA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.472,108.895V39.077h17.117v34.233h36.71V39.077h17.116   v68.466h-22.07v1.352H35.472z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.833,112.498h88.06v-4.955h-88.06V112.498" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="41.2041" y1="105.4561" x2="15.0987" y2="33.732">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="0.39" style="stop-color:#BDBDBD" />
                <stop offset="0.57" style="stop-color:#CACACA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M20.833,31.645h14.639v75.898H20.833V31.645z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="103.9697" y1="110.6855" x2="90.6156" y2="73.9953">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="0.39" style="stop-color:#BDBDBD" />
                <stop offset="0.56" style="stop-color:#CACACA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.695,75.787h23.197v33.107H85.695V75.787z" />
        </g>
        <g id="Group_Tank">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="58.6689" y1="13.4023" x2="78.2627" y2="13.4023">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M58.669,24.438V2.367h19.594v22.071H58.669" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="57.543" y1="1.7471" x2="79.3896" y2="1.7471">
                <stop offset="0" style="stop-color:#262626" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#262626" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M57.543,1.128H79.39v1.239H57.543V1.128z" />
        </g>
        <g id="Group_Filter">
            <path fill="#E5E5E5" stroke="#666666" stroke-width="0.25" d="M60.021,90.427h24.324v19.594H47.634V85.473h12.387V90.427z" />
            <path fill="#B2B2B2" d="M58.669,91.778h24.549v17.116H48.985V86.823h9.684V91.778z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M57.543,101.463l6.081-6.081l1.126,1.013l-3.604,3.941l-4.954-4.954" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M62.498,94.03l-6.306,6.306l-1.127-1.352l3.604-3.603l4.955,4.954" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M69.705,106.417l6.081-6.081l1.352,1.127l-3.829,3.604l-4.729-4.73" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M74.659,98.984l-6.08,6.082l-1.352-1.127l3.604-3.604l4.955,4.73" />
        </g>
        <g id="Group_Supply">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M97.856,52.59V37.951" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M96.731,58.671V52.59h2.252" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M96.731,58.671h2.252V52.59" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M36.598,84.346h22.071" />
        </g>
        <g id="Group_Indicator">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="59.9072" y1="83.2197" x2="59.9072" y2="74.4365">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#616161" />
                <stop offset="1" style="stop-color:#616161" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.2" d="M57.543,74.437h4.729v8.783h-4.729V74.437z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="81.9795" y1="83.2197" x2="81.9795" y2="74.4365">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#616161" />
                <stop offset="1" style="stop-color:#616161" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.2" d="M79.614,74.437h4.73v8.783h-4.73V74.437z" />
        </g>
        <g id="Group_Fittings">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M15.99,46.284h2.365v2.478H15.99V46.284z" />
            <path fill="#666666" d="M19.481,84.346h1.351v4.955h-1.351V84.346z" />
            <path fill="#666666" d="M19.481,34.122h1.351v4.955h-1.351V34.122z" />
            <path fill="#666666" d="M19.481,53.716h1.351v4.955h-1.351V53.716z" />
            <path fill="#666666" d="M19.481,59.797h1.351v4.955h-1.351V59.797z" />
        </g>
        <g id="Group_Tank2">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M25.562,0.002H44.03v1.126H25.562V0.002z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M28.04,1.128h13.513v3.716H28.04V1.128z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="18.4116" y1="108.8945" x2="18.4116" y2="98.9844">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M20.833,108.895H15.99v-9.91h4.842V108.895" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="7.9951" y1="106.417" x2="7.9951" y2="101.4629">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M15.99,106.417H0v-4.954h15.99V106.417" />
        </g>
        <g id="Group_Connector">
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="9.7969" y1="93.4678" x2="12.3872" y2="93.4678">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.28" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#D6D6D6" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M12.387,85.473v15.99h-2.59v-15.99H12.387z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="15.3149" y1="85.6973" x2="15.3149" y2="83.2197">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.28" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#D6D6D6" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M20.833,85.697H9.797V83.22h11.036V85.697z" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
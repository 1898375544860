import { SVGObject } from "../svgobject";

export class SVGHvac6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 83.672" enable-background="new 0 0 112.5 83.672" xml:space="preserve">
        <g id="Group_Extended_Area">
            <path fill="#525252" d="M15.653,30.969h81.081v1.126H15.653V30.969z" />
            <path fill="#525252" d="M100.113,64.977v15.766h-1.803V64.977H100.113z" />
        </g>
        <g id="Group_Dehumidifier_sub">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="86.2275" y1="93.8093" x2="25.9348" y2="21.9553">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.4" style="stop-color:#737373" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#A6A6A6" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M13.852,32.094h84.459v51.576H13.852V32.094z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.852,32.094h84.459v51.576H13.852V32.094" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.617,34.346h16.441v47.072H79.617V34.346" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.212,34.346h25.675v47.072H49.212V34.346" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M23.311,34.346h21.171v47.072H23.311V34.346" />
        </g>
        <g id="Group_Support_Structures">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M2.252,33.22h11.599v50.45H2.252V33.22z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="6.9258" y1="83.6707" x2="6.9258" y2="56.6443">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.4" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,56.644h13.852v27.026H0V56.644z" />
            <path fill="#4C4C4C" d="M0,56.644h13.852l-0.338,1.352h-12.5v4.054h12.5v1.802h-12.5v4.729h12.5v2.253h-12.5v11.711L0,83.671   V56.644z" />
            <path fill="#B2B2B2" d="M1.014,61.374h12.5V62.5h-12.5V61.374z" />
            <path fill="#B2B2B2" d="M1.014,67.229h12.5v2.478h-12.5V67.229z" />
        </g>
        <g id="Group_Control_Box">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="56.8311" y1="31.9456" x2="29.205" y2="-0.9779">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.4" style="stop-color:#737373" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#A6A6A6" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M28.041,0h29.956v30.969H28.041V0z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M19.707,30.969l8.333-6.982V22.86l-9.459,8.108H19.707z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M66.104,30.969l-8.107-6.982V22.86l9.233,8.108H66.104z" />
        </g>
        <g id="Group_Supply">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M82.095,21.509h11.036v9.46H82.095V21.509z" />
        </g>
        <g id="Group_Coupling">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M98.311,48.986l2.928-2.252v1.802h2.479V35.022h-2.479v1.802   l-2.928-1.802V48.986z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M103.041,47.41l7.094-2.478V42.68h-7.094V47.41z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="108.1084" y1="42.6804" x2="108.1084" y2="36.8245">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M103.717,36.824h8.783v5.856h-8.783V36.824z" />
        </g>
        <g id="Group_Display">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M89.076,26.915l-1.125-1.352v-1.577l1.125-1.126h1.577l1.351,1.126   v1.577l-1.351,1.352H89.076z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,26.915l-1.126-1.352v-1.577l1.126-1.126h1.802l1.127,1.126   v1.577l-1.127,1.352H84.347z" />
            <path fill="#CCCCCC" d="M38.626,12.725h10.586v6.981H38.626V12.725z" />
            <path fill="#CCCCCC" d="M30.968,35.022h5.856v1.802h-5.856V35.022z" />
            <path fill="#CCCCCC" d="M59.121,35.022h5.856v1.802h-5.856V35.022z" />
            <path fill="#CCCCCC" d="M85.022,35.022h5.856v1.802h-5.856V35.022z" />
            <path fill="#CCCCCC" d="M83.672,28.041H86.6v1.126h-2.928V28.041z" />
            <path fill="#CCCCCC" d="M88.4,28.041h2.928v1.126H88.4V28.041z" />
        </g>
        <g id="Group_Buttons">
            <circle fill="#525252" cx="62.049" cy="70.721" r="2.252" />
            <path fill="#525252" d="M43.356,17.455h1.126v1.126h-1.126V17.455z" />
            <path fill="#525252" d="M46.734,13.851h1.126v1.464h-1.126V13.851z" />
            <path fill="#525252" d="M43.356,13.851h1.126v1.464h-1.126V13.851z" />
            <path fill="#525252" d="M39.752,13.851h1.126v1.464h-1.126V13.851z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8958333333333334, 0.0, 0.4, 0.8958333333333334, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.703125, 75.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.4, 0.7916666666666666, 0.0, 0.6, 1.0, 0.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8958333333333334, 0.0, 0.4, 0.8958333333333334, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.703125, 75.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Extended_Area",[0.0, 0.6354166666666666, 0.0, 1.0, 0.6354166666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support_Structures",[0.0, 0.9895833333333334, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Control_Box",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Supply",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Coupling",[0.0, 0.5885416666666666, 0.0, 1.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
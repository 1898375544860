import { SVGObject } from "../svgobject";

export class SVGTank64 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 82.547 112.5" enable-background="new 0 0 82.547 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="11.2627" y1="3.3345" x2="71.2856" y2="3.3345">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M11.263,2.59h60.023v1.489H11.263V2.59z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.0015" y1="5.2007" x2="82.5464" y2="5.2007">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0.001,6.572h82.545l-3.829-2.743H3.493L0.001,6.572" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0015" y1="32.022" x2="82.5464" y2="32.022">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0.001,6.419V47.86c0,0,12.245,9.765,40.897,9.765c29.396,0,41.647-9.765,41.647-9.765V6.419H0.001" />
            
                <radialGradient id="SVGID_4_" cx="41.2739" cy="62.2461" r="30.8606" gradientTransform="matrix(1 0 0 1.2181 0 -13.9893)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M0.001,47.431l33.671,28.807h15.089l33.784-28.807" />
        </g>
        <g id="Group_Layer_4">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="35.4746" y1="0.7441" x2="46.7358" y2="0.7441">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M35.475,1.489h11.261V0H35.475V1.489z" />
            <path fill="#333333" d="M16.781,2.84h48.874V1.239H16.781V2.84z" />
        </g>
        <g id="Group_FrontLegs">
            <path fill="#990000" d="M62.274,97.65c-6.093-0.245-19.817-0.757-19.817-0.757V76.238h-2.478v20.655c0,0-10.29,0.094-18.455,0.873   c-9.498,0.907-19.045,3.383-19.045,3.383v2.447c0,0,10.943-3.211,19.67-3.841c9-0.649,17.83-0.414,17.83-0.414v7.979h2.478v-7.979   c0,0,10.984-0.117,20.234,0.592c11.77,0.901,17.378,3.663,17.378,3.663v-2.447C80.069,101.149,69.358,97.936,62.274,97.65z" />
            <path fill="#BF2600" d="M77.592,45.158v5.856H4.844v-5.856H2.479v55.63v2.591v9.121h2.365v-7.888l0.225,0.117   c0,0,11.406,2.098,15.52,2.501c3.672,0.36,15.561,0.652,15.561,0.652h10.136c0,0,11.947-0.411,15.926-0.82   c3.809-0.392,15.381-2.446,15.381-2.446v7.884h2.477v-9.121v-2.591v-55.63H77.592z M60.44,105.167   c-9.297,0.82-28.166,0.667-37.333,0c-10.914-0.794-18.264-3.145-18.264-3.145V53.716h72.749v48.31   C77.592,102.026,68.94,104.417,60.44,105.167z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_FrontLegs",[0.0, 0.3958333333333333, 0.0, 1.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { SwitchControlProperty} from "./properties/switchcontrolproperty"
import Konva from "konva";
import { Switch } from './switch';
import { Value } from '../value';


export class SwitchApple extends Switch{
    clicktype:number
    
    public drawObject():void{
        super.initObject();
        if(this.clicktype != 0) this.clicktype = 1

        let sw = false;
        const switchprop = this.properties['switchcontrol'];
        let switchproperty:SwitchControlProperty;
        if (switchprop!=null){	
            switchproperty =Object.assign(new SwitchControlProperty(), switchprop);
            if (switchproperty!=null) 
                    sw = switchproperty.getCurrentBooleanValue(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);

        let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
        const rect = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                cornerRadius: this.height/2,
                stroke: fillcolor2,
                strokeWidth: 1
              });
              if(sw)rect.fill(fillcolor2)
              this.node.add(rect)
            
            if((sw && this.texton!=null) && this.texton!=""){
            const TextOn = new Konva.Text({
                x: this.height/8,
                y: this.height/4, 
                verticalAlign:"bottom",
                width: this.width-this.height/8,
                height: this.height/2,
                text: this.texton,
                fontSize: this.height/2,
                fontFamily: "Roboto",
                fontStyle: "normal",
                align:"left",
                fill: ColorUtils.convertformat(textcolor),
                });
                if(this.type3d==0){  
                  TextOn.shadowColor('black'),
                  TextOn.shadowBlur(0),
                  TextOn.shadowOffset({ x: 1, y: 1 })
                }
                  this.node.add(TextOn)
            }

            if((!sw && this.textoff!=null) && this.textoff!=""){
                const TextOff = new Konva.Text({
                x: 0,
                y: this.height/4, 
                verticalAlign:"bottom",
                width: this.width-this.height/8,
                height: this.height/2,
                text: this.textoff,
                fontSize: this.height/2,
                fontFamily: "Roboto",
                fontStyle: "normal",
                align:"right",
                fill: ColorUtils.convertformat(textcolor),
                });
                if(this.type3d==0){  
                  TextOff.shadowColor('black'),
                  TextOff.shadowBlur(0),
                  TextOff.shadowOffset({ x: 1, y: 1 })
                }
                this.node.add(TextOff) 
            }          
                            
              const circle = new Konva.Circle({
                x: !sw? this.height/2 : (this.width-this.height/2)-1,
                y: this.height/2,
                radius: (this.height/2)-1,
                fill: 'white'
              });
              this.node.add(circle)
              
            const TextLabel = new Konva.Text({
                x: !sw? 0: (this.width-this.height)-1,
                y: this.height/4, 
                verticalAlign:"bottom",
                width: this.height,
                height: this.height/2,
                text: this.text,
                fontSize: this.height/2,
                fontFamily: "Roboto",
                fontStyle: "normal",
                align:"center",
                fill: ColorUtils.convertformat(textcolor),
              });
              if(this.type3d==0){  
                TextLabel.shadowColor('black'),
                TextLabel.shadowBlur(0),
                TextLabel.shadowOffset({ x: 1, y: 1 })
              }
              this.node.add(TextLabel) 

        this.setFunctions();           
    }

    /*public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "clicktype": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "clicktype": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }*/

    protected setFunctions():void{
        const controlprop = this.properties['switchcontrol'];
        if (controlprop!=null){
          const controlproperty:SwitchControlProperty=Object.assign(new SwitchControlProperty(), controlprop);
          if (controlproperty!=null){
              this.node.on('mousedown touchstart', function () {
                controlproperty.setClick();
              });
              if (this.clicktype==1){
                this.node.on('mouseup touchend', function () {
                controlproperty.setReleased();
                });
              }
          }
        }
    }
}
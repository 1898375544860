import { SVGObject } from "../svgobject";

export class SVGFood14 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 59.796" enable-background="new 0 0 112.5 59.796" xml:space="preserve">
        <g id="Group_Sealer">
            <path fill="#666666" d="M18.582,55.742h77.476v-0.676H18.582V55.742z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="60.8955" y1="61.2661" x2="53.7451" y2="20.714">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M18.582,26.914h77.476v28.152H18.582V26.914z" />
            <path fill="#595959" d="M53.941,38.625h42.116v2.478H53.941V38.625z" />
            <path fill="#999999" d="M18.582,39.751H42.23v1.352H18.582V39.751z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="69.8096" y1="55.6245" x2="65.5503" y2="43.9224">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M60.924,45.607h13.513v8.334H60.924V45.607z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="83.8721" y1="55.5874" x2="79.6392" y2="43.9578">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" d="M75.112,45.607H88.4v8.334H75.112V45.607z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M73.535,50.337V49.21" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M75.788,49.21v1.127" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="58.4883" y1="38.2681" x2="56.1522" y2="25.0193">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.582,31.644h77.476" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.941,53.941h42.116" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M59.121,26.238h36.937v0.676H59.121V26.238z" />
        </g>
        <g id="Group_Mid_Tray">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="65.3379" y1="43.7368" x2="30.6085" y2="14.5954">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.2" d="M42.005,1.014h11.937v56.304H42.005V1.014" />
        </g>
        <g id="Group_Display_Unit">
            <path fill="#CCCCCC" d="M18.582,18.13h23.423v8.784H18.582V18.13z" />
            <path fill="#595959" d="M21.51,19.932h20.495v6.982H21.51V19.932z" />
            <path fill="#666666" d="M33.897,22.184h6.531v4.054h-6.531V22.184z" />
            <path fill="#7F7F7F" d="M22.186,21.058h6.531v5.856h-6.531V21.058z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M21.51,20.382h14.189v1.802" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.717,23.986h5.18" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M28.717,23.536h5.18" />
        </g>
        <g id="Group_Support_Structure">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M18.582,55.066v2.252h1.126v-2.252H18.582z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M19.257,57.318v1.352l-1.126,1.127h2.252l-1.126-1.127V57.318z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M94.932,55.066v2.252h1.126v-2.252H94.932z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M95.607,57.318v1.352l-1.352,1.127h2.478l-1.126-1.127V57.318z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,55.066v2.252h1.126v-2.252H40.879z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M41.554,57.318v1.352l-1.126,1.127h2.252l-1.126-1.127V57.318z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M53.716,55.066v2.252h1.351v-2.252H53.716z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M54.392,57.318v1.352l-1.126,1.127h2.252l-1.126-1.127V57.318z" />
        </g>
        <g id="Group_Tray_Feed">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="108.1289" y1="50.6714" x2="97.9227" y2="22.6302">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.2" d="M96.058,55.066l16.441-11.711V25.112h-14.64v-1.802h-1.802   V55.066z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="16.7114" y1="53.4946" x2="7.4462" y2="28.0385">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.2" d="M18.582,52.814H11.6L0.001,42.229v-9.46l18.581-8.783V52.814z" />
        </g>
        <g id="Group_Leveler">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.113,1.014H37.5V18.13H25.113V1.014z" />
            <path fill="#595959" d="M26.915,2.928h8.784v13.401h-8.784V2.928z" />
        </g>
        <g id="Group_Levers">
            <path fill="#7F7F7F" d="M18.582,22.184L4.618,18.13v-1.126l13.964,1.802V22.184z" />
            <radialGradient id="SVGID_8_" cx="56.8691" cy="6.4185" r="2.928" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#C7C7C7" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_8_)" cx="56.869" cy="6.419" r="2.928" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.2" d="M59.121,26.914v-4.73L55.067,0h-1.126v26.914H59.121z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8958333333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.25, 191.0, 0.5, 0.4427083333333333, 141.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.fills,"Group_Sealer",[0.0, 0.7916666666666666, 0.0, 1.0, 0.6927083333333334, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Leveler",[0.0, 0.40625, 151.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Levers",[0.0, 0.9895833333333334, 0.0, 1.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
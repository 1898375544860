import { SVGObject } from "../svgobject";

export class SVGMiscpipe5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 56.758" enable-background="new 0 0 112.5 56.758" xml:space="preserve">
        <g id="Group_Short_Horizontal_Gas">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1943" y1="56.1943" x2="56.1943" y2="0.4512">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.41" style="stop-color:#FCFFFF" />
            <stop offset="0.61" style="stop-color:#FCFFFF" />
            <stop offset="1" style="stop-color:#5C6666" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M0.451,0.451h111.487v55.744H0.451V0.451z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
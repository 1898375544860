import { ValueProperty } from "./valueproperty";
import { ColorRange } from "./range/colorrange";
import tag from "@/model/tag";

export class RangeValueProperty extends ValueProperty{
    colorrange:ColorRange[];
    showvalue:boolean;
    showhistory:boolean;
    historycolor:string;
    timeperiod:number;


    public getYvalue(value: number, height:number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) percent=0
        if(percent>1) percent=1
        const y = (1-percent)*height*9/10+height/20
        return y
    }

    public getYArrow(tag:tag, height:number):number {
      //  const tag:tag = this.getTag(tagnames); 
        if (tag==null || tag.tagvalue==null) return height*19/20
        return this.getYvalue(+tag.tagvalue,height);
    }

    public getDuration():number{
        switch (this.timeperiod){
            case 0: return 60*60*1000;
            case 1: return 3*60*60*1000;
            case 2: return 6*60*60*1000;
            case 3: return 12*60*60*1000;
            case 4: return 24*60*60*1000;
            case 5: return 365*24*60*60*1000; 
        }
    }

    public get270value(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) percent=0
        if(percent>1) percent=1
        const y = 225-percent*270
        return y
    }

    public getCurrentValue270deg(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) return 0
        if(percent>1) return 270
        else return 270*percent
    }

    public get180value(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) percent=0
        if(percent>1) percent=1
        const y = 180-percent*180
        return y
    }
    
    public getCurrentValue180deg(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) return 0
        if(percent>1) return 180
        else return 180*percent
    }
    public get90value(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) percent=0
        if(percent>1) percent=1
        const y = 180-percent*90
        return y
    }

    public getCurrentValue90deg(value: number):number {
        let percent
        if(value==null) percent=0
        else percent = (value-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
    
        if(percent<0) return 0
        if(percent>1) return 90
        else return 90*percent
    }

    public isShowvalue():boolean {
       return this.showvalue
    }

    public isShowhistory():boolean {
        return this.showhistory
     }
    


    
}
import { SVGObject } from "../svgobject";

export class SVGMotor8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 60.135" enable-background="new 0 0 112.5 60.135" xml:space="preserve">
        <g id="Group_Motor_Body">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="57.3213" y1="0.6768" x2="57.3213" y2="56.5327" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#474747" />
                <stop offset="0.16" style="stop-color:#494949" />
                <stop offset="0.24" style="stop-color:#515151" />
                <stop offset="0.3" style="stop-color:#5E5E5E" />
                <stop offset="0.35" style="stop-color:#707070" />
                <stop offset="0.39" style="stop-color:#898989" />
                <stop offset="0.43" style="stop-color:#A6A6A6" />
                <stop offset="0.47" style="stop-color:#C9C9C9" />
                <stop offset="0.5" style="stop-color:#F1F1F1" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#474747" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M26.689,38.851h59.909l-0.226-8.896l0.226-10.022l0.227-8.559   l0.225-5.52L87.5,3.603l0.676,2.252l0.225,5.52l0.226,8.559l0.228,10.022l-0.229,10.247L88.4,48.761l-0.226,5.518L87.5,56.306   H69.594v0.449h8.446v2.703H59.572v-2.703h1.126v-0.449H45.045v0.449h1.126v2.703H27.815v-2.703h8.333v-0.449h-10.36" />
        </g>
        <g id="Group_Motor_Bodyrear">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="14.5269" y1="0.6768" x2="14.5269" y2="60.1353" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#474747" />
                <stop offset="0.16" style="stop-color:#494949" />
                <stop offset="0.24" style="stop-color:#515151" />
                <stop offset="0.3" style="stop-color:#5E5E5E" />
                <stop offset="0.35" style="stop-color:#707070" />
                <stop offset="0.39" style="stop-color:#898989" />
                <stop offset="0.43" style="stop-color:#A6A6A6" />
                <stop offset="0.47" style="stop-color:#C9C9C9" />
                <stop offset="0.5" style="stop-color:#F1F1F1" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#474747" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,56.306l-0.225,3.152H2.14l0.226-6.982l0.45-10.246   v-25L2.366,7.207L2.14,0.226V0h23.423l1.126,14.752l0.225,14.978l-0.225,9.121" />
            <ellipse fill="#7F7F7F" cx="1.554" cy="30.263" rx="1.217" ry="29.773" />
        </g>
        <g id="Group_Motor_Top">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="56.8691" y1="20.9463" x2="56.8691" y2="56.8696" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.27" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M88.176,39.188H25.563V3.265h51.576H87.5l0.45,13.401   L88.176,39.188z" />
        </g>
        <g id="Group_Fins">
            <path fill="#7F7F7F" d="M25.563,6.193H87.5V3.265H77.139H25.563V6.193z" />
            <path fill="#CCCCCC" d="M78.04,35.022H26.014v2.59H78.04V35.022z" />
            <path fill="#CCCCCC" d="M79.166,35.022H86.6v2.59h-7.434V35.022z" />
            <path fill="#CCCCCC" d="M78.04,40.09H26.014v2.703H78.04V40.09z" />
            <path fill="#CCCCCC" d="M79.166,40.09H86.6v2.703h-7.434V40.09z" />
            <path fill="#B2B2B2" d="M78.04,50.338H26.014v2.702H78.04V50.338z" />
            <path fill="#B2B2B2" d="M79.166,50.338H86.6v2.702h-7.434V50.338z" />
            <path fill="#CCCCCC" d="M79.166,45.045H86.6v2.702h-7.434V45.045z" />
            <path fill="#CCCCCC" d="M78.04,45.045H26.014v2.702H78.04V45.045z" />
        </g>
        <g id="Group_Shaft">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="89.2441" y1="20.9463" x2="89.2441" y2="38.9644" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M89.863,21.171h-1.238v18.018h1.238V21.171z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="90.7646" y1="23.9873" x2="90.7646" y2="36.1489" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M89.863,23.986v12.162h1.127l0.451-0.676l0.225-0.899v-8.784   l-0.225-1.352l-0.451-0.45H89.863z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="101.8574" y1="25.5625" x2="101.8574" y2="34.3472" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#575757" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#575757" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M112.049,25.788H91.666v8.784h20.383V25.788z" />
        </g>
        <g id="Group_Boxback">
            <path fill="#2E2E2E" stroke="#4C4C4C" stroke-width="0.25" d="M65.09,14.189H35.698V41.44H65.09V14.189z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="36.374" y1="31.7568" x2="65.7656" y2="31.7568" gradientTransform="matrix(1 0 0 -1 0 60.1348)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M65.766,14.865H36.374v27.026h29.392V14.865z" />
        </g>
        <g id="Group_Box">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M63.738,19.819H40.315l0.676,18.918H62.5L63.738,19.819z" />
            <path fill="#666666" d="M39.189,18.693l1.126,1.126l0.676,18.918H40.54L39.189,18.693z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M63.738,19.819H40.315l-1.126-1.351h23.536L63.738,19.819z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M64.189,41.216h-1.127l-0.563-0.9l0.563-0.9h1.127l0.676,0.9   L64.189,41.216z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M64.189,17.567h-1.127l-0.563-0.9l0.563-0.901h1.127l0.676,0.901   L64.189,17.567z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M39.189,17.567h-1.352l-0.45-0.9l0.45-0.901h1.352l0.676,0.901   L39.189,17.567z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M39.189,41.216h-1.352l-0.45-0.9l0.45-0.9h1.352l0.676,0.9   L39.189,41.216z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.5520833333333334, 0.0, 0.16, 0.5677083333333334, 0.0, 0.24, 0.6302083333333334, 0.0, 0.3, 0.7291666666666666, 0.0, 0.35, 0.875, 0.0, 0.39, 0.9322916666666666, 17.0, 0.43, 0.703125, 75.0, 0.47, 0.4270833333333333, 145.0, 0.5, 0.11979166666666667, 224.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5520833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5520833333333334, 0.0, 0.16, 0.5677083333333334, 0.0, 0.24, 0.6302083333333334, 0.0, 0.3, 0.7291666666666666, 0.0, 0.35, 0.875, 0.0, 0.39, 0.9322916666666666, 17.0, 0.43, 0.703125, 75.0, 0.47, 0.4270833333333333, 145.0, 0.5, 0.11979166666666667, 224.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5520833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.5989583333333334, 0.0, 0.27, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6770833333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Fins",[0.0, 0.9895833333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.40625, 151.0, 8.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Box",[0.0, 0.40625, 151.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.40625, 151.0, 3.0, 0.20833333333333334, 201.0, 4.0, 0.20833333333333334, 201.0, 5.0, 0.20833333333333334, 201.0, 6.0, 0.20833333333333334, 201.0]);

        super.drawObject();
    }
}
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { Tile } from "./tile";
import { LineColorProperty } from "../properties/linecolorproperty";
import authModule from "@/store/auth.module";
import tagsService from "@/services/tags.service";
import historydatarequest from "@/model/requests/historydatarequest";
import tag from "@/model/tag";
import { format, } from 'date-fns'

export class StockTile extends Tile{
  linecolor:string;
  oldvalue:number;
  duration:number;
  updatehistory = true;
  tagname:string;
  unit:string;
  points: any[] = [];
  
  public deleteObject():void{
    super.deleteObject()
    this.updatehistory=true;
  }
	
  public drawObject():void{
		super.initObject();

    if(this.oldvalue==null) this.oldvalue = 0
    let value = 0   
    let valueproperty
    const valueprop = this.properties['value'];
    if (valueprop!=null){	
      valueproperty =Object.assign(new ValueProperty(), valueprop)
    }
    let tag:tag 
    if (valueproperty!=null) {
        tag = valueproperty.getTag(this.tagnames)  
        if (tag!=null) value = Number(tag.tagvalue)     
    }

    let decimalpos=0;
    if (valueproperty!=null) decimalpos=valueproperty.decimalpos;
   

    if(this.updatehistory){
      if (authModule.currentUser!=null && valueproperty!=null){
        const request:historydatarequest =  {token:authModule.currentUser.accessToken, 
            name:valueproperty.tagname, begindate:Date.now()-this.duration*60000 , enddate: Date.now()};
            tagsService.gethistorydata(request,(data:string)=>{
            //console.log("data",data)
            const historydata =JSON.parse(data);  
            //console.log('historydata', historydata)
            this.points =  historydata;

            const group = this.drawImage(this.points, value, decimalpos)
            this.node.add(group) 
            this.updatehistory = false
            this.oldvalue = value;
         }, (data:string)=>{ console.log('data', data)}
        
      )}   
    }else{
      if(tag !=null){
        //console.log('this.points', this.points)
        this.points.push({x:Date.now(), y:Number(tag.tagvalue)})
        //console.log('this.points1', this.points)
        this.points = this.points.filter((el)=>el.x>=Date.now()-this.duration*60000)
        //console.log('this.points2', this.points)
        const group = this.drawImage(this.points, value, decimalpos)
        this.node.add(group)
        this.oldvalue = value;
      }
    } 
  }


  private drawImage(points:any[], value: number, decimalpos:number):Konva.Group{
    
    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let linecolor:string = this.linecolor;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
       linecolor = linecolorproperty.getColor(this.tagnames, this.linecolor)
		}	

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const valueUnitText = new Konva.Group({
      x: size*0.05,
      y: 0,
      width: this.width-size*0.05*2
    })
    group.add(valueUnitText)

    const valueText = new Konva.Text({
      x: 0,
      y: size*.36-0.24*size,
      verticalAlign: "bottom",
      height: 0.24*size,
      text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.24*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
      offsetY:-size*0.024
    });
  
    const unitText = new Konva.Text({
      x: valueText.width(),
      y: size*.36-0.24*size,
      verticalAlign: "bottom",
      height: 0.24*size,
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });
    valueUnitText.add(valueText, unitText)
    valueUnitText.offsetX(-(this.width-unitText.width()-valueText.width()-size*0.05*2))

    const mindate = Math.min(...points.map(o => o.x))
    const maxdate = Date.now()
    let deltadate = maxdate - mindate;
    deltadate = deltadate==0 ? 1: deltadate;

    const minvalue = Math.min(...points.map(o => o.y));
    const maxvalue = Math.max(...points.map(o => o.y));
    let deltavalue = maxvalue - minvalue;
    deltavalue = deltavalue==0? 1: deltavalue;

    const minY = height*0.6;
    const maxY = minY + height*.35;

    const stepY = (maxY-minY)/5;

    const curvePoints = []
    points.forEach((p)=>{
      if(p.x>mindate || p.x<maxdate){
        const x = (p.x-mindate)*this.width*.9/(maxdate-mindate);
        let y = (maxvalue-p.y)*(this.height*.35-stepY)/(deltavalue);
        if (y<0) y=0;
        if (y>this.height*.35) y=this.height*.35
        curvePoints.push(this.width*.05+x, y+this.height*.6)
      }   
    })
    //console.log('curvePoints', curvePoints)
    if (curvePoints.length<2) return group;

    const curx = this.width*.9;
    let cury = 0;
    cury = (maxvalue-value)*(this.height*.35-stepY)/(deltavalue);

    curvePoints.push(this.width*.05+curx, this.height*.6+cury)
    
   //console.log('cury', cury)
    const curve = new Konva.Line({
      points: curvePoints,
      stroke: ColorUtils.convertformat(this.linecolor),
      strokeWidth: size * 0.01,
    });

    const dot = new Konva.Circle({
      x: this.width*0.95,
      y: this.height*.6+cury,
      radius: size*.014,
      fill: ColorUtils.convertformat(this.linecolor),
    });
    group.add(curve, dot)

    const minValueText = new Konva.Text({
      x: size*0.05,
      y: this.height - size*0.04 - 0.06*size,
      verticalAlign: "middle",
      height: 0.06*size,
      text: parseFloat(minvalue.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });
    group.add(minValueText)

    const maxValueText = new Konva.Text({
      x: size*0.05,
      y: this.height*.6 - size*0.04 - 0.06*size,
      verticalAlign: "bottom",
      //height: 0.06*size,
      text: parseFloat(maxvalue.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });
    group.add(maxValueText)

    const timeSpanText = this.createTimeSpanText((deltadate)/1000)
    const timeText = new Konva.Text({
      x: size*.05,
      y: this.height - size * 0.04 - 0.06*size,
      width: this.width-size*.05*2,
      //height: 0.24*size,
      verticalAlign: 'middle',
      text: timeSpanText,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'center',
    }); 
    group.add(timeText)

    const time = format(Date.now(), "HH:mm").toString()
    const currenttime = new Konva.Text({
      x: this.width*2/3,
      y: this.height - size * 0.04 - 0.06*size,
      verticalAlign:"middle",
      width: this.width/3-size*0.05,
      //height: this.height*0.4,
      text: time,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'right'
    });  
    group.add(currenttime)

    let deviation = (this.oldvalue == 0) ? value : (-((this.oldvalue - value) / this.oldvalue) * 100.0)
    deviation = Math.abs(deviation);
    const delta = value - this.oldvalue;


    const deltaText = new Konva.Text({
      x: this.width/2,
      y: this.height*.6-size*.12 - 0.06*size,
      verticalAlign:"middle",
      width: this.width/2-size*0.05,
      text: parseFloat(delta.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: delta>0? '#8FC65E' : '#E5504C',
      align: 'right'
    });  
    group.add(deltaText)

    const unitPerTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign:"bottom",
      height:0.06*size,
      text: '%',
      fontSize: .04*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: delta>0? '#8FC65E' : '#E5504C',
    });  
    
    const unitPerText = new Konva.Text({
      x: this.width-unitPerTextTransparent.width() - size*0.05,
      y: height*.6 - size*0.04 - 0.06*size,
      verticalAlign:"bottom",
      //width: unitPerTextTransparent.width(),
      height:0.06*size,
      text: '%',
      fontSize: .04*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: delta>0? '#8FC65E' : '#E5504C',
      align: 'right'
    });  
    group.add(unitPerText)

    const percentageTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign:"bottom",
      height:0.06*size,
      text: parseFloat(deviation.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: delta>0? '#8FC65E' : '#E5504C',
    });  

    const percentageText = new Konva.Text({
      x: this.width-unitPerTextTransparent.width()- percentageTextTransparent.width() - size*0.05,
      y: height*.6 - size*0.04 - 0.06*size,
      verticalAlign:"bottom",
      //width: this.width/2-size*0.05,
      height:0.06*size,
      text: parseFloat(deviation.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: delta>0? '#8FC65E' : '#E5504C',
      align: 'right'
    });  
    group.add(percentageText)

    let triangle
    if (delta>0) {
      triangle = new Konva.Line({
        points: [this.width - size*.12 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.05,
                this.width - size*.1 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.08,
                this.width - size*.08 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.05
              ],
        closed: true,
        fill: '#8FC65E'     
      })	
		}else if (delta<0) {
      triangle = new Konva.Line({
        points: [this.width - size*.12 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.08,
                this.width - size*.1 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.05,
                this.width - size*.08 - percentageText.width()-unitPerText.width(),
                this.height*.6 - size*0.08
              ],
        closed: true,
        fill: '#E5504C'      
      })	
		}
		if (delta!=0) group.add(triangle)

    
    
    return group
    
  }

  private createTimeSpanText(timeSpan:number):string {
    const SEC_MONTH = 2_592_000;
    const SEC_DAY = 86_400;
    const SEC_HOUR = 3_600;
    const SEC_MINUTE = 60;
    let durationText = "\u2190 "
    
    if (timeSpan > SEC_MONTH) { // 1 Month (30 days)
        const months = Math.trunc(timeSpan / SEC_MONTH);
        const days   = timeSpan % SEC_MONTH;
        durationText += months + "M"
        if (days > 0) { durationText += Math.trunc(days) +"d"}
        durationText += " \u2192"
    } else if (timeSpan > SEC_DAY) { // 1 Day
        const days  = Math.trunc(timeSpan / SEC_DAY);
        const hours = (timeSpan - (days * SEC_DAY)) / SEC_HOUR;
        durationText += days + "d"
        if (hours > 0) {durationText +=Math.trunc(hours) +"h"}
        durationText += "\u2192"
    } else if (timeSpan > SEC_HOUR) { // 1 Hour
        const hours   = Math.trunc(timeSpan / SEC_HOUR);
        const minutes = (timeSpan - (hours * SEC_HOUR)) / SEC_MINUTE;
        durationText += hours + "h"
        if (minutes > 0) {durationText+=Math.trunc(minutes) +"m"}
        durationText += " \u2192"
    } else if (timeSpan > SEC_MINUTE) { // 1 Minute
        const  minutes = Math.trunc(timeSpan / SEC_MINUTE);
        const seconds = (timeSpan - (minutes * SEC_MINUTE));
        durationText += minutes +"m"
        if (seconds > 0) {durationText+= Math.trunc(seconds) +"s"}
        durationText += " \u2192"
    } else {
        const seconds = Math.trunc(timeSpan);
        durationText += seconds +"s" +" \u2192"
    }
   // console.log('durationText', durationText)
    return durationText;
  }
}
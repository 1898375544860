import { SVGObject } from "../svgobject";

export class SVGValve26 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 38.852 112.5" enable-background="new 0 0 38.852 112.5" xml:space="preserve">
        <g id="Group_6">
            <g>
                <defs>
                    <rect id="SVGID_1_" x="3.041" y="17.23" width="32.657" height="43.243" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0063" y1="112.5068" x2="1.0063" y2="112.5068" gradientTransform="matrix(32.6572 0 0 -32.6572 2.8423 3713.0149)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.08" style="stop-color:#6B6C6C" />
                    <stop offset="0.22" style="stop-color:#979899" />
                    <stop offset="0.35" style="stop-color:#B7B8B9" />
                    <stop offset="0.45" style="stop-color:#CACCCD" />
                    <stop offset="0.52" style="stop-color:#D1D3D4" />
                    <stop offset="0.61" style="stop-color:#C3C5C6" />
                    <stop offset="0.78" style="stop-color:#9FA0A0" />
                    <stop offset="1" style="stop-color:#666666" />
                </linearGradient>
                <rect x="3.041" y="17.23" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="32.657" height="43.243" />
            </g>
        </g>
        <g id="Group_2">
            <g>
                <defs>
                    <rect id="SVGID_4_" x="18.356" y="17.23" width="2.026" height="43.243" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-0.1328" y1="112.3672" x2="0.8672" y2="112.3672" gradientTransform="matrix(2.0264 0 0 -2.0264 18.6244 266.5529)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="18.356" y="17.23" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="2.026" height="43.243" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_7_" x="1.014" y="17.23" width="2.027" height="43.243" />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0.1768" y1="112.6807" x2="1.1768" y2="112.6807" gradientTransform="matrix(2.0273 0 0 -2.0273 0.6552 267.2875)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="1.014" y="17.23" clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="2.027" height="43.243" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_10_" x="35.698" y="17.23" width="2.026" height="43.243" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="0.1772" y1="112.6807" x2="1.1768" y2="112.6807" gradientTransform="matrix(2.0273 0 0 -2.0273 35.3388 267.2875)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="35.698" y="17.23" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="2.026" height="43.243" />
            </g>
        </g>
        <g id="Group_3">
            <g>
                <defs>
                    <rect id="SVGID_13_" x="16.104" y="-0.001" width="6.532" height="8.672" />
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="0.0337" y1="112.5332" x2="1.0332" y2="112.5332" gradientTransform="matrix(6.5322 0 0 -6.5322 15.8838 739.4289)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="16.104" y="-0.001" clip-path="url(#SVGID_14_)" fill="url(#SVGID_15_)" width="6.532" height="8.672" />
            </g>
        </g>
        <g id="Group_Orange">
            <g>
                <defs>
                    <rect id="SVGID_16_" y="8.672" width="38.851" height="8.558" />
                </defs>
                <clipPath id="SVGID_17_">
                    <use xlink:href="#SVGID_16_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-0.0078" y1="112.4941" x2="0.9922" y2="112.4941" gradientTransform="matrix(38.8516 0 0 -38.8516 0.3203 4383.5469)">
                    <stop offset="0" style="stop-color:#FF7F00" />
                    <stop offset="0.04" style="stop-color:#FF8710" />
                    <stop offset="0.2" style="stop-color:#FFA44A" />
                    <stop offset="0.33" style="stop-color:#FFBA75" />
                    <stop offset="0.44" style="stop-color:#FFC78F" />
                    <stop offset="0.5" style="stop-color:#FFCC99" />
                    <stop offset="0.57" style="stop-color:#FFC48A" />
                    <stop offset="0.71" style="stop-color:#FFB162" />
                    <stop offset="0.9" style="stop-color:#FF9123" />
                    <stop offset="1" style="stop-color:#FF7F00" />
                </linearGradient>
                <rect y="8.672" clip-path="url(#SVGID_17_)" fill="url(#SVGID_18_)" width="38.851" height="8.558" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_19_" y="60.473" width="38.851" height="8.784" />
                </defs>
                <clipPath id="SVGID_20_">
                    <use xlink:href="#SVGID_19_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="-0.0078" y1="112.4922" x2="0.9922" y2="112.4922" gradientTransform="matrix(38.8516 0 0 -38.8516 0.3203 4435.3594)">
                    <stop offset="0" style="stop-color:#FF7F00" />
                    <stop offset="0.04" style="stop-color:#FF8710" />
                    <stop offset="0.2" style="stop-color:#FFA44A" />
                    <stop offset="0.33" style="stop-color:#FFBA75" />
                    <stop offset="0.44" style="stop-color:#FFC78F" />
                    <stop offset="0.5" style="stop-color:#FFCC99" />
                    <stop offset="0.57" style="stop-color:#FFC48A" />
                    <stop offset="0.71" style="stop-color:#FFB162" />
                    <stop offset="0.9" style="stop-color:#FF9123" />
                    <stop offset="1" style="stop-color:#FF7F00" />
                </linearGradient>
                <rect y="60.473" clip-path="url(#SVGID_20_)" fill="url(#SVGID_21_)" width="38.851" height="8.784" />
            </g>
        </g>
        <g id="Group_Bolts">
            <rect x="30.293" y="72.41" fill="#595959" width="2.027" height="1.126" />
            <rect x="6.419" y="72.41" fill="#595959" width="2.027" height="1.126" />
            <g>
                <defs>
                    <rect id="SVGID_22_" x="5.293" y="71.283" width="4.279" height="1.127" />
                </defs>
                <clipPath id="SVGID_23_">
                    <use xlink:href="#SVGID_22_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="-0.0049" y1="112.4961" x2="0.9946" y2="112.4961" gradientTransform="matrix(4.2793 0 0 -4.2793 5.3175 553.2487)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="5.293" y="71.283" clip-path="url(#SVGID_23_)" fill="url(#SVGID_24_)" width="4.279" height="1.127" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_25_" x="29.167" y="71.283" width="4.279" height="1.127" />
                </defs>
                <clipPath id="SVGID_26_">
                    <use xlink:href="#SVGID_25_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="112.4961" x2="0.9941" y2="112.4961" gradientTransform="matrix(4.2793 0 0 -4.2793 29.1925 553.2487)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="29.167" y="71.283" clip-path="url(#SVGID_26_)" fill="url(#SVGID_27_)" width="4.279" height="1.127" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_28_" x="5.293" y="58.446" width="4.279" height="2.026" />
                </defs>
                <clipPath id="SVGID_29_">
                    <use xlink:href="#SVGID_28_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="-0.0049" y1="112.4941" x2="0.9946" y2="112.4941" gradientTransform="matrix(4.2793 0 0 -4.2793 5.3175 540.858)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="5.293" y="58.446" clip-path="url(#SVGID_29_)" fill="url(#SVGID_30_)" width="4.279" height="2.026" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_31_" x="29.167" y="58.446" width="4.279" height="2.026" />
                </defs>
                <clipPath id="SVGID_32_">
                    <use xlink:href="#SVGID_31_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="112.4941" x2="0.9941" y2="112.4941" gradientTransform="matrix(4.2793 0 0 -4.2793 29.1925 540.858)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="29.167" y="58.446" clip-path="url(#SVGID_32_)" fill="url(#SVGID_33_)" width="4.279" height="2.026" />
            </g>
        </g>
        <g id="Group_5">
            <g>
                <defs>
                    <rect id="SVGID_34_" x="12.95" y="82.095" width="12.838" height="13.063" />
                </defs>
                <clipPath id="SVGID_35_">
                    <use xlink:href="#SVGID_34_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="112.4932" x2="0.9941" y2="112.4932" gradientTransform="matrix(12.8379 0 0 -12.8379 13.0151 1532.8164)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="12.95" y="82.095" clip-path="url(#SVGID_35_)" fill="url(#SVGID_36_)" width="12.838" height="13.063" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_37_" x="14.978" y="80.067" width="8.784" height="2.026" />
                </defs>
                <clipPath id="SVGID_38_">
                    <use xlink:href="#SVGID_37_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="-0.0181" y1="112.4814" x2="0.9814" y2="112.4814" gradientTransform="matrix(8.7842 0 0 -8.7842 15.1398 1069.1362)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="14.978" y="80.067" clip-path="url(#SVGID_38_)" fill="url(#SVGID_39_)" width="8.784" height="2.026" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_40_" x="17.229" y="71.283" width="4.279" height="8.784" />
                </defs>
                <clipPath id="SVGID_41_">
                    <use xlink:href="#SVGID_40_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="112.4941" x2="0.9941" y2="112.4941" gradientTransform="matrix(4.2793 0 0 -4.2793 17.255 557.0729)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="17.229" y="71.283" clip-path="url(#SVGID_41_)" fill="url(#SVGID_42_)" width="4.279" height="8.784" />
            </g>
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <polygon id="SVGID_43_" points="2.14,71.283 2.14,84.347 4.167,86.6 34.572,86.6 36.6,84.347 36.6,71.283 34.572,69.257      19.257,69.257 19.257,71.283 33.446,71.283 34.572,72.41 34.572,83.221 33.446,84.347 5.293,84.347 4.167,83.221 4.167,72.41      5.293,71.283 19.257,71.283 19.257,69.257 4.167,69.257    " />
                </defs>
                <clipPath id="SVGID_44_">
                    <use xlink:href="#SVGID_43_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="-0.0088" y1="112.4902" x2="0.9912" y2="112.4902" gradientTransform="matrix(34.46 0 0 -34.46 2.4513 3954.355)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.52" style="stop-color:#BCBEC0" />
                    <stop offset="1" style="stop-color:#666666" />
                </linearGradient>
                <rect x="2.14" y="69.257" clip-path="url(#SVGID_44_)" fill="url(#SVGID_45_)" width="34.459" height="17.343" />
            </g>
        </g>
        <g id="Group_1">
            <g>
                <defs>
                    <polygon id="SVGID_46_" points="29.167,99.437 29.167,95.158 9.572,95.158 9.572,99.437 4.167,99.437 4.167,101.689      4.167,104.842 4.167,108.221 34.572,108.221 34.572,104.842 34.572,101.689 34.572,99.437    " />
                </defs>
                <clipPath id="SVGID_47_">
                    <use xlink:href="#SVGID_46_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="-9.765625e-004" y1="112.5" x2="0.999" y2="112.5" gradientTransform="matrix(0 -13.0625 -13.0625 0 1488.9063 108.218)">
                    <stop offset="0" style="stop-color:#A6730D" />
                    <stop offset="0.51" style="stop-color:#F2BF59" />
                    <stop offset="1" style="stop-color:#A6730D" />
                </linearGradient>
                <polyline clip-path="url(#SVGID_47_)" fill="url(#SVGID_48_)" points="19.198,108.221 4.167,108.221 4.167,95.158 34.572,95.158     34.572,108.221 19.198,108.221   " />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_49_" x="12.95" y="95.158" width="12.838" height="13.028" />
                </defs>
                <clipPath id="SVGID_50_">
                    <use xlink:href="#SVGID_49_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="112.4941" x2="0.9941" y2="112.4941" gradientTransform="matrix(12.8379 0 0 -12.8379 13.0151 1545.8633)">
                    <stop offset="0" style="stop-color:#A6730D" />
                    <stop offset="0.51" style="stop-color:#F2BF59" />
                    <stop offset="1" style="stop-color:#A6730D" />
                </linearGradient>
                <rect x="12.95" y="95.158" clip-path="url(#SVGID_50_)" fill="url(#SVGID_51_)" width="12.838" height="13.028" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_52_" width="38.852" height="112.5" />
                </defs>
                <clipPath id="SVGID_53_">
                    <use xlink:href="#SVGID_52_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_53_)" fill="#CC9933" d="M19.257,101.689c2.985,0,5.405,2.42,5.405,5.404    c0,2.986-2.42,5.407-5.405,5.407c-2.985,0-5.405-2.421-5.405-5.407C13.852,104.109,16.272,101.689,19.257,101.689" />
                <path clip-path="url(#SVGID_53_)" fill="#BA8721" d="M19.369,103.828c1.804,0,3.267,1.463,3.267,3.266    c0,1.805-1.463,3.267-3.267,3.267c-1.804,0-3.266-1.462-3.266-3.267C16.103,105.291,17.565,103.828,19.369,103.828" />
            </g>
            
        </g>
        </svg>`
		super.fillGradients();
        
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.5989583333333334, 0.0, 0.08, 0.8333333333333334, 0.0, 0.22, 0.8125, 47.0, 0.35, 0.5625, 111.0, 0.45, 0.4114583333333333, 149.0, 0.52, 0.359375, 163.0, 0.61, 0.46875, 135.0, 0.78, 0.7604166666666666, 61.0, 1.0, 0.7916666666666666, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.9895833333333334, 0.0, 0.04, 0.9479166666666666, 13.0, 0.2, 0.71875, 71.0, 0.33, 0.546875, 115.0, 0.44, 0.4427083333333333, 141.0, 0.5, 0.40625, 151.0, 0.57, 0.46875, 135.0, 0.71, 0.625, 95.0, 0.9, 0.8697916666666666, 33.0, 1.0, 0.9895833333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.9895833333333334, 0.0, 0.04, 0.9479166666666666, 13.0, 0.2, 0.71875, 71.0, 0.33, 0.546875, 115.0, 0.44, 0.4427083333333333, 141.0, 0.5, 0.40625, 151.0, 0.57, 0.46875, 135.0, 0.71, 0.625, 95.0, 0.9, 0.8697916666666666, 33.0, 1.0, 0.9895833333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.515625, 123.0, 1.0, 0.7916666666666666, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_48_",[0.0, 0.7604166666666666, 0.0, 0.51, 0.640625, 91.0, 1.0, 0.7604166666666666, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_51_",[0.0, 0.7604166666666666, 0.0, 0.51, 0.640625, 91.0, 1.0, 0.7604166666666666, 0.0]);
	
        super.drawObject();
    }
}
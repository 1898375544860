import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import tagsModele from "@/store/tags.modele";
import { RecipeRow } from '@/model/project/objects/properties/range/reciperow';
import { ObjectView } from '@/model/project/objects/object';

@Module({ name:"Recipe", dynamic:true, store})
class RecipeModule extends VuexModule {
  public reciperow:RecipeRow;
  public object:ObjectView
  public enabled:boolean
    @Mutation
    public setRecipeRow(row:RecipeRow){
      this.reciperow = row;
    }
    @Mutation
    public setRecipeRowEnabled(value:boolean){
      this.enabled = value;
    }
    @Mutation
    public setObjectView(object:ObjectView){
      this.object = object
    }
    get getRecipeRowEnable():boolean{
     return this.enabled
    }
    get getRecieRow():RecipeRow{
        return this.reciperow
    }
    get getRecipeColumns():Map<string, string>{
        return this.reciperow.columnnames
    }
    get getRecipeIngredients():Map<string, string>{
        return this.reciperow.ingredients
    }
    @Mutation
    public writeRecipeRowValues(){
        if (this.reciperow==null) return;
        for (const [dbname, tagname] of this.reciperow.tagnames) {
    
            tagsModele.writeTagAction({token:"",name: tagname, tagvalue:this.reciperow.ingredients[dbname],datetime:0});
            if (this.object!=null && this.object!=undefined)
              this.object.drawObject()
          }
        
    }
}

export default getModule(RecipeModule);
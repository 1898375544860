import { SVGObject } from "../svgobject";

export class SVGFinish17 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 54.389" enable-background="new 0 0 112.5 54.389" xml:space="preserve">
        <g id="Group_Inline_Sprayer">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="46.5933" y1="26.1782" x2="41.4717" y2="-2.8678">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M7.323,20.156h73.419V3.153H7.323V20.156z" />
            <path fill="#BFBFBF" stroke="#4C4C4C" stroke-width="0.25" d="M0.003,20.156h77.585v7.432H0.003V20.156z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M44.032,47.183h4.729v2.252h-4.729V47.183z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M77.589,20.156h32.431v7.432H77.589V20.156z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M80.066,19.481H7.773" />
        </g>
        <g id="Group_Washer">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="22.8105" y1="50.9922" x2="18.4091" y2="26.0306">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M9.575,27.588h22.071v21.846H9.575V27.588z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="96.416" y1="22.2373" x2="92.5451" y2="0.284">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M80.742,2.365h27.476v17.792H80.742V2.365z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="57.6699" y1="43.2783" x2="54.7182" y2="26.5385">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.46" style="stop-color:#B6B6B6" />
                <stop offset="0.7" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,27.588h14.863v14.639H48.762V27.588z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="95.1738" y1="51.8203" x2="90.5201" y2="25.4277">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M78.265,27.588h29.165v22.071H78.265V27.588z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M92.228,48.983V28.039" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M92.903,48.983V28.039" />
        </g>
        <g id="Group_Reader">
            <path fill="#4C6699" d="M9.575,5.518h7.432v1.126H9.575V5.518z" />
        </g>
        <g id="Group_Blue_Display">
            <path fill="#B2E5E5" d="M1.129,22.634h8.445v2.478H1.129V22.634z" />
        </g>
        <g id="Group_Back_Support">
            <path fill="#4C4C4C" d="M78.265,27.588h-1.352v21.846h1.352V27.588z" />
            <path fill="#4C4C4C" d="M32.997,27.588h-1.351v21.846h1.351V27.588z" />
            <path fill="#333333" d="M4.958,52.587h107.538v-0.676H4.958V52.587z" />
            <path fill="#333333" d="M0.003,28.715h78.261v-1.126H0.003V28.715z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M4.958,49.435h107.538v2.477H4.958V49.435z" />
        </g>
        <g id="Group_Bottom_Support">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M107.43,51.911h2.59v2.478h-2.59V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M90.426,51.911h2.478v2.478h-2.478V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M78.265,51.911h2.478v2.478h-2.478V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M61.148,51.911h2.477v2.478h-2.477V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,51.911h2.478v2.478h-2.478V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M31.646,51.911h2.478v2.478h-2.478V51.911z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M7.323,51.911h2.252v2.478H7.323V51.911z" />
        </g>
        <g id="Group_Spray">
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M17.007,19.03V7.319H30.52" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M17.007,19.03H30.52V7.319" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M50.789,19.03V7.319h13.288" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M50.789,19.03h13.288V7.319" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M73.311,19.03V7.319h6.756" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M73.311,19.03h6.756V7.319" />
        </g>
        <g id="Group_Indicator">
            <path fill="#FFFFFF" d="M26.916,9.909h2.252v1.689h-2.252V9.909z" />
            <path fill="#FFFFFF" d="M60.473,9.909h2.478v1.689h-2.478V9.909z" />
            <path fill="#FFFFFF" d="M76.913,9.909h2.478v1.689h-2.478V9.909z" />
            <path fill="#FFFFFF" d="M93.579,10.472h2.252v1.802h-2.252V10.472z" />
        </g>
        <g id="Group_Inidicator_Yellow">
            <path fill="#FFE57F" d="M26.916,8.107h2.252v1.802h-2.252V8.107z" />
            <path fill="#FFE57F" d="M60.473,8.107h2.478v1.802h-2.478V8.107z" />
            <path fill="#FFE57F" d="M76.913,8.107h2.478v1.802h-2.478V8.107z" />
            <path fill="#FFE57F" d="M93.579,8.445h2.252v2.027h-2.252V8.445z" />
        </g>
        <g id="Group_Button">
            <radialGradient id="SVGID_6_" cx="4.2822" cy="23.8726" r="1.126" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_6_)" cx="4.282" cy="23.873" r="1.126" />
        </g>
        <g id="Group_Supply">
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M8.449,27.588h1.126v3.604H8.449V27.588z" />
        </g>
        <g id="Group_Switch_Box">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M9.125,29.39l1.126-0.675l0.675-1.126l0.676-2.477v-3.153l-1.351-4.729" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M7.773,13.4l3.604-0.676v4.955l-3.604-0.451V13.4z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M12.728,13.4l-1.351-0.676v4.955l1.351-0.451V13.4z" />
        </g>
        <g id="Group_Switch">
            <path fill="#7F0000" d="M9.575,16.553l-1.126-0.676v-1.126l1.126-0.676l0.676,1.126L9.575,16.553z" />
        </g>
        <g id="Group_Box">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="58.4824" y1="52.7295" x2="55.0325" y2="33.1641">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M48.086,34.389h17.342v17.116H48.086V34.389z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="19.4043" y1="52.666" x2="15.9612" y2="33.1391">
                <stop offset="0" style="stop-color:#B4B4B4" />
                <stop offset="0.35" style="stop-color:#B6B6B6" />
                <stop offset="0.55" style="stop-color:#CECFD0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M9.125,34.345H26.24v17.116H9.125V34.345z" />
        </g>
        <g id="Group_Box_Fitting">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.575,48.983v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.575,45.38v-1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.575,41.552v-1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.575,37.948v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.376,35.021h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.205,35.021h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.809,35.021h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.412,35.021h1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.565,48.983v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.565,45.38v-1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.565,41.552v-1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.565,37.948v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.376,50.785h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.205,50.785h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.809,50.785h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.412,50.785h1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,48.983v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,45.38v-1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,41.552v-1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.762,37.948v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M50.563,35.021h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.167,35.021h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.996,35.021h1.125" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M61.599,35.021h1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.526,48.983v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.526,45.38v-1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.526,41.552v-1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.526,37.948v-1.352" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M50.563,50.785h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.167,50.785h1.351" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.996,50.785h1.125" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M61.599,50.785h1.352" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="9.5747" y1="1.8579" x2="19.4839" y2="1.8579">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M9.575,3.153v-2.59h9.909v2.59H9.575" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="7.7729" y1="0.2813" x2="21.2856" y2="0.2813">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.1" d="M7.773,0v0.563h13.513V0H7.773z" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
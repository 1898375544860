import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { SwitchControlProperty} from "./properties/switchcontrolproperty"
import Konva from "konva";
import { ElectricalSwitch } from "./electricalswitch";

export class ElectricalSwitch2Way extends ElectricalSwitch{


    public drawObject():void{
		super.initObject();
		
		if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		const colorline:string = ColorUtils.convertformat(color);
		
        let sw = false;
        const switchprop = this.properties['switchcontrol'];
        let switchproperty:SwitchControlProperty;
        if (switchprop!=null){	
            switchproperty =Object.assign(new SwitchControlProperty(), switchprop);
            if (switchproperty!=null) 
                    sw = switchproperty.getCurrentBooleanValue(this.tagnames);     
        }	

		let xbegin:number ;
		let ybegin:number;
		let xend:number;
		let yend:number;
		const ang:number = Math.atan2(this.height, this.width);
		if (this.beginmarker!='Flat'){
			xbegin=1.5*this.linewidth/2;
			ybegin=1.5*this.linewidth/2;
		}
		else{
			xbegin=0;
			ybegin=0;
		}
		if (this.endmarker!='Flat'){
			xend=1.5*this.linewidth/2;
			yend=1.5*this.linewidth/2;
		}
		else{
			xend=0;
			yend=0;
		}
		
		if (this.beginmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: 0,
			y: this.height/2-1.5*this.linewidth/2,
			points: [0, 0, this.linewidth, 0],
			pointerLength: this.linewidth,
			pointerWidth: this.linewidth,
			pointerAtBeginning:true,
			pointerAtEnding:false,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
			this.node.add(arrow)
		}
		else if (this.beginmarker=='Square'){
			const square = new Konva.Rect({
				x:0,
				y:this.height/2-1.5*this.linewidth/2, 
				width:1.5*this.linewidth,
				height:1.5*this.linewidth,
				fill: colorline,
				stroke: colorline,
			});
			this.node.add(square)
		}
		else if (this.beginmarker=='Circle'){
			const circle = new Konva.Circle({
				x: 0,
				y: this.height/2,
				radius: 1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle)
		}
		if (this.endmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: this.width,
			y: this.height,
			points: [0, 0, 0, 0],
			pointerLength: 1.5*this.linewidth,
			pointerWidth: 1.5*this.linewidth,
			pointerAtBeginning:false,
			pointerAtEnding:true,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
            const arrow1 = new Konva.Arrow({
            x: this.width+1.5*this.linewidth/2,
            y: 1.5*this.linewidth,
            points: [0, 0, 0, 0],
            pointerLength: 1.5*this.linewidth,
            pointerWidth: 1.5*this.linewidth,
            pointerAtBeginning:false,
            pointerAtEnding:true,
            fill: colorline,
            stroke: colorline,
            strokeWidth: 4,
            });
			this.node.add(arrow, arrow1)
		}
		else if (this.endmarker=='Square'){
			const square = new Konva.Rect({
				x:this.width-1.5*this.linewidth/2,
				y:this.height, 
				width:1.5*this.linewidth,
				height:1.5*this.linewidth,
				fill: colorline,
				stroke: colorline,
			});
            const square1 = new Konva.Rect({
				x:this.width-1.5*this.linewidth/2,
				y:1.5*this.linewidth/2, 
				width:1.5*this.linewidth,
				height:1.5*this.linewidth,
				fill: colorline,
				stroke: colorline,
			});
			this.node.add(square, square1)
		}
		else if (this.endmarker=='Circle'){
			const circle = new Konva.Circle({
				x: this.width,
				y: this.height+1.5*this.linewidth/2,
				radius: 1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
            const circle1 = new Konva.Circle({
				x: this.width,
				y: 1.5*this.linewidth/2,
				radius: 1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle, circle1)
        }

        if(sw){
            const line1 = new Konva.Line({
                points: [xbegin, this.height/2,
                this.width, yend],
                stroke: colorline,
                strokeWidth: this.linewidth
                
            })
            this.node.add(line1)
        }else{
            const line1 = new Konva.Line({
                points: [xbegin, this.height/2,
                this.width-xend, this.height+yend],
                stroke: colorline,
                strokeWidth: this.linewidth
                
            })
            this.node.add(line1)
        }
		
		

 
    }
}
import { TwoStateObject } from './twostateobject';
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";

export class IntersectPipe2 extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            
            const pipe = new Konva.Rect({
              x: 0,
              y:this.height/5,
              width: this.width/10,
              height: this.height*3/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
             strokeWidth:1
            })
            const pipe2 = new Konva.Rect({
              x: this.width*9/10,
              y:this.height/5,
              width: this.width/10,
              height: this.height*3/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
             strokeWidth:1
            })
            if(this.type3d==0){
                  pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                  pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                  pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5, 'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
                  pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                  pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                  pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              pipe.fill(fillcolor2)
              pipe2.fill(fillcolor2)
            }
            this.node.add(pipe, pipe2)
            
          const stripe = new Konva.Rect({
            x: 0,
            y: this.height/5,
            width: this.width/50,
            height: this.height*3/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1  
          })
          const stripe2 = new Konva.Rect({
              x: this.width*4/50,
              y: this.height/5,
              width: this.width/50,
              height: this.height*3/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth:1 
          })
          const stripe3 = new Konva.Rect({
          x: this.width*9/10,
          y:this.height/5,
          width: this.width/50,
          height: this.height*3/5,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
      })
      const stripe4 = new Konva.Rect({
          x: this.width*49/50,
          y:this.height/5,
          width: this.width/50,
          height: this.height*3/5,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
      })
      if(this.type3d==0){
              stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
              stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe2.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
              stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe3.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
              stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
              stripe4.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
        }else{
          stripe.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe2.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe3.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe4.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        }
        this.node.add(stripe, stripe2, stripe3, stripe4)

      const pipe3 = new Konva.Line({
          points: [this.width/10, this.height*3/10,
              this.width*3/10, this.height*3/10,
              this.width*5/10, this.height*9/20,
              this.width*7/10, this.height*3/10,
              this.width*9/10, this.height*3/10,
              this.width*9/10, this.height*7/10,
              this.width/10, this.height*7/10,
              this.width/10, this.height*3/10],
          closed: true,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
        })
        if(this.type3d==0){
              pipe3.fillLinearGradientStartPoint({ x: 0, y: this.height*3/10 });
              pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*7/10});
              pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
              0.5, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
        }else{
          pipe3.fill(fillcolor2)
        }
        this.node.add(pipe3)
  
        const pipe4 = new Konva.Rect({
          x: this.width/5,
          y: 0,
          width: this.width*3/5,
          height: this.height/10,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
         strokeWidth:1
        })
        const pipe5 = new Konva.Rect({
          x: this.width/5,
          y: this.height*9/10,
          width: this.width*3/5,
          height: this.height/10,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
         strokeWidth:1
        })
        if(this.type3d==0){
              pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
              pipe4.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              pipe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
              pipe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
              pipe5.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              pipe5.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
        }else{
          pipe4.fill(fillcolor2)
          pipe5.fill(fillcolor2)
        }
        this.node.add(pipe4, pipe5)


      const stripe5 = new Konva.Rect({
          x: this.width/5,
          y: 0,
          width: this.width*3/5,
          height: this.height/50,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1  
        })
        const stripe6 = new Konva.Rect({
          x: this.width/5,
          y: this.height*4/50,
          width: this.width*3/5,
          height: this.height/50,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1  
        })
        const stripe7 = new Konva.Rect({
          x: this.width/5,
          y: this.height*9/10,
          width: this.width*3/5,
          height: this.height/50,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1  
        })
        const stripe8 = new Konva.Rect({
          x: this.width/5,
          y: this.height*49/50,
          width: this.width*3/5,
          height: this.height/50,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1  
        })
        if(this.type3d==0){
              stripe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe5.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              stripe5.fillLinearGradientColorStops([0, fillcolor2, 
                  0.5, 'white',
                  1, fillcolor2])
              stripe6.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe6.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              stripe6.fillLinearGradientColorStops([0, fillcolor2, 
                  0.5, 'white',
                  1, fillcolor2])
              stripe7.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe7.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              stripe7.fillLinearGradientColorStops([0, fillcolor2, 
                  0.5, 'white',
                  1, fillcolor2])
              stripe8.fillLinearGradientStartPoint({ x: 0, y: 0 });
              stripe8.fillLinearGradientEndPoint({ x: this.width*3/5, y: 0});
              stripe8.fillLinearGradientColorStops([0, fillcolor2, 
                  0.5, 'white',
                  1, fillcolor2])
        }else{
          stripe5.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe6.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe7.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          stripe8.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        }
        this.node.add(stripe5, stripe6, stripe7, stripe8)

      const pipe6 = new Konva.Line({
          points: [this.width*3/10, this.height/10,
              this.width*3/10, this.height*3/10,
              this.width*5/10, this.height*9/20,
              this.width*7/10, this.height*3/10,
              this.width*7/10, this.height/10,
              this.width*3/10, this.height/10],
          closed: true,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
        })
      const pipe7 = new Konva.Line({
          points: [this.width*3/10, this.height*9/10,
              this.width*3/10, this.height*7/10,
              this.width*5/10, this.height*11/20,
              this.width*7/10, this.height*7/10,
              this.width*7/10, this.height*9/10,
              this.width*3/10, this.height*9/10],
          closed: true,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
        })
        if(this.type3d==0){
              pipe6.fillLinearGradientStartPoint({ x: this.width*3/10, y: 0 });
              pipe6.fillLinearGradientEndPoint({ x: this.width*7/10, y: 0});
              pipe6.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
              0.5, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
              pipe7.fillLinearGradientStartPoint({ x: this.width*3/10, y: 0 });
              pipe7.fillLinearGradientEndPoint({ x: this.width*7/10, y: 0});
              pipe7.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
              0.55, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
        }else{
          pipe6.fill(fillcolor2)
          pipe7.fill(fillcolor2)
        }
        this.node.add(pipe6, pipe7)
        }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import Konva from "konva";
import { Rectangle } from "./rectangle";
import { Value } from '../value';

export class Cone extends Rectangle{
    aspectratio:number;
    
    public drawObject():void{
		super.initObject();
        
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
        const topwidth = (this.width*this.aspectratio)/100 
        const p = [0, this.height,
            (this.width-topwidth)/2, 0,
            (this.width-topwidth)/2 +topwidth, 0,
            this.width, this.height]

        const polygon = new Konva.Group({
            clipFunc: function (ctx) {
                
              ctx.lineTo(p[0], p[1])
              ctx.lineTo(p[2], p[3])
              ctx.lineTo(p[4], p[5])
              ctx.lineTo(p[6], p[7])
              
          }
      })
		let fillcolor = '0xffffff00';
        if (this.fill){
                let fillheight = this.height
                const fillingprop = this.properties['filling']
                if (fillingprop != null){
                    const fillingproperty:FillingProperty =Object.assign(new FillingProperty(), fillingprop);
                    fillheight = fillingproperty.getHeight(this.tagnames, this.height)
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const rectfill = new Konva.Rect({
                x: 0,
                y: this.height-fillheight,
                width: this.width,
                height: fillheight,
              });
              rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
              rectfill.fillLinearGradientEndPoint({ x: this.width, y: this.height });
              rectfill.fillLinearGradientColorStops([0, ColorUtils.convertformat(fillcolor), 0.5, 'white', 1, ColorUtils.convertformat(fillcolor)])
              polygon.add(rectfill)
        }
          
          
           

              const poly = new Konva.Line({
                points: p,
                stroke: ColorUtils.convertformat(color),
                strokeWidth: this.linewidth,
                closed: true,
              }); 
             
              //this.node.add(poly)
              polygon.add(poly)
              this.node.add(polygon)
            /*const rect = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                stroke: ColorUtils.convertformat(color),
                strokeWidth: this.linewidth,
            });

            this.node.add(rect)*/

        
        
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "aspectratio": {this[field] = value.value;this.rotatednode=null;break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "aspectratio": {value.datatype=6; value.value = this[field];break}
        }
        return value;
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { TextView } from './text'
import { Digital4DigitMeter } from './digital4digitmeter';

export class Digital8DigitMeter extends Digital4DigitMeter{

    public drawObject():void{
		super.initObject();

		let bordercolor:string = this.bordercolor;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					bordercolor = linecolorproperty.getColor(this.tagnames, this.bordercolor)
		}	
    const bordercolor2 = ColorUtils.convertformat(bordercolor)

    let textcolor:string = this.textcolor;
		const textcolorprop = this.properties['textcolor'];
		if (textcolorprop!=null){	
			const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
			if (textcolorproperty!=null) 
					textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
		}	
        const textcolor2 = ColorUtils.convertformat(textcolor)

    let value:number
    let valueproperty:ValueProperty
		const valueprop = this.properties['value'];
		if (valueprop!=null){	
			valueproperty =Object.assign(new ValueProperty(), valueprop);
			if (valueproperty!=null) 
        value = valueproperty.getTagValue(this.tagnames)
		}	

    let fillcolor = this.fillcolor;
    const fillprop = this.properties==null?null:this.properties['fillcolor'];
    if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }	
    const fillcolor2 = ColorUtils.convertformat(fillcolor)
    

		const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
          if(this.type3d==0){
              rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
              rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
              rect.fillLinearGradientColorStops([0, 'white',
                    1, bordercolor2])
          }else{
            rect.fill(ColorUtils.darkColor(bordercolor2, 0.75))
          }
    this.node.add(rect)

  const rect2 = new Konva.Rect({
    x: this.width/25,
    y: this.height/12,
    width: this.width*23/25,
    height: this.height*10/12,
  });
  if(this.type3d==0){
      rect2.fillLinearGradientStartPoint({ x: 0, y: 0 });
      rect2.fillLinearGradientEndPoint({ x: 0, y: this.height*10/12});
      rect2.fillLinearGradientColorStops([0, bordercolor2,
            1, 'white'])
  }else{
    rect2.fill(bordercolor2)
  }
this.node.add(rect2)

    const rect3 = new Konva.Rect({
      x: this.width/15,
      y: this.height/7,
      width: this.width*13/15,
      height: this.height*4/7,
      fill: fillcolor2
    });
  this.node.add(rect3)

    const labelTextOb = new TextView()
    labelTextOb.width = this.width*13/15
    labelTextOb.height = this.height/6
    labelTextOb.text = this.text;
    labelTextOb.fontsize = this.height/6
    labelTextOb.posx = this.width/15
    labelTextOb.posy = this.height*12/14-this.height/8
    labelTextOb.textplacement = 0
    labelTextOb.textcolor = ColorUtils.darkColor(bordercolor2, 0.1)
    labelTextOb.useborder = false
    labelTextOb.bordercolor = null
    labelTextOb.linewidth = 0
    labelTextOb.fill = false
    labelTextOb.fillcolor = ColorUtils.darkColor(bordercolor2, 0.1)
    labelTextOb.underline = false
    labelTextOb.initLayer(this.layer)
    labelTextOb.drawObject()
    this.node.add(labelTextOb.rotatednode)

    const digits= this.drawDigits8(this.width*12/14, this.height/2, value, valueproperty.decimalpos, textcolor2)
          digits.absolutePosition({
            x: this.width/14,
            y: this.height/6
          });
          const node1:Konva.Group = new Konva.Group();
          node1.add(digits);
          this.node.add(node1)
  }

  
private drawDigits8(width:number, height:number, value: number, decimalpos:number, color:string):Konva.Group{
    const digits = new Konva.Group()
    const numb = value.toFixed(decimalpos).toString().split('')
    let j=8;
    const length = numb.includes('.' ||',')? numb.length-1 : numb.length
    for(let i=numb.length-1; i>=length-8; i--){
     
      let chdigit = 'N'
      if(i>=0) chdigit =numb[i]
        if (i!=numb.length-1){
        const dot = new Konva.Rect({
          x: width*(5+6*(j-1))/48,
          y: height*9/10,
          width: width/60,
          height: height/10,
        })
       
        if(chdigit=='.' || chdigit==','){
          dot.fill(color)  
         i=i-1
         chdigit=numb[i]
        }else{
          dot.fill(ColorUtils.darkColor(color,0.15))  
        }
        digits.add(dot)
      }

        j--
      const dig = this.drawDigit(width*5/50, height, chdigit, color)
      dig.absolutePosition({
        x: width*6*j/48,
        y: 0
      })
      digits.add(dig)
    }
    return digits
  }
    
  
  
  }
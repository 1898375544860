
import { defineComponent, ref} from 'vue'
import AuthModule from '../store/auth.module'
import user from '../model/user'
import message from '../model/message'
import response from "@/model/response";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  
  setup(){
    const name = ref(null);
    const email = ref(null);
    const password = ref(null);
    const userpassword = ref(null);
    const confpassword = ref(null);
    const confuserpassword = ref(null);
    const loading = ref(false);
    const message = ref("");
    const successful = ref(false);
    const visibilityIcon = ref('visibility');
    const passwordFieldType = ref('password');
    const visibility = ref(false);
    const {t} = useI18n({useScope:'global'}) 

  return {confpassword,confuserpassword,visibilityIcon,passwordFieldType,visibility,
  name,email,password,userpassword,loading,message,successful,t}
  },
  computed: {
    loggedIn() {
      return AuthModule.isLoggedIn;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
     switchVisibility() {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon =  this.visibility ? 'visibility_off' : 'visibility'
    },
     diffPassword (val:string) {
       const val2 = this.password
       return (val && (val===val2) || this.t('profile.passwordsmustmatch'))
     },
     diffUserPassword (val:string) {
       const val2 = this.userpassword
       return (val && (val===val2) || this.t('profile.userpasswordsmustmatch'))
     },
    isValidEmail (val:string) {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || this.t('profile.invalidemail');
  },
    handleRegister() {
      this.message = "";
      this.successful = false;
      this.loading = true;
      let data:user = {} as user;
      data.username = this.name;
      data.email = this.email;
      data.userpassword = this.userpassword;
      data.password = this.password;
      AuthModule.register(data)
      .then(
        (data:response) => {
          console.log(data);
          this.message = (data.body as message).message;
          if (data.statusCodeValue==200)
            this.successful = true;
          else
            this.successful  =  false;
          
          //this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
});


import { SVGObject } from "../svgobject";

export class SVGValve28 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.76 112.5" enable-background="new 0 0 56.76 112.5" xml:space="preserve">
        <g id="Group_BodyAndPort">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="28.3081" y1="104.9551" x2="28.3081" y2="93.417">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="5.423" y="93.417" fill="url(#SVGID_1_)" width="45.77" height="11.538" />
        </g>
        <g id="Group_Port2">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="3.6895" y1="112.5" x2="3.6895" y2="86.374">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect y="86.374" fill="url(#SVGID_2_)" width="7.379" height="26.126" />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="52.9854" y1="112.5" x2="52.9854" y2="86.374">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="49.213" y="86.374" fill="url(#SVGID_3_)" width="7.545" height="26.126" />
        </g>
        <g id="Group_SafetyValve">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="19.0317" y1="24.8311" x2="37.6133" y2="24.8311">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M19.032,49.212v-9.799v-4.841V24.89v-4.958V5.409c0,0,3.191-4.959,9.253-4.959   c5.781,0,9.328,4.959,9.328,4.959v14.522v4.958v9.682v4.841v9.799H19.032" />
            
                <radialGradient id="SVGID_5_" cx="28.3223" cy="2.8154" r="6.7792" gradientTransform="matrix(-4.371139e-008 -1 1.5 -6.556708e-008 24.0991 31.1377)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M19.032,5.18c0,0,3.191-4.73,9.253-4.73c5.781,0,9.328,4.73,9.328,4.73" />
            <rect x="19.032" y="44.595" fill="#4C4C4C" width="18.582" height="0.488" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="14.415" y1="21.3965" x2="42.2305" y2="21.3965">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="14.415" y="19.032" fill="url(#SVGID_6_)" width="27.815" height="4.729" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="14.415" y1="35.3042" x2="42.2305" y2="35.3042">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="14.415" y="32.996" fill="url(#SVGID_7_)" width="27.815" height="4.617" />
        </g>
        <g id="Group_ValveBody">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="13.8521" y1="52.1401" x2="42.8503" y2="81.1384">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="37.613,46.959 19.032,46.959 16.78,49.212 16.78,84.009 19.032,86.374 37.613,86.374    39.979,84.009 39.979,49.212  " />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="21.3975" y1="88.626" x2="35.3614" y2="102.59">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polygon fill="url(#SVGID_9_)" points="32.996,86.374 23.762,86.374 21.397,88.626 21.397,102.59 23.762,104.955 32.996,104.955    35.361,102.59 35.361,88.626  " />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="28.2954" y1="49.2119" x2="28.2954" y2="84.0093">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <rect x="18.962" y="49.212" fill="url(#SVGID_10_)" width="18.667" height="34.797" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="23.6914" y1="95.5938" x2="32.9414" y2="95.5938">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <rect x="23.691" y="88.626" fill="url(#SVGID_11_)" width="9.25" height="13.937" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { StringUtils } from "../utils/stringutils";
import Konva from "konva";
import { Button } from './button';

export class LeftJumpButton extends Button{
    
    public drawObject():void{
        super.initObject();

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
    
          const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, -this.height/2, 0, this.height/2);
              gradient.addColorStop(0, 'white')
              gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

          
            
            const p1 = [

                this.width, this.height/5,
                this.width*2/5, this.height/5,
                this.width*2/5, 0,
                0, this.height/2,
                this.width*2/5, this.height,
                this.width*2/5, this.height*4/5,
                this.width, this.height*4/5,
                this.width, this.height/5
            ]
            
            const lineout = new Konva.Line({
            points:p1,
            closed: true

          });
                if(this.type3d==0){
                lineout.fillLinearGradientStartPoint({ x: 0, y: 0});
                lineout.fillLinearGradientEndPoint({ x: 0, y: this.height });
                lineout.fillLinearGradientColorStops([0, 'white', 
                1, ColorUtils.darkColor(fillcolor2,0.5)]);
            }else{
              lineout.fill(ColorUtils.darkColor(fillcolor2,0.75))
            }
          this.node.add(lineout); 
        
          const p2 = [
            this.width*9/10, this.height*3/10,
            this.width*3/10, this.height*3/10,
            this.width/10, this.height/2, 
            this.width*3/10, this.height*7/10, 
            this.width*9/10, this.height*7/10,
            this.width*9/10, this.height*3/10
          ]
          const lineinner = new Konva.Line({
            points:p2,
            closed: true

          });
                if(this.type3d==0){
                lineinner.fillLinearGradientStartPoint({ x: 0, y: this.height*3/10});
                lineinner.fillLinearGradientEndPoint({ x: 0, y: this.height*7/10 });
                lineinner.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.5), 
                1, 'white']);
            }else{
              lineinner.fill(fillcolor2)
            }
          this.node.add(lineinner); 


          
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
            
                const Text = new Konva.Text({
                    x: this.width/10+this.width/6,
                    y: 0, 
                    verticalAlign:"middle",
                    width: this.width*8/10-this.width/6,
                    height: this.height,
                    text: text,
                    fontSize: this.fontsize==0? this.height/4 : this.fontsize,
                    fontFamily: StringUtils.getFontFamily(this.fonttype),
                    fontStyle: StringUtils.getFontStyle(this.fonttype),
                    align:StringUtils.getTextPlacement(this.textplacement),
                    fill: ColorUtils.convertformat(textcolor),
                    textDecoration: this.underline?"underline":""
                  });

                  if(this.type3d==0){
                    
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                  this.setFunctions();

                             
    }
   
}
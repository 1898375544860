import { SVGObject } from "../svgobject";

export class Reactor6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 93.902 112.5" enable-background="new 0 0 93.902 112.5" xml:space="preserve">
        <g id="Group_BackLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="76.541" y1="87.5879" x2="85.8984" y2="87.5879">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M85.898,67.861h-9.357v23.221h1.917v16.232h5.523V91.082h1.917V67.861z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="27.168" y1="87.5879" x2="36.5239" y2="87.5879">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M36.524,67.861h-9.356v23.221h1.916v16.232h5.524V91.082h1.916V67.861z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="29.084" y1="99.1982" x2="34.6079" y2="99.1982">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M29.084,91.082v16.232h5.524V91.082H29.084z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="78.457" y1="99.1982" x2="83.9814" y2="99.1982">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M78.457,91.082v16.232h5.524V91.082H78.457z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="76.541" y1="108.4043" x2="85.8984" y2="108.4043">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="83.08,107.146 76.541,107.146 76.541,109.661 83.08,109.661 85.898,109.661 85.898,107.146     " />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="27.168" y1="108.4043" x2="36.5239" y2="108.4043">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polygon fill="url(#SVGID_6_)" points="33.706,107.146 27.168,107.146 27.168,109.661 33.706,109.661 36.524,109.661    36.524,107.146  " />
        </g>
        <g id="Group_TankBody">
            
                <radialGradient id="SVGID_7_" cx="46.895" cy="21.7563" r="33.2099" gradientTransform="matrix(1 0 0 1.4941 0 -11.182)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.03" style="stop-color:#E6E6E6" />
                <stop offset="0.7" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M0.452,28.783h92.886L79.473,13.865H14.43L0.452,28.783" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="7.3281" y1="17.501" x2="86.4609" y2="17.501">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.48" style="stop-color:#878787" />
                <stop offset="0.6" style="stop-color:#747474" />
                <stop offset="0.84" style="stop-color:#444444" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M86.461,21.531c-46.99-10.981-79.133,0-79.133,0l7.66-8.035l63.903-0.025L86.461,21.531z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0.4521" y1="47.0068" x2="93.3379" y2="47.0068">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M0.452,28.407v37.2h92.886v-37.2H0.452" />
            
                <radialGradient id="SVGID_10_" cx="46.895" cy="77.3506" r="33.8509" gradientTransform="matrix(1 0 0 1.9154 0 -71.0336)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M0.452,65.418l23.221,23.41h46.443l23.222-23.41" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="23.6733" y1="91.2305" x2="70.1162" y2="91.2305">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.49" style="stop-color:#ECECEC" />
                <stop offset="0.56" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M23.673,88.731l23.222,4.997l23.221-4.997" />
        </g>
        <g id="Group_Top">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="0.4521" y1="4.1895" x2="93.3379" y2="4.1895">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.53" style="stop-color:#D9D9D9" />
                <stop offset="0.69" style="stop-color:#A8A8A8" />
                <stop offset="0.91" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M93.338,7.816l-2.255-7.252H2.819L0.452,7.816" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="0.4521" y1="10.9346" x2="93.3379" y2="10.9346">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#999999" />
                <stop offset="0.61" style="stop-color:#868686" />
                <stop offset="0.8" style="stop-color:#555555" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M79.473,14.429h11.61l2.255-6.989H0.452l2.367,6.989h11.61" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="5.1865" y1="110.7715" x2="16.7974" y2="110.7715">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_14_)" points="13.303,109.514 5.187,109.514 5.187,112.029 13.303,112.029 16.797,112.029    16.797,109.514  " />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="5.1865" y1="88.7676" x2="16.7974" y2="88.7676">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M16.797,78.908c0,0,0-5.742-3.178-8.242c-3.943-3.102-8.433-2.805-8.433-2.805v23.221h2.254v18.6   h6.989v-18.6h2.368V78.908" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="57.9414" y1="88.7891" x2="69.5527" y2="88.7891">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M69.553,75.389c0-10.389-11.611-9.639-11.611,0.188v15.505h2.367v18.6h6.99v-18.6h2.254" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="60.2529" y1="100.3818" x2="67.2422" y2="100.3818">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M60.253,91.082v18.6h6.989v-18.6" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="7.4971" y1="100.3818" x2="14.4863" y2="100.3818">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M7.497,91.082v18.6h6.989v-18.6" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="57.9414" y1="110.7715" x2="69.5527" y2="110.7715">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_19_)" points="66.059,109.514 57.941,109.514 57.941,112.029 66.059,112.029 69.553,112.029    69.553,109.514  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.16145833333333334, 214.0, 0.03, 0.20833333333333334, 202.0, 0.7, 0.9322916666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.23958333333333334, 0.0, 0.48, 0.9479166666666666, 13.0, 0.6, 0.9010416666666666, 0.0, 0.84, 0.5260416666666666, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.49, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.53, 0.3020833333333333, 177.0, 0.69, 0.6875, 79.0, 0.91, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.23958333333333334, 0.0, 0.51, 0.8072916666666666, 49.0, 0.61, 0.953125, 11.0, 0.8, 0.6614583333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
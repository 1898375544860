import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import { Point } from "../../utils/point";
import { CatmullRom } from "../../utils/catmullrom";
import { Statistics } from "../../utils/statistics";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";

export class SmoothedChartTile extends Tile{
    smoothing= false;
    charttype: number;
    minimum: number; 
    maximum: number;
    fontsize: number;
    sectors: any[] = [];
    sectors2: any[] = [];
    sectors3: any[] = [];

  public drawObject():void{
    super.initObject();

    const group = this.drawImage()
    this.node.add(group) 
    
  }

  private drawImage():Konva.Group{

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }
			
    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      }
    });
     
    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
      x: size*.05,
      y: size*0.1 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(titleText) 
    
  const minX  = this.width*0.15;
	const maxX  = minX + this.width*.8;
	const minY  = height*0.25;
	const maxY  = minY + this.height*.5;
    const stepY = (maxY-minY)/2;

    let y= minY
    for (let i=0;i<=2;i++) {
        const line = new Konva.Line({
            points: [minX,y,maxX,y],
            stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
            strokeWidth: 1,
          });
          group.add(line)
          y+=stepY
    }

    const maxtextTransparent = new Konva.Text({
        x: 0,
        y: 0,
        verticalAlign:"bottom",
        text: this.maximum.toFixed(0).toString(),
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    const mintextTransparent = new Konva.Text({
        x: 0,
        y: 0,
        verticalAlign:"bottom",
        text: this.minimum.toFixed(0).toString(),
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    const average  = ((this.maximum-this.minimum)/2+this.minimum).toFixed(0).toString()
    const avtextTransparent = new Konva.Text({
        x: 0,
        y: 0,
        verticalAlign:"middle",
        text: average,
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  

    let maxwidth=maxtextTransparent.width();
    if (maxwidth<mintextTransparent.width()) maxwidth=mintextTransparent.width();
    if (maxwidth<avtextTransparent.width()) maxwidth=avtextTransparent.width();
    
    const maxtext = new Konva.Text({
        x: (minX+maxwidth)/2-maxtextTransparent.width(),
        y: minY- this.fontsize/2,
        verticalAlign:"middle",
        text: this.maximum.toFixed(0).toString(),
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(maxtext)

    const mintext = new Konva.Text({
        x: (minX+maxwidth)/2-mintextTransparent.width(),
        y: 	maxY-this.fontsize/2,
        verticalAlign:"middle",
        text: this.minimum.toFixed(0).toString(),
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(mintext)
    const avText = new Konva.Text({
        x: (minX+maxwidth)/2-avtextTransparent.width(),
        y: 	(minY+maxY)/2-this.fontsize/2,
        verticalAlign:"middle",
        text: average,
        fontSize: this.fontsize,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(avText)

    let sectorlabels = []
    
    this.sectors.forEach((sec)=>{
        sectorlabels.push(sec.sectorname)
        this.tagnames.push(sec.path)
    })
    this.sectors2.forEach((sec)=>{
        sectorlabels.push(sec.sectorname)
        this.tagnames.push(sec.path)
    })
    this.sectors3.forEach((sec)=>{
        sectorlabels.push(sec.sectorname)
        this.tagnames.push(sec.path)
    })
    sectorlabels = sectorlabels.filter((value, index, array) => array.indexOf(value) === index);
    //console.log('sectorlabels', sectorlabels)

    const deltax =  this.width*0.8/(sectorlabels.length+1);
    sectorlabels.forEach((label, index)=>{
        const tickTextTransparent = new Konva.Text({
            x: 0,
            y: this.height-size*0.1-this.fontsize,
            verticalAlign:"middle",
            text: label,
            fontSize: this.fontsize,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
        }); 
        const tickText = new Konva.Text({
            x: this.width*.15+deltax*(index+1)-tickTextTransparent.width()/2,
            y: this.height-size*0.1-this.fontsize,
            verticalAlign:"middle",
            text: label,
            fontSize: this.fontsize,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
        }); 
        group.add(tickText)
    })

    if (this.sectors.length !=0){
      const sectors = this.sectors
			const curve  = this.drawChart(deltax, sectors, this.charttype, sectorlabels, minY, maxY);
       group.add(curve)
    }

    if (this.sectors2.length !=0){
      const sectors = this.sectors2
			const curve  = this.drawChart(deltax, sectors, this.charttype,  sectorlabels, minY, maxY);
       group.add(curve)
    }

    if (this.sectors3.length !=0){
      const sectors = this.sectors3
			const curve  = this.drawChart(deltax, sectors, this.charttype, sectorlabels, minY, maxY);
       group.add(curve)
    }
      
      return group
     }

  

private addPoints(sectors:any[], label:string, i:number, deltax, charttype:number, points:any[]):any[]{

  sectors.forEach((sector)=>{
    const tag:tag = tagsModele.getTag(sector.path)
    if (tag==null) return;
    if (sector.sectorname ==label) {
      if(points.length==0 && charttype==1) points.push(this.width*.15+deltax*i, this.height*.75)
      points.push(this.width*.15+deltax*i, 
        (this.maximum-Number(tag.tagvalue))/(this.maximum-this.minimum)*this.height*.5+this.height*.25)
      }
  })
  return points;
}

protected drawChart(deltax:number, sectors:any[], charttype: number, sectorlabels, minY:number, maxY:number):Konva.Group {

let polygonpoints = []
let curvepoints = []
const group = new Konva.Group({
  x: 0,
  y: 0,
});
sectorlabels.forEach((label, index)=> {
  if (sectors.length != 0) {
    if (this.charttype==1){
      polygonpoints = this.addPoints(sectors,label,index+1,deltax, 1, polygonpoints);
      curvepoints = this.addPoints(sectors,label,index+1,deltax, 0, curvepoints);
    }else{
      curvepoints = this.addPoints(sectors,label,index+1,deltax, this.charttype, curvepoints);
    }	
  }
  })

    if(this.smoothing){
      if(polygonpoints.length !=0){
        const firstpoint = []
        firstpoint.push(polygonpoints[0])
        firstpoint.push(polygonpoints[1])
        polygonpoints = polygonpoints.splice(2)
        
        const smoothPointsPolygon = Statistics.subdividePoints(Statistics.devideToPoints(polygonpoints))
        polygonpoints = firstpoint
        smoothPointsPolygon.forEach((point)=>{
        polygonpoints.push(point.x, point.y)
        })
      }
    
      if(curvepoints.length !=0){
        const smoothPointsCurve = Statistics.subdividePoints(Statistics.devideToPoints(curvepoints))
        curvepoints = []
        smoothPointsCurve.forEach((point)=>{
        curvepoints.push(point.x, point.y)
        })
      }
    }
      
      if(curvepoints.length !=0){
        if(this.charttype == 1){
          polygonpoints.push(polygonpoints[polygonpoints.length-2], this.height*.75)
          const polygon = new Konva.Line({
            points: polygonpoints,
            closed: true,
          });
          const pointY= polygonpoints.filter((el, index)=>{
            if(index%2 != 0) return el
          })
          const minPointY = Math.min(...pointY)
         // console.log('minPointY', minPointY, maxY)
          polygon.fillLinearGradientStartPoint({ x: 0, y: minPointY});
          polygon.fillLinearGradientEndPoint({ x: 0, y: this.height});
          polygon.fillLinearGradientColorStops([0, ColorUtils.convertformat(sectors[0].color), 1, 'transparent'])
          group.add(polygon)
        }
      
          const curve = new Konva.Line({
            points: curvepoints,
            stroke: ColorUtils.convertformat(sectors[0].color),
            strokeWidth: this.height/100<1 ? 1 : this.height/100,
          });
          group.add(curve)
          polygonpoints = []
          curvepoints = []
    }
  
return group
  
}

/*private devideToPoints(points:any[]){
  const POINTS = []
  if(points.length != 0){
    points.forEach((el, index)=>{
      if(index !=0 && index%2 !=0)return
      if(index == points.length+1)return
      const p = new Point(el, points[index+1])
      POINTS.push(p)
    })
    return POINTS
  }
}

private subdividePoints(POINTS:any[], SUB_DEVISIONS = 64) {
  if (POINTS==null || POINTS.length<3) return POINTS
  const noOfPoints = POINTS.length;
  const subdividedPoints = [];

  const increments = 1/SUB_DEVISIONS;

  for (let i = 0 ; i < noOfPoints - 1 ; i++) {
      const p0 = i == 0 ? POINTS[i] : POINTS[i - 1];
      const p1 = POINTS[i];
      const p2 = POINTS[i + 1];
      const p3 = (i+2 == noOfPoints) ? POINTS[i + 1] : POINTS[i + 2];

      const crs = new CatmullRom(p0, p1, p2, p3);

      for (let j = 0; j <= SUB_DEVISIONS; j++) {
          subdividedPoints[(i * SUB_DEVISIONS) + j] = crs.q(j * increments);
      }
  }
  //console.log('subdividedPoints', subdividedPoints)
  return subdividedPoints;
}*/
}


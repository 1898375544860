import { SVGObject } from "../svgobject";

export class SVGDuct19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 100.785 112.5" enable-background="new 0 0 100.785 112.5" xml:space="preserve">
        <g id="Group_Bend_Duct">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="68.7043" y1="70.2627" x2="-13.2467" y2="22.9482">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.39" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" d="M88.399,36.149L52.589,0.227L0,0.001v91.327h14.301l9.46,9.459   L88.399,36.149z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M52.589,0.227L14.301,91.328" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M18.13,98.31l11.937,12.162l-2.027,2.026l-11.937-12.161L18.13,98.31z   " />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M88.623,27.816l12.162,11.937l-2.026,2.252L86.597,29.843   L88.623,27.816z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="60.4719" y1="72.1846" x2="56.4178" y2="68.1304">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.13,106.418l4.054,4.054l76.575-76.575l-4.055-4.054   L18.13,106.418z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.39, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
import SVGColorCorrection from "./SVGColorCorrection";

class SVGGradient{
    id: string;
    stops:SVGColorCorrection[]

    constructor(id:string) {
      this.id = id
      this.stops= []
      }
    public addStop(offset:number,mul:number, add:number):void {
        this.stops.push(new SVGColorCorrection(offset,mul, add))
    }
    public getSVGCorrection(id:number):SVGColorCorrection{  
        //console.log(id)
        //console.log(this.stops)
        
        return this.stops.find(x => x.offset == id);
    }
}
export default SVGGradient
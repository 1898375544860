import { SVGObject } from "../svgobject";

export class SVGPaper9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 86.602" enable-background="new 0 0 112.5 86.602" xml:space="preserve">
        <g id="Group_Base">
            <path fill="#000066" d="M0,74.889h16.329v1.126H0V74.889z" />
            <path fill="#000066" d="M16.329,81.871h39.865v1.801H16.329V81.871z" />
        </g>
        <g id="Group_Body">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="51.4243" y1="63.4365" x2="35.8091" y2="14.8558">
                <stop offset="0" style="stop-color:#89A3D6" />
                <stop offset="1" style="stop-color:#98B2E5" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M18.581,0.001h37.613v39.527H18.581V0.001z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="56.6309" y1="81.7852" x2="16.2282" y2="39.9617">
                <stop offset="0" style="stop-color:#89A3D6" />
                <stop offset="1" style="stop-color:#98B2E5" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M16.329,39.528h39.865v42.343H16.329V39.528z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="16.4985" y1="74.832" x2="-0.0633" y2="25.9356">
                <stop offset="0" style="stop-color:#89A3D6" />
                <stop offset="1" style="stop-color:#98B2E5" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0,25.564h16.329v49.324H0V25.564z" />
            <path fill="#657FB2" stroke="#4C4C4C" stroke-width="0.25" d="M101.914,20.834h1.127l1.014,22.297h-3.267L101.914,20.834z" />
            <path fill="#98B2E5" d="M56.194,43.132H112.5v42.343H56.194V43.132z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,43.132H112.5v42.343H56.194V43.132" />
            <path fill="#7F99CC" stroke="#4C6699" stroke-width="0.25" d="M3.604,2.141h14.978v16.216H3.604V2.141z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,25.564h16.329v49.324H0V25.564" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.329,39.528h39.865v42.343H16.329V39.528" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.581,0.001h37.613v39.527H18.581V0.001" />
        </g>
        <g id="Group_InnerPart">
            <path fill="#4C6699" d="M43.356,20.834h12.838v18.694H43.356V20.834z" />
            <path fill="#324C7F" d="M44.482,20.834h2.252l2.478,18.694h-2.478L44.482,20.834z" />
            <path fill="#324C7F" d="M51.464,24.889h1.126v14.64h-1.126V24.889z" />
            <path fill="#324C7F" d="M43.356,28.943h10.585v7.207H43.356V28.943z" />
        </g>
        <g id="Group_Support">
            <path fill="#7F99CC" stroke="#4C4C4C" stroke-width="0.25" d="M53.941,81.871h2.252V86.6h-2.252V81.871z" />
            <path fill="#7F99CC" stroke="#4C4C4C" stroke-width="0.25" d="M30.293,81.871h2.478V86.6h-2.478V81.871z" />
            <path fill="#7F99CC" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,74.889h2.252V86.6H1.014V74.889z" />
        </g>
        <g id="Group_Junctions">
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M21.059,37.276V20.834h15.315" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M21.059,37.276h15.315V20.834" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M38.626,18.583h15.315V2.141" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M24.437,18.583h11.937V5.632" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M38.626,18.583V2.141h15.315" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M24.437,18.583V5.632h11.937" />
        </g>
        <g id="Group_Shadow">
            <path fill="#B2CCFF" d="M56.194,51.24H112.5v0.676H56.194V51.24z" />
            <path fill="#B2CCFF" d="M56.194,57.32H112.5v0.451H56.194V57.32z" />
            <path fill="#B2CCFF" d="M56.194,63.177H112.5v0.45H56.194V63.177z" />
            <path fill="#B2CCFF" d="M56.194,69.033H112.5v0.45H56.194V69.033z" />
            <path fill="#B2CCFF" d="M56.194,74.889H112.5v0.45H56.194V74.889z" />
            <path fill="#B2CCFF" d="M56.194,80.744H112.5v0.451H56.194V80.744z" />
            <path fill="#B2CCFF" d="M56.194,43.132H112.5v0.676H56.194V43.132z" />
        </g>
        <g id="Group_Dividers">
            <path fill="#003399" d="M56.194,51.465H112.5v-3.604H56.194V51.465z" />
            <path fill="#003399" d="M56.194,57.32H112.5v-3.604H56.194V57.32z" />
            <path fill="#003399" d="M56.194,63.177H112.5v-3.604H56.194V63.177z" />
            <path fill="#003399" d="M56.194,69.033H112.5V65.43H56.194V69.033z" />
            <path fill="#003399" d="M56.194,74.889H112.5v-3.604H56.194V74.889z" />
            <path fill="#003399" d="M56.194,80.744H112.5v-3.604H56.194V80.744z" />
        </g>
        <g id="Group_Stripe">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,40.879h2.251v40.992h-2.251V40.879z" />
        </g>
        <g id="Group_MainConnector">
            <path fill="#98B2E5" d="M62.725,24.889h20.947v20.496H62.725V24.889z" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M62.725,23.087h20.947v22.298H62.725V23.087" />
            <path fill="#98B2E5" stroke="#4C6699" stroke-width="0.25" d="M37.5,52.592h19.82v30.404H37.5V52.592z" />
        </g>
        <g id="Group_Main">
            <path fill="#CCCCCC" d="M31.644,41.33h3.378v8.783h-3.378V41.33z" />
        </g>
        <g id="Group_Connector">
            <path fill="#E5E5E5" d="M62.859,24.878h20.672v-1.655H62.859V24.878z" />
        </g>
        <g id="Group_Frame">
            <path fill="#333333" d="M68.581,26.69h9.234v2.928h-9.234V26.69z" />
        </g>
        <g id="Group_Switches">
            <path fill="#FFCC00" d="M4.054,14.979h14.077v2.928H4.054V14.979z" />
            <path fill="#FFCC00" d="M11.599,2.591h5.856v1.802h-5.856V2.591z" />
            <path fill="#FFCC00" d="M70.158,26.69h5.855v1.802h-5.855V26.69z" />
        </g>
        <g id="Group_Pole">
            <path fill="#98B2E5" stroke="#4C4C4C" stroke-width="0.25" d="M100.788,54.393h3.604v31.532h-3.604V54.393z" />
            <path fill="#98B2E5" stroke="#4C4C4C" stroke-width="0.25" d="M101.238,39.528h2.479v43.019h-2.479V39.528z" />
            <path fill="#98B2E5" stroke="#4C4C4C" stroke-width="0.25" d="M101.914,20.834h1.127v18.694h-1.127V20.834z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6666666666666666, 85.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6666666666666666, 85.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6666666666666666, 85.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.2604166666666667, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.9479166666666666, 13.0, 1.0, 0.546875, 115.0, 2.0, 0.7447916666666666, 65.0]);
		this.fillGradient(this.fills,"Group_InnerPart",[0.0, 0.8541666666666666, 0.0, 1.0, 0.6510416666666666, 0.0, 2.0, 0.6510416666666666, 0.0, 3.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.7447916666666666, 65.0, 1.0, 0.7447916666666666, 65.0, 2.0, 0.7447916666666666, 65.0]);
		this.fillGradient(this.fills,"Group_Shadow",[0.0, 0.34375, 167.0, 1.0, 0.34375, 167.0, 2.0, 0.34375, 167.0, 3.0, 0.34375, 167.0, 4.0, 0.34375, 167.0, 5.0, 0.34375, 167.0, 6.0, 0.34375, 167.0]);
		this.fillGradient(this.fills,"Group_Dividers",[0.0, 0.5260416666666666, 0.0, 1.0, 0.5260416666666666, 0.0, 2.0, 0.5260416666666666, 0.0, 3.0, 0.5260416666666666, 0.0, 4.0, 0.5260416666666666, 0.0, 5.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_MainConnector",[0.0, 0.546875, 115.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.fills,"Group_Main",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Connector",[0.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Switches",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Pole",[0.0, 0.546875, 115.0, 1.0, 0.546875, 115.0, 2.0, 0.546875, 115.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class Reactor8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 83.117 112.5" enable-background="new 0 0 83.117 112.5" xml:space="preserve">
        <g id="Group_BackLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="15.3923" y1="94.4492" x2="20.7097" y2="94.4492">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M20.71,88.433v19.31h-5.317V81.156L20.71,88.433z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="61.8474" y1="94.4492" x2="67.1648" y2="94.4492">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M61.847,88.433v19.31h5.317V81.156L61.847,88.433z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="7.324219e-004" y1="62.4072" x2="83.116" y2="62.4072">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0.001,31.343L0.001,31.343v41.418v0.001c0,0,0.542,5.021,5.317,9.796   c3.059,3.059,17.177,10.912,36.38,10.912c19.921,0,33.398-8.039,36.457-11.098c4.631-4.631,4.961-9.61,4.961-9.61v-0.001V31.344   v-0.001H0.001z" />
            
                <radialGradient id="SVGID_4_" cx="41.5583" cy="23.4717" r="30.7632" gradientTransform="matrix(1 0 0 1.6431 0 -13.831)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.23" style="stop-color:#C4C4C4" />
                <stop offset="0.76" style="stop-color:#6E6E6E" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M0.001,33.319c0,0,2.558,5.518,41.418,5.518c37.39,0,41.697-5.518,41.697-5.518   s0.028-4.257-3.962-9.089c-5.214-6.311-16.676-13.596-37.846-13.596c-19.508,0-31.769,7.081-37.271,13.109   C-0.016,28.183,0.001,33.319,0.001,33.319z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="54.947" y1="9.8237" x2="72.9468" y2="14.6468">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M71.923,18.47l2.519-9.795L56.53,3.918l-2.519,9.795c0,0-0.441,1.995,8.396,4.478   C71.224,20.667,71.923,18.47,71.923,18.47z" />
            
                <radialGradient id="SVGID_6_" cx="65.4861" cy="4.7715" r="7.1756" gradientTransform="matrix(0.7071 -0.7071 0.8485 0.8485 15.1317 47.0284)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.16" style="stop-color:#D9D9D9" />
                <stop offset="0.48" style="stop-color:#A8A8A8" />
                <stop offset="0.93" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" d="M67.154,0.56c-8.268-2.27-10.635,3.358-10.635,3.358s0.2,2.373,8.406,4.678   c7.258,2.039,9.505,0.08,9.505,0.08S75.246,2.781,67.154,0.56z" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="4.7576" y1="94.5547" x2="11.7546" y2="94.5547">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M11.755,86.474V112.5H4.758V76.959c0,0,1.667-1.428,4.248,1.476   C11.006,80.685,11.755,86.474,11.755,86.474z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="70.8025" y1="94.582" x2="77.7986" y2="94.582">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M70.802,86.474V112.5h6.996V76.959c0,0-1.357-1.425-4.1,1.984   C71.386,81.818,70.802,86.474,70.802,86.474z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.16145833333333334, 214.0, 0.23, 0.46875, 135.0, 0.76, 0.8541666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.3020833333333333, 177.0, 0.48, 0.6875, 79.0, 0.93, 0.6979166666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
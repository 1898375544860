import { SVGObject } from "../svgobject";

export class SVGVehicle10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20000303 Stylable//EN" "http://www.w3.org/TR/2000/03/WD-SVG-20000303/DTD/svg-20000303-stylable.dtd"[]><svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 500 412"><path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M140,44 l0,287 7,0 0,-287 -7,0z" /><path style="fill:#000;stroke:#4c4c4c;stroke-width:2" d="M132,390 l0,-390 -22,0 0,375 -110,0 0,15 132,0z" /><path style="fill:#000;stroke:#4c4c4c;stroke-width:2" d="M176,272 l0,-95 30,0 7,7 8,-7 -22,-22 7,-8 44,44 -7,8 -15,-15 -7,7 22,22 0,52 66,0 0,-7 -4,0 -3,0 -4,0 -4,0 -2,-2 -1,-6 1,-5 2,-2 48,0 4,-30 -7,0 -7,0 -7,0 -7,0 -7,0 -7,0 -7,0 -7,0 -2,-2 -2,-5 -1,-5 2,-2 59,0 7,-66 15,0 0,139 -192,0z" /><path style="fill:#c30;stroke:#4c4c4c;stroke-width:2" d="M162,375 l0,-110 323,0 0,110 -323,0z" /><circle style="fill:#4c4c4c;stroke:#4c4c4c;stroke-width:2" cx="434" cy="368" r="44" /><circle style="fill:#4c4c4c;stroke:#4c4c4c;stroke-width:2" cx="176" cy="353" r="58" /><circle style="fill:#191919;stroke:#4c4c4c;stroke-width:2" cx="434" cy="368" r="22" /><circle style="fill:#191919;stroke:#4c4c4c;stroke-width:2" cx="176" cy="353" r="29" /><path style="fill:#e5e5e5;stroke:#4c4c4c;stroke-width:2" d="M243,346 l51,0 0,7 -51,0 0,-7z" /><path style="fill:#e5e5e5;stroke:#4c4c4c;stroke-width:2" d="M243,309 l51,0 0,7 -51,0 0,-7z" /><path style="fill:#c30;stroke:#4c4c4c;stroke-width:2" d="M228,274 l30,114 -7,2 -30,-114 7,-2z" /><path style="fill:#f60;stroke:#4c4c4c;stroke-width:2" d="M118,272 l114,0 0,15 -114,0 0,-15z" /><path style="fill:#f60;stroke:#4c4c4c;stroke-width:2" d="M500,383 l-7,-147 -111,0 -29,-221 -162,0 -29,221 0,29 14,0 0,-29 30,-192 132,0 30,192 0,29 29,0 0,15 -103,0 0,103 88,0 15,-74 74,0 14,74 15,0z" /></svg>`
		super.fillGradients();
	
        super.drawObject();
    }
}
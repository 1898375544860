import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";
import { BarChartTile } from "./barcharttile";


export class RadialChartTile extends BarChartTile{

  public drawObject():void{
    super.initObject();

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    if(this.sectors.length !=0){
      this.sectors.forEach((sec)=>{
        if (sec['path']==null || tagsModele.getTag(sec['path'])==null) return;
        if (!this.tagnames.includes(sec['path'])) this.tagnames.push(sec['path'])
      })
    }

    if(this.sectors.length > 1){
      this.sectors.sort(function(a, b) {
        if (a==null || b==null) return 0;
        const keyA = tagsModele.getTag(a['path'])
        const keyB = tagsModele.getTag(b['path'])
        if (keyA==null || keyB==null || keyA.tagvalue==null || keyB.tagvalue==null) return 0;
        if (Number(keyA.tagvalue) < Number(keyB.tagvalue)) return 1;
        if (Number(keyA.tagvalue) > Number(keyB.tagvalue)) return -1;
        return 0;
      });
    }

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      }
    });
     
    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
      x: size*.05,
      y: size*0.1 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(titleText) 
    
    if(this.description){
      const text2 = new Konva.Text({
        x: size*.05,
        y: this.height - size*0.05 - 0.06*size,
        width: this.width-size*.05*2,
        verticalAlign: 'bottom',
        text: this.description,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left',
      }); 
      group.add(text2)
    }
    
    const canvasSize = this.description != null ? size * 0.68 : size * 0.795;
    const radius = canvasSize * 0.5;
    const innerSpacer = radius * 0.18;
    const barWidth = (radius - innerSpacer) / this.sectors.length
      
    const centerX = this.width/2;
    const centerY = size*.15+ radius;

    const line = new Konva.Line({
      points: [centerX, size*.15, centerX, centerY],
      stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
      strokeWidth: 1,
    });
    group.add(line)

    const line2 = new Konva.Line({
      points: [centerX-radius, centerY, centerX,centerY],
      stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
      strokeWidth: 1,
    });
    group.add(line2)

    const arc = new Konva.Arc({
      x: centerX,
      y: centerY,
      innerRadius: 0,
      outerRadius: radius,
      angle: 270,
      stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
      strokeWidth: 1,
      fill: 'transparent',
      rotation: -90,
    })
    group.add(arc)

    if(this.sectors.length !=0){
      for (let i=0;i<this.sectors.length;i++) {
        //console.log('this.sectors FOR', this.sectors)
        const rad = radius - (i+1)*barWidth;
        const arcbg = new Konva.Arc({
          x: centerX,
          y: centerY,
          innerRadius: 0,
          outerRadius: rad,
          angle: 270,
          stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
          strokeWidth: 1,
          fill: 'transparent',
          rotation: -90,
        })
        group.add(arcbg)
           
        const sec = this.sectors[i]
        const tag:tag = tagsModele.getTag(sec['path'])
       
        let percent =0;
        let angle = 0;
  
        if (tag !=null && tag.tagvalue!=null) { 
          const value = Number(tag.tagvalue)
          percent = (value-this.minimum)/(this.maximum-this.minimum);
          if (percent<0) percent =0;
          else if (percent>1) percent=1;
          angle = percent*270;
          //console.log('angle', angle)
  
          const valueTextTransparent = new Konva.Text({
            x: 0,
            y: 0,
            text: parseFloat(value.toFixed(this.decimalpos)).toLocaleString(),
            fontSize: barWidth*.5,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: 'transparent',
            
          }); 
  
          const valueText = new Konva.Text({
            x: this.width/2-rad-(barWidth+valueTextTransparent.width())*.5,
            y: centerY-size*.025-barWidth*.5,
            verticalAlign: 'bottom',
            text: parseFloat(value.toFixed(this.decimalpos)).toLocaleString(),
            fontSize: barWidth*.5,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'right',
          }); 
          group.add(valueText) 
        }
  
        const rad2 = radius - i*barWidth-barWidth/2;
  
        const arc2 = new Konva.Arc({
          x: centerX,
          y: centerY,
          innerRadius: radius-barWidth*(i+1),//rad2-barWidth+innerSpacer-arcbg.strokeWidth()*(this.sectors.length-i),
          outerRadius: radius-barWidth*i-arcbg.strokeWidth()/2,//rad2+innerSpacer-arcbg.strokeWidth()*(this.sectors.length-i),
          angle: angle,
          fill: ColorUtils.convertformat(sec['color']),
          rotation: -90,
        })
        group.add(arc2)
  
        if (this.uselegends){
          const labelText = new Konva.Text({
            x: size*.05,
            y: centerY-rad-barWidth/2+size*.0125-barWidth*.5,
            width: this.width/2-size*.05*2,
            verticalAlign: 'bottom',
            text: sec['sectorname'],
            fontSize: barWidth*.5,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'right',
          }); 
          group.add(labelText) 
        }
      }
    }
    
    this.node.add(group) 
  }
}


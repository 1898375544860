import { SVGObject } from "../svgobject";

export class SVGFlow3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20000303 Stylable//EN" "http://www.w3.org/TR/2000/03/WD-SVG-20000303/DTD/svg-20000303-stylable.dtd"[]><svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 522 999"><path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M61,645 l399,0 0,338 -399,0 0,-338z" /><path style="fill:#666" d="M61,753 l399,0 0,92 -399,0 0,-92z" /><path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M138,507 l246,0 0,30 -246,0 0,-30z" /><path style="fill:#000;stroke:#4c4c4c;stroke-width:2" d="M15,15 l492,0 0,492 -492,0 0,-492z" /><circle style="fill:#e5e5e5;stroke:#4c4c4c;stroke-width:2" cx="261" cy="261" r="261" /><circle style="fill:#fff;stroke:#4c4c4c;stroke-width:2" cx="260" cy="260" r="230" /><path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M199,537 l123,0 0,93 -123,0 0,-93z" /><path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M184,630 l154,0 0,369 -154,0 0,-369z" /><path style="fill:#666" d="M199,768 l123,0 0,108 -123,0 0,-108z" /><circle style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" cx="261" cy="775" r="8" /><circle style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" cx="261" cy="868" r="8" /><path style="fill:#b2b2b2;stroke:#4c4c4c;stroke-width:2" d="M76,507 l31,0 0,15 -31,0 0,-15z" /><path style="fill:#b2b2b2;stroke:#4c4c4c;stroke-width:2" d="M415,507 l30,0 0,15 -30,0 0,-15z" /><path style="fill:#e5e5e5;stroke:#4c4c4c;stroke-width:2" d="M153,538 l31,0 0,22 -31,0 0,-22z" /><path style="fill:#e5e5e5;stroke:#4c4c4c;stroke-width:2" d="M337,538 l31,0 0,22 -31,0 0,-22z" /><path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M161,537 l15,0 0,23 -15,0 0,-23" /><path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M345,537 l16,0 0,23 -16,0 0,-23" /></svg>`
		super.fillGradients();

        super.drawObject();
    }
}
import {ObjectView} from '@/model/project/objects/object'
import {Line} from '@/model/project/objects/line'
import { TextView } from './objects/text';
import { Type} from 'class-transformer';
import {ColorUtils} from '@/model/project/utils/colorutils'
import tag from '@/model/tag'
import Konva from 'konva';
import { plainToInstance } from 'class-transformer';
import { Rectangle } from './objects/rectangle';
import { Button } from './objects/button';
import { ImageView } from './objects/image';
import { GroupView } from './objects/group';
import { Ellipse } from './objects/ellipse';
import { Polygon } from './objects/polygon';
import { Polyline } from './objects/polyline';
import { Sector } from './objects/sector';
import { Border } from './objects/border';
import { Scale } from './objects/scale';
import { TextArea } from './objects/textarea';
import { Sphere } from './objects/sphere';
import { Cylinder } from './objects/cylinder';
import { Cylinder3D } from './objects/cylinder3d';
import { Cone } from './objects/cone';
import { Sector3D } from './objects/sector3d';
import { PolygonGradient } from './objects/polygongradient';
import { Tank } from './objects/tank'
import { Border3d } from './objects/border3d';
import { Text3DView } from './objects/text3d';
import { ValueWithHistory } from './objects/valuewithhistory';
import { PressButton } from './objects/pressbutton';
import { ToggleButton } from './objects/togglebutton';
import { LightButton } from './objects/lightbutton';
import { OvalLightButton } from './objects/ovallightbutton';
import { ImageButton } from './objects/imagebutton';
import { OvalJumpButton } from './objects/ovaljumpbutton';
import { RectJumpButton } from './objects/rectjumbbutton';
import { RightJumpButton } from './objects/rightjumpbutton';
import { LeftJumpButton } from './objects/leftjumpbutton';
import { Switch } from './objects/switch';
import { Switch3Pos } from './objects/switch3pos';
import { SwitchApple } from './objects/switchapple';
import { OvalLamp } from './objects/ovallamp';
import { RectangleLamp } from './objects/rectanglelamp';
import { TriangleLamp } from "./objects/trianglelamp";
import { TriangleLamp2 } from "./objects/trianglelamp2";
import { StackLamp } from './objects/stacklamp';
import { Indicator } from './objects/indicator';
import { RectIndicator } from './objects/rectindicator';
import { OvalIndicator } from './objects/ovalindicator';
import { Slider} from './objects/slider'
import { SliderVertical } from './objects/slidervertical';
import { SliderHorizontal } from './objects/sliderhorizontal';
import { SliderApple } from './objects/sliderapple';
import { Counter } from './objects/counter';
import { CounterRect } from './objects/counterrect';
import { Selector } from './objects/selector';
import { Combobox } from './objects/combobox';
import { Menubox } from './objects/menubox';
import { Checkbox } from './objects/checkbox';
import { MenuCheckList } from './objects/menuchecklist';
import { CheckList } from './objects/checklist';
import { ParameterList } from './objects/parameterlist';
import { StraightPipe} from './objects/straightpipe';
import { StraightPipe2 } from './objects/straightpipe2';
import { EndPipe } from './objects/endpipe';
import { EndPipe2 } from './objects/endpipe2';
import { BowPipe } from './objects/bowpipe';
import { BowPipe2 } from './objects/bowpipe2';
import { TeePipe } from './objects/teepipe';
import { TeePipe2 } from './objects/teepipe2';
import { IntersectPipe } from './objects/intersectpipe';
import { IntersectPipe2 } from './objects/intersectpipe2';
import { ElbowPipe } from './objects/elbowpipe';
import { ElbowPipe2 } from './objects/elbowpipe2';
import { PolyPipe } from './objects/polypipe';
import { Valve } from './objects/valve';
import { ValveRound } from './objects/valveround';
import { ValveISA } from './objects/valveisa';
import { ValveBall } from './objects/valveball';
import { ValvePosition } from './objects/valveposition';
import { Pump } from './objects/pump';
import { Motor } from './objects/motor';
import { AirBlowerPump } from './objects/airblowerpump';
import { PumpStand } from './objects/pumpstand';
import { Fan } from './objects/fan';
import { CentrifugalPump } from './objects/centrifugalpump';
import { FanRound } from './objects/fanround';
import { FanSquare } from './objects/fansquare';
import { FanCool } from './objects/fancool';
import { FanBlades } from './objects/fanblades';
import { FanCentrifugal } from './objects/fancentrifugal';
import { FanAxial } from './objects/fanaxial';
import { VerticalTank } from './objects/verticaltank';
import { HorizontalTank } from './objects/horizontaltank';
import { ConeTank } from './objects/conetank';
import { VerticalTankWithTrend } from './objects/verticaltankwithtrend';
import { HorizontalTankWithTrend } from './objects/horizontaltankwithtrend';
import { ScrewConveyer } from './objects/screwconveyer';
import { ScrewConveyerMotion } from './objects/screwconveyermotion';
import { BeltConveyer } from './objects/beltconveyer';
import { Auger } from './objects/auger';
import { AnalogMeter } from './objects/analogmeter';
import { AnalogMeterRect } from './objects/analogmeterrect';
import { AnalogMeter90DegreesRect } from './objects/analogmeter90degree';
import { AnalogMeter90DegreesRect2 } from './objects/analogmeter90degree2';
import { AnalogMeterRound } from './objects/analogmeterround';
import { AnalogMeterVertical } from './objects/analogmetervert';
import { AnalogMeterVerticalFill } from './objects/analogmetervertfill';
import { AnalogMeterHorizontal } from './objects/analogmeterhor';
import { AnalogMeterHorizontalFill } from './objects/analogmeterhorfill';
import { RangeIndicator } from './objects/rangeindicator';
import { Gauge } from './objects/gauge';
import { Gauge180 } from './objects/gauge180';
import { Gauge90 } from './objects/gauge90';
import { Gauge90_2 } from './objects/gauge90_2';
import { DigitalMeter } from './objects/digitalmeter';
import { Digital4DigitMeter } from './objects/digital4digitmeter';
import { Digital6DigitMeter } from './objects/digital6digitmeter';
import { Digital8DigitMeter } from './objects/digital8digitmeter';
import { DigitalMeterHistoryAndEvent } from './objects/digitalmeterhistoryandevent';
import { ElectricalSwitch } from './objects/electricalswitch';
import { ElectricalSwitch2Way } from './objects/electricalswitch2way';
import { ElectricalSwitchWithNeutral } from './objects/electricalswitchwithneutral';
import { ElectricalTransformer } from './objects/electricaltransformer';
import { AnalogClock } from './objects/analogclock';
import { DigitalClock } from './objects/digitalclock';
import { DateView } from './objects/date';
import { Trend } from './objects/trend';
import { EventsTable } from './objects/eventstable';
import { PieChart } from './objects/piechart';
import { EventsTicker } from './objects/eventsticker';
import { Diagram } from './objects/diagram';
import { WindChart } from './objects/windchart';
import { RecipeTable } from './objects/recipetable';
import { RecipeSelector } from './objects/recipeselector';
import { TrendHistoryDB } from './objects/trendhistorydb';
import { HistoryTable } from './objects/historydbtable';
import { TrendHistoryXYDB } from './objects/trendhistoryxydb';
import { ColorPicker } from './objects/colorpicker';
import { ColorRectangle } from './objects/colorrectangle';
import { Video } from './objects/video';
import { SVGTank } from './objects/svg/tanks/svgtank';
import { ReactorTank } from './objects/svg/tanks/reactortank';
import { SVGTank2 } from "./objects/svg/tanks/svgtank2";
import { SVGTank3 } from "./objects/svg/tanks/svgtank3";
import { SVGTank4 } from "./objects/svg/tanks/svgtank4";
import { SVGTank5 } from "./objects/svg/tanks/svgtank5";
import { SVGTank6 } from './objects/svg/tanks/svgtank6';
import { SVGTank7 } from './objects/svg/tanks/svgtank7';
import { SVGTank8 } from './objects/svg/tanks/svgtank8';
import { SVGTank9 } from './objects/svg/tanks/svgtank9';
import { SVGTank10 } from './objects/svg/tanks/svgtank10';
import { SVGTank11 } from './objects/svg/tanks/svgtank11';
import { SVGTank12 } from './objects/svg/tanks/svgtank12';
import { SVGTank13 } from './objects/svg/tanks/svgtank13';
import { SVGTank14 } from './objects/svg/tanks/svgtank14';
import { SVGTank15 } from './objects/svg/tanks/svgtank15';
import { SVGTank16 } from './objects/svg/tanks/svgtank16';
import { SVGTank17 } from './objects/svg/tanks/svgtank17';
import { SVGTank18 } from './objects/svg/tanks/svgtank18';
import { SVGTank19 } from './objects/svg/tanks/svgtank19';
import { SVGTank20 } from './objects/svg/tanks/svgtank20';
import { SVGTank21 } from './objects/svg/tanks/svgtank21';
import { SVGTank22 } from './objects/svg/tanks/svgtank22';
import { SVGTank23 } from './objects/svg/tanks/svgtank23';
import { SVGTank24 } from './objects/svg/tanks/svgtank24';
import { SVGTank25 } from './objects/svg/tanks/svgtank25';
import { SVGTank26 } from './objects/svg/tanks/svgtank26';
import { SVGTank27 } from './objects/svg/tanks/svgtank27';
import { SVGTank28 } from './objects/svg/tanks/svgtank28';
import { SVGTank29 } from './objects/svg/tanks/svgtank29';
import { SVGTank30 } from './objects/svg/tanks/svgtank30';
import { SVGTank32 } from './objects/svg/tanks/svgtank32';
import { SVGTank33 } from './objects/svg/tanks/svgtank33';
import { SVGTank34 } from './objects/svg/tanks/svgtank34';
import { SVGTank35 } from './objects/svg/tanks/svgtank35';
import { SVGTank36 } from './objects/svg/tanks/svgtank36';
import { SVGTank37 } from './objects/svg/tanks/svgtank37';
import { SVGTank38 } from './objects/svg/tanks/svgtank38';
import { SVGTank39 } from './objects/svg/tanks/svgtank39';
import { SVGTank40 } from './objects/svg/tanks/svgtank40';
import { Reactor1 } from './objects/svg/tanks/reactor1';
import { Reactor2 } from './objects/svg/tanks/reactor2';
import { Reactor5 } from './objects/svg/tanks/reactor5';
import { Reactor4 } from './objects/svg/tanks/reactor4';
import { Reactor6 } from './objects/svg/tanks/reactor6';
import { Reactor7 } from './objects/svg/tanks/reactor7';
import { Reactor8 } from './objects/svg/tanks/reactor8';
import { Reactor10 } from './objects/svg/tanks/reactor10';
import { Reactor9 } from './objects/svg/tanks/reactor9';
import { SVGTank41 } from './objects/svg/tanks/svgtank41';
import { SVGTank42 } from './objects/svg/tanks/svgtank42';
import { SVGTank43 } from './objects/svg/tanks/svgtank43';
import { SVGTank44 } from './objects/svg/tanks/svgtank44';
import { SVGTank45 } from './objects/svg/tanks/svgtank45';
import { SVGTank46 } from './objects/svg/tanks/svgtank46';
import { SVGTank47 } from './objects/svg/tanks/svgtank47';
import { SVGTank48 } from './objects/svg/tanks/svgtank48';
import { SVGTank49 } from './objects/svg/tanks/svgtank49';
import { SVGTank50 } from './objects/svg/tanks/svgtank50';
import { SVGTank51 } from './objects/svg/tanks/svgtank51';
import { SVGTank52 } from './objects/svg/tanks/svgtank52';
import { SVGTank53 } from './objects/svg/tanks/svgtank53';
import { SVGTank55 } from './objects/svg/tanks/svgtank55';
import { SVGTank54 } from './objects/svg/tanks/svgtank54';
import { SVGTank56 } from './objects/svg/tanks/svgtank56';
import { SVGTank57 } from './objects/svg/tanks/svgtank57';
import { SVGTank58 } from './objects/svg/tanks/svgtank58';
import { SVGTank59 } from './objects/svg/tanks/svgtank59';
import { SVGTank60 } from './objects/svg/tanks/svgtank60';
import { SVGTank61 } from './objects/svg/tanks/svgtank61';
import { SVGTank62 } from './objects/svg/tanks/svgtank62';
import { SVGTank63 } from './objects/svg/tanks/svgtank63';
import { SVGTank65 } from './objects/svg/tanks/svgtank65';
import { SVGTank64 } from './objects/svg/tanks/svgtank64';
import { SVGTank66 } from './objects/svg/tanks/svgtank66';
import { SVGTank67 } from './objects/svg/tanks/svgtank67';
import { SVGTank68 } from './objects/svg/tanks/svgtank68';
import { SVGTank70 } from './objects/svg/tanks/svgtank70';
import { SVGTank69 } from './objects/svg/tanks/svgtank69';
import { SVGTank71 } from './objects/svg/tanks/svgtank71';
import { SVGTank72 } from './objects/svg/tanks/svgtank72';
import { SVGValve1 } from './objects/svg/valves/svgvalve1';
import { SVGValve2 } from './objects/svg/valves/svgvalve2';
import { SVGValve3 } from './objects/svg/valves/svgvalve3';
import { SVGValve4 } from './objects/svg/valves/svgvalve4';
import { SVGValve5 } from './objects/svg/valves/svgvalve5';
import { SVGValve6 } from './objects/svg/valves/svgvalve6';
import { SVGValve7 } from './objects/svg/valves/svgvalve7';
import { SVGValve8 } from './objects/svg/valves/svgvalve8';
import { SVGValve9 } from './objects/svg/valves/svgvalve9';
import { SVGValve10 } from './objects/svg/valves/svgvalve10';
import { SVGValve11 } from './objects/svg/valves/svgvalve11';
import { SVGValve12 } from './objects/svg/valves/svgvalve12';
import { SVGValve13 } from './objects/svg/valves/svgvalve13';
import { SVGValve14 } from './objects/svg/valves/svgvalve14';
import { SVGValve15 } from './objects/svg/valves/svgvalve15';
import { SVGValve17 } from './objects/svg/valves/svgvalve17';
import { SVGValve16 } from './objects/svg/valves/svgvalve16';
import { SVGValve18 } from './objects/svg/valves/svgvalve18';
import { SVGValve19 } from './objects/svg/valves/svgvalve19';
import { SVGValve20 } from './objects/svg/valves/svgvalve20';
import { SVGValve21 } from './objects/svg/valves/svgvalve21';
import { SVGValve22 } from './objects/svg/valves/svgvalve22';
import { SVGValve23 } from './objects/svg/valves/svgvalve23';
import { SVGValve25 } from './objects/svg/valves/svgvalve25';
import { SVGValve24 } from './objects/svg/valves/svgvalve24';
import { SVGValve26 } from './objects/svg/valves/svgvalve26';
import { SVGValve27 } from './objects/svg/valves/svgvalve27';
import { SVGValve28 } from './objects/svg/valves/svgvalve28';
import { SVGValve29 } from './objects/svg/valves/svgvalve29';
import { SVGVehicle1 } from './objects/svg/vehicles/svgvehicle1';
import { SVGVehicle2 } from './objects/svg/vehicles/svgvehicle2';
import { SVGVehicle3 } from './objects/svg/vehicles/svgvehicle3';
import { SVGVehicle4 } from './objects/svg/vehicles/svgvehicle4';
import { SVGVehicle5 } from './objects/svg/vehicles/svgvehicle5';
import { SVGVehicle6 } from './objects/svg/vehicles/svgvehicle6';
import { SVGVehicle7 } from './objects/svg/vehicles/svgvehicle7';
import { SVGVehicle8 } from './objects/svg/vehicles/svgvehicle8';
import { SVGVehicle9 } from './objects/svg/vehicles/svgvehicle9';
import { SVGVehicle10 } from './objects/svg/vehicles/svgvehicle10';
import { SVGVehicle11 } from './objects/svg/vehicles/svgvehicle11';
import { SVGVehicle12 } from './objects/svg/vehicles/svgvehicle12';
import { SVGVehicle13 } from './objects/svg/vehicles/svgvehicle13';
import { SVGVehicle14 } from './objects/svg/vehicles/svgvehicle14';
import { SVGVehicle15 } from './objects/svg/vehicles/svgvehicle15';
import { SVGVehicle16 } from './objects/svg/vehicles/svgvehicle16';
import { SVGWater1 } from './objects/svg/water/svgwater1';
import { SVGWater3 } from './objects/svg/water/svgwater3';
import { SVGWater2 } from './objects/svg/water/svgwater2';
import { SVGWater4 } from './objects/svg/water/svgwater4';
import { SVGWater5 } from './objects/svg/water/svgwater5';
import { SVGWater6 } from './objects/svg/water/svgwater6';
import { SVGWater7 } from './objects/svg/water/svgwater7';
import { SVGWater9 } from './objects/svg/water/svgwater9';
import { SVGWater8 } from './objects/svg/water/svgwater8';
import { SVGWater10 } from './objects/svg/water/svgwater10';
import { SVGWater11 } from './objects/svg/water/svgwater11';
import { SVGWater12 } from './objects/svg/water/svgwater12';
import { SVGWater13 } from './objects/svg/water/svgwater13';
import { SVGWater14 } from './objects/svg/water/svgwater14';
import { SVGWater15 } from './objects/svg/water/svgwater15';
import { SVGWater16 } from './objects/svg/water/svgwater16';
import { SVGWater17 } from './objects/svg/water/svgwater17';
import { SVGWater18 } from './objects/svg/water/svgwater18';
import { SVGWater19 } from './objects/svg/water/svgwater19';
import { SVGWater20 } from './objects/svg/water/svgwater20';
import { SVGWater21 } from './objects/svg/water/svgwater21';
import { SVGWater22 } from './objects/svg/water/svgwater22';
import { SVGWater23 } from './objects/svg/water/svgwater23';
import { SVGWater24 } from './objects/svg/water/svgwater24';
import { SVGWater25 } from './objects/svg/water/svgwater25';
import { SVGWater26 } from './objects/svg/water/svgwater26';
import { SVGWater27 } from './objects/svg/water/svgwater27';
import { SVGWater28 } from './objects/svg/water/svgwater28';
import { SVGWater29 } from './objects/svg/water/svgwater29';
import { SVGWater30 } from './objects/svg/water/svgwater30';
import { SVGWater31 } from './objects/svg/water/svgwater31';
import { SVGWater32 } from './objects/svg/water/svgwater32';
import { SVGWater33 } from './objects/svg/water/svgwater33';
import { SVGWater34 } from './objects/svg/water/svgwater34';
import { SVGWater35 } from './objects/svg/water/svgwater35';
import { SVGWater36 } from './objects/svg/water/svgwater36';
import { SVGWater37 } from './objects/svg/water/svgwater37';
import { SVGWater38 } from './objects/svg/water/svgwater38';
import { SVGWater39 } from './objects/svg/water/svgwater39';
import { SVGWater40 } from './objects/svg/water/svgwater40';
import { SVGWater41 } from './objects/svg/water/svgwater41';
import { SVGWater42 } from './objects/svg/water/svgwater42';
import { SVGWater43 } from './objects/svg/water/svgwater43';
import { SVGWater44 } from './objects/svg/water/svgwater44';
import { SVGWater45 } from './objects/svg/water/svgwater45';
import { SVGWater46 } from './objects/svg/water/svgwater46';
import { SVGWater47 } from './objects/svg/water/svgwater47';
import { SVGWater48 } from './objects/svg/water/svgwater48';
import { SVGWater49 } from './objects/svg/water/svgwater49';
import { SVGWater50 } from './objects/svg/water/svgwater50';
import { SVGWater51 } from './objects/svg/water/svgwater51';
import { SVGWater52 } from './objects/svg/water/svgwater52';
import { SVGWater53 } from './objects/svg/water/svgwater53';
import { SVGWater54 } from './objects/svg/water/svgwater54';
import { SVGWater55 } from './objects/svg/water/svgwater55';
import { SVGWater56 } from './objects/svg/water/svgwater56';
import { SVGWater57 } from './objects/svg/water/svgwater57';
import { SVGWater58 } from './objects/svg/water/svgwater58';
import { SVGWater59 } from './objects/svg/water/svgwater59';
import { SVGWater60 } from './objects/svg/water/svgwater60';
import { SVGWater61 } from './objects/svg/water/svgwater61';
import { SVGSensor1 } from './objects/svg/sensors/svgsensor1';
import { SVGSensor2 } from './objects/svg/sensors/svgsensor2';
import { SVGSensor3 } from './objects/svg/sensors/svgsensor3';
import { SVGSensor4 } from './objects/svg/sensors/svgsensor4';
import { SVGSensor5 } from './objects/svg/sensors/svgsensor5';
import { SVGSensor6 } from './objects/svg/sensors/svgsensor6';
import { SVGSensor7 } from './objects/svg/sensors/svgsensor7';
import { SVGSensor8 } from './objects/svg/sensors/svgsensor8';
import { SVGSensor9 } from './objects/svg/sensors/svgsensor9';
import { SVGSensor10 } from './objects/svg/sensors/svgsensor10';
import { SVGSensor11 } from './objects/svg/sensors/svgsensor11';
import { SVGSensor12 } from './objects/svg/sensors/svgsensor12';
import { SVGSensor13 } from './objects/svg/sensors/svgsensor13';
import { SVGSensor14 } from './objects/svg/sensors/svgsensor14';
import { SVGSensor15 } from './objects/svg/sensors/svgsensor15';
import { SVGSensor16 } from './objects/svg/sensors/svgsensor16';
import { SVGSensor17 } from './objects/svg/sensors/svgsensor17';
import { SVGSensor18 } from './objects/svg/sensors/svgsensor18';
import { SVGSensor19 } from './objects/svg/sensors/svgsensor19';
import { SVGSensor20 } from './objects/svg/sensors/svgsensor20';
import { SVGSegpipe1 } from './objects/svg/segpipes/svgsegpipe1';
import { SVGSegpipe2 } from './objects/svg/segpipes/svgsegpipe2';
import { SVGSegpipe3 } from './objects/svg/segpipes/svgsegpipe3';
import { SVGSegpipe4 } from './objects/svg/segpipes/svgsegpipe4';
import { SVGSegpipe5 } from './objects/svg/segpipes/svgsegpipe5';
import { SVGSegpipe6 } from './objects/svg/segpipes/svgsegpipe6';
import { SVGSegpipe7 } from './objects/svg/segpipes/svgsegpipe7';
import { SVGSegpipe8 } from './objects/svg/segpipes/svgsegpipe8';
import { SVGSegpipe9 } from './objects/svg/segpipes/svgsegpipe9';
import { SVGSegpipe10 } from './objects/svg/segpipes/svgsegpipe10';
import { SVGSegpipe11 } from './objects/svg/segpipes/svgsegpipe11';
import { SVGSegpipe12 } from './objects/svg/segpipes/svgsegpipe12';
import { SVGSegpipe13 } from './objects/svg/segpipes/svgsegpipe13';
import { SVGSegpipe14 } from './objects/svg/segpipes/svgsegpipe14';
import { SVGScale1 } from './objects/svg/scales/svgscale1';
import { SVGScale2 } from './objects/svg/scales/svgscale2';
import { SVGScale3 } from './objects/svg/scales/svgscale3';
import { SVGScale4 } from './objects/svg/scales/svgscale4';
import { SVGEnergy1 } from './objects/svg/renewenergy/svgenergy1';
import { SVGEnergy2 } from './objects/svg/renewenergy/svgenergy2';
import { SVGEnergy3 } from './objects/svg/renewenergy/svgenergy3';
import { SVGEnergy4 } from './objects/svg/renewenergy/svgenergy4';
import { SVGEnergy5 } from './objects/svg/renewenergy/svgenergy5';
import { SVGEnergy6 } from './objects/svg/renewenergy/svgenergy6';
import { SVGEnergy7 } from './objects/svg/renewenergy/svgenergy7';
import { SVGPump1 } from './objects/svg/pumps/svgpump1';
import { SVGPump2 } from './objects/svg/pumps/svgpump2';
import { SVGPump3 } from './objects/svg/pumps/svgpump3';
import { SVGPump4 } from './objects/svg/pumps/svgpump4';
import { SVGPump5 } from './objects/svg/pumps/svgpump5';
import { SVGPump6 } from './objects/svg/pumps/svgpump6';
import { SVGPump7 } from './objects/svg/pumps/svgpump7';
import { SVGPump8 } from './objects/svg/pumps/svgpump8';
import { SVGPump9 } from './objects/svg/pumps/svgpump9';
import { SVGPump10 } from './objects/svg/pumps/svgpump10';
import { SVGPump11 } from './objects/svg/pumps/svgpump11';
import { SVGPump12 } from './objects/svg/pumps/svgpump12';
import { SVGPump13 } from './objects/svg/pumps/svgpump13';
import { SVGPump14 } from './objects/svg/pumps/svgpump14';
import { SVGPump15 } from './objects/svg/pumps/svgpump15';
import { SVGPump16 } from './objects/svg/pumps/svgpump16';
import { SVGPump17 } from './objects/svg/pumps/svgpump17';
import { SVGPump18 } from './objects/svg/pumps/svgpump18';
import { SVGPump19 } from './objects/svg/pumps/svgpump19';
import { SVGPump20 } from './objects/svg/pumps/svgpump20';
import { SVGPump21 } from './objects/svg/pumps/svgpump21';
import { SVGPump22 } from './objects/svg/pumps/svgpump22';
import { SVGPump23 } from './objects/svg/pumps/svgpump23';
import { SVGPump24 } from './objects/svg/pumps/svgpump24';
import { SVGPaper1 } from './objects/svg/papers/svgpaper1';
import { SVGPaper2 } from './objects/svg/papers/svgpaper2';
import { SVGPaper3 } from './objects/svg/papers/svgpaper3';
import { SVGPaper4 } from './objects/svg/papers/svgpaper4';
import { SVGPaper5 } from './objects/svg/papers/svgpaper5';
import { SVGPaper6 } from './objects/svg/papers/svgpaper6';
import { SVGPaper7 } from './objects/svg/papers/svgpaper7';
import { SVGPaper8 } from './objects/svg/papers/svgpaper8';
import { SVGPaper9 } from './objects/svg/papers/svgpaper9';
import { SVGPaper10 } from './objects/svg/papers/svgpaper10';
import { SVGPaper11 } from './objects/svg/papers/svgpaper11';
import { SVGPaper12 } from './objects/svg/papers/svgpaper12';
import { SVGPaper13 } from './objects/svg/papers/svgpaper13';
import { SVGPaper14 } from './objects/svg/papers/svgpaper14';
import { SVGPaper15 } from './objects/svg/papers/svgpaper15';
import { SVGPaper16 } from './objects/svg/papers/svgpaper16';
import { SVGPaper17 } from './objects/svg/papers/svgpaper17';
import { SVGPaper18 } from './objects/svg/papers/svgpaper18';
import { SVGPaper19 } from './objects/svg/papers/svgpaper19';
import { SVGHeating1 } from './objects/svg/heating/svgheating';
import { SVGHeating2 } from './objects/svg/heating/svgheating2';
import { SVGHeating3 } from './objects/svg/heating/svgheating3';
import { SVGHeating4 } from './objects/svg/heating/svgheating4';
import { SVGHeating5 } from './objects/svg/heating/svgheating5';
import { SVGHeating6 } from './objects/svg/heating/svgheating6';
import { SVGHeating7 } from './objects/svg/heating/svgheating7';
import { SVGHeating8 } from './objects/svg/heating/svgheating8';
import { SVGHeating9 } from './objects/svg/heating/svgheating9';
import { SVGHeating10 } from './objects/svg/heating/svgheating10';
import { SVGHeating11 } from './objects/svg/heating/svgheating11';
import { SVGHeating12 } from './objects/svg/heating/svgheating12';
import { SVGHeating13 } from './objects/svg/heating/svgheating13';
import { SVGHeating14 } from './objects/svg/heating/svgheating14';
import { SVGHeating15 } from './objects/svg/heating/svgheating15';
import { SVGHeating16 } from './objects/svg/heating/svgheating16';
import { SVGHeating17 } from './objects/svg/heating/svgheating17';
import { SVGHeating18 } from './objects/svg/heating/svgheating18';
import { SVGHeating19 } from './objects/svg/heating/svgheating19';
import { SVGHeating20 } from './objects/svg/heating/svgheating20';
import { SVGHeating21 } from './objects/svg/heating/svgheating21';
import { SVGHeating22 } from './objects/svg/heating/svgheating22';
import { SVGHeating23 } from './objects/svg/heating/svgheating23';
import { SVGHeating24 } from './objects/svg/heating/svgheating24';
import { SVGHeating25 } from './objects/svg/heating/svgheating25';
import { SVGHeating26 } from './objects/svg/heating/svgheating26';
import { SVGCooling1 } from './objects/svg/cooling/svgcooling1';
import { SVGCooling2 } from './objects/svg/cooling/svgcooling2';
import { SVGCooling3 } from './objects/svg/cooling/svgcooling3';
import { SVGCooling4 } from './objects/svg/cooling/svgcooling4';
import { SVGCooling5 } from './objects/svg/cooling/svgcooling5';
import { SVGCooling6 } from './objects/svg/cooling/svgcooling6';
import { SVGCooling7 } from './objects/svg/cooling/svgcooling7';
import { SVGCooling8 } from './objects/svg/cooling/svgcooling8';
import { SVGCooling9 } from './objects/svg/cooling/svgcooling9';
import { SVGCooling10 } from './objects/svg/cooling/svgcooling10';
import { SVGCooling11 } from './objects/svg/cooling/svgcooling11';
import { SVGPower1 } from './objects/svg/power/svgpower1';
import { SVGPower2 } from './objects/svg/power/svgpower2';
import { SVGPower3 } from './objects/svg/power/svgpower3';
import { SVGPower4 } from './objects/svg/power/svgpower4';
import { SVGPower5 } from './objects/svg/power/svgpower5';
import { SVGPower6 } from './objects/svg/power/svgpower6';
import { SVGPower7 } from './objects/svg/power/svgpower7';
import { SVGPower8 } from './objects/svg/power/svgpower8';
import { SVGPower9 } from './objects/svg/power/svgpower9';
import { SVGPower10 } from './objects/svg/power/svgpower10';
import { SVGPower11 } from './objects/svg/power/svgpower11';
import { SVGPower12 } from './objects/svg/power/svgpower12';
import { SVGPower13 } from './objects/svg/power/svgpower13';
import { SVGPower14 } from './objects/svg/power/svgpower14';
import { SVGPower15 } from './objects/svg/power/svgpower15';
import { SVGPower16 } from './objects/svg/power/svgpower16';
import { SVGPipe1 } from './objects/svg/pipes/svgpipe1';
import { SVGPipe2 } from './objects/svg/pipes/svgpipe2';
import { SVGPipe3 } from './objects/svg/pipes/svgpipe3';
import { SVGPipe4 } from './objects/svg/pipes/svgpipe4';
import { SVGPipe5 } from './objects/svg/pipes/svgpipe5';
import { SVGPipe6 } from './objects/svg/pipes/svgpipe6';
import { SVGPipe7 } from './objects/svg/pipes/svgpipe7';
import { SVGPipe8 } from './objects/svg/pipes/svgpipe8';
import { SVGPipe9 } from './objects/svg/pipes/svgpipe9';
import { SVGPipe10 } from './objects/svg/pipes/svgpipe10';
import { SVGPipe11 } from './objects/svg/pipes/svgpipe11';
import { SVGPipe12 } from './objects/svg/pipes/svgpipe12';
import { SVGPipe13 } from './objects/svg/pipes/svgpipe13';
import { SVGPipe14 } from './objects/svg/pipes/svgpipe14';
import { SVGPipe15 } from './objects/svg/pipes/svgpipe15';
import { SVGPipe16 } from './objects/svg/pipes/svgpipe16';
import { SVGPipe17 } from './objects/svg/pipes/svgpipe17';
import { SVGPipe18 } from './objects/svg/pipes/svgpipe18';
import { SVGPipe19 } from './objects/svg/pipes/svgpipe19';
import { SVGPipe20 } from './objects/svg/pipes/svgpipe20';
import { SVGPipe21 } from './objects/svg/pipes/svgpipe21';
import { SVGPipe22 } from './objects/svg/pipes/svgpipe22';
import { SVGPipe23 } from './objects/svg/pipes/svgpipe23';
import { SVGPipe24 } from './objects/svg/pipes/svgpipe24';
import { SVGPipe25 } from './objects/svg/pipes/svgpipe25';
import { SVGPipe26 } from './objects/svg/pipes/svgpipe26';
import { SVGPlant1 } from './objects/svg/plant/svgplant1';
import { SVGPlant2 } from './objects/svg/plant/svgplant2';
import { SVGPlant3 } from './objects/svg/plant/svgplant3';
import { SVGPlant4 } from './objects/svg/plant/svgplant4';
import { SVGPlant5 } from './objects/svg/plant/svgplant5';
import { SVGPlant6 } from './objects/svg/plant/svgplant6';
import { SVGPlant7 } from './objects/svg/plant/svgplant7';
import { SVGPlant8 } from './objects/svg/plant/svgplant8';
import { SVGPlant9 } from './objects/svg/plant/svgplant9';
import { SVGPlant10 } from './objects/svg/plant/svgplant10';
import { SVGPlant11 } from './objects/svg/plant/svgplant11';
import { SVGPlant12 } from './objects/svg/plant/svgplant12';
import { SVGPlant13 } from './objects/svg/plant/svgplant13';
import { SVGPlant14 } from './objects/svg/plant/svgplant14';
import { SVGPlant15 } from './objects/svg/plant/svgplant15';
import { SVGPlant16 } from './objects/svg/plant/svgplant16';
import { SVGPlant17 } from './objects/svg/plant/svgplant17';
import { SVGPlant18 } from './objects/svg/plant/svgplant18';
import { SVGPlant19 } from './objects/svg/plant/svgplant19';
import { SVGPlant20 } from './objects/svg/plant/svgplant20';
import { SVGPlant21 } from './objects/svg/plant/svgplant21';
import { SVGPlant22 } from './objects/svg/plant/svgplant22';
import { SVGPlant23 } from './objects/svg/plant/svgplant23';
import { SVGPlant24 } from './objects/svg/plant/svgplant24';
import { SVGPlant25 } from './objects/svg/plant/svgplant25';
import { SVGPlant26 } from './objects/svg/plant/svgplant26';
import { SVGPlant27 } from './objects/svg/plant/svgplant27';
import { SVGPeople1 } from './objects/svg/people/svgpeople1';
import { SVGPeople2 } from './objects/svg/people/svgpeople2';
import { SVGPeople3 } from './objects/svg/people/svgpeople3';
import { SVGPeople4 } from './objects/svg/people/svgpeople4';
import { SVGPeople5 } from './objects/svg/people/svgpeople5';
import { SVGPeople6 } from './objects/svg/people/svgpeople6';
import { SVGPeople7 } from './objects/svg/people/svgpeople7';
import { SVGPeople8 } from './objects/svg/people/svgpeople8';
import { SVGPeople9 } from './objects/svg/people/svgpeople9';
import { SVGPeople10 } from './objects/svg/people/svgpeople10';
import { SVGPanel1 } from './objects/svg/panels/svgpanel';
import { SVGPanel2 } from './objects/svg/panels/svgpanel2';
import { SVGPanel3 } from './objects/svg/panels/svgpanel3';
import { SVGPanel4 } from './objects/svg/panels/svgpanel4';
import { SVGPanel5 } from './objects/svg/panels/svgpanel5';
import { SVGPanel6 } from './objects/svg/panels/svgpanel6';
import { SVGPanel7 } from './objects/svg/panels/svgpanel7';
import { SVGPanel8 } from './objects/svg/panels/svgpanel8';
import { SVGNuclear1 } from './objects/svg/nuclear/svgnuclear1';
import { SVGNuclear2 } from './objects/svg/nuclear/svgnuclear2';
import { SVGNuclear3 } from './objects/svg/nuclear/svgnuclear3';
import { SVGNuclear4 } from './objects/svg/nuclear/svgnuclear4';
import { SVGNuclear5 } from './objects/svg/nuclear/svgnuclear5';
import { SVGNuclear6 } from './objects/svg/nuclear/svgnuclear6';
import { SVGNuclear7 } from './objects/svg/nuclear/svgnuclear7';
import { SVGNet1 } from './objects/svg/networking/svgnet1';
import { SVGNet2 } from './objects/svg/networking/svgnet2';
import { SVGNet3 } from './objects/svg/networking/svgnet3';
import { SVGNet4 } from './objects/svg/networking/svgnet4';
import { SVGNet5 } from './objects/svg/networking/svgnet5';
import { SVGNet6 } from './objects/svg/networking/svgnet6';
import { SVGNet7 } from './objects/svg/networking/svgnet7';
import { SVGNet8 } from './objects/svg/networking/svgnet8';
import { SVGNet9 } from './objects/svg/networking/svgnet9';
import { SVGNet10 } from './objects/svg/networking/svgnet10';
import { SVGNet11 } from './objects/svg/networking/svgnet11';
import { SVGNet12 } from './objects/svg/networking/svgnet12';
import { SVGNet13 } from './objects/svg/networking/svgnet13';
import { SVGNet14 } from './objects/svg/networking/svgnet14';
import { SVGNet15 } from './objects/svg/networking/svgnet15';
import { SVGNet16 } from './objects/svg/networking/svgnet16';
import { SVGNet17 } from './objects/svg/networking/svgnet17';
import { SVGNet18 } from './objects/svg/networking/svgnet18';
import { SVGNet19 } from './objects/svg/networking/svgnet19';
import { SVGNature1 } from './objects/svg/nature/svgnature1';
import { SVGNature2 } from './objects/svg/nature/svgnature2';
import { SVGNature3 } from './objects/svg/nature/svgnature3';
import { SVGNature4 } from './objects/svg/nature/svgnature4';
import { SVGNature5 } from './objects/svg/nature/svgnature5';
import { SVGNature6 } from './objects/svg/nature/svgnature6';
import { SVGNature7 } from './objects/svg/nature/svgnature7';
import { SVGNature8 } from './objects/svg/nature/svgnature8';
import { SVGNature9 } from './objects/svg/nature/svgnature9';
import { SVGNature10 } from './objects/svg/nature/svgnature10';
import { SVGNature11 } from './objects/svg/nature/svgnature11';
import { SVGNature12 } from './objects/svg/nature/svgnature12';
import { SVGNature13 } from './objects/svg/nature/svgnature13';
import { SVGNature14 } from './objects/svg/nature/svgnature14';
import { SVGNature15 } from './objects/svg/nature/svgnature15';
import { SVGInterface1 } from './objects/svg/interfaces/svginterface1';
import { SVGInterface2 } from './objects/svg/interfaces/svginterface2';
import { SVGInterface3 } from './objects/svg/interfaces/svginterface3';
import { SVGInterface4 } from './objects/svg/interfaces/svginterface4';
import { SVGInterface5 } from './objects/svg/interfaces/svginterface5';
import { SVGInterface6 } from './objects/svg/interfaces/svginterface6';
import { SVGInterface7 } from './objects/svg/interfaces/svginterface7';
import { SVGInterface8 } from './objects/svg/interfaces/svginterface8';
import { SVGInterface9 } from './objects/svg/interfaces/svginterface9';
import { SVGInterface10 } from './objects/svg/interfaces/svginterface10';
import { SVGInterface11 } from './objects/svg/interfaces/svginterface11';
import { SVGInterface12 } from './objects/svg/interfaces/svginterface12';
import { SVGInterface13 } from './objects/svg/interfaces/svginterface13';
import { SVGMotor1 } from './objects/svg/motors/svgmotor1';
import { SVGMotor2 } from './objects/svg/motors/svgmotor2';
import { SVGMotor3 } from './objects/svg/motors/svgmotor3';
import { SVGMotor4 } from './objects/svg/motors/svgmotor4';
import { SVGMotor5 } from './objects/svg/motors/svgmotor5';
import { SVGMotor6 } from './objects/svg/motors/svgmotor6';
import { SVGMotor7 } from './objects/svg/motors/svgmotor7';
import { SVGMotor8 } from './objects/svg/motors/svgmotor8';
import { SVGMotor9 } from './objects/svg/motors/svgmotor9';
import { SVGMotor10 } from './objects/svg/motors/svgmotor10';
import { SVGMotor11 } from './objects/svg/motors/svgmotor11';
import { SVGMotor12 } from './objects/svg/motors/svgmotor12';
import { SVGMotor13 } from './objects/svg/motors/svgmotor13';
import { SVGMotor14 } from './objects/svg/motors/svgmotor14';
import { SVGMotor15 } from './objects/svg/motors/svgmotor15';
import { SVGMotor16 } from './objects/svg/motors/svgmotor16';
import { SVGMixer1 } from './objects/svg/mixers/svgmixer1';
import { SVGMixer2 } from './objects/svg/mixers/svgmixer2';
import { SVGMixer3 } from './objects/svg/mixers/svgmixer3';
import { SVGMixer4 } from './objects/svg/mixers/svgmixer4';
import { SVGMixer5 } from './objects/svg/mixers/svgmixer5';
import { SVGMixer6 } from './objects/svg/mixers/svgmixer6';
import { SVGMixer7 } from './objects/svg/mixers/svgmixer7';
import { SVGMixer8 } from './objects/svg/mixers/svgmixer8';
import { SVGMixer9 } from './objects/svg/mixers/svgmixer9';
import { SVGMixer10 } from './objects/svg/mixers/svgmixer10';
import { SVGMixer11 } from './objects/svg/mixers/svgmixer11';
import { SVGMiscpipe1 } from './objects/svg/miscpipes/svgmiscpipe1';
import { SVGMiscpipe2 } from './objects/svg/miscpipes/svgmiscpipe2';
import { SVGMiscpipe3 } from './objects/svg/miscpipes/svgmiscpipe3';
import { SVGMiscpipe4 } from './objects/svg/miscpipes/svgmiscpipe4';
import { SVGMiscpipe5 } from './objects/svg/miscpipes/svgmiscpipe5';
import { SVGMiscpipe6 } from './objects/svg/miscpipes/svgmiscpipe6';
import { SVGMiscpipe7 } from './objects/svg/miscpipes/svgmiscpipe7';
import { SVGMiscpipe8 } from './objects/svg/miscpipes/svgmiscpipe8';
import { SVGMiscpipe9 } from './objects/svg/miscpipes/svgmiscpipe9';
import { SVGMiscpipe10 } from './objects/svg/miscpipes/svgmiscpipe10';
import { SVGMiscpipe11 } from './objects/svg/miscpipes/svgmiscpipe11';
import { SVGMiscpipe12 } from './objects/svg/miscpipes/svgmiscpipe12';
import { SVGMining1 } from './objects/svg/mining/svgmining1';
import { SVGMining2 } from './objects/svg/mining/svgmining2';
import { SVGMining3 } from './objects/svg/mining/svgmining3';
import { SVGMining4 } from './objects/svg/mining/svgmining4';
import { SVGMining5 } from './objects/svg/mining/svgmining5';
import { SVGMining6 } from './objects/svg/mining/svgmining6';
import { SVGMining7 } from './objects/svg/mining/svgmining7';
import { SVGMining8 } from './objects/svg/mining/svgmining8';
import { SVGMining9 } from './objects/svg/mining/svgmining9';
import { SVGMining10 } from './objects/svg/mining/svgmining10';
import { SVGMining11 } from './objects/svg/mining/svgmining11';
import { SVGMining12 } from './objects/svg/mining/svgmining12';
import { SVGMining13 } from './objects/svg/mining/svgmining13';
import { SVGMining14 } from './objects/svg/mining/svgmining14';
import { SVGMining15 } from './objects/svg/mining/svgmining15';
import { SVGMining16 } from './objects/svg/mining/svgmining16';
import { SVGMining17 } from './objects/svg/mining/svgmining17';
import { SVGMining18 } from './objects/svg/mining/svgmining18';
import { SVGMining19 } from './objects/svg/mining/svgmining19';
import { SVGMining20 } from './objects/svg/mining/svgmining20';
import { SVGMining21 } from './objects/svg/mining/svgmining21';
import { SVGMining22 } from './objects/svg/mining/svgmining22';
import { SVGMining23 } from './objects/svg/mining/svgmining23';
import { SVGMining24 } from './objects/svg/mining/svgmining24';
import { SVGMining25 } from './objects/svg/mining/svgmining25';
import { SVGMining26 } from './objects/svg/mining/svgmining26';
import { SVGMining27 } from './objects/svg/mining/svgmining27';
import { SVGMining28 } from './objects/svg/mining/svgmining28';
import { SVGMining29 } from './objects/svg/mining/svgmining29';
import { SVGMining30 } from './objects/svg/mining/svgmining30';
import { SVGMining31 } from './objects/svg/mining/svgmining31';
import { SVGMining32 } from './objects/svg/mining/svgmining32';
import { SVGMining33 } from './objects/svg/mining/svgmining33';
import { SVGMedical1 } from './objects/svg/medical/svgmedical1';
import { SVGMedical2 } from './objects/svg/medical/svgmedical2';
import { SVGMedical3 } from './objects/svg/medical/svgmedical3';
import { SVGMedical4 } from './objects/svg/medical/svgmedical4';
import { SVGMedical5 } from './objects/svg/medical/svgmedical5';
import { SVGMedical6 } from './objects/svg/medical/svgmedical6';
import { SVGMedical7 } from './objects/svg/medical/svgmedical7';
import { SVGMedical8 } from './objects/svg/medical/svgmedical8';
import { SVGMedical9 } from './objects/svg/medical/svgmedical9';
import { SVGMedical10 } from './objects/svg/medical/svgmedical10';
import { SVGMedical11 } from './objects/svg/medical/svgmedical11';
import { SVGMedical12 } from './objects/svg/medical/svgmedical12';
import { SVGMedical13 } from './objects/svg/medical/svgmedical13';
import { SVGMaterial1 } from './objects/svg/material/svgmaterial1';
import { SVGMaterial2 } from './objects/svg/material/svgmaterial2';
import { SVGMaterial3 } from './objects/svg/material/svgmaterial3';
import { SVGMaterial4 } from './objects/svg/material/svgmaterial4';
import { SVGMaterial5 } from './objects/svg/material/svgmaterial5';
import { SVGMaterial6 } from './objects/svg/material/svgmaterial6';
import { SVGMaterial7 } from './objects/svg/material/svgmaterial7';
import { SVGMaterial8 } from './objects/svg/material/svgmaterial8';
import { SVGMaterial9 } from './objects/svg/material/svgmaterial9';
import { SVGMaterial10 } from './objects/svg/material/svgmaterial10';
import { SVGMaterial11 } from './objects/svg/material/svgmaterial11';
import { SVGMaterial12 } from './objects/svg/material/svgmaterial12';
import { SVGMaterial13 } from './objects/svg/material/svgmaterial13';
import { SVGMaterial14 } from './objects/svg/material/svgmaterial14';
import { SVGMaterial15 } from './objects/svg/material/svgmaterial15';
import { SVGMaterial16 } from './objects/svg/material/svgmaterial16';
import { SVGMaterial17 } from './objects/svg/material/svgmaterial17';
import { SVGMaterial18 } from './objects/svg/material/svgmaterial18';
import { SVGMaterial19 } from './objects/svg/material/svgmaterial19';
import { SVGMaterial20 } from './objects/svg/material/svgmaterial20';
import { SVGMaterial21 } from './objects/svg/material/svgmaterial21';
import { SVGMaterial22 } from './objects/svg/material/svgmaterial22';
import { SVGMaterial23 } from './objects/svg/material/svgmaterial23';
import { SVGMaterial24 } from './objects/svg/material/svgmaterial24';
import { SVGMaterial25 } from './objects/svg/material/svgmaterial25';
import { SVGMaterial26 } from './objects/svg/material/svgmaterial26';
import { SVGMaterial27 } from './objects/svg/material/svgmaterial27';
import { SVGMaterial28 } from './objects/svg/material/svgmaterial28';
import { SVGMaterial29 } from './objects/svg/material/svgmaterial29';
import { SVGMaterial30 } from './objects/svg/material/svgmaterial30';
import { SVGMaterial31 } from './objects/svg/material/svgmaterial31';
import { SVGMaterial32 } from './objects/svg/material/svgmaterial32';
import { SVGMaterial33 } from './objects/svg/material/svgmaterial33';
import { SVGMachine1 } from './objects/svg/machining/svgmachine1';
import { SVGMachine2 } from './objects/svg/machining/svgmachine2';
import { SVGMachine3 } from './objects/svg/machining/svgmachine3';
import { SVGMachine4 } from './objects/svg/machining/svgmachine4';
import { SVGMachine5 } from './objects/svg/machining/svgmachine5';
import { SVGMachine6 } from './objects/svg/machining/svgmachine6';
import { SVGMachine7 } from './objects/svg/machining/svgmachine7';
import { SVGMachine8 } from './objects/svg/machining/svgmachine8';
import { SVGMachine9 } from './objects/svg/machining/svgmachine9';
import { SVGMachine10 } from './objects/svg/machining/svgmachine10';
import { SVGMachine11 } from './objects/svg/machining/svgmachine11';
import { SVGMachine12 } from './objects/svg/machining/svgmachine12';
import { SVGMachine13 } from './objects/svg/machining/svgmachine13';
import { SVGMachine14 } from './objects/svg/machining/svgmachine14';
import { SVGMachine15 } from './objects/svg/machining/svgmachine15';
import { SVGMachine16 } from './objects/svg/machining/svgmachine16';
import { SVGMachine17 } from './objects/svg/machining/svgmachine17';
import { SVGMachine18 } from './objects/svg/machining/svgmachine18';
import { SVGMachine19 } from './objects/svg/machining/svgmachine19';
import { SVGMachine20 } from './objects/svg/machining/svgmachine20';
import { SVGMachine21 } from './objects/svg/machining/svgmachine21';
import { SVGMachine22 } from './objects/svg/machining/svgmachine22';
import { SVGMachine23 } from './objects/svg/machining/svgmachine23';
import { SVGMachine24 } from './objects/svg/machining/svgmachine24';
import { SVGMachine25 } from './objects/svg/machining/svgmachine25';
import { SVGMachine26 } from './objects/svg/machining/svgmachine26';
import { SVGMachine27 } from './objects/svg/machining/svgmachine27';
import { SVGMachine28 } from './objects/svg/machining/svgmachine28';
import { SVGMachine29 } from './objects/svg/machining/svgmachine29';
import { SVGMachine30 } from './objects/svg/machining/svgmachine30';
import { SVGMachine31 } from './objects/svg/machining/svgmachine31';
import { SVGMachine32 } from './objects/svg/machining/svgmachine32';
import { SVGMachine33 } from './objects/svg/machining/svgmachine33';
import { SVGMachine34 } from './objects/svg/machining/svgmachine34';
import { SVGLogistic1 } from './objects/svg/logistic/svglogistic1';
import { SVGLogistic2 } from './objects/svg/logistic/svglogistic2';
import { SVGLogistic3 } from './objects/svg/logistic/svglogistic3';
import { SVGLogistic4 } from './objects/svg/logistic/svglogistic4';
import { SVGLogistic5 } from './objects/svg/logistic/svglogistic5';
import { SVGLogistic6 } from './objects/svg/logistic/svglogistic6';
import { SVGLogistic7 } from './objects/svg/logistic/svglogistic7';
import { SVGLogistic8 } from './objects/svg/logistic/svglogistic8';
import { SVGLogistic9 } from './objects/svg/logistic/svglogistic9';
import { SVGLogistic10 } from './objects/svg/logistic/svglogistic10';
import { SVGLogistic11 } from './objects/svg/logistic/svglogistic11';
import { SVGSymbol1 } from './objects/svg/symbols/svgsymbol1';
import { SVGSymbol2 } from './objects/svg/symbols/svgsymbol2';
import { SVGSymbol3 } from './objects/svg/symbols/svgsymbol3';
import { SVGSymbol4 } from './objects/svg/symbols/svgsymbol4';
import { SVGSymbol5 } from './objects/svg/symbols/svgsymbol5';
import { SVGSymbol6 } from './objects/svg/symbols/svgsymbol6';
import { SVGSymbol7 } from './objects/svg/symbols/svgsymbol7';
import { SVGSymbol8 } from './objects/svg/symbols/svgsymbol8';
import { SVGSymbol9 } from './objects/svg/symbols/svgsymbol9';
import { SVGSymbol10 } from './objects/svg/symbols/svgsymbol10';
import { SVGSymbol11 } from './objects/svg/symbols/svgsymbol11';
import { SVGSymbol12 } from './objects/svg/symbols/svgsymbol12';
import { SVGSymbol13 } from './objects/svg/symbols/svgsymbol13';
import { SVGSymbol14 } from './objects/svg/symbols/svgsymbol14';
import { SVGHvac1 } from './objects/svg/hvac/svghvac1';
import { SVGHvac2 } from './objects/svg/hvac/svghvac2';
import { SVGHvac3 } from './objects/svg/hvac/svghvac3';
import { SVGHvac4 } from './objects/svg/hvac/svghvac4';
import { SVGHvac5 } from './objects/svg/hvac/svghvac5';
import { SVGHvac6 } from './objects/svg/hvac/svghvac6';
import { SVGHvac7 } from './objects/svg/hvac/svghvac7';
import { SVGHvac8 } from './objects/svg/hvac/svghvac8';
import { SVGHvac9 } from './objects/svg/hvac/svghvac9';
import { SVGHvac10 } from './objects/svg/hvac/svghvac10';
import { SVGHvac11 } from './objects/svg/hvac/svghvac11';
import { SVGHvac12 } from './objects/svg/hvac/svghvac12';
import { SVGHvac13 } from './objects/svg/hvac/svghvac13';
import { SVGHvac14 } from './objects/svg/hvac/svghvac14';
import { SVGHvac15 } from './objects/svg/hvac/svghvac15';
import { SVGHvac16 } from './objects/svg/hvac/svghvac16';
import { SVGHvac17 } from './objects/svg/hvac/svghvac17';
import { SVGHvac18 } from './objects/svg/hvac/svghvac18';
import { SVGHvac19 } from './objects/svg/hvac/svghvac19';
import { SVGHvac21 } from './objects/svg/hvac/svghvac21';
import { SVGHvac22 } from './objects/svg/hvac/svghvac22';
import { SVGHvac23 } from './objects/svg/hvac/svghvac23';
import { SVGHvac24 } from './objects/svg/hvac/svghvac24';
import { SVGHvac25 } from './objects/svg/hvac/svghvac25';
import { SVGHvac26 } from './objects/svg/hvac/svghvac26';
import { SVGHvac27 } from './objects/svg/hvac/svghvac27';
import { SVGHvac28 } from './objects/svg/hvac/svghvac28';
import { SVGHvac29 } from './objects/svg/hvac/svghvac29';
import { SVGHvac30 } from './objects/svg/hvac/svghvac30';
import { SVGFood1 } from './objects/svg/food/svgfood1';
import { SVGFood2 } from './objects/svg/food/svgfood2';
import { SVGFood3 } from './objects/svg/food/svgfood3';
import { SVGFood4 } from './objects/svg/food/svgfood4';
import { SVGFood5 } from './objects/svg/food/svgfood5';
import { SVGFood6 } from './objects/svg/food/svgfood6';
import { SVGFood7 } from './objects/svg/food/svgfood7';
import { SVGFood8 } from './objects/svg/food/svgfood8';
import { SVGFood9 } from './objects/svg/food/svgfood9';
import { SVGFood10 } from './objects/svg/food/svgfood10';
import { SVGFood11 } from './objects/svg/food/svgfood11';
import { SVGFood12 } from './objects/svg/food/svgfood12';
import { SVGFood13 } from './objects/svg/food/svgfood13';
import { SVGFood14 } from './objects/svg/food/svgfood14';
import { SVGFood15 } from './objects/svg/food/svgfood15';
import { SVGFood16 } from './objects/svg/food/svgfood16';
import { SVGFood17 } from './objects/svg/food/svgfood17';
import { SVGFood18 } from './objects/svg/food/svgfood18';
import { SVGFood19 } from './objects/svg/food/svgfood19';
import { SVGFood20 } from './objects/svg/food/svgfood20';
import { SVGFood21 } from './objects/svg/food/svgfood21';
import { SVGFood22 } from './objects/svg/food/svgfood22';
import { SVGFood23 } from './objects/svg/food/svgfood23';
import { SVGFood24 } from './objects/svg/food/svgfood24';
import { SVGFood25 } from './objects/svg/food/svgfood25';
import { SVGFood26 } from './objects/svg/food/svgfood26';
import { SVGFood27 } from './objects/svg/food/svgfood27';
import { SVGFood28 } from './objects/svg/food/svgfood28';
import { SVGFlex1 } from './objects/svg/flex/svgflex1';
import { SVGFlex2 } from './objects/svg/flex/svgflex2';
import { SVGFlex3 } from './objects/svg/flex/svgflex3';
import { SVGFlex4 } from './objects/svg/flex/svgflex4';
import { SVGFlex5 } from './objects/svg/flex/svgflex5';
import { SVGFlex6 } from './objects/svg/flex/svgflex6';
import { SVGFlex7 } from './objects/svg/flex/svgflex7';
import { SVGFlex8 } from './objects/svg/flex/svgflex8';
import { SVGFlex9 } from './objects/svg/flex/svgflex9';
import { SVGFlex10 } from './objects/svg/flex/svgflex10';
import { SVGFlow1 } from './objects/svg/flow/svgflow1';
import { SVGFlow2 } from './objects/svg/flow/svgflow2';
import { SVGFlow3 } from './objects/svg/flow/svgflow3';
import { SVGFlow4 } from './objects/svg/flow/svgflow4';
import { SVGFlow5 } from './objects/svg/flow/svgflow5';
import { SVGFlow6 } from './objects/svg/flow/svgflow6';
import { SVGFlow7 } from './objects/svg/flow/svgflow7';
import { SVGFlow8 } from './objects/svg/flow/svgflow8';
import { SVGFlow9 } from './objects/svg/flow/svgflow9';
import { SVGFlow10 } from './objects/svg/flow/svgflow10';
import { SVGFlow11 } from './objects/svg/flow/svgflow11';
import { SVGFlow12 } from './objects/svg/flow/svgflow12';
import { SVGFlow13 } from './objects/svg/flow/svgflow13';
import { SVGFlow14 } from './objects/svg/flow/svgflow14';
import { SVGFinish1 } from './objects/svg/finish/svgfinish1';
import { SVGFinish2 } from './objects/svg/finish/svgfinish2';
import { SVGFinish3 } from './objects/svg/finish/svgfinish3';
import { SVGFinish4 } from './objects/svg/finish/svgfinish4';
import { SVGFinish5 } from './objects/svg/finish/svgfinish5';
import { SVGFinish6 } from './objects/svg/finish/svgfinish6';
import { SVGFinish7 } from './objects/svg/finish/svgfinish7';
import { SVGFinish8 } from './objects/svg/finish/svgfinish8';
import { SVGFinish9 } from './objects/svg/finish/svgfinish9';
import { SVGFinish10 } from './objects/svg/finish/svgfinish10';
import { SVGFinish11 } from './objects/svg/finish/svgfinish11';
import { SVGFinish12 } from './objects/svg/finish/svgfinish12';
import { SVGFinish13 } from './objects/svg/finish/svgfinish13';
import { SVGFinish14 } from './objects/svg/finish/svgfinish14';
import { SVGFinish15 } from './objects/svg/finish/svgfinish15';
import { SVGFinish16 } from './objects/svg/finish/svgfinish16';
import { SVGFinish17 } from './objects/svg/finish/svgfinish17';
import { SVGFinish18 } from './objects/svg/finish/svgfinish18';
import { SVGFinish19 } from './objects/svg/finish/svgfinish19';
import { SVGFinish20 } from './objects/svg/finish/svgfinish20';
import { SVGFinish21 } from './objects/svg/finish/svgfinish21';
import { SVGFinish22 } from './objects/svg/finish/svgfinish22';
import { SVGFinish23 } from './objects/svg/finish/svgfinish23';
import { SVGFinish24 } from './objects/svg/finish/svgfinish24';
import { SVGFinish25 } from './objects/svg/finish/svgfinish25';
import { SVGFinish26 } from './objects/svg/finish/svgfinish26';
import { SVGFinish27 } from './objects/svg/finish/svgfinish27';
import { SVGFinish28 } from './objects/svg/finish/svgfinish28';
import { SVGDuct1 } from './objects/svg/duct/svgduct1';
import { SVGDuct2 } from './objects/svg/duct/svgduct2';
import { SVGDuct3 } from './objects/svg/duct/svgduct3';
import { SVGDuct4 } from './objects/svg/duct/svgduct4';
import { SVGDuct5 } from './objects/svg/duct/svgduct5';
import { SVGDuct6 } from './objects/svg/duct/svgduct6';
import { SVGDuct7 } from './objects/svg/duct/svgduct7';
import { SVGDuct8 } from './objects/svg/duct/svgduct8';
import { SVGDuct9 } from './objects/svg/duct/svgduct9';
import { SVGDuct10 } from './objects/svg/duct/svgduct10';
import { SVGDuct11 } from './objects/svg/duct/svgduct11';
import { SVGDuct12 } from './objects/svg/duct/svgduct12';
import { SVGDuct13 } from './objects/svg/duct/svgduct13';
import { SVGDuct14 } from './objects/svg/duct/svgduct14';
import { SVGDuct15 } from './objects/svg/duct/svgduct15';
import { SVGDuct16 } from './objects/svg/duct/svgduct16';
import { SVGDuct17 } from './objects/svg/duct/svgduct17';
import { SVGDuct18 } from './objects/svg/duct/svgduct18';
import { SVGDuct19 } from './objects/svg/duct/svgduct19';
import { SVGDuct20 } from './objects/svg/duct/svgduct20';
import { SVGDuct21 } from './objects/svg/duct/svgduct21';
import { SVGDuct22 } from './objects/svg/duct/svgduct22';
import { SVGDuct23 } from './objects/svg/duct/svgduct23';
import { SVGDuct24 } from './objects/svg/duct/svgduct24';
import { SVGDuct25 } from './objects/svg/duct/svgduct25';
import { SVGConveyor1 } from './objects/svg/conveyors/svgconveyor1';
import { SVGConveyor2 } from './objects/svg/conveyors/svgconveyor2';
import { SVGConveyor3 } from './objects/svg/conveyors/svgconveyor3';
import { SVGConveyor4 } from './objects/svg/conveyors/svgconveyor4';
import { SVGConveyor5 } from './objects/svg/conveyors/svgconveyor5';
import { SVGConveyor6 } from './objects/svg/conveyors/svgconveyor6';
import { SVGConveyor7 } from './objects/svg/conveyors/svgconveyor7';
import { SVGConveyor8 } from './objects/svg/conveyors/svgconveyor8';
import { SVGConveyor9 } from './objects/svg/conveyors/svgconveyor9';
import { SVGConveyor10 } from './objects/svg/conveyors/svgconveyor10';
import { SVGConveyor11 } from './objects/svg/conveyors/svgconveyor11';
import { SVGController1 } from './objects/svg/controllers/svgcontroller1';
import { SVGController2 } from './objects/svg/controllers/svgcontroller2';
import { SVGController3 } from './objects/svg/controllers/svgcontroller3';
import { SVGController4 } from './objects/svg/controllers/svgcontroller4';
import { SVGController5 } from './objects/svg/controllers/svgcontroller5';
import { SVGController6 } from './objects/svg/controllers/svgcontroller6';
import { SVGController7 } from './objects/svg/controllers/svgcontroller7';
import { SVGController8 } from './objects/svg/controllers/svgcontroller8';
import { SVGController9 } from './objects/svg/controllers/svgcontroller9';
import { SVGController10 } from './objects/svg/controllers/svgcontroller10';
import { SVGController11 } from './objects/svg/controllers/svgcontroller11';
import { SVGContainer1 } from './objects/svg/containers/svgcontainer1';
import { SVGContainer2 } from './objects/svg/containers/svgcontainer2';
import { SVGContainer3 } from './objects/svg/containers/svgcontainer3';
import { SVGContainer4 } from './objects/svg/containers/svgcontainer4';
import { SVGContainer5 } from './objects/svg/containers/svgcontainer5';
import { SVGContainer6 } from './objects/svg/containers/svgcontainer6';
import { SVGContainer7 } from './objects/svg/containers/svgcontainer7';
import { SVGContainer8 } from './objects/svg/containers/svgcontainer8';
import { SVGContainer9 } from './objects/svg/containers/svgcontainer9';
import { SVGContainer10 } from './objects/svg/containers/svgcontainer10';
import { SVGContainer11 } from './objects/svg/containers/svgcontainer11';
import { SVGChemical1 } from './objects/svg/chemical/svgchemical1';
import { SVGChemical2 } from './objects/svg/chemical/svgchemical2';
import { SVGChemical3 } from './objects/svg/chemical/svgchemical3';
import { SVGChemical4 } from './objects/svg/chemical/svgchemical4';
import { SVGChemical5 } from './objects/svg/chemical/svgchemical5';
import { SVGChemical6 } from './objects/svg/chemical/svgchemical6';
import { SVGChemical7 } from './objects/svg/chemical/svgchemical7';
import { SVGChemical8 } from './objects/svg/chemical/svgchemical8';
import { SVGChemical9 } from './objects/svg/chemical/svgchemical9';
import { SVGChemical10 } from './objects/svg/chemical/svgchemical10';
import { SVGChemical11 } from './objects/svg/chemical/svgchemical11';
import { SVGChemical12 } from './objects/svg/chemical/svgchemical12';
import { SVGChemical13 } from './objects/svg/chemical/svgchemical13';
import { SVGChemical14 } from './objects/svg/chemical/svgchemical14';
import { SVGChemical15 } from './objects/svg/chemical/svgchemical15';
import { SVGFactory1 } from './objects/svg/factory/svgfactory1';
import { SVGFactory2 } from './objects/svg/factory/svgfactory2';
import { SVGFactory3 } from './objects/svg/factory/svgfactory3';
import { SVGFactory4 } from './objects/svg/factory/svgfactory4';
import { SVGFactory5 } from './objects/svg/factory/svgfactory5';
import { SVGFactory6 } from './objects/svg/factory/svgfactory6';
import { SVGFactory7 } from './objects/svg/factory/svgfactory7';
import { SVGFactory8 } from './objects/svg/factory/svgfactory8';
import { SVGFactory9 } from './objects/svg/factory/svgfactory9';
import { SVGFactory10 } from './objects/svg/factory/svgfactory10';
import { SVGFactory11 } from './objects/svg/factory/svgfactory11';
import { SVGFactory12 } from './objects/svg/factory/svgfactory12';
import { SVGFactory13 } from './objects/svg/factory/svgfactory13';
import { SVGFactory14 } from './objects/svg/factory/svgfactory14';
import { SVGFactory15 } from './objects/svg/factory/svgfactory15';
import { SVGFactory16 } from './objects/svg/factory/svgfactory16';
import { SVGFactory17 } from './objects/svg/factory/svgfactory17';
import { SVGBoiler1 } from './objects/svg/boilers/svgboiler1';
import { SVGBoiler2 } from './objects/svg/boilers/svgboiler2';
import { SVGBoiler3 } from './objects/svg/boilers/svgboiler3';
import { SVGBoiler4 } from './objects/svg/boilers/svgboiler4';
import { SVGBoiler5 } from './objects/svg/boilers/svgboiler5';
import { SVGBoiler6 } from './objects/svg/boilers/svgboiler6';
import { SVGBoiler7 } from './objects/svg/boilers/svgboiler7';
import { SVGBoiler8 } from './objects/svg/boilers/svgboiler8';
import { SVGBoiler9 } from './objects/svg/boilers/svgboiler9';
import { SVGBoiler10 } from './objects/svg/boilers/svgboiler10';
import { SVGBoiler11 } from './objects/svg/boilers/svgboiler11';
import { SVGBoiler12 } from './objects/svg/boilers/svgboiler12';
import { SVGBlower1 } from './objects/svg/blowers/svgblower1';
import { SVGBlower2 } from './objects/svg/blowers/svgblower2';
import { SVGBlower3 } from './objects/svg/blowers/svgblower3';
import { SVGBlower4 } from './objects/svg/blowers/svgblower4';
import { SVGBlower5 } from './objects/svg/blowers/svgblower5';
import { SVGBlower6 } from './objects/svg/blowers/svgblower6';
import { SVGBlower7 } from './objects/svg/blowers/svgblower7';
import { SVGBlower8 } from './objects/svg/blowers/svgblower8';
import { SVGBlower9 } from './objects/svg/blowers/svgblower9';
import { SVGBlower10 } from './objects/svg/blowers/svgblower10';
import { SVGBlower11 } from './objects/svg/blowers/svgblower11';
import { SVGBlower12 } from './objects/svg/blowers/svgblower12';
import { SVGBlower13 } from './objects/svg/blowers/svgblower13';
import { SVGButton1 } from './objects/svg/buttons/svgbutton1';
import { SVGButton2 } from './objects/svg/buttons/svgbutton2';
import { SVGButton3 } from './objects/svg/buttons/svgbutton3';
import { SVGButton4 } from './objects/svg/buttons/svgbutton4';
import { SVGButton5 } from './objects/svg/buttons/svgbutton5';
import { SVGButton6 } from './objects/svg/buttons/svgbutton6';
import { SVGButton7 } from './objects/svg/buttons/svgbutton7';
import { SVGButton8 } from './objects/svg/buttons/svgbutton8';
import { SVGButton9 } from './objects/svg/buttons/svgbutton9';
import { SVGButton10 } from './objects/svg/buttons/svgbutton10';
import { SVGButton11 } from './objects/svg/buttons/svgbutton11';
import { SVGButton12 } from './objects/svg/buttons/svgbutton12';
import { SVGButton13 } from './objects/svg/buttons/svgbutton13';
import { SVGButton14 } from './objects/svg/buttons/svgbutton14';
import { SVGButton15 } from './objects/svg/buttons/svgbutton15';
import { SVGButton16 } from './objects/svg/buttons/svgbutton16';
import { SVGButton17 } from './objects/svg/buttons/svgbutton17';
import { SVGButton18 } from './objects/svg/buttons/svgbutton18';
import { SVGButton19 } from './objects/svg/buttons/svgbutton19';
import { SVGArchitecture1 } from './objects/svg/architecture/svgarchitecture1';
import { SVGArchitecture2 } from './objects/svg/architecture/svgarchitecture2';
import { SVGArchitecture3 } from './objects/svg/architecture/svgarchitecture3';
import { SVGArchitecture4 } from './objects/svg/architecture/svgarchitecture4';
import { SVGArchitecture5 } from './objects/svg/architecture/svgarchitecture5';
import { SVGArchitecture6 } from './objects/svg/architecture/svgarchitecture6';
import { SVGArchitecture7 } from './objects/svg/architecture/svgarchitecture7';
import { SVGArchitecture8 } from './objects/svg/architecture/svgarchitecture8';
import { SVGArchitecture9 } from './objects/svg/architecture/svgarchitecture9';
import { SVGArchitecture10 } from './objects/svg/architecture/svgarchitecture10';
import { SVGArchitecture11 } from './objects/svg/architecture/svgarchitecture11';
import { SVGArchitecture12 } from './objects/svg/architecture/svgarchitecture12';
import { SVGArchitecture13 } from './objects/svg/architecture/svgarchitecture13';
import { SVGArchitecture14 } from './objects/svg/architecture/svgarchitecture14';
import { SVGArchitecture15 } from './objects/svg/architecture/svgarchitecture15';
import { SVGArchitecture16 } from './objects/svg/architecture/svgarchitecture16';
import { SVGArchitecture17 } from './objects/svg/architecture/svgarchitecture17';
import { SVGArchitecture18 } from './objects/svg/architecture/svgarchitecture18';
import { SVGArchitecture19 } from './objects/svg/architecture/svgarchitecture19';
import { SVGArchitecture20 } from './objects/svg/architecture/svgarchitecture20';
import { SVGArchitecture21 } from './objects/svg/architecture/svgarchitecture21';
import { DatabaseTable } from './objects/databasetable';
import { PercantageTile } from './objects/tiles/percantagetile';
import { ClockTile } from './objects/tiles/clocktile';
import { GaugeTile } from './objects/tiles/gaugetile';
import { SparklineTile } from './objects/tiles/sparklinetile';
import { SmoothedChartTile } from './objects/tiles/smoothedcharttile';
import { TimerControlTile } from './objects/tiles/timercontroltile';
import { NumberTile } from './objects/tiles/numbertile';
import { HighlowTile } from './objects/tiles/highlowtile';
import { PlusMinusTile } from './objects/tiles/plusminustile';
import { SliderTile } from './objects/tiles/slidertile';
import { SwitchTile } from './objects/tiles/switchtile';
import { ButtonTile } from './objects/tiles/buttontile';
import { TimeTile } from './objects/tiles/timetile';
import { BarChartTile } from './objects/tiles/barcharttile';
import { RadialChartTile } from './objects/tiles/radialcharttile';
import { DonutChartTile } from './objects/tiles/donutcharttile';
import { CircularProgressTile } from './objects/tiles/circularprogresstile';
import { StockTile } from './objects/tiles/stocktile';
import { GaugeSparklineTile } from './objects/tiles/gaugesparklinetile';
import { BarGaugeTile } from './objects/tiles/bargaugetile';
import { LedTile } from './objects/tiles/ledtile';
import { ColorTile } from './objects/tiles/colortile';
import { FluidTile } from './objects/tiles/fluidtile';
import { Script } from './script';
import { DateChooser } from './objects/datechooser';
import { Gauge2 } from './objects/gauge2';
import { Gauge3 } from './objects/gauge3';

export class Screen{
    name:string;
    color:string;
    type:number;
    windowheight:number;
    windowwidth:number;
    usepassword:boolean;
    password:string;
    posx:number;
    posy:number;
    accesslevel:number;
    layer:Konva.Layer;
    scripts:Script[];
    timerUpdate:any
    id:number;
   //tags:tag[]

    @Type(() => ObjectView , {
        discriminator: {
          property: '__type',
          subTypes: [
            { value: Line, name: 'line' },
            { value: TextView, name: 'text'},
            { value: Ellipse, name: 'ellipse'},
            { value: Rectangle, name: 'rectangle'},
           
            { value: Button, name:'button'},
            { value: ImageView, name:'image'},  
            { value: GroupView, name:'group'},
            { value: Polygon, name:'polygon'},
            { value: Polyline, name:'polyline'},
            { value: Sector, name:'sector'},
            { value: Border, name:'border'},
            { value:Scale, name: 'scale'},
            { value: TextArea, name:'textarea'},
            { value: Sphere, name: 'sphere'},
            { value: Cylinder, name: 'cylinder'},
            { value: Cylinder3D, name: 'cylinder3d'},
            { value: Cone, name: 'cone'},
            { value: Sector3D, name: 'sector3d'},
            { value: PolygonGradient, name:'polygongradient'},
            { value: Tank, name:'tank'},
            {value: Border3d, name:'border3d'},
            {value: Text3DView, name:'text3d'},
            {value: ValueWithHistory, name:'valuewithhistory'},
            {value: PressButton,name:'pressbutton'},
            {value: ToggleButton, name:'togglebutton'},
            {value: LightButton, name:'lightbutton'},
            {value: OvalLightButton, name:'ovallightbutton'},
            {value:ImageButton, name:'imagebutton'},
            {value:OvalJumpButton, name:'ovaljumpbutton'},
            {value:RectJumpButton, name:'rectjumpbutton'},
            {value:RightJumpButton, name:'rightjumpbutton'},
            {value:LeftJumpButton, name:'leftjumpbutton'},
            {value:Switch,name:'switch'},
            {value:Switch3Pos ,name:'switch3pos'},
            {value:SwitchApple, name:'switchapple'},
            {value:OvalLamp, name:'ovallamp'},
            {value:RectangleLamp, name:'rectlamp'},
            {value:TriangleLamp, name:'trilamp'},
            {value:TriangleLamp2, name:'trilamp2'},
            {value:StackLamp, name:'stacklamp'},
            {value:Indicator, name:'indicator'},
            {value: RectIndicator, name:"rectindicator"},
            {value: OvalIndicator, name:"ovalindicator"},
            {value: Slider, name:"slider"},
            {value: SliderVertical, name:"sliderver"},
            {value: SliderHorizontal, name:"sliderhor"},
            {value: SliderApple, name:"sliderapple"},
            {value: Counter, name:"counter"},
            {value: CounterRect, name:"counterrect"},
            {value: Selector, name: "selector"},
            {value: Combobox, name:"combobox"},
            {value:Menubox, name:"menubox"},
            {value: Checkbox, name:"checkbox"},
            {value:MenuCheckList, name:"menuchecklist"},
            {value:CheckList, name:"checklist"},
            {value:ParameterList, name:"parameterlist"},
            {value:StraightPipe, name:"straightpipe"},
            {value:StraightPipe2, name:"straightpipe2"},
            {value:EndPipe, name:"endpipe"},
            {value:EndPipe2, name:"endpipe2"},
            {value:BowPipe, name:"bowpipe"},
            {value:BowPipe2, name:"bowpipe2"},
            {value:TeePipe, name:"teepipe"},
            {value:TeePipe2, name:"teepipe2"},
            {value:IntersectPipe, name:"intersectpipe"},
            {value:IntersectPipe2, name:"intersectpipe2"},
            {value:ElbowPipe, name:"elbowpipe"},
            {value:ElbowPipe2, name:"elbowpipe2"},
            {value:PolyPipe, name:"polypipe"},
            {value:Valve, name:"valve"},
            {value:ValveRound, name:"valveround"},
            {value:ValveISA, name:"valveisa"},
            {value:ValveBall, name:"valveball"},
            {value:ValvePosition, name:"valveposition"},
            {value:Pump, name:"pump"},
            {value:Motor, name:"pumpmotor"},
            {value:AirBlowerPump, name:"airblowerpump"},
            {value:PumpStand,name:"pumpstand"},
            {value:Fan,name:"fan"},
            {value:CentrifugalPump,name:"centrifugalpump"},
            {value:FanRound,name:"fanround"},
            {value:FanSquare,name:"fansquare"},
            {value:FanCool,name:"fancool"},
            {value:FanBlades,name:"fanblades"},
            {value:FanCentrifugal,name:"fancentrifugal"},
            {value:FanAxial, name:"fanaxial"},
            {value:VerticalTank, name:"verticaltank"},
            {value:HorizontalTank, name:"horizontaltank"},
            {value:ConeTank, name:"conetank"},
            {value:VerticalTankWithTrend,name:"verticaltankwithtrend"},
            {value:HorizontalTankWithTrend,name:"horizontaltankwithtrend"},
            {value:ScrewConveyer, name:"screwconveyer"},
            {value:ScrewConveyerMotion, name:"screwconveyermotion"},
            {value: BeltConveyer, name: "beltconveyer"},
            {value:Auger, name:"auger"},
            {value:AnalogMeter, name:"analogmeter"},
            {value:AnalogMeterRect, name:"analogmeterrect"},
            {value:AnalogMeter90DegreesRect,name:"analogmeter90degrees"},
            {value:AnalogMeter90DegreesRect2,name:"analogmeter90degrees2"},
            {value:AnalogMeterRound, name:"analogmeterround"},
            {value:AnalogMeterVertical,name:"analogmetervertical"},
            {value:AnalogMeterVerticalFill,name:"analogmeterverticalfill"},
            {value:AnalogMeterHorizontal, name:"analogmeterhorizontal"},
            {value:AnalogMeterHorizontalFill,name:"analogmeterhorizontalfill"},
            {value:RangeIndicator, name:"rangeindicator"},
            {value:Gauge, name:"gauge"},
            {value:Gauge180, name:"gauge180"},
            {value:Gauge90, name:"gauge90"},
            {value:Gauge90_2, name:"gauge902"},
            {value:DigitalMeter, name: "digitalmeter"},
            {value:Digital4DigitMeter, name: "digitalmeter4"},
            {value:Digital6DigitMeter, name: "digitalmeter6"},
            {value:Digital8DigitMeter, name: "digitalmeter8"},
            {value:DigitalMeterHistoryAndEvent, name: "digitalmeterwithhistory"},
            {value:ElectricalSwitch, name:"electricalswitch"},
            {value:ElectricalSwitch2Way,name: "electricalswitchtwoway"},
            {value:ElectricalSwitchWithNeutral, name:"electricalswitchtwowaywithneutral"},
            {value:ElectricalTransformer, name: "electricaltransformer"},
            {value:AnalogClock,name:"analogclock"},
            {value:DigitalClock,name:"digitalclock"},
            {value:DateView,name:"date"},
            {value:Trend,name:"trend"},
            {value:EventsTable, name:"eventstable"},
            {value:PieChart, name:"piechart"},
            {value:EventsTicker, name:"eventsticker"},
            {value:Diagram, name:"diagram"},
            {value:WindChart, name:"windchart"},
            {value:RecipeTable, name:"recipetable"},
            {value:RecipeSelector, name:"recipeselector"},
            {value:TrendHistoryDB, name:"historydbtrend"},
            {value:HistoryTable, name: "historytable"},
            {value:TrendHistoryXYDB, name: "historyxydbtrend"},
            {value:ColorPicker, name:"colorpicker"},
            {value:ColorRectangle, name:"colorrect"},
            {value:Video, name:"video"},
            {value:SVGTank, name:"tanksvg"},
            {value: ReactorTank, name:"reactortank"},
            {value:SVGTank2, name:"tanksvg2"},
            {value:SVGTank3, name:"tanksvg3"},
            {value:SVGTank4, name:"tanksvg4"},
            {value:SVGTank5, name:"tanksvg5"},
            {value:SVGTank6, name:"tanksvg6"},
            {value:SVGTank7, name:"tanksvg7"},
            {value:SVGTank8, name:"tanksvg8"},
            {value:SVGTank9, name:"tanksvg9"},
            {value:SVGTank10, name:"tanksvg10"},
            {value:SVGTank11, name:"tanksvg11"},
            {value:SVGTank12, name:"tanksvg12"},
            {value:SVGTank13, name:"tanksvg13"},
            {value:SVGTank14, name:"tanksvg14"},
            {value:SVGTank15, name:"tanksvg15"},
            {value:SVGTank16, name:"tanksvg16"},
            {value:SVGTank17, name:"tanksvg17"},
            {value:SVGTank18, name:"tanksvg18"},
            {value:SVGTank19, name:"tanksvg19"},
            {value:SVGTank20, name:"tanksvg20"},
            {value:SVGTank21, name:"tanksvg21"},
            {value:SVGTank22, name:"tanksvg22"},
            {value:SVGTank23, name:"tanksvg23"},
            {value:SVGTank24, name:"tanksvg24"},
            {value:SVGTank25, name:"tanksvg25"},
            {value:SVGTank26, name:"tanksvg26"},
            {value:SVGTank27, name:"tanksvg27"},
            {value:SVGTank28, name:"tanksvg28"},
            {value:SVGTank29, name:"tanksvg29"},
            {value:SVGTank30, name:"tanksvg30"},
            {value:SVGTank32, name:"tanksvg32"},
            {value:SVGTank33, name:"tanksvg33"},
            {value:SVGTank34, name:"tanksvg34"},
            {value:SVGTank35, name:"tanksvg35"},
            {value:SVGTank36, name:"tanksvg36"},
            {value:SVGTank37, name:"tanksvg37"},
            {value:SVGTank38, name:"tanksvg38"},
            {value:SVGTank39, name:"tanksvg39"},
            {value:SVGTank40, name:"tanksvg40"},
            {value:Reactor1, name:"reactor1"},
            {value:Reactor2, name:"reactor2"},
            {value:Reactor4, name:"reactor4"},
            {value:Reactor5, name:"reactor5"},
            {value:Reactor6, name:"reactor6"},
            {value:Reactor7, name:"reactor7"},
            {value:Reactor8, name:"reactor8"},
            {value:Reactor9, name:"reactor9"},
            {value:Reactor10, name:"reactor10"},
            {value:SVGTank41, name:"tanksvg41"},
            {value:SVGTank42, name:"tanksvg42"},
            {value:SVGTank43, name:"tanksvg43"},
            {value:SVGTank44, name:"tanksvg44"},
            {value:SVGTank45, name:"tanksvg45"},
            {value:SVGTank46, name:"tanksvg46"},
            {value:SVGTank47, name:"tanksvg47"},
            {value:SVGTank48, name:"tanksvg48"},
            {value:SVGTank49, name:"tanksvg49"},
            {value:SVGTank50, name:"tanksvg50"},
            {value:SVGTank51, name:"tanksvg51"},
            {value:SVGTank52, name:"tanksvg52"},
            {value:SVGTank53, name:"tanksvg53"},
            {value:SVGTank54, name:"tanksvg54"},
            {value:SVGTank55, name:"tanksvg55"},
            {value:SVGTank56, name:"tanksvg56"},
            {value:SVGTank57, name:"tanksvg57"},
            {value:SVGTank58, name:"tanksvg58"},
            {value:SVGTank59, name:"tanksvg59"},
            {value:SVGTank60, name:"tanksvg60"},
            {value:SVGTank61, name:"tanksvg61"},
            {value:SVGTank62, name:"tanksvg62"},
            {value:SVGTank63, name:"tanksvg63"},
            {value:SVGTank64, name:"tanksvg64"},
            {value:SVGTank65, name:"tanksvg65"},
            {value:SVGTank66, name:"tanksvg66"},
            {value:SVGTank67, name:"tanksvg67"},
            {value:SVGTank68, name:"tanksvg68"},
            {value:SVGTank69, name:"tanksvg69"},
            {value:SVGTank70, name:"tanksvg70"},
            {value:SVGTank71, name:"tanksvg71"},
            {value:SVGTank72, name:"tanksvg72"},
            {value:SVGValve1, name:"valve1"},
            {value:SVGValve2, name:"valve2"},
            {value:SVGValve3, name:"valve3"},
            {value:SVGValve4, name:"valve4"},
            {value:SVGValve5, name:"valve5"},
            {value:SVGValve6, name:"valve6"},
            {value:SVGValve7, name:"valve7"},
            {value:SVGValve8, name:"valve8"},
            {value:SVGValve9, name:"valve9"},
            {value:SVGValve10, name:"valve10"},
            {value:SVGValve10, name:"valve10"},
            {value:SVGValve11, name:"valve11"},
            {value:SVGValve12, name:"valve12"},
            {value:SVGValve13, name:"valve13"},
            {value:SVGValve14, name:"valve14"},
            {value:SVGValve15, name:"valve15"},
            {value:SVGValve16, name:"valve16"},
            {value:SVGValve17, name:"valve17"},
            {value:SVGValve18, name:"valve18"},
            {value:SVGValve19, name:"valve19"},
            {value:SVGValve20, name:"valve20"},
            {value:SVGValve21, name:"valve21"},
            {value:SVGValve22, name:"valve22"},
            {value:SVGValve23, name:"valve23"},
            {value:SVGValve24, name:"valve24"},
            {value:SVGValve25, name:"valve25"},
            {value:SVGValve26, name:"valve26"},
            {value:SVGValve27, name:"valve27"},
            {value:SVGValve28, name:"valve28"},
            {value:SVGValve29, name:"valve29"},
            {value:SVGVehicle1, name:"vehicle1"},
            {value:SVGVehicle2, name:"vehicle2"},
            {value:SVGVehicle3, name:"vehicle3"},
            {value:SVGVehicle4, name:"vehicle4"},
            {value:SVGVehicle5, name:"vehicle5"},
            {value:SVGVehicle6, name:"vehicle6"},
            {value:SVGVehicle7, name:"vehicle7"},
            {value:SVGVehicle8, name:"vehicle8"},
            {value:SVGVehicle9, name:"vehicle9"},
            {value:SVGVehicle10, name:"vehicle10"},
            {value:SVGVehicle11, name:"vehicle11"},
            {value:SVGVehicle12, name:"vehicle12"},
            {value:SVGVehicle13, name:"vehicle13"},
            {value:SVGVehicle14, name:"vehicle14"},
            {value:SVGVehicle15, name:"vehicle15"},
            {value:SVGVehicle16, name:"vehicle16"},
            {value:SVGWater1, name:"water1"},
            {value:SVGWater2, name:"water2"},
            {value:SVGWater3, name:"water3"},
            {value:SVGWater4, name:"water4"},
            {value:SVGWater5, name:"water5"},
            {value:SVGWater6, name:"water6"},
            {value:SVGWater7, name:"water7"},
            {value:SVGWater8, name:"water8"},
            {value:SVGWater9, name:"water9"},
            {value:SVGWater10, name:"water10"},
            {value:SVGWater11, name:"water11"},
            {value:SVGWater12, name:"water12"},
            {value:SVGWater13, name:"water13"},
            {value:SVGWater14, name:"water14"},
            {value:SVGWater15, name:"water15"},
            {value:SVGWater16, name:"water16"},
            {value:SVGWater17, name:"water17"},
            {value:SVGWater18, name:"water18"},
            {value:SVGWater19, name:"water19"},
            {value:SVGWater20, name:"water20"},
            {value:SVGWater21, name:"water21"},
            {value:SVGWater22, name:"water22"},
            {value:SVGWater23, name:"water23"},
            {value:SVGWater24, name:"water24"},
            {value:SVGWater25, name:"water25"},
            {value:SVGWater26, name:"water26"},
            {value:SVGWater27, name:"water27"},
            {value:SVGWater28, name:"water28"},
            {value:SVGWater29, name:"water29"},
            {value:SVGWater30, name:"water30"},
            {value:SVGWater31, name:"water31"},
            {value:SVGWater32, name:"water32"},
            {value:SVGWater33, name:"water33"},
            {value:SVGWater34, name:"water34"},
            {value:SVGWater35, name:"water35"},
            {value:SVGWater36, name:"water36"},
            {value:SVGWater37, name:"water37"},
            {value:SVGWater38, name:"water38"},
            {value:SVGWater39, name:"water39"},
            {value:SVGWater40, name:"water40"},
            {value:SVGWater41, name:"water41"},
            {value:SVGWater42, name:"water42"},
            {value:SVGWater43, name:"water43"},
            {value:SVGWater44, name:"water44"},
            {value:SVGWater45, name:"water45"},
            {value:SVGWater46, name:"water46"},
            {value:SVGWater47, name:"water47"},
            {value:SVGWater48, name:"water48"},
            {value:SVGWater49, name:"water49"},
            {value:SVGWater50, name:"water50"},
            {value:SVGWater51, name:"water51"},
            {value:SVGWater52, name:"water52"},
            {value:SVGWater53, name:"water53"},
            {value:SVGWater54, name:"water54"},
            {value:SVGWater55, name:"water55"},
            {value:SVGWater56, name:"water56"},
            {value:SVGWater57, name:"water57"},
            {value:SVGWater58, name:"water58"},
            {value:SVGWater59, name:"water59"},
            {value:SVGWater60, name:"water60"},
            {value:SVGWater61, name:"water61"},
            {value:SVGSensor1, name:"sensor1"},
            {value:SVGSensor2, name:"sensor2"},
            {value:SVGSensor3, name:"sensor3"},
            {value:SVGSensor4, name:"sensor4"},
            {value:SVGSensor5, name:"sensor5"},
            {value:SVGSensor6, name:"sensor6"},
            {value:SVGSensor7, name:"sensor7"},
            {value:SVGSensor8, name:"sensor8"},
            {value:SVGSensor9, name:"sensor9"},
            {value:SVGSensor10, name:"sensor10"},
            {value:SVGSensor11, name:"sensor11"},
            {value:SVGSensor12, name:"sensor12"},
            {value:SVGSensor13, name:"sensor13"},
            {value:SVGSensor14, name:"sensor14"},
            {value:SVGSensor15, name:"sensor15"},
            {value:SVGSensor16, name:"sensor16"},
            {value:SVGSensor17, name:"sensor17"},
            {value:SVGSensor18, name:"sensor18"},
            {value:SVGSensor19, name:"sensor19"},
            {value:SVGSensor20, name:"sensor20"},
            {value:SVGSegpipe1, name:"segpipe1"},
            {value:SVGSegpipe2, name:"segpipe2"},
            {value:SVGSegpipe3, name:"segpipe3"},
            {value:SVGSegpipe4, name:"segpipe4"},
            {value:SVGSegpipe5, name:"segpipe5"},
            {value:SVGSegpipe6, name:"segpipe6"},
            {value:SVGSegpipe7, name:"segpipe7"},
            {value:SVGSegpipe8, name:"segpipe8"},
            {value:SVGSegpipe9, name:"segpipe9"},
            {value:SVGSegpipe10, name:"segpipe10"},
            {value:SVGSegpipe11, name:"segpipe11"},
            {value:SVGSegpipe12, name:"segpipe12"},
            {value:SVGSegpipe13, name:"segpipe13"},
            {value:SVGSegpipe14, name:"segpipe14"},
            {value:SVGScale1, name:"scale1"},
            {value:SVGScale2, name:"scale2"},
            {value:SVGScale3, name:"scale3"},
            {value:SVGScale4, name:"scale4"},
            {value:SVGEnergy1, name:"energy1"},
            {value:SVGEnergy2, name:"energy2"},
            {value:SVGEnergy3, name:"energy3"},
            {value:SVGEnergy4, name:"energy4"},
            {value:SVGEnergy5, name:"energy5"},
            {value:SVGEnergy6, name:"energy6"},
            {value:SVGEnergy7, name:"energy7"},
            {value:SVGPump1, name:"pump1"},
            {value:SVGPump2, name:"pump2"},
            {value:SVGPump3, name:"pump3"},
            {value:SVGPump4, name:"pump4"},
            {value:SVGPump5, name:"pump5"},
            {value:SVGPump6, name:"pump6"},
            {value:SVGPump7, name:"pump7"},
            {value:SVGPump8, name:"pump8"},
            {value:SVGPump9, name:"pump9"},
            {value:SVGPump10, name:"pump10"},
            {value:SVGPump11, name:"pump11"},
            {value:SVGPump12, name:"pump12"},
            {value:SVGPump13, name:"pump13"},
            {value:SVGPump14, name:"pump14"},
            {value:SVGPump15, name:"pump15"},
            {value:SVGPump16, name:"pump16"},
            {value:SVGPump17, name:"pump17"},
            {value:SVGPump18, name:"pump18"},
            {value:SVGPump19, name:"pump19"},
            {value:SVGPump20, name:"pump20"},
            {value:SVGPump21, name:"pump21"},
            {value:SVGPump22, name:"pump22"},
            {value:SVGPump23, name:"pump23"},
            {value:SVGPump24, name:"pump24"},
            {value:SVGPaper1, name:"paper1"},
            {value:SVGPaper2, name:"paper2"},
            {value:SVGPaper3, name:"paper3"},
            {value:SVGPaper4, name:"paper4"},
            {value:SVGPaper5, name:"paper5"},
            {value:SVGPaper6, name:"paper6"},
            {value:SVGPaper7, name:"paper7"},
            {value:SVGPaper8, name:"paper8"},
            {value:SVGPaper9, name:"paper9"},
            {value:SVGPaper10, name:"paper10"},
            {value:SVGPaper11, name:"paper11"},
            {value:SVGPaper12, name:"paper12"},
            {value:SVGPaper13, name:"paper13"},
            {value:SVGPaper14, name:"paper14"},
            {value:SVGPaper15, name:"paper15"},
            {value:SVGPaper16, name:"paper16"},
            {value:SVGPaper17, name:"paper17"},
            {value:SVGPaper18, name:"paper18"},
            {value:SVGPaper19, name:"paper19"},
            {value:SVGHeating1, name:"heating1"},
            {value:SVGHeating2, name:"heating2"},
            {value:SVGHeating3, name:"heating3"},
            {value:SVGHeating4, name:"heating4"},
            {value:SVGHeating5, name:"heating5"},
            {value:SVGHeating6, name:"heating6"},
            {value:SVGHeating7, name:"heating7"},
            {value:SVGHeating8, name:"heating8"},
            {value:SVGHeating9, name:"heating9"},
            {value:SVGHeating10, name:"heating10"},
            {value:SVGHeating11, name:"heating11"},
            {value:SVGHeating12, name:"heating12"},
            {value:SVGHeating13, name:"heating13"},
            {value:SVGHeating14, name:"heating14"},
            {value:SVGHeating15, name:"heating15"},
            {value:SVGHeating16, name:"heating16"},
            {value:SVGHeating17, name:"heating17"},
            {value:SVGHeating18, name:"heating18"},
            {value:SVGHeating19, name:"heating19"},
            {value:SVGHeating20, name:"heating20"},
            {value:SVGHeating21, name:"heating21"},
            {value:SVGHeating22, name:"heating22"},
            {value:SVGHeating23, name:"heating23"},
            {value:SVGHeating24, name:"heating24"},
            {value:SVGHeating25, name:"heating25"},
            {value:SVGHeating26, name:"heating26"},
            {value:SVGCooling1, name:"cooling1"},
            {value:SVGCooling2, name:"cooling2"},
            {value:SVGCooling3, name:"cooling3"},
            {value:SVGCooling4, name:"cooling4"},
            {value:SVGCooling5, name:"cooling5"},
            {value:SVGCooling6, name:"cooling6"},
            {value:SVGCooling7, name:"cooling7"},
            {value:SVGCooling8, name:"cooling8"},
            {value:SVGCooling9, name:"cooling9"},
            {value:SVGCooling10, name:"cooling10"},
            {value:SVGCooling11, name:"cooling11"},
            {value:SVGPower1, name:"power1"},
            {value:SVGPower2, name:"power2"},
            {value:SVGPower3, name:"power3"},
            {value:SVGPower4, name:"power4"},
            {value:SVGPower5, name:"power5"},
            {value:SVGPower6, name:"power6"},
            {value:SVGPower7, name:"power7"},
            {value:SVGPower8, name:"power8"},
            {value:SVGPower9, name:"power9"},
            {value:SVGPower10, name:"power10"},
            {value:SVGPower11, name:"power11"},
            {value:SVGPower12, name:"power12"},
            {value:SVGPower13, name:"power13"},
            {value:SVGPower14, name:"power14"},
            {value:SVGPower15, name:"power15"},
            {value:SVGPower16, name:"power16"},
            {value:SVGPipe1, name:"pipe1"},
            {value:SVGPipe2, name:"pipe2"},
            {value:SVGPipe3, name:"pipe3"},
            {value:SVGPipe4, name:"pipe4"},
            {value:SVGPipe5, name:"pipe5"},
            {value:SVGPipe6, name:"pipe6"},
            {value:SVGPipe7, name:"pipe7"},
            {value:SVGPipe8, name:"pipe8"},
            {value:SVGPipe9, name:"pipe9"},
            {value:SVGPipe10, name:"pipe10"},
            {value:SVGPipe11, name:"pipe11"},
            {value:SVGPipe12, name:"pipe12"},
            {value:SVGPipe13, name:"pipe13"},
            {value:SVGPipe14, name:"pipe14"},
            {value:SVGPipe15, name:"pipe15"},
            {value:SVGPipe16, name:"pipe16"},
            {value:SVGPipe17, name:"pipe17"},
            {value:SVGPipe18, name:"pipe18"},
            {value:SVGPipe19, name:"pipe19"},
            {value:SVGPipe20, name:"pipe20"},
            {value:SVGPipe21, name:"pipe21"},
            {value:SVGPipe22, name:"pipe22"},
            {value:SVGPipe23, name:"pipe23"},
            {value:SVGPipe24, name:"pipe24"},
            {value:SVGPipe25, name:"pipe25"},
            {value:SVGPipe26, name:"pipe26"},
            {value:SVGPlant1, name:"plant1"},
            {value:SVGPlant2, name:"plant2"},
            {value:SVGPlant3, name:"plant3"},
            {value:SVGPlant4, name:"plant4"},
            {value:SVGPlant5, name:"plant5"},
            {value:SVGPlant6, name:"plant6"},
            {value:SVGPlant7, name:"plant7"},
            {value:SVGPlant8, name:"plant8"},
            {value:SVGPlant9, name:"plant9"},
            {value:SVGPlant10, name:"plant10"},
            {value:SVGPlant10, name:"plant10"},
            {value:SVGPlant11, name:"plant11"},
            {value:SVGPlant12, name:"plant12"},
            {value:SVGPlant13, name:"plant13"},
            {value:SVGPlant14, name:"plant14"},
            {value:SVGPlant15, name:"plant15"},
            {value:SVGPlant16, name:"plant16"},
            {value:SVGPlant17, name:"plant17"},
            {value:SVGPlant18, name:"plant18"},
            {value:SVGPlant19, name:"plant19"},
            {value:SVGPlant20, name:"plant20"},
            {value:SVGPlant21, name:"plant21"},
            {value:SVGPlant22, name:"plant22"},
            {value:SVGPlant23, name:"plant23"},
            {value:SVGPlant24, name:"plant24"},
            {value:SVGPlant25, name:"plant25"},
            {value:SVGPlant26, name:"plant26"},
            {value:SVGPlant27, name:"plant27"},
            {value:SVGPeople1, name:"people1"},
            {value:SVGPeople2, name:"people2"},
            {value:SVGPeople3, name:"people3"},
            {value:SVGPeople4, name:"people4"},
            {value:SVGPeople5, name:"people5"},
            {value:SVGPeople6, name:"people6"},
            {value:SVGPeople7, name:"people7"},
            {value:SVGPeople8, name:"people8"},
            {value:SVGPeople9, name:"people9"},
            {value:SVGPeople10, name:"people10"},
            {value:SVGPanel1, name:"panel1"},
            {value:SVGPanel2, name:"panel2"},
            {value:SVGPanel3, name:"panel3"},
            {value:SVGPanel4, name:"panel4"},
            {value:SVGPanel5, name:"panel5"},
            {value:SVGPanel6, name:"panel6"},
            {value:SVGPanel7, name:"panel7"},
            {value:SVGPanel8, name:"panel8"},
            {value:SVGNuclear1, name:"nuclear1"},
            {value:SVGNuclear2, name:"nuclear2"},
            {value:SVGNuclear3, name:"nuclear3"},
            {value:SVGNuclear4, name:"nuclear4"},
            {value:SVGNuclear5, name:"nuclear5"},
            {value:SVGNuclear6, name:"nuclear6"},
            {value:SVGNuclear7, name:"nuclear7"},
            {value:SVGNet1, name:"networking1"},
            {value:SVGNet2, name:"networking2"},
            {value:SVGNet3, name:"networking3"},
            {value:SVGNet4, name:"networking4"},
            {value:SVGNet5, name:"networking5"},
            {value:SVGNet6, name:"networking6"},
            {value:SVGNet7, name:"networking7"},
            {value:SVGNet8, name:"networking8"},
            {value:SVGNet9, name:"networking9"},
            {value:SVGNet10, name:"networking10"},
            {value:SVGNet11, name:"networking11"},
            {value:SVGNet12, name:"networking12"},
            {value:SVGNet13, name:"networking13"},
            {value:SVGNet14, name:"networking14"},
            {value:SVGNet15, name:"networking15"},
            {value:SVGNet16, name:"networking16"},
            {value:SVGNet17, name:"networking17"},
            {value:SVGNet18, name:"networking18"},
            {value:SVGNet19, name:"networking19"},
            {value:SVGNature1,name:"nature1"},
            {value:SVGNature2,name:"nature2"},
            {value:SVGNature3,name:"nature3"},
            {value:SVGNature4,name:"nature4"},
            {value:SVGNature5,name:"nature5"},
            {value:SVGNature6,name:"nature6"},
            {value:SVGNature7,name:"nature7"},
            {value:SVGNature8,name:"nature8"},
            {value:SVGNature9,name:"nature9"},
            {value:SVGNature10,name:"nature10"},
            {value:SVGNature11,name:"nature11"},
            {value:SVGNature12,name:"nature12"},
            {value:SVGNature13,name:"nature13"},
            {value:SVGNature14,name:"nature14"},
            {value:SVGNature15,name:"nature15"},
            {value:SVGInterface1,name:"interface1"},
            {value:SVGInterface2,name:"interface2"},
            {value:SVGInterface3,name:"interface3"},
            {value:SVGInterface4,name:"interface4"},
            {value:SVGInterface5,name:"interface5"},
            {value:SVGInterface6,name:"interface6"},
            {value:SVGInterface7,name:"interface7"},
            {value:SVGInterface8,name:"interface8"},
            {value:SVGInterface9,name:"interface9"},
            {value:SVGInterface10,name:"interface10"},
            {value:SVGInterface11,name:"interface11"},
            {value:SVGInterface12,name:"interface12"},
            {value:SVGInterface13,name:"interface13"},
            {value:SVGMotor1,name:"motor1"},
            {value:SVGMotor2,name:"motor2"},
            {value:SVGMotor3,name:"motor3"},
            {value:SVGMotor4,name:"motor4"},
            {value:SVGMotor5,name:"motor5"},
            {value:SVGMotor6,name:"motor6"},
            {value:SVGMotor7,name:"motor7"},
            {value:SVGMotor8,name:"motor8"},
            {value:SVGMotor9,name:"motor9"},
            {value:SVGMotor10,name:"motor10"},
            {value:SVGMotor11,name:"motor11"},
            {value:SVGMotor12,name:"motor12"},
            {value:SVGMotor13,name:"motor13"},
            {value:SVGMotor14,name:"motor14"},
            {value:SVGMotor15,name:"motor15"},
            {value:SVGMotor16,name:"motor16"},
            {value:SVGMixer1,name:"mixer1"},
            {value:SVGMixer2,name:"mixer2"},
            {value:SVGMixer3,name:"mixer3"},
            {value:SVGMixer4,name:"mixer4"},
            {value:SVGMixer5,name:"mixer5"},
            {value:SVGMixer6,name:"mixer6"},
            {value:SVGMixer7,name:"mixer7"},
            {value:SVGMixer8,name:"mixer8"},
            {value:SVGMixer9,name:"mixer9"},
            {value:SVGMixer10,name:"mixer10"},
            {value:SVGMixer11,name:"mixer11"},
            {value:SVGMiscpipe1,name:"miscpipe1"},
            {value:SVGMiscpipe2,name:"miscpipe2"},
            {value:SVGMiscpipe3,name:"miscpipe3"},
            {value:SVGMiscpipe4,name:"miscpipe4"},
            {value:SVGMiscpipe5,name:"miscpipe5"},
            {value:SVGMiscpipe6,name:"miscpipe6"},
            {value:SVGMiscpipe7,name:"miscpipe7"},
            {value:SVGMiscpipe8,name:"miscpipe8"},
            {value:SVGMiscpipe9,name:"miscpipe9"},
            {value:SVGMiscpipe10,name:"miscpipe10"},
            {value:SVGMiscpipe11,name:"miscpipe11"},
            {value:SVGMiscpipe12,name:"miscpipe12"},
            {value:SVGMining1,name:"mining1"},
            {value:SVGMining2,name:"mining2"},
            {value:SVGMining3,name:"mining3"},
            {value:SVGMining4,name:"mining4"},
            {value:SVGMining5,name:"mining5"},
            {value:SVGMining6,name:"mining6"},
            {value:SVGMining7,name:"mining7"},
            {value:SVGMining8,name:"mining8"},
            {value:SVGMining9,name:"mining9"},
            {value:SVGMining10,name:"mining10"},
            {value:SVGMining10,name:"mining10"},
            {value:SVGMining11,name:"mining11"},
            {value:SVGMining12,name:"mining12"},
            {value:SVGMining13,name:"mining13"},
            {value:SVGMining14,name:"mining14"},
            {value:SVGMining15,name:"mining15"},
            {value:SVGMining16,name:"mining16"},
            {value:SVGMining17,name:"mining17"},
            {value:SVGMining18,name:"mining18"},
            {value:SVGMining19,name:"mining19"},
            {value:SVGMining20,name:"mining20"},
            {value:SVGMining21,name:"mining21"},
            {value:SVGMining22,name:"mining22"},
            {value:SVGMining23,name:"mining23"},
            {value:SVGMining24,name:"mining24"},
            {value:SVGMining25,name:"mining25"},
            {value:SVGMining26,name:"mining26"},
            {value:SVGMining27,name:"mining27"},
            {value:SVGMining28,name:"mining28"},
            {value:SVGMining29,name:"mining29"},
            {value:SVGMining30,name:"mining30"},
            {value:SVGMining31,name:"mining31"},
            {value:SVGMining32,name:"mining32"},
            {value:SVGMining33,name:"mining33"},
            {value:SVGMedical1,name:"medical1"},
            {value:SVGMedical2,name:"medical2"},
            {value:SVGMedical3,name:"medical3"},
            {value:SVGMedical4,name:"medical4"},
            {value:SVGMedical5,name:"medical5"},
            {value:SVGMedical6,name:"medical6"},
            {value:SVGMedical7,name:"medical7"},
            {value:SVGMedical8,name:"medical8"},
            {value:SVGMedical9,name:"medical9"},
            {value:SVGMedical10,name:"medical10"},
            {value:SVGMedical11,name:"medical11"},
            {value:SVGMedical12,name:"medical12"},
            {value:SVGMedical13,name:"medical13"},
            {value:SVGMaterial1,name:"material1"},
            {value:SVGMaterial2,name:"material2"},
            {value:SVGMaterial3,name:"material3"},
            {value:SVGMaterial4,name:"material4"},
            {value:SVGMaterial5,name:"material5"},
            {value:SVGMaterial6,name:"material6"},
            {value:SVGMaterial7,name:"material7"},
            {value:SVGMaterial8,name:"material8"},
            {value:SVGMaterial9,name:"material9"},
            {value:SVGMaterial10,name:"material10"},
            {value:SVGMaterial10,name:"material10"},
            {value:SVGMaterial11,name:"material11"},
            {value:SVGMaterial12,name:"material12"},
            {value:SVGMaterial13,name:"material13"},
            {value:SVGMaterial14,name:"material14"},
            {value:SVGMaterial15,name:"material15"},
            {value:SVGMaterial16,name:"material16"},
            {value:SVGMaterial17,name:"material17"},
            {value:SVGMaterial18,name:"material18"},
            {value:SVGMaterial19,name:"material19"},
            {value:SVGMaterial20,name:"material20"},
            {value:SVGMaterial21,name:"material21"},
            {value:SVGMaterial22,name:"material22"},
            {value:SVGMaterial23,name:"material23"},
            {value:SVGMaterial24,name:"material24"},
            {value:SVGMaterial25,name:"material25"},
            {value:SVGMaterial26,name:"material26"},
            {value:SVGMaterial27,name:"material27"},
            {value:SVGMaterial28,name:"material28"},
            {value:SVGMaterial29,name:"material29"},
            {value:SVGMaterial30,name:"material30"},
            {value:SVGMaterial31,name:"material31"},
            {value:SVGMaterial32,name:"material32"},
            {value:SVGMaterial33,name:"material33"},
            {value:SVGMachine1,name:"machine1"},
            {value:SVGMachine2,name:"machine2"},
            {value:SVGMachine3,name:"machine3"},
            {value:SVGMachine4,name:"machine4"},
            {value:SVGMachine5,name:"machine5"},
            {value:SVGMachine6,name:"machine6"},
            {value:SVGMachine7,name:"machine7"},
            {value:SVGMachine8,name:"machine8"},
            {value:SVGMachine9,name:"machine9"},
            {value:SVGMachine10,name:"machine10"},
            {value:SVGMachine11,name:"machine11"},
            {value:SVGMachine12,name:"machine12"},
            {value:SVGMachine13,name:"machine13"},
            {value:SVGMachine14,name:"machine14"},
            {value:SVGMachine15,name:"machine15"},
            {value:SVGMachine16,name:"machine16"},
            {value:SVGMachine17,name:"machine17"},
            {value:SVGMachine18,name:"machine18"},
            {value:SVGMachine19,name:"machine19"},
            {value:SVGMachine20,name:"machine20"},
            {value:SVGMachine21,name:"machine21"},
            {value:SVGMachine22,name:"machine22"},
            {value:SVGMachine23,name:"machine23"},
            {value:SVGMachine24,name:"machine24"},
            {value:SVGMachine25,name:"machine25"},
            {value:SVGMachine26,name:"machine26"},
            {value:SVGMachine27,name:"machine27"},
            {value:SVGMachine28,name:"machine28"},
            {value:SVGMachine29,name:"machine29"},
            {value:SVGMachine30,name:"machine30"},
            {value:SVGMachine31,name:"machine31"},
            {value:SVGMachine32,name:"machine32"},
            {value:SVGMachine33,name:"machine33"},
            {value:SVGMachine34,name:"machine34"},
            {value:SVGLogistic1,name:"logistic1"},
            {value:SVGLogistic2,name:"logistic2"},
            {value:SVGLogistic3,name:"logistic3"},
            {value:SVGLogistic4,name:"logistic4"},
            {value:SVGLogistic5,name:"logistic5"},
            {value:SVGLogistic6,name:"logistic6"},
            {value:SVGLogistic7,name:"logistic7"},
            {value:SVGLogistic8,name:"logistic8"},
            {value:SVGLogistic9,name:"logistic9"},
            {value:SVGLogistic10,name:"logistic10"},
            {value:SVGLogistic11,name:"logistic11"},
            {value:SVGSymbol1,name:"symbol1"},
            {value:SVGSymbol2,name:"symbol2"},
            {value:SVGSymbol3,name:"symbol3"},
            {value:SVGSymbol4,name:"symbol4"},
            {value:SVGSymbol5,name:"symbol5"},
            {value:SVGSymbol6,name:"symbol6"},
            {value:SVGSymbol7,name:"symbol7"},
            {value:SVGSymbol8,name:"symbol8"},
            {value:SVGSymbol9,name:"symbol9"},
            {value:SVGSymbol10,name:"symbol10"},
            {value:SVGSymbol11,name:"symbol11"},
            {value:SVGSymbol12,name:"symbol12"},
            {value:SVGSymbol13,name:"symbol13"},
            {value:SVGSymbol14,name:"symbol14"},
            {value:SVGHvac1,name:"hvac1"},
            {value:SVGHvac2,name:"hvac2"},
            {value:SVGHvac3,name:"hvac3"},
            {value:SVGHvac4,name:"hvac4"},
            {value:SVGHvac5,name:"hvac5"},
            {value:SVGHvac6,name:"hvac6"},
            {value:SVGHvac7,name:"hvac7"},
            {value:SVGHvac8,name:"hvac8"},
            {value:SVGHvac9,name:"hvac9"},
            {value:SVGHvac10,name:"hvac10"},
            {value:SVGHvac11,name:"hvac11"},
            {value:SVGHvac12,name:"hvac12"},
            {value:SVGHvac13,name:"hvac13"},
            {value:SVGHvac14,name:"hvac14"},
            {value:SVGHvac15,name:"hvac15"},
            {value:SVGHvac16,name:"hvac16"},
            {value:SVGHvac17,name:"hvac17"},
            {value:SVGHvac18,name:"hvac18"},
            {value:SVGHvac19,name:"hvac19"},
            {value:SVGHvac21,name:"hvac21"},
            {value:SVGHvac22,name:"hvac22"},
            {value:SVGHvac23,name:"hvac23"},
            {value:SVGHvac24,name:"hvac24"},
            {value:SVGHvac25,name:"hvac25"},
            {value:SVGHvac25,name:"hvac25"},
            {value:SVGHvac26,name:"hvac26"},
            {value:SVGHvac27,name:"hvac27"},
            {value:SVGHvac28,name:"hvac28"},
            {value:SVGHvac29,name:"hvac29"},
            {value:SVGHvac30,name:"hvac30"},
            {value:SVGFood1,name:"food1"},
            {value:SVGFood2,name:"food2"},
            {value:SVGFood3,name:"food3"},
            {value:SVGFood4,name:"food4"},
            {value:SVGFood5,name:"food5"},
            {value:SVGFood6,name:"food6"},
            {value:SVGFood7,name:"food7"},
            {value:SVGFood8,name:"food8"},
            {value:SVGFood9,name:"food9"},
            {value:SVGFood10,name:"food10"},
            {value:SVGFood11,name:"food11"},
            {value:SVGFood12,name:"food12"},
            {value:SVGFood13,name:"food13"},
            {value:SVGFood14,name:"food14"},
            {value:SVGFood15,name:"food15"},
            {value:SVGFood16,name:"food16"},
            {value:SVGFood17,name:"food17"},
            {value:SVGFood18,name:"food18"},
            {value:SVGFood19,name:"food19"},
            {value:SVGFood20,name:"food20"},
            {value:SVGFood21,name:"food21"},
            {value:SVGFood22,name:"food22"},
            {value:SVGFood23,name:"food23"},
            {value:SVGFood24,name:"food24"},
            {value:SVGFood25,name:"food25"},
            {value:SVGFood26,name:"food26"},
            {value:SVGFood27,name:"food27"},
            {value:SVGFood28,name:"food28"},
            {value:SVGFlex1,name:"flex1"},
            {value:SVGFlex2,name:"flex2"},
            {value:SVGFlex3,name:"flex3"},
            {value:SVGFlex4,name:"flex4"},
            {value:SVGFlex5,name:"flex5"},
            {value:SVGFlex6,name:"flex6"},
            {value:SVGFlex7,name:"flex7"},
            {value:SVGFlex8,name:"flex8"},
            {value:SVGFlex9,name:"flex9"},
            {value:SVGFlex10,name:"flex10"},
            {value:SVGFlow1,name:"flow1"},
            {value:SVGFlow2,name:"flow2"},
            {value:SVGFlow3,name:"flow3"},
            {value:SVGFlow4,name:"flow4"},
            {value:SVGFlow5,name:"flow5"},
            {value:SVGFlow6,name:"flow6"},
            {value:SVGFlow7,name:"flow7"},
            {value:SVGFlow8,name:"flow8"},
            {value:SVGFlow9,name:"flow9"},
            {value:SVGFlow10,name:"flow10"},
            {value:SVGFlow11,name:"flow11"},
            {value:SVGFlow12,name:"flow12"},
            {value:SVGFlow13,name:"flow13"},
            {value:SVGFlow14,name:"flow14"},
            {value:SVGFinish1,name:"finish1"},
            {value:SVGFinish2,name:"finish2"},
            {value:SVGFinish3,name:"finish3"},
            {value:SVGFinish4,name:"finish4"},
            {value:SVGFinish5,name:"finish5"},
            {value:SVGFinish6,name:"finish6"},
            {value:SVGFinish7,name:"finish7"},
            {value:SVGFinish8,name:"finish8"},
            {value:SVGFinish9,name:"finish9"},
            {value:SVGFinish10,name:"finish10"},
            {value:SVGFinish11,name:"finish11"},
            {value:SVGFinish12,name:"finish12"},
            {value:SVGFinish13,name:"finish13"},
            {value:SVGFinish14,name:"finish14"},
            {value:SVGFinish15,name:"finish15"},
            {value:SVGFinish16,name:"finish16"},
            {value:SVGFinish17,name:"finish17"},
            {value:SVGFinish18,name:"finish18"},
            {value:SVGFinish19,name:"finish19"},
            {value:SVGFinish20,name:"finish20"},
            {value:SVGFinish21,name:"finish21"},
            {value:SVGFinish22,name:"finish22"},
            {value:SVGFinish23,name:"finish23"},
            {value:SVGFinish24,name:"finish24"},
            {value:SVGFinish25,name:"finish25"},
            {value:SVGFinish26,name:"finish26"},
            {value:SVGFinish27,name:"finish27"},
            {value:SVGFinish28,name:"finish28"},
            {value:SVGDuct1,name:"duct1"},
            {value:SVGDuct2,name:"duct2"},
            {value:SVGDuct3,name:"duct3"},
            {value:SVGDuct4,name:"duct4"},
            {value:SVGDuct5,name:"duct5"},
            {value:SVGDuct6,name:"duct6"},
            {value:SVGDuct7,name:"duct7"},
            {value:SVGDuct8,name:"duct8"},
            {value:SVGDuct9,name:"duct9"},
            {value:SVGDuct10,name:"duct10"},
            {value:SVGDuct11,name:"duct11"},
            {value:SVGDuct12,name:"duct12"},
            {value:SVGDuct13,name:"duct13"},
            {value:SVGDuct14,name:"duct14"},
            {value:SVGDuct15,name:"duct15"},
            {value:SVGDuct16,name:"duct16"},
            {value:SVGDuct17,name:"duct17"},
            {value:SVGDuct18,name:"duct18"},
            {value:SVGDuct19,name:"duct19"},
            {value:SVGDuct20,name:"duct20"},
            {value:SVGDuct21,name:"duct21"},
            {value:SVGDuct22,name:"duct22"},
            {value:SVGDuct23,name:"duct23"},
            {value:SVGDuct24,name:"duct24"},
            {value:SVGDuct25,name:"duct25"},
            {value:SVGConveyor1,name:"conveyor1"},
            {value:SVGConveyor2,name:"conveyor2"},
            {value:SVGConveyor3,name:"conveyor3"},
            {value:SVGConveyor4,name:"conveyor4"},
            {value:SVGConveyor5,name:"conveyor5"},
            {value:SVGConveyor6,name:"conveyor6"},
            {value:SVGConveyor7,name:"conveyor7"},
            {value:SVGConveyor8,name:"conveyor8"},
            {value:SVGConveyor9,name:"conveyor9"},
            {value:SVGConveyor10,name:"conveyor10"},
            {value:SVGConveyor11,name:"conveyor11"},
            {value:SVGController1,name:"controller1"},
            {value:SVGController2,name:"controller2"},
            {value:SVGController3,name:"controller3"},
            {value:SVGController4,name:"controller4"},
            {value:SVGController5,name:"controller5"},
            {value:SVGController6,name:"controller6"},
            {value:SVGController7,name:"controller7"},
            {value:SVGController8,name:"controller8"},
            {value:SVGController9,name:"controller9"},
            {value:SVGController10,name:"controller10"},
            {value:SVGController11,name:"controller11"},
            {value:SVGContainer1,name:"container1"},
            {value:SVGContainer2,name:"container2"},
            {value:SVGContainer3,name:"container3"},
            {value:SVGContainer4,name:"container4"},
            {value:SVGContainer5,name:"container5"},
            {value:SVGContainer6,name:"container6"},
            {value:SVGContainer7,name:"container7"},
            {value:SVGContainer8,name:"container8"},
            {value:SVGContainer9,name:"container9"},
            {value:SVGContainer10,name:"container10"},
            {value:SVGContainer11,name:"container11"},
            {value:SVGChemical1,name:"chemical1"},
            {value:SVGChemical2,name:"chemical2"},
            {value:SVGChemical3,name:"chemical3"},
            {value:SVGChemical4,name:"chemical4"},
            {value:SVGChemical5,name:"chemical5"},
            {value:SVGChemical6,name:"chemical6"},
            {value:SVGChemical7,name:"chemical7"},
            {value:SVGChemical8,name:"chemical8"},
            {value:SVGChemical9,name:"chemical9"},
            {value:SVGChemical10,name:"chemical10"},
            {value:SVGChemical11,name:"chemical11"},
            {value:SVGChemical12,name:"chemical12"},
            {value:SVGChemical13,name:"chemical13"},
            {value:SVGChemical14,name:"chemical14"},
            {value:SVGChemical15,name:"chemical15"},
            {value:SVGFactory1,name:"factory1"},
            {value:SVGFactory2,name:"factory2"},
            {value:SVGFactory3,name:"factory3"},
            {value:SVGFactory4,name:"factory4"},
            {value:SVGFactory5,name:"factory5"},
            {value:SVGFactory6,name:"factory6"},
            {value:SVGFactory7,name:"factory7"},
            {value:SVGFactory8,name:"factory8"},
            {value:SVGFactory9,name:"factory9"},
            {value:SVGFactory10,name:"factory10"},
            {value:SVGFactory11,name:"factory11"},
            {value:SVGFactory12,name:"factory12"},
            {value:SVGFactory13,name:"factory13"},
            {value:SVGFactory14,name:"factory14"},
            {value:SVGFactory15,name:"factory15"},
            {value:SVGFactory16,name:"factory16"},
            {value:SVGFactory17,name:"factory17"},
            {value:SVGBoiler1,name:"boiler1"},
            {value:SVGBoiler2,name:"boiler2"},
            {value:SVGBoiler3,name:"boiler3"},
            {value:SVGBoiler4,name:"boiler4"},
            {value:SVGBoiler5,name:"boiler5"},
            {value:SVGBoiler6,name:"boiler6"},
            {value:SVGBoiler7,name:"boiler7"},
            {value:SVGBoiler8,name:"boiler8"},
            {value:SVGBoiler9,name:"boiler9"},
            {value:SVGBoiler10,name:"boiler10"},
            {value:SVGBoiler11,name:"boiler11"},
            {value:SVGBoiler12,name:"boiler12"},
            {value:SVGBlower1,name:"blower1"},
            {value:SVGBlower2,name:"blower2"},
            {value:SVGBlower3,name:"blower3"},
            {value:SVGBlower4,name:"blower4"},
            {value:SVGBlower5,name:"blower5"},
            {value:SVGBlower6,name:"blower6"},
            {value:SVGBlower7,name:"blower7"},
            {value:SVGBlower8,name:"blower8"},
            {value:SVGBlower9,name:"blower9"},
            {value:SVGBlower10,name:"blower10"},
            {value:SVGBlower11,name:"blower11"},
            {value:SVGBlower12,name:"blower12"},
            {value:SVGBlower13,name:"blower13"},
            {value:SVGButton1,name:"button1"},
            {value:SVGButton2,name:"button2"},
            {value:SVGButton3,name:"button3"},
            {value:SVGButton4,name:"button4"},
            {value:SVGButton5,name:"button5"},
            {value:SVGButton6,name:"button6"},
            {value:SVGButton7,name:"button7"},
            {value:SVGButton8,name:"button8"},
            {value:SVGButton9,name:"button9"},
            {value:SVGButton10,name:"button10"},
            {value:SVGButton11,name:"button11"},
            {value:SVGButton12,name:"button12"},
            {value:SVGButton13,name:"button13"},
            {value:SVGButton14,name:"button14"},
            {value:SVGButton15,name:"button15"},
            {value:SVGButton16,name:"button16"},
            {value:SVGButton17,name:"button17"},
            {value:SVGButton18,name:"button18"},
            {value:SVGButton19,name:"button19"},
            {value:SVGArchitecture1,name:"architecture1"},
            {value:SVGArchitecture2,name:"architecture2"},
            {value:SVGArchitecture3,name:"architecture3"},
            {value:SVGArchitecture4,name:"architecture4"},
            {value:SVGArchitecture5,name:"architecture5"},
            {value:SVGArchitecture6,name:"architecture6"},
            {value:SVGArchitecture7,name:"architecture7"},
            {value:SVGArchitecture8,name:"architecture8"},
            {value:SVGArchitecture9,name:"architecture9"},
            {value:SVGArchitecture10,name:"architecture10"},
            {value:SVGArchitecture11,name:"architecture11"},
            {value:SVGArchitecture12,name:"architecture12"},
            {value:SVGArchitecture13,name:"architecture13"},
            {value:SVGArchitecture14,name:"architecture14"},
            {value:SVGArchitecture15,name:"architecture15"},
            {value:SVGArchitecture16,name:"architecture16"},
            {value:SVGArchitecture17,name:"architecture17"},
            {value:SVGArchitecture18,name:"architecture18"},
            {value:SVGArchitecture19,name:"architecture19"},
            {value:SVGArchitecture20,name:"architecture20"},
            {value:SVGArchitecture21,name:"architecture21"},
            {value:DatabaseTable, name:"databasetable"},
            {value:PercantageTile, name:"percantagetile"},
            {value:ClockTile, name:"clocktile"},
            {value:GaugeTile, name:"gaugetile"},
            {value:SparklineTile, name:"sparklinetile"},
            {value:SmoothedChartTile, name:"smoothedcharttile"},
            {value:TimerControlTile, name:"timercontroltile"},
            {value:NumberTile, name:"numbertile"},
            {value:HighlowTile, name:"highlowtile"},
            {value:PlusMinusTile, name:"plusminustile"},
            {value:SliderTile, name:"slidertile"},
            {value:SwitchTile, name:"switchtile"},
            {value:ButtonTile, name:"buttontile"},
            {value:TimeTile, name:"timetile"},
            {value:BarChartTile, name:"barcharttile"},
            {value:RadialChartTile, name:"radialcharttile"},
            {value:DonutChartTile, name:"donutcharttile"},
            {value:CircularProgressTile, name:"circulartile"},
            {value:StockTile, name:"stocktile"},
            {value:GaugeSparklineTile, name:"gaugesparklinetile"},
            {value:BarGaugeTile, name:"bargaugetile"},
            {value:LedTile, name:"ledtile"},
            {value:ColorTile, name:"colortile"},
            {value:FluidTile, name:"fluidtile"},
            {value:DateChooser, name:"datechooser"},
            {value:Gauge2, name:"gauge2"},
            {value:Gauge3, name:"gauge3"},
        
            
          ],
        },
      })
      objects:ObjectView[];
      public getObjectByName(name:string):ObjectView{
        const object = this.objects.find(obj=>obj.name===name);
        return object;
      }
      private initScripts(obj:ObjectView){
        if (obj instanceof GroupView){
          obj.objects.forEach(o=>{
          this.initScripts(o)
        })
     }
        if (obj.scripts2!=null && obj.scripts2.length>0){
          for (let i=0;i<obj.scripts2.length; i++){
            
            obj.scripts2[i] =plainToInstance(Script, JSON.parse(JSON.stringify(obj.scripts2[i])));      
          }
         }
      }
      public checkObjects():void{
        console.log("checkObjects",this.objects);
        if (this.objects==null) return;
          this.objects.forEach(obj=>{
            if(obj.isUpdate())
              obj.drawObject();
          })
      }
      public drawObjects(stage:Konva.Stage):void{
        if (this.scripts!=null && this.scripts.length!=0){
          for (let i=0;i<this.scripts.length; i++){
              this.scripts[i] =plainToInstance(Script, JSON.parse(JSON.stringify(this.scripts[i])));
              if (this.scripts[i].extype<2)
                this.scripts[i].runSTScript(this,null);
          }
     }
          stage.removeChildren();
        this.layer = new Konva.Layer();
        stage.add(this.layer);
        const background = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.windowwidth,
          height: this.windowheight,
          fill: ColorUtils.convertformat(this.color),
          listening: false,
        });
        this.layer.add(background);
        this.objects.forEach((obj=>{
          obj.screen = this.name
         obj.name = obj.name.replace("{screen}",this.name)
          this.initScripts(obj)
            obj.initLayer(this.layer);
            obj.executeOnCreateScripts(this);
            obj.drawObject();
            obj.CheckFunctions(this,obj);
        }))

        if (this.timerUpdate!=null)
        clearInterval(this.timerUpdate);
       this.timerUpdate = setInterval(()=>{
        if (this.objects==null) return;
        this.objects.forEach(obj=>{
          if(obj.isUpdate()){
            obj.setUpdate(false)
            obj.drawObject();
           
          }
        })
       },1000); 
   
      }
      public deleteObjects():void{
        if (this.timerUpdate!=null)
        clearTimeout(this.timerUpdate);
        console.log("deleteObjects")
        if(this.scripts!=null){
        this.scripts.forEach((scr=>{   
          if ( scr.extype==2)
              scr.runSTScript(this,null);
        }))
      }
      if (this.objects!=null){
        this.objects.forEach((obj=>{
          obj.executeOnDestroyScripts(this)
            obj.deleteObject();
            
        }))
      }
        
        ///this.scripts.splice(0, this.scripts.length)
        this.layer=null;
      }
      public updateObjects(tag:tag):void{
        console.log("update Objects:",this.name,tag,this.scripts)
        if (this.scripts!=null && this.scripts.length!=0){
        this.scripts.forEach((scr=>{
          console.log("script execution ",scr.extype)
          if (scr.tagnames!=null && scr.tagnames.includes(tag.path) && scr.extype==0)
              scr.runSTScript(this,null);
        }))
      }
      if (this.objects!=null && this.objects.length!=0){
              this.objects.forEach((obj=>{
                obj.executeOnDataChangeScripts(this,tag)
             //   console.log(obj.name+" "+obj.tagnames)
              if(obj.tagnames!=null && obj.tagnames.includes(tag.path)){
                obj.setUpdate(true)
              //obj.drawObject();
          }
      }))
    }
      }
}
import { SVGObject } from "../svgobject";

export class SVGTank54 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 95.947" enable-background="new 0 0 112.5 95.947" xml:space="preserve">
        <g id="Group_Top">
            <radialGradient id="SVGID_1_" cx="58.502" cy="21.7998" r="41.1763" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M4.504,36.037L58.334,0L112.5,36.037l-53.998,7.562L4.504,36.037" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="6.0811" y1="67.3428" x2="110.9238" y2="67.3428">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.72" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M6.081,38.739h104.843v57.207H6.081V38.739z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.5044" y1="37.3877" x2="112.5" y2="37.3877">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M4.504,36.037H112.5v2.703H4.504V36.037z" />
        </g>
        <g id="Group_Ladder">
            <path fill="#808080" d="M10.36,95.946H7.658v-9.234H3.041v9.234H0V35.249c0,0,0-3.829,3.491-3.829h11.374   c0,0-0.048,0.87-1.448,2.054c-1.083,0.917-3.056,0.986-3.056,0.986H4.504c-1.286,0-1.464,1.577-1.464,1.577V84.01h4.617V41.78   h2.703V95.946z" />
            <path d="M4.504,34.46h5.856c2.431-0.153,3.567-1.464,3.567-1.464H4.504c-3.041,0-3.041,3.041-3.041,3.041v59.91h1.577v-9.234h5.743   v9.234h1.577V41.78c0,0,0.046-0.666-0.338-1.013c-0.584-0.529-1.239-0.451-1.239-0.451v1.464v43.806H3.041V36.037   C3.041,36.037,3.25,34.46,4.504,34.46z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.72, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Ladder",[0.0, 1.0, 0.0]);

        super.drawObject();
    }
}
import { Value } from "../value";
import { format, parse } from 'date-fns';
import { Project } from "../project";
import tagsModele from "@/store/tags.modele";
import { Script } from "../script";
import { MessageDialogBox } from "../messagedialogbox";
import messagedialogboxModule from "@/store/messagedialogbox.module";
import { Screen } from "../screen";
import { ObjectView } from "../objects/object";
import { Trend } from "../objects/trend";
import { DialogBox } from "../dialogbox";
import dialogboxModule from "@/store/dialogbox.module";
import popupscreenModule from "@/store/popupscreen.module";


export class interpretStandartCommands{
    static script:Script
    static screen:Screen;

    public static setScript(script:Script){
        this.script = script
    }
    public static setScreen(screen:Screen){
        this.screen= screen
    }
    static call(func:string, args):Value{
        let res:Value=new Value();
        switch(func){
            case "print":{if (args.length!=1) return; interpretStandartCommands.print( args[0]); break} 
            case "sqrt":{ if (args.length!=1) return; res = interpretStandartCommands.sqrt( args[0]); break}
            case "pow": {if (args.length!=2) return; res = interpretStandartCommands.pow(args[0], args[1]); break}
            case "log": {if (args.length!=2) return; res = interpretStandartCommands.log(args[0], args[1]); break}
            case "ln":{ if (args.length!=1) return; res = interpretStandartCommands.ln( args[0]); break}
            case "bytestoshort": {if (args.length!=2) return; res = interpretStandartCommands.bytestoshort(args[0], args[1]); break}
            case "shorttobyte": {if (args.length!=2) return; res = interpretStandartCommands.shorttobyte(args[0], args[1]); break}
            case "shortstoint": {if (args.length!=2) return; res = interpretStandartCommands.shortstoint(args[0], args[1]); break}
            case "inttoshort": {if (args.length!=2) return; res = interpretStandartCommands.inttoshort(args[0], args[1]); break}
            case "readbit": {if (args.length!=2) return; res = interpretStandartCommands.readbit(args[0], args[1]); break}
            case "setbit": {if (args.length!=2) return; res = interpretStandartCommands.setbit(args[0], args[1]); break}
            case "max": {if (args.length!=2) return; res = interpretStandartCommands.max(args[0], args[1]); break}
            case "min": {if (args.length!=2) return; res = interpretStandartCommands.min(args[0], args[1]); break}
            case "abs":{ if (args.length!=1) return; res = interpretStandartCommands.abs( args[0]); break}
            case "sign":{ if (args.length!=1) return; res = interpretStandartCommands.sign( args[0]); break}
            case "toint":{ if (args.length!=1) return; res = interpretStandartCommands.toint( args[0]); break}
            case "toradians":{ if (args.length!=1) return; res = interpretStandartCommands.toradians( args[0]); break}
            case "todegrees":{ if (args.length!=1) return; res = interpretStandartCommands.todegrees( args[0]); break}
            case "sin":{ if (args.length!=1) return; res = interpretStandartCommands.sin( args[0]); break}
            case "cos":{ if (args.length!=1) return; res = interpretStandartCommands.cos( args[0]); break}
            case "tan":{ if (args.length!=1) return; res = interpretStandartCommands.tan( args[0]); break}
            case "asin":{ if (args.length!=1) return; res = interpretStandartCommands.asin( args[0]); break}
            case "acos":{ if (args.length!=1) return; res = interpretStandartCommands.acos( args[0]); break}
            case "atan":{ if (args.length!=1) return; res = interpretStandartCommands.atan( args[0]); break}
            case "inttohex":{ if (args.length!=1) return; res = interpretStandartCommands.inttohex( args[0]); break}
            case "hextoint":{ if (args.length!=1) return; res = interpretStandartCommands.hextoint( args[0]); break}
            case "stringsequals": {if (args.length!=2) return; res = interpretStandartCommands.stringsequals(args[0], args[1]); break}
            case "stringtodouble":{ if (args.length!=1) return; res = interpretStandartCommands.stringtodouble( args[0]); break}
            case "doubletostring":{ if (args.length!=1) return; res = interpretStandartCommands.doubletostring( args[0]); break}
            case "stringtoint":{ if (args.length!=1) return; res = interpretStandartCommands.stringtoint( args[0]); break}
            case "inttostring":{ if (args.length!=1) return; res = interpretStandartCommands.inttostring( args[0]); break}
            case "substring": {
                if (args.length!=3) return; 
                res = interpretStandartCommands.substring(args[0], args[1], args[2]); break
            }
            case "base64decode":{ if (args.length!=1) return; res = interpretStandartCommands.base64decode( args[0]); break}
            case "base64encode":{ if (args.length!=1) return; res = interpretStandartCommands.base64encode( args[0]); break}
            case "datetime":{ if (args.length!=1) return; res = interpretStandartCommands.datetime( args[0]); break}
            case "currentdatetime":{ if (args.length!=1) return; res = interpretStandartCommands.currentdatetime( args[0]); break}
            case "currentdatetimeinmil":{ res = interpretStandartCommands.currentdatetimeinmil(); break}
            case "split": {if (args.length!=3) return; res = interpretStandartCommands.split(args[0], args[1], args[2]); break}
            case "getglobalargument": {if (args.length!=2) return; res = interpretStandartCommands.getglobalargument(args[0], args[1]); break}
            case "putglobalargument": {if (args.length!=2) return; interpretStandartCommands.putglobalargument(args[0], args[1]); break}
            case "gettagvalue": {if (args.length!=2) return; res = interpretStandartCommands.gettagvalue(args[0], args[1]); break}
            case "settagvalue": {if (args.length!=2) return; interpretStandartCommands.settagvalue(args[0], args[1]); break}
            case "gettagdescription": {if (args.length!=2) return; res =interpretStandartCommands.gettagdescription(args[0], args[1]); break}
            case "settagdescription": {if (args.length!=2) return; interpretStandartCommands.settagdescription(args[0], args[1]); break}
            case "cutbeginstring": {if (args.length!=2) return; res =interpretStandartCommands.cutbeginstring(args[0], args[1]); break}
            case "cutendstring": {if (args.length!=2) return; res =interpretStandartCommands.cutendstring(args[0], args[1]); break}
            case "infodialogbox": {if (args.length!=2) return; interpretStandartCommands.infodialogbox(args[0], args[1]); break}
            case "hidecurve": {if (args.length!=3) return; interpretStandartCommands.hidecurve(args[0], args[1], args[2]); break}
            case "setdialogbox": {if (args.length!=4) return; interpretStandartCommands.setdialogbox(args[0], args[1], args[2], args[3]); break}
            case "addcurve": {if (args.length!=8) return; interpretStandartCommands.addcurve(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]);  break}
            case "removecurve": {if (args.length!=2) return; interpretStandartCommands.removecurve(args[0], args[1]); break}
            case "callpopup": {if (args.length!=1) return; interpretStandartCommands.callpopup(args[0]); break}
            case "callscreen": {if (args.length!=1) return; interpretStandartCommands.callscreen(args[0]); break}
            case "datetimefrom": {if (args.length!=2) return; res = interpretStandartCommands.datetimefrom(args[0], args[1]); break}
            case "datetimeto": {if (args.length!=2) return; res = interpretStandartCommands.datetimeto(args[0], args[1]); break}
            case "closepopup": {interpretStandartCommands.closepopup(); break}
            case "previousscreenname":{res = interpretStandartCommands.previousscreenname(); break}
            case "currentscreenname": {res = interpretStandartCommands.currentscreenname(); break}
            case "bytestoint": {if (args.length!=4) return; res = interpretStandartCommands.bytestoint(args[0], args[1], args[2], args[3]); break}
            case "inttobyte": {if (args.length!=2) return; res = interpretStandartCommands.inttobyte(args[0], args[1]); break}
            case "bytestolong": {if (args.length!=8) return; res = interpretStandartCommands.bytestolong(args[0], args[1], args[2], args[3], args[4],
                 args[5], args[6], args[7]); break}
            case "longtobyte": {if (args.length!=2) return; res = interpretStandartCommands.longtobyte(args[0], args[1]); break}
            case "bytestofloat": {if (args.length!=4) return; res = interpretStandartCommands.bytestofloat(args[0], args[1], args[2] , args[3]); break}
            case "floattobyte": {if (args.length!=2) return; res = interpretStandartCommands.floattobyte(args[0], args[1]); break}
            case "bytestodouble": {if (args.length!=8) return; res = interpretStandartCommands.bytestodouble(args[0], args[1], args[2], args[3], args[4],
                args[5], args[6], args[7]); break}
            case "doubletobyte": {if (args.length!=2) return; res = interpretStandartCommands.doubletobyte(args[0], args[1]); break}
            case "setproperty": {if (args.length!=3) return; interpretStandartCommands.setproperty(args[0], args[1], args[2]); break}
            case "getproperty": {if (args.length!=2) return; res = interpretStandartCommands.getproperty(args[0], args[1]); break}
            case "stringlength": {if (args.length!=1) return; res = interpretStandartCommands.stringlength(args[0]); break}
        }
        return res;
    }

    static print (arg:Value):void{
        //console.log("PRINT",arg)
        console.log( arg.value)
    }

    static sqrt (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.sqrt(arg.value))
        return res 
    }

    static pow (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.pow(arg1.value, arg2.value))
        return res 
    }

    static log (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.log(arg1.value)/Math.log(arg2.value))
        return res
    }

    static ln (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.log(arg.value))
        return res 
    }

    static bytestoshort (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        
        const value1 = this.numberToByte(arg1.value)  
        const value2 = this.numberToByte(arg2.value) 
        const ovalue = (value1<<8)+(value2&0xFF)

        res.datatype = 2,
        res.setValue(ovalue)
        return res
    }

    static shorttobyte (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
    
        const value1 = this.numberToShort(arg1.value)  
        const value2 = this.numberToByte(arg2.value) 
        let ovalue
        if (value2==1) ovalue = (value1>>8);
		else if (value2==2) ovalue = value1 & 0xFF 

        res.datatype = 1,
        res.setValue(ovalue)
        return res
    }

    static shortstoint (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const value1 = this.numberToShort(arg1.value)  
        const value2 = this.numberToShort(arg2.value) 
        const ovalue = (value1<<16)+(value2&0xFFFF)		

        res.datatype = 3,
        res.setValue(ovalue)
        return res
    }

    static inttoshort (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const value1 = this.numberToInteger(arg1.value)
        const value2 = this.numberToByte(arg2.value)
        let ovalue = 0
        if (value2==1)
			ovalue = value1>>16
		else if (value2==2)
			ovalue = value1 & 0xFFFF

        res.datatype = 2,
        res.setValue(ovalue)
        return res
    }

    static readbit (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const value1 = this.numberToInteger(arg1.value)
        const value2 = this.numberToByte(arg2.value)
        let ovalue = false
        ovalue = ((value1 >> value2) & 1)==1

        res.datatype = 0,
        res.setValue(ovalue)
        return res
    }

    static setbit (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const value1 = this.numberToInteger(arg1.value)
        const value2 = this.numberToByte(arg2.value)
        let ovalue = 0
        ovalue = value1 | 1<<value2;

        res.datatype = 3,
        res.setValue(ovalue)
        return res
    }

    static resetbit (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const value1 = this.numberToInteger(arg1.value)
        const value2 = this.numberToByte(arg2.value)
        let ovalue = 0
        ovalue = value1 & ~(1<<value2);

        res.datatype = 3,
        res.setValue(ovalue)
        return res
    }

    static max (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const ovalue = Math.max(arg1.value, arg2.value); 

        res.datatype = 6,
        res.setValue(ovalue)
        return res
    }

    static min (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const ovalue = Math.min(arg1.value, arg2.value); 

        res.datatype = 6,
        res.setValue(ovalue)
        return res
    }

    static abs (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.abs(arg.value))
        return res 
    }

    static sign (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(-arg.value)
        return res 
    }

    static toint (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 3,
        res.setValue(Math.trunc(arg.value))
        return res 
    }

    static toradians (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(arg.value * Math.PI/180)
        return res 
    }

    static todegrees (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(arg.value * 180 / Math.PI)
        return res 
    }

    static sin (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.sin(arg.value))
        return res 
    }

    static cos (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.cos(arg.value))
        return res 
    }

    static tan (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.tan(arg.value))
        return res 
    }

    static asin (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.asin(arg.value))
        return res 
    }

    static acos (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.acos(arg.value))
        return res 
    }

    static atan (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(Math.atan(arg.value))
        return res 
    }

    static inttohex (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 7
        if (arg.value < 0){
            res.setValue((0xFFFFFFFF + Math.trunc(arg.value) + 1).toString(16));
        }else res.setValue((Math.trunc(arg.value)).toString(16))
        return res 
    }

    static hextoint (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 3,
        res.setValue(parseInt(arg.value.toString(), 16))
        return res 
    }
    
    static stringsequals (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const ovalue:boolean = arg1.value.toString() == arg2.value.toString(); 

        res.datatype = 0,
        res.setValue(ovalue)
        return res
    }

    static stringtodouble (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 6,
        res.setValue(parseFloat(arg.value))
        return res 
    }

    static doubletostring (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 7
        res.setValue(arg.value.toString())
        return res 
    }

    static stringtoint (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 3
        const ovalue = parseFloat(arg.value)
        if(Number.isInteger(ovalue)){
            res.setValue(ovalue)
        }else res.setValue(0)
        
        return res 
    }

    static inttostring (arg:Value):Value{
        const res:Value=new Value();
        res.datatype = 7,
        res.setValue(arg.value.toString())
        return res 
    }

    static substring (arg1:Value, arg2:Value, arg3:Value):Value{
        
        const res:Value=new Value();
        const startIndex = arg2.value
        const endIndex = arg3.value
        res.datatype = 7
        console.log("arg1=",arg1)
        res.value = arg1.value.substring(startIndex, endIndex)
        return res
    }

    static base64decode (arg:Value):Value{
        const res:Value=new Value();
        
        const result  = Int8Array.from(atob(arg.value), c => c.charCodeAt(0))
        const values = []
        result.forEach((el)=>{
            values.push({datatype: 1, value: el})
        })

        res.datatype = 8
        res.value = values
        return res 
    }

    static base64encode (arg:Value):Value{
        const res:Value=new Value();
        const strValues = []
        
        arg.value.forEach(element => {
           strValues.push(element.value)
        });
        const u8 = new Uint8Array(strValues.length);
        strValues.forEach((el, index)=>{
            u8[index] = Number(el)
        })
        const b64 = btoa(String.fromCharCode.apply(null, u8));
        res.datatype = 7,
        res.value= b64
        return res 
    }

    static datetime (arg:Value):Value{
        const res:Value=new Value();
        const invalue = Math.trunc(arg.value)
        let outintvalue = 0
        const date = new Date(Date.now())
        switch(invalue){
			case 0: outintvalue = date.getSeconds();
				break;
			case 1: outintvalue = date.getMinutes();
				break;
			case 2: outintvalue = date.getHours()>12? date.getHours()-12 : date.getHours()
				break;
			case 3: outintvalue = date.getHours()
				break;
			case 4: outintvalue = date.getUTCDay()+1//date.getDay();
				break;
			case 5: outintvalue = date.getDate();
				break;
			case 6: outintvalue = date.getMonth();
				break;
			case 7: outintvalue = date.getFullYear();
				break;			
			case 8: {
                const hours = date.getHours()
                const minutes = date.getMinutes()
                outintvalue = 60*hours+minutes
                break;
            } 
			default:
				break;
		}
        res.datatype = 3,
        res.setValue(outintvalue)
        return res 
    }

    static currentdatetime (arg:Value):Value{
        const res:Value=new Value();
        const form = arg.value
        const currentDate = format(Date.now(), form)
        res.datatype = 7
        res.value = currentDate
        return res
    }

    static currentdatetimeinmil():Value{
        
        const res:Value=new Value();
        res.datatype = 4
        res.setValue(Date.now())
        return res
    }

    static split (arg1:Value, arg2:Value, arg3:Value):Value{
        const res:Value=new Value();
        const str = arg1.value
        const arg = arg2.value
        const size = parseInt(arg3.value)
        const array = str.split(arg, size)

        while(array.length<size){
            array.push("") 
        }
        const values = []
        array.forEach(element => {
            values.push({datatype:7, value: element})
        });
       
        res.datatype = 8
        res.value = values
        return res
    }

    static getglobalargument (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const value = Project.getGlobalArgument(arg1.value)
        if(value !=null){
            res.datatype = 7
            res.value = value
        }else{
            res.datatype = 7
            res.value = arg2.value
        }
        return res
    }

    static putglobalargument (arg1:Value, arg2:Value):void{
        const argument = arg1.value.toString();
		const value = arg2.value.toString();
        Project.setGlobalArgument(argument, value)
    }

    static gettagvalue (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();

        const tagname = arg1.value.toString();
		const defaultvalue = arg2.value.toString();
        const tag = tagsModele.getTag(tagname)
       
        if(tag==null || tag.tagvalue==null){
            res.setValue(defaultvalue)
        }else{
            res.datatype = tag.datatype
            if(res.datatype != 7 && res.datatype != 8){
                res.setValue(tag.tagvalue)
            }else{
                res.value = tag.tagvalue
            } 
        } 
        if (this.script!=null && tag!=null){
            if(this.script.tagnames.indexOf(tag.path)=== -1){
                this.script.tagnames.push(tag.path)
            }
           // this.script.tagnames.push(tagname)
        } 
        return res
    }

    static settagvalue (arg1:Value, arg2:Value):void{
        const tagname = arg1.value.toString();
		const value = arg2.value
        const tag = tagsModele.getTag(tagname)
        
        if (tag==null) return;
        if (this.script!=null && tag!=null){
            if(this.script.tagnames.indexOf(tag.path)=== -1){
                this.script.tagnames.push(tag.path)
            }
            tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:value,datetime:0});
           // this.script.tagnames.push(tagname)
        } 
        
    }

    static gettagdescription (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const tagname = arg1.value.toString();
		const defaultdescription = arg2.value.toString();
        const tag = tagsModele.getTagByName(tagname)
        if(tag==null || tag.tagvalue==null){
            tag.description = defaultdescription
            res.datatype = 7
        }else{
            res.value = tag.description
            res.datatype = 7
        }
        return res
    }

    static settagdescription (arg1:Value, arg2:Value):void{
        const tagname = arg1.value.toString();
		const description = arg2.value
        const tag = tagsModele.getTagByName(tagname)
        if (tag==null) return;
        tag.description = description
        console.log("settagdescription", tag.description)
    }

    static cutbeginstring (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const indexstart = arg2.value
        res.datatype = 7
        res.value =  arg1.value.slice(indexstart)
        return res
    }

    static cutendstring (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const indexEnd = arg2.value
        res.datatype = 7
        res.value =  indexEnd !=0? arg1.value.slice(0, -indexEnd) : arg1.value
        return res
    }

    static infodialogbox (arg1:Value, arg2:Value):void{
        const messageDialogBox:MessageDialogBox = new MessageDialogBox();
        messageDialogBox.enable = true;
        messageDialogBox.title = arg1.value;
        messageDialogBox.message = arg2.value;
        messagedialogboxModule.setMessageDialogBox(messageDialogBox);
    }

    static hidecurve (arg1:Value, arg2:Value, arg3:Value):void{
        const trendname:string = arg1.value.toString();
		const curvenam:string = arg2.value.toString();
		const hide:boolean = arg3.value;
        const object:ObjectView = this.screen.getObjectByName(trendname)
        if (object !=null && object instanceof Trend){
            const trend = object
            const curve = trend.curves.find((el) => el.curvename == curvenam)
            if(curve !=null){
                curve.hide = hide
                trend.drawObject()
            }
        }
    }

    static setdialogbox (arg1:Value, arg2:Value, arg3:Value, arg4:Value):void{
        const title:string = arg1.value.toString();
		const infomessage:string = arg2.value.toString();
		const tagname:string = arg3.value.toString();
        const value:string = arg4.value.toString();
        const tag = tagsModele.getTag(tagname)
        if(tag !=null){
            const dialogBox:DialogBox= new DialogBox();
            dialogBox.enable = true;
            dialogBox.title = title;
            dialogBox.message = infomessage;
            dialogBox.tagname = tag.path;
            dialogBox.value = value;
            dialogBox.isValuefromScript = true
            dialogboxModule.setDialogBox(dialogBox);
        }
    }

    static addcurve (arg1:Value, arg2:Value, arg3:Value, arg4:Value, arg5:Value, arg6:Value, arg7:Value, arg8:Value):void{
        const trendname:string = arg1.value.toString();
		const curvenam:string = arg2.value.toString();
		const tagname = arg3.value.toString();
		const linewidth = parseInt(arg4.value)
		const red = parseInt(arg5.value)
		const green = parseInt(arg6.value);
		const blue = parseInt(arg7.value);
		const type = parseInt(arg8.value);
        function componentToHex(c) {
            const hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        let color =  "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue) +'ff';
        color = color.replace('#', '0x');
        console.log('color', color)

        const object:ObjectView = this.screen.getObjectByName(trendname)
        if (object !=null && object instanceof Trend){
            const trend = object
          const curve = {
                id: 0,
                hide: false,
                curvename:curvenam,
                tagname: tagname,
                linewidth: linewidth,
                color: color,
                type: type,
                historydata:[],
                path: tagname
            }
            if(trend.curves.some(el => el.tagname === tagname)) return
            else{
                trend.curves.push(curve)
                trend.drawObject()
            }
        }
    }

    static removecurve (arg1:Value, arg2:Value):void{
        const trendname:string = arg1.value.toString();
		const curvename:string = arg2.value.toString();
		
        const object:ObjectView = this.screen.getObjectByName(trendname)
        if (object !=null && object instanceof Trend){
            const trend = object
            const curve = trend.curves.find((el) => el.curvename == curvename)
            
            if(curve !=null){
                trend.curves = trend.curves.filter(cur=>cur.curvename != curvename)
                trend.drawObject()
            }
        }
    }

    static callpopup(arg:Value):void{
        const screenname = arg.value.toString()
        Project.getPopupScreen(screenname)
    }
    
    static callscreen(arg:Value):void{
        const screenname = arg.value.toString()
        if(this.screen.name != screenname) Project.getScreen(screenname)
    }

    static datetimefrom (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const formatstr = arg1.value.toString();
		const datetime = parseInt(arg2.value);
        res.value = format(datetime, formatstr)
        res.datatype = 7
        return res    
    }

    static datetimeto (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const formatstr = arg1.value.toString();
		const datetime = arg2.value.toString();
        let timeStamp = 0
        timeStamp = (parse(datetime, formatstr, new Date())).getTime()
        res.setValue(timeStamp)
        res.datatype = 4
        return res 
    }

    static closepopup():void{
        popupscreenModule.setPopupEnabled(false)
    }

    static previousscreenname():Value{
        const res:Value=new Value();
        res.value = Project.getPreviousScreen()
        res.datatype = 7
        return res
    }

    static currentscreenname():Value{
        const res:Value=new Value();
        res.value = Project.getCurrentScreenName();
        //this.screen.name
        res.datatype = 7
        return res
    }

    static bytestoint (arg1:Value, arg2:Value, arg3:Value, arg4:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value
		const value2 = arg2.value
		const value3 = arg3.value
		const value4 = arg4.value
        const ovalue = (((value1 & 0xFF)<<24)+((value2  & 0xFF)<<16)+((value3 & 0xFF)<<8)+(value4&0xFF));
        res.setValue(ovalue)
        res.datatype = 3
        return res 
    }

    static inttobyte (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const value1 = Math.trunc(arg1.value)
		const value2 =arg2.value;
        let ovalue = 0;
        if (value2==1)
			ovalue = (value1>>24);
		else if (value2==2)
			ovalue = (value1>>16 & 0xFF);
		else if (value2==3)
			ovalue = (value1>>8 & 0xFF);
		else if (value2==4)
			ovalue = (value1 & 0xFF);	
        res.setValue(ovalue)
        res.datatype = 1
        return res 
    }

    static bytestolong (arg1:Value, arg2:Value, arg3:Value, arg4:Value, arg5:Value, arg6:Value, arg7:Value, arg8:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value
		const value2 = arg2.value
		const value3 = arg3.value
		const value4 = arg4.value
		const value5 = arg5.value
		const value6 = arg6.value
		const value7 = arg7.value
		const value8 = arg8.value

       const byteArray = [value1, value2, value3, value4, value5, value6, value7, value8]

        let ovalue = 0;
        for (let i = 0; i < byteArray.length; i++) {
            if (byteArray[i]<0)
                byteArray[i]=256+byteArray[i]
            ovalue = (ovalue * 256) + byteArray[i];
        }
        res.datatype = 4
        res.value = ovalue
        return res
    }

    static longtobyte (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        let value1 = Math.trunc(arg1.value);
		const value2 = Math.trunc(arg2.value);
        if(value2<9 && value2>0){
            const byteArray = [0, 0, 0, 0, 0, 0, 0, 0];

            for ( let index = 0; index < byteArray.length; index ++ ) {
            const byte = value1 & 0xff;
            byteArray [ index ] = byte;
            value1 = (value1 - byte) / 256 ;
            }
        
            let ovalue=byteArray[8-value2]
            ovalue = ovalue>127?ovalue-256:ovalue
            res.datatype = 4
            res.value = ovalue
            return res 
        }
    }

    static bytestofloat (arg1:Value, arg2:Value, arg3:Value, arg4:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value 
		const value2 = arg2.value 
		const value3 = arg3.value 
		const value4 = arg4.value 

        const data =  [value1, value2, value3, value4];
        const buf = new Uint8Array(data).buffer
        const view = new DataView(buf);
        const num = view.getFloat32(0);
        res.datatype = 5
        res.value = num
        return res 
    }

    static floattobyte (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value;
		const value2 = arg2.value;
        if(value2>0 && value2<5){
            
            const buffer = new ArrayBuffer(4);         
            const longNum = new Float32Array(buffer);  
            longNum[0] = value1;
            const ovalue =  Array.from(new Int8Array(buffer)).reverse()
            res.datatype = 1
            res.setValue(ovalue[value2-1]) 
            return res 
        } 
    }

    static bytestodouble (arg1:Value, arg2:Value, arg3:Value, arg4:Value, arg5:Value, arg6:Value, arg7:Value, arg8:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value
		const value2 = arg2.value
		const value3 = arg3.value
		const value4 = arg4.value
		const value5 = arg5.value
		const value6 = arg6.value
		const value7 = arg7.value
		const value8 = arg8.value

        const data =  [value1, value2, value3, value4, value5, value6, value7, value8];
        const buf = new Uint8Array(data).buffer
        const view = new DataView(buf);
        const num = view.getFloat64(0);
        res.datatype = 6
        res.value = num
        return res 
    }

    static doubletobyte (arg1:Value, arg2:Value):Value{
        const res:Value=new Value();
        const value1 = arg1.value
		const value2 = arg2.value
        if(value2>0 && value2<9){
            const buffer = new ArrayBuffer(8);         
            const longNum = new Float64Array(buffer);  
            longNum[0] = value1;
            const ovalue =  Array.from(new Int8Array(buffer)).reverse()
            res.datatype = 4
            res.value = ovalue[value2-1] 
            return res 
        }
    }
    static setproperty(arg1:Value, arg2:Value, arg3:Value):void{
        const objectname:string = arg1.value.toString();
		const property:string = arg2.value.toString();
		
        const object:ObjectView = this.screen.getObjectByName(objectname)
        if (object!=null)
            object.setField(property, arg3);
    }
    static getproperty(arg1:Value, arg2:Value):Value{
        const objectname:string = arg1.value.toString();
		const property:string = arg2.value.toString();
        const object:ObjectView = this.screen.getObjectByName(objectname)
        if (object!=null){
            return object.getField(property)
        }
    }
    static stringlength(arg1:Value):Value{
        const res:Value=new Value();
        const str:string = arg1.value.toString();
		
        res.datatype = 3;
        res.value = str.length
        console.log(res.value)
        return res;
    }

    static numberToByte(value:number){
        let byte = value & 255
        if (byte>127) byte-=256
        return byte
    }

    static numberToShort(value:number){
        let short = value & 65535
        if (short >32768) short-=65536
        return short
    }

    static numberToInteger(value:number){
        let int:number = Math.round(value)
        if (int>2147483648) int=2147483647
        if (int<-2147483648) int=-2147483647
        return int
    }

   
    
	
}
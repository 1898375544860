import { SVGObject } from "../svgobject";

export class SVGMedical11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 33.782 112.5" xml:space="preserve" enable-background="new 0 0 33.782 112.5">
        <g id="Group_LeftLeg">
            <path d="M25.9,86.598l0.45-14.404c-2.859,0.066-5.715,0.148-8.559,0.207   v0.797l1.127,11.148l-0.563,8.783l2.364,12.273l-1.238,4.729l0.563,1.128l2.929,1.237h3.604l1.125-1.237l-2.928-5.294L25.9,91.328   V86.598z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightLeg">
            <path d="M6.803,72.381l0.404,11.402l-0.563,4.615l0.563,17.567   l-1.126,2.929l-1.802,1.801l0.563,1.127h4.729l2.928-2.252l-0.563-3.604l-0.676-2.928l3.041-19.819l0.908-10.777   C12.39,72.479,9.584,72.475,6.803,72.381z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_LeftArm">
            <path d="M32.995,26.916l-2.365-5.292l-2.928-1.689l-0.379-0.123   c-0.346,3.865-0.414,7.913-0.427,11.707l0.243-4.04l0.563,8.784l-0.563,7.657l0.563,10.473l-0.676,3.041l0.113,2.703l0.338,4.954   l0.9-0.338l2.252-6.308v-3.489l2.365-11.599l0.563-7.094L32.995,26.916z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Neck">
            <path d="M21.366,17.884l-1.32-0.427l-1.127-2.252h-4.053l-1.239,2.928   l-2.27,0.547C14.692,19.976,21.891,20.917,21.366,17.884z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightArm">
            <path d="M6.529,19.842l-2.25,0.542l-2.928,2.365l-1.238,5.292L0.676,37.5   l-0.563,5.855l1.689,10.698l-0.225,4.728l2.365,4.168l1.689,1.238l0.563-5.744l-1.126-4.729l0.676-6.756l0.675-10.473l0.226-8.446   l0.436,3.66C6.97,27.691,6.463,23.803,6.529,19.842z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Dress">
            <path d="M0.901,49.55L0,43.469l0.45-4.842L0,28.154l0.901-5.405   l3.266-2.477l8.671-2.703l2.703,1.802h3.941l1.914-2.027l4.955,1.577l4.278,2.252l2.479,5.293l0.676,7.882l-0.226,6.081   l-0.677,6.418l-5.518,1.014l-0.563-7.883l0.225,10.585l-0.225,8.445l0.563,9.122l-0.563,15.091l-5.068,0.9l-4.954-0.563   l-5.743,0.674L5.743,83.67l0.338-9.908l0.45-9.461l0.451-13.737v-5.968v-7.883L5.743,50.338L0.901,49.55z" fill="#D8F2F2" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M7.094,36.375l0.788,4.955l0.676-4.504l2.478-1.915l-2.59-1.351l-0.789-2.027l-0.901,2.14   l-0.675-3.041l-0.226-5.405l-1.576,4.392L7.094,36.375z" fill="#CBE5E5" />
            <path d="M15.427,83.67l0.788-14.752h1.126l0.902,14.641l-1.578-0.113L15.427,83.67z" fill="#CBE5E5" />
            <path d="M27.364,45.158l-0.563-5.856L26.35,34.46l-2.477,1.689l1.576-2.59l1.125-2.027l-1.125-5.067   l1.352,2.59l1.463-3.829l-0.676,5.405L27.364,45.158z" fill="#CBE5E5" />
        </g>
        <g id="Group_Face">
            <path d="M21.733,11.15h-9.346l-1.239-1.013l-0.675-1.014V7.885   l0.675-1.126h11.824l0.675,1.126v1.239l-0.675,1.014L21.733,11.15z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="17.004" cy="8.448" rx="5.629" ry="7.32" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M16.891,11.038l1.689,0.45h-3.267L16.891,11.038z" fill="#663300" />
            <path d="M15.314,13.065l-0.45-0.451h4.167l-0.45,0.451H15.314z" fill="#B27F7F" />
            <path d="M14.864,12.614h4.167" fill="none" stroke="#CC99B2" stroke-width="0.25" />
            <path d="M20.381,6.646l-2.701-0.45l0.111,0.45l2.815,0.226L20.381,6.646z" fill="#663300" />
            <path d="M13.513,6.646l2.815-0.45l-0.338,0.45l-2.703,0.226L13.513,6.646z" fill="#663300" />
            <path d="M14.639,8.898h0.45l0.225-0.676l-0.225-0.676h-0.45l-0.225,0.676L14.639,8.898z" fill="#663300" />
            <path d="M18.805,8.898h0.451l0.225-0.676l-0.225-0.676h-0.451l-0.225,0.676L18.805,8.898z" fill="#663300" />
        </g>
        <g id="Group_Hair">
            <path d="M11.599,7.998l-0.451-1.352l-0.675,1.352l0.675-5.067l1.577-1.802   l1.915-1.014l2.252-0.112l2.364,0.112l1.802,1.014l1.688,1.802l0.9,5.067l-0.675-1.352l-0.563,1.352l-0.9-3.716l-0.899-1.351   L20.381,2.48l-0.449,0.9l-5.293,0.676H12.5l-0.225,0.225L11.599,7.998z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M19.932,3.38l0.449-2.252h0.227V2.93l-0.227-0.451L19.932,3.38z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
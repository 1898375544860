import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { format } from 'date-fns'
import { EventsTable } from '@/model/project/objects/eventstable';

@Module({ name:"EventsTableDialogBox", dynamic:true, store})
class EventsTableDialogBoxModule extends VuexModule {
  public eventstable:EventsTable;
  public enabled:boolean;
  public beginstring:string;
  public endstring:string;
  public beginpriority:number;
  public endpriority:number;
  public onlyactivestate:boolean;
  public onlyunack:boolean;

    @Mutation
    public setEventsTable(eventstable:EventsTable){
        if (eventstable==null) return;
      this.eventstable = eventstable;
      this.beginstring = format(eventstable.begin, "yyyy-MM-dd HH:mm")
      this.endstring = format(eventstable.end, "yyyy-MM-dd HH:mm")
      this.beginpriority = eventstable.beginpriority
      this.endpriority = eventstable.endpriority
    }
    @Mutation
    public setEventsTableDialogEnabled(value:boolean){
      this.enabled = value
    }

    @Mutation
    public setOnlyActiveState(value:boolean){
      this.onlyactivestate = value;
    }

    @Mutation
    public setOnlyUnAck(value:boolean){
      this.onlyunack = value;
    }
    
    get getEventsTableDialogEnable():boolean{   
      return this.enabled;
    }

    get getOnlyUnAck():boolean{
      return this.onlyunack;
    }
    
    get getOnlyActiveState():boolean{
      return this.onlyactivestate;
    }
    get getEventsTableBegin():string{
        return this.beginstring
    }

    get getEventsTableEnd():string{
        return this.endstring
    }

    get getEventsTableBeginPriority():number{
        return this.beginpriority
    }

    get getEventsTableEndPriority():number{
        return this.endpriority
    }
    @Mutation
    public setEventsTableBegin(value:string){ 
      this.beginstring = value;
    }
    @Mutation
    public setEventsTableEnd(value:string){
     this.endstring = value
    }
    @Mutation
    public setEventsTableBeginPriority(value:number){ 
      this.beginpriority = value;
    }
    @Mutation
    public setEventsTableEndPriority(value:number){
     this.endpriority = value
    }
    

    @Mutation
    public updateEventsTableTimeAndPriority(){
        if (this.eventstable==null) return;
        this.eventstable.begin = Date.parse(this.beginstring)
        this.eventstable.end = Date.parse(this.endstring)
        this.eventstable.beginpriority = this.beginpriority
        if (this.eventstable.beginpriority<0)
          this.eventstable.beginpriority = 0;
        if (this.eventstable.beginpriority>1000)
          this.eventstable.beginpriority=1000
        if (this.eventstable.endpriority<0)
          this.eventstable.endpriority = 0;
        if (this.eventstable.endpriority>1000)
          this.eventstable.endpriority=1000
        this.eventstable.endpriority = this.endpriority
        this.eventstable.drawObject()
    }
}

export default getModule(EventsTableDialogBoxModule);
export default [
    {
      name: 'profile',
      title: 'profile.profile',
      icon: 'person'
    },
    {
      name: 'tags',
      title: 'tagsettings.Tags',
      icon: 'memory'
    },
    {
      name: 'session',
      title: 'usersettings.session',
      icon:'event',
    },
    {
      name: 'history',
      title: 'dialog.history',
      icon:'history',
    },
    {
      name: 'screens',
      title: 'maintoolbar.screens',
      icon:'picture_in_picture',
    },
    {
      name: 'mqtt',
      title: 'usersettings.MQTT',
      icon:'settings',
    },
    {
      name: 'language',
      title: 'localesettings.language',
      icon:'language',
    },
    {
      name: 'alice',
      title: 'usersettings.alice',
      icon:'record_voice_over',
    }
]

    
  
import { FillColorProperty } from "../properties/fillcolorproperty";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tag from "@/model/tag";
import { PercantageTile } from "./percantagetile";

export class ColorTile extends PercantageTile{
	
    public drawObject():void{
		super.initObject();


		let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let fillcolor = '0xffffff00';         
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
      const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    let value = 0   
    let valueproperty
    let decimalpos=0
    const valueprop = this.properties==null?null:this.properties['value'];
    if (valueprop!=null){	
      valueproperty =Object.assign(new ValueProperty(), valueprop);
      if (valueproperty!=null) {
        decimalpos=valueproperty.decimalpos;   
        const tag:tag = valueproperty.getTag(this.tagnames)  
         if (tag!=null) value = Number(tag.tagvalue)  
         
     }      
    }    

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const barRect = new Konva.Rect({
      x: size*.05,
      y: this.height - size * 0.1,
      width: width*.9,
      height: size * 0.02,
      fill: ColorUtils.darkColor(ColorUtils.convertformat(bgcolor), 0.2)
    });
    group.add(barRect)

    const percent = valueproperty.getPercent(this.tagnames);
              
    const bar = new Konva.Rect({
      x: size*.05,
      y: this.height - size * 0.1,
      width: percent*this.width*.9,
      height: size * 0.02,
      fill: ColorUtils.convertformat(fillcolor),
    });
    group.add(bar)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const valueUnitText = new Konva.Group({
      x: size*0.05,
      y: 0,
      width: this.width-size*0.05*2
    })
    group.add(valueUnitText)

    const valueText = new Konva.Text({
      x: 0,
      y: this.height*.5 + size*.2 - 0.4*size,
      verticalAlign: "bottom",
      height: 0.4*size,
      text: value !=null? parseFloat(value.toFixed(decimalpos)).toLocaleString() : "N/A",
      fontSize: 0.4*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      offsetY:-size*0.024
    }); 
 
    const unitText = new Konva.Text({
      x: valueText.width(),
      y: height*.5 + size*.2 - 0.4*size,
      verticalAlign: "bottom",
      height: 0.4*size,
      text: this.unit,
      fontSize: 0.2*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    valueUnitText.add(valueText, unitText)
    valueUnitText.offsetX(-this.width/2+unitText.width()/2+valueText.width()/2+size*0.05)


    const descriptionText = new Konva.Text({
      x: size*0.05,
      y: this.height*.5-size*.2- 0.1*size,
      verticalAlign: "bottom",
      width: this.width-size*0.05*2,
      height: 0.1*size,
      text: this.description,
      fontSize: 0.1*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'center'
    });
    group.add(descriptionText)



  this.node.add(group)     
}
}
import { SVGObject } from "../svgobject";

export class SVGFood20 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 107.654 112.5" enable-background="new 0 0 107.654 112.5" xml:space="preserve">
        <g id="Group_Dark_Layer">
            <path fill="#32324C" stroke="#4C4C4C" stroke-width="0.25" d="M14.977,29.843h78.375V17.907H14.977V29.843z" />
        </g>
        <g id="Group_Filter_System">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="18.835" y1="91.292" x2="38.3071" y2="91.292">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#616161" stroke-width="0.25" d="M28.69,84.667c-4.323,0-8.062,1.506-9.855,3.693   c0,1.955,0,3.908,0,5.863c1.794,2.188,5.532,3.693,9.855,3.693c4.14,0,7.743-1.381,9.617-3.42c0-2.039,0-4.371,0-6.41   C36.434,86.048,32.831,84.667,28.69,84.667z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="19.0308" y1="46.1138" x2="38.1743" y2="46.1138">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M19.031,92.228V2.815l0.676-1.577L21.058,0h15.314   l1.126,1.239l0.675,1.577v89.412" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="43.835" y1="91.292" x2="63.3076" y2="91.292">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#616161" stroke-width="0.25" d="M53.69,84.667c-4.323,0-8.062,1.506-9.855,3.693   c0,1.955,0,3.908,0,5.863c1.794,2.188,5.532,3.693,9.855,3.693c4.141,0,7.743-1.381,9.617-3.42c0-2.039,0-4.371,0-6.41   C61.434,86.048,57.831,84.667,53.69,84.667z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="44.0308" y1="46.1138" x2="63.1748" y2="46.1138">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.031,92.228V2.815l0.676-1.577L46.058,0h15.314   l1.127,1.239l0.676,1.577v89.412" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="69.335" y1="91.292" x2="88.8076" y2="91.292">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#616161" stroke-width="0.25" d="M79.19,84.667c-4.322,0-8.061,1.506-9.855,3.693   c0,1.955,0,3.908,0,5.863c1.795,2.188,5.533,3.693,9.855,3.693c4.141,0,7.744-1.381,9.617-3.42c0-2.039,0-4.371,0-6.41   C86.935,86.048,83.331,84.667,79.19,84.667z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="69.5313" y1="46.1138" x2="88.6748" y2="46.1138">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M69.531,92.228V2.815l0.676-1.577L71.559,0h15.314   l1.126,1.239l0.676,1.577v89.412" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="17.9048" y1="2.085" x2="39.5259" y2="2.085">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M17.905,0.002h21.621v4.167H17.905V0.002" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="43.1294" y1="2.085" x2="64.5244" y2="2.085">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M43.129,0.002h21.395v4.167H43.129V0.002" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="68.1279" y1="2.085" x2="89.749" y2="2.085">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M68.128,0.002h21.621v4.167H68.128V0.002" />
        </g>
        <g id="Group_Indicator_Holder">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="41.7778" y1="21.5098" x2="65.876" y2="21.5098">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M41.778,16.78h24.098v9.459H41.778V16.78z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="67.0029" y1="21.5098" x2="90.876" y2="21.5098">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M67.003,16.78h23.873v9.459H67.003V16.78z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="16.7788" y1="21.5098" x2="40.6519" y2="21.5098">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M16.779,16.78h23.873v9.459H16.779V16.78" />
        </g>
        <g id="Group_Stripes">
            <path fill="#65657F" d="M19.707,28.042h18.018V26.24H19.707V28.042z" />
            <path fill="#65657F" d="M44.931,28.042h17.792V26.24H44.931V28.042z" />
            <path fill="#65657F" d="M69.931,28.042h18.018V26.24H69.931V28.042z" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M19.031,88.625h19.144" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M19.031,87.949h19.144" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M44.255,88.625h19.144" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M44.255,87.949h19.144" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M69.479,88.625h19.145" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M69.479,87.949h19.145" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M19.031,65.203h19.144" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M19.031,64.752h19.144" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M44.255,65.203h19.144" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M44.255,64.752h19.144" />
            <path fill="none" stroke="#32324C" stroke-width="0.25" d="M69.479,65.203h19.145" />
            <path fill="none" stroke="#CBCBE5" stroke-width="0.25" d="M69.479,64.752h19.145" />
        </g>
        <g id="Group_Indicators">
            <radialGradient id="SVGID_13_" cx="78.9385" cy="21.3975" r="3.6035" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.1" cx="78.938" cy="21.397" r="3.604" />
            <radialGradient id="SVGID_14_" cx="53.8271" cy="21.3975" r="3.4905" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.1" cx="53.827" cy="21.397" r="3.491" />
            <radialGradient id="SVGID_15_" cx="28.7153" cy="21.3975" r="3.6035" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.1" cx="28.715" cy="21.397" r="3.604" />
            <path fill="#FF0000" d="M78.938,21.51l0.226-0.226l-2.478-0.45L78.938,21.51z" />
            <path fill="#FF0000" d="M53.94,21.51l-0.226-0.226l-2.027,1.352L53.94,21.51z" />
            <path fill="#FF0000" d="M28.715,21.285L28.49,21.51l1.351,2.027L28.715,21.285z" />
            <path d="M28.715,21.51v-0.226l-2.478,0.226H28.715z" />
            <path d="M53.714,21.51h0.226v-2.478L53.714,21.51z" />
            <path d="M78.938,21.285l0.226,0.226l1.577-1.802L78.938,21.285z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="95.4922" y1="41.3291" x2="95.4922" y2="25.1138">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.1" d="M97.632,25.114v16.215h-4.279V25.114H97.632z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="90.6504" y1="37.7256" x2="90.6504" y2="28.7168">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M93.353,28.717v9.009h-5.404v-9.009H93.353z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="17.0039" y1="89.751" x2="17.0039" y2="80.7422">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M14.301,89.751v-9.009h5.405v9.009H14.301z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="12.1616" y1="93.3545" x2="12.1616" y2="77.1387">
                <stop offset="0.01" style="stop-color:#65657F" />
                <stop offset="0.5" style="stop-color:#B2B2CC" />
                <stop offset="1" style="stop-color:#65657F" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.1" d="M10.022,93.354V77.139h4.279v16.216H10.022z" />
        </g>
        <g id="Group_Minor_Support">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M82.542,96.958h4.73l1.352,1.014h-6.082V96.958z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M57.317,96.958h4.955l1.127,1.014h-6.082V96.958z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M32.319,96.958h4.729l1.126,1.014h-5.855V96.958z" />
            <path fill="#65657F" stroke="#4C4C4C" stroke-width="0.1" d="M31.418,94.932l1.802-1.803l4.955,5.068l-1.576,1.689L31.418,94.932z" />
            <path fill="#65657F" stroke="#4C4C4C" stroke-width="0.1" d="M56.643,94.932l1.576-1.803l5.181,5.068l-1.577,1.689L56.643,94.932z" />
            <path fill="#65657F" stroke="#4C4C4C" stroke-width="0.1" d="M81.866,94.932l1.577-1.803l5.181,5.068l-1.803,1.689L81.866,94.932z" />
        </g>
        <g id="Group_Orange_Support">
            <path fill="#FFCC00" d="M78.263,96.283h5.406v0.675h-5.406V96.283z" />
            <path fill="#FFCC00" d="M53.264,96.283h5.405v0.675h-5.405V96.283z" />
            <path fill="#FFCC00" d="M28.04,96.283h5.405v0.675H28.04V96.283z" />
        </g>
        <g id="Group_Support_Pillars">
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="87.5234" y1="81.6914" x2="91.5262" y2="81.4816">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M86.821,60.473h3.604l1.803,42.229h-3.604L86.821,60.473z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="62.2988" y1="81.6914" x2="66.3016" y2="81.4816">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M61.597,60.473H65.2l1.803,42.229h-3.604L61.597,60.473z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="37.0732" y1="81.6914" x2="41.0775" y2="81.4816">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M36.373,60.473h3.604l1.802,42.229h-3.604L36.373,60.473z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="66.5781" y1="81.4814" x2="70.5804" y2="81.6912">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M67.679,60.473h3.604l-1.803,42.229h-3.604L67.679,60.473z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="41.353" y1="81.4814" x2="45.3563" y2="81.6912">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" d="M42.454,60.473h3.604l-1.802,42.229h-3.604L42.454,60.473z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="16.1279" y1="81.4824" x2="20.1322" y2="81.6923">
                <stop offset="0" style="stop-color:#654F7F" />
                <stop offset="0.5" style="stop-color:#ADB8E0" />
                <stop offset="1" style="stop-color:#654F7F" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M17.229,60.473h3.604l-1.802,42.229h-3.604L17.229,60.473z" />
        </g>
        <g id="Group_Base_Support">
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="56.2881" y1="121.5547" x2="51.3666" y2="93.6435">
                <stop offset="0" style="stop-color:#65657F" />
                <stop offset="1" style="stop-color:#B2B2CC" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M0,102.701h107.654v9.797H0V102.701z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.01, 0.8489583333333334, 0.0, 0.5, 0.546875, 115.0, 1.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.4895833333333333, 129.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.8489583333333334, 0.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.fills,"Group_Dark_Layer",[0.0, 0.4479166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Stripes",[0.0, 0.8489583333333334, 0.0, 1.0, 0.8489583333333334, 0.0, 2.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Minor_Support",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.8489583333333334, 0.0, 4.0, 0.8489583333333334, 0.0, 5.0, 0.8489583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Orange_Support",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
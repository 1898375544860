import { SVGObject } from "../svgobject";

export class SVGMiscpipe9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 113.5 56.079" enable-background="new 0 0 113.5 56.079" xml:space="preserve">
        <g id="Group_Segmented_Short_V">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-498.5547" y1="679.4995" x2="-498.5547" y2="735.5796" gradientTransform="matrix(-1 0 0 1 -441.3047 -679.5)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M113.5,56.08H1V0h112.5V56.08z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M99.924,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M85.959,56.08L85.961,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M71.771,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M57.807,56.08L57.808,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M43.618,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M29.654,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M15.69,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M107.018,56.08L107.02,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M92.941,56.08L92.943,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M78.865,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M64.789,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M50.712,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M36.748,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M22.672,56.08V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.7071" d="M8.595,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M99.523,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M85.559,56.08L85.561,0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M71.371,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M57.406,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M43.217,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M29.253,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M15.289,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M106.617,56.08L106.619,0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M92.541,56.08L92.543,0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M78.465,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M64.389,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M50.312,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M36.348,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M22.272,56.08V0" />
            <path fill="none" stroke="#FCFFFF" stroke-width="0.7071" d="M8.195,56.08V0" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { Recipe } from "../recipe";
import { RecipeRow } from "./properties/range/reciperow";
import Konva from "konva";
import tagsModele from "@/store/tags.modele";
import recipeModule from "@/store/recipe.module";
import { Value } from "../value";

export class RecipeSelector extends ObjectView{
    recipename:string;
    textcolor:string;
    fillcolor:string;
    type3d:number;
    recipe:Recipe;
    rows:RecipeRow[]
    text = ""

    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1
        const fillcolor2 = ColorUtils.convertformat(this.fillcolor);
        const rectstroke = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10
          });
          if (this.type3d==0){
             rectstroke.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectstroke.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectstroke.fillLinearGradientColorStops([0, fillcolor2, 1, ColorUtils.darkColor(fillcolor2,0.5)])
          }
          else
            rectstroke.fill(ColorUtils.darkColor(fillcolor2,0.5))
          this.node.add(rectstroke)
        const rect = new Konva.Rect({
            x: this.height/20,
            y: this.height/20,
            width: this.width-this.height/10,
            height: this.height-this.height/10,
            cornerRadius:this.height/10,
          
          });
          if (this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height });
             rect.fillLinearGradientColorStops([0, fillcolor2, 0.5, ColorUtils.darkColor(fillcolor2,0.85),
                      1,ColorUtils.darkColor(fillcolor2,0.5)]);
          }
          else
                rect.fill(fillcolor2)
          this.node.add(rect);
         
           
          if (this.recipe==null || this.rows==null) return;
          this.rows.forEach((row)=>{
            let correctrow = true;
            if (row.ingredients==null) return;
            row.tagnames = new Map()
            row.columnnames = new Map()
            this.recipe.ingredients.forEach((ing)=>{
                row.tagnames.set(ing.dbname, ing.path)
                row.columnnames.set(ing.dbname, ing.ingredientname)
            });
            for (const [key, value] of Object.entries(row.ingredients)){
                
                let rowvalue = 0;
                
                try{
                    if (value==null){
                        correctrow = false;
                        return;
                    }
                    rowvalue = parseFloat(value)
                    const ing = this.recipe.ingredients.find(i=>i.dbname==key)
                    if (ing==null){
                        correctrow = false;
                        return;
                    }
                    
                    const tag = tagsModele.getTag(ing.path)
                    if (tag==null){
                        correctrow = false
                        return;
                    }

                    const tagvalue = parseFloat(tag.tagvalue)
                    if (rowvalue!=tagvalue){
                        correctrow = false
                        return;
                    }
                }
                catch(err){
                    console.log(err)
                    correctrow = false
                    return
                }
               
            }
            if (correctrow){
                this.text = row._name
                return
            }
        })
             const   fontsize = this.height/2;
                const Text = new Konva.Text({
                    x: this.height/20,
                    y: this.height/20,
                    verticalAlign:"middle",
                    width: this.width-this.height/10,
                    height: this.height-this.height/10,
                    text: this.text,
                    fontSize: fontsize,
                    fontFamily: 'roboto',
                    fontStyle: 'normal',
                    align:'center',
                    fill: ColorUtils.convertformat(this.textcolor),
                    shadowColor:'black',
                    shadowBlur:0,
                    shadowOffset:{ x: 1, y: 1 },
            
                  });

                  this.node.add(Text)
                  this.setFunctions();
                  
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color":
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "textcolor":
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }

    protected setFunctions():void{
  
        const  node1 = new Konva.Group();
        let flagclick = false
        this.node.add(node1)
        this.node.on('click touchstart', ()=>{
            flagclick = !flagclick;
            if (flagclick){
              this.drawMenu(node1)
            }else{
              node1.removeChildren()
            }
        })
    }

    private drawMenu( node:Konva.Group):void{
          if(this.rows.length == 0) return;
          this.rows.forEach((row)=>{
          const rectDropdown = new Konva.Rect({
            x: 0,
            y: this.height+this.height/40+(30*this.rows.indexOf(row)),
            width: this.width,
            height: 30,
            fill: 'white',
            strokeWidth: 0.05,
            stroke: 'black'
          })
        node.add(rectDropdown)
      
      const textDropDown = new Konva.Text({
             x: this.width/30,
             y: this.height+this.height/40+(30*this.rows.indexOf(row)), 
            height: 30,
            width: this.width*29/30,
            text: row._name,
            fontSize: 20,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'left',
            verticalAlign:"middle",
        })
        textDropDown.on('click touchstart', ()=>{
            recipeModule.setRecipeRow(row)
            recipeModule.setRecipeRowEnabled(true)
            recipeModule.setObjectView(this)
        });
        node.add(textDropDown)

          })
    }
}
import { SVGObject } from "../svgobject";

export class SVGHvac15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 89.078" enable-background="new 0 0 112.5 89.078" xml:space="preserve">
        <g id="Group_Support_Cylinder">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="14.0835" y1="70.7344" x2="98.3105" y2="70.7344">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="14.083" y="59.372" fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" width="84.227" height="22.724" />
        </g>
        <g id="Group_Rent_Vent">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="9.459" y1="18.7695" x2="102.9277" y2="18.7695">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M102.928,37.5V4.73L98.311,0H13.852L9.459,4.73v32.534   c0.708-0.147,1.416-0.372,2.124-0.726c-0.708,0.354-1.416,0.578-2.124,0.726v0.068c0.042,0.066,0.085,0.128,0.124,0.206   c-0.039-0.078-0.082-0.14-0.124-0.206V37.5" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="48.8779" x2="112.5" y2="48.8779">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polyline fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" points="9.459,37.166 2.14,44.148 0,51.131 0,60.59    13.852,60.59 98.311,60.59 112.5,60.59 112.5,51.131 110.248,44.148 102.928,37.166  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M13.852,60.924h84.459" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M102.928,37.501H9.459" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M0,51.465h112.5" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M7.996,14.528h96.17" />
        </g>
        <g id="Group_Base_Support">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="91.5254" y1="107.3271" x2="20.8624" y2="63.1721">
                <stop offset="0" style="stop-color:#E5E2DF" />
                <stop offset="0" style="stop-color:#D6D5D4" />
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.1" style="stop-color:#BDBDBD" />
                <stop offset="0.23" style="stop-color:#A6A6A6" />
                <stop offset="0.49" style="stop-color:#CCCCCC" />
                <stop offset="0.66" style="stop-color:#BDBDBD" />
                <stop offset="0.76" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D3D4" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M9.459,81.42h93.468v7.658H9.459V81.42z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.234375, 195.0, 0.0, 0.3333333333333333, 169.0, 0.0, 0.40625, 151.0, 0.1, 0.5208333333333334, 121.0, 0.23, 0.703125, 75.0, 0.49, 0.40625, 151.0, 0.66, 0.5208333333333334, 121.0, 0.76, 0.609375, 99.0, 1.0, 0.359375, 163.0, 1.0, 0.5052083333333334, 125.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import { ObjectView } from './object';
import { TextView } from './text'
import { Value } from '../value';


export class AnalogMeter extends ObjectView{
    color:string
    fillcolor:string
    text:string

    public drawObject():void{
		super.initObject();

	
		let fillcolor = '0xffffff00'; 
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

        let color = this.color; 
    
        const colorprop = this.properties['color'];
            if (colorprop!=null){	
                const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
                if (colorproperty!=null) 
                        color = colorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const color2 = ColorUtils.convertformat(color)    
       
        let value = 0   
        let valueproperty
        const valueprop = this.properties['value'];
        if (valueprop!=null){	
            valueproperty =Object.assign(new ValueProperty(), valueprop);
            if (valueproperty!=null) 
                value = valueproperty.getValue(this.tagnames)     
        }	

        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width/2,
            });
                circle.fillLinearGradientStartPoint({ x: 0, y:-this.width/2 });
                circle.fillLinearGradientEndPoint({ x: 0, y: this.width/2});
                circle.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
        this.node.add(circle)

        const circle2 = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width*23/50,
            });
                circle2.fillLinearGradientStartPoint({ x: 0, y:-this.width*23/50 });
                circle2.fillLinearGradientEndPoint({ x: 0, y: this.width*23/50});
                circle2.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
        this.node.add(circle2)

        const circle3 = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width*2/5,
            });
                circle3.fillLinearGradientStartPoint({ x: 0, y:-this.width*2/5 });
                circle3.fillLinearGradientEndPoint({ x: 0, y: this.width*2/5});
                circle3.fillLinearGradientColorStops([0, 'white',
            1, fillcolor2])
        this.node.add(circle3)

        const line1 = new Konva.Line({
            points: [this.width/10, this.width/2, this.width/5, this.width/2],
            strokeWidth: this.width/100,
            stroke: ColorUtils.darkColor(fillcolor2,0.5)
        })  
        const line2 = new Konva.Line({
            points: [this.width*8/10, this.width/2, this.width*9/10, this.width/2],
            strokeWidth: this.width/100,
            stroke: ColorUtils.darkColor(fillcolor2,0.5)
        }) 
        const line3 = new Konva.Line({
            points: [this.width/2, this.width/10, this.width/2, this.width/5],
            strokeWidth: this.width/100,
            stroke: ColorUtils.darkColor(fillcolor2,0.5)
        }) 
        const line4 = new Konva.Line({
            points: [this.width/4, this.width/4, this.width*3/10, this.width*3/10],
            strokeWidth: this.width/100,
            stroke: ColorUtils.darkColor(fillcolor2,0.5)
        })
        const line5 = new Konva.Line({
            points: [this.width*3/4, this.width/4, this.width*7/10, this.width*3/10],
            strokeWidth: this.width/100,
            stroke: ColorUtils.darkColor(fillcolor2,0.5)
        })
        this.node.add(line1, line2, line3, line4, line5)

        let textValueMin
        if (valueproperty==null) textValueMin ='0'
        else textValueMin = valueproperty.minimumvalue  

        const text = new TextView()
        text.width = this.width/3
        text.height = this.width/12
        text.text = textValueMin.toString()
        text.fontsize = this.width/12
        text.posx = this.width/5
        text.posy = this.width/2-this.width/24
        text.textplacement = 0
        text.textcolor = ColorUtils.darkColor(color2,0.5)
        text.useborder = false
        text.bordercolor = null
        text.linewidth = 1
        text.fill = false
        text.fillcolor = ColorUtils.darkColor(color2,0.5)
        text.underline = false
        text.initLayer(this.layer)
        text.drawObject()
        this.node.add(text.rotatednode)

        let textValueMax
        if (valueproperty==null) textValueMax ='100'
        else textValueMax = valueproperty.maximumvalue  

        const text2 = new TextView()
        text2.width = this.width/3
        text2.height = this.width/12
        text2.text = textValueMax.toString()
        text2.fontsize = this.width/12
        text2.posx = this.width*3/5
        text2.posy = this.width/2-this.width/24
        text2.textplacement = 0
        text2.textcolor = ColorUtils.darkColor(color2,0.5)
        text2.useborder = false
        text2.bordercolor = null
        text2.linewidth = 1
        text2.fill = false
        text2.fillcolor = ColorUtils.darkColor(color2,0.5)
        text2.underline = false
        text2.initLayer(this.layer)
        text2.drawObject()
        this.node.add(text2.rotatednode)

        let textValueMiddle
        if (valueproperty==null) textValueMax ='50'
        else textValueMiddle = (valueproperty.maximumvalue-valueproperty.minimumvalue)/2+valueproperty.minimumvalue

        const text3 = new TextView()
        text3.width = this.width/3
        text3.height = this.width/12
        text3.text = textValueMiddle.toString()
        text3.fontsize = this.width/12
        text3.posx = this.width/2-this.width/6
        text3.posy = this.width/4
        text3.textplacement = 1
        text3.textcolor = ColorUtils.darkColor(color2,0.5)
        text3.useborder = false
        text3.bordercolor = null
        text3.linewidth = 1
        text3.fill = false
        text3.fillcolor = ColorUtils.darkColor(color2,0.5)
        text3.underline = false
        text3.initLayer(this.layer)
        text3.drawObject()
        this.node.add(text3.rotatednode)

        const textLabel = new TextView()
        textLabel.width = this.width/2
        textLabel.height = this.width/12
        textLabel.text = this.text
        textLabel.fontsize = this.width/12
        textLabel.posx = this.width/2-this.width/4
        textLabel.posy = this.width*2/3
        textLabel.textplacement = 1
        textLabel.textcolor = ColorUtils.darkColor(color2,0.5)
        textLabel.useborder = false
        textLabel.bordercolor = null
        textLabel.linewidth = 1
        textLabel.fill = false
        textLabel.fillcolor = ColorUtils.darkColor(color2,0.5)
        textLabel.underline = false
        textLabel.initLayer(this.layer)
        textLabel.drawObject()
        this.node.add(textLabel.rotatednode)

        let arrowWidth = this.width*1/20
        let arrowHeight = this.width*3/10
        if (arrowWidth<1)  arrowWidth = 1;
        if (arrowHeight<1) arrowHeight = 1;
        
        const arrow = new Konva.Line({
            points: [arrowWidth/4, arrowHeight,
                    0, arrowHeight/3,
                    arrowWidth/2, 0,
                    arrowWidth, arrowHeight/3,
                    arrowWidth*3/4, arrowHeight,
                    arrowWidth/4, arrowHeight],
            fill: color2,
            closed: true,
          }); 
        this.node.add(arrow)
          
        let anglearr = 270
        if(value) anglearr = value-90;
		const radians = anglearr*Math.PI/180;

        const posx = this.width/2 -arrowWidth/2;
        const posy = this.height/2 -arrowHeight/2;
        const x =arrowWidth/2+posx-arrowWidth/2* Math.cos(radians) +(arrowHeight)* Math.sin(radians);
        const y = posy+arrowHeight/2 -(arrowWidth/2)* Math.sin(radians) -(arrowHeight)* Math.cos(radians);
        //const x= posx
        //const y=posy
        arrow.position({x:x,y:y})
        arrow.rotation(anglearr) 

        const circleBall = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width/20,
            });
            circleBall.fillLinearGradientStartPoint({ x: 0, y:-this.width/20 });
            circleBall.fillLinearGradientEndPoint({ x: 0, y: this.width/20});
            circleBall.fillLinearGradientColorStops([0, 'white',
                1, ColorUtils.darkColor(fillcolor2,0.5)])
          this.node.add(circleBall)
           
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        const valueproperty: ValueProperty = this.properties==null ? null : this.properties['value'];
        switch(field){
            case "color": {this[field] = value.value;this.rotatednode=null;break;}
            case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
            case "text": {this[field] = value.value;this.rotatednode=null;break;}
            case "maximum": {if (valueproperty!=null){valueproperty.maximumvalue = value.value}   
                break;}
            case "minimum": {if (valueproperty!=null){valueproperty.minimumvalue = value.value}   
                break;}
            case "decimalpos": {if (valueproperty!=null){valueproperty.decimalpos = Math.trunc(value.value)}   
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        const valueproperty: ValueProperty = this.properties==null ? null : this.properties['value'];
        switch(field){
            case "color": {value.datatype=7; value.value = this[field];break}
            case "fillcolor": {value.datatype=7; value.value = this[field];break}
            case "text": {value.datatype=7; value.value = this[field];break}
            case "maximum": {if (valueproperty!=null){
                value.datatype=6; value.value = valueproperty.maximumvalue
                }
                break;}
            case "minimum": {if (valueproperty!=null){
                value.datatype=6; value.value = valueproperty.minimumvalue
                }
                break;}
            case "decimalpos": {if (valueproperty!=null){
                value.datatype=2; value.value = valueproperty.decimalpos
                }
                break;}
        }
        return value;
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 29.924" enable-background="new 0 0 112.5 29.924" xml:space="preserve">

        <g id="Group_Horizonatal_Short">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="94.2881" y1="-23.0383" x2="123.9873" y2="-23.0383" gradientTransform="matrix(0 -1 1 0 79.2883 124.2117)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M112.5,29.924H0V0.224h112.5V29.924z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5989583333333334, 0.0, 0.31, 0.6145833333333334, 0.0, 0.43, 0.6666666666666666, 0.0, 0.5, 0.7552083333333334, 0.0, 0.57, 0.8854166666666666, 0.0, 0.62, 0.9375, 15.0, 0.67, 0.7291666666666666, 69.0, 0.68, 0.65625, 87.0, 0.72, 0.6197916666666666, 97.0, 0.75, 0.515625, 123.0, 0.79, 0.3333333333333333, 169.0, 0.82, 0.08333333333333333, 233.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3958333333333333, 0.0, 0.01, 0.3645833333333333, 0.0, 0.11, 0.19791666666666666, 0.0, 0.23, 0.08333333333333333, 0.0, 0.36, 0.020833333333333332, 0.0, 0.56, 0.0, 0.0, 0.73, 0.010416666666666666, 0.0, 0.83, 0.08333333333333333, 0.0, 0.91, 0.19270833333333334, 0.0, 0.98, 0.3385416666666667, 0.0, 1.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
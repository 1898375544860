import { SVGObject } from "../svgobject";

export class SVGFinish28 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 92.771" enable-background="new 0 0 112.5 92.771" xml:space="preserve">
        <g id="Group_Layer1">
            <path fill="#1A1A1A" d="M33.931,91.645h51.177V88.94H33.931V91.645z" />
            <path fill="#333333" d="M33.931,3.72h51.177v85.22H33.931V3.72z" />
            <path fill="#CCCCCC" d="M33.931,3.72h51.177v52.529H33.931V3.72z" />
            <path fill="#C7C7C7" d="M33.931,40.919L85.107,19.05v37.199H33.931V40.919z" />
            <path fill="#C2C2C2" d="M33.931,43.173l51.177-21.868v34.944H33.931V43.173z" />
            <path fill="#BDBDBD" d="M33.931,46.329l51.177-21.868v31.788H33.931V46.329z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M33.931,3.72h51.177v52.529H33.931V3.72" />
            <path fill="#1A1A1A" d="M102.016,88.94h5.411v1.353h-5.411V88.94z" />
            <path fill="#1A1A1A" d="M77.217,91.645h5.186v1.127h-5.186V91.645z" />
            <path fill="#1A1A1A" d="M36.636,91.645h5.185v1.127h-5.185V91.645z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M77.217,0h1.353v3.72h-1.353V0z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M41.821,0h1.353v3.72h-1.353V0z" />
            <path fill="#FF0000" d="M33.931,3.72h51.177v1.353H33.931V3.72z" />
            <path fill="#193366" stroke="#4C4C4C" stroke-width="0.25" d="M51.064,7.891h16.908v3.832H51.064V7.891z" />
            <path fill="#1A1A1A" d="M85.107,87.587H112.5v1.353H85.107V87.587z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M95.479,64.14h2.254v23.447h-2.254V64.14z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M101.114,64.14h2.028v23.447h-2.028V64.14z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M106.412,64.14h2.367v23.447h-2.367V64.14z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M0,40.468h33.93v14.429L0,52.192V40.468z" />
            <path fill="#191919" d="M52.417,66.62h2.48V88.94h-2.48V66.62z" />
            <path fill="#191919" d="M75.863,66.62h2.706V88.94h-2.706V66.62z" />
            <path fill="#4C6699" d="M33.931,5.073h51.177v2.818H33.931V5.073z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M33.931,3.72h51.177v85.22H33.931V3.72" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M31.451,69.325h9.018v13.076h-9.018V69.325z" />
            <path fill="#FFFF00" d="M32.578,70.678h3.833v3.833h-3.833V70.678z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="95.4785" y1="43.8499" x2="108.7793" y2="43.8499">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M95.479,64.14V23.56h13.301v40.58H95.479" />
            <path fill="#E5E5E5" d="M85.107,3.72h15.668v36.748H85.107V3.72z" />
            <path fill="#E0E0E0" d="M85.107,26.49l15.668-8.116v22.094H85.107V26.49z" />
            <path fill="#E5E5E5" d="M18.149,3.72h15.781v36.748H18.149V3.72z" />
            <path fill="#E0E0E0" d="M18.149,26.49l15.781-8.116v22.094H18.149V26.49z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.149,3.72h15.781v36.748H18.149V3.72" />
            <path fill="#B2B2B2" d="M79.922,18.149h-1.353v38.101h1.353V18.149z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M33.931,53.544h51.177" />
            <path fill="#E5E5E5" d="M39.116,18.149h39.454V66.62H39.116V18.149z" />
            <path fill="#E0E0E0" d="M39.116,54.671l39.454-20.29V66.62H39.116V54.671z" />
            <path fill="#FFFF00" d="M49.712,20.854h5.186v5.186h-5.186V20.854z" />
            <path fill="#FFFF00" d="M58.955,26.039h5.186v5.186h-5.186V26.039z" />
            <path fill="#FF6600" d="M60.984,29.872l-0.902-0.677v-1.127l0.902-0.677h0.902l0.901,0.677v1.127l-0.901,0.677H60.984z" />
            <path fill="#CCCCCC" d="M56.25,20.854h9.243v3.833H56.25V20.854z" />
            <path fill="#FF6600" d="M78.569,26.039h2.706v2.705h-2.706V26.039z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M39.116,18.149h39.454V66.62H39.116V18.149" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M85.107,3.72h15.668v36.748H85.107V3.72" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M85.107,41.821H112.5v11.724l-27.393,2.705V41.821z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M85.107,40.468H112.5v11.724l-27.393,2.705V40.468z" />
            <path fill="#CCCCCC" d="M91.646,49.711l19.614-1.353l-2.48,1.353l-20.967,2.48L91.646,49.711z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M3.721,42.948h6.763v6.764H3.721V42.948z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M19.502,42.948h7.891v7.892h-7.891V42.948z" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M1.241,50.839l30.21,1.353" />
            <path fill="#FF0000" d="M6.313,48.359l-1.24-1.127v-1.804l1.24-1.127h1.804l1.015,1.127v1.804l-1.015,1.127H6.313z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M3.721,49.711v-6.764h6.763" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M19.502,50.839v-7.892h7.891" />
            <path fill="#4C4C4C" d="M24.687,32.577h6.764v4.058h-6.764V32.577z" />
            <path fill="#4C4C4C" d="M24.687,27.392h6.764v3.833h-6.764V27.392z" />
            <path fill="#4C4C4C" d="M24.687,21.982h6.764v4.058h-6.764V21.982z" />
            <path fill="#4C4C4C" d="M24.687,16.796h6.764v4.058h-6.764V16.796z" />
            <path fill="#4C4C4C" d="M24.687,11.723h6.764v3.945h-6.764V11.723z" />
            <path fill="#7F0000" d="M27.393,8.906h1.353v28.857h-1.353V8.906z" />
        </g>
        <g id="Group_Layer_2">
        </g>
        <g id="Group_Layer_3">
        </g>
        <g id="Group_Layer_4">
        </g>
        <g id="Group_Layer_5">
        </g>
        <g id="Group_Layer_6">
        </g>
        <g id="Group_Layer_7">
        </g>
        <g id="Group_Layer_8">
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
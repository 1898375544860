import { SVGObject } from "../svgobject";

export class SVGPeople4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 29.613 112.5" enable-background="new 0 0 29.613 112.5" xml:space="preserve">
        <g id="Group_RightArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M1.576,30.071v7.544L0.901,43.47L0.563,57.32L0,61.261l1.126,3.378   l2.59,1.801l0.225-4.053l0.113-3.49v-2.252l1.801-14.525l1.014-6.306l0.563-7.544" />
        </g>
        <g id="Group_Neck">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M26.01,21.4l-2.814-1.914l-5.518-1.802L16.44,15.32h-4.054   l-1.238,2.927l-6.081,2.365l-2.364,4.279l-1.126,5.18" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M23.533,27.03l0.9,10.697l0.226,7.544l0.9,10.021l0.226,2.928   l-0.226,3.378l0.113,4.054l2.702-3.49l1.238-2.703L28.6,55.856l0.451-11.035L28.6,36.376l-0.338-7.77L26.01,21.4" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M14.188,63.4l0.563,1.238l0.563,8.67l1.126,11.035l0.112,8.67   l1.689,12.386l-1.126,4.729l0.563,1.126l2.928,1.238h3.49l1.126-1.238l-2.928-5.18V86.37l1.802-21.281l0.676-5.405" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M5.292,54.054L4.729,68.017l0.563,15.876L4.729,88.51v17.565   l-1.126,2.815l-1.801,1.801l0.675,1.127h4.617l2.928-2.252l-0.563-3.491l-0.563-3.04l2.927-19.818L14.188,63.4" />
        </g>
        <g id="Group_Shoes">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M25.785,111.818v-1.127l-1.689-1.801l-1.238-2.815h-3.49l-1.689-1.802v3.49   l-0.563,1.803v1.125l0.563,1.127l2.364,0.675h5.18L25.785,111.818z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.351,111.818v-1.127l1.689-1.801l1.126-2.815H7.77l1.688-1.802l0.563,4.054   l0.563,1.239l-1.688,1.125l-1.126,1.127l-2.365,0.675H1.802L1.351,111.818z" />
        </g>
        <g id="Group_BackHair">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M21.281,9.353l-0.901,8.783l-0.9-0.676l-0.226,0.676h-0.9l-1.464-1.239l-0.226-1.239   l2.252-4.842L21.281,9.353z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M7.882,9.353l1.238,9.458l0.112-0.675l0.226,0.675h1.014l-0.113-1.238l0.676,1.238   l3.716-3.828L7.882,9.353z" />
        </g>
        <g id="Group_Face">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M19.367,11.379H9.796l-1.238-1.014L7.882,9.24V8.114l0.676-1.239   h12.16l0.563,1.239V9.24l-0.676,1.126L19.367,11.379z" />
            <ellipse fill="#996633" stroke="#4C4C4C" stroke-width="0.25" cx="14.526" cy="8.677" rx="5.742" ry="7.544" />
            <path fill="#663333" d="M14.526,11.267l1.576,0.45h-3.266L14.526,11.267z" />
            <path fill="#B27F7F" d="M12.836,13.406l-0.45-0.45h4.166l-0.45,0.45H12.836z" />
            <path d="M18.016,6.763L15.2,6.312l0.226,0.451l2.815,0.338L18.016,6.763z" />
            <path d="M10.922,6.763l2.928-0.451l-0.338,0.451l-2.815,0.338L10.922,6.763z" />
            <path d="M12.048,9.127h0.563l0.225-0.676l-0.225-0.676h-0.563l-0.112,0.676L12.048,9.127z" />
            <path d="M16.326,9.127h0.563l0.112-0.676L16.89,7.776h-0.563l-0.112,0.676L16.326,9.127z" />
        </g>
        <g id="Group_TopHair">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M9.008,8.227l-0.45-1.464L7.882,8.227l0.676-5.18l1.688-1.914   l1.802-1.014l2.364-0.112l2.365,0.112l1.914,1.014l1.688,1.914l0.901,5.18l-0.676-1.464l-0.45,1.464l-1.014-3.829l-1.126-1.802   l0.226,2.252h-7.319l0.226-2.252L9.796,4.398L9.008,8.227z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M14.188,65.201l-1.126,12.161l-2.027,13.286l-1.351,14.863H7.206   l-3.152,0.563V83.78l-0.113-8.332L3.49,63.175l1.464-10.359l1.576-3.829l4.955-0.676l6.305,0.563h5.855l2.252,8.219l-0.112,9.797   l-2.14,11.711l0.45,6.756l-1.126,20.154l-1.351-0.563h-1.914l-1.802,0.563l-2.14-19.142L15.2,77.926L14.188,65.201" />
            <path fill="#B2B2B2" d="M14.188,64.639l-1.126,11.147l-2.027,14.3l-0.563,6.306l-0.563-4.729v-9.008l2.703-11.035l0.45-5.291   v-3.717v-2.139l0.563-9.571v9.009l0.563,3.152l2.139-3.717L15.2,64.188l1.126,5.293l0.451,13.174l0.563,9.571l1.576,4.166   l-1.576,0.563l-1.014-6.869L15.2,76.35L14.188,64.639z" />
        </g>
        <g id="Group_UpperBody">
            <path fill="#66FFCC" stroke="#4C4C4C" stroke-width="0.25" d="M5.63,33.899l-5.18-1.351l0.676-4.955l1.351-3.716l2.14-3.49   l4.054-1.239l3.266-1.689l2.139,1.126h2.252l1.577-1.126l6.53,2.252l2.365,1.689l1.576,3.491v2.252l0.676,5.855l-4.617,0.901   l-0.788,10.584v3.828l0.788,1.915H5.63v-1.239l0.676-2.59L5.63,33.899z" />
            <path fill="#4CE5B2" d="M6.531,33.561l0.676,1.577l4.054,1.239l4.054-1.352l7.206,0.901l1.238,1.464l-0.788-4.054L18.128,34.8   L15.2,33.787L11.034,34.8L6.531,33.561z" />
            <path fill="#4CE5B2" d="M23.42,26.805l0.563,5.743l-0.9,0.788l0.788,3.829l0.112-3.378l1.802-0.338l-1.014-0.563l0.563-4.842   l-0.901,2.365L23.42,26.805z" />
            <path fill="#4CE5B2" d="M4.954,29.508l0.45,3.04l-0.788,0.901l1.464,0.338l0.112,3.04l1.239-10.134L6.08,32.548L4.954,29.508z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGButton1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Button_Base">
            <radialGradient id="SVGID_1_" cx="56.25" cy="56.25" r="55.8472" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#575757" />
                <stop offset="0.82" style="stop-color:#DEDEDE" />
                <stop offset="1" style="stop-color:#575757" />
            </radialGradient>
            <ellipse fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" cx="56.25" cy="56.25" rx="56.25" ry="55.441" />
        </g>
        <g id="Group_Button_Side">
            <path fill="#4C4C4C" d="M99.041,54.25c0,24.258-19.663,43.919-43.919,43.919S11.203,78.508,11.203,54.25   c0-24.251,19.663-43.918,43.919-43.918S99.041,29.998,99.041,54.25z" />
        </g>
        <g id="Group_Button_Face">
            
                <ellipse transform="matrix(-1 0.0056 -0.0056 -1 95.5689 93.1019)" fill="#616161" stroke="#333333" stroke-width="0.25" cx="47.654" cy="46.685" rx="42.54" ry="42.655" />
            <circle fill="none" stroke="#C9C9C9" cx="47.75" cy="47.25" r="32.896" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Button_Side",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Button_Face",[0.0, 0.7552083333333334, 0.0]);
		
        super.drawObject();
    }
}
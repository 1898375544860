import { SVGObject } from "../svgobject";

export class SVGDuct3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 85.586" enable-background="new 0 0 112.5 85.586" xml:space="preserve">
        <g id="Group_Base_Layer">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M103.379,78.829H0V6.757h103.379V78.829z" />
        </g>
        <g id="Group_Side1">
            <path fill="#CCCCCC" d="M101.352,76.577V9.009l-49.55,33.784L101.352,76.577z" />
        </g>
        <g id="Group_Side2">
            <path fill="#D9D9D9" d="M51.802,42.568l49.55,33.784H2.252L51.802,42.568z" />
        </g>
        <g id="Group_Side3">
            <path fill="#BFBFBF" d="M2.252,76.577V9.009l49.549,33.784L2.252,76.577z" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,2.253H98.874v2.252H112.5V2.253z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,80.857H98.874v2.478H112.5V80.857z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M107.883,85.586h-4.504V0h4.504V85.586z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Base_Layer",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Side1",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Side2",[0.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.fills,"Group_Side3",[0.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.609375, 99.0]);	

        super.drawObject();
    }
}
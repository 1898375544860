import {
    encodeRoute,
    encodeBearerAuthMetadata, 
    encodeSimpleAuthMetadata, 
    encodeAndAddCustomMetadata, 
    encodeAndAddWellKnownMetadata, 
    MESSAGE_RSOCKET_ROUTING, 
    MESSAGE_RSOCKET_AUTHENTICATION} from "rsocket-core";
import auth from '@/model/auth'
        
   export default class Metadata {
        auth:auth;
        route: string;
            constructor(auth:auth, route:string) {
                this.route = route;
                this.auth = auth;
            }
        
            toMetadata():Buffer {
                let metadata = Buffer.alloc(0);
                if (this.auth) {
                    if (this.auth["type"] === 'bearer') {
                        metadata = encodeAndAddCustomMetadata(
                            metadata,
                            MESSAGE_RSOCKET_AUTHENTICATION.string,
                            encodeBearerAuthMetadata(this.auth["token"]),
                        );
                    }
                    if (this.auth["type"] === 'simple') {
                        metadata = encodeAndAddCustomMetadata(
                            metadata,
                            MESSAGE_RSOCKET_AUTHENTICATION.string,
                            encodeSimpleAuthMetadata(this.auth["username"], this.auth["password"]),
                        );
                    }
                }
                if (this.route) {
                    metadata = encodeAndAddWellKnownMetadata(
                        metadata,
                        MESSAGE_RSOCKET_ROUTING,
                        encodeRoute(this.route)
                    );
                }
                return metadata;
            }
        }
import { SVGObject } from "../svgobject";

export class SVGMaterial11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 64.98 112.5" enable-background="new 0 0 64.98 112.5" xml:space="preserve">
        <g id="Group_Stand_Rear">
            <path fill="#4C7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M5.407,75h2.026v24.887H5.407V75z" />
            <path fill="#4C7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M59.461,75h-2.14v24.887h2.14V75z" />
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M57.321,99.887V83.334l-6.193,16.553H57.321z" />
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,99.887V83.334l6.307,16.553H7.433z" />
            <path fill="#194C4C" stroke="#4C4C4C" stroke-width="0.25" d="M16.218,102.252H48.65v-2.365h13.852v9.234h-4.617v-2.364H7.095   v2.364H2.478v-9.234h13.739V102.252z" />
        </g>
        <g id="Group_Bottom_Collector">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="32.4905" y1="97.4102" x2="32.4905" y2="72.4102">
                <stop offset="0" style="stop-color:#99A6A6" />
                <stop offset="0.39" style="stop-color:#9BA8A8" />
                <stop offset="0.72" style="stop-color:#ADBABA" />
                <stop offset="1" style="stop-color:#B2BFBF" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,72.41l24.887,25h15.09l25-25H0.002" />
            <path fill="#99A6A6" stroke="#4C4C4C" stroke-width="0.25" d="M24.888,97.41h15.09v10.022h-15.09V97.41z" />
        </g>
        <g id="Group_Stand">
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M62.388,102.478V82.433l-7.433,20.045H62.388z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,102.478V82.433l7.433,20.045H2.478z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,72.41h2.477v30.067H0.002V72.41z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M64.978,72.41h-2.59v30.067h2.59V72.41z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M14.978,104.955h34.91v-2.478h15.09V112.5h-5.067v-2.59H4.956v2.59   H0.002v-10.022h14.977V104.955z" />
        </g>
        <g id="Group_Feeder_sub">
            <path fill="#C7CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,7.433h64.977V72.41H0.002V7.433z" />
            <path fill="#E0E5E5" stroke="#7F7F7F" stroke-width="0.25" d="M2.478,69.933l29.955-29.955L2.478,10.022V69.933z" />
            <path fill="#EFF2F2" stroke="#7F7F7F" stroke-width="0.25" d="M2.478,10.022l29.955,29.955l29.955-29.955H2.478z" />
            <path fill="#D4D9D9" stroke="#7F7F7F" stroke-width="0.25" d="M62.388,10.022L32.433,39.978l29.955,29.955V10.022z" />
            <path fill="#C7CCCC" stroke="#7F7F7F" stroke-width="0.25" d="M62.388,69.933L32.433,39.978L2.478,69.933H62.388z" />
            <path fill="#E0E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,7.433l32.432-4.955l32.545,4.955H0.002z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="17.4558" y1="3.7163" x2="47.4109" y2="3.7163">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M17.456,2.478h29.955v2.478H17.456V2.478" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="22.4109" y1="1.2388" x2="42.4558" y2="1.2388">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M22.411,0h20.045v2.478H22.411V0" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7447916666666666, 65.0, 0.39, 0.7291666666666666, 69.0, 0.72, 0.5885416666666666, 105.0, 1.0, 0.546875, 115.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Stand_Rear",[0.0, 0.8541666666666666, 0.0, 1.0, 0.8541666666666666, 0.0, 2.0, 0.6614583333333334, 0.0, 3.0, 0.6614583333333334, 0.0, 4.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Bottom_Collector",[0.0, 0.7447916666666666, 65.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.9375, 15.0, 1.0, 0.9375, 15.0, 2.0, 0.5364583333333334, 117.0, 3.0, 0.5364583333333334, 117.0, 4.0, 0.5364583333333334, 117.0]);
		this.fillGradient(this.fills,"Group_Feeder_sub",[0.0, 0.421875, 147.0, 1.0, 0.22395833333333334, 197.0, 2.0, 0.11979166666666667, 224.0, 3.0, 0.3177083333333333, 173.0, 4.0, 0.421875, 147.0, 5.0, 0.22395833333333334, 197.0]);

        super.drawObject();
    }
}
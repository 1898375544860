import { SVGObject } from "../svgobject";

export class SVGValve10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 81.84" enable-background="new 0 0 112.5 81.84" xml:space="preserve">
        <g id="Group_Port">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="81.333" y1="38.3252" x2="81.333" y2="2.4805">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M50.169,2.48h62.329v35.845H50.169V2.48z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="99.1406" y1="40.918" x2="99.1406" y2="0">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M92.096,0h14.09v40.918h-14.09V0z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="56.8706" y1="38.3252" x2="56.8706" y2="2.4805">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M56.25,2.48h1.24v35.845h-1.24V2.48z" />
        </g>
        <g id="Group_Body">
            <path fill="#FF0000" d="M43.114,65.266h8.063v12.737h-8.063V65.266z" />
            <path fill="#FF0000" d="M3.722,43.397h2.593v3.833H3.722V43.397z" />
            <path fill="#FF0000" d="M6.315,2.48h-1.24v37.197h1.24V2.48z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="0.0024" y1="40.918" x2="51.1777" y2="40.918">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M0.002,81.836v-30.66l6.313-6.426V0h44.863v44.75l-6.425,6.426v30.66H0.002z" />
            <path fill="#7F0000" d="M2.482,10.146h2.592v3.832H2.482V10.146z" />
            <path fill="#A00B0B" d="M11.5,44.75h26.827v34.493H11.5V44.75z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="7.6675" y1="22.3188" x2="49.8247" y2="22.3188">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M7.667,1.24h42.157v42.157H7.667V1.24" />
        </g>
        <g id="Group_1">
            <circle fill="#4C4C4C" cx="24.801" cy="61.997" r="10.821" />
            <circle fill="#B2B2B2" cx="24.801" cy="61.997" r="3.156" />
            <circle fill="#4C4C4C" cx="24.914" cy="47.907" r="1.917" />
            <circle fill="#4C4C4C" cx="24.914" cy="76.087" r="1.916" />
        </g>
        <g id="Group_MeterBody">
            <path fill="#4C4C4C" d="M8.907,5.072h39.678v37.086H8.907V5.072z" />
        </g>
        <g id="Group_Bolt">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="10.5068" y1="40.5596" x2="13.6208" y2="37.4456">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.52" style="stop-color:#FF0000" />
                <stop offset="0.72" style="stop-color:#C30000" />
                <stop offset="0.91" style="stop-color:#920000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <circle fill="url(#SVGID_6_)" cx="12.063" cy="39.002" r="2.202" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="10.5073" y1="9.7866" x2="13.6213" y2="6.6727">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.52" style="stop-color:#FF0000" />
                <stop offset="0.72" style="stop-color:#C30000" />
                <stop offset="0.91" style="stop-color:#920000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <circle fill="url(#SVGID_7_)" cx="12.063" cy="8.229" r="2.202" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="43.7603" y1="9.7866" x2="46.8741" y2="6.6728">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.52" style="stop-color:#FF0000" />
                <stop offset="0.72" style="stop-color:#C30000" />
                <stop offset="0.91" style="stop-color:#920000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <circle fill="url(#SVGID_8_)" cx="45.316" cy="8.229" r="2.202" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="43.7603" y1="40.5601" x2="46.8741" y2="37.4462">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.52" style="stop-color:#FF0000" />
                <stop offset="0.72" style="stop-color:#C30000" />
                <stop offset="0.91" style="stop-color:#920000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <circle fill="url(#SVGID_9_)" cx="45.316" cy="39.002" r="2.202" />
            <circle fill="#FF0000" cx="12.063" cy="8.229" r="1.917" />
            <circle fill="#FF0000" cx="45.316" cy="8.229" r="1.917" />
            <circle fill="#FF0000" cx="12.063" cy="39.001" r="1.917" />
            <circle fill="#FF0000" cx="45.316" cy="39.001" r="1.917" />
            <circle cx="12.063" cy="8.229" r="0.563" />
            <circle cx="45.316" cy="8.229" r="0.563" />
            <circle cx="12.063" cy="39.002" r="0.563" />
            <circle cx="45.316" cy="39.002" r="0.563" />
        </g>
        <g id="Group_Reading">
            <path fill="#FFFFFF" d="M17.925,33.253H39.68v5.072H17.925V33.253z" />
            <path fill="#FFFFFF" d="M17.925,6.313H39.68v3.833H17.925V6.313z" />
            <path fill="#FFFFFF" d="M44.752,17.811l-1.24-1.24l1.24-1.24V17.811z" />
            <path fill="#FFFFFF" d="M41.596,14.654h1.24v1.353h-1.24V14.654z" />
            <path fill="#FFFFFF" d="M41.596,17.246h1.24v1.24h-1.24V17.246z" />
            <path fill="#FFFFFF" d="M13.416,14.654h1.24v1.353h-1.24V14.654z" />
            <path fill="#FFFFFF" d="M13.416,17.246h1.24v1.24h-1.24V17.246z" />
            <path fill="#FFFFFF" d="M15.22,17.811l-1.24-1.24l1.24-1.24V17.811z" />
            <path fill="#E5E5E5" d="M31.339,6.313h8.341v3.833h-8.341V6.313z" />
            <path fill="#FFFFFF" d="M44.793,28.231l0.564-3.218l-1.225-0.214l-0.546,3.112c-1.931-0.489-4.11-0.884-6.478-1.165l0.606-5.045   l-1.234-0.148l-0.608,5.056c-2.233-0.22-4.604-0.344-7.071-0.344c-0.018,0-0.035,0.001-0.052,0.001v-7.103h-1.244v7.124   c-2.271,0.039-4.463,0.169-6.517,0.396l-0.621-5.103l-1.234,0.15l0.622,5.109c-2.405,0.314-4.602,0.748-6.522,1.28l-0.691-3.399   l-1.219,0.248l0.713,3.507c-0.383,0.123-0.775,0.243-1.13,0.373l0.563,1.042c4.293-1.466,10.465-2.387,17.333-2.387   c6.725,0,12.774,0.886,17.055,2.299l0.438-1.1C45.82,28.538,45.313,28.383,44.793,28.231z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.3229166666666667, 0.0, 0.52, 0.6614583333333334, 0.0, 0.72, 0.5052083333333334, 0.0, 0.91, 0.375, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.3229166666666667, 0.0, 0.52, 0.6614583333333334, 0.0, 0.72, 0.5052083333333334, 0.0, 0.91, 0.375, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.3229166666666667, 0.0, 0.52, 0.6614583333333334, 0.0, 0.72, 0.5052083333333334, 0.0, 0.91, 0.375, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.3229166666666667, 0.0, 0.52, 0.6614583333333334, 0.0, 0.72, 0.5052083333333334, 0.0, 0.91, 0.375, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.6614583333333334, 0.0, 1.0, 0.6614583333333334, 0.0, 2.0, 0.6614583333333334, 0.0, 3.0, 0.3229166666666667, 0.0, 4.0, 0.4635416666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Reading",[0.0, 0.0, 255.0, 1.0, 0.0, 255.0, 2.0, 0.0, 255.0, 3.0, 0.0, 255.0, 4.0, 0.0, 255.0, 5.0, 0.0, 255.0, 6.0, 0.0, 255.0, 7.0, 0.0, 255.0, 8.0, 0.20833333333333334, 201.0, 9.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Bolt",[0.0, 0.6614583333333334, 0.0, 1.0, 0.6614583333333334, 0.0, 2.0, 0.6614583333333334, 0.0, 3.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater37 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 72.973" enable-background="new 0 0 112.5 72.973" xml:space="preserve">
        <g id="Group_Motor_Unit">
            <path fill="#616161" d="M16.714,43.144l-1.824-0.73l-0.73-1.783l0.73-1.824l1.824-0.73l1.783,0.73l0.73,1.824l-0.73,1.783   L16.714,43.144" />
            <path fill="#FFFFFF" stroke="#9E9E9E" stroke-width="0.1" d="M16.69,42.445l-1.313-0.525l-0.526-1.283l0.526-1.314l1.313-0.525   l1.284,0.525l0.526,1.314l-0.526,1.283L16.69,42.445" />
            <path fill="#454545" d="M5.181,65.427v-2.253h2.252L5.181,60.81h25.45l-2.252,2.364h2.252v2.253H5.181z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M30.631,58.557V44.594" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M44.595,56.193v-9.234" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M53.941,56.193v-9.234" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="31.8101" y1="58.4451" x2="31.8101" y2="44.2361">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="30.625" y="44.236" fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" width="2.371" height="14.209" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.3994" y1="60.8103" x2="2.3994" y2="42.3416">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M4.799,44.319c0-0.659,0-1.319,0-1.978H4.729L0,44.594v13.852   l4.729,2.365h0.069c0-0.569,0-1.105,0-1.491" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="17.7529" y1="60.8103" x2="17.7529" y2="42.2361">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="4.875" y="42.236" fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" width="25.756" height="18.574" />
            <path fill="#454545" d="M9.798,42.229h16.216v9.347H9.798V42.229z" />
            <path fill="#CCCCCC" d="M9.798,42.229h17.455v1.238H9.798V42.229z" />
            <path fill="#CCCCCC" d="M9.798,44.594h17.455v1.127H9.798V44.594z" />
            <path fill="#CCCCCC" d="M9.798,46.959h17.455v1.126H9.798V46.959z" />
            <path fill="#CCCCCC" d="M9.798,49.211h17.455v1.239H9.798V49.211z" />
            <path fill="#666666" d="M20.271,54.504h5.181v4.054h-5.181V54.504z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M10.924,51.576h9.347v11.599h-9.347V51.576z" />
            <path fill="#FFFFFF" d="M11.487,53.378h8.22v0.563h-8.22V53.378z" />
        </g>
        <g id="Group_ConnectingPipe">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="38.7954" y1="56.1931" x2="38.7954" y2="46.9587">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="32.996" y="46.959" fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" width="11.599" height="9.234" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="59.6846" y1="56.1931" x2="59.6846" y2="46.9587">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="53.941" y="46.959" fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" width="11.486" height="9.234" />
        </g>
        <g id="Group_Support">
            <path fill="#454545" d="M65.428,64.301h37.162v1.127H65.428V64.301z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.2" d="M0.451,72.409v-6.981h111.484v4.729l-23.311,2.252H0.451z" />
        </g>
        <g id="Group_Tank">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="65.4277" y1="40.5398" x2="102.5898" y2="40.5398">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.1" d="M65.428,64.301h37.162V16.779H65.428V64.301" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="65.4277" y1="14.4705" x2="102.5898" y2="14.4705">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.1" d="M65.428,16.779l18.581-4.617l18.581,4.617H65.428z" />
            <path fill="#666666" d="M84.009,12.725l-0.563-0.563v-0.676h1.238v0.676L84.009,12.725z" />
            <path fill="#595959" d="M65.428,23.76h18.581v0.563H65.428V23.76z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M84.009,21.396h17.454" />
        </g>
        <g id="Group_Label">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M79.392,65.427h9.233V44.594h-9.233V65.427z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.392,58.557h9.233" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M82.545,60.472l1.238,1.238l-1.238,1.239l-1.127-1.239L82.545,60.472" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M85.473,60.472l1.238,1.238l-1.238,1.239l-1.126-1.239L85.473,60.472" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="109.5713" y1="29.5037" x2="109.5713" y2="16.7791">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M110.697,16.779v12.725h-2.252V16.779H110.697z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="105.5176" y1="27.2517" x2="105.5176" y2="19.1443">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M108.445,19.144v8.107h-5.855v-8.107H108.445z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="43.4688" y1="1.6326" x2="56.1938" y2="1.6326">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M43.469,0.45h12.725v2.365H43.469V0.45z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="49.25" y1="63.1755" x2="49.25" y2="39.9783">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.1" d="M54.25,46.959v0.361V46.959h-0.309V42.23h-6.982v-2.252   h-2.364v6.98H44.25c0,0.435,0,0.638,0,1.361v7v0.873h0.345v6.982h2.364V60.81h6.982v-4.617h0.309V55.32" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M46.959,65.427h5.743v-7.996h-5.743V65.427z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.959,58.557h5.744" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.959,64.301h5.744" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="46.9595" y1="26.2947" x2="52.7026" y2="26.2947">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M46.959,2.815v42.342l0.563,3.491l2.252,1.126l2.365-1.126l0.563-3.491V2.815H46.959z" />
        </g>
        <g id="Group_Group1">
            <circle fill="#B2B2B2" cx="49.437" cy="23.986" r="15.878" />
            <circle fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="49.437" cy="23.986" r="15.878" />
            <circle fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="49.437" cy="23.986" r="14.752" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="47.144" y1="36.0403" x2="61.4343" y2="21.75">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M49.378,38.274c7.892,0,14.29-6.398,14.29-14.291H48.813c0,0.578,0,0.693,0,1.462" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="37.04" y1="25.8909" x2="51.2197" y2="11.7112">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M49.159,9.65c-7.791,0.117-14.071,6.469-14.071,14.289h13.725c0-0.269,0-0.489,0-1.012" />
            <path fill="#7F7F7F" d="M49.55,22.748l1.239,1.238l-1.352,1.239l-1.126-1.239L49.55,22.748z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Motor_Unit",[0.0, 0.7552083333333334, 0.0, 1.0, 0.0, 255.0, 2.0, 0.5364583333333334, 0.0, 3.0, 0.5364583333333334, 0.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0, 8.0, 0.7916666666666666, 0.0, 9.0, 0.40625, 151.0, 10.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.5364583333333334, 0.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Group1",[0.0, 0.609375, 99.0, 1.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
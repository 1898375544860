import { SVGObject } from "../svgobject";

export class SVGTank34 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 42.725" enable-background="new 0 0 112.5 42.725" xml:space="preserve">
        <g id="Group_TBD">
            <path fill="#666666" d="M108.707,12.175v6.087h3.117l0.676-3.059l-0.676-3.029H108.707z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="55.7427" y1="40.0186" x2="55.7427" y2="0.9033">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M106.977,0.903c0,0,2.142,3.034,2.142,19.727c0,19.389-2.367,19.389-2.367,19.389H2.367V0.903   H106.977z" />
            <ellipse fill="#4D4D4D" cx="2.23" cy="20.404" rx="2.254" ry="19.501" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="82.7402" y1="40.582" x2="82.7402" y2="-0.0229">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#CCCCCC" />
                <stop offset="0.55" style="stop-color:#B9B9B9" />
                <stop offset="0.73" style="stop-color:#898989" />
                <stop offset="0.77" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M82.222,20.625c-0.076,8.9-2.638,19.957-2.638,19.957h4.396c0,0,1.916-11.302,1.916-19.952   c0-9.683-1.916-20.653-1.916-20.653h-4.396C79.584-0.023,82.334,7.426,82.222,20.625z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="29.2524" y1="40.582" x2="29.2524" y2="-0.0229">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#CCCCCC" />
                <stop offset="0.55" style="stop-color:#B9B9B9" />
                <stop offset="0.73" style="stop-color:#898989" />
                <stop offset="0.77" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M28.419,20.625c0,8.383-2.267,19.957-2.267,19.957h4.284c0,0,1.916-12.054,1.916-19.952   c0-8.268-1.916-20.653-1.916-20.653h-4.284C26.152-0.023,28.419,12.69,28.419,20.625z" />
        </g>
        <g id="Group_FrontLegs">
            <path fill="#4D4D4D" d="M81.46,31.487l0.043,11.237l-2.029-1.917L81.46,31.487z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="83.3818" y1="31.4512" x2="83.3818" y2="42.7246">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M81.466,31.451h3.832v11.273h-3.832V31.451z" />
            <path fill="#4D4D4D" d="M27.828,31.487l0.043,11.237l-2.029-1.917L27.828,31.487z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="29.7495" y1="31.4512" x2="29.7495" y2="42.7246">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M27.833,31.451h3.832v11.273h-3.832V31.451z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.40625, 151.0, 0.55, 0.5572916666666666, 113.0, 0.73, 0.9322916666666666, 17.0, 0.77, 1.0, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.40625, 151.0, 0.55, 0.5572916666666666, 113.0, 0.73, 0.9322916666666666, 17.0, 0.77, 1.0, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGButton17 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 58.442 112.5" enable-background="new 0 0 58.442 112.5" xml:space="preserve">
        <g id="Group_Slider_Switch_Platform">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.4761" y1="-265.75" x2="58.106" y2="-265.75" gradientTransform="matrix(1 0 0 -1 0 -209.5)">
                <stop offset="0.73" style="stop-color:#8C8C8C" />
                <stop offset="0.96" style="stop-color:#E6E6E6" />
                <stop offset="1" style="stop-color:#878787" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M54.502,108.967L5.742,3.532L2.476,0h55.63v112.5L54.502,108.967z" />
            <path fill="#A6A6A6" d="M39.777,77.553l14.726,31.456l3.604,3.491H2.476" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.4761" y1="-266.4258" x2="39.7769" y2="-266.4258" gradientTransform="matrix(1 0 0 -1 0 -209.5)">
                <stop offset="0" style="stop-color:#A6A6A6" />
                <stop offset="0.04" style="stop-color:#E6E6E6" />
                <stop offset="0.07" style="stop-color:#A6A6A6" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M2.476,112.5V1.352l3.266,3.49l34.035,72.711" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="5.7417" y1="-267.375" x2="54.5024" y2="-267.375" gradientTransform="matrix(1 0 0 -1 0 -209.5)">
                <stop offset="0.02" style="stop-color:#888888" />
                <stop offset="0.51" style="stop-color:#E6E6E6" />
                <stop offset="1" style="stop-color:#878787" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.742,6.25h48.761V109.5H5.742V6.25z" />
        </g>
        <g id="Group_Slider_Switch_Button_Set">
            <path fill="#870000" stroke="#4C4C4C" stroke-width="0.5" d="M47.521,1.802l6.757,4.167V62.5l-6.757-4.166H0.336V1.802H47.521z" />
            <path fill="#CF0000" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,39.076l6.645,3.829v13.626l-6.645-3.942H0.336V39.076H40.876z   " />
            <path fill="#FF5454" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,52.59l6.645,3.942H7.092L0.336,52.59H40.876z" />
            
            <path fill="#CF0000" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,19.482l6.645,4.279v13.514l-6.645-4.279H0.336V19.482H40.876z   " />
            <path fill="#FF5454" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,32.996l6.645,4.279H7.092l-6.756-4.279H40.876z" />
            <path fill="#CF0000" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,0l6.645,4.393v13.513l-6.645-4.392H0.336V0H40.876z" />
            <path fill="#FF5454" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,13.514l6.645,4.392H7.092l-6.756-4.392H40.876z" />
            <path fill="#FF5454" stroke="#4C4C4C" stroke-width="0.5" d="M47.521,58.334l6.757,4.166H7.092l-6.756-4.166H47.521z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,52.59V39.076" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,32.996V19.482" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M40.876,13.514V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M47.521,58.334V1.802" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Slider_Switch_Button_Set",[0.0, 0.3489583333333333, 0.0, 1.0, 0.5364583333333334, 0.0, 2.0, 0.9010416666666666, 25.0, 3.0, 0.5364583333333334, 0.0, 4.0, 0.9010416666666666, 25.0, 5.0, 0.5364583333333334, 0.0, 6.0, 0.9010416666666666, 25.0, 7.0, 0.9010416666666666, 25.0]);

		
        super.drawObject();
    }
}
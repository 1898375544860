import { Property } from "./property";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";


export class Switch3PosControlProperty extends Property{
   
    tagname2: string;
    path2:string;
    public getCurrentValue(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (!tagnames.includes(this.path2))
            tagnames.push(this.path2);
        const tag2:tag = tagsModele.getTag(this.path2);
        if (tag==null || tag2==null) return 0;
        if ( (tag.tagvalue==='true' || tag.tagvalue==='1') && (tag2.tagvalue=='false' || tag2.tagvalue=='0'))
            return 1;
        if ( (tag2.tagvalue==='true' || tag2.tagvalue==='1') && (tag.tagvalue=='false' || tag.tagvalue=='0'))
            return 2;
        
    }
    public setClick():void{
        
        const tag:tag = tagsModele.getTag(this.path);
        const tag2:tag = tagsModele.getTag(this.path2);
        if (tag==null || tag2==null) return ;
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0",datetime:0});
        tagsModele.writeTagAction({token:"",name: tag2.path, tagvalue:"0", datetime:0});
    }

    public setClick1():void{
        
        const tag:tag = tagsModele.getTag(this.path);
        const tag2:tag = tagsModele.getTag(this.path2);
        if (tag==null || tag2==null) return ;
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0",datetime:0});
        tagsModele.writeTagAction({token:"",name: tag2.path, tagvalue:"1", datetime:0});
    }
    public setClick2():void{
        const tag:tag = tagsModele.getTag(this.path);
        const tag2:tag = tagsModele.getTag(this.path2);
        if (tag==null || tag2==null) return ;
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"1",datetime:0});
        tagsModele.writeTagAction({token:"",name: tag2.path, tagvalue:"0",datetime:0});
    }
                
}
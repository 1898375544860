import { SVGObject } from "../svgobject";

export class SVGPump2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Oil_Well_Pump">
            <path d="M33.383,93.581h16.441v14.077H33.383V93.581z" />
            <path fill="#595959" d="M49.574,107.658H75.25V75H49.574V107.658z" />
            <path fill="#333333" d="M84.347,107.658l-4.843-70.271h-2.026l4.392,70.271H84.347z" />
            <path fill="#666666" d="M9.234,84.347L0,81.869l4.729-20.945l9.234,2.139L9.234,84.347z" />
            <path fill="#4C4C4C" d="M53.828,75.089l-4.504-16.441l25.676-9.234v25.676H53.828z" />
            <path fill="#7F7F7F" d="M39.752,107.658l26.013-28.154l2.14,2.365l-23.423,25.789H39.752z" />
        </g>
        <g id="Group_Support">
            <path fill="#B2B2B2" d="M75,107.658V28.041h4.504v79.618l-0.213-0.25h5.056v5.092H0v-5.092h4.733l-0.004,0.25V75h28.153   l4.504,32.658l-0.125-0.25h37.681L75,107.658z" />
        </g>
        <g id="Group_Arms">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="47.7915" y1="60.9609" x2="49.6868" y2="59.4802">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M30.518,38.852l1.689-1.464l34.797,44.257l-1.801,1.463L30.518,38.852z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="53.4434" y1="54.1191" x2="55.3387" y2="52.6383">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M36.148,31.982l1.802-1.464l34.684,44.257l-1.801,1.463L36.148,31.982z" />
        </g>
        <g id="Group_Crank">
            <path fill="#7F7F7F" d="M14.718,83.605l-2.577-1.289l-1.436-2.182l-0.203-1.523l0.711-2.847l2.236-2.032l1.118-0.306l48.681,2.136   l-0.06,5.788L14.718,83.605z" />
        </g>
        <g id="Group_Pulley">
            <circle fill="#4C4C4C" cx="77.252" cy="32.658" r="4.617" />
            <circle fill="#4C4C4C" cx="35.022" cy="35.022" r="6.982" />
            <circle fill="#666666" cx="67.905" cy="77.252" r="6.982" />
            <radialGradient id="SVGID_3_" cx="35.1348" cy="35.1353" r="4.865" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.19" style="stop-color:#D9D9D9" />
                <stop offset="0.58" style="stop-color:#A8A8A8" />
                <stop offset="0.6" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </radialGradient>
            <circle fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.3" cx="35.135" cy="35.135" r="4.865" />
            <radialGradient id="SVGID_4_" cx="67.793" cy="77.252" r="4.1665" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.19" style="stop-color:#D9D9D9" />
                <stop offset="0.58" style="stop-color:#A8A8A8" />
                <stop offset="0.6" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </radialGradient>
            <circle fill="url(#SVGID_4_)" cx="67.793" cy="77.252" r="4.167" />
        </g>
        <g id="Group_Walking_Beam">
            <path fill="#999999" d="M95.945,16.441l-60.698,9.234l-5.715,13.485l71.255-13.485L95.945,16.441z" />
        </g>
        <g id="Group_HorseHead">
            <path fill="#666666" d="M110.022,40.315V23.311L100.788,0l-9.233,9.459l17.342,30.856H110.022z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="108.8965" y1="66.9482" x2="110.0225" y2="66.9482">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M108.896,40.315h1.126v53.266h-1.126V40.315z" />
        </g>
        <g id="Group_Plunger">
            <path fill="#E5E5E5" d="M108.221,104.955h2.478v0.9h-2.478V104.955z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,95.945h2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,102.027h2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,104.393h2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,104.955h2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,105.855h2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M108.221,110.022h2.478" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="108.2256" y1="103.0078" x2="110.7031" y2="103.0078">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M108.226,94.96h2.478v16.095h-2.478V94.96z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="107.6582" y1="94.7637" x2="111.2617" y2="94.7637">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M107.658,93.581h3.604v2.364h-3.604V93.581z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="106.4189" y1="103.21" x2="112.5" y2="103.21">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M106.419,102.027h6.081v2.365h-6.081V102.027z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="106.4189" y1="105.4053" x2="112.5" y2="105.4053">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M106.419,104.955h6.081v0.9h-6.081V104.955z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="106.4189" y1="111.2617" x2="112.5" y2="111.2617">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M108.221,112.5l-1.802-2.478h6.081l-1.802,2.478H108.221z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.16145833333333334, 214.0, 0.19, 0.3020833333333333, 177.0, 0.58, 0.6875, 79.0, 0.6, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.16145833333333334, 214.0, 0.19, 0.3020833333333333, 177.0, 0.58, 0.6875, 79.0, 0.6, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Oil_Well_Pump",[0.0, 0.6927083333333334, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Crank",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Pulley",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Walking_Beam",[0.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_HorseHead",[0.0, 0.7916666666666666, 0.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMiscpipe7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Gas_Pipe_90">
        <radialGradient id="SVGID_1_" cx="130.415" cy="325.0293" r="27.4649" gradientTransform="matrix(-2.8299 -0.2082 0.2065 -2.8288 375.9068 985.3555)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.09" style="stop-color:#5E6868" />
            <stop offset="0.2" style="stop-color:#909898" />
            <stop offset="0.42" style="stop-color:#FCFFFF" />
            <stop offset="0.6" style="stop-color:#FCFFFF" />
            <stop offset="0.86" style="stop-color:#878D8D" />
            <stop offset="0.95" style="stop-color:#5E6868" />
            <stop offset="1" style="stop-color:#5C6666" />
        </radialGradient>
        <path fill="url(#SVGID_1_)" d="M78.081,112.5C40.027,112.5,0.03,86.273,0.03,34.329l66.156-0.195  c1.383,9.139,5.157,11.344,11.684,11.934C78.637,63.481,78.081,112.5,78.081,112.5z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="73.6436" y1="120.6357" x2="6.4902" y2="120.6357" gradientTransform="matrix(-1 0 0 -1 73.6445 140.6777)">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.41" style="stop-color:#FCFFFF" />
            <stop offset="0.61" style="stop-color:#FCFFFF" />
            <stop offset="1" style="stop-color:#5C6666" />
        </linearGradient>
        <rect y="1.668" fill="url(#SVGID_2_)" width="67.153" height="36.749" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-19.5151" y1="28.2344" x2="-19.5151" y2="94.772" gradientTransform="matrix(-1 0 0 -1 73.6445 140.6777)">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.41" style="stop-color:#FCFFFF" />
            <stop offset="0.61" style="stop-color:#FCFFFF" />
            <stop offset="1" style="stop-color:#5C6666" />
        </linearGradient>
        <rect x="75.243" y="45.906" fill="url(#SVGID_3_)" width="35.833" height="66.538" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.09, 0.7760416666666666, 0.0, 0.2, 0.8385416666666666, 41.0, 0.42, 0.015625, 250.0, 0.6, 0.015625, 250.0, 0.86, 0.9166666666666666, 21.0, 0.95, 0.7760416666666666, 0.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank37 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 69.257 112.5" enable-background="new 0 0 69.257 112.5" xml:space="preserve">
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.1396" y1="109.1211" x2="5.4053" y2="109.1211">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.52" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M2.14,106.531h3.266v5.181H2.14V106.531z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="63.8511" y1="109.1211" x2="67.3423" y2="109.1211">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.52" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M63.851,106.531h3.491v5.181h-3.491V106.531z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="112.1055" x2="7.5449" y2="112.1055">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0,112.5h7.545v-0.788H0V112.5z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="61.8237" y1="112.1055" x2="69.2573" y2="112.1055">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.52" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M61.824,112.5h7.434v-0.788h-7.434V112.5z" />
        </g>
        <g id="Group_Support">
            <path fill="#4C4C4C" d="M10.811,2.703h47.635v5.856H10.811V2.703z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="1.4639" y1="100.1133" x2="67.7925" y2="100.1133">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M1.464,106.757l16.441,1.014h16.779h16.554l16.554-1.014V92.455H1.464V106.757z" />
        </g>
        <g id="Group_Tank_Top">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="34.6279" y1="3.1533" x2="34.6279" y2="11.2612">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M4.167,11.261H65.09V9.347c0,0-17.005-6.193-30.406-6.193c-14.246,0-30.518,6.193-30.518,6.193   V11.261z" />
            <path fill="#7F7F7F" d="M27.14,11.261h14.977V3.378c0,0-2.488-3.378-7.432-3.378c-4.806,0-7.545,3.378-7.545,3.378V11.261z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="4.1665" y1="51.8584" x2="65.0903" y2="51.8584">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M4.167,11.261H65.09v81.194H4.167V11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M4.167,92.455V11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.978,11.261v81.194" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.063,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M58.896,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.955,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M50.789,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.847,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.568,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.739,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M34.685,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M30.518,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.577,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.41,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.468,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.302,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.248,11.824v80.631" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M6.081,11.824v80.631" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="4.1665" y1="9.9102" x2="65.0903" y2="9.9102">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M4.167,11.261H65.09V8.559H4.167V11.261z" />
            <path fill="#626363" d="M4.617,11.261h2.027V12.5H4.617V11.261z" />
            <path fill="#626363" d="M60.135,11.261h2.026V12.5h-2.026V11.261z" />
            <path fill="#626363" d="M33.784,11.261h1.689V12.5h-1.689V11.261z" />
            <path fill="#626363" d="M47.072,11.261h1.689V12.5h-1.689V11.261z" />
            <path fill="#626363" d="M19.482,11.261h1.915V12.5h-1.915V11.261z" />
        </g>
        <g id="Group_Label">
            <path fill="#F0F0F0" d="M17.905,41.667h33.333V50H17.905V41.667z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,43.581h9.122" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,43.581h15.315" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.725,45.383H49.55" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M31.532,47.522h18.243" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M33.784,45.383h-14.64" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M27.59,47.522h-8.108" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
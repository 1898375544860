import { ObjectView } from "./object";
import { Scale} from "./scale";
import { Text3DView } from "./text3d";
import { Border3d } from "./border3d"
import { Cylinder } from "./cylinder"
import { Rectangle} from "./rectangle"
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import { Tank } from "./tank";
import { Value } from "../value";

export class VerticalTank extends ObjectView{
    text:string;
    color:string;
    fillcolor:string;
    type3d:number;
    fontsize:number;

    public drawObject():void{
      super.initObject();
      
      if(this.type3d != 0) this.type3d = 1

      let color:string = this.color;
      const lineprop = this.properties['color'];
      if (lineprop!=null){	
        const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
        if (linecolorproperty!=null) 
            color = linecolorproperty.getColor(this.tagnames, this.color)
      }	
      const color2 = ColorUtils.convertformat(color)

      let fillcolor = '0xffffff00';
        
      let fillheight = this.height
      let fillingproperty:FillingProperty
      const fillingprop = this.properties['filling']
      if (fillingprop != null){
          fillingproperty =Object.assign(new FillingProperty(), fillingprop);
          fillheight = fillingproperty.getHeight(this.tagnames, this.height)
      }

      fillcolor = this.fillcolor;
      const fillprop = this.properties['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }
      const fillcolor2 = ColorUtils.convertformat(fillcolor)

      const tank = new Tank()
      tank.width = this.width
      tank.height = this.height
      tank.fillcolor = color2
      tank.vertical = true
      tank.ratio = 5
      tank.type3d = this.type3d
      tank.initLayer(this.layer)
      tank.drawObject()
      this.node.add(tank.rotatednode)

      const border1 = new Border3d() 
      border1.width = this.width*42/100
      border1.height = this.height*62/80
      border1.posx = this.width*4/100
      border1.posy  =this.height*9/80
      border1.cornerradius = 10 ;
      border1.glass = this.type3d==0? true: false;
      border1.linewidth = this.height/50
      border1.color = ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
      border1.fillcolor = '0x000000'
      border1.fill = true;
      border1.initLayer(this.layer)
      border1.drawObject()
      this.node.add(border1.rotatednode)
                
      const border2 = new Border3d() 
      border2.width = this.width*8/20
      border2.height = this.height*5/24
      border2.posx = this.width*11/20
      border2.posy  =this.height/8
      border2.cornerradius = 10 ;
      border2.glass = this.type3d==0? true: false;
      border2.linewidth = this.height/50
      border2.color =  ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
      border2.fillcolor = '0x000000'
      border2.fill = true;
                
      border2.initLayer(this.layer)
      border2.drawObject()
      this.node.add(border2.rotatednode)
            

      if(this.type3d==0){
        const cylinder = new Cylinder()
        cylinder.width = this.width*8/20-this.height/50
        cylinder.height = fillheight*6/8
        cylinder.posx = this.width/20+this.height/100
        cylinder.posy =  (this.height*6/8 - fillheight*6/8)+this.height/8
        cylinder.properties = new  Map<string, string>()
        cylinder.linewidth = 0
        cylinder.color = color2
        cylinder.fillcolor = ColorUtils.convertbackformat(
          ColorUtils.darkColor(ColorUtils.convertformat(fillcolor2), 0.5))
        cylinder.fill = true
        cylinder.initLayer(this.layer)
        cylinder.drawObject()
        this.node.add(cylinder.rotatednode)
      }else{
        const cylinder = new Rectangle()
        cylinder.width = this.width*8/20-this.height/50
        cylinder.height = fillheight*6/8
        cylinder.posx = this.width/20+this.height/100
        cylinder.posy =  (this.height*6/8 - fillheight*6/8)+this.height/8
        cylinder.properties = new  Map<string, string>()
        cylinder.linewidth = 0
        cylinder.color = color2
        cylinder.fillcolor = fillcolor2
        cylinder.fill = true
        cylinder.initLayer(this.layer)
        cylinder.drawObject()
        this.node.add(cylinder.rotatednode)
      } 

      const scale = new Scale() 
      scale.width = this.width*8/20;
      scale.height = this.height*6/8
      scale.angle = 180
      scale.color = '0xffffff'
      scale.posx = this.width/20
      scale.posy = this.height/8
      scale.scale2=true;  
      scale.scale3=true;
      scale.scaleinterval1=2;
      scale.max = 100
      scale.min = 0
      scale.scale3 =false;
      scale.scaleinterval2 = 5;
      scale.sizemarkers1 = this.width/10
      scale.sizemarkers2 = this.width/20
      scale.linewidth = this.height/150
      scale.type = 0;

			if (scale.linewidth<1) scale.linewidth=1;
			
      scale.initLayer(this.layer)
      scale.drawObject()
      this.node.add(scale.rotatednode)
               
               
      const marker1 = new Text3DView() 
      marker1.width = this.width*6/20
      marker1.height = this.height/8
      marker1.text = fillingprop==null? '100' : fillingprop.maximumvalue;
      marker1.fonttype = null;
      marker1.fontsize = this.fontsize==0? this.height/17 : this.fontsize
      marker1.textplacement = 2
      marker1.textcolor = '0xffffff'
      marker1.useborder = false;
      marker1.bordercolor = null
      marker1.linewidth = 1
      marker1.fill = true
      marker1.fillcolor = "0x00000000";
      marker1.underline = false
      marker1.posx = this.width/20
      marker1.posy = this.height*9/80

      marker1.initLayer(this.layer)
      marker1.drawObject()
      this.node.add(marker1.rotatednode)

      const marker2 = new Text3DView() 
      marker2.width = this.width*6/20
      marker2.height = this.height/8
      marker2.text = fillingprop==null? '0' : fillingprop.minimumvalue;
      marker2.fonttype = null;
      marker2.fontsize = this.fontsize==0? this.height/17 : this.fontsize
      marker2.textplacement = 2
      marker2.textcolor = '0xffffff'
      marker2.useborder = false;
      marker2.bordercolor = null
      marker2.linewidth = 1
      marker2.fill = true
      marker2.fillcolor = '0x00000000';
      marker2.underline = false
      marker2.posx = this.width/20
      marker2.posy = this.height*30/40

      marker2.initLayer(this.layer)
      marker2.drawObject()
      this.node.add(marker2.rotatednode)

      const marker3 = new Text3DView() 
      marker3.width = this.width*6/20
      marker3.height = this.height/8
      marker3.text = fillingprop==null? '50' : 
              (fillingprop.maximumvalue - fillingprop.minimumvalue)/2+fillingprop.minimumvalue;
      marker3.fonttype = null;
      marker3.fontsize = this.fontsize==0? this.height/17 : this.fontsize
      marker3.textplacement = 2
      marker3.textcolor = '0xffffff'
      marker3.useborder = false;
      marker3.bordercolor = null
      marker3.linewidth = 1
      marker3.fill = true
      marker3.fillcolor = "0x00000000";
      marker3.underline = false
      marker3.posx = this.width/40
      marker3.posy = this.height*17/40

      marker3.initLayer(this.layer)
      marker3.drawObject()
      this.node.add(marker3.rotatednode)

      const border3 = new Border3d() 
      border3.width = this.width*42/100
      border3.height = this.height*62/80
      border3.posx = this.width*4/100
      border3.posy  =this.height*9/80
      border3.cornerradius = 10 ;
      border3.glass = this.type3d==0? true: false;
      border3.linewidth = this.height/50
      border3.color = ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
      border3.fillcolor = '0x000000'
      border3.fill = false;
      border3.initLayer(this.layer)
      border3.drawObject()
      this.node.add(border3.rotatednode)

      const label = new Text3DView() 
      label.width = this.width*8/20
      label.height = this.height*2/24
      label.text = this.text
      label.fonttype = null;
      label.fontsize = this.fontsize==0? this.height/15 : this.fontsize
      label.textplacement = 1
      label.textcolor = '0xffffff'
      label.useborder = false;
      label.bordercolor = null
      label.linewidth = 1
      label.fill = true
      label.fillcolor = "0x00000000";
      label.underline = false
      label.posx = this.width*11/20
      label.posy = this.height/7

      label.initLayer(this.layer)
      label.drawObject()
      this.node.add(label.rotatednode)

      const labelValue = new Text3DView() 
      labelValue.width = this.width*8/20
      labelValue.height = this.height*2/24
      labelValue.text = fillingproperty==null?'0':(+fillingproperty.getValue2(this.tagnames)).toFixed(1)
      labelValue.fonttype = null;
      labelValue.fontsize = this.fontsize==0? this.height/17 : this.fontsize
      labelValue.textplacement = 1
      labelValue.textcolor = '0xffffff'
      labelValue.useborder = false;
      labelValue.bordercolor = null
      labelValue.linewidth = 1
      labelValue.fill = true
      labelValue.fillcolor = "0x00000000";
      labelValue.underline = false
      labelValue.posx = this.width*11/20
      labelValue.posy = this.height*17/80

      labelValue.initLayer(this.layer)
      labelValue.drawObject()
      this.node.add(labelValue.rotatednode)
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      const fillingproperty: FillingProperty = this.properties==null ? null : this.properties['filling'];
      switch(field){
          case "color":
          case "fillcolor":
          case "text": {this[field] = value.value;this.rotatednode=null;break;}

          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

          case "fontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

          case "maximum": {if (fillingproperty!=null){fillingproperty.maximumvalue = value.value}   
                break;}
          case "minimum": {if (fillingproperty!=null){fillingproperty.minimumvalue = value.value}   
                break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      const fillingproperty: FillingProperty = this.properties==null ? null : this.properties['filling'];
      switch(field){
          case "color":
          case "fillcolor":
          case "text": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
          case "fontsize": {value.datatype=3; value.value = this[field];break}
          case "maximum": {if (fillingproperty!=null){
            value.datatype=6; value.value = fillingproperty.maximumvalue
            }
            break;}
          case "minimum": {if (fillingproperty!=null){
            value.datatype=6; value.value = fillingproperty.minimumvalue
            }
            break;} 
      }
      return value;
    }
    
}

import AuthModule from '../store/auth.module'
import TagsModule from '../store/tags.modele'
import {computed, defineComponent, ref} from 'vue'
import TagCard from './TagCard.vue'
import tag from '@/model/tag'
import exportFile from 'quasar/src/utils/export-file.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { useI18n } from 'vue-i18n';
import tagsModele from '../store/tags.modele'
//import sound from '../assets/way.mp3'
//import Container from './Container.vue'
export default defineComponent({
  components: { TagCard },
  
 setup() {
  /* */
    const q = useQuasar()
    const perrow =ref(6);
    const tags = ref([]);
    const groups =ref([]);
    const filtergroup =ref('');
    const togglegrid = ref(false);
    const perrowstring = ref()
    const {t,locale} = useI18n({useScope:'global'})
   // const event = ref(false)
    //const eventmessage = ref('')
    //const eventtagname = ref('')

    return {
      event:computed(()=>TagsModule.getEvent),
      eventmessage:computed(()=>TagsModule.getEventMessage),
      eventtagname:computed(()=>TagsModule.getEventTagName),
      perrow,
      t,
      locale,
      perrowstring,
      pagination: {
        rowsPerPage: 12// current rows per page being displayed
      },
      q,
      groups,
      filtergroup,
      togglegrid,
      tags,
       columns: [
        
        { name: 'description', label:t('tags.description'), field: 'description', align:'left' },
        { name: 'tagvalue', label: t('tags.tagsvalue'), field: 'tagvalue' },
        { name: 'unit', label: t('tags.unit'), field: 'unit'},
        { name: 'datetime', label: t('tags.dateandtime'), field: (row:tag)=> new Date(row.datetime).toLocaleString()}
      ],
      
    }
  },
  mounted(){
    tagsModele.setAllMessage(this.t('tags.all'))
    if (!AuthModule.isLoggedIn){
      this.locale = localStorage.getItem('lang')
      this.$router.push("/login")
    }
    else{
      this.locale = AuthModule.currentUser.locale
      if (this.locale!=null)
          localStorage.setItem('lang',this.locale)
        this.getAllTags();
        
    }
    this.perrow=localStorage.getItem('perrow');
    if (this.perrow==null || this.perrow=='')
          this.perrow='0'
    this.$q.lang.table.allRows = this.t('tags.all')
         
  },
  
  methods:{
 
isItReallyMobile () {
 // console.log("width="+this.q.screen.width)
  return this.q.screen.width <1400;
},
updateradio(){
  //console.log('value='+this.perrow);
  localStorage.setItem('perrow',this.perrow);
},
wrapCsvValue (val:string) {
  let formatted =  val

  formatted = formatted === void 0 || formatted === null
    ? '': String(formatted)
  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
},
exportTable () {
        // naive encoding to csv format
        const content = [this.columns.map(col => this.wrapCsvValue(col.label))].concat(
          this.tags.map(tag => this.columns.map(col => this.wrapCsvValue(
            typeof col.field === 'function'
              ? col.field(tag)
              : tag[ col.field === void 0 ? col.name : col.field ]
          )).join(','))
        ).join('\r\n')

        const status = exportFile(
          'Tags_'+new Date().toLocaleString()+'.csv',
          content,
          'text/csv'
        )
       
        if (status !== true) {
          this.q.notify({
            message: this.t('tags.browserdenied'),
            color: 'negative',
            icon: 'warning'
          })
        }
        else{
             this.q.notify({
            message: this.t('tags.browserdownloaded'),
            color: 'positive',
            icon: 'warning'
          })
        }
      },
    filterData(rows:[]) {
    if (this.filtergroup===this.t('tags.all')) return rows;
      return rows.filter(row =>
          (row['taggroup'] + '').toLowerCase().indexOf(this.filtergroup.toLowerCase()) !== -1)
      //return rows
    },
    async getAllTags(){
          if (AuthModule.currentUser!=null){
              //TagsModule.getAllTagsAction(AuthModule.currentUser.accessToken)
              console.log("INIT TAGS")
              TagsModule.initTags();
              console.log('GET CARDS')
              await TagsModule.getCardsAction();
              console.log('GET ALL TAGS')
              TagsModule.getAllTagsAction(AuthModule.currentUser.accessToken)
          }
             // this.event = TagsModule.getEvent;
             // this.eventmessage = TagsModule.getEventMessage;
             // this.eventtagname = TagsModule.getEventTagName;
            

              this.tags = TagsModule.getAllTags;
              this.groups = TagsModule.getGroups;
      },
    closeEventDialog(){
      TagsModule.removeTagEvent();
    }
      
  }
})

import { SVGObject } from "../svgobject";

export class SVGSegpipe11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 87.047 112.5" enable-background="new 0 0 87.047 112.5" xml:space="preserve">
        <g id="Group_Segmented_45_Bend">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="79.2075" y1="63.877" x2="29.2517" y2="40.5822" gradientTransform="matrix(-1 0 0 -1 87.0479 112.5)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M49.323,90.09l16.216-39.414L16.441,30.181L0,69.819   L49.323,90.09z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="55.6763" y1="97.335" x2="15.1309" y2="56.7896" gradientTransform="matrix(-1 0 0 -1 87.0479 112.5)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M87.047,40.439L56.753,70.875L16.172,30.366L46.538,0L87.047,40.439z" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="126.4126" y1="69.8281" x2="85.8672" y2="29.2827" gradientTransform="matrix(-0.7071 0.7071 -0.7071 -0.7071 138.7763 50.9918)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M57.253,69.506l0.101,42.942L0.015,112.5L0.014,69.556L57.253,69.506z" />
        </g>
        <g id="Group_Ring">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-3.2764" y1="130.1494" x2="-3.0173" y2="72.9034" gradientTransform="matrix(-0.7039 0.7103 -0.7103 -0.7039 136.0006 94.6605)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" points="45.387,1.195 46.32,0.265 86.813,40.722 85.911,41.631  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="38.0132" y1="130.3818" x2="38.2727" y2="73.0382" gradientTransform="matrix(-0.7039 0.7103 -0.7103 -0.7039 136.0006 94.6605)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="16.172,30.346 17.101,29.433 57.604,69.972 56.74,70.875  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4725" x1="57.772" y1="69.824" x2="17.286" y2="29.268" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4725" x1="87.047" y1="40.557" x2="46.563" y2="0" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="66.1851" y1="160.6328" x2="66.4441" y2="103.3873" gradientTransform="matrix(0.0045 1 -1 0.0045 160.3812 3.6335)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_6_)" points="0.045,71.215 0.048,69.896 57.288,69.871 57.292,71.152  " />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="107.4761" y1="160.8633" x2="107.7356" y2="103.5211" gradientTransform="matrix(0.0045 1 -1 0.0045 160.3812 3.6335)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="0,112.485 0.011,111.183 57.316,111.208 57.344,112.458  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4725" x1="57.332" y1="110.985" x2="0.025" y2="110.936" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4725" x1="57.336" y1="69.589" x2="0.032" y2="69.538" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.661453333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}
import user from '../model/user'
import session from '../model/session'
import response from '../model/response'
import { VuexModule,getModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/services/auth.service';
import store from './index'
import { format } from 'date-fns'

const storedUser = localStorage.getItem('user');

@Module({ name:"Auth", dynamic:true, store})
class Auth extends VuexModule {
 
  public status =(storedUser!=null && storedUser!='null') ? { loggedIn: true } : { loggedIn: false };
  public user = storedUser ? JSON.parse(storedUser) : null;
  public users:user[] =[] 
  public sessions:session[]=[]

  @Mutation
public addUserMutation(user:user){
        user.begindatestring = format(user.begindate, "yyyy-MM-dd HH:mm")
        user.enddatestring = format(user.enddate, "yyyy-MM-dd HH:mm")
        this.users.push(user);
        this.users = this.users.sort((a, b) => (a.id < b.id ? -1 : 1))
  }
  @Mutation
public addSessionMutation(session:session){
        session.starttimestring = format(session.starttime, "yyyy-MM-dd HH:mm")
        this.sessions.push(session);
        this.sessions = this.sessions.sort((a, b) => (a.username < b.username ? -1 : 1))
  }
  @Mutation
  public removeUserMutation(user:user){
    for (let i = 0; i < this.users.length; i++) {
      const u = this.users[i];
      if (u.id==user.id)
        this.users.splice(i,1)
    }   
  }
  @Mutation
  public loginSuccess(user: user): void {
    this.status.loggedIn = true;
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
    
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public updateSuccess(user: user): void {
    this.status.loggedIn = true;
    this.user.email = user.email;
  }

  @Mutation
  public updateHistorySuccess(user:user): void {
    this.status.loggedIn = true;
    this.user.savehistory = user.savehistory;
    this.user.storageperiod = user.storageperiod;
    localStorage.setItem('user', JSON.stringify(this.user));
  }
  @Mutation
  public updateScreensSuccess(user:user): void {
    this.status.loggedIn = true;
    this.user.savescreens = user.savescreens;
    this.user.mainscreen = user.mainscreen;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  @Mutation
  public updateLocaleSuccess(user:user): void {
    this.status.loggedIn = true;
    this.user.locale = user.locale;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  @Mutation
  public updateFailure(): void {
    this.status.loggedIn = true;
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = null;
    localStorage.setItem('user', null);
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false;
  }
  @Mutation
  public clearAllUsersMutation(){
    this.users.splice(0);
  }
  @Mutation
  public clearAllSessionsMutation(){
    this.sessions.splice(0);
  }

  @Action({ rawError: true })
  login(data: user):Promise<response> { 
    console.log("login ",data)
      return new Promise ((resolve, rejected)=>{      
        AuthService.login(data.username, data.password, (data:string) => {
              console.log(data);
              const response:response = JSON.parse(data);
            if (response.statusCodeValue==200){
                const user2 = response.body as user;
                this.loginSuccess(user2);        
               AuthService.setToken(user2.accessToken);     
            }
            else
              this.loginFailure();
               resolve(response);
          },
         (error) => {    
               this.loginFailure();
               rejected(error);
         });
      })
    }
    @Action({ rawError: true })
    authenticate(data: user):Promise<response> { 
        return new Promise ((resolve, rejected)=>{      
          AuthService.auth(data.username, data.password, (data:string) => {
                console.log(data);
                const response:response = JSON.parse(data);
              /*if (response.statusCodeValue==200){
                  const user2 = response.body as user;
                  this.loginSuccess(user2);        
                 AuthService.setToken(user2.accessToken);     
              }
              else
                this.loginFailure();*/
                 resolve(response);
            },
           (error) => {    
                 this.loginFailure();
                 rejected(error);
           });
        })
      }
  @Action({ rawError: true })
  reset(data: user):Promise<response> { 
      return new Promise ((resolve, rejected)=>{     
        AuthService.reset(data.email, (data:string) => {
              console.log(data);
              const response:response = JSON.parse(data);
               resolve(response);
          },
         (error) => {           
               rejected(error);
         });
      }) 
    }
  @Action({ rawError: true })
  clientdisconnect():Promise<response> {
      console.log('clientdisconnect')
      const token =this.user==null?"":this.user.accessToken;
      const uuid = localStorage.getItem("uuid");
      console.log('token='+token)
      console.log('uid='+uuid)
      return new Promise((resolve, rejected)=>{
        AuthService.clientdisconnect(token, uuid,(data:string) => {
          console.log(data);
          const response:response = JSON.parse(data);
        if (response.statusCodeValue==200)
       
        resolve(response);
      },
     (error) => {    
           rejected(error);
     });
      })
    }
  @Action({ rawError: true })
  signout():void {
      
      //this.logout();
      this.clientdisconnect();
      this.logout();//it after clientdisconnect because user is needed
     
    }

  @Action({ rawError: true })
  register(data: user): Promise<response> {
     return new Promise ((resolve, rejected)=>{
        AuthService.register(data.username, data.email, data.password, data.userpassword, (data:string) => {
              console.log(data);
              const response:response = JSON.parse(data);
            if (response.statusCodeValue==400)
              this.registerFailure();
              //this.context.commit('registerFailure');
            else
               //this.context.commit('registerSuccess');
               this.registerSuccess();
            resolve(response);
          },
         (error) => {    
               //this.context.commit('registerFailure');
               this.registerFailure();
               rejected(error);
         });
      })
    }
  @Action({ rawError: true })
  updatehistory(user:user):Promise<response> {
     
      return new Promise ((resolve, rejected)=>{
        
        AuthService.updatehistorysettings(this.currentUser.accessToken, user.savehistory, user.storageperiod,(data) => {
              console.log(data);
              const response = JSON.parse(data);
            if (response.statusCodeValue==200){
             
              this.updateHistorySuccess(user)
            }
            else
              this.updateFailure()
            resolve(response);
          },
         (error) => {    
              this.updateFailure()
               rejected(error);
         });
    })
  }
  @Action({ rawError: true })
  updatescreens(user:user):Promise<response> {
    
    return new Promise ((resolve, rejected)=>{
      
      AuthService.updatescreenssettings(this.currentUser.accessToken, user.savescreens, user.mainscreen,(data) => {
            console.log(data);
            const response = JSON.parse(data);
          if (response.statusCodeValue==200){
           
            this.updateScreensSuccess(user)
          }
          else
            this.updateFailure()
          resolve(response);
        },
       (error) => {    
            this.updateFailure()
             rejected(error);
       });
  })
}

@Action({ rawError: true })
updatelocale(user:user):Promise<response> {
  
  return new Promise ((resolve, rejected)=>{
    
    AuthService.updatelocalesettings(this.currentUser.accessToken, user.locale,(data) => {
          console.log(data);
          const response = JSON.parse(data);
        if (response.statusCodeValue==200){
         
          this.updateLocaleSuccess(user)
        }
        else
          this.updateFailure()
        resolve(response);
      },
     (error) => {    
          this.updateFailure()
           rejected(error);
     });
})
}

  @Action({ rawError: true })
  update(user:user):Promise<response> {
      return new Promise ((resolve, rejected)=>{
        AuthService.update(user.id, user.username, user.email, user.password, user.userpassword, (data) => {
              console.log(data);
              const response = JSON.parse(data);
            if (response.statusCodeValue==200)
              this.updateSuccess(user)
            else
              this.updateFailure()
            resolve(response);
          },
         (error) => {    
              this.updateFailure()
               rejected(error);
         });
    })
  }
  @Action({ rawError: true })
  updatetariff(user:user):Promise<response> {
      return new Promise ((resolve, rejected)=>{
        AuthService.updatetariff(this.currentUser.accessToken,user.id, user.tariff, (data) => {
              console.log(data);
              const response = JSON.parse(data);             
            resolve(response);
          },
         (error) => {    
               rejected(error);
         });
    })
  }
  @Action({ rawError: true })
  updateenddate(user:user):Promise<response> {
    return new Promise ((resolve, rejected)=>{
      AuthService.updateenddate(this.currentUser.accessToken, user.id, user.enddate, (data) => {
            console.log(data);
            const response = JSON.parse(data);      
          resolve(response);
        },
       (error) => {    
             rejected(error);
       });
  })
}

@Action({ rawError: true })
updateallenddate(enddate:number):Promise<response> {
  return new Promise ((resolve, rejected)=>{
    AuthService.updateallenddate(this.currentUser.accessToken, enddate, (data) => {
          console.log(data);
          const response = JSON.parse(data);      
        resolve(response);
      },
     (error) => {    
           rejected(error);
     });
})
}

@Action({ rawError: true })
  deleteuser(user:user){
    console.log(user.id)    
    console.log(this.currentUser)
      AuthService.deleteuser(this.currentUser.accessToken,user.id, (data) => {
            console.log(data);
            const response = JSON.parse(data);   
            if (response.statusCodeValue==200)
            this.removeUserMutation(user)            
        },
       (error) => {    
          console.log(error)
       });  
}

  @Action({ rawError: true })
public getAllUsersAction(){
    this.clearAllUsersMutation();
    if (!this.currentUser) return;
    AuthService.getallusers(this.currentUser.accessToken,(data:string)=>{
       console.log(data)
        const user:user = JSON.parse(data);   
        console.log('user='+user);   
        this.addUserMutation(user);       
    },(data:string)=>{
      console.log(data);
      this.clearAllUsersMutation();
    });
}
@Action({ rawError: true })
public getAllSessionsAction(){
    this.clearAllSessionsMutation();
    if (!this.currentUser) return;
    AuthService.getallsessions(this.currentUser.accessToken,(data:string)=>{
       console.log(data)
        const session:session = JSON.parse(data);   
        console.log('session='+session);   
        this.addSessionMutation(session);       
    },(data:string)=>{
      console.log(data);
      this.clearAllSessionsMutation();
    });
} 
  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
  get currentUser():user {
    return this.user;
  }
  get getAllUsers():user[] {
    return this.users;
  }
  get getAllSessions():session[]{
    return this.sessions;
  }
}

export default getModule(Auth);
import { SVGObject } from "../svgobject";

export class SVGFood1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 75.34 112.5" enable-background="new 0 0 75.34 112.5" xml:space="preserve">
        <g id="Group_Emulsifier">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.2" d="M17.907,73.086v-4.955l8.333-4.73h33.559l8.333,4.73v4.955H17.907z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="23.7632" y1="67.3418" x2="62.2759" y2="67.3418">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.2" d="M23.763,74.887v-6.08l2.477-1.803v-4.729h-2.477v-2.479h38.513   v2.254l-2.477,0.225v4.729l2.477,1.803v6.08" />
            <path fill="#4D4D4D" d="M26.24,62.275h33.559v4.729H26.24V62.275z" />
            <path fill="none" stroke="#666666" stroke-width="0.2" d="M25.114,68.131h37.162" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M30.97,67.004l-2.252-9.684" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M55.069,67.004l2.252-9.684" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.177,67.004l-1.126-9.684" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M47.862,67.004l1.126-9.684" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M26.24,67.004l-4.73-9.684" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M59.799,67.004l4.729-9.684" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="23.7368" y1="82.417" x2="62.3423" y2="82.417">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M62.342,74.251c0,9.02-8.641,16.332-19.305,16.332c-10.658,0-19.301-7.313-19.301-16.332" />
        </g>
        <g id="Group_Turbo_Chargers">
            <path fill="#737373" d="M53.903,41.182c0,5.787-4.688,9.336-5.886,14.986c5.215-3.123,8.497-8.976,9.146-15.241" />
            <path fill="#737373" d="M32.11,41.349c0,5.787,4.688,9.335,5.886,14.986c-5.215-3.122-8.497-8.976-9.146-15.241" />
            <path fill="#808080" stroke="#4C4C4C" stroke-width="0.2" d="M51.759,45.491v2.261H34.281v-2.261H51.759z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="39.5278" y1="43.5811" x2="46.7358" y2="43.5811">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M46.736,41.779v3.604h-7.208v-3.604H46.736z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="41.7808" y1="52.5903" x2="44.2583" y2="52.5903">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M44.258,47.86v9.46h-2.478v-9.46H44.258z" />
            <path fill="#808080" stroke="#4C4C4C" stroke-width="0.2" d="M48.988,48.986v2.478H37.051v-2.478H48.988z" />
        </g>
        <g id="Group_Support_Structures">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.2" d="M21.51,95.607h43.018v7.208H21.51V95.607z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="81.9839" y1="104.7305" x2="56.7587" y2="79.5053">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="0.6" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.2" d="M64.528,71.734h9.686V112.5h-9.686V71.734" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="29.2808" y1="104.7305" x2="4.0556" y2="79.5053">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="0.6" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.2" d="M11.826,71.734h9.685V112.5h-9.685V71.734" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="15.4292" y1="56.1938" x2="17.9067" y2="56.1938">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M17.907,40.653v31.081h-2.478V40.653H17.907" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="68.1323" y1="56.1938" x2="70.6099" y2="56.1938">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M70.61,40.653v31.081h-2.478V40.653H70.61" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.2" d="M10.7,111.374h11.937v1.126H10.7V111.374z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.2" d="M63.403,111.374h11.937v1.126H63.403V111.374z" />
        </g>
        <g id="Group_Holder">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="44.0679" y1="44.3545" x2="41.9698" y2="27.267">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="0.6" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.2" d="M11.826,30.968h62.388v9.685H11.826V30.968" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M23.763,38.176h38.513" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="23.7114" y1="35.8027" x2="62.312" y2="35.8027">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.2" d="M23.711,38.17c0-2.615,8.642-4.735,19.301-4.735   s19.3,2.12,19.3,4.735" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="23.7627" y1="39.6396" x2="62.2759" y2="39.6396">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="23.763" y="37.5" fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.2" width="38.513" height="4.279" />
        </g>
        <g id="Group_Horizontal_Rotor">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="35.2495" y1="28.7163" x2="35.2495" y2="23.9863">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.2" d="M38.177,28.716h-5.856v-4.73h5.856V28.716" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="22.6367" y1="30.9683" x2="22.6367" y2="21.5088">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.49" style="stop-color:#A4A4A4" />
                <stop offset="0.64" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M32.321,30.968H12.952v-9.459h19.369V30.968z" />
            <path fill="#4C4C4C" d="M4.844,28.716v-7.208h7.208v7.208H4.844z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="6.4766" y1="29.8423" x2="6.4766" y2="21.5088">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.2" d="M0.001,21.509h12.951v8.333H0.001V21.509" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M12.051,27.815H4.844v-1.126h7.208v-1.126H4.844v-0.901h7.208v-1.126   H4.844v-0.901h7.208" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M4.844,29.842v-8.333" />
        </g>
        <g id="Group_Vertical_Rotor">
            <path fill="#4C4C4C" d="M39.528,4.842h8.334v6.982h-8.334V4.842z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="38.1772" y1="15.4844" x2="47.8618" y2="15.4844">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M47.862,0v30.968h-9.685V0H47.862" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M40.655,11.824V4.842h1.126v6.982h1.352V4.842h1.126v6.982h1.126V4.842   h1.126v6.982" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.177,4.842h9.685" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="37.0513" y1="22.0723" x2="48.9878" y2="22.0723">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.1" d="M48.988,21.509v1.126H37.051v-1.126H48.988" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="37.0513" y1="13.7949" x2="48.9878" y2="13.7949">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.1" d="M48.988,13.176v1.239H37.051v-1.239H48.988" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.5989583333333334, 0.0, 0.5, 0.8072916666666666, 49.0, 0.6, 0.453125, 139.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.5989583333333334, 0.0, 0.5, 0.8072916666666666, 49.0, 0.6, 0.453125, 139.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.5989583333333334, 0.0, 0.5, 0.8072916666666666, 49.0, 0.6, 0.453125, 139.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.49, 0.71875, 71.0, 0.64, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Emulsifier",[0.0, 0.9895833333333334, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Turbo_Chargers",[0.0, 0.8958333333333334, 0.0, 1.0, 0.8958333333333334, 0.0, 2.0, 1.0, 0.0, 3.0, 1.0, 0.0]);
		this.fillGradient(this.fills,"Group_Support_Structures",[0.0, 0.8072916666666666, 49.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Vertical_Rotor",[0.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
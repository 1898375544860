import { SVGObject } from "../svgobject";

export class SVGPaper8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.086" enable-background="new 0 0 112.5 98.086" xml:space="preserve">
        <g id="Group_Body">
            <path fill="#336666" d="M62.162,28.717h21.51v19.031h-21.51V28.717z" />
            <path fill="#003333" d="M21.509,40.541h35.924v45.607H21.509V40.541z" />
            <path fill="#194C4C" d="M47.748,47.748h60.022v38.4H47.748V47.748z" />
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M7.095,57.432l-4.617-7.207h14.302v7.207H7.095z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,47.748h14.302v2.478H2.478V47.748z" />
            <path fill="#003333" d="M64.64,19.032h4.729v2.478H64.64V19.032z" />
            <path fill="#003333" d="M50.225,19.032h4.73v2.478h-4.73V19.032z" />
        </g>
        <g id="Group_Motors">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="54.9546" y1="55.1062" x2="54.9546" y2="83.823">
                <stop offset="0" style="stop-color:#467F7F" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#467F7F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M43.018,54.955v9.685h23.875v-9.685h2.477v2.478h33.446v-2.478h2.478v28.717h-2.478v-2.478H69.369   v2.478h-2.477v-9.686H43.018v9.686h-2.478v-2.478H7.095v2.478H4.617V54.955h2.478v2.478h33.446v-2.478H43.018z" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M102.815,57.432v23.762" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M7.095,81.194V57.432" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M40.541,57.432v23.762" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M69.369,81.194V57.432" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M66.893,73.986v-9.347" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M43.018,64.639v9.347" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M43.018,54.955v9.685h23.875v-9.685h2.477v2.478h33.446v-2.478h2.478   v28.717h-2.478v-2.478H69.369v2.478h-2.477v-9.686H43.018v9.686h-2.478v-2.478H7.095v2.478H4.617V54.955h2.478v2.478h33.446v-2.478   H43.018" />
        </g>
        <g id="Group_Frame">
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,78.941h42.793v7.207H2.478V78.941z" />
        </g>
        <g id="Group_MainPipe">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="75.4512" y1="-0.1755" x2="75.4512" y2="18.8567">
                <stop offset="0" style="stop-color:#467F7F" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#467F7F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M45.271,0H112.5v19.032H45.271l-6.87-4.73V4.617L45.271,0z" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M45.271,19.032V0" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M64.64,19.032V0" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M83.672,19.032V0" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M102.815,19.032V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M45.271,0H112.5v19.032H45.271l-6.87-4.73V4.617L45.271,0" />
        </g>
        <g id="Group_Base">
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M0,98.086V86.148h109.91v11.938H0z" />
            <path fill="none" stroke="#998C73" stroke-width="0.25" d="M0,90.879h109.91" />
            <path fill="#D9CCB3" stroke="#4C4C4C" stroke-width="0.25" d="M64.64,86.148v-7.207h7.207v2.253h11.825v-2.253h7.207v7.207H64.64z" />
        </g>
        <g id="Group_BigPipes">
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,0h9.347v47.748H2.478V0z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M86.148,19.032h9.459v38.4h-9.459V19.032z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M38.401,4.617h-7.433l-6.982,7.207v45.608h9.459V16.779l2.478-2.478   h2.478V4.617z" />
        </g>
        <g id="Group_Dividers">
            <path fill="#336666" d="M86.148,48.648h9.459v1.577h-9.459V48.648z" />
            <path fill="#336666" d="M86.148,43.694h9.459v3.378h-9.459V43.694z" />
            <path fill="#336666" d="M86.148,40.541h9.459v1.576h-9.459V40.541z" />
            <path fill="#336666" d="M23.986,48.648h9.459v1.577h-9.459V48.648z" />
            <path fill="#336666" d="M23.986,43.694h9.459v3.378h-9.459V43.694z" />
            <path fill="#336666" d="M23.986,40.541h9.459v1.576h-9.459V40.541z" />
            <path fill="#336666" d="M2.478,41.441h9.347v1.577H2.478V41.441z" />
            <path fill="#336666" d="M2.478,36.599h9.347v3.266H2.478V36.599z" />
            <path fill="#336666" d="M2.478,33.446h9.347v1.576H2.478V33.446z" />
        </g>
        <g id="Group_SmallPipes">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="19.0698" y1="6.6907" x2="19.0698" y2="11.9841">
                <stop offset="0" style="stop-color:#669999" />
                <stop offset="0.5" style="stop-color:#CCFFFF" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M28.568,7.094l-4.582,4.729h-9.685L9.572,7.094H28.568z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="64.6396" y1="50.2253" x2="69.3691" y2="50.2253">
                <stop offset="0" style="stop-color:#669999" />
                <stop offset="0.5" style="stop-color:#CCFFFF" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M64.64,21.51h4.729v57.432H64.64V21.51z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="9.5723" y1="43.0178" x2="14.3018" y2="43.0178">
                <stop offset="0" style="stop-color:#669999" />
                <stop offset="0.5" style="stop-color:#CCFFFF" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M9.572,7.094l4.729,4.729v67.117H9.572V7.094z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="50.2251" y1="53.8289" x2="54.9551" y2="53.8289">
                <stop offset="0" style="stop-color:#669999" />
                <stop offset="0.5" style="stop-color:#CCFFFF" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M50.225,21.51h4.73v64.639h-4.73V21.51z" />
            <circle fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="52.59" cy="81.306" r="2.365" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.64,28.717h4.729" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M50.225,59.684h4.73" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.302,54.955H9.572" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.509,11.824V7.094" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.572,7.094h18.996l-4.582,4.729h-9.685v67.117H9.572V7.094z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8385416666666666, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8385416666666666, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9375, 15.0, 0.5, 0.140625, 219.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.9375, 15.0, 0.5, 0.140625, 219.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.9375, 15.0, 0.5, 0.140625, 219.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.9375, 15.0, 0.5, 0.140625, 219.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.6614583333333334, 0.0, 1.0, 0.2604166666666667, 0.0, 2.0, 0.4583333333333333, 0.0, 3.0, 0.6614583333333334, 0.0, 4.0, 0.5364583333333334, 117.0, 5.0, 0.2604166666666667, 0.0, 6.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.23958333333333334, 193.0, 1.0, 0.4375, 143.0]);
		this.fillGradient(this.fills,"Group_BigPipes",[0.0, 0.9375, 15.0, 1.0, 0.9375, 15.0, 2.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Dividers",[0.0, 0.6614583333333334, 0.0, 1.0, 0.6614583333333334, 0.0, 2.0, 0.6614583333333334, 0.0, 3.0, 0.6614583333333334, 0.0, 4.0, 0.6614583333333334, 0.0, 5.0, 0.6614583333333334, 0.0, 6.0, 0.6614583333333334, 0.0, 7.0, 0.6614583333333334, 0.0, 8.0, 0.6614583333333334, 0.0]);
	
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGEnergy3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 131.25 107.467" enable-background="new 0 0 131.25 107.467" xml:space="preserve">
        <g id="Group_Pole">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M50.976,57.543v49.924h18.393V57.543H50.976z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M61.092,91.571v-9.854l1.708-0.92h3.153l1.708,0.92l-0.132,9.854   c0,0-0.844,0.679-1.182,0.788c-0.938,0.304-2.986,0.111-3.941-0.131C62.05,92.138,61.092,91.571,61.092,91.571z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M61.092,82.374c0,0-0.079-0.513,0-0.657   c0.232-0.426,1.238-0.801,1.708-0.92c0.764-0.193,2.397-0.223,3.153,0c0.465,0.137,1.518,0.474,1.708,0.92   c0.065,0.154-0.132,0.657-0.132,0.657s-0.851,0.814-1.213,0.938c-0.897,0.307-2.9,0.319-3.794,0   C62.12,83.169,61.092,82.374,61.092,82.374z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M63.063,75.411h2.627v6.438c0,0-0.526,0.394-1.313,0.394   c-0.852,0-1.183-0.394-1.183-0.394L63.063,75.411z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M66.61,63.718v16.923l1.468,0.702l-0.154,8.782l-0.656,0.657v16.685   h6.174V87.366l1.445-1.445V75.542l-1.445-0.657V58.2L66.61,63.718z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M50.976,57.543v49.924h-1.182V57.543H50.976z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.078,81.343c0,0,1.843-0.825,2.473-1.146   c0.754-0.383,2.026-1.501,2.551-2.164c0.485-0.613,1.785-2.491,1.785-2.491" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M66.61,80.404c0,0,2.217-0.787,2.89-1.183   c0.543-0.319,1.548-1.107,1.971-1.576c0.568-0.63,1.971-2.76,1.971-2.76" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.267,90.782c0,0,1.977-0.81,2.496-1.052   c0.506-0.236,1.502-0.748,1.971-1.051c0.452-0.292,1.707-1.313,1.707-1.313" />
        </g>
        <g id="Group_OuterGear">
            <path fill="#A6D940" stroke="#669933" stroke-width="0.25" d="M58.333,69.63l-1.971-1.183l0.394,1.84l-2.496-0.92l1.051,1.708   l-2.365-0.263l1.051,1.445l-2.102,0.263l1.708,0.788l-1.577,1.051h1.839l-1.445,1.314l2.102-0.132l-1.183,1.313l2.234-0.394   l-0.263,1.576l1.971-1.051l0.263,1.839l1.971-1.444l0.92,1.444l1.182-1.576l1.971,1.313l-0.131-2.102l2.496,1.051l-0.919-1.708   l2.759,0.395l-1.445-1.577l2.365-0.131l-2.103-1.051l2.233-0.657l-2.233-0.395l1.708-1.182l-2.628-0.132l0.92-1.313l-2.233,0.656   l0.394-1.707L62.8,70.156l-0.131-1.839l-1.971,1.445l-1.445-1.971L58.333,69.63z" />
            <ellipse fill="#82E800" cx="60.304" cy="73.309" rx="1.971" ry="1.183" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="50.9756" y1="66.7356" x2="55.2437" y2="66.7356">
                <stop offset="0" style="stop-color:#669933" />
                <stop offset="0.22" style="stop-color:#669933" />
                <stop offset="0.23" style="stop-color:#689B33" />
                <stop offset="0.4" style="stop-color:#8BBE33" />
                <stop offset="0.5" style="stop-color:#99CC33" />
                <stop offset="0.8" style="stop-color:#669933" />
                <stop offset="0.99" style="stop-color:#669933" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M51.079,67.791c0,0-0.233-1.536,0-1.971   c0.168-0.313,1.183-0.788,1.183-0.788h1.971c0,0,0.793,0.513,0.919,0.788c0.206,0.448,0,1.971,0,1.971s-0.562,0.456-0.788,0.525   c-0.566,0.172-1.793,0.152-2.365,0C51.743,68.248,51.079,67.791,51.079,67.791z" />
            <path fill="#99CC33" stroke="#4C4C4C" stroke-width="0.25" d="M51.079,66.346c-0.055-0.12-0.069-0.414,0-0.525   c0.188-0.302,1.183-0.788,1.183-0.788h1.971c0,0,0.776,0.521,0.919,0.788c0.062,0.115,0.05,0.403,0,0.525   c-0.096,0.236-0.562,0.578-0.788,0.647c-0.566,0.172-1.793,0.152-2.365,0C51.743,66.925,51.195,66.602,51.079,66.346z" />
            <path fill="#669933" stroke="#4C4C4C" stroke-width="0.25" d="M51.764,62.273l2.365,1.052v2.496c0,0-0.472,0.394-1.183,0.394   c-0.48,0-1.051-0.394-1.051-0.394L51.764,62.273z" />
        </g>
        <g id="Group_Blades">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="87.6987" y1="48.4607" x2="89.4507" y2="35.3211">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M47.823,27.196l81.585,16.027v12.744l-46.244-6.7L47.823,27.196z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="25.7891" y1="15.7683" x2="40.2236" y2="10.653">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M19.445,0l3.285,7.62l4.335,5.518l4.861,7.488l3.679,8.277l2.102,0.656l1.445-0.788l-0.525-5.124   l0.394-3.284l1.182-4.861l-0.131-2.759l-1.182-8.276L37.838,0H19.445z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="26.4126" y1="76.3069" x2="4.2095" y2="60.2055">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M3.548,106.022l18.13-21.939l6.438-10.642l3.547-7.62l0.657-5.781l0.525-6.306l1.445-7.883   l2.365-6.438l-0.395-2.233l-1.051-0.788l-1.708,0.788l-2.627,3.941l-5.912,4.599l-2.102,2.102L0.001,82.768l1.708,4.467   l1.445,4.599l0.394,3.416l0.131,6.438L3.548,106.022z" />
        </g>
        <g id="Group_Frame">
            <path fill="#B2B2CC" stroke="#4C4C4C" stroke-width="0.25" d="M38.29,45.978c-3.057-2.612-9.893-6.916-13.006-9.459   c-2.985-2.438-10.174-8.229-8.277-13.007c4.647-11.705,19.66-3.229,25.225-0.263c14.312,7.63,43.217,22.534,56.624,31.662   c1.545,1.051,4.416,3.454,5.781,4.729c1.053,0.983,3.111,3.026,3.941,4.204c0.744,1.057,1.951,3.374,2.365,4.599   c0.361,1.075,0.785,3.332,0.787,4.467c0.004,1.166-0.313,3.534-0.787,4.599c-0.41,0.918-1.74,2.491-2.496,3.152   c-0.777,0.678-2.574,1.761-3.547,2.103c-1.314,0.463-4.127,0.713-5.52,0.788c-1.902,0.103-5.73-0.003-7.619-0.263   c-1.373-0.188-4.1-0.802-5.387-1.313c-8.395-3.335-23.896-12.842-31.399-17.868c-2.216-1.484-6.6-4.606-8.408-6.568   c-1.944-2.11-4.494-7.366-6.306-9.591C39.821,47.408,38.82,46.43,38.29,45.978z" />
            <path fill="#7F7F99" d="M30.708,40.007c0,0-3.299-2.133-6.591-4.94s-6.575-6.287-6.345-9.501c0.121-1.682,1.148-3.157,1.673-2.706   c0.229,0.198,0.476,3.437,3.285,4.992c2.443,1.353,8.327,3.062,8.327,3.062c-1.243,1.964-1.434,3.496-1.543,4.874   C29.29,38.603,30.708,40.007,30.708,40.007z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M32.546,41.427c-1.024-0.539-4.65-4.378,0.431-12.787   c4.763-7.883,10.532-4.545,11.356-3.074c0.749,1.337,2.93,3.974-1.5,11.042C38.493,43.534,34.406,42.406,32.546,41.427z" />
            <path fill="#CCCCFF" stroke="#4C4C4C" stroke-width="0.25" d="M98.797,55.047c0,0,19.467,11.34,10.772,24.896   c-6.278,9.79-23.254,2.168-23.254,2.168L54.917,64.243l-8.408-6.568l17.736,9.722l24.371,13.068c0,0,9.263,4.686,13.991-1.638   C109.843,69.151,98.797,55.047,98.797,55.047z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M32.283,41.253c0,0-0.8-4.748,3.314-10.453   c4.106-5.693,8.536-5.559,8.536-5.559" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M81.718,57.15l13.795,6.963v2.759l-13.795-6.963V57.15z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M73.573,71.864l13.532,7.883l2.232-1.576l-13.4-7.883L73.573,71.864z   " />
        </g>
        <g id="Group_Pipe2">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="73.5151" y1="65.4016" x2="75.6894" y2="61.4544">
                <stop offset="0" style="stop-color:#339966" />
                <stop offset="0.25" style="stop-color:#006633" />
                <stop offset="0.28" style="stop-color:#117340" />
                <stop offset="0.45" style="stop-color:#60AE7B" />
                <stop offset="0.59" style="stop-color:#9ADAA7" />
                <stop offset="0.7" style="stop-color:#BEF5C2" />
                <stop offset="0.75" style="stop-color:#CCFFCC" />
                <stop offset="1" style="stop-color:#339966" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M97.089,77.645L50.976,52.42l-0.263-1.052l0.394-1.444l0.92-0.789l1.182-0.131l46.376,25.225   L97.089,77.645z" />
        </g>
        <g id="Group_InnerGear">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M72.522,67.397l-7.357-4.204l5.519-6.831l6.863,2.95l-4.762,2.567   L72.522,67.397z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M72.522,67.529v-3.021l-2.365,1.182l1.577-2.102h-2.628l2.759-1.183   l-1.576-2.496l2.365,1.576v-2.759l1.313,1.84l0.395-2.496l0.395,2.627l2.627-1.183l-1.313,1.971l1.313,0.132l-0.394,0.394   l-1.313-0.656c0,0-1.875-0.503-2.667,1.188c-0.6,1.28,0.564,2.359,0.564,2.359l1.445,0.657l-0.394,1.183l-0.92-1.577L72.522,67.529   z" />
            <ellipse fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" cx="61.354" cy="57.544" rx="11.825" ry="6.569" />
            <ellipse fill="#B2B2B2" cx="61.354" cy="57.544" rx="9.59" ry="5.386" />
        </g>
        <g id="Group_Pipe1">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="52.2212" y1="43.0706" x2="54.2227" y2="39.515">
                <stop offset="0" style="stop-color:#6666CC" />
                <stop offset="0.25" style="stop-color:#003399" />
                <stop offset="0.75" style="stop-color:#CCCCFF" />
                <stop offset="1" style="stop-color:#6666CC" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#484848" stroke-width="0.25" d="M65.296,50.187l-25.75-14.058c0,0-0.525-1.241,0.263-2.496   c0.8-1.274,1.971-0.92,1.971-0.92l26.013,14.188L65.296,50.187z" />
            <path fill="#6666CC" stroke="#4C4C4C" stroke-width="0.25" d="M74.953,52.334c-0.279,1.37-1.222,1.993-2.219,1.993   c-0.995,0-1.727-1.021-1.449-2.213c0.292-1.254,1.162-2.041,2.157-2.041C74.439,50.073,75.181,51.213,74.953,52.334z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="71.2358" y1="53.4275" x2="73.9557" y2="50.0462">
                <stop offset="0" style="stop-color:#6666CC" />
                <stop offset="0.25" style="stop-color:#003399" />
                <stop offset="0.75" style="stop-color:#CCCCFF" />
                <stop offset="1" style="stop-color:#6666CC" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M72.522,51.702c-0.713,1.203-0.397,2.156-0.397,2.156s-0.188,0-0.563-0.5   c-0.322-0.429-0.378-1.367,0.313-2.281c0.516-0.682,0.974-0.789,1.373-0.853c0.939-0.151,1.308,0.632,1.308,0.632   S73.343,50.318,72.522,51.702z" />
        </g>
        <g id="Group_Gear">
            <path fill="#CC6633" stroke="#FF0000" stroke-width="0.25" d="M79.353,49.53l-1.839-1.051l1.313-0.92l-1.445-0.788l0.789-1.051   l-6.438-3.941l-0.789,0.919l-1.182-0.788l-0.789,1.971l-1.445-0.788v2.759L66.084,44.8l0.263,2.364h-2.497l1.051,2.233   l-2.496,1.052l1.708,0.919l-2.233,1.708l1.708,0.789l-1.708,1.576l1.182,0.657l-0.929,1.721l1.849-0.145v0.788l3.678,1.84   l0.526-0.395l1.336,0.819l0.502-1.87l1.709,1.051v-2.103l1.971,1.314l-0.385-4.568c-0.159,0.026-0.374,0.088-0.539,0.088   c-0.995,0-1.998-1.545-1.721-2.738c0.292-1.254,1.406-2.224,2.547-2.099c0.979,0.107,1.744,1.424,1.542,2.531l3.811-0.309   l-1.445-1.183L79.353,49.53z" />
            <path fill="#E54C19" d="M77.121,51.106l2.232-1.576l-1.971-0.132l1.625-1.825l-2.019,0.118l1.299-1.953l-1.956,0.901l0.131-1.971   l-1.182,1.577l-0.263-1.971l-1.183,1.839l-0.395-1.839l-1.051,2.233l-1.182-1.183l-0.264,2.365l-1.839-0.789l0.263,2.496h-1.971   l0.789,1.971l-2.234,0.92l1.708,1.051l-2.233,1.577l1.971,0.131l-1.971,2.233l2.102-0.394l-1.313,2.233l2.102-1.183l-0.488,2.074   l1.67-1.549l0.135,1.931l1.442-1.931l0.657,1.576l0.788-2.233l1.183,1.183l0.067-2.528l-0.351-1.961c0,0-2.597,0.704-2.516-2.453   c0.016-0.609,1.011-2.488,2.609-2.25c1.228,0.183,1.702,1.715,1.648,2.45c-0.109,1.484-1.742,2.253-1.742,2.253l0.351,1.961   l1.509,0.952v-2.496l2.496-0.132l-1.051-1.839l2.432-0.854L77.121,51.106z" />
            <polygon fill="none" stroke="#FF0000" stroke-width="0.25" points="77.121,51.106 79.353,49.53 77.382,49.398 79.007,47.573    76.989,47.691 78.288,45.738 76.332,46.639 76.462,44.668 75.281,46.245 75.018,44.275 73.835,46.114 73.441,44.275 72.39,46.508    71.208,45.325 70.945,47.691 69.106,46.902 69.369,49.398 67.398,49.398 68.187,51.368 65.953,52.288 67.661,53.339 65.427,54.916    67.398,55.047 65.427,57.281 67.529,56.887 66.216,59.12 68.318,57.938 67.83,60.012 69.5,58.463 69.634,60.394 71.077,58.463    71.734,60.039 72.522,57.806 73.705,58.989 73.705,56.492 75.281,57.412 75.281,54.916 77.777,54.784 76.726,52.946 79.158,52.092     " />
            <path fill="none" stroke="#FF0000" stroke-width="0.25" d="M75.149,52.334c-0.279,1.37-1.355,2.181-2.353,2.181   c-0.995,0-1.852-0.781-1.781-2.25c0.066-1.379,1.281-2.484,2.5-2.469C74.512,49.809,75.377,51.213,75.149,52.334z" />
        </g>
        <g id="Group_Lock">
            <path fill="#6666CC" stroke="#4C4C4C" stroke-width="0.25" d="M46.509,56.099v-3.548l3.416-1.971v3.416L46.509,56.099z" />
            <path fill="#9999FF" stroke="#4C4C4C" stroke-width="0.25" d="M49.925,50.58l-3.416,1.971l-3.547-1.971l3.547-1.445L49.925,50.58z" />
            <path fill="#9999FF" stroke="#4C4C4C" stroke-width="0.25" d="M46.509,56.099v-3.548l-3.619-1.814v3.416L46.509,56.099z" />
            <path fill="#E5E5E5" stroke="#666666" stroke-width="0.25" d="M44.933,50.58l0.131-10.772c0,0,0.566-5.405,4.202-5.781   c4.813-0.498,5.126,5.518,5.126,5.518l-1.577-0.789c0,0-0.202-1.596-1.29-2.364c-1.262-0.891-3.309-1.345-4.351,1.97   c-0.978,3.11,0.262,4.409,1.964,4.542c1.257,0.098,2.494-0.206,2.494-0.206l2.496,1.445v6.438H44.933z" />
            <path fill="#CC9966" stroke="#996633" stroke-width="0.25" d="M49.662,46.225c-2.92,2.49-2.385,4.631-2.385,4.631l-1.557-0.669   c0,0-0.125-1.397,0-1.84c0.188-0.667,0.969-1.862,1.445-2.364c0.438-0.463,1.499-1.24,2.102-1.445   c0.592-0.201,2.496-0.132,2.496-0.132l1.971,1.052l2.496,3.021C56.231,48.479,52.73,43.609,49.662,46.225z" />
            <path fill="#FFCC99" d="M56.1,51.106l-1.314,2.365l-2.233,1.445l-2.167,0.361L48.568,54.7l1.24-0.798l-0.146-3.17l-1.053-0.551   l-1.179,0.886c0,0-0.625-0.609-0.476-1.931c0.078-0.688,0.979-2.121,1.455-2.623c0.438-0.463,1.7-0.981,2.303-1.187   c0.592-0.201,2.496-0.132,2.496-0.132l1.971,1.183l1.289,2.201L56.1,51.106z" />
        </g>
        <g id="Group_Arrow">
            <path fill="#669933" stroke="#4C4C4C" stroke-width="0.25" d="M39.94,14.058c0,0,1.188,0.062,1.577,0.131   c0.369,0.065,1.122,0.207,1.445,0.395c0.321,0.187,1.051,1.051,1.051,1.051l-0.131-1.576c0,0-0.617-0.877-0.919-1.052   c-0.29-0.168-0.984-0.206-1.314-0.263c-0.458-0.078-1.839-0.263-1.839-0.263L39.94,14.058z" />
            <path fill="#99CC33" stroke="#4C4C4C" stroke-width="0.25" d="M27.984,20.626c0,0-2.087,0.03-2.759-0.131   c-0.701-0.168-2.627-1.183-2.627-1.183v-1.577c0,0,1.941,0.803,2.627,0.92c0.68,0.116,2.759,0,2.759,0V20.626z" />
            <path fill="#669933" stroke="#4C4C4C" stroke-width="0.25" d="M26.934,16.685v-1.183c0,0-1.599,0.215-2.102,0.395   c-0.388,0.138-1.141,0.511-1.445,0.788c-0.243,0.221-0.788,1.051-0.788,1.051v-1.839c0,0,0.587-0.498,0.788-0.657   c0.335-0.266,1.145-0.631,1.551-0.765c0.469-0.154,1.865-0.286,1.865-0.286v-1.445l2.365,1.971L26.934,16.685z" />
            <path fill="#99CC33" stroke="#4C4C4C" stroke-width="0.25" d="M40.071,20.758l-2.759-1.052l2.365-3.021l0.263,1.577   c0,0,1.573-0.732,2.053-1.052c0.55-0.366,2.02-1.708,2.02-1.708l0.121,1.905c0,0-1.626,1.202-2.223,1.511   c-0.498,0.258-2.102,0.788-2.102,0.788L40.071,20.758z" />
        </g>
        <g id="Group_Motor">
            <path fill="#FF6633" stroke="#4C4C4C" stroke-width="0.25" d="M77.777,70.954c0,0-1.383-1.029-1.779-1.424   c-0.559-0.557-1.303-3.7-0.12-5.942c1.809-3.429,4.794-3.101,5.382-3.022c0.984,0.131,2.299,0.656,2.299,0.656   s-3.813-0.525-6.012,3.285C75.28,68.436,77.777,70.954,77.777,70.954z" />
            <path fill="#0099CC" stroke="#4C4C4C" stroke-width="0.25" d="M87.105,76.725l-9.197-5.649c0,0-2.673-2.137-0.361-6.568   c1.938-3.716,6.012-3.285,6.012-3.285l9.59,5.781c0,0-4.272-2.234-6.832,2.377C83.828,73.863,87.105,76.725,87.105,76.725z" />
            <path fill="#336699" stroke="#4C4C4C" stroke-width="0.25" d="M87.105,76.725c-1.225-1.119-2.628-3.585-0.92-7.097   c1.953-4.015,5.021-3.25,6.841-2.691c1.072,0.329,2.104,1.774,2.224,2.956c0.094,0.92-0.131,1.971-0.131,1.971l-3.284-1.708   c0,0-1.245-0.163-2.081,0.92c-0.856,1.11-0.283,2.365-0.283,2.365l4.02,2.235c0,0-1.38,1.015-2.318,1.526   C89.813,77.944,87.798,77.358,87.105,76.725z" />
            <path fill="#CCCCFF" stroke="#4C4C4C" stroke-width="0.25" d="M94.828,71.671l-2.336-1.253c0,0-1.461-0.026-2.159,1.128   c-0.813,1.344,0.057,2.42,0.057,2.42l2.364,1.313c0,0-1.856,0.607-3.004,0.173c-1.856-0.703-2.556-2.736-1.463-4.807   c1.051-1.991,3.713-2.461,5.213-1.174C94.322,70.178,94.828,71.671,94.828,71.671z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M76.989,65.821l8.651,5.054" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M76.689,68.711l8.789,4.992" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M78.565,63.193l8.408,4.992" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M81.035,61.546l8.536,5.039" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.71,62.153l8.445,5.035" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M77.619,64.373l8.697,4.994" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M76.642,67.266l8.755,4.992" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M82.244,61.271l8.74,5.182" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M77.091,69.992l8.809,5.024" />
            <path fill="#0099CC" stroke="#4C4C4C" stroke-width="0.25" d="M85.922,78.958c0,0,2.511,0.277,3.284,0   c0.669-0.239,1.682-1.382,2.365-1.576c0.948-0.271,3.941,0.131,3.941,0.131l-0.395-4.204c0,0,2.721-1.806,3.153-2.759   c0.163-0.359,0-1.576,0-1.576l3.021,0.131c0,0,0.736,2.11,1.067,4.204c0.845,5.342-3.877,7.751-6.018,7.989   C90.501,81.948,85.922,78.958,85.922,78.958z" />
            <ellipse fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" cx="99.455" cy="76.593" rx="1.313" ry="1.971" />
        </g>
        <g id="Group_Strips">
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M82.244,58.332l2.496,1.182v1.052l-2.496-1.314V58.332z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M85.66,59.908l2.496,1.313v1.051l-2.496-1.182V59.908z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M88.812,61.616l2.628,1.313v1.051l-2.628-1.313V61.616z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M92.228,63.324l2.496,1.313v1.051l-2.496-1.313V63.324z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M74.886,71.601l2.365,1.445l0.92-0.525l-2.496-1.445L74.886,71.601z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M78.04,73.572l2.496,1.445l0.92-0.525l-2.496-1.576L78.04,73.572z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M81.324,75.411l2.365,1.444l0.92-0.525l-2.496-1.445L81.324,75.411z" />
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M84.477,77.382l2.496,1.444l0.92-0.656l-2.364-1.314L84.477,77.382z" />
        </g>
        <g id="Group_Connector">
            <path fill="#669999" d="M130.459,74.885l-0.525-8.145l-3.416,5.386L130.459,74.885z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M119.03,60.171l-2.365-2.891l1.839-1.708" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M116.665,57.281h-3.547" />
            <path fill="#E5E5E5" d="M104.972,63.046l-4.727,2.89l-4.732-2.89l4.732-1.84L104.972,63.046z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M95.513,63.046l4.732-1.824l4.727,1.824" />
            <path fill="#F58FC2" stroke="#4C4C4C" stroke-width="0.25" d="M100.243,70.813v-4.861l-4.649-2.759l-0.08,4.729L100.243,70.813z" />
            <path fill="#CC6699" stroke="#4C4C4C" stroke-width="0.25" d="M100.243,70.813v-4.861l4.616-2.759l0.113,4.729L100.243,70.813z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M102.082,68.185l13.926,8.015l0.657-0.132l0.395-1.051V57.543   l-0.395-0.394l-0.394,0.394v16.554l-0.264,0.395l-12.743-8.146l-0.657,0.657l13.4,8.014l-0.262,0.395l-13.4-7.883L102.082,68.185z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M117.672,72.688c-0.766,1.439-2.314,3.679-5.382,3.679   c-2.794,0-4.974-3.208-3.941-6.061c1.379-3.81,3.682-5.568,6.438-5.107C117.544,65.659,119.156,69.898,117.672,72.688z" />
            <path d="M116.533,66.346l14.715,9.722l-1.182-10.642l-4.205,6.7l-9.064-6.175L116.533,66.346z" />
        </g>
        <g id="Group_Antenna">
            <circle fill="#FF9933" stroke="#4C4C4C" stroke-width="0.25" cx="119.424" cy="61.616" r="1.577" />
            <circle fill="#FF9933" stroke="#4C4C4C" stroke-width="0.25" cx="111.192" cy="57.543" r="1.971" />
            <circle fill="#FF9933" stroke="#4C4C4C" stroke-width="0.25" cx="119.945" cy="54.785" r="1.708" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7916666666666666, 0.0, 0.22, 0.7916666666666666, 0.0, 0.23, 0.8020833333333334, 0.0, 0.4, 0.9791666666666666, 0.0, 0.5, 0.9375, 15.0, 0.8, 0.7916666666666666, 0.0, 0.99, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.25, 0.3958333333333333, 0.0, 0.28, 0.5052083333333334, 0.0, 0.45, 0.9791666666666666, 5.0, 0.59, 0.6041666666666666, 101.0, 0.7, 0.3645833333333333, 161.0, 0.75, 0.2708333333333333, 185.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.9375, 15.0, 0.25, 0.5260416666666666, 0.0, 0.75, 0.2708333333333333, 185.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.9375, 15.0, 0.25, 0.5260416666666666, 0.0, 0.75, 0.2708333333333333, 185.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Pole",[0.0, 0.9895833333333334, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.40625, 151.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.40625, 151.0, 5.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_OuterGear",[0.0, 0.8385416666666666, 41.0, 1.0, 0.9322916666666666, 0.0, 2.0, 0.9375, 15.0, 3.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.546875, 115.0, 1.0, 0.9479166666666666, 13.0, 2.0, 0.2708333333333333, 185.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_InnerGear",[0.0, 0.9895833333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Gear",[0.0, 0.9270833333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Arrow",[0.0, 0.7916666666666666, 0.0, 1.0, 0.9375, 15.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Motor",[0.0, 0.9375, 15.0, 1.0, 0.9270833333333334, 0.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.2708333333333333, 185.0, 4.0, 0.9270833333333334, 0.0, 5.0, 0.0, 255.0]);

        super.drawObject();
    }
}
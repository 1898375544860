import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "../properties/fillcolorproperty";
import { Tile } from "./tile";
import { SwitchControlProperty } from "../properties/switchcontrolproperty";

export class SwitchTile extends Tile{
    text:string;
    fillcolor:string;

    public drawObject():void{
	super.initObject();

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let fillcolor = '0xffffff00';         
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
      const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }
    
    let sw = false;
    const switchprop = this.properties==null?null:this.properties['switchcontrol'];
    let switchproperty:SwitchControlProperty;
    if (switchprop!=null){	
        switchproperty =Object.assign(new SwitchControlProperty(), switchprop);
        if (switchproperty!=null) 
            sw = switchproperty.getCurrentBooleanValue(this.tagnames);     
    }	

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const text2 = new Konva.Text({
        x: size*0.05,
        y: this.height - size * 0.05 - 0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05*2,
        height: 0.06*size,
        text: this.text,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(text2)

    //console.log('this.description', this.description.length)

    if(this.description.length != 0){
        const descriptionText = new Konva.Text({
            x: size*0.05,
            y: this.height - size*.58 - 0.1*size,
            verticalAlign: "bottom",
            width: this.width-size*0.05*2,
            height: 0.1*size,
            text: this.description,
            fontSize: 0.1*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'center'
        });
        group.add(descriptionText) 
    }

    const switchBorder = new Konva.Rect({
        x: (this.width - size*.445) * 0.5,
        y: this.description.length == 0 ? (this.height - size*.22) * 0.5 : this.height - size * 0.50,
        width: size * 0.445,
        height: size * 0.22,
        cornerRadius: size * 0.22,
        fill: ColorUtils.convertformat(textcolor)
    });
    group.add(switchBorder)

    const switchBackground = new Konva.Rect({
        x: (this.width - size*.425) * 0.5,
        y: this.description.length == 0 ? (this.height - size*.2) * 0.5 : this.height - size * 0.49,
        width: size * 0.425,
        height: size * 0.2,
        cornerRadius: size * 0.2,
        fill: ColorUtils.convertformat(fillcolor)
    });
    group.add(switchBackground)

    const thumb = new Konva.Circle({
        x: sw==true ? switchBackground.x() + switchBackground.width() - size * 0.1 : switchBackground.x() + size * 0.1,
        y: this.description.length == 0 ? this.height * 0.5 : this.height - size*.39,
        radius: size * 0.09,
        fill: ColorUtils.convertformat(textcolor)
    })
    group.add(thumb)

    this.node.add(group)
    this.setFunctions(switchproperty);   
}

    protected setFunctions(switchproperty:SwitchControlProperty):void{
        if (switchproperty!=null){
            this.node.on('mousedown touchstart', function () {
               switchproperty.setClick();
            });
        }         
    }
}
import { SVGObject } from "../svgobject";

export class SVGSensor19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 76.01" enable-background="new 0 0 112.5 76.01" xml:space="preserve">
        <g id="Group_Fluid_Storage">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="87.1885" y1="73.168" x2="8.4193" y2="-5.6012">
                <stop offset="0" style="stop-color:#91F2FF" />
                <stop offset="0.06" style="stop-color:#A3EFFF" />
                <stop offset="0.17" style="stop-color:#C0EBFF" />
                <stop offset="0.28" style="stop-color:#D4E8FF" />
                <stop offset="0.39" style="stop-color:#E1E6FF" />
                <stop offset="0.5" style="stop-color:#E5E5FF" />
                <stop offset="0.62" style="stop-color:#E1E4FE" />
                <stop offset="0.75" style="stop-color:#D4E2FA" />
                <stop offset="0.88" style="stop-color:#C0DFF3" />
                <stop offset="1" style="stop-color:#A6DBEB" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,64.749l2.815,2.703h89.861l2.928-2.703V2.816L92.68,0   H2.817L0.002,2.816V64.749z" />
        </g>
        <g id="Group_Blue_Liquid">
            <path fill="#6666FF" stroke="#4C4C4C" stroke-width="0.25" d="M8.335,53.489l2.815,2.703h59.232v5.63h30.854V59.12H73.085v-2.928   h11.261l2.928-2.703v-25.45H8.448L8.335,53.489z" />
        </g>
        <g id="Group_Yellow_Fluid">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M8.335,28.039h78.938V14.076l-2.928-2.928H73.085V8.446h28.151V5.518   H70.383v5.631H11.15l-2.815,2.928V28.039z" />
        </g>
        <g id="Group_Fluid_Burette">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="101.2363" y1="32.3745" x2="109.6816" y2="32.3745">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.15" style="stop-color:#F1F1F1" />
                <stop offset="0.83" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#ACACAC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M101.236,64.749h8.445V0h-8.445V64.749z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="98.5342" y1="70.3799" x2="112.498" y2="70.3799">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.15" style="stop-color:#F1F1F1" />
                <stop offset="0.83" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#ACACAC" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M98.534,64.749h13.964V76.01H98.534V64.749z" />
        </g>
        <g id="Group_Red_pointer">
            <path fill="#FF0000" d="M104.053,61.822V30.967h2.814v30.854L104.053,61.822L104.053,61.822z" />
        </g>
        <g id="Group_Level_Indicator">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,61.822h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,59.119h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,56.192h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,53.489h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,50.786h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,47.858h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,45.156h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,42.228h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,39.526h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,36.598h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,33.895h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,30.967h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,28.265h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,25.337h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,22.634h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,19.706h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,17.004h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,14.076h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,11.373h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,8.446h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,5.856h2.814" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.053,2.816h2.814" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.328125, 171.0, 0.06, 0.2864583333333333, 181.0, 0.17, 0.22395833333333334, 197.0, 0.28, 0.17708333333333334, 209.0, 0.39, 0.16145833333333334, 214.0, 0.5, 0.14583333333333334, 217.0, 0.62, 0.16666666666666666, 212.0, 0.75, 0.20833333333333334, 201.0, 0.88, 0.2864583333333333, 181.0, 1.0, 0.390625, 155.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.90625, 23.0, 0.15, 0.11979166666666667, 224.0, 0.83, 0.08333333333333333, 233.0, 1.0, 0.65625, 87.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.90625, 23.0, 0.15, 0.11979166666666667, 224.0, 0.83, 0.08333333333333333, 233.0, 1.0, 0.65625, 87.0]);
		this.fillGradient(this.fills,"Group_Blue_Liquid",[0.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Yellow_Fluid",[0.0, 0.671875, 83.0]);

        super.drawObject();
    }
}
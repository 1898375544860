import screensModule from "@/store/screens.module";
import { Screen } from "./project/screen";
import popupscreenModule from "@/store/popupscreen.module";


export default class tag{
    id:number;
    name:string;
    tagvalue:string;
    unit:string;
    usevalue:boolean;
    description:string;
    taggroup:string;
    viewtype:string;
    usehistory:boolean;
    useevents:boolean;
    datetime:number;
    icon:string;
    minvalue:string;
    maxvalue:string;
    colorstr:string;
    bgcolorstr:string;
    decimalpos:number;
    datatype:number;
    reserve1:string;
    reserve2:string;
    groupid:string;
    edgenodeid:string;
    deviceid:string;
    path:string;
    local:boolean;
    color:[];
    bgcolor:[];
    oldvalue:string;

    public static setValue(tag1:tag,value:any){
     // const oldvalue = tag1.tagvalue;
        console.log('setValue tag value',tag1.name, tag1.tagvalue,value, tag1.datatype)// value приходит как строковое значение
        if(tag1.datatype==0){
            if (value==0 || (typeof(value) == 'string'? value.toLowerCase()=='false' : value == false))
                tag1.tagvalue ='0';
            else if(value==1 || (typeof(value) == 'string'? value.toLowerCase()=='true' : value == true))
                tag1.tagvalue ='1';
            else tag1.tagvalue = value
        }else if (tag1.datatype==1){
          if(!tag.isNumeric(value)) return
          value = value & 255;
          if (value>127) value-=256
          value = value.toString()
          tag1.tagvalue = value
        }else if(tag1.datatype==2){
          if(!tag.isNumeric(value)) return
          value = value & 65535;
          if (value>32768) value-=65536
          value = value.toString()
          tag1.tagvalue = value
        }else if(tag1.datatype==3){
          if(!tag.isNumeric(value)) return
          value = Math.trunc(value)
          if (value>2147483648) value=2147483647
          if (value<-2147483648) value=-2147483647
          value = value.toString()
          tag1.tagvalue = value
        }else if(tag1.datatype==4){
          if(!tag.isNumeric(value)) return
          value = Math.trunc(value)
          tag1.tagvalue = value.toString()
        }else if(tag1.datatype==5){
          if(!tag.isNumeric(value)) return
            tag1.tagvalue = value
        }else if(tag1.datatype==6){
          tag1.tagvalue = value
        }else if(tag1.datatype==7){
          tag1.tagvalue = value==null?"":value.toString()
        }else if(tag1.datatype==8){
          if(value.isArray) tag1.tagvalue = value.toString()
        }
       // console.log("old=",tag1.oldvalue)
       // console.log("new=",tag1.tagvalue)
      if (tag1.oldvalue!=tag1.tagvalue){
        tag1.oldvalue=tag1.tagvalue
        const screen:Screen = screensModule.getScreen;
        console.log("screen=",screen)
        if (screen!=null)
          screen.updateObjects(tag1)
    
          const popupscreen:Screen = popupscreenModule.getPopupScreen;
          //console.log("popupscreen", popupscreen)
          if (popupscreen!=null)
            popupscreen.updateObjects(tag1)
          
     }
      }

    static isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
   /* constructor(){
        tag.id = 0;
        tag.name = '';
        tag.tagvalue = '';
        tag.unit = '';
        tag.usevalue = true;
        tag.description = '';
        tag.taggroup = '';
        tag.viewtype = 'value';
        tag.datetime = 0;
        tag.icon = '';
        tag.maxvalue = '';
        tag.maxvalue = '';
        tag.reserve1 = '';
        tag.reserve2 = '';
     }*/
}


//export default tag;
export class Scope{
    storage:Map<string,any>
    
    constructor(){
        this.storage = new Map();
    }
    public get(key:string):any{
        return this.storage.get(key);
    }
    public set(key:string, value:any):void{
        this.storage.set(key, value)
    }
    public hasKey(key:string):boolean{
        return this.storage.has(key);
    }
}
import { SVGObject } from "../svgobject";

export class SVGMiscpipe4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Copper_90_Curve">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="36.1162" y1="90.2363" x2="96.6025" y2="90.2363" gradientTransform="matrix(-1 0 0 -1 96.6025 137.9688)">
            <stop offset="0" style="stop-color:#663300" />
            <stop offset="0.5" style="stop-color:#CC6633" />
            <stop offset="1" style="stop-color:#663300" />
        </linearGradient>
        <rect y="44.986" fill="url(#SVGID_1_)" width="60.486" height="5.491" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="25.0601" y1="25.2207" x2="25.0601" y2="80.4272" gradientTransform="matrix(-1 0 0 -1 96.6025 137.9688)">
            <stop offset="0" style="stop-color:#663300" />
            <stop offset="0.5" style="stop-color:#CC6633" />
            <stop offset="1" style="stop-color:#663300" />
        </linearGradient>
        <rect x="69.408" y="57.542" fill="url(#SVGID_2_)" width="4.269" height="55.207" />
        <radialGradient id="SVGID_3_" cx="140.0811" cy="319.2207" r="23.7788" gradientTransform="matrix(-2.9866 -0.2001 0.2179 -2.719 416.3635 947.3754)" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" style="stop-color:#663300" />
            <stop offset="0.09" style="stop-color:#663300" />
            <stop offset="0.53" style="stop-color:#CC6633" />
            <stop offset="0.95" style="stop-color:#663300" />
            <stop offset="1" style="stop-color:#663300" />
        </radialGradient>
        <path fill="url(#SVGID_3_)" d="M71.319,112.749C36.547,112.749,0,90.923,0,47.694l60.45-0.162c1.263,7.605,4.71,9.44,10.676,9.932  C71.827,71.956,71.319,112.749,71.319,112.749z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="96.6016" y1="113.2002" x2="36.1152" y2="113.2002" gradientTransform="matrix(-1 0 0 -1 96.6025 137.9688)">
            <stop offset="0" style="stop-color:#663300" />
            <stop offset="0.5" style="stop-color:#CC6633" />
            <stop offset="1" style="stop-color:#663300" />
        </linearGradient>
        <rect fill="url(#SVGID_4_)" width="60.486" height="49.54" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="4.8184" y1="25.2764" x2="4.8184" y2="80.4829" gradientTransform="matrix(-1 0 0 -1 96.6025 137.9688)">
            <stop offset="0" style="stop-color:#663300" />
            <stop offset="0.5" style="stop-color:#CC6633" />
            <stop offset="1" style="stop-color:#663300" />
        </linearGradient>
        <rect x="70.908" y="57.486" fill="url(#SVGID_5_)" width="41.752" height="55.207" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.3958333333333333, 0.0, 0.09, 0.3958333333333333, 0.0, 0.53, 0.9270833333333334, 0.0, 0.95, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater27 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 83.218" enable-background="new 0 0 112.5 83.218" xml:space="preserve">
        <g id="Group_Water_Separator">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="104.6162" y1="66.3267" x2="23.6504" y2="-14.6391">
                <stop offset="0.01" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M6.759,2.252h103.487l-9.01,62.949l-9.008,4.504h-4.504   v13.514H71.959V69.705l-9.009-4.504H44.933l-9.008-4.505V38.174H6.759V2.252z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M4.394,0h108.104v2.252H4.394V0z" />
            <path fill="#8C8C8C" d="M38.176,58.444l0.113-54.052h6.644v56.304L38.176,58.444z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.289,58.444V4.392h6.644" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M38.289,58.444l6.644,2.252V4.392" />
            <path fill="#8C8C8C" d="M65.202,62.949V4.392h6.757v60.809L65.202,62.949z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M65.202,62.949V4.392h6.757" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M65.202,62.949l6.757,2.252V4.392" />
            <path fill="#8C8C8C" d="M13.402,35.922l0.113-31.53h6.644v31.53H13.402z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.515,35.922V4.392h6.644" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M13.515,35.922h6.644V4.392" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M69.707,80.965h20.27v2.253h-20.27V80.965z" />
            <path fill="#A6A6A6" d="M87.725,65.201V4.392h20.27l-9.01,58.557l-6.756,2.252H87.725z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M87.725,65.201V4.392h20.27" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M87.725,65.201h4.504l6.756-2.252l9.01-58.557" />
            <ellipse fill="#737373" cx="79.842" cy="76.627" rx="3.379" ry="4.505" />
        </g>
        <g id="Group_Box">
            <path fill="#738C8C" stroke="#4C4C4C" stroke-width="0.25" d="M9.011,38.174l2.252,20.27h24.661v-20.27H9.011z" />
            <ellipse fill="#4C7F7F" cx="30.46" cy="49.435" rx="3.378" ry="4.504" />
        </g>
        <g id="Group_Bolt">
            <radialGradient id="SVGID_2_" cx="79.8418" cy="75.3354" r="3.3779" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" cx="79.842" cy="75.335" r="3.378" />
            <circle fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" cx="79.842" cy="75.335" r="1.689" />
            <radialGradient id="SVGID_3_" cx="30.2939" cy="48.3091" r="3.3779" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" cx="30.294" cy="48.309" r="3.378" />
            <circle fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" cx="30.294" cy="48.309" r="1.688" />
        </g>
        <g id="Group_Support">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M15.655,83.218l-2.252-45.044h11.261l-2.252,45.044H15.655z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M13.402,80.965h11.261v2.253H13.402V80.965z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.402,53.939h11.261" />
        </g>
        <g id="Group_Pipe">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="3.3804" y1="20.1567" x2="3.3804" y2="11.2607">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M0.002,11.261h6.757v8.896H0.002V11.261" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.90625, 23.0, 0.5, 0.5052083333333334, 125.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Water_Separator",[0.0, 0.20833333333333334, 201.0, 1.0, 0.90625, 23.0, 2.0, 0.90625, 23.0, 3.0, 0.90625, 23.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.703125, 75.0, 6.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Box",[0.0, 0.9791666666666666, 5.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.609375, 99.0, 1.0, 0.8072916666666666, 49.0]);
		
        super.drawObject();
    }
}
import { Value } from "../value";
import { ObjectView } from "./object";


export class TwoStateObject extends ObjectView{
    fillcolor:string;
    type3d: number;

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
            case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        }
        console.log("setField MOTOR")
        this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "fillcolor": {value.datatype=7; value.value = this[field];break}
            case "type3d": {value.datatype=1; value.value = this[field];break}
        }
        return value;
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import Konva from "konva";
import { Value } from "../value";

export class ElectricalTransformer extends ObjectView{
    color:string;
    linewidth:number;


    public drawObject():void{
		super.initObject();

		if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        
		const ellipse = new Konva.Ellipse({
            x: this.width/2,
            y: this.height/3,
            radiusX: this.width/2,
            radiusY: this.height/3,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });
          this.node.add(ellipse)
        
        const ellipse2 = new Konva.Ellipse({
            x: this.width/2,
            y: this.height*2/3,
            radiusX: this.width/2,
            radiusY: this.height/3,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });
          this.node.add(ellipse2)
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color": {this[field] = value.value;this.rotatednode=null;break;}
          case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
  }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color": {value.datatype=7; value.value = this[field];break}
          case "linewidth": {value.datatype=2; value.value = this[field];break}
      }
      return value;
    }
        
    
}
import { SVGObject } from "../svgobject";

export class SVGTank53 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 73.65" enable-background="new 0 0 112.5 73.65" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="52.4785" x2="112.5" y2="52.4785">
                <stop offset="0" style="stop-color:#676739" />
                <stop offset="0.14" style="stop-color:#868561" />
                <stop offset="0.3" style="stop-color:#A8A78C" />
                <stop offset="0.41" style="stop-color:#C0C0AE" />
                <stop offset="0.47" style="stop-color:#CCCCBD" />
                <stop offset="0.54" style="stop-color:#C0C0AE" />
                <stop offset="0.67" style="stop-color:#A9A88D" />
                <stop offset="0.84" style="stop-color:#878662" />
                <stop offset="1" style="stop-color:#676739" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M112.5,31.307H0v37.049c0,0,4.19,5.295,56.194,5.295c52.386,0,56.193-5.069,56.193-5.069" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="21.9473" x2="112.5" y2="21.9473">
                <stop offset="0.05" style="stop-color:#676739" />
                <stop offset="0.12" style="stop-color:#77764D" />
                <stop offset="0.25" style="stop-color:#979676" />
                <stop offset="0.37" style="stop-color:#B1B099" />
                <stop offset="0.46" style="stop-color:#C3C3B2" />
                <stop offset="0.52" style="stop-color:#CCCCBD" />
                <stop offset="0.59" style="stop-color:#BBBAA6" />
                <stop offset="0.75" style="stop-color:#989777" />
                <stop offset="0.96" style="stop-color:#69693C" />
                <stop offset="0.98" style="stop-color:#676739" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,31.307c14.811,5.395,88.928,6.354,112.5,0.456v-0.456L65.428,8.108H46.959L0,31.307z" />
        </g>
        <g id="Group_Cap">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="46.9595" y1="4.3057" x2="65.4277" y2="4.3057">
                <stop offset="0" style="stop-color:#676739" />
                <stop offset="0.09" style="stop-color:#797951" />
                <stop offset="0.29" style="stop-color:#A1A083" />
                <stop offset="0.43" style="stop-color:#BEBEAA" />
                <stop offset="0.5" style="stop-color:#CCCCBD" />
                <stop offset="0.56" style="stop-color:#C4C4B2" />
                <stop offset="0.65" style="stop-color:#B2B19A" />
                <stop offset="0.78" style="stop-color:#999879" />
                <stop offset="0.92" style="stop-color:#7A7951" />
                <stop offset="1" style="stop-color:#676739" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="62.049,0 49.662,0 46.959,1.126 46.959,8.162 50.338,8.612 62.166,8.612 65.428,8.162    65.428,1.126  " />
        </g>
        <g id="Group_Doors">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="37.4727" y1="18.0884" x2="40.3847" y2="10.0877">
                <stop offset="0" style="stop-color:#676739" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.52" style="stop-color:#BDBDA9" />
                <stop offset="0.66" style="stop-color:#9E9D80" />
                <stop offset="0.77" style="stop-color:#888864" />
                <stop offset="1" style="stop-color:#676739" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M44.257,12.274l-0.338-0.9L33.57,15.603l0.458,1.22c0,0,1.429,0.845,5.161-0.493   c0.403-0.145,1.199-0.473,1.577-0.676C44.791,13.487,44.257,12.274,44.257,12.274z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="16.4063" y1="32.5088" x2="22.4542" y2="15.8921">
                <stop offset="0" style="stop-color:#676739" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.52" style="stop-color:#BDBDA9" />
                <stop offset="0.66" style="stop-color:#9E9D80" />
                <stop offset="0.77" style="stop-color:#888864" />
                <stop offset="1" style="stop-color:#676739" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M29.842,18.581L8.333,27.478c0,0,0.604,2.44,1.689,2.702c2.166,0.522,7.196-0.043,11.261-1.802   c4.999-2.163,8.605-5.669,8.904-6.522C30.926,19.745,29.842,18.581,29.842,18.581z" />
            
                <ellipse transform="matrix(0.9193 -0.3937 0.3937 0.9193 -7.5324 9.3768)" fill="#CCCCBD" cx="19.092" cy="23.05" rx="11.63" ry="3.866" />
            
                <ellipse transform="matrix(0.9247 -0.3806 0.3806 0.9247 -2.2176 15.7617)" fill="#CCCCBD" cx="38.741" cy="13.488" rx="5.592" ry="1.842" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6770833333333334, 0.0, 0.14, 0.9427083333333334, 0.0, 0.3, 0.765625, 59.0, 0.41, 0.546875, 115.0, 0.47, 0.4427083333333333, 141.0, 0.54, 0.546875, 115.0, 0.67, 0.7604166666666666, 61.0, 0.84, 0.9479166666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.05, 0.6770833333333334, 0.0, 0.12, 0.8072916666666666, 0.0, 0.25, 0.9166666666666666, 21.0, 0.37, 0.6875, 79.0, 0.46, 0.5208333333333334, 121.0, 0.52, 0.4427083333333333, 141.0, 0.59, 0.6041666666666666, 101.0, 0.75, 0.90625, 23.0, 0.96, 0.6979166666666666, 0.0, 0.98, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6770833333333334, 0.0, 0.09, 0.8333333333333334, 0.0, 0.29, 0.828125, 43.0, 0.43, 0.5729166666666666, 109.0, 0.5, 0.4427083333333333, 141.0, 0.56, 0.515625, 123.0, 0.65, 0.6822916666666666, 81.0, 0.78, 0.890625, 27.0, 0.92, 0.8385416666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6770833333333334, 0.0, 0.45, 0.4427083333333333, 141.0, 0.52, 0.578125, 107.0, 0.66, 0.8541666666666666, 37.0, 0.77, 0.9635416666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6770833333333334, 0.0, 0.45, 0.4427083333333333, 141.0, 0.52, 0.578125, 107.0, 0.66, 0.8541666666666666, 37.0, 0.77, 0.9635416666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Doors",[0.0, 0.4427083333333333, 141.0, 1.0, 0.4427083333333333, 141.0]);

        super.drawObject();
    }
}
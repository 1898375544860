import { SVGObject } from "../svgobject";

export class SVGEnergy6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 77.709 113.998" enable-background="new 0 0 77.709 113.998" xml:space="preserve">
        <g id="Group_Pole">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="30.3538" y1="72.0046" x2="34.4612" y2="72.0046">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M34.461,99.391l-0.912-54.773h-2.282l-0.913,54.773H34.461z" />
        </g>
        <g id="Group_Steps">
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M35.146,99.506v14.492" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,100.418h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,101.901h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,103.271h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,104.755h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,106.238h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,107.607h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,108.976h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,110.574h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,111.943h0.57" />
            <path fill="none" stroke="#BA840B" stroke-width="0.25" d="M34.575,113.312h0.57" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M29.098,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M35.032,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M29.669,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M30.924,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M32.407,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M33.891,99.049v-1.141" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M35.603,99.049v-1.141" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M28.948,97.908h6.797" />
        </g>
        <g id="Group_Base">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="30.011" y1="106.4656" x2="34.804" y2="106.4656">
                <stop offset="0" style="stop-color:#DDA901" />
                <stop offset="0.52" style="stop-color:#FFD037" />
                <stop offset="1" style="stop-color:#DDA901" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M30.011,99.163h4.793v14.606h-4.793V99.163z" />
            <path fill="#E8BC2A" stroke="#E8BC2A" stroke-width="0.25" d="M29.098,99.049h6.505v0.457h-6.505V99.049z" />
            <path fill="#E8BC2A" stroke="#E8BC2A" stroke-width="0.25" d="M30.011,113.769h4.793v0.228h-4.793V113.769z" />
            <path fill="#7B610D" d="M34.801,99.506h-4.793h-0.913l0.913,0.228v0.685c0,0,0.859-0.56,2.282-0.575   c1.593-0.018,2.511,0.575,2.511,0.575v-0.685l0.799-0.228H34.801z" />
        </g>
        <g id="Group_Center">
            <radialGradient id="SVGID_3_" cx="32.3562" cy="44.8401" r="3.6091" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#FFFFFF" />
                <stop offset="0.5" style="stop-color:#AAAAAA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M31.837,46.786c-0.507-0.134-1.354-0.976-1.483-1.483c-0.125-0.49,0.201-1.595,0.57-1.94   c0.384-0.359,1.548-0.603,2.054-0.456c0.465,0.135,1.237,0.903,1.37,1.369c0.137,0.479-0.111,1.581-0.457,1.94   C33.521,46.6,32.353,46.922,31.837,46.786z" />
        </g>
        <g id="Group_Blades">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="56.3713" y1="48.9602" x2="55.5844" y2="51.8201">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M33.891,46.216l43.477,10.155l0.342-0.228l-0.228-0.571l-33.32-10.27l-2.739-0.913l-1.483-0.229   l-3.651,0.114h-1.939L33.891,46.216z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="26.7703" y1="21.2986" x2="23.9579" y2="22.0497">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M32.978,42.906L19.856,0.228l-0.457-0.228L19.17,0.343l7.76,34.119l0.571,2.739l0.456,1.483   l1.939,3.081l1.027,1.597L32.978,42.906z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="14.5652" y1="61.2566" x2="17.1111" y2="63.8724">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M30.354,45.302L0,77.938v0.57h0.57l25.561-23.735l2.055-1.825l1.026-1.142l1.712-3.194l0.913-1.826   L30.354,45.302z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.984375, 3.0, 0.52, 0.65625, 87.0, 1.0, 0.984375, 3.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.0, 255.0, 0.5, 0.671875, 83.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.90625, 23.0, 0.5, 0.11458333333333333, 226.0, 1.0, 0.90625, 23.0]);

        super.drawObject();
    }
}
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { NumberTile } from "./numbertile";
import { CounterControlProperty } from "../properties/countercontrolproperty";
import tag from "@/model/tag";

export class PlusMinusTile extends NumberTile{
    countervalue:number;
    decrement:boolean;
    increment:boolean;
    public drawObject():void{
		super.initObject();


	let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    let minimum = 0
    let maximum = 100
    let decimalpos = 0
    let delta = 1
                
    const counterprop = this.properties==null?null:this.properties['countercontrol']
    let counterproperty;
    if (counterprop != null){
        counterproperty =Object.assign(new CounterControlProperty(), counterprop);
        if(counterproperty !=null){
        minimum = counterproperty.minimumvalue
        maximum = counterproperty.maximumvalue
        const tag:tag = counterproperty.getTag(this.tagnames)  
        if (tag!=null) this.countervalue  = Number(tag.tagvalue)  
        //console.log('counterproperty', counterproperty)
        //console.log('this.countervalue', this.countervalue)  

        //this.countervalue = counterproperty.getCurrentValue(this.tagnames)
        decimalpos = counterproperty.decimalpos
        delta = counterproperty.delta
        }
     }  

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const text2 = new Konva.Text({
        x: size*0.05,
        y: this.height - size * 0.05 - 0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05*2,
        height: 0.06*size,
        text: this.text,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(text2)

    const descriptionText = new Konva.Text({
        x: size*0.05,
        y: size*0.52-0.1*size,
        verticalAlign: "bottom",
        width: this.width-size*0.05*2,
        height: 0.1*size,
        text: this.description,
        fontSize: 0.1*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'right'
    });
    group.add(descriptionText) 
  
    const minusLabelTransparent = new Konva.Text({
        x: 0,
        y: 0,
        verticalAlign: "middle",
        text: '-',
        fontSize: 0.2*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: this.decrement? '#8fc65e' : ColorUtils.convertformat(textcolor),
        align: 'center',
     }); 

    const minusLabel = new Konva.Text({
       x: size*0.05 + size*.06 -minusLabelTransparent.width()/2,
       y: this.height - size * 0.20 -size*.06 - minusLabelTransparent.height()/2,
       verticalAlign: "middle",
       text: '-',
       fontSize: 0.2*size,
       fontFamily: StringUtils.getFontFamily(this.fonttype),
       fontStyle: StringUtils.getFontStyle(this.fonttype),
       fill: this.decrement? '#8fc65e' : ColorUtils.convertformat(textcolor),
       align: 'center',
    }); 
    group.add(minusLabel)

    const cirminus = new Konva.Circle({
        x: size*.05+ size*.06,
        y: this.height - size * 0.20 -size*.06,
        radius: size*.06,
        stroke: this.decrement? '#8fc65e' : ColorUtils.convertformat(textcolor),
        fill: 'transparent'
    });
    group.add(cirminus)

    const plusLabelTransparent = new Konva.Text({
        x: 0,
        y: 0,
        verticalAlign: "middle",
        text: '+',
        fontSize: 0.2*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: this.increment? '#8fc65e' : ColorUtils.convertformat(textcolor),
        align: 'center',
     }); 

    const plusLabel = new Konva.Text({
       x: this.width -size*.05 - size*.06 -plusLabelTransparent.width()/2,
       y: this.height - size * 0.20 -size*.06 - plusLabelTransparent.height()/2+ size*.005,
       verticalAlign: "middle",
       text: '+',
       fontSize: 0.2*size,
       fontFamily: StringUtils.getFontFamily(this.fonttype),
       fontStyle: StringUtils.getFontStyle(this.fonttype),
       fill: this.increment? '#8fc65e' : ColorUtils.convertformat(textcolor),
       align: 'center',
    }); 
    group.add(plusLabel)

    const cirplus = new Konva.Circle({
        x: this.width -size*.05 - size*.06,
        y: this.height - size * 0.20 -size*.06,
        radius: size*.06,
        stroke: this.increment? '#8fc65e' : ColorUtils.convertformat(textcolor),
        fill: 'transparent'
    });
    group.add(cirplus)

    const unitTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign: "bottom",
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    }); 
    
    const unitText = new Konva.Text({
      x: this.width-unitTextTransparent.width()-size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: unitTextTransparent.width(),
      height: 0.24*size,
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });  

    const valueText = new Konva.Text({
      x: size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: this.width-size*0.05*2-unitTextTransparent.width(),
      height: 0.24*size,
      text: this.countervalue !=null? parseFloat(this.countervalue.toFixed(decimalpos)).toLocaleString() : 'N/A',
      fontSize: 0.24*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'right',
      offsetY:-size*0.024
    }); 
    group.add(valueText, unitText)
   
    const cirminusposition = cirminus.getAbsolutePosition();
    const cirplusposition = cirplus.getAbsolutePosition();

    if(counterproperty !=null){
      this.node.on('mousedown touchstart', ()=> {
        const mousePos = this.node.getRelativePointerPosition()
        console.log('mousePos.x', mousePos.x)
        if(mousePos.x > (cirminusposition.x - size*.06) &&
            mousePos.x < cirminusposition.x + size*.06 &&
            mousePos.y > (cirminusposition.y - size*.06) &&
            mousePos.y < cirminusposition.y + size*.06){
                this.decrement = true
                this.countervalue = (Number(this.countervalue) - delta) < minimum ? minimum : Number(this.countervalue) - delta;
                console.log("PlusMinus2", this.countervalue )
                
                counterproperty.setValue(this.countervalue.toString())
                this.node.removeChildren()
                this.setUpdate(true)
                //this.drawObject()
        }
    });
    this.node.on('mouseup touchend', ()=> {
                this.decrement = false
                this.node.removeChildren()
                this.setUpdate(true)
                //this.drawObject()
    });

    this.node.on('mousedown touchstart', ()=> {
        const mousePos = this.node.getRelativePointerPosition()
        console.log('mousePos.x', mousePos.x)
        if(mousePos.x > (cirplusposition.x - size*.06) &&
            mousePos.x < cirplusposition.x + size*.06 &&
            mousePos.y > (cirplusposition.y - size*.06) &&
            mousePos.y < cirplusposition.y + size*.06){
                this.increment = true
                this.countervalue = (Number(this.countervalue) + delta) > maximum? maximum : Number(this.countervalue) + delta;
                console.log("PlusMinus1", this.countervalue )
                counterproperty.setValue(this.countervalue.toString())
                this.node.removeChildren()
                this.setUpdate(true)
                //this.drawObject()
        }
    });

    this.node.on('mouseup touchend', ()=> {
                this.increment = false
                this.node.removeChildren()
                this.setUpdate(true)
                //this.drawObject()
    });
    }

    

    this.node.add(group)     
} 

}
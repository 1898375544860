import { SVGObject } from "../svgobject";

export class SVGTank42 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 66.104 112.5" enable-background="new 0 0 66.104 112.5" xml:space="preserve">
        <g id="Group_Bottom">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M16.329,104.166h33.106v1.465H16.329V104.166z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M23.31,102.377V112.5h2.253v-10.035   C24.812,102.432,24.061,102.404,23.31,102.377z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M27.59,102.572v9.928h2.253v-9.795   C29.093,102.656,28.342,102.613,27.59,102.572z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M33.132,102.945c-0.419-0.034-0.843-0.061-1.263-0.092v9.646h2.253   v-9.472C33.792,103.001,33.463,102.975,33.132,102.945z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M36.148,103.186v9.314h2.253v-9.21   C37.652,103.27,36.901,103.234,36.148,103.186z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M40.427,103.284v9.216h2.252v-9.379   C41.93,103.213,41.179,103.26,40.427,103.284z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M19.031,102.248V112.5h2.254v-10.195   C20.531,102.281,19.781,102.266,19.031,102.248z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M45.692,102.527c-0.328,0.093-0.656,0.158-0.984,0.232v9.739h2.252   v-10.513C46.583,102.206,46.168,102.395,45.692,102.527z" />
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.1531" y1="-20.79" x2="41.0364" y2="-20.79" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.06" style="stop-color:#717275" />
                <stop offset="0.2" style="stop-color:#A5A6A8" />
                <stop offset="0.33" style="stop-color:#CBCCCD" />
                <stop offset="0.44" style="stop-color:#E2E3E4" />
                <stop offset="0.5" style="stop-color:#EBECEC" />
                <stop offset="0.57" style="stop-color:#E6E7E7" />
                <stop offset="0.66" style="stop-color:#D6D7D7" />
                <stop offset="0.76" style="stop-color:#BCBDBE" />
                <stop offset="0.86" style="stop-color:#98999B" />
                <stop offset="0.97" style="stop-color:#6B6C6F" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M16.553,111.486v-6.193h32.883v6.193H16.553z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="8.1531" y1="-14.0889" x2="41.0364" y2="-14.0889" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.06" style="stop-color:#717275" />
                <stop offset="0.2" style="stop-color:#A5A6A8" />
                <stop offset="0.33" style="stop-color:#CBCCCD" />
                <stop offset="0.44" style="stop-color:#E2E3E4" />
                <stop offset="0.5" style="stop-color:#EBECEC" />
                <stop offset="0.57" style="stop-color:#E6E7E7" />
                <stop offset="0.66" style="stop-color:#D6D7D7" />
                <stop offset="0.76" style="stop-color:#BCBDBE" />
                <stop offset="0.86" style="stop-color:#98999B" />
                <stop offset="0.97" style="stop-color:#6B6C6F" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M16.553,104.842v-6.307h32.883v6.307H16.553z" />
        </g>
        <g id="Group_body">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-8.3997" y1="29.8857" x2="57.7034" y2="29.8857" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.06" style="stop-color:#717275" />
                <stop offset="0.2" style="stop-color:#A5A6A8" />
                <stop offset="0.33" style="stop-color:#CBCCCD" />
                <stop offset="0.44" style="stop-color:#E2E3E4" />
                <stop offset="0.5" style="stop-color:#EBECEC" />
                <stop offset="0.57" style="stop-color:#E6E7E7" />
                <stop offset="0.66" style="stop-color:#D6D7D7" />
                <stop offset="0.76" style="stop-color:#BCBDBE" />
                <stop offset="0.86" style="stop-color:#98999B" />
                <stop offset="0.97" style="stop-color:#6B6C6F" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M66.103,94.031V14.302H0v79.729l0.112,0.676l2.478,2.027l3.041,1.238l4.054,1.014l4.843,0.9   l5.632,0.676l6.192,0.451l3.267,0.111h3.378h3.379l3.266-0.111l6.193-0.451l5.631-0.676l4.955-0.9l4.054-1.014l3.041-1.238   l2.479-2.027L66.103,94.031z" />
        </g>
        <g id="Group_Top">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.563,6.494V0H23.31v6.41C24.059,6.464,24.811,6.488,25.563,6.494z   " />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M29.843,6.405V0H27.59v6.475C28.341,6.456,29.092,6.432,29.843,6.405   z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M33.197,6.334c0.31,0.001,0.616,0.003,0.926,0.005V0h-2.253v6.35   C32.312,6.341,32.755,6.333,33.197,6.334z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M38.401,6.356V0h-2.253v6.35C36.899,6.354,37.65,6.354,38.401,6.356z   " />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M42.679,6.339V0h-2.252v6.36C41.178,6.358,41.928,6.347,42.679,6.339   z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M19.583,5.856c0.564,0.139,1.132,0.249,1.701,0.337V0h-2.254v5.749   C19.212,5.776,19.396,5.811,19.583,5.856z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M46.959,6.232V0h-2.252v6.313   C45.458,6.297,46.208,6.261,46.959,6.232z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M0,1.126h66.104v13.176H0V1.126z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M2.027,0h2.141v14.302H2.027V0z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M6.194,0h2.364v14.302H6.194V0z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M10.473,0h2.252v14.302h-2.252V0z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M14.752,0h2.365v14.302h-2.365V0z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M48.986,0h2.252v14.302h-2.252V0z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M53.265,0h2.252v14.302h-2.252V0z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M57.545,0h2.252v14.302h-2.252V0z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M61.824,0h2.252v14.302h-2.252V0z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-8.2874" y1="76.9014" x2="57.7034" y2="76.9014" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.06" style="stop-color:#717275" />
                <stop offset="0.2" style="stop-color:#A5A6A8" />
                <stop offset="0.33" style="stop-color:#CBCCCD" />
                <stop offset="0.44" style="stop-color:#E2E3E4" />
                <stop offset="0.5" style="stop-color:#EBECEC" />
                <stop offset="0.57" style="stop-color:#E6E7E7" />
                <stop offset="0.66" style="stop-color:#D6D7D7" />
                <stop offset="0.76" style="stop-color:#BCBDBE" />
                <stop offset="0.86" style="stop-color:#98999B" />
                <stop offset="0.97" style="stop-color:#6B6C6F" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M0.113,13.852V7.545h65.99v6.306L0.113,13.852L0.113,13.852z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-8.2874" y1="83.4883" x2="57.7034" y2="83.4883" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.06" style="stop-color:#717275" />
                <stop offset="0.2" style="stop-color:#A5A6A8" />
                <stop offset="0.33" style="stop-color:#CBCCCD" />
                <stop offset="0.44" style="stop-color:#E2E3E4" />
                <stop offset="0.5" style="stop-color:#EBECEC" />
                <stop offset="0.57" style="stop-color:#E6E7E7" />
                <stop offset="0.66" style="stop-color:#D6D7D7" />
                <stop offset="0.76" style="stop-color:#BCBDBE" />
                <stop offset="0.86" style="stop-color:#98999B" />
                <stop offset="0.97" style="stop-color:#6B6C6F" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M0.113,7.095V1.126h65.99v5.968L0.113,7.095L0.113,7.095z" />
        </g>
        <g id="Group_Flange">
            <radialGradient id="SVGID_6_" cx="32.9954" cy="51.5762" r="14.4141" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#EBEBEB" />
                <stop offset="0.48" style="stop-color:#C0C0C0" />
                <stop offset="0.52" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" cx="32.995" cy="51.576" r="14.414" />
        </g>
        <g id="Group_Bolt">
            
                <radialGradient id="SVGID_7_" cx="34.7297" cy="38.8486" r="3.2432" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_7_)" cx="43.129" cy="48.751" r="3.243" />
            
                <radialGradient id="SVGID_8_" cx="27.3542" cy="46.5337" r="3.2432" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_8_)" cx="35.754" cy="41.066" r="3.243" />
            
                <radialGradient id="SVGID_9_" cx="32.1956" cy="28.292" r="3.2419" gradientTransform="matrix(1 0 0 -1 8.3999 87.5996)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_9_)" cx="40.595" cy="59.308" r="3.242" />
            
                <radialGradient id="SVGID_10_" cx="-495.4021" cy="758.7773" r="3.2422" gradientTransform="matrix(-1 0 0 1 -472.6118 -704.5254)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_10_)" cx="22.79" cy="54.252" r="3.242" />
            
                <radialGradient id="SVGID_11_" cx="-502.5769" cy="766.0635" r="3.2432" gradientTransform="matrix(-1 0 0 1 -472.6118 -704.5254)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_11_)" cx="29.965" cy="61.538" r="3.243" />
            
                <radialGradient id="SVGID_12_" cx="-498.136" cy="748.4199" r="3.2419" gradientTransform="matrix(-1 0 0 1 -472.6118 -704.5254)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_12_)" cx="25.524" cy="43.895" r="3.242" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7239583333333334, 0.0, 0.06, 0.8854166666666666, 0.0, 0.2, 0.703125, 75.0, 0.33, 0.40625, 151.0, 0.44, 0.22395833333333334, 197.0, 0.5, 0.16666666666666666, 212.0, 0.57, 0.20833333333333334, 202.0, 0.66, 0.328125, 171.0, 0.76, 0.5208333333333334, 121.0, 0.86, 0.8072916666666666, 49.0, 0.97, 0.8385416666666666, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7239583333333334, 0.0, 0.06, 0.8854166666666666, 0.0, 0.2, 0.703125, 75.0, 0.33, 0.40625, 151.0, 0.44, 0.22395833333333334, 197.0, 0.5, 0.16666666666666666, 212.0, 0.57, 0.20833333333333334, 202.0, 0.66, 0.328125, 171.0, 0.76, 0.5208333333333334, 121.0, 0.86, 0.8072916666666666, 49.0, 0.97, 0.8385416666666666, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.06, 0.8854166666666666, 0.0, 0.2, 0.703125, 75.0, 0.33, 0.40625, 151.0, 0.44, 0.22395833333333334, 197.0, 0.5, 0.16666666666666666, 212.0, 0.57, 0.20833333333333334, 202.0, 0.66, 0.328125, 171.0, 0.76, 0.5208333333333334, 121.0, 0.86, 0.8072916666666666, 49.0, 0.97, 0.8385416666666666, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7239583333333334, 0.0, 0.06, 0.8854166666666666, 0.0, 0.2, 0.703125, 75.0, 0.33, 0.40625, 151.0, 0.44, 0.22395833333333334, 197.0, 0.5, 0.16666666666666666, 212.0, 0.57, 0.20833333333333334, 202.0, 0.66, 0.328125, 171.0, 0.76, 0.5208333333333334, 121.0, 0.86, 0.8072916666666666, 49.0, 0.97, 0.8385416666666666, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7239583333333334, 0.0, 0.06, 0.8854166666666666, 0.0, 0.2, 0.703125, 75.0, 0.33, 0.40625, 151.0, 0.44, 0.22395833333333334, 197.0, 0.5, 0.16666666666666666, 212.0, 0.57, 0.20833333333333334, 202.0, 0.66, 0.328125, 171.0, 0.76, 0.5208333333333334, 121.0, 0.86, 0.8072916666666666, 49.0, 0.97, 0.8385416666666666, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.16666666666666666, 212.0, 0.48, 0.5, 127.0, 0.52, 1.0, 0.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);

        super.drawObject();
    }
}
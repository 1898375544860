import { ObjectView } from "./object";
import { TextView } from "./text";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import AuthModule from "@/store/auth.module";
import TagsService from '@/services/tags.service';
import historydatarequest from "@/model/requests/historydatarequest";
import { RangeValueProperty } from "./properties/rangevalueproperty";
import Konva from "konva";
import tag from "@/model/tag";
import { Value } from "../value";

export class RangeIndicator extends ObjectView{
    color:string;
    bordercolor:string;
    type:number;
    fillcolor:string;

    public drawObject():void{
        if(this.type != 0) this.type = 1
        let rangeproperty:RangeValueProperty
          const rangeprop = this.properties['rangevalue'];
          let tag:tag
            if (rangeprop!=null){	
                 rangeproperty =Object.assign(new RangeValueProperty(), rangeprop);
                if (rangeproperty!=null) 
                       tag =  rangeproperty.getTag(this.tagnames)
            }	
            console.log('tag.name', tag.name)
        if (tag==null || !tag.usehistory || rangeproperty==null || !rangeproperty.showhistory){
            this.drawRange(tag,rangeproperty,null, null)
            return
        }

        if (AuthModule.currentUser!=null){
            const enddate = Date.now();
            const begindate = enddate - rangeproperty.getDuration();//delta*minutes
           const request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
              name:tag.path==null?tag.name:tag.path, begindate:begindate, enddate:enddate};
            TagsService.gethistorydata(request,(data:string)=>{
                const historydata =JSON.parse(data);
                 let hisMin:number
                 let hisMax:number
                 
                 const dataY = []
                 if(historydata.length>1){
                    historydata.forEach((data)=>{
                        dataY.push(data['y'])
                    })
                    hisMin = Math.min(...dataY)
                    hisMax = Math.max(...dataY)
                }

                 this.drawRange(tag,rangeproperty, hisMin, hisMax);
            }, 
            (data:string)=>{
                console.log('data', data);
             })
        }
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        const rangeroperty: RangeValueProperty = this.properties==null ? null : this.properties['rangevalue'];
        switch(field){
            case "color": 
            case "fillcolor": 
            case "bordercolor": {this[field] = value.value;this.rotatednode=null;break;}
            case "type": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "maximum": {if (rangeroperty!=null){rangeroperty.maximumvalue = value.value} break;}
            case "minimum": {if (rangeroperty!=null){rangeroperty.minimumvalue = value.value} break;}   
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        const rangeroperty: RangeValueProperty = this.properties==null ? null : this.properties['rangevalue'];
        switch(field){
            case "color": 
            case "fillcolor": 
            case "bordercolor": {value.datatype=7; value.value = this[field];break}

            case "type": {value.datatype=1; value.value = this[field];break}
           
            case "maximum": {if (rangeroperty!=null){
                value.datatype=6; value.value = rangeroperty.maximumvalue
                }
                break;}
            case "minimum": {if (rangeroperty!=null){
                value.datatype=6; value.value = rangeroperty.minimumvalue
                }
                break;}
        }
        return value;
    }

    private drawRange(tag:tag,rangeproperty:RangeValueProperty, hisMin:number, hisMax:number):void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties==null ? null : this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)

		let fillcolor = '0xffffff00';    
        fillcolor = this.fillcolor;
        const fillprop = this.properties==null ? null : this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }
        const fillcolor2 = ColorUtils.convertformat(fillcolor);

        const yrectbeg = this.height/20;
		let xrectbeg:number
        let xarrow:number
        let xhis:number;
		if (this.type==0){
			xrectbeg=this.width/2;
			xhis=this.width/2;
			xarrow=0;
		}
		else{
			xrectbeg=0;
			xhis=this.width/4;
			xarrow=this.width;
		}
       
		const rect = new Konva.Rect({
            x: xrectbeg,
            y: yrectbeg,
            width: this.width/2,
            height: this.height*9/10,
            fill: fillcolor2,
            stroke: this.bordercolor,
            strokeWidth: 1
          });
          this.node.add(rect)

            if(rangeproperty!=null && rangeproperty.colorrange!=null && rangeproperty.colorrange.length!=0){
                rangeproperty.colorrange.forEach((range)=>{
                    const ybegin =rangeproperty.getYvalue(range['from'], this.height)
                    const yend = rangeproperty.getYvalue(range['to'], this.height)
                    const rangeColor = range['color']
                    
                    const rectangle = new Konva.Rect({
                        x: xrectbeg,
                        y: yend,
                        width: this.width/2,
                        height: ybegin-yend,
                        fill: ColorUtils.convertformat(rangeColor),
                    })
                    this.node.add(rectangle)
                })
            }

        const yarrow = rangeproperty==null? this.height*19/20: rangeproperty.getYArrow(tag, this.height)
        const arrow = new Konva.Line({
            points: [this.width/2, yarrow,
                    xarrow, yarrow-this.height/20,
                    xarrow, yarrow+this.height/20,
                    this.width/2, yarrow],
            closed: true,
            strokeWidth:1,
            stroke: this.bordercolor,
            fill: color2
        })
        this.node.add(arrow)
        
        const desimalpos = rangeproperty!=null? rangeproperty.decimalpos : null

            let tagvalue =tag==null?"0": Number(tag.tagvalue).toFixed(desimalpos).toString()
            if(rangeproperty!=null && +tagvalue>rangeproperty.maximumvalue) tagvalue = rangeproperty.maximumvalue.toFixed(desimalpos).toString()
            if(rangeproperty!=null && +tagvalue<rangeproperty.minimumvalue) tagvalue = rangeproperty.minimumvalue.toFixed(desimalpos).toString()
            const valueTextOb = new TextView()
                valueTextOb.width = this.width/2
                valueTextOb.height = this.height/12
                valueTextOb.text = tagvalue;
                valueTextOb.fontsize = this.height/12
                valueTextOb.textplacement = 1
                valueTextOb.textcolor = color2
                valueTextOb.useborder = false
                valueTextOb.bordercolor = null
                valueTextOb.linewidth = 1
                valueTextOb.fill = false
                valueTextOb.fillcolor = 'white'
                valueTextOb.underline = false
                if(this.type==0){
                    valueTextOb.posx = 0
                }else{
                    valueTextOb.posx = this.width/2+1
                }
                valueTextOb.posy = yarrow+this.height/12
                if(yarrow>this.height*10/20) valueTextOb.posy = yarrow-this.height/12-this.height/20

                valueTextOb.initLayer(this.layer)
                valueTextOb.drawObject()
                this.node.add(valueTextOb.rotatednode)

        
            if(rangeproperty!=null && rangeproperty.showhistory){
                const hisminheight = rangeproperty.getYvalue(hisMin, this.height);
                const hismaxheight = rangeproperty.getYvalue(hisMax, this.height);
                const rectHis = new Konva.Rect({
                    x: xhis,
                    y: hisminheight,
                    width: this.width/4,
                    height: hismaxheight-hisminheight,
                    fill: ColorUtils.convertformat(rangeproperty.historycolor)
                })
                this.node.add(rectHis)
            }
        
    }
}
import { SVGObject } from "../svgobject";

export class SVGPump8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 80.855" enable-background="new 0 0 112.5 80.855" xml:space="preserve">
        <g id="Group_Support">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="63.1758" y1="2.4763" x2="105.9688" y2="2.4763" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M63.176,76.239h42.793v4.279H63.176V76.239z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="67.4551" y1="9.4026" x2="104.166" y2="9.4026" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M79.729,66.667h15.43l9.008,9.571H67.455L79.729,66.667z" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M86.374,76.239h2.59v-9.571h-2.59V76.239z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M19.933,62.162H6.419v4.505h0.788v9.571H6.419v4.279h20.158v-4.279   H11.712v-9.571h6.869v5.067h27.14v-6.419H23.085l-2.59-2.141L19.933,62.162z" />
        </g>
        <g id="Group_Volute_Chamber">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="55.0166" y1="-6.4163" x2="63.1748" y2="-6.4163" gradientTransform="matrix(0 3.3535 1.0617 0 19.8539 -149.6941)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M26.08,34.807c-7.925-0.014-22.602,0.05-22.602,0.05s-3.477,2.564-3.477,13.515   c0,10.957,3.477,13.79,3.477,13.79H26.08" />
        </g>
        <g id="Group_Layer_9">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="62.4434" y1="16.7791" x2="62.4434" y2="67.0032" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,64.076V13.852l12.275,7.432v35.813l-4.279,3.152   l-4.843,2.702L56.306,64.076z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="53.6602" y1="71.7341" x2="53.6602" y2="6.4192">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <rect x="51.014" y="6.419" fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" width="5.292" height="65.315" />
        </g>
        <g id="Group_Downstream_Pipe_Flange">
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="110.0225" y1="22.9724" x2="110.0225" y2="60.3606" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M112.162,20.495h-4.279v37.388h4.279V20.495z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="105.2129" y1="32.9949" x2="105.2129" y2="50.3372" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M102.542,30.518l0.373,0.789l0.868,3.489v4.279v4.279   l-0.868,3.379l-0.373,1.126h5.341V30.518H102.542z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="101.0176" y1="49.9998" x2="101.0176" y2="28.1536">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M97.786,50c1.36,0,2.721,0,4.081,0c1.524-2.012,2.572-6.207,2.572-11.058   c0-4.645-0.961-8.688-2.381-10.789c-1.42,0-3.043,0-4.463,0" />
        </g>
        <g id="Group_Layer_10">
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="22.5732" y1="53.2859" x2="75.2529" y2="53.2859" gradientTransform="matrix(0 1 -1 0 88.5701 -9.9299)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <rect x="24.34" y="12.643" fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.15" width="21.889" height="52.68" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="22.5083" y1="62.53" x2="75.2803" y2="62.53" gradientTransform="matrix(0 1 -1 0 88.5701 -9.9299)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M36.089,39.288c0-11.716-2.284-21.848-5.602-26.709c-2.966,0-5.929,0-8.893,0   c-3.323,4.862-5.604,14.993-5.604,26.709c0,11.22,2.094,20.983,5.188,26.063c3.094,0,6.631,0,9.724,0   C33.995,60.271,36.089,50.508,36.089,39.288z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="48.3599" y1="71.7341" x2="48.3599" y2="6.4192">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <rect x="45.714" y="6.419" fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" width="5.292" height="65.315" />
        </g>
        <g id="Group_Label">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M3.153,50h10.135v9.459H3.153V50z" />
            <path d="M6.419,57.094L5.518,54.73l0.901-2.141l2.027-0.788l2.478,0.788l0.789,2.141l-0.789,2.364l-2.478,0.788L6.419,57.094z" />
            <path fill="#F0F0F0" d="M22.635,70.721h2.59l1.352-2.479l-1.352-2.14h-2.59l-1.126,2.14L22.635,70.721z" />
            <path fill="#F0F0F0" d="M28.941,70.721h2.703l1.239-2.479l-1.239-2.14h-2.703l-1.014,2.14L28.941,70.721z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.581,66.667v-4.505" />
        </g>
        <g id="Group_Drivershaft_Flange">
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="86.374" y1="14.1892" x2="86.374" y2="69.1438" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,11.712h20.945l2.364,2.141l1.353,5.854l0.563,8.671   L99.1,39.076l-0.226,10.586l-0.563,8.783l-1.352,5.855l-2.363,2.365H73.648V11.712z" />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="71.1152" y1="12.6121" x2="71.1152" y2="71.0588" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M68.581,9.797h5.067v58.446h-5.067V9.797z" />
        </g>
        <g id="Group_Bolts">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,10.585h1.577V9.009h-1.577V10.585z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,20.495h1.577v-1.688h-1.577V20.495z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,30.293h1.577v-1.352h-1.577V30.293z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,40.09h1.577v-1.352h-1.577V40.09z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,50h1.577v-1.688h-1.577V50z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,69.594h1.577v-1.576h-1.577V69.594z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,59.797h1.577v-1.575h-1.577V59.797z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,10.585h1.577v1.127h-1.577V10.585z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,20.495h1.577v0.788h-1.577V20.495z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,30.293h1.577v0.788h-1.577V30.293z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,40.09h1.577v0.788h-1.577V40.09z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,50h1.577v0.788h-1.577V50z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,59.797h1.577v1.015h-1.577V59.797z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,69.594h1.577v0.788h-1.577V69.594z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,9.009h1.577v1.914h-1.577V9.009z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,18.807h1.577v1.914h-1.577V18.807z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,28.716h1.577v1.803h-1.577V28.716z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,38.514h1.577v1.913h-1.577V38.514z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,48.311h1.577v1.914h-1.577V48.311z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,58.221h1.577v1.802h-1.577V58.221z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M56.306,67.68h1.577v1.914h-1.577V67.68z" />
            <path fill="#FFFFFF" d="M43.806,67.455h1.577v0.563h-1.577V67.455z" />
            <path fill="#FFFFFF" d="M43.806,57.657h1.577v0.563h-1.577V57.657z" />
            <path fill="#FFFFFF" d="M43.806,47.523h1.577v0.788h-1.577V47.523z" />
            <path fill="#FFFFFF" d="M43.806,37.725h1.577v1.014h-1.577V37.725z" />
            <path fill="#FFFFFF" d="M43.806,27.927h1.577v1.015h-1.577V27.927z" />
            <path fill="#FFFFFF" d="M43.806,18.356h1.577v0.451h-1.577V18.356z" />
            <path fill="#FFFFFF" d="M43.806,8.446h1.577v0.563h-1.577V8.446z" />
        </g>
        <g id="Group_Level_Indicator">
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M8.221,35.023h2.928v-3.379H8.221V35.023z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="5.856" y1="55.4607" x2="13.5142" y2="55.4607" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M5.856,31.644h7.658v-12.5H5.856V31.644z" />
        </g>
        <g id="Group_Upstream_Pipe_Flange">
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="76.5762" y1="73.1414" x2="93.0176" y2="73.1414" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M76.576,3.716v7.996h16.441V3.716H76.576z" />
            
                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="67.2285" y1="78.8845" x2="102.251" y2="78.8845" gradientTransform="matrix(1 0 0 -1 0 80.855)">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M67.229,0.225v3.49h35.022v-3.49H67.229z" />
        </g>
        <g id="Group_Bolt2">
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,59.459h2.14v-1.802h-2.14V59.459z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,39.076h2.14v-2.14h-2.14V39.076z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,19.933h2.14v-2.141h-2.14V19.933z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,19.933h2.14v1.013h-2.14V19.933z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,39.076h2.14v1.014h-2.14V39.076z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M66.441,59.459h2.14v1.353h-2.14V59.459z" />
            <path fill="#FFFFFF" d="M66.441,56.531h2.14v1.126h-2.14V56.531z" />
            <path fill="#FFFFFF" d="M66.441,36.149h2.14v0.788h-2.14V36.149z" />
            <path fill="#FFFFFF" d="M66.441,16.667h2.14v1.126h-2.14V16.667z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,58.671h12.388v-2.14H73.648V58.671z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,37.95h12.388v-1.802H73.648V37.95z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,18.807h12.388v-2.141H73.648V18.807z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,18.807h12.388v2.139H73.648V18.807z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,37.95h12.388v2.14H73.648V37.95z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M73.648,58.671h12.388v2.141H73.648V58.671z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.125, 223.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.25, 191.0, 1.0, 0.609375, 99.0]);
		
        super.drawObject();
    }
}
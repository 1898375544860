import { SVGObject } from "../svgobject";

export class SVGMaterial20 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 79.617 112.5" enable-background="new 0 0 79.617 112.5" xml:space="preserve">
        <g id="Group_Stand">
            <path fill="#666666" d="M18.694,108.334h7.095v1.801h-7.095V108.334z" />
            <path fill="#666666" d="M69.031,108.334h7.095v1.801h-7.095V108.334z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M65.541,110.135h14.076v2.365H65.541V110.135z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M16.329,110.135h11.712v2.365H16.329V110.135z" />
        </g>
        <g id="Group_Extension">
            <path fill="#A6A6A6" stroke="#4C4C4C" stroke-width="0.25" d="M15.766,77.252h2.928v10.023h-2.928V77.252z" />
            <path fill="#4C4C4C" d="M28.843,81.102c-0.207-0.788-0.193-1.595-0.058-2.447c-0.229-0.051-0.453-0.111-0.661-0.195   c-4.237,2.552-9.639,3.156-14.643,3.302c0.282,0.947,0.286,1.937,0.021,2.852C19.899,84.178,25.373,82.885,28.843,81.102z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M8.109,77.252h3.604v13.514H8.109V77.252z" />
        </g>
        <g id="Group_Tank">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="12.7131" y1="41.6055" x2="7.9156" y2="40.32">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M7.545,43.243L8.671,37.5l4.167,2.252l-0.563,3.491H7.545z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="14.095" y1="39.0298" x2="8.9918" y2="36.0835">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M9.91,34.572l5.067,2.928l-1.802,3.041l-5.067-2.928   L9.91,34.572z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="6.9827" y1="45.0449" x2="12.8381" y2="45.0449">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M6.983,43.243h5.855v3.604H6.983V43.243z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="10.4729" y1="86.374" x2="13.9641" y2="86.374">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M10.473,77.252h3.491v18.244h-3.491V77.252z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="4.6174" y1="76.126" x2="23.4241" y2="76.126">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M4.617,75h18.807v2.252H4.617V75z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="4.6174" y1="63.2324" x2="23.4241" y2="63.2324">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M4.617,51.464h18.807V75H4.617V51.464z" />
        </g>
        <g id="Group_Grinder_sub">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,51.464H4.617v-4.617h14.076v-7.094L2.252,30.405v-6.982   h25.789V51.464z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,9.347h79.617v98.987H16.329v-5.293h11.712V23.423H0V9.347z" />
        </g>
        <g id="Group_Top_Layer">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M9.347,9.347h60.924V0H9.347V9.347z" />
        </g>
        <g id="Group_Box">
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M32.771,103.041H75V67.905" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M32.771,63.288H75V28.153" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M32.771,103.041V67.905H75" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M32.771,63.288V28.153H75" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,103.041v2.364" />
            <path d="M60.924,23.423H75v0.563H60.924V23.423z" />
            <path d="M28.716,23.423h27.478v0.563H28.716V23.423z" />
        </g>
        <g id="Group_Control_Unit">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M39.752,37.5h28.153v16.329H39.752V37.5z" />
            <circle fill="none" stroke="#7F7F7F" stroke-width="0.25" cx="45.609" cy="45.608" r="2.252" />
            <circle fill="#BF0000" cx="45.609" cy="45.608" r="1.126" />
            <circle fill="none" stroke="#FFFFFF" stroke-width="0.25" cx="54.955" cy="43.243" r="1.126" />
            <circle fill="none" stroke="#FFFFFF" stroke-width="0.25" cx="60.811" cy="43.243" r="1.126" />
            <path fill="#CCCCCC" d="M50.338,50.338h3.491v1.126h-3.491V50.338z" />
            <path fill="#CCCCCC" d="M58.559,50.338h3.491v1.126h-3.491V50.338z" />
        </g>
        <g id="Group_Button">
            <radialGradient id="SVGID_7_" cx="43.2434" cy="73.7617" r="3.4907" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#000000" />
                <stop offset="0.54" style="stop-color:#000000" />
                <stop offset="0.73" style="stop-color:#E6E6E6" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <circle fill="url(#SVGID_7_)" cx="43.243" cy="73.761" r="3.491" />
        </g>
        <g id="Group_Switch">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,84.347h11.036v14.077H37.5V84.347z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M38.626,97.185l0.563-3.491h7.657l0.563,3.491" />
        </g>
        <g id="Group_Connector">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="20.4954" y1="39.8086" x2="22.7483" y2="39.8086">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M20.495,42.117V37.5h2.253v4.617H20.495z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="50.7092" y1="84.4648" x2="45.0302" y2="83.4635">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.27" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M47.973,88.4l-2.364-8.221h5.855L49.775,88.4H47.973z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="21.6785" y1="37.5" x2="21.6785" y2="32.207">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M18.131,32.207h7.095V37.5h-7.095V32.207" />
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="18.131" cy="34.797" rx="0.563" ry="2.59" />
            <radialGradient id="SVGID_11_" cx="21.6218" cy="43.2432" r="1.802" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.59" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </radialGradient>
            <circle fill="url(#SVGID_11_)" cx="21.622" cy="43.243" r="1.802" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="20.3831" y1="64.0762" x2="22.7483" y2="64.0762">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M20.383,85.473V42.68h2.365v42.792H20.383z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="38.0632" y1="90.7656" x2="38.0632" y2="88.4004">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#808080" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M25.788,88.4h24.55v2.365h-24.55V88.4z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="57.4299" y1="88.2109" x2="60.7913" y2="88.2109" gradientTransform="matrix(-1 0 0 1 80.6177 0)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M23.188,85.192h-3.361v6.036l3.361-0.525V85.192z" />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="57.5725" y1="87.8535" x2="57.5725" y2="91.2285" gradientTransform="matrix(-1 0 0 1 80.6177 0)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M26.265,87.854v3.375h-6.439l3.207-3.375H26.265z" />
            <radialGradient id="SVGID_16_" cx="42.6799" cy="89.5273" r="3.4907" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.59" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </radialGradient>
            <circle fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" cx="42.681" cy="89.527" r="3.491" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.046875, 243.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.0, 0.0, 0.54, 0.0, 0.0, 0.73, 0.20833333333333334, 202.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.3958333333333333, 0.0, 0.27, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.25, 191.0, 0.59, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.3958333333333333, 0.0, 0.5, 1.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.25, 191.0, 0.59, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Extension",[0.0, 0.703125, 75.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Grinder_sub",[0.0, 0.40625, 151.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Top_Layer",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Control_Unit",[0.0, 0.3958333333333333, 0.0, 1.0, 0.4895833333333333, 0.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Switch",[0.0, 0.5885416666666666, 0.0]);
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextView } from './text'
import Konva from "konva";
import { format } from 'date-fns'
import datedialogboxModule from "@/store/datedialogbox.module";
import { StringUtils } from "../utils/stringutils";
import { TextColorProperty } from "./properties/textcolorproperty";
import { OutputProperty } from "./properties/outputproperty";

export class DateChooser extends TextView{
    format: string;
    date: number
    tagname: string;

    public drawObject():void{
		super.initObject();
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
        if (this.textplacement<0 || this.textplacement>3) this.textplacement = 0
        this.format = this.text

        const outputprop = this.properties==null?null:this.properties['output'];
        let outputproperty:OutputProperty
        if (outputprop!=null){
            outputproperty=Object.assign(new OutputProperty(), outputprop);
            this.tagname =  outputproperty.tagname
            outputproperty.getTag(this.tagnames)
            this.date = Number(outputproperty.getTag(this.tagnames).tagvalue)
        }
       // console.log('outputproperty.getTag(this.tagnames)', outputproperty.getTag(this.tagnames))



       // if(this.date == null || this.date == undefined) this.date = Date.now()

        let borderwidth = 0;
        let color = '0xffffff00';
        if (this.useborder){
            color = this.bordercolor;
            const lineprop = this.properties==null?null:this.properties['linecolor'];
            if (lineprop!=null){	
                const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
                if (linecolorproperty!=null) 
                    color = linecolorproperty.getColor(this.tagnames, this.bordercolor)
            }
            borderwidth = this.linewidth;
        }
        const bordercolor:string = ColorUtils.convertformat(color);

        let fillcolor = '0xffffff00';
        if (this.fill){
            fillcolor = this.fillcolor;
            const fillprop =this.properties==null?null:this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        }
        const fillcolor2:string = ColorUtils.convertformat(fillcolor);
		const rect = new Konva.Rect({
            x: borderwidth/2,
            y: borderwidth/2,
            width: this.width-borderwidth,
            height: this.height-borderwidth,
            fill: fillcolor2,
            stroke: bordercolor,
            strokeWidth: borderwidth,
          });
          this.node.add(rect)

          
          let textcolor = this.textcolor;
            const textcolorprop = this.properties==null?null:this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
//console.log('datechooser', format(this.date, this.format), this.date, this.format)
        const Text = new Konva.Text({
            x: 2*this.linewidth,
            y: this.linewidth,
            verticalAlign:"middle",
            width: this.width-4*this.linewidth,
            height: this.height-1*this.linewidth,
            text: (format(this.date, this.format)).toString(),
            fontSize: this.fontsize,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            align:StringUtils.getTextPlacement(this.textplacement),
            fill: ColorUtils.convertformat(textcolor),
            textDecoration:this.underline?"underline":""
          });

          
                if (outputproperty!=null){
                    this.node.on('mousedown touchstart',()=>{ 
                      //  console.log("DATEDIALOG THIS", this) 
                        datedialogboxModule.setDateChooser(this)     
                        datedialogboxModule.setDateDialogEnabled(true);
                    });
                }
              
          this.node.add(Text)

        
    }
}
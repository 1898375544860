import {ColorUtils} from '@/model/project/utils/colorutils'
import { IndicatorProperty } from './properties/indicatorproperty';
import Konva from "konva";
import { ObjectView } from './object';
import { Value } from '../value';

export class Indicator extends ObjectView{   
    falsecolor:string;
    truecolor:string;
    type3d:number;

    public drawObject():void{
        super.initObject();

        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	


        const truecolor = ColorUtils.convertformat(this.truecolor);
        const falsecolor = ColorUtils.convertformat(this.falsecolor);

        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, 'white', 
            1, '#888888'])
          }
          else{
            rectout.fill('#888888')
          }
          this.node.add(rectout) 
        
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradientrect = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientrect.addColorStop(0, '#888888')
          gradientrect.addColorStop(1, 'white')

          const gradientline1 = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientline1.addColorStop(0, '#888888')
          gradientline1.addColorStop(1, 'white')

          const gradientline2 = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientline2.addColorStop(0, '#888888')
          gradientline2.addColorStop(1, 'white')

          const rectinner = new Konva.Rect({
            x: this.width/10,
            y: this.height/10,
            width: this.width*4/5,
            height: this.height*4/5,
            cornerRadius: this.height/10,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientrect: 'white'
          });
          
          this.node.add(rectinner)  

        const p1 = [
            this.width*3/20, this.height/2,
            this. width*9/20, this.height*4/20,
            this.width*9/20, this.height*16/20,
            this.width*3/20, this.height/2
          ]
        const line1 = new Konva.Line({
            points:p1,
            closed: true,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientline1: 'white'
          });
        const p2 = [
            this.width*17/20, this.height/2,
            this.width*11/20, this.height*4/20,
            this.width*11/20, this.height*16/20,
            this.width*17/20, this.height/2
        ]
        const line2 = new Konva.Line({
            points:p2,
            closed: true,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientline2: 'white'
        });

        if(indicator){
          if (this.type3d==0){
              line2.fillRadialGradientStartPoint({ x: 14/20*this.width-this.height/20, y: this.height/2 }) 
              line2.fillRadialGradientEndRadius(this.height);
              line2.fillRadialGradientColorStops([0, 'white', 
                    1, ColorUtils.darkColor(truecolor,0.75)])

              line1.fillLinearGradientStartPoint({ x: 0, y: this.height*4/20 });
              line1.fillLinearGradientEndPoint({ x: 0, y: this.height*16/20 });
              line1.fillLinearGradientColorStops([0, ColorUtils.darkColor(falsecolor,0.75), 
              1, ColorUtils.darkColor(falsecolor,0.2)])
            
            }else{
              line1.fill(ColorUtils.darkColor(falsecolor,0.25))

              line2.fillRadialGradientStartPoint({ x: 14/20*this.width-this.height/20, y: this.height/2 }) 
              line2.fillRadialGradientEndRadius(this.height);
              line2.fillRadialGradientColorStops([0, 'white', 
                    1, ColorUtils.darkColor(truecolor,0.75)])
            }
        }else{
          if (this.type3d==0){
            line1.fillRadialGradientStartPoint({ x: 6/20*this.width+this.height/20, y: this.height/2 }) 
            line1.fillRadialGradientEndRadius(this.height);
            line1.fillRadialGradientColorStops([0, 'white', 0.5, ColorUtils.darkColor(falsecolor,0.75) ])

            line2.fillLinearGradientStartPoint({ x: 0, y: this.height*4/20 });
            line2.fillLinearGradientEndPoint({ x: 0, y: this.height*16/20 });
            line2.fillLinearGradientColorStops([0, ColorUtils.darkColor(truecolor,0.75), 
                1, ColorUtils.darkColor(truecolor,0.2)])
          }else{
            line1.fillRadialGradientStartPoint({ x: 6/20*this.width+this.height/20, y: this.height/2 }) 
            line1.fillRadialGradientEndRadius(this.height);
            line1.fillRadialGradientColorStops([0, 'white', 0.5, ColorUtils.darkColor(falsecolor,0.75) ])

            line2.fill(ColorUtils.darkColor(truecolor,0.2))
          }
        }
        this.node.add(line1)
        this.node.add(line2) 
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "falsecolor":
          case "truecolor":  {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "falsecolor":
          case "truecolor":  {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }
   
}
import { SVGObject } from "../svgobject";

export class SVGTank62 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.816 112.5" enable-background="new 0 0 56.816 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.4517" y1="56.25" x2="56.2524" y2="56.25">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.51" style="stop-color:#ECECEC" />
                <stop offset="0.58" style="stop-color:#D9D9D9" />
                <stop offset="0.72" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M56.251,9.807c0,0,0.564-9.244-23.511-9.244l-6.741,0c-24.124,0-25.548,9.244-25.548,9.244v92.886   c0,0,0,9.243,25.138,9.243c1.517,0,5.614,0,7.485,0c19.917,0,23.177-8.793,23.177-8.793V9.807z" />
        </g>
        <g id="Group_TankCutaway">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.8188" y1="56.25" x2="53.8843" y2="56.25">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="0.6" style="stop-color:#868686" />
                <stop offset="0.8" style="stop-color:#555555" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <rect x="2.819" y="14.429" fill="url(#SVGID_2_)" width="51.065" height="83.643" />
            <path d="M7.44,19.163v74.286h41.821V19.163H7.44z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.51, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.72, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.5, 0.8072916666666666, 49.0, 0.6, 0.953125, 11.0, 0.8, 0.6614583333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		
        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { RotationIndicatorProperty } from "./properties/rotationindicatorproperty";
import { ScrewConveyer } from './screwconveyer'
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { FanAxial } from './fanaxial';



export class ScrewConveyerMotion extends FanAxial{
    inclined:boolean

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00'
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor)

        let speed = 0
        let indicator = false;
        const rotationprop = this.properties['rotationindicator']
        if(rotationprop!=null){
            const rotationIndicatorProperty: RotationIndicatorProperty = Object.assign(new RotationIndicatorProperty(), rotationprop)
            if(rotationIndicatorProperty!=null) {
            speed = rotationIndicatorProperty.speed;
            indicator = rotationIndicatorProperty.CheckIndicator(this.tagnames)
            }
        }
        const node1 = new Konva.Group();  
        const node2 = new Konva.Group({
          clip: {
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          },
          
        }); 
        const rect = new Konva.Rect({
            x: -this.width*10/20,
            y: this.height*45/100,
            width: this.width*50/20,
            height: this.height*10/100,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })  
        if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height*10/100 });
            rect.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }else{
            rect.fill(fillcolor2)
        }
        node1.add(rect)

        for(let i=-2; i<6; i++){
        const width = this.width
        const height = this.height
        const type3d = this.type3d
        const wingUp = new Konva.Shape({

                sceneFunc: function (context, shape) {
                  context.beginPath();
                  context.moveTo(width*(5*i)/20, height*45/100);
                  //context.arcTo(width*(5*i+1.25)/20, -height/3, width*(5*i+2.5)/20, height*45/100,width*2.25/40)
                  context.arcTo(width*(5*i+1.25)/20, -height*10, width*(5*i+2.5)/20, height*45/100,width*2.5/40)
                  context.lineTo(width*(5*i+2.5)/20, height*55/100);
                  context.lineTo(width*(10*i+3)/40, height*45/100)
                  context.lineTo(width*(5*i)/20, height*45/100)
                  context.closePath();
                  context.fillStrokeShape(shape);
                },
                stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth: 1,    
        });
       if(this.type3d==0){
            wingUp.fillLinearGradientStartPoint({ x: 0, y: height/5 });
            wingUp.fillLinearGradientEndPoint({ x: 0, y: height*55/100 });
            wingUp.fillLinearGradientColorStops([0, 'white',
                0.5, ColorUtils.darkColor(fillcolor2, 0.5),
                1, 'white'])
        }else{
            wingUp.fill(fillcolor2)
        }
        node1.add(wingUp)

       const wingDown = new Konva.Shape({
            sceneFunc: function (context, shape) {
              context.beginPath();
              
              context.moveTo(width*(5*i+2.5)/20, height*55/100)
              context.arcTo(width*(5*i+3.75)/20, 15*height, width*(5*i+5)/20, height*55/100,width*2.5/40)
              context.lineTo(width*(10*i+8)/40, height*55/100);
              context.lineTo(width*(5*i+2.5)/20, height*45/100)
              context.lineTo(width*(5*i+2.5)/20, height*55/100)
              context.closePath();
              context.closePath();
              context.fillStrokeShape(shape);
            },
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1,    
    });
   if(this.type3d==0){
        wingDown.fillLinearGradientStartPoint({ x: 0, y: height*4/5});
        wingDown.fillLinearGradientEndPoint({ x: 0, y: height*45/100 });
        wingDown.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
    }else{
        wingDown.fill(fillcolor2)
    }
    wingDown.position({x:0, y:0})
    node1.add(wingDown)
 }

    node2.add(node1)
    this.node.add(node2)


    if (indicator){
      let x= 0;
      const width = this.width
      const layer = this.layer
      const rotation = this.rotation
      //const inclined = this.inclined
    
       
          const anim = new Konva.Animation(function (frame) {
            if(rotation==0){
              x +=width*0.25*frame.timeDiff/speed;
            node1.x(x);
            if (x>=width*0.25)
              x=0;
            }else{
              x -=width*0.25*frame.timeDiff/speed;
            node1.x(x);
            if (x<=-width*0.25)
              x=0;
            }
            

        }, layer);
        anim.start();
      }

      if(this.inclined){
        const point = {x:this.width/2, y: this.height/2}
        this.rotateAroundPoint(node1, 45, point)
    }
        
    }

    private rotateAroundPoint(shape, angleDegrees, point) {
      const angleRadians = angleDegrees * Math.PI / 180; 
       
      const x =
        point.x +
        (shape.x() - point.x) * Math.cos(angleRadians) -
        (shape.y() - point.y) * Math.sin(angleRadians);
      const y =
        point.y +
        (shape.x() - point.x) * Math.sin(angleRadians) +
        (shape.y() - point.y) * Math.cos(angleRadians);
        
      
      shape.position({x: x, y: y});
      shape.rotation(angleDegrees)
  }   
   
   
        
}

import { SVGObject } from "../svgobject";

export class SVGMaterial30 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Bottom_Platform">
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,97.41h89.978v15.09H0V97.41z" />
            <path fill="#7F7F7F" stroke="#FFFFFF" stroke-width="0.25" d="M31.869,102.478h26.238v4.955H31.869V102.478z" />
        </g>
        <g id="Group_Hammermill">
            <path fill="#194C4C" d="M36.824,55.518h1.915v3.829h-1.915V55.518z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,62.5h12.387v34.91H37.5V62.5z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M52.478,32.433V2.478h33.671V0h2.59v2.478h6.194V0h2.478v2.478h2.477   v14.978H84.91v14.978H52.478z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M49.887,97.41H87.5V62.5H49.887V97.41z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,62.5l0.563-11.937h11.261L49.887,62.5H37.5z" />
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M52.478,64.978H84.91V97.41H52.478V64.978z" />
        </g>
        <g id="Group_Mill">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="68.6934" y1="62.5" x2="68.6934" y2="32.4331">
                <stop offset="0" style="stop-color:#B2E5E5" />
                <stop offset="0.5" style="stop-color:#B2E5E5" />
                <stop offset="1" style="stop-color:#CCFFFF" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M49.887,62.5H87.5l-0.676-30.067H50.563L49.887,62.5z" />
        </g>
        <g id="Group_Bolt">
            <path fill="#CCFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M51.802,30.631h6.306v1.802h-6.306V30.631z" />
            <path fill="#CCFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M79.279,30.631h6.307v1.802h-6.307V30.631z" />
            <path fill="#CCFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M65.541,30.631h6.306v1.802h-6.306V30.631z" />
        </g>
        <g id="Group_Side_Layer">
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M87.5,94.933h25V84.91h-25V94.933z" />
        </g>
        <g id="Group_Display">
            <path fill="#FFFFB2" d="M64.302,57.433h11.261l-0.676-14.978h-9.909L64.302,57.433z" />
            <path fill="#FFFFB2" d="M42.342,56.194h2.59v-3.716h-2.59V56.194z" />
            <path fill="#E5B200" d="M64.978,53.716h9.909v-1.915h-9.909V53.716z" />
        </g>
        <g id="Group_Motor_Support">
            <path fill="#194C4C" d="M13.063,70.607h11.824v6.87H13.063V70.607z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M24.887,69.933h8.221v7.545h-8.221V69.933z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M4.955,69.933h8.108v7.545H4.955V69.933z" />
            <path fill="#7FB2B2" d="M24.887,69.933h8.221v1.914h-8.221V69.933z" />
            <path fill="#7FB2B2" d="M4.955,69.933h8.108v1.914H4.955V69.933z" />
            <path fill="#B2E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M4.955,77.478h28.153V97.41H4.955V77.478z" />
        </g>
        <g id="Group_Motor">
            <path fill="#4C7F7F" d="M4.955,68.693h28.153v1.914H4.955V68.693z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="17.4546" y1="68.6934" x2="17.4546" y2="46.1714">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#B2E5E5" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,68.693h34.91V46.171H0V68.693z" />
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M8.671,46.171h21.284v10.023H8.671V46.171z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,45.608h23.761v1.239H7.433V45.608z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,48.085h23.761v1.239H7.433V48.085z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,50.563h23.761v1.239H7.433V50.563z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,53.041h23.761v1.239H7.433V53.041z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M7.433,55.518h23.761v1.239H7.433V55.518z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M6.869,62.5H34.91" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M6.869,46.171v22.522" />
            <path fill="#FFFFFF" d="M25.563,59.91h7.545v-1.803h-7.545V59.91z" />
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M3.041,58.671h8.784v8.784H3.041V58.671z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M3.041,67.455v-8.784h8.784" />
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M34.91,49.887h1.915v15.09H34.91V49.887z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.34375, 167.0, 0.5, 0.34375, 167.0, 1.0, 0.140625, 219.0]);
		this.fillGradient(this.fills,"Group_Bottom_Platform",[0.0, 0.34375, 167.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Hammermill",[0.0, 0.4583333333333333, 0.0, 1.0, 0.5364583333333334, 117.0, 2.0, 0.34375, 167.0, 3.0, 0.34375, 167.0, 4.0, 0.34375, 167.0, 5.0, 0.7447916666666666, 65.0]);
		this.fillGradient(this.fills,"Group_Bolt",[0.0, 0.140625, 219.0, 1.0, 0.140625, 219.0, 2.0, 0.140625, 219.0]);
		this.fillGradient(this.fills,"Group_Side_Layer",[0.0, 0.5364583333333334, 117.0]);
		this.fillGradient(this.fills,"Group_Display",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.9479166666666666, 13.0]);
		this.fillGradient(this.fills,"Group_Motor_Support",[0.0, 0.4583333333333333, 0.0, 1.0, 0.34375, 167.0, 2.0, 0.34375, 167.0, 3.0, 0.7447916666666666, 65.0, 4.0, 0.7447916666666666, 65.0, 5.0, 0.34375, 167.0]);
		this.fillGradient(this.fills,"Group_Motor",[0.0, 0.8541666666666666, 0.0, 1.0, 0.6614583333333334, 0.0, 2.0, 0.5364583333333334, 117.0, 3.0, 0.5364583333333334, 117.0, 4.0, 0.5364583333333334, 117.0, 5.0, 0.5364583333333334, 117.0, 6.0, 0.5364583333333334, 117.0, 7.0, 0.0, 255.0, 8.0, 0.7447916666666666, 65.0, 9.0, 0.7447916666666666, 65.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 108.9" enable-background="new 0 0 112.5 108.9" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="6.9751" y1="1.6873" x2="105.5254" y2="1.6873">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M6.975,3.375h98.55L56.25,0L6.975,3.375" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="56.1375" x2="112.5" y2="56.1375">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M0,101.925l6.975,6.976h98.55l6.975-6.976v-91.35l-6.975-7.2H6.975L0,10.575V101.925" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.1196" y1="6.9128" x2="112.3799" y2="6.9128">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <polygon fill="url(#SVGID_3_)" points="112.38,10.451 105.525,3.375 6.975,3.375 0.12,10.451 " />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="0.0264" y1="105.4255" x2="112.4736" y2="105.4255">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <polygon fill="url(#SVGID_4_)" points="0.026,101.951 6.975,108.9 105.525,108.9 112.474,101.951 " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",
        [0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55,
            0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0,
             0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 
             79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45,
             0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 
             0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 
            0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 
            0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import Konva from "konva";
import tag from "@/model/tag";
import colorpickerModule from "@/store/colorpicker.module";
import { Value } from "../value";

export class ColorPicker extends ObjectView{
    redcolortagstring:string;
    bluecolortagstring:string;
    greencolortagstring:string;
    opacitycolortagstring:string;
    usecolorrectangle:boolean;
    redcolortagpath:string;
	bluecolortagpath:string;
	greencolortagpath:string;
	opacitycolortagpath:string;


    redtag:tag;
    greentag:tag;
    bluetag:tag;
    opacitytag:tag;

    color:string;

    public drawObject():void{
		super.initObject();

        this.tagnames.push(this.redcolortagpath)
		this.redtag = tagsModele.getTag(this.redcolortagpath)
        this.tagnames.push(this.greencolortagpath)
		this.greentag = tagsModele.getTag(this.greencolortagpath)
        this.tagnames.push(this.bluecolortagpath)
		this.bluetag = tagsModele.getTag(this.bluecolortagpath)
        this.tagnames.push(this.opacitycolortagpath)
		this.opacitytag = tagsModele.getTag(this.opacitycolortagpath)
        this.color='#'
  
        this.color+=this.redtag==null?'00':parseInt(this.redtag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.greentag==null?'00':parseInt(this.greentag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.bluetag==null?'00':parseInt(this.bluetag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.opacitytag==null?'ff': Math.round(parseFloat(this.opacitytag.tagvalue)).toString(16).padStart(2,'0')
        
        console.log('this.color', this.color)

		const fillcolor = '0xaaaaaaff';
      
            const rectfill = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                fill: ColorUtils.convertformat(fillcolor),
              });
              this.node.add(rectfill)

              const rectfill2 = new Konva.Rect({
                x: this.width/10,
                y: this.height/3,
                width: this.width/8,
                height: this.height/3,
                fill: this.color,
              });
              this.node.add(rectfill2)

              const textcolor = new Konva.Text({
                x: this.width/10+this.width/7,
                y: this.height/3,
                verticalAlign:"bottom",
                text: this.color,
                fontSize: this.height/3,
                fontFamily: "Roboto",
                fontStyle: "normal",
              });
              this.node.add(textcolor)

              this.node.on('mousedown touchstart',()=>{  
               colorpickerModule.setColorPicker(this)
               colorpickerModule.setColorPickerEnabled(true)
          });
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "redcolortagstring": {this[field] = value.value;this.rotatednode=null;break;}
            case "bluecolortagstring": {this[field] = value.value;this.rotatednode=null;break;}
            case "greencolortagstring": {this[field] = value.value;this.rotatednode=null;break;}
            case "opacitycolortagstring": {this[field] = value.value;this.rotatednode=null;break;}
            case "usecolorrectangle": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "redcolortagstring": {value.datatype=7; value.value = this[field];break}
            case "bluecolortagstring": {value.datatype=7; value.value = this[field];break}
            case "greencolortagstring": {value.datatype=7; value.value = this[field];break}
            case "opacitycolortagstring": {value.datatype=7; value.value = this[field];break}
            case "usecolorrectangle": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }
}
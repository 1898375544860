import { SVGObject } from "../svgobject";

export class SVGPeople10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 29.613 112.5" enable-background="new 0 0 29.613 112.5" xml:space="preserve">
        <g id="Group_RightArm">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M4.954,20.612l-2.14,4.166l-1.238,5.292v7.544L0.901,43.47   L0.676,57.32L0,61.261l1.013,3.378l2.59,1.801l0.451-4.053l0.112-3.49v-2.252l1.688-14.525l1.014-6.306l0.563-7.544" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M5.18,35.138l0.675,8.107L4.054,56.532" />
        </g>
        <g id="Group_Neck">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M18.228,18.565l-1.242-3.245h-4.472l-1.366,4.018" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M23.533,26.918l0.9,10.81l0.226,7.544l0.9,10.021l0.226,2.928   l-0.226,3.378l0.113,4.054l2.702-3.49l1.238-2.703l-1.126-3.603l0.563-11.147l-0.563-8.332l-0.226-7.77L26.01,21.4l-2.814-1.914" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M14.074,63.4l0.676,1.238l0.563,8.67l1.126,11.035l0.225,8.67   l1.577,12.386l-1.239,4.729l0.563,1.238l2.928,1.126h3.603l1.126-1.126l-2.928-5.292V86.482l1.802-21.394l0.676-5.405" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M5.056,61.251l-0.328,6.766L5.18,83.893L4.729,88.51l0.113,17.565   l-1.239,2.815l-1.801,1.801l0.563,1.238h4.729l3.04-2.477l-0.676-3.378v-1.239l-0.676-1.801l2.928-19.818L14.074,63.4" />
        </g>
        <g id="Group_Shoes">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M25.785,111.93v-1.238l-1.689-1.801l-1.238-2.815h-3.49l-1.689-1.802l-0.112,3.378   l-0.563,1.802v1.238l0.563,1.238l2.364,0.563h5.292L25.785,111.93z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.351,111.93v-1.238l1.689-1.801l1.126-2.815h3.49l1.802-1.802l0.676,4.054   l0.563,1.126l-1.914,1.238l-1.126,1.238l-2.252,0.563H1.802L1.351,111.93z" />
        </g>
        <g id="Group_HairBack">
            <path fill="#7F4C19" stroke="#4C4C4C" stroke-width="0.25" d="M21.281,9.465l-0.901,8.67l-0.9-0.676l-0.226,0.676h-1.013   l-1.352-1.239l-0.226-1.239l2.252-4.954L21.281,9.465z" />
            <path fill="#7F4C19" stroke="#4C4C4C" stroke-width="0.25" d="M7.77,9.465l1.238,9.346l0.338-0.788l0.112,0.788h0.9v-1.351   l0.563,1.351l3.828-3.828L7.77,9.465z" />
        </g>
        <g id="Group_Face">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M19.367,11.379H9.796l-1.352-1.014l-0.45-1.013V8.114l0.45-1.239   h12.161l0.676,1.239v1.239l-0.676,1.013L19.367,11.379z" />
            <ellipse fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" cx="14.526" cy="8.564" rx="5.742" ry="7.544" />
            <path fill="#CC9966" d="M14.412,11.379l1.689,0.337h-3.266L14.412,11.379z" />
            <path fill="#E5B2B2" d="M12.836,13.406l-0.563-0.563h4.391l-0.563,0.563H12.836z" />
            <path fill="#663333" d="M18.016,6.763l-2.928-0.451l0.226,0.451l2.928,0.225L18.016,6.763z" />
            <path fill="#663333" d="M10.922,6.763l2.815-0.451l-0.226,0.451l-2.815,0.225L10.922,6.763z" />
            <path fill="#663333" d="M12.048,9.015h0.676V8.339V7.664h-0.676l-0.226,0.675L12.048,9.015z" />
            <path fill="#663333" d="M16.326,9.015h0.451l0.225-0.676l-0.225-0.675h-0.451l-0.225,0.675L16.326,9.015z" />
        </g>
        <g id="Group_HairFront">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M9.008,8.114L8.67,6.875l-0.9,1.239l0.9-5.18l1.464-1.914   l1.914-0.788l2.364-0.225l2.365,0.225l1.914,0.788l1.576,1.914l1.014,5.18l-0.788-1.239l-0.451,1.239l-0.9-3.716l-1.238-1.914   l0.225,2.365H10.81l0.338-2.365L9.684,4.398L9.008,8.114z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M9.684,105.512h-2.59l-2.928,0.676V83.78l-0.112-8.332L3.49,63.175   l1.352-10.359l1.576-3.829l5.067-0.676l6.305,0.451l5.743,0.112l2.364,8.219l-0.225,9.797l-2.14,11.711l0.563,6.867l-1.238,20.043   l-1.352-0.563h-1.801l-1.802,0.563L15.652,86.37l-0.338-8.444l-1.239-12.725l-1.013,12.161l-2.14,13.286L9.684,105.512z" />
            <path fill="#D9CCB3" d="M14.074,64.752l-1.013,11.034l-2.14,14.3l-0.563,6.306l-0.337-4.729v-9.008l2.477-11.035l0.563-5.291   v-3.717v-2.139l0.563-9.571v9.009l0.45,3.152l2.14-3.717l-0.9,4.842l0.9,5.293l0.563,13.174l0.563,9.571l1.576,4.166l-1.576,0.563   l-1.126-6.869l-0.9-13.736L14.074,64.752z" />
        </g>
        <g id="Group_UpperBody">
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M5.63,33.787l-5.18-1.239l0.676-4.955l1.238-3.828l2.14-3.378   l4.278-1.239l3.266-1.801l2.026,1.238h2.252l1.577-1.238l6.418,2.252l2.478,1.801l1.463,3.378v2.365l0.676,5.743l-4.616,0.901   l-0.676,10.697v3.828l0.676,1.915H5.63v-1.352l0.563-2.477L5.63,33.787z" />
            <path fill="#E5E5E5" d="M6.531,33.561l0.676,1.577l4.054,1.239l4.054-1.352l7.094,0.901l1.351,1.464l-0.788-4.054l-4.842,1.351   l-2.702-1.126v-3.152l-0.676,3.378L11.034,34.8L6.531,33.561z" />
            <path fill="#E5E5E5" d="M23.42,26.805l0.563,5.743l-0.9,0.788l0.9,3.829l0.112-3.378l1.577-0.338l-0.901-0.563l0.563-4.729   l-0.901,2.252L23.42,26.805z" />
            <path fill="#E5E5E5" d="M4.954,29.508l0.45,3.04l-0.9,0.901l1.576,0.338l0.112,3.04l1.239-10.134L6.08,32.548L4.954,29.508z" />
        </g>
        <g id="Group_Tops">
            <path fill="#006633" stroke="#4C4C4C" stroke-width="0.25" d="M3.603,78.826l9.346,1.238l11.147-0.563l1.914-14.863V51.577   l-1.914-15.539l-3.04-18.016l-0.676-0.675l-0.675,11.147h-9.233L9.796,18.022l-1.238,0.563l-3.04,19.93L4.279,55.293L3.603,78.826z   " />
            <path fill="#004C19" d="M24.096,60.247l-4.278-9.346v4.955l1.802,4.391H24.096z" />
            <path fill="#004C19" d="M5.518,60.247l4.166-9.346v4.955l-1.802,4.391H5.518z" />
            <path fill="#004C19" d="M10.472,36.602h9.233l-0.563,0.563h-8.107L10.472,36.602z" />
        </g>
        <g id="Group_Label">
            <path fill="#E5E5E5" d="M17.904,30.409h4.391v1.238h-4.391V30.409z" />
            <path fill="none" stroke="#FF0000" stroke-width="0.25" d="M17.904,30.971h4.391" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { ScrewConveyer } from './screwconveyer';


export class BeltConveyer extends ScrewConveyer{


    public drawObject():void{
		super.initObject();

	
		let fillcolor = '0xffffff00'
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
        const node1 = new Konva.Group() 
        
        const rect = new Konva.Rect({
            x: this.width*3/20,
            y: this.height/4+this.height/20,
            width: this.width*14/20,
            height: this.height*2/4-this.height/10,
            stroke: ColorUtils.darkColor(fillcolor2, 0.75),
            strokeWidth: this.height/20
        })  
        node1.add(rect)


    const circle = new Konva.Circle({
        x: this.width*3/20,
        y: this.height/2,
        radius: this.height/4
    })
    if(this.type3d==0){
        circle.fillRadialGradientStartPoint({x:0, y:0});
        circle.fillRadialGradientEndRadius(this.height/4)
        circle.fillRadialGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.5), 
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2,0.5)]);
        circle.fillPriority('radial-graident')
    }else{
        circle.fill(fillcolor2)
        circle.stroke(ColorUtils.darkColor(fillcolor2,0.1))
        circle.strokeWidth(1)
    }
    node1.add(circle)

const circle2 = new Konva.Circle({
    x: this.width*17/20,
    y: this.height/2,
    radius: this.height/4
})
if(this.type3d==0){
    circle2.fillRadialGradientStartPoint({x:0, y:0});
    circle2.fillRadialGradientEndRadius(this.height/4)
    circle2.fillRadialGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.5), 
        0.5, 'white',
        1, ColorUtils.darkColor(fillcolor2,0.5)]);
    circle2.fillPriority('radial-graident')
}else{
    circle2.fill(fillcolor2)
    circle2.stroke(ColorUtils.darkColor(fillcolor2,0.1))
    circle2.strokeWidth(1)
}
node1.add(circle2)

    if(this.inclined){
        const point = {x:this.width/2, y: this.height/2}
        this.rotateAroundPoint(node1, 45, point)
    }
        
    this.node.add(node1) 
}
        
}

import { Property } from "./property";
import tag from "@/model/tag";

export class FillingProperty extends Property{
    minimumvalue:number;
    maximumvalue:number;
    

    public getHeight(tagnames:string[], height:number):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return height;
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
        if (percent<0) return 0
        if (percent>1) return height
        return percent*height; 
    }

    public getValue2(tagnames:string[]):string{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return '0';
        return tag.tagvalue; 
    }

    public getPercent(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return 0;
        
        let percent
        const currentValue = +this.getValue2(tagnames)

        if(currentValue<this.minimumvalue){
            percent = 0
        }else if(currentValue>this.maximumvalue){
            percent = 100
        }else if((this.maximumvalue-this.minimumvalue)<=0){
            percent = 0
        }else{
            percent = (currentValue-this.minimumvalue)*100/(this.maximumvalue-this.minimumvalue)
        }
        return percent

    }
    
}
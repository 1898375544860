import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import Konva from "konva";
import { Value } from "../value";




export class Line extends ObjectView{
    color:string;
    linewidth:number;
    linestyle:string;
    beginmarker:string;
    endmarker:string;

    public drawObject():void{
		super.initObject();

		if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let dash:number[];
		switch(this.linestyle)
		{
			case "Dash": 
                dash = [2.5*this.linewidth, 2.5*this.linewidth];
				break;
			case "Dot": 
                 dash = [1.5*this.linewidth, 2.5*this.linewidth];
				break;
			case "DashDot": 
                dash = [5*this.linewidth, 2.5*this.linewidth, 1.5*this.linewidth, 2.5*this.linewidth];
				break;
			default:
				break;
		}
		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		const colorline:string = ColorUtils.convertformat(color);
		let xbegin:number ;
		let ybegin:number;
		let xend:number;
		let yend:number;
		const ang:number = Math.atan2(this.height, this.width);
		if (this.beginmarker!='Flat'){
			xbegin=1.5*this.linewidth*Math.cos(ang);
			ybegin=1.5*this.linewidth*Math.sin(ang);
		}
		else{
			xbegin=0;
			ybegin=0;
		}
		if (this.endmarker!='Flat'){
			xend=1.5*this.linewidth*Math.cos(ang);
			yend=1.5*this.linewidth*Math.sin(ang);
		}
		else{
			xend=0;
			yend=0;
		}
		const line = new Konva.Line({
			points: [xbegin,ybegin, this.width-xend, this.height-yend],
			stroke: colorline,
			strokeWidth: this.linewidth,
			dash: dash
		})
		
		if (this.beginmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: 0,
			y: 0,
			points: [0, 0, 2*this.linewidth*Math.cos(ang), 2*this.linewidth*Math.sin(ang)],
			pointerLength: 2*this.linewidth,
			pointerWidth: 2*this.linewidth,
			pointerAtBeginning:true,
			pointerAtEnding:false,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
			this.node.add(arrow)
		}
		else if (this.beginmarker=='Square'){
			const square = new Konva.Rect({
				x:1.5*this.linewidth*Math.cos(ang),
				y:1.5*this.linewidth*Math.sin(ang), 
				width:3*this.linewidth,
				height:3*this.linewidth,
				fill: colorline,
				stroke: colorline,
				offsetX:1.5*this.linewidth,
				offsetY:1.5*this.linewidth,
				rotation:ang * 180/Math.PI
			});
			this.node.add(square)
		}
		else if (this.beginmarker=='Circle'){
			const circle = new Konva.Circle({
				x:1.5*this.linewidth*Math.cos(ang),
				y:1.5*this.linewidth*Math.sin(ang),
				radius:1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle)
		}
		if (this.endmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: this.width-2*this.linewidth*Math.cos(ang),
			y: this.height-2*this.linewidth*Math.sin(ang),
			points: [0, 0, 2*this.linewidth*Math.cos(ang), 2*this.linewidth*Math.sin(ang)],
			pointerLength: 2*this.linewidth,
			pointerWidth: 2*this.linewidth,
			pointerAtBeginning:false,
			pointerAtEnding:true,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
			this.node.add(arrow)
		}
		else if (this.endmarker=='Square'){
			const square = new Konva.Rect({
				x:this.width-3*this.linewidth*Math.cos(ang),
				y:this.height-3*this.linewidth*Math.sin(ang), 
				width:3*this.linewidth,
				height:3*this.linewidth,
				fill: colorline,
				stroke: colorline,
				offsetX:1.5*this.linewidth,
				offsetY:1.5*this.linewidth,
				rotation:ang * 180/Math.PI
			});
			this.node.add(square)
		}
		else if (this.endmarker=='Circle'){
			const circle = new Konva.Circle({
				x:this.width-1.5*this.linewidth*Math.cos(ang),
				y:this.height-1.5*this.linewidth*Math.sin(ang),
				radius:1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle)
		}
		this.node.add(line);
		
		

 
    }

	public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "color": 
			case "linestyle":
			case "beginmarker":
			case "endmarker": {this[field] = value.value;this.rotatednode=null;break;}

            case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "color":
			case "linestyle":
			case "beginmarker":
			case "endmarker": {value.datatype=7; value.value = this[field];break}

            case "linewidth": {value.datatype=2; value.value = this[field];break}
           
        }
        return value;
    }
}
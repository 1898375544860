import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";
import { he } from 'date-fns/locale';

export class Valve extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            

        const pipe = new Konva.Rect({
                x: 0,
                y:this.height/5,
                width: this.width/10,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
               strokeWidth:1
              })
              const pipe2 = new Konva.Rect({
                x: this.width*9/10,
                y:this.height/5,
                width: this.width/10,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
               strokeWidth:1
              })
              if(this.type3d==0){
                if(this.angle==0){
                    pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
                }else if(this.angle==180){
                    pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                    0.85, 'white',
                    1, fillcolor2])
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                    0.85, 'white',
                    1, fillcolor2])
                }else{
                    pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
                }
              }else{
                pipe.fill(fillcolor2)
                pipe2.fill(fillcolor2)
              }
              this.node.add(pipe, pipe2)

            const stripe = new Konva.Rect({
              x: 0,
              y: this.height/5,
              width: this.width/50,
              height: this.height*3/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth:1  
            })
            const stripe2 = new Konva.Rect({
                x: this.width*4/50,
                y: this.height/5,
                width: this.width/50,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth:1 
            })
            const stripe3 = new Konva.Rect({
            x: this.width*9/10,
            y:this.height/5,
            width: this.width/50,
            height: this.height*3/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })
        const stripe4 = new Konva.Rect({
            x: this.width*49/50,
            y:this.height/5,
            width: this.width/50,
            height: this.height*3/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })
        if(this.type3d==0){
            if(this.angle==0){
                stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
                stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe2.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
            }else if(this.angle==180){
                stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
                stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe2.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
            }else{
                stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
                stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe2.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
            }
          }else{
            stripe.fill(ColorUtils.darkColor(fillcolor2, 0.5))
            stripe2.fill(ColorUtils.darkColor(fillcolor2, 0.5))
            stripe3.fill(ColorUtils.darkColor(fillcolor2, 0.5))
            stripe4.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          }
          this.node.add(stripe, stripe2, stripe3, stripe4)

          const pipe3 = new Konva.Line({
            points: [this.width/10, this.height*3/10,
                this.width/5, this.height*3/10,
                this.width*2/5, this.height*2/5,
                this.width*3/5, this.height*2/5,
                this.width*4/5, this.height*3/10,
                this.width*9/10, this.height*3/10,
                this.width*9/10, this.height*7/10,
                this.width*4/5, this.height*7/10,
                this.width*3/5, this.height*3/5,
                this.width*2/5, this.height*3/5,
                this.width*1/5, this.height*7/10,
                this.width/10, this.height*7/10,
                this.width/10, this.height*3/10],
            closed: true,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
          })
          if(this.type3d==0){
            if(this.angle==0){
                pipe3.fillLinearGradientStartPoint({ x: 0, y: this.height*3/10 });
                pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*7/10});
                pipe3.fillLinearGradientColorStops([0, fillcolor2,
                0.15, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
            }else if(this.angle==180){
                pipe3.fillLinearGradientStartPoint({ x: 0, y: this.height*3/10});
                pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*7/10});
                pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.85, 'white',
                1, fillcolor2])
            }else{
                pipe3.fillLinearGradientStartPoint({ x: 0, y: this.height*3/10});
                pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*7/10});
                pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }
          }else{
            pipe3.fill(fillcolor2)
          }
          this.node.add(pipe3)
         
          const pipe4 = new Konva.Rect({
            x: this.width*2/10,
            y: 0,
            width: this.width*3/5,
            height: this.height/10,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
           strokeWidth:1
          })
          if(this.type3d==0){
            if(this.angle==0){
                pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe4.fillLinearGradientEndPoint({ x: 0, y: this.height/10});
                pipe4.fillLinearGradientColorStops([0, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
            }else if(this.angle==180){
                pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe4.fillLinearGradientEndPoint({ x: 0, y: this.height/10});
                pipe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                1, 'white'])
            }else{
                pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe4.fillLinearGradientEndPoint({ x: 0, y: this.height/10});
                pipe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }
          }else{
            pipe4.fill(fillcolor2)
          }
          this.node.add(pipe4)

          
          const pipe5 = new Konva.Rect({
            x: this.width*9/20,
            y: this.height/10,
            width: this.width/10,
            height: this.height*3/10,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
           strokeWidth:1
          })
          if(this.type3d==0){
            if(this.angle==90){
                pipe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe5.fillLinearGradientEndPoint({ x: this.width/10, y: 0});
                pipe5.fillLinearGradientColorStops([0, 'white',
               1, ColorUtils.darkColor(fillcolor2, 0.5)])
            }else if(this.angle==270){
                pipe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe5.fillLinearGradientEndPoint({ x: this.width/10, y: 0});
                pipe5.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                1, 'white'])
            }else{
                pipe5.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe5.fillLinearGradientEndPoint({ x: this.width/10, y: 0});
                pipe5.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }
          }else{
            pipe5.fill(fillcolor2)
          }
          this.node.add(pipe5)
        
}
}
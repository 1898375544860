import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";

import { TwoStateObject } from './twostateobject';
import { Value } from '../value';


export class ScrewConveyer extends TwoStateObject{
    inclined:boolean

    public drawObject():void{
		super.initObject();
        if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00'
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
        const node1 = new Konva.Group()      
        const rect = new Konva.Rect({
            x: this.width/20,
            y: this.height*45/100,
            width: this.width*18/20,
            height: this.height*10/100,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })  
        if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height*10/100 });
            rect.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }else{
            rect.fill(fillcolor2)
        }
        node1.add(rect)

        for(let i=0; i<3; i++){
        const width = this.width
        const height = this.height
        const type3d = this.type3d
        const wingUp = new Konva.Shape({
                sceneFunc: function (context, shape) {
                  context.beginPath();
                  context.moveTo(width*6*i/20+width/20, height*45/100);
                  context.arcTo(width*(6*i+3)/20+width/20, -height, width*(6*i+4)/20, height*45/100,width*3/40)
                  context.lineTo(width*(6*i+4)/20, height*55/100);
                  context.lineTo(width*(12*i+5)/40, height*45/100)
                  context.lineTo(width*(6*i+1)/20, height*45/100)
                  context.closePath();
                  context.fillStrokeShape(shape);
                },
                stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth: 1,    
        });
       if(this.type3d==0){
            wingUp.fillLinearGradientStartPoint({ x: 0, y: height/5 });
            wingUp.fillLinearGradientEndPoint({ x: 0, y: height*55/100 });
            wingUp.fillLinearGradientColorStops([0, 'white',
                0.5, ColorUtils.darkColor(fillcolor2, 0.5),
                1, 'white'])
        }else{
            wingUp.fill(fillcolor2)
        }
        node1.add(wingUp)

        const wingDown = new Konva.Shape({
            sceneFunc: function (context, shape) {
              context.beginPath();
              context.moveTo(width*(6*i+4)/20, height*55/100);
              context.arcTo(width*(6*i+6)/20, height*2, width*(6*i+7)/20, height*55/100,width*3/40)
              context.lineTo(width*(12*i+11)/40, height*55/100)
              context.lineTo(width*(6*i+4)/20, height*45/100);
              context.lineTo(width*(6*i+4)/20, height*55/100)
              context.closePath();
              context.fillStrokeShape(shape);
            },
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1,    
    });
   if(this.type3d==0){
        wingDown.fillLinearGradientStartPoint({ x: 0, y: height*75/100 });
        wingDown.fillLinearGradientEndPoint({ x: 0, y: height*45/100 });
        wingDown.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
    }else{
        wingDown.fill(fillcolor2)
    }
    wingDown.position({x:0, y:0})
    node1.add(wingDown)
 }
    this.node.add(node1)
    if(this.inclined){
        const point = {x:this.width/2, y: this.height/2}
        this.rotateAroundPoint(node1, 45, point)
    }
        
        
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "inclined": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "inclined": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }
   
    protected rotateAroundPoint(shape:Konva.Group, angleDegrees:number, point:any):void {
        const angleRadians = angleDegrees * Math.PI / 180; 
         
        const x =
          point.x +
          (shape.x() - point.x) * Math.cos(angleRadians) -
          (shape.y() - point.y) * Math.sin(angleRadians);
        const y =
          point.y +
          (shape.x() - point.x) * Math.sin(angleRadians) +
          (shape.y() - point.y) * Math.cos(angleRadians);
          
        
        shape.position({x: x, y: y});
        shape.rotation(angleDegrees)
    }     
        
}

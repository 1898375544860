import { SVGObject } from "../svgobject";

export class SVGMiscpipe6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 84.571 112.5" enable-background="new 0 0 84.571 112.5" xml:space="preserve">
        <g id="Group_Gas_Pipe_Tee">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-31.0942" y1="259.3984" x2="27.6167" y2="259.3984" gradientTransform="matrix(1 0 0 1 30.9551 -203.4902)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" points="58.572,-0.333 58.359,112.151 -0.139,112.151 -0.139,-0.333  " />
        </g>
        <g id="Group_Horizontal">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="136.6177" y1="151.0225" x2="77.9067" y2="151.0225" gradientTransform="matrix(0 1 1 0 -93.8462 -51.4536)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="57.053,26.453 29.818,55.656 57.053,85.164 84.536,84.951 84.536,26.453  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFinish27 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 107.762 112.5" enable-background="new 0 0 107.762 112.5" xml:space="preserve">
        <g id="Group_Heat_Exchanger1">
            <path fill="#BFB299" d="M0,35.058h107.762v77.44H0V35.058z" />
            <path fill="#998C73" d="M4.621,0.001h4.622v103.254h89.051v-2.367h-2.143v2.367h-4.734V79.809H21.079V52.756h-2.367v-4.734h2.367   V44.64l4.734-0.226v30.548h65.604v-2.367H25.814V67.86h65.604v-2.142H25.814v-4.734h70.338v35.17h2.143v-51.74H32.69v2.593h65.829   v4.509H32.69v2.367h65.604v4.734H27.955V44.414h-9.243v37.537H89.05v21.305h-4.733V86.91H18.712v2.367h65.604v4.51H18.712v2.367   h65.604v4.734H13.978V39.792h7.102V0.001h4.734v39.791h77.215v67.971H4.621V0.001z" />
        </g>
        <g id="Group_Heat_Pipes">
            <path fill="#D9CCB3" d="M5.749,0.001h2.367v104.381h91.417v-4.734h-4.734v4.734h-2.367V78.456H22.206v-26.94h-4.622v-2.142h4.622   v-6.087h2.367v33.027h67.858v-4.959H24.573v-2.143h67.858v-4.734H24.573v-2.367h70.226v35.282h4.734V43.287H31.675v4.734h67.858   v2.367H31.675v4.734h67.858v2.367H29.308V43.287H17.36l0.225,39.903h70.339v21.192h-2.367V85.558H17.584v4.734h67.972v2.142H17.584   v4.96h67.972v2.255H15.217V40.92h6.989V0.001h2.367V40.92h77.44v65.83H5.749V0.001z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,35.058h4.509V0.001h4.734v35.057h11.836V0.001h4.509v35.057h82.174   v77.44H0V35.058" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMotor15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 91.439" enable-background="new 0 0 112.5 91.439" xml:space="preserve">
        <g id="Group_Base">
            <path fill="#028402" d="M33.784,86.034h49.549v5.405H33.784V86.034" />
        </g>
        <g id="Group_Shaft">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="100.2119" y1="44.7063" x2="100.2119" y2="29.2786">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M87.924,29.279H112.5v15.428H87.924V29.279z" />
        </g>
        <g id="Group_ShaftFacePlate">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="86.875" y1="60.97" x2="86.875" y2="14.7205">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="89.188,17.792 84.563,14.72 84.563,60.97 89.188,57.77  " />
        </g>
        <g id="Group_Ouput_Cover">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="17.1875" y1="63.175" x2="17.1875" y2="12.5007">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="4.625,12.5 4.625,35.698 4.625,37.499 4.625,38.851 4.625,39.977 4.625,63.175    29.75,63.175 29.75,39.977 29.75,38.851 29.75,37.499 29.75,35.698 29.75,12.5  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="2.9282" y1="72.7463" x2="2.9282" y2="2.9285">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M0,2.928h5.856v69.818H0V2.928" />
        </g>
        <g id="Group_Shaft_Casing">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="82.6611" y1="65.9895" x2="82.6611" y2="9.572">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <rect x="80.075" y="9.572" fill="url(#SVGID_5_)" width="5.172" height="56.418" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="68.3906" y1="86.0344" x2="68.3906" y2="7.4324">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M81.756,7.432H55.027v78.603h26.729V7.432" />
        </g>
        <g id="Group_Bolt">
            <path fill="#7F7F7F" d="M85.247,13.288h1.802v0.788h-1.802V13.288z" />
            <path fill="#7F7F7F" d="M85.247,34.121h1.802v0.788h-1.802V34.121z" />
            <path fill="#7F7F7F" d="M85.247,55.968h1.802v1.013h-1.802V55.968z" />
            <path fill="#7F7F7F" d="M25.338,5.63h34.008v1.577H25.338V5.63z" />
            <path fill="#7F7F7F" d="M25.338,66.891h34.008v1.576H25.338V66.891z" />
            <path fill="#D3D3D3" d="M85.247,10.923h1.802v0.563h-1.802V10.923z" />
            <path fill="#D3D3D3" d="M85.247,31.644h1.802v0.9h-1.802V31.644z" />
            <path fill="#D3D3D3" d="M85.247,53.49h1.802v0.9h-1.802V53.49z" />
            <path fill="#D3D3D3" d="M25.338,62.612h34.008v1.352H25.338V62.612z" />
            <path fill="#D3D3D3" d="M25.338,1.352h34.008v1.351H25.338V1.352z" />
            <path fill="#B2B2B2" d="M85.247,13.288h1.802v-1.802h-1.802V13.288z" />
            <path fill="#B2B2B2" d="M85.247,34.121h1.802v-1.577h-1.802V34.121z" />
            <path fill="#B2B2B2" d="M85.247,55.968h1.802v-1.577h-1.802V55.968z" />
            <path fill="#B2B2B2" d="M25.338,66.891h34.008v-2.928H25.338V66.891z" />
            <path fill="#B2B2B2" d="M25.338,5.63h34.008V2.702H25.338V5.63z" />
            <path fill="#7F7F7F" d="M25.338,36.639h34.008v1.576H25.338V36.639z" />
            <path fill="#D3D3D3" d="M25.338,32.36h34.008v1.352H25.338V32.36z" />
            <path fill="#B2B2B2" d="M25.338,36.639h34.008v-2.928H25.338V36.639z" />
        </g>
        <g id="Group_MotorBody">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="53.1533" y1="86.0344" x2="53.1533" y2="7.324219e-004">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M50.113,0h6.081v86.034h-6.081V0" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="41.9487" y1="75.3088" x2="41.9487" y2="7.324219e-004">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M50.113,0H33.784v75.309h16.329V0" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="31.0249" y1="75.3088" x2="31.0249" y2="7.324219e-004">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M28.266,0h5.518v75.309h-5.518V0" />
            <rect x="33.784" y="0" fill="#006600" width="0.232" height="75.309" />
            <rect x="49.955" y="0" fill="#006600" width="0.216" height="75.309" />
            <rect x="56.085" y="7.207" fill="#006600" width="0.216" height="78.827" />
        </g>
        <g id="Group_Vent">
            <path fill="#006000" d="M25.671,19.616c0,0,0.02,7.91,0,8c-0.27,1.26-2.04,1.26-2.04,1.26h-13.16c0,0-1.89,0-2.25-1.26v-8   c0.44-1.48,2.25-1.48,2.25-1.48h13.16C23.631,18.136,25.161,18.136,25.671,19.616z" />
            <path fill="#71C15D" d="M25.91,19.42c0,0,0.02,7.91,0,8c-0.27,1.26-2.04,1.26-2.04,1.26H10.71c0,0-1.89,0-2.25-1.26v-8   c0.44-1.48,2.25-1.48,2.25-1.48h13.16C23.87,17.94,25.4,17.94,25.91,19.42z" />
            <path fill="#006000" d="M25.671,46.382c0,0,0.02,7.91,0,8c-0.27,1.26-2.04,1.26-2.04,1.26h-13.16c0,0-1.89,0-2.25-1.26v-8   c0.44-1.48,2.25-1.48,2.25-1.48h13.16C23.631,44.902,25.161,44.902,25.671,46.382z" />
            <path fill="#71C15D" d="M25.91,46.186c0,0,0.02,7.909,0,8c-0.27,1.26-2.04,1.26-2.04,1.26H10.71c0,0-1.89,0-2.25-1.26v-8   c0.44-1.48,2.25-1.48,2.25-1.48h13.16C23.87,44.706,25.4,44.706,25.91,46.186z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.3489583333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Bolt",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.3489583333333333, 165.0, 6.0, 0.3489583333333333, 165.0, 7.0, 0.3489583333333333, 165.0, 8.0, 0.3489583333333333, 165.0, 9.0, 0.3489583333333333, 165.0, 10.0, 0.609375, 99.0, 11.0, 0.609375, 99.0, 12.0, 0.609375, 99.0, 13.0, 0.609375, 99.0, 14.0, 0.609375, 99.0, 15.0, 0.9895833333333334, 0.0, 16.0, 0.3489583333333333, 165.0, 17.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_MotorBody",[0.0, 0.2604166666666667, 0.0, 1.0, 0.2604166666666667, 0.0, 2.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Vent",[0.0, 0.25, 0.0, 1.0, 0.9635416666666666, 9.0, 2.0, 0.25, 0.0, 3.0, 0.9635416666666666, 9.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMaterial2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 45.158" enable-background="new 0 0 112.5 45.158" xml:space="preserve">
        <g id="Group_Support">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="53.5425" y1="51.1689" x2="57.6081" y2="28.1116">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.15" style="stop-color:#CACACA" />
                <stop offset="0.29" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M101.914,35.924H9.234v7.433h92.68V35.924z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M101.914,45.158H9.234v-1.802h92.68V45.158z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="42.9302" y1="36.9609" x2="47.3863" y2="11.6892">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.12" style="stop-color:#C3C3C3" />
                <stop offset="0.22" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M53.266,12.726H37.049v23.198h16.216V12.726z" />
        </g>
        <g id="Group_Layer_7">
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M19.032,6.194v-0.45l-0.789-0.225l-0.225-0.226l-0.113-0.338   l0.563-1.013l0.113-0.113l0.451-0.112l0.337,0.112l0.338,0.226l0.226,1.238L19.82,5.519l-0.338,0.225h-0.45V6.194l0.337-0.112   l0.789-0.563l0.113-0.113l0.112-0.225V4.955V4.618L19.82,3.717l-0.451-0.113l-0.337-0.113l-0.226,0.113l-0.901,0.451l-0.112,0.563   L17.68,4.955l0.113,0.226v0.225l1.013,0.676L19.032,6.194z" />
        </g>
        <g id="Group_Motor_Unit">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="17.061" y1="34.3467" x2="17.061" y2="7.208">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M31.419,7.208H2.703v27.139h28.716V7.208z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1.3511" y1="34.3467" x2="1.3511" y2="7.208">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M2.703,7.208L0,11.149v19.144l2.703,4.054V7.208z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="32.77" y1="34.3467" x2="32.77" y2="7.208">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M31.419,34.347l2.703-4.054V11.149l-2.703-3.941V34.347z" />
        </g>
        <g id="Group_Motor_Holder">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M10.923,7.208H27.14v27.139H10.923V7.208z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M23.085,28.267h-9.459v6.08h9.459V28.267z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M34.122,17.906h2.703v5.63h-2.703V17.906z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M30.067,32.996h-5.63v2.928h5.63V32.996z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M14.978,32.996H9.685v2.928h5.293V32.996z" />
        </g>
        <g id="Group_Vent">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,8.446H12.275v1.352h13.513V8.446z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,11.149H12.275V12.5h13.513V11.149z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,5.744H12.275v1.464h13.513V5.744z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,13.964H12.275v1.239h13.513V13.964z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,19.482H12.275v1.351h13.513V19.482z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,22.185H12.275v1.351h13.513V22.185z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,16.78H12.275v1.351h13.513V16.78z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,24.888H12.275v1.352h13.513V24.888z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M25.788,27.591H12.275v1.352h13.513V27.591z" />
        </g>
        <g id="Group_Inlet_Outlet">
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="53.9414" y1="209.9854" x2="45.5894" y2="209.9854" gradientTransform="matrix(8.979319e-011 -1 1 8.979319e-011 -113.2041 70.3721)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="87.865" y="16.43" fill="url(#SVGID_6_)" width="17.832" height="8.352" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="14.3008" y1="32.0322" x2="22.5811" y2="32.0322" gradientTransform="matrix(-1 0 0 -1 114.5 45.4082)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M95.486,21.042c0.391-0.115,0.815-0.183,1.274-0.201c0.273-1.57,1.2-3.003,2.793-3.647l0.646-0.92   V5.711h-8.28v10.755l0.659,0.931C94.227,18.012,95.195,19.453,95.486,21.042z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="87.9512" y1="24.6631" x2="104.166" y2="24.6631">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M87.951,24.663h16.215" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="99.4375" y1="0.4512" x2="101.4639" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.438,0.901L99.662,0h1.238l0.563,0.901H99.438z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="95.1582" y1="0.4512" x2="96.959" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.158,0.901L95.383,0h1.352l0.225,0.901H95.158z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="90.6533" y1="0.4512" x2="92.6797" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M90.653,0.901L90.879,0h1.576l0.225,0.901H90.653z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="99.4375" y1="7.0947" x2="101.4639" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.438,6.645l0.225,0.901h1.238l0.563-0.901H99.438z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="95.1582" y1="7.0947" x2="96.959" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.158,6.645l0.225,0.901h1.352l0.225-0.901H95.158z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="90.6533" y1="7.0947" x2="92.6797" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M90.653,6.645l0.226,0.901h1.576l0.225-0.901H90.653z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="99.8867" y1="0.4512" x2="101.4639" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.887,0.901V0h1.014l0.563,0.901H99.887z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="95.3828" y1="0.4512" x2="96.959" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.383,0.901L95.607,0h1.127l0.225,0.901H95.383z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="91.1035" y1="0.4512" x2="92.6797" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.104,0.901V0h1.352l0.225,0.901H91.104z" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="99.8867" y1="7.0947" x2="101.4639" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.887,6.645v0.901h1.014l0.563-0.901H99.887z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="95.3828" y1="7.0947" x2="96.959" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.383,6.645l0.225,0.901h1.127l0.225-0.901H95.383z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="91.1035" y1="7.0947" x2="92.6797" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.104,6.645v0.901h1.352l0.225-0.901H91.104z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="90.2031" y1="5.1807" x2="101.9141" y2="5.1807">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" stroke="#4C4C4C" stroke-width="0.1" d="M101.914,6.645V3.717H90.203v2.928H101.914" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="100.6758" y1="7.0947" x2="101.4639" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.676,7.545h0.225l0.563-0.901H100.9L100.676,7.545z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="96.5088" y1="7.0947" x2="96.959" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" stroke="#4C4C4C" stroke-width="0.25" d="M96.509,7.545h0.226l0.225-0.901h-0.225L96.509,7.545z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="92.2295" y1="7.0947" x2="92.6797" y2="7.0947">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" stroke="#4C4C4C" stroke-width="0.25" d="M92.229,7.545h0.226l0.225-0.901h-0.45V7.545z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="100.6758" y1="0.4512" x2="101.4639" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.676,0h0.225l0.563,0.901H100.9L100.676,0z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="96.5088" y1="0.4512" x2="96.959" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" stroke="#4C4C4C" stroke-width="0.25" d="M96.509,0h0.226l0.225,0.901h-0.225L96.509,0z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="92.2295" y1="0.4512" x2="92.6797" y2="0.4512">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" stroke="#4C4C4C" stroke-width="0.25" d="M92.229,0h0.226l0.225,0.901h-0.45V0z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="90.2031" y1="2.3091" x2="101.9141" y2="2.3091">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" stroke="#4C4C4C" stroke-width="0.1" d="M101.914,0.901v2.815H90.203V0.901H101.914" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,24.212l1.014,0.226v1.351l-1.014,0.226V24.212z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,19.708l1.014,0.225v1.577l-1.014,0.225V19.708z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,15.428l1.014,0.225v1.577l-1.014,0.225V15.428z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="25.1123" x2="105.7432" y2="25.1123">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,24.212l-0.901,0.226v1.351l0.901,0.226V24.212z" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="20.7207" x2="105.7432" y2="20.7207">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,19.708l-0.901,0.225v1.577l0.901,0.225V19.708z" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="16.4414" x2="105.7432" y2="16.4414">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,15.428l-0.901,0.225v1.577l0.901,0.225V15.428z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,24.438l1.014,0.226v1.125l-1.014,0.226V24.438z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,20.158l1.014,0.225v1.126l-1.014,0.225V20.158z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,15.879h1.014v1.351l-1.014,0.225V15.879z" />
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="25.2256" x2="105.7432" y2="25.2256">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,24.438l-0.901,0.226v1.125l0.901,0.226V24.438z" />
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="20.9463" x2="105.7432" y2="20.9463">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,20.158l-0.901,0.225v1.126l0.901,0.225V20.158z" />
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="16.667" x2="105.7432" y2="16.667">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_34_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.743,15.879h-0.901v1.351l0.901,0.225V15.879z" />
            <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="107.207" y1="26.4639" x2="107.207" y2="14.9785">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_35_)" stroke="#4C4C4C" stroke-width="0.1" d="M105.743,26.464h2.929V14.978h-2.929V26.464" />
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="25.7881" x2="105.7432" y2="25.7881">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_36_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.842,25.563v0.225l0.901,0.226v-0.226L104.842,25.563z" />
            <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="21.5093" x2="105.7432" y2="21.5093">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_37_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.842,21.284v0.225l0.901,0.225v-0.45H104.842z" />
            <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="104.8418" y1="17.1172" x2="105.7432" y2="17.1172">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_38_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.842,16.78v0.45l0.901,0.225v-0.45L104.842,16.78z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,25.563v0.225l-1.014,0.226v-0.226L112.5,25.563z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,21.284v0.225l-1.014,0.225v-0.45H112.5z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,16.78v0.45l-1.014,0.225v-0.45L112.5,16.78z" />
            <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="110.0791" y1="26.4639" x2="110.0791" y2="14.9785">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_39_)" stroke="#4C4C4C" stroke-width="0.1" d="M111.486,26.464h-2.814V14.978h2.814V26.464" />
        </g>
        <g id="Group_Mixer">
            <path fill="#B2B2B2" d="M82.095,23.762H54.842v4.054h27.252V23.762z" />
            <path fill="#CCCCCC" d="M82.095,17.906H54.842v5.856h27.252V17.906z" />
            <path fill="#E5E5E5" d="M82.095,17.906H54.842v-5.18h27.252V17.906z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M82.095,12.726H54.842v15.09h27.252V12.726" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M87.951,32.32h-3.379V7.996h3.379V32.32z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M82.095,32.32h2.478V7.996h-2.478V32.32z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M82.095,21.284H54.842v-2.252h27.252V21.284z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M82.095,12.726V7.996l-28.829,4.729H82.095z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M54.842,27.815h-1.577v-15.09h1.577V27.815z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M79.842,19.032v-7.32L77.59,12.05v6.982H79.842z" />
        </g>
        <g id="Group_Clamp">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M88.626,35.924v-1.802h-6.531v-8.783h-1.577v10.585H88.626z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M87.951,34.122h-2.253v-1.126h2.253V34.122z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M62.951,35.924v-1.802h-6.307v-8.783h-1.802v10.585H62.951z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M62.5,34.122h-2.478v-1.126H62.5V34.122z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.15, 0.421875, 147.0, 0.29, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.609375, 99.0, 0.12, 0.4739583333333333, 133.0, 0.22, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.5052083333333334, 125.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Layer_7",[0.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Motor_Holder",[0.0, 0.609375, 99.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Vent",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0, 8.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Mixer",[0.0, 0.609375, 99.0, 1.0, 0.40625, 151.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.40625, 151.0, 4.0, 0.20833333333333334, 201.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.20833333333333334, 201.0, 7.0, 0.20833333333333334, 201.0, 8.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Clamp",[0.0, 0.8072916666666666, 49.0, 1.0, 0.40625, 151.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.40625, 151.0]);
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 37.051" enable-background="new 0 0 112.5 37.051" xml:space="preserve">
        <g id="Group_Cylinder">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-2103.2495" y1="6067.0264" x2="-2102.2495" y2="6067.0264" gradientTransform="matrix(111.4873 0 0 -111.4873 234485.8438 676419.125)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M111.696,27.495c0,0-1.188,8.189-50.113,9.233c-2.815,0.061-8.447,0.063-11.261,0   c-50.113-1.13-50.113-9.233-50.113-9.233V8.913h111.487V27.495z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" stroke-miterlimit="10" d="M111.696,27.495c0,0-1.188,8.189-50.113,9.233   c-2.815,0.061-8.447,0.063-11.261,0c-50.113-1.13-50.113-9.233-50.113-9.233V8.913h111.487V27.495z" />
            <ellipse fill="#CCCCCC" cx="55.953" cy="8.801" rx="55.744" ry="8.108" />
            
                <ellipse fill="none" stroke="#4C4C4C" stroke-width="0.25" stroke-miterlimit="10" cx="55.953" cy="8.801" rx="55.744" ry="8.108" />
        </g>
        <g id="Group_Liquid">
            <path fill="#A6BAD9" d="M56.225,3.755c29.604,0,53.604,3.546,53.604,6.95c0,0-8.88,5.962-53.604,5.962   c-44.276,0-53.604-5.829-53.604-5.829C2.621,7.435,26.621,3.755,56.225,3.755z" />
        </g>
        <g id="Group_Rotator">
            <g>
                <rect x="2.815" y="10.361" fill="#4D4D4D" width="44.144" height="0.563" />
                <polygon fill="#CCCCCC" points="2.815,8.672 46.959,8.672 46.959,10.361 2.219,10.361   " />
            </g>
        </g>
        <g id="Group_Axial">
            <g>
                
                    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-2103.2588" y1="6067.0059" x2="-2102.2588" y2="6067.0059" gradientTransform="matrix(9.2339 0 0 -9.2339 19472.8594 56036.3594)">
                    <stop offset="0" style="stop-color:#263A59" />
                    <stop offset="0.54" style="stop-color:#A6BAD9" />
                    <stop offset="0.63" style="stop-color:#93A7C6" />
                    <stop offset="0.81" style="stop-color:#627695" />
                    <stop offset="1" style="stop-color:#263A59" />
                </linearGradient>
                <rect x="51.577" y="11.826" fill="url(#SVGID_2_)" width="9.234" height="4.841" />
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-2103.2627" y1="6066.9961" x2="-2102.2627" y2="6066.9961" gradientTransform="matrix(18.4683 0 0 -18.4683 38890.6445 112057.1719)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.19" style="stop-color:#818285" />
                    <stop offset="0.4" style="stop-color:#A4A5A8" />
                    <stop offset="0.51" style="stop-color:#B1B3B6" />
                    <stop offset="0.73" style="stop-color:#8C8E91" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <path fill="url(#SVGID_3_)" d="M46.959,11.037V7.771h18.468v3.266c0,0-1.553,1.351-5.743,1.351c-1.745,0-5.236,0-6.982,0    C48.125,12.388,46.959,11.037,46.959,11.037z" />
                <ellipse fill="#E5E5E5" cx="56.194" cy="7.771" rx="9.234" ry="1.464" />
                <polyline fill="none" stroke="#7F7F7F" stroke-width="0.25" points="61.374,7.771 61.036,8.109 59.91,8.335 58.108,8.561     56.194,8.561 54.279,8.561 52.478,8.335 51.352,8.109 51.014,7.771   " />
            </g>
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);

        super.drawObject();
    }
}
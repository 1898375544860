import { SVGObject } from "../svgobject";

export class SVGHeating20 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 49.211 112.5" enable-background="new 0 0 49.211 112.5" xml:space="preserve">
        <g id="Group_Heat_Recovery_System">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.882813e-004" y1="57.2383" x2="49.2114" y2="57.2383">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" points="3.491,6.957 0,107.521 49.211,107.521 45.608,6.957     " />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="3.4019" y1="3.9482" x2="45.8569" y2="3.9482">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.15" style="stop-color:#979797" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.84" style="stop-color:#9F9F9F" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1012" d="M3.402,7.359C4.148,3.257,13.356,0,24.605,0   c11.737,0,21.251,3.545,21.251,7.897" />
        </g>
        <g id="Group_Support">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M9.347,107.771h30.405v4.729H9.347V107.771z" />
        </g>
        <g id="Group_Lever">
            <path fill="#B2B2B2" d="M23.536,10.473l4.504,3.716h2.478v-2.59l-4.729-3.378L23.536,10.473z" />
            <path fill="#B2B2B2" d="M23.536,98.536l4.504,3.378h2.478v-2.252l-4.729-3.604L23.536,98.536z" />
            <path d="M23.536,8.221h2.252v2.252h-2.252V8.221z" />
            <path d="M23.536,96.059h2.252v2.478h-2.252V96.059z" />
        </g>
        <g id="Group_Mesh">
            <path fill="#333333" d="M11.599,18.581H37.5v65.766H11.599V18.581z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M37.5,18.581v65.766H11.599" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,18.581H11.599v65.766" />
            <path fill="#7F7F7F" d="M32.77,19.933h2.479v63.288H32.77V19.933z" />
            <path fill="#7F7F7F" d="M28.041,19.933h2.478v63.288h-2.478V19.933z" />
            <path fill="#7F7F7F" d="M23.536,19.933h2.252v63.288h-2.252V19.933z" />
            <path fill="#7F7F7F" d="M18.807,19.933h2.252v63.288h-2.252V19.933z" />
            <path fill="#7F7F7F" d="M14.077,19.933h2.252v63.288h-2.252V19.933z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M36.374,63.176v2.478H12.951v-2.478H36.374z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M36.374,39.752v2.252H12.951v-2.252H36.374z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.15, 0.8229166666666666, 45.0, 0.5, 0.25, 191.0, 0.84, 0.7604166666666666, 61.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Lever",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Mesh",[0.0, 0.3958333333333333, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.9895833333333334, 0.0, 6.0, 0.9895833333333334, 0.0, 7.0, 0.9895833333333334, 0.0]);
	
	
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGValve11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Ports">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.0063" y1="112.5059" x2="1.0063" y2="112.5059" gradientTransform="matrix(0 -28.1533 -28.1533 0 3223.6685 98.5904)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect y="70.27" fill="url(#SVGID_1_)" width="112.5" height="28.154" />
        </g>
        <g id="Group_Gate_Valve">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="112.5" x2="1" y2="112.5" gradientTransform="matrix(37.5 0 0 -37.5 37.5 4228.0938)">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.5" style="stop-color:#FF0000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <rect x="37.5" fill="url(#SVGID_2_)" width="37.5" height="18.694" />
            <rect x="46.847" y="18.139" fill="none" width="18.694" height="38.609" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0156" y1="112.5156" x2="1.0156" y2="112.5156" gradientTransform="matrix(18.6943 0 0 -18.6943 46.5516 2140.8545)">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.5" style="stop-color:#FF0000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <rect x="46.847" y="18.139" fill="url(#SVGID_3_)" width="18.694" height="38.609" />
            <rect x="37.5" y="56.194" fill="none" width="37.499" height="56.306" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-0.0049" y1="112.4951" x2="0.9951" y2="112.4951" gradientTransform="matrix(0 -56.3057 -56.3057 0 6390.3794 112.2546)">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.5" style="stop-color:#FF0000" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <rect x="37.5" y="56.194" fill="url(#SVGID_4_)" width="37.499" height="56.306" />
            <polygon fill="#7F0000" points="39.752,41.933 46.846,46.847 65.541,46.847 72.635,41.933  " />
        </g>
        <g id="Group_Inlet_Flange">
            <rect x="21.059" y="58.559" fill="none" width="9.347" height="51.576" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-0.0039" y1="112.4961" x2="0.9961" y2="112.4961" gradientTransform="matrix(0 -51.5762 -51.5762 0 5827.8242 109.918)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="21.059" y="58.559" fill="url(#SVGID_5_)" width="9.347" height="51.576" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-0.0034" y1="112.4961" x2="0.9966" y2="112.4961" gradientTransform="matrix(0 -46.8467 -46.8467 0 5279.394 107.6127)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="4.617" y="60.924" fill="url(#SVGID_6_)" width="9.347" height="46.848" />
        </g>
        <g id="Group_Outlet_Flange">
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="-0.0039" y1="112.498" x2="0.9961" y2="112.498" gradientTransform="matrix(0 -51.5762 -51.5762 0 5888.8867 109.918)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="81.982" y="58.559" fill="url(#SVGID_7_)" width="9.346" height="51.576" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-0.0034" y1="112.4971" x2="0.9966" y2="112.4971" gradientTransform="matrix(0 -46.8467 -46.8467 0 5373.2065 107.6127)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="98.424" y="60.924" fill="url(#SVGID_8_)" width="9.348" height="46.848" />
        </g>
        <g id="Group_Flange">
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0.0068" y1="112.5078" x2="1.0068" y2="112.5078" gradientTransform="matrix(32.8823 0 0 -32.8823 39.5234 3736.9141)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="39.753" y="32.77" fill="url(#SVGID_9_)" width="32.882" height="9.347" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3229166666666667, 0.0, 0.5, 0.6614583333333334, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3229166666666667, 0.0, 0.5, 0.6614583333333334, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3229166666666667, 0.0, 0.5, 0.6614583333333334, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
        super.drawObject();
    }
}
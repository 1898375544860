import { SVGObject } from "../svgobject";

export class SVGDuct7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Base_Layer">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M104.393,50.788L42.68,112.5L0,69.707L61.6,7.883L104.393,50.788z" />
        </g>
        <g id="Group_Top_Layer">
            <path fill="#BFBFBF" d="M101.689,50.788L61.6,10.473L52.14,60.248L101.689,50.788z" />
        </g>
        <g id="Group_Right_Layer">
            <path fill="#D9D9D9" d="M52.14,60.021l49.549-9.233L42.68,109.797L52.14,60.021z" />
        </g>
        <g id="Group_Left_Layer">
            <path fill="#CCCCCC" d="M42.68,109.797L2.478,69.482l49.662-9.234L42.68,109.797z" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M64.076,0l-7.882,7.883l1.351,1.464l7.883-8.108L64.076,0z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M111.148,46.734l-8.107,8.108l1.352,1.352l8.107-8.108   L111.148,46.734z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M111.148,52.14l-2.703,2.703L57.32,3.941l2.701-2.703L111.148,52.14z   " />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Base_Layer",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Top_Layer",[0.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Right_Layer",[0.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.fills,"Group_Left_Layer",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.609375, 99.0]);
		

        super.drawObject();
    }
}
import { TwoStateObject } from './twostateobject';
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";

export class EndPipe2 extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            
            const rect = new Konva.Rect({
                x: 0,
                y:this.height/5,
                width: this.width/10,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth:1
                
              });
              if(this.type3d==0){
                    rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rect.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    rect.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
                
              }else{
                rect.fill(fillcolor2)
              }
              this.node.add(rect)

    
        const rectVertical = new Konva.Rect({
            x: 0,
            y:this.height/5,
            width: this.width/50,
            height: this.height*3/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })
        const rectVertical2 = new Konva.Rect({
            x: this.width*4/50,
            y:this.height/5,
            width: this.width/50,
            height: this.height*3/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
        })
        if(this.type3d==0){
                rectVertical.fillLinearGradientStartPoint({ x: 0, y: 0 });
                rectVertical.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                rectVertical.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, 'white',
                    1, fillcolor2])
                rectVertical2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                rectVertical2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                rectVertical2.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, 'white',
                    1, fillcolor2])
          }else{
            rectVertical.fill(ColorUtils.darkColor(fillcolor2, 0.5))
            rectVertical2.fill(ColorUtils.darkColor(fillcolor2, 0.5))
          }
          this.node.add(rectVertical, rectVertical2)

          const pipe2 = new Konva.Rect({
            x: this.width/10,
            y:this.height*3/10,
            width: this.width*2/5,
            height: this.height*4/10,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
          })
          if(this.type3d==0){
                pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            pipe2.fill(fillcolor2)
          }
          this.node.add(pipe2)
        }
}
import { SVGObject } from "../svgobject";

export class SVGBlower6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 84.348 112.5" enable-background="new 0 0 84.348 112.5" xml:space="preserve">
        <g id="Group_11">
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="38.176,15.203 36.824,3.716 78.941,3.716 76.689,15.203 76.689,53.716    " />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-0.0015" y1="112.498" x2="0.9985" y2="112.498" gradientTransform="matrix(42.1172 0 0 -42.1172 36.9166 4766.8071)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="36.824" y="3.716" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="42.117" height="50" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_4_" x="30.519" width="53.829" height="3.716" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="4.882813e-004" y1="112.5" x2="1.0005" y2="112.5" gradientTransform="matrix(53.8291 0 0 -53.8291 30.5001 6057.6763)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="30.519" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="53.829" height="3.716" />
            </g>
        </g>
        <g id="Group_10">
            <rect x="63.176" y="56.194" fill="#BABABA" width="1.126" height="56.306" />
            <rect x="10.473" y="56.194" fill="#BABABA" width="1.126" height="56.306" />
            <g>
                <defs>
                    <rect id="SVGID_7_" x="11.6" y="58.445" width="51.576" height="51.803" />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-0.0039" y1="112.4971" x2="0.9961" y2="112.4971" gradientTransform="matrix(51.5762 0 0 -51.5762 11.8274 5886.4805)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="11.6" y="58.445" clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="51.576" height="51.803" />
            </g>
        </g>
        <g id="Group_9">
            <rect x="72.636" y="35.022" fill="#B5B5B5" width="2.252" height="2.478" />
            <rect x="36.149" y="84.572" fill="#CCCCCC" width="4.054" height="18.469" />
        </g>
        <g id="Group_8">
            <g>
                <defs>
                    <path id="SVGID_10_" d="M0.113,53.603c0,21.208,17.193,38.401,38.401,38.401c21.207,0,38.4-17.192,38.4-38.401     c0-21.208-17.193-38.4-38.4-38.4C17.306,15.203,0.113,32.395,0.113,53.603" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_12_" cx="-0.002" cy="112.501" r="1" gradientTransform="matrix(38.4004 0 0 -38.4004 38.5893 4373.6914)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="0.113" y="15.203" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="76.801" height="76.801" />
            </g>
        </g>
        <g id="Group_7">
            <g>
                <defs>
                    <rect id="SVGID_13_" width="84.348" height="112.5" />
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_14_)" fill="#E5E5E5" d="M71.398,53.604c0,18.224-14.773,32.996-32.997,32.996    c-18.223,0-32.996-14.772-32.996-32.996c0-18.223,14.773-32.996,32.996-32.996C56.625,20.607,71.398,35.38,71.398,53.604" />
            </g>
        </g>
        <g id="Group_6">
            <g>
                <defs>
                    <rect id="SVGID_15_" x="6.87" y="110.248" width="61.035" height="2.252" />
                </defs>
                <clipPath id="SVGID_16_">
                    <use xlink:href="#SVGID_15_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-0.0054" y1="112.4951" x2="0.9946" y2="112.4951" gradientTransform="matrix(61.0352 0 0 -61.0352 7.2069 6977.4976)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="6.87" y="110.248" clip-path="url(#SVGID_16_)" fill="url(#SVGID_17_)" width="61.035" height="2.252" />
            </g>
        </g>
        <g id="Group_5">
            <g>
                <defs>
                    <rect id="SVGID_18_" width="84.348" height="112.5" />
                </defs>
                <clipPath id="SVGID_19_">
                    <use xlink:href="#SVGID_18_" overflow="visible" />
                </clipPath>
                <polygon clip-path="url(#SVGID_19_)" fill="#A3A3A3" points="69.032,51.464 40.203,51.464 40.203,22.635 36.149,22.635     36.149,51.464 7.094,51.464 7.094,55.743 36.149,55.743 36.149,84.572 40.203,84.572 40.203,55.743 69.032,55.743   " />
            </g>
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <path id="SVGID_20_" d="M15.203,53.603c0,12.75,10.336,23.087,23.086,23.087c12.749,0,23.085-10.338,23.085-23.087     c0-12.749-10.336-23.085-23.085-23.085C25.539,30.518,15.203,40.854,15.203,53.603" />
                </defs>
                <clipPath id="SVGID_21_">
                    <use xlink:href="#SVGID_20_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_22_" cx="-0.002" cy="112.4971" r="1" gradientTransform="matrix(23.0857 0 0 -23.0857 38.3292 2650.6917)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="15.203" y="30.518" clip-path="url(#SVGID_21_)" fill="url(#SVGID_22_)" width="46.171" height="46.172" />
            </g>
        </g>
        <g id="Group_3">
            <g>
                <defs>
                    <path id="SVGID_23_" d="M23.536,53.603c0,8.148,6.606,14.753,14.753,14.753c8.146,0,14.752-6.605,14.752-14.753     c0-8.147-6.605-14.752-14.752-14.752C30.142,38.851,23.536,45.456,23.536,53.603" />
                </defs>
                <clipPath id="SVGID_24_">
                    <use xlink:href="#SVGID_23_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_25_" cx="-0.0015" cy="112.498" r="1" gradientTransform="matrix(14.7522 0 0 -14.7522 38.3031 1713.2107)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#000000" />
                    <stop offset="1" style="stop-color:#333333" />
                </radialGradient>
                <rect x="23.536" y="38.851" clip-path="url(#SVGID_24_)" fill="url(#SVGID_25_)" width="29.505" height="29.505" />
            </g>
        </g>
        <g id="Group_2">
            <g>
                <defs>
                    <rect id="SVGID_26_" width="84.348" height="112.5" />
                </defs>
                <clipPath id="SVGID_27_">
                    <use xlink:href="#SVGID_26_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_27_)" fill="#666666" d="M38.289,50.113c1.928,0,3.49,1.563,3.49,3.491    c0,1.928-1.562,3.491-3.49,3.491c-1.929,0-3.491-1.563-3.491-3.491C34.798,51.676,36.36,50.113,38.289,50.113" />
                
                    <rect x="6.37" y="35.199" transform="matrix(-0.848 -0.53 0.53 -0.848 -5.4814 70.951)" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.125" height="2.125" />
                <polygon clip-path="url(#SVGID_27_)" fill="#B5B5B5" points="20.608,21.959 21.734,23.762 19.933,24.888 18.807,22.86   " />
                <rect x="36.825" y="17.455" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.027" height="2.252" />
                <rect x="39.302" y="5.743" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.253" height="2.478" />
                
                    <rect x="19.434" y="83.397" transform="matrix(-0.5302 0.8479 -0.8479 -0.5302 102.9747 111.8576)" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.124" height="2.124" />
                <polygon clip-path="url(#SVGID_27_)" fill="#B5B5B5" points="5.969,71.283 7.996,70.158 8.897,71.959 7.095,73.086   " />
                <rect x="1.689" y="52.815" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.027" height="2.252" />
                <rect x="37.05" y="88.176" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.027" height="2.027" />
                
                    <rect x="54.737" y="83.34" transform="matrix(0.447 0.8945 -0.8945 0.447 106.2757 -3.2217)" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.014" height="2.014" />
                <polygon clip-path="url(#SVGID_27_)" fill="#B5B5B5" points="69.032,72.86 67.23,71.734 68.131,69.933 70.158,71.059   " />
                <rect x="72.185" y="52.59" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.254" height="2.252" />
                <rect x="73.762" y="5.743" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.252" height="2.252" />
                <rect x="72.636" y="19.933" clip-path="url(#SVGID_27_)" fill="#B5B5B5" width="2.252" height="2.252" />
                <polygon clip-path="url(#SVGID_27_)" fill="#4C4C4C" points="55.068,93.693 54.167,93.355 53.829,92.567 54.167,91.666     55.068,91.329 55.856,91.666 56.194,92.567 55.856,93.355   " />
                <polygon clip-path="url(#SVGID_27_)" fill="#4C4C4C" points="55.068,103.041 54.279,102.703 53.941,101.914 54.279,101.126     55.068,100.788 55.856,101.126 56.194,101.914 55.856,102.703   " />
            </g>
        </g>
        <g id="Group_1">
            <g>
                <defs>
                    <path id="SVGID_28_" d="M39.463,43.715l-5.147,19.456l-0.013,0.042c-0.562,2.119-0.541,3.955,0.042,4.105     c0.587,0.148,1.482-1.447,2.045-3.567l5.116-19.5c0.562-2.119,0.562-3.94-0.022-4.089c-0.026-0.006-0.051-0.009-0.078-0.009     C40.824,40.153,39.999,41.687,39.463,43.715" />
                </defs>
                <clipPath id="SVGID_29_">
                    <use xlink:href="#SVGID_28_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="0.0708" y1="112.3418" x2="1.0709" y2="112.3418" gradientTransform="matrix(2.1071 0.5503 0.5503 -2.1071 -25.1407 290.2068)">
                    <stop offset="0" style="stop-color:#808285" />
                    <stop offset="0.01" style="stop-color:#808285" />
                    <stop offset="0.51" style="stop-color:#C0C2C4" />
                    <stop offset="1" style="stop-color:#808285" />
                </linearGradient>
                <polygon clip-path="url(#SVGID_29_)" fill="url(#SVGID_30_)" points="34.273,38.117 48.746,41.897 41.536,69.503 27.063,65.723       " />
            </g>
            <g>
                <defs>
                    <path id="SVGID_31_" d="M28.318,43.998c-0.424,0.425,0.487,1.985,2.026,3.548L44.461,61.89l0.031,0.033     c1.535,1.563,3.129,2.482,3.555,2.055c0.423-0.428-0.492-2.013-2.03-3.576L31.848,46.06c-1.284-1.304-2.587-2.166-3.231-2.166     C28.49,43.894,28.388,43.928,28.318,43.998" />
                </defs>
                <clipPath id="SVGID_32_">
                    <use xlink:href="#SVGID_31_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="-0.1172" y1="112.7051" x2="0.8829" y2="112.7051" gradientTransform="matrix(1.5474 -1.5323 -1.5323 -1.5474 210.3601 229.0634)">
                    <stop offset="0" style="stop-color:#808285" />
                    <stop offset="0.01" style="stop-color:#808285" />
                    <stop offset="0.51" style="stop-color:#C0C2C4" />
                    <stop offset="1" style="stop-color:#808285" />
                </linearGradient>
                <polygon clip-path="url(#SVGID_32_)" fill="url(#SVGID_33_)" points="17.639,54.049 38.282,33.606 58.725,54.25 38.082,74.692       " />
            </g>
            <g>
                <defs>
                    <path id="SVGID_34_" d="M47.284,49.353L28.222,55.81l-0.042,0.012c-2.075,0.702-3.597,1.736-3.399,2.303     c0.2,0.569,2.026,0.436,4.104-0.267l19.083-6.505c2.075-0.702,3.594-1.71,3.393-2.278c-0.082-0.238-0.445-0.351-0.991-0.351     C49.609,48.724,48.494,48.944,47.284,49.353" />
                </defs>
                <clipPath id="SVGID_35_">
                    <use xlink:href="#SVGID_34_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="-0.1294" y1="112.584" x2="0.8707" y2="112.584" gradientTransform="matrix(0.7056 2.0623 2.0623 -0.7056 -194.4045 132.2753)">
                    <stop offset="0" style="stop-color:#808285" />
                    <stop offset="0.01" style="stop-color:#808285" />
                    <stop offset="0.51" style="stop-color:#C0C2C4" />
                    <stop offset="1" style="stop-color:#808285" />
                </linearGradient>
                <polygon clip-path="url(#SVGID_35_)" fill="url(#SVGID_36_)" points="48.734,40.46 54.616,57.649 27.411,66.958 21.529,49.769       " />
            </g>
            <g>
                <defs>
                    <path id="SVGID_37_" d="M34.691,52.685c-0.57,1.804,0.432,3.727,2.234,4.296c1.806,0.566,3.728-0.432,4.298-2.239     c0.567-1.805-0.433-3.727-2.239-4.297c-0.341-0.107-0.686-0.158-1.026-0.158C36.503,50.287,35.149,51.224,34.691,52.685" />
                </defs>
                <clipPath id="SVGID_38_">
                    <use xlink:href="#SVGID_37_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_39_" cx="0.2334" cy="112.5244" r="1" gradientTransform="matrix(3.4064 0.3596 0.3596 -3.4064 -3.3034 436.9259)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#C0C2C4" />
                    <stop offset="0.49" style="stop-color:#BEC0C2" />
                    <stop offset="0.67" style="stop-color:#B7B9BB" />
                    <stop offset="0.8" style="stop-color:#ACAEB0" />
                    <stop offset="0.9" style="stop-color:#9B9D9F" />
                    <stop offset="0.98" style="stop-color:#85878A" />
                    <stop offset="1" style="stop-color:#808285" />
                </radialGradient>
                <polygon clip-path="url(#SVGID_38_)" fill="url(#SVGID_39_)" points="34.206,49.486 42.548,50.367 41.706,58.349 33.363,57.468       " />
            </g>
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[]);
		this.fillGradient(this.gradients,"SVGID_2_",[]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[]);
		this.fillGradient(this.gradients,"SVGID_5_",[]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[]);
		this.fillGradient(this.gradients,"SVGID_8_",[]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[]);
		this.fillGradient(this.gradients,"SVGID_11_",[]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[]);
		this.fillGradient(this.gradients,"SVGID_14_",[]);
		this.fillGradient(this.gradients,"SVGID_15_",[]);
		this.fillGradient(this.gradients,"SVGID_16_",[]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[]);
		this.fillGradient(this.gradients,"SVGID_19_",[]);
		this.fillGradient(this.gradients,"SVGID_20_",[]);
		this.fillGradient(this.gradients,"SVGID_21_",[]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[]);
		this.fillGradient(this.gradients,"SVGID_24_",[]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.0, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[]);
		this.fillGradient(this.gradients,"SVGID_27_",[]);
		this.fillGradient(this.gradients,"SVGID_28_",[]);
		this.fillGradient(this.gradients,"SVGID_29_",[]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.984375, 3.0, 0.01, 0.984375, 3.0, 0.51, 0.484375, 131.0, 1.0, 0.984375, 3.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[]);
		this.fillGradient(this.gradients,"SVGID_32_",[]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.984375, 3.0, 0.01, 0.984375, 3.0, 0.51, 0.484375, 131.0, 1.0, 0.984375, 3.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[]);
		this.fillGradient(this.gradients,"SVGID_35_",[]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.984375, 3.0, 0.01, 0.984375, 3.0, 0.51, 0.484375, 131.0, 1.0, 0.984375, 3.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[]);
		this.fillGradient(this.gradients,"SVGID_38_",[]);

        super.drawObject();
    }
}
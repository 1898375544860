import { SVGObject } from "../svgobject";

export class SVGFinish5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 57.32" enable-background="new 0 0 112.5 57.32" xml:space="preserve">
        <g id="Group_Rear_Stand">
            <path fill="#0000E0" stroke="#4C4C4C" stroke-width="0.25" d="M111.937,52.589l-1.126,0.9V31.081l1.126-0.788V52.589z" />
            <path fill="#0000E0" stroke="#4C4C4C" stroke-width="0.25" d="M9.009,52.589l-0.788,0.9V31.081l0.788-0.788V52.589z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M108.333,53.49h2.478V31.081h-2.478V53.49z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M5.406,53.49h2.815V31.081H5.406V53.49z" />
        </g>
        <g id="Group_Bottom_Collector">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M4.055,33.558h99.887L66.666,53.49H41.554L4.055,33.558z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M109.121,32.207l-5.18,1.352L66.666,53.49l10.474-3.604   L109.121,32.207z" />
        </g>
        <g id="Group_Stand">
            <path fill="#0000E0" stroke="#4C4C4C" stroke-width="0.25" d="M4.393,56.418L3.492,57.32V34.91l0.901-0.789V56.418z" />
            <path fill="#0000E0" stroke="#4C4C4C" stroke-width="0.25" d="M107.432,56.418l-0.788,0.901V34.91l0.788-0.789V56.418z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,57.32h2.478V34.91H1.014V57.32z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M104.166,57.32h2.478V34.91h-2.478V57.32z" />
        </g>
        <g id="Group_Powder_System">
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M0,4.617H107.77V34.91H0V4.617z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M0,4.617H107.77L112.5,0H4.618L0,4.617z" />
            <path fill="#0000E0" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,0l-4.73,4.617V34.91l4.73-4.617V0z" />
        </g>
        <g id="Group_Label">
            <path fill="none" stroke="#6699FF" stroke-width="0.25" d="M90.428,17.567V9.009h3.828" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M90.428,17.567h3.828V9.009" />
            <path fill="none" stroke="#6699FF" stroke-width="0.25" d="M95.607,17.567V9.009h3.829" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M95.607,17.567h3.829V9.009" />
        </g>
        <g id="Group_Door">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.941,33.896h18.807V5.968" />
            <path fill="none" stroke="#6699FF" stroke-width="0.25" d="M34.459,33.896V5.968h18.468" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M34.459,33.896h18.468V5.968" />
            <path fill="none" stroke="#6699FF" stroke-width="0.25" d="M53.941,33.896V5.968h18.807" />
        </g>
        <g id="Group_Door_Handle">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M50.113,18.468h1.914v2.928h-1.914V18.468z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M50.113,19.482h1.914v0.563h-1.914V19.482z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M55.067,18.468h1.915v2.928h-1.915V18.468z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M55.067,19.482h1.915v0.563h-1.915V19.482z" />
        </g>
        <g id="Group_Reader_Control">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M2.14,6.531h14.302v14.415l-0.451,0.225L1.915,7.095L2.14,6.531z" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M1.577,7.095h14.414v14.076H1.577V7.095z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M6.532,16.216l-1.126,0.226l-0.225,1.126l0.225,0.901l1.126,0.451l0.901-0.451   l0.563-0.901l-0.563-1.126L6.532,16.216z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M11.262,16.216l-0.788,0.226l-0.563,1.126l0.563,0.901l0.788,0.451l1.126-0.451   l0.226-0.901l-0.226-1.126L11.262,16.216z" />
            <path fill="#FF0000" d="M6.532,16.441l-0.789,0.338l-0.338,0.788l0.338,0.901l0.789,0.225l0.901-0.225l0.225-0.901l-0.225-0.788   L6.532,16.441z" />
            <path fill="#00FF00" d="M11.262,16.441l-0.788,0.338l-0.338,0.788l0.338,0.901l0.788,0.225l0.788-0.225l0.338-0.901l-0.338-0.788   L11.262,16.441z" />
            <path d="M3.83,8.221h9.572l-1.352,5.518H5.744L3.83,8.221z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M4.618,8.446h8.333l-0.901,3.378H5.744L4.618,8.446z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.573,9.572l-0.789,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M5.181,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M5.969,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.095,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.996,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M8.784,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.911,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.699,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.487,8.784v0.563" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.613,8.784v0.563" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";


export class BarChartTile extends Tile{
    minimum: number;
    maximum: number;
    decimalpos: number;
    sectors: any [];
    uselegends: boolean;


  public drawObject():void{
    super.initObject();

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    if(this.sectors.length != 0){
      this.sectors.forEach((sec)=>{
        if (sec['path']==null || tagsModele.getTag(sec['path'])==null) return;
        if (!this.tagnames.includes(sec['path'])) this.tagnames.push(sec['path'])
      })
    }
    
    if(this.sectors.length > 1){
      this.sectors.sort(function(a, b) {
        if (a==null || b==null) return 0;
        const keyA = tagsModele.getTag(a['path'])
        const keyB = tagsModele.getTag(b['path'])
        if (keyA==null || keyB==null || keyA.tagvalue==null || keyB.tagvalue==null) return 0;
        
        if (Number(keyA.tagvalue) < Number(keyB.tagvalue)) return 1;
        if (Number(keyA.tagvalue) > Number(keyB.tagvalue)) return -1;
        return 0;
      });
    }
    
    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      }
    });
     
    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
      x: size*.05,
      y: size*0.1 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(titleText) 

    if(this.description){
      const text2 = new Konva.Text({
        x: size*.05,
        y: this.height - size*0.05 - 0.06*size,
        width: this.width-size*.05*2,
        verticalAlign: 'bottom',
        text: this.description,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left',
      }); 
      group.add(text2) 
    }
    
    const starty = this.height/5;
		const stepy = this.height*3/5/this.sectors.length;

    for (let i=0;i<this.sectors.length;i++) {
			const y = starty+stepy*i+stepy*.5;

      const barback = new Konva.Line({
        points: [size*.05, y, this.width-size*.05,y],
        stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
        strokeWidth: stepy*.02,
      });
      group.add(barback)
			
		
			let x = size*.05;
			const sec= this.sectors[i];
      const tag:tag = tagsModele.getTag(sec['path'])
      
  
			let percent = 0;
			if (tag !=null && tag.tagvalue!=null){ 
        const value = Number(tag.tagvalue)
     
				percent = (Number(tag.tagvalue)-this.minimum)/(this.maximum-this.minimum);


        const valueText = new Konva.Text({
          x: size*.05,
          y: starty+stepy*i+stepy*.25-stepy*.5,
          width: this.width-size*.05*2,
          verticalAlign: 'bottom',
          text: parseFloat(value.toFixed(this.decimalpos)).toLocaleString(),
          fontSize: stepy*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
          align: 'right',
        }); 
        group.add(valueText) 
			}

      if (this.uselegends){
        const labelText = new Konva.Text({
          x: size*.05,
          y: starty+stepy*i+stepy*.25-stepy*.5,
          width: this.width-size*.05*2,
          verticalAlign: 'bottom',
          text: sec['sectorname'],
          fontSize: stepy*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
          align: 'left',
        }); 
        group.add(labelText) 

      if (percent<0) x = size*.05;
			else if (percent>1) x = this.width-size*.05;
			else x = size*.05+(this.width-size*.1)*percent;
			if (percent>0) {
        const bar = new Konva.Line({
          points: [size*.05,y,x,y],
          stroke:  ColorUtils.convertformat(sec['color']),
          strokeWidth: stepy*.2,
        });
        group.add(bar)
			}
			}
    }
    this.node.add(group)
  }
}


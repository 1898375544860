import { SVGObject } from "../svgobject";

export class SVGValve15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 43.8" enable-background="new 0 0 112.5 43.8" xml:space="preserve">
        <g id="Group_PortAndBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="28.0981" y1="43.8047" x2="28.0981" y2="15.6533">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.22" style="stop-color:#7F7F7F" />
                <stop offset="0.32" style="stop-color:#D9D9D9" />
                <stop offset="0.69" style="stop-color:#D9D9D9" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0.002,15.653h56.192v28.152H0.002V15.653" />
        </g>
        <g id="Group_2">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="13.9653" y1="43.8047" x2="13.9653" y2="15.6533">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M18.583,15.653H9.349v28.152h9.234V15.653" />
        </g>
        <g id="Group_Box">
            <path fill="#666666" d="M18.583,36.823v6.981h1.351v-5.855h25.675v-1.126H18.583z" />
            <path fill="#999999" d="M45.608,23.125l-1.126-0.49v14.188l1.126,1.126V23.125z" />
            <path fill="#CCCCCC" d="M18.583,22.635h25.9v14.188h-25.9V22.635z" />
        </g>
        <g id="Group_1">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="26.9155" y1="7.0137" x2="36.3745" y2="7.0137">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="35.023,3.378 35.023,2.815 28.042,2.815 28.042,3.378 26.916,3.378 26.916,11.211    36.375,11.211 36.375,3.378  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="18.5825" y1="13.626" x2="33.625" y2="13.626">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M18.583,16.328v-5.405h12.187l2.856-0.337v6.081l-2.856-0.338H18.583z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="26.9155" y1="5.1802" x2="36.3745" y2="5.1802">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M26.916,3.378h9.459v3.604h-9.459V3.378z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="25.9341" y1="13.6187" x2="37.5005" y2="13.6187">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M33.85,10.135c-0.967-0.11-2.925-0.091-3.894,0c-1.019,0.095-4.022,0.759-4.022,0.759v5.463   c0,0,3.005,0.649,4.022,0.76c0.812,0.088,2.452,0.074,3.265,0c1.083-0.099,4.279-0.789,4.279-0.789v-5.471   C37.5,10.857,34.774,10.24,33.85,10.135z" />
        </g>
        <g id="Group_Handle1">
            <path fill="#B2B2B2" d="M23.312,10.923c0,0,0.079-1.317,0.719-1.724c0.6-0.381,13.171,0.012,16.397-0.078   c0.32-0.009,0.789-0.559,1.013-0.788c0.76-0.777,1.625-2.124,2.148-2.846c0.91-1.257,1.207-1.434,1.343-1.434   c0.619,0,4.401,0,4.401,0V1.577c0,0-4.646-0.034-5.527,0c-0.361,0.014-0.752,0.524-1.104,0.933   c-0.762,0.885-2.172,3.292-3.175,4.36c-0.106,0.113-17.342,0.113-17.342,0.113s-0.659,0.358-0.789,0.563   c-0.168,0.266-0.338,2.252-0.338,2.252v1.126H23.312" />
        </g>
        <g id="Group_Handle">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="79.9541" y1="5.7432" x2="79.9541" y2="0">
                <stop offset="0" style="stop-color:#D94000" />
                <stop offset="0.45" style="stop-color:#FF8C00" />
                <stop offset="0.62" style="stop-color:#FF7900" />
                <stop offset="0.77" style="stop-color:#FF6600" />
                <stop offset="1" style="stop-color:#D94000" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M47.41,0h65.088v5.743H47.41V0" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.22, 0.9895833333333334, 0.0, 0.32, 0.3020833333333333, 177.0, 0.69, 0.3020833333333333, 177.0, 0.77, 0.703125, 75.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.9791666666666666, 5.0, 0.62, 0.9739583333333334, 0.0, 0.77, 0.9270833333333334, 0.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Box",[0.0, 0.7916666666666666, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.40625, 151.0]);
        super.drawObject();
    }
}
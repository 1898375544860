import { SVGObject } from "../svgobject";

export class SVGPaper17 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 84.996" enable-background="new 0 0 112.5 84.996" xml:space="preserve">
        <g id="Group_Paper">
            <path fill="#FAFAFA" stroke="#4C4C4C" stroke-width="0.25" d="M90.18,68.988L41.483,83.643l-8.567,1.354L27.054,8.793l50.049-8.341   L80.373,0c0,0,9.359-1.334,18.395,5.662c4.412,3.417,9.497,8.774,11.928,17.334c2.903,10.226,1.736,19.332-0.53,25.789   c-3.191,9.094-8.678,13.556-9.049,13.89c-1.412,1.271-3.39,2.909-6.2,4.396C92.545,68.324,90.18,68.988,90.18,68.988z" />
            <ellipse fill="#D6DBE5" stroke="#4C4C4C" stroke-width="0.25" cx="32.916" cy="46.443" rx="32.916" ry="38.439" />
        </g>
        <g id="Group_Roller"> 
                <ellipse fill="#333333" cx="32.916" cy="46.556" rx="2.705" ry="3.269" /> 
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.fills,"Group_Paper",[0.0, 0.046875, 243.0, 1.0, 0.28125, 183.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe26 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 49.211" enable-background="new 0 0 112.5 49.211" xml:space="preserve">
        <g id="Group_Port2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="81.8525" y1="42.0044" x2="81.8525" y2="6.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_1_)" points="56.081,13.847 56.081,35.026 107.625,42.004 107.625,6.982  " />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="109.7969" y1="49.2114" x2="109.7969" y2="4.882813e-004">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M107.095,0h5.405v49.211h-5.405V0" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,44.032h1.803v1.126h-1.803V44.032z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,29.842h1.803v1.352h-1.803V29.842z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,15.428h1.803v1.351h-1.803V15.428z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,1.689h1.803v1.351h-1.803V1.689z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,3.041h1.803v2.252h-1.803V3.041z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,16.779h1.803v2.14h-1.803V16.779z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,31.194h1.803v2.252h-1.803V31.194z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M105.518,45.158h1.803v2.253h-1.803V45.158z" />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="28.0562" y1="35.0356" x2="28.0562" y2="13.8569">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="56.112,13.856 56.08,13.861 0,13.861 0,35.032 56.08,35.032 56.112,35.036  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1.7456" y1="42.23" x2="1.7456" y2="6.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M0,6.982h3.491V42.23H0V6.982" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,37.275h1.576v1.352H3.491V37.275z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,8.784h1.576v1.351H3.491V8.784z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,22.072h1.576v1.351H3.491V22.072z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,23.423h1.576v2.253H3.491V23.423z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,10.135h1.576v2.252H3.491V10.135z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M3.491,38.626h1.576v2.252H3.491V38.626z" />
        </g>
        <g id="Group_Joint">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="56.0811" y1="42.23" x2="56.0811" y2="6.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M53.716,6.982h4.729V42.23h-4.729V6.982" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
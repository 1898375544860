import { requestResponse} from './rsocketutils/rsocket.service'
import { requestStream } from './rsocketutils/rsocket.service';
import getscreenrequest from '@/model/requests/getscreenrequest';


class ScreenService {

 getScreen(request:getscreenrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void) {
    //console.log("token="+request.token + " screenname="+request.screenname);
    requestResponse(request,'screens/get', responseCallback,errorCallback); 
 }
 getScreenToSave(request:getscreenrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void) {
  requestResponse(request,'screens/save', responseCallback,errorCallback); 
}
deleteScreen(request:getscreenrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void) {
  requestResponse(request,'screens/delete', responseCallback,errorCallback); 
}
 getAllScreensInfo(token:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
   requestStream({
   
   },"screens/info/"+token,completeCallback,errorCallback);
 }
 
}

export default new ScreenService;
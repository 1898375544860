import { ObjectView } from "../object";

import { FillColorProperty } from "../properties/fillcolorproperty";
import { ButtonControlProperty } from "../properties/buttoncontrolproperty";
import Konva from "konva";
//import { Canvg, presets } from 'canvg';
import SVGGradient from "../../utils/svg/SVGGradient";
import { SVGColorUtils } from "../../utils/svg/SVGColorUtils";

/*const preset = presets.offscreen()
async function getCanvas(data):Promise<OffscreenCanvas> {
  const {
    width,
    height,
    svg
  } = data
  const canvas = new OffscreenCanvas(width, height)
  const ctx = canvas.getContext('2d')
  console.log(svg)
  const v = await Canvg.fromString(ctx, svg, preset)

  v.resize(width, height, 'none')
  // Render only first frame, ignoring animations and mouse.
  await v.render()
  return canvas;
}*/
export class SVGObject extends ObjectView{
    usefillcolor:boolean;
    fillcolor:string;

    svg:string;
    gradients:SVGGradient[]
    fills:SVGGradient[]
    
    public drawObject():void{

      const parser = new DOMParser();
      const doc = parser.parseFromString(this.svg, "image/svg+xml");
      doc.documentElement.setAttribute("width", this.width.toString())
      doc.documentElement.setAttribute("height", this.height.toString())
      doc.documentElement.setAttribute("preserveAspectRatio", "none");
        if (this.usefillcolor){
            
            let fillcolor = this.fillcolor;

            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            SVGColorUtils.changeColors(doc,fillcolor,this.gradients, this.fills)
            
          }
          this.svg = new XMLSerializer().serializeToString(doc)
      /*  if (doc.getElementById('SVGID_1_')!=null){
        const childNode = doc.getElementById('SVGID_1_').childNodes[3];
        if (childNode.nodeType === Node.ELEMENT_NODE) {
          const element = childNode as Element;
          const attributeValue = element.getAttribute('offset');
          console.log(attributeValue)
     
        }
      }
        console.log(doc.getElementById('SVGID_1_').childNodes[1])*/
   

      // const v = await Canvg.from(ctx, './svgs/1.svg');
     /* getCanvas({
        width:this.width,
        height: this.height,
        svg: this.svg
      }).then((canvas) => {
        const image = new Konva.Image({
          image: canvas,
           x: 0,
           y: 0,
            width: this.width,
           height: this.height
          });
          this.node.add(image);
          this.layer.batchDraw()
      })   */
      const url =  'data:image/svg+xml;base64,' + window.btoa(this.svg);

Konva.Image.fromURL(url, image => {
 /* image.setAttrs({
    width: this.width,
    height: this.height,
    
  });*/
  this.node.add(image);
  this.layer.draw();
})
      this.setFunctions()
    }
    protected fillGradients():void{
      this.gradients = []
      this.fills = []
    }
    protected fillGradient(list:SVGGradient[],id:string, array:number[]):void{
        if (array.length%3!=0) return;
        const grad = new SVGGradient(id);
        for (let i=0;i<array.length;i=i+3){
            grad.addStop(array[i], array[i+1], array[i+2]);
        }
        list.push(grad)
    }
    protected setFunctions():void{
      const controlprop = this.properties['buttoncontrol'];
      if (controlprop!=null){
        const controlproperty:ButtonControlProperty=Object.assign(new ButtonControlProperty(), controlprop);
        if (controlproperty!=null){
            this.node.on('mousedown touchstart', function () {
                   controlproperty.setClick();
              });
            this.node.on('mouseup touchend', function () {
               controlproperty.setReleased();
          });
        }
      }
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import Konva from "konva";
import tagsModele from "@/store/tags.modele";
import { PieChart } from "./piechart";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { Value } from '../value';


export class Diagram extends PieChart{
    fill:boolean;
    fillcolor:string;
    gridcolor:string;
    linestyle:string;
    gridlinewidth:number;
    horizontally:number;
    vertically:number;
    maximum:number;
    minimum:number;
    fontsize:number;
    markcolor:string;

    public drawObject():void{
		super.initObject();

        let dashLine:number[];
		switch(this.linestyle)
		{
			case "Dash": 
            dashLine = [6.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			case "Dot": 
            dashLine = [1.7*this.gridlinewidth, 1.7*this.gridlinewidth];
				break;
			case "DashDot": 
            dashLine = [6.5*this.gridlinewidth, 2*this.gridlinewidth, 1.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			default:
				break;
		}

        let fillcolor = '0xffffff00';
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)   
        } 
        
        const width = this.uselegends?this.width/2:this.width
        const height = this.height
        const sectors = this.sectors
        const fill = this.fill
        const vertically = this.vertically
        const maximum = this.maximum
        const minimum = this.minimum
        const gridcolor = this.gridcolor
        const markcolor = this.markcolor
        const gridlinewidth = this.gridlinewidth == 0? 1 : this.gridlinewidth
        const fontsize = this.fontsize
        const fontStyle = this.linestyle
        

        if(this.uselegends){
            const piechart = this.drawDiagramChart(fill,width, height, sectors, fillcolor, vertically, minimum, 
                maximum, gridcolor, gridlinewidth, dashLine, fontsize, fontStyle, markcolor)
            this.node.add(piechart)
            const legends = this.drawLegends(width, sectors)
            legends.position({x:this.width/2, y:0})
            this.node.add(legends)
        }else{
            const piechart = this.drawDiagramChart(fill,width, height, sectors, fillcolor, vertically, minimum, 
                maximum, gridcolor, gridlinewidth, dashLine, fontsize, fontStyle, markcolor)
            this.node.add(piechart)
        }
        
    } 

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "fillcolor": 
            case "gridcolor":
            case "linestyle": 
            case "markcolor": {this[field] = value.value;this.rotatednode=null;break;}
            case "fontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "gridlinewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "vertically": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "maximum": 
            case "minimum": {this[field] = value.value;this.rotatednode=null;break;}
            case "fill": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "fillcolor": 
            case "gridcolor":
            case "linestyle": 
            case "markcolor": {value.datatype=7; value.value = this[field];break}
            case "fontsize": {value.datatype=3; value.value = this[field];break}
            case "fill": {value.datatype=0; value.value = (this[field]);break}
            case "gridlinewidth": {value.datatype=2; value.value = this[field];break}
            case "vertically": {value.datatype=1; value.value = this[field];break}
            case "maximum": 
            case "minimum": {value.datatype=6; value.value = this[field];break}
        }
        return value;
    }

    private drawDiagramChart(fill:boolean,width:number, height:number, sectors:any, fillcolor:string, vertically:number,
        minimum:number, maximum:number, gridcolor:string, gridlinewidth, dashLine:number[], fontsize:number, fontStyle:string, markcolor:string){
        const node1 = new Konva.Group()
        const horgap = width/10;
        const rectwidth = width-horgap;
        if(fill){
            const rect = new Konva.Rect({
                x: horgap,
                y: 0,
                width: rectwidth,
                height: height,
                fill: ColorUtils.convertformat(fillcolor)
            })
            node1.add(rect)
        }
        const verwidth = vertically== 0 ? 0 : height/vertically;
        const step = (maximum-minimum)/vertically;

        for (let i=0;i<=vertically;i++){
            const line = new Konva.Line({
                points: [horgap, i*verwidth, width, i*verwidth],
                stroke: ColorUtils.convertformat(gridcolor),
                strokeWidth: gridlinewidth,
                dash: dashLine,
            })
            node1.add(line)

            let value = (maximum-i*step)
            
            value = parseFloat(value.toFixed(2));
            const mark = new Konva.Text({
                x: -3*horgap,
                y: i==0? i*verwidth: (i==vertically? i*verwidth-fontsize : i*verwidth-fontsize/2),
                verticalAlign:"middle",
                width: 4*horgap,
                height: fontsize,
                text: value.toString(),
                fontSize: fontsize,
                fill: ColorUtils.convertformat(markcolor),
                align:'right',
                fontStyle: fontStyle=='Solid'? 'bold' : 'normal',
              });
                  node1.add(mark)
        }   
        if(sectors.length>0){
            
            const colwidth = rectwidth/sectors.length;
            let colheight =0;
            const y=horgap;

            sectors.forEach((sec, index)=>{
                if (sec.path==null || tagsModele.getTag(sec.path)==null) return;
                this.tagnames.push(sec.path)
                const tagvalue = this.getTagValue(sec.path)
                
                let percent = 0
                percent= (tagvalue-minimum)/(maximum-minimum)
                if(percent<0) colheight = 0
                else if(percent>1) colheight = height
                else colheight = height*percent 
                
                    const rect = new Konva.Rect({
                    x: y+colwidth*index,
                    y: height-colheight,
                    width: colwidth,
                    height: colheight,
                    fill: ColorUtils.convertformat(sec.color),
                })
                node1.add(rect)
            })
        }       
        return node1
    }

    protected getTagValue(tagname:string):number{
        const tag = tagsModele.getTag(tagname) 
        const tagvalue = (Number(tag.tagvalue))
        return tagvalue
    }        
}
import { SVGObject } from "../svgobject";

export class SVGValve6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 64.75 112.5" enable-background="new 0 0 64.75 112.5" xml:space="preserve">
        <g id="Group_5">
            <rect x="24.774" y="6.68" fill="#4C4C4C" width="11.599" height="7.736" />
            <rect x="25.675" y="13.832" fill="#4C4C4C" width="9.797" height="2.918" />
            <rect x="24.211" y="0.002" fill="#4C4C4C" width="12.725" height="7.094" />
        </g>
        <g id="Group_Port2">
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="39.251,93.467 59.709,90.877 59.709,107.093 39.251,104.293    " />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0107" y1="112.5107" x2="1.0107" y2="112.5107" gradientTransform="matrix(0 -16.2158 -16.2158 0 1873.9341 107.2663)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9D9DB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="39.251" y="90.877" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="20.458" height="16.216" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_4_" x="59.457" y="85.472" width="5.293" height="27.026" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-0.0098" y1="112.4912" x2="0.9902" y2="112.4912" gradientTransform="matrix(0 -27.0264 -27.0264 0 3102.3108 112.2273)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9D9DB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="59.457" y="85.472" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="5.293" height="27.026" />
            </g>
        </g>
        <g id="Group_Port1">
            <g>
                <defs>
                    <polygon id="SVGID_9_" points="19.5,90.877 37.837,90.877 39.639,93.467 39.639,104.391 37.837,107.093 19.5,107.093    " />
                </defs>
                <clipPath id="SVGID_10_">
                    <use xlink:href="#SVGID_9_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="0.0107" y1="112.5098" x2="1.0107" y2="112.5098" gradientTransform="matrix(0 -16.2158 -16.2158 0 1854.0122 107.2663)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9D9DB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="19.5" y="90.877" clip-path="url(#SVGID_10_)" fill="url(#SVGID_11_)" width="20.139" height="16.216" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_12_" x="17.458" y="88.174" width="2.361" height="21.621" />
                </defs>
                <clipPath id="SVGID_13_">
                    <use xlink:href="#SVGID_12_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-0.0024" y1="112.498" x2="0.9976" y2="112.498" gradientTransform="matrix(0 -21.6211 -21.6211 0 2450.9661 109.7468)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9D9DB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="17.458" y="88.174" clip-path="url(#SVGID_13_)" fill="url(#SVGID_14_)" width="2.361" height="21.621" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_15_" x="12.725" y="85.472" width="5.293" height="27.026" />
                </defs>
                <clipPath id="SVGID_16_">
                    <use xlink:href="#SVGID_15_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-0.0098" y1="112.4902" x2="0.9902" y2="112.4902" gradientTransform="matrix(0 -27.0264 -27.0264 0 3055.5764 112.2273)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9D9DB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="12.725" y="85.472" clip-path="url(#SVGID_16_)" fill="url(#SVGID_17_)" width="5.293" height="27.026" />
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_18_" points="37.837,90.877 39.639,93.467 39.639,104.391 37.837,107.093    " />
                </defs>
                <clipPath id="SVGID_19_">
                    <use xlink:href="#SVGID_18_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_20_" cx="0.0537" cy="112.5547" r="0.9998" gradientTransform="matrix(5.7687 0 0 -5.7687 38.4282 748.2761)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.17" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B8BA" />
                    <stop offset="0.91" style="stop-color:#7B7C7F" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="37.837" y="90.877" clip-path="url(#SVGID_19_)" fill="url(#SVGID_20_)" width="1.802" height="16.216" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_21_" d="M26.913,84.684v6.193c0,0-0.003,2.59,3.754,2.59c3.566,0,3.566-2.59,3.566-2.59v-6.193H26.913z" />
                </defs>
                <clipPath id="SVGID_22_">
                    <use xlink:href="#SVGID_21_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="0.0137" y1="112.5137" x2="1.0142" y2="112.5137" gradientTransform="matrix(7.3203 0 0 -7.3203 26.81 912.7122)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.52" style="stop-color:#BCBEC0" />
                    <stop offset="1" style="stop-color:#666666" />
                </linearGradient>
                <rect x="26.91" y="84.684" clip-path="url(#SVGID_22_)" fill="url(#SVGID_23_)" width="7.323" height="8.783" />
            </g>
        </g>
        <g id="Group_4">
            <rect x="18.018" y="80.404" fill="#4C4C4C" width="25.225" height="2.703" />
            <rect x="23.311" y="79.278" fill="#7F7F7F" width="14.526" height="5.404" />
            <rect x="37.837" y="35.136" fill="#999999" width="3.604" height="48.647" />
            <rect x="27.814" y="35.136" fill="#999999" width="5.631" height="37.385" />
            <rect x="19.819" y="35.136" fill="#999999" width="3.491" height="48.647" />
            <rect x="26.013" y="72.521" fill="#666666" width="9.121" height="5.854" />
            <rect x="26.913" y="78.377" width="7.321" height="0.901" />
            <g>
                <defs>
                    <rect id="SVGID_24_" x="24.211" y="39.64" width="12.725" height="2.703" />
                </defs>
                <clipPath id="SVGID_25_">
                    <use xlink:href="#SVGID_24_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="0.0063" y1="112.5068" x2="1.0063" y2="112.5068" gradientTransform="matrix(12.7246 0 0 -12.7246 24.1319 1472.5898)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.52" style="stop-color:#BCBEC0" />
                    <stop offset="1" style="stop-color:#666666" />
                </linearGradient>
                <rect x="24.211" y="39.64" clip-path="url(#SVGID_25_)" fill="url(#SVGID_26_)" width="12.725" height="2.703" />
            </g>
        </g>
        <g id="Group_Hose">
            <g>
                <defs>
                    <rect id="SVGID_27_" width="64.75" height="112.5" />
                </defs>
                <clipPath id="SVGID_28_">
                    <use xlink:href="#SVGID_27_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_28_)" fill="#666666" d="M13.626,31.66c0,0-2.168-0.192-4.729,1.505    c-1.632,1.081-3.41,3.058-4.937,5.92c-1.345,2.518-2.321,5.651-2.82,8.551c-0.598,3.48-0.577,6.531-0.577,6.531h0.901    c0,0,0.074-7.949,3.391-14.381c4.167-8.079,8.771-7.223,8.771-7.223V31.66z" />
            </g>
        </g>
        <g id="Group_3">
            <rect x="3.491" y="47.636" fill="#666666" width="54.164" height="3.552" />
            <rect x="3.491" y="61.486" fill="#191919" width="54.164" height="4.842" />
            <rect x="3.491" y="50.226" fill="#333333" width="54.164" height="12.161" />
            <rect x="13.626" y="31.082" fill="#333333" width="1.688" height="1.689" />
            <polygon fill="#191919" points="0,54.167 0,57.996 3.491,57.996 3.491,55.855 1.689,55.855 1.689,54.167  " />
        </g>
        <g id="Group_2">
            <rect x="13.626" y="43.244" fill="#191919" width="34.008" height="26.801" />
            <rect x="14.526" y="44.032" fill="#996633" width="32.207" height="25.11" />
            <rect x="24.211" y="51.689" fill="none" stroke="#4C4C4C" stroke-width="0.25" width="12.725" height="9.797" />
        </g>
        <g id="Group_1">
            <rect x="39.075" y="34.573" fill="#333333" width="5.631" height="2.703" />
            <polygon fill="#FFFF00" points="9.459,25.114 9.685,22.411 12.725,22.411 14.526,16.218 46.732,16.218 48.534,22.411    51.124,22.411 51.8,25.114  " />
            <rect x="19.819" y="21.285" fill="#7F7F7F" width="4.054" height="1.126" />
            <rect x="37.273" y="21.285" fill="#7F7F7F" width="4.167" height="1.126" />
            <rect x="46.732" y="21.285" fill="#7F7F7F" width="4.167" height="1.126" />
            <rect x="10.248" y="21.285" fill="#7F7F7F" width="4.278" height="1.126" />
            <polygon fill="#4C4C4C" points="30.404,36.938 26.913,36.487 23.31,36.038 19.819,35.586 16.216,35.137 15.99,35.137    14.526,32.208 13.513,28.942 12.95,27.816 12.724,27.705 8.896,27.705 9.459,25.113 51.801,25.113 52.025,27.705 51.575,27.705    48.534,27.705 48.31,27.816 47.07,30.745 45.944,34.009 45.156,35.137 41.44,35.586 37.837,36.038 34.233,36.487 31.418,36.826     " />
            <polygon fill="#D3CC4A" points="49.098,22.411 48.196,15.655 45.494,15.655 45.494,22.411  " />
            <polygon fill="#D3CC4A" points="28.603,22.411 29.278,15.655 31.98,15.655 32.543,22.411  " />
            <polygon fill="#D3CC4A" points="12.049,22.411 12.95,15.655 15.653,15.655 15.653,22.411  " />
        </g>
        </svg>`
		super.fillGradients();
		
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.16145833333333334, 214.0, 0.17, 0.265625, 187.0, 0.48, 0.5625, 111.0, 0.91, 0.9635416666666666, 0.0, 1.0, 0.8541666666666666, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.515625, 123.0, 1.0, 0.7916666666666666, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.515625, 123.0, 1.0, 0.7916666666666666, 0.0]);
		
		this.fillGradient(this.fills,"Group_4",[0.0, 0.5885416666666666, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_3",[0.0, 0.7916666666666666, 0.0, 1.0, 0.19270833333333334, 0.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.3958333333333333, 0.0, 4.0, 0.19270833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_2",[0.0, 0.19270833333333334, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_1",[0.0, 0.3958333333333333, 0.0, 1.0, 0.671875, 83.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.9895833333333334, 0.0, 6.0, 0.5885416666666666, 0.0, 7.0, 0.7291666666666666, 69.0, 8.0, 0.7291666666666666, 69.0, 9.0, 0.7291666666666666, 69.0]);
		
        super.drawObject();
    }
}
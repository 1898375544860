import { SVGObject } from "../svgobject";

export class SVGBoiler5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 95.609" enable-background="new 0 0 112.5 95.609" xml:space="preserve">
        <g id="Group_Boiler_Room">
            <rect y="37.816" fill="#A6BAD9" width="75.338" height="52.927" />
            <polygon fill="#92A3BE" points="0,30.293 0,37.726 75.338,37.726 75.338,14.979  " />
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-0.0195" y1="95.5898" x2="0.9805" y2="95.5898" gradientTransform="matrix(5.1797 0 0 -5.1797 75.4388 546.7038)">
                <stop offset="0" style="stop-color:#A6BAD9" />
                <stop offset="0.09" style="stop-color:#8FA6C7" />
                <stop offset="0.23" style="stop-color:#748EB1" />
                <stop offset="0.37" style="stop-color:#5E7BA0" />
                <stop offset="0.54" style="stop-color:#4F6D94" />
                <stop offset="0.72" style="stop-color:#46658D" />
                <stop offset="1" style="stop-color:#43638B" />
            </linearGradient>
            <rect x="75.338" y="12.501" fill="url(#SVGID_1_)" width="5.18" height="78.152" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="4.617" y1="29.167" x2="4.617" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="4.167" y1="29.167" x2="4.167" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="9.347" y1="28.267" x2="9.347" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="8.891" y1="28.282" x2="8.891" y2="37.741" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="14.077" y1="27.365" x2="14.077" y2="37.725" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="13.572" y1="27.434" x2="13.572" y2="37.793" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="18.806" y1="26.465" x2="18.806" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="18.297" y1="26.465" x2="18.297" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="23.536" y1="25.339" x2="23.536" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="23.042" y1="25.339" x2="23.042" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="28.266" y1="24.438" x2="28.266" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="27.781" y1="24.438" x2="27.781" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="32.996" y1="23.536" x2="32.996" y2="37.725" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="32.547" y1="23.536" x2="32.547" y2="37.725" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="37.725" y1="22.636" x2="37.725" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="37.208" y1="22.636" x2="37.208" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="42.455" y1="21.734" x2="42.455" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="42" y1="21.734" x2="42" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="47.185" y1="20.834" x2="47.185" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="46.703" y1="20.834" x2="46.703" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="51.915" y1="19.708" x2="51.915" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="51.422" y1="19.708" x2="51.422" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="56.644" y1="18.807" x2="56.644" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="56.156" y1="18.807" x2="56.156" y2="37.726" />
            <polyline fill="none" stroke="#4C4C4C" stroke-width="0.5" points="61.148,17.906 61.148,36.487 61.148,37.725  " />
            <polyline fill="none" stroke="#CCCCCC" stroke-width="0.5" points="60.719,17.906 60.719,36.487 60.719,37.725  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="66.104" y1="17.005" x2="66.104" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="65.672" y1="17.005" x2="65.672" y2="37.726" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="70.607" y1="15.879" x2="70.607" y2="37.726" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.5" x1="70.094" y1="15.879" x2="70.094" y2="37.726" />
            <polygon fill="#666666" points="4.842,27.591 0,30.293 75.338,14.978 80.518,12.501  " />
        </g>
        <g id="Group_Outlet">
            <rect x="77.365" y="52.815" fill="#324C7F" width="15.628" height="33.334" />
            <line fill="none" stroke="#808080" stroke-width="0.5" x1="77.252" y1="69.82" x2="92.567" y2="69.82" />
            <line fill="none" stroke="#808080" stroke-width="0.5" x1="84.797" y1="52.815" x2="84.797" y2="86.149" />
            <line fill="none" stroke="#CCCCCC" stroke-width="0.618" x1="84.249" y1="52.815" x2="84.249" y2="86.149" />
        </g>
        <g id="Group_Chimney">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.0098" y1="95.6191" x2="1.0098" y2="95.6191" gradientTransform="matrix(20.0449 0 0 -20.0449 92.2645 1964.4657)">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <rect x="92.455" y="0" fill="url(#SVGID_2_)" width="20.045" height="95.609" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M92.455,19.638c0-5.011,20.045-5.012,20.045,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M92.455,38.176c1.047-2.252,15.089-3.537,20.045,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,76.197c0,5.012-20.045,5.012-20.045,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,57.66c-1.047,2.252-15.089,3.537-20.045,0" />
        </g>
        <g id="Group_Furnace">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0059" y1="95.6152" x2="1.0059" y2="95.6152" gradientTransform="matrix(57.6816 0 0 -57.6816 8.538 5578.2134)">
                <stop offset="0" style="stop-color:#FFDD77" />
                <stop offset="0.21" style="stop-color:#EEC660" />
                <stop offset="0.51" style="stop-color:#DBAD47" />
                <stop offset="0.78" style="stop-color:#D09E38" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <rect x="8.896" y="44.032" fill="url(#SVGID_3_)" width="57.682" height="37.838" />
            <rect x="8.896" y="44.032" width="0.901" height="37.838" />
            <polygon fill="#808080" points="8.896,81.87 66.249,81.87 66.594,81.461 9.813,81.352  " />
        </g>
        <g id="Group_Burner">
            <path fill="#B2B2B2" d="M18.018,51.689h-4.504c-1.602,0-2.901,3.378-2.901,7.545c0,4.168,1.299,7.546,2.901,7.546h4.504   c1.602,0,2.901-3.378,2.901-7.546C20.919,55.067,19.62,51.689,18.018,51.689" />
            <path fill="#F2F2F2" d="M20.919,59.234c0,4.168-1.299,7.546-2.901,7.546s-2.901-3.378-2.901-7.546c0-4.167,1.299-7.545,2.901-7.545   S20.919,55.067,20.919,59.234" />
            <path d="M18.243,53.491c1.058,0,1.915,2.571,1.915,5.743s-0.857,5.744-1.915,5.744c-1.057,0-1.915-2.572-1.915-5.744   S17.186,53.491,18.243,53.491" />
            <path fill="#CC0000" d="M16.833,59.056c0-4.708,1.523-4.889,1.523-4.889s15.347-2.003,20.496-2.026   c0.622-0.003,2.477,0.225,2.477,0.225v0.113c0,0-3.889,0.41-4.496,1.126c-1.255,1.479-7.891,3.266-7.891,3.266h10.811   c0,0-2.313,1.561-5.293,2.027c-1.7,0.267-6.869,0.45-6.869,0.45s1.434,1.192,2.027,1.238c2.648,0.206,6.792-0.558,9.715-0.112   c0.845,0.129,5.375,1.352,5.375,1.352s-2.542-0.145-5.2,0.809c-4.5,1.613-8.539,0.542-8.539,0.542s2.281,0.797,3.491,2.365   c0.272,0.353,1.689,0.563,1.689,0.563s-7.02,0.768-9.234,0.112c-0.876-0.26-3.04-2.027-3.04-2.027l-4.393,0.113l-1.463,0.127   C18.019,64.43,16.833,64.189,16.833,59.056" />
            <path fill="#ED9900" d="M17.229,58.897c0.006-0.26,1.242-0.937,0.901-2.14c-0.318-1.125-0.765-1.447-0.225-1.915   c0.872-0.756,4.425-0.899,5.923-1.007c1.656-0.119,5.676-0.119,5.676-0.119l-0.563,0.226h4.955l-7.208,2.59   c0,0,1.929,0.243,2.478,0.563c1.302,0.757,4.73,1.577,4.73,1.577s-7.454,0.09-7.545,0.563c-0.07,0.367,2.018,1.152,2.711,1.273   c0.889,0.155,3.595-0.034,3.595-0.034s-0.882,0.363-1.126,0.563c-0.248,0.203-0.788,1.014-0.788,1.014h1.351   c0,0-1.11,0.333-1.576,0.676c-0.531,0.391-0.69,0.907-1.253,1.204c-0.503,0.266-2.013,0.147-2.013,0.147h-0.338l0.563,1.126   c0,0-3.271-1.055-3.571-1.148c-1.738-0.54-4.762-0.428-4.762-0.428l-1.014-0.451c0,0-0.716-0.519-0.788-0.788   c-0.165-0.62,0.812-1.851,0.676-2.478C17.95,59.597,17.223,59.218,17.229,58.897" />
            <path fill="#FFFF00" d="M17.455,59.122c0.001-0.483,1.031-1.102,1.126-1.576c0.125-0.622-0.563-2.478-0.563-2.478   s2.815-0.534,4.317-0.685c1.121-0.112,6.156-0.441,6.156-0.441l-4.054,1.576l1.577,0.676c0,0-3.842,0.376-4.055,0.563   c-0.217,0.192,0.26,0.422,1.014,0.563c1.586,0.296,6.419,0.676,6.419,0.676l-7.208,1.352c0,0,1.55,0.611,2.091,0.727   c1.084,0.23,2.865-0.051,2.865-0.051l-0.676,0.225c0,0,0.911,0.666,2.139,1.014c0.949,0.269,3.942,0.112,3.942,0.112l-6.081,1.127   c0,0-0.339,0.163,0,0.675c0.451,0.682,2.365,2.253,2.365,2.253l0.112,0.225c0,0-3.285-1.333-4.113-1.536   c-0.998-0.245-2.418-0.266-2.418-0.266s-4.301,0.172-4.73-0.9c-0.275-0.687,0.927-2.678,0.901-2.815   C18.51,59.764,17.455,59.312,17.455,59.122" />
        </g>
        <g id="Group_Rod_Base">
            <polygon fill="#007F00" points="56.644,78.041 36.599,78.041 66.779,57.996 66.779,70.608  " />
            <rect x="36.599" y="78.041" fill="#87C879" width="20.045" height="3.386" />
            <polygon fill="#004C00" points="56.644,78.041 66.779,70.608 66.779,76.239 59.734,81.458 56.662,81.473  " />
        </g>
        <g id="Group_Rods">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-0.0562" y1="95.5527" x2="0.9434" y2="95.5527" gradientTransform="matrix(3.6094 0 0 -3.6094 63.358 399.558)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="63.156" y="44.021" fill="url(#SVGID_4_)" width="3.608" height="21.292" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-0.0825" y1="95.5254" x2="0.9175" y2="95.5254" gradientTransform="matrix(3.6099 0 0 -3.6099 46.5796 405.5821)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="46.282" y="44.032" fill="url(#SVGID_5_)" width="3.609" height="33.421" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-0.0825" y1="95.5254" x2="0.9175" y2="95.5254" gradientTransform="matrix(3.6099 0 0 -3.6099 50.1733 404.3595)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="49.875" y="44.046" fill="url(#SVGID_6_)" width="3.61" height="30.955" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="-0.0562" y1="95.5527" x2="0.9438" y2="95.5527" gradientTransform="matrix(3.6094 0 0 -3.6094 53.4517 403.14)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="53.25" y="44.033" fill="url(#SVGID_7_)" width="3.609" height="28.437" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-0.0557" y1="95.5527" x2="0.9438" y2="95.5527" gradientTransform="matrix(3.6094 0 0 -3.6094 56.7955 401.8939)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="56.594" y="44.032" fill="url(#SVGID_8_)" width="3.609" height="25.949" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-0.0562" y1="95.5527" x2="0.9438" y2="95.5527" gradientTransform="matrix(3.6094 0 0 -3.6094 60.108 400.722)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="59.906" y="44.041" fill="url(#SVGID_9_)" width="3.609" height="23.584" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5208333333333334, 121.0, 0.09, 0.6822916666666666, 81.0, 0.23, 0.8697916666666666, 33.0, 0.37, 0.9739583333333334, 0.0, 0.54, 0.875, 0.0, 0.72, 0.8072916666666666, 0.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.453125, 139.0, 0.21, 0.6197916666666666, 97.0, 0.51, 0.796875, 51.0, 0.78, 0.90625, 23.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Boiler_Room",[0.0, 0.5208333333333334, 121.0, 1.0, 0.703125, 75.0, 2.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Furnace",[0.0, 1.0, 0.0]);
		this.fillGradient(this.fills,"Group_Burner",[0.0, 0.609375, 99.0, 1.0, 0.11458333333333333, 226.0, 2.0, 0.5260416666666666, 0.0, 3.0, 0.984375, 3.0, 4.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Rod_Base",[0.0, 0.3229166666666667, 0.0, 1.0, 0.8125, 47.0, 2.0, 0.19270833333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGConveyor8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 24.214" enable-background="new 0 0 112.5 24.214" xml:space="preserve">
        <g id="Group_Container">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1943" y1="22.5229" x2="56.1943" y2="1.6904">
                <stop offset="0" style="stop-color:#F2F2F2" />
                <stop offset="0.25" style="stop-color:#E5E5E5" />
                <stop offset="0.75" style="stop-color:#E6E6E6" />
                <stop offset="1" style="stop-color:#F2F2F2" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0.451,1.69h111.487v20.833H0.451V1.69z" />
        </g>
        <g id="Group_Spiral_Rear">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="19.0879" y1="19.0894" x2="5.1239" y2="5.1254">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M16.779,21.398L9.797,2.816H7.433l6.982,18.582H16.779z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="32.9961" y1="19.0337" x2="19.088" y2="5.1256">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M30.631,21.398l-6.87-18.582h-2.365l6.982,18.582H30.631z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="46.9038" y1="19.0894" x2="32.9958" y2="5.1813">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,21.398L37.613,2.816H35.36l6.869,18.582H44.595z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="60.8682" y1="19.0894" x2="46.9042" y2="5.1254">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M58.559,21.398L51.577,2.816h-2.365l6.982,18.582H58.559z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="74.7764" y1="19.0337" x2="60.8683" y2="5.1256">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M72.41,21.398L65.541,2.816h-2.365l6.982,18.582H72.41z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="88.6826" y1="19.0894" x2="74.7753" y2="5.182">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M86.374,21.398L79.393,2.816H77.14l6.869,18.582H86.374z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="102.6465" y1="19.0894" x2="88.6825" y2="5.1254">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M100.338,21.398L93.355,2.816h-2.364l6.981,18.582H100.338z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="111.5986" y1="11.2627" x2="104.0539" y2="3.7179">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.955,2.816l3.49,9.347h2.253l-3.491-9.347H104.955z" />
        </g>
        <g id="Group_Spiral_Front">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="95.6641" y1="19.0894" x2="109.572" y2="5.1814">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M97.973,21.398l6.982-18.582h2.252l-6.869,18.582H97.973z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="81.7012" y1="19.0903" x2="95.6653" y2="5.1262">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M84.009,21.398l6.982-18.582h2.364l-6.981,18.582H84.009z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="67.7939" y1="19.0337" x2="81.7019" y2="5.1257">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M70.158,21.398l6.869-18.582h2.365L72.41,21.398H70.158z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="53.8862" y1="19.0903" x2="67.7939" y2="5.1826">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,21.398l6.982-18.582h2.252l-6.869,18.582H56.194z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="39.9214" y1="19.0894" x2="53.8857" y2="5.125">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M42.229,21.398l6.982-18.582h2.365l-6.982,18.582H42.229z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="26.0137" y1="19.0327" x2="39.9216" y2="5.1248">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M28.378,21.398l6.869-18.582h2.365l-6.982,18.582H28.378z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="12.1064" y1="19.0894" x2="26.0706" y2="5.1252">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M14.415,21.398l6.982-18.582h2.365l-6.982,18.582H14.415z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-1.8574" y1="19.0894" x2="12.1067" y2="5.1252">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,21.398L7.433,2.816h2.365L2.815,21.398H0.451z" />
        </g>
        <g id="Group_Cylinder">
            <path fill="#7F7F7F" d="M0.451,22.523h111.487v1.239H0.451V22.523z" />
            <path fill="#7F7F7F" d="M0.451,0.451h111.487V1.69H0.451V0.451z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.11458333333333333, 226.0, 0.25, 0.20833333333333334, 201.0, 0.75, 0.20833333333333334, 202.0, 1.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.40625, 151.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Cylinder",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
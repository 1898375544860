import { SVGObject } from "../svgobject";

export class SVGMiscpipe12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Gas_90_Curve">
        <radialGradient id="SVGID_1_" cx="138.4736" cy="323.1943" r="27.4649" gradientTransform="matrix(-2.8617 -0.2115 0.2088 -2.8735 403.5826 995.708)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.09" style="stop-color:#5E6868" />
            <stop offset="0.2" style="stop-color:#909898" />
            <stop offset="0.42" style="stop-color:#FCFFFF" />
            <stop offset="0.6" style="stop-color:#FCFFFF" />
            <stop offset="0.86" style="stop-color:#878D8D" />
            <stop offset="0.95" style="stop-color:#5E6868" />
            <stop offset="1" style="stop-color:#5C6666" />
        </radialGradient>
        <path fill="url(#SVGID_1_)" d="M78.96,112.624c-38.482,0-78.93-26.641-78.93-79.405l66.9-0.198  c1.398,9.283,5.215,11.523,11.814,12.122C79.521,62.831,78.96,112.624,78.96,112.624z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="96.6436" y1="118.9717" x2="28.7344" y2="118.9717" gradientTransform="matrix(-1 0 0 -1 96.6445 137.6777)">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.41" style="stop-color:#FCFFFF" />
            <stop offset="0.61" style="stop-color:#FCFFFF" />
            <stop offset="1" style="stop-color:#5C6666" />
        </linearGradient>
        <rect y="0.042" fill="url(#SVGID_2_)" width="67.909" height="37.329" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.4365" y1="25.1113" x2="2.4365" y2="92.6997" gradientTransform="matrix(-1 0 0 -1 96.6445 137.6777)">
            <stop offset="0" style="stop-color:#5C6666" />
            <stop offset="0.41" style="stop-color:#FCFFFF" />
            <stop offset="0.61" style="stop-color:#FCFFFF" />
            <stop offset="1" style="stop-color:#5C6666" />
        </linearGradient>
        <rect x="76.09" y="44.979" fill="url(#SVGID_3_)" width="36.236" height="67.589" />
        <path fill="none" stroke="#4C4C4C" d="M67.455,33.671H0" />
        <path fill="none" stroke="#4C4C4C" d="M78.717,44.933V112.5" />
        <path fill="none" stroke="#4C4C4C" d="M70.721,41.667L22.973,89.414" />
        <path fill="none" stroke="#4C4C4C" d="M68.919,39.302L10.473,73.086" />
        <path fill="none" stroke="#4C4C4C" d="M67.793,36.599L2.59,54.054" />
        <path fill="none" stroke="#4C4C4C" d="M73.086,43.468l-33.784,58.446" />
        <path fill="none" stroke="#4C4C4C" d="M75.788,44.595l-17.454,65.202" />
        <path fill="none" stroke="#4C4C4C" d="M67.455,16.779H0" />
        <path fill="none" stroke="#4C4C4C" d="M95.607,44.933V112.5" />
        <path fill="none" stroke="#FCFFFF" d="M67.455,32.671H0" />
        <path fill="none" stroke="#FCFFFF" d="M77.818,44.933V112.5" />
        <path fill="none" stroke="#FCFFFF" d="M69.471,41.417L21.723,89.164" />
        <path fill="none" stroke="#FCFFFF" d="M68.169,38.302L9.723,72.086" />
        <path fill="none" stroke="#FCFFFF" d="M67.793,35.349L2.59,52.804" />
        <path fill="none" stroke="#FCFFFF" stroke-width="1.0073" d="M71.614,44.025L37.938,102.64" />
        <path fill="none" stroke="#FCFFFF" d="M74.788,44.345l-17.454,65.202" />
        <path fill="none" stroke="#FCFFFF" d="M67.455,15.779H0" />
        <path fill="none" stroke="#FCFFFF" d="M94.607,44.933V112.5" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.09, 0.7760416666666666, 0.0, 0.2, 0.8385416666666666, 41.0, 0.42, 0.015625, 250.0, 0.6, 0.015625, 250.0, 0.86, 0.9166666666666666, 21.0, 0.95, 0.7760416666666666, 0.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
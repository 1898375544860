import { SVGObject } from "../svgobject";

export class SVGWater19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 38.289" enable-background="new 0 0 112.5 38.289" xml:space="preserve">
        <g id="Group_Liquid">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,10.024h103.377v19.031H0.451V10.024z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M110.924,2.929l-7.096,7.095v19.031l7.096-7.207V2.929z" />
        </g>
        <g id="Group_TankSupport">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M3.941,35.136h103.153l-3.267-6.081H0.451L3.941,35.136z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M3.941,35.136h103.153v2.252H3.941V35.136z" />
        </g>
        <g id="Group_Stand">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M7.795,2.591h103.717V0H7.795V2.591z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,33.447l-1.914,1.689l-3.267-6.081l7.096-7.207   L109.009,33.447z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,33.447l-1.914,1.689v2.252l1.914-1.802V33.447z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M7.771,0h103.491l-7.434,7.32H0.451L7.771,0z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M7.32,2.929h104.167V0H7.32V2.929z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M7.32,2.929l-7.095,7.095V7.321L7.32,0V2.929z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M7.771,2.929l-7.32,7.095V7.321L7.771,0V2.929z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,10.024h103.829V7.321H0.451V10.024z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M110.924,2.929l-7.096,7.095V7.321L110.924,0V2.929z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M111.486,2.929l-7.207,7.095V7.321L111.486,0V2.929z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M7.32,10.024H4.842v28.266H7.32V10.024z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M7.32,10.024l0.788-0.676v28.04L7.32,38.289V10.024z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M100.113,10.024h-2.479v28.266h2.479V10.024z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M100.113,10.024l0.787-0.676v28.04l-0.787,0.901V10.024z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M38.852,10.024h-2.478v28.266h2.478V10.024z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M38.852,10.024l0.788-0.676v28.04l-0.788,0.901V10.024z" />
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M69.369,10.024h-2.477v28.266h2.477V10.024z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M69.369,10.024l1.014-0.676v28.04l-1.014,0.901V10.024z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M0,10.474h104.055V7.546H0V10.474z" />
        </g>
        <g id="Group_Group1">
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,34.46h3.491v2.703h-3.491V34.46z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,33.897h-1.238v3.267h1.238V33.897z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,34.686h-1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,35.136h-1.014" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,35.586h-1.465" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,36.15h-1.014" />
        </g>
        <g id="Group_Hole">
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="108.334" cy="29.731" rx="1.576" ry="3.153" />
            <ellipse fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" cx="108.334" cy="29.731" rx="1.576" ry="3.153" />
            <ellipse fill="#7F7F7F" stroke="#7F7F7F" stroke-width="0.25" cx="108.221" cy="29.618" rx="0.676" ry="1.351" />
        </g>
        <g id="Group_WiringBox">
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,21.059l-2.478,2.253v2.478l2.478-2.478V21.059z" />
            <path fill="#0000BF" stroke="#4C4C4C" stroke-width="0.25" d="M106.081,23.312h0.45v2.478h-0.45V23.312z" />
            <path fill="#0000BF" stroke="#4C4C4C" stroke-width="0.25" d="M109.009,21.059l-2.478,2.253h-0.45l2.364-2.253H109.009z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M99.887,8.109h3.492v3.379h-3.492V8.109z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M99.887,8.109h3.492l0.676-1.014h-3.492L99.887,8.109z" />
            <path fill="#0000BF" stroke="#4C4C4C" stroke-width="0.25" d="M104.055,7.095l-0.676,1.014v3.379l0.676-1.014V7.095z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M102.365,11.488v12.387l1.463,1.464h0.789l0.9-1.238" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.fills,"Group_Liquid",[0.0, 0.125, 223.0, 1.0, 0.125, 223.0]);
		this.fillGradient(this.fills,"Group_TankSupport",[0.0, 0.609375, 99.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.125, 223.0, 4.0, 0.609375, 99.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0, 9.0, 0.609375, 99.0, 10.0, 0.125, 223.0, 11.0, 0.9895833333333334, 0.0, 12.0, 0.125, 223.0, 13.0, 0.9895833333333334, 0.0, 14.0, 0.125, 223.0, 15.0, 0.9895833333333334, 0.0, 16.0, 0.125, 223.0, 17.0, 0.9895833333333334, 0.0, 18.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Group1",[0.0, 0.6822916666666666, 81.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.fills,"Group_Hole",[0.0, 0.9895833333333334, 0.0, 1.0, 0.125, 223.0, 2.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_WiringBox",[0.0, 0.6822916666666666, 81.0, 1.0, 0.4895833333333333, 0.0, 2.0, 0.4895833333333333, 0.0, 3.0, 0.6822916666666666, 81.0, 4.0, 0.6822916666666666, 81.0, 5.0, 0.4895833333333333, 0.0]);

        super.drawObject();
    }
}
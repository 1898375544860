import tagsModele from "@/store/tags.modele";
import Konva from "konva";
import { ColorPicker } from "./colorpicker";

export class ColorRectangle extends ColorPicker{


    public drawObject():void{
		super.initObject();

        this.tagnames.push(this.redcolortagpath)
		this.redtag = tagsModele.getTag(this.redcolortagpath)
        this.tagnames.push(this.greencolortagpath)
		this.greentag = tagsModele.getTag(this.greencolortagpath)
        this.tagnames.push(this.bluecolortagpath)
		this.bluetag = tagsModele.getTag(this.bluecolortagpath)
        this.tagnames.push(this.opacitycolortagpath)
		this.opacitytag = tagsModele.getTag(this.opacitycolortagpath)
        this.color='#'
  
        this.color+=this.redtag==null?'00':parseInt(this.redtag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.greentag==null?'00':parseInt(this.greentag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.bluetag==null?'00':parseInt(this.bluetag.tagvalue).toString(16).padStart(2,'0')
        this.color+=this.opacitytag==null?'ff':Math.round(parseFloat(this.opacitytag.tagvalue)).toString(16).padStart(2,'0')

		console.log(this.color)
      
            const rectfill = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                fill: this.color,
              });
              this.node.add(rectfill)

            
    }
  
   
}
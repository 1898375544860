import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import ResetPassword from "./components/ResetPassword.vue"
// lazy-loaded
//const UserSettings = ()=>import("./components/usersettings/UserSettings.vue")
const UserSettings = ()=>import("./components/UserSettings.vue")
const UserSettingsMobile = ()=>import("./components/UserSettingsMobile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const SessionsAdmin = () => import("./components/SessionsAdmin.vue")
const Screens = () =>import("./components/Screens.vue")
const Authenticate = ()=>import("./components/Authenticate.vue")
import AuthModule from './store/auth.module'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: Home,
  
  },
  {
    path: "/screens",
    name: "screens",
    component:Screens
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/authenticate",
    name: "authenticate",
    component:Authenticate,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/usersettings",
    name: "usersettings",
    // lazy-loaded
    //component: Profile,
   component:UserSettings
  },
  {
    path: "/usersettings/:name",
    name: "usersettingsmobile",
    // lazy-loaded
    //component: Profile,
   component:UserSettingsMobile
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/sessions",
    name: "sessions",
    // lazy-loaded
    component: SessionsAdmin,
  },
 /* {
    path: "/session",
    name: "session",
    // lazy-loaded
    component: Session,
  },*/
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPassword
  }
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
 console.log(to.name)
 /* if (to.name==='register')
    return {name: 'register'}
  if (to.name==='reset')
    return {name: 'reset'}*/
  if (!AuthModule.currentUser && to.name !== 'login' && to.name!=='register' && to.name!=='reset' && to.name!=='authenticate') 
    return { name: 'login' }
  
  if (AuthModule.currentUser && AuthModule.currentUser.role!='ADMIN' && to.name=='admin')
    return {name: 'Home'}
    if (AuthModule.currentUser && AuthModule.currentUser.role!='ADMIN' && to.name=='sessions')
    return {name: 'Home'}
  if (AuthModule.currentUser && AuthModule.currentUser.role=='USER' && to.name=='usersettings')
    return {name: 'Home'}
 /* if (AuthModule.currentUser && AuthModule.currentUser.role=='USER' && to.name=='session')
    return {name: 'Home'}*/
})

export default router

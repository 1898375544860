import { Point } from "./point";
import { CatmullRom } from "./catmullrom";

export class Statistics{

    static devideToPoints(points:any[]){
        const POINTS = []
        if(points.length != 0){
          points.forEach((el, index)=>{
            if(index !=0 && index%2 !=0)return
            if(index == points.length+1)return
            const p = new Point(el, points[index+1])
            POINTS.push(p)
          })
          return POINTS
        }
      }
    static subdividePoints(POINTS:any[], SUB_DEVISIONS = 64) {
        if (POINTS==null || POINTS.length<3) return POINTS
        const noOfPoints = POINTS.length;
        const subdividedPoints = [];
      
        const increments = 1/SUB_DEVISIONS;
      
        for (let i = 0 ; i < noOfPoints - 1 ; i++) {
            const p0 = i == 0 ? POINTS[i] : POINTS[i - 1];
            const p1 = POINTS[i];
            const p2 = POINTS[i + 1];
            const p3 = (i+2 == noOfPoints) ? POINTS[i + 1] : POINTS[i + 2];
      
            const crs = new CatmullRom(p0, p1, p2, p3);
      
            for (let j = 0; j <= SUB_DEVISIONS; j++) {
                subdividedPoints[(i * SUB_DEVISIONS) + j] = crs.q(j * increments);
            }
        }
        return subdividedPoints;
      }
}

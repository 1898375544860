import { Value } from "../value";
import { ObjectView } from "./object";
import Konva from "konva";

export class Video extends ObjectView{
    url:string;
    type:number;
    securtity:boolean;
    username:string;
    password:string;
    motiondetect:boolean;
    tagname:string;
    interval:number;
    autosaveimage:boolean;
    savecondition:number;
    diff:number;

    public drawObject():void{
      super.initObject();
      const video = document.createElement('video');
      video.src = this.url
      //'https://upload.wikimedia.org/wikipedia/commons/transcoded/c/c4/Physicsworks.ogv/Physicsworks.ogv.240p.vp9.webm'
      video.muted = true
      video.autoplay = true
  
      // this.url

      const image = new Konva.Image({
        image: video,
        x: 0,
        y: 0,
        width:this.width,
        height:this.height
      });
     this.node.add(image)
     const anim = new Konva.Animation(function () {
        // do nothing, animation just need to update the layer
      }, this.layer);
     video.addEventListener('loadedmetadata', function (e) {
       // text.text('Press PLAY...');
        //image.width(this.width);
        //image.height(this.height);
       // image.width(video.videoWidth);
       // image.height(video.videoHeight);
        video.play()
      anim.start()
      });
    }

    /*public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "url":
          case "username":
          case "password": {this[field] = value.value;this.rotatednode=null;break;}

          case "type":
          case "interval":
          case "savecondition":  {this[field] = Math.round(value.value);this.rotatednode=null;break;}

          case "diff":  {this[field] = value.value;this.rotatednode=null;break;}

          case "security":
          case "motiondetect":
          case "autosaveimage": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "url":
          case "username":
          case "password": {value.datatype=7; value.value = this[field];break}

          case "type": {value.datatype=1; value.value = this[field];break}

          case "interval":
          case "autosaveimage":
          case "savecondition":  {value.datatype=3; value.value = this[field];break}

          case "diff":  {value.datatype=6; value.value = this[field];break}

          case "security":
          case "motiondetect": {value.datatype=0; value.value = (this[field]);break}

          case "fillcolor": {value.datatype=7; value.value = this[field];break}
      }
      return value;
    } */
}
import { SVGObject } from "../svgobject";

export class SVGPipe6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="Group_x5F_Right_x5F_Angle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Right_Angle">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.25" y1="56.2495" x2="0.2251" y2="56.2495">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.18" style="stop-color:#959595" />
            <stop offset="0.5" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#666666" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M56.25,112.5H0.225V0L56.25,16.71V112.5z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="56.2505" y1="56.25" x2="56.2505" y2="0">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M112.5,56.25V0H0l56.25,56.25H112.5z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
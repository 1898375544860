import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { StringUtils } from "../utils/stringutils";
import Konva from "konva";
import { Button } from "./button";

export class PressButton extends Button{
    
    public drawObject():void{
        super.initObject();
        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0,fillcolor2)
              gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            stroke: this.type3d==0? gradient: ColorUtils.darkColor(fillcolor2,0.5),
            strokeWidth: this.width/40
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2,0.5)])
          }
          else
            rectout.fill(ColorUtils.darkColor(fillcolor2,0.75))
          this.node.add(rectout)
        
        
          const rectinner = new Konva.Rect({
            x: this.width/15,
            y: this.height/10,
            width: this.width*13/15,
            height: this.height*8/10,
          });
          if (this.type3d==0){
            rectinner.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectinner.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectinner.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.5), 1, 'white']);
          }
          else
                rectinner.fill(fillcolor2)
          this.node.add(rectinner);
          
          
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
            
            let fontsize = this.fontsize;
            if (fontsize == 0)
                fontsize = this.height/2;
                const Text = new Konva.Text({
                    x: this.width/15,
                    y: this.height/10, 
                    verticalAlign:"middle",
                    width: this.width*13/15,
                    height: this.height-this.height/10,
                    text: text,
                    fontSize: fontsize,
                    fontFamily: StringUtils.getFontFamily(this.fonttype),
                    fontStyle: StringUtils.getFontStyle(this.fonttype),
                    align:StringUtils.getTextPlacement(this.textplacement),
                    fill: ColorUtils.convertformat(textcolor),
                    textDecoration: this.underline?"underline":""
                  });
                  if(this.type3d==0){
                    
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                  this.setFunctions();
                  
    }
   
}
import { FillingProperty } from "./fillingproperty";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";

export class CounterControlProperty extends FillingProperty{
    delta:number;
    decimalpos:number;
    

    public getCurrentValue(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return this.minimumvalue
        if (tag.tagvalue==undefined) return this.minimumvalue
        if (+tag.tagvalue<this.minimumvalue) return this.minimumvalue;
        if (+tag.tagvalue>this.maximumvalue) return this.maximumvalue;
        return +tag.tagvalue; 
    }

    public setValue(value: string):void{
        const tag:tag = tagsModele.getTag(this.path); 
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue: value, datetime:0});
    }
    
}
import { Text3DView } from "./text3d";
import { TextInputProperty } from "./properties/textinputproperty";
import historyandeventsModule from "@/store/historyandevents.module";
import { Value } from "../value";

export class ValueWithHistory extends Text3DView{
    side:number;
    format:string;
    horizontally:number;
    vertically:number;
    gridlinewidth:number;
    gridwidth:number;
    gridheight:number;
    gridlinecolor:string;
    gridlinestyle:string;
    gridfontsize:number;
    markcolor:string;
    type3d:number

    public drawObject():void{
        super.drawObject()
        const textprop = this.properties==null?null:this.properties['textinput']
        if (textprop!=null){
          const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
          if (textinputproperty!=null){
              const tag = textinputproperty.getTag(this.tagnames)
              if (tag!=null){
                this.node.on('mousedown touchstart', function () {
                    historyandeventsModule.setEventsAndHistoryTagname(tag.name)
                    historyandeventsModule.setEventsAndHistorygEnabled(true)
                });
              }
            }
        }
    }

   /* public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "format":
            case "gridlinecolor":
            case "gridlinestyle":
            case "markcolor":  {this[field] = value.value;this.rotatednode=null;break;}

            case "side":
            case "vertically":
            case "horizontally":
            case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            
            case "gridlinewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "gridwidth":
            case "gridheight":
            case "gridfontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        }
        this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "format":
            case "gridlinecolor":
            case "gridlinestyle":
            case "markcolor":  {value.datatype=7; value.value = this[field];break}

            case "side":
            case "vertically":
            case "horizontally":
            case "type3d": {value.datatype=1; value.value = this[field];break}

            case "gridlinewidth": {value.datatype=2; value.value = this[field];break}

            case "gridwidth":
            case "gridheight":
            case "gridfontsize": {value.datatype=3; value.value = this[field];break}
        }
        return value;
    }*/
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { StringUtils } from "../utils/stringutils";
import Konva from "konva";
import { Button } from './button';

export class OvalJumpButton extends Button{
    
    public drawObject():void{
        super.initObject();

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
    
          const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, -this.height/2, 0, this.height/2);
              gradient.addColorStop(0, 'white')
              gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

          const ellipse = new Konva.Ellipse({
            x: this.width/2,
            y: this.height/2,
            radiusX: this.width/2-this.width/34,
            radiusY: this.height/2-this.width/34,
            stroke: this.type3d==0? gradient: ColorUtils.darkColor(fillcolor2,0.75),
            strokeWidth: this.width/17

          });
                if(this.type3d==0){
                ellipse.fillLinearGradientStartPoint({ x: 0, y: this.height/2-this.width/34});
                ellipse.fillLinearGradientEndPoint({ x: 0, y: -(this.height/2-this.width/34) });
                ellipse.fillLinearGradientColorStops([0, 'white', 
                1, ColorUtils.darkColor(fillcolor2,0.5)]);
            }else{
              ellipse.fill(fillcolor2)
            }
          this.node.add(ellipse); 
          
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
            
                const Text = new Konva.Text({
                    x: this.width/17,
                    y: 0, 
                    verticalAlign:"middle",
                    width: this.width-2*this.width/17,
                    height: this.height,
                    text: text,
                    fontSize: this.fontsize==0? this.height/4 : this.fontsize,
                    fontFamily: StringUtils.getFontFamily(this.fonttype),
                    fontStyle: StringUtils.getFontStyle(this.fonttype),
                    align:StringUtils.getTextPlacement(this.textplacement),
                    fill: ColorUtils.convertformat(textcolor),
                    textDecoration: this.underline?"underline":""
                  });

                  if(this.type3d==0){
                    
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                  this.setFunctions();

                             
    }
   
}
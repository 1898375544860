import { SVGObject } from "../svgobject";

export class SVGPump12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 40.882 112.5" enable-background="new 0 0 40.882 112.5" xml:space="preserve">
        <g id="Group_Moulded_Volute">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.3687" y1="103.5469" x2="38.1802" y2="103.5469" gradientTransform="matrix(1 0 0 -1 0 112.5)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M20.386,17.905H2.369l0.45-3.604l1.015-3.378l1.688-3.04   l2.14-2.59l2.703-2.252l3.041-1.689l3.379-1.014L20.386,0l3.604,0.338l3.379,1.014l3.041,1.689L33,5.293l2.14,2.59l1.688,3.041   l1.014,3.378l0.339,3.604L20.386,17.905L20.386,17.905z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.0034" y1="91.4414" x2="40.8823" y2="91.4414" gradientTransform="matrix(1 0 0 -1 0 112.5)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M40.882,24.212H0.003l0.563-3.378l1.803-2.928h36.034   l1.804,2.928l0.676,2.928V24.212z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M1.242,24.212h38.289v3.829H1.242V24.212z" />
            <ellipse fill="#666666" stroke="#4C4C4C" stroke-width="0.25" cx="35.476" cy="20.946" rx="1.576" ry="1.914" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.076,28.716l0.676-0.676" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M19.71,17.905h1.352v-2.703H19.71V17.905z" />
            <ellipse fill="#999999" stroke="#4C4C4C" stroke-width="0.25" cx="36.041" cy="20.946" rx="1.688" ry="1.914" />
        </g>
        <g id="Group_Drivershaft_Flange">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="3.042" y1="19.8203" x2="37.7271" y2="19.8203" gradientTransform="matrix(1 0 0 -1 0 112.5)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.042,72.859h34.685V112.5H3.042V72.859z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M7.547,79.166h1.351v25.676H7.547V79.166z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M31.195,79.166h1.352v25.676h-1.352V79.166z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M23.539,79.166h1.352v25.676h-1.352V79.166z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M15.204,79.166h1.352v25.676h-1.352V79.166z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M6.195,88.852l0.676-0.676V74.212H2.367l-0.451,0.45L6.195,88.852z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M1.916,74.662h4.279v14.189H1.916V74.662z" />
        </g>
        <g id="Group_Volute_Chamber">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M1.24,71.509h38.289v1.353H1.24V71.509z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1.2402" y1="60.1348" x2="39.5288" y2="60.1348" gradientTransform="matrix(1 0 0 -1 0 112.5)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M1.24,33.221h38.289v38.288H1.24V33.221z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="9.765625e-004" y1="81.8691" x2="40.8804" y2="81.8691" gradientTransform="matrix(1 0 0 -1 0 112.5)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M39.529,33.221H1.24H1.015l-1.014-2.703l1.014-2.365   L1.24,28.04h38.289l0.225,0.113l1.127,2.365l-1.127,2.703H39.529z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M1.24,56.194h38.289v5.18H1.24V56.194z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M1.916,65.879h7.658v1.125H1.916V65.879z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M14.078,65.879h7.658v1.125h-7.658V65.879z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M26.24,65.879h7.658v1.125H26.24V65.879z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M38.177,65.879h1.352v1.125h-1.352V65.879z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M1.24,58.221h38.289v1.127H1.24V58.221z" />
            <path fill="#666666" d="M30.519,44.032h6.531v6.306h-6.531V44.032z" />
            <path fill="#666666" d="M2.367,35.698h35.811v0.676H2.367V35.698z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M6.195,33.896l0.676-0.676v-5.18H2.367l-0.451,0.676L6.195,33.896z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.402,70.834h-4.504v-7.658" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.24,70.834h-4.504v-7.658" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.078,70.834H9.574v-7.658" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M1.916,63.176h10.135" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.078,63.176h10.134" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.015,63.176h10.359" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.177,63.176h1.352" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M1.916,28.716h4.279v5.18H1.916V28.716z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
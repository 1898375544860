import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import Konva from "konva";
import { Polygon } from './polygon';

export class PolygonGradient extends Polygon{

    public drawObject():void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
        const coordinates = this.coordinates;
        const polygon = new Konva.Group({
          clipFunc: function (ctx) {
              coordinates.forEach((coord=>{
                  ctx.lineTo(coord.coordx, coord.coordy);
              }));
          },
          
        });
		let fillcolor = '0xffffff00';
        if (this.fill){
                let fillheight = this.height
                const fillingprop = this.properties['filling']
                if (fillingprop != null){
                    const fillingproperty:FillingProperty =Object.assign(new FillingProperty(), fillingprop);
                    fillheight = fillingproperty.getHeight(this.tagnames, this.height)
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const rectfill = new Konva.Rect({
                x: 0,
                y: this.height - fillheight,
                width: this.width,
                height: fillheight,
               // fill: ColorUtils.convertformat(fillcolor),
              });
              rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
              rectfill.fillLinearGradientEndPoint({ x: this.width, y: this.height });
              rectfill.fillLinearGradientColorStops([0, ColorUtils.convertformat(fillcolor), 0.5, 'white', 1, ColorUtils.convertformat(fillcolor)])
              polygon.add(rectfill)
        }   
        const p=[];
        this.coordinates.forEach((coord=>{
            p.push(coord.coordx);
            p.push(coord.coordy)
        }));
        const poly = new Konva.Line({
            points:p,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
            closed: true,
          }); 
         
          polygon.add(poly)
          this.node.add(polygon)
    }
}
import { SVGObject } from "../svgobject";

export class SVGMiscpipe3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Vertical">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-3.1299" y1="260.3984" x2="55.5811" y2="260.3984" gradientTransform="matrix(1 0 0 1 30.9551 -203.4902)">
                <stop offset="0" style="stop-color:#663300" />
                <stop offset="0.5" style="stop-color:#CC6633" />
                <stop offset="1" style="stop-color:#663300" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" points="86.536,0.667 86.323,113.151 27.825,113.151 27.825,0.667  " />
        </g>
        <g id="Group_Copper_Pipe_H2">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="151.582" y1="163.6816" x2="92.8711" y2="163.6816" gradientTransform="matrix(0 1 -1 0 191.4805 -65.418)">
                <stop offset="0" style="stop-color:#663300" />
                <stop offset="0.5" style="stop-color:#CC6633" />
                <stop offset="1" style="stop-color:#663300" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="27.923,27.453 55.158,56.656 27.923,86.164 0.44,85.951 0.44,27.453  " />
        </g>
        <g id="Group_H1">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="151.582" y1="165.0225" x2="92.8711" y2="165.0225" gradientTransform="matrix(0 1 1 0 -79.8818 -65.418)">
                <stop offset="0" style="stop-color:#663300" />
                <stop offset="0.5" style="stop-color:#CC6633" />
                <stop offset="1" style="stop-color:#663300" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="85.018,27.453 57.782,56.656 85.018,86.164 112.5,85.951 112.5,27.453  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
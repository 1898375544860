import { SVGObject } from "../svgobject";

export class SVGWater53 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0.001 112.5 69.479" enable-background="new 0 0.001 112.5 69.479" xml:space="preserve">
        <g id="Group_Pipe2">
            <path fill="#CCCCCC" d="M75.449,19.594h-2.59v5.744h2.59V19.594z" />
            <path fill="none" d="M75.449,19.594h-2.59v5.744h2.59V19.594" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="50.1572" y1="2.3647" x2="50.1572" y2="9.765625e-004">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M48.648,2.365h3.018V0.001h-3.018V2.365z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="95.375" y1="2.3647" x2="95.375" y2="9.765625e-004">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M93.918,2.365h2.915V0.001h-2.915V2.365z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="73" y1="27.4766" x2="73" y2="25.1118">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M57.5,25.112h31v2.365h-31V25.112z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="53.1533" y1="14.0303" x2="55.6309" y2="14.0303">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M53.153,23.581V4.479h2.478v19.101H53.153z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="89.8633" y1="14.0303" x2="92.3418" y2="14.0303">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M89.863,23.581V4.479h2.479v19.101H89.863z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="44.5947" y1="17.6963" x2="46.96" y2="17.6963">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M44.595,31.579V3.813h2.365v27.766H44.595z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="98.7598" y1="17.6963" x2="101.125" y2="17.6963">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M98.76,31.579V3.813h2.365v27.766H98.76z" />
        </g>
        <g id="Group_PipeFittings">
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-2103.248" y1="6083.2461" x2="-2102.248" y2="6083.2461" gradientTransform="matrix(4.3916 0 0 -4.3916 9280.2021 26746.2598)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="43.582" y="30.568" fill="url(#SVGID_8_)" width="4.392" height="1.015" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-2103.3555" y1="6082.9883" x2="-2102.3555" y2="6082.9883" gradientTransform="matrix(0 2.4727 2.4727 0 -14988.8203 5200.9712)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_9_)" points="52.726,0.003 54.501,2.477 50.674,2.477 50.674,0.003  " />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-2103.2119" y1="6083.3311" x2="-2102.2119" y2="6083.3311" gradientTransform="matrix(-2.4785 0 0 2.4785 -5157.1943 -15074.627)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_10_)" points="55.616,2.866 55.616,4.948 53.138,4.948 53.138,0.871  " />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-2103.3081" y1="6083.1035" x2="-2102.3086" y2="6083.1035" gradientTransform="matrix(2.4385 0 0 -2.4385 5173.4414 14836.6309)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_11_)" points="44.524,2.848 46.962,1.099 46.962,4.871 44.524,4.871  " />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-2103.2354" y1="6083.2764" x2="-2102.2354" y2="6083.2764" gradientTransform="matrix(0 2.4414 2.4414 0 -14804.3223 5134.8389)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_12_)" points="47.347,0.001 49.398,0.001 49.398,2.442 45.379,2.442  " />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-2103.23" y1="6083.2842" x2="-2102.23" y2="6083.2842" gradientTransform="matrix(0 2.5205 2.5205 0 -15234.7705 5301.1963)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_13_)" points="98.287,0.003 100.096,2.524 96.195,2.524 96.195,0.003  " />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-2103.2729" y1="6083.1885" x2="-2102.2729" y2="6083.1885" gradientTransform="matrix(-2.5254 0 0 2.5254 -5210.373 -15359.5156)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_14_)" points="101.231,2.921 101.231,5.043 98.706,5.043 98.706,0.887  " />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="-2103.1704" y1="6083.4287" x2="-2102.1704" y2="6083.4287" gradientTransform="matrix(2.6152 0 0 -2.6152 5589.9819 15912.584)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_15_)" points="89.77,3.059 92.385,1.181 92.385,5.227 89.77,5.227  " />
            
                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="-2103.1841" y1="6083.3984" x2="-2102.1841" y2="6083.3984" gradientTransform="matrix(0 2.6196 2.6196 0 -15843.2256 5509.5039)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_16_)" points="92.797,0.003 94.998,0.003 94.998,2.623 90.687,2.623  " />
            
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-2103.291" y1="6083.1436" x2="-2102.291" y2="6083.1436" gradientTransform="matrix(-2.5049 0 0 2.5049 -5176.1807 -15213.1699)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_17_)" points="92.354,24.635 89.849,26.432 89.849,22.559 92.354,22.559  " />
            
                <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-2103.332" y1="6083.0459" x2="-2102.332" y2="6083.0459" gradientTransform="matrix(0 -2.5098 -2.5098 0 15356.6367 -5251.3828)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_18_)" points="89.454,27.561 87.347,27.561 87.347,25.051 91.475,25.051  " />
            
                <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="-2103.1416" y1="6083.4941" x2="-2102.1416" y2="6083.4941" gradientTransform="matrix(0 -2.5439 -2.5439 0 15532.0039 -5322.71)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_19_)" points="56.062,27.472 54.236,24.928 58.171,24.928 58.171,27.472  " />
            
                <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="-2103.1558" y1="6083.4629" x2="-2102.1558" y2="6083.4629" gradientTransform="matrix(2.5483 0 0 -2.5483 5412.5625 15526.9707)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_20_)" points="53.091,24.527 53.091,22.386 55.639,22.386 55.639,26.58  " />
            
                <radialGradient id="SVGID_21_" cx="-203.4258" cy="154.2754" r="23.7788" gradientTransform="matrix(-0.1197 0.0088 0.0087 0.1192 21.5303 -13.9077)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M47.379,0.003c-1.394,0-2.858,0.957-2.858,2.851l2.423,0.007c0.05-0.333,0.188-0.414,0.428-0.435   C47.399,1.792,47.379,0.003,47.379,0.003z" />
            
                <radialGradient id="SVGID_22_" cx="-579.6138" cy="182.8223" r="23.7655" gradientTransform="matrix(-0.1285 0.0094 0.0094 0.1279 16.4829 -15.0352)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_22_)" d="M92.832,0.004c-1.496,0-3.066,1.026-3.066,3.06l2.598,0.008c0.055-0.358,0.205-0.444,0.461-0.467   C92.854,1.923,92.832,0.004,92.832,0.004z" />
            
                <radialGradient id="SVGID_23_" cx="793.7441" cy="53.4336" r="21.516" gradientTransform="matrix(0.1339 0.0098 -0.0098 0.1337 -52.9055 -12.2192)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_23_)" d="M52.722,0.002c1.411,0,2.894,0.971,2.894,2.894l-2.453,0.007c-0.051-0.338-0.19-0.42-0.433-0.442   C52.7,1.817,52.722,0.002,52.722,0.002z" />
            
                <radialGradient id="SVGID_24_" cx="1132.9473" cy="28.6714" r="21.5212" gradientTransform="matrix(0.1366 0.01 -0.01 0.1363 -55.9919 -12.4927)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M98.28,0.001c1.439,0,2.951,0.99,2.951,2.952L98.73,2.96c-0.052-0.345-0.195-0.428-0.442-0.451   C98.258,1.852,98.28,0.001,98.28,0.001z" />
            
                <radialGradient id="SVGID_25_" cx="-1084.4639" cy="561.6074" r="23.7717" gradientTransform="matrix(-0.1249 -0.0092 0.0091 -0.1245 -84.6 84.666)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_25_)" d="M56.07,27.476c-1.454,0-2.98-1-2.98-2.98l2.526-0.008c0.053,0.349,0.196,0.433,0.447,0.456   C56.09,25.606,56.07,27.476,56.07,27.476z" />
            
                <radialGradient id="SVGID_26_" cx="862.6074" cy="271.8135" r="19.8123" gradientTransform="matrix(0.1473 -0.0108 -0.0107 -0.147 -34.5232 74.0742)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_26_)" d="M89.424,27.56c1.428,0,2.93-0.983,2.93-2.93l-2.482-0.007c-0.053,0.343-0.193,0.425-0.439,0.447   C89.402,25.722,89.424,27.56,89.424,27.56z" />
        </g>
        <g id="Group_Pipe1">
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="15.8394" y1="65.2012" x2="15.8394" y2="62.8359">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M0.366,62.836h30.946v2.365H0.366V62.836z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="0.2271" y1="66.1016" x2="0.2271" y2="61.8223">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" d="M0.452,61.822H0.001v4.279h0.451V61.822z" />
        </g>
        <g id="Group_FlowMeter">
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="75.0801" y1="20.8838" x2="82.111" y2="24.1624">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" d="M75.208,20.607h6.773v3.83h-6.773V20.607z" />
            <circle fill="#87C879" cx="41.499" cy="11.993" r="1.746" />
            <circle fill="#87C879" cx="95.663" cy="11.993" r="1.745" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="96.7129" y1="10.9585" x2="103.1716" y2="12.6891">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" d="M97.296,14.864v-6.08h5.293v6.08H97.296z" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="42.5479" y1="10.9585" x2="49.0065" y2="12.6891">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" d="M43.131,14.864v-6.08h5.293v6.08H43.131z" />
        </g>
        <g id="Group_Tank3">
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="86.5273" y1="46.4746" x2="114.9335" y2="54.086">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" d="M90.652,31.081h20.156v38.4H90.652V31.081z" />
        </g>
        <g id="Group_Tank2">
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="26.7241" y1="46.5029" x2="54.9209" y2="54.0582">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" d="M30.856,31.081h19.932v38.4H30.856V31.081z" />
            <path fill="#87C879" d="M32.996,33.333h15.652V44.03H32.996V33.333z" />
        </g>
        <g id="Group_Tank1">
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="52.9277" y1="46.1035" x2="89.4141" y2="46.1035">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_34_)" d="M52.928,31.081h36.486V58.67l-18.713,2.457L52.928,58.67V31.081z" />
            <radialGradient id="SVGID_35_" cx="71.1709" cy="61.2031" r="13.0236" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_35_)" d="M89.414,58.67l-16.104,5.066h-4.73L52.928,58.67H89.414z" />
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="68.5801" y1="66.4961" x2="73.3105" y2="66.4961">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_36_)" d="M68.58,63.736h4.73v5.519h-4.73V63.736z" />
        </g>
        <g id="Group_Pipe3">
            <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="93.043" y1="56.0801" x2="93.043" y2="54.165">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_37_)" d="M74.375,54.165h37.335v1.915H74.375V54.165z" />
        </g>
        <g id="Group_PipeFitting3">
            <circle fill="#231F20" cx="72.093" cy="50.552" r="1.925" />
            
                <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="-2103.0874" y1="6083.25" x2="-2102.0879" y2="6083.25" gradientTransform="matrix(3.4907 1.6277 1.6277 -3.4907 -2490.1111 24707.9824)">
                <stop offset="0" style="stop-color:#939598" />
                <stop offset="0.06" style="stop-color:#939598" />
                <stop offset="0.15" style="stop-color:#A0A2A5" />
                <stop offset="0.32" style="stop-color:#C3C4C6" />
                <stop offset="0.48" style="stop-color:#EBEBEC" />
                <stop offset="0.59" style="stop-color:#E0E0E2" />
                <stop offset="0.76" style="stop-color:#C3C4C6" />
                <stop offset="0.99" style="stop-color:#949699" />
                <stop offset="1" style="stop-color:#939598" />
            </linearGradient>
            <circle fill="url(#SVGID_38_)" cx="72.093" cy="50.798" r="1.925" />
            
                <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="-2103.2109" y1="6083.334" x2="-2102.2109" y2="6083.334" gradientTransform="matrix(0 -3.8281 -3.8281 0 23399.7129 -7994.3223)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="111.71" y="53.152" fill="url(#SVGID_39_)" width="0.788" height="3.828" />
            
                <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="-2103.3706" y1="6082.9531" x2="-2102.3706" y2="6082.9531" gradientTransform="matrix(0 -2.1602 -2.1602 0 13214.0498 -4487.4688)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_40_)" points="73.535,56.232 71.985,54.072 75.328,54.072 75.328,56.232  " />
            
                <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="-2103.1416" y1="6083.498" x2="-2102.1416" y2="6083.498" gradientTransform="matrix(2.165 0 0 -2.165 4624.313 13223.4941)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_41_)" d="M71.011,53.731v-3.095c0,0,0.16-0.666,1.072-0.666c0.89,0,1.093,0.666,1.093,0.666v4.838   L71.011,53.731z" />
            
                <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="-2103.2563" y1="6083.2266" x2="-2102.2563" y2="6083.2266" gradientTransform="matrix(3.1543 0 0 -3.1543 6727.7666 19241.8652)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_42_)" points="95.607,53.488 95.607,54.165 94.48,54.165 94.48,53.488 93.467,52.926 96.621,52.926  " />
            
                <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="-2103.501" y1="6082.6465" x2="-2102.5015" y2="6082.6465" gradientTransform="matrix(1.127 0 0 -1.127 2465.1265 6908.9697)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.5" style="stop-color:#ECECED" />
                <stop offset="0.58" style="stop-color:#D9D9DB" />
                <stop offset="0.76" style="stop-color:#A8A9AB" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="94.48" y="53.488" fill="url(#SVGID_43_)" width="1.127" height="0.677" />
            
                <radialGradient id="SVGID_44_" cx="-1270.3364" cy="304.3037" r="23.7728" gradientTransform="matrix(-0.1059 -0.0078 0.0077 -0.1057 -63.432 76.1235)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_44_)" d="M73.539,56.233c-1.232,0-2.527-0.849-2.527-2.528l2.143-0.007c0.045,0.296,0.166,0.367,0.379,0.387   C73.557,54.647,73.539,56.233,73.539,56.233z" />
        </g>
        <g id="Group_ControlBox">
            <path fill="#BEBEBE" d="M23.199,46.906v15.93c0,0-0.09,0.445-0.846,0.445c-0.697,0-0.844-0.445-0.844-0.445v-15.93H23.199z" />
            <path fill="#BEBEBE" d="M10.925,46.906v15.93c0,0-0.132,0.445-0.82,0.445c-0.6,0-0.771-0.445-0.771-0.445v-15.93H10.925z" />
            <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="2.0439" y1="31.3169" x2="30.9529" y2="39.063">
                <stop offset="0" style="stop-color:#006600" />
                <stop offset="0.52" style="stop-color:#87C879" />
                <stop offset="0.61" style="stop-color:#74BA68" />
                <stop offset="0.79" style="stop-color:#43973C" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_45_)" d="M4.281,22.972h24.436v24.436H4.281V22.972z" />
            <path fill="#CCCCCC" d="M5.745,34.684H6.42v2.478H5.745V34.684z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.8385416666666666, 41.0, 0.06, 0.8385416666666666, 41.0, 0.15, 0.734375, 67.0, 0.32, 0.46875, 135.0, 0.48, 0.16666666666666666, 212.0, 0.59, 0.25, 191.0, 0.76, 0.46875, 135.0, 0.99, 0.828125, 43.0, 1.0, 0.8385416666666666, 41.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_43_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_44_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.2604166666666667, 0.0, 0.52, 0.8125, 47.0, 0.61, 0.9479166666666666, 13.0, 0.79, 0.7135416666666666, 0.0, 1.0, 0.2604166666666667, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { ObjectView } from './object';
import { Value } from '../value';

export class Sphere extends ObjectView{
    fillcolor:string;

    public drawObject():void{
      super.initObject();
      let fillcolor = '0xffffff00';
                
      fillcolor = this.fillcolor;
      const fillprop = this.properties==null? null : ['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
                  fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }	
      const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
      const ellipsefill = new Konva.Ellipse({
          x: this.width/2,
          y: this.height/2,
          radiusX: (this.width)/2,
          radiusY: (this.height)/2,
      });
      ellipsefill.fillRadialGradientStartPoint({x:0, y:-this.height*.35});
      ellipsefill.fillRadialGradientColorStops([0, 'white', 0.6, fillcolor2])
      ellipsefill.fillRadialGradientEndRadius(this.height)
      ellipsefill.fillPriority('radial-graident')
      this.node.add(ellipsefill)
    } 

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
      }
      return value;
    } 
}
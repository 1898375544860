
import ScreenModule from '@/store/screens.module'
import AuthModule from '@/store/auth.module'
import popupscreenModule from '@/store/popupscreen.module';
import Konva from 'konva';
import {Screen} from '@/model/project/screen';
export class Project{
    static stage:Konva.Stage;
    static popupstage:Konva.Stage;
    static currentscreenname:string;
    static previousscreenname:string;
    static globalArguments:object ={};
        
    static reloadScreen():void{
        this.getScreen(this.currentscreenname)
    }
    static drawScreen(screen:Screen, stage:Konva.Stage, containerstring:string):void{

        //cons ole.log("SCREEN.scripts=",screen.scripts[0].opcodes[0].type)
        const container = document.querySelector(containerstring);
        // now we need to fit stage into parent container
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
         
            if (containerWidth<screen.windowwidth || containerHeight<screen.windowheight || window.orientation !== undefined){
                const scale = containerWidth/ screen.windowwidth;
                /*const scale2= containerHeight/screen.windowheight;
                if (scale2>scale)
                    scale = scale2;*/
                stage.width(screen.windowwidth*scale)
                stage.height(screen.windowheight*scale)
                stage.scale({ x: scale, y: scale })
                
           }
        screen.drawObjects(stage);   
    }
    
    static removeScreen(){
        if (ScreenModule.getScreen!=null){
            ScreenModule.getScreen.deleteObjects();
            //ScreenModule.setScreenMutation(null)
        }
    }
    static getScreen(screenname:string):void{
 
        console.log("previousscree=",this.previousscreenname)
        console.log("currentscreen=",this.currentscreenname)
        if (Project.stage!=null){
            Project.stage.width(1);
            Project.stage.height(1);
            Project.stage.destroy();
        }
        Project.stage = new Konva.Stage({
            container: 'container',
            width:window.innerWidth,
           height:window.innerHeight
          }); 
          if (ScreenModule.getScreen!=null){
            ScreenModule.getScreen.deleteObjects();
          }
        const screen = ScreenModule.getScreenByName(screenname)
        console.log("screen=",screen)
        if (screen!=null){
            ScreenModule.setScreenMutation(screen)
            this.drawScreen(screen, Project.stage,'#stage-parent')
            return;
        }
        console.log("screenname=",screenname)
        ScreenModule.getScreenAction({token:AuthModule.currentUser.accessToken,id:0,screenname:screenname})
        .then(((screen)=>{   
            this.drawScreen(screen, Project.stage, '#stage-parent')
       
        }));
    }
    static getPopupScreen(screenname:string):void{
       // console.log("getPopupScreen:", screenname)
        popupscreenModule.setPopupEnabled(true)
        if (Project.popupstage!=null){
            Project.popupstage.width(1);
            Project.popupstage.height(1);
            Project.popupstage.destroy();
        }
        const screen= popupscreenModule.getPopupScreenByName(screenname);
        if (screen!=null){
            popupscreenModule.setPopupScreenMutation(screen)
      
             window.setTimeout(()=>{
               Project.popupstage = new Konva.Stage({
                    container: 'popup',
                    width: screen.windowwidth,
                    height: screen.windowheight,
                  });
                  this.drawScreen(screen, Project.popupstage,'#popup' )
               },100) ///bad solution. It's needed because setPopupEnabled needs time to show div module where 'popup' element
              //this.drawScreen(screen, Project.popupstage,"#popup" )
            return;
        }
   
       popupscreenModule.getPopupScreenAction({token:AuthModule.currentUser.accessToken,id:0,screenname:screenname})
        .then(((screen)=>{
         
            Project.popupstage = new Konva.Stage({
                container: 'popup',
                width: screen.windowwidth,
                height: screen.windowheight,
              }); 
              
              this.drawScreen(screen, Project.popupstage,"#popup" )
    
        }));

    }
    static getGlobalArgument(key:string):string{
        return this.globalArguments[key]
    }
    static setGlobalArgument(key:string, value:string):void{
        this.globalArguments[key] = value
        console.log('setGlobalArgument this.globalArguments', this.globalArguments)
    }
    static getCurrentScreenName():string{
        return this.currentscreenname
    }
    static setCurrentScreenName(name:string){
        this.previousscreenname = this.currentscreenname==undefined?name:this.currentscreenname
        this.currentscreenname = name;
    }
    static getPreviousScreen():string{
        console.log('getPreviousScreen this.previousscreenname', this.previousscreenname)
        return this.previousscreenname
    }
    static setPreviousScreen(name:string){
        this.previousscreenname = name;
    }


}

import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { NumberTile } from "./numbertile";

export class HighlowTile extends NumberTile{
    oldvalue:number;
    public drawObject():void{
		super.initObject();

	if(this.oldvalue==null) this.oldvalue = 0
        let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    let value
    let valueproperty
    const valueprop = this.properties['value'];
    if (valueprop!=null){	
      valueproperty =Object.assign(new ValueProperty(), valueprop);
      if (valueproperty!=null) 
        value = valueproperty.getTagValue(this.tagnames)         
    }     
    
    let decimalpos=0;
    if (valueproperty!=null) decimalpos=valueproperty.decimalpos;
    
    const deviation = this.oldvalue == 0 ? value : -((this.oldvalue - value) / this.oldvalue) * 100.0;
    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const text2 = new Konva.Text({
        x: size*0.05,
        y: this.height - size * 0.05 - 0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05*2,
        height: 0.06*size,
        text: this.text,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(text2)

    const descriptionText = new Konva.Text({
        x: size*0.05,
        y: size*0.52-0.1*size,
        verticalAlign: "bottom",
        width: this.width-size*0.05*2,
        height: 0.1*size,
        text: this.description,
        fontSize: 0.1*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'right'
    });
    group.add(descriptionText)   
     
    const unitTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign: "bottom",
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    }); 

    const unitText = new Konva.Text({
      x: this.width-unitTextTransparent.width()-size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: unitTextTransparent.width(),
      height: 0.24*size,
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });  

    const valueText = new Konva.Text({
      x: size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: this.width-size*0.05*2-unitTextTransparent.width(),
      height: 0.24*size,
      text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.24*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'right',
      offsetY:-size*0.024
    }); 
    group.add(valueText, unitText)

    if (deviation>0) {
        const triangle = new Konva.Line({
            points: [size*.05, this. height*.75,
                    size*.15, this.height*.75,
                    size*.1, this.height*.70],
            closed: true,
            fill: '#8fc65e'
        })
        group.add(triangle)
    }else if (deviation<0) {
        const triangle = new Konva.Line({
            points: [size*.05, this.height*.70,
                    size*.15, this.height*.70,
                    size*.1, this.height*.75],
            closed: true,
            fill: '#e5504c'
        })
        group.add(triangle)
    }

    const percentageText = new Konva.Text({
        x: size*0.17,
        y: this.height*.75-size*0.18,
        verticalAlign: "bottom",
        //width: this.width-size*0.05*2,
        height: 0.18*size,
        text: deviation.toFixed(1).toString(),
        fontSize: 0.18*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: deviation>=0? '#8fc65e' :  '#e5504c',
        align: 'left',
        offsetY:-size*0.024
      });
    
    const unitPerText = new Konva.Text({
      x: size*0.17+percentageText.width(),
      y: this.height*.75-size*0.18,
      verticalAlign: "bottom",
     // width: size*0.05+percentageText.width(),
      height: 0.18*size,
      text: "%",
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: deviation>=0? '#8fc65e' :  '#e5504c',
      align: 'left'
    });
    group.add(percentageText,unitPerText)

    this.node.add(group)   
    this.oldvalue = value;  
}
}
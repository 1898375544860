import { ObjectView } from "./object";
import { ColorRange } from "./properties/range/colorrange";
import  eventsdata from "@/model/eventsdata"
import authModule from "@/store/auth.module";
import tagsService from "@/services/tags.service";
import eventsdatarequest from '@/model/requests/eventsdatarequest'
import { TextView } from "./text";
import { format } from 'date-fns'
import eventstabledialogboxModule from "@/store/eventstabledialogbox.module";
import { Value } from "../value";
import screensModule from "@/store/screens.module";


export class EventsTable extends ObjectView{
    title:string;
    titlefontsize:number;
    unackcolor:string;
    ackcolor:string;
    timeformat:string;
    nameenable:boolean;
    timeenable:boolean;
    typeenable:boolean;
    priorityenable:boolean;
    messageenable:boolean;
    valueenable:boolean;
    acktimeenable:boolean;
    namewidth:number;
    timewidth:number;
    typewidth:number;
    prioritywidth:number;
    messagewidth:number;
    valuewidth:number;
    acktimewidth:number;
    nametitle:string;
    timetitle:string;
    typetitle:string;
    prioritytitle:string;
    messagetitle:string;
    valuetitle:string;
    acktimetitle:string;
    onlyactivestate:boolean;
    onlyunack:boolean;
    usetitle:boolean;
    prioritycolorrange:ColorRange[];

    begin:number;
    end: number;
    beginpriority:number;
    endpriority:number;
    eventsdata:eventsdata[];

    x:number;
    y:number;

    beginrow:number;

    public drawObject():void{
        if(this.timeformat==null || this.timeformat == '') this.timeformat = "d/MM hh:mm:ss"
       // super.initObject();
       if (this.begin==undefined)  this.begin=Date.now()-24*60*60*1000;
       if (this.end==undefined || this.end==0) this.end=Date.now();
        if (this.beginpriority==undefined) this.beginpriority=0;
        if (this.endpriority==undefined || this.endpriority==0) this.endpriority=1000;

        if (authModule.currentUser!=null){
           const request:eventsdatarequest = {token:authModule.currentUser.accessToken, 
              name:"", begindate:this.begin, enddate:this.end, beginpriority:this.beginpriority,
            endpriority:this.endpriority, onlyactivestate:this.onlyactivestate,unack:this.onlyunack,
            id:screensModule.screendevid};
            tagsService.geteventsdata(request,(data:string)=>{
       
            this.eventsdata =JSON.parse(data);
           /* this.eventsdata.forEach(event => {
              event.time = new Date(event.time).toLocaleString();
            });*/
            this.beginrow=0;
            this.drawEventTable();
          }, 
            (data:string)=>{
                console.log(data);
             })
      }
      
    }
    public setField(field:string, value:Value){
        super.setField(field, value)
        const timems = Date.now()
        //console.log('timems', timems)
        switch(field){
            case "title": 
            case "unackcolor": 
            case "ackcolor": 
            case "timeformat": 
            case "nametitle": 
            case "timetitle": 
            case "prioritytitle":
            case "messagetitle":
            case "valuetitle":
            case "acktimetitle": {this[field] = value.value;this.rotatednode=null;break;}

            case "titlefontsize": 
            case "namewidth": 
            case "timewidth": 
            case "prioritywidth": 
            case "messagewidth": 
            case "valuewidth": 
            case "acktimewidth": 
            case "beginpriority":
            case "endpriority":{this[field] = Math.round(value.value);this.rotatednode=null;break;}
            
            case "usetitle":
            case "nameenable":
            case "timeenable":
            case "priorityenable": 
            case "messageenable":
            case "valueenable":
            case "acktimeenable":
            case "onlyactivestate":
            case "onlyunack":{
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
            
            case "begin": {
                this[field] = timems-Math.round(value.value*60*1000);
				break;}

            case "end":{
                if (value.value==null || value.value == 0) this.end = Date.now();
                this[field] = timems - Math.round(value.value*60*1000);
				break;
            }
            case "begindatetime": {
                if (value.value==null) this.begin = 0;
                this.begin = value.value;
				break;
            }
            case "enddatetime": {
                if (value.value==null) this.end = Date.now();
                this.end = value.value;
				break;
            }
            //case "enbegin":
            //case "enprend":
            //case "enprbegin":
            //case "enend":
            //case "disablesavereport":
            //case "disableprint":
            //case "savereport"
            //case "filename":
        }
        console.log("DRAWOBJECT")
        //this.drawObject();
    }
    public getField(field:string):Value{
        const value = super.getField(field)
        const timems = Date.now()
        switch(field){
            case "title": 
            case "unackcolor": 
            case "ackcolor": 
            case "timeformat": 
            case "nametitle": 
            case "timetitle":
            case "prioritytitle":
            case "messagetitle": 
            case "valuetitle":
            case "acktimetitle":{value.datatype=7; value.value = this[field];break}

            case "titlefontsize": 
            case "namewidth": 
            case "timewidth": 
            case "prioritywidth": 
            case "messagewidth": 
            case "valuewidth":
            case "acktimewidth": 
            case "beginpriority":
            case "endpriority":{value.datatype=3; value.value = this[field];break}

            case "usetitle":
            case "nameenable":
            case "timeenable":
            case "priorityenable":
            case "messageenable": 
            case "valueenable":
            case "acktimeenable":
            case "onlyactivestate":
            case "onlyunack":{value.datatype=0; value.value = this[field]; break}

            case "begin":
            case "end": {value.datatype=3; 
                if(this[field] != null)value.value = Math.round((timems-this[field])/60000); 
                break}
            case "begindatetime": {value.datatype=4; value.value = this.begin; break}
            case "enddatetime": {value.datatype=4; value.value = this.end; break}	

            //case "enbegin":
            //case "enprend":
            //case "enprbegin":
            //case "enend":
            //case "filename":
            //case "disablesavereport":
            //case "disableprint":
            //case "savereport":
        }
        return value;
    }
    private drawEventTable():void{
       super.initObject()
        this.x = 0
        this.y=0
        if (this.usetitle){
            const title = new TextView()
            title.width = this.width
            title.height = this.height/10
            title.text = this.title
            title.fontsize = this.titlefontsize
            title.posx = 0
            title.posy = 0
            title.textplacement = 0
            title.textcolor = '0x000000FF'
            title.useborder = false
            title.bordercolor = null
            title.linewidth = 0
            title.fill = false
            title.fillcolor = '0xFFFFFF00'
            title.underline = false
            title.initLayer(this.layer)
            title.drawObject()
            this.node.add(title.rotatednode)
            this.y+=this.height/10;
        }
        if (this.nameenable){
            this.drawTitle(this.namewidth, this.nametitle)
            this.x+=this.namewidth
        }
        if (this.timeenable){
            this.drawTitle( this.timewidth, this.timetitle)
            this.x+=this.timewidth
        }
        if (this.typeenable){
            this.drawTitle(this.typewidth, this.typetitle)
            this.x+=this.typewidth
        }
        if (this.priorityenable){
            this.drawTitle(this.prioritywidth, this.prioritytitle)
            this.x+=this.prioritywidth
        }
        if (this.messageenable){
            this.drawTitle(this.messagewidth, this.messagetitle)
            this.x+=this.messagewidth
        }
        if (this.valueenable){
            this.drawTitle(this.valuewidth, this.valuetitle)
            this.x+=this.valuewidth
        }
        if (this.acktimeenable){
            this.drawTitle(this.acktimewidth, this.acktimetitle)
            this.x+=this.acktimewidth
        }
        this.y+=this.titlefontsize+5
        let numrow = 0;
        this.eventsdata.forEach((ev)=>{
            numrow++;
            //console.log("numrow="+numrow)
            if (numrow<this.beginrow)
                return;
            if (this.y+this.titlefontsize+5>this.height)
             return;
            
            this.drawRow(ev)
        });
        this.node.on('mousedown touchstart',()=>{  
            eventstabledialogboxModule.setEventsTable(this)   
            eventstabledialogboxModule.setEventsTableDialogEnabled(true)
            eventstabledialogboxModule.setOnlyActiveState(this.onlyactivestate)
            eventstabledialogboxModule.setOnlyUnAck(this.onlyunack)
      });
        this.node.on('wheel', (e)=> {
            this.beginrow+=e.evt.deltaY/(3*this.titlefontsize+3)
            if(this.beginrow<0)
                this.beginrow=0
            if (this.beginrow>this.eventsdata.length-2)
                this.beginrow=this.eventsdata.length-2
            console.log("beginrow="+this.beginrow)
        
            setTimeout(()=>{
                this.drawEventTable()
            }, 200);
            //this.drawEventTable();
        })
    }
    private drawRow(event:eventsdata){
        this.x=0
        let color ="0x000000FF"
        let bgcolor = "0xFFFFFFFF"
        this.prioritycolorrange.forEach((range)=>{
                if (range.from<=event.priority && range.to>=event.priority)
                    color = range.color
        })
        if (event.ack)
            bgcolor = this.ackcolor
        else
            bgcolor = this.unackcolor
        
        if (this.nameenable){
            this.drawRowCell(this.namewidth, event.name,color, bgcolor)
            this.x+=this.namewidth
        }
        if (this.timeenable){
            this.drawRowCell( this.timewidth, format(event.time, this.timeformat).toString(), color, bgcolor)
            this.x+=this.timewidth
        }
        if (this.typeenable){
            this.drawRowCell(this.typewidth, event.type,color,bgcolor)
            this.x+=this.typewidth
        }
        if (this.priorityenable){
            this.drawRowCell(this.prioritywidth, event.priority.toFixed(0),color,bgcolor)
            this.x+=this.prioritywidth
        }
        if (this.messageenable){
            this.drawRowCell(this.messagewidth, event.message,color,bgcolor)
            this.x+=this.messagewidth
        }
        if (this.valueenable){
            this.drawRowCell(this.valuewidth, event.value,color,bgcolor)
            this.x+=this.valuewidth
        }
        if (this.acktimeenable){
            this.drawRowCell( this.acktimewidth, event.acktime==0?"": format(event.acktime, this.timeformat).toString(), color, bgcolor)
            this.x+=this.acktimewidth
        }
        this.y+=this.titlefontsize+5
    }
    private drawRowCell(width:number, text:string, textcolor:string, bgcolor:string){
        this.drawCell(width, text, textcolor,bgcolor)
    }
    private drawTitle(width:number, text:string){
        this.drawCell(width,text,'0x000000FF', '0xBBBBBBFF')
    }
    private drawCell(width:number, text:string, textcolor:string, fillcolor:string){
        if (this.x>this.width) return;
        const cell = new TextView()
        cell.width = (this.x+width)>this.width?this.width-this.x:width
        cell.height = this.titlefontsize+5
        cell.text = text
        cell.fontsize = this.titlefontsize
        cell.posx = this.x;
        cell.posy = this.y
        cell.textplacement = 1;
        cell.textcolor = textcolor
        cell.useborder = true
        cell.bordercolor = '0x000000FF'
        cell.linewidth = 1
        cell.fill = true
        cell.fillcolor = fillcolor
        cell.underline = false
        cell.initLayer(this.layer)
        cell.drawObject()
        this.node.add(cell.rotatednode)
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { RotationIndicatorProperty } from "./properties/rotationindicatorproperty";
import Konva from "konva";
import { SuperGif } from '../utils/supergif/super-gif';
import { Fan } from './fan';
import pump_slow_rot from '@/assets/propeller_axial_left_01.gif'
import pump_slow from '@/assets/propeller_axial_right_01.gif'
import pump_medium from '@/assets/propeller_axial_right_005.gif'
import pump_medium_rot from '@/assets/propeller_axial_left_005.gif'
import pump_fast from '@/assets/propeller_axial_left_002.gif'
import pump_fast_rot from '@/assets/propeller_axial_right_002.gif'
import pump_stil from '@/assets/propeller_axial_left.png'
import pump_stil2 from '@/assets/propeller_axial_right.png'

export class FanAxial extends Fan{
    supergif:SuperGif;
    imageObj:HTMLImageElement;
    public drawObject():void{

		super.initObject();
    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
                 
    fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
  let speed = 0
  let indicator = false;
  const rotationprop = this.properties['rotationindicator']
  if(rotationprop!=null){
    const rotationIndicatorProperty: RotationIndicatorProperty = Object.assign(new RotationIndicatorProperty(), rotationprop)
    if(rotationIndicatorProperty!=null) {
      speed = rotationIndicatorProperty.speed;
      indicator = rotationIndicatorProperty.CheckIndicator(this.tagnames)
    }
    
  }
  if (fillcolor2!='#00000000'){
        const rectfill = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
        });
        if(this.type3d==0){
          rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
          rectfill.fillLinearGradientEndPoint({ x: this.width, y: this.height });
          rectfill.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2,0.6),
             0.5, fillcolor2, 1, ColorUtils.darkColor(fillcolor2,0.6)])
        }
        else{
          rectfill.fill(fillcolor2)
        }
        this.node.add(rectfill) 
      }
        if (this.imageObj==null)
          this.imageObj = new Image();
       
         if (indicator) {
          if (this.rotation==0) {
            if (speed==5000)
              this.imageObj.src = pump_medium
            else if (speed<5000)
              this.imageObj.src = pump_fast
            else
              this.imageObj.src = pump_slow
          }
          else {
            if (speed==5000)
              this.imageObj.src = pump_medium_rot
            else if (speed<5000)
              this.imageObj.src = pump_fast_rot
            else
              this.imageObj.src = pump_slow_rot
          }
          if (this.supergif==null){
             this.supergif = new SuperGif(this.imageObj,{
           
            });
          this.supergif.load(function(){
             console.log("loaded")
          })
        }
          const animation = new Konva.Animation(() => {const a =1;}, this.layer);
          animation.start();
        }
        else{
            if (this.rotation==0)
                this.imageObj.src = pump_stil2
            else 
                this.imageObj.src = pump_stil
        }
          const image = new Konva.Image({
            image: indicator?this.supergif.getCanvas():this.imageObj,
            width:this.width,
            height:this.height
          });
          this.node.add(image)
}

}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83271c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-12" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { class: "text resetlabel" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('reset.enteremail')), 1),
      _createVNode(_component_q_form, {
        onSubmit: _ctx.handleReset,
        class: "q-gutter-md"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_input, {
            filled: "",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            label: _ctx.t('profile.email'),
            hint: _ctx.t('profile.enteremail'),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.t('profile.emailisrequired'),
                    val => val.length <50 || _ctx.t('profile.mustbemaximum50ch'),
                    this.isValidEmail]
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "email" })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "hint", "rules"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_btn, {
              label: _ctx.t('reset.resetpassword'),
              class: "full-width",
              size: "lg",
              type: "submit",
              color: "primary",
              loading: _ctx.loading,
              disable: _ctx.loading
            }, null, 8, ["label", "loading", "disable"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.message)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ]))
}
import { SVGObject } from "../svgobject";

export class SVGButton19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Slider2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.102 112.5" enable-background="new 0 0 74.102 112.5" xml:space="preserve">
        <g id="Group_Slider2">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-10.3071" y1="41.7998" x2="46.626" y2="58.1251">
            <stop offset="0.01" style="stop-color:#CCCCCC" />
            <stop offset="1" style="stop-color:#666666" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" d="M31.195,111.938L0.452,4.279L12.051,0.45l30.743,15.428  L61.938,4.279" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="47.4556" y1="54.7861" x2="57.2766" y2="57.6023">
            <stop offset="0" style="stop-color:#666666" />
            <stop offset="1" style="stop-color:#B6B6B6" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.5" d="M61.938,4.279l11.6-3.829L42.794,108.107l-11.599,3.83" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.4521" y1="58.1084" x2="61.938" y2="58.1084">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.5" d="M31.195,111.938L0.452,4.279l30.743,15.428L61.938,4.279  L31.195,111.938z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.40625, 151.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 1.0, 0.578125, 107.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.4322916666666667, 0.0]);

		
        super.drawObject();
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { Rectangle } from "./rectangle";
import { Value } from '../value';


export class Border extends Rectangle{
   
    inner:boolean;
    

    public drawObject():void{
		super.initObject();
        
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
	
		let fillcolor = '0xffffff00';
        if (this.fill){
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const rectfill = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                fill: ColorUtils.convertformat(fillcolor),
              });
              this.node.add(rectfill)
            }
            
            const line1 = new Konva.Line({
                points: [0,0,0, this.height],
                stroke: this.inner ? 'white': 'black'
            })
            const line2 = new Konva.Line({
                points: [0, this.height, this.width, this.height],
                stroke: !this.inner ? 'white': 'black'
            })
            const line3 = new Konva.Line({
                points: [this.width, this.height, this.width, 0],
                stroke: !this.inner ? 'white': 'black'
            })
            const line4 = new Konva.Line({
                points: [this.width, 0, 0, 0],
                stroke: this.inner ? 'white': 'black'
            })
            const line5 = new Konva.Line({
                points: [this.linewidth, this.linewidth, this.linewidth, this.height-this.linewidth],
                stroke: !this.inner ? 'white': 'black'
            })
            const line6 = new Konva.Line({
                points: [this.linewidth, this.height-this.linewidth, this.width-this.linewidth, this.height-this.linewidth],
                stroke: this.inner ? 'white': 'black'
            })
            const line7 = new Konva.Line({
                points: [this.width-this.linewidth, this.height-this.linewidth, this.width-this.linewidth, this.linewidth],
                stroke: this.inner ? 'white': 'black'
            })
            const line8 = new Konva.Line({
                points: [this.width-this.linewidth, this.linewidth, this.linewidth, this.linewidth, this.linewidth],
                stroke: !this.inner ? 'white': 'black'
            })
          this.node.add(line1, line2, line3, line4, line5, line6, line7, line8)
          console.log('this.linewidth', this.linewidth)
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "inner": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "inner": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank63 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 95.27" enable-background="new 0 0 112.5 95.27" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="54.6733" y1="95.27" x2="54.6733" y2="40.7661">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M3.378,40.766v0.148C1.48,42.337,0,53.928,0,68.018s1.48,25.681,3.378,27.104v0.148h105.968V40.766   H3.378z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="49.5493" y1="39.9775" x2="62.8379" y2="39.9775">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M49.549,39.189h13.289v1.576H49.549V39.189z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="47.9731" y1="39.0205" x2="64.4141" y2="39.0205">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M47.973,39.189h16.441v-0.338H47.973V39.189z" />
            <path d="M63.626,38.852h0.338v0.338h-0.338V38.852z" />
            <path d="M60.924,38.852h0.788v0.338h-0.788V38.852z" />
            <path d="M58.672,38.852h0.337v0.338h-0.337V38.852z" />
            <path d="M55.968,38.852h0.451v0.338h-0.451V38.852z" />
            <path d="M53.378,38.852h0.338v0.338h-0.338V38.852z" />
            <path d="M51.014,38.852h0.45v0.338h-0.45V38.852z" />
            <path d="M48.423,38.852h0.338v0.338h-0.338V38.852z" />
            <ellipse fill="#CC9933" cx="109.234" cy="68.018" rx="3.266" ry="27.252" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M37.275,95.27h-0.113c0,0-3.266-4.266-3.266-21.847   c0-1.407,0-4.223,0-5.63c0-26.187,3.266-27.028,3.266-27.028h0.113" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M75.275,95.27h-0.113c0,0-3.266-4.266-3.266-21.847   c0-1.407,0-4.223,0-5.63c0-26.187,3.266-27.028,3.266-27.028h0.113" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M34.171,78.153h37.726" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2484" d="M0.676,84.235h33.896" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M72.41,84.235h34.347" />
            <ellipse fill="#4D4D4D" cx="109.121" cy="59.009" rx="0.901" ry="4.505" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="7.9956" y1="39.02" x2="15.2026" y2="39.02">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M7.996,37.275h7.207v3.491H7.996V37.275z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="6.7568" y1="36.937" x2="16.3286" y2="36.937">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M6.757,37.275h9.572v-0.676H6.757V37.275z" />
            <path d="M6.757,36.599h9.572v-0.788H6.757V36.599z" />
        </g>
        <g id="Group_Icon1">
            <path fill="#FFFFFF" d="M3.378,80.743h8.333v1.915H3.378V80.743z" />
            <path fill="#AE2F2F" d="M3.378,77.365h8.333v3.378H3.378V77.365z" />
        </g>
        <g id="Group_Icon2">
            <path fill="#400D0D" d="M10.585,63.625v-4.616l0.451-4.505h11.036l-0.338,4.505v4.616H10.585z" />
        </g>
        <g id="Group_2">
            <path fill="#4C4C4C" d="M77.027,6.419l-0.451-1.126h5.744l-0.338,1.126H77.027z" />
            <path fill="#4C4C4C" d="M79.279,0.676h0.338V0h-0.338V0.676z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="76.2383" y1="2.9844" x2="82.6582" y2="2.9844">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M76.238,5.293l0.338-4.617h5.744l0.338,4.617H76.238" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="77.0273" y1="23.5923" x2="81.9824" y2="23.5923">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M77.027,6.419h4.955v34.347h-4.955V6.419" />
            <path fill="#999999" d="M75.788,33.896h7.319v1.464h-7.319V33.896z" />
            <path fill="#999999" d="M75.788,35.36h7.319v1.239h-7.319V35.36z" />
            <path d="M75.788,35.36h7.319v0.451h-7.319V35.36z" />
        </g>
        <g id="Group_1">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="102.9277" y1="21.1147" x2="105.6309" y2="21.1147">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M102.928,1.464h2.703v39.302h-2.703V1.464z" />
            <path fill="#333333" d="M102.928,36.937l-0.788-0.338l0.788-1.915h2.703l0.338,1.915l-0.338,0.338H102.928z" />
            <path fill="#333333" d="M106.307,8.333l-1.914-6.87l3.04-0.788l1.914,6.869L106.307,8.333z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="105.9346" y1="7.9473" x2="104.0965" y2="1.0877">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M104.393,8.333l-1.465-6.87l2.703-0.788l1.802,6.869L104.393,8.333z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
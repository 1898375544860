import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import tag from "@/model/tag";
import TagsService from '@/services/tags.service';
import { TextView } from './text'
import historydatarequest from "@/model/requests/historydatarequest";
import { RangeValueProperty } from "./properties/rangevalueproperty";
import AuthModule from "@/store/auth.module";
import { Gauge } from './gauge';


export class Gauge2 extends Gauge{

    public drawObject():void{
        //super.initObject();
        let rangeproperty:RangeValueProperty
          const rangeprop = this.properties['rangevalue'];
          let tag:tag
            if (rangeprop!=null){	
                 rangeproperty =Object.assign(new RangeValueProperty(), rangeprop);
                if (rangeproperty!=null) 
                       tag =  rangeproperty.getTag(this.tagnames)
            }	
        if (tag==null || !tag.usehistory || rangeproperty==null || !rangeproperty.showhistory)
        {
            this.drawRangeGauge(tag,rangeproperty,null, null)
            return;
        }
        if (AuthModule.currentUser!=null){
            const enddate = Date.now();
            const begindate = enddate - rangeproperty.getDuration();//delta*minutes
           const request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
              name:tag.path==null?tag.name:tag.path, begindate:begindate, enddate:enddate};
            TagsService.gethistorydata(request,(data:string)=>{
                 const historydata =JSON.parse(data);
                 let hisMin:number
                 let hisMax:number
                 
                 const dataY = []
                 if(historydata.length>1){
                 historydata.forEach((data)=>{
                   dataY.push(data['y'])
                 })
                 hisMin = Math.min(...dataY)
                 hisMax = Math.max(...dataY)
                }

                this.drawRangeGauge(tag,rangeproperty, hisMin, hisMax);
            
            }, 
            (data:string)=>{
                console.log('data', data);
             })
        }
    }
    private drawRangeGauge(tag:tag,rangeproperty:RangeValueProperty, hisMin:number, hisMax:number){
        super.initObject();

		let fillcolor = '0xffffff00'; 
        fillcolor = this.fillcolor;
        const fillprop = this.properties==null? null : this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor)

        let color = this.color; 
        const colorprop = this.properties==null? null : this.properties['color'];
            if (colorprop!=null){	
                const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
                if (colorproperty!=null) 
                        color = colorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        const color2 = ColorUtils.convertformat(color)    
       
  
       /* const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradientrectSmall = ctx.createLinearGradient(0, 0, 0, this.height*2/20);
          gradientrectSmall.addColorStop(0, '#000000')
          gradientrectSmall.addColorStop(1, '#FFFFFF')*/

      /*  const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: this.width/2,
            fill: fillcolor2,
            });
          this.node.add(circle)*/
         

        const backsector = new Konva.Arc({
          x: this.width/2,
          y: this.height/2,
          innerRadius: this.width*.25,
          outerRadius: this.width*.45,
          angle: 300,
          rotation: 120,
          fill: fillcolor2,
          })
          this.node.add(backsector)
         

        const radius = this.width*.445;
		const innerradius =radius-this.width/8;
		const innerradius2 =radius-this.width/12;
		const degreestep = 270/this.interval;
		const degreestep2 = degreestep/2;
		let valuemin=0;
		let valuemax=240;
		let decimalpos=0;

        if (rangeproperty!=null){
            valuemin = rangeproperty.minimumvalue;
            valuemax = rangeproperty.maximumvalue;
            decimalpos=rangeproperty.decimalpos;
        }
        
        if(rangeproperty!=null && rangeproperty.colorrange!=null && rangeproperty.colorrange.length!=0){
            rangeproperty.colorrange.forEach((range)=>{
                const begindegree =rangeproperty.get270value(range['from'])
                const enddegree  = rangeproperty.get270value(range['to'])
                const rangeColor = range['color']
                
                const sector = new Konva.Arc({
                    x: this.width/2,
                    y: this.height/2,
                    innerRadius: this.width*.40,
                    outerRadius: this.width*.45,
                    angle: begindegree-enddegree,
                    rotation:-begindegree,
                    fill: ColorUtils.convertformat(rangeColor)
                })
                this.node.add(sector)
                
            })
        }
        if(rangeproperty!=null && rangeproperty.showhistory){
            const begindegree = rangeproperty.get270value(hisMin);
            const enddegree = rangeproperty.get270value(hisMax)

            const sectorHistory = new Konva.Arc({
                x: this.width/2,
                y: this.height/2,
                innerRadius: 0,
                outerRadius: this.width/3,
                angle: begindegree-enddegree,
                rotation:-begindegree
                    })
            sectorHistory.fillRadialGradientStartPoint({ x: 0, y: 0 });
            sectorHistory.fillRadialGradientStartRadius(0);
            sectorHistory.fillRadialGradientEndRadius(this.width/3);
            sectorHistory.fillRadialGradientColorStops([0.75, ColorUtils.convertformat(rangeproperty.historycolor).slice(0,-2)+'00',
                1, ColorUtils.convertformat(rangeproperty.historycolor)])
            this.node.add(sectorHistory)
            
        }

        const valuestep = (valuemax-valuemin)/this.interval;

        for (let i=0;i<=this.interval;i++){
            const degrees=degreestep*i-45;
            const radians = degrees*(Math.PI/180);
            const x1=this.width/2-radius*Math.cos(radians);
            const y1=this.height/2-radius*Math.sin(radians);
            const x2=this.width/2-innerradius*Math.cos(radians);
            const y2=this.height/2-innerradius*Math.sin(radians);
            const line = new Konva.Line({
                points: [x1, y1, x2, y2],
                strokeWidth: this.width/75,
                stroke: color2,
            })
            this.node.add(line)
            
            for (let j=1; j<2; j++){
                if (i==this.interval) break;
                const degrees2 = degrees+degreestep2*j;
                const radians2 = degrees2*(Math.PI/180);
                const x12=this.width/2-radius*Math.cos(radians2);
                const y12=this.height/2-radius*Math.sin(radians2);
                const x22=this.width/2-innerradius2*Math.cos(radians2);
                const y22=this.height/2-innerradius2*Math.sin(radians2);
                const line2 = new Konva.Line({
                    points: [x12, y12, x22, y22],
                    strokeWidth: this.width/75,
                    stroke: color2,
                })
                this.node.add(line2)
              
            }
            
            const value = valuemin+valuestep*i;
				const valuetext = value.toFixed(0).toString()
                const textTob = new TextView()
                textTob.width = this.width/4
                textTob.height = this.height/25
                textTob.text = valuetext
                textTob.fontsize = this.height/25
                textTob.textplacement =degrees>92? 0: (degrees<88 ? 2 : 1)
                textTob.textcolor = 'white'
                textTob.useborder = false
                textTob.bordercolor = null
                textTob.linewidth = 1
                textTob.fill = false
                textTob.fillcolor = 'white'
                textTob.underline = false

                if (degrees<=-45){
                    textTob.posx = (x1-textTob.width-this.width/50)
					textTob.posy = (y1+this.height/25-textTob.height)
				}else if (degrees<=0){
					textTob.posx = (x1-textTob.width-this.width/50)
					textTob.posy = (y1+this.height/50-textTob.height)

                }else if (degrees<=45){
                    textTob.posx = (x1-textTob.width-this.width/50)
					textTob.posy = (y1+this.height/150-textTob.height)
                }else if(degrees<=88){
					textTob.posx = (x1-textTob.width-this.width/50)
					textTob.posy = (y1-this.height/75-textTob.height)
                }else if (degrees>=225){
                    textTob.posx = (x1+this.width/50)
					textTob.posy = (y1+this.height/25-textTob.height)
				}else if(degrees>180){
					textTob.posx = (x1+this.width/50)
					textTob.posy = (y1+this.height/50-textTob.height)
                }else if (degrees>135){
                    textTob.posx = (x1+this.width/50)
					textTob.posy = (y1-this.height/150-textTob.height)
                }else if(degrees>92){
                    textTob.posx = (x1+this.width/75);
					textTob.posy = (y1-this.height/75-textTob.height)
                }else{
                    textTob.posx = (x1-textTob.width/2);
					textTob.posy = (y1-textTob.height*1.5)
                }

                textTob.initLayer(this.layer)
                textTob.drawObject()
                this.node.add(textTob.rotatednode)  
        }
     
        let arrowWidth = this.width/3
        let arrowHeight = innerradius2/2
        if (arrowWidth<1)  arrowWidth = 1;
        if (arrowHeight<1) arrowHeight = 1;
        
        const arrow = new Konva.Group({
            x: 0,
            y: 0,
          }); 

        const triangle = new Konva.Line({
            points: [0, arrowHeight/2,
            arrowWidth-arrowHeight/2, 0,
            arrowWidth-arrowHeight/2, arrowHeight],
            fill: color2,
            closed: true,
        });
        arrow.add(triangle)

        const circleBall = new Konva.Circle({
            x: arrowWidth-arrowHeight/2,
            y: arrowHeight/2,
            radius: arrowHeight*.48,
            fill: color2,
        });
        arrow.add(circleBall)

        const circleBallsmall = new Konva.Circle({
            x: arrowWidth-arrowHeight/2,
            y: arrowHeight/2,
            radius: arrowHeight*.24,
            fill: fillcolor2,
        });
        arrow.add(circleBallsmall)

        this.node.add(arrow)
        
        const tagvalue = tag==null? 0: rangeproperty.getCurrentValue270deg(Number(tag.tagvalue))
        
        //const len = arrowWidth-arrowHeight/2+arrowHeight*.48;

        const anglearr = tagvalue-45;
		//const radians = anglearr*Math.PI/180;
       
        const posx = this.width/2;
        const posy = this.height/2

        //const x =arrowWidth/2+posx-arrowWidth/2* Math.cos(radians) +(arrowHeight)* Math.sin(radians);
        //const y = posy+arrowHeight/2 -(arrowWidth/2)* Math.sin(radians) -(arrowHeight)* Math.cos(radians);
       
        arrow.offsetX(arrowWidth-arrowHeight/2)
        arrow.offsetY(arrowHeight/2)
        arrow.position({x:posx,y:posy})
        arrow.rotation(anglearr)
          
        
          if (this.usedigital){
            let tagvalueText =tag==null? 0: Number(tag.tagvalue)
            if(rangeproperty!=null && +tagvalueText>rangeproperty.maximumvalue) tagvalueText = rangeproperty.maximumvalue
            if(rangeproperty!=null && +tagvalueText<rangeproperty.minimumvalue) tagvalueText = rangeproperty.minimumvalue
            const digback = new Konva.Line({
                points: [this.width*.40, this.height*.83,
                    this.width*.40,this.height*.8,
                    this.width*.42, this.height*.78,
                    this.width*.58, this.height*.78, 
                    this.width*.60, this.height*.8, 
                    this.width*.60, this.height*.83, 
                    this.width*.58, this. height*.85, 
                    this.width*.42, this. height*.85],
                fill: 'gray',
                closed: true,
              });
              this.node.add(digback)

              const valuetextob = new Konva.Text({
                x: this.width/2-this.width*.1,
                y: this.height*.83- this.height/25,
                width: this.width*.2,
                height: this.height*.05,
                text: tagvalueText.toFixed(decimalpos).toString(),
                fontSize: this.height/25,
                fill: 'white',
                align: 'center',
                verticalAlign: 'middle'
              });
              this.node.add(valuetextob)

            }

       const textplusunit = new TextView()
       textplusunit.width = this.width/2
       textplusunit.height = this.height/25
       textplusunit.text = this.text +', '+this.unit
       textplusunit.fontsize = this.height/25
       textplusunit.posx = this.width/2-this.width/4
       textplusunit.posy = this.height*.90 - this.height/25
       textplusunit.textplacement = 1
       textplusunit.textcolor = color2
       textplusunit.useborder = false
       textplusunit.bordercolor = null
       textplusunit.linewidth = 1
       textplusunit.fill = false
       textplusunit.fillcolor = 'white'
       textplusunit.underline = false
       textplusunit.initLayer(this.layer)
       textplusunit.drawObject()
       this.node.add(textplusunit.rotatednode)
           
    }
}
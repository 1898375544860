import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { Curve } from "./properties/range/curve";
import { TextView } from './text'
import Konva from "konva";
import { format } from 'date-fns'
import AuthModule from "@/store/auth.module";
import historydatarequest from "@/model/requests/historydatarequest";
import TagsService from '@/services/tags.service';
import trenddialogboxModule from "@/store/trenddialogbox.module";
import { CurrentPoint } from "../utils/currentpoint";
import { KonvaEventObject } from "konva/lib/Node";
import { Value } from "../value";
import { Statistics } from "../utils/statistics";


export class Trend extends ObjectView{
    color:string;
    linewidth:number;
    fill:boolean;
    fillcolor:string;
    gridcolor:string;
    linestyle:string;
    gridlinewidth:number;
    horizontally:number;
    vertically:number;
    maximum:number;
    minimum:number;
    fontsize:number;
    markcolor:string;
    timeformat:string;
    defaulttimeperiod:number;
    curves:Curve[];
    begin:number;
    end: number;
    shapes:Konva.Shape[];
    labels:Konva.Label[];
    points:CurrentPoint[]

    public drawObject():void{
		super.initObject();

        
        if(this.defaulttimeperiod==null) this.defaulttimeperiod = 10
        if(this.timeformat==null || this.timeformat == '') this.timeformat = "hh:mm:ss"
        if (this.begin==undefined)  this.begin=Date.now()-this.defaulttimeperiod*60*1000;
        if (this.end==undefined || this.end==0) this.end=Date.now();
        

       /* if((this.begin==0 || this.begin==undefined) && (this.end==0 || this.end==undefined)){
            this.end = Date.now()
            this.begin = this.end - 1000*60*this.defaulttimeperiod;   
        }*/
        
        let dash:number[];
		switch(this.linestyle)
		{
			case "Dash": 
                dash = [6.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			case "Dot": 
                dash = [1.7*this.gridlinewidth, 1.7*this.gridlinewidth];
				break;
			case "DashDot": 
                dash = [6.5*this.gridlinewidth, 2*this.gridlinewidth, 1.5*this.gridlinewidth, 2*this.gridlinewidth];
				break;
			default:
				break;
		}

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
				color = linecolorproperty.getColor(this.tagnames, this.color)
		}	

		let fillcolor = '0xffffff00';
       
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)   
        } 

        if (AuthModule.currentUser!=null){
           this.curves.forEach((curve)=>{
            if (curve.hide) return;
           
            const request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
                name:curve.path==null?curve.tagname:curve.path, begindate:this.begin, enddate:this.end};
        
              TagsService.gethistorydata(request,(data:string)=>{
        
                   const historydata =JSON.parse(data);   
                   curve.historydata = historydata         
                   const points = []
            
                   if(historydata.length>1){
                   historydata.forEach((data, index)=>{
                        if(data['x']<this.begin || data['x']>this.end)return
                    
                        if(curve.type==0 || curve.type==4){
                            const x =(data['x']-this.begin)*rectwidth/(this.end-this.begin)
                            let y = (this.maximum - data['y'])*rectheight/(this.maximum-this.minimum)
                            if(y<0) y=0
                            if(y> rectheight) y=rectheight
                            points.push(horgap+x)
                            points.push(y)
                        }else if(curve.type==1 || curve.type==5){
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                points.push(horgap+x)
                                points.push(rectheight)
                            }else{
                                points.push(horgap+x)
                                points.push(y)
                            }
                        }else if(curve.type==2){
                            const previosData = historydata[index-1]
                            let oldy = index==0? 0 : (this.maximum-previosData['y'])*rectheight/(this.maximum-this.minimum);
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                oldy=y}
                            if(oldy!=y){
                                points.push(horgap+x)
                                points.push(oldy)
                            }
                            points.push(horgap+x)
                            points.push(y)
                            oldy=y
                        }else if(curve.type==3){
                            const previosData = historydata[index-1]
                            let oldy = index==0? 0 : (this.maximum-previosData['y'])*rectheight/(this.maximum-this.minimum);
                            const x = (data['x']-this.begin)*rectwidth/(this.end-this.begin);
                            let y = (this.maximum-data['y'])*rectheight/(this.maximum-this.minimum);
                            if (y<0) y=0;
                            if (y>rectheight) y=rectheight;
                            if (points.length<=0){
                                oldy=y
                                points.push(horgap+x)
                                points.push(rectheight)}
                            if(oldy!=y){
                                points.push(horgap+x)
                                points.push(oldy)
                            }
                            points.push(horgap+x)
                            points.push(y)
                            oldy=y
                        } 
                    })
                    if (curve.type==4 || curve.type==5){
                        const smoothPointsPolygon = Statistics.subdividePoints(Statistics.devideToPoints(points),8)
                        points.splice(0, points.length)
                        smoothPointsPolygon.forEach((point)=>{
                            points.push(point.x, point.y)
                        })
                    }
                    if(curve.type==0 || curve.type==2 || curve.type==4){
                    const curveLine = new Konva.Line({
                        points: points,
                        strokeWidth: curve.linewidth,
                        stroke: ColorUtils.convertformat(curve.color)
                    })
                    this.node.add(curveLine)
                    }else if(curve.type==1 || curve.type==3 || curve.type==5){
                        if(points.length>2){
                            points.push(points[points.length-2])
                            points.push(rectheight)
                        }
                        const curveLine = new Konva.Line({
                            points:points,
                            closed: true,
                            fill: ColorUtils.transColor(ColorUtils.convertformat(curve.color),0.3),
                            strokeWidth: curve.linewidth,
                            stroke: ColorUtils.convertformat(curve.color)
                        })
                        this.node.add(curveLine)
                    }
                    const border = new Konva.Rect({
                        x: horgap,
                        y: 0,
                        width: rectwidth,
                        height: rectheight,
                        stroke: ColorUtils.convertformat(color),
                        strokeWidth: this.linewidth,
                    });
                    this.node.add(border)
                }
              }, 
              (data:string)=>{
                  console.log('data', data);
               })
           })
        } 
        
        if (this.linewidth<1 || this.linewidth==0) this.linewidth=1;
		if (this.gridlinewidth<1)this.gridlinewidth=1;
		if (this.horizontally<1)this.horizontally=1;
		if (this.vertically<1) this.vertically=1;
		const vergap = this.height/10;
		const horgap = this.width/10;
		const rectwidth=this.width-horgap;
		const rectheight=this.height-vergap;
		if (this.fill){
			const rect = new Konva.Rect({
                x: horgap,
                y: 0,
                width: rectwidth,
                height: rectheight,
                fill: ColorUtils.convertformat(fillcolor),
                strokeWidth: this.linewidth,
            });
            this.node.add(rect)
		}

        const horwidth = rectwidth/this.horizontally;
		const verwidth = rectheight/this.vertically;
		const step = (this.maximum-this.minimum)/this.vertically;

        for (let i=0;i<=this.vertically;i++){
            const line = new Konva.Line({
                points: [horgap, i*verwidth, this.width, i*verwidth],
                stroke: ColorUtils.convertformat(this.gridcolor),
                strokeWidth: this.gridlinewidth,
                dash: dash
            })
            this.node.add(line)
			
			let value = this.maximum-i*step;
            value = parseFloat(value.toFixed(2));

            const mark = new TextView()
            mark.width = this.width/10
            mark.height = this.fontsize
            mark.text = value.toString()
            mark.fontsize = this.fontsize
            mark.posx = 0
            mark.textplacement = 2
            mark.textcolor = this.markcolor
            mark.useborder = false
            mark.bordercolor = null
            mark.linewidth = this.linewidth
            mark.fill = false
            mark.fillcolor = 'white'
            mark.underline = false
            if(i==0) {mark.posy = i*verwidth}
            else if(i==this.vertically){mark.posy =i*verwidth-this.fontsize}
            else{mark.posy = i*verwidth-this.fontsize*2/3}

            mark.initLayer(this.layer)
            mark.drawObject()
            this.node.add(mark.rotatednode)
		}

		for (let j=0;j<=this.horizontally;j++){
            const line1 = new Konva.Line({
                points: [horgap+j*horwidth, 0, horgap+j*horwidth, rectheight],
                stroke: ColorUtils.convertformat(this.gridcolor),
                strokeWidth: this.gridlinewidth,
                dash: dash
            })
            this.node.add(line1)
          
           
           const timestep = (this.end-this.begin)/this.horizontally;

            const markTime = new TextView()
            markTime.width = horwidth
            markTime.height = this.fontsize
            markTime.text = format(this.begin+timestep*j, this.timeformat).toString() 
            markTime.fontsize = this.fontsize
            markTime.posy = rectheight+this.fontsize/2
            markTime.textplacement = j==0?  0 : (j==this.horizontally? 2: 1)
            markTime.textcolor = this.markcolor
            markTime.useborder = false
            markTime.bordercolor = null
            markTime.linewidth = this.linewidth
            markTime.fill = false
            markTime.fillcolor = 'white'
            markTime.underline = false
            if(j==0) {markTime.posx = horgap}
            else if(j==this.horizontally){markTime.posx =this.width-horwidth}
            else{markTime.posx = horgap+(j-1)*horwidth+horwidth/2}

            markTime.initLayer(this.layer)
            markTime.drawObject()
            this.node.add(markTime.rotatednode)
		}
        this.node.on('mousedown touchstart',()=>{  
            trenddialogboxModule.setTrend(this) 
            trenddialogboxModule.setHistoryDB(null)    
            trenddialogboxModule.setTrendDialogEnabled(true);
        });

      this.node.on('mousemove',(event)=>{
        this.clearShapesAndLabels()
         this.findPoints(event)
          this.drawTimesLinesAndTexttime(this.points)
          this.drawCurveTicks(this.points)
      })
      this.node.on('mouseleave mouseout',(event)=>{
       this.clearShapesAndLabels()
      })
    }
    public setField(field:string, value:Value){
        super.setField(field, value)
        const timems = Date.now()
        switch(field){
            case "color":
            case "fillcolor":
            case "gridcolor":
            case "linestyle":
            case "timeformat":
            case "markcolor":   {this[field] = value.value;this.rotatednode=null;break;}

            case "gridlinewidth":
            case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "vertically":
            case "horizontally": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "maximum":
            case "minimum": {this[field] = value.value;this.rotatednode=null;break;}

            case "fontsize":
            case "defaulttimeperiod": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "fill": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}

            case "begin": {
                this[field] = timems-Math.round(value.value*60*1000);
                break;
            }
            case "end":{
                if (value.value==null || value.value == 0) this.end = Date.now();
                this[field] = timems - Math.round(value.value*60*1000);
                break;
            }
            case "begindatetime": {
                if (value.value==null) this.begin = 0;
                this.begin = value.value;
                break;
            }
            case "enddatetime": {
                if (value.value==null) this.end = Date.now();
                this.end = value.value;
                break;
            }
            //case "title":
            //case "number":
        }
        //this.drawObject();
    }
    public getField(field:string):Value{
        const value = super.getField(field)
        const timems = Date.now()
        switch(field){
            case "color":
            case "fillcolor":
            case "gridcolor":
            case "linestyle":
            case "timeformat":
            case "markcolor":   {value.datatype=7; value.value = this[field];break}

            case "gridlinewidth":
            case "linewidth": {value.datatype=2; value.value = this[field];break}

            case "vertically":
            case "horizontally": {value.datatype=1; value.value = this[field];break}

            case "maximum":
            case "minimum": {value.datatype=6; value.value = this[field];break}

            case "fontsize":
            case "defaulttimeperiod": {value.datatype=3; value.value = this[field];break}

            case "fill": {value.datatype=0; value.value = (this[field]);break}

            case "begin":
            case "end": {value.datatype=3; 
                if(this[field] != null) value.value = Math.round((timems-this[field])/60000); 
                break}
            case "begindatetime": {value.datatype=4; value.value = this.begin; break}
            case "enddatetime": {value.datatype=4; value.value = this.end; break}	
            //case "title":
            //case "number":
        }
        return value;
    }
    findPoints(event:KonvaEventObject<MouseEvent>):void{
        if (event==null || event.target==null || event.target.getStage()==null) return
        const pos =  event.target.getStage().getPointerPosition();
        const scale = event.target.getStage().getAbsoluteScale();
        const zoomedwidth = this.width*scale.x;
        const zoomedheight = this.height*scale.y;
        const x = (pos.x - this.posx*scale.x)-0.1*zoomedwidth;
        const y = (pos.y - this.posy*scale.y);
        if (x<0 || x>zoomedwidth*0.9) return;
        if (y<0 || y>zoomedheight*0.9) return;
  
        const time = (this.end-this.begin)*x/(zoomedwidth*0.9)+this.begin;
        if (this.points!=null)
          this.points.length=0;
          this.points = []
          this.shapes = []
          this.labels = []
        this.curves.forEach((curve)=>{
         
          if (curve.hide) return;
          let deltamin = Number.MAX_VALUE
          let valuemin = null
          if (curve.historydata==null) return;
          curve.historydata.forEach((data)=>{
              const curvalue = data['x']
              if (Math.abs(curvalue-time)<deltamin){
                  deltamin = Math.abs(curvalue-time)
                  valuemin = data
              }
              if (valuemin==null) return;
             // console.log(valuemin)
             
          })
          if (valuemin==null) return
          const xmin = (valuemin['x']-this.begin)*0.9*this.width/(this.end-this.begin);
          let ymin = (this.maximum-valuemin['y'])*0.9*this.height/(this.maximum-this.minimum);
          if (ymin<0) ymin=0;
          if (ymin>0.9*this.height) ymin=0.9*this.height;
          this.points.push(new CurrentPoint(xmin, ymin, curve, valuemin));
        
        })
    }
    clearShapesAndLabels():void{
        if (this.shapes!=null){
            this.shapes.forEach((shape)=>{
                shape.destroy()
            })
            this.shapes.length =0;
        }
        if (this.labels!=null){
            this.labels.forEach((label)=>{
                label.destroy();
            })
            this.labels.length=0;
        }
    }
    drawCurveTicks(points:CurrentPoint[]):void{
        if (points==null || points.length==0) return;
        
        const sortedpoints = points.sort((pointa, pointb)=>pointb.y - pointa.y)
        let ymax = sortedpoints[0].y
        sortedpoints.forEach((point)=>{
            const circle = new Konva.Circle({
                x: point.x+0.1*this.width,
                y: point.y,
                radius: this.height/100,
                fill:ColorUtils.convertformat(point.curve.color),
                stroke: ColorUtils.convertformat(point.curve.color),
                strokeWidth: 1,
              });
            this.shapes.push(circle)
            this.node.add(circle)

            const tooltip = new Konva.Label({
                x: 0.1*this.width+point.x,
                y: point.y>ymax?ymax:point.y,            
              });

              tooltip.add(
                new Konva.Tag({
                  fill: ColorUtils.convertformat(this.fillcolor),
                  stroke:ColorUtils.convertformat(point.curve.color),
                  strokeWidth:1,
                  pointerDirection:point.x>this.width*.45?'right':'left',
                  pointerWidth:this.width/50,
                  pointerHeight:point.y>ymax?0: this.height/50,
                  lineJoin: 'round',
                  shadowColor: 'black',
                  shadowBlur: 1,
                  shadowOffsetX: 1,
                  shadowOffsetY: 1,
                  shadowOpacity: 0.5,
                })
              );
              tooltip.add(
                new Konva.Text({
                  text: point.curve.curvename+':'+point.historyvalue['y'],
                  fontFamily: 'Calibri',
                  fontSize: this.fontsize,
                  padding: 2,
                  fill: ColorUtils.convertformat(point.curve.color),
                })
              );
            
              ymax=point.y>ymax?ymax-tooltip.getHeight():point.y-tooltip.getHeight();
              this.node.add(tooltip)
             this.labels.push(tooltip)
        })
    }
    drawTimesLinesAndTexttime(points:CurrentPoint[]):void{
            if (points==null || points.length==0) return;
            const sortedpoints = points.sort((pointa, pointb)=>pointa.x - pointb.x)
            
            const xmin = sortedpoints[0].x
            const xmax = sortedpoints[sortedpoints.length-1].x
            const linemin = new Konva.Line({
                points: [this.width*0.1+xmin,0, this.width*0.1+xmin, 0.9*this.height],
                stroke: ColorUtils.convertformat(this.gridcolor),
                strokeWidth: this.linewidth
            })
            this.node.add(linemin)
            this.shapes.push(linemin)
            if (xmax-xmin>1){
                const linemax = new Konva.Line({
                    points: [this.width*0.1+xmax,0, this.width*0.1+xmax, 0.9*this.height],
                    stroke: ColorUtils.convertformat(this.gridcolor),
                    strokeWidth: this.linewidth
                })
                this.node.add(linemax)
                this.shapes.push(linemax)
            }
            const datemin = sortedpoints[0].historyvalue['x']
        
            const timetext = format(datemin, "MMM dd, HH:mm:ss")
            const datemax = sortedpoints[sortedpoints.length-1].historyvalue['x']
            if (datemax-datemin>1000){
                timetext.concat('-').concat(format(datemax,"HH:mm:ss"))
            }
            const tooltip = new Konva.Label({
                x: 0.1*this.width+xmin+(xmax-xmin)/2,
                y: 0.9*this.height,
               
              });

              tooltip.add(
                new Konva.Tag({
                  fill: ColorUtils.convertformat(this.fillcolor),
                  pointerDirection: 'up',
                  pointerWidth: this.width/50,
                  pointerHeight: this.height/50,
                  lineJoin: 'round',
                  shadowColor: 'black',
                  shadowBlur: 1,
                  shadowOffsetX: 1,
                  shadowOffsetY: 1,
                  shadowOpacity: 0.5,
                })
              );
              tooltip.add(
                new Konva.Text({
                  text: timetext,
                  fontFamily: 'Calibri',
                  fontSize: this.fontsize,
                  padding: 2,
                  fill: ColorUtils.convertformat(this.markcolor),
                })
              );
              this.node.add(tooltip)
             this.labels.push(tooltip)
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank55 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 105.631" enable-background="new 0 0 112.5 105.631" xml:space="preserve">
        <g id="Group_Railing">
            <path fill="#4C4C4C" d="M111.482,0.417h-15.99H79.838H63.734H48.081H31.978H16.325H0v7.095h1.572V5.034h14.752v2.478h1.238V5.034   h14.415v2.478h1.239V5.034h14.865v2.478h1.239V5.034h14.415v2.478h1.238V5.034h14.865v2.478h1.238V5.034h14.416v2.478h1.238V5.034   h14.752v2.478h1.018V0.417H111.482z M16.325,4.133H1.572V2.894h14.752V4.133z M16.325,2.444H1.572V1.205h14.752V2.444z    M31.978,4.133H17.563V2.894h14.415V4.133z M31.978,2.444H17.563V1.205h14.415V2.444z M48.081,4.133H33.216V2.894h14.865V4.133z    M48.081,2.444H33.216V1.205h14.865V2.444z M63.734,4.133H49.32V2.894h14.415V4.133z M63.734,2.444H49.32V1.205h14.415V2.444z    M79.838,4.133H64.973V2.894h14.865V4.133z M79.838,2.444H64.973V1.205h14.865V2.444z M95.492,4.133H81.076V2.894h14.416V4.133z    M95.492,2.444H81.076V1.205h14.416V2.444z M111.482,4.133H96.73V2.894h14.752V4.133z M111.482,2.444H96.73V1.205h14.752V2.444z" />
            <path fill="#FFFF00" d="M111.148,0h-15.99H79.504H63.4H47.748H31.644H15.991H0v7.095h1.239V4.617h14.752v2.478h1.238V4.617h14.415   v2.478h1.239V4.617h14.865v2.478h1.239V4.617H63.4v2.478h1.239V4.617h14.864v2.478h1.239V4.617h14.415v2.478h1.238V4.617h14.752   v2.478h1.352V6.869v-0.45v-0.45V5.406V4.843V4.167V3.491V2.816V2.252V1.577V1.014v-0.45V0.226V0H111.148z M15.991,3.716H1.239   V2.478h14.752V3.716z M15.991,2.028H1.239V0.788h14.752V2.028z M31.644,3.716H17.229V2.478h14.415V3.716z M31.644,2.028H17.229   V0.788h14.415V2.028z M47.748,3.716H32.883V2.478h14.865V3.716z M47.748,2.028H32.883V0.788h14.865V2.028z M63.4,3.716H48.986   V2.478H63.4V3.716z M63.4,2.028H48.986V0.788H63.4V2.028z M79.504,3.716H64.64V2.478h14.864V3.716z M79.504,2.028H64.64V0.788   h14.864V2.028z M95.158,3.716H80.743V2.478h14.415V3.716z M95.158,2.028H80.743V0.788h14.415V2.028z M111.148,3.716H96.396V2.478   h14.752V3.716z M111.148,2.028H96.396V0.788h14.752V2.028z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="56.1946" x2="112.5" y2="56.1946">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,6.757h112.5v98.874H0V6.757z" />
            <path fill="#333333" d="M0,103.942h112.5v1.689H0V103.942z" />
            <path fill="#333333" d="M0,84.122h112.5v1.688H0V84.122z" />
            <path fill="#333333" d="M0,64.19h112.5v1.689H0V64.19z" />
            <path fill="#333333" d="M0,44.369h112.5v1.689H0V44.369z" />
            <path fill="#333333" d="M0,24.888h112.5v1.238H0V24.888z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Railing",[0.0, 0.5885416666666666, 0.0, 1.0, 0.671875, 83.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGValve27 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 82.43" enable-background="new 0 0 112.5 82.43" xml:space="preserve">
        <g id="Group_Stem">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2569" d="M21.599,15.611l3.357-3.356L13.266,0.451L9.91,3.807L21.599,15.611" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.271,11.036L16.554,7.32" />
            <polygon fill="#666666" points="20.624,9.572 20.279,9.894 22.31,11.924 22.132,12.101 20.097,10.065 18.806,11.271 22.319,14.79    24.136,13.091  " />
        </g>
        <g id="Group_Port2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.2769" y1="77.4785" x2="8.2769" y2="58.334">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" points="16.554,77.479 0,77.479 0,58.334 16.34,58.334  " />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="104.2441" y1="77.4785" x2="104.2441" y2="58.334">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <polyline fill="url(#SVGID_2_)" points="95.988,58.334 112.5,58.334 112.5,77.479 96.208,77.479  " />
        </g>
        <g id="Group_BodyAndPort">
            <path fill="#263A59" d="M44.032,59.234l3.491-3.49v-5.182l9.234,8.672H44.032z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="23.7051" y1="82.4336" x2="23.7051" y2="54.0547">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <rect x="14.415" y="54.055" fill="url(#SVGID_3_)" width="18.581" height="28.379" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="56.1392" y1="77.5195" x2="56.1392" y2="58.334">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <rect x="32.771" y="58.334" fill="url(#SVGID_4_)" width="46.736" height="19.186" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="88.6826" y1="82.4336" x2="88.6826" y2="54.0547">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <rect x="79.393" y="54.055" fill="url(#SVGID_5_)" width="18.58" height="28.379" />
        </g>
        <g id="Group_Layer_6">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="21.3125" y1="25.1416" x2="34.3753" y2="12.0788">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M39.302,17.005L26.239,10.36l-6.532,6.645l6.532,13.063" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="54.8159" y1="58.5986" x2="67.9121" y2="45.5024">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M54.611,58.334L37.669,41.391l13.066-13.066l33.046,33.048c0,0,0.051,7.279-9.844,7.281   C63.563,68.656,54.611,58.334,54.611,58.334z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="23.7383" y1="43.918" x2="53.2541" y2="14.4021">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="18.035,38.215 47.551,8.7 58.955,20.104 29.439,49.619  " />
            <path fill="none" stroke="#263A59" stroke-width="0.2458" d="M45.897,49.619l13.224-13.02" />
        </g>
        <g id="Group_Layer_5">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="35.3604" y1="80.124" x2="39.9775" y2="80.124">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M35.36,78.379h4.617v3.49H35.36V78.379z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="42.2295" y1="80.124" x2="46.9595" y2="80.124">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M42.229,78.379h4.73v3.49h-4.73V78.379z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="72.4102" y1="80.124" x2="77.1396" y2="80.124">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M72.41,78.379h4.729v3.49H72.41V78.379z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="65.4277" y1="80.124" x2="70.1582" y2="80.124">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.45" style="stop-color:#A6BAD9" />
                <stop offset="0.55" style="stop-color:#93A7C6" />
                <stop offset="0.73" style="stop-color:#637796" />
                <stop offset="0.77" style="stop-color:#596D8C" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M70.158,78.379h-4.73v3.49h4.73V78.379z" />
            <path fill="#8CA0BF" d="M37.05,72.523h1.126v5.855H37.05V72.523z" />
            <path fill="#8CA0BF" d="M44.032,72.523h1.126v5.855h-1.126V72.523z" />
            <path fill="#8CA0BF" d="M67.229,72.523h1.126v5.855h-1.126V72.523z" />
            <path fill="#8CA0BF" d="M74.212,72.523h1.126v5.855h-1.126V72.523z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4739583333333333, 0.0, 0.45, 0.5208333333333334, 121.0, 0.55, 0.671875, 83.0, 0.73, 0.9479166666666666, 0.0, 0.77, 0.875, 0.0, 1.0, 0.4739583333333333, 0.0]);

        super.drawObject();
    }
}
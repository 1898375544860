import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { ObjectView } from "./object";
import tagsModele from "@/store/tags.modele";
import { Parameter } from './properties/range/parameter';
import { DialogBox } from "../dialogbox";
import dialogboxModule from "@/store/dialogbox.module";
import { Value } from '../value';

export class ParameterList extends ObjectView{
    color:string;
    type3d:number;
    fillcolor:string;
    linewidth:number;
    simultaneously:boolean;
    ranges:Parameter[];

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
    if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

    this.ranges.forEach(range => {
        this.tagnames.push(range.path)
    });
   

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)


		let fillcolor = '0xffffff00';
        
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
            gradient.addColorStop(0, color2)
            gradient.addColorStop(1, ColorUtils.darkColor(color2,0.75))

      const rect = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          strokeWidth: this.linewidth==0? this.height/50 : this.linewidth,
          stroke: this.type3d==0? gradient : color2
        });
        if(this.type3d==0){
          rect.fillLinearGradientStartPoint({ x: 0, y: 0});
          rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
          rect.fillLinearGradientColorStops([0, fillcolor2,
              0.5, ColorUtils.darkColor(fillcolor2, 0.95),
              1, ColorUtils.darkColor(fillcolor2, 0.85)])
        }else{
          rect.fill(fillcolor2)
        }
        this.node.add(rect)

        const line = new Konva.Line({
          points: [this.width*7/10, 0, this.width*7/10,this.height],
          stroke: this.type3d==0? gradient : color2,
      });
          if(this.linewidth != 0) line.strokeWidth(this.linewidth)
          if(this.type3d==0){
              line.fillLinearGradientStartPoint({ x: 0, y: 0});
              line.fillLinearGradientEndPoint({ x: 0, y: this.height});
              line.fillLinearGradientColorStops([0, fillcolor2,
              0.5, ColorUtils.darkColor(fillcolor2, 0.95),
              1, ColorUtils.darkColor(fillcolor2, 0.85)])
          }else{
              line.fill(fillcolor)
          }
      this.node.add(line)


      if(this.ranges.length != 0){
          this.ranges.forEach((textview)=>{
              const node1 = new Konva.Group()
              const tag =tagsModele.getTag(textview.path)
              const rowheight = this.height/this.ranges.length
              const line1 = new Konva.Line({
                  points: [0, rowheight*(this.ranges.indexOf(textview)+1), this.width, rowheight*(this.ranges.indexOf(textview)+1)],
                  stroke: color2, //this.type3d==0? gradient : color2,
                  strokeWidth: this.linewidth==0? this.height/50 : this.linewidth
              })
              node1.add(line1)

              const textMenu = new Konva.Text({
                  x: this.linewidth==0? this.height/50 +this.width/100 : this.linewidth +this.width/100,
                  y: rowheight*this.ranges.indexOf(textview), 
                  height: rowheight,
                  width: this.width*7/10,
                  fontSize: rowheight*2/3,
                  text: textview.name,
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  align: 'left',
                  verticalAlign:"middle",
                  fill: color2
                  })
                  if(this.type3d==0){       
                      textMenu.shadowColor('black'),
                      textMenu.shadowBlur(0),
                      textMenu.shadowOffset({ x: 0.5, y: 0.5 })
                    }
                  node1.add(textMenu)
                    const value = tag==null?'0':((+tag.tagvalue).toFixed(textview.decimalpos)).toString()
                  const textMenuValue = new Konva.Text({
                    x: this.width*7/10,
                    y: rowheight*this.ranges.indexOf(textview), 
                    height: rowheight,
                    width: this.width*3/10,
                    fontSize: rowheight*2/3,
                    text: value,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    align: 'center',
                    verticalAlign:"middle",
                    fill: color2
                    })
                    if(this.type3d==0){    
                         
                        textMenuValue.shadowColor('black'),
                        textMenuValue.shadowBlur(0),
                        textMenuValue.shadowOffset({ x: 0.5, y: 0.5 })
                      }
                    node1.add(textMenuValue)   

              node1.on('mousedown touchstart', function () {
                const dialogbox:DialogBox = new DialogBox();
                dialogbox.enable = true;
                dialogbox.title = tag.name
                dialogbox.tagname = tag.path;
                dialogboxModule.setDialogBox(dialogbox);
          });
              this.node.add(node1)
              
    })
}
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color":
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}

          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

          /*case "simultaneously": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}*/
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color":
          case "fillcolor": {value.datatype=7; value.value = this[field];break}

          case "type3d": {value.datatype=1; value.value = this[field];break}
          case "linewidth": {value.datatype=2; value.value = this[field];break}

          //case "simultaneously": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }
}
import { SVGObject } from "../svgobject";

export class SVGButton2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Black_Button_Pressed_front">
            <radialGradient id="SVGID_1_" cx="56.25" cy="56.25" r="56.25" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.82" style="stop-color:#DEDEDE" />
                <stop offset="1" style="stop-color:#575757" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" cx="56.25" cy="56.25" r="56.25" />
        </g>
        <g id="Group_Black_Button_Pressed_Base">
            
                <ellipse transform="matrix(-1 -0.0031 0.0031 -1 113.1301 113.9634)" fill="#4C4C4C" stroke="#4C4C4C" cx="56.654" cy="56.893" rx="42.54" ry="42.656" />
            <circle fill="none" stroke="#C9C9C9" cx="56.25" cy="57.25" r="32.896" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Black_Button_Pressed_Base",[0.0, 0.5885416666666666, 0.0]);
		
        super.drawObject();
    }
}
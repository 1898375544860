import { SVGObject } from "../svgobject";

export class SVGContainer3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Group_x5F_Box" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 61.826" enable-background="new 0 0 112.5 61.826" xml:space="preserve">
        <g id="Group_Box">
        <path fill="#C2A385" d="M22.41,5.519h56.307l6.36,28.153l-6.36,28.153H22.41V5.519z" />
        <path fill="#996633" d="M22.41,5.519l11.261-5.518h56.306L78.717,5.519H22.41z" />
        <path fill="#AD845B" d="M78.717,61.825V5.519l11.261-5.518v56.193L78.717,61.825z" />
        <path fill="#D6C2AD" d="M13.964,22.411L22.41,5.519h56.307l-8.446,16.892H13.964z" />
        <path fill="#D6C2AD" d="M0,13.965l11.148-5.63l22.523-8.333L22.41,5.519L0,13.965z" />
        <path fill="#D6C2AD" d="M78.717,5.519l11.261-5.518L112.5,8.334l-11.262,5.63L78.717,5.519z" />
        <path fill="#AD845B" d="M22.41,5.519l11.261-5.518v5.518H22.41z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGEnergy1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 131.25 87.898" enable-background="new 0 0 131.25 87.898" xml:space="preserve">
        <g id="Group_InnerFrame">
            <path fill="#A6A68C" d="M32.614,72.586l89.701,7.295l0.263-43.224L16.422,24.308l0.263,12.087L32.614,72.586z" />
            <path fill="#E5E5CB" stroke="#4C4C4C" stroke-width="0.25" d="M80.405,85.793l6.7,0.263l10.051-1.169l14.912,1.038l6.271-0.616   l3.057-0.567l5.254-2.496l2.76-10.115L19.838,61.488L4.335,68.845L80.405,85.793z" />
            <path fill="#A6A68C" stroke="#7F7F7F" stroke-width="0.25" d="M26.67,58.072L16.422,28.774l0.92-11.167l-2.628-0.263L2.496,24.702   l-0.788,1.971l0.263,8.54l2.496,33.633L26.67,58.072z" />
            <path fill="#E5E5CB" d="M8.146,37.207c-0.948,0.449-1.656-0.41-1.839-0.944c-0.733-2.13,1.484-8.967,1.484-8.967   s1.062,1.739,1.421,3.876C9.515,32.98,9.308,36.657,8.146,37.207z" />
            <ellipse fill="#8C8C72" cx="6.963" cy="31.665" rx="1.708" ry="4.992" />
            <path fill="#E5E5CB" d="M8.328,48.22c-0.375,0-0.313-0.396-0.313-0.396l0.367-2.233c0,0,0.252,0.273,0.351,0.707   C8.828,46.714,8.852,48.22,8.328,48.22z" />
            <ellipse fill="#8C8C72" cx="8.146" cy="46.642" rx="0.394" ry="1.314" />
        </g>
        <g id="Group_Board">
            <path fill="#00004C" d="M47.822,27.723h20.496l-2.497,31.663H45.326L47.822,27.723z" />
        </g>
        <g id="Group_Gear">
            <path fill="#B2CCCC" stroke="#7F7F7F" stroke-width="0.25" d="M116.009,74.363l1.971,0.264l-0.132,1.313h-2.103l-0.525,1.183   l1.708,0.525l-0.788,1.182l-2.102-0.262l-1.052,1.051l1.445,0.788l-1.577,0.92l-1.707-0.526l-1.576,0.789l0.787,1.051l-1.971,0.657   l-1.445-0.788l-1.839,0.525l0.132,1.051l-2.234,0.395l-0.919-1.051l-2.103,0.262l-0.395,1.051h-2.232l-0.395-1.051l-2.102-0.131   l-0.92,0.92l-2.365-0.395l0.264-1.051l-1.84-0.525l-1.576,0.788l-1.84-0.657l0.788-1.051l-1.577-0.657l-1.838,0.395l-1.445-0.788   l1.445-0.92l-1.184-0.92l-1.971,0.131l-0.92-1.182l1.709-0.525L116.009,74.363z" />
            <path fill="#7F9999" stroke="#7F7F7F" stroke-width="0.25" d="M116.009,73.576l1.971,0.262l-0.132,1.314h-2.103l-0.525,1.051   l1.708,0.656l-0.788,1.184l-2.102-0.264l-1.052,1.051l1.445,0.789l-1.577,0.92l-1.707-0.525l-1.576,0.787l0.787,1.052l-1.971,0.657   l-1.445-0.789l-1.839,0.525l0.132,1.052l-2.234,0.394l-0.919-1.051l-2.103,0.263l-0.395,1.052h-2.232l-0.395-1.052l-2.102-0.132   l-0.92,0.92l-2.365-0.394l0.264-1.052l-1.84-0.525l-1.576,0.789l-1.84-0.657l0.788-1.052l-1.577-0.656l-1.838,0.395l-1.445-0.92   l1.445-0.789l-1.184-0.919l-1.971,0.132l-0.92-1.184l1.709-0.525L116.009,73.576z" />
            <path fill="#999999" stroke="#7F7F7F" stroke-width="0.25" d="M121.263,67.925l1.971,0.395l-0.262,1.183l-2.103,0.132l-0.394,1.051   l1.707,0.656l-0.92,1.183l-1.971-0.263l-1.051,0.92l1.314,0.788l-1.445,1.051l-1.708-0.525l-1.577,0.788l0.658,0.92l-1.84,0.788   l-1.445-0.788l-1.84,0.395l0.132,1.051l-2.233,0.395l-0.92-0.92l-2.102,0.263l-0.395,1.051h-2.365l-0.262-1.051l-2.103-0.263   l-1.052,1.051l-2.232-0.395l0.262-1.182l-1.839-0.395l-1.577,0.788l-1.838-0.788l0.787-0.92l-1.576-0.788l-1.84,0.525l-1.445-0.919   l1.314-0.789l-1.051-1.051l-1.971,0.263l-0.92-1.183l1.708-0.525L121.263,67.925z" />
        </g>
        <g id="Group_Base">
            <path fill="#666666" d="M17.999,70.028l0.394-8.54l2.365-2.627h2.627l2.759-1.971l22.729,1.313l1.708,1.708l4.598,0.395   l-2.496,3.678l5.124,1.445l-0.788,11.43L17.999,70.028z" />
            <path fill="#999999" stroke="#666666" stroke-width="2" d="M62.011,73.707l4.467-2.102l3.153,0.131l-2.364,3.547L62.011,73.707z" />
            <path fill="#666666" d="M72.785,75.152l-0.263-3.021l-2.891-0.395l-2.364,3.547L72.785,75.152z" />
            <path fill="#4C4C4C" d="M105.761,80.669l9.984-5.387l-0.525-6.438l-53.603-2.102v4.072l14.583,2.233l22.729,3.548l0.395,3.678   L105.761,80.669z" />
            <path fill="#7F7F7F" d="M80.537,67.664l8.408,12.086l9.59,1.183l15.24-11.43l-14.846,0.788l1.84,2.759l0.131,0.657l-0.131,0.788   l-6.044,4.335l-1.183,0.264h-0.788l-1.052-0.525l-0.656-0.789l-3.941-6.568v-0.789l0.263-1.051l0.526-0.788l1.313-0.657   l2.629-0.394L80.537,67.664z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="2" d="M73.705,77.648l-15.24-1.84l0.657-9.984" />
            <path fill="#B2B2B2" d="M88.55,76.991l17.605-7.095l1.182-0.131h4.205l2.496,0.263l0.131-2.628l-11.036-0.788l-11.521-0.606   l-0.207,2.574l3.845,0.266l0.657,0.395l0.131,0.525l-0.262,0.525l-10.512,4.336h-0.656l-0.525-0.395l-2.13-5.286l1.079-0.626   l8.373,0.26l0.2-2.574l-10.938-0.576h-0.131l-14.394-0.764l0.075,4.18h4.333c0,0,1.829,0.612,1.708,3.021   c-0.119,2.363-2.628,2.628-2.628,2.628l-4.992-0.525c0,0-0.815,0-1.155-0.349C63.08,73.206,63,72.605,63,72.605l0.063-2.861   c0,0,0.328-0.451,0.657-0.636c0.393-0.221,0.789-0.263,0.789-0.263h1.71l-0.078-4.18l-2.946-0.156h-3.285l-2.102,0.657   l-0.919,11.431l40.86,5.387h1.838l5.912,0.262c0,0,1.286-0.172,1.709-0.262c0.366-0.078,1.102-0.246,1.445-0.395   c1.014-0.438,3.81-2.233,3.81-2.233s0.247-0.38,0.263-0.526c0.02-0.176-0.134-0.535-0.263-0.656   c-0.151-0.143-0.788-0.263-0.788-0.263h-4.73c0,0-1.42-0.06-1.839,0.132c-0.127,0.058-0.394,0.394-0.394,0.394v0.92l0.525,0.525   v0.394l-0.395,0.264l-5.781,0.131l-2.102-0.131l-8.803-1.183l-0.525-0.263l-0.131-0.92l0.262-0.788L88.55,76.991z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.888,76.597l40.86,5.387h1.838l5.912,0.262   c0,0,2.402-0.366,3.154-0.656c1.03-0.396,3.81-2.233,3.81-2.233l0.263-0.526c0,0-0.134-0.535-0.263-0.656   c-0.151-0.143-0.788-0.263-0.788-0.263h-4.73c0,0-1.42-0.06-1.839,0.132c-0.127,0.058-0.394,0.394-0.394,0.394v0.92l0.525,0.525   v0.394l-0.395,0.264l-5.781,0.131l-2.102-0.131l-8.803-1.183l-0.525-0.263l-0.131-0.92l0.262-0.788l0.789-0.395l17.605-7.095   l1.182-0.131h4.205l2.496,0.263l0.131-2.628l-11.036-0.788l-22.597-1.183l-20.627-0.92l-2.102,0.657L56.888,76.597" />
            <path fill="none" stroke="#7F7F7F" stroke-width="2" d="M58.727,65.693L58.07,75.94" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M73.705,77.648l-15.109-1.84l0.919-9.984" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M73.705,78.173l-16.029-1.84" />
            <path fill="#7F7F7F" d="M76.463,70.028h0.525v-2.496h-0.525V70.028z" />
            <path fill="#7F7F7F" d="M60.041,69.896h0.395V67.4h-0.395V69.896z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M73.966,78.173l0.92-11.693" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M74.23,78.304l0.92-11.692" />
            <path fill="#B2B2B2" d="M55.705,74.495l-10.904-1.839l0.131-8.277h-0.525l-0.132,9.328l11.299,2.233L55.705,74.495z" />
            <path fill="#B2B2B2" d="M41.253,72.261l-17.999-2.891v-6.832h-0.525v7.489l18.524,3.021V72.261z" />
            <path fill="#B2B2B2" d="M19.444,69.634l0.131-7.227H19.05l-0.131,7.227H19.444z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.836,75.94l0.526-10.247" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.407,73.707v-9.197" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M41.516,73.312l0.263-9.196" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.466,70.16l0.263-7.621" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.788,69.24l0.263-7.488" />
            <path fill="#FFFFF2" d="M27.721,67.137v-2.233l12.612,1.445l-0.131,2.102L27.721,67.137z" />
            <path fill="#B2B2B2" d="M18.919,61.751l38.494,3.415l1.708-0.657l-38.626-3.547L18.919,61.751z" />
            <path fill="#E5E5E5" d="M62.8,68.845h0.525V66.48H62.8V68.845z" />
        </g>
        <g id="Group_Motor2">
            <path fill="#7F7F7F" d="M60.566,58.992l3.153-10.248l20.759,0.789l-3.941,10.51L60.566,58.992z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M60.566,58.992v6.175l19.838,0.657v-5.781L60.566,58.992z" />
            <path fill="#999999" d="M80.798,63.458l-0.787-1.313l-0.133-1.313l0.264-1.051l4.072-11.299l0.789-0.395l4.598,0.131l0.657,0.525   l1.051,3.154l-5.255,11.43L80.798,63.458z" />
            <path fill="#666666" d="M73.832,59.425l1.97,0.083l-0.253,6.038l-1.97-0.083L73.832,59.425z" />
            <path fill="#666666" d="M67.673,59.215l1.837,0.088l-0.286,6.035l-1.837-0.088L67.673,59.215z" />
            <path fill="#666666" d="M60.566,58.941h1.971v6.176h-1.971V58.941z" />
            <path fill="#7F7F7F" d="M62.406,61.488h3.021v2.496h-3.021V61.488z" />
            <path fill="#7F7F7F" d="M71.865,62.277h1.84v2.627h-1.84V62.277z" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M69.106,57.678l1.708-7.883" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M73.966,57.678l1.577-7.62" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M75.15,57.546l1.707-7.226" />
            <path fill="#666666" stroke="#666666" stroke-width="0.25" d="M94.593,66.218l-8.408-0.525c0,0-1.707-2.682-1.707-7.432   c0-4.719,2.232-7.678,2.232-7.678l8.008,0.197c0,0,2.25,1.449,2.25,7.816C96.968,65.203,94.593,66.218,94.593,66.218z" />
            <ellipse fill="#7F7F7F" stroke="#666666" stroke-width="0.25" cx="94.2" cy="58.598" rx="2.496" ry="7.489" />
            <path fill="#00CCCC" d="M71.996,53.21l7.357,0.526v0.394l-7.357-0.525V53.21z" />
            <path fill="#00CCCC" d="M74.625,47.825l4.729,5.649l-0.262,0.263l-4.73-5.649L74.625,47.825z" />
        </g>
        <g id="Group_Support">
            <path fill="#CC6633" d="M50.187,58.861l-0.919-1.445h-0.657l-1.051,1.445H50.187z" />
            <path fill="#CC6633" d="M44.932,61.093l-0.92-1.313h-0.657l-1.051,1.313H44.932z" />
            <path fill="#CC6633" d="M23.123,59.123l-0.92-1.444h-0.657l-1.051,1.444H23.123z" />
            <path fill="#CC6633" d="M82.244,59.123l4.861,0.132v6.175l-4.861-0.131V59.123z" />
        </g>
        <g id="Group_Motor1">
            <path fill="#B2B2B2" d="M13.401,50.978l0.131,3.678h2.102l0.394-3.547L13.401,50.978z" />
            <path fill="#7F7F7F" d="M21.021,53.737l-4.73-0.263l-0.131-1.84l-3.941-0.131l0.394-7.62l4.467,0.525V37.84l4.204,0.788   L21.021,53.737z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M16.948,44.409l3.678,0.263" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M16.685,51.898l3.547,0.131" />
            <path fill="#999999" stroke="#7F7F7F" stroke-width="0.25" d="M19.97,57.152l0.657-29.297L46.64,30.22l-1.314,29.692L19.97,57.152z   " />
            <path fill="#B2B2B2" stroke="#7F7F7F" stroke-width="0.25" d="M50.582,57.152l0.788-29.297L46.64,30.22l-1.314,29.692   L50.582,57.152z" />
            <path fill="#B2B2B2" stroke="#7F7F7F" stroke-width="0.25" d="M26.407,26.016l24.962,1.839L46.64,30.22l-25.882-2.365   L26.407,26.016z" />
            <path fill="#666666" d="M19.707,44.015l26.802,2.102v-0.657l-26.802-2.102V44.015z" />
            <path fill="#7F7F7F" d="M19.707,41.649l26.802,1.971v1.839l-26.802-2.102V41.649z" />
            <ellipse fill="none" stroke="#666666" stroke-width="0.25" cx="48.611" cy="51.109" rx="1.839" ry="5.649" />
            <ellipse fill="none" stroke="#666666" stroke-width="0.25" cx="48.742" cy="51.109" rx="1.313" ry="4.072" />
            <ellipse fill="none" stroke="#666666" stroke-width="0.25" cx="49.005" cy="51.109" rx="0.919" ry="2.759" />
        </g>
        <g id="Group_Pipes2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="47.7749" y1="41.5596" x2="48.572" y2="31.3161">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M48.348,42.044c0,0-0.722-2.035-0.722-5.275c0-3.944,0.722-5.761,0.722-5.761" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="52.9946" y1="42.0737" x2="53.7999" y2="31.7261">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M58.727,42.963l-10.379-0.919c0,0-0.722-3.935-0.722-5.275c0-1.419,0.722-5.761,0.722-5.761   l10.379,1.051V42.963z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="15.1982" y1="38.5093" x2="16.4147" y2="26.7218">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M20.364,38.628l-8.934-0.657c0,0-0.627-1.644-0.627-5.387c0-3.941,0.627-5.649,0.627-5.649   l9.196,0.919L20.364,38.628z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M48.348,31.139l10.379,0.92" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M58.727,42.963l-10.379-0.919" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M11.43,26.935l9.065,0.919" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M20.495,38.759l-9.065-0.788" />
            <ellipse fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="58.727" cy="37.446" rx="2.759" ry="5.518" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="107.1255" y1="63.1108" x2="107.7291" y2="57.1917">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M120.607,64.641l-26.145-2.628c0,0-0.604-1.486-0.51-3.314c0.079-1.545,0.51-2.991,0.51-2.991   l26.145,2.628V64.641z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M94.462,55.708l26.145,2.628" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M94.413,62.013l26.145,2.628" />
        </g>
        <g id="Group_Rotater">
            <path fill="#339966" d="M58.596,58.335l-1.183-3.416h-6.175c0,0-1.03-2.199-0.884-4.699c0.151-2.579,1.672-4.104,1.672-4.104   l6.438,0.131l1.599-2.519l3.13,0.023l-2.102,3.153l-0.788,3.941v3.81l1.708,3.68H58.596z" />
            <path fill="#4CB27F" d="M62.011,58.335h-1.313c0,0-1.627-1.445-1.487-7.555c0.098-4.266,2.669-7.029,2.669-7.029h1.314   c0,0,1.6,0.797,1.321,7.461C64.305,56.214,62.011,58.335,62.011,58.335z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="108.5864" y1="59.9771" x2="119.5562" y2="59.9771">
                <stop offset="0" style="stop-color:#57A68A" />
                <stop offset="0.5" style="stop-color:#57A68A" />
                <stop offset="1" style="stop-color:#57A68A" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C7F7F" stroke-width="0.25" d="M118.374,67.925l-8.276-0.92c0,0-1.772-2.151-1.478-6.828   c0.355-5.641,2.528-8.148,2.528-8.148l8.408,0.92L118.374,67.925z" />
        </g>
        <g id="Group_Pipes1">
            <path fill="#4C4C4C" d="M91.572,45.854h12.613v1.576H91.572V45.854z" />
            <path fill="#4C4C4C" d="M86.974,49.007h13.795v-1.709H86.974V49.007z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M85.265,46.773l8.015-3.678l12.876,0.394l-8.015,3.941L85.265,46.773   z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M85.265,46.773v1.445l12.876,0.395V47.43L85.265,46.773z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M105.498,43.752v1.445l-7.356,3.416V47.43L105.498,43.752z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="92.0972" y1="42.7046" x2="104.9722" y2="42.7046">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="0.91" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M104.972,40.336v3.416c0,0-1.824,1.322-6.118,1.322c-3,0-6.757-1.322-6.757-1.322v-3.416H104.972z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M92.229,43.883v-3.547" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.841,40.336v3.547" />
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="98.535" cy="40.205" rx="6.307" ry="1.577" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M100.769,42.044v3.547" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="96.9585" y1="38.6279" x2="99.98" y2="38.6279">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.48" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.98,36.92v3.416h-3.021V36.92H99.98z" />
            <ellipse fill="#7F7F7F" stroke="#666666" stroke-width="0.25" cx="98.469" cy="36.789" rx="1.511" ry="0.453" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="87.894" y1="44.4087" x2="100.6372" y2="44.4087">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="0.91" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M100.637,42.044v3.416c0,0-3.086,1.313-6.438,1.313c-2.891,0-6.306-1.313-6.306-1.313v-3.416   H100.637z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M87.894,45.591v-3.547" />
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="94.332" cy="41.912" rx="6.438" ry="1.576" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="92.7544" y1="40.6646" x2="95.7769" y2="40.6646">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.48" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.777,39.022v3.285h-3.022v-3.285H95.777z" />
            <ellipse fill="#7F7F7F" stroke="#666666" stroke-width="0.25" cx="94.265" cy="38.891" rx="1.512" ry="0.288" />
        </g>
        <g id="Group_Wheel">
            <path fill="#E5E5E5" stroke="#999999" stroke-width="0.25" d="M124.811,83.955l-2.233,1.576h-1.971l-1.971-1.576l-1.708-3.154   l-1.313-4.335l-0.657-5.387l-0.263-5.912l0.395-6.175l0.92-5.912l1.313-5.387l1.709-4.335l2.102-3.021l2.102-1.708h2.104   l1.971,1.708l1.576,3.021l1.313,4.467l0.788,5.255l0.264,5.912l-0.395,6.175l-0.92,5.912l-1.314,5.387l-1.839,4.335L124.811,83.955   z" />
            <path fill="#B2B2B2" stroke="#999999" stroke-width="0.25" d="M124.679,79.093l-1.576,1.182h-1.707l-1.577-1.182l-1.183-2.496   l-1.051-3.285l-0.525-4.204l-0.132-4.599l0.263-4.729l0.657-4.73l1.051-4.204l1.445-3.284l1.445-2.365l1.708-1.313h1.707   l1.445,1.313l1.314,2.365l0.92,3.284l0.525,4.335l0.263,4.6l-0.263,4.729l-0.789,4.599l-0.919,4.204l-1.445,3.285L124.679,79.093z" />
            <path fill="#4C4C4C" d="M124.679,71.341l-0.919,0.789h-1.313l-0.92-0.789l-0.657-1.182l-0.525-1.84l-0.263-2.365l-0.131-2.496   l0.131-2.627l0.395-2.628l0.525-2.233L121.79,54l0.788-1.183l0.92-0.788h1.445l0.788,0.788L126.519,54l0.525,1.971l0.264,2.233   l0.131,2.628l-0.131,2.627l-0.395,2.496l-0.525,2.365l-0.92,1.84L124.679,71.341z" />
            <path fill="#7F7F7F" d="M125.205,71.341l-0.919,0.789h-0.919l-0.789-0.789l-0.788-1.182l-0.526-1.84l-0.394-2.365v-2.496   l0.132-2.627l0.395-2.628l0.656-2.233L122.708,54l0.92-1.183l0.92-0.788h0.92l0.788,0.788L126.914,54l0.525,1.971l0.395,2.233   l0.131,2.628l-0.263,2.627l-0.263,2.496l-0.657,2.365l-0.788,1.84L125.205,71.341z" />
            <ellipse fill="#4C4C4C" cx="124.286" cy="62.145" rx="2.233" ry="6.7" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M131.25,62.298c0,12.938-4.885,23.377-9.955,23.377   c-6.504,0-8.898-12.585-8.398-23.56c0.589-12.924,4.85-22.533,9.862-23.292C130.729,37.615,131.25,49.361,131.25,62.298z" />
            <path fill="#E5E5E5" stroke="#999999" stroke-width="0.25" d="M131.25,62.542c0,12.502-4.513,22.433-8.753,22.433   c-5.438,0-7.44-12.161-7.022-22.766c0.492-12.488,4.13-22.227,8.296-23.094C130.478,37.72,131.25,50.042,131.25,62.542z" />
            <path fill="#B2B2B2" stroke="#999999" stroke-width="0.25" d="M130.144,60.449c0,8.326-1.435,15.168-5.711,15.563   c-4.383,0.404-6.934-4.63-6.934-12.956s2.475-14.406,6.322-14.669C128.192,48.087,130.144,52.123,130.144,60.449z" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M124.352,50.305l0.699,1.098l0.699-0.898" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M128.653,60.177l0.482,0.723l0.604-0.723" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M118.833,55.837l0.722,1.205l0.965-1.084" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M118.111,67.104l0.723,1.205l0.843-1.084" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M126.937,71.141l0.603,0.844l0.482-0.844" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M121.907,74.153l0.723,1.325l0.843-1.204" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M118.111,67.104l0.964-1.566l0.602,1.446" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M121.907,74.153l0.964-1.566l0.602,1.445" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M126.937,71.141l0.603-1.205l0.482,1.085" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M128.653,60.177l0.604-1.085l0.482,0.965" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M124.352,50.305l0.799-1.298l0.6,1.298" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.25" d="M118.833,55.958l0.964-1.566l0.723,1.566" />
            <ellipse fill="#4C4C4C" cx="123.695" cy="62.145" rx="4.105" ry="10.313" />
            
                <radialGradient id="SVGID_10_" cx="124.4175" cy="62.0132" r="4.0448" gradientTransform="matrix(1 0 0 2.55 0 -96.1216)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.55" style="stop-color:#4C4C4C" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </radialGradient>
            <ellipse fill="url(#SVGID_10_)" cx="124.417" cy="62.013" rx="4.105" ry="10.313" />
        </g>
        <g id="Group_Frame">
            <path fill="#8C8C72" stroke="#666666" stroke-width="0.25" d="M2.233,68.189L0,27.854v-3.021l1.314-0.92l13.4-6.569L2.496,24.702   l-0.788,1.971l0.263,8.54l2.496,33.633L2.233,68.189z" />
            <path fill="#999999" stroke="#7F7F7F" stroke-width="0.25" d="M5.649,72.524l77.12,15.108l7.096,0.132l7.883-0.92l21.283,1.052   c0,0,2.767-0.555,3.547-1.052c1.295-0.824,4.072-4.599,4.072-4.599l-2.627,0.789l-2.627,1.707l-3.548,0.658l-5.78,0.525   l-15.109-1.052l-9.854,1.183l-6.7-0.263L4.335,68.845L5.649,72.524z" />
            <path fill="#A6A68C" stroke="#4C4C4C" stroke-width="0.25" d="M14.846,19.052l7.226-5.387l72.391,9.854l-4.336,5.124L14.846,19.052   z" />
            <path fill="#E5E5CB" stroke="#7F7F7F" stroke-width="0.25" d="M4.204,23.913l6.701-5.255l78.96,9.722l4.072-4.861   c0,0,4.951,0.663,6.568,1.051c2.182,0.523,6.452,1.939,8.54,2.759c2.462,0.967,7.257,3.223,9.591,4.467   c1.794,0.956,6.963,4.205,6.963,4.205l0.264,3.284l-7.752,1.314c0,0-7.867-2.036-10.51-2.627   c-3.464-0.776-10.423-2.191-13.928-2.759C71.418,31.606,4.204,23.913,4.204,23.913z" />
            <path fill="#999999" d="M23.254,20.104l1.839-4.73l0.525-10.642l5.649-2.627l-0.92,12.875l1.183,5.912l-1.051,0.131l-0.657-4.598   l-1.445,4.335l-1.313-0.131l2.365-5.649L30.48,3.024l-4.335,2.102l0.131,0.92l1.577-0.657v1.314l1.839-0.789v0.657l-3.285,1.313   l-0.525,7.489l0.789,5.124L23.254,20.104z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.247,6.44l0.526-6.438" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.984375, 3.0, 0.5, 0.984375, 3.0, 1.0, 0.984375, 3.0]);
		this.fillGradient(this.fills,"Group_InnerFrame",[0.0, 0.7760416666666666, 57.0, 1.0, 0.28125, 183.0, 2.0, 0.7760416666666666, 57.0, 3.0, 0.28125, 183.0, 4.0, 0.9791666666666666, 5.0, 5.0, 0.28125, 183.0, 6.0, 0.9791666666666666, 5.0]);
		this.fillGradient(this.fills,"Group_Board",[0.0, 0.19270833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Gear",[0.0, 0.4739583333333333, 133.0, 1.0, 0.875, 31.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.7916666666666666, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.609375, 99.0, 6.0, 0.9895833333333334, 0.0, 7.0, 0.9895833333333334, 0.0, 8.0, 0.609375, 99.0, 9.0, 0.609375, 99.0, 10.0, 0.609375, 99.0, 11.0, 0.046875, 243.0, 12.0, 0.609375, 99.0, 13.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Motor2",[0.0, 0.9895833333333334, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.7916666666666666, 0.0, 4.0, 0.7916666666666666, 0.0, 5.0, 0.7916666666666666, 0.0, 6.0, 0.9895833333333334, 0.0, 7.0, 0.9895833333333334, 0.0, 8.0, 0.7916666666666666, 0.0, 9.0, 0.9895833333333334, 0.0, 10.0, 0.9375, 15.0, 11.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.9270833333333334, 0.0, 1.0, 0.9270833333333334, 0.0, 2.0, 0.9270833333333334, 0.0, 3.0, 0.9270833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Motor1",[0.0, 0.609375, 99.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.609375, 99.0, 4.0, 0.609375, 99.0, 5.0, 0.7916666666666666, 0.0, 6.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Rotater",[0.0, 0.7916666666666666, 0.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Wheel",[0.0, 0.20833333333333334, 201.0, 1.0, 0.609375, 99.0, 2.0, 0.5885416666666666, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.40625, 151.0, 6.0, 0.20833333333333334, 201.0, 7.0, 0.609375, 99.0, 8.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.9791666666666666, 5.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.7760416666666666, 57.0, 3.0, 0.28125, 183.0, 4.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
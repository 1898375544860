import { SVGObject } from "../svgobject";

export class SVGMotor16 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 105.52" enable-background="new 0 0 112.5 105.52" xml:space="preserve">
        <g id="Group_MotorBody">
            <radialGradient id="SVGID_1_" cx="57.0391" cy="52.2222" r="53.8659" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.55" style="stop-color:#ECECEC" />
                <stop offset="0.61" style="stop-color:#D9D9D9" />
                <stop offset="0.74" style="stop-color:#A8A8A8" />
                <stop offset="0.93" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M112.5,48.535C112.5,21.73,90.771,0,63.965,0c-11.063,0-19.804,4.267-31.08,10.266   C14.652,18.42,1.579,34.552,1.579,55.909c0,26.806,21.73,48.535,48.535,48.535c11.063,0,22.039-5.023,29.85-9.236   C99.719,87.088,112.5,69.893,112.5,48.535z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M84.234,89.863l14.368-7.115" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M47.973,7.657L63.76,0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.076,10.022l15.078-7.479" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.774,14.977l15.022-7.555" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M82.883,21.396l15.022-7.512" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M89.076,28.603l15.101-7.434" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M93.693,36.711l14.918-7.371" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M96.621,45.607l14.841-7.398" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M97.747,55.404l14.753-7.25" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M96.846,66.215l14.553-7.289" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M92.116,78.939l14.911-7.471" />
        </g>
        <g id="Group_ShaftFacePlate">
            <circle fill="#CCCCCC" cx="49.213" cy="56.247" r="48.535" />
            <circle fill="#4C4C4C" cx="49.212" cy="56.192" r="13.852" />
            <circle fill="#B2B2B2" cx="49.212" cy="56.247" r="12.836" />
            <path fill="#4C4C4C" d="M68.469,89.188L61.6,77.251c0,0,8.9-4.955,11.126-14.415c2.263-9.618-2.343-18.806-2.343-18.806   l12.049-6.869c0,0,6.645,10.347,4.617,25.225c-0.125,0.914-0.583,2.705-0.788,3.604C82.75,81.383,68.469,89.188,68.469,89.188z" />
            <path fill="#4C4C4C" d="M68.446,23.104l-6.855,11.944c0,0-8.761-5.195-18.056-2.355c-9.449,2.887-15.068,11.493-15.068,11.493   l-12.001-6.952c0,0,5.594-10.95,19.469-16.689c0.853-0.353,2.631-0.858,3.51-1.134C54.513,14.694,68.446,23.104,68.446,23.104z" />
            <path fill="#4C4C4C" d="M11.26,56.306l13.771-0.059c0,0-0.1,10.185,7.018,16.802c7.236,6.727,17.5,7.273,17.5,7.273l0.004,13.869   c0,0-12.279,0.652-24.202-8.475c-0.734-0.561-2.063-1.846-2.742-2.468C10.971,72.578,11.26,56.306,11.26,56.306z" />
        </g>
        <g id="Group_Shaft">
            <radialGradient id="SVGID_2_" cx="45.833" cy="57.9941" r="13.0863" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.15" style="stop-color:#E8E8E8" />
                <stop offset="0.3" style="stop-color:#DBDBDB" />
                <stop offset="0.45" style="stop-color:#C7C7C7" />
                <stop offset="0.6" style="stop-color:#ABABAB" />
                <stop offset="0.75" style="stop-color:#868686" />
                <stop offset="0.9" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M59.684,56.417c0-5.908-4.789-10.697-10.697-10.697c-3.011,0-5.653,1.463-8.121,2.765   c-6.282,3.315-8.883,5.641-8.883,11.085c0,5.909,4.79,10.698,10.698,10.698c3.011,0,5.782-1.597,8.322-2.753   C56.875,64.842,59.684,61.862,59.684,56.417z" />
            <circle fill="#CCCCCC" cx="42.681" cy="59.571" r="10.698" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.55, 0.16145833333333334, 214.0, 0.61, 0.3020833333333333, 177.0, 0.74, 0.6875, 79.0, 0.93, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.15, 0.1875, 207.0, 0.3, 0.2864583333333333, 181.0, 0.45, 0.4427083333333333, 141.0, 0.6, 0.6666666666666666, 85.0, 0.75, 0.953125, 11.0, 0.9, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_ShaftFacePlate",[0.0, 0.40625, 151.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.609375, 99.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Shaft",[0.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
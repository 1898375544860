import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";


export class PumpStand extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            const roof = new Konva.Rect({
                x: 0,
                y: this.height*45/100,
                width: this.width/10,
                height: this.height*2/5,
                cornerRadius: this.height/15,
              })
            const roof2 = new Konva.Rect({
              x: this.width/30,
              y: this.height*40/100,
              width: this.width/10,
              height: this.height*50/100,
              cornerRadius: this.height/15,
            })
            if(this.type3d==0){
                roof.fillLinearGradientStartPoint({ x: 0, y: 0});
                roof.fillLinearGradientEndPoint({ x: 0, y: this.height*2/5});
                roof.fillLinearGradientColorStops([0, 'black',
                    0.3,'grey',
                    1, 'black'])
                roof2.fillLinearGradientStartPoint({ x: 0, y: 0});
                roof2.fillLinearGradientEndPoint({ x: 0, y: this.height*50/100});
                roof2.fillLinearGradientColorStops([0, 'black',
                    0.3,'grey',
                    1, 'black'])
              }else{
                roof.fill('black')
                roof2.fill('black')
              }
            this.node.add(roof, roof2)
            
        const pipe = new Konva.Rect({
            x: this.width*45/100,
            y: this.height*55/100,
            width: this.width*10/100,
            height: this.height*20/100,
            //cornerRadius: this.height/15,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1
          })
          if(this.type3d==0){
            pipe.fillLinearGradientStartPoint({ x: 0, y: 0});
            pipe.fillLinearGradientEndPoint({ x: 0, y: this.height*20/100});
            pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.3, fillcolor2,
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else{
            pipe.fill(fillcolor2)
          }
        this.node.add(pipe)

       /* const pumpEnd = new Konva.Rect({
            x: this.width*4/10,
            y: this.height*40/100,
            width: this.width/12,
            height: this.height*50/100,
            cornerRadius: this.height/4,
            fill: ColorUtils.darkColor(fillcolor2, 0.7)
          })
        this.node.add(pumpEnd)*/

        const pumpEnd = new Konva.Ellipse({
            x: this.width*4/10+this.width/24,
            y: this.height*40/100+this.height/4,
            radiusX: this.width/24,
            radiusY: this.height/4,
            //cornerRadius: this.height/4,
            fill: ColorUtils.darkColor(fillcolor2, 0.7)
          })
        this.node.add(pumpEnd)

        const pump = new Konva.Rect({
            x: this.width/10,
            y: this.height*40/100,
            width: this.width*35/100,
            height: this.height*50/100,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1,
          })
          if(this.type3d==0){
            pump.fillLinearGradientStartPoint({ x: 0, y: 0});
            pump.fillLinearGradientEndPoint({ x: 0, y: this.height*50/100});
            pump.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.3, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            pump.fill(fillcolor2)
          }
        this.node.add(pump)

        const str1 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*64/100,
            width: this.width*35/100-this.width/50,
            height: this.height*2/100,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str2 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*55/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str3 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*48/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str4 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*40/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str5 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*70/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: this.type3d==0? ColorUtils.darkColor(fillcolor2, 0.9) : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str6 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*78/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str7 = new Konva.Rect({
            x: this.width/10+this.width/50,
            y: this.height*86/100,
            width: this.width*35/100-this.width/50,
            height: this.height*4/100,
            fill: this.type3d==0? ColorUtils.darkColor(fillcolor2, 0.5) : ColorUtils.darkColor(fillcolor2, 0.75)
          })
        this.node.add(str1, str2, str2, str3, str4, str5, str6, str7)

        
        const cylinder = new Konva.Rect({
            x: this.width*55/100,
            y: this.height*25/100,
            width: this.width*40/100,
            height: this.height*65/100,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1
          })
        const cappipe = new Konva.Rect({
          x: this.width*65/100,
          y: this.height*15/100,
          width: this.width*20/100,
          height: this.height*10/100,
          stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth:1
        })
        if(this.type3d==0){
          cylinder.fillLinearGradientStartPoint({ x: 0, y: 0});
          cylinder.fillLinearGradientEndPoint({ x: this.width*40/100, y: 0});
          cylinder.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
              0.5,'lightgrey',
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
          cappipe.fillLinearGradientStartPoint({ x: 0, y: 0});
          cappipe.fillLinearGradientEndPoint({ x: this.width*20/100, y: 0});
          cappipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
              0.5,'lightgrey',
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
        }else{
          cylinder.fill(fillcolor2)
          cappipe.fill(fillcolor2)
        }
        this.node.add(cylinder, cappipe)

        const cap= new Konva.Rect({
            x: this.width*62.5/100,
            y: this.height*5/100,
            width: this.width*25/100,
            height: this.height*10/100,
          })
        const cap2 = new Konva.Rect({
          x: this.width*61/100,
          y: this.height*12/100,
          width: this.width*28/100,
          height: this.height*3/100,
        })
        if(this.type3d==0){
          cap.fillLinearGradientStartPoint({ x: 0, y: 0});
          cap.fillLinearGradientEndPoint({ x: this.width*25/100, y: 0});
          cap.fillLinearGradientColorStops([0, 'black',
              0.5,'grey',
              1, 'black'])
          cap2.fillLinearGradientStartPoint({ x: 0, y: 0});
          cap2.fillLinearGradientEndPoint({ x: this.width*28/100, y: 0});
          cap2.fillLinearGradientColorStops([0, 'black',
              0.5,'grey',
              1, 'black'])
        }else{
          cap.fill('black')
          cap2.fill('black')
        }
        this.node.add(cap, cap2)

        const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradient = ctx.createLinearGradient(0, -this.height*27/100, 0, this.height*27/100);
          gradient.addColorStop(0,'white')
          gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.1))

        const cir = new Konva.Ellipse({
              x: this.width*74/100,
              y: this.height*64/100,
              radiusX: this.width*22/100,
              radiusY: this.height*27/100,
              stroke: this.type3d==0? gradient : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth: this.height/100,
            });
            if(this.type3d==0){
              cir.fillLinearGradientStartPoint({ x: -this.width*22/100, y: -this.height*27/100});
              cir.fillLinearGradientEndPoint({ x: this.width*22/100, y: this.height*27/100});
              cir.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,'lightgrey',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              cir.fill(fillcolor2)
            }
        this.node.add(cir)

        const rad = this.height/40;

        const r1 = new Konva.Circle({
            x: this.width*56/100,
            y: this.height*64/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.75)
        })
        const r2 = new Konva.Circle({
            x: this.width*92/100,
            y: this.height*64/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.75)
        })
        const r3 = new Konva.Circle({
            x: this.width*74/100,
            y: this.height*42/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.75)
        })
        const r4 = new Konva.Circle({
            x: this.width*74/100,
            y: this.height*85/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.75)
        })
        this.node.add(r1, r2, r3, r4)

        const leg1 = new Konva.Line({
            points: [this.width*57/100, this.height*87/100, this.width*53/100,this.height*95/100],
            strokeWidth: this.width/50,
            stroke: fillcolor2,
        })
        const leg12 = new Konva.Line({
            points: [this.width*53/100,this.height*95/100, this.width*49/100,this.height*95/100],
            strokeWidth: this.width/50,
            stroke: fillcolor2
        })
        const leg2 = new Konva.Line({
            points: [this.width*92/100, this.height*87/100, this.width*96/100,this.height*95/100],
            strokeWidth: this.width/50,
            stroke: fillcolor2,
        })
        const leg22 = new Konva.Line({
            points: [this.width*96/100,this.height*95/100, this.width*100/100,this.height*95/100],
            strokeWidth: this.width/50,
            stroke: fillcolor2,
        })
        this.node.add(leg1, leg12, leg2, leg22)
            
}
}
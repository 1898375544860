import { SVGObject } from "../svgobject";

export class SVGMaterial18 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 77.588 112.5" enable-background="new 0 0 77.588 112.5" xml:space="preserve">
        <g id="Group_Tuner">
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,73.648l-1.802-0.113v1.802H0.45l0.113-4.616h0.563   l-0.112,1.688h1.802V73.648z" />
        </g>
        <g id="Group_Cross_Rod">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M77.024,0.453l-4.617,6.981v2.365l4.617-6.981V0.453z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M77.024,14.416l-4.617,4.955v2.252l4.617-4.842V14.416z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M77.024,74.773l-4.617-2.364v2.364l4.617,2.253V74.773z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M77.024,97.972l-4.617-5.181v2.252l4.617,5.181V97.972z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,77.702l4.617-5.293H5.18l-4.73,2.364v2.929H2.815z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,97.972l4.617-5.181v2.252l-4.617,5.181V97.972z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,74.773l4.617-2.364v2.364l-4.617,2.253V74.773z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,0.453l4.617,6.981v2.365L2.815,2.817V0.453z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.815,14.416l4.617,4.955v2.252l-4.617-4.842V14.416z" />
        </g>
        <g id="Group_Back_Rod">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M72.407,27.704v32.544h-2.026V27.704H72.407z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,27.704v32.544H7.432V27.704H9.459z" />
            <rect x="9.459" y="92.679" fill="#999999" width="60.922" height="2.14" />
            <rect x="9.459" y="72.409" fill="#999999" width="60.922" height="2.026" />
            <rect x="9.459" y="19.708" fill="#999999" width="60.922" height="1.915" />
            <polygon fill="#999999" points="7.432,27.704 7.432,7.434 72.407,7.434 72.407,27.704 70.381,27.704 70.381,9.461 9.459,9.461    9.459,27.704  " />
            <rect x="70.381" y="60.248" fill="#999999" width="2.026" height="44.705" />
            <rect x="7.432" y="60.248" fill="#999999" width="2.026" height="44.705" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,92.679h60.922v2.14H9.459V92.679" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,72.409h60.922v2.026H9.459V72.409" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,19.708h60.922v1.915H9.459V19.708" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.432,27.704V7.434h64.975v20.27h-2.026V9.461H9.459v18.243H7.432" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.407,60.248v44.705h-2.026V60.248H72.407" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,60.248v44.705H7.432V60.248H9.459" />
        </g>
        <g id="Group_Bag">
            <path fill="#FAFAFA" stroke="#4C4C4C" stroke-width="0.25" d="M21.057,68.131l-2.026-13.289V34.46l1.126-10.248l2.027-3.041   l16.215,1.014l15.315-1.014l4.054,1.014l2.026,8.22l1.127,20.382l-1.127,14.188l-1.013,4.055l-2.591,1.126l-12.725,1.126   l-17.454-1.126L21.057,68.131z" />
            <path fill="#D9D9D9" d="M55.741,56.869l1.014,10.135l1.014-12.162l-5.067-6.193L55.741,56.869z" />
            <path fill="#D9D9D9" d="M36.373,50.789l15.314-10.248l-18.355,9.234l-1.013-7.207l-2.027,9.234L36.373,50.789z" />
            <path fill="#D9D9D9" d="M21.057,57.883l1.127-17.342l-1.127-10.135l-0.9,16.216L21.057,57.883z" />
            <path fill="#EBEBEB" d="M56.755,65.99l3.04-10.134l-3.04-14.301l1.014,13.288L56.755,65.99z" />
            <path fill="#EBEBEB" d="M54.727,39.528l1.014,9.121l1.014-17.229l-2.027-8.221V39.528z" />
            <path fill="#EBEBEB" d="M50.674,37.5l-11.261,8.108l9.234-10.135l4.054-12.274L50.674,37.5z" />
            <path fill="#EBEBEB" d="M37.386,34.46l-8.108-11.261l7.095,2.027l12.274-2.027l-13.288,5.067L37.386,34.46z" />
            <path fill="#EBEBEB" d="M25.224,45.608l-2.027-13.175l2.027-8.22V45.608z" />
            <path fill="#EBEBEB" d="M29.278,57.883l-2.026-15.315l3.04,9.234l6.081-1.013l11.261-7.207L29.278,57.883z" />
            <path fill="#EBEBEB" d="M44.48,53.829l-10.135,4.054l7.095-4.054l11.261-15.315L44.48,53.829z" />
            <path fill="#EBEBEB" d="M49.21,68.918l6.531-3.941l-19.368-1.013l9.233-5.067L28.264,62.95L49.21,68.918z" />
            <path fill="#EBEBEB" d="M26.238,70.157l8.107-2.026L24.098,62.95l-0.901-13.175l-1.013,15.202L26.238,70.157z" />
            <path fill="#CCCCCC" d="M26.012,70.157l12.725-2.364l17.454,2.364H26.012z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M22.521,22.524l16.216,1.239l15.09-1.239l3.491,1.239" />
        </g>
        <g id="Group_Hanger">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M54.953,16.781v5.855l2.365,0.113l1.126-5.968v-4.729h-2.815   L54.953,16.781z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M24.886,16.781v5.855l-2.365,0.113l-1.238-5.968l0.112-4.729h2.815   L24.886,16.781z" />
        </g>
        <g id="Group_Discharger">
            <path fill="#4C4C4C" d="M52.137,97.972l-1.802-5.181l-0.563,2.252l1.802,5.181L52.137,97.972z" />
            <path fill="#4C4C4C" d="M27.702,97.972l1.802-5.181l0.563,2.252l-1.688,5.181L27.702,97.972z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M28.378,82.318h23.197l-2.365-4.053H30.63L28.378,82.318z" />
            <path fill="#B2B2B2" d="M26.012,70.157l9.234-1.239l20.944,1.239l-6.418,0.563l-2.928,3.49l0.676,4.617l1.126,1.689l-9.347,1.238   l-7.545-1.238l1.238-1.689l-1.238-4.617L26.012,70.157z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M32.994,79.954l6.307,0.563l8.221-0.563" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M28.378,82.318h23.197v14.414H28.378V82.318z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.994,78.828l6.307,0.563l8.221-0.563" />
            <path fill="#666666" d="M26.125,70.045l16.666,1.238l11.036-1.238v1.801H26.012L26.125,70.045z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.264,71.846l3.491,2.365l1.238,4.617l-1.238,1.689l7.545,1.238   l9.347-1.238l-1.126-1.689l-0.676-4.617l1.802-2.365" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M47.521,72.409l-2.365,2.928v2.929" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M44.593,72.973l-1.802,2.364v2.929" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M42.228,72.409l-1.126,2.364l0.563,3.492" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M39.413,72.409l0.563,2.928v2.929" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M36.711,72.409l0.9,2.928l1.126,3.491" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M31.192,72.409l2.928,2.928l1.239,2.929" />
            <path fill="none" stroke="#666666" stroke-width="0.25" d="M34.008,72.409l1.914,2.928l0.563,2.929" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.378,83.445h23.197" />
            <path fill="#CCCCCC" d="M35.922,92.115l1.127-2.928l2.928-1.125l2.814,1.125l1.239,2.928l-1.239,2.928l-2.814,1.127l-2.928-1.127   L35.922,92.115z" />
            <radialGradient id="SVGID_1_" cx="39.9197" cy="92.1152" r="2.8996" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" stroke="#7F7F7F" stroke-width="0.25" d="M37.049,92.115l0.788-2.026l2.14-0.901l2.026,0.901   l0.788,2.026l-0.788,2.141l-2.026,0.787l-2.14-0.787L37.049,92.115z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M38.737,92.115l0.338-0.787l0.901-0.338l0.788,0.338l0.338,0.787   v1.803h-2.365V92.115z" />
            <radialGradient id="SVGID_2_" cx="39.9197" cy="93.917" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M38.737,93.918l0.338-0.789l0.901-0.338l0.788,0.338l0.338,0.789l-0.338,0.787l-0.788,0.338   l-0.901-0.338L38.737,93.918z" />
        </g>
        <g id="Group_Bolt">
            <radialGradient id="SVGID_3_" cx="56.7546" cy="13.2334" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M55.629,13.29l0.338-0.901l0.788-0.338l0.788,0.338l0.338,0.901l-0.338,0.788l-0.788,0.338   l-0.788-0.338L55.629,13.29z" />
            <radialGradient id="SVGID_4_" cx="23.0847" cy="13.2334" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M21.959,13.29l0.338-0.901l0.788-0.338l0.788,0.338l0.338,0.901l-0.338,0.788l-0.788,0.338   l-0.788-0.338L21.959,13.29z" />
        </g>
        <g id="Group_Front_Stand">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M5.067,23.763v37.048H2.815V23.763H5.067z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M77.024,23.763v37.048h-2.252V23.763H77.024z" />
            <rect x="74.772" y="60.811" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" width="2.252" height="51.125" />
            <rect x="5.18" y="97.972" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" width="69.592" height="2.252" />
            <rect x="5.18" y="74.773" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" width="69.592" height="2.253" />
            <rect x="2.815" y="60.811" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" width="2.252" height="51.125" />
            <rect x="5.18" y="14.416" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.2" width="69.592" height="2.365" />
            <polygon fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.2" points="2.815,23.763 2.815,0.453 77.024,0.453 77.024,23.763    74.772,23.763 74.772,2.817 5.18,2.817 5.18,23.763  " />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M75.898,26.015v32.431" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M3.941,26.015v32.431" />
        </g>
        <g id="Group_Switch">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M12.724,68.918h9.234l-0.563,1.352H12.05L12.724,68.918z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M12.05,70.157h9.346v11.599H12.05V70.157z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M21.959,80.404V68.918l-0.563,1.352v11.486L21.959,80.404z" />
            <path d="M13.851,72.297h5.742v7.207h-5.742V72.297z" />
            <path fill="#CCCCCC" d="M14.414,72.973h4.617v5.855h-4.617V72.973z" />
        </g>
        <g id="Group_Button">
            <path fill="#7F5959" d="M16.103,74.773h2.364v1.689h-2.364V74.773z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.40625, 151.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.fills,"Group_Tuner",[0.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Cross_Rod",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Back_Rod",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Bag",[0.0, 0.046875, 243.0, 1.0, 0.3020833333333333, 177.0, 2.0, 0.3020833333333333, 177.0, 3.0, 0.3020833333333333, 177.0, 4.0, 0.16666666666666666, 212.0, 5.0, 0.16666666666666666, 212.0, 6.0, 0.16666666666666666, 212.0, 7.0, 0.16666666666666666, 212.0, 8.0, 0.16666666666666666, 212.0, 9.0, 0.16666666666666666, 212.0, 10.0, 0.16666666666666666, 212.0, 11.0, 0.16666666666666666, 212.0, 12.0, 0.16666666666666666, 212.0, 13.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Hanger",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Discharger",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.609375, 99.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.7916666666666666, 0.0, 6.0, 0.40625, 151.0, 7.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Front_Stand",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Switch",[0.0, 0.20833333333333334, 201.0, 1.0, 0.609375, 99.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Button",[0.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
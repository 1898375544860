import { SVGObject } from "../svgobject";

export class SVGConveyor6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 33.336" enable-background="new 0 0 112.5 33.336" xml:space="preserve">
        <g id="Group_Cross_Rods">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,22.637l6.645-6.532" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,16.105l6.645,6.532" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,23.425h6.419" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,30.632l6.645-6.531" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,24.101l6.645,6.531" />
        </g>
        <g id="Group_Storage_Structure">
            <path fill="#4C4C4C" d="M93.018,22.411h15.316v9.347H93.018V22.411z" />
            <path fill="#999999" d="M95.607,22.411h7.096V17.23h-7.096V22.411z" />
            <path d="M93.693,22.411h5.293v-3.716h-5.293V22.411z" />
            <path fill="#666666" d="M101.238,22.411h6.758v-5.968h-6.758V22.411z" />
            <path fill="#7F7F7F" d="M97.072,27.704h13.514v4.843H97.072V27.704z" />
            <path fill="#FFCC00" stroke="#4C4C4C" stroke-width="0.1" d="M94.145,7.434h0.676v25.451h-0.676V7.434z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.1" d="M93.018,7.434h16.104v8.221H93.018V7.434z" />
        </g>
        <g id="Group_Support_Stand">
            <path fill="#BF8C00" stroke="#4C4C4C" stroke-width="0.1" d="M49.662,32.885l1.464-13.852h0.789l-1.577,13.852H49.662z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M50.338,29.168h3.041V28.38h-3.041V29.168z" />
            <path fill="#FFCC00" stroke="#4C4C4C" stroke-width="0.1" d="M53.378,32.885l-1.577-13.852h1.126l1.577,13.852H53.378z" />
        </g>
        <g id="Group_V_Rod_Back">
            <path fill="#BF8C00" d="M92.68,5.069l-0.225,2.59l0.676-0.226l0.225-2.59L92.68,5.069z" />
            <path fill="#BF8C00" d="M89.752,5.745l-0.225,2.703l0.788-0.225l0.112-2.59L89.752,5.745z" />
            <path fill="#BF8C00" d="M86.824,6.533L86.6,9.235l0.787-0.225l0.227-2.59L86.824,6.533z" />
            <path fill="#BF8C00" d="M84.009,7.321l-0.226,2.703l0.676-0.225l0.226-2.59L84.009,7.321z" />
            <path fill="#BF8C00" d="M81.081,8.109l-0.226,2.703l0.789-0.225l0.112-2.59L81.081,8.109z" />
            <path fill="#BF8C00" d="M78.153,8.898l-0.226,2.59l0.789-0.113l0.225-2.703L78.153,8.898z" />
            <path fill="#BF8C00" d="M75.225,9.686l-0.111,2.59l0.675-0.113l0.226-2.703L75.225,9.686z" />
            <path fill="#BF8C00" d="M72.41,10.474l-0.226,2.59l0.676-0.113l0.338-2.703L72.41,10.474z" />
            <path fill="#BF8C00" d="M69.482,11.263l-0.226,2.59l0.788-0.225l0.226-2.59L69.482,11.263z" />
            <path fill="#BF8C00" d="M66.666,11.938l-0.225,2.703l0.676-0.225l0.225-2.59L66.666,11.938z" />
            <path fill="#BF8C00" d="M63.738,12.727l-0.225,2.703l0.788-0.225l0.112-2.59L63.738,12.727z" />
            <path fill="#BF8C00" d="M60.811,13.515l-0.112,2.703l0.676-0.226l0.226-2.59L60.811,13.515z" />
            <path fill="#BF8C00" d="M57.996,14.303l-0.226,2.59l0.675-0.112l0.227-2.703L57.996,14.303z" />
            <path fill="#BF8C00" d="M55.067,15.091l-0.225,2.59l0.676-0.113l0.225-2.702L55.067,15.091z" />
            <path fill="#BF8C00" d="M52.14,15.88l-0.113,2.59l0.676-0.112l0.113-2.703L52.14,15.88z" />
            <path fill="#BF8C00" d="M49.324,16.668l-0.225,2.59l0.676-0.225L50,16.443L49.324,16.668z" />
            <path fill="#BF8C00" d="M46.396,17.456l-0.225,2.591l0.675-0.226l0.226-2.591L46.396,17.456z" />
            <path fill="#BF8C00" d="M43.468,18.244l-0.225,2.59l0.789-0.225l0.112-2.59L43.468,18.244z" />
            <path fill="#BF8C00" d="M40.541,18.92l-0.113,2.703l0.676-0.225l0.225-2.591L40.541,18.92z" />
            <path fill="#BF8C00" d="M37.725,19.709L37.5,22.411l0.676-0.226l0.225-2.59L37.725,19.709z" />
            <path fill="#BF8C00" d="M34.797,20.497l-0.225,2.702l0.788-0.225l0.113-2.703L34.797,20.497z" />
            <path fill="#BF8C00" d="M31.869,21.285l-0.225,2.703l0.789-0.226l0.225-2.702L31.869,21.285z" />
            <path fill="#BF8C00" d="M29.054,22.073l-0.226,2.591l0.789-0.113l0.112-2.703L29.054,22.073z" />
            <path fill="#BF8C00" d="M26.126,22.861l-0.225,2.59l0.788-0.225l0.226-2.59L26.126,22.861z" />
            <path fill="#BF8C00" d="M23.311,23.65l-0.338,2.59l0.788-0.226l0.225-2.59L23.311,23.65z" />
            <path fill="#BF8C00" d="M20.383,24.438l-0.225,2.59l0.676-0.226l0.225-2.59L20.383,24.438z" />
            <path fill="#BF8C00" d="M17.455,25.113l-0.226,2.703l0.789-0.225l0.225-2.59L17.455,25.113z" />
            <path fill="#BF8C00" d="M14.527,25.902l-0.112,2.703l0.676-0.226l0.225-2.591L14.527,25.902z" />
            <path fill="#BF8C00" d="M11.712,26.69l-0.226,2.702l0.676-0.225l0.225-2.59L11.712,26.69z" />
            <path fill="#BF8C00" d="M8.784,27.479l-0.225,2.59l0.788-0.112l0.113-2.702L8.784,27.479z" />
            <path fill="#BF8C00" d="M5.856,28.268l-0.113,2.59l0.676-0.113l0.225-2.702L5.856,28.268z" />
            <path fill="#BF8C00" d="M3.041,29.056l-0.225,2.59l0.676-0.112l0.225-2.703L3.041,29.056z" />
        </g>
        <g id="Group_Conveyor_Belt">
            <path fill="#999999" d="M0.451,27.141L92.905,2.366l0.338,0.901L0.676,28.154L0.451,27.141z" />
            <path fill="#7F7F7F" d="M0.788,28.605L93.355,3.83l-0.112-0.563L0.676,28.154L0.788,28.605z" />
            <path fill="#FFCC00" d="M1.689,31.983L94.257,7.208l0.226,0.901L2.027,32.885L1.689,31.983z" />
            <path fill="#FFCC00" d="M0.901,29.056L93.469,4.281l0.112,0.45L1.014,29.506L0.901,29.056z" />
            <path fill="#CCCCCC" d="M0.338,26.69L92.793,1.916l0.112,0.45L0.451,27.141L0.338,26.69z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M0.901,29.056L93.469,4.281" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M93.581,4.731L1.126,29.619" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M1.352,30.52L93.807,5.745" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M2.027,32.885L94.482,8.109" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M1.689,31.983L94.257,7.208" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M0.338,26.69L92.793,1.916" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M0.451,27.141L92.905,2.366" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M0.788,28.605L93.355,3.83" />
        </g>
        <g id="Group_V_Rod_Front">
            <path fill="#E5B200" d="M90.991,5.52l1.464,2.14l0.676-0.226l-1.465-2.14L90.991,5.52z" />
            <path fill="#E5B200" d="M88.063,6.195l1.465,2.252l0.788-0.225l-1.577-2.14L88.063,6.195z" />
            <path fill="#E5B200" d="M85.135,6.983L86.6,9.235l0.787-0.225l-1.463-2.14L85.135,6.983z" />
            <path fill="#E5B200" d="M82.32,7.771l1.463,2.252l0.676-0.225l-1.463-2.14L82.32,7.771z" />
            <path fill="#E5B200" d="M79.393,8.56l1.463,2.252l0.789-0.225l-1.577-2.252L79.393,8.56z" />
            <path fill="#E5B200" d="M76.464,9.348l1.464,2.14l0.789-0.113l-1.465-2.252L76.464,9.348z" />
            <path fill="#E5B200" d="M73.648,10.137l1.465,2.14l0.675-0.113l-1.464-2.252L73.648,10.137z" />
            <path fill="#E5B200" d="M70.721,10.925l1.464,2.14l0.676-0.113L71.396,10.7L70.721,10.925z" />
            <path fill="#E5B200" d="M67.793,11.713l1.464,2.14l0.788-0.225l-1.464-2.14L67.793,11.713z" />
            <path fill="#E5B200" d="M64.978,12.501l1.464,2.14l0.676-0.225l-1.464-2.14L64.978,12.501z" />
            <path fill="#E5B200" d="M62.049,13.29l1.465,2.14l0.788-0.225l-1.577-2.14L62.049,13.29z" />
            <path fill="#E5B200" d="M59.121,13.965l1.577,2.252l0.676-0.226l-1.464-2.14L59.121,13.965z" />
            <path fill="#E5B200" d="M56.194,14.753l1.577,2.14l0.675-0.112l-1.463-2.252L56.194,14.753z" />
            <path fill="#E5B200" d="M53.378,15.542l1.464,2.14l0.676-0.113l-1.464-2.252L53.378,15.542z" />
            <path fill="#E5B200" d="M50.451,16.33l1.576,2.14l0.676-0.112l-1.464-2.252L50.451,16.33z" />
            <path fill="#E5B200" d="M47.635,17.119l1.464,2.139l0.676-0.225l-1.464-2.14L47.635,17.119z" />
            <path fill="#E5B200" d="M44.707,17.906l1.464,2.141l0.675-0.226l-1.464-2.14L44.707,17.906z" />
            <path fill="#E5B200" d="M41.779,18.695l1.464,2.139l0.789-0.225l-1.464-2.14L41.779,18.695z" />
            <path fill="#E5B200" d="M38.852,19.371l1.576,2.252l0.676-0.225l-1.464-2.141L38.852,19.371z" />
            <path fill="#E5B200" d="M36.036,20.159l1.464,2.252l0.676-0.226l-1.464-2.139L36.036,20.159z" />
            <path fill="#E5B200" d="M33.108,20.947l1.464,2.252l0.788-0.225l-1.577-2.141L33.108,20.947z" />
            <path fill="#E5B200" d="M30.18,21.735l1.464,2.253l0.789-0.226l-1.464-2.253L30.18,21.735z" />
            <path fill="#E5B200" d="M27.365,22.523l1.464,2.141l0.789-0.113l-1.577-2.252L27.365,22.523z" />
            <path fill="#E5B200" d="M24.437,23.313l1.464,2.139l0.788-0.225l-1.576-2.14L24.437,23.313z" />
            <path fill="#E5B200" d="M21.622,24.101l1.352,2.14l0.788-0.226l-1.464-2.14L21.622,24.101z" />
            <path fill="#E5B200" d="M18.694,24.889l1.464,2.14l0.676-0.226l-1.464-2.139L18.694,24.889z" />
            <path fill="#E5B200" d="M15.766,25.564l1.464,2.252l0.789-0.225l-1.464-2.141L15.766,25.564z" />
            <path fill="#E5B200" d="M12.951,26.353l1.464,2.253l0.676-0.226l-1.464-2.14L12.951,26.353z" />
            <path fill="#E5B200" d="M10.022,27.141l1.464,2.252l0.676-0.225l-1.464-2.14L10.022,27.141z" />
            <path fill="#E5B200" d="M7.095,27.93l1.464,2.139l0.788-0.112l-1.576-2.252L7.095,27.93z" />
            <path fill="#E5B200" d="M4.167,28.718l1.577,2.14l0.676-0.113l-1.464-2.252L4.167,28.718z" />
            <path fill="#E5B200" d="M1.352,29.506l1.464,2.14l0.676-0.112l-1.464-2.252L1.352,29.506z" />
        </g>
        <g id="Group_Belt_Holder">
            <path fill="#FFCC00" d="M91.217,2.253l0.338-0.112l0.338,0.563l0.449,1.802l-0.676,0.225l-0.563-1.915L91.217,2.253z" />
            <path fill="#FFCC00" d="M88.4,3.042h0.226l0.338,0.338l0.563,1.914L88.738,5.52l-0.45-1.915L88.4,3.042z" />
            <path fill="#FFCC00" d="M85.473,3.83l0.226-0.112l0.45,0.45L86.6,6.083l-0.676,0.225L85.36,4.393L85.473,3.83z" />
            <path fill="#FFCC00" d="M82.545,4.619l0.226-0.113l0.45,0.45l0.451,1.915l-0.676,0.225l-0.563-1.914L82.545,4.619z" />
            <path fill="#FFCC00" d="M79.729,5.407l0.226-0.113l0.338,0.451l0.563,1.915l-0.788,0.225l-0.45-1.915L79.729,5.407z" />
            <path fill="#FFCC00" d="M76.802,6.195l0.226-0.112l0.338,0.45l0.563,1.915L77.252,8.56l-0.563-1.802L76.802,6.195z" />
            <path fill="#FFCC00" d="M73.986,6.871H74.1l0.449,0.451L75,9.235l-0.676,0.113l-0.45-1.915L73.986,6.871z" />
            <path fill="#FFCC00" d="M71.059,7.659h0.225l0.338,0.45l0.451,1.915l-0.676,0.113l-0.451-1.915L71.059,7.659z" />
            <path fill="#FFCC00" d="M68.131,8.447h0.225l0.338,0.451l0.563,1.802l-0.788,0.225L68.018,9.01L68.131,8.447z" />
            <path fill="#FFCC00" d="M65.203,9.235h0.225l0.451,0.338l0.449,1.915l-0.675,0.225L65.09,9.799L65.203,9.235z" />
            <path fill="#FFCC00" d="M62.387,10.024l0.227-0.113l0.338,0.451l0.563,1.915l-0.789,0.225l-0.449-1.915L62.387,10.024z" />
            <path fill="#FFCC00" d="M59.459,10.812l0.226-0.112l0.338,0.45l0.563,1.915L59.91,13.29l-0.563-1.915L59.459,10.812z" />
            <path fill="#FFCC00" d="M56.532,11.601l0.337-0.113l0.338,0.451l0.451,1.914l-0.676,0.226l-0.563-1.915L56.532,11.601z" />
            <path fill="#FFCC00" d="M53.604,12.389l0.338-0.112l0.338,0.45l0.563,1.915l-0.788,0.225l-0.451-1.915V12.389z" />
            <path fill="#FFCC00" d="M50.788,13.064h0.226l0.338,0.45l0.563,1.915l-0.676,0.113l-0.563-1.802L50.788,13.064z" />
            <path fill="#FFCC00" d="M47.86,13.853h0.338l0.338,0.451l0.45,1.915l-0.675,0.112l-0.563-1.914L47.86,13.853z" />
            <path fill="#FFCC00" d="M44.933,14.641h0.338l0.337,0.45l0.451,1.802l-0.676,0.226l-0.563-1.915L44.933,14.641z" />
            <path fill="#FFCC00" d="M42.117,15.429h0.225l0.338,0.338l0.563,1.915l-0.676,0.225l-0.563-1.914L42.117,15.429z" />
            <path fill="#FFCC00" d="M39.189,16.218l0.226-0.113l0.45,0.451l0.451,1.914l-0.676,0.226l-0.563-1.915L39.189,16.218z" />
            <path fill="#FFCC00" d="M36.374,17.006l0.225-0.113l0.338,0.451l0.45,1.914l-0.675,0.226l-0.451-1.915L36.374,17.006z" />
            <path fill="#FFCC00" d="M33.446,17.794l0.226-0.112l0.337,0.45l0.563,1.915l-0.789,0.225l-0.45-1.914L33.446,17.794z" />
            <path fill="#FFCC00" d="M30.518,18.582l0.225-0.112l0.451,0.45l0.45,1.914l-0.676,0.227l-0.563-1.915L30.518,18.582z" />
            <path fill="#FFCC00" d="M27.703,19.371l0.113-0.113l0.45,0.451l0.563,1.914l-0.788,0.112l-0.45-1.802L27.703,19.371z" />
            <path fill="#FFCC00" d="M24.775,20.047H25l0.338,0.45l0.563,1.914l-0.788,0.112l-0.451-1.914L24.775,20.047z" />
            <path fill="#FFCC00" d="M21.847,20.834h0.226l0.45,0.451l0.451,1.914l-0.676,0.113l-0.563-1.914L21.847,20.834z" />
            <path fill="#FFCC00" d="M19.032,21.623h0.112l0.451,0.45l0.563,1.802l-0.789,0.226l-0.45-1.915L19.032,21.623z" />
            <path fill="#FFCC00" d="M16.104,22.411h0.225l0.338,0.338l0.563,1.915l-0.788,0.225l-0.45-1.914L16.104,22.411z" />
            <path fill="#FFCC00" d="M13.176,23.199l0.225-0.112l0.338,0.45l0.563,1.914l-0.676,0.226l-0.563-1.914L13.176,23.199z" />
            <path fill="#FFCC00" d="M10.248,23.988l0.338-0.113l0.338,0.451l0.563,1.914l-0.788,0.225l-0.563-1.914L10.248,23.988z" />
            <path fill="#FFCC00" d="M7.433,24.776l0.225-0.112l0.338,0.449l0.563,1.915l-0.788,0.112L7.32,25.34L7.433,24.776z" />
            <path fill="#FFCC00" d="M4.504,25.451h0.225l0.451,0.451l0.451,1.914l-0.676,0.226l-0.563-1.915L4.504,25.451z" />
            <path fill="#FFCC00" d="M1.577,26.24h0.338l0.338,0.45l0.45,1.915l-0.676,0.112l-0.45-1.802V26.24z" />
        </g>
        <g id="Group_Feeder_Vessel">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="101.5762" y1="15.6543" x2="101.5762" y2="1.4653">
                <stop offset="0" style="stop-color:#E5B200" />
                <stop offset="0.25" style="stop-color:#FBC800" />
                <stop offset="1" style="stop-color:#FFCC00" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" d="M95.945,8.222l4.168,7.432h7.094V1.465H97.072L95.945,8.222z" />
            <path fill="#FFD940" stroke="#4C4C4C" stroke-width="0.1" d="M92.229,0.339l2.365,8.671l8.108-1.577V1.465L92.229,0.339z" />
            <path fill="#BF8C00" stroke="#4C4C4C" stroke-width="0.1" d="M100.451,16.443l4.166,10.473h2.59V16.443H100.451z" />
        </g>
        <g id="Group_Support_Structure">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,23.199l6.756-6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,16.443l6.756,6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M102.365,16.443l6.756,6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M102.365,23.199l6.756-6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,23.988h14.301" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,31.42l6.756-6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,24.664l6.756,6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M102.365,24.664l6.756,6.756" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M102.365,31.42l6.756-6.756" />
            <path fill="#E5B200" stroke="#4C4C4C" stroke-width="0.1" d="M109.121,16.443h0.676v16.442h-0.676V16.443z" />
            <path fill="#E5B200" stroke="#4C4C4C" stroke-width="0.1" d="M101.576,16.443h0.789v16.442h-0.789V16.443z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M92.68,16.443v-0.789h19.369v0.789H92.68z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M94.82,27.704v-0.788h14.977v0.788H94.82z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M92.68,23.988v-0.789h3.266v0.789H92.68z" />
            <path fill="#FFCC00" stroke="#4C4C4C" stroke-width="0.1" d="M90.428,7.434h0.676v24.324h-0.676V7.434z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M88.852,16.105v-0.789l3.828,0.338v0.789L88.852,16.105z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,27.254v-0.676l3.717,0.338v0.788L91.104,27.254z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M88.852,23.537v-0.676l3.828,0.338v0.789L88.852,23.537z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.1" d="M95.945,15.654V9.01" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.1" d="M88.852,15.316h4.166l2.928,0.338H92.68L88.852,15.316z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.1" d="M88.852,22.861h4.166l2.928,0.338H92.68L88.852,22.861z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.1" d="M91.104,26.578h15.315l3.378,0.338H94.82L91.104,26.578z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9479166666666666, 13.0, 0.25, 0.828125, 43.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Storage_Structure",[0.0, 0.5885416666666666, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Support_Stand",[0.0, 0.8541666666666666, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_V_Rod_Back",[0.0, 0.8541666666666666, 0.0, 1.0, 0.8541666666666666, 0.0, 2.0, 0.8541666666666666, 0.0, 3.0, 0.8541666666666666, 0.0, 4.0, 0.8541666666666666, 0.0, 5.0, 0.8541666666666666, 0.0, 6.0, 0.8541666666666666, 0.0, 7.0, 0.8541666666666666, 0.0, 8.0, 0.8541666666666666, 0.0, 9.0, 0.8541666666666666, 0.0, 10.0, 0.8541666666666666, 0.0, 11.0, 0.8541666666666666, 0.0, 12.0, 0.8541666666666666, 0.0, 13.0, 0.8541666666666666, 0.0, 14.0, 0.8541666666666666, 0.0, 15.0, 0.8541666666666666, 0.0, 16.0, 0.8541666666666666, 0.0, 17.0, 0.8541666666666666, 0.0, 18.0, 0.8541666666666666, 0.0, 19.0, 0.8541666666666666, 0.0, 20.0, 0.8541666666666666, 0.0, 21.0, 0.8541666666666666, 0.0, 22.0, 0.8541666666666666, 0.0, 23.0, 0.8541666666666666, 0.0, 24.0, 0.8541666666666666, 0.0, 25.0, 0.8541666666666666, 0.0, 26.0, 0.8541666666666666, 0.0, 27.0, 0.8541666666666666, 0.0, 28.0, 0.8541666666666666, 0.0, 29.0, 0.8541666666666666, 0.0, 30.0, 0.8541666666666666, 0.0, 31.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Conveyor_Belt",[0.0, 0.8072916666666666, 49.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_V_Rod_Front",[0.0, 0.9479166666666666, 13.0, 1.0, 0.9479166666666666, 13.0, 2.0, 0.9479166666666666, 13.0, 3.0, 0.9479166666666666, 13.0, 4.0, 0.9479166666666666, 13.0, 5.0, 0.9479166666666666, 13.0, 6.0, 0.9479166666666666, 13.0, 7.0, 0.9479166666666666, 13.0, 8.0, 0.9479166666666666, 13.0, 9.0, 0.9479166666666666, 13.0, 10.0, 0.9479166666666666, 13.0, 11.0, 0.9479166666666666, 13.0, 12.0, 0.9479166666666666, 13.0, 13.0, 0.9479166666666666, 13.0, 14.0, 0.9479166666666666, 13.0, 15.0, 0.9479166666666666, 13.0, 16.0, 0.9479166666666666, 13.0, 17.0, 0.9479166666666666, 13.0, 18.0, 0.9479166666666666, 13.0, 19.0, 0.9479166666666666, 13.0, 20.0, 0.9479166666666666, 13.0, 21.0, 0.9479166666666666, 13.0, 22.0, 0.9479166666666666, 13.0, 23.0, 0.9479166666666666, 13.0, 24.0, 0.9479166666666666, 13.0, 25.0, 0.9479166666666666, 13.0, 26.0, 0.9479166666666666, 13.0, 27.0, 0.9479166666666666, 13.0, 28.0, 0.9479166666666666, 13.0, 29.0, 0.9479166666666666, 13.0, 30.0, 0.9479166666666666, 13.0, 31.0, 0.9479166666666666, 13.0]);
		this.fillGradient(this.fills,"Group_Belt_Holder",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0, 8.0, 0.8072916666666666, 49.0, 9.0, 0.8072916666666666, 49.0, 10.0, 0.8072916666666666, 49.0, 11.0, 0.8072916666666666, 49.0, 12.0, 0.8072916666666666, 49.0, 13.0, 0.8072916666666666, 49.0, 14.0, 0.8072916666666666, 49.0, 15.0, 0.8072916666666666, 49.0, 16.0, 0.8072916666666666, 49.0, 17.0, 0.8072916666666666, 49.0, 18.0, 0.8072916666666666, 49.0, 19.0, 0.8072916666666666, 49.0, 20.0, 0.8072916666666666, 49.0, 21.0, 0.8072916666666666, 49.0, 22.0, 0.8072916666666666, 49.0, 23.0, 0.8072916666666666, 49.0, 24.0, 0.8072916666666666, 49.0, 25.0, 0.8072916666666666, 49.0, 26.0, 0.8072916666666666, 49.0, 27.0, 0.8072916666666666, 49.0, 28.0, 0.8072916666666666, 49.0, 29.0, 0.8072916666666666, 49.0, 30.0, 0.8072916666666666, 49.0, 31.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Feeder_Vessel",[0.0, 0.609375, 99.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Support_Structure",[0.0, 0.9479166666666666, 13.0, 1.0, 0.9479166666666666, 13.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0, 8.0, 0.40625, 151.0, 9.0, 0.0, 255.0, 10.0, 0.0, 255.0, 11.0, 0.0, 255.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { SwitchControlProperty} from "./properties/switchcontrolproperty"
import Konva from "konva";
import { ObjectView } from './object';
import { Value } from '../value';


export class Switch extends ObjectView{
    text:string;
    textcolor:string;
    fillcolor:string;
    texton:string;
    textoff:string;
    type3d:number;
    
    
    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1
        let sw = false;
        const switchprop = this.properties==null? null : this.properties['switchcontrol'];
        let switchproperty:SwitchControlProperty;
        if (switchprop!=null){	
            switchproperty =Object.assign(new SwitchControlProperty(), switchprop);
            if (switchproperty!=null) 
              sw = switchproperty.getCurrentBooleanValue(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties==null? null : this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
              fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const gradientrect = ctx.createLinearGradient(0, 0, 0, this.height);
        gradientrect.addColorStop(0,'white')
        gradientrect.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.1))

        const gradientellipse = ctx.createLinearGradient(0, -this.height*2/3, 0, this.height/3);
        gradientellipse.addColorStop(0,'white')
        gradientellipse.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.1))

        const ellipseout = new Konva.Ellipse({
          x: this.width/2,
          y: this.height*2/3,
          radiusX: this.width/2-this.width/100,
          radiusY: this.height/3-this.width/100,
          stroke: this.type3d==0? gradientellipse: ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth: this.width/50
        });
        this.node.add(ellipseout)

        const rectout = new Konva.Rect({
          x: this.width/100,
          y: this.width/100,
          width: this.width-this.width/50,
          height: this.height*2/3-this.width/50,
          stroke: this.type3d==0? gradientrect: ColorUtils.darkColor(fillcolor2, 0.1),
          strokeWidth: this.width/50
        });
        this.node.add(rectout)

        const ellipseinner = new Konva.Ellipse({
          x: this.width/2,
          y: this.height*2/3,
          radiusX: this.width/2-this.width/50,
          radiusY: this.height/3-this.width/50,
          fill: fillcolor2
        });
        this.node.add(ellipseinner)

        const rectinner = new Konva.Rect({
          x: this.width/50,
          y: this.width/50,
          width: this.width-this.width/25,
          height: this.height*2/3-this.width/25,
          fill: fillcolor2
        });
        this.node.add(rectinner)

        const length = this.width/2>this.height/3 ? this.width/2: this.height/3;   
        const rad = 45* Math.PI/180;
              
        let x1 = this.width/2 - length*Math.cos(rad)
        if(x1<this.width/10)x1=this.width/10
        let y1 = this.height*2/3 - length * Math.sin(rad);
        if(y1<this.height/5) y1=this.height/5;

        const p1 = [
            this.width/2, this.height*2/3,
            x1, y1,
            x1, y1-this.height/20
        ]
        const line1= new Konva.Line({
            points:p1,
            strokeWidth: this.width/30,
        })
        line1.stroke(ColorUtils.darkColor(fillcolor2, 0.3))
        this.node.add(line1)

        let x2 = this.width/2 + length*Math.cos(rad);
        if (x2>this.width*9/10) x2=this.width*9/10;
        let y2 = this.height*2/3 - length * Math.sin(rad);
        if (y2<this.height/5) y2=this.height/5;
                  
        const p2 = [
          this.width/2, this.height*2/3,
          x2, y2, 
          x2, y2-this.height/20
        ]
                  
        const line2= new Konva.Line({
          points:p2,
          strokeWidth: this.width/30,
        })
        line2.stroke(ColorUtils.darkColor(fillcolor2, 0.3))
        this.node.add(line2)

        const radius  = (this.width/2-this.width/20)<(this.height/3-this.height/20)?
                      (this.width/2-this.width/20):
                      (this.height/3-this.height/20)
                            
        const circleout = new Konva.Circle({
          x: this.width/2,
          y: this.height*2/3,
          radius: radius,
        });
        if(this.type3d==0){
          circleout.fillLinearGradientStartPoint({ x: 0, y: -radius});
          circleout.fillLinearGradientEndPoint({ x: 0, y: radius });
          circleout.fillLinearGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2, 0.1)])
        }else{
          circleout.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        }
        this.node.add(circleout)

        const circlemiddle = new Konva.Circle({
          x: this.width/2,
          y: this.height*2/3,
          radius: radius*9/10,
        });
        if(this.type3d==0){
          circlemiddle.fillLinearGradientStartPoint({ x: 0, y: -radius*9/10});
          circlemiddle.fillLinearGradientEndPoint({ x: 0, y: radius*9/10 });
          circlemiddle.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1), 1, 'white'])
        }else{
          circlemiddle.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        }
        this.node.add(circlemiddle)

        const circleinner= new Konva.Circle({
          x: this.width/2,
          y: this.height*2/3,
          radius: radius*8/10,
        });
        circleinner.fill(ColorUtils.darkColor(fillcolor2, 0.75))
              
        this.node.add(circleinner)

        let arrowwidth =this.width*1/8;
        let arrowheight = radius*2;
        if (arrowwidth<1) arrowwidth = 1;
        if (arrowheight<1) arrowheight = 1;

        const p3 = [
          0, arrowheight,
          0, arrowheight/2,
          arrowwidth/2-arrowwidth/10, 0,
          arrowwidth/2+arrowwidth/10, 0,
          arrowwidth/2+arrowwidth/10, 0,
          arrowwidth, arrowheight/2,
          arrowwidth, arrowheight,
          0, arrowheight
        ]
        const anglearr = sw? -45 : 45;
        const radians = anglearr*Math.PI/180;
        const arrow= new Konva.Line({
            points:p3,
            stroke: 'black',
            closed: true
        })

        const posx = this.width/2 -arrowwidth/2;
        const posy = this.height*2/3 -arrowheight/2;
        const x = arrowwidth/2+ posx-arrowwidth/2  * Math.cos(radians) +(arrowheight/2) * Math.sin(radians);
        const y = posy+arrowheight/2-(arrowwidth/2 ) * Math.sin(radians) -(arrowheight/2) * Math.cos(radians);
             // const x=arrowwidth/2+this.width/2-arrowwidth/2*Math.cos(radians)-arrowheight/2 * Math.sin(radians);
              //const y=arrowheight/2+this.height*2/3-arrowwidth/2*Math.sin(radians)-arrowheight/2 * Math.cos(radians);
        arrow.position({x:x,y:y})
             
        arrow.fill(ColorUtils.darkColor(fillcolor2, 0.3))

        arrow.rotation(anglearr)
              //arrow.offsetX(-radius*Math.cos(radians))
             // arrow.offsetY(this.height*2/3-arrowheight+radius*Math.cos(radians))
        this.node.add(arrow)

        let textcolor = this.textcolor;
          const textcolorprop = this.properties==null ? null : this.properties['textcolor'];
          if (textcolorprop!=null){	
              const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
              if (textcolorproperty!=null) 
                  textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
          }
              
        const TextOn = new Konva.Text({
            x: this.width/25,
            y: y1-this.height/20-this.height/10, 
            verticalAlign:"middle",
            width: this.width/2-this.width/25,
            height: this.height/10,
            text: this.texton,
            fontSize: this.height/10,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align:"left",
            fill: ColorUtils.convertformat(textcolor),
          });
          this.node.add(TextOn)

          const TextOff = new Konva.Text({
            x: this.width/2,
            y: y2-this.height/20-this.height/10, 
            verticalAlign:"middle",
            width: this.width/2-this.width/25,
            height: this.height/10,
            text: this.textoff,
            fontSize: this.height/10,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align:"right",
            fill: ColorUtils.convertformat(textcolor),
          });
          this.node.add(TextOff)  
                  
          const TextLabel = new Konva.Text({
            x: this.width/25,
            y: this.height/6+this.width/50 -this.height/8, 
            verticalAlign:"bottom",
            width: this.width-2*this.width/25,
            height: this.height/8,
            text: this.text,
            fontSize: this.height/8,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align:"center",
            fill: ColorUtils.convertformat(textcolor),
          });
          this.node.add(TextLabel) 
          this.setFunctions(switchproperty);           
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "textcolor": 
          case "fillcolor":
          case "text":
          case "texton":
          case "textoff":  {this[field] = value.value;this.rotatednode=null;break;}

          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "textcolor": 
          case "fillcolor":
          case "text":
          case "texton":
          case "textoff":  {value.datatype=7; value.value = this[field];break}

          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }

    protected setFunctions(switchproperty:SwitchControlProperty):void{
       
        if (switchproperty!=null){
          this.node.on('mousedown touchstart', function () {
                 switchproperty.setClick();
            });
          }
    }
}
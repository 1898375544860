import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { Tile } from "./tile";
import { RangeValueProperty } from "../properties/rangevalueproperty";
import tag from "@/model/tag";
import authModule from "@/store/auth.module";
import historydatarequest from "@/model/requests/historydatarequest";
import tagsService from "@/services/tags.service";
import { ColorRange } from "../properties/range/colorrange";

export class GaugeSparklineTile extends Tile{
    updatehistory = true;
    tagname: string;
    points: any[] = [];
    
    public deleteObject():void{
        super.deleteObject()
        this.updatehistory=true;
      }
    public drawObject():void{
		super.initObject();

        let rangeproperty:RangeValueProperty
        const rangeprop = this.properties==null ? null : this.properties['rangevalue'];
        let tag:tag
        if (rangeprop!=null){	
                rangeproperty =Object.assign(new RangeValueProperty(), rangeprop);
                if (rangeproperty!=null) tag = rangeproperty.getTag(this.tagnames)
        }

        if(this.updatehistory){
        
            if (authModule.currentUser!=null && rangeproperty!=null && tag!=null){
                const request:historydatarequest =  {token:authModule.currentUser.accessToken, 
                  name:tag.path==null?tag.name:tag.path, begindate:Date.now()-rangeproperty.getDuration() , enddate: Date.now()};
                  tagsService.gethistorydata(request,(data:string)=>{
                
                const historydata =JSON.parse(data);  
                this.points =  historydata;
                //console.log('this.points ', this.points)
                const group = this.drawImage(tag, rangeproperty, this.points)
                this.node.add(group) 
                this.updatehistory = false
                  
               }, (data:string)=>{ console.log('data', data)}
              
            )}   
          }else{
            if(tag !=null){
              this.points.push({x:Date.now(), y:Number(tag.tagvalue)})
              //console.log('this.points1', this.points)
              this.points = this.points.filter((el)=>el.x>=Date.now()-rangeproperty.getDuration())
              const group = this.drawImage(tag, rangeproperty, this.points)
              this.node.add(group) 
            }
          }    
    }

    private drawImage(tag:tag, rangeproperty:RangeValueProperty, points:any[]):Konva.Group{
        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
        const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
        const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
        if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }
	
        const size = this.width < this.height ? this.width : this.height;
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
        clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
        },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)

        const textTitle = new Konva.Text({
        x: size*0.05,
        y: size*0.1-0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05,
        height: 0.06*size,
        text: this.title,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(textTitle)

        const textDescription = new Konva.Text({
            x: size*0.05,
            y: this.height - size*0.05-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.description,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(textDescription)

        let angle = 0;
		const radius = size*.3;		
		let valuemin = 0;
		let valuemax = 100;
		let value = 0;
        let decimalpos = 0;
        let fcolor = 'blue'

        if (rangeproperty!=null){
            decimalpos = rangeproperty.decimalpos;
            valuemin = Number(rangeproperty.minimumvalue);
            valuemax = Number(rangeproperty.maximumvalue);
            if(tag !=null) value = Number(tag.tagvalue)
            
            angle = rangeproperty.getCurrentValue270deg(value);

            if(rangeproperty.colorrange!=null && rangeproperty.colorrange.length!=0){
                rangeproperty.colorrange.forEach((el)=>{
                    if(el.from < value && value < el.to) fcolor = el.color
                })
            }
        }
        //console.log('valuemin', valuemin, valuemax)

        const barBackground = new Konva.Arc({
            x: this.width / 2,
            y: this.height/ 2, 
            innerRadius: radius+size * 0.06/2-size * 0.06,
            outerRadius: radius+size * 0.06/2,
            angle: 270,
            fill: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
            rotation: 135
        });
        group.add(barBackground)

        const bar = new Konva.Arc({
            x: this.width / 2,
            y: this.height/ 2, 
            innerRadius: radius+size * 0.06/2-size * 0.06,
            outerRadius: radius+size * 0.06/2,
            angle: angle,
            fill: ColorUtils.convertformat(fcolor),
            rotation: 135
        });
        group.add(bar)

        const mintext = new Konva.Text({
            x: this.width/2+radius*Math.cos(135*Math.PI/180),
            y: this.height/2 + radius*Math.sin(135*Math.PI/180),
            verticalAlign:"top",
            text: parseFloat(valuemin.toFixed(decimalpos)).toLocaleString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(mintext)

        const maxtextTransparent = new Konva.Text({
            x: 0,
            y: 0,
            verticalAlign:"top",
            text: parseFloat(valuemax.toFixed(decimalpos)).toLocaleString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  

        const maxtext = new Konva.Text({
            x: this.width/2-radius*Math.cos(135*Math.PI/180)- 1.1*maxtextTransparent.width(),
            y: this.height/2 + radius*Math.sin(135*Math.PI/180),
            verticalAlign:"top",
            text: parseFloat(valuemax.toFixed(decimalpos)).toLocaleString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(maxtext)

        if(rangeproperty!=null && rangeproperty.isShowvalue()){
            const valuetext = new Konva.Text({
                x: width/4,
                y: this.height-size*.1-0.12*size,
                width: this.width/2,
                verticalAlign:"bottom",
                text: value !=null ? parseFloat(value.toFixed(decimalpos)).toLocaleString() : 'N/A',
                fontSize: 0.12*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align:'center'
                });  
            group.add(valuetext)
        }

        if (rangeproperty!=null && rangeproperty.isShowhistory()) {
           
            const mindate = Math.min(...points.map(o => o.x))
            const maxdate = Date.now()
        
            let deltadate = maxdate - mindate;
            deltadate = deltadate==0? 1: deltadate;

            const minvalue = Math.min(...points.map(o => o.y));
            const maxvalue = Math.max(...points.map(o => o.y));
            let deltavalue = maxvalue - minvalue;
            deltavalue = deltavalue==0? 1: deltavalue;
			
			const minX  = width*.5-radius*.7;
            const maxX  = width*.5 + radius*.7;
            const minY  = height*0.5-radius*.3;
            const maxY  = minY + radius*.8;

            const stepY = (maxY-minY)/5;
            let y1 = minY;

            for(let i=0; i<5; i++) {
                const line = new Konva.Line({
                    points: [minX,y1,maxX,y1],
                    strokeWidth: 1,
                    stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
                })
                group.add(line)
                y1+=stepY;	
            }

            const curvePoints = []
            points.forEach((p)=>{
            const x = (p.x-mindate)*radius*1.4/(maxdate-mindate);
            let y = (maxvalue-p.y)*(radius*.8-stepY)/(deltavalue);
            if (y<0) y=0;
            if (y>radius*.8) y=radius*.8
            curvePoints.push(minX+x, y+minY)
            })
            //console.log('curvePoints', curvePoints)
            if (curvePoints.length<2)
            return group;

            //const curx = this.width*.9;
            let cury = 0;

            if (tag!=null) {
            cury = (maxvalue-Number(tag.tagvalue))*(radius*.8-stepY)/(deltavalue);
            curvePoints.push(maxX, minY+cury)
            }

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, maxY , 0, minY);

            if(rangeproperty.colorrange !=null && rangeproperty.colorrange.length > 0){	
                rangeproperty.colorrange.forEach((col:ColorRange)=>{
                  if(col.from>maxvalue && col.to>maxvalue) return
                  if(col.from<minvalue && col.to<minvalue) return

                  let from = (col.from - minvalue)/deltavalue
                  if(from != null){
                    from = from>1? 1: (from<0 ? 0: from);
                  }else from = 1

                  let to = (col.to - minvalue)/deltavalue
                  if(to != null){
                    to = to>1 ? 1 : (to<0 ? 0 : to)
                  }else to = 0

                  gradient.addColorStop(from, ColorUtils.convertformat(col.color))
                  gradient.addColorStop(to, ColorUtils.convertformat(col.color))
                })
              } 

            const curve = new Konva.Line({
                points: curvePoints,
                stroke: (rangeproperty.colorrange==null || rangeproperty.colorrange.length == 0)?
                        ColorUtils.convertformat(fcolor)
                     : gradient,
                strokeWidth: size * 0.005,
            });
          
            const dot = new Konva.Circle({
                x: maxX,
                y: minY+cury,
                radius: size*.01,
            });

            const colorStops = []
            rangeproperty.colorrange.forEach((col:ColorRange)=>{
            // console.log('col', col.color)
            if(col.from>maxvalue && col.to>maxvalue) return
            if(col.from<minvalue && col.to<minvalue) return
            let from = (col.from - minvalue)/deltavalue
            from = from>1? 1: (from<0 ? 0: from);
            let to = (col.to - minvalue)/deltavalue
            to = to>1 ? 1 : (to<0 ? 0 : to);
            colorStops.push(from, ColorUtils.convertformat(col.color), to, ColorUtils.convertformat(col.color))
            })
            if(rangeproperty.colorrange ==null || rangeproperty.colorrange.length == 0 || colorStops.length ==0){
                dot.fill(ColorUtils.convertformat(fcolor))
            }else{
            // console.log('colorStops', colorStops)
                dot.fillLinearGradientStartPoint({ x: 0, y: -(minY+height*.45-this.height*.5-cury)});
                dot.fillLinearGradientEndPoint({ x: 0, y: this.height*.5+cury-minY});
                dot.fillLinearGradientColorStops(colorStops)
            }	
            group.add(curve)
            group.add(dot)
        }
        return group
    }
}
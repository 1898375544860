import { SVGObject } from "../svgobject";

export class SVGTank10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 101.045 112.5" enable-background="new 0 0 101.045 112.5" xml:space="preserve">
        <g id="Group_BackLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.8979" y1="82.4092" x2="20.0444" y2="82.4092">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M5.898,52.32h14.146v60.178H5.898V52.32" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="5" y1="82.4102" x2="20.9424" y2="82.4102">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M5,81.512h15.942v1.796H5V81.512z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="81.751" y1="82.4092" x2="95.8965" y2="82.4092">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M81.751,52.32h14.146v60.178H81.751V52.32" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="80.6279" y1="82.4102" x2="96.7949" y2="82.4102">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M80.628,81.512h16.167v1.796H80.628V81.512z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="80.6279" y1="110.7012" x2="96.7949" y2="110.7012">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M80.628,109.804h16.167v1.796H80.628V109.804z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="5" y1="110.7012" x2="20.9424" y2="110.7012">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M5,109.804h15.942v1.796H5V109.804z" />
        </g>
        <g id="Group_TankBody">
            <radialGradient id="SVGID_7_" cx="50.9146" cy="54.7075" r="49.4207" gradientUnits="userSpaceOnUse">
                <stop offset="0.14" style="stop-color:#F2F2F2" />
                <stop offset="0.24" style="stop-color:#E3E3E3" />
                <stop offset="0.44" style="stop-color:#BBBBBB" />
                <stop offset="0.72" style="stop-color:#7A7A7A" />
                <stop offset="1" style="stop-color:#333333" />
            </radialGradient>
            <circle fill="url(#SVGID_7_)" cx="50.582" cy="51.374" r="49.624" />
            <ellipse fill="none" stroke="#808080" stroke-width="0.25" cx="50.298" cy="51.422" rx="34.355" ry="49.625" />
            <ellipse fill="none" stroke="#808080" stroke-width="0.25" cx="50.522" cy="51.422" rx="14.146" ry="49.625" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.439,1.125H59.28" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.439,0.226H59.28" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.235,2.696V0.002" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.483,2.696V0.002" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.726,2.696V0.002" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M52.992,2.696V0.002" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M33.906,7.861h1.347" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.151,6.289h1.123" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="43.3369" y1="82.4092" x2="57.4834" y2="82.4092">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M43.337,52.32h14.146v60.178H43.337V52.32" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="42.439" y1="82.4102" x2="58.3818" y2="82.4102">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M42.439,81.512h15.943v1.796H42.439V81.512z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="42.439" y1="110.7012" x2="58.3818" y2="110.7012">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#BFBFBF" />
                <stop offset="0.69" style="stop-color:#ACACAC" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M42.439,109.804h15.943v1.796H42.439V109.804z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="43.3516" y1="51.2168" x2="57.4922" y2="51.2168">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M57.492,52.467c0,1.831-3.165,3.314-7.07,3.314c-3.905,0-7.07-1.484-7.07-3.314   c0-1.83,3.166-5.814,7.07-5.814C54.327,46.653,57.492,50.637,57.492,52.467z" />
        </g>
        <g id="Group_Ladder">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,52.32v60.178" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.637,52.32v60.178" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,110.702h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,108.906h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,107.109h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,105.537h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,103.741h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,101.945h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,100.148h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,98.352h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,96.556h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,94.76h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,92.963h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,91.166h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,89.595h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,87.574h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,86.002h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,84.205h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,82.409h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,80.613h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,78.816h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,77.021h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,75.225h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,73.428h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,71.855h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,70.06h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,68.039h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,66.467h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,64.67h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,62.874h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,61.078h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,59.281h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,57.709h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,55.913h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,53.892h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,50.524h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,48.728h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,46.932h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.167,45.359h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.392,43.563h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.392,41.767h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.841,39.971h2.694" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.065,38.174h2.694" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.29,36.378h2.694" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.739,34.582h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.413,32.785h2.694" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.086,30.989h2.245" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.535,29.417h2.47" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.434,27.621h2.021" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.883,25.824h2.245" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.781,24.028h2.021" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.455,22.231H24.7" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M23.577,20.435h2.021" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.475,18.639h2.021" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.823,16.843h1.796" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M27.17,15.046h1.572" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.292,13.25h1.796" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M29.864,11.678h1.572" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M31.661,9.882h1.572" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,52.32h2.695" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.637,51.872v-0.225v-0.225l0.225-5.165l0.449-4.94l0.674-4.715   l1.123-4.491l1.348-4.266l1.572-4.266l2.021-3.817l2.021-3.368l2.246-3.368l2.47-2.695l2.695-2.47l2.694-2.246l2.919-1.572   l3.144-1.347l3.144-0.674l3.144-0.225" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.942,51.872v-0.225v-0.225l0.225-5.165l0.449-4.94l0.898-4.715   l1.123-4.491l1.572-4.266l1.572-4.266l2.021-3.817l2.245-3.368l2.47-3.368l2.694-2.695l2.919-2.47l2.919-2.246l3.144-1.572   l3.368-1.347l3.368-0.674l3.593-0.225" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.14, 0.11458333333333333, 226.0, 0.24, 0.22395833333333334, 197.0, 0.44, 0.5364583333333334, 117.0, 0.72, 0.9479166666666666, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.5052083333333334, 125.0, 0.69, 0.65625, 87.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGEnergy2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 131.25 83.333" enable-background="new 0 0 131.25 83.333" xml:space="preserve">
        <g id="Group_Blades">
            <path fill="#FFFFFF" d="M12.641,19.253l0.882-13.228c0,0,3.57,0.673,5.725,0.397C25.972,5.561,32.776,0,32.776,0l-0.882,21.164   L12.641,19.253z" />
            <path fill="#FFFFFF" d="M22.782,83.333l7.349-34.244l-19.253-5.732L7.938,72.898c0,0,4.018-1.413,6.32-0.735   C20.322,73.948,22.782,83.333,22.782,83.333z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.776,0l-0.882,21.164l-19.253-1.911l0.882-13.228" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.782,83.333l7.349-34.244l-19.253-5.732L7.938,72.898" />
            <path fill="#E5E5E5" d="M22.929,81.422l6.761-32.188l-12.346-1.028c0,0,4.068,2.917,4.924,4.263   c3.233,5.082,1.836,12.492,1.836,12.492L22.929,81.422z" />
            <path fill="#E5E5E5" d="M13.082,19.253c0,0,13.406,0.318,14.844-1.029c2.261-2.117,4.703-17.931,4.703-17.931L31.601,20.87   L13.082,19.253z" />
        </g>
        <g id="Group_Pole">
            <path fill="#FFFFFF" d="M47.474,75.837l0.441-23.81l26.749-0.441l0.44,30.424l-2.792-2.793l-3.675-2.498l-4.997-1.617l-5.144-0.588   h-4.703l-3.38,0.441L47.474,75.837z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M47.474,75.837l0.441-23.81l26.749-0.441l0.293,24.251" />
            <path fill="#E5E5E5" d="M61.729,52.91L50.56,52.028l23.809-0.441l0.295,23.516l-1.176-6.907l-1.471-7.202l-1.322-3.674l-1.91-2.205   l-2.205-1.322L61.729,52.91z" />
        </g>
        <g id="Group_Neck">
            <path fill="#7F7F7F" d="M38.214,38.506l-5.438-0.441l0.882-4.409h5.291L38.214,38.506z" />
            <path fill="#B2B2B2" d="M38.949,32.04l-5.585-0.735l0.294,2.352h5.291V32.04z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M39.978,47.471l-2.792-0.146l1.911-24.985l2.646,0.147L39.978,47.471   z" />
        </g>
        <g id="Group_Center">
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M30.131,50.118c-3.674,0.106-6.173,0.828-10.659-0.295   c-5.572-1.395-8.007-5.438-8.007-5.438S0.03,37.218,0.001,30.129c-0.029-7.348,12.052-9.7,12.052-9.7l0.588-1.176   c0.882-0.908,4.504-1.532,6.173-1.566c2.086-0.042,8.377,0.979,8.377,0.979l-4.997,1.176l10.288-0.735l7.496,0.147l-2.205,30.129   c0,0-2.797,0.139-3.349,0.131c-1.145-0.017-3.412-0.425-3.412-0.425L30.131,50.118z" />
            <path fill="#E5E5E5" d="M29.837,49.823c0,0-5.879,0.617-9.994-0.367c-4.732-1.132-7.79-4.924-7.79-4.924s6.761,2.939,10.288,2.939   c11.071,0,12.199-11.169,12.199-11.169l3.821,0.147L37.36,48.898c0,0-1.208,0.331-3.114,0.204c-1.617-0.107-3.38-0.455-3.38-0.455   L29.837,49.823z" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M0.001,30.864c0,0.397,0.967,0.705,1.323,0.882   c0.801,0.398,2.509,0.971,3.38,1.176c1.695,0.398,6.908,0.881,6.908,0.881" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M25.575,48.5c0,0-2.543-0.154-3.38-0.294   c-1.046-0.175-3.105-0.707-4.115-1.028c-0.677-0.216-1.994-0.747-2.646-1.029c-0.86-0.372-3.38-1.617-3.38-1.617" />
        </g>
        <g id="Group_Wheel">
            <circle fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" cx="22.635" cy="34.392" r="11.611" />
            <circle fill="#B2B2B2" cx="22.635" cy="34.391" r="10.288" />
            <circle fill="#7F7F7F" stroke="#666666" stroke-width="0.25" cx="22.635" cy="34.392" r="9.259" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M29.837,27.043L15.14,41.74" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.923,34.391h-20.87" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M29.837,41.74L15.14,27.043" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.488,44.827V24.104" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.629,31.746l-20.135,5.438" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M31.601,39.682L13.523,29.247" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.281,44.532l-5.438-20.134" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.344,43.503l10.435-18.077" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.281,24.397l-5.438,20.134" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M31.601,29.247L13.523,39.682" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.629,37.184l-20.135-5.438" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M27.779,43.503L17.344,25.426" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M30.866,28.071l-16.608,12.64" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.776,35.861L12.2,33.068" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.808,42.768l-12.64-16.607" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.166,44.679l2.792-20.576" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.776,33.068L12.2,35.714" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M30.719,40.858L14.258,28.071" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M23.811,44.827l-2.646-20.723" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.168,42.622l12.787-16.46" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.604,24.838L18.52,44.091" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.188,30.423l-19.253,7.937" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M32.188,38.506l-19.253-8.083" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.457,44.091L18.52,24.838" />
            <circle fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" cx="22.488" cy="34.391" r="7.642" />
            <path fill="#F2F2F2" d="M30.048,34.373c0,1.718-0.592,3.172-1.54,4.57c-0.026,0.038-5.574-4.86-5.574-4.86l-6.337-4.42   c0,0,2.377-2.952,5.901-2.84C26.666,26.955,30.048,30.203,30.048,34.373z" />
        </g>
        <g id="Group_Antenna">
            <path fill="#999999" stroke="#666666" stroke-width="0.25" d="M101.559,20.576h0.735v-7.349h-0.735V20.576z" />
            <path fill="#999999" stroke="#666666" stroke-width="0.25" d="M121.694,19.694l3.087-4.85l0.293,0.588l-3.086,4.409L121.694,19.694   z" />
            <path fill="#999999" stroke="#666666" stroke-width="0.25" d="M126.544,19.988h-1.176V0.735h-0.295v3.086h-0.293v16.167h-1.471   l-0.146-0.441H99.943v0.882h0.44v-0.441h21.163l-0.146,0.441h5.145V19.988z" />
            <path fill="#999999" stroke="#666666" stroke-width="0.25" d="M101.119,15.579h1.616v-0.588h-1.616V15.579z" />
            <path fill="#999999" stroke="#666666" stroke-width="0.25" d="M124.339,6.173h1.47V5.438h-1.47V6.173z" />
        </g>
        <g id="Group_Body">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M39.096,50.852l1.764-29.247l0.882-1.029h89.064l0.441,0.882   l-3.675,7.349l-1.616,1.176l-1.764,0.146l-3.234,7.349L121.4,38.8l-6.025,10.435L85.246,49.97l-7.202,1.323l-3.38,1.028   l-26.896,0.147l-2.939-1.617H39.096z" />
        </g>
        <g id="Group_InnerFrame">
            <path fill="#FFFFFF" d="M40.272,49.97l1.764-28.365l84.067,0.441l4.703-1.47l0.441,0.882l-4.998,2.058l-12.934,24.25l-27.777,1.176   l-5.584,1.176h-5.438l-14.991,0.44l15.139,1.028H47.768l-1.911-1.616H40.272z" />
            <path fill="#FFFFFF" d="M50.266,32.187h-2.939l-0.735-0.294v-1.616l1.029-0.441h2.792l0.735,0.588v1.47L50.266,32.187z" />
        </g>
        <g id="Group_Layer_7">
            <path fill="#CCCCFF" d="M108.173,43.063l-21.018-1.764v-1.323l-1.322-0.147v-0.882h-3.381v-0.441h-3.969l-0.146,2.646l0.734,0.147   l-0.146,1.029h-0.734l-0.147,1.47l-14.256-0.294l-2.205-4.556l-1.323-1.029l0.294-6.172l1.029-1.911v-1.028l6.614,0.146   l0.146,1.911l10.582,0.734l-0.146,4.556l9.847,0.294l0.588-8.818l1.028-1.029v-1.616l19.107,1.176l0.146,2.204h9.847l-0.44,6.908   h-1.323l-8.671-1.617L108.173,43.063z" />
        </g>
        <g id="Group_Layer_6">
            <path fill="#CCCCCC" d="M49.09,48.207l-0.147-0.882L47.18,45.12l-1.029,1.029l-0.882-0.588l1.47-1.029c0,0,0.083-0.57,0-0.734   c-0.556-1.103-3.968-2.939-3.968-2.939H40.86c0,0-1.134-0.443-0.294-2.499c0.062-0.152-0.588-0.294-0.588-0.294l0.294-6.613   c0,0,1.179-0.441,1.176-2.058c0-0.294,0-1.176,0-1.176l-1.617-0.147l0.294-5.731h0.441v3.233h2.352l0.588,0.588v2.792l11.463,0.882   v2.205h1.029v1.028h1.617v-2.058h0.882v1.911h1.176v-1.47h3.233l-0.441,16.902L49.09,48.207z" />
            <path fill="#BFBFBF" d="M51.148,44.385l-2.205,2.939L47.18,45.12l-1.029,1.029l-0.882-0.588l1.47-1.029c0,0,0.083-0.57,0-0.734   c-0.556-1.103-3.968-2.939-3.968-2.939H40.86c0,0-0.523-0.39-0.588-0.588c-0.15-0.459,0.294-1.911,0.294-1.911l1.323-0.882   l1.029-3.968l0.735-3.086l1.764,0.147l0.294,2.939c0,0,1.512,0.743,2.058,0.735c0.71-0.01,2.023-0.688,2.646-1.029   c0.519-0.285,2.058-1.175,2.058-1.175l0.734-2.058l1.764-0.147v2.205h1.029v1.028h1.617v-2.058h0.882v1.911h1.176v-1.47h3.233   l-1.029,7.642c0,0-5.272,1.782-6.908,2.646C53.941,42.282,51.148,44.385,51.148,44.385z" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M43.358,50.264l2.792-1.47l2.205-2.352" />
        </g>
        <g id="Group_Layer_5">
            <path fill="#999999" d="M48.208,50.411l-0.882-0.588l0.147-1.469h27.778l-0.147,1.616l-0.882,0.588L48.208,50.411z" />
        </g>
        <g id="Group_Layer_4">
            <path fill="#9999FF" d="M62.023,49.823v-2.057l-0.735-0.441v-2.498h0.882v-2.205h0.441l-0.294-6.761h2.205l0.147,6.467h0.294v2.499   l0.294,0.146v2.352l-0.294,0.734v1.911h-1.176l-0.294-1.764l-0.441,1.764L62.023,49.823z" />
            <path fill="#9999FF" d="M69.666,49.823v-2.057l0.735-0.441v-2.498h-0.735v-2.205h-0.588l0.295-6.761h-2.059l-0.146,6.467h-0.294   v2.499l-0.294,0.146v2.352l0.294,0.734v1.911h1.028l0.441-1.764l0.294,1.764L69.666,49.823z" />
            <path fill="#CCCCFF" d="M108.173,42.18l1.91-1.175l0.294-2.792l-1.911-1.176L108.173,42.18z" />
            <path fill="#CCCCFF" d="M110.377,39.388h1.763v0.735h-1.763V39.388z" />
            <path fill="#9999FF" d="M108.173,42.18l1.028-0.587l0.882-0.588l0.146-1.47h-1.91L108.173,42.18z" />
        </g>
        <g id="Group_Layer_3">
            <path fill="#9999FF" d="M119.195,31.746l-0.293,3.527h-0.735h-0.588l-4.409-0.882l-4.262-0.735l0.294-2.646L119.195,31.746z" />
            <path fill="#BFBFF2" d="M91.271,43.503l6.908-5.291l7.495-4.262l7.349-2.792l4.703-1.176l4.556-0.147h3.968l1.617-1.616H121.4   l-4.409,0.294l-4.851,1.176l-8.67,2.939l-6.908,3.674l-5.145,2.939L91.271,43.503z" />
        </g>
        <g id="Group_Layer_2">
            <path fill="#999999" d="M59.231,48.354v-1.617L72.9,46.59l-1.176-3.087l39.977,0.147v2.792l-4.557,0.147l-1.029,0.44v0.735H74.81   v0.588H59.231z" />
        </g>
        <g id="Group_Layer_1">
            <path fill="#E5E5FF" d="M83.482,42.916l0.146-13.962h7.937v14.11L83.482,42.916z" />
            <path fill="#E5E5FF" d="M79.513,41.593l0.146-9.112h1.764v2.646l-0.877-0.006l-0.004,0.594h0.734v3.968h-0.734l-0.147,2.058   L79.513,41.593z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Blades",[0.0, 0.0, 255.0, 1.0, 0.0, 255.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Pole",[0.0, 0.0, 255.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Neck",[0.0, 0.9895833333333334, 0.0, 1.0, 0.609375, 99.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Center",[0.0, 0.0, 255.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Wheel",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.0, 255.0, 4.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.fills,"Group_Antenna",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_InnerFrame",[0.0, 0.0, 255.0, 1.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Layer_7",[0.0, 0.2708333333333333, 185.0]);
		this.fillGradient(this.fills,"Group_Layer_6",[0.0, 0.40625, 151.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Layer_3",[0.0, 0.5364583333333334, 117.0, 1.0, 0.375, 159.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGBoiler12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Pipe">
            <path fill="none" d="M112.5,21.734H92.68v2.928h19.82V21.734" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="101.793" y1="24.6621" x2="101.793" y2="21.7344">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M112.5,21.734H91.086v2.928H112.5V21.734z" />
        </g>
        <g id="Group_Antenna">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="85.2031" y1="29.3921" x2="85.2031" y2="16.5542">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M92.68,16.554H77.728v12.838H92.68V16.554z" />
            <path fill="none" d="M92.68,16.554H79.166l-2.702-3.604v20.045l2.702-3.604H92.68V16.554" />
            <rect x="91.193" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="89.881" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="88.464" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="87.151" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="85.776" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="84.437" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="83.104" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <rect x="81.733" y="16.554" fill="#4D4D4D" width="0.271" height="12.838" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="74.958" y1="32.9956" x2="74.958" y2="12.9502">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M79.166,16.554l-2.702-3.604l-5.714,9.487l5.714,10.558l2.702-3.604V16.554z" />
            <path fill="#CC9933" d="M76.464,15.203H64.042v10.585h12.422V15.203z" />
            <path fill="#FFCC66" d="M76.464,12.95H64.042v2.252h12.422V12.95z" />
            <path fill="#996600" d="M76.464,25.788H64.042v7.208h12.422V25.788z" />
        </g>
        <g id="Group_IndicatorSwitch">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="48.5361" y1="2.0552" x2="50.7881" y2="2.0552">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M48.536,0h2.252v4.11h-2.252V0z" />
        </g>
        <g id="Group_Indicator">
            <path fill="none" d="M48.536,0h2.252v4.11h-2.252V0" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="47.8604" y1="4.2388" x2="51.4639" y2="4.2388">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M47.86,3.604h3.604v1.271H47.86V3.604z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="46.2837" y1="5.3081" x2="53.2656" y2="5.3081">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M46.284,4.617h6.982v1.382h-6.982V4.617z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="44.9326" y1="6.8296" x2="54.3921" y2="6.8296">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M44.933,5.825h9.459v2.01h-9.459V5.825z" />
        </g>
        <g id="Group_ControlBody">
            <path fill="#5C5C5C" d="M20.237,112.5V6.869h45.191V112.5H20.237z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="0.0781" y1="59.6846" x2="21.0586" y2="59.6846">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M0.078,112.5V6.869h20.98V112.5H0.078z" />
            <path fill="none" d="M0.113,112.5V6.869h65.315V112.5H0.113" />
            <path fill="#E5E5E5" d="M0,44.482h2.928v30.405H0V44.482z" />
        </g>
        <g id="Group_ControlScrew">
            <path fill="#191919" d="M65.428,66.104h-6.531l-1.126-1.126v-5.856l1.126-1.125h6.531V66.104z" />
            <circle fill="#CCCCCC" cx="61.599" cy="62.05" r="3.354" />
            <path fill="#7F7F7F" d="M63.752,64.681l-4.784-4.784l0.638-0.542l4.625,4.847L63.752,64.681z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Antenna",[0.0, 0.5989583333333334, 0.0, 1.0, 0.5989583333333334, 0.0, 2.0, 0.5989583333333334, 0.0, 3.0, 0.5989583333333334, 0.0, 4.0, 0.5989583333333334, 0.0, 5.0, 0.5989583333333334, 0.0, 6.0, 0.5989583333333334, 0.0, 7.0, 0.5989583333333334, 0.0, 8.0, 0.9375, 15.0, 9.0, 0.5364583333333334, 117.0, 10.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_ControlBody",[0.0, 0.7135416666666666, 0.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_ControlScrew",[0.0, 0.19270833333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGHeating9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 65.649" enable-background="new 0 0 112.5 65.649" xml:space="preserve">
        <g id="Group_Heat_Exchanger">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.2505" y1="56.1899" x2="56.2505" y2="9.3467">
                <stop offset="0" style="stop-color:#527D7D" />
                <stop offset="0.5" style="stop-color:#CCD9D9" />
                <stop offset="1" style="stop-color:#527D7D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" d="M9.35,9.346l-9.347,4.729v37.498L9.35,56.19h93.688   l9.459-4.616V14.076l-9.459-4.729H9.35z" />
        </g>
        <g id="Group_Tuner">
            <path fill="#144F4F" d="M8.111,8.22h7.094v2.365H8.111V8.22z" />
            <path fill="#144F4F" d="M8.111,17.566h7.094v2.365H8.111V17.566z" />
            <path fill="#144F4F" d="M8.111,12.837h7.094v2.365H8.111V12.837z" />
            <path fill="#144F4F" d="M8.111,26.913h7.094v2.365H8.111V26.913z" />
            <path fill="#144F4F" d="M8.111,22.296h7.094v2.365H8.111V22.296z" />
            <path fill="#144F4F" d="M8.111,36.372h7.094v2.252H8.111V36.372z" />
            <path fill="#144F4F" d="M8.111,31.643h7.094v2.365H8.111V31.643z" />
            <path fill="#144F4F" d="M8.111,45.718h7.094v2.364H8.111V45.718z" />
            <path fill="#144F4F" d="M8.111,40.989h7.094v2.365H8.111V40.989z" />
            <path fill="#144F4F" d="M8.111,55.065h7.094v2.364H8.111V55.065z" />
            <path fill="#144F4F" d="M8.111,50.334h7.094V52.7H8.111V50.334z" />
            <path fill="#144F4F" d="M97.183,8.22h7.095v2.365h-7.095V8.22z" />
            <path fill="#144F4F" d="M97.183,17.566h7.095v2.365h-7.095V17.566z" />
            <path fill="#144F4F" d="M97.183,12.837h7.095v2.365h-7.095V12.837z" />
            <path fill="#144F4F" d="M97.183,26.913h7.095v2.365h-7.095V26.913z" />
            <path fill="#144F4F" d="M97.183,22.296h7.095v2.365h-7.095V22.296z" />
            <path fill="#144F4F" d="M97.183,36.372h7.095v2.252h-7.095V36.372z" />
            <path fill="#144F4F" d="M97.183,31.643h7.095v2.365h-7.095V31.643z" />
            <path fill="#144F4F" d="M97.183,45.718h7.095v2.364h-7.095V45.718z" />
            <path fill="#144F4F" d="M97.183,40.989h7.095v2.365h-7.095V40.989z" />
            <path fill="#144F4F" d="M97.183,55.065h7.095v2.364h-7.095V55.065z" />
            <path fill="#144F4F" d="M97.183,50.334h7.095V52.7h-7.095V50.334z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="100.7295" y1="58.5552" x2="100.7295" y2="6.9819">
                <stop offset="0" style="stop-color:#527D7D" />
                <stop offset="0.5" style="stop-color:#CCD9D9" />
                <stop offset="1" style="stop-color:#527D7D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M98.421,6.981v51.574h4.617V6.981H98.421z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="11.6577" y1="58.5552" x2="11.6577" y2="6.9819">
                <stop offset="0" style="stop-color:#527D7D" />
                <stop offset="0.5" style="stop-color:#CCD9D9" />
                <stop offset="1" style="stop-color:#527D7D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M9.35,6.981v51.574h4.617V6.981H9.35z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.714,6.981v51.574" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M100.785,6.981v51.574" />
        </g>
        <g id="Group_Support">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="18.6958" y1="64.4106" x2="28.4927" y2="64.4106">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M18.696,65.649v-2.477h9.797v2.477H18.696z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="19.8218" y1="59.6812" x2="27.3662" y2="59.6812">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M19.822,63.172V56.19h7.544v6.982H19.822" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="83.8945" y1="64.4106" x2="93.6914" y2="64.4106">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M83.895,65.649v-2.477h9.797v2.477H83.895" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="85.0215" y1="59.6812" x2="92.5654" y2="59.6812">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M85.021,63.172V56.19h7.544v6.982H85.021" />
        </g>
        <g id="Group_Bent_Tube">
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="44.0747" y1="48.7671" x2="52.1392" y2="48.7671" gradientTransform="matrix(1 0 0 -1 0 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="44.075" y="2.104" fill="url(#SVGID_8_)" width="8.064" height="29.556" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="36.4902" y1="25.8315" x2="36.4902" y2="33.1914" gradientTransform="matrix(1 0 0 -1 0 65.6494)">
                <stop offset="0" style="stop-color:#646566" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="0.84" style="stop-color:#909091" />
                <stop offset="0.99" style="stop-color:#646566" />
            </linearGradient>
            <rect x="29.628" y="32.458" fill="url(#SVGID_9_)" width="13.724" height="7.36" />
            
                <radialGradient id="SVGID_10_" cx="143.9922" cy="284.7876" r="3.1702" gradientTransform="matrix(2.9866 -0.2001 -0.2179 -2.719 -324.8664 834.788)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M42.624,39.816c4.636,0,9.509-2.91,9.509-8.671l-8.061-0.021c-0.168,1.014-0.628,1.259-1.423,1.324   C42.555,34.376,42.624,39.816,42.624,39.816z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="43.7412" y1="16.0376" x2="43.7412" y2="23.397" gradientTransform="matrix(1 0 0 -1 0 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect x="29.462" y="42.252" fill="url(#SVGID_11_)" width="28.558" height="7.359" />
            
                <radialGradient id="SVGID_12_" cx="157.1631" cy="280.2192" r="3.1701" gradientTransform="matrix(-2.9866 -0.2001 0.2179 -2.719 438.2648 834.788)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" d="M30.443,49.606c-4.636,0-9.509-2.91-9.509-8.674l8.061-0.021c0.168,1.014,0.628,1.26,1.423,1.324   C30.511,44.167,30.443,49.606,30.443,49.606z" />
            
                <radialGradient id="SVGID_13_" cx="144.9619" cy="274.353" r="3.1701" gradientTransform="matrix(-2.9866 0.2001 0.2179 2.719 403.0988 -734.326)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" d="M30.441,32.464c-4.636,0-9.509,2.91-9.509,8.674l8.062,0.021c0.168-1.013,0.627-1.259,1.422-1.323   C30.51,37.904,30.441,32.464,30.441,32.464z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="43.0752" y1="48.6362" x2="51.1396" y2="48.6362" gradientTransform="matrix(-1 0 0 -1 112.1201 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="60.98" y="2.079" fill="url(#SVGID_14_)" width="8.064" height="29.869" />
            
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="35.4912" y1="25.856" x2="35.4912" y2="33.2168" gradientTransform="matrix(-1 0 0 -1 112.1201 65.6494)">
                <stop offset="0" style="stop-color:#646566" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="0.84" style="stop-color:#909091" />
                <stop offset="0.99" style="stop-color:#646566" />
            </linearGradient>
            <rect x="69.768" y="32.433" fill="url(#SVGID_15_)" width="13.723" height="7.361" />
            
                <radialGradient id="SVGID_16_" cx="143.6611" cy="284.8325" r="3.1701" gradientTransform="matrix(-2.9866 -0.2001 0.2179 -2.719 436.9855 834.788)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" d="M70.496,39.762c-4.637,0-9.51-2.91-9.51-8.672l8.061-0.021c0.168,1.014,0.629,1.258,1.424,1.325   C70.564,34.323,70.496,39.762,70.496,39.762z" />
            
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="42.3262" y1="16.0239" x2="42.3262" y2="23.3833" gradientTransform="matrix(-1 0 0 -1 112.1201 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect x="55.932" y="42.266" fill="url(#SVGID_17_)" width="27.725" height="7.359" />
            
                <radialGradient id="SVGID_18_" cx="157.4971" cy="280.2046" r="3.1691" gradientTransform="matrix(2.9866 -0.2001 -0.2179 -2.719 -326.1437 834.788)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" d="M82.676,49.581c4.636,0,9.509-2.91,9.509-8.674l-8.061-0.021c-0.168,1.014-0.628,1.26-1.423,1.324   C82.607,44.141,82.676,49.581,82.676,49.581z" />
            
                <radialGradient id="SVGID_19_" cx="145.293" cy="274.3198" r="3.1701" gradientTransform="matrix(2.9866 0.2001 -0.2179 2.719 -290.9797 -734.326)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.1" style="stop-color:#666666" />
                <stop offset="0.22" style="stop-color:#888888" />
                <stop offset="0.4" style="stop-color:#BEBEBE" />
                <stop offset="0.52" style="stop-color:#E0E0E0" />
                <stop offset="0.64" style="stop-color:#C3C3C3" />
                <stop offset="0.89" style="stop-color:#787878" />
                <stop offset="1" style="stop-color:#545454" />
            </radialGradient>
            <path fill="url(#SVGID_19_)" d="M82.678,32.439c4.636,0,9.509,2.911,9.509,8.674l-8.062,0.021c-0.168-1.014-0.627-1.26-1.422-1.324   C82.609,37.879,82.678,32.439,82.678,32.439z" />
            
                <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="43.1743" y1="64.3716" x2="53.0166" y2="64.3716" gradientTransform="matrix(1 0 0 -1 0 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="43.174" fill="url(#SVGID_20_)" width="9.842" height="2.556" />
            
                <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="60.041" y1="64.3716" x2="69.8828" y2="64.3716" gradientTransform="matrix(1 0 0 -1 0 65.6494)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="60.041" fill="url(#SVGID_21_)" width="9.842" height="2.556" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8541666666666666, 0.0, 0.5, 0.34375, 167.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8541666666666666, 0.0, 0.5, 0.34375, 167.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8541666666666666, 0.0, 0.5, 0.34375, 167.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7864583333333334, 0.0, 0.51, 0.25, 191.0, 0.84, 0.875, 31.0, 0.99, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.5, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7864583333333334, 0.0, 0.51, 0.25, 191.0, 0.84, 0.875, 31.0, 0.99, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);

        super.drawObject();
    }
}
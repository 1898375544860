import { SVGObject } from "../svgobject";

export class SVGHvac2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 107.992" enable-background="new 0 0 112.5 107.992" xml:space="preserve">
        <g id="Group_Industrial_Heater">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="78.5205" y1="120.3577" x2="38.3689" y2="10.042">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="0.5" style="stop-color:#BEBEBE" />
                <stop offset="0.6" style="stop-color:#CACACA" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M4.393,22.409h108.105v85.583H4.393V22.409z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M4.393,22.409h108.105v85.583H4.393V22.409" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M76.463,22.409v58.557" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M40.428,22.409v58.557" />
        </g>
        <g id="Group_Mesh">
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M4.393,105.74h108.105" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M4.393,80.966h108.105" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.2" d="M4.393,83.219h108.105" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,85.471h31.53v18.018h-31.53V85.471z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,85.471h31.53v18.018h-31.53V85.471z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,85.471h31.531v18.018H6.646V85.471z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,94.48l9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M87.725,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M96.733,85.471l13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M105.742,85.471l4.504,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M80.968,85.471l17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,92.228l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,101.237l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M89.977,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M98.873,85.471l11.373,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M107.994,85.471l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M83.221,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,89.975l13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,98.871l4.505,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M92.229,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M101.238,85.471l9.008,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M85.473,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,87.723l15.765,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,96.732l6.757,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M94.48,85.471l15.766,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M103.49,85.471l6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,94.48l-9.008,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M101.238,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M92.229,85.471l-13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M83.221,85.471l-4.505,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M107.994,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,92.228l-11.373,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,101.237l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M98.873,85.471l-17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M89.977,85.471L78.716,96.732" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M80.968,85.471l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M105.742,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,89.975l-13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,98.871l-4.504,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M96.733,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M87.725,85.471l-9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M103.49,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,87.723L94.48,103.489" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M110.246,96.732l-6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M94.48,85.471l-15.765,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M85.473,85.471l-6.757,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,94.48l9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M51.689,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M60.698,85.471l13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M69.707,85.471l4.504,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M44.933,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,92.228l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,101.237l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M53.941,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M62.95,85.471l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M71.959,85.471l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M47.185,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,89.975l13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,98.871l4.504,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M56.194,85.471l18.017,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M65.203,85.471l9.008,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M49.437,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,87.723l15.765,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M42.681,96.732l6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M58.445,85.471l15.766,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M67.455,85.471l6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,85.471l-18.017,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,94.48l-9.008,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M65.203,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M56.194,85.471l-13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M47.185,85.471l-4.504,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M71.959,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,92.228L62.95,103.489" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,101.237l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M62.95,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M53.941,85.471L42.681,96.732" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M44.933,85.471l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M69.707,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,89.975l-13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,98.871l-4.504,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M60.698,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M51.689,85.471l-9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M67.455,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,87.723l-15.766,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M74.211,96.732l-6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M58.445,85.471l-15.765,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M49.437,85.471l-6.756,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,94.48l9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M15.654,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M24.663,85.471l13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M33.672,85.471l4.504,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M8.897,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,92.228l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,101.237l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M17.906,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M26.915,85.471l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M35.924,85.471l2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M11.262,85.471l17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,89.975l13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,98.871l4.617,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M20.159,85.471l18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M29.167,85.471l9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M13.515,85.471l17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,87.723l15.765,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M6.646,96.732l6.869,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M22.411,85.471l15.766,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M31.419,85.471l6.757,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,94.48l-9.009,9.009" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M29.167,85.471l-17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M20.159,85.471l-13.513,13.4" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M11.262,85.471l-4.617,4.504" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M35.924,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,92.228l-11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,101.237l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M26.915,85.471L8.897,103.489" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M17.906,85.471L6.646,96.732" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M8.897,85.471l-2.252,2.252" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M33.672,85.471l-18.018,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,89.975l-13.513,13.514" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,98.871l-4.504,4.617" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M24.663,85.471L6.646,103.489" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M15.654,85.471L6.646,94.48" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M31.419,85.471l-17.905,18.018" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,87.723l-15.766,15.766" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,96.732l-6.757,6.757" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M22.411,85.471L6.646,101.237" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M13.515,85.471l-6.869,6.757" />
        </g>
        <g id="Group_Inlet">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.2" d="M0.001,85.471h4.392v15.766H0.001V85.471z" />
        </g>
        <g id="Group_Top_Shield">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.2" d="M8.897,17.905h99.097v4.505H8.897V17.905z" />
        </g>
        <g id="Group_Exhausts">
            <path fill="#7F7F7F" d="M22.411,13.513l2.252,2.14h11.261v-2.14H22.411z" />
            <path fill="#7F7F7F" d="M40.428,13.513l2.252,2.14h11.261v-2.14H40.428z" />
            <path fill="#7F7F7F" d="M94.48,13.513l-2.252,2.14H80.968v-2.14H94.48z" />
            <path fill="#7F7F7F" d="M76.463,13.513l-2.252,2.14H62.95v-2.14H76.463z" />
            <path fill="#B2B2B2" d="M78.716,17.905V2.14L80.968,0H94.48v13.513l-2.252,2.14v2.252H78.716z" />
            <path fill="#B2B2B2" d="M60.698,17.905V2.14L62.95,0h13.513v13.513l-2.252,2.14v2.252H60.698z" />
            <path fill="#B2B2B2" d="M56.194,17.905V2.14L53.941,0H40.428v13.513l2.252,2.14v2.252H56.194z" />
            <path fill="#B2B2B2" d="M38.176,17.905V2.14L35.924,0H22.411v13.513l2.252,2.14v2.252H38.176z" />
            <path fill="#4C4C4C" d="M94.48,0H80.968v13.513H94.48V0z" />
            <path fill="#4C4C4C" d="M76.463,0H62.95v13.513h13.513V0z" />
            <path fill="#4C4C4C" d="M40.428,0h13.513v13.513H40.428V0z" />
            <path fill="#4C4C4C" d="M22.411,0h13.513v13.513H22.411V0z" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M22.411,11.261h13.513" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M35.924,8.221H22.411" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M35.924,5.18H22.411" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M35.924,2.14H22.411" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M40.428,11.261h13.513" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M53.941,8.221H40.428" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M53.941,5.18H40.428" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M53.941,2.14H40.428" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M94.48,11.261H80.968" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M80.968,8.221H94.48" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M80.968,5.18H94.48" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M80.968,2.14H94.48" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M76.463,11.261H62.95" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M62.95,8.221h13.513" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M62.95,5.18h13.513" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.2" d="M62.95,2.14h13.513" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M78.716,17.905V2.14L80.968,0H94.48v13.513l-2.252,2.14v2.252H78.716" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M60.698,17.905V2.14L62.95,0h13.513v13.513l-2.252,2.14v2.252H60.698" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M38.176,17.905V2.14L35.924,0H22.411v13.513l2.252,2.14v2.252H38.176" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2" d="M56.194,17.905V2.14L53.941,0H40.428v13.513l2.252,2.14v2.252H56.194" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5208333333333334, 121.0, 0.5, 0.515625, 123.0, 0.6, 0.421875, 147.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Mesh",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Inlet",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Top_Shield",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Exhausts",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.609375, 99.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.5885416666666666, 0.0, 9.0, 0.5885416666666666, 0.0, 10.0, 0.5885416666666666, 0.0, 11.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
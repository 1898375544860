

import {ref} from "vue"
import AuthModule from '../store/auth.module'
import TagsService from '@/services/tags.service';
import eventsdatarequest from '../model/requests/eventsdatarequest'
import { format } from 'date-fns'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import exportFile from 'quasar/src/utils/export-file.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useI18n } from 'vue-i18n';
import screensModule from "@/store/screens.module";
  
  
  export default {
    props:{
      tagname:{
        type: String
      },
     
      description:{
        type:String
      },
      useevents:{
        type:Boolean
      },
    },
  
    setup(props) {
      const {t} = useI18n({useScope:'global'})
      const q = useQuasar()
      let eventsdata = ref([])
      let begindate = ref(1619066131947)
      let maximizedToggle=ref(false)
      let events = ref(false)
      
     
      return {
        t,
        q,
       columns:[

{ name: 'time', align: 'center', label:t('events.time'), field: 'time', sortable: true },
{ name: 'type', align: 'center',label: t('events.type'), field: 'type', sortable: true },
{ name: 'priority',align: 'center', label: t('events.priority'), field: 'priority' },
{ name: 'message',align: 'center', label: t('events.message'), field: 'message' },
{ name: 'value',align: 'center', label: t('events.value'), field: 'value' },
],
        eventsdata,
        begindate,
        maximizedToggle,
        events,
        model: ref({label:t('events.hour1'), value:60}),
        filterpriority:ref({label:t('tags.all'),value:{from:0, to:1000}}),
        typeoptions:[
            {label:t('tags.all'),value:{from:0, to:1000}},
            {label:t('events.alarm'),value:{from:0, to:100}},
            {label:t('events.warning'),value:{from:100, to:500}},
            {label:t('events.normal'),value:{from:500, to:1000}}
        ],
        options: [
          {label:t('events.hour1'), value:60},
          {label:t('events.hours2'), value:120},
          {label:t('events.hours3'), value:180},
          {label:t('events.hours6'), value:360},
          {label:t('events.hours12'), value:720},
          {label:t('events.day1'), value:1440},
          {label:t('events.days3'), value:4320},
          {label:t('events.days7'), value:10080},
          {label:t('events.days14'), value:20160},
          {label:t('events.days30'), value:43200}
        ]
        //history2
          }
          
        },
  methods:{
    wrapCsvValue (val:string) {
              let formatted =  val
  
              formatted = formatted === void 0 || formatted === null
                      ? '': String(formatted)
              formatted = formatted.split('"').join('""')
              return `"${formatted}"`
        },
  exportTable () {
    let content=this.wrapCsvValue(this.t('tags.dateandtime')).concat(',');
    content = content.concat(this.wrapCsvValue(this.t('events.type'))).concat(',') 
    content = content.concat(this.wrapCsvValue(this.t('events.priority'))).concat(',') 
    content = content.concat(this.wrapCsvValue(this.t('events.message'))).concat(',') 
    content = content.concat(this.wrapCsvValue(this.t('events.value'))).concat(',') 
    content = content.replace(/.$/,"\r\n")
    if (this.eventsdata){
             
             this.eventsdata.forEach((row)=>{
               content = content.concat(this.wrapCsvValue(row.time)).concat(',')
               content = content.concat(this.wrapCsvValue(row.type)).concat(',')
               content = content.concat(row.priority).concat(',')
               content = content.concat(this.wrapCsvValue(row.message)).concat(',')
               content = content.concat(row.value).concat(',')
               content = content.replace(/.$/,"\r\n")
           })
         }
            
            const status = exportFile(
            'Events_data('+format(this.begindate, "yyyy-MM-dd HH:mm - ").toString()+format(Date.now(), "yyyy-MM-dd HH:mm").toString()+').csv',
            content,
            'text/csv'
          )
         
          if (status !== true) {
            this.q.notify({
              message: this.t('tags.browserdenied'),
              color: 'negative',
              icon: 'warning'
            })
          }
          else{
               this.q.notify({
              message: this.t('tags.browserdownloaded'),
              color: 'positive',
              icon: 'warning'
            })
         }
        },
  exportTablePDF(){
          const doc = new jsPDF()
     
          var xOffset = doc.internal.pageSize.width / 2
          doc.text('Events data('+format(this.begindate, "yyyy-MM-dd HH:mm - ").toString()+format(Date.now(), "yyyy-MM-dd HH:mm").toString()+')',
              xOffset, 8, {align: 'center'});
          let pdfrows = [];
          let headers = [];
        headers=['Date and Time','Type','Priority','Message','Value']

            if (this.eventsdata){
             
              this.eventsdata.forEach((row)=>{
                let pdfrow = []
                pdfrow.push(row.time)
                pdfrow.push(row.type)
                pdfrow.push(row.priority)
                pdfrow.push(row.message)
                pdfrow.push(row.value)
              pdfrows.push(pdfrow)
            })
          }
         
      
          autoTable(doc, {
                    head:[headers],
                    body: pdfrows,
              })
            doc.save('Events_data('+format(this.begindate, "yyyy-MM-dd HH-mm -- ").toString()+format(Date.now(), "yyyy-MM-dd HH-mm").toString()+').pdf')
        },
    filterData(rows:[]) {
     
    if (this.filterpriority.label===this.t('tags.all')) return rows;
      return rows.filter(row =>
          row['priority']>this.filterpriority.value.from && row['priority']<=this.filterpriority.value.to
           )
      //return rows
    },
    updateTimeSelection(value){
        console.log(value)
        this.getEventsData(value.value)
    },
    onBeforeShow(){
        console.log("onBeforeshow")
        this.maximizedToggle =  localStorage.getItem('maximizedToggle')=='true'
        this.model ={label:this.t('events.hour1'), value:60}
        this.getEventsData(60)
        
    },
    onBeforeHide(){
      this.history = false;
      this.$emit('update:Value', false)
        console.log("onBeforeHide")
        localStorage.setItem('maximizedToggle', this.maximizedToggle)
    },
    getEventsData(deltatime:number){
    
    
      if (AuthModule.currentUser!=null){
          let enddate = Date.now();
          this.begindate = enddate - deltatime*60*1000;//delta*minutes
         let request:eventsdatarequest = {token:AuthModule.currentUser.accessToken, 
            name:this.tagname, begindate:this.begindate, enddate:enddate, beginpriority:0,
          endpriority:1000, onlyactivestate:false, unack:false, id:-1};
          TagsService.geteventsdata(request,(data:string)=>{
          console.log(data);
               
          this.eventsdata =JSON.parse(data);
          this.eventsdata.forEach(event => {
            event.time = new Date(event.time).toLocaleString();
          });
     
        }, 
          (data:string)=>{
              console.log(data);
           })
    }
  },
  }
    
    
  }  
  
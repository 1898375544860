import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import tagsModele from "@/store/tags.modele";
import { Checkbox } from './checkbox';
import { Value } from '../value';

export class MenuCheckList extends Checkbox{
   
    animation:boolean
    expandedtype:number

    flagclick:boolean
  
    public drawObject():void{
		super.initObject();

    if(this.expandedtype != 0) this.expandedtype = 1
    this.ranges.forEach(range => {
        this.tagnames.push(range.path)
    });
   
		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)

		let fillcolor = '0xffffff00';
        
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, color2)
              gradient.addColorStop(1, ColorUtils.darkColor(color2,0.5))

              const gradient1 = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient1.addColorStop(0, fillcolor2)
              gradient1.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

          const rect1 = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10,
            stroke: this.type3d==0? gradient : color2,
            strokeWidth: this.linewidth==0? this.height/20 : this.linewidth
          });
              if(this.type3d==0){
                rect1.fillLinearGradientStartPoint({ x: 0, y: 0});
                rect1.fillLinearGradientEndPoint({ x: 0, y: this.height});
                rect1.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                rect1.fill(fillcolor2)
              }
          this.node.add(rect1)

          const rect2 = new Konva.Rect({
            x: this.width/20,
            y: 0,
            width: this.width*18/20,
            height: this.height,
            cornerRadius: this.height/10,
            stroke: this.type3d==0? gradient : color2,
            strokeWidth: this.linewidth==0? this.height/20 : this.linewidth
          });
         if(this.type3d==0){
                rect2.fillLinearGradientStartPoint({ x: 0, y: 0});
                rect2.fillLinearGradientEndPoint({ x: 0, y: this.height});
                rect2.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            rect2.fill(fillcolor2)
          }
          this.node.add(rect2)


  
          const  node1 = new Konva.Group();
          const height = this.height
          const width = this.width
          const linewidth = this.linewidth==0? this.height/20 : this.linewidth    
          const ranges = this.ranges
          this.node.add(node1)
          const type3d = this.type3d
          const expandedtype = this.expandedtype
          const layer = this.layer
          const animation = this.animation
          let flagclick = this.flagclick
         
        
            this.node.on('click', function(){
            flagclick = !flagclick;
                if (flagclick){
                    if(expandedtype==0){
                        drawMenuVertical(node1,ranges, height, width, type3d, animation, layer, linewidth)
                    }else{
                        drawMenuHorizontal(node1,ranges, height, width, type3d, animation, layer, linewidth)
                    }
              }else{   
                node1.removeChildren()
              }
        })
      

        function drawMenuVertical(node:Konva.Group,ranges, height, width, type3d, animation, layer, linewidth){
            if(ranges.length != 0){
            ranges.forEach((textview)=>{
              const node2 = new Konva.Group()
              const tag =tagsModele.getTag(textview.path);
    
              const rectMenu = new Konva.Rect({
                x: 0,
                y: animation? 0:(height+height/4)*(ranges.indexOf(textview)+1),
                width: width,
                height: height,
                cornerRadius: height/10,
                strokeWidth: linewidth==0? height/20 : linewidth,
                stroke: type3d==0? ((+tag.tagvalue==textview.value)? gradient1 : gradient):((+tag.tagvalue==textview.value)? fillcolor2: color2)
              });
              if(type3d==0){
                if(+tag.tagvalue==textview.value){
                    rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
                    rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
                    rectMenu.fillLinearGradientColorStops([0, color2,
                        0.5, ColorUtils.darkColor(color2, 0.85),
                        1, ColorUtils.darkColor(color2, 0.75)])
                }else{
                    rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
                    rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
                    rectMenu.fillLinearGradientColorStops([0, fillcolor2,
                        0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                        1, ColorUtils.darkColor(fillcolor2, 0.75)])
                }
          }else{
            if(+tag.tagvalue==textview.value){
              rectMenu.fill(color2)
            }else{
              rectMenu.fill(fillcolor2)
            }
          }
              node2.add(rectMenu)

              const rectMenuInner = new Konva.Rect({
                x: width/20,
                y: animation? 0:(height+height/4)*(ranges.indexOf(textview)+1),
                width: width*18/20,
                height: height,
                cornerRadius: height/10,
                strokeWidth: linewidth==0? height/20 : linewidth,
                stroke: type3d==0? ((+tag.tagvalue==textview.value)? gradient1 : gradient):((+tag.tagvalue==textview.value)? fillcolor2: color2)
              });
              if(type3d==0){
                if(+tag.tagvalue==textview.value){
                    rectMenuInner.fillLinearGradientStartPoint({ x: 0, y: 0});
                    rectMenuInner.fillLinearGradientEndPoint({ x: 0, y: height});
                    rectMenuInner.fillLinearGradientColorStops([0, color2,
                        0.5, ColorUtils.darkColor(color2, 0.85),
                        1, ColorUtils.darkColor(color2, 0.75)])
                }else{
                    rectMenuInner.fillLinearGradientStartPoint({ x: 0, y: 0});
                    rectMenuInner.fillLinearGradientEndPoint({ x: 0, y: height});
                    rectMenuInner.fillLinearGradientColorStops([0, fillcolor2,
                        0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                        1, ColorUtils.darkColor(fillcolor2, 0.75)])
                }
          }else{
            if(+tag.tagvalue==textview.value){
              rectMenuInner.fill(color2)
            }else{
              rectMenuInner.fill(fillcolor2)
            }
          }
              node2.add(rectMenuInner)

        const textMenu = new Konva.Text({
            x: width/20,
            y: animation?0:(height+height/4)*(ranges.indexOf(textview)+1),
            width: width*18/20,
            height: height,
            text: textview.text,
            fontSize: height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2,
            })
            if(type3d==0){       
              textMenu.shadowColor('black'),
              textMenu.shadowBlur(0),
              textMenu.shadowOffset({ x: 0.5, y: 0.5 })
            }
            if (+tag.tagvalue==textview.value){
                textMenu.fill(fillcolor2)     
            }else{
                textMenu.fill(color2)
            }
            
            node2.on('click touchend', function(){
                if (+tag.tagvalue==textview.value){
                       tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.uncheckvalue.toString(), datetime:0});
                }else{
                   tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.value.toString(), datetime:0});
                }
               })  

          node2.add(textMenu)
          node.add(node2)

          const dist = (height+height/4)*(ranges.indexOf(textview)+1);
          const animTime = 500
        
            if(animation){
            const anim = new Konva.Animation(function (frame) {
                    node2.y(dist * frame.time/animTime);
                    //rectMenu.y(dist * frame.time/animTime);
                    if (node2.attrs.y>=dist){
                    anim.stop()
                    node2.attrs.y=dist
                    //rectMenu.attrs.y = dist
                    }
            }, layer);
            anim.start();
            }
        })
      }
    }
          
        function drawMenuHorizontal( node:Konva.Group,ranges, height, width, type3d, animation, layer, linewidth){
       
       
        if(ranges.length != 0){
            ranges.forEach((textview)=>{
            const tag =tagsModele.getTag(textview.path);
            const node2 = new Konva.Group()

      const rectMenu = new Konva.Rect({
        x: animation?0:(width+width/8)*(ranges.indexOf(textview)+1),
        y: 0,
        width: width,
        height: height,
        cornerRadius: height/10,
        strokeWidth: linewidth==0? height/20 : linewidth,
        stroke: type3d==0? (+tag.tagvalue==textview.value? gradient1 : gradient):(+tag.tagvalue==textview.value? fillcolor2: color2)
      });
      if(type3d==0){
            if(+tag.tagvalue==textview.value){
                rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
                rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
                rectMenu.fillLinearGradientColorStops([0, color2,
                    0.5, ColorUtils.darkColor(color2, 0.85),
                    1, ColorUtils.darkColor(color2, 0.75)])
            }else{
                rectMenu.fillLinearGradientStartPoint({ x: 0, y: 0});
                rectMenu.fillLinearGradientEndPoint({ x: 0, y: height});
                rectMenu.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }
      }else{
        if(+tag.tagvalue==textview.value){
          rectMenu.fill(color2)
        }else{
          rectMenu.fill(fillcolor2)
        }
      }
      node2.add(rectMenu)

      const rectMenuInner = new Konva.Rect({
        x: animation? width/20:(width/20+width+width/8)*(ranges.indexOf(textview)+1),
        y: 0,
        width: width*18/20,
        height: height,
        cornerRadius: height/10,
        strokeWidth: linewidth==0? height/20 : linewidth,
        stroke: type3d==0? (+tag.tagvalue ==textview.value? gradient1 : gradient):(+tag.tagvalue==textview.value? fillcolor2: color2)
      });
      if(type3d==0){
            if(+tag.tagvalue==textview.value){
                rectMenuInner.fillLinearGradientStartPoint({ x: 0, y: 0});
                rectMenuInner.fillLinearGradientEndPoint({ x: 0, y: height});
                rectMenuInner.fillLinearGradientColorStops([0, color2,
                    0.5, ColorUtils.darkColor(color2, 0.85),
                    1, ColorUtils.darkColor(color2, 0.75)])
            }else{
                rectMenuInner.fillLinearGradientStartPoint({ x: 0, y: 0});
                rectMenuInner.fillLinearGradientEndPoint({ x: 0, y: height});
                rectMenuInner.fillLinearGradientColorStops([0, fillcolor2,
                    0.5, ColorUtils.darkColor(fillcolor2, 0.85),
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }
      }else{
        if(+tag.tagvalue==textview.value){
          rectMenuInner.fill(color2)
        }else{
          rectMenuInner.fill(fillcolor2)
        }
      }
      node2.add(rectMenuInner)
      
    const textMenu = new Konva.Text({
        x: animation?width/20:(width/20+width+width/8)*(ranges.indexOf(textview)+1),
        y: linewidth,
        width: width*18/20,
        height: height-2*linewidth,
        text: textview.text,
        fontSize: height/2,
        fontFamily: "Roboto",
        fontStyle: "normal",
        align: 'center',
        verticalAlign:"middle",
        fill: +tag.tagvalue==textview.value? fillcolor2 : color2
        })
            if(type3d==0){       
              textMenu.shadowColor('black'),
              textMenu.shadowBlur(0),
              textMenu.shadowOffset({ x: 0.5, y: 0.5 })
            }
            if (+tag.tagvalue==textview.value){
                textMenu.fill(fillcolor2)     
            }else{
                textMenu.fill(color2)
            }
           
            node2.add(textMenu)

            node2.on('click touchend', function(){
            if (+tag.tagvalue==textview.value){
                   tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.uncheckvalue.toString(), datetime:0});
            }
               else{
               tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.value.toString(), datetime:0});
               }
           })
        
           node.add(node2)
   
    const dist = (width+width/8)*(ranges.indexOf(textview)+1);
    const animTime = 500
    if(animation){ 
    const anim = new Konva.Animation(function (frame) {
        node2.x(dist * frame.time/animTime)
            if (node2.attrs.x>=dist){
            anim.stop()
            node2.attrs.x=dist
            }
    }, layer);
    anim.start();
    }
    
    })
    }
    
    }

    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "expandedtype": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "animation": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "expandedtype": {value.datatype=1; value.value = this[field];break}
          case "animation": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }
}


import SpringClient from './rsocket'
import AuthModule from '@/store/auth.module'



let client:SpringClient = null;

   export async function connect(errorCallback):Promise<void>{
      // console.log('rsocket service connect')
       if (client == null){
           console.log('client=null');
        
           // client = new SpringClient("ws://192.168.1.33:7001/rsocket", 60000, 180000, 'application/json',errorCallback);
            client = new SpringClient(process.env.VUE_APP_API_URL, 60000, 180000, 'application/json',errorCallback);
         // client = new SpringClient("wss://cloud.teslascada.com/rsocket", 60000, 180000, 'application/json',errorCallback);
            await client.connect();
            const user = AuthModule.currentUser;
            if (user!=null)
                setToken(user.accessToken);
       }
     }
    export async function requestStream(data:any, route:string,
        onnextCallback = (data:string) =>{
             // console.log(data);
        },
        errorCallback = (error:any)=>{
            console.log(error);
        }
        ):Promise<void>{
       await connect(errorCallback);
        client.requestStream(data,route,onnextCallback, errorCallback);
    }  
    export async function requestResponse(data:any, route:string, completeCallback = (data:any) => {
       // console.log(data);
    },
    errorCallback = (error:any) => {
        console.log(error);
    }):Promise<void>{           
         await connect(errorCallback);
        client.requestResponse(data,route,completeCallback,errorCallback);
    }   
    
    export function setToken(token:string):void{
        if (token==null)
            client.logout();
        else
            client.bearerAuth(token); 
    }
 

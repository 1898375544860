import { SVGObject } from "../svgobject";

export class SVGPump23 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 75.377" enable-background="new 0 0 112.5 75.377" xml:space="preserve">
        <g id="Group_Drivershaft_Flange">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M26.1,27.226H0V3.151h60.3L63.9,3.376l3.6,0.45l3.6,0.9l3.375,1.35   l3.15,1.35l2.925,1.8l2.7,2.25l2.7,2.25l2.25,2.475l2.25,2.925l1.8,2.925l1.35,3.15l1.352,3.375l0.898,3.375l0.45,3.6l0.226,3.601   L96.3,42.752l-0.45,3.601l-0.898,3.601L93.6,53.101l-1.35,3.15l-1.8,3.15l-2.25,2.699l-2.25,2.476l-2.7,2.475l-2.7,2.025   l-2.925,1.8l-3.15,1.575l-3.375,1.35l-3.6,0.9l-3.6,0.45L60.3,75.376l-3.6-0.225l-3.6-0.45l-3.6-0.9l-3.15-1.35l-3.15-1.575   l-3.15-1.8l-2.7-2.025l-2.475-2.475L32.4,62.101l-2.025-2.699l-1.8-3.149L27,53.101l-1.35-3.148l-0.9-3.602l-0.45-3.6l-0.225-3.6   l0.45-4.5l0.45-3.15l0.675-2.925L26.1,27.226z" />
        </g>
        <g id="Group_Downstream_Pipe_Flange">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="30.1499" y1="50.1758" x2="30.1499" y2="70.2012" gradientTransform="matrix(1 0 0 -1 0 75.377)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M60.3,5.176H0v20.025h60.3V5.176z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="4.0503" y1="45.2256" x2="4.0503" y2="75.376" gradientTransform="matrix(1 0 0 -1 0 75.377)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.1,0.001H0v30.15h8.1V0.001" />
        </g>
        <g id="Group_Volute_Chamber">
            
                <radialGradient id="SVGID_3_" cx="60.2998" cy="36.2261" r="34.2" gradientTransform="matrix(1 0 0 -1 0 75.377)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <circle fill="url(#SVGID_3_)" cx="60.3" cy="39.151" r="34.2" />
        </g>
        <g id="Group_Downstream_Pipe_Flange1">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="76.2754" y1="24.0752" x2="76.2754" y2="48.1509" gradientTransform="matrix(1 0 0 -1 0 75.377)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.4,27.226H60.3l-2.475,0.225l-2.25,0.675l-2.025,1.125   l-1.8,1.575l-1.575,1.575l-1.125,2.025l-0.675,2.25l-0.225,2.476l0.225,2.476l0.675,2.25l1.125,2.024l1.575,1.801l1.8,1.574   l2.025,1.125l2.25,0.676l2.475,0.225H104.4" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="108.5625" y1="20.9258" x2="108.5625" y2="51.3013" gradientTransform="matrix(1 0 0 -1 0 75.377)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,24.076h-7.875v30.375h7.875V24.076" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.08333333333333333, 233.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
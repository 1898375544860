import { SVGObject } from "../svgobject";

export class SVGTank71 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 75.002 112.5" enable-background="new 0 0 75.002 112.5" xml:space="preserve">
        <g id="Group_Layer_4">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="37.4451" y1="98.9863" x2="37.4451" y2="107.207">
                <stop offset="0" style="stop-color:#99CCCC" />
                <stop offset="0.22" style="stop-color:#82B5B5" />
                <stop offset="0.68" style="stop-color:#467979" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M73.763,98.986H1.127v8.221h72.636V98.986z" />
        </g>
        <g id="Group1">
            <path fill="#A5A5A5" d="M56.195,103.717h-37.5v8.107h37.5V103.717z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.0012" y1="56.25" x2="75.0012" y2="56.25">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.49" style="stop-color:#ECECEC" />
                <stop offset="0.56" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M41.217,0c-1.407,0-4.223,0-5.63,0C3.626,0,0.001,2.14,0.001,2.14v0.113v96.396   c0,0,2.75,4.148,25.788,4.148v9.703h23.424v-9.706c24.55,0,25.788-4.37,25.788-4.37V2.252C75.001,2.252,72.001,0,41.217,0z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="88.1191" x2="75.0022" y2="88.1191">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M75.002,86.855c0,0-5.669,3.333-37.558,3.333S0,86.855,0,86.855l0.001-0.805   c0,0,5.555,3.604,37.443,3.604s37.557-3.604,37.557-3.604" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="77.5898" x2="75.0022" y2="77.5898">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M75.002,76.326c0,0-5.669,3.333-37.558,3.333S0,76.326,0,76.326l0.001-0.805   c0,0,5.555,3.604,37.443,3.604s37.557-3.604,37.557-3.604" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="33.0518" x2="75.0022" y2="33.0518">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M75.002,33.012c0,0-5.612,0.885-37.501,0.885S0,33.012,0,33.012l0.001-0.805   c0,0,5.611,0.845,37.5,0.845s37.5-0.845,37.5-0.845" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="41.8359" x2="75.0022" y2="41.8359">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M75.002,41.796c0,0-5.612,0.885-37.501,0.885S0,41.796,0,41.796l0.001-0.805   c0,0,5.611,0.845,37.5,0.845s37.5-0.845,37.5-0.845" />
        </g>
        <g id="Group_Label4">
            <path fill="#606060" d="M65.767,6.982V19.82l9.234-0.901V7.883L65.767,6.982z" />
            <path fill="#D9D9D9" d="M67.118,8.221v10.248l7.883-0.675V8.896L67.118,8.221z" />
        </g>
        <g id="Group_Label3">
            <path fill="#666666" d="M46.848,92.567H28.042v8.108h18.806V92.567z" />
        </g>
        <g id="Group_Label2">
            <path fill="#FFFFFF" d="M18.695,9.347h37.5v14.64h-37.5V9.347z" />
        </g>
        <g id="Group_Label1">
            <path fill="#669999" d="M39.191,20.496h15.202v2.252H39.191V20.496z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5364583333333334, 117.0, 0.22, 0.71875, 71.0, 0.68, 0.8072916666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.49, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGBlower11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 59.35" enable-background="new 0 0 112.5 59.35" xml:space="preserve">
        <g id="Group_8">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="13.177" width="12.189" height="25.338" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0044" y1="59.3545" x2="1.0044" y2="59.3545" gradientTransform="matrix(0 -25.3369 -25.3369 0 1509.9579 38.6405)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect y="13.177" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="12.189" height="25.338" />
            </g>
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="10.698" y1="38.515" x2="10.698" y2="13.178" />
        </g>
        <g id="Group_7">
            <g>
                <defs>
                    <rect id="SVGID_4_" x="100.306" y="13.12" width="12.194" height="25.337" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="0.0063" y1="59.3545" x2="1.0063" y2="59.3545" gradientTransform="matrix(0 -25.3369 -25.3369 0 1610.2704 38.6405)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="100.306" y="13.12" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="12.194" height="25.337" />
            </g>
            <line fill="none" stroke="#4C4C4C" stroke-width="0.5" x1="101.689" y1="38.515" x2="101.689" y2="13.178" />
        </g>
        <g id="Group_6">
            <g>
                <defs>
                    <polygon id="SVGID_7_" points="81.531,8.109 71.396,0 40.991,0 30.856,8.109 56.193,15.673    " />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_9_" cx="0.0103" cy="59.3564" r="1.0008" gradientTransform="matrix(0 -18.6959 -25.2127 0 1552.7415 8.0294)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#99CCCC" />
                    <stop offset="0.16" style="stop-color:#8BBEBE" />
                    <stop offset="0.47" style="stop-color:#659898" />
                    <stop offset="0.89" style="stop-color:#295C5C" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </radialGradient>
                <rect x="30.856" y="0" clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="50.675" height="15.673" />
            </g>
        </g>
        <g id="Group_5">
            <polygon fill="#003333" points="30.856,43.583 40.991,51.128 71.397,51.128 81.531,43.583 56.193,38.515  " />
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <rect id="SVGID_10_" x="23.299" y="8.109" width="66.386" height="35.475" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="0.0107" y1="59.3604" x2="1.0107" y2="59.3604" gradientTransform="matrix(0 -35.4731 -35.4731 0 2162.175 43.948)">
                    <stop offset="0" style="stop-color:#194C4C" />
                    <stop offset="0.45" style="stop-color:#99CCCC" />
                    <stop offset="0.55" style="stop-color:#86B9B9" />
                    <stop offset="0.73" style="stop-color:#568989" />
                    <stop offset="0.77" style="stop-color:#4C7F7F" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </linearGradient>
                <rect x="23.299" y="8.109" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="66.386" height="35.475" />
            </g>
        </g>
        <g id="Group_3">
            <rect x="30.856" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
            <rect x="38.514" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
            <rect x="46.059" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
            <rect x="53.716" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
            <rect x="61.262" y="10.586" fill="#324C4C" width="5.066" height="30.405" />
            <rect x="68.807" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
            <rect x="76.464" y="10.586" fill="#324C4C" width="5.067" height="30.405" />
        </g>
        <g id="Group_2">
            <g>
                <defs>
                    <rect id="SVGID_13_" x="35.923" y="42.987" width="5.124" height="13.875" />
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="0.0376" y1="59.3877" x2="1.0376" y2="59.3877" gradientTransform="matrix(5.124 0 0 -5.124 35.7325 354.2231)">
                    <stop offset="0" style="stop-color:#194C4C" />
                    <stop offset="0.45" style="stop-color:#99CCCC" />
                    <stop offset="0.55" style="stop-color:#86B9B9" />
                    <stop offset="0.73" style="stop-color:#568989" />
                    <stop offset="0.77" style="stop-color:#4C7F7F" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </linearGradient>
                <rect x="35.923" y="42.987" clip-path="url(#SVGID_14_)" fill="url(#SVGID_15_)" width="5.124" height="13.875" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_16_" x="33.446" y="56.195" width="10.135" height="3.154" />
                </defs>
                <clipPath id="SVGID_17_">
                    <use xlink:href="#SVGID_16_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-0.0371" y1="59.3135" x2="0.9629" y2="59.3135" gradientTransform="matrix(10.1353 0 0 -10.1353 33.8217 658.9225)">
                    <stop offset="0" style="stop-color:#194C4C" />
                    <stop offset="0.45" style="stop-color:#99CCCC" />
                    <stop offset="0.55" style="stop-color:#86B9B9" />
                    <stop offset="0.73" style="stop-color:#568989" />
                    <stop offset="0.77" style="stop-color:#4C7F7F" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </linearGradient>
                <rect x="33.446" y="56.195" clip-path="url(#SVGID_17_)" fill="url(#SVGID_18_)" width="10.135" height="3.154" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_19_" x="71.283" y="42.987" width="5.125" height="13.875" />
                </defs>
                <clipPath id="SVGID_20_">
                    <use xlink:href="#SVGID_19_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="-4.882813e-004" y1="59.3486" x2="0.9995" y2="59.3486" gradientTransform="matrix(5.125 0 0 -5.125 71.2851 354.0853)">
                    <stop offset="0" style="stop-color:#194C4C" />
                    <stop offset="0.45" style="stop-color:#99CCCC" />
                    <stop offset="0.55" style="stop-color:#86B9B9" />
                    <stop offset="0.73" style="stop-color:#568989" />
                    <stop offset="0.77" style="stop-color:#4C7F7F" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </linearGradient>
                <rect x="71.283" y="42.987" clip-path="url(#SVGID_20_)" fill="url(#SVGID_21_)" width="5.125" height="13.875" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_22_" x="68.807" y="56.195" width="10.136" height="3.154" />
                </defs>
                <clipPath id="SVGID_23_">
                    <use xlink:href="#SVGID_22_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="-0.0273" y1="59.3232" x2="0.9727" y2="59.3232" gradientTransform="matrix(10.1348 0 0 -10.1348 69.0845 658.9933)">
                    <stop offset="0" style="stop-color:#194C4C" />
                    <stop offset="0.45" style="stop-color:#99CCCC" />
                    <stop offset="0.55" style="stop-color:#86B9B9" />
                    <stop offset="0.73" style="stop-color:#568989" />
                    <stop offset="0.77" style="stop-color:#4C7F7F" />
                    <stop offset="1" style="stop-color:#194C4C" />
                </linearGradient>
                <rect x="68.807" y="56.195" clip-path="url(#SVGID_23_)" fill="url(#SVGID_24_)" width="10.136" height="3.154" />
            </g>
        </g>
        <g id="Group_1">
            <g>
                <defs>
                    <rect id="SVGID_25_" x="86.599" y="3.042" width="15.091" height="45.497" />
                </defs>
                <clipPath id="SVGID_26_">
                    <use xlink:href="#SVGID_25_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="0.002" y1="59.3506" x2="1.002" y2="59.3506" gradientTransform="matrix(0 -45.4956 -45.4956 0 2794.3521 48.5998)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="86.599" y="3.042" clip-path="url(#SVGID_26_)" fill="url(#SVGID_27_)" width="15.091" height="45.497" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_28_" x="10.698" y="3.042" width="15.09" height="45.497" />
                </defs>
                <clipPath id="SVGID_29_">
                    <use xlink:href="#SVGID_28_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="0.002" y1="59.3516" x2="1.002" y2="59.3516" gradientTransform="matrix(0 -45.4956 -45.4956 0 2718.4458 48.5998)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="10.698" y="3.042" clip-path="url(#SVGID_29_)" fill="url(#SVGID_30_)" width="15.09" height="45.497" />
            </g>
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[]);
		this.fillGradient(this.gradients,"SVGID_2_",[]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[]);
		this.fillGradient(this.gradients,"SVGID_5_",[]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[]);
		this.fillGradient(this.gradients,"SVGID_8_",[]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.5364583333333334, 117.0, 0.16, 0.6510416666666666, 89.0, 0.47, 0.9479166666666666, 13.0, 0.89, 0.5833333333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[]);
		this.fillGradient(this.gradients,"SVGID_11_",[]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[]);
		this.fillGradient(this.gradients,"SVGID_14_",[]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[]);
		this.fillGradient(this.gradients,"SVGID_17_",[]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[]);
		this.fillGradient(this.gradients,"SVGID_20_",[]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[]);
		this.fillGradient(this.gradients,"SVGID_23_",[]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[]);
		this.fillGradient(this.gradients,"SVGID_26_",[]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[]);
		this.fillGradient(this.gradients,"SVGID_29_",[]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">

        <g id="Group_90_curve_1">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="36.1162" y1="90.2363" x2="96.6025" y2="90.2363" gradientTransform="matrix(-1 0 0 1 96.6025 -25.2188)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.18" style="stop-color:#959595" />
            <stop offset="0.5" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#666666" />
        </linearGradient>
        <rect y="62.271" fill="url(#SVGID_1_)" width="60.486" height="5.491" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="25.0601" y1="25.2188" x2="25.0601" y2="80.4263" gradientTransform="matrix(-1 0 0 1 96.6025 -25.2188)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <rect x="69.408" fill="url(#SVGID_2_)" width="4.269" height="55.207" />
        <radialGradient id="SVGID_3_" cx="140.0811" cy="319.2207" r="23.7788" gradientTransform="matrix(-2.9866 0.2001 0.2179 2.719 416.3635 -834.6254)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#595959" />
            <stop offset="0.1" style="stop-color:#666767" />
            <stop offset="0.22" style="stop-color:#888888" />
            <stop offset="0.4" style="stop-color:#BEBEBE" />
            <stop offset="0.53" style="stop-color:#E0E0E0" />
            <stop offset="0.64" style="stop-color:#C3C3C3" />
            <stop offset="0.89" style="stop-color:#787878" />
            <stop offset="1" style="stop-color:#545454" />
        </radialGradient>
        <path fill="url(#SVGID_3_)" d="M71.319,0C36.547,0,0,21.826,0,65.055l60.45,0.162c1.263-7.605,4.71-9.44,10.676-9.932  C71.827,40.793,71.319,0,71.319,0z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="36.1162" y1="113.1973" x2="96.6025" y2="113.1973" gradientTransform="matrix(-1 0 0 1 96.6025 -25.2188)">
            <stop offset="0.01" style="stop-color:#676868" />
            <stop offset="0.18" style="stop-color:#939394" />
            <stop offset="0.5" style="stop-color:#E0E0E0" />
            <stop offset="0.74" style="stop-color:#A9AAAA" />
            <stop offset="1" style="stop-color:#656667" />
        </linearGradient>
        <rect y="63.209" fill="url(#SVGID_4_)" width="60.486" height="49.54" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="4.8184" y1="25.2749" x2="4.8184" y2="80.4819" gradientTransform="matrix(-1 0 0 1 96.6025 -25.2188)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="0.75" style="stop-color:#A3A4A4" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <rect x="70.908" y="0.056" fill="url(#SVGID_5_)" width="41.752" height="55.207" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.1, 0.7916666666666666, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.53, 0.25, 191.0, 0.64, 0.4739583333333333, 133.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.8020833333333334, 0.0, 0.18, 0.8541666666666666, 37.0, 0.5, 0.25, 191.0, 0.74, 0.6822916666666666, 81.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 0.75, 0.7291666666666666, 69.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
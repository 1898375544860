import { SVGObject } from "../svgobject";

export class SVGPaper12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 105.516" enable-background="new 0 0 112.5 105.516" xml:space="preserve">
        <g id="Group_Pole">
            <path fill="#E54C00" stroke="#4C4C4C" stroke-width="0.25" d="M98.535,39.751V2.478h4.504l2.479,37.273H98.535z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="92.6797" y1="30.4045" x2="98.5352" y2="30.4045">
                <stop offset="0" style="stop-color:#FF6600" />
                <stop offset="0.52" style="stop-color:#E54C00" />
                <stop offset="1" style="stop-color:#FF6600" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M92.68,21.058h5.855v18.693H92.68V21.058z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M92.68,21.058h5.855v18.693H92.68V21.058" />
            <path fill="#FF6600" stroke="#4C4C4C" stroke-width="0.25" d="M98.535,30.518h4.504v4.504h-4.504V30.518z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M99.661,0h2.253l1.125,2.478h-4.504L99.661,0z" />
        </g>
        <g id="Group_Wheel">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M105.742,105.29l-1.014,0.226h-0.788l-0.901-0.226l-0.9-0.45   l-0.676-0.676h5.631l-0.676,0.676L105.742,105.29z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M44.708,105.29l-0.901,0.226h-0.901l-0.901-0.226l-0.901-0.45   l-0.676-0.676v-4.279l0.676-0.675l0.901-0.451l0.901-0.225h0.901l0.901,0.225l0.676,0.451l0.675,0.675v4.279l-0.675,0.676   L44.708,105.29z" />
        </g>
        <g id="Group_Base">
            <path fill="#FF6600" stroke="#4C4C4C" stroke-width="0.25" d="M90.202,53.941h1.126v27.026h-1.126V53.941z" />
            <path fill="#FF6600" d="M0.001,99.66h93.805V87.948H2.479v-2.478h88.85v-9.233l-16.441,9.233h-9.234l25.676-16.44V38.625h2.478   l17.567,1.126l1.126,48.197v16.441H0.001V99.66z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0.001,99.66h93.805V87.948H2.479v-2.478h63.174l25.676-16.44V38.625   h2.478l17.567,1.126l1.126,48.197v16.441H0.001V99.66" />
            <path fill="none" stroke="#B21900" stroke-width="0.25" d="M98.535,83.219V51.463h9.234" />
            <path fill="none" stroke="#FFD9BF" stroke-width="0.25" d="M107.77,51.463v31.756h-9.234" />
            <path fill="none" stroke="#B21900" stroke-width="0.25" d="M93.806,87.948V38.625" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.887,85.471l16.441-9.233v9.233H74.887" />
        </g>
        <g id="Group_Carrier">
            <path fill="#E54C00" stroke="#4C4C4C" stroke-width="0.25" d="M1.015,94.931v-6.982h92.791v6.982H1.015z" />
        </g>
        <g id="Group_CarrierBase">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M2.141,87.948l0.338-1.126h91.327v1.126H2.141z" />
        </g>
        <g id="Group_Shadow">
            <path d="M74.887,85.471l16.441-9.233v1.126l-15.09,8.107H74.887z" />
            <path d="M1.015,94.931h92.791v1.126H1.015V94.931z" />
        </g>
        <g id="Group_CarrierStripes">
            <path d="M5.519,87.724l-0.901-0.901h4.054l1.239,0.901H5.519z" />
            <path d="M13.852,87.724l-1.126-0.901h4.279l0.901,0.901H13.852z" />
            <path d="M21.96,87.724l-0.901-0.901h4.054l1.126,0.901H21.96z" />
            <path d="M30.293,87.724l-1.126-0.901h4.279l0.901,0.901H30.293z" />
            <path d="M38.401,87.724L37.5,86.823h4.054l1.126,0.901H38.401z" />
            <path d="M46.734,87.724l-1.126-0.901h4.279l0.901,0.901H46.734z" />
            <path d="M55.067,87.724l-1.126-0.901h4.279l0.9,0.901H55.067z" />
            <path d="M63.176,87.724l-0.9-0.901h4.053l1.127,0.901H63.176z" />
            <path d="M71.509,87.724l-1.126-0.901h4.279l0.9,0.901H71.509z" />
            <path d="M79.617,87.724l-0.901-0.901h4.054l1.127,0.901H79.617z" />
            <path d="M87.949,87.724l-1.125-0.901h4.279l0.9,0.901H87.949z" />
        </g>
        <g id="Group_Stripe">
            <path fill="#333333" d="M91.328,69.031v-1.126h20.495v1.126H91.328z" />
            <path fill="#333333" d="M91.328,66.778v-0.675h20.495v0.675H91.328z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9270833333333334, 0.0, 0.52, 0.7864583333333334, 0.0, 1.0, 0.9270833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Pole",[0.0, 0.7864583333333334, 0.0, 1.0, 0.9270833333333334, 0.0, 2.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Wheel",[0.0, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.9270833333333334, 0.0, 1.0, 0.9270833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Carrier",[0.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_CarrierBase",[0.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Stripe",[0.0, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0]);
        super.drawObject();
    }
}
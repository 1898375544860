import { SVGObject } from "../svgobject";

export class SVGDuct1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 21.96" enable-background="new 0 0 112.5 21.96" xml:space="preserve">
        <g id="Group_Base_Layer">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M26.689,20.158H0V1.689h26.689V20.158z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M54.392,20.158H27.815V1.689h26.577V20.158z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M82.32,20.158H55.743V1.689H82.32V20.158z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M110.248,20.158H83.445V1.689h26.803V20.158z" />
        </g>
        <g id="Group_Right_Layer">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="13.4009" y1="10.811" x2="26.0137" y2="10.811">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M26.014,19.482V2.14l-12.613,8.784L26.014,19.482z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="41.1035" y1="10.811" x2="53.9414" y2="10.811">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M53.941,19.482V2.14l-12.838,8.784L53.941,19.482z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="69.0313" y1="10.811" x2="81.8691" y2="10.811">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M81.869,19.482V2.14l-12.838,8.784L81.869,19.482z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="96.959" y1="10.811" x2="109.7969" y2="10.811">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M109.797,19.482V2.14l-12.838,8.784L109.797,19.482z" />
        </g>
        <g id="Group_Bottom_Layer">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="13.2886" y1="19.4819" x2="13.2886" y2="10.9243">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M13.401,10.924l12.613,8.558H0.563L13.401,10.924z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="41.1035" y1="19.4819" x2="41.1035" y2="10.9243">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M41.104,10.924l12.838,8.558H28.266L41.104,10.924z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="69.0313" y1="19.4819" x2="69.0313" y2="10.9243">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M69.031,10.924l12.838,8.558H56.194L69.031,10.924z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="96.959" y1="19.4819" x2="96.959" y2="10.9243">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M96.959,10.924l12.838,8.558H84.121L96.959,10.924z" />
        </g>
        <g id="Group_Left_Layer">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0.563" y1="10.811" x2="13.4009" y2="10.811">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.45" style="stop-color:#C4C4C4" />
                <stop offset="1" style="stop-color:#C6C6C6" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M0.563,19.482V2.14l12.838,8.784L0.563,19.482z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="28.2656" y1="10.811" x2="41.1035" y2="10.811">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.45" style="stop-color:#C4C4C4" />
                <stop offset="1" style="stop-color:#C6C6C6" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M28.266,19.482V2.14l12.838,8.784L28.266,19.482z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="10.811" x2="69.0313" y2="10.811">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.45" style="stop-color:#C4C4C4" />
                <stop offset="1" style="stop-color:#C6C6C6" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M56.194,19.482V2.14l12.837,8.784L56.194,19.482z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="84.1211" y1="10.811" x2="96.959" y2="10.811">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.45" style="stop-color:#C4C4C4" />
                <stop offset="1" style="stop-color:#C6C6C6" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M84.121,19.482V2.14l12.838,8.784L84.121,19.482z" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M84.797,0.451h-3.604v0.563h3.604V0.451z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M84.797,20.833h-3.604v0.451h3.604V20.833z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M83.445,21.96H82.32V0h1.125V21.96z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M111.374,21.96h-1.126V0h1.126V21.96z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M28.941,0.451h-3.378v0.563h3.378V0.451z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M28.941,20.833h-3.378v0.451h3.378V20.833z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M27.815,21.96h-1.126V0h1.126V21.96z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M56.644,0.451h-3.378v0.563h3.378V0.451z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M56.644,20.833h-3.378v0.451h3.378V20.833z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M55.518,21.96h-1.126V0h1.126V21.96z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,0.451h-3.491v0.563h3.491V0.451z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,20.833h-3.491v0.451h3.491V20.833z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.3020833333333333, 177.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.3020833333333333, 177.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.3020833333333333, 177.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.3020833333333333, 177.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.609375, 99.0, 0.45, 0.46875, 135.0, 1.0, 0.453125, 139.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.609375, 99.0, 0.45, 0.46875, 135.0, 1.0, 0.453125, 139.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.609375, 99.0, 0.45, 0.46875, 135.0, 1.0, 0.453125, 139.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.609375, 99.0, 0.45, 0.46875, 135.0, 1.0, 0.453125, 139.0]);
		this.fillGradient(this.fills,"Group_Base_Layer",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0, 6.0, 0.609375, 99.0, 7.0, 0.5885416666666666, 0.0, 8.0, 0.5885416666666666, 0.0, 9.0, 0.609375, 99.0, 10.0, 0.5885416666666666, 0.0, 11.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank29 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 65.541 112.5" enable-background="new 0 0 65.541 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="25.676" y1="4.0596" x2="39.7532" y2="4.0596">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.53" style="stop-color:#ECECEC" />
            <stop offset="0.59" style="stop-color:#D9D9D9" />
            <stop offset="0.73" style="stop-color:#A8A8A8" />
            <stop offset="0.92" style="stop-color:#5A5A5A" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M25.676,8.119h14.077V0H25.676V8.119z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="11.5999" y1="10.7163" x2="53.8293" y2="10.7163">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.53" style="stop-color:#ECECEC" />
            <stop offset="0.59" style="stop-color:#D9D9D9" />
            <stop offset="0.73" style="stop-color:#A8A8A8" />
            <stop offset="0.92" style="stop-color:#5A5A5A" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M11.6,14.451h42.229V6.982H11.6V14.451z" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.6174" y1="18.2661" x2="60.9241" y2="18.2661">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.53" style="stop-color:#ECECEC" />
            <stop offset="0.59" style="stop-color:#D9D9D9" />
            <stop offset="0.73" style="stop-color:#A8A8A8" />
            <stop offset="0.92" style="stop-color:#5A5A5A" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M4.617,22.568h56.307v-8.604H4.617V22.568z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="66.7793" x2="65.5413" y2="66.7793">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.53" style="stop-color:#ECECEC" />
            <stop offset="0.59" style="stop-color:#D9D9D9" />
            <stop offset="0.73" style="stop-color:#A8A8A8" />
            <stop offset="0.92" style="stop-color:#5A5A5A" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M0,21.059h65.541V112.5H0V21.059z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.53, 0.16145833333333334, 214.0, 0.59, 0.3020833333333333, 177.0, 0.73, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.53, 0.16145833333333334, 214.0, 0.59, 0.3020833333333333, 177.0, 0.73, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.53, 0.16145833333333334, 214.0, 0.59, 0.3020833333333333, 177.0, 0.73, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.53, 0.16145833333333334, 214.0, 0.59, 0.3020833333333333, 177.0, 0.73, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		
        super.drawObject();
    }
}
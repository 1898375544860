import { SVGObject } from "../svgobject";

export class SVGHeating19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 62.727 112.5" enable-background="new 0 0 62.727 112.5" xml:space="preserve">
        <g id="Group_Furnace">
            <path fill="#CC4C00" stroke="#4C4C4C" stroke-width="0.25" d="M0.563,24.212H57.32v85.247H0.563V24.212z" />
        </g>
        <g id="Group_Inner_Surface">
            <path fill="#E5CBB2" stroke="#4C4C4C" stroke-width="0.25" d="M4.055,27.815h17.793v-3.604h14.189v3.604H53.83v78.153H4.055V27.815   z" />
        </g>
        <g id="Group_Back_Spring">
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,48.536l0.902-0.226l0.787-0.45l0.563-0.788l0.112-0.901   l-0.112-0.9l-0.563-0.789l-0.787-0.563l-24.55-2.478l-0.901,0.113l-0.788,0.563l-0.563,0.788l-0.112,0.901l0.112,0.901l0.563,0.789   l0.788,0.45L40.766,48.536z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,41.441l0.902-0.225l0.787-0.451l0.563-0.788l0.112-0.901   l-0.112-1.014l-0.563-0.788l-0.787-0.451l-24.55-2.59l-0.901,0.225l-0.788,0.563l-0.563,0.788l-0.112,0.789l0.112,0.901   l0.563,0.788l0.788,0.563L40.766,41.441z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,55.631l0.902-0.226l0.787-0.45l0.563-0.789l0.112-0.901   l-0.112-0.901l-0.563-0.788l-0.787-0.563l-24.55-2.478l-0.901,0.112l-0.788,0.563L14.865,50l-0.112,0.901l0.112,0.901l0.563,0.788   l0.788,0.451L40.766,55.631z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,62.725l0.902-0.111l0.787-0.563l0.563-0.788l0.112-0.901   l-0.112-0.901l-0.563-0.788l-0.787-0.563l-24.55-2.477l-0.901,0.112l-0.788,0.563l-0.563,0.789l-0.112,0.901l0.112,0.9l0.563,0.788   l0.788,0.45L40.766,62.725z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,69.82l0.902-0.226l0.787-0.45l0.563-0.789l0.112-0.9   l-0.112-0.9l-0.563-0.789l-0.787-0.563l-24.55-2.479l-0.901,0.226l-0.788,0.45l-0.563,0.789l-0.112,0.9l0.112,0.901l0.563,0.788   l0.788,0.563L40.766,69.82z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,76.914l0.902-0.112l0.787-0.563l0.563-0.788l0.112-0.9   l-0.112-0.901l-0.563-0.788l-0.787-0.563l-24.55-2.477l-0.901,0.225l-0.788,0.451l-0.563,0.787l-0.112,0.901l0.112,0.901   l0.563,0.788l0.788,0.563L40.766,76.914z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,84.009l0.902-0.112l0.787-0.563l0.563-0.789l0.112-0.9   l-0.112-0.901l-0.563-0.788l-0.787-0.451l-24.55-2.59l-0.901,0.226l-0.788,0.45l-0.563,0.789l-0.112,0.9l0.112,0.9l0.563,0.789   l0.788,0.563L40.766,84.009z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,91.104l0.902-0.112l0.787-0.563l0.563-0.788l0.112-0.901   l-0.112-0.9l-0.563-0.788l-0.787-0.45l-24.55-2.591l-0.901,0.226l-0.788,0.45l-0.563,0.901l-0.112,0.788l0.112,0.901l0.563,0.787   l0.788,0.563L40.766,91.104z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,98.311l0.902-0.225l0.787-0.451l0.563-0.9l0.112-0.9   l-0.112-0.901l-0.563-0.788l-0.787-0.451l-24.55-2.59l-0.901,0.226l-0.788,0.45l-0.563,0.788l-0.112,0.901l0.112,0.9l0.563,0.789   l0.788,0.563L40.766,98.311z" />
            <path fill="#CC8C4C" stroke="#4C4C4C" stroke-width="0.25" d="M40.766,105.405l0.902-0.226l0.787-0.563l0.563-0.788l0.112-0.788   l-0.112-0.901l-0.563-0.788l-0.787-0.563l-24.55-2.478l-0.901,0.113l-0.788,0.563l-0.563,0.789l-0.112,0.787l0.112,1.014   l0.563,0.789l0.788,0.45L40.766,105.405z" />
        </g>
        <g id="Group_Front_Spring">
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,37.838l-0.901-0.225l-0.788-0.451l-0.563-0.788l-0.112-0.901   l0.112-0.901l0.563-0.789l0.788-0.563l24.549-2.478l0.902,0.113l0.787,0.563l0.563,0.788l0.112,0.901l-0.112,0.901l-0.563,0.789   l-0.787,0.563L17.118,37.838z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,44.933l-0.901-0.113l-0.788-0.563l-0.563-0.789l-0.112-0.901   l0.112-0.901l0.563-0.788l0.788-0.451l24.549-2.59l0.902,0.112l0.787,0.563l0.563,0.788l0.112,0.901l-0.112,0.901l-0.563,0.789   l-0.787,0.563L17.118,44.933z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,52.027l-0.901-0.112l-0.788-0.563l-0.563-0.789l-0.112-0.901   l0.112-0.901l0.563-0.788l0.788-0.451l24.549-2.59l0.902,0.225l0.787,0.45l0.563,0.789l0.112,0.901l-0.112,0.901l-0.563,0.788   l-0.787,0.563L17.118,52.027z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,59.121l-0.901-0.112l-0.788-0.563l-0.563-0.787l-0.112-0.901   l0.112-0.901l0.563-0.789l0.788-0.45l24.549-2.59l0.902,0.226l0.787,0.45l0.563,0.789l0.112,0.901l-0.112,0.901l-0.563,0.788   l-0.787,0.563L17.118,59.121z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,66.329l-0.901-0.226l-0.788-0.563l-0.563-0.789l-0.112-0.788   l0.112-0.901l0.563-0.787l0.788-0.563l24.549-2.591l0.902,0.226l0.787,0.45l0.563,0.789l0.112,0.9L43.019,62.5l-0.563,0.788   l-0.787,0.45L17.118,66.329z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,73.311l-0.901-0.112l-0.788-0.563l-0.563-0.788l-0.112-0.788   l0.112-0.9l0.563-0.901l0.788-0.45l24.549-2.478l0.902,0.112l0.787,0.563l0.563,0.789l0.112,0.788l-0.112,0.901l-0.563,0.9   l-0.787,0.451L17.118,73.311z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,80.518l-0.901-0.225l-0.788-0.451l-0.563-0.787l-0.112-0.901   l0.112-0.901l0.563-0.788l0.788-0.563l24.549-2.59l0.902,0.226l0.787,0.563l0.563,0.787l0.112,0.901l-0.112,0.901l-0.563,0.788   l-0.787,0.45L17.118,80.518z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,87.613l-0.901-0.227l-0.788-0.449l-0.563-0.789l-0.112-0.9   l0.112-0.901l0.563-0.788l0.788-0.563l24.549-2.479l0.902,0.113l0.787,0.563l0.563,0.789l0.112,0.9l-0.112,0.9l-0.563,0.789   l-0.787,0.45L17.118,87.613z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,94.707l-0.901-0.225l-0.788-0.451l-0.563-0.788l-0.112-0.901   l0.112-0.9l0.563-0.788l0.788-0.563l24.549-2.477l0.902,0.111l0.787,0.563l0.563,0.788l0.112,0.901l-0.112,0.901l-0.563,0.787   l-0.787,0.451L17.118,94.707z" />
            <path fill="#FFBF7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.118,101.802l-0.901-0.112l-0.788-0.563l-0.563-0.788l-0.112-0.9   l0.112-0.901l0.563-0.788l0.788-0.451l24.549-2.59l0.902,0.113l0.787,0.563l0.563,0.788l0.112,0.901l-0.112,0.9l-0.563,0.789   l-0.787,0.563L17.118,101.802z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.2935" y1="110.6416" x2="52.5908" y2="110.6416">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.293,111.824v-2.365h47.297v2.365H5.293z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="4.0547" y1="27.1963" x2="53.8301" y2="27.1963">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M4.055,27.815h17.793v-3.604h14.189v3.604H53.83v2.365H4.055   V27.815z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M4.055,28.941H53.83" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.848,27.815h14.189" />
        </g>
        <g id="Group_Fire">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="29.9556" y1="102.3652" x2="29.9556" y2="30.1807">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="1" style="stop-color:#FF3300" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M29.505,102.365l-4.842-14.752l-0.901,2.139l-0.788-16.554l-1.239,1.688l1.689-16.779l-1.689,1.239   l4.054-15.991l-1.577,1.126l3.716-13.964l2.027-0.338l5.744,13.514l-2.027-1.239l4.504,20.495l-1.688-1.238l1.688,16.892   l-1.238-2.027l-1.576,15.203l-0.901-2.478L29.505,102.365z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M29.505,102.365l-4.842-14.752l-0.901,2.139l-0.788-16.554l-1.239,1.688   l1.689-16.779l-1.689,1.239l4.054-15.991l-1.577,1.126l3.716-13.964l2.027-0.338l5.744,13.514l-2.027-1.239l4.504,20.495   l-1.688-1.238l1.688,16.892l-1.238-2.027l-1.576,15.203l-0.901-2.478L29.505,102.365" />
        </g>
        <g id="Group_Inlet_Outlet">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="59.7412" y1="107.0947" x2="59.7412" y2="97.6348">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M57.32,107.095v-9.46h4.842v9.46H57.32z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="59.7412" y1="36.0361" x2="59.7412" y2="26.5767">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M57.32,36.036v-9.459h4.842v9.459H57.32z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.162,38.401V24.212" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.162,109.459V95.271" />
        </g>
        <g id="Group_Feeder">
            <path fill="#CC4C00" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,2.928h18.919l5.969-2.365h8.221v6.982L44.37,7.658   l-5.969,2.365v14.189H19.482V2.928z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M27.816,21.847V7.658h2.365v14.189H27.816z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.401,10.022V2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.37,7.658V0.563" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.671875, 83.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Furnace",[0.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Inner_Surface",[0.0, 0.4114583333333333, 149.0]);
		this.fillGradient(this.fills,"Group_Back_Spring",[0.0, 0.90625, 23.0, 1.0, 0.90625, 23.0, 2.0, 0.90625, 23.0, 3.0, 0.90625, 23.0, 4.0, 0.90625, 23.0, 5.0, 0.90625, 23.0, 6.0, 0.90625, 23.0, 7.0, 0.90625, 23.0, 8.0, 0.90625, 23.0, 9.0, 0.90625, 23.0]);
		this.fillGradient(this.fills,"Group_Front_Spring",[0.0, 0.5052083333333334, 125.0, 1.0, 0.5052083333333334, 125.0, 2.0, 0.5052083333333334, 125.0, 3.0, 0.5052083333333334, 125.0, 4.0, 0.5052083333333334, 125.0, 5.0, 0.5052083333333334, 125.0, 6.0, 0.5052083333333334, 125.0, 7.0, 0.5052083333333334, 125.0, 8.0, 0.5052083333333334, 125.0, 9.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Feeder",[0.0, 0.7239583333333334, 0.0, 1.0, 0.0, 255.0]);
	
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { TextInputProperty } from "./properties/textinputproperty";
import { StringUtils } from "../utils/stringutils";
import { OutputProperty } from "./properties/outputproperty";
import { DialogBox } from "../dialogbox";
import dialogboxModule from "@/store/dialogbox.module";
import { Value } from "../value";


export class TextView extends ObjectView{
    text:string;
    fonttype:number;
    fontsize:number;
    textplacement:number;
    textcolor:string;
    useborder:boolean;
    bordercolor:string;
    linewidth:number;
    fill:boolean;
    fillcolor:string;
    underline:boolean;

    public drawObject():void{
		super.initObject();

        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
        if (this.textplacement<0 || this.textplacement>3) this.textplacement = 0

        let borderwidth = 0;
        let color = '0xffffff00';
        if (this.useborder){
            color = this.bordercolor;
            const lineprop = this.properties==null?null:this.properties['linecolor'];
            if (lineprop!=null){	
                const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
                if (linecolorproperty!=null) 
                    color = linecolorproperty.getColor(this.tagnames, this.bordercolor)
            }
            borderwidth = this.linewidth;
        }
        const bordercolor:string = ColorUtils.convertformat(color);

        let fillcolor = '0xffffff00';
        if (this.fill){
            fillcolor = this.fillcolor;
            const fillprop =this.properties==null?null:this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        }
        const fillcolor2:string = ColorUtils.convertformat(fillcolor);
		const rect = new Konva.Rect({
            x: borderwidth/2,
            y: borderwidth/2,
            width: this.width-borderwidth,
            height: this.height-borderwidth,
            fill: fillcolor2,
            stroke: bordercolor,
            strokeWidth: borderwidth,
          });
          this.node.add(rect)

          let text = this.text;
          const textprop =this.properties==null?null: this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties==null?null:this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }

            const Text = new Konva.Text({
                x: 2*this.linewidth+1,
                y: this.linewidth,
                verticalAlign:"middle",
                width: this.width-4*this.linewidth-1,
                height: this.height-1*this.linewidth,
                text: text,
                fontSize: this.fontsize,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                align:StringUtils.getTextPlacement(this.textplacement),
                fill: ColorUtils.convertformat(textcolor),
                textDecoration:this.underline?"underline":""
              });
              const outputprop = this.properties==null?null:this.properties['output'];
              if (outputprop!=null){
                const outputproperty:OutputProperty=Object.assign(new OutputProperty(), outputprop);
                if (outputproperty!=null){
                    this.node.on('mousedown touchstart', function () {
                            const dialogbox:DialogBox = new DialogBox();
                            dialogbox.enable = true;
                            dialogbox.title = outputproperty.title;
                            dialogbox.tagname = outputproperty.path;
                            dialogboxModule.setDialogBox(dialogbox);
                      });
                }
              }
              this.node.add(Text);
    }

    public setField(field:string, value:Value){
        console.log("TEXT SETFIELD", field, value)
        super.setField(field, value)
        const textinputproperty: TextInputProperty = this.properties==null ? null : this.properties['textinput'];
        switch(field){
            case "textcolor":
            case "fillcolor":
            case "bordercolor":
            case "text": {this[field] = value.value;this.rotatednode=null;break;}

            case "fonttype":
            case "fontsize":
            case "textplacement": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "useborder":
            case "fill":
            case "underline": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}

            case "textbefore": {if (textinputproperty!=null){textinputproperty.textbefore = value.value}   
                break;}
            case "textafter": {if (textinputproperty!=null){textinputproperty.textafter = value.value}   
                break;}
            case "decimalpos": {if (textinputproperty!=null){textinputproperty.decimalpos = Math.round(value.value)}   
                break;}
        }

        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        const textinputproperty: TextInputProperty = this.properties==null ? null : this.properties['textinput'];
        switch(field){
            case "textcolor":
            case "fillcolor":
            case "bordercolor":
            case "text": {value.datatype=7; value.value = this[field];break}

            case "fonttype":
            case "fontsize":
            case "textplacement": {value.datatype=3; value.value = this[field];break}

            case "linewidth": {value.datatype=2; value.value = this[field];break}

            case "useborder":
            case "fill":
            case "underline": {value.datatype=0; value.value = (this[field]);break}

            case "textbefore": {if (textinputproperty!=null){
                value.datatype=7; value.value = textinputproperty.textbefore
                }
                break;}
            case "textafter": {if (textinputproperty!=null){
                value.datatype=7; value.value = textinputproperty.textafter
                }
                break;}
            case "decimalpos": {if (textinputproperty!=null){
                value.datatype=2; value.value = textinputproperty.decimalpos
                }
                break;}
        }
        return value;
    }
}
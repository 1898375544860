import { SVGObject } from "../svgobject";

export class SVGChemical14 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 58.558" enable-background="new 0 0 112.5 58.558" xml:space="preserve">
        <g id="Group_Legs">
            <path fill="#4C4C4C" d="M38.626,39.865l0.563,15.201h2.365l0.563-15.201H38.626z" />
            <path fill="#4C4C4C" d="M103.604,39.865l0.675,15.201h2.253l0.676-15.201H103.604z" />
        </g>
        <g id="Group_LegWheels">
            <path d="M38.626,56.755c0.004-0.329,0.217-1.005,0.45-1.238s1.239-0.451,1.239-0.451s1.097,0.202,1.352,0.451   c0.236,0.23,0.445,0.909,0.45,1.238c0.005,0.356-0.199,1.1-0.45,1.352s-0.995,0.455-1.352,0.451   c-0.329-0.004-1.009-0.215-1.239-0.451C38.829,57.851,38.622,57.112,38.626,56.755z" />
            <path d="M103.604,56.755c0.006-0.34,0.313-1.008,0.563-1.238c0.242-0.224,1.238-0.451,1.238-0.451s0.997,0.228,1.239,0.451   c0.249,0.231,0.558,0.898,0.563,1.238c0.006,0.366-0.299,1.099-0.563,1.352c-0.238,0.228-0.909,0.451-1.239,0.451   c-0.329,0-1-0.224-1.238-0.451C103.901,57.854,103.598,57.122,103.604,56.755z" />
        </g>
        <g id="Group_Base">
            <path d="M37.388,39.865h70.382v1.125H37.388V39.865z" />
        </g>
        <g id="Group_Column">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M29.843,27.477l5.292-4.054h1.126l-5.293,4.054H29.843z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="107.5742" y1="39.7319" x2="37.4644" y2="11.797">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DEDEDE" />
                <stop offset="1" style="stop-color:#E5E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M37.388,39.865v-9.46h-4.617l4.617-18.693h70.382l4.729,18.693h-4.729v9.46H37.388z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.388,39.865v-9.46h-4.617l4.617-18.693h70.382l4.729,18.693h-4.729   v9.46H37.388" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M39.752,28.04V37.5h65.652V28.04" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M41.554,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M50.901,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M60.248,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M69.594,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.054,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M88.4,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M97.747,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M43.919,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.266,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.612,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M71.959,35.698h1.238" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M81.418,35.698h1.127" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M90.766,35.698h1.125" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M100.111,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.171,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.631,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M64.978,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.324,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M83.671,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M93.13,35.698h1.126" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M102.477,35.698h1.127" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M48.536,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.883,35.698h1.238" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M67.342,35.698h1.127" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M76.689,35.698h1.125" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M86.035,35.698h1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M95.494,35.698h1.127" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M72.635,37.5V11.711" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M33.333,28.04h6.419" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M105.404,28.04h6.419" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M39.752,37.5h65.652" />
        </g>
        <g id="Group_Feeder">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M1.465,30.179l-1.126-0.45l-0.338-1.126l0.338-1.013l1.126-0.451   h32.112l-0.751,3.04H1.465z" />
            <path d="M1.53,29.841l-0.901-0.337l-0.338-0.901l0.338-0.788l0.901-0.338h31.804l-0.556,2.251L1.53,29.841z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,28.04h32.769v3.604H56.194V28.04z" />
            <path d="M56.194,28.603h28.152v2.478H56.194V28.603z" />
            <path d="M53.829,28.603h-4.617v2.478h4.617V28.603z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M49.212,29.841h35.134" />
        </g>
        <g id="Group_Rotater">
            <path d="M60.811,11.711V3.491h9.459v8.221H60.811z" />
            <path d="M63.177,0.106v2.928h1.238v0.563h2.252V3.034h1.239V0.106H63.177z" />
        </g>
        <g id="Group_Dryer">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M65.541,11.711V6.982h14.076v4.729H65.541z" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M76.126,7.545v3.491" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M73.761,7.545v3.491" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M66.104,7.545h4.729" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M66.104,8.108h4.729" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M66.104,9.347h4.729" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M66.104,11.036h4.729" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M71.396,7.545h1.801" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M71.396,8.108h1.801" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M71.396,8.784h1.801" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M71.396,10.473h1.801" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,7.545h1.802" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,8.108h1.802" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,8.671h1.802" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,9.234h1.802" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,10.473h1.802" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M77.252,11.036h1.802" />
        </g>
        <g id="Group_Pipe">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="59.6846" y1="9.2339" x2="59.6846" y2="5.8637">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M58.559,9.234V5.856h2.252v3.378H58.559z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M58.559,9.234V5.856h2.252v3.378H58.559z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.40625, 151.0, 0.5, 0.265625, 187.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Legs",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Column",[0.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_Feeder",[0.0, 0.40625, 151.0, 1.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Dryer",[0.0, 0.5885416666666666, 0.0]);
		
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { StringUtils } from "../utils/stringutils";
import authModule from "@/store/auth.module";
import eventsdatarequest from '@/model/requests/eventsdatarequest'
import tagsService from "@/services/tags.service";
import  eventsdata from "@/model/eventsdata"
import { Value } from "../value";
import screensModule from "@/store/screens.module";


export class EventsTicker extends ObjectView{
    defaulttext:string;
    beginpriority:number;
    endpriority:number;
    speed:number;
    fonttype:number;
    fontsize:number;
    textcolor:string;
    useborder:boolean;
    bordercolor:string;
    linewidth:number;
    fill:boolean;
    fillcolor:string;
    underline:boolean;

    eventsdata:eventsdata[];
    eventsnum:number
    moveanim:Konva.Animation

    public drawObject():void{
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
        
        if (authModule.currentUser!=null){
           
            const request:eventsdatarequest = {token:authModule.currentUser.accessToken, 
               name:"", begindate:0, enddate:Date.now(), beginpriority:this.beginpriority,
             endpriority:this.endpriority, onlyactivestate:true,unack:false, id:screensModule.screendevid};
             tagsService.geteventsdata(request,(data:string)=>{
             //console.log(data);
                  
             this.eventsdata =JSON.parse(data);
             this.eventsnum=-1;
             console.log(this.eventsdata)
      
             this.drawEventsTicker(this.getNextEvent());
           }, 
             (data:string)=>{
                 console.log(data);
              })
       }
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "defaulttext": 
            case "textcolor":
            case "fillcolor":
            case "bordercolor": {this[field] = value.value;this.rotatednode=null;break;}
            
            case "beginpriority": 
            case "endpriority": 
            case "speed":
            case "fonttype":
            case "fontsize":{this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "linewidth":{this[field] = Math.round(value.value);this.rotatednode=null;break;}
           
            case "useborder":
            case "fill":
            case "underline": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "defaulttext":
            case "textcolor": 
            case "fillcolor":
            case "bordercolor":   {value.datatype=7; value.value = this[field];break}

            case "beginpriority": 
            case "endpriority": 
            case "speed":
            case "fonttype":
            case "fontsize":{value.datatype=3; value.value = this[field];break}

            case "linewidth":{value.datatype=2; value.value = this[field];break}

            case "useborder":
            case "fill": 
            case "underline": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }

    private getNextEvent():string{
        if (this.eventsdata==null || this.eventsdata.length==0)
            return this.defaulttext
        this.eventsnum++;
        if (this.eventsnum>=this.eventsdata.length)
            this.eventsnum=0
        const event = this.eventsdata[this.eventsnum]
        if (event==null) {
            this.eventsnum=-1
            return this.defaulttext
        }
        return event.message
    }

    private drawEventsTicker(eventmessage:string){
        super.initObject();
        let borderwidth = 0;
        let color = '0xffffff00';
        if (this.useborder){
            color = this.bordercolor;
            const lineprop = this.properties==null?null:this.properties['linecolor'];
            if (lineprop!=null){	
                const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
                if (linecolorproperty!=null) 
                    color = linecolorproperty.getColor(this.tagnames, this.bordercolor)
            }
            borderwidth = this.linewidth;
        }
        const bordercolor:string = ColorUtils.convertformat(color);

        let fillcolor = '0xffffff00';
        if (this.fill){
            fillcolor = this.fillcolor;
            const fillprop =this.properties==null?null:this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        }
        const fillcolor2:string = ColorUtils.convertformat(fillcolor);
		const rect = new Konva.Rect({
            x: borderwidth/2,
            y: borderwidth/2,
            width: this.width-borderwidth,
            height: this.height-borderwidth,
            fill: fillcolor2,
            stroke: bordercolor,
            strokeWidth: borderwidth,
          });
          this.node.add(rect)

         
  
          let textcolor = this.textcolor;
            const textcolorprop = this.properties==null?null:this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }

            const Text = new Konva.Text({
                x: this.linewidth,
                y: this.linewidth*2,
                verticalAlign:"middle",
                width: this.width-4*this.linewidth,
                height: this.height-2*this.linewidth,
                text: eventmessage,
                fontSize: this.fontsize,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                align:'left',
                fill: ColorUtils.convertformat(textcolor),
                textDecoration:this.underline?"underline":""
              });
              if (this.moveanim!=null)
                 this.moveanim.stop()
              this.moveanim = new Konva.Animation((frame)=> {
               // console.log(this.width)
               // console.log( Text.getTextWidth())
                Text.x(
                  
                  this.width-Text.getTextWidth() - frame.time *this.width/this.speed
                );
                if (Text.attrs.x<=this.linewidth){
                    this.moveanim.stop()
                    this.drawEventsTicker(this.getNextEvent())
                }
              }, this.layer);
        
              this.moveanim.start();
              
              this.node.add(Text);
    }
}
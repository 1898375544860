import { SVGObject } from "../svgobject";

export class SVGPump6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 55.18" enable-background="new 0 0 112.5 55.18" xml:space="preserve">
        <g id="Group_Motor_Shaft_Casing">
            <path fill="#194C4C" stroke="#4C4C4C" stroke-width="0.25" d="M35.585,27.703h5.518v16.104h-5.518V27.703z" />
        </g>
        <g id="Group_Flange">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="42.8496" y1="9.9104" x2="42.8496" y2="28.8289" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M41.104,45.27V26.352h3.491V45.27H41.104z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.793,45.27V26.352" />
        </g>
        <g id="Group_Impeller_Casing">
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,21.622h13.063v1.351H19.482V21.622z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="17.793" y1="7.2063" x2="17.793" y2="32.2068" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.041,47.973h30.18l2.365-1.688V24.663l-2.365-1.689h-0.45   H32.32H3.041l-2.59,1.689L0,26.352v0.338v0.677v17.229l1.352,2.703l1.351,0.676L3.041,47.973" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="38.3442" y1="26.4641" x2="38.3442" y2="27.4768" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#4C7F7F" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#4C6666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.585,27.703h5.518v1.013h-5.518V27.703z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="38.3442" y1="11.3733" x2="38.3442" y2="12.3859" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#4C7F7F" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#4C6666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.585,42.793h5.518v1.014h-5.518V42.793z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M35.585,35.249h5.518v1.014h-5.518V35.249z" />
        </g>
        <g id="Group_Motor_Heat_Sink_Shade">
            <path fill="#194C4C" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,22.973h21.622v25H11.599V22.973z" />
        </g>
        <g id="Group_Motor_Heat_Sink">
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,32.883h22.297v1.014H11.599V32.883z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,25.676h22.297v1.352H11.599V25.676z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,36.262h22.297v1.463H11.599V36.262z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,29.392h22.297v1.126H11.599V29.392z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,22.298h22.297v1.013H11.599V22.298z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,39.752h22.297v1.352H11.599V39.752z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,43.469h22.297v1.125H11.599V43.469z" />
        </g>
        <g id="Group_WireBox">
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M18.806,21.96h14.415v-7.996H18.806V21.96z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M19.482,21.171v-6.419h12.725" />
        </g>
        <g id="Group_Outlet_Port">
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="91.8926" y1="50.7874" x2="95.9453" y2="50.7874" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M91.893,2.365h4.053v4.054h-4.053V2.365" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="85.6982" y1="53.9973" x2="102.1387" y2="53.9973" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.698,0h16.44v2.365h-16.44V0" />
        </g>
        <g id="Group_Pump_Setup_Support">
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,46.959h22.297v1.014H11.599V46.959z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="16.4409" y1="9.7961" x2="30.5181" y2="9.7961" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M30.518,49.663v-1.689H28.04l-1.014-6.869H19.82l-1.014,6.869   h-2.365v1.689H30.518z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,49.663h107.32v5.518H0V49.663z" />
        </g>
        <g id="Group_Conneting_Channel">
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="64.9785" y1="9.5725" x2="64.9785" y2="29.8425" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#4C7F7F" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#4C6666" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,41.104l40.766,4.504v-20.27l-40.766,5.18V41.104z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,30.518l40.766-5.18v1.688l-40.766,5.181V30.518z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,39.415l40.766,4.842v1.688l-40.766-4.842V39.415z" />
        </g>
        <g id="Group_Bolt">
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="45.1016" y1="11.3733" x2="45.1016" y2="13.0618" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#4C7F7F" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#4C6666" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,42.118h1.013v1.688h-1.013V42.118z" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="45.1016" y1="26.4641" x2="45.1016" y2="28.1541" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#4C7F7F" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#4C6666" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,27.027h1.013v1.689h-1.013V27.027z" />
        </g>
        <g id="Group_Volute_Chamber">
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="93.918" y1="5.5168" x2="93.918" y2="43.9187" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.359,11.262v38.401h17.117V11.262H85.359" />
        </g>
        <g id="Group_Heat_Sink">
            <path fill="#194C4C" stroke="#4C4C4C" stroke-width="0.25" d="M102.479,6.419v5.18h-2.365v37.726H89.527V11.6h-4.168V6.42   L102.479,6.419L102.479,6.419z" />
        </g>
        <g id="Group_Pump_Heat_Sink">
            <path fill="#7FB2B2" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,38.063h10.586v2.027H89.527V38.063z" />
            <path fill="#669999" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,45.946h10.586v1.689H89.527V45.946z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,30.518h10.586v1.689H89.527V30.518z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,21.96h10.586v1.688H89.527V21.96z" />
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,13.964h10.586v2.14H89.527V13.964z" />
        </g>
        <g id="Group_Inlet_Casing">
            <path fill="#99CCCC" stroke="#4C4C4C" stroke-width="0.25" d="M107.32,25.676l-4.844-12.049v34.347l4.844-16.44V25.676z" />
            <path fill="#4C7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M107.32,27.703l-4.844-10.248v26.352l4.844-14.415V27.703z" />
        </g>
        <g id="Group_Inlet_Port">
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="111.2607" y1="18.1311" x2="111.2607" y2="35.0222" gradientTransform="matrix(1 0 0 -1 0 55.1802)">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.51" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M110.021,37.049V20.158h2.479v16.891H110.021" />
            <path fill="#336666" stroke="#4C4C4C" stroke-width="0.25" d="M110.021,25.676h-2.701v5.856h2.701V25.676z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.8541666666666666, 0.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.8541666666666666, 0.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.8541666666666666, 0.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.8541666666666666, 0.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.8541666666666666, 0.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.9375, 15.0, 0.51, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_WireBox",[0.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_Pump_Setup_Support",[0.0, 0.9375, 15.0, 1.0, 0.5364583333333334, 117.0]);

        super.drawObject();
    }
}
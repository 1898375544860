import { SVGObject } from "../svgobject";

export class SVGTank3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 87.076 112.5" enable-background="new 0 0 87.076 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="42.333" x2="87.0432" y2="42.333">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,78.587l5.74,3.903h75.925l5.378-3.914V6.09l-5.378-3.914H5.74L0,6.287l0.365,72.29" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="6.4436" y1="1.3838" x2="81.2307" y2="1.3838">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M6.444,1.826L44.941,0l36.29,1.982C55.849,3.346,17.515,2.695,6.444,1.826z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="43.3137" y1="9.6396" x2="85.3794" y2="-0.8485">
                <stop offset="0.29" style="stop-color:#F7F7F7" />
                <stop offset="0.38" style="stop-color:#ECECEC" />
                <stop offset="0.52" style="stop-color:#CFCFCF" />
                <stop offset="0.71" style="stop-color:#A0A0A0" />
                <stop offset="0.94" style="stop-color:#5F5F5F" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M81.224,1.951C70.662,2.323,46.816,2.392,42.896,2.515c-0.169,1.736-0.169,4.771-0.169,4.771   c7.153,0.124,41.003-0.55,44.35-1.329C84.46,4.06,81.224,1.951,81.224,1.951z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="42.636" y1="9.7642" x2="84.5643" y2="-0.6897" gradientTransform="matrix(-1 0 0 1 86.3267 0)">
                <stop offset="0.29" style="stop-color:#F7F7F7" />
                <stop offset="0.38" style="stop-color:#ECECEC" />
                <stop offset="0.52" style="stop-color:#CFCFCF" />
                <stop offset="0.71" style="stop-color:#A0A0A0" />
                <stop offset="0.94" style="stop-color:#5F5F5F" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M5.948,1.838c10.56,0.373,34.406,0.441,38.325,0.565c0.17,1.736,0.035,4.879,0.035,4.879   C37.157,7.407,3.348,7.157,0,6.377C2.618,4.48,5.948,1.838,5.948,1.838z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="43.5378" y1="78.4121" x2="43.5378" y2="82.875">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="0.001,78.412 0.001,78.75 5.626,82.875 81.676,82.875 87.075,78.75 87.075,78.412  " />
            <path fill="none" d="M87.075,76.725l-0.225,0.45l-3.15,0.9l-4.05,0.675l-5.175,0.45l-3.15,0.225L67.95,79.65h-3.375l-3.824,0.225   H56.7l-4.05,0.225H35.325l-4.274-0.225h-3.825l-3.825-0.225h-3.6l-3.15-0.226L13.501,79.2l-5.175-0.45l-4.051-0.675l-3.149-0.9   l-0.226-0.45" />
        </g>
        <g id="Group_TankFrontLegs">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="72.1799" y1="95.541" x2="82.7542" y2="95.541">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M82.733,81.609c0-0.792-2.326-1.477-5.265-1.477s-5.215,0.709-5.215,1.501L72.18,110.95h10.574   L82.733,81.609z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="70.3801" y1="111.6074" x2="84.5549" y2="111.6074">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="70.38" y="110.715" fill="url(#SVGID_7_)" width="14.175" height="1.785" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="4.5012" y1="95.541" x2="15.0754" y2="95.541">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M15.054,81.609c0-0.792-2.326-1.477-5.265-1.477s-5.215,0.709-5.215,1.501L4.501,110.95h10.574   L15.054,81.609z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="2.7014" y1="111.6074" x2="16.8762" y2="111.6074">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="2.701" y="110.715" fill="url(#SVGID_9_)" width="14.175" height="1.785" />
        </g>
        <g id="Group_TankBackLegs">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="57.5706" y1="93.2236" x2="66.9963" y2="93.2236">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M66.977,81.805c0-0.705-2.074-1.316-4.692-1.316c-2.62,0-4.648,0.633-4.648,1.34l-0.065,24.131   h9.426L66.977,81.805z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="55.967" y1="106.543" x2="68.5999" y2="106.543">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <rect x="55.967" y="105.748" fill="url(#SVGID_11_)" width="12.633" height="1.591" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="19.8835" y1="93.2109" x2="29.3088" y2="93.2109">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M29.29,81.792c0-0.705-2.073-1.316-4.692-1.316c-2.62,0-4.648,0.633-4.648,1.34l-0.065,24.131   h9.425L29.29,81.792z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="18.2795" y1="106.5313" x2="30.9138" y2="106.5313">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <rect x="18.28" y="105.735" fill="url(#SVGID_13_)" width="12.634" height="1.591" />
        </g>
        </svg>`
        super.fillGradients()
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.29, 0.07291666666666667, 236.0, 0.38, 0.16145833333333334, 214.0, 0.52, 0.3802083333333333, 157.0, 0.71, 0.75, 63.0, 0.94, 0.7395833333333334, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.29, 0.07291666666666667, 236.0, 0.38, 0.16145833333333334, 214.0, 0.52, 0.3802083333333333, 157.0, 0.71, 0.75, 63.0, 0.94, 0.7395833333333334, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		
		
        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { Polygon } from './polygon';
import { Value } from '../value';
const LEFT = 1;
const TOP = 2;
const RIGHT = 4;
const BOTTOM = 8;
const LEFTTOP = 3;
const TOPRIGHT = 6;
const RIGHTBOTTOM = 12;
const LEFTBOTTOM = 9;
const LEFTTOPRIGHT = 7;
const TOPRIGHTBOTTOM = 14;
const RIGHTBOTTOMLEFT = 13;
const BOTTOMLEFTTOP = 11;
const LEFTRIGHT = 5;
const TOPBOTTOM = 10;
const ALLSIDES = 15;
export class PolyPipe extends Polygon{
    type3d:number;
   
    public drawObject():void{
		super.initObject();
        if(this.type3d != 0) this.type3d = 1
		let fillcolor = '0xffffff00';
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
        const coordinates = this.coordinates;
        let linewidth = this.linewidth
        if (linewidth<1) linewidth=1;


        

        
              coordinates.forEach((coord, index)=>{
                
                if (index+1>=coordinates.length) return;
                const coord2 = coordinates[index+1]
                if (coord.coordy == coord2.coordy){
                    const pipewidth = Math.abs(coord2.coordx - coord.coordx)
                    if (coord.coordx<coord2.coordx){
					this.drawHorPipe(this.node, coord.coordx, coord.coordy-linewidth/2, pipewidth,linewidth,fillcolor2, this.type3d);
                    }else{
					this.drawHorPipe(this.node,coord2.coordx, coord.coordy-linewidth/2, pipewidth,linewidth,fillcolor2, this.type3d);
                    }
                }else if(coord.coordx == coord2.coordx){
                    const pipeheight = Math.abs(coord2.coordy - coord.coordy)

                    if (coord.coordy<coord2.coordy){
					this.drawVerPipe(this.node,coord.coordx-linewidth/2, coord.coordy,linewidth, pipeheight,fillcolor2, this.type3d);
                    }else{
					this.drawVerPipe(this.node,coord.coordx-linewidth/2,coord2.coordy,linewidth, pipeheight, fillcolor2, this.type3d)
                    }

                }   
              })
              let nodes=[];
              nodes = coordinates.filter((value, index, self)=>{
                return self.findIndex(v=>v.coordx==value.coordx && v.coordy==value.coordy)===index;
              })
             
              nodes.forEach((nodescoord)=>{
                const side = this.checkSides(coordinates, nodescoord)
                nodescoord['side'] = side
            
                switch (nodescoord.side) {
                    case LEFT:
					this.drawHorOneSidePipe(this.node, nodescoord.coordx-linewidth/4, 
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					break;
                    case TOP:
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
							nodescoord.coordy-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                    case RIGHT:
					this.drawHorOneSidePipe(this.node, nodescoord.coordx,
                            nodescoord.coordy-linewidth*0.75, linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					break;
                    case BOTTOM:
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
							nodescoord.coordy,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                    case LEFTRIGHT:
					break;
                    case LEFTTOPRIGHT:
					this.drawHorPipe(this.node, nodescoord.coordx-linewidth,
							nodescoord.coordy-linewidth/2, 2*linewidth,linewidth,fillcolor2, this.type3d);		
					this.drawLeftTopRightPipe(this.node,nodescoord.coordx-linewidth/2,
                            nodescoord.coordy-linewidth,linewidth, linewidth,fillcolor2,this.type3d);			
					this.drawHorOneSidePipe(this.node, nodescoord.coordx-linewidth-linewidth/4,
                            nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);				
					this.drawHorOneSidePipe(this.node,nodescoord.coordx+linewidth,
                            nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);		
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                            nodescoord.coordy-linewidth-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                   case LEFTTOP:
					this.drawHorPipe(this.node,nodescoord.coordx-linewidth/2,
                            nodescoord.coordy-linewidth/2,linewidth,linewidth,fillcolor2,this.type3d);
					this.drawLeftTopPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx-linewidth-linewidth/4,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
                            nodescoord.coordy-linewidth-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                    case TOPRIGHT:
					this.drawVerPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth/2,linewidth,linewidth,fillcolor2,this.type3d);
					this.drawTopRightPipe(this.node, nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth/2,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx+linewidth,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy-linewidth-linewidth/4, linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                    case RIGHTBOTTOM:
					this.drawVerPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth/2,linewidth,linewidth,fillcolor2,this.type3d);
					this.drawRightBottomPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth/2,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawHorOneSidePipe(this.node, nodescoord.coordx+linewidth,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                    case LEFTBOTTOM:
					this.drawHorPipe(this.node, nodescoord.coordx-linewidth/2,
						nodescoord.coordy-linewidth/2,linewidth,linewidth,fillcolor2,this.type3d);
					this.drawLeftBottomPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth/2,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx-linewidth-linewidth/4,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                   case TOPRIGHTBOTTOM:
					this.drawVerPipe(this.node, nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth,linewidth,2*linewidth,fillcolor2,this.type3d);
					this.drawTopRightBottomPipe(this.node,nodescoord.coordx,
                        nodescoord.coordy-linewidth/2,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy-linewidth-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx+linewidth,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					break;
                    case RIGHTBOTTOMLEFT:
					this.drawHorPipe(this.node,nodescoord.coordx-linewidth,
                        nodescoord.coordy-linewidth/2,2*linewidth,linewidth,fillcolor2,this.type3d);
                    this.drawLeftBottomRightPipe(this.node, nodescoord.coordx-linewidth/2,
                        nodescoord.coordy,linewidth, linewidth,fillcolor2,this.type3d);
                    this.drawHorOneSidePipe(this.node, nodescoord.coordx-linewidth-linewidth/4,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
                    this.drawHorOneSidePipe(this.node,nodescoord.coordx+linewidth,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
                    this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
                    break;
                    case BOTTOMLEFTTOP:
					this.drawVerPipe(this.node, nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth,linewidth,2*linewidth,fillcolor2,this.type3d);
					this.drawBottomLeftTopPipe(this.node, nodescoord.coordx-linewidth,
                        nodescoord.coordy-linewidth/2,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy-linewidth-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx-linewidth-linewidth/4,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					break;
                    case TOPBOTTOM:
					break;
				case ALLSIDES:
					this.drawHorPipe(this.node,nodescoord.coordx-linewidth,
                        nodescoord.coordy-linewidth/2, 2*linewidth,linewidth,fillcolor2,this.type3d);
					this.drawLeftBottomRightPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawLeftTopRightPipe(this.node,nodescoord.coordx-linewidth/2,
                        nodescoord.coordy-linewidth,linewidth, linewidth,fillcolor2,this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx-linewidth-linewidth/4,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawHorOneSidePipe(this.node,nodescoord.coordx+linewidth,
                        nodescoord.coordy-linewidth*0.75,linewidth/4, linewidth*1.5, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node, nodescoord.coordx-linewidth*0.75, 
                        nodescoord.coordy+linewidth,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					this.drawVerOneSidePipe(this.node,nodescoord.coordx-linewidth*0.75,
                        nodescoord.coordy-linewidth-linewidth/4,linewidth*1.5, linewidth/4, fillcolor2, this.type3d);
					break;
                  }
              })   
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "type3d": {value.datatype=1; value.value = this[field];break}
        }
        return value;
    }


  /*  private checkCoordinates(el1, el2):boolean{
        let equal = false;
        
            if(el1.coordx == el2.coordx && el1.coordy == el2.coordy) {
                
                equal = true
                return 
            }
        
       return equal;
    }*/
    private checkSides(coordinates, nodescoord):number{
        let side = 0;
        coordinates.forEach((coord, index)=>{
            
            if(nodescoord.coordx ==coord.coordx && nodescoord.coordy == coord.coordy){
                if(index-1>=0) side = (side | this.checkNeighbour(nodescoord, coordinates[index-1]))
                if(index+1<coordinates.length) side = (side | this.checkNeighbour(nodescoord, coordinates[index+1]))
            }

        })
        return side
    }
		
    private checkNeighbour(c2, c1):number{
        let side =0;
    
		if (c1.coordx==c2.coordx){
			if (c2.coordy>c1.coordy)
				side =  (side | TOP);
			if (c2.coordy<c1.coordy)
				side = (side | BOTTOM);
		}
		if (c1.coordy==c2.coordy){
			if (c2.coordx>c1.coordx)
				side = (side | LEFT);
			if (c2.coordx<c1.coordx)
				side = (side | RIGHT);
		}
		return side;
    }

    private drawHorOneSidePipe(pipeline, coordx, coordy, width, height:number, fillcolor2, type3d) {
		const pipegroup = new Konva.Group()
        const pipe = new Konva.Rect({
            x: 0+coordx,
            y: 0+coordy,
            width: width,
            height: height,
            //stroke: 'black',
            //strokeWidth:1
          })
          const stripe = new Konva.Rect({
            x: 0+coordx,
            y: 0+coordy,
            width: width/5,
            height: height,
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2,0.1),
            strokeWidth:1 
          })
          const stripe2 = new Konva.Rect({
            x: width*4/5+coordx,
            y: 0+coordy,
            width: width/5,
            height: height,
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2,0.1),
            strokeWidth:1 
          })
          this.drawHorGrad(pipe, 0, height, fillcolor2, type3d)
          pipegroup.add(pipe, stripe, stripe2)
          pipeline.add(pipegroup)
    }

    private drawVerOneSidePipe(pipeline, coordx, coordy, width, height, fillcolor2, type3d) {
        const pipegroup = new Konva.Group()
        const pipe = new Konva.Rect({
            x: 0+coordx,
            y: 0+coordy,
            width: width,
            height: height,
            //stroke: 'black',
            //strokeWidth:1
          })
          const stripe = new Konva.Rect({
            x: 0+coordx,
            y: 0+coordy,
            width: width,
            height: height/5,
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2,0.1),
            strokeWidth:1 
          })
          const stripe2 = new Konva.Rect({
            x: 0+coordx,
            y: height*4/5+coordy,
            width: width,
            height: height/5,
            stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2,0.1),
            strokeWidth:1 
          })
          //this.drawVertGrad(pipe, 0, width, fillcolor2, type3d)
          pipegroup.add(pipe, stripe, stripe2)
          this.drawVertGrad(pipe, 0, width, fillcolor2, type3d)
          pipeline.add(pipegroup)
    }

    private drawHorPipe(pipeline, coordx, coordy, width, height, fillcolor2, type3d) {
            const pipe = new Konva.Rect({
                x: 0+coordx,
                y: 0+coordy,
                width: width,
                height: height,
               //stroke: 'black',
                //strokeWidth:1
              })
              this.drawHorGrad(pipe, 0, height, fillcolor2, type3d)
              pipeline.add(pipe);
    }

    private drawVerPipe(pipeline, coordx, coordy, width, height, fillcolor2,type3d) {
        const pipe = new Konva.Rect({
            x: 0+coordx,
            y: 0+coordy,
            width: width,
            height: height,
            //stroke: 'black',
            //strokeWidth:1
          })
          this.drawVertGrad(pipe, 0, width, fillcolor2, type3d)
          pipeline.add(pipe)
    }

    private drawLeftBottomRightPipe(pipeline, coordx, coordy, width, height, fillcolor2,type3d) {
        const pipe= new Konva.Line({
            points: [width/2+coordx, 0+coordy,
            width+coordx, height/2+coordy,
            width+coordx, height+coordy,
            0+coordx, height+coordy,
            0+coordx, height/2+coordy,
            width/2+coordx, 0+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawVertGrad(pipe, coordx, width+coordx, fillcolor2, type3d)
        pipeline.add(pipe)
	}

    private drawBottomLeftTopPipe(pipeline, coordx, coordy, width,  height, fillcolor2, type3d) {
            const pipe= new Konva.Line({
                points: [width+coordx, height/2+coordy,
                width/2+coordx, height+coordy,
                0+coordx, height+coordy,
                0+coordx, 0+coordy,
                width/2+coordx, 0+coordy,
                width+coordx, height/2+coordy],
                closed: true,
                //stroke: 'black',
                //strokeWidth: 1
            });
            this.drawHorGrad(pipe, coordy, height+coordy, fillcolor2, type3d)
            pipeline.add(pipe) 
    }

    private drawTopRightBottomPipe(pipeline, coordx, coordy, width, height, fillcolor2, type3d) {
            const pipe= new Konva.Line({
                points: [0+coordx, height/2+coordy,
                width/2+coordx, height+coordy,
                width+coordx, height+coordy,
                width+coordx, 0+coordy,
                width/2+coordx, 0+coordy,
                0+coordx, height/2+coordy],
                closed: true,
                //stroke: 'black',
                //strokeWidth: 1
            });
            this.drawHorGrad(pipe, coordy, height+coordy, fillcolor2, type3d)
            pipeline.add(pipe)
    }

    private drawLeftTopPipe(pipeline, coordx, coordy, width, height, fillcolor2, type3d) {
        const pipe= new Konva.Line({
            points: [width+coordx, height*1.5+coordy,
            width+coordx, 0+coordy,
            0+coordx, 0+coordy,
            0+coordx, height*0.5+coordy,
            width+coordx, height*1.5+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawVertGrad(pipe, coordx, width+coordx, fillcolor2, type3d)
        pipeline.add(pipe)
	}

    private drawLeftBottomPipe(pipeline, coordx, coordy, width, height, fillcolor2,type3d) {
        const pipe= new Konva.Line({
            points: [width+coordx, 0+coordy,
            width+coordx, height*1.5+coordy,
            0+coordx, height*1.5+coordy,
            0+coordx, height+coordy,
            width+coordx, 0+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawVertGrad(pipe, coordx, width+coordx, fillcolor2, type3d)
        pipeline.add(pipe)
    }

    private drawRightBottomPipe(pipeline, coordx, coordy, width, height, fillcolor2,type3d) {
        const pipe= new Konva.Line({
            points: [width*1.5+coordx, 0+coordy,
            width*1.5+coordx, height+coordy,
            width+coordx, height+coordy,
            0+coordx, 0+coordy,
            width*1.5+coordx, 0+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawHorGrad(pipe, coordy, height+coordy, fillcolor2, type3d)
        pipeline.add(pipe)
	}

    private drawTopRightPipe(pipeline, coordx, coordy, width, height,  fillcolor2,type3d) {
        const pipe= new Konva.Line({
            points: [width+coordx, 0+coordy,
            0+coordx, height+coordy,
            width*1.5+coordx, height+coordy,
            width*1.5+coordx, 0+coordy,
            width+coordx, 0+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawHorGrad(pipe, coordy, height+coordy, fillcolor2, type3d)
        pipeline.add(pipe)
	}

    private drawLeftTopRightPipe(pipeline, coordx, coordy, width, height, fillcolor2,type3d) {
        const pipe= new Konva.Line({
            points: [width/2+coordx, height+coordy,
            width+coordx, height/2+coordy,
            width+coordx, 0+coordy,
            0+coordx, 0+coordy,
            0+coordx, height/2+coordy,
            width/2+coordx, height+coordy],
            closed: true,
            //stroke: 'black',
            //strokeWidth: 1
        });
        this.drawVertGrad(pipe, coordx, width+coordx, fillcolor2, type3d)
        pipeline.add(pipe)
    }
           
    private drawHorGrad(pipe, coordy, height, fillcolor2, type3d) {
		if (type3d==0){
            pipe.fillLinearGradientStartPoint({ x: 0, y: coordy });
            pipe.fillLinearGradientEndPoint({ x: 0, y: height });
            pipe.fillLinearGradientColorStops([0, fillcolor2, 
                0.15, 'white', 
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
			}
			else {
				pipe.fill(fillcolor2);
				pipe.stroke(ColorUtils.darkColor(fillcolor2,0.1));
                pipe.strokeWidth(1)
			}
	}

    private drawVertGrad(pipe, coordx, width, fillcolor2, type3d) {
		if (type3d==0){
            pipe.fillLinearGradientStartPoint({ x: coordx, y: 0 });
            pipe.fillLinearGradientEndPoint({ x: width, y: 0 });
            pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75), 
                0.5, 'white', 
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
			}else{
				pipe.fill(fillcolor2);
				pipe.stroke(ColorUtils.darkColor(fillcolor2,0.1));
                pipe.strokeWidth(1)
			}
	}
}
import { SVGObject } from "../svgobject";

export class SVGTank60 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.959 112.5" enable-background="new 0 0 74.959 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="56.25" x2="74.959" y2="56.25">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.03" style="stop-color:#424242" />
            <stop offset="0.26" style="stop-color:#9D9D9D" />
            <stop offset="0.43" style="stop-color:#D6D6D6" />
            <stop offset="0.51" style="stop-color:#ECECEC" />
            <stop offset="0.57" style="stop-color:#DFDFDF" />
            <stop offset="0.67" style="stop-color:#BFBFBF" />
            <stop offset="0.81" style="stop-color:#8B8B8B" />
            <stop offset="0.98" style="stop-color:#434343" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M74.959,100.774V11.726L36.448,9.302L0,11.726v89.049l37.479,2.424L74.959,100.774z" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.711,100.774V11.726" />
        <radialGradient id="SVGID_2_" cx="37.4795" cy="5.9893" r="26.8379" gradientTransform="matrix(1 0 0 1.3 0 -1.7968)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#ECECEC" />
            <stop offset="0.14" style="stop-color:#DDDDDD" />
            <stop offset="0.4" style="stop-color:#B8B8B8" />
            <stop offset="0.75" style="stop-color:#7C7C7C" />
            <stop offset="1" style="stop-color:#4D4D4D" />
        </radialGradient>
        <path fill="url(#SVGID_2_)" d="M74.959,11.976v-0.25c0,0-1.417-11.723-35.62-11.723c-1.438,0-4.311,0-5.748,0  C4.622,0.002,0,11.162,0,11.162v0.814H74.959z" />
        <radialGradient id="SVGID_3_" cx="37.4795" cy="-7.875" r="26.8379" gradientTransform="matrix(1 0 0 1.3 0 116.7491)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#ECECEC" />
            <stop offset="0.14" style="stop-color:#DDDDDD" />
            <stop offset="0.4" style="stop-color:#B8B8B8" />
            <stop offset="0.75" style="stop-color:#7C7C7C" />
            <stop offset="1" style="stop-color:#4D4D4D" />
        </radialGradient>
        <path fill="url(#SVGID_3_)" d="M0,100.525v0.25c0,0,1.417,11.723,35.62,11.723c1.438,0,4.311,0,5.748,0  c28.969,0,33.591-11.159,33.591-11.159v-0.813H0z" />
        <path fill="#7F7F7F" d="M4.622,8.118h2.367v2.367H4.622V8.118z" />
        <path fill="#7F7F7F" d="M11.61,8.118h2.367v2.367H11.61V8.118z" />
        <path fill="#7F7F7F" d="M18.711,8.118h2.367v2.367h-2.367V8.118z" />
        <path fill="#7F7F7F" d="M25.7,8.118h2.367v2.367H25.7V8.118z" />
        <path fill="#7F7F7F" d="M32.689,8.118h2.367v2.367h-2.367V8.118z" />
        <path fill="#7F7F7F" d="M39.79,8.118h2.367v2.367H39.79V8.118z" />
        <path fill="#7F7F7F" d="M46.892,8.118h2.367v2.367h-2.367V8.118z" />
        <path fill="#7F7F7F" d="M53.88,8.118h2.367v2.367H53.88V8.118z" />
        <path fill="#7F7F7F" d="M60.869,8.118h2.367v2.367h-2.367V8.118z" />
        <path fill="#7F7F7F" d="M67.971,8.118h2.367v2.367h-2.367V8.118z" />
        <path fill="#7F7F7F" d="M20.515,99.647V97.28h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,92.658v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,85.558v-2.255h2.367v2.255H20.515z" />
        <path fill="#7F7F7F" d="M20.515,78.568v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,71.58v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,64.479v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,57.49v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,50.501v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,43.4v-2.367h2.367V43.4H20.515z" />
        <path fill="#7F7F7F" d="M20.515,36.411v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,29.31v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,22.321v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M20.515,15.22v-2.367h2.367v2.367H20.515z" />
        <path fill="#7F7F7F" d="M4.622,102.015h2.367v2.367H4.622V102.015z" />
        <path fill="#7F7F7F" d="M11.61,102.015h2.367v2.367H11.61V102.015z" />
        <path fill="#7F7F7F" d="M18.711,102.015h2.367v2.367h-2.367V102.015z" />
        <path fill="#7F7F7F" d="M25.7,102.015h2.367v2.367H25.7V102.015z" />
        <path fill="#7F7F7F" d="M32.689,102.015h2.367v2.367h-2.367V102.015z" />
        <path fill="#7F7F7F" d="M39.79,102.015h2.367v2.367H39.79V102.015z" />
        <path fill="#7F7F7F" d="M46.892,102.015h2.367v2.367h-2.367V102.015z" />
        <path fill="#7F7F7F" d="M53.88,102.015h2.367v2.367H53.88V102.015z" />
        <path fill="#7F7F7F" d="M60.869,102.015h2.367v2.367h-2.367V102.015z" />
        <path fill="#7F7F7F" d="M67.971,102.015h2.367v2.367h-2.367V102.015z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.03, 0.5104166666666666, 0.0, 0.26, 0.7760416666666666, 57.0, 0.43, 0.328125, 171.0, 0.51, 0.16145833333333334, 214.0, 0.57, 0.2552083333333333, 189.0, 0.67, 0.5052083333333334, 125.0, 0.81, 0.9166666666666666, 21.0, 0.98, 0.5208333333333334, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 107.09 112.5" enable-background="new 0 0 107.09 112.5" xml:space="preserve">
        <g id="Group_Bucket_sub">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.856" y1="62.2715" x2="101.2363" y2="62.2715">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B3B3B3" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M90.652,106.531c0,0-3.98,5.292-37.162,5.292c-34.319,0-37.049-5.292-37.049-5.292L5.856,12.72   h95.38L90.652,106.531z" />
            <path fill="#CCCCCC" d="M106.475,6.032c0,0.975-16.428,10.41-52.928,10.41c-38.166,0-53.081-9.437-53.039-10.41   C0.563,4.73,9.296,0.564,53.49,0.564C92.922,0.564,106.475,4.186,106.475,6.032z" />
            <path fill="#4C4C4C" d="M102.645,7.656c0,2.309-22.006,3.852-49.154,3.852c-27.147,0-49.155-1.385-49.155-3.693   c0-2.305,22.007-4.66,49.155-4.66C80.639,3.154,102.645,5.351,102.645,7.656z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.563" y1="12.7197" x2="106.5293" y2="12.7197">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0.563,12.72V5.856c0,0,5.293,5.293,53.984,5.293c46.689,0,51.982-5.293,51.982-5.293v5.651   c0,0-9.529,8.075-51.649,8.075C9.25,19.583,0.563,12.72,0.563,12.72z" />
        </g>
        <g id="Group_Handle">
            <path fill="none" stroke="#4C4C4C" stroke-width="2" d="M103.828,13.031c0,0,0,71.265-50.337,71.265S3.153,14.003,3.153,14.003" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="53.5464" y1="86.0352" x2="53.5464" y2="80.7422">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M42.905,80.742h21.283v5.293H42.905V80.742z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "../properties/fillcolorproperty";
import { SwitchControlProperty } from "../properties/switchcontrolproperty";
import { SwitchTile } from "./switchtile";
import { ButtonControlProperty } from "../properties/buttoncontrolproperty";

export class ButtonTile extends SwitchTile{

    public drawObject():void{
      
	super.initObject();

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let fillcolor = '0xffffff00';         
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
      const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const text2 = new Konva.Text({
        x: size*0.05,
        y: this.height - size * 0.05 - 0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05*2,
        height: 0.06*size,
        text: this.text,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(text2)

    const but = new Konva.Rect({
        x: this.width/2-size*.26,
        y: this.height/2-size*.135,
        width: size*.52,
        height: size*.27,
        cornerRadius: size/30,
        fill: ColorUtils.convertformat(textcolor)
    });
    group.add(but)

    const button = new Konva.Rect({
        x: this.width/2-size*.25,
        y: this.height/2-size*.125,
        width: size*.5,
        height: size*.25,
        cornerRadius: size/30,
        fill: ColorUtils.convertformat(fillcolor)
    });
    group.add(button)

    const textob = new Konva.Text({
        x: this.width/2-size*.25,
        y: this.height/2-size*.125,
        verticalAlign:"middle",
        width: size*.5,
        height: size*.25,
        text: this.description,
        fontSize: size*.15,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'center'
    });  
    group.add(textob)
    

    this.node.add(group)
    this.setFunctions();   
    }

    protected setFunctions():void{
        const controlprop = this.properties['buttoncontrol'];
        if (controlprop!=null){
          const controlproperty:ButtonControlProperty=Object.assign(new ButtonControlProperty(), controlprop);
          if (controlproperty!=null){
              this.node.on('mousedown touchstart', function () {
                     controlproperty.setClick();
                });
              this.node.on('mouseup touchend', function () {
                 controlproperty.setReleased();
            });
          }
        }
      }
}
import { SVGObject } from "../svgobject";

export class SVGChemical6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.08 112.5" enable-background="new 0 0 56.08 112.5" xml:space="preserve">
        <g id="Group_SupporterBack">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M44.707,22.185l-12.5,17.567h-1.689l12.5-17.567H44.707z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M11.261,22.185l12.612,17.567h1.577l-12.5-17.567H11.261z" />
        </g>
        <g id="Group_Column">
            <path fill="#7F7F7F" d="M19.82,3.491h16.441V112.5H19.82V3.491z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.8469" y1="57.9961" x2="34.1213" y2="57.9961">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M21.847,3.491h12.274V112.5H21.847V3.491z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.82,3.491h16.441V112.5H19.82V3.491" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.82,28.041h16.441" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.82,70.27h16.441" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M19.82,84.347h16.441" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M19.82,98.424h16.441" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M19.82,42.117h16.441" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M19.82,56.194h16.441" />
        </g>
        <g id="Group_SupporterFront">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M51.464,22.185L33.896,46.847h-2.364l17.567-24.662H51.464z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M4.504,22.185l17.681,24.662h2.252L6.869,22.185H4.504z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M4.617,19.708h46.847v2.365H4.617V19.708z" />
        </g>
        <g id="Group_Grills">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M35.923,1.014L20.721,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M31.194,1.014L16.216,15.878" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M4.392,0.789L2.14,2.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M8.897,0.789L2.14,7.433" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.401,1.014L2.14,12.275" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.906,1.014L2.702,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.409,1.014L7.207,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.914,1.014L11.711,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.14,14.527l1.688,1.689" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.14,10.023l6.193,6.193" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.14,5.293l10.698,10.585" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.654,1.014l15.202,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.149,1.014l15.09,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M6.644,1.014l15.09,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.14,1.014l15.202,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.828,14.527l-1.689,1.689" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.828,10.023l-6.193,6.193" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.828,5.293L43.13,15.878" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.202,1.014l-15.09,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M44.82,1.014L29.73,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.324,1.014L34.234,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.828,1.014L38.626,16.216" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.157,1.126l15.09,15.09" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.775,1.014L39.64,15.878" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M51.576,0.789l2.252,2.14" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M47.072,0.789l6.757,6.644" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.568,1.014l11.261,11.261" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.063,1.014l15.09,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M33.559,1.014l15.09,15.203" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M29.054,1.014l15.09,15.203" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,16.216V0h53.94v16.216h-1.126V1.014H2.14v6.194h51.688v1.126   H2.14v7.883H1.014z" />
            <path fill="#E5E5E5" d="M1.352,15.991V0.226h53.265v15.766h-0.563V0.789H1.914v6.757h52.252v0.563H1.914v7.883H1.352z" />
            <path fill="#8C8C8C" stroke="#4C4C4C" stroke-width="0.25" d="M0,16.216h56.08v3.491H0V16.216z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Column",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_SupporterFront",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Grills",[0.0, 0.9895833333333334, 0.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.90625, 23.0]);

        super.drawObject();
    }
}
import Konva from "konva";
import { RotationProperty } from "./properties/rotationproperty";
import { MotionProperty } from "./properties/motionproperty";
import { FlashProperty } from "./properties/flashproperty";
import { VisibilityProperty } from "./properties/visibilityproperty";

import tag from '@/model/tag';
import { Value } from "../value";
import { Script } from "../script";
import UDProperty from "@/model/udpproperty";
import { Screen } from "../screen";
import { GroupView } from "./group";

//import {DurationRange} from "./properties/range/durationrange";

export class ObjectView{
    name:string;
    posx:number;
    posy:number;
    height:number;
    width:number;
    angle:number;
    tagnames:string[]=[];
    scripts2:Script[];
    udproperties:UDProperty[]
    update =false;
    invisible=false;
    screen:string;
    group:GroupView;


    properties: Map<string, string>;


    node:Konva.Group;
    rotatednode:Konva.Group;
    layer:Konva.Layer;
    anim:Konva.Animation;
    
    public isUpdate():boolean{
        return this.update;
    }
    public setUpdate(u:boolean):void{
        this.update=u;
    }
    public initLayer(layer:Konva.Layer):void{
      this.layer = layer;
      

    }
    public drawObject():void{
      this.update=false;
      return;
    }
    
    public executeOnCreateScripts(screen:Screen){
      if (this.scripts2!=null && this.scripts2.length>0){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype<2)
           scr.runSTScript(screen, this)
        }))
      }
    }
    public executeOnDataChangeScripts(screen:Screen,tag:tag){
      if (this.scripts2!=null){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype==0 && scr.tagnames.includes(tag.name))
            scr.runSTScript(screen, this)
        }))
      }
    }
    public executeOnDestroyScripts(screen:Screen){
      if (this.scripts2!=null){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype==2)
           scr.runSTScript(screen, this)
        }))
      }
    }
    public executeClickScripts(screen:Screen){
      if (this.scripts2!=null){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype==3)
            scr.runSTScript(screen, this)
        }))
      }
    }
    public executeOnMouseEnteredScripts(screen:Screen){
      if (this.scripts2!=null){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype==4)
            scr.runSTScript(screen, this)
        }))
      }
    }
    public executeOnMouseExitedScripts(screen:Screen){
      if (this.scripts2!=null){
        this.scripts2.forEach((scr=>{
          if (scr!=null && scr.extype==5)
            scr.runSTScript(screen, this)
        }))
      }
    }
    public deleteObject():void{
      
      this.tagnames.splice(0,this.tagnames.length)
      return
    }
    public initObject():void{
        if (this.node!=null)
          this.node.destroy()
        let shiftx=0;
        let shifty=0;
        switch (this.angle) {
            case 90:{
                shiftx=0;
                shifty=this.height;
                break;
            }
            case 180:{
                shiftx=this.width;
                shifty=this.height;
                break;
            }
            case 270:{
                shiftx=this.width;
                shifty=0;
                break;
            }
        
            default:
                break;
        }
        this.node = new Konva.Group({
            x: 0,
            y: 0,
            rotation:this.angle,
            offsetX: shiftx,
            offsetY: shifty
        });
 
        if (this.rotatednode==null){  
        this.rotatednode =new Konva.Group({
            x: this.posx,
            y: this.posy,
        }) 
          
      }
      else this.rotatednode.removeChildren()
     if (this.layer!=null)
      this.layer.add(this.rotatednode);
      this.rotatednode.add(this.node);
        //this.layer.add(this.rotatednode)
       
        this.CheckDynamicRotation();
        this.CheckDynamicMotion();
        this.CheckFlash();
        this.CheckVisibility(); 
       // this.CheckFunctions(this);
    }
    public CheckFunctions(screen:Screen, object:ObjectView){
     if (this.rotatednode==null) return;
      this.rotatednode.on('mousedown touchstart', function () {
          if (object!=null)
            object.executeClickScripts(screen);
        });
      this.rotatednode.on('mouseenter', function () {
            if (object!=null)
              object.executeOnMouseEnteredScripts(screen)
        });
  
        this.rotatednode.on('mouseleave', function () {
            if (object!=null)
              object.executeOnMouseExitedScripts(screen)
        });
  
    }
    private CheckDynamicRotation():void{
        if (this.properties==null) return;
        const rotproperty = this.properties['rotation'];
        if (rotproperty==null) return;
        const rotationproperty:RotationProperty =Object.assign(new RotationProperty(),rotproperty);     
        if (rotationproperty==null) return;
        
       
      const angle = rotationproperty.getAngle(this.tagnames);
      const angleRadians:number = angle * Math.PI / 180;
      const x =
      rotationproperty.pivotx + this.posx +
      - rotationproperty.pivotx * Math.cos(angleRadians) -
      - rotationproperty.pivoty * Math.sin(angleRadians);
      const y =
      rotationproperty.pivoty + this.posy+
      - rotationproperty.pivotx * Math.sin(angleRadians) +
      - rotationproperty.pivoty * Math.cos(angleRadians);
      // move the rotated shape in relation to the rotation point.
      this.rotatednode.position({x: x, y: y});
      this.rotatednode.rotation(angle)
    }

    private CheckDynamicMotion():void{
      if (this.properties==null) return;
      const motionprop = this.properties['motion'];
      if (motionprop ==null) return;
        const motionproperty:MotionProperty =Object.assign(new MotionProperty(),motionprop);
        if (motionproperty==null) return;
      const tag:tag = motionproperty.getTag(this.tagnames);
      if (tag==null) return;
      let x =this.rotatednode.x();
      let y =this.rotatednode.y();
      motionproperty.motionrange.forEach(mrange => {
        if (mrange.from<=+tag.tagvalue && +tag.tagvalue<=mrange.to){
             x = x+mrange.motionx;
             y = y+mrange.motiony;
          }
        });
        this.rotatednode.position({x: x, y: y});
    }

    private CheckFlash():void{
      if (this.properties==null) return;
      const flashprop = this.properties['flash'];
      if (flashprop==null) return;
      const flashproperty:FlashProperty =Object.assign(new FlashProperty(), flashprop);
      if (flashproperty==null) return;
      
     const duration = flashproperty.CheckFlash(this.tagnames);
      if (this.anim!=null){
        this.anim.stop();
        this.rotatednode.opacity(1) 
      }
      if (duration==0)
        return;
    
      this.anim = new Konva.Animation(frame => {
        this.rotatednode.opacity((Math.sin(frame.time* Math.PI / duration) + 1) / 2);
      }, this.layer);
      this.anim.start();
    }

    private CheckVisibility():void{
      if (this.rotatednode==null) return;
      if (this.invisible){
        this.rotatednode.hide();
        return;
      }
      else this.rotatednode.show();
      if (this.properties==null) return;
      const visprop = this.properties['visibility'];
      if (visprop==null) return;
      const visibilityproperty:VisibilityProperty =Object.assign(new VisibilityProperty(),visprop);
      if (visibilityproperty==null) return;
      
      const visible = visibilityproperty.CheckVisibility(this.tagnames);
      if (visible)
        this.rotatednode.show();
      else
        this.rotatednode.hide();
    }

    public setField(field:string, value:Value){
        switch(field){
          case "posx": 
          case "posy": 
          case "width":
          case "height": {this[field] = parseFloat(value.value);this.rotatednode=null;break;}
          case "angle": {if (value.value==0 || value.value==90 || value.value==180 || value.value==270){
                this.angle = Math.trunc(value.value);
                } 
                break;
              }
          case "update": {if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true')
          this.update=true; break }
          case "invisible":{
            if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true')
            this.invisible=true;
            else this.invisible=false;
             break 
          }
          case "visibilityvalue": {
            const visibilityproperty:VisibilityProperty = this.properties==null ? null : this.properties['visibility'];
            if (visibilityproperty!=null){
              visibilityproperty.value = value.value;
            } 
            break
          }
          case "trueflashduration": {
            const flashproperty: FlashProperty = this.properties==null ? null : this.properties['flash'];
            if (flashproperty!=null){
              flashproperty.trueflashduration = Math.round(value.value)
            }
            break;
          }
          case "falseflashduration": {
            const flashproperty2: FlashProperty = this.properties==null ? null : this.properties['flash'];
            if (flashproperty2!=null){
              flashproperty2.falseflashduration = Math.round(value.value)
            }
            break;
          }
         
          default:
            if (this.udproperties==null || this.udproperties.length==0) return
            this.udproperties.forEach(prop=>{
              if(field==prop.property){
                prop.value = value.value
              }
            })
        }
        this.update=true;
        //this.drawObject()
       
    }

    public getField(field:string):Value{
      const value:Value = new Value();
      switch(field){
        case "posx": 
        case "posy": 
        case "width":
        case "height":{value.datatype=6; value.value = this[field];break}
        case "angle": {value.datatype=3; value.value = this[field];break}
        case "update":
        case "invisible":
          {value.datatype=0; value.value = this[field];break}
        case "screen": {value.datatype=7; value.value = this[field];break}
        case "visibilityvalue": {
          const visibilityproperty:VisibilityProperty = this.properties==null ? null : this.properties['visibility'];
          if (visibilityproperty!=null){value.datatype=7; value.value = visibilityproperty.value}
          break;
        }
        case "name":{
          value.datatype=7; value.value = this[field];break
        }
        case "trueflashduration": {
          const flashproperty: FlashProperty = this.properties==null ? null : this.properties['flash'];
            if (flashproperty!=null){
              value.datatype=3; value.value = flashproperty.trueflashduration
            }
            break;
        }
        case "falseflashduration": {
          const flashproperty2: FlashProperty = this.properties==null ? null : this.properties['flash'];
            if (flashproperty2!=null){
              value.datatype=3; value.value = flashproperty2.falseflashduration
            }
            break;
        }
        case "absposx":{
          let x = this.posx;
          let gr = this.group;
          while (gr!=null){
            x+=gr.posx;
            gr = gr.group;
          }
          value.datatype = 6;
          value.value =x;
          break;
        }
        case "absposy":{
          let y = this.posy;
          let gr = this.group;
          while (gr!=null){
            y+=gr.posy;
            gr = gr.group;
          }
          value.datatype = 6;
          value.value =y;
          break;
        }
        default:{
          if (this.udproperties==null || this.udproperties.length==0) return null
          this.udproperties.forEach(prop=>{
            if(field==prop.property){
              value.datatype=7 
              value.value = prop.value
            }
          })
        }
      }
      return value;
  }
  }



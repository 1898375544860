import { SVGObject } from "../svgobject";

export class SVGHeating16 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 84.348" enable-background="new 0 0 112.5 84.348" xml:space="preserve">
        <g id="Group_Boiler">
            <path fill="#0D2140" stroke="#4C4C4C" stroke-width="0.25" d="M13.626,70.609h50.112v3.716H13.626V70.609z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="29.3354" y1="28.0413" x2="29.3354" y2="5.5188">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M13.063,5.519h32.545v22.522H13.063V5.519z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="51.8579" y1="31.7571" x2="51.8579" y2="1.802">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#E5E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M58.107,1.802h-12.5v29.955h12.5V1.802" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="6.813" y1="31.7571" x2="6.813" y2="1.802">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#E5E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M13.063,1.802h-12.5v29.955h12.5V1.802" />
        </g>
        <g id="Group_Heat_Transfer_System">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="41.8354" y1="70.6086" x2="41.8354" y2="0.5642">
                <stop offset="0" style="stop-color:#405472" />
                <stop offset="0.09" style="stop-color:#7589A8" />
                <stop offset="0.26" style="stop-color:#8CA0BF" />
                <stop offset="0.58" style="stop-color:#4B5F7D" />
                <stop offset="1" style="stop-color:#405472" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M0.563,70.609V32.996h12.5l2.478-9.91h5.067v9.91h17.455v-9.91   h5.068l2.477,9.91h12.5V0.564h20.046v32.433h4.954v37.612H0.563z" />
            <path fill="#8CA0BF" d="M0.563,48.086v7.433h82.544v-7.433h-4.954V0.564H58.107v47.522H0.563z" />
            <path fill="#A6BAD9" d="M58.107,0.564h20.046v14.978H58.107V0.564z" />
            <path fill="#405473" d="M45.608,34.348h0.563V69.37h-0.563V34.348z" />
            <path fill="#405473" d="M20.608,34.348h0.563V69.37h-0.563V34.348z" />
            <path fill="none" stroke="#405473" stroke-width="0.25" d="M13.626,33.672V69.37" />
            <path fill="none" stroke="#405473" stroke-width="0.25" d="M38.063,33.672V69.37" />
        </g>
        <g id="Group_Blue_Pipes">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="3.1533" y1="55.8567" x2="10.5854" y2="55.8567">
                <stop offset="0" style="stop-color:#526D8C" />
                <stop offset="0.5" style="stop-color:#BFD3F2" />
                <stop offset="1" style="stop-color:#596D8C" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M3.153,65.541h7.432V46.172H3.153V65.541z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="3.1533" y1="37.7258" x2="10.5854" y2="37.7258">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.5" style="stop-color:#8CA0BF" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M3.153,46.172h7.432V29.28H3.153V46.172" />
        </g>
        <g id="Group_Support_Structure">
            <path fill="#263A59" stroke="#4C4C4C" stroke-width="0.1" d="M69.369,77.478H1.802v3.717h67.567V77.478z" />
            <path fill="#7387A6" stroke="#4C4C4C" stroke-width="0.1" d="M0.563,76.24h70.044v1.238H0.563V76.24z" />
            <path fill="#7387A6" stroke="#4C4C4C" stroke-width="0.1" d="M0.563,81.195h70.044v1.238H0.563V81.195z" />
            <path fill="#405473" stroke="#4C4C4C" stroke-width="0.1" d="M63.738,59.91h3.717V76.24h-3.717V59.91z" />
            <path fill="#405473" stroke="#4C4C4C" stroke-width="0.1" d="M38.739,59.91h3.716V76.24h-3.716V59.91z" />
            <path fill="#405473" stroke="#4C4C4C" stroke-width="0.25" d="M9.91,59.91h3.716V76.24H9.91V59.91z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M8.108,64.302h77.478v1.915H8.108V64.302z" />
            <path fill="#7387A6" stroke="#4C4C4C" stroke-width="0.1" d="M0.563,72.41h13.739v11.262H0.563V72.41z" />
        </g>
        <g id="Group_Control_Units">
            <path fill="#666666" d="M69.369,57.433h0.563v6.869h-0.563V57.433z" />
            <path fill="#333333" d="M26.802,63.74h17.567v0.563H26.802V63.74z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.1" d="M26.802,49.888h17.567V63.74H26.802V49.888z" />
            <path fill="#FFFFFF" d="M36.824,51.24h6.307v6.869h-6.307V51.24z" />
            <path fill="#CCCCCC" d="M28.041,51.24h4.392V62.5h-4.392V51.24z" />
            <path fill="#FF0000" d="M39.978,59.348h0.563v0.563h-0.563V59.348z" />
            <path fill="#FFCC00" d="M41.216,59.348h1.915v0.563h-1.915V59.348z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.1" d="M66.779,49.324h6.307v8.108h-6.307V49.324z" />
            <path fill="#A6BAD9" d="M69.933,49.888h2.478v2.59h-2.478V49.888z" />
            <path fill="#B2B2B2" d="M71.172,53.041h1.238v3.829h-1.238V53.041z" />
        </g>
        <g id="Group_Conveyor">
            <path fill="#996600" stroke="#4C4C4C" stroke-width="0.25" d="M13.626,72.41l1.915-8.108l-1.239-3.716l-3.716-1.238l-4.392,1.238   L1.802,66.78v5.631H13.626z" />
            <circle fill="#333333" stroke="#4C4C4C" stroke-width="0.25" cx="10.473" cy="64.865" r="4.279" />
            <circle fill="#999999" cx="10.585" cy="64.865" r="3.153" />
            <circle fill="#EBEBEB" cx="10.585" cy="64.865" r="0.676" />
        </g>
        <g id="Group_Pipes">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="97.4658" y1="58.1086" x2="97.4658" y2="45.6086">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M83.107,45.609v12.5h28.717v-12.5H83.107" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="87.8379" y1="23.3367" x2="87.8379" y2="13.3142">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M97.522,13.314h-4.392H78.153v10.022h14.978" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="98.0859" y1="34.8792" x2="108.1074" y2="34.8792">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <polyline fill="url(#SVGID_9_)" points="98.086,28.291 98.086,45.859 108.107,45.859 108.107,23.9  " />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="95.6084" y1="25.8137" x2="102.8154" y2="18.6067">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <polyline fill="url(#SVGID_10_)" points="108.107,23.9 97.522,13.314 93.131,23.336 98.086,28.291  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 1.0, 0.0, 0.5, 0.046875, 243.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6770833333333334, 0.0, 0.09, 0.90625, 23.0, 0.26, 0.7291666666666666, 69.0, 0.58, 0.7604166666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8541666666666666, 0.0, 0.5, 0.328125, 171.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4739583333333333, 0.0, 0.5, 0.7291666666666666, 69.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 1.0, 0.0, 0.5, 0.046875, 243.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 1.0, 0.0, 0.5, 0.046875, 243.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 1.0, 0.0, 0.5, 0.046875, 243.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 1.0, 0.0, 0.5, 0.046875, 243.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.fills,"Group_Heat_Transfer_System",[0.0, 0.7291666666666666, 69.0, 1.0, 0.5208333333333334, 121.0, 2.0, 0.6770833333333334, 0.0, 3.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Support_Structure",[0.0, 0.4739583333333333, 0.0, 1.0, 0.921875, 19.0, 2.0, 0.921875, 19.0, 3.0, 0.6770833333333334, 0.0, 4.0, 0.6770833333333334, 0.0, 5.0, 0.6770833333333334, 0.0, 6.0, 0.609375, 99.0, 7.0, 0.921875, 19.0]);
		this.fillGradient(this.fills,"Group_Control_Units",[0.0, 0.7916666666666666, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.0, 255.0, 4.0, 0.40625, 151.0, 5.0, 0.6614583333333334, 0.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.40625, 151.0, 8.0, 0.5208333333333334, 121.0, 9.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Conveyor",[0.0, 0.6614583333333334, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.16666666666666666, 212.0]);

        super.drawObject();
    }
}
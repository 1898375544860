import { SVGObject } from "../svgobject";

export class SVGMaterial10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 94.594" enable-background="new 0 0 112.5 94.594" xml:space="preserve">
        <g id="Group_Hopper">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="84.2344" y1="73.4355" x2="7.8813" y2="-2.9175">
                <stop offset="0" style="stop-color:#A6A6A6" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#A6A6A6" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.2504" d="M0,4.963l16.635,84h39.288c0,0,13.71-1.667,17.89-5.667   c4.179-4,6.185-2.666,11.368-19.833c5.183-17.167,1.17-23.167,1.17-23.167L107.75,14.63l-8.86-9.667H0z" />
        </g>
        <g id="Group_Buttons">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.91,10.36h22.41l0.563,8.671H10.586L9.91,10.36" />
            <path fill="#7F7F7F" d="M11.149,11.374H12.5V12.5h-1.352V11.374z" />
            <path fill="#7F7F7F" d="M11.375,13.288H12.5v1.014h-1.126V13.288z" />
            <path fill="#7F7F7F" d="M11.712,17.117h1.013v1.126h-1.013V17.117z" />
            <path fill="#7F7F7F" d="M12.5,11.374h1.239V12.5H12.5V11.374z" />
            <path fill="#7F7F7F" d="M12.726,13.288h1.352v1.014h-1.352V13.288z" />
            <path fill="#7F7F7F" d="M12.951,15.09h1.126v1.014h-1.126V15.09z" />
            <path fill="#7F7F7F" d="M12.951,17.117h1.352v1.126h-1.352V17.117z" />
            <path fill="#7F7F7F" d="M14.077,11.374h1.013V12.5h-1.013V11.374z" />
            <path fill="#7F7F7F" d="M14.077,13.288h1.239v1.014h-1.239V13.288z" />
            <path fill="#7F7F7F" d="M14.302,15.09h1.352v1.014h-1.352V15.09z" />
            <path fill="#7F7F7F" d="M14.302,17.117h1.352v1.126h-1.352V17.117z" />
            <path fill="#7F7F7F" d="M15.316,11.374h1.351V12.5h-1.351V11.374z" />
            <path fill="#7F7F7F" d="M15.654,13.288h1.013v1.014h-1.013V13.288z" />
            <path fill="#7F7F7F" d="M15.654,15.09h1.238v1.014h-1.238V15.09z" />
            <path fill="#7F7F7F" d="M15.879,17.117h1.013v1.126h-1.013V17.117z" />
            <path fill="#7F7F7F" d="M16.667,11.374h1.239V12.5h-1.239V11.374z" />
            <path fill="#7F7F7F" d="M17.118,15.09h1.126v1.014h-1.126V15.09z" />
            <path fill="#7F7F7F" d="M17.118,17.117h1.351v1.126h-1.351V17.117z" />
            <path fill="#7F7F7F" d="M18.244,11.374h1.014V12.5h-1.014V11.374z" />
            <path fill="#7F7F7F" d="M18.244,13.288h1.239v1.014h-1.239V13.288z" />
            <path fill="#7F7F7F" d="M18.469,15.09h1.014v1.014h-1.014V15.09z" />
            <path fill="#7F7F7F" d="M19.82,13.288h1.013v1.014H19.82V13.288z" />
            <path fill="#7F7F7F" d="M21.059,13.288h1.126v1.014h-1.126V13.288z" />
            <path fill="#7F7F7F" d="M21.397,15.09h1.013v1.014h-1.013V15.09z" />
            <path fill="#7F7F7F" d="M22.41,11.374h1.014V12.5H22.41V11.374z" />
            <path fill="#7F7F7F" d="M22.635,15.09h1.126v1.014h-1.126V15.09z" />
            <path fill="#7F7F7F" d="M22.635,17.117h1.352v1.126h-1.352V17.117z" />
            <path fill="#7F7F7F" d="M23.762,11.374h1.013V12.5h-1.013V11.374z" />
            <path fill="#7F7F7F" d="M23.987,15.09h1.352v1.014h-1.352V15.09z" />
            <path fill="#7F7F7F" d="M24.212,17.117h1.126v1.126h-1.126V17.117z" />
            <path fill="#7F7F7F" d="M25,11.374h1.351V12.5H25V11.374z" />
            <path fill="#7F7F7F" d="M25.563,15.09h1.013v1.014h-1.013V15.09z" />
            <path fill="#7F7F7F" d="M25.563,17.117h1.013v1.126h-1.013V17.117z" />
            <path fill="#7F7F7F" d="M26.352,11.374h1.239V12.5h-1.239V11.374z" />
            <path fill="#7F7F7F" d="M27.928,11.374h1.014V12.5h-1.014V11.374z" />
            <path fill="#7F7F7F" d="M28.153,15.09h1.352v1.014h-1.352V15.09z" />
            <path fill="#7F7F7F" d="M28.379,17.117h1.126v1.126h-1.126V17.117z" />
            <path fill="#7F7F7F" d="M29.167,11.374h1.351V12.5h-1.351V11.374z" />
            <path fill="#7F7F7F" d="M29.505,15.09h1.239v1.014h-1.239V15.09z" />
            <path fill="#7F7F7F" d="M29.73,17.117h1.014v1.126H29.73V17.117z" />
        </g>
        <g id="Group_Side_Layer">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.2503" d="M88.626,41.328l23.311-30.15l-9.46-10.728H7.433l0.788,4.63h90.652   l8.672,9.824l-25.9,31.166" />
            <path fill="#B2B2B2" stroke="#4D4D4D" stroke-width="0.2524" d="M81.736,46.138c2.104,3.542,3.52,11.947,3.533,12.791   c0.169,10.382-5.163,17.979-11.853,24.701c29.404-18.415,15.386-42.708,15.386-42.708" />
        </g>
        <g id="Group_Feeder">
            <path fill="#999999" d="M8.108,3.491L8.334,5.18H5.519L8.108,3.491z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M105.066,17.905l-7.77-9.685H0.901l-0.45-3.153h98.422l8.672,9.797   L105.066,17.905z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M108.783,12.95l-7.77-9.347H7.996L7.433,0.45h95.043l8.672,9.572   L108.783,12.95z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M5.631,5.067h-5.18L7.433,0.45l0.563,3.153L5.631,5.067z" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M108.559,13.513l2.59-3.378l-3.829,4.392l-2.253,3.378   L108.559,13.513z" />
        </g>
        <g id="Group_Base_Platform">
            <path fill="#F0F0F0" stroke="#4C4C4C" stroke-width="0.25" d="M16.667,89.188H7.996L3.83,92.342l85.584,1.801l10.248-8.107   l-28.829-1.576l-4.167,2.364L60.924,88.4l-6.082,0.788h-0.225h-0.225H16.667z" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M18.694,89.188h-2.027L9.009,49.662H7.996l1.577,39.526H18.694z" />
        </g>
        <g id="Group_Tap">
            <polyline fill="#7F7F7F" points="6.645,54.391 8.221,54.617 7.996,53.04 6.982,52.814  " />
            
                <ellipse transform="matrix(-0.9849 -0.1729 0.1729 -0.9849 2.8434 107.2135)" fill="#7F7F7F" cx="6.09" cy="53.483" rx="0.822" ry="2.999" />
        </g>
        <g id="Group_Axial">
            <radialGradient id="SVGID_2_" cx="55.8203" cy="59.4531" r="4.8921" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" cx="55.819" cy="59.454" r="4.891" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.703125, 75.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.703125, 75.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Side_Layer",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Feeder",[0.0, 0.8072916666666666, 49.0, 1.0, 0.0, 255.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.25, 191.0]);
		this.fillGradient(this.fills,"Group_Base_Platform",[0.0, 0.125, 223.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.fills,"Group_Tap",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0]);
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SelectorProperty } from "./properties/selectorproperty";

import Konva from "konva";
import { Value } from "../value";

export class Selector extends ObjectView{
    color:string;
    type3d:number;
    fillcolor:string;

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
    let selproperty

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
    const color2 = ColorUtils.convertformat(color)


		let fillcolor = '0xffffff00';
        
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }	
    const fillcolor2 = ColorUtils.convertformat(fillcolor)
        
    let ranges=[]
    let value = 0
    const selprop = this.properties['selector']
    if(selprop!=null){
         selproperty = Object.assign(new SelectorProperty(), selprop)
        if(selproperty!=null){
            ranges = selproperty.textrange
            value = selproperty.getCurrentValue(this.tagnames);
        }
    }
    const size = ranges.length; 
    if(ranges==null || ranges.length==0){
        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            stroke: fillcolor2,
            fill: color2
          });
          this.node.add(rect)
    }else{
        for (let i=0;i<size;i++){
          const textview = ranges[i];
          const rangevalue = textview.value;
          const text = textview.text

          const rect = new Konva.Rect({
            x: i*this.width/size,
            y: 0,
            width: this.width/size,
            height: this.height,
            stroke: fillcolor2,
            strokeWidth:1
          });

          if (rangevalue==value){
            if(this.type3d==0){
              rect.fillLinearGradientStartPoint({ x: 0, y: 0});
              rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
              rect.fillLinearGradientColorStops([0, fillcolor2,
              0.5, ColorUtils.darkColor(fillcolor2, 0.9),
              1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              rect.fill(fillcolor2)
            }
          }else{
              if(this.type3d==0){
                rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
                rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
                rect.fillLinearGradientColorStops([0, color2,
                0.5, ColorUtils.darkColor(color2, 0.9),
                1, ColorUtils.darkColor(color2, 0.75)])
              }else{
                rect.fill(color2)
              }
          }
          this.node.add(rect)

          const Text = new Konva.Text({
            x: i*this.width/size,
            y: 0,
            width: this.width/size,
            height: this.height,
            text: text,
            fontSize: this.height/2,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align: 'center',
            verticalAlign:"middle",
            fill: color2,
          });

          if (rangevalue==value){
            Text.fill(color2)
          }else{
            Text.fill(fillcolor2)
          }

          if(this.type3d==0){
            Text.shadowColor('black'),
            Text.shadowBlur(0),
            Text.shadowOffset({ x: 1, y: 1 })
          }
          this.node.add(Text)
	
        }  
        this.node.on('mouseup touchend', ()=> {
          const mousePos = this.node.getRelativePointerPosition()
          
          for (let i=0;i<size;i++){
              const textview = ranges[i];
              const rangevalue = textview.value;
             
              if(mousePos.x>i*this.width/size && mousePos.x < (i*this.width/size+this.width/size)){
                selproperty.sendTagValue(this.tagnames, rangevalue.toString())
              }
          }    
        });
      }
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color":
          case "fillcolor":  {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color":
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }
}

import { SVGObject } from "../svgobject";

export class SVGFlex5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 111.6 112.5" enable-background="new 0 0 111.6 112.5" xml:space="preserve">
        <g id="Group_Short_Tube">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="72.5803" y1="76.2959" x2="36.319" y2="40.0346">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M72.636,3.941L0.114,76.238L36.374,112.5l72.297-72.297L72.636,3.941z" />
        </g>
        <g id="Group_45_Lines">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.1707" y1="76.2959" x2="72.5803" y2="3.8862">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.114,76.238L72.636,3.941" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="36.3752" y1="112.501" x2="108.6724" y2="40.2039">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M36.374,112.5l72.297-72.297" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="87.5002" y1="23.9868" x2="90.4287" y2="21.0584">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M66.329,2.815L69.257,0L111.6,42.229l-2.929,2.928   L66.329,2.815z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="81.4192" y1="30.0679" x2="84.4602" y2="27.0269">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M60.248,8.896l3.153-2.928l42.117,42.117l-2.929,3.153   L60.248,8.896z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="75.3381" y1="36.1494" x2="78.3794" y2="33.1082">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M54.167,14.978l3.153-2.928l42.117,42.117l-2.929,3.154   L54.167,14.978z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="69.3694" y1="42.1177" x2="72.4104" y2="39.0767">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M48.311,21.059l2.929-3.153l42.342,42.343l-3.153,2.928   L48.311,21.059z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="63.2883" y1="48.1987" x2="66.3293" y2="45.1577">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M42.23,27.14l2.929-3.153L87.5,66.329l-3.153,2.928   L42.23,27.14z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="57.3206" y1="54.167" x2="60.249" y2="51.2385">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M36.149,32.996l2.929-2.928L81.419,72.41l-2.928,2.928   L36.149,32.996z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="51.239" y1="60.248" x2="54.2798" y2="57.2073">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M30.068,39.077l3.153-2.928l42.117,42.117l-2.928,3.153   L30.068,39.077z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="45.158" y1="66.3291" x2="48.1987" y2="63.2883">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.987,45.158l3.153-2.928l42.117,42.117L66.329,87.5   L23.987,45.158z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="39.1897" y1="72.2979" x2="42.2305" y2="69.2571">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.131,51.239l2.928-3.153l42.343,42.342l-3.153,2.928   L18.131,51.239z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="33.1086" y1="78.3789" x2="36.1497" y2="75.3379">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.05,57.32l2.928-3.154l42.343,42.342l-3.153,2.929   L12.05,57.32z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="27.1404" y1="84.3467" x2="30.0686" y2="81.4185">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.969,63.176l2.928-2.928L51.24,102.59l-2.929,2.928   L5.969,63.176z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="21.0872" y1="90.457" x2="24.1287" y2="87.4155">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,69.257l3.041-2.928l42.117,42.116L42.23,111.6L0,69.257z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);


        super.drawObject();
    }
}
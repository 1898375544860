import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { SwitchControlProperty} from "./properties/switchcontrolproperty"
import Konva from "konva";
import { Value } from "../value";




export class ElectricalSwitch extends ObjectView{
    color:string;
    linewidth:number;
    beginmarker:string;
    endmarker:string;

    public drawObject():void{
		super.initObject();
		
		if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
		
		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	

        let sw = false;
        const switchprop = this.properties['switchcontrol'];
        let switchproperty:SwitchControlProperty;
        if (switchprop!=null){	
            switchproperty =Object.assign(new SwitchControlProperty(), switchprop);
            if (switchproperty!=null) 
                    sw = switchproperty.getCurrentBooleanValue(this.tagnames);     
        }	

		const colorline:string = ColorUtils.convertformat(color);
		let xbegin:number ;
		let ybegin:number;
		let xend:number;
		let yend:number;
		const ang:number = Math.atan2(this.height, this.width);
		if (this.beginmarker!='Flat'){
			xbegin=1.5*this.linewidth*Math.cos(ang);
			ybegin=1.5*this.linewidth*Math.sin(ang);
		}
		else{
			xbegin=0;
			ybegin=0;
		}
		if (this.endmarker!='Flat'){
			xend=1.5*this.linewidth*Math.cos(ang);
			yend=1.5*this.linewidth*Math.sin(ang);
		}
		else{
			xend=0;
			yend=0;
		}
		
		if (this.beginmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: 0,
			y: this.height/2,
			points: [0, 0, this.linewidth, 0],
			pointerLength: this.linewidth,
			pointerWidth: this.linewidth,
			pointerAtBeginning:true,
			pointerAtEnding:false,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
			this.node.add(arrow)
		}
		else if (this.beginmarker=='Square'){
			const square = new Konva.Rect({
				x:0,
				y:this.height/2-1.5*this.linewidth/2, 
				width:1.5*this.linewidth,
				height:1.5*this.linewidth,
				fill: colorline,
				stroke: colorline,
			});
			this.node.add(square)
		}
		else if (this.beginmarker=='Circle'){
			const circle = new Konva.Circle({
				x: 0,
				y: this.height/2,
				radius: 1.4*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle)
		}
		if (this.endmarker=='Arrow'){
			const arrow = new Konva.Arrow({
			x: this.width,
			y: this.height/2,
			points: [0, 0, 0, 0],
			pointerLength: 1.5*this.linewidth,
			pointerWidth: 1.5*this.linewidth,
			pointerAtBeginning:false,
			pointerAtEnding:true,
			fill: colorline,
			stroke: colorline,
			strokeWidth: 4,
			});
			this.node.add(arrow)
		}
		else if (this.endmarker=='Square'){
			const square = new Konva.Rect({
				x:this.width,
				y:this.height/2-1.5*this.linewidth/2, 
				width:1.5*this.linewidth,
				height:1.5*this.linewidth,
				fill: colorline,
				stroke: colorline,
			});
			this.node.add(square)
		}
		else if (this.endmarker=='Circle'){
			const circle = new Konva.Circle({
				x: this.width,
				y: this.height/2,
				radius: 1.5*this.linewidth,
				fill:colorline,
				stroke:colorline
			})
			this.node.add(circle)
        }

        if(sw){
            const line1 = new Konva.Line({
                points: [xbegin/2, this.height/2,
                        this.width-xend/2, this.height/2],
                stroke: colorline,
                strokeWidth: this.linewidth
            })
            this.node.add(line1)
        }else{
            const line1 = new Konva.Line({
                points: [xbegin/2, this.height/2,
                this.width-xend/2, yend/2],
                stroke: colorline,
                strokeWidth: this.linewidth
            })
            this.node.add(line1)
        }
    }

	public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "color": 
			case "beginmarker": 
			case "endmarker": {this[field] = value.value;this.rotatednode=null;break;}
            case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "color": 
			case "beginmarker": 
			case "endmarker": {value.datatype=7; value.value = this[field];break}
            case "linewidth": {value.datatype=2; value.value = this[field];break}
        }
        return value;
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import Konva from "konva";
import { AnalogMeterRect } from './analogmeterrect';


export class AnalogMeterHorizontal extends AnalogMeterRect{

  node1:Konva.Group;
    public drawObject():void{
		super.initObject();
        
    this.drawObjectHorizontal(false);
    }
    protected drawObjectHorizontal(fill:boolean):void{
      this.node1  = new Konva.Group({
        x: 0,
        y: 0,
       });
        this.node1.removeChildren();
        
		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
    const color2 = ColorUtils.convertformat(color)

    let value = 0   
    let valueproperty:ValueProperty
    const valueprop = this.properties['value'];
      if (valueprop!=null){	
          valueproperty =Object.assign(new ValueProperty(), valueprop);
          if (valueproperty!=null) 
                  value = valueproperty.getTagValue(this.tagnames)           
      }	
    let fillcolor = '0xffffff00';
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
    const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
             const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient1 = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient1.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.9))
              gradient1.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

              const gradient2 = ctx.createLinearGradient(0, 0, 0, this.height*12/20);
              gradient2.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.5))
              gradient2.addColorStop(1, fillcolor2)

              const gradient3 = ctx.createLinearGradient(0, 0, 0, this.height*4/20);
              gradient3.addColorStop(0, 'black')
              gradient3.addColorStop(1, 'white')
              
            const rect = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                cornerRadius:this.height/20,
                stroke:  gradient1,
                strokeWidth: this.height/100,
                fill: fillcolor2
              });
              this.node1.add(rect)
              
              const rect2 = new Konva.Rect({
                x: this.width/40,
                y: this.height/40,
                width: this.width*38/40,
                height: this.height*12/20,
                cornerRadius:this.height/40,
                stroke: gradient2,
                strokeWidth: this.height/100,
                fill: 'white'
              });
              this.node1.add(rect2)

              const rect4 = new Konva.Rect({
                x: this.width/10,
                y: this.height*9/20,
                width: this.width*8/10,
                height: this.height*3/40,
                cornerRadius:this.height/20,
                stroke: gradient2,
                strokeWidth: this.height/100,
                fill: 'black'
              });
              this.node1.add(rect4)
              
			let valuemin=0;
			let valuemax=240;
			let decimalpos=0;

      if (valueproperty!=null){
				valuemin = valueproperty.minimumvalue;
				valuemax = valueproperty.maximumvalue;
				decimalpos=valueproperty.decimalpos;
      }
      const valuestep = (valuemax-valuemin)/this.interval;

      for(let i=0; i<=this.interval; i++){
                const delta = i/this.interval
               
                const line1 = new Konva.Line({
                    points: [this.width/10+this.width*8/10*delta, 
                            this.height*7/20,
                            this.width/10+this.width*8/10*delta,
                            this.height*5/20
                            ],
                    strokeWidth: this.width/150,
                    stroke: ColorUtils.darkColor(color2, 0.1)
                  });
                  this.node1.add(line1); 
                  for(let j=1; j<5; j++){
                    if (i==this.interval) break
                    const delta2 = this.width*16/20/this.interval*j/5
                    const line2 = new Konva.Line({
                        points: [(this.width/10+this.width*8/10*delta)+delta2, 
                                this.height*7/20,
                                (this.width/10+this.width*8/10*delta)+delta2,
                                this.height*6/20
                                ],
                        strokeWidth: this.width/200,
                        stroke: ColorUtils.darkColor(color2, 0.1)
                      });
                      this.node1.add(line2); 
                  }
                const valuepos = valuemin+valuestep*i;
                const valuetext = valuepos.toFixed(decimalpos)
                   const Text = new Konva.Text({
                    x: (this.width/10+this.width*8/10*delta)-((this.width*8/10)/this.interval/2),
                    y: this.height*5/20-this.height/8,
                    //verticalAlign:"top",
                    width: (this.width*8/10)/this.interval,
                    height: this.height/8,
                    text: valuetext,
                    fontSize: this.height/8,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fill: ColorUtils.darkColor(color2, 0.1),
                    align: 'center',
                    
                  });  
                  this.node1.add(Text)
      } 

      const TextUnit = new Konva.Text({
        x: this.width/30,
        y: this.height*12/20-this.height/8,
        width: this.width/30,
        height: this.height*12/20,
        text: this.unit,
        fontSize: this.height/8,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fill: 'black',
      });  
      this.node1.add(TextUnit)

      const percent = valueproperty.getPercent(this.tagnames);

       if(!fill){
        const x1ind=this.width/10 + this.width*8/10*percent;
        const y1ind=this.height*10/20;
        const x2ind=this.width/10 + this.width*8/10*percent;
        const y2ind=this.height*6/20;

        const lineind = new Konva.Line({
          points: [x1ind, y1ind, x2ind, y2ind],
          strokeWidth: this.width/80,
          stroke: color2
        })
        this.node1.add(lineind)
       }else{
        const widthfill = this.width*8/10*percent;
        const rectfill = new Konva.Rect({
          x: this.width/10,
          y: this.height*9/20,
          width: widthfill,
          height: this.height*3/40
        })
        rectfill.fillLinearGradientStartPoint({ x: 0, y: 0});
        rectfill.fillLinearGradientEndPoint({ x: 0, y: this.height*3/40});
        rectfill.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.5),
        0.5, 'white',
        1, ColorUtils.darkColor(color2, 0.5)]) 
        this.node1.add(rectfill)
       }

            const TextLabel = new Konva.Text({
                x: this.width/4,
                y: this.height*29/40-this.height/10,
                //verticalAlign:"bottom",
                width: this.width/2,
                height: this.height/8,
                text: this.text,
                fontSize: this.height/8,
                fontFamily: "Roboto",
                fontStyle: "normal",
                fill: 'black',
                align: 'center'
              });  
              this.node1.add(TextLabel)	

              if(this.usedigital){
                const rectdigit = new Konva.Rect({
                    x: this.width/4,
                    y: this.height*30/40,
                    width: this.width/2,
                    height: this.height*4/20,
                    cornerRadius:this.height/50,
                    stroke: gradient3,
                    strokeWidth: this.height/100,
                  });
                  rectdigit.fillLinearGradientStartPoint({ x: 0, y: 0 });
                  rectdigit.fillLinearGradientEndPoint({ x: 0, y: this.height*4/20 });
                  rectdigit.fillLinearGradientColorStops([0, 'white', 
                    0.25, 'black'])
                 
                  this.node1.add(rectdigit)
                
                  const textValue = new Konva.Text({
                    x: this.width/4,
                    y: this.height*72/80-this.height/9,
                    verticalAlign:"bottom",
                    width: this.width/2,
                    height: this.height/7,
                    text: value.toFixed(decimalpos),
                    fontSize: this.height/7,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fill: 'white',
                    align: 'center'
              });  
              this.node1.add(textValue)
              }
  
            this.node.add(this.node1)
        
      


            }
}
import { SVGObject } from "../svgobject";

export class SVGFood23 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 92.908" enable-background="new 0 0 112.5 92.908" xml:space="preserve">
        <g id="Group_Shell">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="47.9009" y1="7.1292" x2="104.7764" y2="7.1292" gradientTransform="matrix(-0.5523 -0.9659 0.4884 -0.2793 96.0742 121.2872)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_1_)" points="45.26,88.947 13.848,34.01 69.526,2.175 100.939,57.11  " />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M55.549,44.832c9.83,14.696,6.644,34.073-7.118,43.278   c-13.765,9.211-32.893,4.762-42.725-9.936c-9.831-14.697-6.642-34.074,7.122-43.282C26.591,25.687,45.717,30.136,55.549,44.832z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="91.0332" y1="52.9592" x2="93.1283" y2="-1.6608" gradientTransform="matrix(1.2106 -0.8098 0.6478 0.9683 -45.8936 81.0532)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M106.732,14.97c9.807,14.605,6.771,33.764-6.78,42.794c-13.556,9.032-32.496,4.515-42.306-10.091   c-9.808-14.605-6.771-33.765,6.783-42.796C77.983-4.153,96.922,0.365,106.732,14.97z" />
        </g>
        <g id="Group_Gaskets">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="55.5571" y1="-59.0105" x2="68.9307" y2="-59.0105" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="29.884,87.896 22.513,74.97 35.612,67.499 42.984,80.424  " />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M31.862,77.456c2.146,3.425,1.436,7.942-1.587,10.09   c-3.022,2.15-7.213,1.115-9.358-2.311c-2.146-3.425-1.435-7.942,1.587-10.091C25.526,72.997,29.715,74.031,31.862,77.456z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="68.7109" y1="-72.8372" x2="82.083" y2="-72.8372" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" points="15.875,79.036 8.504,66.112 21.604,58.642    28.975,71.566  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="62.7275" y1="18.2146" x2="63.2201" y2="5.372" gradientTransform="matrix(1.2167 -0.812 0.6467 0.9691 -46.698 81.4687)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M43.349,37.909c2.302,3.437,1.572,7.947-1.631,10.076   c-3.204,2.129-7.667,1.07-9.969-2.367c-2.302-3.436-1.573-7.948,1.63-10.077C36.583,33.414,41.045,34.473,43.349,37.909z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="60.916" y1="43.2688" x2="61.4086" y2="30.4267" gradientTransform="matrix(1.2167 -0.812 0.6467 0.9691 -46.698 81.4687)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M57.349,63.659c2.302,3.437,1.571,7.947-1.631,10.076   c-3.204,2.129-7.667,1.07-9.969-2.367c-2.302-3.436-1.573-7.947,1.63-10.076C50.583,59.163,55.045,60.223,57.349,63.659z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="55.127" y1="-32.9148" x2="68.4995" y2="-32.9148" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="42.875,81.036 35.504,68.112 48.604,60.642 55.975,73.566  " />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="81.4639" y1="-31.8552" x2="94.8369" y2="-31.8552" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="28.875,55.286 21.504,42.362 34.604,34.891 41.975,47.816  " />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.853,68.597c2.146,3.426,1.436,7.943-1.587,10.091   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092S15.707,65.173,17.853,68.597z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="81.0342" y1="-59.9612" x2="94.4063" y2="-59.9612" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.1" points="15.375,63.536 8.004,50.612 21.104,43.141    28.475,56.066  " />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M17.353,53.097c2.146,3.426,1.436,7.943-1.587,10.091   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092S15.207,49.673,17.353,53.097z" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="68.582" y1="-46.3835" x2="81.9551" y2="-46.3835" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.1" points="28.875,71.786 21.504,58.862 34.604,51.392    41.975,64.316  " />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M30.853,61.347c2.146,3.426,1.436,7.943-1.587,10.091   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092S28.707,57.923,30.853,61.347z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M30.853,44.847c2.146,3.425,1.436,7.943-1.587,10.09   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092C24.517,40.388,28.707,41.423,30.853,44.847z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M44.853,70.597c2.146,3.426,1.436,7.943-1.587,10.091   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092S42.707,67.173,44.853,70.597z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="66.8467" y1="30.9529" x2="67.3393" y2="18.1103" gradientTransform="matrix(1.2167 -0.812 0.6467 0.9691 -46.698 81.4687)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.599,46.909c2.302,3.437,1.571,7.947-1.631,10.076   c-3.204,2.129-7.667,1.07-9.969-2.367c-2.302-3.436-1.573-7.947,1.63-10.077C49.833,42.414,54.295,43.473,56.599,46.909z" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="68.5381" y1="-19.324" x2="81.9111" y2="-19.324" gradientTransform="matrix(-0.5512 -0.9665 0.4888 -0.2788 95.9016 121.4086)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <polygon fill="url(#SVGID_12_)" points="42.125,64.286 34.754,51.362 47.854,43.891 55.225,56.816  " />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M44.103,53.847c2.146,3.426,1.436,7.943-1.587,10.091   c-3.022,2.149-7.213,1.115-9.358-2.31c-2.146-3.426-1.435-7.943,1.587-10.092S41.957,50.423,44.103,53.847z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Shell",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Gaskets",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.9895833333333334, 0.0, 6.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
import RSocketWebSocketClient from "rsocket-websocket-client";
import ReconnectableRSocket from '@/services/rsocketutils/ReconnectableRSocket';
import ResubscribeOperator from "./ResubscribeOperator";
import type {Payload} from 'rsocket-types';
import {Flowable} from 'rsocket-flowable';
import auth from '../../model/auth';
import {
	BufferEncoders, 
	MESSAGE_RSOCKET_COMPOSITE_METADATA, 
	RSocketClient,
} from "rsocket-core";
import Metadata from "@/services/rsocketutils/metadata";
const maxRSocketRequestN = 2147483647;
/* eslint-disable */


export default class SpringClient{
    
    static client:SpringClient=null;
  //  clientFactory: () => RSocketClient<Buffer, Buffer>;
    socket: ReconnectableRSocket<Buffer, Buffer>=null;
    auth: auth;
   

    constructor(wsUrl:string, keepAlive:number, lifetime:number , dataMimeType:string, errorCallback) {           
        
        this.socket = new ReconnectableRSocket(()=> new RSocketClient({
            
            setup: {
                keepAlive: keepAlive,
                lifetime: lifetime,
                dataMimeType: dataMimeType,
                metadataMimeType: MESSAGE_RSOCKET_COMPOSITE_METADATA.string
            },
            transport: new RSocketWebSocketClient({
                debug:true,
                url: wsUrl,
                wsCreator: url => new WebSocket(url),
            }
            , BufferEncoders
            )
        }), errorCallback)
    }
    async connect():Promise<void>{
       await this.socket.connect();
    }
    bearerAuth(token:string):void {
        this.auth = {type: 'bearer', token: token, username: '', password:''}
       
    }
    setToken(token:string):void{
        if (token===null)
            this.logout();
        else
            this.bearerAuth(token);
    }
    simpleAuth(username:string, password:string):void {
        /*this.auth['type'] = 'simple';
        this.auth['username'] = username;
        this.auth['password'] = password;*/
        this.auth = {type: 'bearer', token: '', username: username, password:password}
    }

    logout():void {
        this.auth = null;
    }
    requestResponse(data:any, route:string,
        completeCallback = (data:any) => {
            //console.log(data);
        },
        errorCallback = (error:any) => {
            console.log(error);
        }
        //,
       /* subscribeCallback = (sub:any) => {
            console.log('subscribed');
        }*/
        ):void{
       
            const metadata = new Metadata(this.auth,route).toMetadata();
            data = Buffer.from(JSON.stringify(data));
            this.socket.requestResponse({
                data,
                metadata
            }).subscribe({
            onComplete: data => {
                completeCallback(data.data.toString('utf8'));
            },
            onError: error => {
                console.log(error);
                //errorCallback(error);
            },
            onSubscribe: () => {
                 console.log("subscribe requestresponse!");
                //console.log(sub);
        //sub.request(1);
                //subscribeCallback(sub);
            }
        });
    
    }
    requestStream(data:any, route:string,
      /*  completeCallback = () => {
            console.log(data);
        },
        errorCallback = (error:any) => {
            console.log(error);
        },*/
        onnextCallback = (data:string) =>{
            console.log(data);
        },
        errorCallback = (error:any) => {
            console.log(error);
        }
        //,
       /* subscribeCallback = (sub:any) => {
            console.log(sub);
        }*/
    ) :void{
    
        const metadata = new Metadata(this.auth,route).toMetadata();
        data = Buffer.from(JSON.stringify(data));

        const request = new Flowable<Payload<Buffer, Buffer>>(subscriber => {
            this.socket
              .requestStream({
                data: data,
                metadata: metadata
              })
              .subscribe(subscriber);
              
          });

         request
         .lift<Payload<Buffer, Buffer>>(actual => new ResubscribeOperator(request, actual))
          /*this.socket
          .requestStream({
            data: data,
            metadata: metadata
          })*/
          .subscribe({
             // eslint-disable-next-line no-console
            onComplete: () => {
                //console.log('Request-stream completed')
                //completeCallback
            },
            onError: error =>{
             console.error(`Request-stream error:${error.message}`);
             errorCallback(error);
            },
            // eslint-disable-next-line no-console
            onNext: value => {
               // console.log("got next value in requestStream..");
                onnextCallback(value.data.toString('utf8'));
            },
             onSubscribe: sub => {
                 
                    //console.log("subscribe request Stream!");
                    //console.log(sub);
                    sub.request(maxRSocketRequestN);
                    //subscribeCallback(sub);
             }
  });
  
   
}
  
}
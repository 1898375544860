import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import tagsModele from "@/store/tags.modele";
import { ColorPicker } from '@/model/project/objects/colorpicker';

@Module({ name:"ColorPicker", dynamic:true, store})
class ColorPickerModule extends VuexModule {

  public colorpicker:ColorPicker
  public enabled:boolean
  public color:string
    @Mutation
    public setColorPicker(colorpicker:ColorPicker){
      this.colorpicker = colorpicker;
      if (this.colorpicker==null)
        this.color = '#ffffffff'
        else
       this.color =  this.colorpicker.color

    }
    @Mutation
    public setColorPickerEnabled(value:boolean){
      this.enabled = value;
    }

    @Mutation
    public setColor(color:string){
      this.color = color;
    }
    
    get getColorPickerEnable():boolean{
     return this.enabled
    }
    get getColor():string{
        return this.color
    }
   
    @Mutation
    public writeColorValues(){
       
        const R = parseInt(this.color.substring(1,3),16);
        const G = parseInt(this.color.substring(3,5),16);
        const B = parseInt(this.color.substring(5,7),16);
        const OO = this.color.length>=9?parseInt(this.color.substring(7,9),16):255;
  
            tagsModele.writeTagAction({token:"",name: this.colorpicker.redcolortagpath, 
                tagvalue:R.toString(),datetime:0});
            tagsModele.writeTagAction({token:"",name: this.colorpicker.greencolortagpath, 
                tagvalue:G.toString(),datetime:0});
            tagsModele.writeTagAction({token:"",name: this.colorpicker.bluecolortagpath, 
                tagvalue:B.toString(),datetime:0});
            tagsModele.writeTagAction({token:"",name: this.colorpicker.opacitycolortagpath, 
                tagvalue:OO.toString(),datetime:0});
           
      
        
    }
}

export default getModule(ColorPickerModule);
import { SVGObject } from "../svgobject";

export class SVGTank12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 110.477" enable-background="new 0 0 112.5 110.477" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.7251" y1="54.001" x2="108.6748" y2="54.001">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M108.449,6.526V6.301c0,0-24.75-6.3-47.024-6.3c-23.142,0-47.25,6.525-47.25,6.525l-9.45,9.675   l63,91.8c0,0,8.61-3.157,11.694-4.921c3.302-1.889,10.592-7.465,13.281-10.154c9.704-9.703,13.5-17.55,13.5-17.55l2.475-68.85   H108.449z" />
            
                <radialGradient id="SVGID_2_" cx="51.9746" cy="63.2266" r="47.25" gradientTransform="matrix(1 0 0 1.8 0 -50.5812)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M96.975,84.602l2.25-68.625h-94.5l2.25,68.625c0,0,14.228,25.875,45,25.875   C81.631,110.477,96.975,84.602,96.975,84.602z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.7251" y1="13.0508" x2="99" y2="13.0508">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M99,16.651v-0.9c0,0-24.75-6.3-47.025-6.3c-23.142,0-47.25,6.525-47.25,6.525v0.675H99z" />
        </g>
        <g id="Group1">
            <path fill="#E5E5E5" d="M0,16.651h103.05v6.975H0V16.651z" />
            <path fill="#E5E5E5" d="M0,23.851h103.05v6.975H0V23.851z" />
            <path fill="#7F7F7F" d="M14.175,6.751l-9.45,9.45v0.45H0l9.45-9.225h4.05L14.175,6.751z" />
            <path fill="#7F7F7F" d="M103.05,16.651H99v-0.9l9.675-9.225v0.9h3.825L103.05,16.651z" />
            <path fill="#B2B2B2" d="M103.05,30.826v-7.2l9.45-9.225v6.975L103.05,30.826z" />
            <path fill="#B2B2B2" d="M103.05,23.851v-7.2l9.45-9.225v6.975L103.05,23.851z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group1",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.609375, 99.0, 5.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
import { Curve } from "../objects/properties/range/curve"

export class CurrentPoint{
    x:number
    y:number
    curve:Curve
    historyvalue:any

    constructor(x:number,y:number, curve:Curve, value:any){
        this.x =x;
        this.y = y;
        this.curve = curve;
        this.historyvalue = value;
    }
}
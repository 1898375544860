
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";

export class StraightPipe extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            
            const rect = new Konva.Rect({
                x: 0,
                y:this.height*3/10,
                width: this.width,
                height: this.height*4/10,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth:1
                
              });
              if(this.type3d==0){
                if(this.angle==0){
                    rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rect.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                    rect.fillLinearGradientColorStops([0, fillcolor2,
                    0.15, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
                }else if(this.angle==180){
                    rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rect.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                    rect.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                    0.85, 'white',
                    1, fillcolor2])
                }else{
                    rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rect.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                    rect.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, 'white',
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])
                }
              }else{
                rect.fill(fillcolor2)
              }
              this.node.add(rect)
          
		
        }
}
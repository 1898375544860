import {ColorUtils} from '@/model/project/utils/colorutils'
import Konva from "konva";
import { ObjectView } from './object';
import { TextView } from './text'
import { Value } from '../value';


export class AnalogClock extends ObjectView{

    text:string
    //timer:NodeJS.Timeout;

  public deleteObject():void{
    super.deleteObject()
    //clearTimeout(this.timer)
  }

    public drawObject():void{
        this.setUpdate(true)
       // this.timer = setTimeout(()=>{this.drawObject()}, 1000);
		super.initObject();
        const color = '#808080' //'grey'

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const gradient1 = ctx.createLinearGradient(0, 0, 0, this.width);
        gradient1.addColorStop(0, ColorUtils.darkColor(color, 0.9))
        gradient1.addColorStop(1, ColorUtils.darkColor(color, 0.5))
        const gradient2 = ctx.createLinearGradient(0, 0, 0, this.width*19/40);
        gradient2.addColorStop(0, ColorUtils.darkColor(color, 0.5))
        gradient2.addColorStop(1, color)

        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width/2,
            strokeWidth: this.height/120,
            stroke: gradient1,
            fill: color
            });
          this.node.add(circle)

          const circle3 = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: this.width*19/40,
            strokeWidth: this.height/80,
            stroke: gradient2,
            fill: 'white'
            });
          this.node.add(circle3)

        const radius = this.width*19/40;		
		const innerradius =radius-this.width/20;
		const interval=12;
		const degreestep = 360/interval;
		const valuemin=0;
		const valuemax=12;
        const valuestep = (valuemax-valuemin)/interval;


        for(let i=0; i<interval; i++){
            const degrees=degreestep*i+90;
			const radians = degrees*Math.PI/180;
			const x1=this.width/2-radius*Math.cos(radians);
			const y1=this.height/2-radius*Math.sin(radians);
			const x2=this.width/2-innerradius*Math.cos(radians);
			const y2=this.height/2-innerradius*Math.sin(radians);
            const line1 = new Konva.Line({
                points: [x1, y1, x2, y2],
                strokeWidth: this.width/100,
                stroke: ColorUtils.darkColor(color, 0.1)
            })
            this.node.add(line1)
            const value = valuemin+valuestep*i;
            let valueText = value.toFixed(0).toString()

			if (value==0) valueText="12";

            const textob = new TextView()
                textob.width = this.width/6
                textob.height = this.height/10
                textob.text = valueText
                textob.fontsize = this.height/10
                //textob.textplacement = 1
                textob.textcolor = 'black'
                textob.useborder = false
                textob.bordercolor = null
                textob.linewidth = 1
                textob.fill = false
                textob.fillcolor = 'black'
                textob.underline = false
			if (degrees>360 && degrees<450){
				textob.posx = (x2-this.width/100*Math.cos(radians));
				textob.posy = (y2+this.height/10*Math.sin(radians))-this.height/12;
                textob.textplacement = 0
			}
			else if (degrees==360){
				textob.posx = (x2+this.width/100);
				textob.posy = (y2+this.height/30)-this.height/13;
                textob.textplacement = 0
			}
			else if (degrees>270 && degrees<360){
				textob.posx = (x2-this.width/100*Math.cos(radians));
				textob.posy = (y2+this.height/50*Math.sin(radians))-this.height/14;
                textob.textplacement = 0
            }else if (degrees==270){
				textob.posx = (x2-this.width/12);
				textob.posy = (y2-this.height/100) -this.height/12;
                textob.textplacement = 1
			}
			else if (degrees>180 && degrees<270){
				textob.posx = (x2+this.width/10*Math.cos(radians));
				textob.posy = (y2+this.height/50*Math.sin(radians))-this.height/14;
                textob.textplacement = 0
			}
			else if (degrees==180){
				textob.posx = (x2-this.width/12);
				textob.posy = (y2+this.height/30)-this.height/13;
                textob.textplacement = 0
			}
			else if (degrees>90 && degrees<180){
				textob.posx = (x2+this.width/15*Math.cos(radians));
				textob.posy = (y2+this.height/10*Math.sin(radians))-this.height/12;
                textob.textplacement = 0
			}
			else{
				textob.posx = (x2-this.width/12);
				textob.posy = (y2+this.height/12)-this.height/12;
                textob.textplacement = 1
			}
			textob.initLayer(this.layer)
            textob.drawObject()
            this.node.add(textob.rotatednode)		

        }

        const currentdate = new Date(); 
        const hours =  currentdate.getHours()
        const minutes = currentdate.getMinutes()  
        const seconds = currentdate.getSeconds();

        let hourarrowwidth = this.width*1/20;
		let hourarrowheight = this.width*3/10;
		if (hourarrowwidth<1) hourarrowwidth = 1;
		if (hourarrowheight<1) hourarrowheight = 1;
        const arrowHour = new Konva.Line({
            points: [hourarrowwidth/4, hourarrowheight,
                    0, hourarrowheight/3,
                    hourarrowwidth/2, 0,
                    hourarrowwidth,hourarrowheight/3,
                    hourarrowwidth*3/4,hourarrowheight,
                    hourarrowwidth/4, hourarrowheight
                 ],
            closed: true,
            fill: 'black'
        })
        arrowHour.position({x:this.width/2-hourarrowwidth/2, y:this.height/2-hourarrowheight})
        const group1 = new Konva.Group()
        group1.add(arrowHour)
        this.node.add(group1)
        this.rotateAroundPoint(group1, hours/12*360+minutes/60*30, {x:this.width/2, y:this.height/2})

        let minutearrowwidth = this.width*1/30;
		let minutearrowheight = innerradius;
		if (minutearrowwidth<1) minutearrowwidth = 1;
		if (minutearrowheight<1) minutearrowheight = 1;
        const minuteArrow = new Konva.Line({
            points: [minutearrowwidth/4, minutearrowheight,
                    0, minutearrowheight/3,
                    minutearrowwidth/2, 0,
                    minutearrowwidth, minutearrowheight/3,
                    minutearrowwidth*3/4, minutearrowheight,
                    minutearrowwidth/4, minutearrowheight
                ],
            closed: true,
            fill: 'black'
        })
        minuteArrow.position({x:this.width/2-minutearrowwidth/2, y:this.height/2-minutearrowheight})
        const group2 = new Konva.Group()
        group2.add(minuteArrow)
        this.node.add(group2)
        this.rotateAroundPoint(group2, minutes/60*360+seconds/60*6, {x:this.width/2, y:this.height/2})

        

        let arrowwidth = this.width/60;
		let arrowheight = radius;
		if (arrowwidth<1) arrowwidth = 1;
		if (arrowheight<1) arrowheight = 1;
        const arrow = new Konva.Line({
            points: [0, arrowheight, 
                    arrowwidth/2, 0,
                    arrowwidth, arrowheight,
                    0, arrowheight
                ], 
            closed: true,
            fill: 'blue'
        })
        arrow.position({x:this.width/2-arrowwidth/2, y:this.height/2-arrowheight})
        const group3 = new Konva.Group()
        group3.add(arrow)
        this.node.add(group3)

        this.rotateAroundPoint(group3, seconds/60*360, {x:this.width/2, y:this.height/2})
        
        const circleBall = new Konva.Circle({
            x: this.width/2,
            y: this.width/2,
            radius: arrowwidth,
            fill: 'black'
            });
            
          this.node.add(circleBall)

        const textLabel = new TextView()
        textLabel.width = this.width/2
        textLabel.height = this.height/10
        textLabel.text = this.text
        textLabel.fontsize = this.height/10
        textLabel.posx = this.width/2-this.width/4
        textLabel.posy = this.height*13/20-this.height/10
        textLabel.textplacement = 1
        textLabel.textcolor = 'black'
        textLabel.useborder = false
        textLabel.bordercolor = null
        textLabel.linewidth = 1
        textLabel.fill = false
        textLabel.fillcolor = 'black'
        textLabel.underline = false
        textLabel.initLayer(this.layer)
        textLabel.drawObject()
        this.node.add(textLabel.rotatednode) 
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "text": {this[field] = value.value; this.rotatednode=null;break;}
        }
        //this.drawObject();
    }
    
    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "text": {value.datatype=7; value.value = this[field];break}
        }
        return value;
    }

    protected rotateAroundPoint(shape:Konva.Group, angleDegrees:number, point:any):void {
        const angleRadians = angleDegrees * Math.PI / 180; 
         
        const x =
          point.x +
          (shape.x() - point.x) * Math.cos(angleRadians) -
          (shape.y() - point.y) * Math.sin(angleRadians);
        const y =
          point.y +
          (shape.x() - point.x) * Math.sin(angleRadians) +
          (shape.y() - point.y) * Math.cos(angleRadians);
          
        shape.position({x: x, y: y});
        shape.rotation(angleDegrees)
    }   
}
import { Scale} from "./scale";
import { Border3d } from "./border3d"
import { Cylinder } from "./cylinder"
import { Rectangle} from "./rectangle"
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import { Tank } from "./tank";
import { VerticalTank } from "./verticaltank";
import Konva from "konva";
import AuthModule from "@/store/auth.module";
import historydatarequest from "@/model/requests/historydatarequest";
import TagsService from '@/services/tags.service';
import tankdialogboxModule from "@/store/tankdialogbox.module";
import { Value } from "../value";


export class VerticalTankWithTrend extends VerticalTank{
    begin:number;
    end: number;
    duration:number;

    public drawObject():void{
      super.initObject();

      this.end = Date.now()
      if(this.duration<=0 || this.duration==undefined) this.duration = 60*1000
      
      this.begin = Date.now()-this.duration;
      
      

      /*this.end = Date.now()

      if(this.begin==0 || this.begin==undefined){
        this.begin = this.end - 1000*60*10;   
      }*/

      let color:string = this.color;
      const lineprop = this.properties==null? null : this.properties['color'];
      if (lineprop!=null){	
        const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
        if (linecolorproperty!=null) 
          color = linecolorproperty.getColor(this.tagnames, this.color)
      }	
      const color2 = ColorUtils.convertformat(color)

      let fillcolor = '0xffffff00';
        
      let fillheight = this.height
      let fillingproperty:FillingProperty
      const fillingprop = this.properties==null? null : this.properties['filling']
      if (fillingprop != null){
          fillingproperty =Object.assign(new FillingProperty(), fillingprop);
          fillheight = fillingproperty.getHeight(this.tagnames, this.height)
      }
      fillcolor = this.fillcolor;
    
      const fillprop = this.properties==null? null : this.properties['fillcolor'];
      if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }
      const fillcolor2 = ColorUtils.convertformat(fillcolor)

      const vergap = this.height/8
      const horgap = this.width*35/100
      const rectwidth = this.width-horgap
      const rectheight = this.height-2*vergap
      const maximum = fillingprop==null?100: fillingprop.maximumvalue
      const minimum = fillingprop==null?0:fillingprop.minimumvalue

      if (AuthModule.currentUser!=null && fillingproperty!=null){
        const tag=fillingproperty.getTag(this.tagnames)
        if (tag!=null){
        const request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
            name:tag.path==null?tag.name:tag.path, begindate:this.begin, enddate:this.end};
        TagsService.gethistorydata(request,(data:string)=>{
            const historydata =JSON.parse(data); 
                        
            const points = []
            if(historydata.length>1){
              historydata.forEach((data)=>{
                if(data['x']<this.begin || data['x']>this.end)return
                const x =(data['x']-this.begin)*rectwidth/(this.end-this.begin)
                let y = (maximum - data['y'])*rectheight/(maximum-minimum)
                if(y<0) y=0
                if(y> rectheight) y=rectheight
                points.push(x)
                points.push(y+vergap)
              })
              const line = new Konva.Line({
                points: points,
                stroke: fillcolor2,
                strokeWidth: 1
              })
              this.node.add(line)
            }
          }, 
          (data:string)=>{
            console.log('data', data);
          }
        )    
      }
      } 

      const tank = new Tank()
      tank.width = this.width
      tank.height = this.height
      tank.fillcolor = color2
      tank.vertical = true
      tank.ratio = 5
      tank.type3d = this.type3d
      tank.initLayer(this.layer)
      tank.drawObject()
      this.node.add(tank.rotatednode)
             
      const border1 = new Border3d() 
      border1.width = this.width*32/100
      border1.height = this.height*62/80
      border1.posx = this.width*66/100
      border1.posy  =this.height*9/80
      border1.cornerradius = 10 ;
      border1.glass = this.type3d==0? true: false;
      border1.linewidth = this.height/50
      border1.color = ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
      border1.fillcolor = '0x000000'
      border1.fill = true;
      border1.initLayer(this.layer)
      border1.drawObject()
      this.node.add(border1.rotatednode)
            

      if(this.type3d==0){
        const cylinder = new Cylinder()
        cylinder.width = this.width*6/20
        cylinder.height = fillheight*6/8
        cylinder.posx = this.width*67/100
        cylinder.posy =  (this.height*6/8 - fillheight*6/8)+this.height/8
        cylinder.properties = new  Map<string, string>()
        cylinder.linewidth = 0
        cylinder.color = color2
        cylinder.fillcolor = ColorUtils.convertbackformat(
          ColorUtils.darkColor(ColorUtils.convertformat(fillcolor2), 0.5))
        cylinder.fill = true
        cylinder.initLayer(this.layer)
        cylinder.drawObject()
        this.node.add(cylinder.rotatednode)
      }else{
        const cylinder = new Rectangle()
        cylinder.width = this.width*6/20
        cylinder.height = fillheight*6/8
        cylinder.posx = this.width*67/100
        cylinder.posy =  (this.height*6/8 - fillheight*6/8)+this.height/8
        cylinder.properties = new  Map<string, string>()
        cylinder.linewidth = 0
        cylinder.color = color2
        cylinder.fillcolor = fillcolor2
        cylinder.fill = true
        cylinder.initLayer(this.layer)
        cylinder.drawObject()
        this.node.add(cylinder.rotatednode)
      } 
  
      const scale = new Scale() 
      scale.width = this.width*6/20;
      scale.height = this.height*6/8
      scale.color = '0xffffff'
      scale.posx = this.width*67/100
      scale.posy = this.height/8
      scale.scale2=true;  
      scale.scaleinterval1=2;
      scale.max = fillingprop==null?100: fillingprop.maximumvalue
      scale.min = fillingprop==null?0:fillingprop.minimumvalue
      scale.scale3 =false;
      scale.scaleinterval2 = 5;
      scale.sizemarkers1 = this.width/10
      scale.sizemarkers2 = this.width/20
      scale.linewidth = this.height/150
      scale.usedigit = true
      scale.type = 0;
      scale.fontsize = this.fontsize
      scale.decimalpos = 0

      if (scale.linewidth<1) scale.linewidth=1;
			
      scale.initLayer(this.layer)
      scale.drawObject()
      this.node.add(scale.rotatednode)

      const border3 = new Border3d() 
      border3.width = this.width*32/100
      border3.height = this.height*62/80
      border3.posx = this.width*66/100
      border3.posy  =this.height*9/80
      border3.cornerradius = 10 ;
      border3.glass = this.type3d==0? true: false;
      border3.linewidth = this.height/50
      border3.color = ColorUtils.convertbackformat(
        ColorUtils.darkColor(ColorUtils.convertformat(color2), 0.3))
      border3.fillcolor = '0x000000'
      border3.fill = false;
      border3.initLayer(this.layer)
      border3.drawObject()
      this.node.add(border3.rotatednode)
    
      const line = new Konva.Line({
          points: [0, vergap,rectwidth,vergap ],
          stroke: ColorUtils.darkColor(color2, 0.75)
      })
      const line2 = new Konva.Line({
          points: [0, vergap+rectheight,rectwidth,vergap+rectheight ],
          stroke: ColorUtils.darkColor(color2, 0.75)
      })
      const line3 = new Konva.Line({
          points: [rectwidth/2, vergap,rectwidth/2,vergap+rectheight ],
          stroke: ColorUtils.darkColor(color2, 0.75)
      })
      const line4 = new Konva.Line({
          points: [0, vergap+rectheight/2,rectwidth,vergap+rectheight/2 ],
          stroke: ColorUtils.darkColor(color2, 0.75)
      })
      this.node.add(line, line2, line3, line4)

      this.node.on('mousedown touchstart',()=>{  
          tankdialogboxModule.setTank(this)     
          tankdialogboxModule.setTankDialogEnabled(true);
      });
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      const timems = Date.now()
      switch(field){
        case "begin": {
          this[field] = timems-Math.round(value.value*60*1000);
          this.duration = this.end - this.begin
          break;
        }
        case "duration": {this[field] = Math.round(value.value);
          break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      const timems = Date.now()
      switch(field){
        case "begin": {value.datatype=3; 
            if(this[field] != null) value.value = Math.round((timems-this[field])/60000); 
            break}
        case "duration": {value.datatype=4; value.value = this[field];break}
      }
      return value;
    }
    
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import Konva from "konva";
import { Value } from "../value";

export class Rectangle extends ObjectView{
    color:string;
    linewidth:number;
    fill:boolean;
    fillcolor:string;

    public drawObject():void{
		super.initObject();
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties==null?  null : this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		let fillcolor = '0xffffff00';
        if (this.fill){
                let fillheight = this.height
                const fillingprop = this.properties==null?  null : this.properties['filling']
                if (fillingprop != null){
                    const fillingproperty:FillingProperty =Object.assign(new FillingProperty(), fillingprop);
                    fillheight = fillingproperty.getHeight(this.tagnames, this.height)
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties==null?  null : this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const rectfill = new Konva.Rect({
                x: 0,
                y: this.height - fillheight,
                width: this.width,
                height: fillheight,
                fill: ColorUtils.convertformat(fillcolor),
              });
              this.node.add(rectfill)
        }    
		const rect = new Konva.Rect({
            x: this.linewidth/2,
            y: this.linewidth/2,
            width: this.width-this.linewidth,
            height: this.height-this.linewidth,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });

          this.node.add(rect)
    }
    
    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "color": {this[field] = value.value;this.rotatednode=null;break;}
            case "linewidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "fill": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
            case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "color": {value.datatype=7; value.value = this[field];break}
            case "linewidth": {value.datatype=3; value.value = this[field];break}
            case "fill": {value.datatype=0; value.value = (this[field]);break}
            case "fillcolor": {value.datatype=7; value.value = this[field];break}
        }
        return value;
    }
}
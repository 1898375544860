import { SVGObject } from "../svgobject";

export class SVGConveyor9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 35.582 112.5" enable-background="new 0 0 35.582 112.5" xml:space="preserve">
        <g id="Group_Rear_Spiral">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="32.4026" y1="43.6108" x2="14.2733" y2="25.4816">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,37.502l0.563,1.238l0.563,0.901l0.563,1.239   l0.226,1.351v1.689l-0.226,0.901l-0.45,1.014l-0.563,1.013l-0.676,1.014h0.676l1.464-0.338l1.351-0.451l1.464-0.788l1.464-0.901   l1.464-1.238l0.788-0.676l0.788-0.676l0.789-0.788l0.788-0.788l0.9-0.789l-0.112-1.351v-1.239l-0.112-1.126L35.132,35.7   l-0.45-1.802l-0.676-1.351l-0.788-1.126l-1.014-0.9l-1.126-0.789l-1.126-0.563l-1.352-0.563l-1.464-0.45l-1.464-0.563l-1.576-0.676   l-1.576-0.788l-1.577-0.901l-0.9-0.563l-0.788-0.676l-0.901-0.788l-0.788-0.788l-0.45,0.338l-0.788,0.675l-0.788,0.789l-0.676,0.9   L14.3,25.904l-0.788,0.788l-0.675,0.788l-0.901,0.789l-0.45,0.225l0.788,0.563l1.689,1.013l1.464,1.126l1.576,1.014l1.464,1.126   l1.464,1.126l1.464,1.126l1.464,1.239L23.647,37.502z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="32.4299" y1="79.6152" x2="14.3013" y2="61.4866">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,73.535l0.563,1.125l0.563,1.014l0.563,1.126   l0.226,1.464v1.689l-0.226,0.9l-0.45,0.9l-0.563,1.014l-0.676,1.127h0.676l1.464-0.338l1.351-0.563l1.464-0.676l1.464-1.013   l1.464-1.127l0.788-0.676l0.788-0.676l0.789-0.787l0.788-0.789l0.9-0.788l-0.112-1.351v-1.239l-0.112-1.126l-0.226-1.014   l-0.45-1.801l-0.676-1.352l-0.788-1.238l-1.014-0.901l-1.126-0.675l-1.126-0.563l-1.352-0.563l-1.464-0.563l-1.464-0.563   l-1.576-0.563l-1.576-0.787l-1.577-1.014l-0.9-0.563l-0.788-0.563l-0.901-0.788l-0.788-0.789l-0.45,0.338l-0.788,0.676   l-0.788,0.789l-0.676,0.787L14.3,61.937l-0.788,0.788l-0.675,0.788l-0.901,0.676l-0.45,0.338l0.788,0.563l1.689,1.013l1.464,1.014   l1.576,1.126l1.464,1.126l1.464,1.014l1.464,1.238l1.464,1.239L23.647,73.535z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="34.2317" y1="113.8467" x2="16.1024" y2="95.7174">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.4" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,109.567v2.478v0.45h0.676h10.472h0.788l-0.112-1.352   v-1.351l-0.112-1.014l-0.226-1.014l-0.45-1.802l-0.676-1.464l-0.788-1.126l-1.014-0.9l-1.126-0.676l-1.126-0.676l-1.352-0.45   l-1.464-0.563l-1.464-0.563l-1.576-0.563l-1.576-0.788l-1.577-1.013l-0.9-0.563l-0.788-0.676l-0.901-0.676l-0.788-0.788   l-0.45,0.225l-0.788,0.789l-0.788,0.788l-0.676,0.788L14.3,97.856l-0.788,0.901l-0.675,0.788l-0.901,0.675l-0.45,0.338l0.788,0.451   l1.689,1.126l1.464,1.013l1.576,1.127l1.464,1.014l1.464,1.125l1.464,1.127l1.464,1.351L23.647,109.567z" />
        </g>
        <g id="Group_Spiral_Front">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="8.446" y1="31.647" x2="37.8352" y2="2.2578">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.3" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,37.502l-3.491-1.689l-3.49-1.689l-3.378-1.689   l-3.153-1.689l-3.04-1.689l-2.478-1.689L2.14,25.341l-1.014-1.352H0.788L0,22.525v-0.45v-0.45l0.788-3.378l0.338-0.338l3.152-1.013   l3.266-1.126l3.153-1.126l3.152-1.239l3.153-1.126l3.152-1.126l3.153-1.126l3.266-1.126l3.04-2.14l2.59-2.477l2.14-2.59l0.9-1.126   l0.338-0.563v0.451v6.869l-1.464,3.04l-2.364,2.815l-2.478,2.477l-2.815,2.59l-2.928,2.252l-3.04,2.027l-2.928,1.464l-2.026,2.59   l0.9,3.378l2.252,2.477l2.703,2.59l1.576,1.689L23.647,37.502z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="8.446" y1="67.6797" x2="37.8916" y2="38.2341">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.3" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,73.535l-3.491-1.689l-3.49-1.689l-3.378-1.689   l-3.153-1.688l-3.04-1.688L4.616,63.4l-2.477-2.14l-1.014-1.238H0.788L0,58.559v-0.451v-0.563l0.788-3.266l0.338-0.338l3.152-1.126   l3.266-1.126l3.153-1.126l3.152-1.126l3.153-1.126l3.152-1.126l3.153-1.126l3.266-1.126l3.04-2.14l2.59-2.477l2.14-2.59l0.9-1.239   l0.338-0.563v0.563v6.869l-1.464,2.928l-2.364,2.928l-2.478,2.477l-2.815,2.478l-2.928,2.365l-3.04,2.027l-2.928,1.464L15.539,62.5   l0.9,3.377l2.252,2.59l2.703,2.479l1.576,1.688L23.647,73.535z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="8.5022" y1="103.6563" x2="37.8914" y2="74.2671">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.3" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.647,109.567l-3.491-1.802l-3.49-1.576l-3.378-1.802   l-3.153-1.577l-3.04-1.801l-2.478-1.576L2.14,97.293l-1.014-1.238l-0.338-0.112L0,94.479v-0.45v-0.45l0.788-3.266l0.338-0.338   l3.152-1.126l3.266-1.126l3.153-1.126l3.152-1.126l3.153-1.127l3.152-1.125l3.153-1.127l3.266-1.125l3.04-2.252l2.59-2.365   l2.14-2.59l0.9-1.238l0.338-0.563v0.563v6.868l-1.464,2.928l-2.364,2.928l-2.478,2.478l-2.815,2.477l-2.928,2.365l-3.04,1.914   l-2.928,1.576l-2.026,2.478l0.9,3.378l2.252,2.478l2.703,2.59l1.576,1.688L23.647,109.567z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.4, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.40625, 151.0, 0.3, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.40625, 151.0, 0.3, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.40625, 151.0, 0.3, 0.07291666666666667, 236.0, 1.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
import { FillColorProperty } from "../properties/fillcolorproperty";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { PercantageTile } from "./percantagetile";
import { LineColorProperty } from "../properties/linecolorproperty";

export class GaugeTile extends PercantageTile{
	needlecolor:string;
	needlecolorpicker:string;
    threshold:number;
    
    public drawObject():void{
		super.initObject();


        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
        const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let fillcolor = '0xffffff00';         
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
        const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
        if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }

        let needlecolor:string = this.needlecolor;
		const lineprop = this.properties==null?null:this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					needlecolor = linecolorproperty.getColor(this.tagnames, this.needlecolor)		
		}	

        let value = 0
        let valueDegrees;
        let valueproperty;
        const valueprop = this.properties['value'];
        if (valueprop!=null){	
        valueproperty =Object.assign(new ValueProperty(), valueprop);
        if (valueproperty!=null) 
            value = valueproperty.getTagValue(this.tagnames) 
            valueDegrees = valueproperty.getValue(this.tagnames)         
        }
        let decimalpos=0;
        if (valueproperty!=null) decimalpos=valueproperty.decimalpos;
        
        let minValue = 0;
        let maxValue = 100;

        if(valueproperty !=null){
            minValue = valueproperty.minimumvalue;
            maxValue = valueproperty.maximumvalue;

        }      

        const size = this.width < this.height ? this.width : this.height;
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
        clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
        },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)


        const textTitle = new Konva.Text({
        x: size*0.05,
        y: size*0.1-0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05,
        height: 0.06*size,
        text: this.title,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(textTitle)

        const textDescription = new Konva.Text({
            x: size*0.05,
            y: this.height - size*0.05-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.description,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(textDescription)

        const barBackground = new Konva.Arc({
            x: this.width / 2,
            y: this.height/ 2 + size * 0.2825, 
            innerRadius: size * 0.3,
            outerRadius: size * 0.3 + size * 0.045,
            angle: 180,
            fill: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
            rotation: 180
        });
        group.add(barBackground)

        const angleStep = 180/(maxValue - minValue);
        //console.log('angleStep', angleStep)

        const thresholdBar = new Konva.Arc({
            x: this.width / 2,
            y: this.height/ 2 + size * 0.2825, 
            innerRadius: size * 0.3,
            outerRadius: size * 0.3 + size * 0.045,
            angle: (maxValue - this.threshold) * angleStep,
            fill: ColorUtils.convertformat(fillcolor),
            rotation: -((maxValue - this.threshold) * angleStep)
        });
        group.add(thresholdBar)

        const arrowComplex = new Konva.Group({
            x: 0,
            y: 0,
          })
          group.add(arrowComplex)

        const needleWidth  = size * 0.014;
        const needleHeight = size * 0.345 /*size * 0.3325;*/
        const needleRect = new Konva.Rect({
            x: -(size * 0.035/2 - needleWidth/2),
            y: 0,
            width: size * 0.035,
            height: size * 0.055,
            fill: ColorUtils.convertformat(bgcolor)
        });
        arrowComplex.add(needleRect)

        const anglearr = valueDegrees-90;
		const radians = anglearr*Math.PI/180;
        
        const needle = new Konva.Rect({
            x: 0,
            y: 0,
            width: needleWidth,
            height: needleHeight,
            fill: ColorUtils.convertformat(needlecolor),
          }); 
          arrowComplex.add(needle)
       
        const posx = this.width/2 -needleWidth/2;
        const posy = this.height/ 2 + size * 0.2825 -2/*this.height/ 2 + size * 0.2825 -size * 0.045/2*/

        const x =needleWidth/2+posx-needleWidth/2* Math.cos(radians) +(needleHeight)* Math.sin(radians);
        const y = posy+needleWidth/2 -(needleWidth/2)* Math.sin(radians) -(needleHeight)* Math.cos(radians);
    
        arrowComplex.position({x:x,y:y})
        arrowComplex.rotation(anglearr) 

        group.add(arrowComplex)

        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2+ size * 0.2825,
            radius: needleWidth*1.5,
            fill: ColorUtils.convertformat(needlecolor),
          });
          group.add(circle)

          const valueUnitText = new Konva.Group({
            x: size*0.05,
            y: 0,
            width: this.width-size*0.05*2
          })
          group.add(valueUnitText)
          
          const valueText = new Konva.Text({
            x: 0,
            y: size*0.37-0.24*size,
            verticalAlign:"bottom",
           // width: this.width-size*0.05,
            height: 0.24*size,
            text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
            fontSize: 0.24*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            offsetY:-size*0.024
            });  
        valueUnitText.add(valueText)

        const unitText = new Konva.Text({
            x: valueText.width(),
            y: size*0.37-0.24*size,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.24*size,
            text:this.unit,
            fontSize: 0.12*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        valueUnitText.add(unitText)

        valueUnitText.offsetX(-this.width/2+unitText.width()/2+valueText.width()/2+size*0.05)

        const minValueTextTransparent = new Konva.Text({
            x: this.width/2-0.345*size,
            y: this.height/2+size * 0.3625-0.08*size,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text:minValue.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  

        const minValueText = new Konva.Text({
            x: this.width/2-0.3*size-0.045*size/2-minValueTextTransparent.width()/2,
            y: this.height/2+size * 0.3625-0.07*size,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text:minValue.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(minValueText)

        const maxValueTextTransparent = new Konva.Text({
            x: this.width/2-0.3*size-minValueTextTransparent.width()/2,
            y: this.height/2+size * 0.3625-0.07*size,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text:maxValue.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  

        const maxValueText = new Konva.Text({
            x: this.width/2+0.3*size+0.045*size/2-maxValueTextTransparent.width()/2,
            y: this.height/2+size*0.3625-0.07*size,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text:maxValue.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(maxValueText)

        
        const thresholdTextTransparent = new Konva.Text({
            x: 0,
            y: 0,
            verticalAlign:"bottom",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text: this.threshold.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  

        const thresholdText = new Konva.Text({
            x: this.width/2 - thresholdTextTransparent.width()/2,
            y: this.height/2+size*0.43-0.07*size,
            verticalAlign:"middle",
            //width: this.width-size*0.05,
            height: 0.08*size,
            text: this.threshold.toString(),
            fontSize: 0.08*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(bgcolor),
            align: 'center'
            });  
        

        const thresholdRect = new Konva.Rect({
            x: this.width/2 - thresholdTextTransparent.width()/2-+size*0.05/2,
            y: this.height/2+size*0.43-0.07*size- 0.01*size, /*this.height/2+ size*0.35*/
            width: thresholdTextTransparent.width()+size*0.05,
            height: 0.09*size,
            fill: value>this.threshold? ColorUtils.convertformat(fillcolor) : ColorUtils.darkColor(ColorUtils.convertformat(bgcolor), 3),
            cornerRadius: size * 0.025,
        });
        group.add(thresholdRect)
        group.add(thresholdText)
          
        this.node.add(group)     
    }
}
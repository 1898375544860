import { ObjectView } from "./object";
import { TextView } from "./text";
import { RecipeRow } from "./properties/range/reciperow";
import Konva from "konva";
import { DatabaseColumn } from "./properties/range/databasecolumn";
import { Value } from "../value";


export class DatabaseTable extends ObjectView{

    title:string;
    fontsize:number;
    columnwidth:number;
    usetitle:boolean;
    rows:RecipeRow[];
    cols:string[];
    usecolumns:boolean;
    columns:DatabaseColumn[];

    x:number;
    y:number;

    beginrow=0;

    public drawObject():void{
        console.log(this.columns[0].dbname)
        super.initObject()
        this.x = 0
        this.y=0
        if (this.usetitle){
            const title = new TextView()
            title.width = this.width
            title.height = this.height/10
            title.text = this.title
            title.fontsize = this.fontsize
            title.posx = 0
            title.posy = 0
            title.textplacement = 0
            title.textcolor = '0x000000FF'
            title.useborder = false
            title.bordercolor = null
            title.linewidth = 0
            title.fill = false
            title.fillcolor = '0xFFFFFF00'
            title.underline = false
            title.initLayer(this.layer)
            title.drawObject()
            this.node.add(title.rotatednode)
            this.y+=this.height/10;
        }
      
           
        
        this.cols.forEach((col)=>{
            const column =  this.getDBColumn(col,this.columns);
                if (this.usecolumns && column){
                    this.drawTitle(column.width,column.title)
                    this.x+=column.width
                }
                else{
                    this.drawTitle(this.columnwidth,col)
                    this.x+=this.columnwidth
                }
        })
        this.y+=this.fontsize+5
        this.x=0;
        let numrow = 0;
        this.rows.forEach((reciperow)=>{
            numrow++;
            if (numrow<this.beginrow)
                return;
            this.drawRow(reciperow)
        })
 
        this.node.on('wheel', (e)=> {
            this.beginrow+=e.evt.deltaY/(3*this.fontsize+3)
            if(this.beginrow<0)
                this.beginrow=0
            if (this.beginrow>this.rows.length-2)
                this.beginrow=this.rows.length-2
            //console.log("beginrow="+this.beginrow)
        
            setTimeout(()=>{
                this.drawObject()
            }, 200);
        })
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "databasename": 
            case "username": 
            case "password": 
            case "resultset": 
            case "title":
            case "tablename": 
            case "columnwidtharray":
            case "csv": {this[field] = value.value;this.rotatednode=null;break;}
            case "usetitle": 
            case "disableprint":
            case "disablesavereport":{
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
            case "fontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "columnwidth": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            case "rownumber": {this[field] = Math.trunc(value.value);this.rotatednode=null;break;}  
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "databasename": 
            case "username": 
            case "password": 
            case "resultset": 
            case "title":
            case "tablename": 
            case "columnwidtharray":
            case "csv": {value.datatype=7; value.value = this[field];break}
            case "usetitle":
            case "disableprint":
            case "disablesavereport": {value.datatype=0; value.value = (this[field]);break}
            case "fontsize": {value.datatype=3; value.value = this[field];break}
            case "columnwidth": {value.datatype=3; value.value = this[field];break}
            case "rownumber": {value.datatype=3; value.value = this[field];break}
        }
        return value;
    }
    
    private drawRow(reciperow:RecipeRow){
        if (this.y+this.fontsize+5>this.height)
             return;
        this.x=0
        const row = new Konva.Group()
        let color ="0x000000FF"
        let bgcolor = "0xFFFFFFFF"
       // this.drawRowCell(row,this.namecolumnwidth,reciperow._name,color,bgcolor)
      //  console.log(reciperow)
      //  this.x+=this.namecolumnwidth
        reciperow.tagnames = new Map()
        reciperow.columnnames = new Map()
        this.cols.forEach((col)=>{
               // reciperow.tagnames.set(ing.dbname, ing.tagname)
               // reciperow.columnnames.set(ing.dbname, ing.ingredientname)
               const column =  this.getDBColumn(col,this.columns);
               if (this.usecolumns && column){
                    const value = +reciperow.ingredients[col]
                    column.bgrange.forEach((range)=>{
                        if (range.from<=value && range.to>value)
                            bgcolor = range.color
                    })
                    column.textrange.forEach((range)=>{
                        if (range.from<=value && range.to>value)
                            color = range.color
                    })
                    this.drawRowCell(row, column.width,reciperow.ingredients[col],color, bgcolor)
                    this.x+=column.width
               }
               else{
                this.drawRowCell(row,this.columnwidth,reciperow.ingredients[col],color,bgcolor)
                this.x+=this.columnwidth
               }
        })
      /*  row.setAttr("row", reciperow)
        row.on('mousedown touchstart',()=>{  
            //console.log(row.getAttr("row"))
            recipeModule.setRecipeRow(row.getAttr("row"))
            recipeModule.setRecipeRowEnabled(true)
      });*/
          this.node.add(row)
        this.y+=this.fontsize+5
    }
    private drawRowCell(node:Konva.Group,width:number, text:string, textcolor:string, bgcolor:string){
        this.drawCell(node, width, text, textcolor,bgcolor)
    }
    private drawTitle(width:number, text:string){
        this.drawCell(this.node,width,text,'0x000000FF', '0xBBBBBBFF')
    }
    private drawCell(node:Konva.Group, width:number, text:string, textcolor:string, fillcolor:string){
        if (this.x>this.width) return;
        const cell = new TextView()
        cell.width = (this.x+width)>this.width?this.width-this.x:width
        cell.height = this.fontsize+5
        cell.text = text
        cell.fontsize = this.fontsize
        cell.posx = this.x;
        cell.posy = this.y
        cell.textplacement = 1;
        cell.textcolor = textcolor
        cell.useborder = true
        cell.bordercolor = '0x000000FF'
        cell.linewidth = 1
        cell.fill = true
        cell.fillcolor = fillcolor
        cell.underline = false
        cell.initLayer(this.layer)
        cell.drawObject()
        node.add(cell.rotatednode)
    }
    private getDBColumn(col:string, columns:DatabaseColumn[]):DatabaseColumn{
        const column = columns.find(col1 => col1.dbname === col)
        return column
    }

}

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip,TimeScale, Legend,
   LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'
import 'chartjs-adapter-date-fns';
import {ref} from "vue"
import AuthModule from '../store/auth.module'
import TagsService from '@/services/tags.service';
import historydatarequest from '../model/requests/historydatarequest'
import { format } from 'date-fns'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import exportFile from 'quasar/src/utils/export-file.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useI18n } from 'vue-i18n';

ChartJS.register(Title, Tooltip, Legend, TimeScale, LineElement, 
  PointElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      return {
       
      }
    }
    },
    plugins: {
      type: Array,
      default: () => [{
        legend: {
                display: true
            }
    }
      ]
    },
    tagname:{
      type: String
    },
   
    description:{
      type:String
    },
    maxToggle:{
      type:Boolean
    },
    usehistory:{
      type:Boolean
    },
    id:{
      type:Number
    },
    linecolor:{
      type:String
    }
  },
  computed:{
     
      chartData(){
        return {     
          datasets:[{
            label: this.tagname,
            radius:0,
            data:this.historydata,
            borderColor: this.linecolor,
            borderWidth: 1,
            backgroundColor: this.linecolor,
            fill:false
          }]
        };
      },
      chartOptions(){
          return {
          scales: {
               x: {
                    type: 'time',
                    min: this.begindate,
                    time: {
                             tooltipFormat: 'dd MMM HH:mm',
                            
                             displayFormats: {
                                 millisecond: 'HH:mm',
                                 second:'HH:mm:ss',
                                 minute: 'HH:mm',
                                 hour: 'dd MMM HH:mm'
                              }
                           },
                    title: {
                             display: true,
                              text: this.t('events.date')
                           }
                   },
                y: {
                       title: {
                                display: true,
                                text: this.t('events.value')
                              }
                  }
                 }
          }
        }  
  },
  setup(props) {
    const {t} = useI18n({useScope:'global'})   
    const q = useQuasar()
    let historydata = ref([])
    let begindate = ref(1619066131947)
    let maximizedToggle=ref(false)
    let history = ref(false)
   
    return {
      t,
     q,
      historydata,
      begindate,
      maximizedToggle,
      history,
      model: ref({label:t('events.hour1'), value:60}),

      options: [
      {label:t('events.hour1'), value:60},
          {label:t('events.hours2'), value:120},
          {label:t('events.hours3'), value:180},
          {label:t('events.hours6'), value:360},
          {label:t('events.hours12'), value:720},
          {label:t('events.day1'), value:1440},
          {label:t('events.days3'), value:4320},
          {label:t('events.days7'), value:10080},
          {label:t('events.days14'), value:20160},
          {label:t('events.days30'), value:43200}
      ]
      //history2
        }
        
      },
methods:{
  wrapCsvValue (val:string) {
              let formatted =  val
  
              formatted = formatted === void 0 || formatted === null
                      ? '': String(formatted)
              formatted = formatted.split('"').join('""')
              return `"${formatted}"`
        },
  exportTable () {
    let content=this.wrapCsvValue(this.t('tags.dateandtime')).concat(',');
    content = content.concat(this.wrapCsvValue(this.description)).concat(',') 
    content = content.replace(/.$/,"\r\n")
    if (this.historydata){
             
             this.historydata.forEach((row)=>{
               content = content.concat(format(row.x,"yyyy-MM-dd HH:mm:ss")).concat(',')
               content = content.concat(row.y).concat(',')
               content = content.replace(/.$/,"\r\n")
           })
         }
            
            const status = exportFile(
            'History_data('+format(this.begindate, "yyyy-MM-dd HH:mm - ").toString()+format(Date.now(), "yyyy-MM-dd HH:mm").toString()+').csv',
            content,
            'text/csv'
          )
         
          if (status !== true) {
            this.q.notify({
              message: this.t('tags.browserdenied'),
              color: 'negative',
              icon: 'warning'
            })
          }
          else{
               this.q.notify({
              message: this.t('tags.browserdownloaded'),
              color: 'positive',
              icon: 'warning'
            })
         }
        },
  exportTablePDF(){
          const doc = new jsPDF()
          var xOffset = doc.internal.pageSize.width / 2
          doc.text('History data('+format(this.begindate, "yyyy-MM-dd HH:mm - ").toString()+format(Date.now(), "yyyy-MM-dd HH:mm").toString()+')',
              xOffset, 8, {align: 'center'});
          let pdfrows = [];
          let headers = [];
        headers=['Date and Time']
          headers.push(this.description)
            if (this.historydata){
             
              this.historydata.forEach((row)=>{
                let pdfrow = []
                pdfrow.push(format(row.x,"yyyy-MM-dd HH:mm:ss"))
                pdfrow.push(row.y)
              pdfrows.push(pdfrow)
            })
          }

          autoTable(doc, {
                    head:[headers],
                    body: pdfrows,
              })
            doc.save('History_data('+format(this.begindate, "yyyy-MM-dd HH-mm -- ").toString()+format(Date.now(), "yyyy-MM-dd HH-mm").toString()+').pdf')
        },
  updateTimeSelection(value){
      console.log(value)
      this.getHistoryData(value.value)
  },
  onBeforeShow(){
      console.log("onBeforeshow")
      this.maximizedToggle =  localStorage.getItem('maximizedToggle')=='true'
      this.model ={label:this.t('events.hour1'), value:60}
      this.getHistoryData(60)
      
  },
  onBeforeHide(){
    this.history = false;
    this.$emit('update:Value', false)
      console.log("onBeforeHide")
      localStorage.setItem('maximizedToggle', this.maximizedToggle)
  },
  getHistoryData(deltatime:number){
   // console.log(this.id)
    //console.log(this.tagname)
    //console.log(this.description)
  
    if (AuthModule.currentUser!=null){
        let enddate = Date.now();
        this.begindate = enddate - deltatime*60*1000;//delta*minutes
       let request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
          name:this.tagname, begindate:this.begindate, enddate:enddate};
        TagsService.gethistorydata(request,(data:string)=>{
        console.log(data);
             
        this.historydata =JSON.parse(data);
   
      }, 
        (data:string)=>{
            console.log(data);
         })
  }
},
}
  
  
}  

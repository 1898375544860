import { SVGObject } from "../svgobject";

export class SVGBlower3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 61.148" enable-background="new 0 0 112.5 61.148" xml:space="preserve">
        <g id="Group_Exhaust">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.703,51.802l2.14-12.726h37.613v-3.828H68.92V31.87h24.212v19.933   L2.703,51.802L2.703,51.802z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M68.355,31.983v-1.126H93.13v1.126H68.355z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M41.892,35.249v-1.127h27.027v1.127H41.892z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M4.279,39.077v-1.125h38.176v1.125H4.279z" />
        </g>
        <g id="Group_Fasteners">
            <radialGradient id="SVGID_1_" cx="17.0039" cy="45.6077" r="3.2664" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#F5F5F5" />
                <stop offset="0.58" style="stop-color:#E2E2E3" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" cx="17.004" cy="45.608" r="3.266" />
            <radialGradient id="SVGID_2_" cx="47.8604" cy="41.217" r="3.2656" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#F5F5F5" />
                <stop offset="0.58" style="stop-color:#E2E2E3" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" cx="47.86" cy="41.217" r="3.266" />
            <radialGradient id="SVGID_3_" cx="82.0957" cy="37.9514" r="3.2656" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#F5F5F5" />
                <stop offset="0.58" style="stop-color:#E2E2E3" />
                <stop offset="1" style="stop-color:#666666" />
            </radialGradient>
            <circle fill="url(#SVGID_3_)" cx="82.096" cy="37.951" r="3.266" />
        </g>
        <g id="Group_Label4">
            <path fill="#FFFFFF" d="M71.059,39.64h5.519v2.253h-5.519V39.64z" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M71.059,41.893V39.64h5.519" />
        </g>
        <g id="Group_Label3">
            <path fill="#FFFFFF" d="M71.059,34.122h5.519v4.393h-5.519V34.122z" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M71.059,38.514v-4.393h5.519" />
        </g>
        <g id="Group_Red">
            <path fill="#990000" d="M72.186,40.204h3.377v1.125h-3.377V40.204z" />
        </g>
        <g id="Group_Bottom_Support">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M1.577,52.928H100.9v6.531H1.577V52.928z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,59.459h101.576v1.127H0.451V59.459z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,51.802h101.576v1.126H0.451V51.802z" />
        </g>
        <g id="Group_Rotor">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="69.9893" y1="34.1223" x2="69.9893" y2="15.4285">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M46.847,34.122V15.428h11.036v3.829h6.645v-2.815h28.604   v7.771l-3.266,6.644H68.919v3.266H46.847z" />
        </g>
        <g id="Group_Label2">
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M48.986,31.87v-4.393h5.518" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M54.504,27.478v4.393h-5.405" />
        </g>
        <g id="Group_Label1">
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M85.473,28.604v-2.252h4.394" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M89.865,26.352v2.252h-4.393" />
        </g>
        <g id="Group_Shaft">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="45.7212" y1="27.4778" x2="45.7212" y2="18.6936">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.595,27.478h2.252v-8.784h-2.252V27.478z" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="22.5229" y1="35.2493" x2="22.5229" y2="10.9246">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.811,35.249l8.784-1.804V12.613l-8.784-1.688H4.842   L0.45,12.613v21.734l4.392,0.901L35.811,35.249" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M4.842,35.249V10.924" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.486,10.924v24.325" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M35.811,10.924v24.325" />
        </g>
        <g id="Group_Motor_Vent">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M12.613,16.104h22.072v19.146H12.613V16.104z" />
        </g>
        <g id="Group_Motor_Fins">
            <path fill="#E5E5E5" d="M12.049,24.775h23.198v0.901H12.049V24.775z" />
            <path fill="#E5E5E5" d="M12.049,27.365h23.198v0.9H12.049V27.365z" />
            <path fill="#E5E5E5" d="M12.049,17.005h23.198v0.901H12.049V17.005z" />
            <path fill="#E5E5E5" d="M12.049,19.595h23.198v0.901H12.049V19.595z" />
            <path fill="#BFBFBF" d="M12.049,29.618h23.198v0.788H12.049V29.618z" />
            <path fill="#BFBFBF" d="M12.049,31.307h23.198v0.9H12.049V31.307z" />
            <path fill="#999999" d="M12.049,33.108h23.198v0.789H12.049V33.108z" />
            <path fill="#F2F2F2" d="M12.049,22.185h23.198v0.901H12.049V22.185z" />
        </g>
        <g id="Group_Stand">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M13.739,36.825l0.563-1.576h18.694l0.563,1.576H13.739z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="11.4858" y1="35.7561" x2="35.812" y2="35.7561">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M11.486,38.514v-1.688h6.645v-3.828h11.036v3.828h6.645v1.688   H11.486z" />
        </g>
        <g id="Group_Motor_Top">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="25.9009" y1="10.9241" x2="40.2031" y2="10.9241">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M26.352,12.05l-0.451-2.252h14.302L39.64,12.05H26.352z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="25.9009" y1="7.658" x2="40.2031" y2="7.658">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M25.901,9.347l1.014-3.378h12.162l1.126,3.378H25.901z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M24.775,9.347h16.554v0.451H24.775V9.347z" />
        </g>
        <g id="Group_Coupling">
            <path fill="#666666" d="M106.419,39.64h1.126V13.176h-1.126V39.64z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="99.7754" y1="49.5491" x2="99.7754" y2="7.6589">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M93.131,7.658h13.288v41.891H93.131V7.658z" />
        </g>
        <g id="Group_1">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="99.7744" y1="47.4114" x2="99.7744" y2="1.5779">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M95.383,47.411V1.577h8.783V47.41" />
            <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,0.451h11.036v1.126H94.257V0.451z" />
            <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,14.189h11.036v1.126H94.257V14.189z" />
            <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,27.478h11.036v1.126H94.257V27.478z" />
            <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,40.204h11.036v1.125H94.257V40.204z" />
        </g>
        <g id="Group_Port">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="109.1777" y1="51.802" x2="109.1777" y2="1.0144">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M107.545,51.802h3.266V1.014h-3.266V51.802" />
        </g>
        <g id="Group_Bolt">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,3.266h1.127v3.267h-1.127V3.266z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,11.825h1.127v3.266h-1.127V11.825z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,20.496h1.127v3.266h-1.127V20.496z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,29.055h1.127v3.266h-1.127V29.055z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,37.725h1.127v3.267h-1.127V37.725z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M110.811,46.284h1.127v3.266h-1.127V46.284z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.08333333333333333, 233.0, 0.58, 0.234375, 195.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.08333333333333333, 233.0, 0.58, 0.234375, 195.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.08333333333333333, 233.0, 0.58, 0.234375, 195.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.7916666666666666, 0.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Exhaust",[0.0, 0.609375, 99.0, 1.0, 0.11458333333333333, 226.0, 2.0, 0.11458333333333333, 226.0, 3.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.fills,"Group_Bottom_Support",[0.0, 0.609375, 99.0, 1.0, 0.11458333333333333, 226.0, 2.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.fills,"Group_Motor_Vent",[0.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Motor_Fins",[0.0, 0.20833333333333334, 201.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.20833333333333334, 201.0, 4.0, 0.5052083333333334, 125.0, 5.0, 0.5052083333333334, 125.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_1",[0.0, 0.3020833333333333, 177.0, 1.0, 0.3020833333333333, 177.0, 2.0, 0.3020833333333333, 177.0, 3.0, 0.3020833333333333, 177.0]);

        super.drawObject();
    }
}
import { Property } from "./property";
import { TextValueRange } from "./range/textvaluerange";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";



export class SelectorProperty extends Property{
   
    textrange:TextValueRange[];

    public getCurrentText(tagnames:string[], defaulttext:string):string{
        const tag:tag = this.getTag(tagnames);
		if (tag==null) return defaulttext; 
    }

    public getCurrentValue(tagnames:string[]):number{
		const tag:tag = this.getTag(tagnames);
        if(tag!=null){
            return +tag.tagvalue
        }
        return 0
    }

    public sendTagValue(tagnames:string[], value:string):void{
        const tag:tag = this.getTag(tagnames);
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:value, datetime:0});
    }


    
}
import { SVGObject } from "../svgobject";

export class SVGHeating26 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 70.271" enable-background="new 0 0 112.5 70.271" xml:space="preserve">
        <g id="Group_Compensator_sub">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.2505" y1="58.5598" x2="56.2505" y2="11.6003">
                <stop offset="0" style="stop-color:#991919" />
                <stop offset="0.5" style="stop-color:#FF7F7F" />
                <stop offset="1" style="stop-color:#991919" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#991919" stroke-width="0.25" d="M112.5,11.6H0v46.96h112.5V11.6" />
        </g>
        <g id="Group_Middle_Layer">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="58.5598" x2="56.1938" y2="11.6003">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.5" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M88.964,11.6H23.423v46.96h65.541V11.6z" />
        </g>
        <g id="Group_Coils">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="28.0967" y1="63.177" x2="28.0967" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" d="M30.405,6.983h-4.617v56.194h4.617V6.983z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="42.1172" y1="63.177" x2="42.1172" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M44.482,6.983h-4.729v56.194h4.729V6.983" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="35.1353" y1="63.177" x2="35.1353" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M37.5,6.983h-4.729v56.194H37.5V6.983" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="49.2114" y1="63.177" x2="49.2114" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.1" d="M51.577,6.983h-4.73v56.194h4.73V6.983" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="63.2324" y1="63.177" x2="63.2324" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.1" d="M65.541,6.983h-4.617v56.194h4.617V6.983" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="63.177" x2="56.1938" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.1" d="M58.559,6.983h-4.73v56.194h4.73V6.983" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="70.3262" y1="63.177" x2="70.3262" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.1" d="M72.635,6.983h-4.617v56.194h4.617V6.983" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="84.3477" y1="63.177" x2="84.3477" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.1" d="M86.712,6.983h-4.729v56.194h4.729V6.983" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="77.3086" y1="63.177" x2="77.3086" y2="6.9832">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.1" d="M79.617,6.983H75v56.194h4.617V6.983" />
        </g>
        <g id="Group_Frame">
            <path fill="#7F0000" stroke="#4C4C4C" stroke-width="0.25" d="M103.041,65.541v2.365H9.347v-2.365H103.041z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="4.6184" x2="56.1938" y2="2.2532">
                <stop offset="0" style="stop-color:#FF7F7F" />
                <stop offset="0.5" style="stop-color:#E8BAB6" />
                <stop offset="1" style="stop-color:#FF7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#961919" stroke-width="0.25" d="M103.041,2.253v2.365H9.347V2.253H103.041z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="96.0586" y1="70.2708" x2="96.0586" y2="0.0017">
                <stop offset="0" style="stop-color:#991919" />
                <stop offset="0.5" style="stop-color:#FF7F7F" />
                <stop offset="1" style="stop-color:#991919" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#991919" stroke-width="0.1" d="M98.424,0.001h-4.73v70.27h4.73V0.001z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="16.3286" y1="70.2708" x2="16.3286" y2="0.0017">
                <stop offset="0" style="stop-color:#991919" />
                <stop offset="0.5" style="stop-color:#FF7F7F" />
                <stop offset="1" style="stop-color:#991919" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#991919" stroke-width="0.1" d="M18.694,0.001h-4.73v70.27h4.73V0.001z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5208333333333334, 0.0, 0.5, 0.6822916666666666, 81.0, 1.0, 0.5208333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.5, 0.3020833333333333, 177.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.0, 255.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.6822916666666666, 81.0, 0.5, 0.4375, 143.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.5208333333333334, 0.0, 0.5, 0.6822916666666666, 81.0, 1.0, 0.5208333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.5208333333333334, 0.0, 0.5, 0.6822916666666666, 81.0, 1.0, 0.5208333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.3229166666666667, 0.0]);
        super.drawObject();
    }
}
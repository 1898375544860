import { SVGObject } from "../svgobject";

export class SVGTank52 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 63.324 112.5" enable-background="new 0 0 63.324 112.5" xml:space="preserve">
        <g id="Group_Railing">
            <path fill="#FFCC66" d="M9.834,5.279h43.895v1.679H9.834V5.279z" />
            <path fill="#FFCC66" d="M11.514,8.877h40.537v1.679H11.514V8.877z" />
            <path fill="#CC9933" d="M11.514,9.597h40.537v0.959H11.514V9.597z" />
            <path fill="#CC9933" d="M9.834,6.238h43.895v0.72H9.834V6.238z" />
            <path fill="#FFCC66" d="M36.939,11.516V6.958l2.639-5.277h2.638l-2.638,5.277v4.558H36.939z" />
            <path fill="#FFCC66" d="M26.385,11.516V6.958l-2.638-5.277h-2.639l2.639,5.277v4.558H26.385z" />
            <path fill="#CC9933" d="M37.898,11.516V6.958l2.639-5.277h-0.959l-2.639,5.277v4.558H37.898z" />
            <path fill="#CC9933" d="M25.666,11.516V6.958l-2.639-5.277h0.72l2.638,5.277v4.558H25.666z" />
            <path fill="#FFCC66" d="M55.408,1.681H7.916l4.558,5.277v4.558H9.834V6.958L3.598,0.002h56.368l-6.236,6.956v4.558h-2.639V6.958   L55.408,1.681z" />
            <path fill="#CC9933" d="M11.514,11.516V6.958L6.236,0.961h52.77l-5.276,5.997v4.558h-0.96V6.958l4.559-5.277H7.916l4.558,5.277   v4.558H11.514z" />
            <path fill="none" d="M36.939,11.516V6.958l2.639-5.277h2.638l-2.638,5.277v4.558H36.939" />
            <path fill="none" d="M26.385,11.516V6.958l-2.638-5.277h-2.639l2.639,5.277v4.558H26.385" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="62.0059" x2="63.3242" y2="62.0059">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M9.354,11.516c0,0-2.256-0.142-5.517,3.118C-0.089,18.561,0,21.11,0,21.11v91.388h63.324V21.11   c0,0,0-2.86-2.398-5.277c-3.482-3.509-6.717-4.317-6.717-4.317" />
            <path fill="#7F7F7F" d="M8.395,13.674H6.236v98.824h2.159V13.674z" />
        </g>
        <g id="Group_Ladder">
            <path fill="#7F7F7F" d="M17.03,112.498v-4.797H7.676v-1.439h9.354v-4.797H7.676v-1.439h9.354v-4.797H7.676v-1.439h9.354v-4.798   H7.676v-1.679h9.354v-4.558H7.676v-1.679h9.354v-4.558H7.676V74.84h9.354v-4.558H7.676v-1.679h9.354v-4.798H7.676v-1.438h9.354   v-4.798H7.676V56.13h9.354v-4.797H7.676v-1.439h9.354v-4.797H7.676v-1.439h9.354V38.86H7.676v-1.679h9.354v-4.557H7.676v-1.679   h9.354v-4.558H7.676v-1.679h9.354v-4.797H7.676v-1.439h9.354v-4.797h2.398v98.824H17.03z" />
            <path fill="#FFCC66" d="M14.632,11.516v4.557H5.997v-4.557H3.838V110.1h2.159v-4.558h8.635v4.558h2.398V11.516H14.632z    M14.632,103.863H5.997v-4.798h8.635V103.863z M14.632,97.627H5.997v-4.798h8.635V97.627z M14.632,91.391H5.997v-4.798h8.635   V91.391z M14.632,85.153H5.997v-4.797h8.635V85.153z M14.632,78.917H5.997v-4.798h8.635V78.917z M14.632,72.441H5.997v-4.559h8.635   V72.441z M14.632,66.204H5.997v-4.558h8.635V66.204z M14.632,59.968H5.997v-4.557h8.635V59.968z M14.632,53.731H5.997v-4.797h8.635   V53.731z M14.632,47.495H5.997v-4.797h8.635V47.495z M14.632,41.258H5.997v-4.797h8.635V41.258z M14.632,35.022H5.997v-4.797h8.635   V35.022z M14.632,28.786H5.997v-4.797h8.635V28.786z M14.632,22.309H5.997v-4.557h8.635V22.309z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { TextInputProperty } from "./properties/textinputproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { TextView } from './text'
import { Value } from "../value";



export class DigitalMeter extends ObjectView{
    text:string
    textcolor:string;
    type3d:number;
    bordercolor:string;
    fillcolor:string;

    public drawObject():void{
		super.initObject();

		let bordercolor:string = this.bordercolor;
		const lineprop = this.properties==null?  null : this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					bordercolor = linecolorproperty.getColor(this.tagnames, this.bordercolor)
		}	
    const bordercolor2 = ColorUtils.convertformat(bordercolor)
    let textcolor:string = this.textcolor;
		const textcolorprop = this.properties==null?  null : this.properties['textcolor'];
		if (textcolorprop!=null){	
			const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
			if (textcolorproperty!=null) 
					textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
		}	

    let textinput:string
    //let textinputproperty:TextInputProperty
		const textinputprop = this.properties==null?  null : this.properties['textinput'];
		if (textinputprop!=null){	
			const textinputproperty =Object.assign(new TextInputProperty(), textinputprop);
			if (textinputproperty!=null) 
        textinput = textinputproperty.getCurrentText(this.tagnames, '0')
		}	
    
    const fillcolor2 = ColorUtils.convertformat(this.fillcolor)

		const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
          if(this.type3d==0){
              rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
              rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
              rect.fillLinearGradientColorStops([0, 'white',
                    1, bordercolor2])
          }else{
            rect.fill(ColorUtils.darkColor(bordercolor2, 0.75))
          }
    this.node.add(rect)

  const rect2 = new Konva.Rect({
    x: this.width/25,
    y: this.height/12,
    width: this.width*23/25,
    height: this.height*10/12,
  });
  if(this.type3d==0){
      rect2.fillLinearGradientStartPoint({ x: 0, y: 0 });
      rect2.fillLinearGradientEndPoint({ x: 0, y: this.height*10/12});
      rect2.fillLinearGradientColorStops([0, bordercolor2,
            1, 'white'])
  }else{
    rect2.fill(bordercolor2)
  }
this.node.add(rect2)

    const rect3 = new Konva.Rect({
      x: this.width/15,
      y: this.height/7,
      width: this.width*13/15,
      height: this.height*4/7,
    });
    if(this.type3d==0){
        rect3.fillLinearGradientStartPoint({ x: 0, y: 0 });
        rect3.fillLinearGradientEndPoint({ x: 0, y: this.height/7});
        rect3.fillLinearGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2, 0.1)])
    }else{
      rect3.fill(ColorUtils.darkColor(fillcolor2, 0.1))
    }
  this.node.add(rect3)

    const labelTextOb = new TextView()
    labelTextOb.width = this.width*13/15
    labelTextOb.height = this.height/6
    labelTextOb.text = this.text;
    labelTextOb.fontsize = this.height/6
    labelTextOb.posx = this.width/15
    labelTextOb.posy = this.height*12/14-this.height/8
    labelTextOb.textplacement = 0
    labelTextOb.textcolor = ColorUtils.darkColor(bordercolor2, 0.1)
    labelTextOb.useborder = false
    labelTextOb.bordercolor = null
    labelTextOb.linewidth = 0
    labelTextOb.fill = false
    labelTextOb.fillcolor = ColorUtils.darkColor(bordercolor2, 0.1)
    labelTextOb.underline = false
    labelTextOb.initLayer(this.layer)
    labelTextOb.drawObject()
    this.node.add(labelTextOb.rotatednode)

    const valueTextOb = new Konva.Text({
      x: this.width/15,
      y: this.height*10/14-this.height/2,
      verticalAlign:"middle",
      width: this.width*13/15,
      height: this.height/2,
      text: textinput,
      fontSize: this.height/2,
      fontFamily: "DS-Digital",
      align:'right',
      fontStyle: "normal",
      //align:StringUtils.getTextPlacement(this.textplacement),
      fill: ColorUtils.convertformat(textcolor),
      //textDecoration:this.underline?"underline":""
    })
    
    this.node.add(valueTextOb)
  }

  public setField(field:string, value:Value){
    super.setField(field, value)
    switch(field){
        case "textcolor": 
        case "fillcolor": 
        case "bordercolor": 
        case "text": {this[field] = value.value;this.rotatednode=null;break;}
        case "type3d": {this[field] = Math.trunc(value.value);this.rotatednode=null;break;}
    }
    //this.drawObject();
  }

  public getField(field:string):Value{
    const value = super.getField(field)
    switch(field){
        case "textcolor": 
        case "fillcolor": 
        case "bordercolor": 
        case "text": {value.datatype=7; value.value = this[field];break}
        case "type3d": {value.datatype=1; value.value = this[field];break}
    }
    return value;
  }
    
  
  }
import {ColorUtils} from '@/model/project/utils/colorutils'
import { IndicatorProperty } from './properties/indicatorproperty';
import Konva from "konva";
import { Indicator } from './indicator';

export class OvalIndicator extends Indicator{   
   

    public drawObject():void{
        super.initObject();

        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	


        const truecolor = ColorUtils.convertformat(this.truecolor);
        const falsecolor = ColorUtils.convertformat(this.falsecolor);

        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, 'white', 
            1, '#888888'])
          }
          else{
            rectout.fill('#888888')
          }
          this.node.add(rectout) 
        
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradientrect = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientrect.addColorStop(0, '#888888')
          gradientrect.addColorStop(1, 'white')

          const gradientoval = ctx.createLinearGradient(0, -this.height*6/20, 0, this.height*6/20);
          gradientoval.addColorStop(0, '#888888')
          gradientoval.addColorStop(1, 'white')


          const rectinner = new Konva.Rect({
            x: this.width/10,
            y: this.height/10,
            width: this.width*4/5,
            height: this.height*4/5,
            cornerRadius: this.height/10,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientrect: 'white'
          });
          
          this.node.add(rectinner)   

        const oval1 = new Konva.Ellipse({
            x: this.width*6/20,
            y: this.height*10/20,
            radiusX: this.width*3/20,
            radiusY: this.height*6/20,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientoval: 'white'
          });
          if(indicator){
          if (this.type3d==0){
            oval1.fillLinearGradientStartPoint({ x: 0, y: -this.height*6/20 });
            oval1.fillLinearGradientEndPoint({ x: 0, y: this.height*6/20 });
            oval1.fillLinearGradientColorStops([0, ColorUtils.darkColor(falsecolor,0.75), 
            1, ColorUtils.darkColor(falsecolor,0.2)])
          }
          else{
            oval1.fill(ColorUtils.darkColor(falsecolor,0.25))
            oval1.stroke('white')
            oval1.strokeWidth(this.height/40)
          }
        }else{
            oval1.fillRadialGradientStartPoint({x:0, y:0});
            oval1.fillRadialGradientEndRadius(this.height/2)
            oval1.fillRadialGradientColorStops([0, 'white', 1, falsecolor]);
            oval1.fillPriority('radial-graident')
        }
          this.node.add(oval1)
  
        const oval2 = new Konva.Ellipse({
                x: this.width*14/20,
                y: this.height*10/20,
                radiusX: this.width*3/20,
                radiusY: this.height*6/20,
                strokeWidth: this.height/40,
                stroke: this.type3d==0? gradientoval: 'white'
              });
              if(indicator){
                  //oval2.fill(truecolor)

                  oval2.fillRadialGradientStartPoint({x:0, y:0});
                  oval2.fillRadialGradientEndRadius(this.height/2)
                  oval2.fillRadialGradientColorStops([0, 'white', 1, truecolor]);
                  oval2.fillPriority('radial-graident')
                
              }else{
                if(this.type3d==0){
                oval2.fillLinearGradientStartPoint({ x: 0, y: -this.height*6/20 });
                oval2.fillLinearGradientEndPoint({ x: 0, y: this.height*6/20 });
                oval2.fillLinearGradientColorStops([0, ColorUtils.darkColor(truecolor,0.75), 
                  1, ColorUtils.darkColor(truecolor,0.2)])
              }else{
                oval2.fill(ColorUtils.darkColor(truecolor,0.2))
              }
            }
            this.node.add(oval2)        

    }
   
}
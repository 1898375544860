import { SVGObject } from "../svgobject";

export class SVGMedical10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 28.828 112.5" xml:space="preserve" enable-background="new 0 0 28.828 112.5">
        <g id="Group_LeftLeg">
            <path d="M22.747,81.869l1.185-9.626c-3.045,0.119-6.074,0.215-9.124,0.101   l0.056,0.854l1.127,11.148l0.225,8.671l1.577,12.388l-1.127,4.729l0.563,1.127l2.928,1.238h3.491l1.126-1.238l-2.928-5.181V86.486   L22.747,81.869z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightLeg">
            <path d="M4.842,83.896L4.28,88.514v17.567l-1.126,2.815l-1.802,1.802   l0.675,1.126h4.617l2.929-2.252l-0.563-3.491l-0.563-3.04l2.929-19.82l1.25-11.001c-2.717-0.204-5.456-0.616-8.245-1.381   L4.842,83.896z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_LeftArm">
            <path d="M28.154,36.486l-0.338-7.883l-2.253-7.207l-1.915-1.303   c-0.359,4.315-0.021,8.79,0.05,13.151l0.063,0.652l-0.051,0.158c0.001,0.119,0,0.237,0,0.356l0.276,3.314l0.338,7.545l1.352,10.248   l-0.563,1.802l-0.563,3.716v4.843l3.041-4.167l0.563-3.04v-2.816l0.45-11.036L28.154,36.486z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightArm">
            <path d="M4.28,62.275l-0.563-3.041v-1.802l1.689-15.315l0.9-6.306   l0.445-4.964c0.155-3.74,0.543-7.527,0.312-11.189l-2.445,0.951l-2.365,4.279L1.126,30.18v7.433L0.45,43.468L0.113,57.32   l0.45,4.054l1.239,3.378l2.538,1.767l0.031-0.767L4.28,62.275z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_HairBack">
            <path d="M20.833,9.347l-0.9,8.784l-0.901-0.676l-0.226,0.676h-0.9   l-1.464-1.239l-0.226-1.239l2.252-4.842L20.833,9.347z" fill="#CCCC33" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M7.433,9.347l1.238,9.459l0.112-0.675l0.226,0.675h1.014   l-0.113-1.238l0.676,1.238l2.365-5.743L7.433,9.347z" fill="#CCCC33" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Neck">
            <path d="M18.516,18.1l-1.286-0.42l-1.238-2.365h-4.055l-1.238,2.928   l-0.361,0.14C9.789,21.219,16.477,23.5,18.516,18.1z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Dress">
            <path d="M0.113,51.126L0,43.243l0.788-11.937l1.014-5.292l1.576-4.617   l1.577-1.239l5.518-1.802l1.914,1.126h4.167l2.252-1.464l4.843,1.577l2.365,2.14l2.14,7.32l0.225,9.797l0.45,4.729l-0.225,4.392   l-4.055,0.901l0.338,7.658l0.226,3.603l-0.338,10.361l-1.126,15.766l-4.729,1.014l-5.181-0.564L7.77,87.275l-3.828-0.113   L3.716,74.1L4.28,60.248l-0.226-1.352l0.676-6.87L0.113,51.126z" fill="#FFE5F2" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M4.73,47.86l1.914-14.415l4.055,1.239l4.166-1.351l7.32,0.901l1.238,1.239l1.239,10.923   l-0.45-14.302l-0.901-4.617l-1.352,5.293l-4.166,0.225l-3.041-0.901l-4.167,1.013l-4.279-1.126L6.194,27.59l-0.788,5.743   L4.73,47.86z" fill="#F2D8E5" />
            <path d="M12.275,86.711l0.901-15.428h1.013l1.014,15.428L13.514,86.6L12.275,86.711z" fill="#F2D8E5" />
        </g>
        <g id="Group_Face">
            <path d="M18.919,11.374H9.347L8.108,10.36L7.433,9.234V7.996L8.108,6.87   h12.05l0.676,1.126v1.239l-0.676,1.126L18.919,11.374z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="14.076" cy="8.671" rx="5.743" ry="7.545" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M14.076,11.261l1.577,0.451h-3.267L14.076,11.261z" fill="#E5B27F" />
            <path d="M12.387,13.401l-0.45-0.563h4.167l-0.45,0.563H12.387z" fill="#E5B2B2" />
            <path d="M11.937,12.838h4.167" fill="none" stroke="#CC9999" stroke-width="0.25" />
            <path d="M17.568,6.757l-2.815-0.451l0.226,0.451l2.815,0.225L17.568,6.757z" fill="#665940" />
            <path d="M10.473,6.757l2.928-0.451l-0.338,0.451l-2.815,0.225L10.473,6.757z" fill="#665940" />
            <path d="M11.712,9.122h0.45l0.225-0.676l-0.225-0.675h-0.45l-0.226,0.675L11.712,9.122z" fill="#665940" />
            <path d="M15.878,9.122h0.563l0.112-0.676l-0.112-0.675h-0.563l-0.225,0.675L15.878,9.122z" fill="#665940" />
            <path d="M18.919,11.374H9.347L8.108,10.36L7.433,9.234V7.996L8.108,6.87   h12.05l0.676,1.126v1.239l-0.676,1.126L18.919,11.374z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="14.076" cy="8.671" rx="5.743" ry="7.545" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M14.076,11.261l1.577,0.451h-3.267L14.076,11.261z" fill="#E5B27F" />
            <path d="M12.387,13.401l-0.45-0.563h4.167l-0.45,0.563H12.387z" fill="#E5B2B2" />
            <path d="M11.937,12.838h4.167" fill="none" stroke="#CC9999" stroke-width="0.25" />
            <path d="M17.568,6.757l-2.815-0.451l0.226,0.451l2.815,0.225L17.568,6.757z" fill="#665940" />
            <path d="M10.473,6.757l2.928-0.451l-0.338,0.451l-2.815,0.225L10.473,6.757z" fill="#665940" />
            <path d="M11.712,9.122h0.45l0.225-0.676l-0.225-0.675h-0.45l-0.226,0.675L11.712,9.122z" fill="#665940" />
            <path d="M15.878,9.122h0.563l0.112-0.676l-0.112-0.675h-0.563l-0.225,0.675L15.878,9.122z" fill="#665940" />
        </g>
        <g id="Group_HairTop">
            <path d="M8.559,8.221L8.108,6.757L7.433,8.221l0.675-5.18l1.689-1.915   l1.802-1.013L14.076,0l2.252,0.113l1.915,1.013l1.689,1.915l0.9,5.18l-0.676-1.464l-0.45,1.464l-1.014-3.829l-1.126-1.915   l0.226,2.365h-7.32l0.226-2.365L9.347,4.392L8.559,8.221z" fill="#E5E54C" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { StringUtils } from "../utils/stringutils";
import { ButtonControlProperty } from "./properties/buttoncontrolproperty";
import Konva from "konva";
import { Value } from "../value";

export class Button extends ObjectView{
    text:string;
    textcolor:string;
    fillcolor:string;
    type3d:number;
    fonttype:number;
    fontsize:number;
    textplacement:number;
    animation:boolean;
    underline:boolean;

    public drawObject():void{
        super.initObject();
        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        const rectstroke = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10
          });
          if (this.type3d==0){
            rectstroke.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectstroke.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectstroke.fillLinearGradientColorStops([0, fillcolor2, 0.5, ColorUtils.darkColor(fillcolor2,0.5)])
          }
          else
            rectstroke.fill(ColorUtils.darkColor(fillcolor2,0.5))
          this.node.add(rectstroke)

          const rect = new Konva.Rect({
            x: this.height/20,
            y: this.height/20,
            width: this.width-this.height/10,
            height: this.height-this.height/10,
            cornerRadius:this.height/10,
          
          });
          if (this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rect.fillLinearGradientColorStops([0, 'white', 0.5, ColorUtils.darkColor(fillcolor2,0.85),
                      1,ColorUtils.darkColor(fillcolor2,0.5)]);
          }
          else
                rect.fill(fillcolor2)
          this.node.add(rect);

          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
          const textcolorprop = this.properties['textcolor'];
          if (textcolorprop!=null){	
              const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
              if (textcolorproperty!=null) 
                textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
          }
            
            let fontsize = this.fontsize;
            if (fontsize == 0) fontsize = this.height/2;
            const Text = new Konva.Text({
                x: this.height/20,
                y: this.height/20,
                verticalAlign:"middle",
                width: this.width-this.height/10,
                height: this.height-this.height/10,
                text: text,
                fontSize: fontsize,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                align:StringUtils.getTextPlacement(this.textplacement),
                fill: ColorUtils.convertformat(textcolor),
                shadowColor:'black',
                shadowBlur:0,
                shadowOffset:{ x: 1, y: 1 },
                textDecoration:this.underline?"underline":""
            });

            this.node.add(Text)
            this.setFunctions();      
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "textcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "text": {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "fonttype": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "fontsize": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "textplacement": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "animation": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
          case "underline": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      //this.drawObject();
  }

  public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "textcolor": {value.datatype=7; value.value = this[field];break}
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "text": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
          case "fonttype": {value.datatype=3; value.value = this[field];break}
          case "fontsize": {value.datatype=3; value.value = this[field];break}
          case "textplacement": {value.datatype=3; value.value = this[field];break}
          case "animation": {value.datatype=0; value.value = (this[field]);break}
          case "underline": {value.datatype=0; value.value = (this[field]);break}     
      }
      return value;
  }

  protected setFunctions():void{
      const controlprop = this.properties['buttoncontrol'];
      if (controlprop!=null){
        const controlproperty:ButtonControlProperty=Object.assign(new ButtonControlProperty(), controlprop);
        if (controlproperty!=null){
            this.node.on('mousedown touchstart', function () {
                   controlproperty.setClick();
              });
            this.node.on('mouseup touchend', function () {
               controlproperty.setReleased();
          });
        }
      }
  }
}
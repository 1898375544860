import { Property } from "./property";
import tag from "@/model/tag";


export class VisibilityProperty extends Property{
    value:string;
    type:number;

    public CheckVisibility(tagnames:string[]):boolean{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return true;
        let visible = false;
      console.log("CHECK VIS",+this.value, +tag.tagvalue)
        switch (this.type)
          {
              case 0: if (+this.value==+tag.tagvalue)
                              visible = true;
                      break;
              case 1: if (+tag.tagvalue>=+this.value)
                visible = true;
                      break;
              case 2:if (+tag.tagvalue<=+this.value)
                 visible = true;
                      break;
              case 3: if (+tag.tagvalue>+this.value)
                visible = true;
                      break;
              case 4: if (+tag.tagvalue<+this.value)
                visible = true;
                      break;
              case 5: if (+tag.tagvalue!=+this.value)
               visible = true;
                      break;
              
              default: break;
          }
          return visible;
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SliderControlProperty } from "./properties/slidercontrolproperty";
import Konva from "konva";
import { SliderVertical } from './slidervertical';


export class SliderHorizontal extends SliderVertical{

    public drawObject():void{
      super.initObject();

      if(this.type3d != 0) this.type3d = 1

      this.node1  = new Konva.Group({
            x: 0,
            y: 0,
      });
      this.drawObjectHorizontal(true);
    }

    private drawObjectHorizontal(usefunctions:boolean){
        this.node1.removeChildren();
		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
        const color2 = ColorUtils.convertformat(color)

		let fillcolor = '0xffffff00';
        
                let widthfill = this.width
                let value = 0
                let minimum = 0
                let maximum = 240
                let decimalpos = 0
                
                const sliderprop = this.properties['slidercontrol']
                let sliderproperty;
                if (sliderprop != null){
                    sliderproperty =Object.assign(new SliderControlProperty(), sliderprop);
                    value = sliderproperty.getCurrentValue(this.tagnames)
                    widthfill = sliderproperty.getCurrentFillingFromValue(this.tagnames, this.width*8/10)
                    decimalpos = sliderproperty.decimalpos
                    minimum = sliderproperty.minimumvalue
                    maximum = sliderproperty.maximumvalue
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient1 = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient1.addColorStop(0, ColorUtils.darkColor(color2,0.9))
              gradient1.addColorStop(1, ColorUtils.darkColor(color2,0.5))

              const gradient2 = ctx.createLinearGradient(0, this.height/40, 0, this.height/40 + this.height*12/20);
              gradient2.addColorStop(0, ColorUtils.darkColor(color2,0.3))
              gradient2.addColorStop(1, color2)

              const gradient3 = ctx.createLinearGradient(0, 0, 0, this.height*4/20);
              gradient3.addColorStop(0, 'black')
              gradient3.addColorStop(1, 'white')

            
            const rectout = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                cornerRadius:this.height/20,
                stroke: this.type3d==0? gradient1 : ColorUtils.darkColor(color2, 0.5),
                strokeWidth: this.height/100,
                fill: ColorUtils.darkColor(color2, 0.75)
              });
              this.node1.add(rectout)

              const rectinner = new Konva.Rect({
                x: this.width/40,
                y: this.height/40,
                width: this.width*38/40,
                height: this.height*12/20,
                cornerRadius:this.height/40,
                stroke: this.type3d==0? gradient2 : ColorUtils.darkColor(color2, 0.5),
                strokeWidth: this.height/100,
              });
              if(this.type3d==0){
                rectinner.fillLinearGradientStartPoint({ x: 0, y: 0 });
                rectinner.fillLinearGradientEndPoint({ x: this.width*38/40, y: 0});
                rectinner.fillLinearGradientColorStops([0, color2,
                    0.5, 'white',
                    1, color2])
              }else{
                rectinner.fill('white')
              }
              this.node1.add(rectinner)

              for(let i=0; i<=this.interval; i++){
                const delta = i/this.interval
               
                const line1 = new Konva.Line({
                    points: [this.width/10+this.width*8/10*delta, 
                            this.height*7/20,
                            this.width/10+this.width*8/10*delta,
                            this.height*5/20
                            ],
                    strokeWidth: this.width/150,
                    stroke: ColorUtils.darkColor(color2, 0.1)
                  });
                  this.node1.add(line1); 
                  for(let j=1; j<5; j++){
                    if (i==this.interval) break
                    const delta2 = this.width*16/20/this.interval*j/5
                    const line2 = new Konva.Line({
                        points: [(this.width/10+this.width*8/10*delta)+delta2, 
                                this.height*7/20,
                                (this.width/10+this.width*8/10*delta)+delta2,
                                this.height*6/20
                                ],
                        strokeWidth: this.width/200,
                        stroke: ColorUtils.darkColor(color2, 0.1)
                      });
                      this.node1.add(line2); 
                  }
                const valuestep = (maximum-minimum)/this.interval;
                const valuepos = minimum+valuestep*i;
                const valuetext = valuepos.toFixed(decimalpos)
                   const Text = new Konva.Text({
                    
                    x: (this.width/10+this.width*8/10*delta)-((this.width*8/10)/this.interval/2),
                    y: this.height*4/20-this.height/8,
                    //verticalAlign:"top",
                    width: (this.width*8/10)/this.interval,
                    height: this.height/8,
                    text: valuetext,
                    fontSize: this.height/8,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fill: ColorUtils.darkColor(color2, 0.1),
                    align: 'center',
                    
                  });  
                  this.node1.add(Text)
              } 
              const recthorizontal = new Konva.Rect({
                x: this.width/10,
                y: this.height*9/20,
                width: this.width*8/10,
                height: this.height*2/40,
                cornerRadius: this.height/20,
                fill: ColorUtils.darkColor(color2, 0.75)
              });
              this.node1.add(recthorizontal)

              
              const rectfill = new Konva.Rect({
                x: this.width/10,
                y: this.height*9/20,
                width: widthfill,
                height: this.height*2/40,
                cornerRadius: this.height/20,
                fill: ColorUtils.darkColor(fillcolor2, 0.75)
              });
              this.node1.add(rectfill)
             
            const slider = this.drawArrow(widthfill, color2, false);
            this.node1.add(slider)


            const TextLabel = new Konva.Text({
                x: this.width/4,
                y: this.height*25/40+this.height/200,
                //verticalAlign:"bottom",
                width: this.width/2,
                height: this.height/8,
                text: this.text,
                fontSize: this.height/8,
                fontFamily: "Roboto",
                fontStyle: "normal",
                fill: 'black',
                align: 'center'
              });  
              this.node1.add(TextLabel)	

              const TextUnit = new Konva.Text({
                x: this.width/30,
                y: this.height*12/20-this.height/8,
                //verticalAlign:"top",
                width: this.width/30,
                height: this.height*12/20,
                text: this.unit,
                fontSize: this.height/8,
                fontFamily: "Roboto",
                fontStyle: "normal",
                fill: 'black',
              });  
              this.node1.add(TextUnit)
              
              
              if(this.usedigital){
                const rectdigit = new Konva.Rect({
                    x: this.width/4,
                    y: this.height*30/40,
                    width: this.width/2,
                    height: this.height*4/20,
                    cornerRadius:this.height/50,
                    stroke: this.type3d==0? gradient3 : ColorUtils.darkColor(color2, 0.5),
                    strokeWidth: this.height/100,
                  });
                  if(this.type3d==0){
                    rectdigit.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rectdigit.fillLinearGradientEndPoint({ x: 0, y: this.height*4/20});
                    rectdigit.fillLinearGradientColorStops([0, 'white',
                        0.25, 'black',
                      1, 'black'])
                  }else{
                    rectdigit.fill(ColorUtils.darkColor(color2, 0.1))
                  }
                  this.node1.add(rectdigit)

                  const textValue = new Konva.Text({
                    x: this.width/4,
                    y: this.height*30/40,
                    verticalAlign:"middle",
                    width: this.width/2,
                    height: this.height*4/20,
                    text: value.toFixed(decimalpos),
                    fontSize: this.height/7,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fill: 'white',
                    align: 'center'
              });  
              this.node1.add(textValue)
              }
  
            this.node.add(this.node1)
        
       if (usefunctions)
            this.setFunctions(widthfill, color2, sliderproperty);


    }
        
    private drawArrow(widthfill:number, color2:string, draggable:boolean):Konva.Line{  
      let arrowwidth = this.width/25;
      let arrowheight = this.height/4;
      if (arrowwidth<1) arrowwidth = 1;
      if (arrowheight<1) arrowheight = 1;
      const xbegin =  this.width/10+widthfill-arrowwidth/2
      const ybegin = this.height*21/40+arrowheight/3
      const slider = new Konva.Line({
          points: [0, 0,
                   0, -arrowheight*2/3,
                   arrowwidth/2, -arrowheight,
                   arrowwidth, -arrowheight*2/3,
                   arrowwidth, 0,
                   0, 0
                 ],
          x: xbegin,
          y: ybegin,
          closed: true,
          draggable:draggable
      });
      if(this.type3d==0){
        slider.fillLinearGradientStartPoint({ x: 0, y: 0});
        slider.fillLinearGradientEndPoint({ x: arrowwidth, y: 0 });
        slider.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.3),
            0.5, ColorUtils.darkColor(color2, 0.75),
            1, ColorUtils.darkColor(color2, 0.3)])
      }else{
        slider.fill(ColorUtils.darkColor(color2, 0.5))
      }
                 
      return slider
    }

    protected setFunctions(widthfill:number,color2:string, controlproperty:SliderControlProperty):void{
      if(controlproperty != null){
        let arrowwidth = this.width/25;
        let arrowheight = this.height/4;
        if (arrowwidth<1) arrowwidth = 1;
        if (arrowheight<1) arrowheight = 1;

        const slider = this.drawArrow(widthfill, color2, true)
        this.node.add(slider)

        controlproperty.getTag
        const originalY = slider.y();
        slider.on('dragmove', () => {
          slider.y(originalY);
          if (slider.x()<this.width/10-arrowwidth/2) slider.x(this.width/10-arrowwidth/2)
          if (slider.x()>9*this.width/10-arrowwidth/2) slider.x(9*this.width/10-arrowwidth/2)
             
          controlproperty.setCurrentHorFillingFromValue(this.tagnames, slider.x(),this.width)
          this.drawObjectHorizontal(false);               
        });

        slider.on('dragend',()=>{     
          controlproperty.sendTagValue(this.tagnames, this.interval)        
        });

        this.node.on('click touchend', ()=> {
          const pos = this.node.getRelativePointerPosition()
          controlproperty.setCurrentHorFillingFromValue(this.tagnames, pos.x,this.width)
          controlproperty.sendTagValue(this.tagnames, this.interval)
        })
      }
      
    }
    
}
import { SVGObject } from "../svgobject";

export class SVGPipe19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 97.66" enable-background="new 0 0 112.5 97.66" xml:space="preserve">

        <g id="Group_45_Angle_Pipe">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="65.8096" y1="90.4124" x2="21.3516" y2="13.4088">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.49" style="stop-color:#CCCCCC" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M0.281,25.573l35.396-0.001l26.03-25.329l50.515,50.374c0,0-28.941,29.058-34.414,32.706  S55.913,96.223,43.229,96.819c-12.683,0.597-42.938,0.298-42.938,0.298L0.281,25.573z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="33.6021" y1="33.0784" x2="102.7012" y2="33.0784" gradientTransform="matrix(0.7336 0.7438 -0.7025 0.6929 45.8602 -33.9988)">
            <stop offset="0" style="stop-color:#646566" />
            <stop offset="0.52" style="stop-color:#E0E0E0" />
            <stop offset="0.84" style="stop-color:#929293" />
            <stop offset="0.99" style="stop-color:#656667" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M86.004,75.705c-0.127,0-0.246,0.011-0.368,0.018L32.563,28.424L61.735,0l50.769,50.627  L87.274,75.854C86.882,75.76,86.461,75.705,86.004,75.705z" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.7124" y1="14.3733" x2="74.7549" y2="14.3733" gradientTransform="matrix(9.000000e-004 -1.027 1.0367 9.000000e-004 3.2298 102.2017)">
            <stop offset="0.01" style="stop-color:#656667" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M36.333,97.36H0.01L0,25.457l36.298,0L36.333,97.36z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="50.4932" y1="87.7063" x2="58.0112" y2="16.0542" gradientTransform="matrix(0.754 -0.6569 0.6569 0.754 -17.1565 50.3665)">
            <stop offset="0.01" style="stop-color:#636465" />
            <stop offset="0.17" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M35.574,25.456l32.737,63.837c0,0,6.192-2.676,10.318-6.162c1.656-1.4,1.656-1.4,8.893-7.516  L35.574,25.456z" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="35.3315" y1="89.6731" x2="48.4564" y2="18.9733" gradientTransform="matrix(0.864 -0.5034 0.5034 0.864 -15.9757 33.5029)">
            <stop offset="0.01" style="stop-color:#636465" />
            <stop offset="0.17" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_5_)" d="M35.574,25.456c0,0,12.609,70.718,12.963,70.64c10.529-2.354,10.271-2.621,12.279-3.354  c1.71-0.625,1.71-0.625,9.389-4.264L35.574,25.456z" />
        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="24.9766" y1="84.469" x2="38.1186" y2="13.6766" gradientTransform="matrix(0.9591 -0.2833 0.2833 0.9591 -5.3515 23.1886)">
            <stop offset="0.01" style="stop-color:#636465" />
            <stop offset="0.17" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_6_)" d="M35.574,25.456L32.16,97.36c0,0,9.074-0.076,11.004-0.299c2.42-0.279,9.567-1.852,9.567-1.852  L35.574,25.456z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.49, 0.40625, 151.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7864583333333334, 0.0, 0.52, 0.25, 191.0, 0.84, 0.859375, 35.0, 0.99, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.7916666666666666, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.7760416666666666, 0.0, 0.17, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7760416666666666, 0.0, 0.17, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.7760416666666666, 0.0, 0.17, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 46.735 112.5" enable-background="new 0 0 46.735 112.5" xml:space="preserve">
        <g id="Group_Bottle">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.882813e-004" y1="59.7412" x2="46.7349" y2="59.7412">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M4.505,112.5h37.5l4.73-4.842V56.194l-2.252-4.73l2.252-4.729l-2.252-28.153l-2.479-2.252   l-11.711-4.73V9.347h2.252V6.982H13.852v2.365h2.477v2.252l-11.824,4.73l-2.252,2.252L0,46.734l2.252,4.729L0,56.194v51.464   L4.505,112.5z" />
            <path fill="none" d="M4.505,112.5h37.5l4.73-4.842V56.194l-2.252-4.73l2.252-4.729l-2.252-28.153l-2.479-2.252l-11.711-4.73V9.347   h2.252V6.982H13.852v2.365h2.477v2.252l-11.824,4.73l-2.252,2.252L0,46.734l2.252,4.729L0,56.194v51.464L4.505,112.5" />
            <path fill="none" d="M6.87,51.464h32.883" />
            <path fill="none" d="M16.329,9.347h13.964" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="16.3291" y1="5.8555" x2="30.2935" y2="5.8555">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M16.329,6.982V4.729h13.964v2.252H16.329z" />
            <path fill="none" d="M16.329,6.982V0h13.964v6.982H16.329" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="49.1689" x2="46.7349" y2="49.1689">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.12" style="stop-color:#626262" />
                <stop offset="0.3" style="stop-color:#9B9B9B" />
                <stop offset="0.44" style="stop-color:#BEBEBE" />
                <stop offset="0.51" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#A7A7A7" />
                <stop offset="0.95" style="stop-color:#4A4A4A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="0,46.657 46.735,46.734 44.474,51.681 2.193,51.681  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="9.765625e-004" y1="109.9883" x2="46.7349" y2="109.9883">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" points="0.001,107.477 46.735,107.554 42.475,112.5 4.194,112.5  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0" y1="66.3086" x2="46.7349" y2="66.3086" gradientTransform="matrix(1 0 0 -1 0 120.3379)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.09" style="stop-color:#555555" />
                <stop offset="0.29" style="stop-color:#8E8E8E" />
                <stop offset="0.43" style="stop-color:#B2B2B2" />
                <stop offset="0.51" style="stop-color:#BFBFBF" />
                <stop offset="0.66" style="stop-color:#9A9A9A" />
                <stop offset="0.98" style="stop-color:#3D3D3D" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="0,56.541 46.735,56.463 44.474,51.517 2.193,51.517  " />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="16.3242" y1="10.4717" x2="30.2993" y2="10.4717">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.12" style="stop-color:#626262" />
                <stop offset="0.3" style="stop-color:#9B9B9B" />
                <stop offset="0.44" style="stop-color:#BEBEBE" />
                <stop offset="0.51" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#A7A7A7" />
                <stop offset="0.95" style="stop-color:#4A4A4A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="16.324" y="9.361" fill="url(#SVGID_6_)" width="13.975" height="2.221" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="2.2041" y1="102.7773" x2="44.394" y2="102.7773" gradientTransform="matrix(1 0 0 -1 0 120.3379)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.12" style="stop-color:#626262" />
                <stop offset="0.3" style="stop-color:#9B9B9B" />
                <stop offset="0.44" style="stop-color:#BEBEBE" />
                <stop offset="0.51" style="stop-color:#CCCCCC" />
                <stop offset="0.65" style="stop-color:#A7A7A7" />
                <stop offset="0.95" style="stop-color:#4A4A4A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="2.204,18.596 44.394,18.656 42.133,16.603 4.397,16.465  " />
        </g>
        <g id="Group_Cap">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="16.3291" y1="3.4912" x2="30.2935" y2="3.4912">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M16.329,6.982V0h13.964v6.982H16.329z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMaterial27 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 107.885" enable-background="new 0 0 112.5 107.885" xml:space="preserve">
        <g id="Group_Base_Platform">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,105.519l2.478-7.094h77.14l2.478,7.094H0z" />
            <path fill="#BFBFBF" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,105.519h77.14v2.365H2.478V105.519z" />
        </g>
        <g id="Group_Outlet">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="93.0742" y1="82.0957" x2="93.0742" y2="72.8613">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M73.648,82.096v-9.234H112.5v9.234H73.648z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="110.1348" y1="84.5732" x2="110.1348" y2="70.3838">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M109.009,84.573V70.384h2.253v14.189H109.009z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="98.5361" y1="84.4043" x2="107.8828" y2="84.4043">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M107.883,91.442h-9.347v-9.347l4.729-4.729l4.617,4.729V91.442z" />
        </g>
        <g id="Group_Inlet">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="30.293" y1="1.1836" x2="53.9414" y2="1.1836">
                <stop offset="0" style="stop-color:#808080" />
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M30.293,0.001h23.648v2.365H30.293V0.001" />
        </g>
        <g id="Group_Weigh">
            <circle fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" cx="16.329" cy="76.915" r="6.531" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M22.522,44.033h8.446v2.252h-8.446V44.033z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M9.797,32.771h13.176v43.468H9.797V32.771z" />
            <path fill="#A6A6A6" stroke="#4C4C4C" stroke-width="0.25" d="M5.856,89.19h8.671v9.234H5.856V89.19z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M5.856,89.19h8.671v-9.348H5.856V89.19z" />
            <path fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" d="M5.856,39.303h9.347v9.459H5.856V39.303z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M22.973,65.204h7.545v8.783h-7.545V65.204z" />
            <path fill="#7F7F7F" d="M12.725,62.726h5.856v10.586h-5.856V62.726z" />
        </g>
        <g id="Group_Tuner">
            <radialGradient id="SVGID_5_" cx="25.2256" cy="68.2441" r="4.3916" gradientUnits="userSpaceOnUse">
                <stop offset="0.5" style="stop-color:#F7F7F7" />
                <stop offset="1" style="stop-color:#999999" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M25.113,72.636l-3.041-1.238l-1.239-3.153l1.239-3.153   l3.041-1.238l3.153,1.238l1.352,3.153l-1.352,3.153L25.113,72.636z" />	 
                <path fill="#8C8C8C" stroke="#4C4C4C" stroke-width="0.25" d="M16.329,79.843h18.694v18.582H16.329V79.843z" />
                <path fill="#8C8C8C" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,67.005h1.127v20.834h-1.127V67.005z" />
                <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,61.149v32.658h37.612V61.149H32.771z" />
                <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M70.383,61.149h2.252v37.275h-2.252V61.149z" />
                <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M30.518,61.149h2.252v37.275h-2.252V61.149z" />	 
        </g>
        <g id="Group_Feeder">
            <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M14.189,4.73l18.582,56.419h37.612V4.73H14.189z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M30.518,58.672h42.117v2.478H30.518V58.672z" />
        </g>
        <g id="Group_Lid">
            <path fill="#7F7F7F" d="M15.766,1.353h0.563v4.729h-0.563V1.353z" />
            <path fill="#7F7F7F" d="M20.383,1.353h0.789v4.729h-0.789V1.353z" />
            <path fill="#7F7F7F" d="M25.113,1.353h0.675v4.729h-0.675V1.353z" />
            <path fill="#7F7F7F" d="M29.842,1.353h0.676v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M34.572,1.353h0.45v4.729h-0.45V1.353z" />
            <path fill="#7F7F7F" d="M39.302,1.353h0.451v4.729h-0.451V1.353z" />
            <path fill="#7F7F7F" d="M43.806,1.353h0.676v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M48.536,1.353h0.676v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M53.266,1.353h0.676v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M57.996,1.353h0.676v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M62.725,1.353H63.4v4.729h-0.676V1.353z" />
            <path fill="#7F7F7F" d="M67.455,1.353h0.45v4.729h-0.45V1.353z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M14.189,2.366h56.194V4.73H14.189V2.366z" />
        </g> 
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.07291666666666667, 236.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 1.0, 0.0, 0.5, 0.07291666666666667, 236.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.5, 0.07291666666666667, 236.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Base_Platform",[0.0, 0.20833333333333334, 201.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Weigh",[0.0, 0.11458333333333333, 226.0, 1.0, 0.40625, 151.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.703125, 75.0, 4.0, 0.11458333333333333, 226.0, 5.0, 0.11458333333333333, 226.0, 6.0, 0.40625, 151.0, 7.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Tuner",[0.0, 0.90625, 23.0, 1.0, 0.90625, 23.0, 2.0, 0.609375, 99.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Feeder",[0.0, 0.3020833333333333, 177.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Lid",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.9895833333333334, 0.0, 3.0, 0.9895833333333334, 0.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.9895833333333334, 0.0, 6.0, 0.9895833333333334, 0.0, 7.0, 0.9895833333333334, 0.0, 8.0, 0.9895833333333334, 0.0, 9.0, 0.9895833333333334, 0.0, 10.0, 0.9895833333333334, 0.0, 11.0, 0.9895833333333334, 0.0, 12.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
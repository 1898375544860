import { SVGObject } from "../svgobject";

export class SVGTank72 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Hatch_sub">
            <radialGradient id="SVGID_1_" cx="56.1934" cy="56.1934" r="56.1934" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#EBEBEB" />
                <stop offset="0.68" style="stop-color:#C0C0C0" />
                <stop offset="0.7" style="stop-color:#808080" />
                <stop offset="0.76" style="stop-color:#D4D4D4" />
                <stop offset="0.94" style="stop-color:#D4D4D4" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" cx="56.194" cy="56.193" r="56.193" />
        </g>
        <g id="Group_Bolt">
            <radialGradient id="SVGID_2_" cx="54.707" cy="7.9561" r="7.0049" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" cx="54.707" cy="7.956" r="7.005" />
            <radialGradient id="SVGID_3_" cx="79.2305" cy="14.0771" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_3_)" cx="79.23" cy="14.077" r="7.005" />
            <radialGradient id="SVGID_4_" cx="97.4668" cy="31.2207" r="7.0044" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_4_)" cx="97.466" cy="31.221" r="7.005" />
            <radialGradient id="SVGID_5_" cx="104.3926" cy="55.165" r="7.0049" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_5_)" cx="104.393" cy="55.165" r="7.005" />
            <radialGradient id="SVGID_6_" cx="98.3105" cy="79.1084" r="7.0049" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_6_)" cx="98.311" cy="79.108" r="7.005" />
            <radialGradient id="SVGID_7_" cx="81.2344" cy="97.4658" r="7.0044" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_7_)" cx="81.233" cy="97.466" r="7.005" />
            <radialGradient id="SVGID_8_" cx="57.9043" cy="104.166" r="7.0049" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_8_)" cx="57.904" cy="104.166" r="7.005" />
            <radialGradient id="SVGID_9_" cx="33.1851" cy="98.3105" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_9_)" cx="33.185" cy="98.311" r="7.005" />
            <radialGradient id="SVGID_10_" cx="14.9214" cy="81.1123" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_10_)" cx="14.921" cy="81.112" r="7.005" />
            <radialGradient id="SVGID_11_" cx="8.2207" cy="57.2471" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_11_)" cx="8.22" cy="57.247" r="7.005" />
            <radialGradient id="SVGID_12_" cx="14.0767" cy="33.1021" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_12_)" cx="14.076" cy="33.102" r="7.005" />
            <radialGradient id="SVGID_13_" cx="31.1167" cy="14.9609" r="7.0046" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.7" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_13_)" cx="31.116" cy="14.961" r="7.005" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.16666666666666666, 212.0, 0.68, 0.5, 127.0, 0.7, 1.0, 0.0, 0.76, 0.34375, 167.0, 0.94, 0.34375, 167.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.40625, 151.0, 0.7, 0.5052083333333334, 125.0, 1.0, 0.8958333333333334, 0.0]);

        super.drawObject();
    }
}
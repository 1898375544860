import { SVGObject } from "../svgobject";

export class SVGMotor6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 45.043" enable-background="new 0 0 112.5 45.043" xml:space="preserve">
        <g id="Group_Mounting_Plate">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.002" y1="9.2334" x2="112.498" y2="9.2334" gradientTransform="matrix(1 0 0 -1 0 45.043)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E8E8E8" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,45.043V40.65L30.97,26.576h80.739l0.789,4.504   L83.559,45.043H0.002z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0.002,40.65h83.555l28.151-14.074" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M83.559,40.65v4.393" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M11.713,32.544h31.868v0.675H11.713V32.544z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M59.234,33.518h32.543v0.789H59.234V33.518z" />
        </g>
        <g id="Group_Commutator_Shaft">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="33.0415" y1="5.5215" x2="33.0415" y2="32.4321">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="21.375" y="5.521" fill="url(#SVGID_2_)" width="23.333" height="26.91" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="289.1777" y1="431.4883" x2="289.1777" y2="458.6602" gradientTransform="matrix(-1 0 0 1 304.5879 -425.999)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M23.5,5.49v27.099l-10.168,0.072c-4.018,0-6.01-6.074-6.01-13.571c0-7.496,1.992-13.571,6.01-13.571   L23.5,5.49z" />
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="44.582" cy="19.153" rx="4.246" ry="13.767" />
        </g>
        <g id="Group_Shaft">
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="55.5352" y1="0.396" x2="49.2539" y2="0.396" gradientTransform="matrix(0 0.9801 0.5168 0 49.1809 -32.8331)">
                <stop offset="0.01" style="stop-color:#666666" />
                <stop offset="0.51" style="stop-color:#E8E8E8" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1129" d="M55.189,15.454c-0.458-0.026-10.305,0-10.305,0   s-1.303,0.577-1.303,3.042c0,2.465,1.303,3.103,1.303,3.103h10.305" />
        </g>
        <g id="Group_Label1">
            <path fill="#BFBABA" stroke="#4C4C4C" stroke-width="0.25" d="M13.966,15.427h11.148v11.937H13.966V15.427z" />
        </g>
        <g id="Group_Label2">
            <path fill="#B8B8B8" stroke="#4C4C4C" stroke-width="0.25" d="M17.682,0h22.972v5.18H17.682V0z" />
        </g>
        <g id="Group_Motor_Body">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="83.3984" y1="3.4189" x2="83.3984" y2="33.4019">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <rect x="68.458" y="3.419" fill="url(#SVGID_5_)" width="29.88" height="29.982" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="242.0938" y1="429.3828" x2="242.0938" y2="459.6563" gradientTransform="matrix(-1 0 0 1 304.5879 -425.999)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M70.583,3.384v30.193l-10.169,0.08c-4.018,0-6.009-6.768-6.009-15.121   c0-8.352,1.992-15.121,6.009-15.121L70.583,3.384z" />
            <ellipse fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" cx="98.135" cy="18.474" rx="5.682" ry="15.016" />
        </g>
        <g id="Group_Fins">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M56.869,7.319h32.544v0.789H56.869V7.319z" />
            <path fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" d="M56.869,8.108h30.404v0.788H56.869V8.108z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M55.405,13.963h28.828v0.788H55.405V13.963z" />
            <path fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" d="M55.405,14.751h26.013v0.789H55.405V14.751z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M55.405,24.436h30.292v0.676H55.405V24.436z" />
            <path fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" d="M55.405,25.111h26.013V25.9H55.405V25.111z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.1875, 207.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.7916666666666666, 0.0, 0.51, 0.1875, 207.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.7864583333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Label1",[0.0, 0.5364583333333334, 117.0]);
		this.fillGradient(this.fills,"Group_Label2",[0.0, 0.5625, 111.0]);
		this.fillGradient(this.fills,"Group_Fins",[0.0, 0.0, 255.0, 1.0, 0.0, 255.0, 2.0, 0.0, 255.0]);
		
        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import tagsModele from "@/store/tags.modele";
import { Checkbox } from './checkbox';
import { StringUtils } from '../utils/stringutils';


export class CheckList extends Checkbox{

    fonttype:number;
    fontsize:number;

    public drawObject():void{
		super.initObject();
        this.ranges.forEach(range => {
            this.tagnames.push(range.path)
        });
   

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
        const color2 = ColorUtils.convertformat(color)


		let fillcolor = '0xffffff00';
        
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, color2)
              gradient.addColorStop(1, ColorUtils.darkColor(color2,0.75))

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            strokeWidth: this.linewidth==0? this.height/50 : this.linewidth,
            stroke: this.type3d==0? gradient : color2
          });
          if(this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0});
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
            rect.fillLinearGradientColorStops([0, fillcolor2,
                0.5, ColorUtils.darkColor(fillcolor2, 0.95),
                1, ColorUtils.darkColor(fillcolor2, 0.85)])
          }else{
            rect.fill(fillcolor2)
          }
          this.node.add(rect)


          const line = new Konva.Line({
            points: [this.width*4/5, 0, this.width*4/5,this.height],
            stroke: this.type3d==0? gradient : color2,
        });
            if(this.linewidth != 0) line.strokeWidth(this.linewidth)
            if(this.type3d==0){
                line.fillLinearGradientStartPoint({ x: 0, y: 0});
                line.fillLinearGradientEndPoint({ x: 0, y: this.height});
                line.fillLinearGradientColorStops([0, fillcolor2,
                0.5, ColorUtils.darkColor(fillcolor2, 0.95),
                1, ColorUtils.darkColor(fillcolor2, 0.85)])
            }else{
                line.fill(fillcolor)
            }
        this.node.add(line)


        if(this.ranges.length != 0){
            this.ranges.forEach((textview)=>{
                const node1 = new Konva.Group()
                const rowheight = this.height/this.ranges.length
                const line1 = new Konva.Line({
                    points: [0, rowheight*(this.ranges.indexOf(textview)+1), this.width, rowheight*(this.ranges.indexOf(textview)+1)],
                    stroke: color2, //this.type3d==0? gradient : color2,
                    strokeWidth: this.linewidth==0? this.height/50 : this.linewidth
                })
                node1.add(line1)

                const textMenu = new Konva.Text({
                    x: this.linewidth==0? this.height/50 +this.width/100 : this.linewidth +this.width/100,
                    y: rowheight*this.ranges.indexOf(textview), 
                    height: rowheight,
                    width: this.width-2*this.linewidth,
                    fontSize: this.fontsize==0?rowheight*2/3:this.fontsize,
                    text: textview.text,
                    fontFamily: StringUtils.getFontFamily(this.fonttype),
                    fontStyle: StringUtils.getFontStyle(this.fonttype),
                    align: 'left',
                    verticalAlign:"middle",
                    fill: color2
                    })
                    if(this.type3d==0){       
                        textMenu.shadowColor('black'),
                        textMenu.shadowBlur(0),
                        textMenu.shadowOffset({ x: 0.5, y: 0.5 })
                      }
                    node1.add(textMenu)

                const tag =tagsModele.getTag(textview.path);    

                if(tag!=null && +tag.tagvalue==textview.value){
                    const arrow = new Konva.Line({
                        points: [this.width*17/20, rowheight*this.ranges.indexOf(textview)+rowheight*1/3,
                            this.width*18/20, rowheight*this.ranges.indexOf(textview)+rowheight*3/4,
                            this.width*19/20, rowheight*this.ranges.indexOf(textview)+rowheight*1/4],
                        stroke: color2
                    })
                    node1.add(arrow)
                }
                this.node.add(node1)
                
                node1.on('click touchend', function(){
                    if (+tag.tagvalue==textview.value){
                           tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.uncheckvalue.toString(), datetime:0});
                    }else{
                       tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:textview.value.toString(), datetime:0});
                    }
                   })  


            })
        }

    }
}
import { SVGObject } from "../svgobject";

export class SVGTank18 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 96.523 112.5" enable-background="new 0 0 96.523 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="7.875" y1="56.25" x2="88.4238" y2="56.25">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M7.875,112.499V0.001h80.549v112.498H7.875" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.0029" y1="11.5283" x2="96.5205" y2="11.5283">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M96.521,13.935c0,0,0.003-4.185-6.072-5.834H6.075c-5.688,0.711-6.072,5.834-6.072,5.834v1.021  h96.518V13.935z" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0029" y1="16.8682" x2="96.5205" y2="16.8682" gradientTransform="matrix(-1 0 0 -1 96.5234 118.3496)">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M0.003,98.564c0,0-0.003,4.186,6.072,5.834h84.374c5.688-0.711,6.071-5.834,6.071-5.834H0.003z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="0" y1="58.9688" x2="96.5234" y2="58.9688">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M0,13.54h96.523v84.983c0,0-0.887,5.876-6.075,5.876H6.075C0,103.119,0,98.522,0,98.522V13.54" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="96.5195" y1="8.0962" x2="0.002" y2="8.0962" gradientTransform="matrix(-1 0 0 -1 96.5234 109.0664)">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <path fill="url(#SVGID_5_)" d="M0.003,98.564c0,0-0.003,4.185,6.072,5.834h84.374c5.688-0.711,6.071-5.834,6.071-5.834v-1.021H0.003  V98.564z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGBoiler1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 72.41" enable-background="new 0 0 112.5 72.41" xml:space="preserve">
        <g id="Group_SmokeOutlet">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="5.8794" x2="16.5542" y2="5.8794">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.45" style="stop-color:#99CCCC" />
                <stop offset="0.55" style="stop-color:#86B9B9" />
                <stop offset="0.73" style="stop-color:#568989" />
                <stop offset="0.77" style="stop-color:#4C7F7F" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,9.934V1.825h16.554v8.108H0" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="5.4561" x2="16.5542" y2="5.4561">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.52" style="stop-color:#99CCCC" />
                <stop offset="0.61" style="stop-color:#86B9B9" />
                <stop offset="0.79" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,10.912V0h16.554v10.912" />
        </g>
        <g id="Group_BoilerBody">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="45.5522" y1="64.3027" x2="45.5522" y2="8.1089">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.52" style="stop-color:#99CCCC" />
                <stop offset="0.61" style="stop-color:#86B9B9" />
                <stop offset="0.79" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0,8.108h91.104v56.194H0V8.108" />
        </g>
        <g id="Group_Burner">
            <path fill="#336666" d="M99.212,60.248h11.937V72.41H99.212V60.248z" />
        </g>
        <g id="Group_BaseBody">
            <path fill="#99CCCC" d="M0,67.005h106.982v5.405H0V67.005z" />
            <path fill="#669999" d="M0,68.355h106.982v2.703H0V68.355z" />
            <path fill="#003333" d="M1.239,63.962h88.513v3.041H1.239V63.962z" />
            <path fill="#669999" d="M82.32,67.005l0.676-7.433h2.026l0.676,7.433H82.32z" />
            <path fill="#669999" d="M5.405,67.005l0.563-7.433h2.14l0.563,7.433H5.405z" />
        </g>
        <g id="Group_SafetyValves">
            <path fill="none" stroke="#336666" stroke-width="2" d="M75.57,8.099v55.357" />
            <path fill="#003333" d="M42.905,5.406h0.676v2.703h-0.676V5.406z" />
            <path fill="#003333" d="M52.815,5.406h0.676v2.703h-0.676V5.406z" />
        </g>
        <g id="Group_FuelBurner">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="101.8018" y1="61.5996" x2="101.8018" y2="47.6348">
                <stop offset="0" style="stop-color:#194C4C" />
                <stop offset="0.52" style="stop-color:#99CCCC" />
                <stop offset="0.61" style="stop-color:#86B9B9" />
                <stop offset="0.79" style="stop-color:#558888" />
                <stop offset="1" style="stop-color:#194C4C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M91.104,49.662h2.703v2.703h9.234v-2.703l1.238-2.027l1.464,2.027v2.703h6.757v4.729h-6.757v2.478   l-1.464,2.027l-1.238-2.027v-2.478h-9.234v2.478h-2.703V49.662z" />
            <path fill="none" d="M91.104,49.662h2.703v2.703h9.234v-2.703l1.238-2.027l1.464,2.027v2.703h6.757v4.729h-6.757v2.478   l-1.464,2.027l-1.238-2.027v-2.478h-9.234v2.478h-2.703V49.662" />
            <path fill="none" stroke="#4C4C4C" d="M94.148,52.536v4.729" />
        </g>
        <g id="Group_ControlPanel">
            <path fill="none" stroke="#336666" stroke-width="0.5" d="M68.563,41.555h4.298V30.518" />
            <path fill="none" stroke="#CCFFFF" stroke-width="0.5" d="M68.355,41.814V30.744h4.738" />
            <path fill="#99CCCC" d="M96.509,51.689h11.937v11.938H96.509V51.689z" />
            <path fill="#003333" d="M106.419,57.545h1.239v1.352h-1.239V57.545z" />
            <path fill="none" stroke="#003333" stroke-width="0.5" d="M68.355,24.212h4.505v-7.8" />
            <path fill="none" stroke="#99CCCC" stroke-width="0.5" d="M68.355,24.455v-8.239h4.754" />
            <path fill="#336666" d="M69.146,23.212h3.157v0.394h-3.157V23.212z" />
        </g>
        <g id="Group_FuelHeatingFilteringSystems">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="61.5986" y1="66.3291" x2="61.5986" y2="64.3027">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M21.284,64.303h80.63v2.026h-80.63V64.303z" />
            <path fill="none" d="M21.284,64.303h80.63v2.026h-80.63V64.303" />
            <path fill="#669999" d="M67.004,63.627h3.83v3.378h-3.83V63.627z" />
            <path fill="#669999" d="M58.221,63.627h4.055v3.378h-4.055V63.627z" />
            <path fill="#669999" d="M49.437,63.627h4.054v3.378h-4.054V63.627z" />
            <path fill="#669999" d="M40.878,63.627h3.829v3.378h-3.829V63.627z" />
            <path fill="#669999" d="M32.095,63.627h4.054v3.378h-4.054V63.627z" />
            <path fill="#CCFFFF" d="M67.004,64.978h3.83v0.676h-3.83V64.978z" />
            <path fill="#CCFFFF" d="M58.221,64.978h4.055v0.676h-4.055V64.978z" />
            <path fill="#CCFFFF" d="M49.437,64.978h4.054v0.676h-4.054V64.978z" />
            <path fill="#CCFFFF" d="M40.878,64.978h3.829v0.676h-3.829V64.978z" />
            <path fill="#CCFFFF" d="M32.095,64.978h4.054v0.676h-4.054V64.978z" />
            <path fill="none" d="M40.878,63.627h3.829v3.378h-3.829V63.627" />
            <path fill="none" d="M49.437,63.627h4.054v3.378h-4.054V63.627" />
            <path fill="none" d="M58.221,63.627h4.055v3.378h-4.055V63.627" />
        </g>
        <g id="Group_DoorHandles">
            <path fill="#336666" d="M91.104,25.73h2.027v13.513h-2.027V25.73z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4583333333333333, 0.0, 0.45, 0.5364583333333334, 117.0, 0.55, 0.6875, 79.0, 0.73, 0.9322916666666666, 0.0, 0.77, 0.8541666666666666, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4583333333333333, 0.0, 0.52, 0.5364583333333334, 117.0, 0.61, 0.6875, 79.0, 0.79, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4583333333333333, 0.0, 0.52, 0.5364583333333334, 117.0, 0.61, 0.6875, 79.0, 0.79, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4583333333333333, 0.0, 0.52, 0.5364583333333334, 117.0, 0.61, 0.6875, 79.0, 0.79, 0.9270833333333334, 0.0, 1.0, 0.4583333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Burner",[0.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_BaseBody",[0.0, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0, 2.0, 0.2604166666666667, 0.0, 3.0, 0.9375, 15.0, 4.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_ControlPanel",[0.0, 0.5364583333333334, 117.0, 1.0, 0.2604166666666667, 0.0, 2.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_FuelHeatingFilteringSystems",[0.0, 0.9375, 15.0, 1.0, 0.9375, 15.0, 2.0, 0.9375, 15.0, 3.0, 0.9375, 15.0, 4.0, 0.9375, 15.0, 5.0, 0.140625, 219.0, 6.0, 0.140625, 219.0, 7.0, 0.140625, 219.0, 8.0, 0.140625, 219.0, 9.0, 0.140625, 219.0]);
		this.fillGradient(this.fills,"Group_DoorHandles",[0.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}

import { defineComponent,ref} from "vue"
import AuthModule from '../store/auth.module'
import TagsModule from '../store/tags.modele'
import LineChart from './LineChart.vue'
import EventsTable from './EventsTable.vue'
import writetagvaluerequest from "@/model/requests/writetagvaluerequest"
 
export default defineComponent ({
  
  components: {LineChart, EventsTable}
  ,
  props: {
  
    id: Number,
    viewtype:String,
    usehistory:Boolean,
    useevents:Boolean,
    name: String,
    description: String,
    tagvalue:String,
    unit:String,
    datetime: Number,
    icon: String,
    color: String,
    bgcolor: String,
    minvalue:String,
    maxvalue:String,
    usevalue:Boolean,
    decimalpos:Number,
    path:String
    
  },
  setup(props){
 
    let value=ref(props.tagvalue);
    let timeoutID=ref()
    return{
      value,
      timeoutID
    //  history:ref(false)
      //maximizedToggle: ref(true)
   }
  },
  computed:{
     
      calvalue:{
        get() {
           return this.tagvalue
        },
    // setter
        set(newValue) {
            this.value = newValue
        }
      },
      slidervalue:{
        get() {
          
           return parseFloat(this.tagvalue)
        },
    // setter
        set(newValue) {
          
            this.value = String(newValue) 
             // this.handleInput();
        }
      }
  },
  methods:{
    handleInput(){
        if (AuthModule.currentUser!=null)
        {
          //TagsModule.writeTagAction({token:"",name: this.name, tagvalue:this.value});
          TagsModule.writeTagAction(this.writetagvaluerequest(this.path, this.value))
        }
    },
    handleKnobInput(){
        if (AuthModule.currentUser!=null)
        {
          //let name = this.name
          let path = this.path
          let value = this.value
          clearTimeout(this.timeoutID)
          this.timeoutID = setTimeout(function(){
            TagsModule.writeTagAction({token:"", name:path, tagvalue:value,datetime:0});
           // TagsModule.writeTagAction(writetagvaluerequest(path, value))

                 // console.log("Executed after 1 second");
            }, 500);
          
        }
    },
   clickbutton(){
        if (AuthModule.currentUser!=null)
        {
          let value = 0;
          if (this.tagvalue=='1' || this.tagvalue=="true")
            value = 1;
          console.log("value="+value + "tagvalue="+this.tagvalue);
          let tagvalue=value==0?'1':'0';
          //TagsModule.writeTagAction( {token:"",name: this.name, tagvalue:tagvalue});
          TagsModule.writeTagAction(this.writetagvaluerequest(this.path, tagvalue))
        }
    },
    writetagvaluerequest(name:string, value:string):writetagvaluerequest{
        const request:writetagvaluerequest={token:"", name:name, tagvalue:value,datetime:0};
        return request;
    },
    getDateTimeStr(){
        return new Date(this.datetime).toLocaleString();
    },
    getTagValue(){
        const value = parseFloat(this.tagvalue)
        if (isNaN(value)){
          return this.tagvalue
        }
        return Number(value.toFixed(this.decimalpos))
    },
    getTagValueStr(){
      //console.log("value="+this.tagvalue)
        return this.tagvalue+this.unit
    },
    getTagValueProgress(){
       let value = (this.getTagValue()-this.getMinValue())/(this.getMaxValue()-this.getMinValue());
       //console.log("value="+value)
       return value;
    },
     getMinValue(){
        return parseFloat(this.minvalue)
    },
     getMaxValue(){
        return parseFloat(this.maxvalue)
    },
    getMarkersStep(){
      return (this.getMaxValue()-this.getMinValue())/2
    },
    getFrontColorStr2(){
        return this.getFrontColor("");
    },
    getFrontColorStr(){
        return this.getFrontColor("color: ");
    },
    getFrontColorText(){
        return this.getFrontColor("color: ")+" ;background: "+this.getBgColor();
    },
    getFrontColor(color){
        let col = 'black';
       let value = parseFloat(this.tagvalue);
      if (this.color!=null && this.color !=''&&this.color!='null'){
          let range = JSON.parse(this.color);
          range.forEach(range => {
            if (range.from<=value && value<=range.to){
              col = range.color;
            }
          });
        }
       // console.log('col='+col)
        return color+col;
    },
    getBgColor(){
      let bgcol = 'white';
      let value = parseFloat(this.tagvalue);

      if (this.bgcolor!=null && this.bgcolor!=''&& this.bgcolor!='null'){
          let bgrange = JSON.parse(this.bgcolor);
          bgrange.forEach(bgrange => {
          if (bgrange.from<=value && value<=bgrange.to){
          bgcol = bgrange.color;
            }
          });
        }
       // console.log('bgcol='+bgcol)
        return bgcol;
    },
    getColor(){
     // return this.getFrontColorStr()+"; background: radial-gradient(circle, #ffffff 0%, "+this.getBgColor() +" 100%)";
     return this.getFrontColorStr()+"; background: "+this.getBgColor();
    
     //return this.getFrontColorStr()+"; background: linear-gradient(135deg, "+"#ffffffff"+", "+this.getBgColor() +")";
    },
    getSliderColor(){
      if (this.getBgColor()=='white' || this.getBgColor()=="#ffffffff")
      return 'dark'
      return 'white';
    },
    getSliderTextColor(){
      if (this.getBgColor()=='white' || this.getBgColor()=="#ffffffff")
      return 'white'
      return 'dark';
    }
  }
  
})

import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { NumberTile } from "./numbertile";
import { FillColorProperty } from "../properties/fillcolorproperty";
import { SliderControlProperty } from "../properties/slidercontrolproperty";

export class SliderTile extends NumberTile{
    fillcolor:string;
    value:number;

    public drawObject():void{
		super.initObject();
        this.draw(true)
}


private draw(usefunctions:boolean){
    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let fillcolor = '0xffffff00';         
    fillcolor = this.fillcolor;
    const fillprop = this.properties['fillcolor'];
    if (fillprop!=null){	
      const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
        if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }
    
    let value = 0
    let minimum = 0
    let maximum = 100
    let decimalpos = 1
                
    const sliderprop = this.properties==null?null:this.properties['slidercontrol']
    let sliderproperty;
    if (sliderprop != null){
        sliderproperty =Object.assign(new SliderControlProperty(), sliderprop);
        value = sliderproperty.getCurrentValue(this.tagnames)
        decimalpos = sliderproperty.decimalpos
        minimum = sliderproperty.minimumvalue
        maximum = sliderproperty.maximumvalue
    }   

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      },
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const textTitle = new Konva.Text({
      x: size*0.05,
      y: size*0.1-0.06*size,
      verticalAlign:"top",
      width: this.width-size*0.05*2,
      height: 0.06*size,
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(textTitle)

    const text2 = new Konva.Text({
        x: size*0.05,
        y: this.height - size * 0.05 - 0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05*2,
        height: 0.06*size,
        text: this.text,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(text2)

    const descriptionText = new Konva.Text({
        x: size*0.05,
        y: size*0.52-0.1*size,
        verticalAlign: "bottom",
        width: this.width-size*0.05*2,
        height: 0.1*size,
        text: this.description,
        fontSize: 0.1*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'right'
    });
    group.add(descriptionText)   
     
    const unitTextTransparent = new Konva.Text({
      x: 0,
      y: 0,
      verticalAlign: "bottom",
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    }); 

    const unitText = new Konva.Text({
      x: this.width-unitTextTransparent.width()-size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: unitTextTransparent.width(),
      height: 0.24*size,
      text: this.unit,
      fontSize: 0.12*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left'
    });  

    const valueText = new Konva.Text({
      x: size*0.05,
      y: size*0.39-0.24*size,
      verticalAlign: "bottom",
      width: this.width-size*0.05*2-unitTextTransparent.width(),
      height: 0.24*size,
      text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
      fontSize: 0.24*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'right',
      offsetY:-size*0.024
    }); 
    group.add(valueText, unitText)

	const trackStart  = size * 0.14;
	const trackLength = this.width - size * 0.28;
	const centerX = trackStart + (trackLength * ((value - minimum) / (maximum-minimum)));
	const centerY  = this.height * 0.71;

    const barBackground = new Konva.Rect({
        x: trackStart,
        y: centerY - size * 0.01375,
        width: trackLength,
        height: size * 0.0275,
        cornerRadius: size * 0.0275,
        fill: ColorUtils.convertformat(textcolor)
    });
    group.add(barBackground)
    
    const bar = new Konva.Rect({
        x: trackStart,
        y: centerY - size * 0.01375,
        width: centerX - trackStart,
        height: size * 0.0275,
        cornerRadius: size * 0.0275,
        fill: ColorUtils.convertformat(fillcolor)
    });
    group.add(bar)   

    const thumb = this.drawSlider(centerX, centerY, size, textcolor)
    group.add(thumb) 
    this.node.add(group)
    
    if (usefunctions) this.setFunctions(trackStart, trackLength, centerX, centerY, size, 
        textcolor, sliderproperty)
}

private drawSlider(centerX:number, centerY:number, size:number, textcolor:string):Konva.Shape{
    const thumb = new Konva.Circle({
        x: centerX,
        y: centerY,
        radius: size * 0.07,
        fill: ColorUtils.convertformat(textcolor),
        draggable:true,
    })
    return thumb
}

protected setFunctions(trackStart:number, trackLength:number, centerX:number, centerY:number, size:number, 
    textcolor:string, sliderproperty:SliderControlProperty):void{
             
    const thumb = this.drawSlider(centerX, centerY, size, textcolor)
    this.node.add(thumb)
    sliderproperty.getCurrentValue(this.tagnames)
    const originalY = thumb.y();
    thumb.on('dragmove', () => {
        thumb.y(originalY);
        console.log(thumb.y())
        if (thumb.x()<(trackStart-size * 0.07))thumb.x(trackStart-size * 0.07)
        if (thumb.x()>trackStart+trackLength+size * 0.07) thumb.x(trackStart+trackLength+size * 0.07)
                             
        sliderproperty.setCurrentHorFillingFromValue(this.tagnames, thumb.x(),this.width)
        this.draw(false);
    });

    thumb.on('dragend',()=>{
        sliderproperty.sendTagValue(this.tagnames)         
    });

    this.node.on('click touchend', ()=> {
      const pos = this.node.getRelativePointerPosition()
      sliderproperty.setCurrentHorFillingFromValue(this.tagnames, pos.x,this.width)
      sliderproperty.sendTagValue(this.tagnames)
    })
  }
}
import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { DialogBox } from '@/model/project/dialogbox';
import tagsModele from "@/store/tags.modele";
import tag from '@/model/tag';

@Module({ name:"DialogBox", dynamic:true, store})
class DialogBoxModule extends VuexModule {
  public dialogbox:DialogBox;
    @Mutation
    public setDialogBox(box:DialogBox){
      this.dialogbox = box;
      //if(this.dialogbox.value !=null) this.setValue(this.dialogbox.value)
    }
    @Mutation
    public setEnabled(value:boolean){
      if (this.dialogbox!=null)
        this.dialogbox.enable = value;
    }
    
    get getEnable():boolean{
      if (this.dialogbox!=null)
        return this.dialogbox.enable;
      return false;
    }
    get getTitle():string{
        if (this.dialogbox==null) return "";
        return this.dialogbox.title
    }
    get getMessage():string{
      if (this.dialogbox==null) return "";
      return this.dialogbox.message
    }

    @Mutation
    public setValue(value:string){
      console.log('dialogboxsetValue')
    
      if (this.dialogbox!=null)
        this.dialogbox.value = value;
    }
    get getValue():string{
        if (this.dialogbox==null) return "";
        if(this.dialogbox.isValuefromScript) return this.dialogbox.value
        
        const tagfind:tag = tagsModele.getTag(this.dialogbox.tagname)
        if (tagfind==null) return ""
        this.dialogbox.value = tagfind.tagvalue
        return tagfind.tagvalue;
    }

    @Mutation
    public writeValue(){
        if (this.dialogbox==null) return;
        tagsModele.writeTagAction({token:"",name: this.dialogbox.tagname, tagvalue:this.dialogbox.value,datetime:0});
    }
}

export default getModule(DialogBoxModule);

import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SliderControlProperty } from "./properties/slidercontrolproperty";
import Konva from "konva";
import { Slider } from "./slider";

export class SliderApple extends Slider{
    public drawObject():void{
		super.initObject();
        this.node1  = new Konva.Group({
            x: 0,
            y: 0,
           });
        this.drawSliderApple(true);
    }
    private drawSliderApple(usefunctions:boolean){
        this.node1.removeChildren();
       // this.node1.destroy();
      
		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
        const color2 = ColorUtils.convertformat(color)
		let fillcolor = '0xffffff00';
        
                let fillheight = this.height
            
                
                const sliderprop = this.properties['slidercontrol']
                let sliderproperty;
                if (sliderprop != null){
                    sliderproperty =Object.assign(new SliderControlProperty(), sliderprop);
                    //value = sliderproperty.getCurrentValue(this.tagnames)
                    fillheight = sliderproperty.getCurrentAppleFilling(this.tagnames, this.height, this.width)
                   
                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            const rectvertical = new Konva.Rect({
                x: this.width*39/80,
                y: 0,
                width: this.width*2/80,
                height: this.height,
                cornerRadius: this.width/80,
                fill: color2
              });
              this.node1.add(rectvertical)

              const rectfill = new Konva.Rect({
                x: this.width*39/80,
                y: this.height-fillheight,
                width: this.width*2/80,
                height: fillheight,
                cornerRadius:this.width/80,
                fill: fillcolor2
              });
              this.node1.add(rectfill)   

             const slider = this.drawCircle(fillheight, color2, false)
              this.node1.add(slider)
              
            this.node.add(this.node1)

              
       if (usefunctions)
            this.setFunctions(fillheight, color2, sliderproperty);


            }

            private drawCircle(fillheight:number, color2:string, draggable:boolean):Konva.Circle{
                if(this.type3d==0){
                    let slidershad = null
                    if(this.angle==0){
                        slidershad = new Konva.Circle({
                            x: this.width/2,
                            y: this.height-fillheight+ this.height/100,
                            radius: this.width/5,
                            opacity: 0.25,
                            fill: 'black'
                        })
                        
                    }else if(this.angle==90){
                        slidershad = new Konva.Circle({
                            x: this.width/2+this.height/100,
                            y: this.height-fillheight,
                            radius: this.width/5,
                            opacity: 0.25,
                            fill: 'black'
                        })
                    }else if(this.angle==180){
                        slidershad = new Konva.Circle({
                            x: this.width/2,
                            y: this.height-fillheight-this.width/10-this.height/100,
                            radius: this.width/5,
                            opacity: 0.25,
                            fill: 'black'
                    })
                }else{
                    slidershad = new Konva.Circle({
                        x: this.width/2-this.height/100,
                        y: this.height-fillheight-this.width/10,
                        radius: this.width/5,
                        opacity: 0.25,
                        fill: 'black'
    
                })
            }
            this.node1.add(slidershad)
        }
                  
                  const slider = new Konva.Circle({
                    x: this.width/2,
                    y: this.height- fillheight,
                    radius: this.width/5,
                    strokeWidth: 1,
                    stroke: 'grey',
                    fill: 'white',
                    draggable:draggable
                  })
        return slider
            }
            
            protected setFunctions(fillheight:number,color2:string, controlproperty:SliderControlProperty):void{
              
                  const slider = this.drawCircle(fillheight, color2, true)
                  this.node.add(slider)
                  controlproperty.getTag
                  const originalX = slider.x();
                    slider.on('dragmove', () => {
                            slider.x(originalX);
                            if (slider.y()<this.width/5)
                                slider.y(this.width/5)
                            if (slider.y()>(this.height-this.width/5))
                                slider.y(this.height-this.width/5)
                                this.drawSliderApple(false);
                                controlproperty.setCurrentAppleFillingFromValue(this.tagnames, this.height-slider.y(),this.height, this.width)
                                this.drawSliderApple(false);
                    });
                    slider.on('dragend',()=>{
                                controlproperty.sendTagValue(this.tagnames)         
                    });

                    this.node.on('click touchend', ()=> {
                        const pos = this.node.getRelativePointerPosition()
                       controlproperty.setCurrentAppleFillingFromValue(this.tagnames, this.height-pos.y,this.height, this.width)
                       controlproperty.sendTagValue(this.tagnames)
                     })
          }
    
}
import { SVGObject } from "../svgobject";

export class SVGWater48 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 53.043" enable-background="new 0 0 112.5 53.043" xml:space="preserve">
        <g id="Group_PressBody">
            <path fill="#CCCCCC" d="M2.59,51.69l98.986-5.405L94.933,4.506L81.531,0.902H12.049L2.59,51.69z" />
        </g>
        <g id="Group_FiltrationPlates">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="11.5991" y1="2.8162" x2="12.7251" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M11.599,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="14.8647" y1="2.8162" x2="16.1035" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M14.865,0.001h1.239v5.631h-1.239V0.001z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="18.4683" y1="2.8162" x2="19.5947" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M18.468,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="21.8467" y1="2.8162" x2="23.1982" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M21.847,0.001h1.352v5.631h-1.352V0.001z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="25.3379" y1="2.8162" x2="26.5767" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M25.338,0.001h1.239v5.631h-1.239V0.001z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="28.9414" y1="2.8162" x2="30.0674" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M28.941,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="32.207" y1="2.8162" x2="33.4458" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M32.207,0.001h1.239v5.631h-1.239V0.001z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="35.811" y1="2.8162" x2="36.937" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M35.811,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="39.0767" y1="2.8162" x2="40.5405" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M39.077,0.001h1.464v5.631h-1.464V0.001z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="42.6802" y1="2.8162" x2="43.9189" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M42.68,0.001h1.239v5.631H42.68V0.001z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="46.2837" y1="2.8162" x2="47.4097" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M46.284,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="49.5493" y1="2.8162" x2="50.6758" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M49.549,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="53.0405" y1="2.8162" x2="54.2793" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M53.041,0.001h1.239v5.631h-1.239V0.001z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="56.4189" y1="2.8162" x2="57.8828" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M56.419,0.001h1.464v5.631h-1.464V0.001z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="60.0225" y1="2.8162" x2="61.1484" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M60.022,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="63.5137" y1="2.8162" x2="64.752" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M63.514,0.001h1.238v5.631h-1.238V0.001z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="66.8926" y1="2.8162" x2="68.0176" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M66.893,0.001h1.125v5.631h-1.125V0.001z" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="70.3828" y1="2.8162" x2="71.6211" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M70.383,0.001h1.238v5.631h-1.238V0.001z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="73.7617" y1="2.8162" x2="75.2246" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M73.762,0.001h1.463v5.631h-1.463V0.001z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="77.3652" y1="2.8162" x2="78.4912" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M77.365,0.001h1.126v5.631h-1.126V0.001z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="80.8555" y1="2.8162" x2="82.0947" y2="2.8162">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M80.855,0.001h1.239v5.631h-1.239V0.001z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="11.5991" y1="28.7732" x2="12.7251" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M11.599,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="14.8647" y1="28.7732" x2="16.1035" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M14.865,21.51h1.239v14.527h-1.239V21.51z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="18.4683" y1="28.7732" x2="19.5947" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" d="M18.468,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="21.8467" y1="28.7732" x2="23.1982" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M21.847,21.51h1.352v14.527h-1.352V21.51z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="25.3379" y1="28.7732" x2="26.5767" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M25.338,21.51h1.239v14.527h-1.239V21.51z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="28.9414" y1="28.7732" x2="30.0674" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M28.941,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="32.207" y1="28.7732" x2="33.4458" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" d="M32.207,21.51h1.239v14.527h-1.239V21.51z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="35.811" y1="28.7732" x2="36.937" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" d="M35.811,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="39.0767" y1="28.7732" x2="40.5405" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" d="M39.077,21.51h1.464v14.527h-1.464V21.51z" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="42.6802" y1="28.7732" x2="43.9189" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" d="M42.68,21.51h1.239v14.527H42.68V21.51z" />
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="46.2837" y1="28.7732" x2="47.4097" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" d="M46.284,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="49.5493" y1="28.7732" x2="50.6758" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" d="M49.549,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="53.0405" y1="28.7732" x2="54.2793" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_34_)" d="M53.041,21.51h1.239v14.527h-1.239V21.51z" />
            <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="56.4189" y1="28.7732" x2="57.8828" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_35_)" d="M56.419,21.51h1.464v14.527h-1.464V21.51z" />
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="60.0225" y1="28.7732" x2="61.1484" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_36_)" d="M60.022,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="63.5137" y1="28.7732" x2="64.752" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_37_)" d="M63.514,21.51h1.238v14.527h-1.238V21.51z" />
            <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="64.2109" y1="27.2605" x2="70.6994" y2="30.2861">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_38_)" d="M66.893,21.51h1.125v14.527h-1.125V21.51z" />
            <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="70.3828" y1="28.7732" x2="71.6211" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_39_)" d="M70.383,21.51h1.238v14.527h-1.238V21.51z" />
            <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="73.7617" y1="28.7732" x2="75.2246" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_40_)" d="M73.762,21.51h1.463v14.527h-1.463V21.51z" />
            <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="77.3652" y1="28.7732" x2="78.4912" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_41_)" d="M77.365,21.51h1.126v14.527h-1.126V21.51z" />
            <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="80.8555" y1="28.7732" x2="82.0947" y2="28.7732">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.53" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_42_)" d="M80.855,21.51h1.239v14.527h-1.239V21.51z" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M11.599,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M15.09,52.14V39.866l1.689-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M18.468,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M21.959,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M25.563,52.14V39.866l1.689-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M28.941,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M32.433,52.14V39.866l1.914-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M36.036,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M39.302,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M42.905,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M46.509,52.14V39.866l1.802-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M49.775,52.14V39.866l1.914-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M53.378,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M56.869,52.14V39.866l1.914-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M60.473,52.14V39.866l1.914-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M63.738,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M67.342,52.14V39.866l1.915-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M70.945,52.14V39.866l1.803-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M74.212,52.14V39.866l1.914-2.365v-3.829" />
            <path fill="none" stroke="#231F20" stroke-width="0.25" d="M77.815,52.14V39.866l1.914-2.365v-3.829" />
        </g>
        <g id="Group_FilterPlates">
            <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="12.1865" y1="7.7532" x2="15.4037" y2="9.2534">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_43_)" d="M13.176,5.632h1.239v5.743h-1.239V5.632z" />
            <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="15.5654" y1="7.7532" x2="18.7826" y2="9.2534">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_44_)" d="M16.554,5.632h1.239v5.743h-1.239V5.632z" />
            <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="19.1587" y1="7.7747" x2="22.283" y2="9.2315">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_45_)" d="M20.158,5.632h1.126v5.743h-1.126V5.632z" />
            <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="22.6587" y1="7.7532" x2="25.8768" y2="9.2538">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_46_)" d="M23.648,5.632h1.239v5.743h-1.239V5.632z" />
            <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="26.0278" y1="7.7747" x2="29.153" y2="9.2319">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_47_)" d="M27.027,5.632h1.126v5.743h-1.126V5.632z" />
            <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="29.5293" y1="7.7532" x2="32.7465" y2="9.2534">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_48_)" d="M30.518,5.632h1.239v5.743h-1.239V5.632z" />
            <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="32.897" y1="7.7747" x2="36.0221" y2="9.2319">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_49_)" d="M33.896,5.632h1.126v5.743h-1.126V5.632z" />
            <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="36.5015" y1="7.7751" x2="39.6257" y2="9.232">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_50_)" d="M37.5,5.632h1.126v5.743H37.5V5.632z" />
            <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="39.7964" y1="7.7097" x2="43.1986" y2="9.2962">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_51_)" d="M40.766,5.632h1.464v5.743h-1.464V5.632z" />
            <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="43.3696" y1="7.7747" x2="46.4957" y2="9.2324">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_52_)" d="M44.369,5.632h1.127v5.743h-1.127V5.632z" />
            <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="46.8716" y1="7.7537" x2="50.0888" y2="9.2539">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_53_)" d="M47.86,5.632H49.1v5.743H47.86V5.632z" />
            <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="50.2388" y1="7.7747" x2="53.3648" y2="9.2324">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_54_)" d="M51.238,5.632h1.127v5.743h-1.127V5.632z" />
            <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="53.7402" y1="7.7527" x2="56.9583" y2="9.2533">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_55_)" d="M54.729,5.632h1.239v5.743h-1.239V5.632z" />
            <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="57.335" y1="7.7751" x2="60.4584" y2="9.2316">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_56_)" d="M58.334,5.632h1.125v5.743h-1.125V5.632z" />
            <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="60.7129" y1="7.7751" x2="63.8372" y2="9.232">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_57_)" d="M61.712,5.632h1.126v5.743h-1.126V5.632z" />
            <linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse" x1="64.2139" y1="7.7537" x2="67.4302" y2="9.2535">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_58_)" d="M65.203,5.632h1.238v5.743h-1.238V5.632z" />
            <linearGradient id="SVGID_59_" gradientUnits="userSpaceOnUse" x1="67.4795" y1="7.7532" x2="70.6967" y2="9.2534">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_59_)" d="M68.469,5.632h1.238v5.743h-1.238V5.632z" />
            <linearGradient id="SVGID_60_" gradientUnits="userSpaceOnUse" x1="71.084" y1="7.7532" x2="74.3003" y2="9.253">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_60_)" d="M72.072,5.632h1.238v5.743h-1.238V5.632z" />
            <linearGradient id="SVGID_61_" gradientUnits="userSpaceOnUse" x1="74.4717" y1="7.7317" x2="77.7809" y2="9.2748">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_61_)" d="M75.451,5.632h1.351v5.743h-1.351V5.632z" />
            <linearGradient id="SVGID_62_" gradientUnits="userSpaceOnUse" x1="77.9521" y1="7.7537" x2="81.1685" y2="9.2535">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_62_)" d="M78.941,5.632h1.238v5.743h-1.238V5.632z" />
            <linearGradient id="SVGID_63_" gradientUnits="userSpaceOnUse" x1="81.5557" y1="7.7537" x2="84.772" y2="9.2535">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_63_)" d="M82.545,5.632h1.238v5.743h-1.238V5.632z" />
        </g>
        <g id="Group_BackMetalFrame">
            <linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse" x1="96.2568" y1="25.6824" x2="113.3032" y2="33.6312">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_64_)" d="M106.982,11.375h-4.055l2.14,37.726l1.915-1.915V11.375z" />
            <linearGradient id="SVGID_65_" gradientUnits="userSpaceOnUse" x1="101.0137" y1="30.4509" x2="105.0674" y2="30.4509">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_65_)" d="M101.014,12.951l4.054-1.148V49.1h-4.054V12.951z" />
            <linearGradient id="SVGID_66_" gradientUnits="userSpaceOnUse" x1="101.0137" y1="12.1628" x2="106.9824" y2="12.1628">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_66_)" d="M101.014,12.951h4.054l1.915-1.576h-4.055L101.014,12.951z" />
        </g>
        <g id="Group_ControlBox">
            <radialGradient id="SVGID_67_" cx="98.7051" cy="26.6335" r="7.6827" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </radialGradient>
            <path fill="url(#SVGID_67_)" d="M90.428,19.595h16.555v14.076H90.428V19.595z" />
            <linearGradient id="SVGID_68_" gradientUnits="userSpaceOnUse" x1="90.4277" y1="17.0056" x2="112.5" y2="17.0056">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_68_)" d="M90.428,19.595h16.555l5.518-5.18H95.834L90.428,19.595z" />
            <radialGradient id="SVGID_69_" cx="109.7412" cy="24.0437" r="7.0819" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </radialGradient>
            <path fill="url(#SVGID_69_)" d="M112.5,14.416l-5.518,5.18v14.076l5.518-5.619V14.416z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M107.883,21.847l3.604-3.378v7.545l-3.604,3.378V21.847z" />
        </g>
        <g id="Group_FrontMetalFrame">
            <linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse" x1="3.9414" y1="15.1355" x2="9.9097" y2="15.1355">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_70_)" d="M3.941,12.951l5.293,5.945l0.675-7.521H5.856L3.941,12.951z" />
            <linearGradient id="SVGID_71_" gradientUnits="userSpaceOnUse" x1="3.9414" y1="31.0251" x2="7.9956" y2="31.0251">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_71_)" d="M3.941,12.951h4.054V49.1H3.941V12.951z" />
            <linearGradient id="SVGID_72_" gradientUnits="userSpaceOnUse" x1="1.5454" y1="26.7834" x2="16.2304" y2="33.6312">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_72_)" d="M9.91,11.375l-1.914,1.576V49.1l1.914-1.915V11.375z" />
            <linearGradient id="SVGID_73_" gradientUnits="userSpaceOnUse" x1="-5.7236" y1="29.282" x2="12.1981" y2="37.639">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_73_)" d="M5.856,15.428L0,17.006l3.941,36.036l1.915-1.802V15.428z" />
            <linearGradient id="SVGID_74_" gradientUnits="userSpaceOnUse" x1="0.4229" y1="17.5745" x2="17.4692" y2="25.5233">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_74_)" d="M11.148,3.266H7.095l2.14,37.726l1.914-1.914V3.266z" />
            <linearGradient id="SVGID_75_" gradientUnits="userSpaceOnUse" x1="83.4453" y1="4.1111" x2="89.4141" y2="4.1111">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_75_)" d="M83.445,4.956H87.5l1.914-1.689H85.36L83.445,4.956z" />
            <linearGradient id="SVGID_76_" gradientUnits="userSpaceOnUse" x1="81.0938" y1="18.6956" x2="95.7354" y2="25.5231">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_76_)" d="M89.414,3.266L87.5,4.956v36.036l1.914-1.914V3.266z" />
            <linearGradient id="SVGID_77_" gradientUnits="userSpaceOnUse" x1="89.1895" y1="22.0051" x2="95.834" y2="22.0051">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_77_)" d="M89.189,6.87l6.645-2.162v34.595h-6.645V6.87z" />
            <linearGradient id="SVGID_78_" gradientUnits="userSpaceOnUse" x1="89.8027" y1="17.6428" x2="104.6373" y2="24.5603">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_78_)" d="M99.391,3.266l-3.557,1.441v34.595l3.557-3.491V3.266z" />
            <linearGradient id="SVGID_79_" gradientUnits="userSpaceOnUse" x1="92.8486" y1="29.7976" x2="109.5259" y2="37.5744">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_79_)" d="M103.153,15.428h-3.604l2.027,37.613l1.577-1.802V15.428z" />
            <linearGradient id="SVGID_80_" gradientUnits="userSpaceOnUse" x1="8.562" y1="17.1741" x2="84.9102" y2="17.1741">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_80_)" d="M84.91,13.965H8.562v6.419H84.91V13.965z" />
            <path fill="#7F7F7F" d="M4.729,51.014h93.354v1.126H4.729V51.014z" />
            <radialGradient id="SVGID_81_" cx="47.0723" cy="13.2327" r="26.7604" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </radialGradient>
            <path fill="url(#SVGID_81_)" d="M83.445,13.965H9.234l1.351-1.464H84.91L83.445,13.965z" />
            <linearGradient id="SVGID_82_" gradientUnits="userSpaceOnUse" x1="83.1641" y1="22.9739" x2="87.5" y2="22.9739">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_82_)" d="M83.164,4.956H87.5v36.036h-4.336V4.956z" />
            <linearGradient id="SVGID_83_" gradientUnits="userSpaceOnUse" x1="97.5225" y1="34.2351" x2="101.5762" y2="34.2351">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_83_)" d="M97.522,17.006l4.054-1.577v37.613h-4.054V17.006z" />
            <linearGradient id="SVGID_84_" gradientUnits="userSpaceOnUse" x1="89.1895" y1="5.0681" x2="99.4375" y2="5.0681">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_84_)" d="M89.189,6.87h6.645l3.604-3.604h-6.42L89.189,6.87z" />
            <linearGradient id="SVGID_85_" gradientUnits="userSpaceOnUse" x1="97.5225" y1="16.217" x2="103.1533" y2="16.217">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_85_)" d="M97.522,17.006h4.054l1.577-1.577h-3.941L97.522,17.006z" />
            <linearGradient id="SVGID_86_" gradientUnits="userSpaceOnUse" x1="0" y1="35.0232" x2="3.9414" y2="35.0232">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_86_)" d="M0,17.006h3.941v36.036H0V17.006z" />
            <linearGradient id="SVGID_87_" gradientUnits="userSpaceOnUse" x1="0" y1="16.217" x2="5.856" y2="16.217">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_87_)" d="M0,17.006h3.941l1.915-1.577H1.802L0,17.006z" />
            <linearGradient id="SVGID_88_" gradientUnits="userSpaceOnUse" x1="5.1802" y1="22.2566" x2="9.2344" y2="22.2566">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_88_)" d="M5.18,4.956l4.054-1.435v37.471H5.18V4.956z" />
            <linearGradient id="SVGID_89_" gradientUnits="userSpaceOnUse" x1="5.1802" y1="4.1111" x2="11.1484" y2="4.1111">
                <stop offset="0" style="stop-color:#0000E0" />
                <stop offset="0.22" style="stop-color:#1717E6" />
                <stop offset="0.68" style="stop-color:#5353F4" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_89_)" d="M5.18,4.956h4.054l1.914-1.689H7.095L5.18,4.956z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.8072916666666666, 49.0, 0.53, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_43_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_44_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_46_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_47_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_48_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_49_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_50_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_51_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_52_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_53_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_54_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_55_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_56_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_57_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_58_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_59_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_60_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_61_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_62_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_63_",[0.0, 0.4739583333333333, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_64_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_65_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_66_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_67_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_68_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_69_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_70_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_71_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_72_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_73_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_74_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_75_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_76_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_77_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_78_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_79_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_80_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_81_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_82_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_83_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_84_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_85_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_86_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_87_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_88_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_89_",[0.0, 0.5729166666666666, 0.0, 0.22, 0.7135416666666666, 0.0, 0.68, 0.9375, 15.0, 1.0, 0.6822916666666666, 81.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater60 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 101.462" enable-background="new 0 0 112.5 101.462" xml:space="preserve">
        <g id="Group_Stem">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="86.374" y1="44.9048" x2="86.374" y2="32.1406">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M74.844,32.141h23.061v12.764H74.844V32.141" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="38.5229" y1="51.4014" x2="38.5229" y2="25.6436">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect y="25.643" fill="url(#SVGID_2_)" width="77.045" height="25.758" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="70.8867" y1="51.4014" x2="70.8867" y2="25.6436">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="70.532" y="25.643" fill="url(#SVGID_3_)" width="0.71" height="25.758" />
        </g>
        <g id="Group_Knob">
            <path fill="#4D4D4D" d="M97.904,64.166h11.168V12.878H97.904V64.166z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="105.9385" y1="44.9048" x2="105.9385" y2="32.1406">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M99.499,44.905h12.879V32.141H99.499V44.905z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.253" d="M99.499,36.357h12.879" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.253" d="M99.499,40.688h12.879" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="97.9023" y1="67.3564" x2="97.9023" y2="9.5737">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M96.307,9.573h3.192v57.783h-3.192" />
        </g>
        <g id="Group_WallMountFlange">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="22.5664" y1="38.5215" x2="35.332" y2="38.5215">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M35.332,19.261v38.523l-3.191,6.382v12.878h-6.497V64.166l-3.077-6.382V19.261l3.077-6.382V0h6.497   v12.88L35.332,19.261" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="32.1406" y1="20.8564" x2="35.332" y2="20.8564">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M32.141,12.879v9.573l3.191,6.381v-9.573L32.141,12.879z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="26.3247" y1="20.6621" x2="21.8856" y2="21.0505">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M25.644,12.879v9.573l-3.077,6.381v-9.573L25.644,12.879z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="25.7656" y1="55.9863" x2="22.4351" y2="56.2777">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M25.644,64.166v-9.573l-3.077-6.497v9.688L25.644,64.166z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="32.1406" y1="56.1309" x2="35.332" y2="56.1309">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M32.141,64.166v-9.573l3.191-6.497v9.688L32.141,64.166z" />
        </g>
        <g id="Group_Faucet">
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="75.7412" y1="-9.1421" x2="75.7412" y2="-40.4829" gradientTransform="matrix(0.5211 0.8535 -0.8535 0.5211 0.9575 20.1455)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M38.992,64.899l18.792,18.527l3.192,7.979L83.5,86.606l-8.626-15.1l-4.214-7.342l-3.188-12.764   l-17.305-0.67L38.992,64.899z" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="41.5815" y1="-14.7524" x2="57.6619" y2="-42.6044" gradientTransform="matrix(0.5211 0.8535 -0.8535 0.5211 0.9575 20.1455)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M35.332,38.466v14.193l1.094,5.17l31.046-6.428c0,0-3.991-10.059-7.179-11.936   C54.916,36.3,35.332,38.466,35.332,38.466z" />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="30.8579" y1="-5.8018" x2="36.695" y2="-37.2445" gradientTransform="matrix(0.2911 0.9567 -0.9567 0.2911 21.2852 28.2681)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M67.472,51.401l-32.027,1.37c0,0,0.515,2.247,0.63,3.112c0.116,0.881,0.667,3.486,0.667,3.486   L67.472,51.401z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="53.4673" y1="-8.4556" x2="56.7797" y2="-40.0249" gradientTransform="matrix(0.506 0.8625 -0.8625 0.506 3.1355 20.2935)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M67.472,51.401l-30.814,7.646c0,0,0.731,2.82,1.108,3.703c0.245,0.572,1.227,2.148,1.227,2.148   L67.472,51.401z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="60.7119" y1="97.665" x2="85.4009" y2="88.679">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M62.002,101.208l-2.623-9.461l24.733-6.609l2.621,9.459L62.002,101.208" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.253" d="M60.293,94.825l24.618-6.609" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.253" d="M85.822,91.405l-24.733,6.61" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Group_x5F_Crate" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 103.262" enable-background="new 0 0 112.5 103.262" xml:space="preserve">
        <g id="Group_Crate">
        <path fill="#D6C2AD" d="M0.452,9.797l18.581-9.234h92.79L93.354,9.797H0.452z" />
        <path fill="#AD845B" d="M0.452,9.797h92.902v92.902H0.452V9.797z" />
        <path fill="#996633" d="M9.799,93.465V19.031l2.478,2.478v71.957H9.799z" />
        <path fill="#C2A385" d="M0.452,9.797h9.347v92.902H0.452V9.797z" />
        <path fill="#C2A385" d="M84.121,9.797h9.233v92.902h-9.233V9.797z" />
        <polygon fill="#B89471" points="84.121,9.797 9.799,9.797 9.799,26.125 77.139,93.353 77.278,93.465 9.799,93.465 9.799,102.699   84.121,102.699 84.008,86.371 16.667,19.031 84.121,19.031 " />
        <path fill="#C2A385" d="M16.781,6.419l8.107-4.055h74.323l-8.334,4.055H16.781z" />
        <path fill="#996633" d="M99.211,2.365L96.732,3.49H24.888l-5.855,2.929h-2.252l8.107-4.055H99.211z" />
        <path fill="none" d="M16.781,6.419l8.107-4.055" />
        <path fill="#996633" d="M93.354,13.023l18.581-8.617v85.916l-18.581,8.617V13.023z" />
        <path fill="#7A502B" d="M110.021,10.698v74.21l-14.865,7.545v-2.365l13.758-6.879l-0.169-71.829L110.021,10.698z" />
        <path fill="#AD845B" d="M111.936,93.353V0.45L93.242,9.797v92.902L111.936,93.353z M95.156,18.212l14.865-7.475v73.935  l-14.865,7.416V18.212z" />
        <polygon fill="#9B6D45" points="16.667,19.031 84.008,86.371 84.121,83.298 19.25,19.031 " />
        <path fill="#9B6D45" d="M77.139,93.465H9.799l2.478-2.478H74.77L77.139,93.465z" />
        <path fill="#684226" d="M110.021,10.698v73.975l-1.276-1.379V11.379L110.021,10.698z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
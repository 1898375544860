import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import Konva from "konva";
import { Value } from "../value";

export class Scale extends ObjectView{
    color:string;
    linewidth:number;
    useborder:boolean;
    scale2:boolean;
    scale3:boolean;
    scaleinterval1:number;
    scaleinterval2:number;
    scaleinterval3:number;
    sizemarkers1:number;
    sizemarkers2:number;
    sizemarkers3:number;
    type:number;
    usedigit:boolean;
    max:number;
    min:number;
    decimalpos:number;
    fontsize:number;

    public drawObject():void{
		super.initObject();

		if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
        if(this.type > 3 || this.type< 0) this.type = 0

		let color:string = this.color;
		const lineprop =this.properties==null?null:this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		const colorline:string = ColorUtils.convertformat(color);
        
        if(this.useborder){
            const rect = new Konva.Rect({
                x: this.linewidth/2,
                y: this.linewidth/2,
                width: this.width-this.linewidth,
                height: this.height-this.linewidth,
                stroke: colorline,
                strokeWidth: this.linewidth,
              });
              this.node.add(rect)
        }
		
        if(this.type <2){
            this.drawVerticalScale(colorline)
        }
        else{
            this.drawHorizontalScale(colorline)
        }	
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "color": {this[field] = value.value;this.rotatednode=null;break;}
            case "linewidth":
            case "scaleinterval1":
            case "scaleinterval2":
            case "scaleinterval3":
            case "sizemarkers1":
            case "sizemarkers2":
            case "sizemarkers3":
            case "fontsize":  {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "type":
            case "decimalpos": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
            
            case "min":
            case "max": {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "useborder":
            case "scale2":
            case "scale3":
            case "usedigit":  {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "color": {value.datatype=7; value.value = this[field];break}
            case "linewidth":
            case "scaleinterval1":
            case "scaleinterval2":
            case "scaleinterval3":
            case "sizemarkers1":
            case "sizemarkers2":
            case "sizemarkers3":
            case "fontsize": {value.datatype=3; value.value = this[field];break}

            case "type":
            case "decimalpos": {value.datatype=1; value.value = this[field];break}

            case "min":
            case "max": {value.datatype=6; value.value = this[field];break}

            case "useborder":
            case "scale2":
            case "scale3":
            case "usedigit": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }

    private drawVerticalScale(colorline:string){
        //console.log("Vertical scale")
        const int1 = (this.height-this.linewidth)/this.scaleinterval1
        
        const valuestep = (this.max - this.min)/this.scaleinterval1
        //console.log(valuestep)
        let d = 0;
       for(let i=this.linewidth/2; i <= this.height-this.linewidth/2; i=i+int1){
            const line = new Konva.Line({
                points: [this.type==0?this.linewidth/2:this.width-this.sizemarkers1, i, 
                    this.type==0?this.sizemarkers1:this.width-this.linewidth/2, i],
                stroke: colorline,
                strokeWidth: this.linewidth,
            })
            this.node.add(line)
            if (this.usedigit){
                const valuepos = this.max - d*valuestep;
               
            
                const valueposstr = (Math.round(+valuepos*10**this.decimalpos)/(10**this.decimalpos)).
                     toFixed(this.decimalpos);
                let fontsize = this.fontsize;
                if (fontsize ==0)
                fontsize = this.height/10;
                let x = this.sizemarkers1+this.linewidth;
                if (this.type==1)
                     x = 0;
                let y = i-int1/2;
                let align = 'middle'
                if (d==0){
                     y = i+this.linewidth;
                     align = 'top'
                }
                if (d==this.scaleinterval1){
                    y= i - int1+this.linewidth/2
                    align = 'bottom'
                }
                const Text = new Konva.Text({
                    x: x,
                    y: y,
                    verticalAlign:align,
                    align:this.type==0?"left":"right",
                    width: this.width-this.sizemarkers1-this.linewidth,
                    height: int1,
                    text: valueposstr,
                    fontSize: fontsize,
                    fontFamily: "Roboto",
                    fill: colorline          
                  });
                  this.node.add(Text)
                  d++;
                }
            let int2 = int1
            if(this.scale2){
                int2 = int1/this.scaleinterval2
                for(let j=this.linewidth/2; j <= this.height-this.linewidth/2; j=j+int2){
                    const line = new Konva.Line({
                        points: [this.type==0 ? this.linewidth/2 : this.width-this.sizemarkers2, j, 
                        this.type==0? this.sizemarkers2 : this.width-this.linewidth/2, j],
                        stroke: colorline,
                        strokeWidth: this.linewidth, 
                    })
                    this.node.add(line)
            }
        }
            let int3 = int2
            if(this.scale3){
                this.scale2 ? int3 = int2/this.scaleinterval3 : int3 = int2/(this.scaleinterval3*this.scaleinterval2)
                for(let k=this.linewidth/2; k <= this.height-this.linewidth/2; k=k+int3){
                    const line = new Konva.Line({
                        points: [this.type==0 ? this.linewidth/2 : this.width-this.sizemarkers3, k, 
                        this.type==0 ? this.sizemarkers3 : this.width-this.linewidth/2, k],
                        stroke: colorline,
                        strokeWidth: this.linewidth,
                    })
                    this.node.add(line)
            }
        }
    }
    }

    private drawHorizontalScale(colorline:string){
    const int1 = (this.width-this.linewidth)/this.scaleinterval1
    const valuestep = (this.max - this.min)/this.scaleinterval1
        let d = 0;
   
   for(let i=this.linewidth/2; i <= this.width-this.linewidth/2; i=i+int1){
        const line = new Konva.Line({
            points: [i, this.type==2 ? this.linewidth/2 : this.height-this.linewidth/2, 
            i, this.type==2 ? this.linewidth/2+this.sizemarkers1 : this.height-this.sizemarkers1],
            stroke: colorline,
            strokeWidth: this.linewidth,
        })
        this.node.add(line)

        if (this.usedigit){
            const valuepos = this.min + d*valuestep;
        
        
            const valueposstr = (Math.round(+valuepos*10**this.decimalpos)/(10**this.decimalpos)).
                 toFixed(this.decimalpos);
            let fontsize = this.fontsize;
            if (fontsize ==0)
            fontsize = this.height/10;
            let y = this.sizemarkers1+this.linewidth;
            
            if (this.type==3)
                 y = 0;
            let x = i-int1/2
            let align = 'center'
            if (d==0){
                 x = this.linewidth/2;
                 align = 'left'
            }
            if (d==this.scaleinterval1){
                x = i - int1-this.linewidth/2
                align = 'right'
            }
            
            const Text = new Konva.Text({
                x: x,
                y: y,
                verticalAlign: this.type==2?"top":"bottom",
                align: align,
                width: int1,
                height: this.height-this.sizemarkers1-this.linewidth/2,
                text: valueposstr,
                fontSize: fontsize,
                fontFamily: "Roboto",
                fill: colorline          
              });
              this.node.add(Text)
              d++;
            }

        let int2 = int1
        if(this.scale2){
            int2 = int1/this.scaleinterval2
            for(let j=this.linewidth/2; j <= this.width-this.linewidth/2; j=j+int2){
                const line = new Konva.Line({
                    points: [j, this.type==2 ? this.linewidth/2 : this.height-this.linewidth/2, 
                    j, this.type==2 ? this.linewidth/2+this.sizemarkers2 : this.height-this.sizemarkers2],
                    stroke: colorline,
                    strokeWidth: this.linewidth, 
                })
                this.node.add(line)
        }
    }
        let int3 = int2
        if(this.scale3){
            this.scale2 ? int3 = int2/this.scaleinterval3 : int3 = int2/(this.scaleinterval3*this.scaleinterval2)
           
            for(let k=this.linewidth/2; k <= this.width-this.linewidth/2; k=k+int3){
                const line = new Konva.Line({
                    points: [k, this.type==2 ? this.linewidth/2 : this.height-this.linewidth/2, 
                    k, this.type==2 ? this.linewidth/2+this.sizemarkers3 : this.height-this.sizemarkers3],
                    stroke: colorline,
                    strokeWidth: this.linewidth,
                })
                this.node.add(line)
        }
    }
}
    }
}



import { SVGObject } from "../svgobject";

export class SVGPaper11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 103.492" enable-background="new 0 0 112.5 103.492" xml:space="preserve">
        <g id="Group_Frame">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,57.321h21.396v44.933H31.419V85.474h5.63V57.321z" />
        </g>
        <g id="Group_Body">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="58.3643" y1="69.5178" x2="40.4341" y2="40.4973">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M40.428,40.429h18.018v29.279H40.428V40.429z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M51.689,0.001h6.756v2.252h-4.504v38.176h-2.252V0.001z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M42.68,0.001h6.757v40.428H42.68V0.001z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,42.681h31.531v3.379H8.896V42.681z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,47.186h31.531v13.514H22.41v36.036H8.896V47.186z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="17.9614" y1="102.1946" x2="17.9614" y2="96.6986">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,96.736h35.923v5.518H0V96.736z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,96.736h35.923v5.518H0V96.736" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M10.135,89.979V73.086h11.148" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M10.135,89.979h11.148V73.086" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.428,40.429h18.018v29.279H40.428V40.429" />
        </g>
        <g id="Group_Pipe">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M58.445,0.001h6.758V101.24h-6.758V0.001z" />
        </g>
        <g id="Group_Supporter">
            <path fill="#999999" d="M42.68,71.96h15.765v29.279H42.68V71.96z" />
            <path fill="#7F7F7F" d="M49.437,85.474h9.008v15.766h-9.008V85.474z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M22.41,91.105h5.631v5.631H22.41V91.105z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M29.167,91.105h5.631v5.631h-5.631V91.105z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M2.252,56.195h6.644v40.541H2.252V56.195z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="8.8359" y1="79.8762" x2="0.333" y2="64.5344">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0,64.077h8.896v15.766H0V64.077z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,64.077h8.896v15.766H0V64.077" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.437,101.24V85.474h9.008" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M71.959,74.212h6.758V71.96h-6.758V74.212z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M65.203,74.212h15.766v27.027H65.203V74.212z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M78.717,103.491V71.96l2.252-2.252h4.504l2.252,4.504L112.5,98.988   v4.504H78.717z" />
        </g>
        <g id="Group_Divider">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M49.437,3.379h2.252v37.05h-2.252V3.379z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M10.135,47.186h30.292V46.06H10.135V47.186z" />
        </g>
        <g id="Group_PowerSupplier">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="46.6396" y1="87.3811" x2="36.2881" y2="72.0393">
                <stop offset="0" style="stop-color:#BDBDBD" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M35.923,71.96h11.261v15.766H35.923V71.96z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M35.923,71.96h11.261v15.766H35.923V71.96" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M43.806,87.726h1.126v13.514h-1.126V87.726z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M41.554,87.726h1.126v13.514h-1.126V87.726z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M39.302,87.726h1.126v13.514h-1.126V87.726z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M37.049,87.726h1.126v13.514h-1.126V87.726z" />
        </g>
        <g id="Group_Detector">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M73.086,85.474l2.252-4.505l-2.252-4.504l-2.252,4.504L73.086,85.474   z" />
        </g>
        <g id="Group_Bolt">
            <circle fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" cx="25.225" cy="54.505" r="2.815" />
        </g>
        <g id="Group_Main">
            <path fill="#FFFFFF" d="M38.176,74.212h6.757v2.253h-6.757V74.212z" />
            <path fill="#666666" d="M38.176,74.212h6.757v-1.126h-6.757V74.212z" />
        </g>
        <g id="Group_Base">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="61.8242" y1="101.2151" x2="61.8242" y2="103.504">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M35.923,101.24h51.801v2.252H35.923V101.24z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.9895833333333334, 0.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.40625, 151.0, 0.5, 0.8072916666666666, 49.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.9895833333333334, 0.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5208333333333334, 121.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.40625, 151.0, 0.5, 0.8072916666666666, 49.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Pipe",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Supporter",[0.0, 0.8072916666666666, 49.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Divider",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}

import {requestStream, requestResponse} from './rsocketutils/rsocket.service'
import writetagvaluerequest from '@/model/requests/writetagvaluerequest';
import historydatarequest from '@/model/requests/historydatarequest';
import eventsdatarequest from '@/model/requests/eventsdatarequest';
import historydatabaserequest from '@/model/requests/historydatabaserequest';
import historydbtablerequest from '@/model/requests/historydbtablerequest'
import cardsrequest from '@/model/requests/cardsrequest';

class TagsService {

 getalltags(token:string,uuid:string, onnextCallback:(data:string)=>void, errorCallback:(data:string)=>void) {
    requestStream({token:token, uuid:uuid, subscription:'#'},'tags/alltags', onnextCallback,errorCallback); 
 }

 tagupdate(request:writetagvaluerequest, 
   responseCallback:(data:string)=>void,
   errorCallback:(data:string)=>void){
   requestResponse(request,"tags/writetag",responseCallback, errorCallback);
}

gethistorydata(request:historydatarequest, 
   onnextCallback:(data:string)=>void,
   errorCallback:(data:string)=>void){
      requestResponse(request, "tags/historydata", onnextCallback, errorCallback)
   }
   gethistorydatabase(request:historydatabaserequest, 
      onnextCallback:(data:string)=>void,
      errorCallback:(data:string)=>void){
         requestResponse(request, "tags/historydatabase", onnextCallback, errorCallback)
      }
   gethistorydbtable(request:historydbtablerequest, 
         onnextCallback:(data:string)=>void,
         errorCallback:(data:string)=>void){
            requestResponse(request, "tags/historydbtable", onnextCallback, errorCallback)
         }
   geteventsdata(request:eventsdatarequest, 
      onnextCallback:(data:string)=>void,
      errorCallback:(data:string)=>void){
         //console.log("eventsdatarequest=", request)
         requestResponse(request, "tags/eventsdata", onnextCallback, errorCallback)
      }
   getCardsToSave(request:cardsrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void) {
         requestResponse(request,'cards/save', responseCallback,errorCallback); 
       }
   getCards(request:cardsrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void){
         requestResponse(request,'cards/get', responseCallback,errorCallback);
       }
}

export default new TagsService;
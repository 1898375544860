import { SVGObject } from "../svgobject";

export class SVGTank extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.2559" y1="0" x2="56.2559" y2="6.916">
            <stop offset="0" style="stop-color:#C8C8C8" />
            <stop offset="0.29" style="stop-color:#B1B1B1" />
            <stop offset="0.91" style="stop-color:#757575" />
            <stop offset="1" style="stop-color:#6C6C6C" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M112.49,6.916c0,0,0.01-4.947-6.965-6.916c0,0-86.692,0-98.55,0C1.25,0,0.022,6.916,0.022,6.916  H112.49z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="56.2495" y1="105.4141" x2="56.2495" y2="112.5">
            <stop offset="0" style="stop-color:#C8C8C8" />
            <stop offset="0.29" style="stop-color:#B1B1B1" />
            <stop offset="0.91" style="stop-color:#757575" />
            <stop offset="1" style="stop-color:#6C6C6C" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M0,105.414c0,0-0.122,2.083,2.442,4.648c1.802,1.803,4.533,2.438,4.533,2.438H99.45h6.075  c0,0,2.035,0.092,4.757-2.631c1.832-1.833,2.218-4.344,2.218-4.344v-0.111H0z" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="56" x2="112.5" y2="56">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M0,6.475h112.5v99.05H0V6.975" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_", [0.0, 0.43, 143.0,
											0.29,0.62,97.0,
											0.91,0.91,0.0,
											1.0,0.84,0.0]);
		this.fillGradient(this.gradients,"SVGID_2_", [0.0, 0.43, 143.0,
				0.29,0.62,97.0,
				0.91,0.91,0.0,
				1.0,0.84,0.0]);
		
		this.fillGradient(this.gradients,"SVGID_3_", [0.0, 0.44, 0.0,
				0.45,0.16,214.0,
				0.55,0.22,177.0,
				0.76,0.69,79.0,
				0.77,0.72,75.0,
				1.0,0.44,0.0]);
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPeople7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 28.152 112.5" enable-background="new 0 0 28.152 112.5" xml:space="preserve">
        <g id="Group_HeelsBottom">
            <path fill="#000033" stroke="#4C4C4C" stroke-width="0.25" d="M9.797,110.133l-0.338-3.604l5.181-0.788l-0.563,4.616L9.797,110.133   z" />
            <path fill="#000033" stroke="#4C4C4C" stroke-width="0.25" d="M13.626,111.822l-0.338-3.604l5.18-0.789l-0.563,4.617   L13.626,111.822z" />
        </g>
        <g id="Group_Neck">
            <path fill="#98B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M18.017,18.02l-0.563-2.59h-6.981L9.91,17.907L18.017,18.02z" />
        </g>
        <g id="Group_Layer_2">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M22.297,18.92l-5.293-1.689l-1.126-2.365h-4.054l-1.126,2.928   l-5.969,2.252" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M22.747,26.24l0.788,10.473l0.338,7.32l1.352,9.909l-0.563,1.802   l-0.563,3.604v4.729l2.928-4.166l0.563-5.518l0.451-10.923l-0.451-8.108l-0.338-7.545l-2.139-6.981l-2.815-1.915" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M13.513,61.6l0.563,1.238l0.676,8.445L13.626,81.98l0.112,8.445   l0.901,13.738l-0.901,1.014l1.014,5.293l2.027-0.225l1.688-4.73l-0.563-1.125l1.351-15.766l0.901-4.617l0.225-3.379l3.717-15.314   v-7.77" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M5.149,55.526l-0.757,10.69l2.365,15.202l1.914,7.207l1.014,7.545   l0.338,7.77v1.352l1.352,4.279l2.702-1.352l-0.676-3.153l0.45-1.126l0.113-3.604l-0.113-6.981l-0.225-4.054l-0.112-8.221V61.6" />
        </g>
        <g id="Group_RightArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M4.729,20.046l-2.364,4.054l-1.014,5.18v7.32l-0.676,5.63   L0.338,55.743l0.45,3.829l1.127,3.378l2.59,1.802l-0.113-4.279l-0.45-4.617l1.576-14.864l0.901-6.193l0.563-7.32" />
        </g>
        <g id="Group_UpperBody">
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M6.982,44.82v-3.829l-0.338-3.829l-0.563-1.915l0.112,1.239   l-0.45,4.054L4.279,54.73l-1.464-0.226l-2.702,1.239L0,43.019l0.788-10.698l0.901-5.743l1.126-4.167l1.914-2.815l5.855-1.802   l3.717,3.604l0.112-1.126l3.04-2.928l6.193,1.689l1.689,1.802l2.14,6.981l0.226,6.531l0.45,9.234l-0.338,10.248l-0.676-0.451   l-2.14,0.338l-1.352-9.684l-0.225-7.545l-0.112-2.815l-1.127,3.266l0.113,5.855l0.45,1.013l-0.563,0.789L6.982,44.82z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M14.302,22.862v-3.829l3.152-3.716l0.563,2.59L14.302,22.862z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M14.076,22.862V18.92l-3.491-3.491l-0.563,2.477L14.076,22.862z" />
            <path fill="#E5E5E5" d="M24.436,48.762l-0.226-5.856l2.14-0.675l-1.914-1.689l-0.563-5.292l0.901-1.351l-1.352-4.392l-0.226-2.928   l-0.338,5.067l-1.688,2.14l-4.167,0.113l4.955,3.266l0.9-1.915l0.451-2.14l0.225,3.491l0.226,7.319L24.436,48.762z" />
            <path fill="#E5E5E5" d="M4.505,49.55v-6.418L2.59,42.681l2.027-0.563l0.788-3.829l-0.338-2.815l0.563-2.139l0.112-4.279   l0.788,3.378l5.631,1.576l-5.067,1.464v3.604l-0.901-3.941l-0.225,0.225L6.081,36.6l-0.788,6.869L4.505,49.55z" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M14.188,45.383l0.451-0.338l-0.226-2.252l0.45-15.202l-0.563-5.292" />
            <path fill="#FFFF99" stroke="#4C4C4C" stroke-width="0.25" d="M4.167,54.955l-2.252,0.901l-0.676,1.351l-0.563,2.252l0.112,1.352   l0.676,0.113l-0.338-1.127l0.338-1.914l0.563-1.351l2.14-1.126V54.955z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M4.279,54.73l0.226-3.153l-2.253-0.225L0.113,52.59v3.266l2.59-1.464   L4.279,54.73z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M27.814,53.829l0.112-2.815l-1.464-0.338l-1.802,0.338l0.338,2.703   l2.14-0.338L27.814,53.829z" />
        </g>
        <g id="Group_NeckChain">
            <path fill="#FFFF99" d="M18.355,17.457l0.9,0.338v3.829l-0.788,3.041l-1.464,2.252l-1.914,1.239h-1.914l-1.915-1.126l-1.464-2.365   l-0.788-3.041v-3.378l0.901-0.45v3.378l0.675,2.478l1.127,1.915l1.576,1.013h1.689l1.576-1.013l1.126-1.915l0.676-2.59V17.457z" />
        </g>
        <g id="Group_Hair_Back">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M20.495,9.124l-0.901,8.558l-0.788-0.675l-0.226,0.675h-1.013l-1.352-1.239   l-0.226-1.238l2.253-4.729L20.495,9.124z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M7.432,9.124l1.126,9.121l0.225-0.675l0.226,0.675H9.91v-1.238l0.563,1.238   l2.365-5.63L7.432,9.124z" />
        </g>
        <g id="Group_Face">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M18.693,11.038H9.346l-1.238-0.901L7.432,9.011V7.885l0.676-1.126   h11.823l0.563,1.126v1.126l-0.563,1.126L18.693,11.038z" />
            <ellipse fill="#996633" stroke="#4C4C4C" stroke-width="0.25" cx="13.964" cy="8.335" rx="5.63" ry="7.32" />
            <path fill="#663333" d="M13.85,10.925l1.689,0.45h-3.266L13.85,10.925z" />
            <path fill="#B27F7F" d="M12.274,12.952l-0.45-0.45h4.166l-0.45,0.45H12.274z" />
            <path d="M17.342,6.534l-2.815-0.451l0.226,0.451l2.815,0.338L17.342,6.534z" />
            <path d="M10.472,6.534l2.703-0.451L12.95,6.534l-2.702,0.338L10.472,6.534z" />
            <path d="M11.598,8.786h0.45l0.226-0.563l-0.226-0.788h-0.45l-0.225,0.788L11.598,8.786z" />
            <path d="M15.765,8.786h0.45l0.112-0.563l-0.112-0.788h-0.45L15.54,8.223L15.765,8.786z" />
        </g>
        <g id="Group_Hair_front">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M8.558,7.998l-0.45-1.464L7.432,7.998L8.108,2.93l1.576-1.915   l1.914-0.901l2.252-0.112l2.253,0.112l1.914,0.901l1.576,1.915l0.901,5.067l-0.676-1.464l-0.45,1.464l-0.901-3.716l-1.126-1.802   l0.226,2.252h-7.095l0.226-2.252L9.346,4.281L8.558,7.998z" />
        </g>
        <g id="Group_EarRings">
            <path fill="#FFFF99" d="M8.108,9.912L7.883,11.15l0.226,1.464l0.225-1.464L8.108,9.912z" />
            <path fill="#FFFF99" d="M19.819,9.912l-0.226,1.239l0.226,1.464l0.226-1.464L19.819,9.912z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#000066" stroke="#4C4C4C" stroke-width="0.25" d="M4.391,79.616L4.279,64.752l0.676-11.599l1.802-6.869l4.166-0.45   l7.095,0.45h4.729l1.352,10.36l0.112,9.234l-3.153,14.414L13.4,80.518l-0.676-1.239L4.391,79.616z" />
            <path fill="#00004C" d="M13.626,79.842l-0.112-12.049L12.5,78.94L13.626,79.842z" />
        </g>
        <g id="Group_Belt">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M6.757,46.847v-2.365h15.765v2.365H6.757z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M12.724,44.145l2.815,0.113v2.59H12.5L12.724,44.145z" />
            <path d="M13.062,44.482l2.027,0.113v1.802h-2.14L13.062,44.482z" />
        </g>
        <g id="Group_HeelsFront">
            <path fill="#000066" stroke="#4C4C4C" stroke-width="0.25" d="M10.585,110.695l-0.563-1.351l-0.563-2.702l0.789-1.577l0.9,3.266   l0.9,0.901l1.014-0.676v-3.491l0.901-0.337l-0.338,0.563v1.126l0.338,2.815l-1.014,1.352L10.585,110.695z" />
            <path fill="#000066" stroke="#4C4C4C" stroke-width="0.25" d="M14.414,112.498l-0.563-1.465l-0.563-2.702l1.014-2.14l0.676,3.941   l0.9,0.789l1.014-0.676l0.45-2.816l1.126-1.576l0.226,1.127l-0.45,1.238l-0.451,2.703l-1.013,1.351L14.414,112.498z" />
        </g>
        <g id="Group_Eye_Glasses">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M8.22,7.322l5.518,0.225l5.969-0.112" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.302,7.547l0.563,1.802l2.365-0.225l0.225-1.239" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M9.91,7.772l0.338,1.351l2.814,0.113L13.4,7.547" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFood19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 102.593 112.5" enable-background="new 0 0 102.593 112.5" xml:space="preserve">
        <g id="Group_Outlet">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="53.9351" y1="73.7969" x2="66.519" y2="73.7969" gradientTransform="matrix(0 1 -1 0 87.7183 25.897)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M15.644,86.203c0-2.795-0.392-5.209-0.96-6.371c-0.508,0-1.016,0-1.524,0   c-0.572,1.162-0.961,3.576-0.961,6.371c0,2.674,0.357,5.004,0.889,6.213c0.531,0,1.136,0,1.667,0   C15.285,91.207,15.644,88.877,15.644,86.203z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="53.9761" y1="61.4199" x2="66.6147" y2="61.4199" gradientTransform="matrix(0 1 -1 0 87.7183 25.897)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="13.633" y="79.873" fill="url(#SVGID_2_)" width="25.331" height="12.639" />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="36.269" y1="72.6299" x2="48.853" y2="72.6299" gradientTransform="matrix(0 1 -1 0 87.7183 25.897)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M16.811,68.537c0-2.795-0.392-5.209-0.96-6.371c-0.508,0-1.016,0-1.524,0   c-0.572,1.162-0.961,3.576-0.961,6.371c0,2.674,0.357,5.004,0.889,6.213c0.531,0,1.136,0,1.667,0   C16.452,73.541,16.811,71.211,16.811,68.537z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="36.3101" y1="60.2529" x2="48.9487" y2="60.2529" gradientTransform="matrix(0 1 -1 0 87.7183 25.897)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="14.8" y="62.207" fill="url(#SVGID_4_)" width="25.331" height="12.639" />
        </g>
        <g id="Group_Inlet">
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-4.1323" y1="125.4639" x2="8.4517" y2="125.4639" gradientTransform="matrix(0 -1 1 0 -26.5562 88.4468)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M97.185,86.208c0,2.795,0.392,5.209,0.961,6.371c0.508,0,1.016,0,1.523,0   c0.572-1.162,0.961-3.576,0.961-6.371c0-2.674-0.357-5.004-0.889-6.213c-0.531,0-1.137,0-1.667,0   C97.544,81.204,97.185,83.534,97.185,86.208z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-4.1909" y1="113.0879" x2="8.4478" y2="113.0879" gradientTransform="matrix(0 -1 1 0 -26.5562 88.4468)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="73.866" y="79.999" fill="url(#SVGID_6_)" width="25.331" height="12.639" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="13.9146" y1="127.4004" x2="22.9028" y2="127.4004" gradientTransform="matrix(0 -1 1 0 -26.2227 86.7827)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M99.49,68.318c0,1.996,0.384,3.721,0.941,4.55c0.498,0,0.996,0,1.494,0   c0.561-0.829,0.942-2.554,0.942-4.55c0-1.911-0.351-3.574-0.872-4.438c-0.521,0-1.113,0-1.634,0   C99.842,64.744,99.49,66.407,99.49,68.318z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="13.9429" y1="115.2666" x2="22.9712" y2="115.2666" gradientTransform="matrix(0 -1 1 0 -26.2227 86.7827)">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="76.627" y="63.812" fill="url(#SVGID_8_)" width="24.834" height="9.028" />
        </g>
        <g id="Group_Blue_Pipe2">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="20.0508" y1="3.2534" x2="28.8892" y2="3.2534">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M24.524,1.597c-1.962,0-3.659,0.377-4.473,0.924c0,0.488,0,0.977,0,1.465   c0.814,0.548,2.511,0.924,4.473,0.924c1.879,0,3.515-0.345,4.365-0.855c0-0.51,0-1.092,0-1.602   C28.039,1.943,26.403,1.597,24.524,1.597z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="20.0044" y1="27.4194" x2="28.8164" y2="27.4194">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M24.464,25.763c-1.957,0-3.648,0.377-4.46,0.924c0,0.488,0,0.977,0,1.465   c0.812,0.549,2.503,0.924,4.46,0.924c1.874,0,3.504-0.344,4.352-0.855c0-0.51,0-1.092,0-1.602   C27.969,26.109,26.338,25.763,24.464,25.763z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="20.0313" y1="15.5225" x2="28.8828" y2="15.5225">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <rect x="20.031" y="3.348" fill="url(#SVGID_11_)" width="8.852" height="24.349" />
        </g>
        <g id="Group_Blue_Pipe1">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="8.8843" y1="3.4199" x2="17.7227" y2="3.4199">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M13.357,1.764c-1.962,0-3.659,0.377-4.473,0.924c0,0.488,0,0.977,0,1.465   c0.814,0.548,2.511,0.924,4.473,0.924c1.879,0,3.515-0.345,4.365-0.855c0-0.51,0-1.092,0-1.602   C16.872,2.109,15.237,1.764,13.357,1.764z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="8.8379" y1="27.5859" x2="17.6499" y2="27.5859">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M13.298,25.93c-1.957,0-3.648,0.377-4.46,0.924c0,0.488,0,0.977,0,1.465   c0.812,0.549,2.503,0.924,4.46,0.924c1.874,0,3.504-0.344,4.352-0.855c0-0.51,0-1.092,0-1.602   C16.802,26.275,15.171,25.93,13.298,25.93z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="8.8647" y1="15.689" x2="17.7163" y2="15.689">
                <stop offset="0" style="stop-color:#3B6ED4" />
                <stop offset="0.5" style="stop-color:#6699FF" />
                <stop offset="1" style="stop-color:#2C5FC5" />
            </linearGradient>
            <rect x="8.865" y="3.515" fill="url(#SVGID_14_)" width="8.852" height="24.349" />
        </g>
        <g id="Group_Nitrogetn_SupplyPipe">
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="48.9878" y1="4.6733" x2="51.6904" y2="4.6733">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M48.988,0h2.703v9.347h-2.703V0z" />
        </g>
        <g id="Group_Nitrogetn_Supply">
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="46.9609" y1="12.3872" x2="53.7183" y2="12.3872">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M46.961,9.347h6.757v6.081h-6.757V9.347z" />
        </g>
        <g id="Group_Indicator_1">
            <path fill="#FFCCAB" stroke="#4C4C4C" stroke-width="0.25" d="M19.934,39.302h5.631v1.126h-5.631V39.302z" />
            <path fill="#FFCCAB" stroke="#4C4C4C" stroke-width="0.25" d="M8.334,38.626h5.969v1.352H8.334V38.626z" />
        </g>
        <g id="Group_Support">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M26.916,106.193l7.432-6.756h42.567l8.108,6.756H26.916z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M26.916,106.193h58.108v6.307H71.96v-1.802H39.979v1.802H26.916   V106.193z" />
            <path fill="#404040" d="M28.042,107.545h10.811v3.604H28.042V107.545z" />
            <path fill="#404040" d="M73.087,107.545h10.811v3.604H73.087V107.545z" />
            <path fill="#404040" d="M39.979,106.869H71.96v3.153H39.979V106.869z" />
        </g>
        <g id="Group_Black_Box">
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M13.177,34.347h4.392v6.757h-4.392V34.347z" />
            <path fill="#404040" d="M36.826,25.563h52.703v66.892H77.591v11.938H36.826V25.563z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M91.33,18.581h-2.477v-12.5h2.477V18.581z" />
            <path fill="#4D4D4D" stroke="#4C4C4C" stroke-width="0.25" d="M79.393,12.95v1.352H35.7V12.95H79.393z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M17.569,48.761l2.365,1.126h3.153" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M30.069,42.455l0.451-4.505l3.829-3.604" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M16.33,39.302v7.433l1.239,2.027" />
        </g>
        <g id="Group_Digi_Monitor">
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="90.1489" y1="35.5293" x2="68.8653" y2="14.2457">
                <stop offset="0" style="stop-color:#404040" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M69.484,13.626h20.045v22.522H69.484V13.626" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="96.2827" y1="80.7217" x2="63.8552" y2="53.5118">
                <stop offset="0" style="stop-color:#404040" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M66.33,50.563h27.479v33.108H66.33V50.563z" />
        </g>
        <g id="Group_Support_Pipes">
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="23.0869" y1="55.9482" x2="25.5645" y2="55.9482">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M23.087,82.996V28.9h2.478v54.096H23.087" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="11.8257" y1="34.1006" x2="14.3032" y2="34.1006">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M11.826,39.302V28.9h2.478v10.402H11.826" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="33.8979" y1="54.3359" x2="36.375" y2="54.3359">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M33.898,5.631h2.477v97.41h-2.477V5.631" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="62.5015" y1="54.3359" x2="64.979" y2="54.3359">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M62.501,5.631h2.478v97.41h-2.478V5.631" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="43.5264" y1="5.6309" x2="43.5264" y2="3.0405">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M87.05,3.041v2.59H0.001v-2.59H87.05" />
        </g>
        <g id="Group_Level_Indicators">
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M26.916,61.148l1.802-3.152v-9.911h0.675v9.911l1.802,3.152h2.703   v2.027h-2.027l-1.351,2.478h-2.928l-1.351-2.478H11.15v-2.027h5.631v-4.955h0.788v4.955H26.916z" />
        </g>
        <g id="Group_Level_Watch">
            <radialGradient id="SVGID_24_" cx="29.0552" cy="45.2705" r="2.8152" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <circle fill="url(#SVGID_24_)" cx="29.055" cy="45.27" r="2.815" />
            <radialGradient id="SVGID_25_" cx="17.231" cy="54.6172" r="1.5767" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#404040" />
            </radialGradient>
            <circle fill="url(#SVGID_25_)" cx="17.231" cy="54.617" r="1.577" />
        </g>
        <g id="Group_Lever">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M78.267,50.563h2.478V36.148h-2.478V50.563z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M78.267,103.041h2.478v-19.37h-2.478V103.041z" />
            <path fill="#404040" d="M70.609,56.194h10.136v9.459H70.609V56.194z" />
            <path fill="#404040" d="M73.763,69.031h3.828v3.379h-3.828V69.031z" />
            <path fill="#404040" d="M75.79,16.779h5.631v4.279H75.79V16.779z" />
        </g>
        <g id="Group_Nitrogen_Generator">
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="41.4888" y1="19.9111" x2="59.1646" y2="19.9111">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M50.435,15.25c-3.924,0-7.317,1.061-8.946,2.6c0,1.374,0,2.749,0,4.122   c1.629,1.542,5.022,2.6,8.946,2.6c3.759,0,7.03-0.97,8.729-2.408c0-1.435,0-3.072,0-4.507C57.465,16.223,54.194,15.25,50.435,15.25   z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="41.396" y1="87.9229" x2="59.02" y2="87.9229">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M50.316,83.262c-3.913,0-7.296,1.061-8.92,2.6c0,1.375,0,2.748,0,4.122   c1.624,1.546,5.007,2.601,8.92,2.601c3.747,0,7.009-0.968,8.704-2.407c0-1.436,0-3.073,0-4.508   C57.325,84.234,54.063,83.262,50.316,83.262z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="41.4497" y1="54.4404" x2="59.1528" y2="54.4404">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="41.45" y="20.178" fill="url(#SVGID_28_)" width="17.703" height="68.525" />
            <path fill="#E5E5E5" d="M46.285,36.148h8.108v8.108h-8.108V36.148z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M46.285,44.257v-8.108h8.108" />
            <path fill="none" stroke="#999999" stroke-width="0.25" d="M46.285,44.257h8.108v-8.108" />
            <path fill="#B7B7C4" stroke="#4C4C4C" stroke-width="0.25" d="M47.637,81.193h5.63v24.324h-5.63V81.193z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.9895833333333334, 0.0, 0.5, 0.671875, 83.0, 1.0, 0.875, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.5, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.5, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.609375, 99.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.40625, 151.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.5, 0.0, 3.0, 0.5, 0.0, 4.0, 0.5, 0.0]);
		this.fillGradient(this.fills,"Group_Black_Box",[0.0, 0.5989583333333334, 0.0, 1.0, 0.5, 0.0, 2.0, 0.5989583333333334, 0.0, 3.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Level_Indicators",[0.0, 0.23958333333333334, 193.0]);
		this.fillGradient(this.fills,"Group_Lever",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.5, 0.0, 3.0, 0.5, 0.0, 4.0, 0.5, 0.0]);
		this.fillGradient(this.fills,"Group_Nitrogen_Generator",[0.0, 0.20833333333333334, 201.0, 1.0, 0.5364583333333334, 117.0]);


        super.drawObject();
    }
}
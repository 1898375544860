import { SVGObject } from "../svgobject";

export class SVGPipe13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 93.578 112.5" enable-background="new 0 0 93.578 112.5" xml:space="preserve">
        <g id="Group_Flange">
            <radialGradient id="SVGID_1_" cx="46.7332" cy="46.7344" r="46.7327" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.17" style="stop-color:#DDDDDD" />
                <stop offset="0.5" style="stop-color:#B8B8B8" />
                <stop offset="0.94" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#737373" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" cx="46.733" cy="46.734" r="46.733" />
        </g>
        <g id="Group_Bolt">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="40.4714" y1="75.9268" x2="53.1082" y2="88.5635">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_2_)" cx="46.789" cy="82.245" r="8.935" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="41.9661" y1="77.4219" x2="51.6135" y2="87.0693">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_3_)" cx="46.789" cy="82.245" r="6.822" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="5.2859" y1="40.5132" x2="17.9227" y2="53.15">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_4_)" cx="11.604" cy="46.831" r="8.935" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="15.2229" y1="15.2876" x2="27.859" y2="27.9237">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_5_)" cx="21.54" cy="21.605" r="8.935" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="40.1887" y1="5.2432" x2="52.8255" y2="17.8799">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_6_)" cx="46.507" cy="11.561" r="8.935" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="65.6819" y1="15.1792" x2="78.3187" y2="27.816">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_7_)" cx="72" cy="21.498" r="8.935" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="75.7307" y1="40.5132" x2="88.3675" y2="53.15">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_8_)" cx="82.048" cy="46.831" r="8.935" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="65.9905" y1="65.9912" x2="78.6266" y2="78.6273">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_9_)" cx="72.308" cy="72.309" r="8.935" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="15.2849" y1="65.9912" x2="27.9217" y2="78.628">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <circle fill="url(#SVGID_10_)" cx="21.603" cy="72.309" r="8.935" />
        </g>
        <g id="Group_Layer_4">
            <radialGradient id="SVGID_11_" cx="11.6038" cy="46.8311" r="6.8218" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_11_)" cx="11.604" cy="46.831" r="6.822" />
            <radialGradient id="SVGID_12_" cx="21.5403" cy="21.6055" r="6.8218" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_12_)" cx="21.54" cy="21.605" r="6.822" />
            <radialGradient id="SVGID_13_" cx="46.5066" cy="11.561" r="6.8218" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_13_)" cx="46.507" cy="11.561" r="6.822" />
            <radialGradient id="SVGID_14_" cx="72.0002" cy="21.4976" r="6.8215" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_14_)" cx="72" cy="21.498" r="6.822" />
            <radialGradient id="SVGID_15_" cx="82.0481" cy="46.8311" r="6.8215" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_15_)" cx="82.048" cy="46.831" r="6.822" />
            <radialGradient id="SVGID_16_" cx="72.3079" cy="72.3086" r="6.8218" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle fill="url(#SVGID_16_)" cx="72.308" cy="72.309" r="6.822" />
            <radialGradient id="Group_x5F_Bolt_x5F_Middle_1_" cx="21.6028" cy="72.3086" r="6.8218" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.7" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#808080" />
            </radialGradient>
            <circle id="Group_x5F_Bolt_x5F_Middle" fill="url(#Group_x5F_Bolt_x5F_Middle_1_)" cx="21.603" cy="72.309" r="6.822" />
        </g>
        <g id="Group_Pipe">
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="23.3098" y1="68.1299" x2="69.8176" y2="68.1299">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M69.699,46.734c-0.121-12.708-10.455-22.972-23.191-22.972c-12.737,0-23.071,10.265-23.192,22.972   H23.31v65.764h46.508V46.734H69.699z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.16145833333333334, 214.0, 0.17, 0.2708333333333333, 185.0, 0.5, 0.5625, 111.0, 0.94, 0.9635416666666666, 0.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.25, 191.0, 0.7, 0.25, 191.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";


export class Motor extends TwoStateObject{
  

    public drawObject():void{
      console.log("MOTOR")
		super.initObject();

    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            /*const ovalLeft = new Konva.Ellipse({
              x: this.width/10,
              y: this.height/ 2,
              radiusX: this.width/10,
              radiusY: this.height/2,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth: 1,
            });
            if(this.type3d==0){
              ovalLeft.fillLinearGradientStartPoint({ x: 0, y: -this.height/2});
              ovalLeft.fillLinearGradientEndPoint({ x: 0, y: this.height/2});
              ovalLeft.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              ovalLeft.fill(fillcolor2)
            }
            this.node.add(ovalLeft)

            const ovalRight = new Konva.Ellipse({
              x: this.width*7/10,
              y: this.height/ 2,
              radiusX: this.width/20,
              radiusY: this.height/2,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth: 1,
            });
            if(this.type3d==0){
              ovalRight.fillLinearGradientStartPoint({ x: 0, y: -this.height/2});
              ovalRight.fillLinearGradientEndPoint({ x: 0, y: this.height/2});
              ovalRight.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,fillcolor2,
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              ovalRight.fill(fillcolor2)
            }
            this.node.add(ovalRight)*/
            
            const motor = new Konva.Rect({
              x: 0,
              y:0,
              width: this.width,
              height: this.height*0.9,
              cornerRadius: this.height/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth:1
            })
            if(this.type3d==0){
              motor.fillLinearGradientStartPoint({ x: 0, y: 0});
              motor.fillLinearGradientEndPoint({ x: 0, y: this.height*0.9});
              motor.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              motor.fill(fillcolor2)
            }
            this.node.add(motor)

            const str1 = new Konva.Rect({
                x: this.width/5,
                y: this.height*44/100,
                width: this.width*4/6,
                height: this.height*2/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str2 = new Konva.Rect({
                x: this.width/5,
                y: this.height*36/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str3 = new Konva.Rect({
                x: this.width/5,
                y: this.height*28/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str4 = new Konva.Rect({
                x: this.width/5,
                y: this.height*20/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str5 = new Konva.Rect({
                x: this.width/5,
                y: this.height*12/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str6 = new Konva.Rect({
                x: this.width/5,
                y: this.height*4/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str7 = new Konva.Rect({
                x: this.width/5,
                y: this.height*50/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str8 = new Konva.Rect({
                x: this.width/5,
                y: this.height*58/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str9 = new Konva.Rect({
                x: this.width/5,
                y: this.height*66/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str10 = new Konva.Rect({
                x: this.width/5,
                y: this.height*74/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? ColorUtils.darkColor(fillcolor2, 0.5) : ColorUtils.darkColor(fillcolor2, 0.75)
              })
              const str11 = new Konva.Rect({
                x: this.width/5,
                y: this.height*82/100,
                width: this.width*4/6,
                height: this.height*4/100,
                fill: this.type3d==0? ColorUtils.darkColor(fillcolor2, 0.5) : ColorUtils.darkColor(fillcolor2, 0.75)
              })
            this.node.add(str1, str2, str2, str3, str4, str5, str6, str7, str8, str9, str10, str11)

            const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height*0.6);
              gradient.addColorStop(0,'white')
              gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

        const box = new Konva.Rect({
                x: this.width/2,
                y:this.height*0.3,
                width: this.width/3,
                height: this.height*0.6,
                fill: fillcolor2,
                stroke: this.type3d==0? gradient : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth: this.type3d==0? 1 : this.width/120
              })
              this.node.add(box)

        const rad = this.height/40;
        const r1 = new Konva.Circle({
            x: this.width*55/100,
            y: this.height*37/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.5)
        })
        const r2 = new Konva.Circle({
            x: this.width*78/100,
            y: this.height*37/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.5)
        })
        const r3 = new Konva.Circle({
            x: this.width*55/100,
            y: this.height*83/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.5)
        })
        const r4 = new Konva.Circle({
            x: this.width*78/100,
            y: this.height*83/100,
            radius: rad,
            fill: ColorUtils.darkColor(fillcolor2, 0.5)
        })
        this.node.add(r1, r2, r3, r4)

        const leg = new Konva.Line({
            points: [this.width/5, this.height*90/100,
                    this.width*16/100, this.height,
                    this.width*89/100, this.height,
                    this.width*85/100, this.height*90/100],
            closed: true,
            fill: fillcolor2,
            stroke: ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1
        })
        this.node.add(leg) 

}
}
import { SVGObject } from "../svgobject";

export class SVGWater15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 75.11" enable-background="new 0 0 112.5 75.11" xml:space="preserve">
        <g id="Group_Tank">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1.0928" y1="47.3579" x2="110.8428" y2="47.3579">
                <stop offset="0.01" style="stop-color:#003333" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#003333" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M110.843,20.382L1.093,19.555v40.25h0c0,2.595,0,5.19,0,7.785c10.111,4.486,31.18,7.57,55.547,7.57   c23.334,0,43.639-2.828,54.202-7.007c0-2.646,0-5.526,0-8.349h0.001V20.382z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-0.4297" y1="22.6919" x2="112.916" y2="22.6919">
                <stop offset="0.01" style="stop-color:#669999" />
                <stop offset="0.49" style="stop-color:#99FFFF" />
                <stop offset="1" style="stop-color:#669999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#009F46" stroke-width="0.3273" d="M-0.43,19.579c10.397,3.689,32.55,6.227,57.606,6.227   c23.997,0,44.882-2.326,55.739-5.764L-0.43,19.579z" />
        </g>
        <g id="Group_Dome">
            <g>
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="1.0928" y1="11.9985" x2="110.584" y2="11.9985">
                    <stop offset="0.01" style="stop-color:#595959" />
                    <stop offset="0.5" style="stop-color:#E0E0E0" />
                    <stop offset="1" style="stop-color:#595959" />
                </linearGradient>
                <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.3475" d="M110.584,19.932C110.584,8.583,86.214,0,55.849,0    C25.482,0,1.093,8.23,1.093,19.579c0,0,29.458,4.798,55.313,4.395C84.47,23.535,103.11,22.008,110.584,19.932z" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M87.976,4.617l6.756,4.504l4.729,5.405l2.478,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M60.948,0.225l9.46,1.577l8.107,2.815l6.757,4.504l4.955,5.405    l2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M51.715,0.225l9.233,1.577l8.334,2.815l6.756,4.504l4.73,5.405    l2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M44.958,0.45l6.531,1.352l8.333,2.815l6.756,4.504l4.73,5.405    l2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M38.652,1.126l3.604,0.676l8.107,2.815l6.757,4.504l4.955,5.405    l2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M32.796,1.802l8.333,2.815l6.757,4.504l4.729,5.405l2.478,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M27.166,3.041l4.504,1.577l6.756,4.504l4.73,5.405l2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M22.211,4.617l6.756,4.504l4.955,5.405l2.478,5.856v2.703" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M19.734,9.121l4.729,5.405l2.478,5.856v2.252" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M10.274,9.121l4.73,5.405l2.477,5.856v1.802" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M5.095,12.725l3.153,7.658" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M22.211,4.617l-6.757,4.504l-4.729,5.405l-2.478,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M49.012,0.225l-9.234,1.577l-8.333,2.815l-6.531,4.504l-4.955,5.405    l-2.478,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M58.472,0.225l-9.234,1.577l-8.333,2.815l-6.756,4.504l-4.73,5.405    l-2.477,5.856" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M65.228,0.676l-6.531,1.126l-8.333,2.815l-6.756,4.504l-4.729,5.405    L36.4,20.382" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M67.931,1.802l-8.333,2.815l-6.532,4.504l-4.955,5.405l-2.478,5.856    v3.153" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M77.39,1.802l-8.333,2.815L62.3,9.121l-4.729,5.405l-2.477,5.856v3.153    " />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M83.245,3.041l-4.729,1.577l-6.757,4.504l-4.729,5.405l-2.479,5.856    v3.153" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M87.75,4.617l-6.532,4.504l-4.954,5.405l-2.478,5.856v2.703" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M90.452,9.121l-4.729,5.405l-2.478,5.856v2.252" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M99.911,9.121l-4.729,5.405l-2.479,5.856v1.577" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M105.092,13.063l-3.153,7.32v0.676" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M71.984,1.126l-4.054,0.451l-4.729,0.225h-5.405h-5.405h-5.405    l-4.729-0.225l-4.054-0.451" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M86.849,4.392l-4.504,0.676l-6.307,0.676l-7.883,0.225l-8.558,0.225    h-9.009L42.03,5.968l-7.882-0.225l-6.306-0.676l-4.955-0.676" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M99.461,8.446l-2.928,0.901l-5.855,0.901l-8.333,0.675L72.21,11.374    l-11.262,0.451H49.237l-11.261-0.451l-10.135-0.451l-8.333-0.675l-6.081-0.901L10.5,8.446" />
                <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M107.794,15.653l-4.729,1.577l-6.757,1.126l-9.684,0.675l-11.712,0.676    L61.85,19.932H48.336l-13.063-0.225l-11.937-0.676l-9.459-0.675l-6.981-1.126l-4.279-1.352" />
            </g>
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.2604166666666667, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.9375, 15.0, 0.49, 0.2708333333333333, 185.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe15 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 56.254" enable-background="new 0 0 112.5 56.254" xml:space="preserve">

        <g id="Group_180_angle_1">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="68.0371" y1="51.2771" x2="68.0371" y2="2.926" gradientTransform="matrix(1 0 0 1 -12.3105 -2.9219)">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <rect x="54.263" y="0.004" fill="url(#SVGID_1_)" width="2.926" height="48.352" />
        <radialGradient id="SVGID_2_" cx="66.4805" cy="-181.7551" r="54.1772" gradientTransform="matrix(1 0 0 -1 -12.708 -129.0239)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#606061" />
            <stop offset="0.1" style="stop-color:#626263" />
            <stop offset="0.25" style="stop-color:#898A8A" />
            <stop offset="0.36" style="stop-color:#A8A8A8" />
            <stop offset="0.56" style="stop-color:#E0E0E0" />
            <stop offset="0.67" style="stop-color:#C3C3C3" />
            <stop offset="0.85" style="stop-color:#919192" />
            <stop offset="1" style="stop-color:#646566" />
        </radialGradient>
        <path fill="url(#SVGID_2_)" d="M56.218,0l1.619,48.247c-2.393,0-8.646-0.494-10.078,8.006H0.002C0.002,56.253-1.248,0,56.218,0z" />
        <radialGradient id="SVGID_3_" cx="49.1343" cy="-181.7551" r="54.1772" gradientTransform="matrix(-1 0 0 -1 107.8525 -129.0239)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#5D5E5E" />
            <stop offset="0.1" style="stop-color:#5F6060" />
            <stop offset="0.25" style="stop-color:#888888" />
            <stop offset="0.36" style="stop-color:#A8A8A8" />
            <stop offset="0.56" style="stop-color:#E0E0E0" />
            <stop offset="0.67" style="stop-color:#C3C3C3" />
            <stop offset="0.85" style="stop-color:#919192" />
            <stop offset="1" style="stop-color:#646566" />
        </radialGradient>
        <path fill="url(#SVGID_3_)" d="M56.273,0l-1.619,48.247c2.393,0,8.647-0.494,10.079,8.006h47.757C112.489,56.253,113.739,0,56.273,0  z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.75, 0.0, 0.1, 0.7604166666666666, 0.0, 0.25, 0.9322916666666666, 17.0, 0.36, 0.6875, 79.0, 0.56, 0.25, 191.0, 0.67, 0.4739583333333333, 133.0, 0.85, 0.8697916666666666, 33.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.1, 0.7395833333333334, 0.0, 0.25, 0.9375, 15.0, 0.36, 0.6875, 79.0, 0.56, 0.25, 191.0, 0.67, 0.4739583333333333, 133.0, 0.85, 0.8697916666666666, 33.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";


export class TimeTile extends Tile{
    tagname: string;
    text: string;
    path: string;

    public drawObject():void{
 
		super.initObject();
        if (!this.tagnames.includes(this.path))
        this.tagnames.push(this.path);

        const SEC_HOUR = 3600;
        const SEC_MINUTE = 60;  

        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
          const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
          const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
          if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }
        
        const size = this.width < this.height ? this.width : this.height;
        
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
          clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
          },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)

        const titleText = new Konva.Text({
            x: size*0.05,
            y: size*0.1-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.title,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(titleText)

        const text2 = new Konva.Text({
            x: size*0.05,
            y: this.height - size * 0.05 - 0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05*2,
            height: 0.06*size,
            text: this.text,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(text2)
    

        const descriptionText = new Konva.Text({
            x: size*0.05,
            y: size*.52-0.1*size,
            verticalAlign:"top",
            width: this.width-size*0.05*2,
            height: 0.1*size,
            text: this.description,
            fontSize: 0.1*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'right'
        });  
        group.add(descriptionText)

        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        const tag:tag = tagsModele.getTag(this.path)
        if(tag!=null && tag.tagvalue !=null){
            const duration = Math.trunc(Number(tag.tagvalue))
            
            hours =Math.trunc(duration/SEC_HOUR);
			minutes = Math.trunc(duration%SEC_HOUR/SEC_MINUTE);
			seconds = Math.trunc(duration%SEC_MINUTE);
            //console.log('duration', duration, hours, minutes, seconds )

            const unitText2Transparent = new Konva.Text({
                x: 0,
                y: 0,
                verticalAlign: "bottom",
                text: hours>0 ? "m" : "s",
                fontSize: 0.12*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left'
            });  

            const unitText2 = new Konva.Text({
                x: this.width-unitText2Transparent.width()-size*0.05,
                y: size*0.39-0.24*size,
                verticalAlign: "bottom",
                width: unitText2Transparent.width(),
                height: 0.24*size,
                text: hours>0 ? "m" : "s",
                fontSize: 0.12*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left'
            }); 
              
            const valueText2Transparent = new Konva.Text({
                x: 0,
                y: 0,
                verticalAlign: "bottom",
                text:  hours>0 ? minutes.toString() : seconds.toString(),
                fontSize: 0.24*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left',
                offsetY:-size*0.024
            }); 

            const valueText2 = new Konva.Text({
                x: this.width-unitText2Transparent.width()- valueText2Transparent.width()-size*0.05,
                y: size*0.39-0.24*size,
                verticalAlign: "bottom",
                height: 0.24*size,
                text:  hours>0 ? minutes.toString() : seconds.toString(),
                fontSize: 0.24*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left',
                offsetY:-size*0.024
            });

            const unitTextTransparent = new Konva.Text({
                x: 0,
                y: 0,
                verticalAlign: "bottom",
                text: hours>0? "h" : "m",
                fontSize: 0.12*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left'
            });  

            const unitText = new Konva.Text({
                x: this.width-unitText2Transparent.width()- valueText2Transparent.width()
                    -unitTextTransparent.width()-size*0.05,
                y: size*0.39-0.24*size,
                verticalAlign: "bottom",
                height: 0.24*size,
                text: hours>0? "h" : "m",
                fontSize: 0.12*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'left'
            });  

            const valueText = new Konva.Text({
                x: size*0.05,
                y: size*0.39-0.24*size,
                verticalAlign: "bottom",
                width: this.width-unitText2Transparent.width()- valueText2Transparent.width()
                -unitTextTransparent.width()-size*0.05*2,
                height: 0.24*size,
                text:  hours>0 ? hours.toString() : minutes.toString(),
                fontSize: 0.24*size,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                fill: ColorUtils.convertformat(textcolor),
                align: 'right',
                offsetY:-size*0.024
            }); 
            group.add(unitText, unitText2, valueText, valueText2)
        }
        this.node.add(group)     
    }
}
import { SVGObject } from "../svgobject";

export class SVGConveyor11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 28.043" enable-background="new 0 0 112.5 28.043" xml:space="preserve">
        <g id="Group_Connecting_Rods">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="84.3467" y1="24.5513" x2="84.3467" y2="21.0601">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M63.288,21.06v3.491h42.117V21.06H63.288z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="84.3467" y1="6.9839" x2="84.3467" y2="3.4927">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M63.288,3.493v3.491h42.117V3.493H63.288z" />
        </g>
        <g id="Group_Yellow_Stripe">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M105.405,28.042V0.002h7.095v28.04H105.405z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,28.042V0.002h7.094v28.04H56.194z" />
        </g>
        <g id="Group_Black_Stripe">
            <path d="M105.405,0.002v7.094l7.095-7.094H105.405z" />
            <path d="M112.5,6.871l-7.095,7.095v7.094l7.095-7.094V6.871z" />
            <path d="M112.5,21.06l-7.095,6.981h7.095V21.06z" />
            <path d="M56.194,0.002v7.094l7.094-7.094H56.194z" />
            <path d="M63.288,6.871l-7.094,7.095v7.094l7.094-7.094V6.871z" />
            <path d="M63.288,21.06l-7.094,6.981h7.094V21.06z" />
        </g>
        <g id="Group_Blue_Box">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="42.0542" y1="33.187" x2="28.1025" y2="-5.1449">
                <stop offset="0" style="stop-color:#596D8C" />
                <stop offset="0.38" style="stop-color:#5D7190" />
                <stop offset="0.58" style="stop-color:#6F83A2" />
                <stop offset="1" style="stop-color:#7387A6" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M13.964,28.042V0.002h42.23v28.04H13.964z" />
        </g>
        <g id="Group_Handle">
            <path fill="#263A59" d="M13.964,28.042V0.002H0v28.04H13.964z" />
        </g>
        <g id="Group_Metal_Pusher">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="14.4521" y1="25.9858" x2="0.6387" y2="2.0602">
                <stop offset="0" style="stop-color:#BFBFBF" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M12.838,1.128H2.252v25.789h10.585V1.128z" />
        </g>
        <g id="Group_Extensers">
            <path fill="none" stroke="#4C4C4C" d="M12.838,1.128H2.252v25.789h10.585V1.128" />
            <path fill="none" stroke="#FFFFFF" d="M2.252,26.917V1.128h10.585" />
            <path fill="none" stroke="#7387A6" stroke-width="0.2" d="M0,28.042V0.002h13.964" />
            <path fill="none" stroke="#BFD3F2" stroke-width="0.25" d="M13.964,28.042V0.002h42.23" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.875, 0.0, 0.38, 0.9010416666666666, 0.0, 0.58, 0.953125, 11.0, 1.0, 0.921875, 19.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5052083333333334, 125.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.fills,"Group_Yellow_Stripe",[0.0, 0.671875, 83.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Handle",[0.0, 0.4739583333333333, 0.0]);

        super.drawObject();
    }
}
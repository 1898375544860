import SVGColorCorrection from "./SVGColorCorrection";
import SVGGradient from "./SVGGradient";

export class SVGColorUtils{

 static changeColors(doc:Document, fillcolor:string, 
       grads:SVGGradient[], fills:SVGGradient[]):void {
    SVGColorUtils.changeGradientColors(doc, fillcolor, grads);
    SVGColorUtils.changeFillColors(doc,fillcolor,fills);
}
    static changeFillColors(doc:Document, fillcolor:string, fills:SVGGradient[]):void{
        if (doc==null || fills==null) return;
	fills.forEach((fill:SVGGradient) =>{
			const grnode = doc.getElementById(fill.id);
			if (grnode==null || grnode.childNodes==null) return;
			let j=0;
			for (let i=0;i<grnode.childNodes.length;i++) {
				const node = grnode.childNodes[i];
				if (node==null) continue;
                if (node.nodeType != Node.ELEMENT_NODE) continue;
                const element = node as Element;
				const nodefill = element.getAttribute("fill");
				if (nodefill==null) continue;
                if (nodefill.includes("url")) continue;
				const correction:SVGColorCorrection = fill.getSVGCorrection(j);
              
				if (correction==null)
					continue;

				element.setAttribute("fill",SVGColorUtils.changeColor(fillcolor,
					correction.mul, correction.add ));
				j++;
			}
		});
    }
    static changeGradientColors(doc:Document, fillcolor:string, grads:SVGGradient[]):void{
        if (doc==null || grads==null) return;
        grads.forEach((grad:SVGGradient)=>{
            const gradnode = doc.getElementById(grad.id);
            if (gradnode==null || gradnode.childNodes==null) return;
            for ( let i=0;i<gradnode.childNodes.length;i++) {
				const node:ChildNode = gradnode.childNodes[i];
				if (node==null) continue;
                if (node.nodeType != Node.ELEMENT_NODE) continue;
				const element = node as Element;
				const nodeoffset = element.getAttribute('offset');
				if (nodeoffset==null || nodeoffset===undefined) continue;
			
				const correction:SVGColorCorrection = grad.getSVGCorrection(+nodeoffset);
              
				if (correction==null)
					continue;
				element.setAttribute("style","stop-color:"+SVGColorUtils.changeColor(fillcolor,
					correction.mul, correction.add ));
               
			}
        })
    }
    static changeColor(color:string, mul:number, add:number):string {
        //console.log(color)
       // const color = element.getAttribute("style")
        let R = parseInt(color.substring(2,4),16);
        let G = parseInt(color.substring(4,6),16);
        let B = parseInt(color.substring(6,8),16);
        const OO = color.length>=9?color.substring(8,10):"";
    
        R = Math.floor(R * mul+add);
        G = Math.floor(G * mul+add);
        B = Math.floor(B * mul+add);
    
        R = (R<255)?R:255;  
        R = (R>0)?R:0;
        G = (G<255)?G:255;  
        G = (G>0)?G:0;
        B = (B<255)?B:255;  
        B = (B>0)?B:0;
    
        const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB+OO;
    }
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import Konva from "konva";
import {Sector} from "./properties/range/sector"
import tagsModele from "@/store/tags.modele";
import { Value } from "../value";



export class PieChart extends ObjectView{
   sectors:Sector[]
   uselegends:boolean
   donut:boolean

    public drawObject():void{
		super.initObject();
        
        let amount = 0
        
        this.sectors.forEach((sec)=>{
            if (sec.path==null || tagsModele.getTag(sec.path)==null) return;
            this.tagnames.push(sec.path)
            const tagvalue = this.getTagValue(sec.path)
            amount +=Math.abs(tagvalue)
        })
        if(amount==0){
            this.sectors.forEach((sec)=>{
                sec['angle'] = 360/this.sectors.length
            })
        }else{
            this.sectors.forEach((sec)=>{
                if (sec.path==null || tagsModele.getTag(sec.path)==null) sec['angle']=0;
               sec['angle'] = Math.abs(this.getTagValue(sec.path))/amount*360
            })
        }

        const width2 = this.uselegends?this.width/2:this.width
        const width = width2>this.height? this.height : width2
        const sectors = this.sectors
        const donut = this.donut
    
        if(this.uselegends){
            const piechart = this.drawPieChart(donut,width, sectors)
            this.node.add(piechart)
            const legends = this.drawLegends(width, sectors)
            legends.position({x:this.width/2, y:0})
            this.node.add(legends)
        }else{
            const piechart = this.drawPieChart(donut,width, sectors)
            this.node.add(piechart)
        }
        
    } 

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "uselegends":
            case "donut": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "uselegends":
            case "donut": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }

    private drawPieChart(donut:boolean,width:number, sectors:any){
        const node1 = new Konva.Group()
        let startangle=0
        const vp = new Konva.Group({
            clipFunc: function (ctx) {
                ctx.arc(width/2, width/2, width/2, 0, Math.PI * 2, false);
                ctx.arc(width/2, width/2, width/6, 0, Math.PI * 2, true);
              }
            })
        sectors.forEach((sec)=>{
            const wedge = new Konva.Wedge({
                x: width/2,
                y: width/2,
                radius: width/2,
                angle: sec.angle,
                fill: ColorUtils.convertformat(sec.color),
                rotation: -(startangle+sec.angle),
              });
              startangle+=sec.angle
              if (donut)
                vp.add(wedge)
              else
                node1.add(wedge)
        })
        if (donut)
          node1.add(vp)
    
             
        return node1
    }

    protected getTagValue(tagname:string):number{
        const tag = tagsModele.getTag(tagname) 
        const tagvalue = (Number(tag.tagvalue))
        return tagvalue
    }

    protected drawLegends(width:number, sectors:any):Konva.Group{
        const node1 = new Konva.Group()
        sectors.forEach((sec, index)=>{
            if(sectors.length<7){
                const circle = new Konva.Circle({
                    x: width/10,
                    y: width*(index+1)/(sectors.length+1),
                    radius: width/20,
                    fill: ColorUtils.convertformat(sec.color)
                })
                const text = new Konva.Text({
                    x: width*2/10,
                    y: width*(index+1)/(sectors.length+1)-width/16,
                    verticalAlign:"middle",
                    width: width-width*2/10,
                    height: width/8,
                    text: sec.sectorname,
                    fontSize: width/8,
                    fill: ColorUtils.convertformat(sec.color),
                  });
                  node1.add(circle, text)
            }else{
                const circle = new Konva.Circle({
                    x: width/10,
                    y: width*(index+1)/(sectors.length+1),
                    radius: width/sectors.length*3,
                    fill: ColorUtils.convertformat(sec.color)
                })
                const text = new Konva.Text({
                    x: width*2/10,
                    y: width*(index+1)/(sectors.length+1)-width/(sectors.length+1),
                    verticalAlign:"middle",
                    width: width/2,
                    height: width/(sectors.length+1),
                    text: sec.sectorname,
                    fontSize: width/(sectors.length+1),
                    fill: ColorUtils.convertformat(sec.color),
                  });
                node1.add(circle, text)
            }
        })
        return node1
    }        
}
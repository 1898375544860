import { SVGObject } from "../svgobject";

export class SVGBlower8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 84.377" enable-background="new 0 0 112.5 84.377" xml:space="preserve">
        <g id="Group_Port">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="75.625" y1="28.126" x2="75.625" y2="0">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect x="38.75" fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" width="73.75" height="28.126" />
        </g>
        <g id="Group_Blower">
            <radialGradient id="SVGID_2_" cx="42.3979" cy="42.481" r="42.4395" gradientUnits="userSpaceOnUse">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.31" style="stop-color:#5B5B5B" />
                <stop offset="0.43" style="stop-color:#626262" />
                <stop offset="0.5" style="stop-color:#6D6D6D" />
                <stop offset="0.57" style="stop-color:#7E7E7E" />
                <stop offset="0.62" style="stop-color:#949494" />
                <stop offset="0.67" style="stop-color:#ACACAC" />
                <stop offset="0.73" style="stop-color:#B1B1B1" />
                <stop offset="0.79" style="stop-color:#BEBEBE" />
                <stop offset="0.79" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <ellipse fill="url(#SVGID_2_)" cx="42.398" cy="42.481" rx="42.398" ry="42.481" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.51, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.31, 0.7083333333333334, 0.0, 0.43, 0.7604166666666666, 0.0, 0.5, 0.8489583333333334, 0.0, 0.57, 0.9791666666666666, 0.0, 0.62, 0.84375, 39.0, 0.67, 0.65625, 87.0, 0.73, 0.6197916666666666, 97.0, 0.79, 0.515625, 123.0, 0.79, 0.5052083333333334, 125.0, 1.0, 0.6927083333333334, 0.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMedical9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 29.844 112.5" xml:space="preserve" enable-background="new 0 0 29.844 112.5">
        <g id="Group_LeftLeg">
            <path d="M23.603,70.961c-2.827-0.217-5.648-0.459-8.484-0.642l0.197,2.991   l1.127,11.036l0.225,8.671l1.577,12.388l-1.126,4.617l0.563,1.239l2.928,1.238H24.1l1.126-1.238l-2.928-5.293V86.374L23.603,70.961   z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightLeg">
            <path d="M4.804,70.096l0.489,13.801l-0.563,4.617v17.455l-1.126,2.928   l-1.802,1.802l0.676,1.126h4.616l2.929-2.365l-0.563-3.49l-0.563-2.928l2.928-19.82l1.551-13.005   C10.547,70.063,7.697,69.995,4.804,70.096z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_LeftArm">
            <path d="M28.605,55.856l0.45-11.036l-0.45-8.446l-0.338-7.658l-2.253-7.32   l-1.421-0.967c-0.277,2.975-0.652,5.879-0.837,8.829l0.456,4.638l-0.082,0.256l0.308,3.686l0.338,7.432l0.788,10.023l0.226,2.928   L25.564,61.6l0.112,4.054l2.703-3.491l1.239-2.815L28.605,55.856z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_RightArm">
            <path d="M7.2,19.794l-2.132,0.814l-2.365,4.279L1.577,30.18v7.545   l-0.676,5.743L0.564,57.207l-0.563,3.941l1.126,3.604l2.59,1.689l0.226-4.055l0.112-3.49v-2.252L5.857,42.23l1.014-6.419   l0.175-2.342C7.24,28.911,7.565,24.353,7.2,19.794z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Neck">
            <path d="M17.681,17.68l-1.238-2.365h-4.055l-1.126,2.928l-0.018,0.007   c2.649,0,5.523,0,8.182,0L17.681,17.68z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Shoes">
            <path d="M25.902,111.824v-1.126l-1.802-1.802l-1.238-2.928H19.37l-1.689-1.689v3.49   l-0.563,1.689v1.239l0.563,1.126l2.365,0.676h5.293L25.902,111.824z" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M1.24,111.824v-1.126l1.802-1.802l1.126-2.928h3.491l1.802-1.689l0.563,4.055   l0.676,1.125l-1.802,1.239l-1.239,1.126L5.406,112.5H1.803L1.24,111.824z" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_BlazerBack">
            <path d="M8.447,82.32l5.631-16.33l5.631,16.33H8.447z" fill="#666666" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_LowerBody">
            <path d="M9.686,105.518H7.208l-3.153,0.563V83.672l-0.112-8.221   L3.491,63.176l1.464-10.36l1.577-3.829l4.955-0.675l6.419,0.45l5.743,0.225l2.252,8.108l-0.226,9.685L23.65,78.49l0.563,6.87   l-1.238,20.157l-1.352-0.676h-1.914l-1.802,0.676l-2.14-19.144l-0.563-8.446L14.19,65.203l-1.126,12.162l-2.027,13.288   L9.686,105.518z" fill="#000066" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M14.19,64.752l-1.126,11.036L11.036,90.09l-0.563,6.307l-0.563-4.73v-9.008l2.703-11.037l0.45-5.404   v-3.604v-2.141l0.563-9.572v9.009l0.563,3.152l2.14-3.716l-1.126,4.843l1.126,5.293l0.451,13.176l0.563,9.459l1.577,4.279   l-1.577,0.563l-1.014-6.869l-1.126-13.738L14.19,64.752z" fill="#000033" />
        </g>
        <g id="Group_UpperBody">
            <path d="M5.631,33.896l-5.067-1.239l0.563-5.067l1.352-3.716l2.14-3.378   l4.054-1.352l3.266-1.689l2.14,1.126h2.252l1.577-1.126l6.531,2.252l2.365,1.689l1.576,3.491v2.252l0.676,5.856l-4.617,0.901   l-0.676,10.585v3.829l0.676,1.914H5.744v-1.239l0.563-2.59L5.631,33.896z" fill="#9999FF" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M6.532,33.559l0.788,1.577l3.941,1.239l4.054-1.352l7.208,0.901l1.238,1.464l-0.676-4.054   l-4.955,1.351l-2.928-0.901l-4.167,1.014L6.532,33.559z" fill="#7F7FE5" />
            <path d="M23.424,26.802l0.563,5.743l-0.901,0.789l0.788,3.829l0.113-3.378l1.688-0.338l-0.9-0.563   l0.563-4.729l-0.901,2.252L23.424,26.802z" fill="#7F7FE5" />
            <path d="M4.955,29.504l0.451,3.041l-0.788,0.901l1.464,0.338l0.112,3.041l1.238-10.135l-1.351,5.856   L4.955,29.504z" fill="#7F7FE5" />
            <path d="M18.469,31.306v-2.478h0.676v2.478H18.469z" fill="#000066" />
            <path d="M17.793,31.306v-1.802h0.676v1.802H17.793z" fill="#FF0000" />
            <path d="M19.708,31.306v-1.802h0.676v1.802H19.708z" />
        </g>
        <g id="Group_HairBack">
            <path d="M21.284,9.347l-0.9,8.784l-0.901-0.675l-0.225,0.675h-0.901l-1.352-1.238   l-0.338-1.239l2.253-4.842L21.284,9.347z" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M7.884,9.347l1.238,9.459l0.112-0.676l0.338,0.676h0.901l-0.112-1.239   l0.675,1.239l3.717-3.829L7.884,9.347z" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Face">
            <path d="M19.37,11.374H9.798L8.56,10.36L7.884,9.234V8.108L8.56,6.982   h12.049l0.676,1.126v1.126l-0.676,1.126L19.37,11.374z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="14.528" cy="8.671" rx="5.743" ry="7.545" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M14.415,11.261l1.689,0.451h-3.266L14.415,11.261z" fill="#663333" />
            <path d="M12.839,13.401l-0.451-0.45h4.167l-0.45,0.45H12.839z" fill="#996666" />
            <path d="M12.388,12.951h4.167" fill="none" stroke="#CC9999" stroke-width="0.25" />
            <path d="M18.019,6.757l-2.815-0.451l0.226,0.451l2.815,0.225L18.019,6.757z" />
            <path d="M10.924,6.757l2.928-0.451l-0.338,0.451l-2.814,0.225L10.924,6.757z" />
            <path d="M12.163,9.122h0.45l0.226-0.676L12.614,7.77h-0.45l-0.226,0.676L12.163,9.122z" />
            <path d="M16.329,9.122h0.563l0.226-0.676L16.893,7.77h-0.563l-0.225,0.676L16.329,9.122z" />
        </g>
        <g id="Group_HairTop">
            <path d="M9.01,8.221L8.56,6.757L7.884,8.221l0.676-5.18l1.688-1.915   l1.915-0.901L14.415,0l2.478,0.225l1.802,0.901l1.689,1.915l0.9,5.18l-0.676-1.464l-0.45,1.464l-1.014-3.829L18.019,2.59   l0.226,2.252h-7.32L11.15,2.59L9.798,4.392L9.01,8.221z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Blazer">
            <path d="M17.455,17.23l5.969,1.576l3.153,1.915l1.914,3.153l0.676,6.193   l0.676,15.09l-0.676,10.135l-1.238-0.676h-2.59l-0.789-9.572l-0.563-10.923l0.788,13.626l1.238,11.937l0.113,21.96l-6.982,1.125   l-1.689-1.125l-1.126-32.883l-0.563-12.5L17.455,17.23z" fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M12.501,17.23L9.01,18.806l-4.955,1.239l-3.153,6.307   l-0.676,9.459v13.738v8.22l1.126-1.688l2.703,0.451l-0.676,4.392L2.141,81.869l9.797,0.9l1.689-1.125l-0.563-45.383l0.563-11.374   L12.501,17.23z" fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="4.281" cy="36.036" rx="1.576" ry="9.009" fill="#CCCCCC" />
            <ellipse cx="24.776" cy="35.811" rx="1.914" ry="13.739" fill="#CCCCCC" />
            <path d="M9.01,64.64l0.676-6.87l-5.743-0.675v1.239l4.504,0.676L9.01,64.64z" fill="#E5E5E5" />
            <path d="M20.384,65.315l-0.676-6.981l5.631-0.564v1.24l-4.393,0.675L20.384,65.315z" fill="#E5E5E5" />
            <path d="M17.231,31.306h5.631v0.676h-5.631V31.306z" fill="#CCCCCC" />
        </g>
        <g id="Group_Stethoscope">
            <path d="M10.812,41.441h0.676l0.563,5.631l-0.563,3.153l-1.915,0.676v-0.676   l1.239-0.563l0.676-2.59l-0.676-5.068h-0.563l-0.676-5.63v-5.631l0.676-6.306L9.573,18.13l1.915-1.802l0.563,0.563h-0.563   l-1.239,1.914l0.563,5.631l-0.563,6.306v5.631L10.812,41.441z" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M10.136,41.104L9.01,41.779l2.364,5.18l2.027,2.365l1.914-0.338   l-0.337-0.563l-1.352,0.113l-1.802-1.915l-2.027-4.617l0.563-0.338L10.136,41.104z" fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M19.032,48.986l-1.239-2.59l1.239-2.478l1.238,2.478   L19.032,48.986z" fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="19.032" cy="30.743" rx="1.238" ry="14.415" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
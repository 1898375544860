import { opcode } from "../opcode";
import { Value } from "../value";

export class interpretMathOp{
    static mathop(left:Value, right:Value,opcode:opcode,datatype:number):Value{
        let res:Value;

        const leftvalue = (String(left.value).toLowerCase() === 'true');
        const rightvalue = (String(right.value).toLowerCase() === 'true');
        
        switch(datatype){
            case 0: {res =  interpretMathOp.mathopboolean(leftvalue, rightvalue,opcode,datatype); break}
            case 1: 
            case 2:
            case 3:
            case 4:
            case 5:
            case 6: {res = interpretMathOp.mathopnumber(Number(left.value), Number(right.value),opcode,datatype);break;}
            case 7: {res = interpretMathOp.mathopstring(left.value, right.value,opcode,datatype);break;}

        }
        return res;
    }

    static mathopboolean(left:boolean,right:boolean,opcode:opcode,datatype:number):Value{
        //let res:Value
        const resvalue:Value = new Value()
       
        let res:boolean;
        switch (opcode.type){
            case "BOOLEAN_AND": {res = left && right; break;}
            case "BOOLEAN_OR": {res = left || right; break}
            case "BOOLEAN_XOR": {res = left != right; break}
            case "BINARY_AND": {res = left && right; break}
            case "BINARY_OR": {res = left || right; break}
            case "BINARY_XOR": {res = left != right; break}
        }
        resvalue.datatype = datatype;
        resvalue.value = String(res);
        return resvalue
    }

    static mathopnumber(left:number, right:number,opcode:opcode, datatype:number):Value{
       // console.log('mathopnumber datatype', datatype)
       // console.log('mathopnumber left right', left, right, opcode.type )
        const resvalue:Value = new Value()
        let res:number;
        switch (opcode.type){
            case "BINARY_ADD": {res=left+right; break;}
            case "BINARY_REM": {res=left-right; break;}
            case "BINARY_MUL": {res=left*right; break}
            case "BINARY_DIV": {res=left/right; break}
            case "BINARY_GT": {res=left>right ? 1:0; datatype=0; break}
            case "BINARY_GTE": {res=left>=right ? 1:0;datatype=0; break}
            case "BINARY_LT": {res=left<right ? 1:0;datatype=0; break}
            case "BINARY_LTE": {res=left<=right ? 1:0;datatype=0; break}
            case "BINARY_EQ": {res=left==right ? 1:0;datatype=0; break}
            case "BINARY_EXEQ": {res=left!=right ? 1:0;datatype=0; break}
            case "BINARY_AND": {res=left & right; break}
            case "BINARY_OR": {res=left | right; break}
            case "BINARY_XOR": {res=left ^ right; break}
            case "MODULO": {res=left % right; break}
            case "LEFT_SHIFT": {res=left << right; break}
            case "RIGHT_SHIFT": {res=left >> right; break}
        }
        if(datatype==1 || datatype==2 || datatype==3 || datatype==4) res = Math.trunc(res)
        resvalue.datatype = datatype;
        resvalue.setValue(String(res));
        return resvalue
    }

    static mathopstring(left:string,right:string,opcode:opcode,datatype:number):Value{
        const resvalue:Value = new Value()
      
        let res:string;
        switch (opcode.type){
            case "BINARY_ADD": {res = left+right; break;}
        }
        resvalue.datatype = datatype;
        resvalue.setValue(String(res));
        //console.log('mathopstring resvalue', resvalue)
        return resvalue
    }
}
import { Property } from "./property";
import { TextRange } from "./range/textrange";
import tag from "@/model/tag";


export class TextInputProperty extends Property{
    value:string;
    type:number;
    truetext:string;
    falsetext:string;
    textbefore:string;
    textafter:string;
    decimalpos:number;
    beforedecimalpos:number;
    textrange:TextRange[];

    public getCurrentText(tagnames:string[], defaulttext:string):string{
        const tag:tag = this.getTag(tagnames);
		if (tag==null) return defaulttext;
        return this.textbefore+this.getText(tag)+this.textafter;
    }

   
    private getText(tag:tag):string{
       
        let text:string = this.falsetext;
      
        switch (this.type){
              case 0:
              
                  if (isNaN(parseFloat(tag.tagvalue)) )
                    text = tag.tagvalue
                  else{
                  text = (Math.round(+tag.tagvalue*10**this.decimalpos)/(10**this.decimalpos)).
                        toFixed(this.decimalpos);
                        if (this.beforedecimalpos>0)
                        text =text.padStart(this.beforedecimalpos+(this.decimalpos>0?this.decimalpos+1:0),'0');
                  }
                    break;
              case 1: 
                      if((this.value.toLowerCase()=='true') && tag.tagvalue=='1' || tag.tagvalue.toLocaleLowerCase()=='true'){
                        text = this.truetext;
                      }
                      if((this.value.toLowerCase()=='false') && tag.tagvalue=='0' || tag.tagvalue.toLocaleLowerCase()=='false'){
                        text = this.truetext;
                      }
                      if (+this.value==+tag.tagvalue)
                        text = this.truetext;
                      break;
              case 2: if (+tag.tagvalue>=+this.value)
                        text = this.truetext;
                      break;
              case 3:if (+tag.tagvalue<=+this.value)
                        text = this.truetext;
                      break;
              case 4: if (+tag.tagvalue>+this.value)
                        text = this.truetext;
                      break;
              case 5: if (+tag.tagvalue<+this.value)
                        text = this.truetext;
                      break;
              case 6: if (+tag.tagvalue!=+this.value)
                         text = this.truetext;         
                      break;
              case 7: for (const mrange of this.textrange){
                if (mrange.from<=+tag.tagvalue && +tag.tagvalue<=mrange.to){
                  text = mrange.text;
                  break;
                }
              }
            break;
              default: break;
        }
        return text;
    }
}
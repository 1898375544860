import { SVGObject } from "../svgobject";

export class SVGWater25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Group_x5F_Screen" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 48.873 112.5" enable-background="new 0 0 48.873 112.5" xml:space="preserve">
        <g id="Group_Screen_sub">
        <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M48.873,0v87.95h-9.797V4.843L48.873,0z" />
        <path fill="#D9D9D9" stroke="#4C4C4C" stroke-width="0.25" d="M9.685,112.5l39.188-24.55h-9.797l-29.37,18.452L9.685,112.5z" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,112.5l39.076-48.986" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M19.482,103.828l24.437-30.518" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M34.234,95.27l9.685-12.162" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,102.703l39.076-48.987" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,92.905l39.076-48.986" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,83.107l39.076-48.873" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,73.311l39.076-48.874" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,63.514L43.918,14.64" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,53.716L43.918,4.843" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,43.919L38.063,2.365" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M4.842,34.234L24.437,9.685" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,87.95L4.842,70.833" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,78.152L4.842,61.036" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,68.469L4.842,51.239" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,58.671L4.842,41.554" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,48.874L4.842,31.757" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,39.077L4.842,21.959" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,29.279L12.162,15.315" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,19.482L21.959,9.91" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M43.918,9.685L32.77,4.843" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M36.599,94.594L4.842,80.631" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M26.914,100L4.842,90.428" />
        <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M15.99,105.067l-11.148-4.843" />
        <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M9.685,19.482L48.873,0h-9.797L0,19.482H9.685z" />
        <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.685,19.482V112.5H0V19.482H9.685z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
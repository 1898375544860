import { SVGObject } from "../svgobject";

export class SVGValve22 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 81.19" enable-background="new 0 0 112.5 81.19" xml:space="preserve">
        <g id="Group_Horizontal_Pipe">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="46.084" y1="73.6484" x2="46.084" y2="36.6001">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="15.583" y="36.6" fill="url(#SVGID_1_)" width="61" height="37.049" />
        </g>
        <g id="Group_Bolt">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="81.7012" y1="78.2656" x2="81.7012" y2="71.3965">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="88.738,71.396 74.775,71.396 72.41,71.961 72.41,77.703 74.775,78.266 88.738,78.266    90.991,77.703 90.991,71.961  " />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="81.7012" y1="58.5586" x2="81.7012" y2="51.6895">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="88.626,51.689 74.775,51.689 72.41,52.252 72.41,57.996 74.775,58.559 88.626,58.559    90.991,57.996 90.991,52.252  " />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="81.7012" y1="38.8516" x2="81.7012" y2="31.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" points="88.738,31.982 74.775,31.982 72.41,32.433 72.41,38.289 74.775,38.852 88.738,38.852    90.991,38.289 90.991,32.433  " />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="9.8535" y1="38.8516" x2="9.8535" y2="31.9824">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="16.779,31.982 2.928,31.982 0.563,32.433 0.563,38.289 2.928,38.852 16.779,38.852    19.144,38.289 19.144,32.433  " />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="9.7974" y1="58.5586" x2="9.7974" y2="51.6895">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_6_)" points="16.779,51.689 2.815,51.689 0.45,52.252 0.45,57.996 2.815,58.559 16.779,58.559    19.144,57.996 19.144,52.252  " />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="9.8535" y1="78.2656" x2="9.8535" y2="71.3965">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="16.779,71.396 2.928,71.396 0.563,71.961 0.563,77.703 2.928,78.266 16.779,78.266    19.144,77.703 19.144,71.961  " />
        </g>
        <g id="Group_Flange">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="9.7974" y1="81.1953" x2="9.7974" y2="29.6182">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="2.815" y="29.618" fill="url(#SVGID_8_)" width="13.964" height="51.578" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="81.7002" y1="81.1953" x2="81.7002" y2="29.6182">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="74.662" y="29.618" fill="url(#SVGID_9_)" width="14.076" height="51.578" />
            <rect x="9.114" y="29.618" fill="#4C4C4C" width="0.943" height="51.578" />
            <rect x="81.229" y="29.618" fill="#4C4C4C" width="0.943" height="51.578" />
        </g>
        <g id="Group_Handle">
            <path fill="#CCCCCC" d="M37.05,8.784l0.675-2.928h14.527l2.252-4.054h3.941v2.252h-2.927l-2.14,4.279H39.302v0.451H37.05z" />
            <path fill="#666666" d="M38.176,8.784l0.676-1.802h13.851l2.365-4.054h3.378v1.126h-2.927l-2.14,4.279H39.302v0.451H38.176z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="85.1914" y1="5.1807" x2="85.1914" y2="0.4507">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="58.445" y="0.451" fill="url(#SVGID_10_)" width="53.492" height="4.73" />
        </g>
        <g id="Group_Vertical_Pipe">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="27.9277" y1="28.4917" x2="64.0762" y2="28.4917">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M64.076,8.784H27.928v27.635c0,0,0,0.437,0,0.66c0,6.141,8.092,11.12,18.074,11.12   s18.074-4.979,18.074-11.12c0-0.223,0-0.66,0-0.66V8.784z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="21.1714" y1="18.5254" x2="70.3828" y2="18.5254">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="21.171" y="14.527" fill="url(#SVGID_12_)" width="49.211" height="7.996" />
        </g>
        <g id="Group_Handle_Bolt">
            <rect x="42.906" y="2.478" fill="#E5E5E5" width="5.743" height="6.952" />
            <rect x="42.229" y="2.928" fill="#B2B2B2" width="1.812" height="6.502" />
            <rect x="47.522" y="2.928" fill="#B2B2B2" width="1.802" height="6.502" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Handle_Bolt",[0.0, 0.20833333333333334, 201.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
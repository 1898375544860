
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { Rectangle } from "./rectangle";
import { Value } from '../value';

export class Sector extends Rectangle{
    startangle:number;
    rotationangle:number;
  

    public drawObject():void{
		super.initObject();

        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
				color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
		let fillcolor = '0xffffff00';

        if (this.fill){          
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        }   

        if(this.width>this.height){
            const arc = new Konva.Arc({
            x: this.width / 2,
            y: this.height / 2,
            innerRadius: 0,
            outerRadius: this.height/2-this.linewidth/2,
            angle: -this.rotationangle,
            rotation: -this.startangle,
            clockwise:this.rotationangle<0? false :true,
            fill: ColorUtils.convertformat(fillcolor),
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
            });

           const grouparc =new Konva.Group({
                x: -this.width/2*this.width/this.height+this.width/2-this.linewidth/2,
                y: 0,
                scaleX: this.width/this.height
            })
            grouparc.add(arc)
            this.node.add(grouparc)
        }else{
            const arc = new Konva.Arc({
                x: this.width / 2,
                y: this.height / 2,
                innerRadius: 0,
                outerRadius: this.width/2-this.linewidth/2,
                angle: -this.rotationangle,
                rotation: -this.startangle,
                clockwise:this.rotationangle<0? false :true,
                fill: ColorUtils.convertformat(fillcolor),
                stroke: ColorUtils.convertformat(color),
                strokeWidth: this.linewidth,
            });

          const grouparc =new Konva.Group({
            x: 0,
            y: -this.height/2*this.height/this.width+this.height/2-this.linewidth/2,
            scaleY: this.height/this.width
            })
            grouparc.add(arc)
            this.node.add(grouparc)
        }
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "startangle":
            case "rotationangle": {this[field] = value.value;this.rotatednode=null;break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "startangle":
            case "rotationangle": {value.datatype=6; value.value = this[field];break}
        }
        return value;
    }
}
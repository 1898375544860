import { SVGObject } from "../svgobject";

export class SVGMaterial21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.201" enable-background="new 0 0 112.5 98.201" xml:space="preserve">
        <g id="Group_Support">
            <path fill="#99997A" stroke="#4C4C4C" stroke-width="0.25" d="M85.022,56.195l0.563-19.257l0.45-1.014l-0.45,20.271H85.022z" />
            <path fill="#7F7F7F" d="M94.707,56.195v-1.014h-0.563V38.966h0.563v-1.014h1.014v18.243H94.707z" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="92.6807" y1="56.1951" x2="92.6807" y2="37.9524">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#EBEBEB" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M73.424,37.952h7.545l0.563,2.026h2.59l0.451,2.027h10.135v-3.04h1.014v-1.014h14.189l2.027,2.026   v14.189l-2.027,2.027H95.721v-1.014h-1.014v-3.04H84.572l-0.451,2.026h-2.59l-0.563,2.027h-7.545V37.952z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M81.531,54.168V39.978" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M84.572,52.141V42.006" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M94.707,52.141V42.006" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M95.721,55.181V38.966" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M109.91,56.195V37.952" />
        </g>
        <g id="Group_Motor_Holder">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="89.6396" y1="56.1951" x2="89.6396" y2="35.925">
                <stop offset="0" style="stop-color:#CCCCBD" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="1" style="stop-color:#EDEDE8" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.586,56.195l0.45-20.271h7.095l0.563,20.271H85.586z" />
        </g>
        <g id="Group_Shredder">
            <path fill="#E5E5DD" stroke="#4C4C4C" stroke-width="0.25" d="M12.613,56.195V42.006h-2.027v-2.027h42.568v-4.054h20.271v20.271   H12.613z" />
            <path fill="#CCCCBD" d="M14.64,54.168V42.006h38.514v12.162H14.64z" />
            <path fill="#CCCCBD" d="M55.18,42.006v14.189h16.216V42.006H55.18z" />
            <path fill="none" stroke="#4C4C16" stroke-width="0.25" d="M14.64,54.168V42.006h38.514" />
            <path fill="none" stroke="#4C4C16" stroke-width="0.25" d="M55.18,56.195V42.006h16.216" />
        </g>
        <g id="Group_Label">
            <path fill="#333300" d="M26.802,50.002v-3.942h14.189v3.942H26.802z" />
            <path fill="#CCCCBD" d="M27.815,47.524h4.054v1.013h-4.054V47.524z" />
            <path fill="#7F7F59" d="M32.883,47.524h6.081v1.013h-6.081V47.524z" />
        </g>
        <g id="Group_Stand_Rear">
            <path fill="#4C4C16" d="M18.694,94.709V64.303h81.082v30.405h6.08V58.222H12.613v36.486H18.694z" />
            <path fill="#4C4C16" d="M10.022,94.709h9.234v1.014h-9.234V94.709z" />
            <path fill="#4C4C16" d="M60.248,94.709h10.135v1.014H60.248V94.709z" />
            <path fill="#4C4C16" d="M99.212,94.709h9.233v1.014h-9.233V94.709z" />
        </g>
        <g id="Group_Stand">
            <path fill="#E5E5DD" stroke="#4C4C4C" stroke-width="0.25" d="M10.585,96.735v-40.54h97.297v40.54h-6.081V62.276h-34.46V86.6h34.46   v6.081h-34.46v4.054h-6.08V62.276H16.667v34.459H10.585z" />
        </g>
        <g id="Group_Stand_Base">
            <path fill="#CCCCBD" stroke="#4C4C4C" stroke-width="0.25" d="M7.545,96.735H17.68v1.014H7.545V96.735z" />
            <path fill="#CCCCBD" stroke="#4C4C4C" stroke-width="0.25" d="M60.248,96.735h10.135v1.014H60.248V96.735z" />
            <path fill="#CCCCBD" stroke="#4C4C4C" stroke-width="0.25" d="M100.788,96.735h10.136v1.014h-10.136V96.735z" />
        </g>
        <g id="Group_Feeder">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="44.2964" y1="42.4636" x2="27.2051" y2="-4.4944">
                <stop offset="0" style="stop-color:#B2B29B" />
                <stop offset="0.23" style="stop-color:#B9B9A4" />
                <stop offset="0.41" style="stop-color:#CCCCBD" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M14.64,39.978L2.478,4.506h56.757l-8.108,35.473H14.64z" />
            <path fill="#E5E5DD" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,4.506V0.452h60.811v4.054H0.451z" />
            <path fill="#4C4C16" d="M0.451,4.506v0.45h60.811v-0.45H0.451z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M14.64,39.978L2.478,4.506h56.757l-8.108,35.473H14.64" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.5, 0.16666666666666666, 212.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4427083333333333, 141.0, 0.45, 0.4427083333333333, 141.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.671875, 83.0, 0.23, 0.609375, 99.0, 0.41, 0.4427083333333333, 141.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.890625, 27.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Shredder",[0.0, 0.234375, 195.0, 1.0, 0.4427083333333333, 141.0, 2.0, 0.4427083333333333, 141.0]);
		this.fillGradient(this.fills,"Group_Label",[0.0, 0.2604166666666667, 0.0, 1.0, 0.4427083333333333, 141.0, 2.0, 0.8854166666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Stand_Rear",[0.0, 0.4479166666666667, 0.0, 1.0, 0.4479166666666667, 0.0, 2.0, 0.4479166666666667, 0.0, 3.0, 0.4479166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.234375, 195.0]);
		this.fillGradient(this.fills,"Group_Stand_Base",[0.0, 0.4427083333333333, 141.0, 1.0, 0.4427083333333333, 141.0, 2.0, 0.4427083333333333, 141.0]);
		this.fillGradient(this.fills,"Group_Feeder",[0.0, 0.234375, 195.0, 1.0, 0.4479166666666667, 0.0]);

        super.drawObject();
    }
}
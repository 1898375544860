import { TwoStateObject } from './twostateobject';
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";

export class ElbowPipe2 extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();

    if(this.type3d != 0) this.type3d = 1
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            
            const arc = new Konva.Arc({
                x: 0,
                y: 0,
                innerRadius: this.width*6/10,
                outerRadius: this.width,
                angle: 90,
                stroke: this.type3d==0? null : 'black',
                strokeWidth: 1
              });
              if(this.type3d==0){
               // if(this.angle==0){
                        arc.fillRadialGradientStartPoint({ x: 0, y: 0 });
                        arc.fillRadialGradientEndRadius(this.width);
                        arc.fillRadialGradientColorStops([0.6, ColorUtils.darkColor(fillcolor2, 0.75),
                        0.8, 'white',
                        1, ColorUtils.darkColor(fillcolor2, 0.75)])
              }else{
                arc.fill(fillcolor2)
              }
              this.node.add(arc)
        }
}
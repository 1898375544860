import { SVGObject } from "../svgobject";

export class SVGDuct21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 89.979 112.5" enable-background="new 0 0 89.979 112.5" xml:space="preserve">
        <g id="Group_Wye_Duct">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.882813e-004" y1="56.25" x2="83.2217" y2="56.25">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.39" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" d="M69.482,89.527L46.735,112.5L0,65.428l9.347-9.234L0,46.734   L46.735,0l9.459,9.347l13.288,13.964h13.739v65.991L69.482,89.527z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M69.257,23.311L9.347,56.194l59.91,32.882" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M69.257,89.076V22.41" />
        </g>
        <g id="Group_Joint">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M80.067,19.933h9.91v-2.027h-9.91V19.933z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M80.067,94.031h9.91v-2.252h-9.91V94.031z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="83.2217" y1="55.8555" x2="86.5996" y2="55.8555">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.5" d="M83.222,16.104H86.6v79.504h-3.378V16.104z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.39, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.816 112.5" enable-background="new 0 0 56.816 112.5" xml:space="preserve">
        <g id="Group_Support">
            <path fill="#CCCCCC" d="M14.43,28.407h27.843v9.244H14.43V28.407z" />
            <path fill="#E5E5E5" d="M14.43,112.049V37.65h27.843v74.398H14.43z" />
            <path fill="#999999" d="M16.233,28.407h0.451v83.642h-0.451V28.407z" />
            <path fill="#999999" d="M19.051,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#999999" d="M21.982,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#999999" d="M24.913,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#999999" d="M27.844,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#999999" d="M30.662,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#999999" d="M33.593,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#999999" d="M36.524,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#999999" d="M39.455,28.407h0.451v83.642h-0.451V28.407z" />
            <path fill="#F2F2F2" d="M40.582,28.407h1.127v83.642h-1.127V28.407z" />
            <path fill="#F2F2F2" d="M37.651,28.407h1.128v83.642h-1.128V28.407z" />
            <path fill="#F2F2F2" d="M34.72,28.407h1.24v83.642h-1.24V28.407z" />
            <path fill="#F2F2F2" d="M31.902,28.407h1.127v83.642h-1.127V28.407z" />
            <path fill="#F2F2F2" d="M28.971,28.407h1.128v83.642h-1.128V28.407z" />
            <path fill="#F2F2F2" d="M26.041,28.407h1.127v83.642h-1.127V28.407z" />
            <path fill="#F2F2F2" d="M23.109,28.407h1.24v83.642h-1.24V28.407z" />
            <path fill="#F2F2F2" d="M20.179,28.407h1.24v83.642h-1.24V28.407z" />
            <path fill="#F2F2F2" d="M17.36,28.407h1.127v83.642H17.36V28.407z" />
            <path fill="#F2F2F2" d="M14.43,28.407h1.127v83.642H14.43V28.407z" />
            <path fill="#CCCCCC" d="M41.822,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#CCCCCC" d="M38.891,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#CCCCCC" d="M35.96,28.407h0.563v83.642H35.96V28.407z" />
            <path fill="#CCCCCC" d="M33.029,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#CCCCCC" d="M30.099,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#CCCCCC" d="M27.28,28.407h0.563v83.642H27.28V28.407z" />
            <path fill="#CCCCCC" d="M24.35,28.407h0.563v83.642H24.35V28.407z" />
            <path fill="#CCCCCC" d="M21.418,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#CCCCCC" d="M18.488,28.407h0.563v83.642h-0.563V28.407z" />
            <path fill="#CCCCCC" d="M15.669,28.407h0.564v83.642h-0.564V28.407z" />
            <path fill="#7F7F7F" d="M16.233,28.407h0.451v9.244h-0.451V28.407z" />
            <path fill="#7F7F7F" d="M19.051,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#7F7F7F" d="M21.982,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#7F7F7F" d="M24.913,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#7F7F7F" d="M27.844,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#7F7F7F" d="M30.662,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#7F7F7F" d="M33.593,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#7F7F7F" d="M36.524,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#7F7F7F" d="M39.455,28.407h0.451v9.244h-0.451V28.407z" />
            <path fill="#E5E5E5" d="M40.582,28.407h1.127v9.244h-1.127V28.407z" />
            <path fill="#E5E5E5" d="M37.651,28.407h1.128v9.244h-1.128V28.407z" />
            <path fill="#E5E5E5" d="M34.72,28.407h1.24v9.244h-1.24V28.407z" />
            <path fill="#E5E5E5" d="M31.902,28.407h1.127v9.244h-1.127V28.407z" />
            <path fill="#E5E5E5" d="M28.971,28.407h1.128v9.244h-1.128V28.407z" />
            <path fill="#E5E5E5" d="M26.041,28.407h1.127v9.244h-1.127V28.407z" />
            <path fill="#E5E5E5" d="M23.109,28.407h1.24v9.244h-1.24V28.407z" />
            <path fill="#E5E5E5" d="M20.179,28.407h1.24v9.244h-1.24V28.407z" />
            <path fill="#E5E5E5" d="M17.36,28.407h1.127v9.244H17.36V28.407z" />
            <path fill="#E5E5E5" d="M14.43,28.407h1.127v9.244H14.43V28.407z" />
            <path fill="#B2B2B2" d="M41.822,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#B2B2B2" d="M38.891,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#B2B2B2" d="M35.96,28.407h0.563v9.244H35.96V28.407z" />
            <path fill="#B2B2B2" d="M33.029,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#B2B2B2" d="M30.099,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#B2B2B2" d="M27.28,28.407h0.563v9.244H27.28V28.407z" />
            <path fill="#B2B2B2" d="M24.35,28.407h0.563v9.244H24.35V28.407z" />
            <path fill="#B2B2B2" d="M21.418,28.407h0.564v9.244h-0.564V28.407z" />
            <path fill="#B2B2B2" d="M18.488,28.407h0.563v9.244h-0.563V28.407z" />
            <path fill="#B2B2B2" d="M15.669,28.407h0.564v9.244h-0.564V28.407z" />
        </g>
        <g id="Group_ElevatedTank">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.4517" y1="12.1743" x2="56.2515" y2="12.1743">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0.452,23.785v-18.6L14.43,0.563h28.519l13.303,4.622v18.6H0.452z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.4517" y1="26.0957" x2="56.2515" y2="26.0957">
                <stop offset="0.01" style="stop-color:#404040" />
                <stop offset="0.5" style="stop-color:#C7C7C7" />
                <stop offset="1" style="stop-color:#404040" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M14.43,28.407L0.452,23.785h55.8l-13.979,4.622H14.43z" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M2.819,5.186h51.065" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M2.819,23.785h51.065" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5, 0.0, 0.5, 0.4427083333333333, 141.0, 1.0, 0.5, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.40625, 151.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0, 8.0, 0.8072916666666666, 49.0, 9.0, 0.8072916666666666, 49.0, 10.0, 0.8072916666666666, 49.0, 11.0, 0.11458333333333333, 226.0, 12.0, 0.11458333333333333, 226.0, 13.0, 0.11458333333333333, 226.0, 14.0, 0.11458333333333333, 226.0, 15.0, 0.11458333333333333, 226.0, 16.0, 0.11458333333333333, 226.0, 17.0, 0.11458333333333333, 226.0, 18.0, 0.11458333333333333, 226.0, 19.0, 0.11458333333333333, 226.0, 20.0, 0.11458333333333333, 226.0, 21.0, 0.40625, 151.0, 22.0, 0.40625, 151.0, 23.0, 0.40625, 151.0, 24.0, 0.40625, 151.0, 25.0, 0.40625, 151.0, 26.0, 0.40625, 151.0, 27.0, 0.40625, 151.0, 28.0, 0.40625, 151.0, 29.0, 0.40625, 151.0, 30.0, 0.40625, 151.0, 31.0, 0.9895833333333334, 0.0, 32.0, 0.9895833333333334, 0.0, 33.0, 0.9895833333333334, 0.0, 34.0, 0.9895833333333334, 0.0, 35.0, 0.9895833333333334, 0.0, 36.0, 0.9895833333333334, 0.0, 37.0, 0.9895833333333334, 0.0, 38.0, 0.9895833333333334, 0.0, 39.0, 0.9895833333333334, 0.0, 40.0, 0.20833333333333334, 201.0, 41.0, 0.20833333333333334, 201.0, 42.0, 0.20833333333333334, 201.0, 43.0, 0.20833333333333334, 201.0, 44.0, 0.20833333333333334, 201.0, 45.0, 0.20833333333333334, 201.0, 46.0, 0.20833333333333334, 201.0, 47.0, 0.20833333333333334, 201.0, 48.0, 0.20833333333333334, 201.0, 49.0, 0.20833333333333334, 201.0, 50.0, 0.609375, 99.0, 51.0, 0.609375, 99.0, 52.0, 0.609375, 99.0, 53.0, 0.609375, 99.0, 54.0, 0.609375, 99.0, 55.0, 0.609375, 99.0, 56.0, 0.609375, 99.0, 57.0, 0.609375, 99.0, 58.0, 0.609375, 99.0, 59.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
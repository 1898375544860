import { SVGObject } from "../svgobject";

export class SVGPaper7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 95.832 112.5" enable-background="new 0 0 95.832 112.5" xml:space="preserve">
        <g id="Group_CoatingArea">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="23.9302" y1="60.2881" x2="23.9302" y2="111.7382">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M7.996,60.473h31.869v52.026H7.996V60.473z" />
            <path fill="#666666" d="M7.996,60.473h31.869v4.055H7.996V60.473z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="38.9736" y1="55.5962" x2="8.6948" y2="0.8154">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M7.996,0.338h31.869v56.081H7.996V0.338z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.996,0.001h31.869v112.498H7.996V0.001" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M6.869,24.438h1.126v84.008H6.869V24.438z" />
        </g>
        <g id="Group_Body">
            <path fill="#333333" d="M39.864,60.473h55.968v52.026H39.864V60.473z" />
        </g>
        <g id="Group_Floor">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="51.9141" y1="56.4077" x2="51.9141" y2="64.0961">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M7.996,56.419h87.836v4.054H7.996V56.419z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,55.968h2.928v4.956h-2.928V55.968z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M95.832,55.968h-2.928v4.956h2.928V55.968z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M66.891,55.968h-2.928v4.956h2.928V55.968z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M15.878,55.968h3.041v4.956h-3.041V55.968z" />
        </g>
        <g id="Group_Level7">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="0" y1="102.3643" x2="7.9956" y2="102.3643">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,98.311h7.996v8.107H0V98.311z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="102.364" r="3.04" />
            <circle fill="#4C4C4C" cx="3.885" cy="102.364" r="2.026" />
        </g>
        <g id="Group_Level6">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0" y1="90.3711" x2="7.9956" y2="90.3711">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,86.486h7.996v7.77H0V86.486z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="90.315" r="3.041" />
            <circle fill="#4C4C4C" cx="3.885" cy="90.314" r="2.027" />
        </g>
        <g id="Group_Level5">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="0" y1="78.3779" x2="7.9956" y2="78.3779">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,74.324h7.996v8.107H0V74.324z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="78.378" r="3.04" />
            <circle fill="#4C4C4C" cx="3.885" cy="78.378" r="2.027" />
        </g>
        <g id="Group_Level4">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="0" y1="66.3848" x2="7.9956" y2="66.3848">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,62.5h7.996v7.77H0V62.5z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.857" cy="66.385" r="3.041" />
            <circle fill="#4C4C4C" cx="3.856" cy="66.385" r="2.027" />
        </g>
        <g id="Group_Level3">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="0" y1="54.3916" x2="7.9956" y2="54.3916">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,50.338h7.996v8.107H0V50.338z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="54.392" r="3.041" />
            <circle fill="#4C4C4C" cx="3.885" cy="54.392" r="2.027" />
        </g>
        <g id="Group_Level2">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0" y1="42.3984" x2="7.9956" y2="42.3984">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,38.514h7.996v7.77H0V38.514z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="42.455" r="3.041" />
            <circle fill="#4C4C4C" cx="3.885" cy="42.455" r="2.027" />
        </g>
        <g id="Group_Level1">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="0" y1="30.4063" x2="7.9956" y2="30.4063">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.14" style="stop-color:#979797" />
                <stop offset="0.32" style="stop-color:#AEAEAE" />
                <stop offset="0.52" style="stop-color:#BFBFBF" />
                <stop offset="0.73" style="stop-color:#C9C9C9" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,26.352h7.996v8.108H0V26.352z" />
            <circle fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" cx="3.885" cy="30.406" r="3.041" />
            <circle fill="#4C4C4C" cx="3.885" cy="30.406" r="2.027" />
        </g>
        <g id="Group_Controller">
            <path fill="#B2B2B2" d="M20.383,70.27h18.918v12.162H20.383V70.27z" />
            <path fill="#CCCCCC" d="M19.932,88.4h19.932l-0.563-5.969H20.383L19.932,88.4z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.383,70.27h18.918v12.162l0.563,5.969l0.676,2.14l-0.676,1.915   v20.044H19.932V92.229l-0.563-1.914l0.563-1.914l0.451-5.969V70.27" />
            <path fill="#999999" d="M27.928,72.297h2.027v1.576h-2.027V72.297z" />
            <path fill="#999999" d="M30.855,72.297h2.027v1.576h-2.027V72.297z" />
            <path fill="#999999" d="M34.008,72.297h2.027v1.576h-2.027V72.297z" />
            <path fill="#666666" d="M24.774,79.504H25.9v0.901h-1.126V79.504z" />
            <path fill="#666666" d="M28.829,79.504h1.126v0.901h-1.126V79.504z" />
            <path fill="#666666" d="M32.882,79.504h1.126v0.901h-1.126V79.504z" />
            <path fill="#666666" d="M36.937,79.504h0.901v0.901h-0.901V79.504z" />
            <path fill="#666666" d="M28.378,83.333h10.135v0.676H28.378V83.333z" />
            <path fill="#191919" d="M21.847,83.333h1.577v1.126h-1.577V83.333z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="29.8979" y1="112.0811" x2="29.8979" y2="92.5423">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M19.932,92.455h19.932v20.044H19.932V92.455z" />
            <path fill="#4C4C4C" d="M19.932,92.455h19.932l0.676-1.915H19.369L19.932,92.455z" />
            <path fill="#666666" d="M19.932,88.4h19.932l0.676,2.14H19.369L19.932,88.4z" />
        </g>
        <g id="Group_Steps">
            <path fill="#CCCCCC" d="M45.382,43.469v2.027H9.347v-2.027H45.382z" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M66.328,43.469v2.027h27.026v-2.027H66.328z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M45.382,43.469v2.027H9.347v-2.027H45.382" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M45.382,29.956h2.027v26.013h-2.027V29.956z" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M66.553,29.956h-2.139v26.013h2.139V29.956z" />
            <path fill="#CCCCCC" d="M45.382,29.956h2.027v26.013h-2.027V29.956z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="105.8359" x2="55.9111" y2="109.1353">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,105.968h21.959v2.928H44.932V105.968" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="99.4951" x2="55.9111" y2="102.3233">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,99.437h21.959v2.928H44.932V99.437" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="92.9258" x2="55.9111" y2="95.8281">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,92.904h21.959v2.929H44.932V92.904" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="86.4316" x2="55.9111" y2="89.5025">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,86.486h21.959v2.928H44.932V86.486" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="79.9385" x2="55.9111" y2="82.9942">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,79.955h21.959v2.928H44.932V79.955" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="73.3682" x2="55.9111" y2="76.2805">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,73.424h21.959v2.928H44.932V73.424" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="66.875" x2="55.9111" y2="69.7177">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,66.892h21.959v3.04H44.932V66.892" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="55.9111" y1="60.501" x2="55.9111" y2="63.3036">
                <stop offset="0" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.932,60.473h21.959V63.4H44.932V60.473" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M45.382,29.956h2.027l-2.478,56.531v26.013h-2.027V86.486   L45.382,29.956z" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M66.328,29.956h-1.914l2.477,56.531v26.013h2.027V86.486   L66.328,29.956z" />
            <path fill="#999999" stroke="#7F7F7F" stroke-width="0.25" d="M9.347,31.982h2.027v11.486H9.347V31.982z" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M66.328,29.956v2.027h27.026v-2.027H66.328z" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M95.381,29.956h-2.026v26.013h2.026V29.956z" />
            <path fill="#CCCCCC" d="M45.382,31.982H9.347v-2.027h36.036V31.982z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M45.382,29.956v2.027H9.347v-2.027H45.382" />
            <path fill="#CCCCCC" stroke="#7F7F7F" stroke-width="0.25" d="M16.554,29.956h1.689v26.013h-1.689V29.956z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6614583333333334, 0.0, 0.14, 0.5520833333333334, 45.0, 0.32, 0.4322916666666667, 91.0, 0.52, 0.34375, 125.0, 0.73, 0.2916666666666667, 145.0, 1.0, 0.2760416666666667, 151.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.6614583333333334, 0.0, 1.0, 0.53125, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.14583333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Body",[0.0, 0.265625, 0.0]);
		this.fillGradient(this.fills,"Group_Controller",[0.0, 0.4114583333333333, 99.0, 1.0, 0.2760416666666667, 151.0, 2.0, 0.5416666666666666, 49.0, 3.0, 0.5416666666666666, 49.0, 4.0, 0.5416666666666666, 49.0, 5.0, 0.53125, 0.0, 6.0, 0.53125, 0.0, 7.0, 0.53125, 0.0, 8.0, 0.53125, 0.0, 9.0, 0.53125, 0.0, 10.0, 0.13020833333333334, 0.0, 11.0, 0.3958333333333333, 0.0, 12.0, 0.53125, 0.0]);
		this.fillGradient(this.fills,"Group_Steps",[0.0, 0.2760416666666667, 151.0, 1.0, 0.2760416666666667, 151.0, 2.0, 0.2760416666666667, 151.0, 3.0, 0.2760416666666667, 151.0, 4.0, 0.2760416666666667, 151.0, 5.0, 0.2760416666666667, 151.0, 6.0, 0.2760416666666667, 151.0, 7.0, 0.5416666666666666, 49.0, 8.0, 0.2760416666666667, 151.0, 9.0, 0.2760416666666667, 151.0, 10.0, 0.2760416666666667, 151.0, 11.0, 0.2760416666666667, 151.0]);

        super.drawObject();
    }
}
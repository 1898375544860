import { SVGObject } from "../svgobject";

export class SVGDuct22 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 106.984" enable-background="new 0 0 112.5 106.984" xml:space="preserve">
        <g id="Group_Duct">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="50.677" x2="106.7568" y2="50.677">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.39" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" d="M39.189,0.001l11.487,11.262V0.001h56.081v89.865v11.486   H50.676V89.866L0,39.416L39.189,0.001z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M106.757,11.263H50.676v78.604h56.081" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M109.009,98.425v8.559h1.802v-8.559H109.009z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M46.396,98.425v8.559h1.802v-8.559H46.396z" />
        </g>
        <g id="Group_Joint_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="78.6602" y1="104.0559" x2="78.6602" y2="101.3528">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.5" d="M112.5,101.353v2.703H44.82v-2.703H112.5z" />
        </g>
        <g id="Group_Duct_Top">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="65.541" y1="87.8391" x2="65.541" y2="12.9519">
                <stop offset="0" style="stop-color:#D1D1D1" />
                <stop offset="0.22" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M52.703,12.951l25.676,37.5L52.703,87.839V12.951z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="78.3789" y1="50.3953" x2="104.2793" y2="50.3953">
                <stop offset="0" style="stop-color:#E4E4E4" />
                <stop offset="1" style="stop-color:#D6D6D6" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M104.279,12.951l-25.9,37.5l25.9,37.388V12.951z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="62.6699" y1="34.7419" x2="94.2012" y2="3.2107">
                <stop offset="0" style="stop-color:#C9C9C9" />
                <stop offset="0.19" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M104.279,13.289l-25.9,37.162L52.703,13.289H104.279z" />
            <path fill="#D4D4D4" d="M104.279,87.839l-25.9-37.388L52.703,87.839H104.279z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.39, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3645833333333333, 161.0, 0.22, 0.453125, 139.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.21875, 199.0, 1.0, 0.328125, 171.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4270833333333333, 145.0, 0.19, 0.453125, 139.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Duct_Top",[0.0, 0.34375, 167.0]);
        super.drawObject();
    }
}
export class ColorUtils{
    static convertformat=(color:string):string=>{
                return color.replace('0x','#');
    }
    static convertbackformat=(color:string):string=>{
            if (!color.includes('#')) return color;
            return color.replace('#','0x')
    }
    static darkColor(color:string, percent:number):string {

        let R = parseInt(color.substring(1,3),16);
        let G = parseInt(color.substring(3,5),16);
        let B = parseInt(color.substring(5,7),16);
        const OO = color.length>=9?color.substring(7,9):"";
    
        R = Math.floor(R * percent);
        G = Math.floor(G * percent);
        B = Math.floor(B * percent);
    
        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  
    
        const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB+OO;
    }
     static transColor (color:string, k:number):string
     {
        let opacity = parseInt(color.substring(7,9),16);
        opacity = Math.floor( opacity*k);
        const OO = (opacity.toString(16).length==1)?"0"+opacity.toString(16):opacity.toString(16);
        return color.slice(0,-2)+OO;
	}
}
import { SVGObject } from "../svgobject";

export class SVGBlower5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Layer_1">
            <polygon fill="#263A59" points="98.536,84.009 51.577,84.009 51.577,56.194 104.955,56.194 104.955,81.757  " />
        </g>
        <g id="Group_Layer_9">
            <g>
                <defs>
                    <rect id="SVGID_1_" width="112.5" height="112.5" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_2_)" fill="#596D8C" d="M109.234,65.203L70.607,33.446c0,0-6.023-4.432-8.788-5.563l-0.107-0.068    l-0.009,0.022c-2.906-1.172-6.081-1.823-9.408-1.823c-13.908,0-25.182,11.273-25.182,25.18c0,10.534,6.382,19.927,15.52,23.865    c0,0,6.432,1.968,9.506,1.968c0,0,49.715,4.166,50.113,4.166c5.833,0,9.686-4.519,9.686-9.448    C111.938,69.168,110.94,66.833,109.234,65.203" />
                <path clip-path="url(#SVGID_2_)" fill="#0D2140" d="M102.59,64.302c4.168,0,7.545,3.378,7.545,7.545s-3.377,7.546-7.545,7.546    c-4.167,0-7.545-3.379-7.545-7.546S98.423,64.302,102.59,64.302" />
            </g>
        </g>
        <g id="Group_Layer_10">
            <g>
                <defs>
                    <path id="SVGID_3_" d="M51.576,2.815v6.982C26.023,9.798,5.307,30.514,5.307,56.067c0,13.34,5.653,25.354,14.685,33.798h-0.384     l-7.446,5.742l-2.365,13.965h83.559l-2.364-13.965l-7.823-5.742h-0.005c9.032-8.444,14.685-20.458,14.685-33.798V2.815H51.576z" />
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0.0024" y1="112.502" x2="1.0024" y2="112.502" gradientTransform="matrix(92.5405 0 0 -92.5405 5.0768 10467.2354)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.58" style="stop-color:#D9D9DB" />
                    <stop offset="0.76" style="stop-color:#A8A9AB" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="5.307" y="2.815" clip-path="url(#SVGID_4_)" fill="url(#SVGID_5_)" width="92.541" height="106.757" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_6_" d="M51.576,2.815v6.982c0,0,6.424,0.203,13.467,2.199c3.287,0.932,9.421,3.5,11.894,5.129     c1.654,1.088,6.368,5.104,7.748,6.523c3.502,3.604,7.237,9.428,9.01,12.951c2.181,4.338,3.603,11.486,3.603,11.486l0.114-45.27     H51.576z" />
                </defs>
                <clipPath id="SVGID_7_">
                    <use xlink:href="#SVGID_6_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-4.882813e-004" y1="112.5" x2="0.9995" y2="112.5" gradientTransform="matrix(45.8335 0 0 -45.8335 51.6051 5181.6763)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.19" style="stop-color:#818285" />
                    <stop offset="0.4" style="stop-color:#A4A5A8" />
                    <stop offset="0.51" style="stop-color:#B1B3B6" />
                    <stop offset="0.73" style="stop-color:#8C8E91" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="51.576" y="2.815" clip-path="url(#SVGID_7_)" fill="url(#SVGID_8_)" width="45.835" height="45.27" />
            </g>
        </g>
        <g id="Group_Layer_2">
            <g>
                <defs>
                    <rect id="SVGID_9_" width="112.5" height="112.5" />
                </defs>
                <clipPath id="SVGID_10_">
                    <use xlink:href="#SVGID_9_" overflow="visible" />
                </clipPath>
                <rect y="49.212" clip-path="url(#SVGID_10_)" fill="#4D4D4D" width="102.59" height="4.617" />
            </g>
        </g>
        <g id="Group_Layer_3">
            <rect x="42.229" y="-0.049" fill="#4D4D4D" width="64.978" height="2.865" />
        </g>
        <g id="Group_Layer_4">
            <rect x="5.18" y="109.572" fill="#D6D6D2" width="92.792" height="2.928" />
            <path fill="#4D4D4D" d="M83.445,89.865l2.816,2.252c0,0-16.137,13.964-33.56,13.964c-21.077,0-35.81-13.964-35.81-13.964   l2.815-2.252c0,0,12.249,12.725,32.995,12.725C69.525,102.59,83.445,89.865,83.445,89.865" />
        </g>
        <g id="Group_Layer_5">
            <path fill="#8C8C8C" d="M51.577,12.162c21.768,0,39.416,17.646,39.416,39.415c0,21.768-17.647,39.414-39.416,39.414   S12.162,73.345,12.162,51.577C12.162,29.808,29.809,12.162,51.577,12.162" />
        </g>
        <g id="Group_Layer_6">
            <path fill="#4D4D4D" d="M87.684,51.711c0-2.702-0.104-2.915-0.104-2.915l-19.102-0.305l17.844-6.845c0,0-0.5-1.795-1.143-3.397   C84.176,35.74,84,35.582,84,35.582l-19.042,7.275l14.634-14.185c0,0-1.547-1.843-2.125-2.434c-0.57-0.581-2.465-2.25-2.465-2.25   L61.353,37.224l7.751-17.354c0,0-2.121-1.136-2.879-1.47c-0.744-0.327-3.123-1.172-3.123-1.172l-8.178,18.319l0.295-20.075   l-6.58-0.044l-0.281,18.945L41.57,16.678l-6.125,2.405l7.128,18.59L28.6,23.275l-4.684,4.585l13.36,13.768l-17.516-7.81   l-2.643,5.997l18.275,8.15l-19.992-0.32l-0.043,6.581l19.116,0.281l-17.906,6.841l2.408,6.127l18.481-7.089L23.287,74.123   l4.59,4.681l13.628-13.212l-7.772,17.404l6.001,2.639l8.137-18.221l-0.297,20.052l6.58,0.046l0.279-18.911l6.808,17.752   l6.125-2.403l-7.177-18.717l14.007,14.431c0,0,1.939-1.602,2.494-2.145c0.617-0.604,2.189-2.444,2.189-2.444L65.662,61.46   l17.389,7.752c0,0,1.205-2.208,1.52-2.918c0.348-0.79,1.123-3.083,1.123-3.083l-18.437-8.217l20.224,0.295   C87.48,55.289,87.684,53.438,87.684,51.711" />
            <path fill="#4C4C16" d="M51.577,18.468c18.285,0,33.108,14.823,33.108,33.108c0,18.285-14.823,33.108-33.108,33.108   c-18.285,0-33.108-14.823-33.108-33.108C18.469,33.292,33.292,18.468,51.577,18.468" />
            <path fill="#333300" d="M76.463,51.576c0,13.434-10.89,24.324-24.323,24.324c-13.435,0-24.324-10.891-24.324-24.324   c0-13.434,10.889-24.324,24.324-24.324C65.573,27.252,76.463,38.142,76.463,51.576" />
        </g>
        <g id="Group_Layer_7">
            <g>
                <defs>
                    <polygon id="SVGID_11_" points="54.325,75.9 50.417,75.9 49.208,63.532 49.208,39.618 49.917,27.252 54.292,27.252      55.071,39.615 55.071,63.536    " />
                </defs>
                <clipPath id="SVGID_12_">
                    <use xlink:href="#SVGID_11_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-0.042" y1="112.457" x2="0.9575" y2="112.457" gradientTransform="matrix(5.8638 0 0 -5.8638 49.4559 711.0011)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.19" style="stop-color:#818285" />
                    <stop offset="0.4" style="stop-color:#A4A5A8" />
                    <stop offset="0.51" style="stop-color:#B1B3B6" />
                    <stop offset="0.73" style="stop-color:#8C8E91" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="49.208" y="27.252" clip-path="url(#SVGID_12_)" fill="url(#SVGID_13_)" width="5.863" height="48.648" />
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_14_" points="27.874,53.266 27.868,48.792 40.349,48.026 63.928,48.026 76.412,48.618 76.412,53.153      64.234,53.89 40.046,53.89    " />
                </defs>
                <clipPath id="SVGID_15_">
                    <use xlink:href="#SVGID_14_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="-0.0576" y1="112.4414" x2="0.9424" y2="112.4414" gradientTransform="matrix(0 -5.8643 -5.8643 0 711.5295 53.549)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.19" style="stop-color:#818285" />
                    <stop offset="0.4" style="stop-color:#A4A5A8" />
                    <stop offset="0.51" style="stop-color:#B1B3B6" />
                    <stop offset="0.73" style="stop-color:#8C8E91" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="27.868" y="48.026" clip-path="url(#SVGID_15_)" fill="url(#SVGID_16_)" width="48.544" height="5.864" />
            </g>
        </g>
        <g id="Group_Layer_8">
            <g>
                <defs>
                    <path id="SVGID_17_" d="M52.14,39.264c-6.8,0-12.312,5.513-12.312,12.312c0,6.801,5.512,12.312,12.312,12.312     c6.799,0,12.312-5.511,12.312-12.312c0-5.862-4.097-10.768-9.584-12.009c-0.874-0.197-1.783-0.303-2.716-0.303     C52.148,39.264,52.144,39.264,52.14,39.264" />
                </defs>
                <clipPath id="SVGID_18_">
                    <use xlink:href="#SVGID_17_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_19_" cx="0" cy="112.5" r="1" gradientTransform="matrix(12.3125 0 0 -12.3125 52.1406 1436.7344)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="39.828" y="39.264" clip-path="url(#SVGID_18_)" fill="url(#SVGID_19_)" width="24.624" height="24.624" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_20_" d="M48.264,51.625c0,2.14,1.735,3.875,3.875,3.875c2.14,0,3.875-1.735,3.875-3.875s-1.735-3.875-3.875-3.875     C49.999,47.75,48.264,49.485,48.264,51.625" />
                </defs>
                <clipPath id="SVGID_21_">
                    <use xlink:href="#SVGID_20_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_22_" cx="0" cy="112.499" r="1" gradientTransform="matrix(3.875 0 0 -3.875 52.1406 487.5606)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="48.264" y="47.75" clip-path="url(#SVGID_21_)" fill="url(#SVGID_22_)" width="7.75" height="7.75" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_23_" d="M46.983,43.667c0,0.708,0.574,1.282,1.281,1.282c0.708,0,1.281-0.574,1.281-1.282     c0-0.707-0.573-1.281-1.281-1.281C47.557,42.386,46.983,42.96,46.983,43.667" />
                </defs>
                <clipPath id="SVGID_24_">
                    <use xlink:href="#SVGID_23_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_25_" cx="-0.3174" cy="112.1787" r="1" gradientTransform="matrix(1.2813 0 0 -1.2813 48.67 187.4023)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="46.983" y="42.386" clip-path="url(#SVGID_24_)" fill="url(#SVGID_25_)" width="2.562" height="2.563" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_26_" d="M55.072,43.667c0,0.708,0.574,1.282,1.281,1.282c0.708,0,1.282-0.574,1.282-1.282     c0-0.707-0.574-1.281-1.282-1.281C55.646,42.386,55.072,42.96,55.072,43.667" />
                </defs>
                <clipPath id="SVGID_27_">
                    <use xlink:href="#SVGID_26_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_28_" cx="-0.3174" cy="112.1787" r="1" gradientTransform="matrix(1.2813 0 0 -1.2813 56.7599 187.4023)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="55.072" y="42.386" clip-path="url(#SVGID_27_)" fill="url(#SVGID_28_)" width="2.563" height="2.563" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_29_" d="M55.071,58.615c0,0.708,0.574,1.282,1.282,1.282c0.708,0,1.281-0.574,1.281-1.282     s-0.573-1.281-1.281-1.281C55.645,57.334,55.071,57.907,55.071,58.615" />
                </defs>
                <clipPath id="SVGID_30_">
                    <use xlink:href="#SVGID_29_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_31_" cx="-0.3174" cy="112.1777" r="1" gradientTransform="matrix(1.2813 0 0 -1.2813 56.7589 202.3495)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="55.071" y="57.334" clip-path="url(#SVGID_30_)" fill="url(#SVGID_31_)" width="2.563" height="2.563" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_32_" d="M59.92,51.391c0,0.706,0.574,1.281,1.281,1.281c0.708,0,1.281-0.575,1.281-1.281     c0-0.708-0.573-1.282-1.281-1.282C60.494,50.109,59.92,50.683,59.92,51.391" />
                </defs>
                <clipPath id="SVGID_33_">
                    <use xlink:href="#SVGID_32_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_34_" cx="-0.3174" cy="112.1797" r="1" gradientTransform="matrix(1.2813 0 0 -1.2813 61.6075 195.1249)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="59.92" y="50.109" clip-path="url(#SVGID_33_)" fill="url(#SVGID_34_)" width="2.563" height="2.563" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_35_" d="M42.021,51.073c0,0.707,0.574,1.281,1.282,1.281c0.707,0,1.28-0.574,1.28-1.281     c0-0.708-0.573-1.281-1.28-1.281C42.595,49.792,42.021,50.365,42.021,51.073" />
                </defs>
                <clipPath id="SVGID_36_">
                    <use xlink:href="#SVGID_35_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_37_" cx="-0.3174" cy="112.1777" r="1" gradientTransform="matrix(1.2813 0 0 -1.2813 43.7091 194.8065)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="42.021" y="49.792" clip-path="url(#SVGID_36_)" fill="url(#SVGID_37_)" width="2.562" height="2.562" />
            </g>
            <g>
                <defs>
                    <path id="SVGID_38_" d="M46.645,58.615c0,0.708,0.574,1.282,1.281,1.282c0.708,0,1.282-0.574,1.282-1.282     s-0.574-1.281-1.282-1.281C47.219,57.334,46.645,57.907,46.645,58.615" />
                </defs>
                <clipPath id="SVGID_39_">
                    <use xlink:href="#SVGID_38_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_40_" cx="0.0371" cy="112.5371" r="1.0007" gradientTransform="matrix(1.281 0 0 -1.281 47.8794 202.7758)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="46.645" y="57.334" clip-path="url(#SVGID_39_)" fill="url(#SVGID_40_)" width="2.563" height="2.563" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_41_" width="112.5" height="112.5" />
                </defs>
                <clipPath id="SVGID_42_">
                    <use xlink:href="#SVGID_41_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_42_)" fill="#4D4D4D" d="M53.828,51.576c0,0.932-0.755,1.688-1.688,1.688    c-0.932,0-1.687-0.756-1.687-1.688s0.755-1.688,1.687-1.688C53.073,49.888,53.828,50.644,53.828,51.576" />
            </g>
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[]);
		this.fillGradient(this.gradients,"SVGID_2_",[]);
		this.fillGradient(this.gradients,"SVGID_3_",[]);
		this.fillGradient(this.gradients,"SVGID_4_",[]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7239583333333334, 0.0, 0.5, 0.16145833333333334, 214.0, 0.58, 0.3020833333333333, 177.0, 0.76, 0.6822916666666666, 81.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[]);
		this.fillGradient(this.gradients,"SVGID_7_",[]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[]);
		this.fillGradient(this.gradients,"SVGID_10_",[]);
		this.fillGradient(this.gradients,"SVGID_11_",[]);
		this.fillGradient(this.gradients,"SVGID_12_",[]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[]);
		this.fillGradient(this.gradients,"SVGID_15_",[]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[]);
		this.fillGradient(this.gradients,"SVGID_18_",[]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[]);
		this.fillGradient(this.gradients,"SVGID_21_",[]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[]);
		this.fillGradient(this.gradients,"SVGID_24_",[]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[]);
		this.fillGradient(this.gradients,"SVGID_27_",[]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[]);
		this.fillGradient(this.gradients,"SVGID_30_",[]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[]);
		this.fillGradient(this.gradients,"SVGID_33_",[]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[]);
		this.fillGradient(this.gradients,"SVGID_36_",[]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[]);
		this.fillGradient(this.gradients,"SVGID_39_",[]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[]);
		this.fillGradient(this.gradients,"SVGID_42_",[]);
        super.drawObject();
    }
}
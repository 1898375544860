import { SVGObject } from "../svgobject";

export class SVGPeople9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 32.098 112.5" enable-background="new 0 0 32.098 112.5" xml:space="preserve">
        <g id="Group_Chest_Neck">
            <polyline fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" points="24.676,21.359 19.483,19.707 18.244,17.455 14.303,17.455    13.177,20.27 7.543,22.009 16.572,39.633  " />
            <path fill="#E5B27F" d="M10.249,21.959l5.631,0.45l0.338,0.901l0.45-0.901l6.081-0.675l-5.855,1.464l-0.789,1.915l-0.676-2.027   L10.249,21.959z" />
        </g>
        <g id="Group_LeftArm">
            <polyline fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" points="25.789,38.626 26.354,29.392 26.016,36.148 26.803,39.302    26.354,45.496 28.042,55.18 27.141,57.545 27.479,60.924 28.154,61.486 28.268,59.01 28.605,58.896 27.93,65.203 28.718,65.99    30.744,61.262 31.195,58.107 30.857,55.743 32.096,44.933 32.096,38.514 31.308,30.293 29.731,23.761 26.916,22.072 24.676,21.359     " />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M17.119,64.302V74.1l1.125,10.923l-0.563,8.559l2.365,11.937   l-1.127,4.617l0.563,1.127l2.815,1.238h3.49l1.127-1.238l-2.928-5.068l1.126-14.414v-4.504l0.675-21.172l-0.675-9.797" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M7.997,51.802l-0.563,6.87L6.308,68.919l0.563,15.428l-0.563,4.617   l0.563,17.229l-1.126,2.816l-1.802,1.688l0.563,1.126h4.617l2.928-2.252l-0.563-3.379l-0.675-2.928l2.928-19.482l1.689-19.481" />
        </g>
        <g id="Group_RightArm">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M7.543,22.009l-3.487,1.077l-2.928,3.491l-0.563,3.941v8.671   l-0.563,5.744L0.564,55.18l0.563,4.617l0.676,2.141l2.14,2.59l1.126-0.226L3.605,61.6l-0.338-2.59l0.676-0.789l0.563,3.604   l0.789-0.563l0.112-2.928l-1.464-3.717l1.352-11.599l1.126-6.419l-0.113-6.532" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#9999FF" stroke="#4C4C4C" stroke-width="0.25" d="M11.713,107.32l-1.802-1.127H5.407l-0.675-15.54l0.675-6.87   L4.731,68.919L5.97,56.306l0.563-4.504l6.306-0.563l6.87,0.563h5.631l0.563,10.812l0.676,7.994L25.34,85.022l0.563,7.433   l-1.126,14.302l-0.45-0.563h-4.617l-0.563,0.563l-1.802-13.176l-0.563-9.234l-1.126-13.739l-1.126,13.176l-2.252,14.303   L11.713,107.32z" />
            <path fill="#7F7FE5" d="M15.655,70.045l-1.126,12.05l-2.252,15.428l-0.563,6.87l-0.676-5.068v-9.797l2.928-12.05l0.563-5.631   v-4.054v-2.252l0.563-10.361v9.797l0.563,3.378l2.365-4.054l-1.238,5.181l1.238,5.742l0.563,14.303l0.563,10.359l1.689,4.506   l-1.689,0.563l-1.126-7.433l-1.238-14.864L15.655,70.045z" />
        </g>
        <g id="Group_Vest">
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M6.533,50l-0.676-2.59l-0.113-5.968l1.126-4.842l0.337-1.577   L6.42,33.896L6.083,29.73l0.789-7.658l2.14-0.676l1.914,4.504l3.378,2.027l4.504,0.338l2.815-1.689l1.689-4.054l0.338-1.802   l2.252,0.789l-0.788,5.292v3.716l1.239,1.802l-0.451,3.378l0.676,4.167l-0.338,5.18L25.227,50l-2.928,1.464l-6.419,0.338   l-4.729-0.563L6.533,50z" />
            <path fill="#E5E5E5" d="M7.772,34.122l6.306,1.014l1.802-1.689l2.14,1.802l6.307-0.675l1.576-2.252l-0.225,3.153l0.563,3.941   L24.889,37.5l-3.941-1.239l-4.729-0.45l-5.405,0.45l-3.378,1.689l-1.239,2.252l1.126-5.293l-0.676-1.013L6.42,30.968L7.772,34.122z   " />
        </g>
        <g id="Group_Belt">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M6.533,52.365V50h18.919v2.365H6.533z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M14.641,49.662l2.59,0.113v2.478h-2.815L14.641,49.662z" />
            <path d="M14.979,50.113h1.915v1.802h-2.027L14.979,50.113z" />
        </g>
        <g id="Group_Pouch">
            <path fill="#CCCC33" stroke="#4C4C4C" stroke-width="0.25" d="M6.308,52.59l19.257-0.451l0.113,2.252l-19.82,1.013L6.308,52.59z" />
            <path fill="#E5E54C" stroke="#4C4C4C" stroke-width="0.25" d="M6.195,57.095l0.45-4.955h1.577l-0.338,4.955H6.195z" />
            <path fill="#FFFF66" stroke="#4C4C4C" stroke-width="0.25" d="M11.038,53.829l13.175-0.45l-0.788,3.041l-1.914,3.604l-3.153,0.338   h-3.153l-2.815,0.45l-1.238-1.352l-1.577-5.18L11.038,53.829z" />
            <path fill="#CCCC33" d="M10.587,56.982l1.464,2.364l1.464,0.338l2.027-0.563l2.702,0.113l3.154-0.113l1.576-2.139l-1.576,2.928   l-2.928,0.338h-3.267l-2.815,0.563l-1.126-1.352L10.587,56.982z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M25.678,52.252l-0.338-3.829h-1.239l0.226,3.829H25.678z" />
            <path fill="#E5E54C" stroke="#4C4C4C" stroke-width="0.25" d="M26.127,56.532l-0.449-4.843l-1.577-0.112l0.338,4.955H26.127z" />
            <path fill="#E5E54C" d="M19.033,54.955l-4.505,0.112l-2.59,0.676l1.014-0.901l1.914-0.338h3.267l2.814-0.45L19.033,54.955z" />
        </g>
        <g id="Group_Hammer">
            <path fill="#7F0000" stroke="#4C4C4C" stroke-width="0.25" d="M9.236,50.451L7.546,68.243h2.252l1.352-17.792H9.236z" />
            <path fill="#E5E54C" stroke="#4C4C4C" stroke-width="0.25" d="M8.222,58.559l0.563-6.982l2.59,0.112l-0.676,6.87H8.222z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M11.15,50.676l0.788-1.239l0.788-0.113L13.177,50l0.225-2.928   l-0.563,0.45h-0.788l-0.451-0.676l-2.478-0.112L6.42,47.748l-1.239,2.365l1.689-1.464l1.351-0.225v1.239l0.901,0.901L11.15,50.676z   " />
        </g>
        <g id="Group_Boots">
            <path fill="#663333" stroke="#4C4C4C" stroke-width="0.25" d="M27.141,111.824v-1.126l-1.688-1.688l-1.126-2.816l0.112-2.928   h-3.941l0.563,2.928l-1.239-2.365l-0.676,0.564v3.49l-0.563,1.689v1.126l0.563,1.126l2.253,0.676h5.18L27.141,111.824z" />
            <path fill="#663333" stroke="#4C4C4C" stroke-width="0.25" d="M3.042,111.824v-1.126l1.802-1.688l1.126-2.816l-0.338-3.49h3.941   l0.675,2.59l-0.112-2.027h1.126l0.676,0.563v2.929v1.126l0.338,1.689l-1.689,1.126l-1.239,1.126L7.096,112.5H3.605L3.042,111.824z" />
        </g>
        <g id="Group_CapRear">
            <path fill="#666600" stroke="#4C4C4C" stroke-width="0.25" d="M8.673,9.009l7.094-5.744l7.545,5.631l-3.941,2.252l-5.969,0.113   L8.673,9.009z" />
        </g>
        <g id="Group_Face">
            <path fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" d="M20.723,13.514h-9.235L10.362,12.5l-0.676-1.014V10.36l0.676-1.126   h11.599l0.563,1.126v1.126L21.961,12.5L20.723,13.514z" />
            <ellipse fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" cx="16.105" cy="10.923" rx="5.518" ry="7.207" />
            <path fill="#E5B27F" d="M15.993,13.401l1.576,0.338h-3.152L15.993,13.401z" />
            <path fill="#665940" d="M19.371,9.122l-2.703-0.45l0.226,0.45l2.702,0.225L19.371,9.122z" />
            <path fill="#665940" d="M12.614,9.122l2.703-0.45l-0.225,0.45l-2.703,0.225L12.614,9.122z" />
            <path fill="#665940" d="M13.74,11.261h0.451l0.225-0.676l-0.225-0.563H13.74l-0.113,0.563L13.74,11.261z" />
            <path fill="#665940" d="M17.795,11.261h0.449l0.227-0.676l-0.227-0.563h-0.449l-0.227,0.563L17.795,11.261z" />
        </g>
        <g id="Group_Hair">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M10.813,10.473l-0.451-1.352l-0.676,1.352l0.676-4.955l1.577-1.802   l1.802-0.901l2.252-0.225l2.251,0.225l1.803,0.901l1.576,1.802l0.9,4.955l-0.676-1.352l-0.449,1.352L20.497,6.87l-0.901-1.352   l-0.225-0.451L18.92,5.968l-5.18,0.676h-2.027L11.488,6.87L10.813,10.473z" />
            <path fill="#663300" d="M18.92,5.968l0.451-2.252h0.225v1.802l-0.225-0.451L18.92,5.968z" />
        </g>
        <g id="Group_Beard">
            <path fill="#F2BF8C" d="M12.276,15.878l-1.014-1.802L10.7,12.049V9.797l0.563,0.563l0.901,3.378l1.352,1.689l0.45-0.901   l2.027-0.225l2.365,0.225l0.45,1.126l1.239-1.689l1.126-3.604l0.338-0.563v2.252l-0.563,2.027l-1.014,1.802l-1.352,1.352   l-1.576,0.675h-1.802l-1.577-0.675L12.276,15.878z" />
        </g>
        <g id="Group_Cap">
            <path fill="#CC9999" d="M14.416,15.315l-0.451-0.338h4.054l-0.451,0.338H14.416z" />
            <path fill="#FFFF99" stroke="#4C4C4C" stroke-width="0.25" d="M8.673,9.009l3.041-0.789l7.882-0.113l3.717,0.789l-0.9-1.352   l-0.789-3.153L20.385,2.14l-3.153-1.352l-0.563-0.675L15.767,0l-0.901,0.113l-0.563,0.788l-2.928,1.464l-1.126,2.252L9.348,7.658   L8.673,9.009z" />
            <path fill="#E5E57F" d="M12.952,7.207l1.576-0.337L15.092,0l-1.915,6.419L9.686,7.658L12.952,7.207z" />
            <path fill="#E5E57F" d="M18.471,7.207L17.006,6.87L16.443,0l1.801,6.419l3.379,1.239L18.471,7.207z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
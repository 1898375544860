import { SVGObject } from "../svgobject";

export class SVGConveyor10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 44.936" enable-background="new 0 0 112.5 44.936" xml:space="preserve">
        <g id="Group_Pusher_Body">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="67.3652" y1="53.1628" x2="45.0207" y2="-8.2283">
                <stop offset="0" style="stop-color:#596D8C" />
                <stop offset="0.28" style="stop-color:#5D7190" />
                <stop offset="0.5" style="stop-color:#6F83A2" />
                <stop offset="1" style="stop-color:#7387A6" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M22.41,44.933V0.002h67.568v44.932H22.41z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.41,0.002v44.932" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.41,44.933V0.002h67.568v44.932H22.41" />
            <path fill="none" stroke="#BFD3F2" stroke-width="0.25" d="M22.41,44.933V0.002h67.568" />
        </g>
        <g id="Group_Handle">
            <path fill="#263A59" stroke="#4C4C4C" d="M22.41,44.933V0.002H0v44.932H22.41z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="25.7993" y1="38.7761" x2="-1.4746" y2="6.2723">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#F0F0F0" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M20.608,1.916H3.716v41.216h16.892V1.916z" />
            <path fill="none" stroke="#4C4C4C" d="M20.608,1.916H3.716v41.216h16.892V1.916" />
            <path fill="none" stroke="#FFFFFF" d="M3.716,43.132V1.916h16.892" />
            <path fill="none" stroke="#7387A6" d="M0,44.933V0.002h22.41" />
        </g>
        <g id="Group_Yellow_Stripe">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M89.978,44.933V0.002H112.5v44.932H89.978z" />
        </g>
        <g id="Group_Black_Stripe">
            <path fill="#333333" d="M89.978,0.002v11.374l11.261-11.374H89.978z" />
            <path fill="#333333" d="M101.238,11.15L89.978,22.411v11.374l11.261-11.374V11.15z" />
            <path fill="#333333" d="M101.238,33.673L89.978,44.933h11.261V33.673z" />
            <path fill="#333333" d="M101.126,0.002v11.374L112.5,0.002H101.126z" />
            <path fill="#333333" d="M112.5,11.15l-11.374,11.261v11.374L112.5,22.411V11.15z" />
            <path fill="#333333" d="M112.5,33.673l-11.374,11.261H112.5V33.673z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.875, 0.0, 0.28, 0.9010416666666666, 0.0, 0.5, 0.953125, 11.0, 1.0, 0.921875, 19.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.40625, 151.0, 0.5, 0.125, 223.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Handle",[0.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Yellow_Stripe",[0.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Black_Stripe",[0.0, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.3958333333333333, 0.0, 4.0, 0.3958333333333333, 0.0, 5.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMedical1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 55.404" xml:space="preserve" enable-background="new 0 0 112.5 55.404">
        <g id="Group_Light">
            <path d="M38.739,5.518h0.563V1.126h-0.563V5.518z" fill="#7F7F7F" />
            <path d="M38.739,4.955H37.05V1.577h1.689V4.955z" fill="#FF0000" />
        </g>
        <g id="Group_Body">
            <path d="M22.636,48.197l-0.901-4.392l-4.167-4.167l-5.855-0.9l-5.18,2.702   l-1.802,2.365L3.83,47.634H0.564l-0.563-3.941l0.563-1.013l1.014-0.113V29.842l1.689-1.014L13.29,25.45l7.207-11.599l2.703-1.126   h14.977l0.563,0.563h0.563V0h70.381v47.071h2.814v1.126H97.521l-0.9-4.054l-3.941-3.941l-5.519-0.901l-4.954,2.478l-2.479,5.067   v1.352H22.636" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M22.636,48.197l-0.901-4.392l-4.167-4.167l-5.855-0.9l-5.18,2.702l-1.802,2.365L3.83,47.634H0.564   l-0.563-3.941l0.563-1.013l1.014-0.113V29.842l1.689-1.014L13.29,25.45l7.207-11.599l2.703-1.126h14.977l0.563,0.563h0.563V0   h70.381v47.071h2.814v1.126H97.521l-0.9-4.054l-3.941-3.941l-5.519-0.901l-4.954,2.478l-2.479,5.067v1.352H22.636z" fill="#F2F2F2" />
            <path d="M20.497,40.99v-0.563h17.68v0.563H20.497z" fill="#E5E5E5" />
            <path d="M97.521,46.733l-2.59-4.955l-4.955-2.478l-5.405,0.901   l-3.941,3.941l-0.901,4.054H39.302v-1.126h39.301v-0.563l1.014-2.928l1.914-2.478l2.477-1.914l3.041-1.014h3.152l2.929,1.014   l2.59,1.914l1.915,2.478l0.9,2.928v0.563h13.963v1.126H97.521V46.733z" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M28.83,12.725h-4.504v-1.127h4.504V12.725z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M3.267,38.738H1.578v-5.519h1.689V38.738z" fill="#E5E5E5" />
            <path d="M58.107,45.945V28.828h17.23" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M13.29,25.45h2.703l6.644-11.148l-2.14-0.45L13.29,25.45z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M33.784,27.139V14.302h-9.459l-5.518,10.022v2.815H33.784z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M33.221,26.576V14.864h-8.558l-5.293,9.46v2.252H33.221z" fill="#B2B2B2" />
            <path d="M15.654,24.662l6.193-10.135l-1.577-0.338L13.74,24.662H15.654z" fill="#B2B2B2" />
            <path d="M6.083,27.927l0.563,1.464l0.45,5.968l31.081,0.113l1.689,1.126h62.611l4.955-10.022h2.252v-6.081   h-5.518l-5.068,9.91H11.6l-0.563-2.703l-0.788-1.238L6.083,27.927z" fill="#CC0000" />
            <path d="M23.199,47.634l-1.126-4.504h16.104l1.689,0.563h69.818v3.941H23.199z" fill="#CC0000" />
            <path d="M23.199,46.508h15.54v1.689H22.636l-0.901-4.392l-4.167-4.167   l-5.855-0.9l-5.18,2.702l-1.802,2.365L3.83,47.634H0.564l-0.563-3.941l0.563-1.013h2.14v3.828h0.563l1.014-3.828l1.802-2.365   l2.59-1.802l3.041-0.9h3.041l3.041,0.9l2.59,1.802l1.915,2.365L23.199,46.508z" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M33.784,16.553h7.207l8.333-14.414h5.518L43.694,21.058h-3.829l-1.689-0.563h-4.392V16.553z" fill="#CC0000" />
            <path d="M38.739,13.288h0.563v34.909h-0.563V13.288z" fill="#4C4C4C" />
            <path d="M104.053,7.207h3.941V2.703L104.053,7.207z" fill="#7F7F7F" />
            <path d="M98.535,7.207h3.941V2.703L98.535,7.207z" fill="#7F7F7F" />
            <path d="M48.761,7.207h3.829V2.703L48.761,7.207z" fill="#7F7F7F" />
            <path d="M43.131,7.207h3.941V2.703L43.131,7.207z" fill="#7F7F7F" />
            <path d="M4.956,38.175h3.941v-2.702L4.956,38.175z" fill="#7F7F7F" />
            <path d="M8.334,37.837H5.52V35.81h2.815V37.837z" fill="#FF0000" />
            <path d="M107.432,6.644h-2.703V3.265h2.703V6.644z" fill="#FF0000" />
            <path d="M46.509,6.644h-2.815V3.265h2.815V6.644z" fill="#FF0000" />
            <path d="M3.267,38.738h1.126v-3.829H3.267V38.738z" fill="#E5E500" />
            <path d="M101.35,40.427h-2.814v-3.266h2.814V40.427z" />
            <path d="M65.314,40.427H62.5v-3.266h2.814V40.427z" />
            <path d="M71.396,40.427h-2.703v-3.266h2.703V40.427z" />
            <path d="M51.464,27.702h-2.815v-3.378h2.815V27.702z" />
            <path d="M101.35,40.427h-1.688v-3.266h1.688V40.427z" fill="#7F7F7F" />
            <path d="M71.396,40.427h-1.577v-3.266h1.577V40.427z" fill="#7F7F7F" />
            <path d="M65.314,40.427h-1.688v-3.266h1.688V40.427z" fill="#7F7F7F" />
            <path d="M51.464,27.702h-1.689v-3.378h1.689V27.702z" fill="#7F7F7F" />
            <path d="M42.118,45.945V9.346h14.977" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M52.027,6.644h-2.703V3.265h2.703V6.644z" fill="#FFFFFF" />
            <path d="M101.913,6.644h-2.815V3.265h2.815V6.644z" fill="#FFFFFF" />
            <path d="M32.658,29.278h1.126v3.379h-1.126V29.278z" fill="#333333" />
        </g>
        <g id="Group_BehindWheels">
            <path d="M3.83,47.071l18.806,0.563l-1.014-5.518l-5.63-3.941h-5.518l-5.518,3.941L3.83,47.071z" />
            <path d="M79.166,47.634h18.918l-1.125-4.504l-5.631-4.392h-5.518l-5.519,4.392L79.166,47.634z" />
        </g>
        <g id="Group_AmbulanceSymbol">
            <path d="M103.715,11.036h1.576v7.207h-1.576V11.036z" fill="#CC0000" />
            <path d="M101.012,13.513l0.789-1.352l6.193,3.604l-0.788,1.352L101.012,13.513z" fill="#CC0000" />
            <path d="M107.206,12.162l0.788,1.352l-6.193,3.604l-0.789-1.352L107.206,12.162z" fill="#CC0000" />
        </g>
        <g id="Group_BackWheel">
            <circle cx="88.625" cy="48.197" r="7.207" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="88.625" cy="48.197" r="4.955" fill="#7F7F7F" />
            <circle cx="88.625" cy="48.084" r="3.829" fill="#E5E5E5" />
        </g>
        <g id="Group_FrontWheel">
            <circle cx="13.29" cy="48.197" r="7.207" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="13.29" cy="48.197" r="4.955" fill="#7F7F7F" />
            <circle cx="13.177" cy="48.084" r="3.829" fill="#E5E5E5" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Port3">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-533.2466" y1="-61.8921" x2="-533.2466" y2="-5.7515" gradientTransform="matrix(0 -1 -1 0 22.5 -477)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M28.252,112.492V0h56.141v112.492H28.252z" />
        </g>
        <g id="Group_Port2">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="25.2153" y1="84.4814" x2="25.2153" y2="28.0796" gradientTransform="matrix(-1 0 0 1 115.2539 0)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M112.416,28.079v56.25l-28.131,0.152c-11.113,0-16.624-12.613-16.624-28.172   c0-15.559,5.511-28.171,16.624-28.171L112.416,28.079z" />
        </g>
        <g id="Group_Port1">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="21.5532" y1="84.5" x2="21.5532" y2="28.0986" gradientTransform="matrix(1 0 0 1 0.8242 0)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0,28.098v56.25L28.131,84.5c11.113,0,16.624-12.613,16.624-28.171   c0-15.559-5.511-28.171-16.624-28.171L0,28.098z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGSegpipe13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.309" enable-background="new 0 0 112.5 98.309" xml:space="preserve">
        <g id="Group_Segmented_Pipe">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1592" y1="47.1182" x2="22.7926" y2="13.7516" gradientTransform="matrix(0.7071 -0.7071 -0.7071 -0.7071 52.8879 124.134)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M6.099,98.213l106.238,0.082l0.126-47.188H6.223L6.099,98.213z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-0.1938" y1="83.4697" x2="0.0193" y2="36.3613" gradientTransform="matrix(1 0.0045 0.0045 -1 6.8603 134.6272)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="7.596,51.156 6.508,51.156 6.489,98.26 7.543,98.266  " />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="33.856" y1="83.6621" x2="34.0695" y2="36.4717" gradientTransform="matrix(1 0.0045 0.0045 -1 6.8603 134.6272)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="41.629,51.117 40.557,51.125 40.579,98.285 41.607,98.309  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.3892" x1="40.393" y1="98.297" x2="40.352" y2="51.138" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.3892" x1="6.256" y1="98.303" x2="6.213" y2="51.143" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="70.2148" y1="83.7871" x2="70.428" y2="36.6787" gradientTransform="matrix(1 0.0045 0.0045 -1 6.8603 134.6272)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" points="78.007,51.156 76.919,51.156 76.898,98.26 77.955,98.266  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="104.7461" y1="83.9814" x2="104.9597" y2="36.791" gradientTransform="matrix(1 0.0045 0.0045 -1 6.8603 134.6272)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="112.521,51.117 111.449,51.125 111.47,98.285 112.498,98.309  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.3892" x1="111.285" y1="98.297" x2="111.245" y2="51.138" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.3892" x1="76.561" y1="98.297" x2="76.521" y2="51.138" />
        </g>
        <g id="Group_Angle">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="25.3677" y1="58.5615" x2="59.0127" y2="24.9165">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M0,33.154L33.5,0l47.25,46.654c0,0,4,4,0.75,6.5s-9.25,8.75-21.75,9.25s-29.5-0.25-35.75-5.75   S0,33.154,0,33.154z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.4" d="M23.204,55.618c0.313,0.278,0.61,0.547,0.879,0.786   c6.25,5.498,23.252,6.25,35.752,5.749c12.5-0.5,18.496-6.75,21.748-9.25c0.796-0.613,1.152-1.313,1.245-2.026" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="23.2871" y1="56.1758" x2="82.9116" y2="56.1758">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M81.393,50.337c-0.098-0.043-0.195-0.09-0.288-0.134c-4.81,4.587-11.299,9.06-18.14,9.814   c-2.212,0.62-4.624,0.986-7.275,0.986c-0.986,0-1.938-0.049-2.861-0.14c-0.088,0.006-0.156,0.037-0.244,0.039   c-3.628,0.066-7.373-0.336-11.064-0.954c-0.088,0-0.176-0.022-0.264-0.024c-1.035-0.021-2.061-0.129-3.076-0.271   c-1.758-0.229-3.501-0.524-5.181-0.793c-2.285-0.363-4.546-1.367-6.636-2.742c-0.435,0.025-0.859,0.068-1.309,0.076   c-0.225,0.07-0.464,0.176-0.688,0.239c6.406,5.349,23.179,6.081,35.547,5.586c12.5-0.501,18.501-6.751,21.753-9.251   c0.796-0.612,1.152-1.315,1.245-2.025C82.403,50.685,81.891,50.555,81.393,50.337z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}
import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { format, } from 'date-fns'
import { ru, enUS, fr, de, es, it, pt, pl, zhCN } from "date-fns/locale";
import authModule from "@/store/auth.module";


export class ClockTile extends Tile{
	dateformat:string;
  timeformat:string;
  timer:NodeJS.Timeout;

  public deleteObject():void{
    super.deleteObject()
    clearTimeout(this.timer)
  }
    public drawObject():void{
      this.setUpdate(true)
    //this.timer = setTimeout(()=>{this.drawObject()}, 1000);
		super.initObject();

           const locales = {
            "en-US": enUS,
            'en': enUS,
            "fr-FR": fr,
            'de-DE': de,
            'pt-BR': pt,
            'pl-PL': pl,
            'ru-RU': ru,
            'ru': ru,
            'es-ES': es,
            'es': es,
            'it-IT': it,
            'zh-CN': zhCN
            // add other locales you need
          };

        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
          const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
          const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
          if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }
        
        const size = this.width < this.height ? this.width : this.height;
        
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
          clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
          },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)

        const titleText = new Konva.Text({
            x: size*0.05,
            y: size*0.1-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.title,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
          });  
          group.add(titleText)

          const descriptionText = new Konva.Text({
            x: size*0.05,
            y: this.height - size * 0.05-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.description,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
          });  
          group.add(descriptionText)

          const timeRect = new Konva.Rect({
            x: 0,
            y: size * 0.15,
            width: this.width,
            height: this.height*0.4,
            fill: ColorUtils.darkColor(ColorUtils.convertformat(bgcolor), 0.5)
        });
        group.add(timeRect)

        let loc = enUS
        if(authModule.currentUser != null){
          loc =  locales[authModule.currentUser.locale]? locales[authModule.currentUser.locale] : enUS
        }
        const time = format(Date.now(), this.timeformat).toString()
        const dateformat = this.dateformat.replaceAll('Y', 'y')
        const date = format(Date.now(), dateformat, {locale:loc}).toString()
        const weekDay = format(Date.now(), 'EEEE', {locale:loc}).toString()
    
        const timeText = new Konva.Text({
            x: size*0.05,
            y: size * 0.15,
            verticalAlign:"middle",
            width: this.width-size*0.05*2,
            height: this.height*0.4,
            text: time,
            fontSize: 0.3*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'center'
        });  
        group.add(timeText)

        const dateText = new Konva.Text({
            x: size*0.05,
            y: this.height - size * 0.15- 0.1*size,
            verticalAlign:"bottom",
            width: this.width-size*0.05*2,
            height: 0.1*size,
            text: date,
            fontSize: 0.1*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'left'
        });  
          group.add(dateText) 
          
          const dateOfWeekText = new Konva.Text({
            x: size*0.05,
            y: this.height - size * 0.275- 0.1*size,
            verticalAlign:"bottom",
            width: this.width-size*0.05*2,
            height: 0.1*size,
            text: weekDay,
            fontSize: 0.1*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            align: 'left'
        });  
          group.add(dateOfWeekText)  

        this.node.add(group)     
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.908 112.5" enable-background="new 0 0 74.908 112.5" xml:space="preserve">
        <g id="Group_BackLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="12.7961" y1="91.4385" x2="17.3274" y2="91.4385">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#5C5C5C" />
                <stop offset="0.77" style="stop-color:#595959" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M12.796,77.576v26.658c0,0,0.533,1.066,2.398,1.066c1.51,0,2.133-1.066,2.133-1.066V79.176   L12.796,77.576z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="57.3147" y1="91.4385" x2="62.1125" y2="91.4385">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#5C5C5C" />
                <stop offset="0.77" style="stop-color:#595959" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M62.113,77.576v26.658c0,0-0.721,1.066-2.398,1.066c-1.729,0-2.399-1.066-2.399-1.066V79.176   L62.113,77.576z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.441406e-004" y1="48.1855" x2="74.9084" y2="48.1855">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M74.908,64.514V6.133H0v58.38c0,0,11.463,25.725,37.321,25.725   C64.068,90.238,74.908,64.514,74.908,64.514z" />
            
                <radialGradient id="SVGID_4_" cx="37.4543" cy="77.9766" r="28.1428" gradientTransform="matrix(1 0 0 1.3 0 -23.393)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M74.908,65.313v-0.8c0,0-5.454,4.82-29.856,6.132c-3.727,0.2-11.2,0.156-14.929,0   C8.788,69.75,0,64.514,0,64.514v0.8c0,0,3.253,26.125,37.588,26.125C72.192,91.438,74.908,65.313,74.908,65.313z" />
            <ellipse fill="#7F7F7F" cx="37.322" cy="6.133" rx="37.32" ry="6.131" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="4.5315" y1="91.4385" x2="11.4631" y2="91.4385">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M4.531,70.379v40.52c0,0,0.642,1.6,3.466,1.6c2.52,0,3.466-1.6,3.466-1.6V79.709   c0,0-0.065-3.291-2.134-6.459C7.454,70.379,4.531,70.379,4.531,70.379z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="63.178" y1="91.4385" x2="70.1106" y2="91.4385">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M70.111,70.379v40.52c0,0-1.125,1.6-3.466,1.6c-2.284,0-3.466-1.6-3.466-1.6V79.709   c0,0-0.085-3.142,1.9-5.959C67.454,70.379,70.111,70.379,70.111,70.379z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.7135416666666666, 0.0, 0.77, 0.6927083333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.7135416666666666, 0.0, 0.77, 0.6927083333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
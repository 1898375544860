import { SVGObject } from "../svgobject";

export class SVGMiscpipe1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 37.498" enable-background="new 0 0 112.5 37.498" xml:space="preserve">
        <g id="Group_Short_Horizontal_Copper">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.25" y1="37.4978" x2="56.25" y2="2.441406e-004">
            <stop offset="0" style="stop-color:#663300" />
            <stop offset="0.5" style="stop-color:#CC6633" />
            <stop offset="1" style="stop-color:#663300" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M0.003,0h112.493v37.498H0.003V0z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.9270833333333334, 0.0, 1.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGDuct25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.162 112.5" enable-background="new 0 0 112.162 112.5" xml:space="preserve">
        <g id="Group_Duct_Joint">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M0,96.283L15.879,112.5l2.702-2.928L2.59,93.581L0,96.283z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M93.355,2.815l16.216,15.991l2.591-2.703L96.283,0L93.355,2.815z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="58.9529" y1="59.0664" x2="53.4341" y2="53.5477">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M104.391,2.815l5.405,5.406L7.996,109.797l-5.406-5.405   L104.391,2.815z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.fills,"Group_Duct_Joint",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);


        super.drawObject();
    }
}
import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'

@Module({ name:"HistoryAndEvents", dynamic:true, store})
class HistoryAndEvents extends VuexModule {
  public enabled:boolean;
  public tagname:string;

    @Mutation
    public setEventsAndHistoryTagname(tagname:string){
       this.tagname = tagname
    }
    @Mutation
    public setEventsAndHistorygEnabled(value:boolean){
      this.enabled = value
    }
    
    get getEventsAndHistoryEnable():boolean{   
      return this.enabled;
    }
    get getEventsAndHistoryTagname():string{
        return this.tagname
    }

   
}

export default getModule(HistoryAndEvents);
import { SVGObject } from "../svgobject";

export class SVGHeating10 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 34.459" enable-background="new 0 0 112.5 34.459" xml:space="preserve">
        <g id="Group_Heat_Exchanger">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="29.8428" x2="56.1938" y2="11.4868">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M1.126,11.486h110.134v18.356H1.126V11.486" />
        </g>
        <g id="Group_Hanger">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M60.698,11.486l1.351-3.378l3.379-1.013l3.153,1.013l1.351,3.378   h-1.351L67.68,9.234l-2.252-0.676l-2.252,0.676l-1.127,2.252H60.698z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M42.455,11.486l1.352-3.378l3.153-1.013l3.378,1.013l1.352,3.378   h-1.352l-1.126-2.252l-2.252-0.676l-2.252,0.676l-0.901,2.252H42.455z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M51.689,9.009h9.009v3.604h-9.009V9.009z" />
        </g>
        <g id="Group_Stand">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M68.355,20.721h1.576v11.937h-1.576V20.721z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M42.455,20.721h1.577v11.937h-1.577V20.721z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M38.852,34.459v-1.802h5.18v1.802H38.852z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M68.355,34.459v-1.802h5.181v1.802H68.355z" />
        </g>
        <g id="Group_Adjustments">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,9.122h4.504v2.59H0V9.122z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,14.189h4.504v2.59H0V14.189z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,19.369h4.504v2.478H0V19.369z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,24.437h4.504v2.59H0V24.437z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,29.505h4.504v2.59H0V29.505z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.122,9.122h9.234v2.59H9.122V9.122z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.122,14.189h9.234v2.59H9.122V14.189z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.122,19.369h9.234v2.478H9.122V19.369z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.122,24.437h9.234v2.59H9.122V24.437z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.122,29.505h9.234v2.59H9.122V29.505z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,32.095h-4.617v-2.59h4.617V32.095z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,27.026h-4.617v-2.59h4.617V27.026z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,21.96h-4.617v-2.591h4.617V21.96z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,16.892h-4.617v-2.59h4.617V16.892z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,11.711h-4.617v-2.59h4.617V11.711z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M103.266,32.095h-9.234v-2.59h9.234V32.095z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M103.266,27.026h-9.234v-2.59h9.234V27.026z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M103.266,21.96h-9.234v-2.591h9.234V21.96z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M103.266,16.892h-9.234v-2.59h9.234V16.892z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M103.266,11.711h-9.234v-2.59h9.234V11.711z" />
        </g>
        <g id="Group_Couplings">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="11.4302" y1="34.459" x2="11.4302" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M10.248,6.869h2.365v27.59h-2.365V6.869" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.2524" y1="34.459" x2="2.2524" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" d="M1.126,6.869h2.252v27.59H1.126V6.869" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="16.0474" y1="34.459" x2="16.0474" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.1" d="M14.865,6.869h2.365v27.59h-2.365V6.869" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="100.957" y1="34.459" x2="100.957" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M102.139,34.459h-2.364V6.869h2.364V34.459" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="110.1348" y1="34.459" x2="110.1348" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E5E5E5" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.1" d="M111.261,34.459h-2.252V6.869h2.252V34.459" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="96.3398" y1="34.459" x2="96.3398" y2="6.8696">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.1" d="M97.521,34.459h-2.364V6.869h2.364V34.459" />
        </g>
        <g id="Group_Inlet_Outlet_Taps">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="74.5488" y1="9.4033" x2="84.7969" y2="9.4033">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M84.797,12.612V3.378H74.549v9.234l5.181,2.815L84.797,12.612z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="71.0586" y1="1.6895" x2="88.2881" y2="1.6895">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.1" d="M71.059,3.378V0h17.229v3.378H71.059" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="26.3516" y1="9.4033" x2="36.5991" y2="9.4033">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M36.599,12.612V3.378H26.352v9.234l5.18,2.815L36.599,12.612z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="22.8608" y1="1.6895" x2="40.0903" y2="1.6895">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.1" d="M22.861,3.378V0H40.09v3.378H22.861" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="103.7158" y1="10.3037" x2="107.5449" y2="10.3037">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.1" d="M107.545,11.486V7.996h-3.829v3.491l1.915,1.126   L107.545,11.486z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="102.5898" y1="7.4326" x2="108.4453" y2="7.4326">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.1" d="M102.59,7.996V6.869h5.855v1.126H102.59" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="103.4902" y1="30.9121" x2="107.4316" y2="30.9121">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.1" d="M103.49,29.729v3.491h3.941v-3.491l-1.914-1.126   L103.49,29.729z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="102.5898" y1="33.8398" x2="108.4453" y2="33.8398">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.1" d="M108.445,33.221v1.238h-5.855v-1.238H108.445" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.20833333333333334, 201.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.25, 191.0, 1.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
import { FillingProperty } from "./fillingproperty";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";

export class SliderControlProperty extends FillingProperty{
    snaptotick:boolean;
    decimalpos:number;
    
    

    public getCurrentValue(tagnames:string[]): number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return this.minimumvalue;
		const min = this.minimumvalue;
		const max = this.maximumvalue
        let value = +tag.tagvalue
		if (value>max) value=max;
		if (value<min) value=min;
		return value;
    }

    public getCurrentFilling(tagnames:string[], height:number):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return height/20;
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
        if (percent<0) return height/20
        if (percent>1) return height
        return percent*9*height/10+height/20; 

    }
    public getCurrentAppleFilling(tagnames:string[], height:number, width:number):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return width/5;
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
        if (percent<0) return width/5
        if (percent>1) return height-width/5
        return percent*(height-width*2/5)+width/5; 

    }
    public getCurrentFillingFromValue(tagnames:string[], height:number):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return 0;
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
        if (percent<0) return 0
        if (percent>1) return height
        return percent*height; 
    }
    public setCurrentFilling(tagnames:string[], fillheight:number, height:number):void{
        const tag:tag = this.getTag(tagnames);
        
        if (tag==null) return;
        const percent:number = (fillheight-height/20)/(height*18/20)
        tag.tagvalue = ((this.maximumvalue - this.minimumvalue)*percent+this.minimumvalue).toFixed(this.decimalpos).toString();

    }

    public setCurrentFillingFromValue(tagnames:string[], fillheight:number, height:number):void{
        const tag:tag = this.getTag(tagnames);
        
     
        if (tag==null) return;
        const percent:number = (fillheight-height*6/20-height/40)/(height*12/20)
        
        tag.tagvalue = ((this.maximumvalue - this.minimumvalue)*percent+this.minimumvalue).toString();
    }   
    public setCurrentHorFillingFromValue(tagnames:string[], widthfill:number, width:number):void{
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return;
        const percent:number = (widthfill-width*1/10+width/50)/(width*8/10)
        tag.tagvalue = ((this.maximumvalue - this.minimumvalue)*percent+this.minimumvalue).toFixed(this.decimalpos).toString();
    }   
    public setCurrentAppleFillingFromValue(tagnames:string[], fillheight:number, height:number, width:number):void{
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return;
        const percent:number = (fillheight-width/5)/(height-2*width/5)
        tag.tagvalue = ((this.maximumvalue - this.minimumvalue)*percent+this.minimumvalue).toFixed(this.decimalpos).toString();
    
    }           
    public sendTagValue(tagnames:string[], interval=10):void{
       
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return;
       
            let percent= (+tag.tagvalue-this.minimumvalue)/
							(this.maximumvalue-this.minimumvalue);
        if (this.snaptotick){                   
					const decpercent = Math.floor(percent*interval);
					percent = decpercent/interval;
                }
					tag.tagvalue = ((this.maximumvalue-this.minimumvalue)*percent+this.minimumvalue).toFixed(this.decimalpos).toString();
                    
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:tag.tagvalue,datetime:0});
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ImageProperty } from './properties/imageproperty';
import Konva from "konva";
import { Button } from "./button";
import { SuperGif } from '../utils/supergif/super-gif';
import { Value } from '../value';
import { ImageView } from './image';


export class ImageButton extends Button{
    transparent:boolean;
    supergif:SuperGif;
    imageObj:HTMLImageElement;

    public drawObject():void{
        super.initObject();

        let imagebase = "";
        const imageprop = this.properties['image'];
        if (imageprop!=null){	
            const imageproperty:ImageProperty =Object.assign(new ImageProperty(), imageprop);
            if (imageproperty!=null) 
                    imagebase = imageproperty.getImage(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, fillcolor2)
              gradient.addColorStop(1, ColorUtils.darkColor(fillcolor2, 0.5))
        if(!this.transparent){
        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            stroke: this.type3d==0? gradient: ColorUtils.darkColor(fillcolor2,0.5),
            strokeWidth: this.width/25
          });
          if (this.type3d==0){
            rect.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rect.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rect.fillLinearGradientColorStops([0, 'white',
               0.5, ColorUtils.darkColor(fillcolor2, 0.85), 
                1, ColorUtils.darkColor(fillcolor2,0.5)]);    
          }
          else{
            rect.fill(fillcolor2)
          }
          this.node.add(rect)
        }

        const imagev = new ImageView();
        imagev.imagebase = imagebase;
        imagev.width = this.width*4/5;
        imagev.height =  this.height*4/5;
        imagev.posx = this.width*1/10;
        imagev.posy = this.height*1/10;
        imagev.initLayer(this.layer)
        imagev.drawObject()
        this.node.add(imagev.rotatednode)
       /* if (this.imageObj==null)
          this.imageObj = new Image();
          
          const width = this.width;
          const height = this.height;
          const node = this.node;
          const byteCharacters = atob(imagebase);
          const gif = byteCharacters.includes("GIF");
          const svg = byteCharacters.includes("svg>");
          if (svg){
            console.log("SVG=true")
            const parser = new DOMParser();
            const doc = parser.parseFromString(byteCharacters, "image/svg+xml");
            doc.documentElement.setAttribute("width", (width*4/5).toString())
            doc.documentElement.setAttribute("height", (height*4/5).toString())
            
            //doc.documentElement.setAttribute("left", (width*1/5).toString())
            //doc.documentElement.setAttribute("top", (height*1/5).toString())
            //let currentStyle = doc.documentElement.getAttribute('style') || '';
           let currentStyle = 'position: absolute;'; // Set position to absolute
            currentStyle += 'left: ' + width*1/10 + 'px;'; // Set new position x
            currentStyle += 'top: ' + height*1/10 + 'px;'
            doc.documentElement.setAttribute("style", currentStyle)
   
           
            doc.documentElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
            const svg = new XMLSerializer().serializeToString(doc)
            const url =  'data:image/svg+xml;base64,' + btoa(svg);

            Konva.Image.fromURL(url, image => {
              
                this.node.add(image);
                this.layer.draw();
            })
          }
         
          else 
          {
            if (gif ){
            if (this.supergif==null){
                const byteNumbers = new Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                   byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
               const byteArray = new Uint8Array(byteNumbers);
                const objectURL = URL.createObjectURL(new Blob([byteArray], {type: 'image/gif'}))
               this.imageObj.src = objectURL
               this.supergif = new SuperGif(this.imageObj,{});
              
             this.supergif.load(function(){
          
                 console.log("loaded")
              })
              
            }
             
            const gifanimation = new Konva.Animation(() => {const a =1;}, this.layer);
             gifanimation.start()
            
         }
          else{
            this.imageObj.src ="data:image/png;base64,"+imagebase;
           }
        
          //imageObj.onload = function () {
        
          const img = new Konva.Image({
          
           x: width/10,
            y: height/10,
            width: width*4/5,
            height: height*4/5,
          //  image: imageObj,
           image:gif?this.supergif.getCanvas():this.imageObj
  
          });
  
          // add the shape to the layer
          node.add(img);
        }*/
        
        
       
      
        this.setFunctions();                     
    }
    
    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "transparent": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      //this.drawObject();
  }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "transparent": {value.datatype=0; value.value = (this[field]); break}
      }
      console.log('getField value.value', value.value)
      return value;
    }
   
}
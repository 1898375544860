import { SVGObject } from "../svgobject";

export class SVGSegpipe7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Segmented_Pipe">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="65.627" y1="67.2041" x2="45.1547" y2="46.7318" gradientTransform="matrix(-1 0 0 1 111.6641 -0.9717)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M112.983,92.306L20.108-0.643l-20.58,20.364l92.91,92.91L112.983,92.306z" />
        </g>
        <g id="Group_Ring">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="6.3906" y1="106.7998" x2="6.5214" y2="77.8968" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="76.237,96.133 76.952,96.848 97.401,76.424 96.711,75.73  " />
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-15.1001" y1="106.8271" x2="-14.9691" y2="77.8768" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="91.358,111.429 92.065,112.128 112.508,91.654 111.842,90.97  " />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="28.6279" y1="107.0029" x2="28.7587" y2="78.0999" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_4_)" points="60.442,80.479 61.155,81.193 81.604,60.771 80.913,60.076  " />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="49.626" y1="107.0859" x2="49.7568" y2="78.1814" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_5_)" points="45.602,65.623 46.316,66.338 66.767,45.915 66.075,45.22  " />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="70.5098" y1="107.2021" x2="70.6408" y2="78.2518" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_6_)" points="30.832,50.884 31.538,51.583 51.981,31.11 51.316,30.426  " />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="114.2363" y1="107.3779" x2="114.3671" y2="78.4749" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="-0.084,19.936 0.628,20.649 21.077,0.227 20.386,-0.468  " />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="92.6035" y1="107.3018" x2="92.7345" y2="78.3494" gradientTransform="matrix(-0.7039 -0.7103 -0.7103 0.7039 156.96 25.8593)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="15.208,35.261 15.915,35.96 36.36,15.489 35.695,14.803  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="97.677" y1="76.942" x2="77.234" y2="97.419" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="112.604" y1="91.867" x2="92.164" y2="112.345" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="81.857" y1="61.126" x2="61.42" y2="81.602" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="52.143" y1="31.391" x2="31.7" y2="51.868" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="67.072" y1="46.315" x2="46.631" y2="66.794" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="21.498" y1="0.745" x2="1.055" y2="21.222" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="36.325" y1="15.575" x2="15.886" y2="36.051" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}
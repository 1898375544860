import { SVGObject } from "../svgobject";

export class SVGMedical2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 52.143" xml:space="preserve" enable-background="new 0 0 112.5 52.143">
        <g id="Group_Bottom">
            <path d="M53.829,51.015H23.423l-2.59-1.576v-2.14h1.576v1.013l1.577,1.127   h4.167l0.45-7.32h1.126v7.32h18.807l0.563-7.883l1.014-0.563v8.446h3.716V51.015z" fill="#7F0000" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M53.829,52.142H19.707l-2.928-1.689v-2.365h1.802v1.126   l1.802,1.239H25l0.563-8.221h1.239v8.221h21.171l0.563-8.896l1.126-0.563v9.46h4.167V52.142z" fill="#BF3F3F" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Body">
            <path d="M39.752,12.839l1.689-5.293l1.239-1.802l1.126-0.563h6.532l1.126,0.563l1.126,1.802l1.802,5.293   H39.752z" fill="#E5E5E5" />
            <path d="M39.752,12.839l1.689-5.293l1.239-1.802l1.126-0.563h6.532l-5.743,0.901l-1.126,1.464l-1.351,5.293   H39.752z" fill="#FFFFFF" />
            <path d="M48.986,12.839l1.352-2.027l1.014-3.041L51.014,5.97l-0.676-0.788l1.126,0.563l1.126,1.802   l1.802,5.293H48.986z" fill="#999999" />
            <path d="M39.752,12.839l1.689-5.293l1.239-1.802l1.126-0.563h6.532   l1.126,0.563l1.126,1.802l1.802,5.293H39.752" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M37.387,12.839l18.807-5.856h9.347l1.125,6.982L37.387,12.839z" fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M53.829,13.965h12.837l-0.338-1.915h-12.5V13.965z" fill="#999999" />
            <path d="M53.829,11.375h12.388l0.111,0.675h-12.5V11.375z" fill="#E5E5E5" />
            <path d="M53.829,8.335h11.937V7.997H53.829V8.335z" fill="#E5E5E5" />
            <path d="M22.185,42.231h-4.729l-2.365-0.676l25.901-4.617l6.982-5.293l5.292-5.293l3.491-2.365l2.928-2.365   l1.802-1.126l1.802-1.126l2.14-1.351l47.072,0.788l-1.238,1.126H66.666l-1.688,1.126l-8.221,15.316l-8.221,5.855H22.185z" fill="#B23232" />
            <path d="M22.072,42.231h-4.617l-2.365-0.676l29.955-1.238l3.491-1.239l2.815-2.478l3.941-3.829l2.928-6.306   l2.027-3.153l1.464-2.703l3.716-2.703l47.072,0.789l-1.126,1.238H66.666l-1.688,1.126l-8.221,15.316l-8.221,5.855H22.072z" fill="#7F0000" />
            <path d="M22.072,42.231h-4.617l-2.365-0.676l-3.491-2.252L9.797,35.7   l1.802-5.856l1.126-1.689l8.221-8.221l6.419-3.604l5.969-2.928l4.054-0.563h23.423l5.855,1.126h44.708l1.126,1.802v2.928   l-1.126,1.238H66.666l-1.688,1.126l-8.221,15.316l-8.221,5.855H22.072" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Back">
            <path d="M100.338,23.425l-1.576-1.126l6.193-15.878l4.617-0.451   l-3.041,16.329L100.338,23.425z" fill="#7F0000" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M98.424,23.988l-1.803-1.126l7.096-18.243l5.292-0.563   l-3.491,18.806L98.424,23.988z" fill="#B23232" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M101.914,22.299l3.604-16.554h-1.238l-5.855,16.554H101.914z" fill="#E56565" />
            <path d="M65.991,15.091h44.707l-0.226-0.901H65.991V15.091z" fill="#FFFFFF" />
        </g>
        <g id="Group_Front">
            <path d="M17.455,42.231l-1.239-5.293v-2.365l1.802-5.293l-6.419,0.563l1.126-1.689l6.419-1.238l4.73-6.419   l5.855-4.167h-2.365l-6.419,3.604l-8.221,8.221l-1.126,1.689L9.797,35.7l1.802,3.604l3.491,2.252L17.455,42.231z" fill="#663333" />
            <path d="M11.599,29.844l6.419-0.563" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Door">
            <path d="M19.144,32.771l1.802-4.054L25,22.299l7.095-5.969h4.167l-7.095,13.514l-2.365,1.802l-7.095,1.802   L19.144,32.771z" fill="#663333" />
            <path d="M19.707,33.447l-0.563-0.676l1.802-4.054L25,22.299l7.095-5.969   h4.167" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M19.707,38.064l12.388-2.928l7.094-19.933" fill="none" stroke="#7F7F7F" stroke-width="0.25" />
        </g>
        <g id="Group_Window">
            <path d="M38.626,22.186l2.027-5.63l0.788-0.676l10.36-0.676l0.789,0.676v4.842l-0.789,0.676l-12.5,1.464   L38.626,22.186z" fill="#E5E5E5" />
            <path d="M39.752,21.623l1.689-4.729l0.676-0.563l8.784-0.563l0.563,0.563v4.167l-0.563,0.563l-10.585,1.126   L39.752,21.623z" fill="#663333" />
            <path d="M40.315,22.299l-0.563-0.676l1.689-4.729l0.676-0.563l8.784-0.563   l0.563,0.563" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Symbol">
            <path d="M55.518,14.416h2.14v7.32h-2.14V14.416z" fill="#CC4C4C" />
            <path d="M53.041,17.119l1.014-1.689l6.194,3.491l-1.014,1.915L53.041,17.119z" fill="#CC4C4C" />
            <path d="M59.234,15.429l1.014,1.689l-6.194,3.716l-1.126-1.915L59.234,15.429z" fill="#CC4C4C" />
        </g>
        <g id="Group_Wings">
            <path d="M46.171,2.254h1.802l2.928,0.563v0.675l-1.802,0.563v0.563l0.563,0.563h-5.18l0.563-0.563V4.056   l-1.802-0.563V2.817L46.171,2.254z" fill="#B23232" />
            <path d="M46.622,2.254h0.901l1.577,0.563v0.675l-0.901,0.563v0.563l0.338,0.563h-2.928l0.338-0.563V4.056   l-1.013-0.563V2.817L46.622,2.254z" fill="#E56565" />
            <path d="M46.171,2.254h1.802l2.928,0.563v0.675l-1.802,0.563v0.563   l0.563,0.563h-5.18l0.563-0.563V4.056l-1.802-0.563V2.817L46.171,2.254" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M46.734,3.492l1.239-0.675l1.126-2.815h-4.617l1.126,2.815   L46.734,3.492z" fill="#999999" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M50.338,3.492l41.666-0.675l2.365-1.126l-45.27,1.126   L50.338,3.492z" fill="#999999" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M43.806,3.492L2.252,2.817L0,1.69l45.045,1.126L43.806,3.492z" fill="#999999" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGValve1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 37.95 112.5" enable-background="new 0 0 37.95 112.5" xml:space="preserve">
        <g id="Group_Stem">
            <path fill="#FFF091" d="M17.681,44.933h2.478V61.6h-2.478V44.933z" />
            <path fill="#FFFFFF" d="M18.582,45.608h0.676v15.09h-0.676V45.608z" />
            <path fill="#333333" d="M17.23,55.293h3.154v1.576H17.23V55.293z" />
        </g>
        <g id="Group_Piston">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="16.5542" y1="52.0273" x2="21.2842" y2="52.0273">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M16.554,49.662h4.73v4.73h-4.73V49.662z" />
            <path fill="#0000CC" d="M14.978,54.392h7.882v0.901h-7.882V54.392z" />
        </g>
        <g id="Group_BodyAndPort">
            <path fill="#0000B2" d="M9.798,107.32h18.243v0.555H9.798V107.32z" />
            <path fill="#0000CC" d="M10.699,79.617H27.14v0.9H10.699V79.617z" />
            <path fill="#0000CC" d="M14.077,61.6h9.459v2.252h-9.459V61.6z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="12.5005" y1="71.0586" x2="25.1123" y2="71.0586">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M12.5,64.752h12.612v12.613H12.5V64.752z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.8428" y1="110.9238" x2="33.2217" y2="110.9238">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M4.843,112.5v-3.153h28.379v3.153H4.843z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="4.8428" y1="108.5586" x2="33.2217" y2="108.5586">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.27" style="stop-color:#0000E8" />
                <stop offset="0.83" style="stop-color:#0000AC" />
                <stop offset="1" style="stop-color:#000099" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M4.843,109.347l3.153-1.576h22.071l3.154,1.576H4.843z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="3.041" y1="96.6221" x2="34.7979" y2="96.6221">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M3.041,88.4l1.802-0.45l1.464-0.788l1.464-0.9l0.789-0.226l0.788-0.112h18.919l1.688,0.338   l1.577,0.9l1.576,0.788l0.789,0.338l0.9,0.112v10.361L33.108,99.1l-1.576,0.787l-1.465,0.676l-1.576,0.451v6.307H9.572v-6.307   l-1.689-0.451l-1.464-0.676L4.843,99.1l-0.901-0.338H3.041V88.4z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="9.3472" y1="83.2207" x2="28.2666" y2="83.2207">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M9.347,80.518h18.919v5.406H9.347V80.518z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="9.3472" y1="78.4912" x2="28.2666" y2="78.4912">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M9.347,77.365h18.919v2.252H9.347V77.365z" />
            <path display="none" fill="#0000FF" d="M9.798,75.788h2.252v1.577H9.798V75.788z" />
            <path fill="#0000FF" d="M25.563,75.788h2.478v1.577h-2.478V75.788z" />
            <path fill="#0000FF" d="M17.681,75.788h2.478v1.577h-2.478V75.788z" />
            <path fill="#0000FF" d="M21.96,75.788h2.478v1.577H21.96V75.788z" />
            <path fill="#0000FF" d="M13.401,75.788h2.252v1.577h-2.252V75.788z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="36.374" y1="101.0137" x2="36.374" y2="85.9238">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M34.798,85.924h3.152v15.09h-3.152V85.924z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="1.521" y1="101.0137" x2="1.521" y2="85.9238">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M0,85.924h3.041v15.09H0V85.924z" />
            <path fill="#00007F" d="M14.978,63.852h7.882v0.9h-7.882V63.852z" />
            <ellipse fill="#4C4CFF" cx="18.694" cy="92.68" rx="12.5" ry="5.856" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="9.5723" y1="104.6172" x2="28.4912" y2="104.6172">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M9.572,101.914h18.919v5.406H9.572V101.914z" />
        </g>
        <g id="Group_Yoke">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="9.3472" y1="53.041" x2="28.2666" y2="53.041">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.06" style="stop-color:#B9BAFF" />
                <stop offset="0.07" style="stop-color:#A4A5FF" />
                <stop offset="0.09" style="stop-color:#7071FF" />
                <stop offset="0.12" style="stop-color:#4A4BFF" />
                <stop offset="0.14" style="stop-color:#3334FF" />
                <stop offset="0.15" style="stop-color:#2A2BFF" />
                <stop offset="0.52" style="stop-color:#B9BAFF" />
                <stop offset="0.82" style="stop-color:#0000FF" />
                <stop offset="0.94" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M9.347,39.752l0.113-0.563l0.788-1.013l2.703-1.577v2.478l-1.126,0.338l-0.338,0.45l-0.113,0.338   v26.125l0.113,0.451l0.563,0.563l1.352,0.113H25.45l0.901-0.451l0.112-0.449V40.203v-0.338l-1.014-0.676l-0.563-0.112v-2.478   l2.14,1.239l0.901,0.901l0.225,0.45l0.113,0.563v1.689v25.563l-0.226,1.014l-0.788,0.789l-0.901,0.45l-1.239,0.226H12.5   l-1.126-0.226l-1.013-0.45l-0.676-0.789l-0.225-0.45l-0.113-0.563V39.752z" />
        </g>
        <g id="Group_ActuatorCase">
            <path fill="#0000CC" d="M14.077,39.302h9.459v0.901h-9.459V39.302z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="16.5542" y1="42.5674" x2="21.2842" y2="42.5674">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M16.554,40.203h4.73v4.73h-4.73V40.203z" />
            <path fill="#0000E5" d="M0,4.617h37.95v1.577H0V4.617z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="9.3472" y1="25.9009" x2="28.2666" y2="25.9009">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M9.347,14.978l2.478,1.577L12.5,37.725l0.45,1.464l0.338,0.113H24.55l0,0l0.226-0.113l0.337-1.464   l0.901-21.171l2.253-1.577V12.5H9.347V14.978z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="3.041" y1="2.3086" x2="34.7979" y2="2.3086">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M34.798,1.464v3.153H3.041V1.464l0.563-1.126L4.392,0h28.604l1.238,0.338L34.798,1.464z" />
            <path fill="#0000CC" d="M11.825,16.554l-2.478-1.577h18.919l-2.253,1.577H11.825z" />
            <path fill="#0000CC" d="M0,6.194h37.95v1.577H0V6.194z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="3.041" y1="10.1353" x2="34.7979" y2="10.1353">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M3.041,10.923V7.771h31.757v0.901v2.252l-0.563,1.126L33.56,12.5h-2.141H4.618l-1.014-0.45   L3.041,10.923z" />
            <path fill="#4C4CFF" d="M0.789,4.617h1.577V3.941H0.789V4.617z" />
            <path fill="#4C4CFF" d="M35.474,4.617h1.576V3.941h-1.576V4.617z" />
            <path fill="#4C4CFF" d="M18.131,4.617h1.577V3.941h-1.577V4.617z" />
            <path fill="#4C4CFF" d="M6.194,4.617h1.577V3.941H6.194V4.617z" />
            <path fill="#4C4CFF" d="M11.825,4.617h1.577V3.941h-1.577V4.617z" />
            <path fill="#4C4CFF" d="M24.438,4.617h1.576V3.941h-1.576V4.617z" />
            <path fill="#4C4CFF" d="M30.067,4.617h1.577V3.941h-1.577V4.617z" />
            <path fill="#0000CC" d="M0.789,8.671h1.577V7.771H0.789V8.671z" />
            <path fill="#0000CC" d="M35.474,8.671h1.576V7.771h-1.576V8.671z" />
            <path fill="#0000CC" d="M18.131,8.671h1.577V7.771h-1.577V8.671z" />
            <path fill="#0000CC" d="M6.194,8.671h1.577V7.771H6.194V8.671z" />
            <path fill="#0000CC" d="M11.825,8.671h1.577V7.771h-1.577V8.671z" />
            <path fill="#0000CC" d="M24.438,8.671h1.576V7.771h-1.576V8.671z" />
            <path fill="#0000CC" d="M30.067,8.671h1.577V7.771h-1.577V8.671z" />
            <rect y="5.941" fill="#4C4C4C" width="37.951" height="0.328" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="14.9141" y1="29.8423" x2="22.9238" y2="29.8423">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.51" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_16_)" d="M21.384,28.153h-4.93c-0.851,0-1.54,0.756-1.54,1.689   s0.689,1.689,1.54,1.689h4.93c0.851,0,1.54-0.756,1.54-1.689S22.234,28.153,21.384,28.153z" />
            
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#00007F" stroke="#4C4C4C" stroke-width="0" stroke-linecap="square" stroke-miterlimit="10" d="   M21.583,29.829c0,0.18-0.134,0.325-0.299,0.325c-0.161,0-0.295-0.146-0.295-0.325c0-0.179,0.134-0.325,0.295-0.325   C21.449,29.504,21.583,29.649,21.583,29.829z" />
            
                <ellipse fill-rule="evenodd" clip-rule="evenodd" fill="#00007F" stroke="#4C4C4C" stroke-width="0" stroke-linecap="square" stroke-miterlimit="10" cx="16.556" cy="29.829" rx="0.296" ry="0.325" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.27, 0.5989583333333334, 0.0, 0.83, 0.4427083333333333, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.6614583333333334, 0.0, 0.06, 0.375, 159.0, 0.07, 0.484375, 131.0, 0.09, 0.75, 63.0, 0.12, 0.953125, 11.0, 0.14, 0.9270833333333334, 0.0, 0.15, 0.8802083333333334, 0.0, 0.52, 0.375, 159.0, 0.82, 0.6614583333333334, 0.0, 0.94, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.6614583333333334, 0.0, 0.51, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_BodyAndPort",[0.0, 0.4583333333333333, 0.0, 1.0, 0.5260416666666666, 0.0, 2.0, 0.5260416666666666, 0.0, 3.0, 0.6614583333333334, 0.0, 4.0, 0.6614583333333334, 0.0, 5.0, 0.6614583333333334, 0.0, 6.0, 0.6614583333333334, 0.0, 7.0, 0.6614583333333334, 0.0, 8.0, 0.3229166666666667, 0.0, 9.0, 0.9479166666666666, 13.0]);
		this.fillGradient(this.fills,"Group_ActuatorCase",[0.0, 0.5260416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5260416666666666, 0.0, 3.0, 0.5260416666666666, 0.0, 4.0, 0.9479166666666666, 13.0, 5.0, 0.9479166666666666, 13.0, 6.0, 0.9479166666666666, 13.0, 7.0, 0.9479166666666666, 13.0, 8.0, 0.9479166666666666, 13.0, 9.0, 0.9479166666666666, 13.0, 10.0, 0.9479166666666666, 13.0, 11.0, 0.5260416666666666, 0.0, 12.0, 0.5260416666666666, 0.0, 13.0, 0.5260416666666666, 0.0, 14.0, 0.5260416666666666, 0.0, 15.0, 0.5260416666666666, 0.0, 16.0, 0.5260416666666666, 0.0, 17.0, 0.5260416666666666, 0.0, 18.0, 0.5885416666666666, 0.0, 19.0, 0.3229166666666667, 0.0, 20.0, 0.3229166666666667, 0.0]);

        super.drawObject();
    }
}
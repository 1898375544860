import { ObjectView } from "./objects/object";
import { opcode } from "./opcode";
import { Screen } from "./screen";
import { Interpreter } from "./utils/interpreter";

export class Script{
    name:string;
    everycycle:boolean;
    extype:number;
    opcodes:opcode[];
    tagnames:string[]=[];


    public runSTScript(screen:Screen, object:ObjectView):void{
        const interpreter = new Interpreter(this,screen,object);
        interpreter.opcodes = this.opcodes;
        interpreter.execute();
    }
}
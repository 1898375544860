import { SVGObject } from "../svgobject";

export class SVGBlower13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 54.842 112.5" enable-background="new 0 0 54.842 112.5" xml:space="preserve">
        <g id="Group_8">
            <g>
                <defs>
                    <rect id="SVGID_1_" width="54.842" height="112.5" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_2_)" fill="#CCCCCC" d="M34.347,94.933H20.383C9.504,94.933,7.32,81.869,7.32,81.869V54.843    l-4.842-4.955h49.887l-4.954,4.955v27.026C47.41,87.834,40.671,94.933,34.347,94.933" />
                <path clip-path="url(#SVGID_2_)" fill="#E5E5E5" d="M32.995,92.455H21.734c-9.564-1.126-10.473-11.486-10.473-11.486v-24.1    l-3.942-4.504H47.41l-4.055,4.504v24.1C43.355,80.969,41.554,91.329,32.995,92.455" />
            </g>
        </g>
        <g id="Group_7">
            <g>
                <defs>
                    <rect id="SVGID_3_" width="54.842" height="112.5" />
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_4_)" fill="#231F20" d="M29.842,112.5h-5.18c-3.928,0-4.833-4.444-4.955-5.293V94.933h15.09v12.274    C34.797,108.875,33.546,112.5,29.842,112.5" />
            </g>
        </g>
        <g id="Group_6">
            <polygon fill="#999999" points="19.707,91.329 19.707,85.022 14.752,85.022 14.752,82.545 39.752,82.545 39.752,85.022    34.797,85.022 34.797,91.329  " />
        </g>
        <g id="Group_5">
            <polygon fill="#B2B232" points="14.752,82.545 17.23,80.067 17.23,57.545 18.581,52.365 36.148,52.365 37.274,57.545    37.274,80.067 39.752,82.545  " />
            <polygon fill="#CCCC4C" points="18.581,82.545 20.383,80.067 20.383,58.445 21.283,53.716 33.445,53.716 34.347,58.445    34.347,80.067 36.148,82.545  " />
            <g>
                <defs>
                    <rect id="SVGID_5_" width="54.842" height="112.5" />
                </defs>
                <clipPath id="SVGID_6_">
                    <use xlink:href="#SVGID_5_" overflow="visible" />
                </clipPath>
                <polygon clip-path="url(#SVGID_6_)" fill="#FFFFFF" points="26.014,93.807 21.059,87.5 19.707,74.887 19.707,66.328 21.059,77.59     22.41,87.5   " />
            </g>
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <polygon id="SVGID_7_" points="4.842,0 1.126,2.478 0,4.842 0,32.32 54.842,32.32 54.842,4.842 53.492,2.478 49.887,0    " />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0" y1="112.5" x2="1" y2="112.5" gradientTransform="matrix(54.8418 0 0 -54.8418 -0.0447 6185.8657)">
                    <stop offset="0" style="stop-color:#231F20" />
                    <stop offset="0.5" style="stop-color:#ECECED" />
                    <stop offset="0.56" style="stop-color:#D9D9DA" />
                    <stop offset="0.69" style="stop-color:#A9A8A9" />
                    <stop offset="0.87" style="stop-color:#5D5A5B" />
                    <stop offset="1" style="stop-color:#231F20" />
                </linearGradient>
                <rect clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="54.842" height="32.32" />
            </g>
        </g>
        <g id="Group_3">
            <path fill="#CC9933" d="M5.405,3.829c1.617,0,2.928,4.437,2.928,9.91c0,5.473-1.311,9.909-2.928,9.909s-2.928-4.436-2.928-9.909   C2.478,8.266,3.788,3.829,5.405,3.829" />
            <path fill="#B27F19" d="M6.644,7.545c0.995,0,1.802,2.723,1.802,6.081s-0.807,6.081-1.802,6.081s-1.802-2.723-1.802-6.081   S5.648,7.545,6.644,7.545" />
        </g>
        <g id="Group_2">
            <g>
                <defs>
                    <rect id="SVGID_10_" x="2.478" y="32.32" width="49.887" height="17.567" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="0.0029" y1="112.5029" x2="1.0029" y2="112.5029" gradientTransform="matrix(49.8867 0 0 -49.8867 2.306 5653.5391)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.19" style="stop-color:#818285" />
                    <stop offset="0.4" style="stop-color:#A4A5A8" />
                    <stop offset="0.51" style="stop-color:#B1B3B6" />
                    <stop offset="0.73" style="stop-color:#8C8E91" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="2.478" y="32.32" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="49.887" height="17.567" />
            </g>
        </g>
        <g id="Group_1">
            <polyline fill="none" stroke="#4D4D4D" stroke-width="0.25" points="2.478,49.887 52.364,47.41 52.364,44.932 2.478,47.41    2.478,44.932 52.364,42.455 52.364,39.977 2.478,42.455 2.478,39.977 52.364,37.5 52.364,35.022 2.478,37.5 2.478,35.022    52.364,32.32  " />
            <rect x="2.478" y="32.32" fill="none" stroke="#4C4C4C" stroke-width="0.25" width="49.887" height="17.567" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[]);
		this.fillGradient(this.gradients,"SVGID_2_",[]);
		this.fillGradient(this.gradients,"SVGID_3_",[]);
		this.fillGradient(this.gradients,"SVGID_4_",[]);
		this.fillGradient(this.gradients,"SVGID_5_",[]);
		this.fillGradient(this.gradients,"SVGID_6_",[]);
		this.fillGradient(this.gradients,"SVGID_7_",[]);
		this.fillGradient(this.gradients,"SVGID_8_",[]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.25, 0.0, 0.5, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.69, 0.6875, 79.0, 0.87, 0.7083333333333334, 0.0, 1.0, 0.25, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[]);
		this.fillGradient(this.gradients,"SVGID_11_",[]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater50 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Cylinder">
            <path fill="#231F20" d="M81.369,36.148h1.289v17.793h-1.289V36.148z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="67" y1="78.2773" x2="81.8691" y2="78.2773">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M67,55.202h14.869v46.149H67V55.202" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="67" y1="44.4824" x2="81.8691" y2="44.4824">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M67,32.771h14.869v23.424H67V32.771" />
            <path fill="#231F20" d="M67,103.678h14.869v-2.326H67V103.678z" />
        </g>
        <g id="Group_Pipe1">
            <path id="XMLID_3_" fill="#7F7F7F" d="M108.334,8.833h1.238v5.959h-1.238V8.833z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="106.5313" y1="7.5449" x2="111.1484" y2="7.5449">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M106.531,5.855h4.617v3.379h-4.617V5.855z" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.25" d="M110.698,9.234v4.843" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.25" d="M107.095,9.234v4.843" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="106.5313" y1="36.9136" x2="111.1484" y2="36.9136">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.53" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.81" style="stop-color:#555555" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M106.531,14.077h4.617V59.75h-4.617V14.077z" />
        </g>
        <g id="Group_Pump">
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-2103.2539" y1="6104.752" x2="-2102.2539" y2="6104.752" gradientTransform="matrix(15.6771 2.7643 2.7643 -15.6771 16194.6113 101590.7578)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.19" style="stop-color:#818285" />
                <stop offset="0.4" style="stop-color:#A4A5A8" />
                <stop offset="0.51" style="stop-color:#B1B3B6" />
                <stop offset="0.73" style="stop-color:#8C8E91" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M105.518,58.445V61.5c0,0.933-1.238,0.933-1.238,0.933l0.004,0.006   c-1.641-0.706-3.445-1.106-5.346-1.106v27.125c7.49,0,13.563-6.072,13.563-13.563V58.445H105.518z" />
            
                <radialGradient id="SVGID_6_" cx="-2103.2363" cy="6104.7813" r="1" gradientTransform="matrix(11.7114 0 0 -11.7114 24730.2695 71570.4219)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#B1B3B6" />
                <stop offset="0.38" style="stop-color:#B1B3B6" />
                <stop offset="0.66" style="stop-color:#8C8E91" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </radialGradient>
            <circle fill="url(#SVGID_6_)" cx="98.424" cy="74.888" r="11.712" />
        </g>
        <g id="Group_Pipe2">
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="-2103.2671" y1="6104.7129" x2="-2102.2671" y2="6104.7129" gradientTransform="matrix(0 -9.2344 -9.2344 0 56451.6289 -19411.9316)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.19" style="stop-color:#818285" />
                <stop offset="0.4" style="stop-color:#A4A5A8" />
                <stop offset="0.51" style="stop-color:#B1B3B6" />
                <stop offset="0.73" style="stop-color:#8C8E91" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="68.581" y="1.239" fill="url(#SVGID_7_)" width="19.383" height="9.234" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-2103.2666" y1="6104.7109" x2="-2102.2666" y2="6104.7109" gradientTransform="matrix(9.2344 0 0 -9.2344 19516.0957 56413.8633)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.19" style="stop-color:#818285" />
                <stop offset="0.4" style="stop-color:#A4A5A8" />
                <stop offset="0.51" style="stop-color:#B1B3B6" />
                <stop offset="0.73" style="stop-color:#8C8E91" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="93.693" y="17.455" fill="url(#SVGID_8_)" width="9.234" height="46.132" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-2103.2432" y1="6104.7637" x2="-2102.2432" y2="6104.7637" gradientTransform="matrix(0 11.8662 11.8662 0 -72348.0938 24957.5)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_9_)" points="90.387,0.014 99.027,11.866 85.473,11.866 85.473,0  " />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-2103.2695" y1="6104.7031" x2="-2102.2695" y2="6104.7031" gradientTransform="matrix(-12.0684 0 0 12.0684 -25278.6426 -73662.5547)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_10_)" points="104.458,13.735 104.458,18.694 92.39,18.694 92.39,4.17  " />
            
                <radialGradient id="SVGID_11_" cx="-2103.2432" cy="6104.707" r="0.9998" gradientTransform="matrix(13.6252 0 0 -13.4044 28748.4258 81842.9844)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_11_)" d="M90.322,0v11.865c2.095,0,2.075,2.055,2.075,2.055h12.061C104.458,13.92,104.458,0,90.322,0z" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-2103.2456" y1="6104.7598" x2="-2102.2456" y2="6104.7598" gradientTransform="matrix(11.7705 0 0 -11.7705 24807.6719 71868.1094)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_12_)" points="51.439,13.889 63.196,5.317 63.196,18.763 51.426,18.763  " />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-2103.2485" y1="6104.7559" x2="-2102.2485" y2="6104.7559" gradientTransform="matrix(0 11.9707 11.9707 0 -73015.2734 25177.2871)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.45" style="stop-color:#ECECED" />
                <stop offset="0.57" style="stop-color:#D9DADB" />
                <stop offset="0.77" style="stop-color:#B1B3B6" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <polygon fill="url(#SVGID_13_)" points="65.05,-0.07 70.271,-0.07 70.271,11.901 55.563,11.901  " />
            <rect x="101.875" y="24.549" fill="#4D4D4D" width="9.273" height="4.73" />
            <rect x="91.329" y="24.549" fill="#4D4D4D" width="5.968" height="4.73" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-2103.2104" y1="6104.8438" x2="-2102.2104" y2="6104.8438" gradientTransform="matrix(6.9814 0 0 -6.9814 14778.2891 42711.8867)">
                <stop offset="0" style="stop-color:#5C5D60" />
                <stop offset="0.19" style="stop-color:#818285" />
                <stop offset="0.4" style="stop-color:#A4A5A8" />
                <stop offset="0.51" style="stop-color:#B1B3B6" />
                <stop offset="0.73" style="stop-color:#8C8E91" />
                <stop offset="1" style="stop-color:#5C5D60" />
            </linearGradient>
            <rect x="94.933" y="78.491" fill="url(#SVGID_14_)" width="6.981" height="26.093" />
            
                <radialGradient id="SVGID_15_" cx="31.9644" cy="330.6914" r="23.7798" gradientTransform="matrix(-0.5769 0.043 0.0421 0.5845 68.9924 -181.5229)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.09" style="stop-color:#4D4D4D" />
                <stop offset="0.14" style="stop-color:#696969" />
                <stop offset="0.22" style="stop-color:#949494" />
                <stop offset="0.26" style="stop-color:#A5A5A5" />
                <stop offset="0.36" style="stop-color:#C2C2C2" />
                <stop offset="0.4" style="stop-color:#CCCCCC" />
                <stop offset="0.5" style="stop-color:#DFDFDF" />
                <stop offset="0.57" style="stop-color:#E6E6E6" />
                <stop offset="0.68" style="stop-color:#C6C6C6" />
                <stop offset="0.89" style="stop-color:#767676" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" d="M65.198-0.066c-6.717,0-13.775,4.691-13.775,13.983l11.676,0.035   c0.244-1.635,0.91-2.029,2.063-2.135C65.298,8.702,65.198-0.066,65.198-0.066z" />
            <line fill="none" stroke="#4D4D4D" stroke-width="0.2538" x1="51.577" y1="12.726" x2="63.535" y2="12.726" />
        </g>
        <g id="Group_Tank">
            <path fill="#231F20" d="M8.108,13.531v0.949h17.567v-0.949H8.108z" />
            <path fill="#231F20" d="M27.928,13.531v0.949h18.469v-0.949H27.928z" />
            <path fill="#231F20" d="M67.521,22.297h1.055v-4.842h-1.055V22.297z" />
            <path fill="#231F20" d="M48.198,13.584v1.553h18.468v-1.553H48.198z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="0.3857" y1="38.2705" x2="74.4998" y2="58.1293">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M6.869,82.323h61.148V14.077H6.869V82.323z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="1.8804" y1="85.2441" x2="27.7367" y2="97.3011">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M4.504,79.617h20.608v23.311H4.504V79.617z" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="22.8467" y1="85.1064" x2="49.2942" y2="97.4391">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M25.406,79.617h21.328v23.311H25.406V79.617z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="44.5298" y1="85.1904" x2="70.6162" y2="97.3547">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M47.128,79.617h20.889v23.311H47.128V79.617z" />
            <path fill="#4D4D4D" d="M47.41,15.09h0.563v58.558H47.41V15.09z" />
            <path fill="#4D4D4D" d="M26.915,15.09h0.45v58.558h-0.45V15.09z" />
            <path fill="#CCCCCC" d="M66.666,75.451v0.787H8.108v-0.787H66.666z" />
            <path fill="#CCCCCC" d="M48.761,15.09h0.451v58.558h-0.451V15.09z" />
            <path fill="#CCCCCC" d="M27.928,15.09h0.789v58.558h-0.789V15.09z" />
            <path fill="#CCCCCC" d="M8.671,15.09h0.563v58.558H8.671V15.09z" />
            <path fill="#4D4D4D" d="M66.666,74.438v0.449H8.108v-0.449H66.666z" />
            <path fill="#4D4D4D" d="M25.124,102.928h0.563V79.617h-0.563V102.928z" />
            <path fill="#4D4D4D" d="M46.734,102.928h0.451V79.617h-0.451V102.928z" />
            <path fill="#4D4D4D" d="M47.41,79.055h0.563v-2.816H47.41V79.055z" />
            <path fill="#4D4D4D" d="M26.915,79.055h0.45v-2.816h-0.45V79.055z" />
            <path fill="#FFFFFF" d="M65.766,15.09h0.451v58.558h-0.451V15.09z" />
            <path fill="#FFFFFF" d="M46.171,15.09h0.789v58.558h-0.789V15.09z" />
            <path fill="#FFFFFF" d="M25.676,15.09h0.788v58.558h-0.788V15.09z" />
            <path fill="#231F20" d="M5.856,79.055v0.563h18.243v-0.563H5.856z" />
            <path fill="#231F20" d="M26.464,79.055v0.563h19.257v-0.563H26.464z" />
            <path fill="#231F20" d="M47.41,79.055v0.563h19.256v-0.563H47.41z" />
        </g>
        <g id="Group_Group1">
            <polygon fill="#4D4D4D" points="68.018,83.107 68.018,85.022 72.072,85.022 72.072,90.879 72.86,91.893 73.648,90.879    73.648,85.022 73.648,83.107  " />
        </g>
        <g id="Group_Label">
            <path fill="#FFFFFF" d="M29.729,37.5h15.428v14.077H29.729V37.5z" />
            <path fill="#F0F0EB" d="M72.635,60.811h4.617v7.207h-4.617V60.811z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M77.344,60.811h-4.709v7.314" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.25" d="M29.729,51.577h15.428" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.25" d="M72.5,68.018h4.752v-7.346" />
        </g>
        <g id="Group_Connector">
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="92.3418" y1="71.5098" x2="104.2793" y2="71.5098">
                <stop offset="0.06" style="stop-color:#808080" />
                <stop offset="0.13" style="stop-color:#8D8D8D" />
                <stop offset="0.28" style="stop-color:#B0B0B0" />
                <stop offset="0.48" style="stop-color:#E8E8E8" />
                <stop offset="0.48" style="stop-color:#EBEBEB" />
                <stop offset="0.57" style="stop-color:#E0E0E0" />
                <stop offset="0.72" style="stop-color:#C3C3C3" />
                <stop offset="0.92" style="stop-color:#949494" />
                <stop offset="1" style="stop-color:#808080" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M92.342,62.162v12.726c0,3.297,2.672,5.969,5.969,5.969s5.969-2.672,5.969-5.969V62.162H92.342z" />
        </g>
        <g id="Group_Base">
            <path fill="#231F20" d="M2.252,106.752H0v5.748h2.252V106.752z" />
            <path fill="#231F20" d="M110.135,106.752h2.365v5.748h-2.365V106.752z" />
            <path fill="#4D4D4D" d="M0,102.928h112.5v4.955H0V102.928z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.53, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.81, 0.6614583333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6041666666666666, 101.0, 0.38, 0.6041666666666666, 101.0, 0.66, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7239583333333334, 0.0, 0.19, 0.984375, 3.0, 0.4, 0.7135416666666666, 73.0, 0.51, 0.6041666666666666, 101.0, 0.73, 0.890625, 27.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.5989583333333334, 0.0, 0.09, 0.5989583333333334, 0.0, 0.14, 0.8177083333333334, 0.0, 0.22, 0.84375, 39.0, 0.26, 0.7135416666666666, 73.0, 0.36, 0.484375, 131.0, 0.4, 0.40625, 151.0, 0.5, 0.2552083333333333, 189.0, 0.57, 0.20833333333333334, 202.0, 0.68, 0.453125, 139.0, 0.89, 0.9166666666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.06, 1.0, 0.0, 0.13, 0.9010416666666666, 25.0, 0.28, 0.625, 95.0, 0.48, 0.1875, 207.0, 0.48, 0.16666666666666666, 212.0, 0.57, 0.25, 191.0, 0.72, 0.4739583333333333, 133.0, 0.92, 0.84375, 39.0, 1.0, 1.0, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.25, 0.0, 1.0, 0.25, 0.0, 2.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
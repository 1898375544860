import { SVGObject } from "../svgobject";

export class SVGChemical7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 35.921 112.5" enable-background="new 0 0 35.921 112.5" xml:space="preserve">
        <g id="Group_Supporters">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M2.252,26.917l6.644,7.882h18.017l6.757-7.882h-4.505l-3.941,4.504H10.585   l-3.829-4.504H2.252z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M2.252,53.941l6.644,7.883h18.017l6.757-7.883h-4.505l-3.941,4.504H10.585   l-3.829-4.504H2.252z" />
        </g>
        <g id="Group_Column">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.8955" y1="61.543" x2="26.9126" y2="61.543">
                <stop offset="0" style="stop-color:#B2BFB2" />
                <stop offset="0.5" style="stop-color:#EFF2EF" />
                <stop offset="1" style="stop-color:#B2BFB2" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M26.913,110.131v0.226l-3.266,1.576l-3.829,0.45l-0.9,0.113l-3.604-0.113l-3.716-0.563l-2.59-1.464   l-0.113-0.226V10.589h18.017V110.131z" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,11.152h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,29.168h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,47.186h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,65.202h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,83.219h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,101.123h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,15.656h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,33.673h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,51.689h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,69.706h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,87.723h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,105.627h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,20.16h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,38.177h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,56.194h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,74.211h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,92.227h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,24.665h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,42.681h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,60.697h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,78.715h18.017" />
            <path fill="none" stroke="#B2B2A3" stroke-width="0.25" d="M8.896,96.619h18.017" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M26.913,110.131c0,0-1.958,2.335-8.952,2.335   c-7.714,0-9.065-2.335-9.065-2.335V10.589h18.017V110.131z" />
            <circle fill="#E0E5E0" stroke="#4C4C4C" stroke-width="0.25" cx="17.904" cy="19.597" r="3.378" />
            <circle fill="#E0E5E0" stroke="#4C4C4C" stroke-width="0.25" cx="17.904" cy="46.622" r="3.378" />
            <circle fill="#C2CCC2" stroke="#657F65" stroke-width="0.25" cx="17.904" cy="19.597" r="2.139" />
            <circle fill="#C2CCC2" stroke="#657F65" stroke-width="0.25" cx="17.904" cy="46.622" r="2.139" />
        </g>
        <g id="Group_Steps">
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,11.715h4.504v98.979H5.518V11.715z" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,74.773h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,77.025H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,79.277h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,81.417h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,83.669H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,85.922h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,88.286h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,90.426H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,92.79h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,94.93h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,97.182H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,99.434h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,101.799h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,103.938H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,106.303h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,108.441h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,38.853h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,41.104H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,43.357h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,45.496h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,47.749H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,50h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,52.365h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,54.505H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,56.869h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,59.009h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,61.261H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,63.513h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,65.878h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,68.018H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,70.27h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,72.521h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,13.967h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,16.332h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,18.584H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,20.836h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,22.976h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,25.228H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,27.479h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,29.844h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M10.022,32.096H5.518" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,34.349h4.504" />
            <path fill="none" stroke="#FFCC00" stroke-width="0.25" d="M5.518,36.488h4.504" />
        </g>
        <g id="Group_Top">
            <path fill="#0A330A" stroke="#4C4C4C" stroke-width="0.25" d="M16.215,3.382h9.572v4.955h-9.572V3.382z" />
            <path fill="#657F65" stroke="#4C4C4C" stroke-width="0.25" d="M6.756,8.337h22.408v2.815H6.756V8.337z" />
            <ellipse fill="#1A401A" stroke="#4C4C4C" stroke-width="0.25" cx="17.904" cy="11.715" rx="11.148" ry="0.563" />
        </g>
        <g id="Group_OutletPipe">
            <path fill="#476647" stroke="#4C4C4C" stroke-width="0.25" d="M31.98,5.071H12.274v3.266h1.689V6.648h16.328v103.033h1.689V5.071z" />
        </g>
        <g id="Group_Grills">
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M16.778,53.941h2.364v8.445h-2.364V53.941z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M33.669,52.252h1.125v-8.445h-1.125V52.252z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M24.66,52.252h1.127v-8.445H24.66V52.252z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M10.022,52.252h1.238v-8.445h-1.238V52.252z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,52.252h1.238v-8.445H1.014V52.252z" />
            <path fill="#476647" stroke="#4C4C4C" stroke-width="0.25" d="M0,52.252h35.921v1.689H0V52.252z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.014,43.807h33.781v1.126H1.014V43.807z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.014,47.749h33.781v1.126H1.014V47.749z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M33.669,25.228h1.125v-8.445h-1.125V25.228z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M24.66,25.228h1.127v-8.445H24.66V25.228z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M10.022,25.228h1.238v-8.445h-1.238V25.228z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M1.014,25.228h1.238v-8.445H1.014V25.228z" />
            <path fill="#476647" stroke="#4C4C4C" stroke-width="0.25" d="M0,25.228h35.921v1.689H0V25.228z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.014,16.782h33.781v1.126H1.014V16.782z" />
            <path stroke="#4C4C4C" stroke-width="0.25" d="M1.014,20.723h33.781v1.126H1.014V20.723z" />
            <path fill="#284C28" stroke="#4C4C4C" stroke-width="0.25" d="M16.778,26.917h2.364v8.445h-2.364V26.917z" />
        </g>
        <g id="Group_InletPipe">
            <path fill="#859985" stroke="#4C4C4C" stroke-width="0.25" d="M3.378,3.833h15.653V0.004h1.688v5.518H5.067v106.975H3.378V3.833z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.578125, 107.0, 0.5, 0.125, 223.0, 1.0, 0.578125, 107.0]);
		this.fillGradient(this.fills,"Group_Column",[0.0, 0.23958333333333334, 193.0, 1.0, 0.23958333333333334, 193.0, 2.0, 0.4583333333333333, 137.0, 3.0, 0.4583333333333333, 137.0]);
		this.fillGradient(this.fills,"Group_Top",[0.0, 0.17708333333333334, 0.0, 1.0, 0.8489583333333334, 0.0, 2.0, 0.2916666666666667, 0.0]);
		this.fillGradient(this.fills,"Group_OutletPipe",[0.0, 0.6302083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Grills",[0.0, 0.4010416666666667, 0.0, 1.0, 0.4010416666666667, 0.0, 2.0, 0.4010416666666667, 0.0, 3.0, 0.4010416666666667, 0.0, 4.0, 0.4010416666666667, 0.0, 5.0, 0.6302083333333334, 0.0, 6.0, 0.4010416666666667, 0.0, 7.0, 0.4010416666666667, 0.0, 8.0, 0.4010416666666667, 0.0, 9.0, 0.4010416666666667, 0.0, 10.0, 0.6302083333333334, 0.0, 11.0, 0.4010416666666667, 0.0]);
		this.fillGradient(this.fills,"Group_InletPipe",[0.0, 0.9166666666666666, 21.0]);
		
        super.drawObject();
    }
}
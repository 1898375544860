import { SVGObject } from "../svgobject";

export class SVGValve7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 74.77 112.5" enable-background="new 0 0 74.77 112.5" xml:space="preserve">
        <g id="Group_5">
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="27.701,6.646 38.793,5.406 49.886,6.646 49.886,8.898 38.793,9.969 27.701,8.898    " />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-0.0112" y1="112.4893" x2="0.9888" y2="112.4893" gradientTransform="matrix(22.1846 0 0 -22.1846 27.9479 2503.2261)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="27.701" y="5.406" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="22.185" height="4.563" />
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_4_" points="27.701,6.646 32.093,0.002 45.493,0.002 49.886,6.646    " />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <radialGradient id="SVGID_6_" cx="0.0127" cy="112.5" r="1" gradientTransform="matrix(0 -8.1879 -9.8255 0 1144.1674 3.4232)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#ECECED" />
                    <stop offset="0.16" style="stop-color:#DEDEDF" />
                    <stop offset="0.48" style="stop-color:#B8B9BB" />
                    <stop offset="0.9" style="stop-color:#7C7D80" />
                    <stop offset="1" style="stop-color:#6D6E71" />
                </radialGradient>
                <rect x="27.701" y="0.002" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="22.185" height="6.644" />
            </g>
        </g>
        <g id="Group_1">
            <g>
                <defs>
                    <rect id="SVGID_7_" x="2.815" y="76.463" width="71.957" height="31.531" />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-0.0137" y1="112.4863" x2="0.9863" y2="112.4863" gradientTransform="matrix(0 -31.5313 -31.5313 0 3585.667 107.5871)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="2.815" y="76.463" clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="71.957" height="31.531" />
            </g>
            <rect x="5.067" y="94.537" fill="#A5A5A5" width="4.392" height="7.883" />
            <rect x="68.128" y="79.728" fill="#A5A5A5" width="4.392" height="7.884" />
            <rect x="68.128" y="94.594" fill="#D3D3D3" width="4.392" height="7.883" />
            <rect x="68.128" y="94.537" fill="#A5A5A5" width="4.392" height="7.883" />
            <rect x="5.067" y="79.728" fill="#A5A5A5" width="4.392" height="7.884" />
        </g>
        <g id="Group_10">
            <rect x="29.886" y="85.359" fill="#AFAFAF" width="17.86" height="18.266" />
        </g>
        <g id="Group_11">
            <g>
                <defs>
                    <rect id="SVGID_10_" x="9.459" y="71.959" width="18.242" height="40.539" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-0.0073" y1="112.4922" x2="0.9927" y2="112.4922" gradientTransform="matrix(0 -40.5391 -40.5391 0 4578.9063 112.1893)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="9.459" y="71.959" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="18.242" height="40.539" />
            </g>
        </g>
        <g id="Group_12">
            <g>
                <defs>
                    <rect id="SVGID_13_" x="49.886" y="71.959" width="18.242" height="40.539" />
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="-0.0073" y1="112.4941" x2="0.9927" y2="112.4941" gradientTransform="matrix(0 -40.5391 -40.5391 0 4619.4375 112.1893)">
                    <stop offset="0" style="stop-color:#5C5D60" />
                    <stop offset="0.45" style="stop-color:#ECECED" />
                    <stop offset="0.57" style="stop-color:#D9DADB" />
                    <stop offset="0.77" style="stop-color:#B1B3B6" />
                    <stop offset="1" style="stop-color:#5C5D60" />
                </linearGradient>
                <rect x="49.886" y="71.959" clip-path="url(#SVGID_14_)" fill="url(#SVGID_15_)" width="18.242" height="40.539" />
            </g>
        </g>
        <g id="Group_13">
            <rect x="29.953" y="70.417" fill="#666666" width="17.793" height="6.667" />
            <polygon fill="#7F7F7F" points="25.449,70.833 52.137,70.833 49.886,53.716 27.701,53.716  " />
            <rect x="32.093" y="54.842" fill="#666666" width="13.4" height="14.865" />
        </g>
        <g id="Group_14">
            <g>
                <defs>
                    <rect id="SVGID_16_" width="74.77" height="112.5" />
                </defs>
                <clipPath id="SVGID_17_">
                    <use xlink:href="#SVGID_16_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_17_)" fill="#CCCCCC" d="M29.841,63.063c1.804,0,3.266,1.463,3.266,3.266    c0,1.804-1.462,3.266-3.266,3.266s-3.266-1.462-3.266-3.266C26.575,64.525,28.037,63.063,29.841,63.063" />
                <path clip-path="url(#SVGID_17_)" fill="#CCCCCC" d="M47.633,63.063c1.804,0,3.266,1.463,3.266,3.266    c0,1.804-1.462,3.266-3.266,3.266s-3.266-1.462-3.266-3.266C44.367,64.525,45.829,63.063,47.633,63.063" />
            </g>
        </g>
        <g id="Group_9">
            <rect x="25.449" y="32.996" fill="#CECECE" width="26.688" height="4.842" />
        </g>
        <g id="Group_2">
            <rect x="27.701" y="31.532" fill="#333333" width="22.185" height="7.77" />
        </g>
        <g id="Group_3">
            <g>
                <defs>
                    <rect id="SVGID_18_" x="19.481" y="41.555" width="38.286" height="10.022" />
                </defs>
                <clipPath id="SVGID_19_">
                    <use xlink:href="#SVGID_18_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="-0.0225" y1="112.4766" x2="0.9775" y2="112.4766" gradientTransform="matrix(0 -10.022 -10.022 0 1165.8657 51.353)">
                    <stop offset="0" style="stop-color:#000099" />
                    <stop offset="0.45" style="stop-color:#0000FF" />
                    <stop offset="0.56" style="stop-color:#0000EC" />
                    <stop offset="0.76" style="stop-color:#0000BC" />
                    <stop offset="0.77" style="stop-color:#0000BA" />
                    <stop offset="1" style="stop-color:#000099" />
                </linearGradient>
                <rect x="19.481" y="41.555" clip-path="url(#SVGID_19_)" fill="url(#SVGID_20_)" width="38.286" height="10.022" />
            </g>
            <rect x="24.323" y="39.302" fill="#0000BF" width="28.939" height="14.414" />
            <g>
                <defs>
                    <rect id="SVGID_21_" x="25.449" y="40.429" width="26.688" height="12.274" />
                </defs>
                <clipPath id="SVGID_22_">
                    <use xlink:href="#SVGID_21_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="0.0166" y1="112.5166" x2="1.0166" y2="112.5166" gradientTransform="matrix(0 12.2739 12.2739 0 -1342.2313 40.2171)">
                    <stop offset="0" style="stop-color:#0000FF" />
                    <stop offset="0.27" style="stop-color:#0000E8" />
                    <stop offset="0.83" style="stop-color:#0000AC" />
                    <stop offset="1" style="stop-color:#000099" />
                </linearGradient>
                <rect x="25.449" y="40.429" clip-path="url(#SVGID_22_)" fill="url(#SVGID_23_)" width="26.688" height="12.274" />
            </g>
            <rect x="27.701" y="41.555" fill="#2626EF" width="10.022" height="10.022" />
            <polygon fill="#0000BF" points="34.346,51.577 30.967,51.577 27.701,48.199 27.701,44.821 30.967,41.555 34.346,41.555    37.724,44.821 37.724,48.199  " />
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <rect id="SVGID_24_" x="16.103" y="8.898" width="45.382" height="22.634" />
                </defs>
                <clipPath id="SVGID_25_">
                    <use xlink:href="#SVGID_24_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="-0.0078" y1="112.4922" x2="0.9922" y2="112.4922" gradientTransform="matrix(0 -22.6343 -22.6343 0 2584.9653 31.3389)">
                    <stop offset="0" style="stop-color:#464547" />
                    <stop offset="0.52" style="stop-color:#A7A9AC" />
                    <stop offset="0.63" style="stop-color:#959699" />
                    <stop offset="0.86" style="stop-color:#666669" />
                    <stop offset="1" style="stop-color:#464547" />
                </linearGradient>
                <rect x="16.103" y="8.898" clip-path="url(#SVGID_25_)" fill="url(#SVGID_26_)" width="45.382" height="22.634" />
            </g>
            <rect x="15.934" y="22.349" fill="#4C4C4C" width="45.382" height="0.351" />
        </g>
        <g id="Group_7">
            <g>
                <defs>
                    <rect id="SVGID_27_" width="74.77" height="112.5" />
                </defs>
                <clipPath id="SVGID_28_">
                    <use xlink:href="#SVGID_27_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_28_)" fill="#CCCCCC" d="M51.012,16.668c2.425,0,4.392,2.067,4.392,4.617    c0,2.55-1.967,4.617-4.392,4.617c-2.426,0-4.392-2.067-4.392-4.617C46.62,18.735,48.586,16.668,51.012,16.668" />
                <path clip-path="url(#SVGID_28_)" fill="#666666" d="M51.236,17.344c2.177,0,3.941,1.765,3.941,3.941    c0,2.177-1.765,3.942-3.941,3.942s-3.941-1.765-3.941-3.942C47.295,19.109,49.06,17.344,51.236,17.344" />
                <path clip-path="url(#SVGID_28_)" fill="#0000FF" d="M51.012,17.794c1.804,0,3.266,1.563,3.266,3.491    c0,1.927-1.462,3.49-3.266,3.49s-3.266-1.563-3.266-3.49C47.746,19.357,49.208,17.794,51.012,17.794" />
                <path clip-path="url(#SVGID_28_)" fill="#0000BF" d="M51.012,19.146c1.243,0,2.252,1.008,2.252,2.252s-1.009,2.252-2.252,2.252    c-1.244,0-2.253-1.008-2.253-2.252S49.768,19.146,51.012,19.146" />
            </g>
        </g>
        <g id="Group_8">
            <g>
                <defs>
                    <rect id="SVGID_29_" width="74.77" height="112.5" />
                </defs>
                <clipPath id="SVGID_30_">
                    <use xlink:href="#SVGID_29_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_30_)" fill="#999999" d="M25.449,34.667c0,0-8.881-0.194-11.824-0.497    c-1.692-0.173-7.95-1.026-8.757-3.778C2.509,22.349,3.634,17,6.908,17.747c2.72,0.62,7.046,1.222,7.046,1.222l0.153-3.375    c0,0-1.648-0.11-3.144-0.256c-0.939-0.091-5.329-1.26-7.179-0.777c-1.088,0.284-2.427,1.057-3.232,4.137    c-1.293,4.952,0.3,10.966,0.728,12.691c0.677,2.727,4.135,4.451,5.46,4.949c3.312,1.246,18.709,1.246,18.709,1.246V34.667z" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_31_" x="61.484" y="21.51" width="3.265" height="7.771" />
                </defs>
                <clipPath id="SVGID_32_">
                    <use xlink:href="#SVGID_31_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="0.0625" y1="112.5645" x2="1.0625" y2="112.5645" gradientTransform="matrix(-3.2656 0 0 3.2656 64.9548 -342.1919)">
                    <stop offset="0" style="stop-color:#C8C8C8" />
                    <stop offset="0.29" style="stop-color:#B1B1B1" />
                    <stop offset="0.91" style="stop-color:#757575" />
                    <stop offset="1" style="stop-color:#6C6C6C" />
                </linearGradient>
                <rect x="61.484" y="21.51" clip-path="url(#SVGID_32_)" fill="url(#SVGID_33_)" width="3.265" height="7.771" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_34_" width="74.77" height="112.5" />
                </defs>
                <clipPath id="SVGID_35_">
                    <use xlink:href="#SVGID_34_" overflow="visible" />
                </clipPath>
                <rect x="13.625" y="14.078" clip-path="url(#SVGID_35_)" fill="#666666" width="2.478" height="6.306" />
            </g>
        </g>
        </svg>`
		super.fillGradients();

		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.16145833333333334, 214.0, 0.16, 0.265625, 187.0, 0.48, 0.5572916666666666, 113.0, 0.9, 0.9739583333333334, 0.0, 1.0, 0.8541666666666666, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7239583333333334, 0.0, 0.45, 0.16145833333333334, 214.0, 0.57, 0.296875, 179.0, 0.77, 0.6041666666666666, 101.0, 1.0, 0.7239583333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.3958333333333333, 0.0, 0.45, 0.6614583333333334, 0.0, 0.56, 0.6041666666666666, 0.0, 0.76, 0.4791666666666667, 0.0, 0.77, 0.4791666666666667, 0.0, 1.0, 0.3958333333333333, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.6614583333333334, 0.0, 0.27, 0.5989583333333334, 0.0, 0.83, 0.4427083333333333, 0.0, 1.0, 0.3958333333333333, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.5416666666666666, 0.0, 0.52, 0.6822916666666666, 81.0, 0.63, 0.828125, 43.0, 0.86, 0.8020833333333334, 0.0, 1.0, 0.5416666666666666, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		
        super.drawObject();
    }
}


import {defineComponent,ref} from 'vue'
import AuthModule from '../store/auth.module'
//import router from '../router'
import user from '../model/user'
import message from '../model/message'
import { useI18n } from 'vue-i18n';


export default defineComponent({

  
  setup(){ 
    const {t} = useI18n({useScope:'global'})
      const loading = ref(false);
      const message = ref("");
      const name = ref(null);
      const password = ref(null);
      const visibilityIcon = ref('visibility');
      const passwordFieldType = ref('password');
      const visibility = ref(false);
    return {
      loading,
      message,
      name,
      password,
      visibilityIcon,
      passwordFieldType,
      visibility,
      t}
  },
 
  computed: {
    loggedIn() {
      return AuthModule.isLoggedIn;
    }
  },
    
  created() {

      if (this.loggedIn) {
        this.$router.push("/home");
      }
      
   },
   methods:{
     switchVisibility() {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon =  this.visibility ? 'visibility_off' : 'visibility'
    },
    resetPassword(){
      this.$router.push("/reset");
    },
     handleLogin(){
       this.loading = true;
        let data:user = {} as user;
        data.username = this.name;
        data.password = this.password;
       AuthModule.login(data).then(
        (response) => {
          this.loading = false;
          //console.log(response);
          //console.log(response.body)
       
          this.message = (response.body as message).message;
          if (response.statusCodeValue==200)
          this.$router.push("/home")
            //router.push("/home");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      ); 
    } 
   }   
})

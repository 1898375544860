import { SVGObject } from "../svgobject";

export class SVGWater23 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 14.191 112.5" enable-background="new 0 0 14.191 112.5" xml:space="preserve">
        <g id="Group_Frame">
            <polyline fill="#4D4D4D" points="3.984,111.19 3.9,40.503 0.266,41.466 0.266,112.524 2.068,112.524 3.984,111.19 4.095,77.164     " />
            <path fill="#231F20" d="M11.037,41.441v62.162l-10.36,5.405l3.047,0.532l10.466-4.361l-1.577-64.188L11.037,41.441z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-9.8799" y1="67.2832" x2="24.4067" y2="83.2713">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M1.803,42.229V112.5l12.388-7.32V38.739L1.803,42.229z M3.83,109.009V44.144l8.783-3.153v63.063   L3.83,109.009z" />
        </g>
        <g id="Group_Wheel">
            <radialGradient id="SVGID_2_" cx="7.0957" cy="39.4141" r="5.2635" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <ellipse fill="url(#SVGID_2_)" cx="7.096" cy="39.414" rx="7.095" ry="2.252" />
            <path fill="#333333" d="M0.677,39.865L5.294,37.5l-2.59,3.041L0.677,39.865z" />
            <path fill="#333333" d="M3.83,40.991L6.533,37.5l-0.451,3.491H3.83z" />
            <path display="none" fill="#333333" d="M9.911,40.991L7.208,37.5l0.45,3.491H9.911z" />
            <path fill="#333333" d="M13.402,39.865L8.785,37.5l2.702,3.041L13.402,39.865z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="6.082" y1="18.75" x2="7.6582" y2="18.75">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M6.082,0h1.576v37.297c0,0-0.25,0.203-0.781,0.203c-0.563,0-0.795-0.203-0.795-0.203V0z" />
        </g>
        <g id="Group_Gate">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-1.1006" y1="54.7183" x2="17.2858" y2="63.292">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M3.83,44.144l8.783-3.153v32.319L3.83,77.14V44.144z" />
            <path fill="#231F20" d="M4.956,49.887v7.32l6.531-2.703l1.126-3.491l-6.531,2.703L4.956,49.887z" />
            <path fill="#231F20" d="M4.956,62.613v7.207l6.531-2.703l1.126-3.491l-6.531,2.703L4.956,62.613z" />
            <path fill="#4D4D4D" d="M4.956,57.207l6.531-2.703l1.126-3.491l-6.531,2.703L4.956,57.207z" />
            <path fill="#4D4D4D" d="M4.956,69.82l6.531-2.703l1.126-3.491l-6.531,2.703L4.956,69.82z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.5989583333333334, 0.0, 1.0, 0.25, 0.0]);
		this.fillGradient(this.fills,"Group_Gate",[0.0, 0.25, 0.0, 1.0, 0.25, 0.0, 2.0, 0.5989583333333334, 0.0, 3.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
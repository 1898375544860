import { SVGObject } from "../svgobject";

export class SVGPeople2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 33.443 112.5" enable-background="new 0 0 33.443 112.5" xml:space="preserve">
        <g id="Group_RightArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M4.279,21.961l-2.928,3.491l-0.788,3.941l0.226,8.896l-0.676,5.743   l0.676,10.472l0.563,4.617l1.127,3.491L5.18,65.99l0.563-3.04L3.49,58.896l1.126,0.563l1.126,1.803l0.226-2.141l-1.802-5.292   l1.802-12.162v-5.18l0.563-7.545" />
        </g>
        <g id="Group_Neck">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M19.819,18.471l-1.126-3.716h-4.054l-1.126,4.279" />
        </g>
        <g id="Group_LeftArm">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M26.8,28.268l0.563,8.783l0.225,5.968l0.901,11.373l-0.788,2.365   l0.338,3.491l0.563,0.563l0.226-2.477l0.338-0.113l-0.676,6.306l0.676,0.901l2.252-4.842l0.45-3.266l-0.338-2.252l1.126-11.036   l0.563-6.981l-0.676-7.319l-2.252-7.207l-2.928-1.689" />
        </g>
        <g id="Group_LeftLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M17.567,63.738v9.797l1.126,11.035l-0.563,8.783l2.365,12.162   l-1.238,4.616l0.563,1.126l2.928,1.238h3.491l1.126-1.238l-2.815-5.18l1.126-14.525v-4.617l0.563-21.508l-0.563-9.797" />
        </g>
        <g id="Group_RightLeg">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M7.657,57.883L6.531,68.355l0.563,15.652l-0.563,4.617l0.563,17.453   l-1.126,2.815l-1.802,1.802l0.676,1.126h4.617l2.928-2.253l-0.563-3.49l-0.676-2.928l2.928-19.706l1.802-19.706" />
        </g>
        <g id="Group_UpperBody">
            <path fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" d="M6.418,34.686H2.252L0,35.249l0.563-8.108l1.689-4.166l2.928-2.928   l8.896-2.928l1.126,0.563h2.928l1.802-0.563l6.419,1.689l4.729,3.604l1.802,4.054l0.563,7.094v2.928l-2.928-1.126h-3.49v-4.167   L26.35,38.29V50H6.982l0.563-8.22L6.418,31.195V34.686z" />
        </g>
        <g id="Group_Face">
            <path fill="#996633" stroke="#4C4C4C" stroke-width="0.25" d="M21.62,11.148h-9.459l-1.238-0.901l-0.676-1.125V7.882l0.676-1.126   h11.936l0.563,1.126v1.239l-0.563,1.125L21.62,11.148z" />
            <ellipse fill="#996633" stroke="#4C4C4C" stroke-width="0.25" cx="16.779" cy="8.449" rx="5.63" ry="7.319" />
            <path fill="#663333" d="M16.779,11.036l1.576,0.45h-3.266L16.779,11.036z" />
            <path fill="#B27F7F" d="M15.089,13.174l-0.45-0.449h4.166l-0.45,0.449H15.089z" />
            <path fill="none" stroke="#CC99B2" stroke-width="0.25" d="M14.639,12.725h4.166" />
            <path d="M20.156,6.644l-2.702-0.45l0.225,0.45l2.815,0.225L20.156,6.644z" />
            <path d="M13.287,6.644l2.815-0.45l-0.338,0.45l-2.702,0.225L13.287,6.644z" />
            <path d="M14.413,8.895h0.45l0.226-0.675l-0.226-0.676h-0.45L14.189,8.22L14.413,8.895z" />
            <path d="M18.58,8.895h0.45l0.226-0.675l-0.226-0.676h-0.45L18.355,8.22L18.58,8.895z" />
        </g>
        <g id="Group_Hair">
            <path fill="#333333" d="M11.373,7.995l-0.45-1.351l-0.676,1.351l0.676-5.067l1.688-1.802l1.802-0.901L16.779,0l2.252,0.225   l1.914,0.901l1.576,1.802l0.901,5.067l-0.676-1.351l-0.45,1.351l-0.901-3.603l-0.9-1.464l-0.338-0.451l-0.45,0.901l-5.293,0.789   h-2.026l-0.226,0.225L11.373,7.995z" />
            <path fill="#191919" d="M19.706,3.378l0.45-2.252h0.338v1.802l-0.338-0.451L19.706,3.378z" />
        </g>
        <g id="Group_Belt">
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M7.544,51.802v-2.365H25.9v2.365H7.544z" />
        </g>
        <g id="Group_Shoes">
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M3.49,111.934v-1.352l1.689-1.688l1.238-2.929h3.491l1.689-1.801   l0.788,4.279l0.45,1.014l-1.802,1.125l-1.126,1.352l-2.365,0.563h-3.49L3.49,111.934z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M28.039,111.934v-1.352l-1.689-1.688l-1.126-2.929h-3.491   l-1.802-1.801v3.49l-0.675,1.803v1.125l0.675,1.352l2.365,0.563h5.292L28.039,111.934z" />
        </g>
        <g id="Group_LowerBody">
            <path fill="#6666CC" stroke="#4C4C4C" stroke-width="0.25" d="M12.274,107.092l-1.802-1.127H5.742L5.18,90.088l0.563-7.094   L5.18,67.68l1.126-12.95l0.563-4.617L7.544,50v1.802h4.167V50h1.126v1.802h7.094V50h1.239v1.802h4.616V50l0.676,0.112l0.451,11.149   l0.675,8.22L26.35,84.232l0.563,7.545l-1.127,14.752l-0.563-0.564h-4.729l-0.563,0.564l-1.802-13.514l-0.563-9.459l-1.239-14.075   l-1.126,13.513l-2.364,14.752L12.274,107.092z" />
            <path fill="#4C4CB2" d="M16.327,68.918l-1.126,12.387l-2.364,15.877l-0.45,6.982l-0.788-5.293v-9.909l3.04-12.499l0.563-5.855   v-4.055v-2.363l0.676-10.585v10.021l0.45,3.492l2.365-4.167l-1.014,5.292l1.014,5.969l0.563,14.751l0.675,10.473l1.802,4.729   l-1.802,0.676l-1.238-7.658l-1.014-15.314L16.327,68.918z" />
        </g>
        <g id="Group_Layer_12">
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGBlower12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 86.264 112.5" enable-background="new 0 0 86.264 112.5" xml:space="preserve">
        <g id="Group_Simple_Compressor">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="13.9651" y1="80.3496" x2="86.261" y2="80.3496">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.58" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#525252" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" points="30.069,48.198 18.02,62.162 18.019,78.266    18.019,108.445 13.965,108.445 13.965,112.5 86.261,112.5 86.261,108.445 82.32,108.445 82.32,62.162 70.27,48.198  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.019,100.338h64.302" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.019,108.445h64.302" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M30.068,48.198l4.056,1.915" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M66.218,50.113l4.054-1.915" />
        </g>
        <g id="Group_Exhaust_Fumes1">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="44.1448" y1="18.0181" x2="44.1448" y2="0">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.58" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#525252" />
            </linearGradient>
            <polyline fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" points="70.27,18.018 70.27,17.25 70.132,17.25 70.27,17.25    70.27,12.05 72.297,12.05 72.297,5.968 70.27,5.968 70.27,0 62.162,0 62.162,3.942 46.172,3.942 44.145,1.915 40.09,5.969    34.122,5.969 24.1,3.942 22.073,3.942 15.992,7.996 22.073,12.05 24.1,12.05 34.124,10.022 42.119,10.022 42.119,18.018  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.073,12.05V3.941" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.1,3.941v8.108" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M34.123,10.022V5.968" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.118,10.022l-2.027-4.054l6.082-2.027" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.118,12.05h20.045V3.941" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M68.244,18.018V0" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M70.271,12.05V5.968" />
        </g>
        <g id="Group_Exhaust_Fumes2">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.0012" y1="76.2949" x2="18.0198" y2="76.2949">
                <stop offset="0" style="stop-color:#525252" />
                <stop offset="0.42" style="stop-color:#F5F5F5" />
                <stop offset="0.99" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.019,78.266h-7.996v7.995l2.027,10.136v1.914l-4.054,6.082   l-4.056-6.082v-1.914l2.027-10.136v-5.969l-4.054-4.056l2.025-2.025v-15.99H0.001v-8.108H5.97v-1.915h6.081v1.915h5.969v12.049" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,98.311H3.942" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,96.396H3.942" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.023,86.262H5.969" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.023,78.266l-4.054,2.027l-2.027-6.081" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M3.942,58.221h8.108v20.045" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.051,50.113H5.969" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0.001,52.14H18.02" />
        </g>
        <g id="Group_5">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="30.0686" y1="33.1084" x2="70.2698" y2="33.1084">
                <stop offset="0.01" style="stop-color:#737373" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#737373" />
            </linearGradient>
            <rect x="30.069" y="18.018" fill="url(#SVGID_4_)" width="40.201" height="30.18" />
        </g>
        <g id="Group_4">
            <path fill="#C0C0C0" stroke="#4C4C4C" stroke-width="0.25" d="M26.014,32.095h48.198v2.027H26.014V32.095z" />
            <path fill="#C0C0C0" stroke="#4C4C4C" stroke-width="0.25" d="M26.014,28.041h48.198v2.027H26.014V28.041z" />
            <path fill="#C0C0C0" stroke="#4C4C4C" stroke-width="0.25" d="M26.014,24.099h48.198v1.915H26.014V24.099z" />
            <path fill="#C0C0C0" stroke="#4C4C4C" stroke-width="0.25" d="M26.014,36.148h48.198v1.915H26.014V36.148z" />
        </g>
        <g id="Group_3">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="22.2986" y1="72.8066" x2="78.2659" y2="72.8066">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M78.266,72.808c0,13.412-12.529,24.275-27.983,24.275   c-15.455,0-27.984-10.863-27.984-24.275c0-13.413,12.529-24.278,27.984-24.278C65.737,48.53,78.266,59.395,78.266,72.808z" />
        </g>
        <g id="Group_2">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="22.0715" y1="87.6113" x2="78.2659" y2="87.6113">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <rect x="22.072" y="74.886" fill="url(#SVGID_6_)" width="56.194" height="25.452" />
        </g>
        <g id="Group_1">
            <radialGradient id="SVGID_7_" cx="50.1145" cy="76.2393" r="20.0449" gradientUnits="userSpaceOnUse">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.39" style="stop-color:#5B5B5B" />
                <stop offset="0.52" style="stop-color:#626262" />
                <stop offset="0.62" style="stop-color:#6D6D6D" />
                <stop offset="0.7" style="stop-color:#7E7E7E" />
                <stop offset="0.76" style="stop-color:#949494" />
                <stop offset="0.82" style="stop-color:#ACACAC" />
                <stop offset="0.84" style="stop-color:#B1B1B1" />
                <stop offset="0.86" style="stop-color:#BEBEBE" />
                <stop offset="0.89" style="stop-color:#D5D5D5" />
                <stop offset="0.9" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_7_)" cx="50.114" cy="76.239" r="20.045" />
            <radialGradient id="SVGID_8_" cx="50.1145" cy="76.2393" r="15.9907" gradientUnits="userSpaceOnUse">
                <stop offset="0.79" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <circle fill="url(#SVGID_8_)" cx="50.114" cy="76.239" r="15.991" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.58, 0.08333333333333333, 233.0, 1.0, 0.6354166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.58, 0.08333333333333333, 233.0, 1.0, 0.6354166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6354166666666666, 0.0, 0.42, 0.08333333333333333, 233.0, 0.99, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.8958333333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.39, 0.7083333333333334, 0.0, 0.52, 0.7604166666666666, 0.0, 0.62, 0.8489583333333334, 0.0, 0.7, 0.9791666666666666, 0.0, 0.76, 0.84375, 39.0, 0.82, 0.65625, 87.0, 0.84, 0.6197916666666666, 97.0, 0.86, 0.515625, 123.0, 0.89, 0.3333333333333333, 169.0, 0.9, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.79, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);

        super.drawObject();
    }
}
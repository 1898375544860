import { SVGObject } from "../svgobject";

export class SVGChemical8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 18.691 112.5" enable-background="new 0 0 18.691 112.5" xml:space="preserve">
        <g id="Group_Fire">
            <path fill="#FF0000" d="M10.81,6.876c0.396,0.077,1.688-2.702,1.688-2.702s0.351,1.771,0.451,2.364   c0.209,1.228-0.113,3.266-0.113,3.266s0.563-1.033,0.789-1.352c0.689-0.968,1.214-1.247,1.688-1.576   c0.563-0.392,1.351-1.689,1.351-1.689s-0.372,1.028-0.459,1.422c-0.172,0.781,0.125,0.797-0.217,2.631   c-0.333,1.79-0.787,2.589-0.787,2.589l-0.113,0.676H3.603c0,0-0.898-1.521-1.013-2.815c-0.12-1.349,0.3-2.456,0.45-3.265   c0.134-0.723,0-1.239,0-1.239s1.274,2.079,1.689,3.378C4.811,8.82,5.471,7.587,5.63,7.214C5.731,6.975,5.903,6.455,5.855,6.2   C5.33,3.422,6.193,2.597,6.193,2.597s0.569,2.652,1.239,2.477c0.077-0.02,0.53-3.75,1.126-4.841   c0.038-0.07,0.225-0.226,0.225-0.226s0.37,2.324,0.675,3.041c0.12,0.28,0.488,0.773,0.676,1.013   C10.893,5.031,10.429,6.802,10.81,6.876z" />
            <path fill="#FF7F00" d="M10.697,9.353c0,0,0.273-0.052,0.338-0.112c0.764-0.725,1.125-2.252,1.125-2.252s0.172,0.759,0.226,1.013   c0.304,1.451-0.119,2.051-0.027,2.727c0.014,0.099,0.2,0.346,0.2,0.346s0.309-0.557,0.503-0.821   c0.426-0.578,1.081-0.707,1.914-2.139c0.134-0.229,0.563-0.901,0.563-0.901s-0.248,1.605-0.338,2.139   c-0.215,1.272-0.479,1.699-0.676,2.252c-0.078,0.219-0.226,0.901-0.226,0.901H4.391l-0.9-1.914c0,0-0.192-0.545-0.113-1.126   c0.05-0.367,0.209-1.094,0.225-1.464c0.04-0.939,0-0.788,0-0.788s0.802,1.473,1.239,1.801c0.142,0.107,0.675,0.226,0.675,0.226   s0.572-0.902,0.676-1.239C6.36,7.461,6.258,6.292,6.418,5.75c0.046-0.157,0.338-0.563,0.338-0.563l0.901,2.477   c0,0,0.325-2.968,0.788-3.828C8.482,3.766,8.67,3.611,8.67,3.611s0.072,0.846,0.113,1.126C8.924,5.703,9.346,6.2,9.346,6.2   s0.453,0.347,0.9,1.126C10.784,8.263,10.697,9.353,10.697,9.353z" />
            <path fill="#FFFF00" d="M9.796,11.38c0.037,0.224,0.45,0.788,0.45,0.788s0.812-0.718,1.014-1.013   c0.156-0.229,0.323-0.767,0.45-1.014c0.047-0.09,0.226-0.337,0.226-0.337s-0.27,0.925-0.338,1.238   c-0.037,0.167-0.113,0.676-0.113,0.676s1.298-0.434,1.352-0.451c0.76-0.236,1.126-1.463,1.126-1.463s-0.131,0.938-0.226,1.238   c-0.122,0.384-0.675,1.464-0.675,1.464H5.18l-1.068-1.6c0,0,0.654,0.554,0.688,0.5c0.088-0.144-0.182-1.04-0.182-1.04   s0.213,0.743,0.675,1.126c0.217,0.18,0.901,0.676,0.901,0.676s0.504-0.527,0.575-0.887c0.141-0.719-0.043-1.058-0.012-1.252   c0.043-0.264,0.338-1.014,0.338-1.014s0.177,0.9,0.299,1.235c0.052,0.142,0.376,0.68,0.376,0.68s0.267-2.969,0.639-4.055   C8.429,6.815,8.783,6.2,8.783,6.2s-0.14,1.362,0.394,2.644c0.226,0.543,0.825,0.734,0.778,1.625   C9.949,10.584,9.758,11.152,9.796,11.38z" />
        </g>
        <g id="Group_Tower">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-4.882813e-004" y1="63.3398" x2="18.6914" y2="63.3398">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M9.346,112.492C-0.19,112.483,0,110.128,0,110.128l2.59-94.582c0,0,0.443-1.358,6.506-1.358   c6.443,0,7.006,1.358,7.006,1.358l2.59,94.582C18.691,110.128,18.096,112.5,9.346,112.492z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1.0132" y1="13.9697" x2="17.6777" y2="13.9697">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M1.013,15.546v-3.153h16.665v3.153H1.013z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Fire",[0.0, 0.6614583333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.671875, 83.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank65 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 63.17" enable-background="new 0 0 112.5 63.17" xml:space="preserve">
        <g id="Group_Tank">
            <g>
                <defs>
                    <path id="SVGID_1_" d="M105.404,5.743H6.984v0.011C3.118,5.97,0,17.399,0,31.474C0,45.549,3.118,57.1,6.984,57.316h98.42     c3.917,0,7.096-11.633,7.096-25.842C112.5,17.264,109.322,5.743,105.404,5.743z" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-0.0063" y1="63.1621" x2="0.9937" y2="63.1621" gradientTransform="matrix(0 -51.5728 -51.5728 0 3313.7112 56.9576)">
                    <stop offset="0" style="stop-color:#5C5C5B" />
                    <stop offset="0.49" style="stop-color:#F1F1F1" />
                    <stop offset="0.56" style="stop-color:#E3E3E3" />
                    <stop offset="0.71" style="stop-color:#BEBEBE" />
                    <stop offset="0.92" style="stop-color:#7C7C7B" />
                    <stop offset="1" style="stop-color:#5C5C5B" />
                </linearGradient>
                <rect y="5.743" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="112.5" height="51.573" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_4_" width="112.5" height="63.17" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_5_)" fill="none" stroke="#878787" stroke-width="0.5" stroke-miterlimit="10" d="M105.404,5.743    L105.404,5.743H6.984v0.01C3.117,5.97,0,17.398,0,31.473c0,14.076,3.117,25.625,6.984,25.843h98.419    c3.918,0,7.097-11.633,7.097-25.843C112.5,17.263,109.322,5.743,105.404,5.743z" />
                <rect x="6.984" y="57.316" clip-path="url(#SVGID_5_)" fill="#13110C" width="99.099" height="1.127" />
            </g>
        </g>
        <g id="Group_Stand">
            <g>
                <defs>
                    <rect id="SVGID_6_" x="11.714" y="3.378" width="7.095" height="56.19" />
                </defs>
                <clipPath id="SVGID_7_">
                    <use xlink:href="#SVGID_6_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="63.1631" x2="0.9941" y2="63.1631" gradientTransform="matrix(0 -56.1895 -56.1895 0 3564.4004 59.2052)">
                    <stop offset="0" style="stop-color:#5C5C5B" />
                    <stop offset="0.45" style="stop-color:#DADADA" />
                    <stop offset="0.62" style="stop-color:#CCCBCB" />
                    <stop offset="0.77" style="stop-color:#BDBCBC" />
                    <stop offset="1" style="stop-color:#5C5C5B" />
                </linearGradient>
                <rect x="11.714" y="3.378" clip-path="url(#SVGID_7_)" fill="url(#SVGID_8_)" width="7.095" height="56.19" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_9_" x="51.465" y="3.378" width="7.206" height="56.19" />
                </defs>
                <clipPath id="SVGID_10_">
                    <use xlink:href="#SVGID_9_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="63.165" x2="0.9941" y2="63.165" gradientTransform="matrix(0 -56.1895 -56.1895 0 3604.2129 59.2052)">
                    <stop offset="0" style="stop-color:#5C5C5B" />
                    <stop offset="0.45" style="stop-color:#DADADA" />
                    <stop offset="0.62" style="stop-color:#CCCBCB" />
                    <stop offset="0.77" style="stop-color:#BDBCBC" />
                    <stop offset="1" style="stop-color:#5C5C5B" />
                </linearGradient>
                <rect x="51.465" y="3.378" clip-path="url(#SVGID_10_)" fill="url(#SVGID_11_)" width="7.206" height="56.19" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_12_" x="93.804" y="3.378" width="6.982" height="56.19" />
                </defs>
                <clipPath id="SVGID_13_">
                    <use xlink:href="#SVGID_12_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-0.0059" y1="63.1631" x2="0.9941" y2="63.1631" gradientTransform="matrix(0 -56.1895 -56.1895 0 3646.4316 59.2052)">
                    <stop offset="0" style="stop-color:#5C5C5B" />
                    <stop offset="0.45" style="stop-color:#DADADA" />
                    <stop offset="0.62" style="stop-color:#CCCBCB" />
                    <stop offset="0.77" style="stop-color:#BDBCBC" />
                    <stop offset="1" style="stop-color:#5C5C5B" />
                </linearGradient>
                <rect x="93.804" y="3.378" clip-path="url(#SVGID_13_)" fill="url(#SVGID_14_)" width="6.982" height="56.19" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_15_" x="14.191" y="2.252" width="2.252" height="57.316" />
                </defs>
                <clipPath id="SVGID_16_">
                    <use xlink:href="#SVGID_15_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="0.0034" y1="63.1729" x2="1.0034" y2="63.1729" gradientTransform="matrix(0 -57.3159 -57.3159 0 3636.1345 59.7534)">
                    <stop offset="0" style="stop-color:#424241" />
                    <stop offset="0.45" style="stop-color:#B2B2B2" />
                    <stop offset="0.62" style="stop-color:#A3A3A2" />
                    <stop offset="0.77" style="stop-color:#929292" />
                    <stop offset="1" style="stop-color:#424241" />
                </linearGradient>
                <rect x="14.191" y="2.252" clip-path="url(#SVGID_16_)" fill="url(#SVGID_17_)" width="2.252" height="57.316" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_18_" x="53.941" y="2.252" width="2.253" height="57.316" />
                </defs>
                <clipPath id="SVGID_19_">
                    <use xlink:href="#SVGID_18_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="0.0034" y1="63.1729" x2="1.0034" y2="63.1729" gradientTransform="matrix(0 -57.3159 -57.3159 0 3675.8845 59.7534)">
                    <stop offset="0" style="stop-color:#424241" />
                    <stop offset="0.45" style="stop-color:#B2B2B2" />
                    <stop offset="0.62" style="stop-color:#A3A3A2" />
                    <stop offset="0.77" style="stop-color:#929292" />
                    <stop offset="1" style="stop-color:#424241" />
                </linearGradient>
                <rect x="53.941" y="2.252" clip-path="url(#SVGID_19_)" fill="url(#SVGID_20_)" width="2.253" height="57.316" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_21_" x="96.057" y="2.252" width="2.252" height="57.316" />
                </defs>
                <clipPath id="SVGID_22_">
                    <use xlink:href="#SVGID_21_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="0.0034" y1="63.1729" x2="1.0034" y2="63.1729" gradientTransform="matrix(0 -57.3159 -57.3159 0 3717.9783 59.7534)">
                    <stop offset="0" style="stop-color:#424241" />
                    <stop offset="0.45" style="stop-color:#B2B2B2" />
                    <stop offset="0.62" style="stop-color:#A3A3A2" />
                    <stop offset="0.77" style="stop-color:#929292" />
                    <stop offset="1" style="stop-color:#424241" />
                </linearGradient>
                <rect x="96.057" y="2.252" clip-path="url(#SVGID_22_)" fill="url(#SVGID_23_)" width="2.252" height="57.316" />
            </g>
        </g>
        <g id="Group_Top">
            <g>
                <defs>
                    <path id="SVGID_24_" d="M21.06,1.126v9.346c0,0,3.065,1.153,14.076,1.153c10.614,0,14.076-1.153,14.076-1.153V1.126H21.06z" />
                </defs>
                <clipPath id="SVGID_25_">
                    <use xlink:href="#SVGID_24_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="-0.0122" y1="63.1611" x2="0.9878" y2="63.1611" gradientTransform="matrix(28.1519 0 0 -28.1519 21.411 1784.485)">
                    <stop offset="0" style="stop-color:#5C5C5B" />
                    <stop offset="0.51" style="stop-color:#DADADA" />
                    <stop offset="0.59" style="stop-color:#CBCBCB" />
                    <stop offset="0.76" style="stop-color:#A4A4A4" />
                    <stop offset="0.99" style="stop-color:#5E5E5D" />
                    <stop offset="1" style="stop-color:#5C5C5B" />
                </linearGradient>
                <rect x="21.06" y="1.126" clip-path="url(#SVGID_25_)" fill="url(#SVGID_26_)" width="28.152" height="10.499" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_27_" width="112.5" height="63.17" />
                </defs>
                <clipPath id="SVGID_28_">
                    <use xlink:href="#SVGID_27_" overflow="visible" />
                </clipPath>
                <path clip-path="url(#SVGID_28_)" fill="none" stroke="#706F6F" stroke-width="0.5" stroke-miterlimit="10" d="M49.212,10.473    c0,0-3.462,1.152-14.076,1.152c-11.011,0-14.076-1.152-14.076-1.152V1.125h28.152V10.473z" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_29_" x="22.186" width="25.9" height="1.126" />
                </defs>
                <clipPath id="SVGID_30_">
                    <use xlink:href="#SVGID_29_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="0.0015" y1="63.1709" x2="1.0015" y2="63.1709" gradientTransform="matrix(25.8999 0 0 -25.8999 22.1495 1636.6984)">
                    <stop offset="0" style="stop-color:#424241" />
                    <stop offset="0.5" style="stop-color:#B2B2B2" />
                    <stop offset="0.6" style="stop-color:#A3A2A2" />
                    <stop offset="0.8" style="stop-color:#787877" />
                    <stop offset="1" style="stop-color:#424241" />
                </linearGradient>
                <rect x="22.186" clip-path="url(#SVGID_30_)" fill="url(#SVGID_31_)" width="25.9" height="1.126" />
            </g>
        </g>
        <g id="Group_Base">
            <g>
                <defs>
                    <rect id="SVGID_32_" width="112.5" height="63.17" />
                </defs>
                <clipPath id="SVGID_33_">
                    <use xlink:href="#SVGID_32_" overflow="visible" />
                </clipPath>
                <rect x="3.606" y="59.377" clip-path="url(#SVGID_33_)" fill="#9B9795" width="105.175" height="1.543" />
                <rect x="1.129" y="60.777" clip-path="url(#SVGID_33_)" fill="#837F7E" width="110.13" height="1.27" />
                <rect x="0.002" y="61.813" clip-path="url(#SVGID_33_)" fill="#6E6A69" width="112.496" height="1.359" />
            </g>
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7083333333333334, 0.0, 0.49, 0.11979166666666667, 224.0, 0.56, 0.22395833333333334, 197.0, 0.71, 0.515625, 123.0, 0.92, 0.9583333333333334, 0.0, 1.0, 0.7083333333333334, 0.0]);
		
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7083333333333334, 0.0, 0.45, 0.296875, 179.0, 0.62, 0.4114583333333333, 149.0, 0.77, 0.53125, 119.0, 1.0, 0.7083333333333334, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7083333333333334, 0.0, 0.45, 0.296875, 179.0, 0.62, 0.4114583333333333, 149.0, 0.77, 0.53125, 119.0, 1.0, 0.7083333333333334, 0.0]);

		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7083333333333334, 0.0, 0.45, 0.296875, 179.0, 0.62, 0.4114583333333333, 149.0, 0.77, 0.53125, 119.0, 1.0, 0.7083333333333334, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.5052083333333334, 0.0, 0.45, 0.609375, 99.0, 0.62, 0.734375, 67.0, 0.77, 0.859375, 35.0, 1.0, 0.5052083333333334, 0.0]);
	
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.5052083333333334, 0.0, 0.45, 0.609375, 99.0, 0.62, 0.734375, 67.0, 0.77, 0.859375, 35.0, 1.0, 0.5052083333333334, 0.0]);

		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.5052083333333334, 0.0, 0.45, 0.609375, 99.0, 0.62, 0.734375, 67.0, 0.77, 0.859375, 35.0, 1.0, 0.5052083333333334, 0.0]);

		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.7083333333333334, 0.0, 0.51, 0.296875, 179.0, 0.59, 0.4114583333333333, 149.0, 0.76, 0.71875, 71.0, 0.99, 0.7239583333333334, 0.0, 1.0, 0.7083333333333334, 0.0]);

		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.5052083333333334, 0.0, 0.5, 0.609375, 99.0, 0.6, 0.734375, 67.0, 0.8, 0.9270833333333334, 0.0, 1.0, 0.5052083333333334, 0.0]);

        super.drawObject();
    }
}
import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { DialogBox } from '@/model/project/dialogbox';
import tagsModele from "@/store/tags.modele";
import tag from '@/model/tag';
import { DateChooser } from '@/model/project/objects/datechooser';
import { format, parseISO } from 'date-fns'

@Module({ name:"DateDialogBox", dynamic:true, store})
class DateDialogBoxModule extends VuexModule {
  public DateChooser:DateChooser;
  public enabled:boolean;
  //public title:string
  public value:string
  public date:number;
  public dateformat: string
  public tagname: string
    //@Mutation
    /*public setDateDialogBox(box:DateChooser){
      this.datedialogbox = box;
    }*/
    @Mutation
    public setDateChooser(DateChooser:DateChooser){
        if (DateChooser==null) return;
        
        this.DateChooser = DateChooser;
       // if(DateChooser.format == null || DateChooser.format == undefined){
            this.dateformat =  "yyyy-MM-dd HH:mm"
        //}else this.dateformat = DateChooser.format
       
        
        if(DateChooser.date ==null || DateChooser.date == undefined){
            this.date = Date.now()
        }else this.date = DateChooser.date

        if(DateChooser.tagname!=null || DateChooser.tagname!= '')
           this.tagname = DateChooser.tagname
      
    }
    @Mutation
    public setDateDialogEnabled(value:boolean){
      console.log(value)
      this.enabled = value;
    }
    
    get getDateDialogEnable():boolean{
     return this.enabled
    }

    get getDate():string{
        //return this.date
        return format(this.date, this.dateformat)
    }
    
    @Mutation
    public setDate(value:string){
        //console.log('setDate value', value, Date.parse(value))
      this.date = Date.parse(value);
    }

    @Mutation
    public updateDateChooser(){
        if (this.DateChooser==null) return;
        this.DateChooser.date = this.date

        tagsModele.writeTagAction({token:"",name: this.tagname, tagvalue:(this.date).toString(),datetime:0});

        this.DateChooser.drawObject()
    }
}

export default getModule(DateDialogBoxModule);
import { SVGObject } from "../svgobject";

export class SVGPump5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 60.645" enable-background="new 0 0 112.5 60.645" xml:space="preserve">
        <g id="Group_Pump_Stand">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M23.898,60.083l-3.269-3.945l-0.677-3.833h-2.48l-0.676,3.833   l-3.269,3.945H23.898z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M54.333,60.083l-3.269-3.945l-0.676-3.833h-2.592l-0.677,3.833   l-3.156,3.945H54.333z" />
            <path fill="#4C4C4C" d="M5.75,49.711h51.74v6.538H5.75V49.711z" />
        </g>
        <g id="Group_Driver_Shaft">
            <path fill="#4C4C4C" d="M21.306,51.741h32.352v1.239H21.306V51.741z" />
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="39.397" y1="5.7483" x2="39.397" y2="41.9333" gradientTransform="matrix(1 0 0 -1 0 60.645)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M21.306,54.897L0.564,52.304v-31l20.741-2.592v2.592h30.999   l10.371,2.48v-2.48h2.593v10.371H78.23v10.259H65.268v10.37h-2.592v-2.593l-10.371,2.593H21.306V54.897" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.306,52.304v-31" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M62.676,49.711V23.785" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M65.268,41.934V31.675" />
        </g>
        <g id="Group_HeatSink_Shade">
            <path fill="#4C4C4C" d="M21.306,21.305h30.999v15.444H21.306V21.305z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M22.545,52.304h27.843V42.61H22.545V52.304z" />
        </g>
        <g id="Group_HeatSink">
            <path fill="#666666" d="M21.306,49.147h32.352v1.24H21.306V49.147z" />
            <path fill="#666666" d="M21.306,51.064h32.352v1.24H21.306V51.064z" />
            <path fill="#E5E5E5" d="M21.306,32.916h32.352v1.239H21.306V32.916z" />
            <path fill="#CCCCCC" d="M21.306,29.646h32.352v1.353H21.306V29.646z" />
            <path fill="#B2B2B2" d="M21.306,27.167h32.352v1.24H21.306V27.167z" />
            <path fill="#B2B2B2" d="M21.306,24.461h32.352v1.353H21.306V24.461z" />
            <path fill="#999999" d="M21.306,21.981h32.352v1.24H21.306V21.981z" />
            <path fill="#999999" d="M21.306,19.952h32.352v1.353H21.306V19.952z" />
        </g>
        <g id="Group_MakeUp_Tank">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M28.407,45.202h16.12V57.49h-16.12V45.202z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M28.407,45.202l1.24-1.916h14.316l0.677,1.916H28.407z" />
        </g>
        <g id="Group_Drip_Humidifier">
            <path fill="#333333" d="M71.807,36.184l-3.494-0.676l-1.016-1.24l1.016-1.353l3.494-0.678l3.381,0.678l1.127,1.353l-1.127,1.24   L71.807,36.184z" />
            <path fill="#333333" d="M71.807,41.37l3.381-0.677l1.127-1.239l-1.127-1.24l-3.381-0.676l-3.494,0.676l-1.016,1.24l1.016,1.239   L71.807,41.37z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M71.807,37.424l3.381,0.676l0.564,0.678l-0.564,0.677l-3.381,0.676   l-3.494-0.676l-0.451-0.677l0.451-0.678L71.807,37.424z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M71.807,33.592l-3.494,0.676l-0.563,0.564l0.563,0.676l3.494,0.676   l3.381-0.676l0.564-0.676l-0.564-0.564L71.807,33.592z" />
        </g>
        <g id="Group_Volute_Chamber">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="95.083" y1="0.5618" x2="95.083" y2="47.1189" gradientTransform="matrix(1 0 0 -1 0 60.645)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M78.23,60.083V13.527h2.594v2.594h20.74l2.594,2.593v7.778   h2.592v-5.187h5.186v31h-5.186v-5.186h-2.592v7.777l-2.594,2.593h-20.74v2.594H78.23" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M80.824,57.49V16.12" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M104.158,47.119V26.49" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M106.75,26.49v20.628" />
        </g>
        <g id="Group_Upstream_Pipe_Flange">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="83.416" y1="52.303" x2="98.9727" y2="52.303" gradientTransform="matrix(1 0 0 -1 0 60.645)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M86.01,16.12V3.269h-2.594V0.563h15.557v2.706h-2.705V16.12   H86.01" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M86.01,3.269h10.258" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Driver_Shaft",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_HeatSink_Shade",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_HeatSink",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.40625, 151.0, 4.0, 0.609375, 99.0, 5.0, 0.609375, 99.0, 6.0, 0.8072916666666666, 49.0, 7.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_MakeUp_Tank",[0.0, 0.609375, 99.0, 1.0, 0.40625, 151.0]);
        super.drawObject();
    }
}
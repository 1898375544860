import { SVGObject } from "../svgobject";

export class SVGTank43 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 60.926 112.5" enable-background="new 0 0 60.926 112.5" xml:space="preserve">
        <g id="Group_BackLegs">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="6.6887" y1="92.4961" x2="10.6311" y2="92.4961">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M10.631,77.136c0-0.705-1.05-1.265-1.994-1.265s-1.926,0.633-1.926,1.34l-0.022,31.911H10.6   L10.631,77.136z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="5.4143" y1="109.3652" x2="11.9797" y2="109.3652">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <rect x="5.414" y="108.914" fill="url(#SVGID_2_)" width="6.565" height="0.901" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="47.6194" y1="91.5723" x2="51.5618" y2="91.5723">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M51.562,76.211c0-0.705-1.05-1.265-1.994-1.265s-1.926,0.633-1.926,1.34l-0.022,31.911h3.911   L51.562,76.211z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="46.345" y1="108.4395" x2="52.9104" y2="108.4395">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <rect x="46.345" y="107.989" fill="url(#SVGID_4_)" width="6.565" height="0.901" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="27.9417" y1="89.8281" x2="31.884" y2="89.8281">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M31.884,74.468c0-0.705-1.05-1.265-1.994-1.265s-1.926,0.633-1.926,1.34l-0.022,31.911h3.911   L31.884,74.468z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="26.6672" y1="106.6973" x2="33.2327" y2="106.6973">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <rect x="26.667" y="106.246" fill="url(#SVGID_6_)" width="6.565" height="0.901" />
        </g>
        <g id="Group_TankBottom">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="2.2542" y1="58.5586" x2="58.5598" y2="58.5586">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.49" style="stop-color:#E56565" />
                <stop offset="0.73" style="stop-color:#B33333" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M2.254,75V32.771H58.56V75c0,0,0,9.347-25.014,9.347c-1.095,0-3.283,0-4.378,0   C2.254,84.347,2.254,75,2.254,75" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="2.2542" y1="72.6348" x2="58.5598" y2="72.6348">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#C84B4B" />
                <stop offset="0.63" style="stop-color:#B73838" />
                <stop offset="0.77" style="stop-color:#A62626" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M2.254,70.271H58.56v3.491l-9.347,0.563L30.406,75l-18.693-0.676l-9.459-0.563V70.271z" />
            <path fill="#CCB2B2" d="M0.001,48.536h60.924v1.239H0.001V48.536z" />
        </g>
        <g id="Group_TankTop">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="2.2542" y1="20.2603" x2="58.5598" y2="20.2603">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M58.56,13.964v18.807H2.254V13.964c0,0,2.599-6.214,28.709-6.214   C57.603,7.75,58.56,13.964,58.56,13.964" />
            
                <radialGradient id="SVGID_10_" cx="30.407" cy="9.333" r="20.1844" gradientTransform="matrix(1 0 0 1.5 0 -4.6665)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M58.56,14.048v-0.084c0,0-1.764-9.347-28.153-9.347c-26.11,0-28.152,9.347-28.152,9.347v0.084   H58.56z" />
        </g>
        <g id="Group_Indicators">
            <path fill="#D3D3D3" d="M35.137,2.252V0h-9.348v2.252h2.253v7.094c0,0,0.405,1.625,2.367,1.625c1.96,0,2.362-1.625,2.362-1.625   V2.252H35.137z" />
            <path fill="#B2B2B2" d="M39.191,1.126v1.689h1.802v5.292c0,0,0.299,1.239,1.742,1.239c1.369,0,1.749-1.239,1.749-1.239V2.815h1.689   V1.126H39.191z" />
            <path fill="#B2B2B2" d="M14.641,1.126v1.689h1.689v5.292c0,0,0.148,1.239,1.746,1.239c1.497,0,1.746-1.239,1.746-1.239V2.815h1.802   V1.126H14.641z" />
            <path fill="#999999" d="M12.277,3.491V2.252h-4.73v1.239h1.239v4.617c0,0,0.146,0.752,1.126,0.752c0.989,0,1.126-0.752,1.126-0.752   V3.491H12.277z" />
            <path fill="#999999" d="M48.65,2.252v1.239h1.127v4.617h0.005c0,0.005-0.004,0.007-0.004,0.012c0,0.336,0.67,0.869,1.196,0.869   c0.552,0,1.167-0.622,1.167-0.88V3.491h1.126V2.252H48.65z" />
        </g>
        <g id="Group_FrontLegs">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="12.1633" y1="111.8809" x2="20.4973" y2="111.8809">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M12.163,111.262h8.334v1.238h-8.334V111.262z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="39.7542" y1="111.8809" x2="48.0872" y2="111.8809">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M39.754,111.262h8.333v1.238h-8.333V111.262z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="13.8528" y1="93.1309" x2="18.6956" y2="93.1309">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M13.853,75h4.843v36.262h-4.843V75z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="41.5559" y1="93.1309" x2="46.2854" y2="93.1309">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M41.556,75h4.729v36.262h-4.729V75" />
            <path fill="none" d="M13.965,75h4.73v36.262h-4.73V75" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="13.8528" y1="74.541" x2="18.6956" y2="74.541">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M18.696,75.057c0,1.337-1.084,1.391-2.421,1.391s-2.421-0.054-2.421-1.391   c0-0.719,1.084-2.422,2.421-2.422S18.696,74.119,18.696,75.057z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="41.5569" y1="74.541" x2="46.2893" y2="74.541">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M46.289,75.057c0,1.337-1.06,1.391-2.366,1.391s-2.366-0.054-2.366-1.391   c0-0.719,1.06-2.422,2.366-2.422S46.289,74.119,46.289,75.057z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.3229166666666667, 0.0, 0.49, 0.8854166666666666, 29.0, 0.73, 0.7239583333333334, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.9010416666666666, 0.0, 0.63, 0.7604166666666666, 0.0, 0.77, 0.625, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
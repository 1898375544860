import { SVGObject } from "../svgobject";

export class SVGMedical8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 35.137 112.5" xml:space="preserve" enable-background="new 0 0 35.137 112.5">
        <g id="Group_Right_Arm">
            <path d="M5.18,20.383l-2.928,2.365l-1.126,5.293L1.689,37.5l-0.675,5.856   l0.675,10.473l0.563,4.73l1.126,3.49l1.802,1.803l0.563-4.167l1.238-1.126L5.18,53.266l1.802-12.275v-5.292l0.563-7.658   l0.021,0.179c-0.011-2.407-1.289-5.45-1.276-8.104L5.18,20.383z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Neck">
            <path d="M22.205,17.831l-1.146-0.376l-1.239-2.252h-4.054l-1.239,2.928   l-0.637,0.153C16.815,18.419,19.284,18.873,22.205,17.831z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Left_Arm">
            <path d="M33.897,26.915l-2.365-5.293l-2.928-1.689l-1.159-0.381   c0.297,2.74,1.229,5.665,0.619,8.291l0.54,8.419l-0.563,7.658l1.802,9.91l-0.563,5.856l1.126,3.604l1.802,0.563l0.563-5.855v-3.604   l1.239-11.149l0.563-6.982L33.897,26.915z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Left_Leg">
            <path d="M26.915,91.328V86.6l0.563-21.583   c-3.009-0.319-5.904-0.508-8.783-0.615v8.797l1.126,11.148l-0.563,8.784l2.364,12.274l-1.126,4.729l0.563,1.127l2.928,1.238h3.491   l1.126-1.238l-2.928-5.293L26.915,91.328z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Right_Leg">
            <path d="M7.546,67.905l0.563,15.767L7.546,88.4l0.563,17.568l-1.126,2.928   l-1.802,1.802l0.563,1.126h4.729l2.928-2.252l-0.563-3.604l-0.563-2.928l2.928-19.934l1.591-18.766   c-2.896-0.075-5.816-0.09-8.859-0.091L7.546,67.905z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_BlazerBack">
            <path d="M12.275,82.545l5.181-15.203l5.292,15.203H12.275z" fill="#666666" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Shirt">
            <path d="M7.546,35.135l-4.167-0.563H1.127l-0.676-7.095l1.238-4.73   L5.18,19.82l9.347-2.252l0.676-1.802l1.126,2.365h2.929l1.238-2.365l1.126,1.802l7.658,2.252l2.928,1.802l2.364,5.856l0.563,5.856   v2.365l-2.928-0.563l-4.167,0.563v-4.054l-0.563,6.982l-0.563,11.149H8.672v-7.658L7.546,31.644V35.135z" fill="#CBE5E5" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M21.059,28.041h5.292v0.676h-5.292V28.041z" fill="#CCCCCC" />
            <path d="M22.185,28.041v-2.365h0.563v2.365H22.185z" fill="#000066" />
            <path d="M21.622,28.041v-1.689h0.563v1.689H21.622z" fill="#FF0000" />
            <path d="M23.424,28.041v-1.689h0.563v1.689H23.424z" />
        </g>
        <g id="Group_Belt">
            <path d="M8.108,52.14v-2.365h19.37v2.365H8.108z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Shoes">
            <path d="M29.28,111.824v-1.126l-1.802-1.802l-1.127-2.928h-3.604   l-1.688-1.689v3.49l-0.563,1.803v1.126l0.563,1.126l2.364,0.676h5.293L29.28,111.824z" fill="#663300" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M4.618,111.824v-1.126l1.802-1.802l1.126-2.928h3.491l1.802-1.689   l0.563,4.055l0.563,1.238l-1.689,1.126l-1.238,1.126L8.672,112.5H5.18L4.618,111.824z" fill="#663300" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Lower">
            <path d="M13.401,107.207l-1.802-1.238H6.982L6.306,90.203l0.676-7.096   L6.306,67.905l1.239-12.838l0.563-3.604l6.419-0.563l7.095,0.563h5.856l0.563,10.022l0.563,8.221l-1.126,14.64l0.563,7.546   l-1.126,14.752l-0.563-0.676h-4.729l-0.563,0.676l-1.802-13.514l-0.563-9.459l-1.238-13.965l-1.127,13.4l-2.364,14.641   L13.401,107.207z" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M17.456,69.145l-1.127,12.274l-2.364,15.766l-0.563,7.095l-0.563-5.293V88.964l2.928-12.274l0.563-5.855v-4.055v-2.365   l0.563-10.585v10.023l0.563,3.49l2.364-4.166l-1.126,5.293l1.126,5.855l0.676,14.64l0.563,10.585l1.688,4.73l-1.688,0.563   l-1.239-7.657l-1.126-15.202L17.456,69.145z" />
        </g>
        <g id="Group_Face">
            <path d="M22.635,11.148h-9.347l-1.239-1.013l-0.675-1.126V7.883   l0.675-1.126h11.825l0.675,1.126v1.126l-0.675,1.126L22.635,11.148z" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="17.906" cy="8.446" rx="5.631" ry="7.32" fill="#FFCC99" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M17.906,11.036l1.576,0.45h-3.266L17.906,11.036z" fill="#E5B27F" />
            <path d="M16.217,13.063l-0.45-0.45h4.167l-0.451,0.45H16.217z" fill="#E5B2B2" />
            <path d="M15.766,12.613h4.167" fill="none" stroke="#CC99B2" stroke-width="0.25" />
            <path d="M21.284,6.644l-2.702-0.45l0.225,0.45L21.51,6.87L21.284,6.644z" fill="#665940" />
            <path d="M14.415,6.644l2.815-0.45l-0.338,0.45L14.189,6.87L14.415,6.644z" fill="#665940" />
            <path d="M15.541,8.896h0.45l0.226-0.676l-0.226-0.676h-0.45l-0.225,0.676L15.541,8.896z" fill="#665940" />
            <path d="M19.708,8.896h0.45l0.226-0.676l-0.226-0.676h-0.45l-0.226,0.676L19.708,8.896z" fill="#665940" />
        </g>
        <g id="Group_Hair">
            <path d="M12.501,7.996L12.05,6.644l-0.675,1.352l0.675-5.068l1.689-1.802   l1.802-1.013L17.906,0l2.252,0.113l1.802,1.013l1.689,1.802l0.9,5.068l-0.675-1.352l-0.451,1.352l-0.9-3.716L21.51,2.928   l-0.226-0.45l-0.45,0.901l-5.293,0.676h-2.026l-0.226,0.225L12.501,7.996z" fill="#996633" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M20.834,3.378l0.45-2.252h0.226v1.802l-0.226-0.45L20.834,3.378z" fill="#663300" />
        </g>
        <g id="Group_Blazer">
            <path d="M15.203,16.892L6.42,19.257l-5.293,2.928l-1.126,1.802   l0.45,12.838l-0.45,10.022l1.126,9.91l1.126-1.689l3.491-0.675l1.238-6.982l0.563-11.148l0.563,8.784L6.42,66.779L5.744,81.982   l8.783,1.125l1.802-1.125L15.766,37.5l0.563-12.387L15.203,16.892z" fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M20.496,16.892l9.347,1.802l4.167,2.928l0.563,2.365l0.563,10.586   L34.46,46.847l-1.126,9.91l-1.126-1.689l-3.491-0.675l-0.676-7.545l-0.563-11.711v14.64l0.563,7.545l1.802,24.662l-7.545,1.125   l-1.802-1.125l-1.238-31.082L18.694,37.5L20.496,16.892z" fill="#F2F2F2" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="6.306" cy="37.162" rx="1.689" ry="9.685" fill="#CCCCCC" />
            <path d="M26.915,28.716l0.563,4.054l-1.127,1.802V37.5l1.127,12.275v-14.64l0.563,8.784l0.563-8.784   l1.802-3.491l-2.365,2.252l1.239-11.712l-1.802,8.221L26.915,28.716z" fill="#CCCCCC" />
            <path d="M12.163,65.541l0.676-6.42l-5.293-0.563v1.126l4.054,0.563L12.163,65.541z" fill="#E5E5E5" />
            <path d="M22.748,66.217l-0.563-6.532l5.18-0.563v1.127l-4.054,0.676L22.748,66.217z" fill="#E5E5E5" />
        </g>
        <g id="Group_Stethoscope">
            <path d="M13.965,39.752h0.563l0.676,5.292l-0.676,2.928l-1.688,0.563v-0.563   l1.126-0.563l0.563-2.365l-0.563-4.729h-0.563l-0.563-5.18v-5.293l0.563-5.856l-0.563-5.856l1.688-1.802l0.676,0.563h-0.676   l-1.126,1.802l0.563,5.292l-0.563,5.856v5.293L13.965,39.752z" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M13.289,39.527l-1.014,0.563l2.14,4.842l2.027,2.252l1.688-0.338   l-0.225-0.563l-1.352,0.113l-1.576-1.802l-1.915-4.279l0.563-0.338L13.289,39.527z" fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M21.622,46.847l-1.126-2.365l1.126-2.365l1.239,2.365   L21.622,46.847z" fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" />
            <ellipse cx="21.622" cy="29.729" rx="1.126" ry="13.401" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
export class Stack{
    items:any[];
    constructor()
    {
        this.items = [];
    }
    public push(element:any):void
    {
        // push element into the items
        this.items.push(element);
    }
    public pop():any
    {
        // return top most element in the stack
        // and removes it from the stack
        // Underflow if stack is empty
        if (this.items.length == 0)
            return null;
        return this.items.pop();
    }
    public peek():any
    {
        // return the top most element from the stack
        // but does'nt delete it.
        return this.items[this.items.length - 1];
    }
    // isEmpty function
    public isEmpty():boolean
    {
	// return true if stack is empty
	return this.items.length == 0;
    }

}
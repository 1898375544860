import { SVGObject } from "../svgobject";

export class SVGFlex6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 111.824 112.5" enable-background="new 0 0 111.824 112.5" xml:space="preserve">
        <g id="Group_Tube">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="65.7153" y1="68.2598" x2="43.7564" y2="46.3008" gradientTransform="matrix(1 0 0 1 -0.0063 0.0967)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M87.668,2.479L0,90.259L21.565,112.5l88.288-88.288L87.668,2.479z" />
        </g>
        <g id="Group_45_Lines">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="44.1162" y1="46.3687" x2="88.0911" y2="2.3938">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.031,46.284L88.175,2.478" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="66.104" y1="68.3564" x2="110.022" y2="24.4385">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M66.103,68.355l43.918-43.918" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="97.1841" y1="14.5283" x2="98.9863" y2="12.7261">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M84.346,1.689L86.148,0l25.676,25.563l-1.803,1.802   L84.346,1.689z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="93.355" y1="18.3569" x2="95.3823" y2="16.3296">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M80.517,5.519l2.027-2.027l25.676,25.675l-2.027,2.027   L80.517,5.519z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="89.7515" y1="21.9604" x2="91.553" y2="20.1589">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M76.914,9.122l1.802-1.689l25.675,25.563l-1.801,1.802   L76.914,9.122z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="86.1479" y1="25.564" x2="87.9497" y2="23.7622">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M73.31,12.726l1.802-1.802l25.675,25.675l-1.801,1.802   L73.31,12.726z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="82.5444" y1="29.1675" x2="84.3462" y2="27.3657">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M69.707,16.329l1.802-1.802l25.675,25.675l-1.801,1.802   L69.707,16.329z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="78.7163" y1="32.9961" x2="80.5181" y2="31.1943">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M65.878,20.158l1.801-1.689l25.676,25.563l-1.802,1.802   L65.878,20.158z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="75.1118" y1="36.6001" x2="76.9138" y2="34.7981">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M62.274,23.762l1.802-1.802l25.676,25.675l-1.802,1.802   L62.274,23.762z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="71.5083" y1="40.2036" x2="73.3103" y2="38.4016">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M58.67,27.365l1.802-1.802l25.676,25.675l-1.802,1.802   L58.67,27.365z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="67.6802" y1="44.0322" x2="69.7073" y2="42.0051">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M54.842,31.194l2.027-2.027l25.676,25.675l-2.027,2.027   L54.842,31.194z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="64.0767" y1="47.6357" x2="65.8784" y2="45.834">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M51.238,34.797l1.802-1.802l25.675,25.675l-1.802,1.802   L51.238,34.797z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="60.4731" y1="51.2393" x2="62.2749" y2="49.4375">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M47.635,38.401l1.802-1.802l25.675,25.676l-1.802,1.801   L47.635,38.401z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="56.8696" y1="54.8428" x2="58.6716" y2="53.0408">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.031,42.005l1.802-1.802l25.675,25.676l-1.802,1.801   L44.031,42.005z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="0.1978" y1="90.4004" x2="44.0601" y2="46.5381">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.225,90.428l43.806-43.918" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="21.9595" y1="112.5" x2="66.1035" y2="68.356">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M21.959,112.5l44.144-44.145" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="53.0405" y1="58.6719" x2="54.8423" y2="56.8701">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M40.202,45.833l1.802-1.802l25.675,25.675l-1.801,1.802   L40.202,45.833z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="49.437" y1="62.2754" x2="51.2388" y2="60.4736">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M36.599,49.437l1.802-1.802l25.675,25.675l-1.802,1.802   L36.599,49.437z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="45.8335" y1="65.8789" x2="47.6353" y2="64.0771">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M32.995,53.041l1.802-1.802l25.675,25.675l-1.802,1.802   L32.995,53.041z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="42.0044" y1="69.708" x2="43.8064" y2="67.906">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" stroke="#4C4C4C" stroke-width="0.25" d="M29.167,56.869l1.802-1.802l25.676,25.676l-1.802,1.802   L29.167,56.869z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="38.4009" y1="73.3115" x2="40.259" y2="71.4534">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" stroke="#4C4C4C" stroke-width="0.25" d="M25.563,60.473l1.914-1.802L53.04,84.347l-1.802,1.802   L25.563,60.473z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="34.7974" y1="76.915" x2="36.655" y2="75.0574">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" stroke="#4C4C4C" stroke-width="0.25" d="M21.959,64.076l1.914-1.801L49.437,87.95l-1.802,1.802   L21.959,64.076z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="30.9688" y1="80.7432" x2="33.052" y2="78.6599">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.13,67.905l2.14-2.026l25.563,25.563l-2.027,2.139   L18.13,67.905z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="27.3652" y1="84.3467" x2="29.2234" y2="82.4885">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" stroke="#4C4C4C" stroke-width="0.25" d="M14.527,71.509l1.915-1.802l25.563,25.676l-1.802,1.801   L14.527,71.509z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="23.7612" y1="87.9512" x2="25.6196" y2="86.0928">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.923,75.112l1.915-1.802L38.4,98.986l-1.802,1.802   L10.923,75.112z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="20.1855" y1="91.582" x2="22.0439" y2="89.7236">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" stroke="#4C4C4C" stroke-width="0.25" d="M7.432,78.716l1.802-1.802l25.563,25.563l-1.802,1.915   L7.432,78.716z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="16.3291" y1="95.3828" x2="18.1873" y2="93.5247">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.491,82.545l1.915-1.802l25.563,25.675l-1.802,1.803   L3.491,82.545z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="12.7529" y1="99.0146" x2="14.6675" y2="97.1001">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.6" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,86.148l1.802-1.802l25.675,25.563l-1.914,1.915L0,86.148z   " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.7916666666666666, 0.0, 0.6, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);


        super.drawObject();
    }
}
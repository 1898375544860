import { SVGObject } from "../svgobject";

export class SVGHeating6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 84.348 112.5" enable-background="new 0 0 84.348 112.5" xml:space="preserve">
        <g id="Group_Base">
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M0,0h84.348v112.5H0V0z" />
        </g>
        <g id="Group_Windings">
            
                <radialGradient id="SVGID_1_" cx="-27.0671" cy="102.6777" r="9.7961" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -7.3013)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M8.209,99.189c-2.37,0.16-4.748,0.232-7.125,0.226c-0.165,0.069-0.346,0.119-0.524,0.171   c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692c0.175,0.733,0.196,1.502,0.076,2.248   c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94c3.028-0.012,5.957-0.154,8.739-0.407   C7.969,103.322,7.576,101.062,8.209,99.189z" />
            
                <radialGradient id="SVGID_2_" cx="-25.4011" cy="102.6787" r="9.7952" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -7.3012)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M32.551,88.054c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c18.24-0.07,32.957-4.811,32.957-10.651C39.275,92.059,36.77,89.838,32.551,88.054z" />
            
                <radialGradient id="SVGID_3_" cx="-25.4744" cy="32.7188" r="9.7949" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 126.7159)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M16.499,84.216c0.122,2.483-1.351,5.053-4.418,5.265c5.622,0.277,11.205,1.06,16.589,2.787   c0.082,0.025,0.15,0.064,0.229,0.094c0.274,0.074,0.535,0.164,0.779,0.281c1.972,0.42,3.021,1.691,3.334,3.178   c0.475,1.531,0.241,3.408-0.711,4.881c4.219-1.783,6.724-4.004,6.724-6.412C39.025,89.616,29.604,85.652,16.499,84.216z" />
            
                <radialGradient id="SVGID_4_" cx="-25.3279" cy="88.8857" r="9.7952" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -8.6306)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M32.801,73.054c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c18.24-0.07,32.957-4.811,32.957-10.651C39.525,77.059,37.02,74.838,32.801,73.054z" />
            
                <radialGradient id="SVGID_5_" cx="-25.4011" cy="46.5117" r="9.7949" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 125.3865)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M14.999,69.039c-0.119,2.399-1.725,4.711-4.825,4.711c-2.211,0-3.664-1.176-4.358-2.721   c0.017,0.504-0.026,1.011-0.143,1.5c0.057,0.227,0.095,0.459,0.124,0.693c0.174,0.301,0.328,0.613,0.436,0.947   c0.179,0.051,0.36,0.102,0.524,0.17c0.765-0.002,1.53,0.008,2.295,0.021c0.35-0.071,0.723-0.11,1.122-0.11   c0.515,0,0.984,0.07,1.417,0.188c5.872,0.245,11.709,1.026,17.33,2.83c0.082,0.025,0.15,0.064,0.229,0.094   c0.274,0.074,0.535,0.164,0.779,0.281c1.972,0.42,3.021,1.691,3.334,3.178c0.475,1.531,0.241,3.408-0.711,4.881   c4.219-1.783,6.724-4.004,6.724-6.412C39.275,74.41,29.007,70.303,14.999,69.039z" />
            
                <radialGradient id="SVGID_6_" cx="-25.4744" cy="74.6338" r="9.7944" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -10.0042)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" d="M32.301,57.554c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c0.754-0.003,1.5-0.017,2.241-0.035c0.547-0.21,1.166-0.333,1.865-0.333c0.528,0,1.009,0.073,1.451,0.196   c15.58-0.898,27.4-5.246,27.4-10.479C39.025,61.559,36.52,59.338,32.301,57.554z" />
            
                <radialGradient id="SVGID_7_" cx="-25.5476" cy="60.7637" r="9.7881" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 124.0129)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M13.983,53.496c0.616,1.888,0.201,4.157-1.251,5.526c5.317,0.317,10.592,1.109,15.688,2.745   c0.082,0.025,0.15,0.064,0.229,0.094c0.274,0.074,0.535,0.164,0.779,0.281c1.972,0.42,3.021,1.691,3.334,3.178   c0.475,1.531,0.241,3.408-0.711,4.881c4.219-1.783,6.724-4.004,6.724-6.412C38.775,58.851,28.254,54.704,13.983,53.496z" />
            
                <radialGradient id="SVGID_8_" cx="-25.4011" cy="60.6104" r="9.7952" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -11.3558)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_8_)" d="M32.551,42.304c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c18.24-0.07,32.957-4.811,32.957-10.651C39.275,46.309,36.77,44.088,32.551,42.304z" />
            
                <radialGradient id="SVGID_9_" cx="-25.4744" cy="74.7861" r="9.7949" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 122.6614)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" d="M13.005,38.147C12.943,40.597,11.335,43,8.174,43c-1.065,0-1.951-0.277-2.664-0.732   c0.01,0.069,0.028,0.136,0.037,0.205c0.174,0.301,0.328,0.613,0.436,0.947c0.179,0.051,0.36,0.102,0.524,0.17   c7.489-0.021,15.002,0.629,22.163,2.928c0.082,0.025,0.15,0.064,0.229,0.094c0.274,0.074,0.535,0.164,0.779,0.281   c1.972,0.42,3.021,1.691,3.334,3.178c0.475,1.531,0.241,3.408-0.711,4.881c4.219-1.783,6.724-4.004,6.724-6.412   C39.025,43.46,27.898,39.215,13.005,38.147z" />
            
                <radialGradient id="SVGID_10_" cx="-25.4744" cy="46.1279" r="9.7949" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -12.7516)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M32.301,26.554c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c18.24-0.07,32.957-4.811,32.957-10.651C39.025,30.559,36.52,28.338,32.301,26.554z" />
            
                <radialGradient id="SVGID_11_" cx="-25.5476" cy="89.2686" r="9.7949" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 121.2656)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_11_)" d="M5.317,24.589c0.011,0.485-0.031,0.97-0.144,1.44c0.057,0.227,0.095,0.459,0.124,0.693   c0.174,0.301,0.328,0.613,0.436,0.947c0.179,0.051,0.36,0.102,0.524,0.17c0.812-0.002,1.624,0.007,2.436,0.022   C6.89,27.449,5.766,26.133,5.317,24.589z" />
            
                <radialGradient id="SVGID_12_" cx="-25.5476" cy="89.2686" r="9.7949" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 121.2656)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" d="M14.743,22.562c0.182,2.399-1.135,4.927-3.942,5.365c5.97,0.232,11.907,1.007,17.619,2.841   c0.082,0.025,0.15,0.064,0.229,0.094c0.274,0.074,0.535,0.164,0.779,0.281c1.972,0.42,3.021,1.691,3.334,3.178   c0.475,1.531,0.241,3.408-0.711,4.881c4.219-1.783,6.724-4.004,6.724-6.412C38.775,27.939,28.626,23.851,14.743,22.562z" />
            
                <radialGradient id="SVGID_13_" cx="-25.5476" cy="32.335" r="9.7949" gradientTransform="matrix(3.4058 0 0 0.9911 92.4213 -14.081)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" d="M32.051,11.554c0.952,1.474,1.186,3.351,0.711,4.881c-0.313,1.487-1.362,2.758-3.334,3.179   c-0.244,0.116-0.505,0.207-0.779,0.281c-0.079,0.029-0.147,0.067-0.229,0.094c-7.161,2.298-14.674,2.948-22.163,2.927   c-0.165,0.069-0.346,0.119-0.524,0.171c-0.108,0.333-0.262,0.646-0.436,0.947c-0.028,0.233-0.066,0.466-0.124,0.692   c0.175,0.733,0.196,1.502,0.076,2.248c0.084,0.229,0.153,0.464,0.206,0.704c0.147,0.302,0.276,0.613,0.363,0.94   c18.24-0.07,32.957-4.811,32.957-10.651C38.775,15.559,36.27,13.338,32.051,11.554z" />
            
                <radialGradient id="SVGID_14_" cx="-78.6404" cy="103.1289" r="9.795" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -7.2578)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_14_)" d="M84.348,101.716c-0.057-0.227-0.096-0.459-0.123-0.692c-0.175-0.301-0.328-0.614-0.437-0.947   c-0.179-0.052-0.36-0.102-0.524-0.171c-3.004,0.009-6.01-0.099-8.996-0.37c0.861,1.734,0.734,3.988-0.389,5.563   c3.102,0.319,6.4,0.497,9.824,0.51c0.086-0.327,0.215-0.639,0.362-0.94c0.054-0.24,0.122-0.475,0.206-0.704   C84.152,103.218,84.173,102.449,84.348,101.716z" />
            
                <radialGradient id="SVGID_15_" cx="-77.0046" cy="102.9082" r="9.7952" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -7.2791)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" d="M51.897,88.304c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C45.173,92.309,47.678,90.088,51.897,88.304z" />
            
                <radialGradient id="SVGID_16_" cx="-77.0779" cy="32.4888" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 126.738)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" d="M75.851,83.93C58.8,84.405,45.423,88.97,45.423,94.539c0,2.408,2.505,4.629,6.724,6.412   c-0.952-1.473-1.186-3.35-0.711-4.881c0.313-1.486,1.361-2.758,3.334-3.178c0.244-0.117,0.505-0.207,0.779-0.281   c0.078-0.029,0.146-0.068,0.229-0.094c7.161-2.299,14.674-2.949,22.163-2.928c0.165-0.068,0.346-0.119,0.524-0.17   c0.062-0.189,0.14-0.371,0.226-0.548C76.686,88.039,75.74,85.952,75.851,83.93z" />
            
                <radialGradient id="SVGID_17_" cx="-76.9309" cy="88.4258" r="9.7952" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -8.6749)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_17_)" d="M51.647,72.554c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C44.923,76.559,47.428,74.338,51.647,72.554z" />
            
                <radialGradient id="SVGID_18_" cx="-77.0046" cy="46.9707" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 125.3423)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" d="M71.436,69.744c-0.637,0.317-1.388,0.506-2.262,0.506c-1.458,0-2.582-0.514-3.381-1.305   c-12.103,1.598-20.62,5.4-20.62,9.844c0,2.408,2.505,4.629,6.724,6.412c-0.952-1.473-1.186-3.35-0.711-4.881   c0.313-1.486,1.361-2.758,3.334-3.178c0.244-0.117,0.505-0.207,0.779-0.281c0.078-0.029,0.146-0.068,0.229-0.094   c5.933-1.904,12.106-2.668,18.309-2.865c-0.204-0.668-0.283-1.379-0.23-2.081C72.615,71.383,71.895,70.635,71.436,69.744z" />
            
                <radialGradient id="SVGID_19_" cx="-76.9309" cy="74.6338" r="9.7947" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -10.0042)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_19_)" d="M51.647,57.554c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C44.923,61.559,47.428,59.338,51.647,57.554z" />
            
                <radialGradient id="SVGID_20_" cx="-77.0046" cy="60.7637" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 124.0129)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_20_)" d="M75.237,56.335C74.542,56.752,73.688,57,72.674,57c-2.492,0-4.017-1.495-4.582-3.328   c-13.309,1.399-22.919,5.397-22.919,10.117c0,2.408,2.505,4.629,6.724,6.412c-0.952-1.473-1.186-3.35-0.711-4.881   c0.313-1.486,1.361-2.758,3.334-3.178c0.244-0.117,0.505-0.207,0.779-0.281c0.078-0.029,0.146-0.068,0.229-0.094   c7.161-2.299,14.674-2.949,22.163-2.928c0.165-0.068,0.346-0.119,0.524-0.17c0.005-0.017,0.014-0.031,0.019-0.047   C76.817,58.311,75.827,57.436,75.237,56.335z" />
            
                <radialGradient id="SVGID_21_" cx="-77.0779" cy="60.6104" r="9.7952" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -11.3558)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M52.147,42.304c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C45.423,46.309,47.928,44.088,52.147,42.304z" />
            
                <radialGradient id="SVGID_22_" cx="-77.1511" cy="74.7861" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 122.6614)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_22_)" d="M74.282,41.805c-0.703,0.434-1.571,0.695-2.608,0.695c-2.742,0-4.314-1.81-4.724-3.889   c-12.449,1.545-21.277,5.405-21.277,9.928c0,2.408,2.505,4.629,6.724,6.412c-0.952-1.473-1.186-3.35-0.711-4.881   c0.313-1.486,1.361-2.758,3.334-3.178c0.244-0.117,0.505-0.207,0.779-0.281c0.078-0.029,0.146-0.068,0.229-0.094   c6.751-2.167,13.814-2.861,20.876-2.916C75.739,43.324,74.876,42.655,74.282,41.805z" />
            
                <radialGradient id="SVGID_23_" cx="-77.0046" cy="46.5879" r="9.7952" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -12.7073)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_23_)" d="M51.897,27.054c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C45.173,31.059,47.678,28.838,51.897,27.054z" />
            
                <radialGradient id="SVGID_24_" cx="-77.0779" cy="88.8096" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 121.3098)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M52.147,39.701c-0.952-1.473-1.186-3.35-0.711-4.881c0.313-1.486,1.361-2.758,3.334-3.178   c0.244-0.117,0.505-0.207,0.779-0.281c0.078-0.029,0.146-0.068,0.229-0.094c7.161-2.299,14.674-2.949,22.163-2.928   c0.165-0.068,0.346-0.119,0.524-0.17c0.108-0.334,0.262-0.646,0.436-0.947c0.029-0.234,0.066-0.467,0.124-0.693   c-0.175-0.732-0.196-1.502-0.076-2.248c-0.085-0.229-0.153-0.463-0.206-0.703c-0.147-0.303-0.275-0.613-0.363-0.941   c-18.239,0.07-32.956,4.811-32.956,10.652C45.423,35.697,47.928,37.918,52.147,39.701z" />
            
                <radialGradient id="SVGID_25_" cx="-77.0779" cy="32.335" r="9.7952" gradientTransform="matrix(-3.4058 0 0 0.9911 -183.7236 -14.081)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_25_)" d="M52.147,11.554c-0.952,1.474-1.186,3.351-0.711,4.881c0.313,1.487,1.361,2.758,3.334,3.179   c0.244,0.116,0.505,0.207,0.779,0.281c0.078,0.029,0.146,0.067,0.229,0.094c7.161,2.298,14.674,2.948,22.163,2.927   c0.165,0.069,0.346,0.119,0.524,0.171c0.108,0.333,0.262,0.646,0.436,0.947c0.029,0.233,0.066,0.466,0.124,0.692   c-0.175,0.733-0.196,1.502-0.076,2.248c-0.085,0.229-0.153,0.464-0.206,0.704c-0.147,0.302-0.275,0.613-0.363,0.94   c-18.239-0.07-32.956-4.811-32.956-10.651C45.423,15.559,47.928,13.338,52.147,11.554z" />
            
                <radialGradient id="SVGID_26_" cx="-77.1511" cy="103.0615" r="9.7952" gradientTransform="matrix(-3.4058 0 0 -0.9911 -183.7236 119.9362)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_26_)" d="M52.397,24.201c-0.952-1.473-1.186-3.35-0.711-4.881c0.313-1.486,1.361-2.758,3.334-3.178   c0.244-0.117,0.505-0.207,0.779-0.281c0.078-0.029,0.146-0.068,0.229-0.094c7.161-2.299,14.674-2.949,22.163-2.928   c0.165-0.068,0.346-0.119,0.524-0.17c0.108-0.334,0.262-0.646,0.436-0.947c0.029-0.234,0.066-0.467,0.124-0.693   c-0.175-0.732-0.196-1.502-0.076-2.248c-0.085-0.229-0.153-0.463-0.206-0.703c-0.147-0.303-0.275-0.613-0.363-0.941   c-18.239,0.07-32.956,4.811-32.956,10.652C45.673,20.197,48.178,22.418,52.397,24.201z" />
            
                <radialGradient id="SVGID_27_" cx="-50.8005" cy="48.4175" r="9.7955" gradientTransform="matrix(3.4058 0 0 -0.9911 173.2553 65.6755)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_27_)" d="M0,10.928c0.057,0.227,0.096,0.459,0.123,0.692c0.175,0.301,0.328,0.614,0.437,0.947   c0.179,0.052,0.36,0.102,0.524,0.171c3.004-0.009,6.01,0.099,8.996,0.37c-0.861-1.734-0.734-3.988,0.389-5.563   c-3.102-0.319-6.4-0.497-9.824-0.51C0.559,7.362,0.43,7.674,0.282,7.976C0.229,8.216,0.16,8.45,0.076,8.68   C0.197,9.426,0.175,10.194,0,10.928z" />
            
                <radialGradient id="SVGID_28_" cx="-25.6213" cy="103.0615" r="9.7952" gradientTransform="matrix(3.4058 0 0 -0.9911 92.4213 119.9362)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_28_)" d="M31.801,24.201c0.952-1.473,1.186-3.35,0.711-4.881c-0.313-1.486-1.362-2.758-3.334-3.178   c-0.244-0.117-0.505-0.207-0.779-0.281c-0.079-0.029-0.147-0.068-0.229-0.094c-7.161-2.299-14.674-2.949-22.163-2.928   c-0.165-0.068-0.346-0.119-0.524-0.17c-0.108-0.334-0.262-0.646-0.436-0.947c-0.028-0.234-0.066-0.467-0.124-0.693   c0.175-0.732,0.196-1.502,0.076-2.248c0.084-0.229,0.153-0.463,0.206-0.703c0.147-0.303,0.276-0.613,0.363-0.941   c18.24,0.07,32.957,4.811,32.957,10.652C38.525,20.197,36.02,22.418,31.801,24.201z" />
            
                <radialGradient id="SVGID_29_" cx="-60.1609" cy="48.6016" r="9.795" gradientTransform="matrix(-3.4058 0 0 -0.9911 -120.7861 65.6577)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.72" style="stop-color:#BBBBBB" />
                <stop offset="0.87" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </radialGradient>
            <path fill="url(#SVGID_29_)" d="M84.348,10.728c-0.057,0.227-0.096,0.459-0.123,0.692c-0.175,0.301-0.328,0.614-0.437,0.947   c-0.179,0.052-0.36,0.102-0.524,0.171c-3.004-0.009-6.01,0.099-8.996,0.37c0.861-1.734,0.734-3.988-0.389-5.563   c3.102-0.319,6.4-0.497,9.824-0.51c0.086,0.327,0.215,0.639,0.362,0.94c0.054,0.24,0.122,0.475,0.206,0.704   C84.152,9.226,84.173,9.994,84.348,10.728z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.8072916666666666, 49.0, 0.72, 0.5364583333333334, 117.0, 0.87, 0.08333333333333333, 233.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.25, 191.0]);

        super.drawObject();
    }
}
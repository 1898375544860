import { SVGObject } from "../svgobject";

export class SVGContainer2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Group_x5F_Box" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 103.039" enable-background="new 0 0 112.5 103.039" xml:space="preserve">
        <g id="Group_Box">
        <path fill="#C2A385" d="M0.001,9.347l51.463-7.6l42.229,7.6l11.711,48.036l-11.711,45.656H0.001V9.347z" />
        <path fill="#D6C2AD" d="M0.001,9.347L18.694,0h93.805L93.693,9.347H0.001z" />
        <path fill="#AD845B" d="M93.693,103.039V9.347L112.499,0v93.692L93.693,103.039z" />
        <path fill="#CCB299" d="M42.117,37.5V9.347h9.347V37.5H42.117z" />
        <polygon fill="#E0D1C1" points="105.404,3.491 63.246,3.491 70.27,0 60.924,0 53.9,3.491 11.712,3.491 6.982,5.743 49.368,5.743   42.117,9.347 51.464,9.347 58.714,5.743 100.787,5.743 " />
        <path fill="#B89471" d="M100.787,32.77V5.743l4.617-2.252v26.914L100.787,32.77z" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SliderControlProperty } from "./properties/slidercontrolproperty";
import Konva from "konva";
import { Slider } from "./slider";
import { Value } from '../value';


export class SliderVertical extends Slider{
    unit:string;
    usedigital:boolean;
    interval:number;
    text:string

    public drawObject():void{
      if(this.interval <= 0) this.interval = 1
      super.initObject();
      this.node1  = new Konva.Group({
          x: 0,
          y: 0,
      });
      this.drawObjectVertical(true);
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "text":
          case "unit": {this[field] = value.value;this.rotatednode=null;break;}

          case "interval": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "usedigital": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "text":
          case "unit": {value.datatype=7; value.value = this[field];break}

          case "interval": {value.datatype=3; value.value = this[field];break}
          case "usedigital": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }

    private drawObjectVertical(usefunctions:boolean){
      this.node1.removeChildren();
        
      let color:string = this.color;
      const lineprop = this.properties==null? null : this.properties['color'];
      if (lineprop!=null){	
        const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
        if (linecolorproperty!=null) 
            color = linecolorproperty.getColor(this.tagnames, this.color)
      }	
      const color2 = ColorUtils.convertformat(color)
      
      let fillcolor = '0xffffff00';
        
      let fillheight = this.height*12/20
      let value = 0
      let minimum = 0
      let maximum = 100
      let decimalpos = 1
                
      const sliderprop = this.properties==null? null : this.properties['slidercontrol']
      let sliderproperty;
      if (sliderprop != null){
          sliderproperty =Object.assign(new SliderControlProperty(), sliderprop);
          value = sliderproperty.getCurrentValue(this.tagnames)
          fillheight = sliderproperty.getCurrentFillingFromValue(this.tagnames, this.height*12/20)
          decimalpos = sliderproperty.decimalpos
          minimum = sliderproperty.minimumvalue
          maximum = sliderproperty.maximumvalue
      }

      fillcolor = this.fillcolor;
      const fillprop = this.properties==null? null : this.properties['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
                  fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }	
      const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const gradient1 = ctx.createLinearGradient(0, 0, 0, this.height);
      gradient1.addColorStop(0, ColorUtils.darkColor(color2,0.5))
      gradient1.addColorStop(1, ColorUtils.darkColor(color2,0.3))

      const gradient2 = ctx.createLinearGradient(0, this.height/40, 0, this.height/40 + this.height*15/20);
      gradient2.addColorStop(0, ColorUtils.darkColor(color2,0.3))
      gradient2.addColorStop(1, color2)

      const gradient3 = ctx.createLinearGradient(0, 0, 0, this.height*2/20);
      gradient3.addColorStop(0, 'black')
      gradient3.addColorStop(1, 'white')

            
      const rectout = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          cornerRadius:this.height/20,
          stroke: this.type3d==0? gradient1 : ColorUtils.darkColor(color2, 0.5),
          strokeWidth: this.height/100,
          fill: ColorUtils.darkColor(color2, 0.75)
      });
      this.node1.add(rectout)

      const rectinner = new Konva.Rect({
        x: this.width/40,
        y: this.height/40,
        width: this.width*38/40,
        height: this.height*15/20,
        cornerRadius:this.height/40,
        stroke: this.type3d==0? gradient2 : ColorUtils.darkColor(color2, 0.5),
        strokeWidth: this.height/100,
      });

      if(this.type3d==0){
        rectinner.fillLinearGradientStartPoint({ x: 0, y: this.height/40 });
        rectinner.fillLinearGradientEndPoint({ x: 0, y: this.height*15/20-this.height/100});
        rectinner.fillLinearGradientColorStops([0, color2,
            0.5, 'white',
            1, color2])
      }else{
        rectinner.fill('white')
      }
      this.node1.add(rectinner)

      
      for(let i=0; i<=this.interval; i++){
        const delta = i/this.interval
        const line1 = new Konva.Line({
            points: [this.width*4/10, 
                    this.height*14/20 - this.height*12/20*delta,
                    this.width*5/10,
                    this.height*14/20 - this.height*12/20*delta
                    ],
            strokeWidth: this.height/150,
            stroke: ColorUtils.darkColor(color2, 0.1)
        });
        this.node1.add(line1); 

        for(let j=1; j<5; j++){
          if (i==this.interval) break
          const delta2 = this.height*12/20/this.interval*j/5
          const line2 = new Konva.Line({
              points: [this.width*4/10, 
                      (this.height*14/20 - this.height*12/20*delta)-delta2,
                      this.width*9/20,
                      (this.height*14/20 - this.height*12/20*delta)-delta2
                      ],
              strokeWidth: this.height/200,
              stroke: ColorUtils.darkColor(color2, 0.1)
          });
          this.node1.add(line2); 
        }

        const valuestep = (maximum-minimum)/this.interval;
        const valuepos = minimum+valuestep*i;
				const valuetext = valuepos.toFixed(decimalpos)

        const Text = new Konva.Text({
          x: this.width*11/20,
          y: this.height*14/20 - this.height*12/20*delta-this.height/20/2,
          width: this.width*3/5,
          height: this.height/20,
          text: valuetext,
          fontSize: this.height/20,
          fontFamily: "Roboto",
          fontStyle: "normal",
          fill: ColorUtils.darkColor(color2, 0.1),
          rotation: (this.angle==90 || this.angle ==270)? 270 : 0
          });  
          this.node1.add(Text)	
      }

      const rectvertical = new Konva.Rect({
        x: this.width/5,
        y: this.height/10,
        width: this.width/15,
        height: this.height*12/20,
        fill: ColorUtils.darkColor(color2, 0.75)
      });
      this.node1.add(rectvertical)

      const rectfill = new Konva.Rect({
        x: this.width/5,
        y: this.height/10+(this.height*12/20-fillheight),
        width: this.width/15,
        height: fillheight,
        fill: ColorUtils.darkColor(fillcolor2, 0.75)
      });
      this.node1.add(rectfill)

      const slider = this.drawArrowVertical(fillheight, color2, false)
      this.node1.add(slider)

      const TextLabel = new Konva.Text({
          x: this.width/6,
          y: this.height*17/20- this.height/15,
          verticalAlign:"bottom",
          width: this.width*4/6,
          height: this.height/15,
          text: this.text,
          fontSize: this.height/15,
          fontFamily: "Roboto",
          fontStyle: "normal",
          fill: 'black',
          align: 'center'
      });  
      this.node1.add(TextLabel)	

      const TextUnit = new Konva.Text({
        x: this.width/6,
        y: this.height*15/20 -this.height/18/2,
        width: this.width*4/6,
        height: this.height/18,
        text: this.unit,
        fontSize: this.height/18,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fill: 'black',
      });  
      this.node1.add(TextUnit)

              
      if(this.usedigital){
        const rectdigit = new Konva.Rect({
            x: this.width/10,
            y: this.height*35/40,
            width: this.width*8/10,
            height: this.height*2/20,
            cornerRadius:this.height/50,
            stroke: this.type3d==0? gradient3 : ColorUtils.darkColor(color2, 0.5),
            strokeWidth: this.height/100,
          });
          if(this.type3d==0){
            rectdigit.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectdigit.fillLinearGradientEndPoint({ x: 0, y: this.height*2/20});
            rectdigit.fillLinearGradientColorStops([0, 'white',
                0.25, 'black',
              1, 'black'])
          }else{
            rectdigit.fill('white')
          }
          this.node1.add(rectdigit)
        
          const textValue = new Konva.Text({
            x: this.width/6,
            y: this.height*76/80 -this.height/18,
            verticalAlign:"top",
            width: this.width*4/6,
            height: this.height/18,
            text: value.toFixed(decimalpos),
            fontSize: this.height/15,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fill: 'white',
            align: 'center'
          });  
          this.node1.add(textValue)
      }
              
      this.node.add(this.node1)
              
      if (usefunctions)
        this.setFunctions(fillheight, color2, sliderproperty);
    }

    private drawArrowVertical(fillheight:number, color2:string, draggable:boolean):Konva.Line{
      let arrowwidth = this.width/3;
      let arrowheight = this.height/20;
      if (arrowwidth<1) arrowwidth = 1;
      if (arrowheight<1) arrowheight = 1;
      const xbegin =  this.width/5-arrowwidth/3
      const ybegin = this.height*14/20-arrowheight/2-fillheight
      const slider = new Konva.Line({
        points: [0, 0,
                  arrowwidth*2/3, 0,
                  arrowwidth, arrowheight/2,
                  arrowwidth*2/3, arrowheight,
                  0, arrowheight,
                  0, 0
                ],
          x: xbegin,
          y: ybegin,
          closed: true,
          draggable: draggable
      });
      if(this.type3d==0){
        slider.fillLinearGradientStartPoint({ x: 0, y: 0 });
        slider.fillLinearGradientEndPoint({ x: 0, y: arrowheight });
        slider.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.3),
            0.5, ColorUtils.darkColor(color2, 0.75),
            1, ColorUtils.darkColor(color2, 0.3)])
      }else{
        slider.fill(ColorUtils.darkColor(color2, 0.5))
      }
      return slider
    }
        
    protected setFunctions(fillheight:number,color2:string, controlproperty:SliderControlProperty):void{

      if(controlproperty !=null){
        let arrowwidth = this.width/3;
       let arrowheight = this.height/20;
        if (arrowwidth<1) arrowwidth = 1;
        if (arrowheight<1) arrowheight = 1;

        const slider = this.drawArrowVertical(fillheight, color2, true)
        this.node.add(slider)

        controlproperty.getTag
        const originalX = slider.x();
        slider.on('dragmove', () => {
          slider.x(originalX);
                           
          if (slider.y()<this.height/20+arrowheight/2)
              slider.y(this.height/20+arrowheight/2)
          if (slider.y()>14*this.height/20-arrowheight/2)
              slider.y(14*this.height/20-arrowheight/2)
              this.drawObjectVertical(false);
              controlproperty.setCurrentFillingFromValue(this.tagnames, this.height-slider.y(),this.height)
              this.drawObjectVertical(false);            
        });

        slider.on('dragend',()=>{
          controlproperty.sendTagValue(this.tagnames, this.interval)            
        });

        this.node.on('click touchend', ()=> {
          const pos = this.node.getRelativePointerPosition()
          controlproperty.setCurrentFillingFromValue(this.tagnames, this.height-pos.y,this.height)
          controlproperty.sendTagValue(this.tagnames, this.interval)
        })
      } 
      

    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { RotationIndicatorProperty } from "./properties/rotationindicatorproperty";
import Konva from "konva";
import { Fan } from './fan';


export class FanCool extends Fan{

    public drawObject():void{

		super.initObject();
    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
                 
    fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
  let speed = 0
  let indicator = false;
  const rotationprop = this.properties['rotationindicator']
  if(rotationprop!=null){
    const rotationIndicatorProperty: RotationIndicatorProperty = Object.assign(new RotationIndicatorProperty(), rotationprop)
    if(rotationIndicatorProperty!=null) {
      speed = rotationIndicatorProperty.speed;
      indicator = rotationIndicatorProperty.CheckIndicator(this.tagnames)
    }
  }

  const rect = new Konva.Rect({
    x: 0,
    y: 0,
    width: this.width,
    height: this.height
})  
if(this.type3d==0){
    rect.fillLinearGradientStartPoint({ x: 0, y: 0});
    rect.fillLinearGradientEndPoint({ x: this.width, y: 0});
    rect.fillLinearGradientColorStops([0.01, ColorUtils.darkColor(fillcolor2, 0.6),
        0.51, 'white', 
        1, ColorUtils.darkColor(fillcolor2, 0.6)])
}else{
    rect.fill(fillcolor2)
    
}  
this.node.add(rect)
		
const circle = new Konva.Circle({
    x: this.width/2,
    y: this.height/2,
    radius: this.width*0.4652,
    fill: fillcolor2
})  
const circle2 = new Konva.Circle({
    x: this.width/2,
    y: this.height/2,
    radius: this.width*0.4452,
    fill: "#333333"
})  
this.node.add(circle, circle2)

const pointx = this.width/2
const pointy = this.height/2
const shapex = this.width/2-this.width*.07
const shapey = this.height*0.57
  const node1 = new Konva.Group()
  for(let i=360; i>0; i-=60){
    const wing = this.drawWings(node1, this.width, this.height, fillcolor2, this.type3d)
    const x =
            pointx+
            (shapex-pointx)* Math.cos(i*Math.PI/180) -
            (shapey-pointy)* Math.sin(i*Math.PI/180);
            const y =
            pointy+
            (shapex-pointx)* Math.sin(i*Math.PI/180) +
            (shapey-pointy)* Math.cos(i*Math.PI/180);
    wing.position({x:x, y: y})
    wing.rotation(i)
    node1.add(wing)
   }

   
   const circle3 = new Konva.Circle({
       x: this.width/2,
       y: this.height/2,
       radius: this.width*0.13,
       fill: ColorUtils.darkColor(fillcolor2, 0.8)
   })
   const circle4 = new Konva.Circle({
    x: this.width/2,
    y: this.height/2,
    radius: this.width*0.11,
    fill: '#E5E5E5'
    })
    const circle5 = new Konva.Circle({
        x: this.width/2,
        y: this.height/2,
        radius: this.width*0.04,
        fill: fillcolor2
        })
   node1.add(circle3, circle3, circle4, circle5)

   this.node.add(node1)
   
   if (indicator){
      node1.position({x:this.width/2, y:this.height/2})
      node1.offset({
           x: this.width/2,
            y: this.height/2,
      })
   const angle = this.rotation==0?360:-360;
   const anim = new Konva.Animation(function (frame) {
   const angleDiff =angle* (frame.time ) / speed;
   node1.rotation(angleDiff);
   }, this.layer);
 anim.start();
}

}
private drawWings(node, width:number, height:number, fillcolor2:string, type3d:number){
    const windWidth = width*.14+1
    const windHeight = height*0.3464
    const winggroup = new Konva.Group();
    const rect = new Konva.Rect({
        x: 0,
        y: 0, 
        width: windWidth,
        height: windHeight,
        fill: ColorUtils.darkColor(fillcolor2, 0.6)
    })  
    winggroup.add(rect)  
    const coord1 = [{x:-2.027, y:-3.491},
      {x:-1.576, y:-3.491},
      {x:-1.238, y:-3.717},
      {x:-0.676, y:-3.716},
      {x:-0.338, y:-3.829},
      {x:0.0, y:-3.828},
      {x:0.451, y:-3.828},
      {x:0.9, y:-3.717},
      {x:1.352, y:-3.604},
      {x:1.801, y:-3.491},
      {x:0.676, y:-1.126},
      {x:0.677, y:-1.127},
        {x:0.0, y:-1.015}]
        
    const coord2 = [{x:-2.027, y:3.379},
          {x:-1.464, y:3.604},
          {x:-1.126, y:3.604},
          {x:-0.789, y:3.829},
          {x:-0.338, y:3.829},
          {x:-0.113, y:1.238},
          {x:0.0, y:1.353},
          {x:0.226, y:3.828},
          {x:0.563, y:3.717},
          {x:1.464, y:3.604},
          {x:1.802, y:3.49},
          {x:0.677, y:1.127}, 
          {x:0.5, y:1.013}]
    const wing = new Konva.Shape({
        sceneFunc: function (context, shape) {
          context.beginPath();
          context.moveTo(windWidth, windHeight);
          let x= windWidth
          let y = windHeight
          coord1.forEach(coord=>{
              x = x + coord.x/15.75*windWidth;
              y = y + coord.y/38.97*windHeight;
              context.lineTo(x,y)
          })
        
          context.lineTo(windWidth, 0)
          context.lineTo(0, 0)
        
         x =0
         y=0
         coord2.forEach(coord=>{
          x = x + coord.x/15.75*windWidth;
          y = y + coord.y/38.97*windHeight;
          context.lineTo(x,y)
      })
          
        context.lineTo(0, windHeight)
        context.lineTo(windWidth,windHeight)
          //context.closePath();
          context.fillStrokeShape(shape);
        },
       // stroke: type3d==0? 'black' : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth: 1,
      });
      if(type3d==0){
        wing.fillLinearGradientStartPoint({ x: 0, y: 0});
        wing.fillLinearGradientEndPoint({ x: 0, y: windHeight});
        wing.fillLinearGradientColorStops([0,ColorUtils.darkColor(fillcolor2, 0.72), 
          0.5, ColorUtils.darkColor(fillcolor2, 1.84),
          0.58, ColorUtils.darkColor(fillcolor2, 1.7),
          0.76, ColorUtils.darkColor(fillcolor2, 1.31),
                  1, ColorUtils.darkColor(fillcolor2, 0.72)])
    }else{
        wing.fill(fillcolor2)
    }
      //node.add(wing)
      //node.add(rect)
      winggroup.add(wing)
      return winggroup

}
}
import { SVGObject } from "../svgobject";

export class SVGTank38 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 65.879 112.5" enable-background="new 0 0 65.879 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="57.2637" x2="65.8794" y2="57.2637">
            <stop offset="0" style="stop-color:#333399" />
            <stop offset="0.52" style="stop-color:#C7C4E2" />
            <stop offset="0.65" style="stop-color:#A3A0D0" />
            <stop offset="0.94" style="stop-color:#4747A3" />
            <stop offset="1" style="stop-color:#333399" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M65.879,2.252L0,2.027V110.36c0,0,7.857,2.14,26.306,2.14c3.345,0,10.036,0,13.381,0  c20.42,0,26.192-2.14,26.192-2.14V2.252" />
        <ellipse fill="#333399" cx="32.884" cy="2.027" rx="32.882" ry="2.027" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.52, 0.3802083333333333, 157.0, 0.65, 0.6197916666666666, 97.0, 0.94, 0.7864583333333334, 0.0, 1.0, 0.6614583333333334, 0.0]);

		
        super.drawObject();
    }
}
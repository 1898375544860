

import { AnalogMeterVertical } from './analogmetervert';


export class AnalogMeterVerticalFill extends AnalogMeterVertical{


    public drawObject():void{
		super.initObject();
        this.drawObjectVertical(true);
    }

   
    
}
import { SVGObject } from "../svgobject";

export class SVGTank23 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 92.254 112.5" enable-background="new 0 0 92.254 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            
                <radialGradient id="SVGID_1_" cx="296.6345" cy="109.582" r="40.1146" gradientTransform="matrix(0.8528 0 0 1.7004 -206.8433 -92.6866)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" d="M45.377,113.044c37.927,0,46.877-33.13,46.877-33.13s-8.912-5.664-46.127-5.664   C10.889,74.25,0,79.914,0,79.914S8.476,113.044,45.377,113.044z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-2.441406e-004" y1="43.9717" x2="92.4509" y2="43.9717">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0.197,80.609V7.61L0,7.594C0,3.4,20.651,0,46.125,0C71.599,0,92.25,3.4,92.25,7.594l0.201,0.017   v72.999l-0.003-0.26c0,4.193-20.65,7.594-46.125,7.594c-25.474,0-46.125-3.4-46.125-7.594" />
            <ellipse fill="none" cx="46.127" cy="7.594" rx="46.125" ry="7.594" />
            <ellipse fill="#4D4D4D" cx="46.125" cy="7.594" rx="46.125" ry="7.594" />
        </g>
        <g id="Group_TankBlades">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="29.6785" y1="77.7598" x2="29.6785" y2="61.7383">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M46.281,69.814l-25.193-8.076c-4.426,0-8.012,3.586-8.012,8.012c0,4.427,3.586,8.01,8.012,8.01   L46.281,69.814z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="29.1814" y1="77.7598" x2="29.1814" y2="61.7383" gradientTransform="matrix(-1 0 0 1 91.2563 0)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M45.472,69.814l25.193-8.076c4.426,0,8.012,3.586,8.012,8.012c0,4.427-3.586,8.01-8.012,8.01   L45.472,69.814z" />
        </g>
        <g id="Group_Hanger">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="42.4138" y1="41.625" x2="49.196" y2="41.625">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="42.414" y="15.229" fill="url(#SVGID_5_)" width="6.782" height="52.792" />
            <radialGradient id="SVGID_6_" cx="45.6716" cy="70.2158" r="5.2529" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <circle fill="url(#SVGID_6_)" cx="45.672" cy="70.216" r="5.253" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
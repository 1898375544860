import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { RotationIndicatorProperty } from "./properties/rotationindicatorproperty";
import Konva from "konva";
import { TwoStateObject } from "./twostateobject";
import { Value } from '../value';


export class Fan extends TwoStateObject{
  rotation:number

  public drawObject():void{

		super.initObject();
    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
              
    fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
  let speed = 0
  let indicator = false;
  const rotationprop = this.properties['rotationindicator']
  if(rotationprop!=null){
    const rotationIndicatorProperty: RotationIndicatorProperty = Object.assign(new RotationIndicatorProperty(), rotationprop)
    if(rotationIndicatorProperty!=null) {
      speed = rotationIndicatorProperty.speed;
      indicator = rotationIndicatorProperty.CheckIndicator(this.tagnames)
    }
    
  }
        const node1 = new Konva.Group()     
        for(let i=0; i<360; i+=45){
          const wing = this.drawWing(node1, this.width, this.height, fillcolor2, this.type3d)
          const x =
            this.width/2+
            (this.width/2-this.width/10-this.width/2)* Math.cos(i*Math.PI/180) -
            (0-this.height/2)* Math.sin(i*Math.PI/180);
            const y =
            this.height/2+
            (this.width/2-this.width/10-this.width/2)* Math.sin(i*Math.PI/180) +
            (0-this.height/2)* Math.cos(i*Math.PI/180);
          wing.position({x:x, y: y})
          wing.rotation(i)
          //node1.add(wing)
        }

        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: this.width/10,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1,
            fill: fillcolor2
        })
        node1.add(circle)
        this.node.add(node1)
        if (indicator){
           node1.position({x:this.width/2, y:this.height/2})
           node1.offset({
                x: this.width/2,
                 y: this.height/2,
           })
        const angle = this.rotation==0?360:-360;
        const anim = new Konva.Animation(function (frame) {
          //console.log(frame.time)
        const angleDiff =angle* (frame.time ) / speed;
        node1.rotation(angleDiff);
        }, this.layer);

      anim.start();
    }

  }
  public drawWing(node:Konva.Group,  width:number, height: number, fillcolor2: string, type3d:number):Konva.Shape{
    const windWidth = width/5
    const windHeight = height/2

    const wing = new Konva.Shape({
        sceneFunc: function (context, shape) {
          context.beginPath();
          context.moveTo(windWidth/4, windHeight);
          context.lineTo(0, windHeight/5);
          context.arcTo(windHeight/20, 0, windWidth,0,windWidth/2)
          context.lineTo(windWidth, 0);
          context.lineTo(windWidth*3/4, windHeight)
          context.closePath();
          context.fillStrokeShape(shape);
        },
        stroke: type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth: 1,
      });
      if(type3d==0){
        wing.fillLinearGradientStartPoint({ x: 0, y: 0});
        wing.fillLinearGradientEndPoint({ x: windWidth, y: 0});
        wing.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                  1, fillcolor2])
    }else{
        wing.fill(fillcolor2)
    }
      node.add(wing)
      return wing
  }

  public setField(field:string, value:Value){
    super.setField(field, value)
    switch(field){
        case "rotation": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
        case "speed": {
          const rotationproperty: RotationIndicatorProperty = this.properties==null ? null : this.properties['rotationindicator'];
          if (rotationproperty != null)  rotationproperty.speed = Math.round(value.value)
          this.rotatednode=null
          break;
        }
    }
    //this.drawObject();
}

  public getField(field:string):Value{
    const value = super.getField(field)
    switch(field){
        case "rotation": {value.datatype=1; value.value = this[field];break}
        case "speed": {
          const rotationproperty: RotationIndicatorProperty = this.properties==null ? null : this.properties['rotationindicator'];
          if (rotationproperty != null) value.datatype=3; value.value = rotationproperty.speed;
          break}
    }
    return value;
  }

}
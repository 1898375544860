import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { LineColorProperty } from './properties/linecolorproperty';
import Konva from "konva";
import { Rectangle } from "./rectangle";
import { Value } from '../value';


export class Border3d extends Rectangle{
   
    cornerradius:number;
    glass:boolean;
    
    public drawObject():void{
		super.initObject();
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1
	
        let color:string = this.color;
		const lineprop = this.properties==null?null:this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)		
		}	
		let fillcolor = '0xffffff00';            
            fillcolor = this.fillcolor;
            const fillprop = this.properties==null?null:this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	

            const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, this.height);
              gradient.addColorStop(0, ColorUtils.convertformat(color))
              gradient.addColorStop(1, 'white')
            const rectout = new Konva.Rect({
                x: this.linewidth/2,
                y: this.linewidth/2,
                width: this.width-this.linewidth,
                height: this.height-this.linewidth,
                cornerRadius: this.cornerradius/4,
               stroke:this.glass?gradient:ColorUtils.convertformat(color),
               strokeWidth:this.linewidth
              });
              if (this.fill){
                if (this.glass){
                    rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
             
                     rectout.fillLinearGradientColorStops([0, 'white',
                         0.15, ColorUtils.convertformat(fillcolor)]) 
                }
                else
                    rectout.fill(ColorUtils.convertformat(fillcolor))
              }
            
              this.node.add(rectout)
  
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        switch(field){
            case "cornerradius": {this[field] = value.value;this.rotatednode=null;break;}
            case "glass": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        switch(field){
            case "cornerradius": {value.datatype=6; value.value = this[field];break}
            case "glass": {value.datatype=0; value.value = (this[field]);break}
        }
        return value;
    }
}
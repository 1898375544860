import { SVGObject } from "../svgobject";

export class SVGTank56 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 61.149 112.5" enable-background="new 0 0 61.149 112.5" xml:space="preserve">
        <g id="Group_1">
            <path fill="#B2B2B2" d="M26.014,20.158h9.009v89.189h-9.009V20.158z" />
        </g>
        <g id="Group_2">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M12.5,16.104l13.514,4.054h9.009l13.514-4.054H12.5z" />
            <path fill="#999999" d="M12.5,4.842v11.261h36.036V4.842L30.519,0L12.5,4.842z" />
            <path fill="#B2B2B2" d="M39.528,15.653h2.702v-5.406h-2.702V15.653z" />
            <path fill="#7F7F7F" d="M36.825,15.653h2.703v-5.406h-2.703V15.653z" />
        </g>
        <g id="Group_TankBody1">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="35.0229" y1="64.3018" x2="61.1489" y2="64.3018">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M35.023,20.158c0,0,1.055-4.054,12.597-4.054c0.229,0,0.688,0,0.917,0   c11.833,0,12.612,3.829,12.612,3.829v88.063c0,0-2.887,4.504-12.387,4.504c-0.133,0-0.398,0-0.531,0   c-10.469,0-13.208-4.279-13.208-4.279" />
            <path fill="#B2B2B2" d="M48.537,112.05h2.702v-5.405h-2.702V112.05z" />
            <path fill="#7F7F7F" d="M45.834,112.05h2.702v-5.405h-2.702V112.05z" />
        </g>
        <g id="Group_TankBody2">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="4.882813e-004" y1="64.3018" x2="26.0142" y2="64.3018">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,20.158c0,0,0.867-4.054,12.275-4.054c0.169,0,0.506,0,0.675,0   c13.063,0,13.063,3.716,13.063,3.716v88.176c0,0-1.314,4.504-12.752,4.504c-0.18,0-0.539,0-0.719,0   C1.262,112.5,0,108.221,0,108.221" />
            <path fill="#B2B2B2" d="M12.951,112.05h2.703v-5.405h-2.703V112.05z" />
            <path fill="#7F7F7F" d="M10.248,112.05h2.703v-5.405h-2.703V112.05z" />
        </g>
        <g id="Group_Railing">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,13.401v2.703" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M61.149,20.158v-1.802" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.997,14.752v2.703" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2622" d="M52.14,13.626v2.478" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.735,13.401v2.703" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M35.023,13.401v2.703V13.401z" />
            <path fill="#4C4C4C" d="M61.149,19.707c0,0-0.097-0.728-0.45-1.013c-2.109-1.704-4.258-2.22-5.854-2.464   c-1.325-0.203-4.049-0.514-5.406-0.576c-0.338-0.016-14.415,0-14.415,0v0.45c0,0,14.077-0.012,14.415,0   c1.452,0.05,4.308,0.297,5.781,0.587c1.422,0.279,3.854,1.125,5.479,2.454c0.176,0.144,0.45,0.789,0.45,0.789v0.225V19.707z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M61.149,17.905c0,0-0.577-2.873-5.657-3.949   c-0.908-0.192-2.067-0.28-3.352-0.417l-4.054-0.139H35.023v0.901h13.063c0,0,3.067,0.074,4.054,0.116   c1.297,0.055,2.539,0.341,3.371,0.563c0.953,0.253,1.782,0.527,2.485,0.896c2.145,1.125,3.152,2.705,3.152,2.705v0.225V17.905" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_1",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_2",[0.0, 0.5885416666666666, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.609375, 99.0, 3.0, 0.9895833333333334, 0.0]);	
        super.drawObject();
    }
}
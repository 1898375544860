import { SVGObject } from "../svgobject";

export class SVGDuct11 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.047 112.5" enable-background="new 0 0 112.047 112.5" xml:space="preserve">
        <g id="Group_Split_Duct">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="51.7444" y1="103.9395" x2="51.7444" y2="0.002">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.39" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" d="M103.488,0.001v103.938H17.342V86.711H0V0.677h17.342   L103.488,0.001z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M17.342,0.677v86.034h86.146" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M13.851,112.498V99.436h-2.928v13.063H13.851z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M109.795,112.498V99.436h-2.703v13.063H109.795z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="60.3582" y1="108.2188" x2="60.3582" y2="103.7148">
                <stop offset="0" style="stop-color:#666666" />
                <stop offset="0.44" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.5" d="M8.671,108.219v-4.504h103.376v4.504H8.671z" />
        </g>
        <g id="Group_Duct_Top">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-0.1404" y1="63.373" x2="40.23" y2="23.0027">
                <stop offset="0" style="stop-color:#C9C9C9" />
                <stop offset="0.19" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M20.045,2.817l40.089,40.314L20.045,83.558V2.817z" />
            <path fill="#CCCCCC" d="M100.56,2.817L60.134,43.131l40.427,40.427V2.817z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="40.0046" y1="23.2275" x2="80.3752" y2="-17.1431">
                <stop offset="0" style="stop-color:#B8B8B8" />
                <stop offset="1" style="stop-color:#B2B2B2" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M100.56,3.042L60.134,43.131L19.819,3.042H100.56z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="19.8191" y1="63.3438" x2="100.5603" y2="63.3438">
                <stop offset="0" style="stop-color:#E4E4E4" />
                <stop offset="1" style="stop-color:#D6D6D6" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M100.56,83.558L60.134,43.131L19.819,83.558H100.56z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.39, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7916666666666666, 0.0, 0.44, 0.609375, 99.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4270833333333333, 145.0, 0.19, 0.453125, 139.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5625, 111.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.21875, 199.0, 1.0, 0.328125, 171.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Duct_Top",[0.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
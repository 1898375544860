import { SVGObject } from "../svgobject";

export class SVGValve12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 65.988 112.5" enable-background="new 0 0 65.988 112.5" xml:space="preserve">
        <g id="Group_Connector">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="23.6472" y1="82.6006" x2="42.2283" y2="82.6006">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="1" style="stop-color:#FFFF99" />
            </linearGradient>
            <polygon fill="url(#SVGID_1_)" points="37.048,80.518 28.828,80.518 28.715,80.518 23.647,80.518 23.647,84.684 28.715,84.684    28.828,84.684 37.048,84.684 42.228,84.684 42.228,80.518  " />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="14.4412" y1="108.2246" x2="14.4993" y2="89.9492">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="1" style="stop-color:#FFFF99" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="12.386,89.751 12.386,94.818 12.386,103.15 12.386,108.331 16.553,108.331 16.553,103.15    16.553,94.818 16.553,89.751  " />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="51.488" y1="108.2256" x2="51.5461" y2="89.9492">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="1" style="stop-color:#FFFF99" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="49.434,89.751 49.434,94.818 49.434,103.15 49.434,108.331 53.601,108.331 53.601,103.15    53.601,94.818 53.601,89.751  " />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.434,103.15v-8.332h4.167v8.332H49.434z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M42.228,80.518v4.166h-5.181v-4.166H42.228z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.386,103.15v-8.332h4.167v8.332H12.386z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.434,89.751h4.167v5.067h-4.167V89.751z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.715,80.518h8.333v4.166h-8.333V80.518z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.386,89.751h4.167v5.067h-4.167V89.751z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M12.386,103.15h4.167v5.181h-4.167V103.15z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="32.8552" y1="86.8174" x2="33.1453" y2="112.2871">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="0.78" style="stop-color:#FFFF7D" />
                <stop offset="1" style="stop-color:#FFFF00" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.381,108.331l-0.338,0.112l-0.788,0.563l-0.9,0.901   l-0.676,1.013l-0.338,0.45c0,0-1.333,0.724-3.604,0.789c-1.736,0.05-4.617,0.112-4.617,0.112l-1.126,0.226l-1.238-0.226   c0,0-1.53-0.061-3.379-0.112c-2.314-0.064-4.729-0.789-4.729-0.789l-0.788-0.9l-0.676-1.014l-0.788-0.788l-0.338-0.226l-0.45-0.112   l-4.054-1.014l-0.112-16.554l6.193-2.026v-2.026h20.606v2.026l6.193,2.026v16.441l-1.013,0.338l-1.014,0.225l-1.014,0.338   L45.381,108.331z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.434,103.15h4.167v5.181h-4.167V103.15z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M28.715,80.518v4.166h-5.181v-4.166H28.715z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M22.635,84.57h20.606v2.027H22.635V84.57z" />
        </g>
        <g id="Group_Pipe2">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="26.8005" y1="77.3643" x2="39.1873" y2="77.3643">
                <stop offset="0" style="stop-color:#FFCC00" />
                <stop offset="0.5" style="stop-color:#FFFF7D" />
                <stop offset="1" style="stop-color:#FFCC00" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M26.801,74.211h12.387v6.307H26.801V74.211z" />
        </g>
        <g id="Group_Body2">
            <path fill="#FFCC00" stroke="#4C4C4C" stroke-width="0.25" d="M11.26,32.997h43.354v41.214H11.26V32.997z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="12.3865" y1="53.6035" x2="53.6013" y2="53.6035">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="0.5" style="stop-color:#FFFF99" />
                <stop offset="1" style="stop-color:#FFFF1D" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M12.386,32.997h41.215v41.214H12.386V32.997z" />
        </g>
        <g id="Group_Support">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,20.61H11.26v14.414H0V20.61z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,0.002h6.193v12.274H0V0.002z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M59.795,0.002h6.193v12.274h-6.193V0.002z" />
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M54.615,20.61h11.373v14.414H54.615V20.61z" />
        </g>
        <g id="Group_Body1">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="6.1931" y1="6.1396" x2="59.7947" y2="6.1396">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="0.5" style="stop-color:#FFFF99" />
                <stop offset="1" style="stop-color:#FFFF1D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M6.193,0.002h53.602v12.274H6.193V0.002z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="6.1931" y1="26.8032" x2="59.7947" y2="26.8032">
                <stop offset="0" style="stop-color:#FFFF00" />
                <stop offset="0.5" style="stop-color:#FFFF99" />
                <stop offset="1" style="stop-color:#FFFF1D" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M6.193,20.61h53.602v12.387H6.193V20.61z" />
        </g>
        <g id="Group_Screws">
            <circle fill="#CCCCCC" cx="49.434" cy="51.464" r="2.027" />
            <circle fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" cx="15.427" cy="51.464" r="2.027" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M15.427,49.438v4.166" />
            <circle fill="none" stroke="#4C4C4C" stroke-width="0.25" cx="49.434" cy="51.464" r="2.027" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M49.322,49.438v4.166" />
        </g>
        <g id="Group_Pipe1">
            <path fill="#FFFF00" stroke="#4C4C4C" stroke-width="0.25" d="M0,12.277h65.988v8.333H0V12.277z" />
        </g>
        <g id="Group_Bolts">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M4.053,12.277h5.181V9.236H4.053V12.277z" />
            <path fill="#CCCCCC" d="M56.755,12.277h5.18V9.236h-5.18V12.277z" />
            <path fill="#CCCCCC" d="M21.621,12.277h5.18V9.236h-5.18V12.277z" />
            <path fill="#CCCCCC" d="M39.187,12.277h5.18V9.236h-5.18V12.277z" />
            <path fill="#CCCCCC" d="M4.053,24.664h5.181v-3.041H4.053V24.664z" />
            <path fill="#CCCCCC" d="M56.755,24.664h5.18v-3.041h-5.18V24.664z" />
            <path fill="#CCCCCC" d="M21.621,24.664h5.18v-3.041h-5.18V24.664z" />
            <path fill="#CCCCCC" d="M39.187,24.664h5.18v-3.041h-5.18V24.664z" />
            <path fill="#CCCCCC" d="M3.153,20.61h7.094v1.013H3.153V20.61z" />
            <path fill="#CCCCCC" d="M20.607,20.61h7.207v1.013h-7.207V20.61z" />
            <path fill="#CCCCCC" d="M38.174,20.61h7.207v1.013h-7.207V20.61z" />
            <path fill="#CCCCCC" d="M55.741,20.61h7.207v1.013h-7.207V20.61z" />
            <path fill="#CCCCCC" d="M5.067,24.664H8.22v2.14H5.067V24.664z" />
            <path fill="#CCCCCC" d="M22.635,24.664h3.152v2.14h-3.152V24.664z" />
            <path fill="#CCCCCC" d="M40.201,24.664h3.04v2.14h-3.04V24.664z" />
            <path fill="#CCCCCC" d="M57.767,24.664h3.041v2.14h-3.041V24.664z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.094,9.236v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M58.781,9.236v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.661,9.236v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M41.215,9.236v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.094,21.623v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M58.781,21.623v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.661,21.623v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M41.215,21.623v3.041" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.755,12.277h5.18V9.236h-5.18V12.277" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.621,12.277h5.18V9.236h-5.18V12.277" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M39.187,12.277h5.18V9.236h-5.18V12.277" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M4.053,24.664h5.181v-3.041H4.053V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.755,24.664h5.18v-3.041h-5.18V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.621,24.664h5.18v-3.041h-5.18V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M39.187,24.664h5.18v-3.041h-5.18V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M3.153,20.61h7.094v1.013H3.153V20.61" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.607,20.61h7.207v1.013h-7.207V20.61" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M38.174,20.61h7.207v1.013h-7.207V20.61" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M55.741,20.61h7.207v1.013h-7.207V20.61" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M5.067,24.664H8.22v2.14H5.067V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M22.635,24.664h3.152v2.14h-3.152V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M40.201,24.664h3.04v2.14h-3.04V24.664" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M57.767,24.664h3.041v2.14h-3.041V24.664" />
        </g>
        <g id="Group_BlackLine">
            <path d="M65.988,16.443v-0.901H0v1.802h65.988V16.443z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.671875, 83.0, 1.0, 0.2708333333333333, 185.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.671875, 83.0, 1.0, 0.2708333333333333, 185.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.671875, 83.0, 1.0, 0.2708333333333333, 185.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.671875, 83.0, 0.78, 0.3489583333333333, 165.0, 1.0, 0.671875, 83.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.5, 0.3489583333333333, 165.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.671875, 83.0, 0.5, 0.2708333333333333, 185.0, 1.0, 0.6041666666666666, 101.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.671875, 83.0, 0.5, 0.2708333333333333, 185.0, 1.0, 0.6041666666666666, 101.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.671875, 83.0, 0.5, 0.2708333333333333, 185.0, 1.0, 0.6041666666666666, 101.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.671875, 83.0, 1.0, 0.671875, 83.0, 2.0, 0.671875, 83.0, 3.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Pipe1",[0.0, 0.671875, 83.0]);
		this.fillGradient(this.fills,"Group_Bolts",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0, 6.0, 0.40625, 151.0, 7.0, 0.40625, 151.0, 8.0, 0.40625, 151.0, 9.0, 0.40625, 151.0, 10.0, 0.40625, 151.0, 11.0, 0.40625, 151.0, 12.0, 0.40625, 151.0, 13.0, 0.40625, 151.0, 14.0, 0.40625, 151.0, 15.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGTank50 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.37" enable-background="new 0 0 112.5 98.37" xml:space="preserve">
        <g id="Group_Container_sub">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="52.6543" x2="112.5" y2="52.6543">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.07" style="stop-color:#575757" />
            <stop offset="0.22" style="stop-color:#969696" />
            <stop offset="0.35" style="stop-color:#C5C5C5" />
            <stop offset="0.45" style="stop-color:#E1E1E1" />
            <stop offset="0.5" style="stop-color:#ECECEC" />
            <stop offset="0.56" style="stop-color:#E4E4E4" />
            <stop offset="0.64" style="stop-color:#CFCFCF" />
            <stop offset="0.74" style="stop-color:#ADADAD" />
            <stop offset="0.86" style="stop-color:#7E7E7E" />
            <stop offset="0.98" style="stop-color:#424242" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M112.5,6.935H0l14.127,86.302c0,0,7.623,5.137,42.123,5.137c35.375,0,41.866-4.623,41.866-4.623  L112.5,6.935" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="6.9351" x2="112.5" y2="6.9351">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <ellipse fill="url(#SVGID_2_)" cx="56.25" cy="6.935" rx="56.25" ry="6.935" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.07, 0.6770833333333334, 0.0, 0.22, 0.828125, 43.0, 0.35, 0.4583333333333333, 137.0, 0.45, 0.23958333333333334, 193.0, 0.5, 0.16145833333333334, 214.0, 0.56, 0.21875, 199.0, 0.64, 0.3802083333333333, 157.0, 0.74, 0.6510416666666666, 89.0, 0.86, 0.9791666666666666, 0.0, 0.98, 0.5104166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
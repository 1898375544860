import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { format } from 'date-fns'
import { ru, enUS, fr, de, es, it, pt, pl, zhCN } from "date-fns/locale";
import authModule from "@/store/auth.module";


export class TimerControlTile extends Tile{

  //timer:NodeJS.Timeout;

  public deleteObject():void{
    super.deleteObject()
    //clearTimeout(this.timer)
  }
  public drawObject():void{
    super.initObject();

    const locales = {
      "en-US": enUS,
      'en': enUS,
      "fr-FR": fr,
      'de-DE': de,
      'pt-BR': pt,
      'pl-PL': pl,
      'ru-RU': ru,
      'ru': ru,
      'es-ES': es,
      'es': es,
      'it-IT': it,
      'zh-CN': zhCN
      // add other locales you need
    };

   //this.timer = setTimeout(()=>{this.drawObject()}, 1000);
   this.setUpdate(true)

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    const size =this.width < this.height ? this.width*.8 : this.height*.8;
    const centerX = this.width * 0.5;
    const centerY = this.height * 0.5;
    const width = this.width;
    const height = this.height;

    const group = new Konva.Group({
        clipFunc: function (ctx) {
          ctx.strokeStyle = "red";
          ctx.lineTo(0.05*size, 0);
          ctx.lineTo(width-0.05*size, 0);
          ctx.quadraticCurveTo(width, 0, width, 0.05*size);
          ctx.lineTo(width, height-0.05*size)
          ctx.quadraticCurveTo(width, height, width-0.05*size, height);
          ctx.lineTo(0.05*size, height)
          ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
          ctx.lineTo(0, 0.05*size)
          ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
        }
    });

    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
        x: size*.05,
        y: size*0.1 - 0.06*size,
        width: this.width-size*.05*2,
        verticalAlign: 'bottom',
        text: this.title,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left',
      }); 
    group.add(titleText) 

    const descriptionText = new Konva.Text({
      x: size*.05,
      y: this.height - size*0.05 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.description,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(descriptionText) 
      
    let  sinValue;
    let  cosValue;
    const  startAngle = 180;
    const  angleStep  = 360 / 60;
    let angle = 0
    //const radians = degrees*Math.PI/180;
    
    for (let counter = 0 ; counter<=59;counter++) {
     
      sinValue = Math.sin((angle + startAngle)*Math.PI/180);
      cosValue = Math.cos((angle + startAngle)*Math.PI/180);

      const x1 = centerX + size * 0.405 * sinValue //innerPoint x
      const y1 = centerY + size * 0.405 * cosValue // innerPoint y
      const x2 = centerX + size * 0.465 * sinValue // outerPoint x
      const y2 = centerY + size * 0.465 * cosValue // outerPoint y
      const x3 = centerX + size * 0.435 * sinValue // innerMinutePoint x
      const y3 = centerY + size * 0.435 * cosValue //innerMinutePoint  y

      if (counter % 5 == 0) {
        // Draw hour tickmark
        const hourTickmark = new Konva.Line({
          points: [x1, y1, x2, y2],
          strokeWidth: size * 0.01,
          stroke: ColorUtils.convertformat(textcolor)
        })
        group.add(hourTickmark) 
      }
      // Draw minute tickmark
      const minuteTickMarks = new Konva.Line({
        points: [x3, y3, x2, y2],
        strokeWidth: size * 0.005,
        stroke: ColorUtils.convertformat(textcolor)
      })
      group.add(minuteTickMarks)

      angle -= angleStep
    }

    const currentdate = new Date(); 
    const hours =  currentdate.getHours()
    const minutes = currentdate.getMinutes()  
    const seconds = currentdate.getSeconds();

    const arrowHour = new Konva.Rect({
      x: 0,
      y: 0,
      width: size * 0.015,
      height: size * 0.29,
      cornerRadius: size * 0.015,
      fill: ColorUtils.convertformat(textcolor)
    });
    arrowHour.position({x:this.width/2-arrowHour.width()/2, y:this.height/2-arrowHour.height()})
    group.add(arrowHour) 
    this.rotateAroundPoint(arrowHour, hours/12*360+minutes/60*30, {x:this.width/2, y:this.height/2})

    const arrowMinute = new Konva.Rect({
      x: 0,
      y: 0,
      width: size * 0.015,
      height: size * 0.47,
      cornerRadius: size * 0.015,
      fill: ColorUtils.convertformat(textcolor)
    });
    arrowMinute.position({x:this.width/2-arrowMinute.width()/2, y:this.height/2-arrowMinute.height()})
    group.add(arrowMinute) 
    this.rotateAroundPoint(arrowMinute, minutes/60*360+seconds/60*6, {x:this.width/2, y:this.height/2})

    const arrowSecond = new Konva.Rect({
      x: 0,
      y: 0,
      width: size * 0.005,
      height: size * 0.47,
      cornerRadius: size * 0.015,
      fill: ColorUtils.convertformat(textcolor)
    });
    arrowSecond.position({x:this.width/2-arrowSecond.width()/2, y:this.height/2-arrowSecond.height()})
    group.add(arrowSecond)
    this.rotateAroundPoint(arrowSecond, seconds/60*360, {x:this.width/2, y:this.height/2})

    const circleBall = new Konva.Circle({
      x: centerX,
      y: centerY,
      radius: size * 0.0225,
      fill: ColorUtils.convertformat(textcolor)
      });
    group.add(circleBall)
    
    let loc = enUS
    if(authModule.currentUser != null){
      loc =  locales[authModule.currentUser.locale]? locales[authModule.currentUser.locale] : enUS
    }
    
    const datetext = format(Date.now(), "EE d", {locale:loc}).toString()
    const ampmtext = format(Date.now(), "a", {locale:enUS}).toString()
    
    //console.log('ampmtext', ampmtext)
   
    const dateText = new Konva.Text({
      x: size*.05,
      y: height * 0.5 + size * 0.22 - 0.08*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: datetext,
      fontSize: 0.08*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'center',
    }); 
    group.add(dateText) 

    const amPmText = new Konva.Text({
      x: size*.05,
      y: height * 0.5 - size * 0.2 - 0.08*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: ampmtext,
      fontSize: 0.08*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'center',
    }); 
    group.add(amPmText) 
    
    this.node.add(group)  
  }

  protected rotateAroundPoint(shape:Konva.Shape, angleDegrees:number, point:any):void {
    const angleRadians = angleDegrees * Math.PI / 180; 
     
    const x =
      point.x +
      (shape.x() - point.x) * Math.cos(angleRadians) -
      (shape.y() - point.y) * Math.sin(angleRadians);
    const y =
      point.y +
      (shape.x() - point.x) * Math.sin(angleRadians) +
      (shape.y() - point.y) * Math.cos(angleRadians);
      
    
    shape.position({x: x, y: y});
    shape.rotation(angleDegrees)
  }   
  
}

import { SVGObject } from "../svgobject";

export class SVGMiscpipe8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Vertical">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-3.1299" y1="259.3984" x2="55.5811" y2="259.3984" gradientTransform="matrix(1 0 0 1 30.9551 -203.4902)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <polyline fill="url(#SVGID_1_)" points="86.536,-0.333 86.323,112.151 27.825,112.151 27.825,-0.333  " />
        </g>
        <g id="Group_Gas_Pipe_Junction">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="150.582" y1="163.6807" x2="91.8711" y2="163.6807" gradientTransform="matrix(0 1 -1 0 191.4805 -65.418)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="27.924,26.453 55.158,55.656 27.924,85.164 0.44,84.951 0.44,26.453  " />
        </g>
        <g id="Group_1">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="150.582" y1="165.0225" x2="91.8711" y2="165.0225" gradientTransform="matrix(0 1 1 0 -79.8818 -65.418)">
                <stop offset="0" style="stop-color:#5C6666" />
                <stop offset="0.41" style="stop-color:#FCFFFF" />
                <stop offset="0.61" style="stop-color:#FCFFFF" />
                <stop offset="1" style="stop-color:#5C6666" />
            </linearGradient>
            <polygon fill="url(#SVGID_3_)" points="85.018,26.453 57.782,55.656 85.018,85.164 112.5,84.951 112.5,26.453  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.7604166666666666, 0.0, 0.41, 0.015625, 250.0, 0.61, 0.015625, 250.0, 1.0, 0.7604166666666666, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";


export class Pump extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)

            const ovalLeft = new Konva.Ellipse({
              x: this.width/10,
              y: this.height/ 2,
              radiusX: this.width/10,
              radiusY: this.height/2,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth: 1,
            });
            if(this.type3d==0){
              ovalLeft.fillLinearGradientStartPoint({ x: 0, y: -this.height/2});
              ovalLeft.fillLinearGradientEndPoint({ x: 0, y: this.height/2});
              ovalLeft.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              ovalLeft.fill(fillcolor2)
            }
            this.node.add(ovalLeft)

            const ovalRight = new Konva.Ellipse({
              x: this.width*7/10,
              y: this.height/ 2,
              radiusX: this.width/20,
              radiusY: this.height/2,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth: 1,
            });
            if(this.type3d==0){
              ovalRight.fillLinearGradientStartPoint({ x: 0, y: -this.height/2});
              ovalRight.fillLinearGradientEndPoint({ x: 0, y: this.height/2});
              ovalRight.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,fillcolor2,
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              ovalRight.fill(fillcolor2)
            }
            this.node.add(ovalRight)

            const rect = new Konva.Rect({
              x: this.width/10,
              y:0,
              width: this.width*6/10,
              height: this.height,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
             strokeWidth:1
            })
            if(this.type3d==0){
              rect.fillLinearGradientStartPoint({ x: 0, y: 0});
              rect.fillLinearGradientEndPoint({ x: 0, y: this.height});
              rect.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                  0.5,'white',
                  1, ColorUtils.darkColor(fillcolor2, 0.75)])
            }else{
              rect.fill(fillcolor2)
            }
            this.node.add(rect)
            
        const pipe2 = new Konva.Rect({
                x: this.width*19/20,
                y:this.height/5,
                width: this.width/20,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
               strokeWidth:1
              })
              if(this.type3d==0){
                if(this.angle==0){
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.15, fillcolor2,
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])  
                } else if(this.angle==180){
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                    0.85, fillcolor2,
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])  
                }else{
                    pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                    pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                    0.5, fillcolor2,
                    1, ColorUtils.darkColor(fillcolor2, 0.75)])  
                }
              }else{
                pipe2.fill(fillcolor2)
              }
              this.node.add(pipe2)

            const stripe3 = new Konva.Rect({
              x: this.width*19/20,
              y: this.height/5,
              width: this.width/100,
              height: this.height*3/5,
              stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
              strokeWidth:1  
            })
            const stripe4 = new Konva.Rect({
                x: this.width*99/100,
                y: this.height/5,
                width: this.width/100,
                height: this.height*3/5,
                stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
                strokeWidth:1 
            })
        if(this.type3d==0){
            if(this.angle==0){
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, 'white',
                1, fillcolor2])
            }else if(this.angle==180){
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, fillcolor2,
                1, 'white'])
            }else{
                stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe3.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
                stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
                stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
                stripe4.fillLinearGradientColorStops([0, fillcolor2,
                0.5, 'white',
                1, fillcolor2])
            }
          }
          this.node.add(stripe3, stripe4)

          const pipe3 = new Konva.Rect({
            x: this.width*29/40,
            y: this.height*3/10,
            width: this.width*9/40,
            height: this.height*4/10,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth:1  
          })
          if(this.type3d==0){
                pipe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
                pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*4/10});
                pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.75),
                0.5, fillcolor2,
                1, ColorUtils.darkColor(fillcolor2, 0.75)])
          }else{
            pipe3.fill(fillcolor2)
          }
          this.node.add(pipe3)
        
         
         const str1 = new Konva.Rect({
            x: this.width/10,
            y: this.height*9/20,
            width: this.width*6/10,
            height: this.height/10,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str2 = new Konva.Rect({
            x: this.width/10,
            y: this.height*5/20,
            width: this.width*6/10,
            height: this.height/10,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str3 = new Konva.Rect({
            x: this.width/10,
            y: this.height/20,
            width: this.width*6/10,
            height: this.height/10,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str4 = new Konva.Rect({
            x: this.width/10,
            y: this.height*13/20,
            width: this.width*6/10,
            height: this.height/10,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
          const str5 = new Konva.Rect({
            x: this.width/10,
            y: this.height*17/20,
            width: this.width*6/10,
            height: this.height/10,
            fill: this.type3d==0? fillcolor2 : ColorUtils.darkColor(fillcolor2, 0.75)
          })
        this.node.add(str1, str2, str2, str3, str4, str5)
            
}
}
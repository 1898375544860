import { Property } from "./property";
import tag from "@/model/tag";


export class ImageProperty extends Property{
    value:string;
    type:number;
    imagebasetrue:string;
    imagebasefalse:string;

    public getImage(tagnames:string[]):string{
        const tag:tag = this.getTag(tagnames);
        const ind = this.getIndicator(tag)
        if(ind) return this.imagebasetrue
        return this.imagebasefalse;

    }
    private getIndicator(tag:tag):boolean{
        if (tag==null) return true;
        let indicator = false;

      
        switch (this.type)
          {
              case 0: if (+this.value==+tag.tagvalue)
                              indicator = true;
                      break;
              case 1: if (+tag.tagvalue>=+this.value)
                indicator = true;
                      break;
              case 2:if (+tag.tagvalue<=+this.value)
                 indicator = true;
                      break;
              case 3: if (+tag.tagvalue>+this.value)
                indicator = true;
                      break;
              case 4: if (+tag.tagvalue<+this.value)
                indicator = true;
                      break;
              case 5: if (+tag.tagvalue!=+this.value)
               indicator = true;
                      break;
              
              default: break;
          }
          return indicator;
    }
}

import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { Rectangle } from "./rectangle";
import Konva from "konva";

export class Ellipse extends Rectangle{
   

    public drawObject():void{
		super.initObject();
        
        if (this.linewidth==null || this.linewidth<=0) this.linewidth = 1

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		let fillcolor = '0xffffff00';
        if (this.fill){
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const ellipsefill = new Konva.Ellipse({
                x: this.width/2,
                y: this.height/2,
                radiusX: (this.width-this.linewidth)/2,
                radiusY: (this.height-this.linewidth)/2,
                fill: ColorUtils.convertformat(fillcolor),
              });
              this.node.add(ellipsefill)
        }    
		const ellipse = new Konva.Ellipse({
            x: this.width/2,
            y: this.height/2,
            radiusX: (this.width-this.linewidth)/2,
            radiusY: (this.height-this.linewidth)/2,
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });

          this.node.add(ellipse)
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import tag from "@/model/tag";
import TagsService from '@/services/tags.service';
import { TextView } from './text'
import historydatarequest from "@/model/requests/historydatarequest";
import { RangeValueProperty } from "./properties/rangevalueproperty";
import AuthModule from "@/store/auth.module";
import { Gauge180 } from './gauge180';


export class Gauge90 extends Gauge180{

    public drawObject():void{
        let rangeproperty:RangeValueProperty
          const rangeprop = this.properties['rangevalue'];
          let tag:tag
            if (rangeprop!=null){	
                 rangeproperty =Object.assign(new RangeValueProperty(), rangeprop);
                if (rangeproperty!=null) 
                       tag =  rangeproperty.getTag(this.tagnames)
            }	
        if (tag==null || !tag.usehistory || rangeproperty==null || !rangeproperty.showhistory)
        {
            this.drawRange90(tag,rangeproperty,null, null)
            return;
        }
        if (AuthModule.currentUser!=null){
            const enddate = Date.now();
            const begindate = enddate - rangeproperty.getDuration();//delta*minutes
           const request:historydatarequest = {token:AuthModule.currentUser.accessToken, 
              name:tag.path==null?tag.name:tag.path, begindate:begindate, enddate:enddate};
            TagsService.gethistorydata(request,(data:string)=>{
                 const historydata =JSON.parse(data);
                 let hisMin:number
                 let hisMax:number
                 
                 const dataY = []
                 if(historydata.length>1){
                 historydata.forEach((data)=>{
                   dataY.push(data['y'])
                 })
                 hisMin = Math.min(...dataY)
                 hisMax = Math.max(...dataY)
                }

                 this.drawRange90(tag,rangeproperty, hisMin, hisMax);
            }, 
            (data:string)=>{
                console.log('data', data);
             })
        }
        }
        private drawRange90(tag:tag,rangeproperty:RangeValueProperty, hisMin:number, hisMax:number):void{
            super.initObject();

	
		let fillcolor = '0xffffff00'; 
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor)

        let color = this.color; 
        const colorprop = this.properties['color'];
            if (colorprop!=null){	
                const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
                if (colorproperty!=null) 
                        color = colorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        const color2 = ColorUtils.convertformat(color)    

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')

        const gradientrectSmall = ctx.createLinearGradient(0, 0, 0, this.height*2/20);
        gradientrectSmall.addColorStop(0, '#000000')
        gradientrectSmall.addColorStop(1, '#FFFFFF')

        
        const radius1 = this.width*19/20;
		const radius2 = this.height*19/20;
		let radius:number;
		if (radius1<radius2) radius=radius1;
		else radius=radius2;

        const arc = new Konva.Arc({
            x: this.width*19/20,
            y: this.height*19/20,
            innerRadius: 0,
            outerRadius: radius,
            angle: 90,
            fill: fillcolor2,
            rotation: 180
        })
        this.node.add(arc)
        
		const innerradius =radius-this.width/20//-this.width/100;
		const innerradius2 =radius-this.width/40//-this.width/100;
		const degreestep = 90/this.interval;
		const degreestep2 = degreestep/5;
		let valuemin=0;
		let valuemax=100;
		let decimalpos=0;

        if (rangeproperty!=null){
            valuemin = rangeproperty.minimumvalue;
            valuemax = rangeproperty.maximumvalue;
            decimalpos=rangeproperty.decimalpos;
        }
        
        if(rangeproperty!=null && rangeproperty.colorrange!=null && rangeproperty.colorrange.length!=0){
            rangeproperty.colorrange.forEach((range)=>{
                const begindegree =rangeproperty.get90value(range['from'])
                const enddegree  = rangeproperty.get90value(range['to'])
                const rangeColor = range['color']
                
                const sector = new Konva.Arc({
                    x: this.width*19/20,
                    y: this.height*19/20,
                    innerRadius: 0,
                    outerRadius: radius,
                    angle: begindegree-enddegree,
                    rotation:-begindegree
                })
                sector.fillRadialGradientStartPoint({ x: 0, y: 0 });
                sector.fillRadialGradientStartRadius(0);
                sector.fillRadialGradientEndRadius(radius);
                sector.fillRadialGradientColorStops([0.75, ColorUtils.convertformat(rangeColor).slice(0,-2)+'00',
                    1, ColorUtils.convertformat(rangeColor)])
                this.node.add(sector)
            })
        }
        if(rangeproperty!=null && rangeproperty.showhistory){
            const begindegree = rangeproperty.get90value(hisMin);
            const enddegree = rangeproperty.get90value(hisMax)

            const sectorHistory = new Konva.Arc({
                x: this.width*19/20,
                y: this.height*19/20,
                innerRadius: 0,
                outerRadius: radius*2/3,
                angle: begindegree-enddegree,
                rotation:-begindegree
                    })
            sectorHistory.fillRadialGradientStartPoint({ x: 0, y: 0 });
            sectorHistory.fillRadialGradientStartRadius(0);
            sectorHistory.fillRadialGradientEndRadius(radius*2/3);
            sectorHistory.fillRadialGradientColorStops([0.75, ColorUtils.convertformat(rangeproperty.historycolor).slice(0,-2)+'00',
                1, ColorUtils.convertformat(rangeproperty.historycolor)])
            this.node.add(sectorHistory)
        }

        const valuestep = (valuemax-valuemin)/this.interval;

        for (let i=0;i<=this.interval;i++){
            const degrees=degreestep*i;
            const radians = degrees*(Math.PI/180);
            const x1=this.width*19/20-radius*Math.cos(radians);
            const y1=this.height*19/20-radius*Math.sin(radians);
            const x2=this.width*19/20-innerradius*Math.cos(radians);
            const y2=this.height*19/20-innerradius*Math.sin(radians);
            const line = new Konva.Line({
                points: [x1, y1, x2, y2],
                strokeWidth: this.height/75,
                stroke: 'white'
            })
            this.node.add(line)
            for (let j=1; j<5; j++){
                if (i==this.interval) break;

               

                const degrees2 = degrees+degreestep2*j;
                const radians2 = degrees2*(Math.PI/180);
                const x12=this.width*19/20-radius*Math.cos(radians2);
                const y12=this.height*19/20-radius*Math.sin(radians2);
                const x22=this.width*19/20-innerradius2*Math.cos(radians2);
                const y22=this.height*19/20-innerradius2*Math.sin(radians2);
                const line2 = new Konva.Line({
                    points: [x12, y12, x22, y22],
                    strokeWidth: this.height/100,
                    stroke: 'white'
                })
                this.node.add(line2)
            }
            const value = valuemin+valuestep*i;
				const valuetext = value.toFixed(decimalpos).toString()
                const textTob = new TextView()
                textTob.width = this.width/3
                textTob.height = this.height/12
                textTob.text = valuetext
                textTob.fontsize = this.height/12
                textTob.textplacement = (degrees<88)? 0: 0
                textTob.textcolor = 'white'
                textTob.useborder = false
                textTob.bordercolor = null
                textTob.linewidth = 1
                textTob.fill = false
                textTob.fillcolor = 'white'
                textTob.underline = false

                if(degrees<88){
					textTob.posx = x2+this.width/100*Math.cos(radians)
					textTob.posy = y2+this.height/10*Math.sin(radians)-this.height/50-this.height/17
                }else{
                    textTob.posx = (x2-this.width/6);
					textTob.posy = y2+this.height/10*Math.sin(radians)-this.height/12
                }
                textTob.initLayer(this.layer)
                textTob.drawObject()
                this.node.add(textTob.rotatednode)
        }
        const textUnit = new TextView()
                textUnit.width = this.width/4
                textUnit.height = this.height/10
                textUnit.text = this.unit
                textUnit.fontsize = this.height/10
                textUnit.posx = this.width*18/20-this.width/8
                textUnit.posy = this.height*35/40-this.height/10
                textUnit.textplacement = 1
                textUnit.textcolor = 'white'
                textUnit.useborder = false
                textUnit.bordercolor = null
                textUnit.linewidth = 1
                textUnit.fill = false
                textUnit.fillcolor = 'white'
                textUnit.underline = false
                textUnit.initLayer(this.layer)
                textUnit.drawObject()
                this.node.add(textUnit.rotatednode)

        let arrowWidth = this.height/40
        let arrowHeight = innerradius2
        if (arrowWidth<1)  arrowWidth = 1;
        if (arrowHeight<1) arrowHeight = 1;

        const arrow = new Konva.Rect({
            x: 0,
            y: 0,
            width: arrowWidth,
            height: arrowHeight,
            fill: color2,
          }); 
        this.node.add(arrow)

        const tagvalue = tag==null? 0: rangeproperty.getCurrentValue90deg(Number(tag.tagvalue))
        
        const anglearr = tagvalue-90;
		const radians = anglearr*Math.PI/180;
       
        const posx = this.width*19/20 -arrowWidth/2;
        const posy = this.height*19/20 -arrowHeight/2

        const x =arrowWidth/2+posx-arrowWidth/2* Math.cos(radians) +(arrowHeight)* Math.sin(radians);
        const y = posy+arrowHeight/2 -(arrowWidth/2)* Math.sin(radians) -(arrowHeight)* Math.cos(radians);
    
        arrow.position({x:x,y:y})
        arrow.rotation(anglearr) 
        
       const circleBall = new Konva.Circle({
            x: this.width*19/20,
            y: this.height*19/20,
            radius: 1.5*arrowWidth,
            fill: color2
            });
          this.node.add(circleBall)

        if (this.usedigital){
			const digrect = new Konva.Rect({
                x: this.width*20/40,
                y: this.height*24/40,
                width: this.width*18/40,
                height: this.height*2/20,
                cornerRadius: this.height/100,
                stroke: gradientrectSmall,
                strokeWidth: this.height/100,
            })
              digrect.fillLinearGradientStartPoint({ x: 0, y: 0 });
              digrect.fillLinearGradientEndPoint({ x: 0, y: this.height*2/20 });
              digrect.fillLinearGradientColorStops([0, 'white', 0.25, 'black'])
              this.node.add(digrect)

            let tagvalueText =tag==null? 0: Number(tag.tagvalue)
            if(rangeproperty!=null && +tagvalueText>rangeproperty.maximumvalue) tagvalueText = rangeproperty.maximumvalue
            if(rangeproperty!=null && +tagvalueText<rangeproperty.minimumvalue) tagvalueText = rangeproperty.minimumvalue


               const valueTextOb = new TextView()
                valueTextOb.width = this.width*18/40
                valueTextOb.height = this.height/14
                valueTextOb.text = tagvalueText.toFixed(decimalpos).toString();
                valueTextOb.fontsize = this.height/14
                valueTextOb.posx = this.width*29/40-this.width*18/80
                valueTextOb.posy = this.height*55/80-this.height/14
                valueTextOb.textplacement = 1
                valueTextOb.textcolor = 'white'
                valueTextOb.useborder = false
                valueTextOb.bordercolor = null
                valueTextOb.linewidth = 0
                valueTextOb.fill = false
                valueTextOb.fillcolor = 'white'
                valueTextOb.underline = false
                valueTextOb.initLayer(this.layer)
                valueTextOb.drawObject()
                this.node.add(valueTextOb.rotatednode)
            }

       const textLabel = new TextView()
        textLabel.width = this.width-this.width/8
        textLabel.height = this.height/12
        textLabel.text = this.text
        textLabel.fontsize = this.height/12
        textLabel.posx = this.width/15
        textLabel.posy = this.height*47/80-this.height/12
        textLabel.textplacement = 2
        textLabel.textcolor = 'white'
        textLabel.useborder = false
        textLabel.bordercolor = null
        textLabel.linewidth = 1
        textLabel.fill = false
        textLabel.fillcolor = 'white'
        textLabel.underline = false
        textLabel.initLayer(this.layer)
        textLabel.drawObject()
        this.node.add(textLabel.rotatednode)
           
        }
}
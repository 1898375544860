import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { FillingProperty } from "./properties/fillingproperty";
import Konva from "konva";
import { Cylinder } from './cylinder';

export class Cylinder3D extends Cylinder{
   

    public drawObject():void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
        const color2 = ColorUtils.convertformat(color);
		let fillcolor = '0xffffff00';
        if (this.fill){
                let fillheight = this.height
                const fillingprop = this.properties['filling']
                if (fillingprop != null){
                    const fillingproperty:FillingProperty =Object.assign(new FillingProperty(), fillingprop);
                    fillheight = fillingproperty.getHeight(this.tagnames, this.height)

                 }
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            const k = fillheight/this.height
            
            const ellipsebottom = new Konva.Ellipse({
              x: this.width/2,
              y: 0.9*this.height,
              radiusX: (this.width-this.linewidth)/2,
              radiusY: 0.1*this.height,
              strokeWidth: this.linewidth,
            });
            ellipsebottom.fillLinearGradientStartPoint({ x: -this.width/2, y: this.height });
            ellipsebottom.fillLinearGradientEndPoint({ x: this.width/2, y: this.height });
            ellipsebottom.fillLinearGradientColorStops([0, ColorUtils.darkColor(k==0? color2: fillcolor2, 0.5), 1, k==0? color2: fillcolor2])
  
            this.node.add(ellipsebottom)
          
           
            const rect = new Konva.Rect({
                x: 0,
                y: 0.1*this.height,
                width: this.width,
                height: 0.8*this.height,
              });
              rect.fillLinearGradientStartPoint({ x: 0, y: this.height });
              rect.fillLinearGradientEndPoint({ x: this.width, y: this.height });
              rect.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.5), 1, color2])
              this.node.add(rect)

              const rectfill = new Konva.Rect({
                x: 0,
                y: 0.9*this.height-k*0.8*this.height,
                width: this.width,
                height: k*0.8*this.height,
              });
              rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
              rectfill.fillLinearGradientEndPoint({ x: this.width, y: this.height });
              rectfill.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5), 1, fillcolor2])
              this.node.add(rectfill)

              const ellipsetop = new Konva.Ellipse({
                x: this.width/2,
                y: 0.1*this.height,
                radiusX: (this.width-this.linewidth)/2,
                radiusY: 0.1*this.height,
                fill: ColorUtils.convertformat(color),
                strokeWidth: this.linewidth,
              });
    
              this.node.add(ellipsetop)

              if(k !==0){
              const ellipsemiddle = new Konva.Ellipse({
                x: this.width/2,
                y: 0.9*this.height-k*0.8*this.height,
                radiusX: (this.width-this.linewidth)/2,
                radiusY: 0.1*this.height,
                fill: ColorUtils.convertformat(fillcolor),
                strokeWidth: this.linewidth,
              });
              this.node.add(ellipsemiddle)
            }

              



        }    
		

         
    }
}
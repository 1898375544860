
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import Konva from "konva";
import { TextInputProperty } from "./properties/textinputproperty";
import { StringUtils } from "../utils/stringutils";
import { OutputProperty } from "./properties/outputproperty";
import { DialogBox } from "../dialogbox";
import dialogboxModule from "@/store/dialogbox.module";
import { TextView } from "./text";


export class Text3DView extends TextView{
  
    public drawObject():void{
		super.initObject();
        
        let borderwidth = 0;
        let color = '0xffffff00';
        if (this.useborder){
            color = this.bordercolor;
            const lineprop = this.properties==null?null:this.properties['linecolor'];
            if (lineprop!=null){	
                const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
                if (linecolorproperty!=null) 
                    color = linecolorproperty.getColor(this.tagnames, this.bordercolor)
            }
            borderwidth = this.linewidth;
        }
        const bordercolor:string = ColorUtils.convertformat(color);

        let fillcolor = '0xffffff00';
        if (this.fill){
            fillcolor = this.fillcolor;
            const fillprop = this.properties==null?null:this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
        }
        const fillcolor2:string = ColorUtils.convertformat(fillcolor);
		const rect = new Konva.Rect({
            x: borderwidth/2,
            y: borderwidth/2,
            width: this.width-borderwidth,
            height: this.height-borderwidth,
            fill: fillcolor2,
            stroke: bordercolor,
            strokeWidth: borderwidth,
          });
          this.node.add(rect)

          let text = this.text;
          const textprop = this.properties==null?null:this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties==null?null:this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }

            const Text = new Konva.Text({
                x: 2*this.linewidth,
                y: this.linewidth,
                verticalAlign:"middle",
                width: this.width-2*this.linewidth,
                height: this.height-1*this.linewidth,
                text: text,
                fontSize: this.fontsize,
                fontFamily: StringUtils.getFontFamily(this.fonttype),
                fontStyle: StringUtils.getFontStyle(this.fonttype),
                align:StringUtils.getTextPlacement(this.textplacement),
                fill: ColorUtils.convertformat(textcolor),
                textDecoration:this.underline?"underline":"",
                shadowColor:'black',
                shadowBlur:0,
                shadowOffset:{ x: 1, y: 1 },
              });
              const outputprop =this.properties==null?null: this.properties['output'];
              if (outputprop!=null){
                const outputproperty:OutputProperty=Object.assign(new OutputProperty(), outputprop);
                if (outputproperty!=null){
                    this.node.on('mousedown touchstart', function () {
                            const dialogbox:DialogBox = new DialogBox();
                            dialogbox.enable = true;
                            dialogbox.title = outputproperty.title;
                            dialogbox.tagname = outputproperty.path;
                            dialogboxModule.setDialogBox(dialogbox);
                      });
                }
              }
              this.node.add(Text);
    }
}
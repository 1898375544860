import { SVGObject } from "../svgobject";

export class SVGPipe21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 99.835" enable-background="new 0 0 112.5 99.835" xml:space="preserve">

        <g id="Group_60_Deg_Pipe">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="69.6895" y1="12.8789" x2="25.9976" y2="88.5555" gradientTransform="matrix(1 0 0 -1 0 103.6162)">
            <stop offset="0" style="stop-color:#323232" />
            <stop offset="0.5" style="stop-color:#CCCCCC" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M52.083,0l-17.5,30.251H0v69.584c0,0,45.583,1.916,62.25-5.584s22.168-12.834,29.334-24  C98.75,59.083,112.5,35.084,112.5,35.084L52.083,0z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="94.25" y1="99.9087" x2="94.25" y2="30.168" gradientTransform="matrix(-1 0 0 1 112.5 0)">
            <stop offset="0" style="stop-color:#3C3C3C" />
            <stop offset="0.19" style="stop-color:#868686" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <rect y="30.167" fill="url(#SVGID_2_)" width="36.5" height="69.741" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="54.4751" y1="96.5933" x2="89.4054" y2="36.0922" gradientTransform="matrix(0 -1 1 0 6.3325 106.1675)">
            <stop offset="0" style="stop-color:#3D3D3D" />
            <stop offset="0.21" style="stop-color:#8C8C8C" />
            <stop offset="0.44" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M112.5,35.109L93.107,68.387l-58.422-38.22L52.127,0" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="52.2427" y1="84.2505" x2="67.7595" y2="15.8793" gradientTransform="matrix(0.4573 -0.8893 0.8893 0.4573 -9.0134 81.5542)">
            <stop offset="0" style="stop-color:#323232" />
            <stop offset="0.21" style="stop-color:#838383" />
            <stop offset="0.46" style="stop-color:#ECECEC" />
            <stop offset="0.56" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M81,83.292c-0.486,0-0.932,0.063-1.345,0.168L34.79,30.112l62.416,31.372l-1.998,3.453  c0,0-4.948,7.944-6.791,10.313c-1.472,1.893-5.563,6.203-7.33,8.046C81.058,83.296,81.03,83.292,81,83.292z" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="26.0664" y1="87.1782" x2="38.768" y2="18.7583" gradientTransform="matrix(0.9225 -0.3859 0.3859 0.9225 -8.0362 27.6487)">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.21" style="stop-color:#868686" />
            <stop offset="0.46" style="stop-color:#ECECEC" />
            <stop offset="0.56" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_5_)" d="M34.752,30.039l0.373,69.797c0,0,4.789-0.037,13.073-1.563c8.821-1.625,11.907-3.21,11.907-3.21  l3.517-1.455L34.752,30.039z" />
        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="42.0259" y1="85.1841" x2="49.366" y2="15.2273" gradientTransform="matrix(0.8004 -0.5994 0.5994 0.8004 -13.8275 47.4045)">
            <stop offset="0" style="stop-color:#303030" />
            <stop offset="0.11" style="stop-color:#595959" />
            <stop offset="0.21" style="stop-color:#828282" />
            <stop offset="0.47" style="stop-color:#ECECEC" />
            <stop offset="0.56" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <path fill="url(#SVGID_6_)" d="M34.752,30.039l24.575,65.299c0,0,8.198-3.222,10.757-4.587c2.672-1.427,5.625-3.035,11.026-7.478  L34.752,30.039z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3854166666666667, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4635416666666667, 0.0, 0.19, 0.953125, 11.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4739583333333333, 0.0, 0.21, 0.90625, 23.0, 0.44, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3854166666666667, 0.0, 0.21, 0.9791666666666666, 5.0, 0.46, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.21, 0.953125, 11.0, 0.46, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.375, 0.0, 0.11, 0.6927083333333334, 0.0, 0.21, 0.984375, 3.0, 0.47, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
import { Counter } from './counter';
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import { CounterControlProperty } from "./properties/countercontrolproperty";
import { TextColorProperty } from "./properties/textcolorproperty";


export class CounterRect extends Counter{
  

    public drawObject():void{
		super.initObject();

       
                let value = 0
                let minimum = 0
                let maximum = 240
                let decimalpos = 0
                let delta = 1
                
                const counterprop = this.properties['countercontrol']
                let counterproperty;
                if (counterprop != null){
                    counterproperty =Object.assign(new CounterControlProperty(), counterprop);
                    if(counterproperty !=null){
                    minimum = counterproperty.minimumvalue
                    maximum = counterproperty.maximumvalue
                    value = counterproperty.getCurrentValue(this.tagnames)
                    decimalpos = counterproperty.decimalpos
                    delta = counterproperty.delta
                    }
                 }

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
		const color2 = ColorUtils.convertformat(color);


    let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
    const textcolor2 = ColorUtils.convertformat(textcolor)
        
        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, color2, 
            1, ColorUtils.darkColor(color2,0.5)])
          }
          else{
            rectout.fill(color2)
          }
          this.node.add(rectout) 

        const rectBlack = new Konva.Rect({
            x: this.width*7/32,
            y: this.height/16,
            width: this.width*18/32,
            height: this.height*14/16,
            fill: 'black'
          });
          this.node.add(rectBlack) 

          const bPlus = this.drawButton(this.width/50, this.height/16, this.width/5, this.height*7/16, color2, "+")
          this.node.add(bPlus) 

          const bMinus = this.drawButton(this.width/50, this.height/2, this.width/5, this.height*7/16, color2, "-")
          this.node.add(bMinus) 

          const bSet = this.drawButton(this.width*25/32, this.height/16, this.width/5, this.height*7/16, color2, "S")
          this.node.add(bSet) 

          const bReset = this.drawButton(this.width*25/32, this.height/2, this.width/5, this.height*7/16, color2, "R")
          this.node.add(bReset) 

          let digits= this.drawDigits6(this.width/2, this.height*3/4, value, decimalpos, textcolor2)
          digits.absolutePosition({
            x: this.width/4,
            y: this.height/8
          });
          const node1:Konva.Group = new Konva.Group();
          node1.add(digits);
          this.node.add(node1)
          this.node.on('mouseup touchend', ()=> {
            const mousePos = this.node.getRelativePointerPosition()
            if(mousePos.x < (this.width/50+this.width/5)&&
              mousePos.x>this.width/50 && 
              mousePos.y>this.height/2 &&
              mousePos.y<(this.height/2+this.height*7/16)){
            value= value-delta<minimum? minimum : value-delta}

            else if(mousePos.x < (this.width/5+this.width/50)&&
                    mousePos.x>this.width/50 && 
                    mousePos.y>this.height/16 &&
                    mousePos.y<(this.height/16+this.height*7/16)){
              value= value+delta>maximum? maximum: value+delta}

            else if(mousePos.x < (this.width/5+this.width*25/32)&&
                    mousePos.x>this.width*25/32 && 
                    mousePos.y>this.height/16 &&
                    mousePos.y<(this.height/16+this.height*7/16)){
            counterproperty.setValue(value.toString())}
            else if(mousePos.x < (this.width/5+this.width*25/32)&&
                    mousePos.x>this.width*25/32 && 
                    mousePos.y>this.height/2 &&
                    mousePos.y<(this.height/2+this.height*7/16)){
            value = counterproperty.getCurrentValue(this.tagnames)}
            

            node1.removeChildren();
            digits = this.drawDigits6(this.width/2, this.height*3/4, value, decimalpos, textcolor2)
            digits.absolutePosition({
              x: this.width/4,
              y: this.height/8
            });
            node1.add(digits)
        });



        
    }
    
    private drawButton(beginx:number, beginy: number, width:number, height:number, color2: string, text:string):Konva.Group{ 
        const button = new Konva.Group()
        const rectout = new Konva.Rect({
            x: beginx,
            y: beginy,
            width: width,
            height: height,
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0});
            rectout.fillLinearGradientEndPoint({ x: 0, y: height });
            rectout.fillLinearGradientColorStops([0, 'white' , 
            1, ColorUtils.darkColor(color2, 0.1)])
          }
          else{
            rectout.fill(color2)
        } 
        button.add(rectout)

    const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const gradient = ctx.createLinearGradient(0, 0, 0, height*8/10);
              gradient.addColorStop(0, 'black')
              gradient.addColorStop(1, 'white')

    const rectinner = new Konva.Rect({
        x: beginx+width/10,
        y: beginy+height/10,
        width: width*8/10,
        height: height*8/10,
        fill: color2,
        stroke: this.type3d==0? gradient: 'black',
        strokeWidth: width/40
      });
    button.add(rectinner)
    
        const buttonText = new Konva.Text({
            x: beginx,
            y: beginy+height/10, 
            verticalAlign:"middle",
            width: width,
            height: height,
            text: text,
            fontSize: height,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align:'center',
            fill: 'black',
          });
          button.add(buttonText)

        return button
    }
    
    private drawDigit(width:number, height:number, chdigit: string, color:string):Konva.Group{
        
        const digit = new Konva.Group()

        const lineTop = new Konva.Line({
            points: [width/20, 0,
                    width*19/20, 0,
                    width*4/5, height/10,
                    width/5, height/10],
            closed: true,
        })
        if(chdigit=='2' || chdigit =='3' || chdigit == '5' || chdigit == '6'|| chdigit == '7'
        || chdigit == '8' || chdigit == '9' || chdigit == '0'){
            lineTop.fill(color)
        }else{
            lineTop.fill(ColorUtils.darkColor(color,0.15))
        }
        digit.add(lineTop)

    const lineTopRight = new Konva.Line({
      points: [width, height/40,
              width, height*20/40,
              width*33/40, height*15/40,
              width*33/40, height*6/40],
      closed: true,
    })
      if(chdigit=='1' || chdigit=='2' || chdigit =='3' || chdigit=='4'  || chdigit == '7'
          || chdigit == '8' || chdigit == '9' || chdigit == '0'){
        lineTopRight.fill(color)
        }else{
          lineTopRight.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineTopRight)

  
    const lineTopLeft = new Konva.Line({
      points: [0, height/40,
              0, height*20/40,
              width*7/40, height*15/40,
              width*7/40, height*6/40],
      closed: true,
      })
      if(chdigit=='4' || chdigit == '5' || chdigit == '6'
      || chdigit == '8' || chdigit == '9' || chdigit == '0'){
        lineTopLeft.fill(color)
        }else{
          lineTopLeft.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineTopLeft)

    const lineBottomLeft = new Konva.Line({
      points: [0, height*20/40,
              0, height*39/40,
              width*7/40, height*34/40,
              width*7/40, height*25/40],
      closed: true,
      })
      if(chdigit=='2' ||  chdigit == '6'
      || chdigit == '8' ||  chdigit == '0'){
        lineBottomLeft.fill(color)
        }else{
          lineBottomLeft.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineBottomLeft)

    const lineBottomRight = new Konva.Line({
      points: [width, height*20/40,
              width, height*39/40,
              width*33/40, height*34/40,
              width*33/40, height*25/40],
      closed: true,
      })
      if(chdigit=='1' ||  chdigit =='3' || chdigit=='4' || chdigit == '5' || chdigit == '6'|| chdigit == '7'
      || chdigit == '8' || chdigit == '9' || chdigit == '0'){
        lineBottomRight.fill(color)
        }else{
          lineBottomRight.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineBottomRight)

    const lineBottom = new Konva.Line({
      points: [width/20, height,
              width*19/20, height,
              width*4/5, height*9/10,
              width/5, height*9/10],
      closed: true,
      })
      if(chdigit=='2' || chdigit =='3'  || chdigit == '5' || chdigit == '6'
      || chdigit == '8' || chdigit == '9' || chdigit == '0'){
        lineBottom.fill(color)
        }else{
          lineBottom.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineBottom)
    
    const lineMiddle = new Konva.Line({
      points: [width/20, height/2,
              width/5, height*9/20,
              width*4/5, height*9/20,
              width*19/20, height/2,
              width*4/5, height*11/20,
              width/5, height*11/20],
      closed: true,
      })
      if(chdigit=='-' || chdigit=='2' || chdigit =='3' || chdigit=='4' || chdigit == '5' || chdigit == '6'
      || chdigit == '8' || chdigit == '9'){
        lineMiddle.fill(color)
        }else{
          lineMiddle.fill(ColorUtils.darkColor(color,0.15))
        }
    digit.add(lineMiddle)
        return digit
    }
    
    private drawDigits6(width:number, height:number, value: number, decimalpos:number, color:string):Konva.Group{
      const digits = new Konva.Group()
      const numb = value.toFixed(decimalpos).toString().split('')
      let j=6;
      //let d = 5
      //let drawdot = false;
      const length = numb.includes('.' ||',')? numb.length-1 : numb.length
      for(let i=numb.length-1; i>=length-6; i--){
       
        let chdigit = 'N'
        if(i>=0) chdigit =numb[i]
          if (i!=numb.length-1){
          const dot = new Konva.Rect({
            x: width*(50+60*(j-1))/350,
            y: height*9/10,
            width: width/50,
            height: height/10,
          })
      
          if(chdigit=='.' || chdigit==','){
            dot.fill(color)  
           i=i-1
           chdigit=numb[i]
          }else{
            dot.fill(ColorUtils.darkColor(color,0.15))  
          }
          digits.add(dot)
        }

          j--
        const dig = this.drawDigit(width*5/37, height, chdigit, color)
        dig.absolutePosition({
          x: width*6*j/35,
          y: 0
        })
        digits.add(dig)
      }
      return digits
    }
    

       
}
import { SVGObject } from "../svgobject";

export class SVGButton18 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 66.219 112.5" enable-background="new 0 0 66.219 112.5" xml:space="preserve">
        <g id="Group_Throw_Switch_Holder">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-2.441406e-004" y1="33.9526" x2="16.78" y2="33.9526" gradientTransform="matrix(-1 0 0 -1 66.2183 112.6348)">
                <stop offset="0" style="stop-color:#0D0D0D" />
                <stop offset="0.5" style="stop-color:#3D3D3D" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M49.438,44.729h16.78v67.905h-16.78V44.729z" />
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="49.325" y1="33.9526" x2="66.2185" y2="33.9526" gradientTransform="matrix(-1 0 0 -1 66.2183 112.6348)">
                <stop offset="0" style="stop-color:#0D0D0D" />
                <stop offset="0.5" style="stop-color:#3D3D3D" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,44.729h16.894v67.905H0V44.729z" />
        </g>
        <g id="Group_Lever">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="5.1814" y1="67.0039" x2="60.9246" y2="67.0039" gradientTransform="matrix(-1 0 0 -1 66.2183 112.6348)">
                <stop offset="0.04" style="stop-color:#737373" />
                <stop offset="0.08" style="stop-color:#E0E0E0" />
                <stop offset="0.13" style="stop-color:#737373" />
                <stop offset="0.28" style="stop-color:#1F1F1F" />
                <stop offset="0.44" style="stop-color:#737373" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="0.56" style="stop-color:#737373" />
                <stop offset="0.73" style="stop-color:#1F1F1F" />
                <stop offset="0.86" style="stop-color:#737373" />
                <stop offset="0.92" style="stop-color:#E0E0E0" />
                <stop offset="0.97" style="stop-color:#737373" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M37.839,37.859h23.198v39.415H51.69V47.094H14.64v30.181   H5.294V37.859h23.198V13.986h9.347V37.859z" />
        </g>
        <g id="Group_Lever_Head">
            <circle fill="#FF0000" stroke="#4C4C4C" stroke-width="0.25" cx="33.109" cy="8.919" r="8.919" />
            
                <radialGradient id="SVGID_4_" cx="30.6316" cy="102.2393" r="5.7969" gradientTransform="matrix(-1 0 0 -1 66.2183 112.6348)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#FFD9D9" />
                <stop offset="1" style="stop-color:#FF0000" />
            </radialGradient>
            <circle fill="url(#SVGID_4_)" cx="35.587" cy="10.396" r="5.796" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.09895833333333333, 0.0, 0.5, 0.4739583333333333, 0.0, 1.0, 0.09895833333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.09895833333333333, 0.0, 0.5, 0.4739583333333333, 0.0, 1.0, 0.09895833333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.04, 0.8958333333333334, 0.0, 0.08, 0.25, 191.0, 0.13, 0.8958333333333334, 0.0, 0.28, 0.23958333333333334, 0.0, 0.44, 0.8958333333333334, 0.0, 0.5, 0.25, 191.0, 0.56, 0.8958333333333334, 0.0, 0.73, 0.23958333333333334, 0.0, 0.86, 0.8958333333333334, 0.0, 0.92, 0.25, 191.0, 0.97, 0.8958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.20833333333333334, 201.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Lever_Head",[0.0, 0.6614583333333334, 0.0]);

		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGDuct9 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.047" enable-background="new 0 0 112.5 112.047" xml:space="preserve">
        <g id="Group_Bend_Duct">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.5" d="M112.498,112.047H0.001V18.693H18.47V0h93.353v18.693L112.498,112.047   z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M111.822,18.693H18.47v93.354" />
        </g>
        <g id="Group_Side1">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.1724" y1="64.8064" x2="64.6396" y2="64.8064">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.42" style="stop-color:#C6C6C6" />
                <stop offset="1" style="stop-color:#D1D1D1" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M21.172,21.171l43.467,43.58l-43.467,43.692V21.171z" />
        </g>
        <g id="Group_Side2">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="64.6396" y1="64.8064" x2="108.2188" y2="64.8064">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="0.33" style="stop-color:#C9C9C9" />
                <stop offset="0.92" style="stop-color:#C6C6C6" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M108.219,21.171L64.64,64.751l43.579,43.692V21.171z" />
        </g>
        <g id="Group_Top_Layer1">
            <path fill="#B2B2B2" d="M108.219,21.396L64.64,64.751L20.947,21.396H108.219z" />
        </g>
        <g id="Group_Top_Layer2">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="64.582" y1="108.4431" x2="64.582" y2="64.7507">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#EBEBEB" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M108.219,108.443L64.64,64.751l-43.693,43.692H108.219z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.609375, 99.0, 0.42, 0.453125, 139.0, 1.0, 0.3645833333333333, 161.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.40625, 151.0, 0.33, 0.4270833333333333, 145.0, 0.92, 0.453125, 139.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3020833333333333, 177.0, 1.0, 0.16666666666666666, 212.0]);
		this.fillGradient(this.fills,"Group_Bend_Duct",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Top_Layer1",[0.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
import screendevicesrequest from '@/model/requests/screendevicesrequest';
import {requestResponse, requestStream, setToken} from './rsocketutils/rsocket.service'

 class AuthService{
  // eslint-disable-next-line
  getallusers(token:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
    requestStream({
    
    },"admin/users/"+token,completeCallback,errorCallback);
  }
  getallsessions(token:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
    requestStream({
  },"admin/sessions/"+token,completeCallback,errorCallback);
  }
  getalldevices(token:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
    requestStream({
  },"session/devices/"+token,completeCallback,errorCallback);
  }
  getscreendevices(request:screendevicesrequest, responseCallback:(data:string)=>void, errorCallback:(data:string)=>void){
    requestResponse(request,'session/screendevices', responseCallback,errorCallback);
  }
  login(username:string,password:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
        requestResponse({
          username: username,
          password: password
        },"user/signin",completeCallback,errorCallback);
    }
  auth(username:string,password:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
      requestResponse({
        username: username,
        password: password
      },"user/auth",completeCallback,errorCallback);
  }
    // eslint-disable-next-line
  reset(email:string, completeCallback:(data:string)=>void, errorCallback:(data:any)=>void) {
      requestResponse({
        email: email
      },"user/reset",completeCallback,errorCallback);
  }

  clientdisconnect(token:string, uuid:string,
    completeCallback:(data:any)=>void, errorCallback:(data:any)=>void){
     // console.log("logout"+" token="+token+" uuid="+uuid)
      requestResponse({
        token: token,
        uuid: uuid
      },"user/logout", completeCallback, errorCallback);
    }
    logout() {  
        localStorage.removeItem('user');
        //client.client.logout();
        setToken(null);
         
      }
    setToken(token:string){
        //client.client.setToken(token);
        setToken(token)
    }
    // eslint-disable-next-line
      register(username:string,email:string, password:string, userpassword:string,completeCallback:(data:any)=>void, errorCallback:(data:any)=>void) {
   
        requestResponse({
          username: username,
          email: email,
          password: password,
          userpassword: userpassword
        },"user/signup",completeCallback,errorCallback)
      
      }
    
      update(id:number,username:string, email:string, password:string,
        // eslint-disable-next-line
         userpassword:string,completeCallback:(data:any)=>void, 
         // eslint-disable-next-line
        errorCallback:(data:any)=>void) {
        
        requestResponse({
          id: id,
          username: username,
          email: email,
          password: password,
          userpassword: userpassword
        },"user/update",completeCallback,errorCallback)  
    }
    updatehistorysettings(token:string, savehistory:boolean, storageperiod:number, 
              completeCallback:(data:any)=>void,
              errorCallback:(data:any)=>void){
                requestResponse({
                  token:token,
                  savehistory:savehistory,
                  storageperiod:storageperiod
                },"user/updatehistory",completeCallback,errorCallback)
    }
    updatescreenssettings(token:string, savescreens:boolean, mainscreen:string, 
      completeCallback:(data:any)=>void,
      errorCallback:(data:any)=>void){
        requestResponse({
          token:token,
          savescreens:savescreens,
          mainscreen:mainscreen
        },"user/updatescreens",completeCallback,errorCallback)
}
updatelocalesettings(token:string, locale:string, 
  completeCallback:(data:any)=>void,
  errorCallback:(data:any)=>void){
    requestResponse({
      token:token,
      locale:locale
    },"user/updatelocale",completeCallback,errorCallback)
}
    updatetariff(token:string, id:number,tariff:string,completeCallback:(data:any)=>void, 
       // eslint-disable-next-line
      errorCallback:(data:any)=>void) {
      
      requestResponse({
        token: token,
        id: id,
        tariff: tariff
      },"admin/updatetariff",completeCallback,errorCallback)  
  }

  updateenddate(token:string, id:number,enddate:number,completeCallback:(data:any)=>void, 
       // eslint-disable-next-line
      errorCallback:(data:any)=>void) {
      
      requestResponse({
        token: token,
        id: id,
        enddate: enddate
      },"admin/updateenddate",completeCallback,errorCallback)  
  }
  updateallenddate(token:string, enddate:number,completeCallback:(data:any)=>void, 
       // eslint-disable-next-line
      errorCallback:(data:any)=>void) {
      
      requestResponse({
        token: token,
        enddate: enddate
      },"admin/updateallenddate",completeCallback,errorCallback)  
  }

  deleteuser(token:string, id:number,completeCallback:(data:any)=>void, 
       // eslint-disable-next-line
      errorCallback:(data:any)=>void) {
      
      requestResponse({
        token:token,
        id: id,
      },"admin/deleteuser",completeCallback,errorCallback)  
  }
}
export default new AuthService();
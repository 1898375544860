import { SVGObject } from "../svgobject";

export class SVGHvac14 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 76.766 112.5" enable-background="new 0 0 76.766 112.5" xml:space="preserve">
        <g id="Group_Bottom_Support">
            <path fill="#606060" d="M0,109.907h76.766v2.593H0V109.907z" />
        </g>
        <g id="Group_HVAC_Unit">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="71.5854" y1="112.543" x2="4.9565" y2="-2.8617">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.4" style="stop-color:#D9D9D9" />
                <stop offset="0.6" style="stop-color:#F2F2F2" />
                <stop offset="1" style="stop-color:#F2F2F2" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" d="M0,0h76.542v109.682H0V0z" />
        </g>
        <g id="Group_Indication_Back">
            <path fill="#4C4C4C" d="M0,5.298h76.766v11.047H0V5.298z" />
        </g>
        <g id="Group_Controllers">
            <path fill="#E5E5E5" d="M54.672,7.666h19.389v6.651H54.672V7.666z" />
            <radialGradient id="SVGID_2_" cx="60.3091" cy="10.8213" r="2.7623" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M59.181,13.64l-1.578-1.691V9.807l1.578-1.804h2.255l1.578,1.804v2.142l-1.578,1.691H59.181" />
            <radialGradient id="SVGID_3_" cx="68.5376" cy="10.8213" r="2.8184" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M67.523,13.64l-1.804-1.691V9.807l1.804-1.804h2.028l1.805,1.804v2.142l-1.805,1.691H67.523" />
        </g>
        <g id="Group_Button">
            <path fill="#CCCCCC" d="M2.593,7.666h2.706v3.945H2.593V7.666z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3020833333333333, 177.0, 0.4, 0.3020833333333333, 177.0, 0.6, 0.11458333333333333, 226.0, 1.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Bottom_Support",[0.0, 0.75, 0.0]);
		this.fillGradient(this.fills,"Group_Indication_Back",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Button",[0.0, 0.40625, 151.0]);
        super.drawObject();
    }
}
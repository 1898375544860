import { SVGObject } from "../svgobject";

export class SVGButton7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 77.32" enable-background="new 0 0 112.5 77.32" xml:space="preserve">
        <g id="Group_Rectangular_Button_Base">
            <path fill="#666666" stroke-width="0.75" d="M3.582,3.583h105.336v70.154H3.582V3.583z" />
            <path fill="#CCCCCC" stroke-width="0.25" d="M3.582,73.738l15.744-6.84L92.359,11.74l15.743-6.84H3.582V73.738z" />
        </g>
        <g id="Group_Rectangular_Push_Button_Face">
            <path stroke-width="2" d="M91.896,64.172l14.452,8.387" />
            <path stroke-width="2" d="M19.623,14.162L6.08,5.96" />
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.522" y1="39.0354" x2="91.977" y2="38.9156" gradientTransform="matrix(1.0068 0.0017 -0.0017 1.0098 -1.0631 -0.2404)">
                <stop offset="0" style="stop-color:#969696" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#616161" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#474747" stroke-width="0.25" d="M20.626,13.268l70.847,0.118l-0.086,51.773L20.539,65.04   L20.626,13.268z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.828125, 43.0, 0.5, 0.25, 191.0, 1.0, 0.7552083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Rectangular_Button_Base",[0.0, 0.7916666666666666, 0.0, 1.0, 0.40625, 151.0]);
		
		
        super.drawObject();
    }
}
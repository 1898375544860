import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { VerticalTankWithTrend } from '@/model/project/objects/verticaltankwithtrend';
import { format } from 'date-fns'

@Module({ name:"TankDialogBox", dynamic:true, store})
class TankDialogBoxModule extends VuexModule {
  public VerticalTankWithTrend:VerticalTankWithTrend;
  public enabled:boolean;
  public beginstring:string;
 // public endstring:string;
 // public curves:any[]
    @Mutation
    public setTank(VerticalTankWithTrend:VerticalTankWithTrend){
        if (VerticalTankWithTrend==null) return;
        
      this.VerticalTankWithTrend = VerticalTankWithTrend;
      this.beginstring = format(VerticalTankWithTrend.begin, "yyyy-MM-dd HH:mm")
    }
    @Mutation
    public setTankDialogEnabled(value:boolean){
      this.enabled = value
    }
    
    get getTankDialogEnable():boolean{   
      return this.enabled;
    }
    get getTankBegin():string{
        return this.beginstring
    }

    @Mutation
    public setTankBegin(value:string){
    
      this.beginstring = value;
    }
    @Mutation
    public updateTankTime(){
        if (this.VerticalTankWithTrend==null) return;
        this.VerticalTankWithTrend.begin = Date.parse(this.beginstring)
        this.VerticalTankWithTrend.drawObject()
    }
}

export default getModule(TankDialogBoxModule);
import { FillColorProperty } from "../properties/fillcolorproperty";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { ValueProperty } from "../properties/valueproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "../properties/linecolorproperty";
import { GaugeTile } from "./gaugetile";

export class BarGaugeTile extends GaugeTile{
    
    public drawObject():void{
		super.initObject();


        let bgcolor = '0xffffff00';         
        bgcolor = this.bgcolor;
        const colorprop = this.properties['color'];
        if (colorprop!=null){	
        const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
        }

        let fillcolor = '0xffffff00';         
        fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
        const fillcolorproperty:FillColorProperty =Object.assign(new ColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties==null?null:this.properties['textcolor'];
        if (textcolorprop!=null){	
        const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
        if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }

        let needlecolor:string = this.needlecolor;
		const lineprop = this.properties==null?null:this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					needlecolor = linecolorproperty.getColor(this.tagnames, this.needlecolor)		
		}	

        let value
        let valueproperty;
        let angle
        let anglethreshold = 0;
        let minValue = 0;
        let maxValue = 100;
        let decimalpos=0;
        
        const valueprop = this.properties==null?null:this.properties['value'];
        if (valueprop!=null){	
        valueproperty =Object.assign(new ValueProperty(), valueprop);
        if (valueproperty!=null) 
            value = valueproperty.getTagValue(this.tagnames) 
            angle = valueproperty.getValue(this.tagnames)  
            decimalpos=valueproperty.decimalpos;
            minValue = valueproperty.minimumvalue;
            maxValue = valueproperty.maximumvalue;
            if(maxValue-minValue != 0) anglethreshold= 180 - 180*(this.threshold-minValue)/(maxValue-minValue);       
        } 
        //console.log('value', value, angle)  

        const size = this.width < this.height ? this.width : this.height;
        const width = this.width;
        const height = this.height;
        const group = new Konva.Group({
        clipFunc: function (ctx) {
            ctx.strokeStyle = "red";
            ctx.lineTo(0.05*size, 0);
            ctx.lineTo(width-0.05*size, 0);
            ctx.quadraticCurveTo(width, 0, width, 0.05*size);
            ctx.lineTo(width, height-0.05*size)
            ctx.quadraticCurveTo(width, height, width-0.05*size, height);
            ctx.lineTo(0.05*size, height)
            ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
            ctx.lineTo(0, 0.05*size)
            ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
        },
        });

        const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fill: ColorUtils.convertformat(bgcolor)
        });
        group.add(rect)

        const textTitle = new Konva.Text({
        x: size*0.05,
        y: size*0.1-0.06*size,
        verticalAlign:"top",
        width: this.width-size*0.05,
        height: 0.06*size,
        text: this.title,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        });  
        group.add(textTitle)

        const textDescription = new Konva.Text({
            x: size*0.05,
            y: this.height - size*0.05-0.06*size,
            verticalAlign:"top",
            width: this.width-size*0.05,
            height: 0.06*size,
            text: this.description,
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(textDescription)

        const centerX   = this.width * 0.5;
        const centerY   = this.height * 0.5+size*.2825;
        const barRadius = size * 0.3;
        const barWidth  = size * 0.1;

        const barBackground = new Konva.Arc({
            x: centerX,
            y: centerY, 
            innerRadius: barRadius-barWidth/2,
            outerRadius: barRadius+barWidth/2,
            angle: 180,
            fill: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
            rotation: 180
        });
        group.add(barBackground)

        if(angle !=null){
            const thresholdBar = new Konva.Arc({
                x: centerX,
                y: centerY, 
                innerRadius: barRadius-barWidth/2,
                outerRadius: barRadius+barWidth/2,
                angle: angle,
                fill: ColorUtils.convertformat(fillcolor),
                rotation: 180
            });
            group.add(thresholdBar)
        }        
        

        const valueUnitText = new Konva.Group({
            x: size*0.05,
            y: 0,
            width: this.width-size*0.05*2
        })
        group.add(valueUnitText)
          
          const valueText = new Konva.Text({
            x: 0,
            y: size*0.35-0.24*size,
            verticalAlign:"bottom",
            height: 0.24*size,
            text: parseFloat(value.toFixed(decimalpos)).toLocaleString(),
            fontSize: 0.24*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            offsetY:-size*0.024
            });  
        valueUnitText.add(valueText)

        const unitText = new Konva.Text({
            x: valueText.width(),
            y: size*0.35-0.24*size,
            verticalAlign:"bottom",
            height: 0.24*size,
            text:this.unit,
            fontSize: 0.12*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        valueUnitText.add(unitText)

        valueUnitText.offsetX(-this.width/2+unitText.width()/2+valueText.width()/2+size*0.05)

        const minValueText = new Konva.Text({
            x: centerX - barRadius+barWidth/2+size*.01,
            y: centerY-0.05*size,
            verticalAlign:"bottom",
            height: 0.06*size,
            text:(minValue.toFixed(0)).toString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(minValueText)

        const maxValueTextTransparent = new Konva.Text({
            x: 0,
            y: 0,
            verticalAlign:"bottom",
            height: 0.06*size,
            text: maxValue.toFixed(0).toString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  

        const maxValueText = new Konva.Text({
            x: centerX+barRadius-barWidth/2-maxValueTextTransparent.width()-size*.01,
            y: centerY-0.05*size,
            verticalAlign:"bottom",
            height: 0.06*size,
            text: maxValue.toFixed(0).toString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(maxValueText)

        const xbegin = centerX+(barRadius-barWidth*.5)*Math.cos(anglethreshold*Math.PI/180);
        const xend = centerX+(barRadius+barWidth*.5)*Math.cos(anglethreshold*Math.PI/180);
        const ybegin = centerY-(barRadius-barWidth*.5)*Math.sin(anglethreshold*Math.PI/180);
        const yend = centerY-(barRadius+barWidth*.5)*Math.sin(anglethreshold*Math.PI/180);

        const line = new Konva.Line({
            points: [xbegin,ybegin,xend,yend],
            stroke: ColorUtils.convertformat(needlecolor),
            strokeWidth: 1
        })
        group.add(line)
        //console.log('anglethreshold', anglethreshold)
        
        const threshtext = new Konva.Text({
            x: 0,
            y: 0,
            verticalAlign:"bottom",
            height: 0.06*size,
            text: this.threshold.toFixed(0).toString(),
            fontSize: 0.06*size,
            fontFamily: StringUtils.getFontFamily(this.fonttype),
            fontStyle: StringUtils.getFontStyle(this.fonttype),
            fill: ColorUtils.convertformat(textcolor),
            });  
        group.add(threshtext)

        if(360-anglethreshold <= 240 && 360-anglethreshold >180 ){
            threshtext.x(xend-threshtext.width()-size*.01)
            threshtext.y(yend-0.06*size)
           // console.log('1')
        }else if(360-anglethreshold >=240 && 360-anglethreshold < 300){
            threshtext.x(xend-threshtext.width()/2)
            threshtext.y(yend-0.06*size-size*.01)
          //  console.log('2')
        }else{
            threshtext.x(xend+size*.01)
            threshtext.y(yend-0.06*size+0.01*size)
          //  console.log('3')
        }

          
        this.node.add(group)     
    }
}
import { SVGObject } from "../svgobject";

export class SVGContainer4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 56.195 112.5" enable-background="new 0 0 56.195 112.5" xml:space="preserve">
        <g id="Group_Bottle">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.0012" y1="60.9238" x2="56.1946" y2="60.9238">
                <stop offset="0" style="stop-color:#333399" />
                <stop offset="0.52" style="stop-color:#C7C4E2" />
                <stop offset="0.65" style="stop-color:#A3A0D0" />
                <stop offset="0.94" style="stop-color:#4747A3" />
                <stop offset="1" style="stop-color:#333399" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M4.618,9.347L0.001,23.423v84.347l4.617,4.729h46.847l4.729-4.729V23.423L51.465,9.347H4.618z" />
            <path fill="none" d="M4.618,9.347L0.001,23.423v84.347l4.617,4.729h46.847l4.729-4.729V23.423L51.465,9.347H4.618" />
        </g>
        <g id="Group_Cap">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="2.2532" y1="8.1641" x2="53.8303" y2="8.1641">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M2.253,9.347H53.83V6.982H2.253V9.347z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.6184" y1="3.4912" x2="51.4651" y2="3.4912">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M4.618,6.982h46.847V0H4.618V6.982z" />
            <path fill="none" d="M2.253,9.347H53.83V6.982H2.253V9.347" />
            <path fill="none" d="M4.618,6.982h46.847V0H4.618V6.982" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGDuct16 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 100.785" enable-background="new 0 0 112.5 100.785" xml:space="preserve">
        <g id="Group_Bend_Duct">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.5" d="M36.149,88.398L0.227,52.589L0.001,0h91.327v14.301l9.459,9.46   L36.149,88.398z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M0.227,52.589l91.102-38.288" />
        </g>
        <g id="Group_Joints">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M98.311,18.13l12.162,11.937l2.025-2.026l-12.162-11.938L98.311,18.13   z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.5" d="M27.816,88.624l11.937,12.161l2.252-2.026L29.843,86.598   L27.816,88.624z" />
        </g>
        <g id="Group_Rod">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.5" d="M106.305,18.13l4.168,4.054L33.897,98.759l-4.054-4.055L106.305,18.13   z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.fills,"Group_Bend_Duct",[0.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Joints",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Rod",[0.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
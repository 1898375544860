import { SVGObject } from "../svgobject";

export class SVGTank19 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_TankBody">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="66.5625" x2="112.5" y2="66.5625">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <rect y="20.625" fill="url(#SVGID_1_)" width="112.5" height="91.875" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="7.2002" y1="5.7002" x2="105.5996" y2="5.7002">
            <stop offset="0" style="stop-color:#383838" />
            <stop offset="0.45" style="stop-color:#ECECEC" />
            <stop offset="0.55" style="stop-color:#D9D9D9" />
            <stop offset="0.76" style="stop-color:#A8A8A8" />
            <stop offset="0.77" style="stop-color:#A6A6A6" />
            <stop offset="1" style="stop-color:#383838" />
        </linearGradient>
        <polygon fill="url(#SVGID_2_)" points="96,11.4 105.6,0 7.2,0 16.5,11.4 " />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="16.2002" x2="112.5" y2="16.2002">
            <stop offset="0" style="stop-color:#1F1F1F" />
            <stop offset="0.45" style="stop-color:#999999" />
            <stop offset="0.62" style="stop-color:#868686" />
            <stop offset="0.77" style="stop-color:#737373" />
            <stop offset="1" style="stop-color:#1F1F1F" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M96,11.4H16.5C3.211,11.4,0,21,0,21h112.5C112.5,21,107.419,11.4,96,11.4z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater24 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 18.807 112.5" enable-background="new 0 0 18.807 112.5" xml:space="preserve">
        <g id="Group_Gate">
            <path fill="#231F20" d="M16.779,60.375L0.901,65.541l0.919,1.167l16.985-5.916L16.779,60.375z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-2.334" y1="81.415" x2="23.2267" y2="93.3342">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M5.067,65.541l11.712-4.055l2.026,41.329L2.478,112.5L5.067,65.541z" />
            <path fill="#231F20" d="M6.532,88.514v9.685l1.577-4.617L6.532,88.514z" />
            <path fill="#231F20" d="M6.532,71.734v9.572l1.577-4.505L6.532,71.734z" />
            <path fill="#4D4D4D" d="M6.532,81.307l8.67-4.505l1.577-4.617l-8.671,4.617L6.532,81.307z" />
            <path fill="#4D4D4D" d="M6.532,98.198l8.67-4.617l1.577-4.617l-8.671,4.617L6.532,98.198z" />
        </g>
        <g id="Group_Rod">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="8.1084" y1="41.4517" x2="10.1357" y2="41.4517">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M8.108,19.257h2.027v43.952c0,0-0.232,0.438-0.982,0.438c-0.875,0-1.045-0.438-1.045-0.438V19.257z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="8.1084" y1="6.5313" x2="10.1357" y2="6.5313">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M8.108,0h2.027v12.719c0,0-0.232,0.344-0.998,0.344c-0.734,0-1.029-0.344-1.029-0.344V0z" />
        </g>
        <g id="Group_Frame">
            <path fill="#4D4D4D" d="M14.753,18.243v42.793l4.054,0.839L16.779,17.68L14.753,18.243z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-15.6816" y1="51.8726" x2="32.158" y2="74.1805">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M0,18.243V112.5h2.478l16.328-9.685V14.64L0,18.243z M5.067,107.883V21.734l11.712-4.054v83.537   L5.067,107.883z" />
            <path fill="#4D4D4D" d="M2.478,112.5V19.257l16.328-4.617h-4.053L0,18.243V112.5H2.478z" />
            <path fill="none" d="M0,18.243l2.478,1.014" />
        </g>
        <g id="Group_Wheel">
            <radialGradient id="SVGID_5_" cx="9.4028" cy="15.6616" r="6.9896" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" d="M18.806,16.681c0,1.611-9.183,2.029-9.684,2.029C8.644,18.71,0,18.333,0,16.681   c0-2.247,4.21-4.068,9.403-4.068S18.806,14.435,18.806,16.681z" />
            <path fill="#333333" d="M0.901,16.104l6.194-3.041l-3.604,4.054L0.901,16.104z" />
            <path fill="#333333" d="M5.067,17.68l3.604-4.617L8.108,17.68H5.067z" />
            <path fill="#333333" d="M13.176,17.68l-3.604-4.617l0.563,4.617H13.176z" />
            <path fill="#333333" d="M17.793,16.104l-6.081-3.041l3.49,4.054L17.793,16.104z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.5989583333333334, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Wheel",[0.0, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.3958333333333333, 0.0]);

        super.drawObject();
    }
}
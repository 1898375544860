import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9568be54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row items-center no-wrap" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row items-center no-wrap" }
const _hoisted_4 = { class: "absolute-full flex flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_EventsTable = _resolveComponent("EventsTable")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_slider = _resolveComponent("q-slider")!
  const _component_q_knob = _resolveComponent("q-knob")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_ctx.usevalue)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        style: _normalizeStyle(_ctx.getColor())
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, {
            class: "text-center",
            style: {height:'60px', fontSize: '18px'}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.description), 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_LineChart, {
                    id: this.id,
                    tagname: this.path,
                    linecolor: "#3333ff",
                    description: this.description,
                    usehistory: this.usehistory
                  }, null, 8, ["id", "tagname", "description", "usehistory"]),
                  _createVNode(_component_EventsTable, {
                    tagname: this.path,
                    description: this.description,
                    useevents: this.useevents
                  }, null, 8, ["tagname", "description", "useevents"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          (_ctx.viewtype=='value')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 0,
                class: "flex flex-center",
                style: {height:'100px', fontSize: '40px' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.getTagValue()) + _toDisplayString(_ctx.unit), 1),
                  (_ctx.icon!=null && _ctx.icon!=='')
                    ? (_openBlock(), _createBlock(_component_q_icon, {
                        key: 0,
                        name: _ctx.icon
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='cirprogress')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 1,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_circular_progress, {
                    "show-value": "",
                    "font-size": "18px",
                    value: _ctx.getTagValue(),
                    size: "80px",
                    thickness: 0.05,
                    "track-color": "transparent",
                    min: _ctx.getMinValue(),
                    max: _ctx.getMaxValue()
                  }, {
                    default: _withCtx(() => [
                      (_ctx.icon!=null && _ctx.icon!=='')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: _ctx.icon
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.getTagValue()) + _toDisplayString(_ctx.unit), 1)
                    ]),
                    _: 1
                  }, 8, ["value", "thickness", "min", "max"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='progress')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 2,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_linear_progress, {
                    size: "50px",
                    style: _normalizeStyle(_ctx.getFrontColorStr()),
                    value: _ctx.getTagValueProgress(),
                    class: "q-mt-sm"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_q_badge, {
                          style: _normalizeStyle(_ctx.getFrontColorText())
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTagValue()) + _toDisplayString(_ctx.unit), 1),
                            (_ctx.icon!=null && _ctx.icon!=='')
                              ? (_openBlock(), _createBlock(_component_q_icon, {
                                  key: 0,
                                  name: _ctx.icon
                                }, null, 8, ["name"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["style"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["style", "value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='icon')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 3,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  (_ctx.icon!=null && _ctx.icon!=='')
                    ? (_openBlock(), _createBlock(_component_q_icon, {
                        key: 0,
                        name: _ctx.icon,
                        style: {"font-size":"3.5rem"}
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='button')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 4,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    icon: _ctx.icon,
                    label: _ctx.unit,
                    size: "22px",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickbutton()))
                  }, null, 8, ["icon", "label"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='input')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 5,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    class: "qinput",
                    onKeydown: _withKeys(_withModifiers(_ctx.handleInput, ["prevent"]), ["enter"]),
                    modelValue: _ctx.calvalue,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.calvalue) = $event)),
                    label: _ctx.unit,
                    style: {height:'100px', fontSize: '32px' }
                  }, {
                    before: _withCtx(() => [
                      (_ctx.icon!=null && _ctx.icon!=='')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: _ctx.icon,
                            style: {"font-size":"2.5rem"}
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true)
                    ]),
                    after: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        round: "",
                        dense: "",
                        flat: "",
                        icon: "send",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInput()))
                      })
                    ]),
                    _: 1
                  }, 8, ["onKeydown", "modelValue", "label"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='slider')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 6,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  (_ctx.icon!=null && _ctx.icon!=='')
                    ? (_openBlock(), _createBlock(_component_q_item_section, {
                        key: 0,
                        avatar: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: _ctx.icon,
                            style: {"font-size":"2.5rem"}
                          }, null, 8, ["name"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_slider, {
                        class: "slider",
                        modelValue: _ctx.slidervalue,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.slidervalue) = $event)),
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleInput())),
                        min: _ctx.getMinValue(),
                        max: _ctx.getMaxValue(),
                        markers: _ctx.getMarkersStep(),
                        "marker-labels": "",
                        color: _ctx.getSliderColor(),
                        "label-text-color": _ctx.getSliderTextColor(),
                        label: ""
                      }, null, 8, ["modelValue", "min", "max", "markers", "color", "label-text-color"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewtype=='knob')
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 7,
                class: "flex flex-center",
                style: {height:'100px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_knob, {
                    "show-value": "",
                    "font-size": "16px",
                    modelValue: _ctx.slidervalue,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.slidervalue) = $event)),
                    onDragValue: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleKnobInput())),
                    size: "60px",
                    thickness: 0.05,
                    style: _normalizeStyle(_ctx.getFrontColorStr()),
                    min: _ctx.getMinValue(),
                    max: _ctx.getMaxValue(),
                    "track-color": "transparent"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.icon!=null && _ctx.icon!=='')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: _ctx.icon,
                            class: "q-mr-xs"
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.tagvalue) + _toDisplayString(_ctx.unit), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "thickness", "style", "min", "max"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_card_actions, {
            class: "actions",
            align: "between"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.getDateTimeStr()), 1),
              _createElementVNode("div", null, " #" + _toDisplayString(_ctx.name), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { Value } from "../value";

export class Tank extends ObjectView{
    fillcolor:string;
    vertical:boolean;
    ratio:number;
    type3d:number;
    
    
    public drawObject():void{
      super.initObject();

      if(this.type3d != 0) this.type3d = 1
      if(this.ratio<=0) this.ratio = 90
		
      let fillcolor = '0xffffff00';
      fillcolor = this.fillcolor;
      const fillprop = this.properties==null ? null : this.properties['fillcolor'];
      if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
            fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
      }	

      if(this.vertical){
          this.drawVerticalTank(fillcolor)
      }
      else{
          this.drawHorizontalTank(fillcolor)
      }
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "fillcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
          case "ratio": {this[field] = value.value;this.rotatednode=null;break;}
          case "vertical": {
              if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                  this[field]= false
                  this.rotatednode=null;
              }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                  this[field]= true 
                  this.rotatednode=null;
              } 
              break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
          case "ratio": {value.datatype=6; value.value = this[field];break}
          case "vertical": {value.datatype=0; value.value = (this[field]);break}
      }
      return value;
    }

    private drawHorizontalTank(fillcolor:string){    
      const rectfill = new Konva.Rect({
          x: this.width/2/this.ratio,
          y: 0,
          width: this.width - this.width/this.ratio,
          height: this.height,
      });

      this.node.add(rectfill)
      if (this.type3d==0){ 
      rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
      rectfill.fillLinearGradientEndPoint({ x: 0, y: 0 });
      rectfill.fillLinearGradientColorStops([0, 
          ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.5), 
          1, 'white'])
      }else{
          rectfill.fill(ColorUtils.convertformat(fillcolor))
      }   

      const ellipseright = new Konva.Ellipse({
          x: this.width-this.width/2/this.ratio,
          y: this.height/2,
          radiusX: this.width/2/this.ratio,
          radiusY: this.height/2,
      });
      if (this.type3d==0){ 
        ellipseright.fillLinearGradientStartPoint({ x: 0, y: this.height/2 });
        ellipseright.fillLinearGradientEndPoint({ x: 0, y: -this.height/2 });
        ellipseright.fillLinearGradientColorStops([0, 
          ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.5), 
          1, 'white'])
      }else{
        ellipseright.fill(ColorUtils.convertformat(fillcolor))
      }

      this.node.add(ellipseright)

      const ellipseleft = new Konva.Ellipse({
        x: this.width/2/this.ratio,
        y: this.height/2,
        radiusX: this.width/2/this.ratio,
        radiusY: this.height/2,
      });
      if (this.type3d==0){ 
        ellipseleft.fillLinearGradientStartPoint({ x: 0, y: this.height/2 });
        ellipseleft.fillLinearGradientEndPoint({ x: 0, y: -this.height/2 });
        ellipseleft.fillLinearGradientColorStops([0, 
          ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.5), 
          1, 'white'])
      }else{
        ellipseleft.fill(ColorUtils.convertformat(fillcolor))
      }

      this.node.add(ellipseleft)
        
      const line1 = new Konva.Rect({
          x:this.width/2/this.ratio,
          y: 0,
          width: this.height/100,
          height: this.height,
      })
      if (this.type3d==0){ 
        line1.fillLinearGradientStartPoint({ x: 0, y: this.height });
        line1.fillLinearGradientEndPoint({ x: 0, y: 0 });
        line1.fillLinearGradientColorStops([0, 
            ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.3), 
            1, 'white'])
      }else{
        line1.fill(ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.5))
      }
      this.node.add(line1)

      const line2 = new Konva.Rect({
          x:this.width-this.width/2/this.ratio,
          y: 0,
          width: this.height/100,
          height: this.height
      })
      if (this.type3d==0){ 
        line2.fillLinearGradientStartPoint({ x: 0, y: this.height });
        line2.fillLinearGradientEndPoint({ x: 0, y: 0 });
        line2.fillLinearGradientColorStops([0, 
            ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.3), 
            1, 'white'])
      }else{
        line2.fill(ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.5))
      }
      this.node.add(line2)
    }

    private drawVerticalTank(fillcolor:string){

        const ellipsetop = new Konva.Ellipse({
            x: this.width/2,
            y: this.height/2/this.ratio,
            radiusX: this.width/2,
            radiusY: this.height/2/this.ratio,
          });
          if (this.type3d==0){ 
          ellipsetop.fillLinearGradientStartPoint({ x: 0, y: 0 });
          ellipsetop.fillLinearGradientEndPoint({ x: 0, y: -this.height/2/this.ratio });
          ellipsetop.fillLinearGradientColorStops([0, 
            ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.85), 
            1, 'white'])
          }else{
            ellipsetop.fill(ColorUtils.convertformat(fillcolor))
          }

        this.node.add(ellipsetop)

        const ellipsebottom = new Konva.Ellipse({
            x: this.width/2,
            y: this.height-this.height/2/this.ratio,
            radiusX: this.width/2,
            radiusY: this.height/2/this.ratio,
          });
          if (this.type3d==0){ 
            ellipsebottom.fillRadialGradientStartPoint({ x: 0, y: 0 });
            ellipsebottom.fillRadialGradientEndRadius(this.width/2);
            ellipsebottom.fillRadialGradientColorStops([0, 
                'white',
                1, ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.6)])
          }
          else{
            ellipsebottom.fill(ColorUtils.convertformat(fillcolor))
          }

        this.node.add(ellipsebottom)

        const rectfill = new Konva.Rect({
            x: 0,
            y:this.height/2/this.ratio,
            width: this.width,
            height: this.height-this.height/this.ratio,
            });
        
        this.node.add(rectfill)
        if (this.type3d==0){ 
            rectfill.fillLinearGradientStartPoint({ x: 0, y: this.height });
            rectfill.fillLinearGradientEndPoint({ x: this.width, y: this.height });
            rectfill.fillLinearGradientColorStops([0, 
                ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.85), 
                0.5, 'white',
                1, ColorUtils.darkColor(ColorUtils.convertformat(fillcolor), 0.85)])
            }else{
                rectfill.fill(ColorUtils.convertformat(fillcolor))
            }   
       
    }
}
import { SVGObject } from "../svgobject";

export class SVGBlower4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 101.686" enable-background="new 0 0 112.5 101.686" xml:space="preserve">
        <g id="Group_7">
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="21.397,26.688 92.068,26.757 92.068,77.793 21.397,77.586    " />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.002" y1="101.6846" x2="1.002" y2="101.6846" gradientTransform="matrix(0 -51.1045 51.1045 0 -5139.8154 77.8659)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.18" style="stop-color:#767676" />
                    <stop offset="0.4" style="stop-color:#A2A2A2" />
                    <stop offset="0.51" style="stop-color:#B3B3B3" />
                    <stop offset="0.6" style="stop-color:#A8A8A8" />
                    <stop offset="0.75" style="stop-color:#8B8B8B" />
                    <stop offset="0.94" style="stop-color:#5C5C5C" />
                    <stop offset="1" style="stop-color:#4D4D4D" />
                </linearGradient>
                <rect x="21.397" y="26.688" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="70.671" height="51.105" />
            </g>
        </g>
        <g id="Group_6">
            <g>
                <defs>
                    <rect id="SVGID_4_" x="91.104" y="23.958" width="21.396" height="56.36" />
                </defs>
                <clipPath id="SVGID_5_">
                    <use xlink:href="#SVGID_4_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-0.0039" y1="101.6826" x2="0.9961" y2="101.6826" gradientTransform="matrix(0 -56.3594 56.3594 0 -5628.9917 80.1439)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="91.104" y="23.958" clip-path="url(#SVGID_5_)" fill="url(#SVGID_6_)" width="21.396" height="56.36" />
            </g>
        </g>
        <g id="Group_5">
            <rect x="10.024" y="77.588" fill="#4D4D4D" width="83.556" height="24.098" />
        </g>
        <g id="Group_4">
            <g>
                <defs>
                    <polyline id="SVGID_7_" points="92.878,85.583 92.878,98.982 98.984,98.982 98.984,85.583 93.691,85.583    " />
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="0.0088" y1="101.6943" x2="1.0088" y2="101.6943" gradientTransform="matrix(0 -13.3994 13.3994 0 -1266.7124 99.0937)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="92.878" y="85.583" clip-path="url(#SVGID_8_)" fill="url(#SVGID_9_)" width="6.106" height="13.399" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_10_" x="26.689" y="82.881" width="66.891" height="18.805" />
                </defs>
                <clipPath id="SVGID_11_">
                    <use xlink:href="#SVGID_10_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-0.0151" y1="101.6699" x2="0.9849" y2="101.6699" gradientTransform="matrix(0 -18.8057 18.8057 0 -1851.8337 101.3934)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="26.689" y="82.881" clip-path="url(#SVGID_11_)" fill="url(#SVGID_12_)" width="66.891" height="18.805" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_13_" x="25.339" y="93.393" width="37.499" height="8.293" />
                </defs>
                <clipPath id="SVGID_14_">
                    <use xlink:href="#SVGID_13_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="-0.0303" y1="101.6543" x2="0.9697" y2="101.6543" gradientTransform="matrix(0 -8.293 8.293 0 -798.9341 101.4305)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.18" style="stop-color:#767676" />
                    <stop offset="0.4" style="stop-color:#A2A2A2" />
                    <stop offset="0.51" style="stop-color:#B3B3B3" />
                    <stop offset="0.6" style="stop-color:#A8A8A8" />
                    <stop offset="0.75" style="stop-color:#8B8B8B" />
                    <stop offset="0.94" style="stop-color:#5C5C5C" />
                    <stop offset="1" style="stop-color:#4D4D4D" />
                </linearGradient>
                <rect x="25.339" y="93.393" clip-path="url(#SVGID_14_)" fill="url(#SVGID_15_)" width="37.499" height="8.293" />
            </g>
        </g>
        <g id="Group_3">
            <g>
                <defs>
                    <rect id="SVGID_16_" x="0.002" y="7.996" width="21.396" height="88.285" />
                </defs>
                <clipPath id="SVGID_17_">
                    <use xlink:href="#SVGID_16_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="-0.0034" y1="101.6826" x2="0.9966" y2="101.6826" gradientTransform="matrix(0 -88.2852 88.2852 0 -8966.3066 95.9953)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="0.002" y="7.996" clip-path="url(#SVGID_17_)" fill="url(#SVGID_18_)" width="21.396" height="88.285" />
            </g>
        </g>
        <g id="Group_2">
            <g>
                <defs>
                    <rect id="SVGID_19_" x="21.397" y="0" width="2.027" height="13.963" />
                </defs>
                <clipPath id="SVGID_20_">
                    <use xlink:href="#SVGID_19_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="-0.0049" y1="101.6807" x2="0.9951" y2="101.6807" gradientTransform="matrix(0 -13.9629 13.9629 0 -1397.347 13.8926)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="21.397" y="0" clip-path="url(#SVGID_20_)" fill="url(#SVGID_21_)" width="2.027" height="13.963" />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_22_" x="23.425" y="9.348" width="1.914" height="3.941" />
                </defs>
                <clipPath id="SVGID_23_">
                    <use xlink:href="#SVGID_22_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="0.0132" y1="101.6982" x2="1.0132" y2="101.6982" gradientTransform="matrix(0 -3.9414 3.9414 0 -376.4542 13.3385)">
                    <stop offset="0" style="stop-color:#383838" />
                    <stop offset="0.5" style="stop-color:#ECECEC" />
                    <stop offset="0.57" style="stop-color:#D9D9D9" />
                    <stop offset="0.71" style="stop-color:#A8A8A8" />
                    <stop offset="0.92" style="stop-color:#5A5A5A" />
                    <stop offset="1" style="stop-color:#383838" />
                </linearGradient>
                <rect x="23.425" y="9.348" clip-path="url(#SVGID_23_)" fill="url(#SVGID_24_)" width="1.914" height="3.941" />
            </g>
        </g>
        <g id="Group_1">
            <rect x="8.673" y="5.292" fill="#7F7F7F" width="3.941" height="93.69" />
            <g>
                <defs>
                    <rect id="SVGID_25_" x="8.673" y="5.292" width="3.941" height="93.69" />
                </defs>
                <clipPath id="SVGID_26_">
                    <use xlink:href="#SVGID_25_" overflow="visible" />
                </clipPath>
                
                    <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="0.0029" y1="101.6885" x2="1.0029" y2="101.6885" gradientTransform="matrix(0 -93.6904 93.6904 0 -9516.5752 99.2407)">
                    <stop offset="0" style="stop-color:#4D4D4D" />
                    <stop offset="0.18" style="stop-color:#767676" />
                    <stop offset="0.4" style="stop-color:#A2A2A2" />
                    <stop offset="0.51" style="stop-color:#B3B3B3" />
                    <stop offset="0.6" style="stop-color:#A8A8A8" />
                    <stop offset="0.75" style="stop-color:#8B8B8B" />
                    <stop offset="0.94" style="stop-color:#5C5C5C" />
                    <stop offset="1" style="stop-color:#4D4D4D" />
                </linearGradient>
                <rect x="8.673" y="5.292" clip-path="url(#SVGID_26_)" fill="url(#SVGID_27_)" width="3.941" height="93.69" />
            </g>
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[]);
		this.fillGradient(this.gradients,"SVGID_2_",[]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.5989583333333334, 0.0, 0.18, 0.9166666666666666, 0.0, 0.4, 0.734375, 67.0, 0.51, 0.6041666666666666, 101.0, 0.6, 0.6875, 79.0, 0.75, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[]);
		this.fillGradient(this.gradients,"SVGID_5_",[]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[]);
		this.fillGradient(this.gradients,"SVGID_8_",[]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[]);
		this.fillGradient(this.gradients,"SVGID_11_",[]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[]);
		this.fillGradient(this.gradients,"SVGID_14_",[]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.5989583333333334, 0.0, 0.18, 0.9166666666666666, 0.0, 0.4, 0.734375, 67.0, 0.51, 0.6041666666666666, 101.0, 0.6, 0.6875, 79.0, 0.75, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[]);
		this.fillGradient(this.gradients,"SVGID_17_",[]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[]);
		this.fillGradient(this.gradients,"SVGID_20_",[]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[]);
		this.fillGradient(this.gradients,"SVGID_23_",[]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[]);
		this.fillGradient(this.gradients,"SVGID_26_",[]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.5989583333333334, 0.0, 0.18, 0.9166666666666666, 0.0, 0.4, 0.734375, 67.0, 0.51, 0.6041666666666666, 101.0, 0.6, 0.6875, 79.0, 0.75, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
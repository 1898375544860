import { SVGObject } from "../svgobject";

export class ReactorTank extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 96.055" enable-background="new 0 0 112.5 96.055" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="53.8853" y1="65.5383" x2="53.8853" y2="4.6179">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M9.349,4.617c-5.488,0-9.347,13.638-9.347,30.46s3.858,30.46,9.347,30.46h98.42V4.617H9.349z" />
            <ellipse fill="#7F7F7F" cx="107.769" cy="35.022" rx="2.364" ry="30.404" />
        </g>
        <g id="Group_TankSupprt">
            <path fill="#194C4C" d="M13.965,65.538h86.822v7.095H13.965V65.538z" />
            <path d="M18.695,72.633h77.362v16.328H18.695V72.633z" />
            <path fill="#336666" d="M16.33,84.344h42.229v9.347H16.33V84.344z" />
            <path fill="#194C4C" d="M56.194,86.708h46.845v9.347H56.194V86.708z" />
            <path fill="#A3B2B2" d="M53.829,93.691h51.575v2.364H53.829V93.691z" />
            <path fill="#A3B2B2" d="M53.829,86.708h51.575v2.365H53.829V86.708z" />
        </g>
        <g id="Group_Support">
            <path fill="#A6A6A6" d="M58.559,63.286h6.981v13.963h-6.981V63.286z" />
            <path fill="#A6A6A6" d="M21.06,63.286h6.982v13.963H21.06V63.286z" />
            <path fill="#A6A694" d="M13.965,77.249h21.17v7.095h-21.17V77.249z" />
            <path fill="#CCCCC2" d="M42.118,77.249h44.593v9.459H42.118V77.249z" />
            <path fill="#7F7F7F" d="M39.753,70.268h7.094v14.076h-7.094V70.268z" />
            <path fill="#4C4C4C" d="M13.965,79.615h21.17v2.364h-21.17V79.615z" />
        </g>
        <g id="Group_Lines">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="10.5308" y1="67.9026" x2="10.5308" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M9.349,2.252h2.365v65.65H9.349V2.252z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="19.8774" y1="67.9026" x2="19.8774" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M18.695,2.252h2.365v65.65h-2.365V2.252z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="29.2241" y1="67.9026" x2="29.2241" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M28.042,2.252h2.365v65.65h-2.365V2.252z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="38.6265" y1="67.9026" x2="38.6265" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M37.5,2.252h2.252v65.65H37.5V2.252z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="48.0298" y1="67.9026" x2="48.0298" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M46.847,2.252h2.365v65.65h-2.365V2.252z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="57.376" y1="67.9026" x2="57.376" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M56.194,2.252h2.365v65.65h-2.365V2.252z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="66.7227" y1="67.9026" x2="66.7227" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M65.54,2.252h2.364v65.65H65.54V2.252z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="76.126" y1="67.9026" x2="76.126" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M75,2.252h2.252v65.65H75V2.252z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="85.5283" y1="67.9026" x2="85.5283" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M84.346,2.252h2.365v65.65h-2.365V2.252z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="94.875" y1="67.9026" x2="94.875" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M93.692,2.252h2.364v65.65h-2.364V2.252z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="104.2217" y1="67.9026" x2="104.2217" y2="2.2527">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M103.039,2.252h2.365v65.65h-2.365V2.252z" />
        </g>
        <g id="Group_Label">
            <path fill="#A8ADAD" d="M81.98,23.423h18.807v11.711H81.98V23.423z" />
            <path fill="#EFF2F2" d="M96.057,23.423v2.364H84.346v6.981h11.711v2.365H81.98V23.423H96.057z" />
        </g>
        <g id="Group_Tank_Valves1">
            <path d="M25.001,1.464v3.153h1.464V1.464h1.577V0h-4.617v1.464H25.001z" />
            <path d="M88.287,1.464v3.153h1.576V1.464h1.465V0h-4.617v1.464H88.287z" />
        </g>
        <g id="Group_Tank_Valves2">
            <path fill="#E5E5E5" d="M110.922,13.288h-3.153c0,0-0.347,0.731,0,1.464h3.153v1.576h1.576v-4.617h-1.576V13.288z" />
            <path fill="#E5E5E5" d="M110.922,55.404h-3.153c0,0-0.253,0.702,0,1.576h3.153v1.577h1.576v-4.729h-1.576V55.404z" />
        </g>
        </svg>`
        super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_", [0.0, 0.43, 0.0,
											0.45,0.16,214.0,
											0.55,0.30,177.0,
											0.76,0.69,79.0,
											0.77,0.7,75.0,
											1.0,0.43,0.0]);
		this.fillGradient(this.gradients,"SVGID_2_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		
		this.fillGradient(this.gradients,"SVGID_3_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_4_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		
		this.fillGradient(this.gradients,"SVGID_5_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		
		this.fillGradient(this.gradients,"SVGID_6_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_7_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_8_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_9_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_10_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_11_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.gradients,"SVGID_12_", [0.0, 0.24,0.00,
				0.45,0.81,49.00,
				0.62,0.95,11.00,
				0.77,0.90,0.00,
				1.0,0.24,0.00]);
		this.fillGradient(this.fills, "Group_TankSupprt", [0, 0.46, 0.00,
				1, 0.66, 0.00,
				2, 0.46, 0.00,
				3, 0.65, 89.00,
				4, 0.65, 89.00,
        ]);
		this.fillGradient(this.fills, "Group_Support", [0, 0.70,75.00,
				1, 0.70,75.00,
				2, 0.75,63.00,
				3, 0.44,143.00,
				4, 0.99,0.00,
				5, 0.59,0.00,
        ]);
		this.fillGradient(this.fills, "Group_Label", [0, 0.67,85.00,
				1,0.12,224.00,
				
        ]);
		this.fillGradient(this.fills, "Group_Tank_Valves2", [0, 0.21,201.00,
				1,0.21,201.00]);
		
        super.drawObject();
    }
}
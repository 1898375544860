import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { SliderControlProperty } from "./properties/slidercontrolproperty";
import Konva from "konva";
import { Value } from "../value";


export class Slider extends ObjectView{
    color:string;
    fillcolor:string;
    type3d:number;

    node1:Konva.Group;

    public drawObject():void{
      super.initObject();

      if(this.type3d != 0) this.type3d = 1

      this.node1  = new Konva.Group({
        x: 0,
        y: 0,
      });
      this.drawObject2(true);
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color":
          case "fillcolor":  {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color":
          case "fillcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }

    private drawObject2(usefunctions:boolean){
        this.node1.removeChildren();
    
        let color:string = this.color;
        const lineprop = this.properties['color'];
        if (lineprop!=null){	
            const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
            if (linecolorproperty!=null) 
              color = linecolorproperty.getColor(this.tagnames, this.color)
        }	
        const color2 = ColorUtils.convertformat(color)
        let fillcolor = '0xffffff00';
        
        let fillheight = this.height
        let value = 0
        let minimum = 0
        let maximum = 100
        let decimalpos = 1
                
        const sliderprop = this.properties== null ? null : this.properties['slidercontrol']
        let sliderproperty;
        if (sliderprop != null){
            sliderproperty =Object.assign(new SliderControlProperty(), sliderprop);
            value = sliderproperty.getCurrentValue(this.tagnames)
            fillheight = sliderproperty.getCurrentFilling(this.tagnames, this.height)
            decimalpos = sliderproperty.decimalpos
            minimum = sliderproperty.minimumvalue
            maximum = sliderproperty.maximumvalue
        }
        fillcolor = this.fillcolor;
        const fillprop = this.properties== null ? null : this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
        const rectvertical = new Konva.Rect({
            x: this.width*3/5,
            y: 0,
            width: this.width/5,
            height: this.height,
            cornerRadius:this.width/5,
        });
        if(this.type3d==0){
          rectvertical.fillLinearGradientStartPoint({ x: 0, y: 0 });
          rectvertical.fillLinearGradientEndPoint({ x: this.width/5, y: 0 });
          rectvertical.fillLinearGradientColorStops([0, color2,
              0.5, ColorUtils.darkColor(color2, 0.3),
              1, color2])
        }else{
          rectvertical.fill(ColorUtils.convertformat(color))
        }
        this.node1.add(rectvertical)

        const line1 = new Konva.Line({
            points: [this.width*2/5, this.height*19/20, this.width*3/5, this.height*19/20],
            strokeWidth: this.height/100,
            stroke: ColorUtils.darkColor(color2,0.3)
        });
        this.node1.add(line1); 
               
            
        const line2 = new Konva.Line({
            points: [this.width*2/5, this.height/20, this.width*3/5, this.height/20],
            strokeWidth: this.height/100,
            stroke: ColorUtils.darkColor(color2, 0.3)
        });
        this.node1.add(line2);  

        for(let heighty=this.height/20+this.height/11; heighty<this.height*19/20; heighty=heighty+this.height/11){
          const linetick = new Konva.Line({
              points: [this.width/2, heighty, this.width*3/5, heighty],
              strokeWidth: this.height/100,
              stroke: ColorUtils.darkColor(color2, 0.3)
            });
            this.node1.add(linetick); 
        }

        const Textmax = new Konva.Text({
          x: (this.angle==90 || this.angle ==270)? this.width/10 : 0,
          y: (this.angle==90 || this.angle ==270)? this.height*3/20: 0,
          verticalAlign:"bottom",
          //width: this.width*3/5,
          height: this.height/10,
          text: maximum==null? "100": maximum.toString(),
          fontSize: this.height/10,
          fontFamily: "Roboto",
          fontStyle: "normal",
          align: 'left',
          fill: ColorUtils.darkColor(color2, 0.3),
          rotation: (this.angle==90 || this.angle ==270)? 270 : 0
        });  
        this.node1.add(Textmax); 

        const Textmin = new Konva.Text({
          x: (this.angle==90 || this.angle ==270)? this.width/10 :0,
          y: (this.angle==90 || this.angle ==270)? this.height :this.height*18/20,
          verticalAlign:"middle",
          //width: this.width*3/5,
          height: this.height/10,
          text: maximum==null? "0": minimum.toString(),
          fontSize: this.height/10,
          fontFamily: "Roboto",
          fontStyle: "normal",
          align: 'left',
          fill: ColorUtils.darkColor(color2, 0.3),
          rotation: (this.angle==90 || this.angle ==270)? 270 : 0,
        });  
        this.node1.add(Textmin);   

        const TextCurrentValue = new Konva.Text({
          x: (this.angle==90 || this.angle ==270)? this.width/10 : 0,
          y: (this.angle==90 || this.angle ==270)? this.height*12/20 :this.height/2- this.height/20,
          verticalAlign:"bottom",
          //width: this.width*4/5,
          height: this.height/10,
          text: value.toFixed(decimalpos),
          fontSize: this.height/10,
          fontFamily: "Roboto",
          fontStyle: "normal",
          fill: fillcolor2,
          align: 'left',
          rotation: (this.angle==90 || this.angle ==270)? 270 : 0
        });  
        this.node1.add(TextCurrentValue);

        const rectfill = new Konva.Rect({
          x: this.width*3/5,
          y: this.height-fillheight,
          width: this.width/5,
          height: fillheight,
          cornerRadius:this.width/5,
        });
        if(this.type3d==0){
          rectfill.fillLinearGradientStartPoint({ x: 0, y: 0 });
          rectfill.fillLinearGradientEndPoint({ x: this.width/5, y: 0 });
          rectfill.fillLinearGradientColorStops([0, fillcolor2,
              0.5, ColorUtils.darkColor(fillcolor2, 0.5),
              1, fillcolor2])
        }else{
          rectfill.fill(ColorUtils.convertformat(fillcolor))
        }
        this.node1.add(rectfill)  

        const slider = this.drawSlider(fillheight,color2, false)
        this.node1.add(slider)

        this.node.add(this.node1)
  
        if (usefunctions) this.setFunctions(fillheight, color2, sliderproperty)
    }

    private drawSlider(fillheight:number,color2:string, draggable:boolean):Konva.Ellipse{
      const slider = new Konva.Ellipse({
        x: this.width*2/5+this.width*3/10,
        y: this.height- fillheight,
        radiusX: this.width*3/10,
        radiusY: this.height/20,
        draggable:draggable

      });
      if(this.type3d==0){
        slider.fillLinearGradientStartPoint({ x: 0, y: -this.height/20 });
        slider.fillLinearGradientEndPoint({ x: 0, y: this.height/20 });
        slider.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.3),
            0.5, color2,
            1, ColorUtils.darkColor(color2, 0.3)])
      }else{
        slider.fill(ColorUtils.darkColor(color2, 0.5))
      }
      return slider
    }
            
    protected setFunctions(fillheight:number,color2:string, controlproperty:SliderControlProperty):void{
             
      if(controlproperty != null){
        const slider = this.drawSlider(fillheight, color2, true)
        this.node.add(slider)
        controlproperty.getTag
        const originalX = slider.x();
        slider.on('dragmove', () => {
          slider.x(originalX);
          
          if (slider.y()<this.height/20) slider.y(this.height/20)
          if (slider.y()>19*this.height/20) slider.y(19*this.height/20)
          this.drawObject2(false);
          controlproperty.setCurrentFilling(this.tagnames, this.height-slider.y(),this.height)
          this.drawObject2(false);
        });

        slider.on('dragend',()=>{
          controlproperty.sendTagValue(this.tagnames)         
        });

        this.node.on('click touchend', ()=> {
          const pos = this.node.getRelativePointerPosition()
          controlproperty.setCurrentFilling(this.tagnames, this.height-pos.y,this.height)
          controlproperty.sendTagValue(this.tagnames)
        })
      } 
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Tee_H">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="49.543" y1="205.125" x2="-8.457" y2="205.125" gradientTransform="matrix(0 1 -1 0 261.375 8.707)">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <rect y="0.25" fill="url(#SVGID_1_)" width="112.5" height="58" />
        </g>
        <g id="Group_Tee_V">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="27.25" y1="69.8086" x2="84.75" y2="69.8086">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.18" style="stop-color:#959595" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <polygon fill="url(#SVGID_2_)" points="27.25,57.959 56.353,27.118 84.75,56.959 84.75,112.5 27.25,112.5  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		
        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGChemical1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 57.658 112.5" enable-background="new 0 0 57.658 112.5" xml:space="preserve">
        <g id="Group_InnerMetalFrame">
            <path fill="#333333" d="M2.027,112.049V33.333h36.824v78.715h-5.067v-1.688l3.378-3.378V95.27l-3.378-3.266v-1.688l3.378-3.378   V66.893l-3.378-3.379h-4.167v-1.689h4.167l3.378-3.379V38.401l-3.378-3.378H7.094l-3.378,3.378v20.044l3.378,3.379H24.55v1.689   H7.094l-3.378,3.379v20.045l3.378,3.378v1.688L3.717,95.27v11.713l3.378,3.378v1.688H2.027z" />
        </g>
        <g id="Group_TBD">
            <path fill="#231F20" d="M17.117,101.576h4.167v-9.572h-4.167V101.576z" />
            <path fill="#333333" d="M6.194,101.576h5.067v-7.995H6.194V101.576z" />
            <path fill="#333333" d="M20.383,96.959h3.379v11.262h-3.379V96.959z" />
            <path fill="#333333" d="M25.45,95.27h6.307v11.713H25.45V95.27z" />
            <path fill="#666666" d="M11.261,95.27h7.545v6.307h-7.545V95.27z" />
            <path fill="#666666" d="M28.379,108.221h9.234v-7.883h-9.234V108.221z" />
            <path fill="#4C4C4C" d="M22.973,108.221h11.711v1.352H22.973V108.221z" />
            <path fill="#4C4C4C" d="M5.405,109.572h16.329v-7.996H5.405V109.572z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="20.8333" y1="100.3379" x2="20.8333" y2="99.0996">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M15.427,99.1h10.812v1.238H15.427V99.1z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="19.989" y1="104.9551" x2="19.989" y2="103.7168">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M13.739,103.717h12.5v1.238h-12.5V103.717z" />
            <path fill="#231F20" d="M20.383,106.982h7.996v1.238h-7.996V106.982z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.383,66.893" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M7.094,66.893" />
            <path fill="#7F7F7F" d="M2.027,92.004h36.824v-1.688H2.027V92.004z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.477,31.757V6.644h7.095" />
        </g>
        <g id="Group_Components">
            <path fill="#231F20" d="M5.405,89.414V75.225l6.645-5.066v-7.434h9.685v12.5H25v1.239h-3.266v4.167H25v1.238h-3.266v3.829H25   v-15.54h7.545v15.09h0.789V80.18h4.616v9.234H5.405z" />
            <path fill="#231F20" d="M15.427,31.757v-7.996h6.307v5.856h0.338V11.261h0.882l0.02,18.356h0.337V9.572h0.901V25h9.572v4.617h0.45   v-6.644h4.617v8.784H15.427z" />
            <path fill="#231F20" d="M5.405,60.135l-1.688-1.689h1.688v-6.644h3.267v6.644H23.31l-1.238-2.026h-4.167v-0.788h3.829l-0.9-2.14   h-2.929v4.954h-4.617V43.469h4.617v8.333h2.478v-5.067h16.779v5.067l-0.788,6.644h0.788v1.689H5.405z" />
        </g>
        <g id="Group_ControlBox">
            <path fill="#7F7F7F" d="M25.901,29.167h6.306V19.144h-6.306V29.167z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="32.574" y1="29.2515" x2="25.4228" y2="22.1003">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M26.689,30.518v-9.685h4.617v9.685H26.689z" />
            <path fill="#FFCC00" d="M26.689,18.356v2.478h4.617v-2.478H26.689z" />
        </g>
        <g id="Group_BackPlatform">
            <path fill="#4C4C4C" d="M40.541,96.959h16.666V95.27H40.541V96.959z" />
            <path fill="#4C4C4C" d="M40.541,63.964h16.666v-1.688H40.541V63.964z" />
            <path fill="#4C4C4C" d="M40.541,31.757h8.333v1.577h-8.333V31.757z" />
        </g>
        <g id="Group_BackStairs">
            <path fill="#231F20" d="M47.973,37.162h0.45v10.473h-0.45V37.162z" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,75.225h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,71.959h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,68.581h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,65.203h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,73.648h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,70.271h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,66.893h7.095" />
            <path fill="#231F20" d="M47.973,66.441h0.45v10.473h-0.45V66.441z" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,102.027h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2499" d="M40.942,100.338h7.091" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2499" d="M40.942,98.648h7.091" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,110.36h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,106.982h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,103.717h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,108.671h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M40.879,105.293h7.095" />
            <path fill="#231F20" d="M40.541,101.576h0.338v10.473h-0.338V101.576z" />
            <path fill="#231F20" d="M47.973,101.576h0.45v10.473h-0.45V101.576z" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,37.613h6.637" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2359" d="M41.337,34.234h6.317" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,40.878h6.637" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,44.257h6.637" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2359" d="M41.337,35.923h6.317" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,39.302h6.637" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,42.567h6.637" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.2418" d="M41.337,45.946h6.637" />
        </g>
        <g id="Group_TBD1">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="23.7615" y1="62.2754" x2="30.5183" y2="62.2754">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M23.761,34.234h6.757v56.081h-6.757V34.234z" />
            <circle fill="#4D4D4D" cx="28.379" cy="46.734" r="1.239" />
        </g>
        <g id="Group_TBD2">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="3.7166" y1="19.7534" x2="20.3826" y2="19.7534">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M3.717,31.757V9.122L12.05,7.75l8.333,1.372v22.635H3.717z" />
            <radialGradient id="SVGID_6_" cx="12.0496" cy="8.1084" r="5.9357" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CBCBBC" />
                <stop offset="0.16" style="stop-color:#C0C0AE" />
                <stop offset="0.46" style="stop-color:#A4A488" />
                <stop offset="0.88" style="stop-color:#76764C" />
                <stop offset="1" style="stop-color:#676739" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" d="M3.717,9.122c0,0,1.736-2.027,8.333-2.027c6.278,0,8.333,2.027,8.333,2.027H3.717z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="9.572" y1="16.0474" x2="14.5271" y2="16.0474">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M9.572,31.757V2.478c0,0,0.506-2.14,2.478-2.14c2.153,0,2.478,2.14,2.478,2.14v29.279H9.572" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="9.1218" y1="36.6641" x2="14.9773" y2="36.6641">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M9.122,39.094h5.855v-4.859H9.122V39.094" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="7.4324" y1="46.2471" x2="16.6667" y2="46.2471">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M7.432,52.59V38.401h9.234V52.59l-4.619,1.504L7.432,52.59z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="7.0945" y1="77.3945" x2="20.3826" y2="77.3945">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M7.094,89.414V66.893l6.644-1.518l6.644,1.518v22.521H7.094" />
            
                <radialGradient id="SVGID_11_" cx="13.7385" cy="65.8223" r="4.7586" gradientTransform="matrix(-4.371139e-008 -1 2 -8.742278e-008 -117.906 79.5608)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CBCBBC" />
                <stop offset="0.16" style="stop-color:#C0C0AE" />
                <stop offset="0.46" style="stop-color:#A4A488" />
                <stop offset="0.88" style="stop-color:#76764C" />
                <stop offset="1" style="stop-color:#676739" />
            </radialGradient>
            <path fill="url(#SVGID_11_)" d="M7.094,66.893c0,0,1.734-2.141,6.645-2.141c5.121,0,6.644,2.141,6.644,2.141" />
            
                <radialGradient id="SVGID_12_" cx="12.0496" cy="54.6733" r="3.5819" gradientTransform="matrix(-4.371139e-008 -1 2 -8.742278e-008 -97.2971 66.7229)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#CBCBBC" />
                <stop offset="0.16" style="stop-color:#C0C0AE" />
                <stop offset="0.46" style="stop-color:#A4A488" />
                <stop offset="0.88" style="stop-color:#76764C" />
                <stop offset="1" style="stop-color:#676739" />
            </radialGradient>
            <polygon fill="url(#SVGID_12_)" points="13.739,56.757 10.36,56.757 7.432,52.59 16.667,52.59  " />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="10.3601" y1="58.4453" x2="13.739" y2="58.4453">
                <stop offset="0" style="stop-color:#666638" />
                <stop offset="0.45" style="stop-color:#CCCCBD" />
                <stop offset="0.53" style="stop-color:#BEBEAA" />
                <stop offset="0.7" style="stop-color:#98987A" />
                <stop offset="0.77" style="stop-color:#878763" />
                <stop offset="1" style="stop-color:#666638" />
            </linearGradient>
            <rect x="10.36" y="56.757" fill="url(#SVGID_13_)" width="3.379" height="3.378" />
        </g>
        <g id="Group_Railing">
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="56.4187" y1="56.6445" x2="57.2068" y2="56.6445">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M57.207,62.275h-0.788V51.014h0.788V62.275z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="48.6492" y1="57.207" x2="48.6492" y2="56.4194">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M40.541,57.207v-0.788h15.878l0.338,0.394l-0.338,0.395H40.541z" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="48.8738" y1="51.8018" x2="48.8738" y2="51.0137">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_16_)" d="M40.541,51.802v-0.788h16.666l-0.788,0.788H40.541z" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="48.8738" y1="84.9102" x2="48.8738" y2="84.0088">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M40.541,84.91v-0.901h16.666v0.901H40.541z" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="40.5408" y1="34.0088" x2="41.3289" y2="34.0088">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_18_)" d="M41.329,47.635h-0.788V20.383h0.788V47.635z" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="47.6355" y1="28.7725" x2="48.4236" y2="28.7725">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_19_)" d="M48.424,37.162h-0.788V20.383h0.788V37.162z" />
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="47.6355" y1="58.7275" x2="48.4236" y2="58.7275">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M48.424,66.441h-0.788V51.014h0.788V66.441z" />
            <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="40.5408" y1="63.9639" x2="41.3289" y2="63.9639">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_21_)" d="M41.329,76.914h-0.788v-25.9h0.788V76.914z" />
            <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="56.4187" y1="71.2832" x2="57.2068" y2="71.2832">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_22_)" d="M57.207,76.914h-0.788V65.653h0.788V76.914z" />
            <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="48.8738" y1="71.8467" x2="48.8738" y2="71.0586">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M41.329,71.847l-0.395-0.367l0.395-0.421h15.09l0.395,0.379l-0.395,0.409H41.329z" />
            <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="49.071" y1="66.4414" x2="49.071" y2="65.6533">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_24_)" d="M41.329,66.441l-0.395-0.399l0.395-0.389h15.878l-0.788,0.788H41.329z" />
            <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="56.4187" y1="42.0049" x2="57.2068" y2="42.0049">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_25_)" d="M57.207,47.635h-0.788V36.374h0.788V47.635z" />
            <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="48.8738" y1="42.5674" x2="48.8738" y2="41.7793">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M41.329,42.567l-0.395-0.442l0.395-0.346h15.09l0.395,0.41l-0.395,0.378H41.329z" />
            <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="49.071" y1="37.1621" x2="49.071" y2="36.374">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_27_)" d="M41.329,37.162l-0.395-0.396l0.395-0.392h15.878l-0.788,0.788H41.329z" />
            <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="32.5447" y1="23.5928" x2="33.446" y2="23.5928">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_28_)" d="M33.446,26.689h-0.901v-6.194h0.901V26.689z" />
            <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="39.6394" y1="26.126" x2="40.5408" y2="26.126">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" d="M40.541,31.757h-0.901V20.496h0.901V31.757z" />
            <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="36.5427" y1="26.6895" x2="36.5427" y2="25.9009">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_30_)" d="M32.545,26.689l0.901-0.789h6.193l0.901,0.789H32.545z" />
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="36.5427" y1="21.2837" x2="36.5427" y2="20.4956">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_31_)" d="M33.446,21.284l-0.901-0.788h7.996l-0.901,0.788H33.446z" />
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="20.4397" y1="49.7749" x2="20.4397" y2="48.874">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" d="M2.027,49.775v-0.901h36.824v0.901H2.027z" />
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="20.4397" y1="55.1802" x2="20.4397" y2="54.2793">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_33_)" d="M2.027,55.18v-0.901h36.824v0.901H2.027z" />
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="20.4397" y1="79.0547" x2="20.4397" y2="78.1533">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_34_)" d="M2.027,79.055v-0.901h36.824v0.901H2.027z" />
            <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="20.4397" y1="84.459" x2="20.4397" y2="83.5586">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_35_)" d="M2.027,84.459v-0.9h36.824v0.9H2.027z" />
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="20.0447" y1="81.3066" x2="20.8337" y2="81.3066">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_36_)" d="M20.834,83.559l-0.395,0.45l-0.395-0.45v-4.504l0.395-0.451l0.395,0.451V83.559z" />
            <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="20.0447" y1="86.7109" x2="20.8337" y2="86.7109">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_37_)" d="M20.834,89.414h-0.789v-4.955l0.395-0.45l0.395,0.45V89.414z" />
            <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="20.0447" y1="51.9712" x2="20.8337" y2="51.9712">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_38_)" d="M20.834,54.224l-0.395,0.45l-0.395-0.45V49.72l0.395-0.451l0.395,0.451V54.224z" />
            <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="20.0447" y1="57.4043" x2="20.8337" y2="57.4043">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_39_)" d="M20.834,60.135h-0.789v-5.011l0.395-0.45l0.395,0.45V60.135z" />
            <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="12.8938" y1="21.2837" x2="12.8938" y2="20.4956">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_40_)" d="M0.338,21.284v-0.788H25.45v0.788H0.338z" />
            <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="24.5496" y1="23.5928" x2="25.45" y2="23.5928">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_41_)" d="M25.45,26.689h-0.9v-5.406l0.9-0.788V26.689z" />
            <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="0.3376" y1="26.126" x2="1.239" y2="26.126">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_42_)" d="M1.239,31.757H0.338V20.496l0.901,0.788V31.757z" />
            <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="16.2166" y1="26.3232" x2="17.1169" y2="26.3232">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_43_)" d="M17.117,31.757h-0.9V21.284l0.44-0.394l0.46,0.394V31.757z" />
            <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="8.7278" y1="26.6895" x2="8.7278" y2="25.9009">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_44_)" d="M1.239,26.689l-0.451-0.393l0.451-0.396h14.978l0.45,0.396l-0.45,0.393H1.239z" />
            <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="21.0583" y1="26.6895" x2="21.0583" y2="25.9009">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_45_)" d="M17.058,26.689l-0.392-0.393l0.45-0.396h7.489l0.844,0.789H17.058z" />
            <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="56.4187" y1="89.6387" x2="57.2068" y2="89.6387">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_46_)" d="M57.207,95.27h-0.788V84.91l0.788-0.901V95.27z" />
            <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="48.6765" y1="90.3154" x2="48.6765" y2="89.5273">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_47_)" d="M40.541,90.315v-0.788h15.878l0.395,0.33l-0.395,0.458H40.541z" />
            <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="40.5408" y1="92.793" x2="41.3289" y2="92.793">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_48_)" d="M41.329,101.576h-0.788V84.009h0.788V101.576z" />
            <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="47.6355" y1="92.793" x2="48.4236" y2="92.793">
                <stop offset="0" style="stop-color:#CC9933" />
                <stop offset="0.5" style="stop-color:#FFCC66" />
                <stop offset="1" style="stop-color:#CC9933" />
            </linearGradient>
            <path fill="url(#SVGID_49_)" d="M48.424,101.576h-0.788V84.009h0.788V101.576z" />
        </g>
        <g id="Group_FrontStairs">
            <path fill="#231F20" d="M49.324,37.162h0.338v25.113h-0.338V37.162z" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,60.586h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,57.32h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,53.941h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,50.563h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,59.009h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,55.631h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,52.252h7.095" />
            <path fill="#231F20" d="M56.757,49.324h0.45v12.951h-0.45V49.324z" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,93.693h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,90.315h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,86.938h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,83.671h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,80.293h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,92.004h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,88.626h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,85.36h7.095" />
            <path fill="#231F20" stroke="#4C4C4C" stroke-width="0.25" d="M49.662,81.982h7.095" />
            <path fill="#231F20" d="M49.324,71.847h0.338v23.536h-0.338V71.847z" />
            <path fill="#231F20" d="M49.324,66.441h0.338v4.617h-0.338V66.441z" />
            <path fill="#231F20" d="M56.757,78.604h0.45v16.779h-0.45V78.604z" />
        </g>
        <g id="Group_FrontPlatform">
            <path fill="#7F7F7F" d="M40.541,49.324h16.666v-1.689H40.541V49.324z" />
            <path fill="#7F7F7F" d="M40.541,78.604h16.666v-1.689H40.541V78.604z" />
        </g>
        <g id="Group_OuterMetalFrame">
            <path fill="#7F7F7F" d="M2.027,112.049h36.824v-1.688H2.027V112.049z" />
            <path fill="#B2B2B2" d="M2.027,110.36h36.824v-0.788H2.027V110.36z" />
            <path fill="#B2B2B2" d="M2.027,61.036h36.824v-0.901H2.027V61.036z" />
            <path fill="#B2B2B2" d="M2.027,90.315h36.824v-0.901H2.027V90.315z" />
            <path fill="#7F7F7F" d="M0.338,112.049V31.757h40.203v80.292h-5.067v-1.688l3.378-3.378V95.27l-3.378-3.266v-1.688l3.378-3.378   V65.991l-3.378-3.267v-1.688l3.378-3.378V36.712l-3.378-3.378H5.405l-3.378,3.378v20.946l3.378,3.378v1.688l-3.378,3.267v20.946   l3.378,3.378v1.688L2.027,95.27v11.713l3.378,3.378v1.688H0.338z" />
            <path fill="#7F7F7F" d="M38.851,109.121l-0.45,0.451l-18.019-7.996l-17.905,7.996l-0.45-0.451l17.567-7.995L2.027,93.243l0.45-0.45   l17.905,7.995l18.019-7.995l0.45,0.45l-17.567,7.883L38.851,109.121z" />
            <path fill="#7F7F7F" d="M38.401,59.797l-0.451,0.338l-17.567-12.5l-17.905,12.5l-0.45-0.338l18.018-12.612L2.027,34.234l0.45-0.451   l17.905,12.951l18.019-12.951l0.45,0.451l-18.018,12.95L38.401,59.797z" />
            <path fill="#7F7F7F" d="M38.851,89.076l-0.45,0.338l-18.019-12.5l-17.905,12.5l-0.45-0.338l18.018-12.612l-18.018-12.5l0.45-0.45   l17.905,12.5l17.567-12.5l0.451,0.45l-17.567,12.5L38.851,89.076z" />
            <path fill="#7F7F7F" d="M2.027,62.725h36.824v-1.688H2.027V62.725z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.453125, 139.0, 0.16, 0.546875, 115.0, 0.46, 0.796875, 51.0, 0.88, 0.8072916666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.453125, 139.0, 0.16, 0.546875, 115.0, 0.46, 0.796875, 51.0, 0.88, 0.8072916666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.453125, 139.0, 0.16, 0.546875, 115.0, 0.46, 0.796875, 51.0, 0.88, 0.8072916666666666, 0.0, 1.0, 0.6770833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.6666666666666666, 0.0, 0.45, 0.4427083333333333, 141.0, 0.53, 0.5729166666666666, 109.0, 0.7, 0.890625, 27.0, 0.77, 0.9583333333333334, 0.0, 1.0, 0.6666666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_43_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_44_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_46_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_47_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_48_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.gradients,"SVGID_49_",[0.0, 0.9375, 15.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.9375, 15.0]);
		this.fillGradient(this.fills,"Group_InnerMetalFrame",[0.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_TBD",[0.0, 0.25, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.3958333333333333, 0.0, 4.0, 0.7916666666666666, 0.0, 5.0, 0.7916666666666666, 0.0, 6.0, 0.5885416666666666, 0.0, 7.0, 0.5885416666666666, 0.0, 8.0, 0.25, 0.0, 9.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Components",[0.0, 0.25, 0.0, 1.0, 0.25, 0.0, 2.0, 0.25, 0.0]);
		this.fillGradient(this.fills,"Group_ControlBox",[0.0, 0.9895833333333334, 0.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_BackPlatform",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_BackStairs",[0.0, 0.25, 0.0, 1.0, 0.25, 0.0, 2.0, 0.25, 0.0, 3.0, 0.25, 0.0, 4.0, 0.25, 0.0, 5.0, 0.25, 0.0, 6.0, 0.25, 0.0, 7.0, 0.25, 0.0, 8.0, 0.25, 0.0, 9.0, 0.25, 0.0, 10.0, 0.25, 0.0, 11.0, 0.25, 0.0, 12.0, 0.25, 0.0, 13.0, 0.25, 0.0, 14.0, 0.25, 0.0, 15.0, 0.25, 0.0, 16.0, 0.25, 0.0, 17.0, 0.25, 0.0, 18.0, 0.25, 0.0, 19.0, 0.25, 0.0, 20.0, 0.25, 0.0, 21.0, 0.25, 0.0, 22.0, 0.25, 0.0, 23.0, 0.25, 0.0, 24.0, 0.25, 0.0, 25.0, 0.25, 0.0, 26.0, 0.25, 0.0]);
		this.fillGradient(this.fills,"Group_TBD1",[0.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_FrontStairs",[0.0, 0.25, 0.0, 1.0, 0.25, 0.0, 2.0, 0.25, 0.0, 3.0, 0.25, 0.0, 4.0, 0.25, 0.0, 5.0, 0.25, 0.0, 6.0, 0.25, 0.0, 7.0, 0.25, 0.0, 8.0, 0.25, 0.0, 9.0, 0.25, 0.0, 10.0, 0.25, 0.0, 11.0, 0.25, 0.0, 12.0, 0.25, 0.0, 13.0, 0.25, 0.0, 14.0, 0.25, 0.0, 15.0, 0.25, 0.0, 16.0, 0.25, 0.0, 17.0, 0.25, 0.0, 18.0, 0.25, 0.0, 19.0, 0.25, 0.0, 20.0, 0.25, 0.0]);
		this.fillGradient(this.fills,"Group_FrontPlatform",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_OuterMetalFrame",[0.0, 0.9895833333333334, 0.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.9895833333333334, 0.0, 5.0, 0.9895833333333334, 0.0, 6.0, 0.9895833333333334, 0.0, 7.0, 0.9895833333333334, 0.0, 8.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
import { Property } from "./property";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";


export class SwitchControlProperty extends Property{
   
       
    public getCurrentBooleanValue(tagnames:string[]):boolean{
        const tag:tag = this.getTag(tagnames);
        if (tag==null || tag.tagvalue==null) return false;
        //console.log("tag.value=",tag.tagvalue)
        if (tag==null) return false;
        return tag.tagvalue==='true' || +tag.tagvalue==1;
    }

    public setClick():void{
        const tag:tag = tagsModele.getTag(this.path);
        if (tag==null || tag.tagvalue==null) return;
        if (tag.tagvalue==='true' || tag.tagvalue==='1')
            tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0", datetime:0});
        else
            tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"1", datetime:0});
    }

    public setReleased():void{
        const tag:tag = tagsModele.getTag(this.path); 
        if (tag==null) return;
        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0",datetime:0});
    }
}

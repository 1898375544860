import { ObjectView } from "./object";
import { TextView } from "./text";
import Konva from "konva";
import { HistoryDatabase } from "../historydatabase";
import authModule from "@/store/auth.module";
import historydbtablerequest from "@/model/requests/historydbtablerequest";
import tagsService from "@/services/tags.service";
import historyrow from "@/model/historyrow";
import historydbtabledialogboxModule from "@/store/historydbtabledialogbox.module";
import { Value } from "../value";
import screensModule from "@/store/screens.module";

export class HistoryTable extends ObjectView{
    historydbname:string;
    title:string;
    fontsize:number;
    datecolumnwidth:number;
    timecolumnwidth:number;
    othercolumnwidth:number;
    decimalpos:number;
    autorefresh:boolean;
    orderby:number;
    type:number;
    timeformat:string;
    usetitle:boolean;
    historydb:HistoryDatabase;

    begin:number;
    end:number;
    x:number;
    y:number;

    rows:historyrow[]

    beginrow=0;

    public drawObject():void{
       
       if (this.begin==undefined)  this.begin=Date.now()-60*60*1000;
       if (this.end==undefined || this.end==0) this.end=Date.now();
       


         /*if((this.begin==0 || this.begin==undefined) && (this.end==0 || this.end==undefined)){
             this.end = Date.now()
             this.begin = Date.now()-60*60*1000; 
         }*/
         if (authModule.currentUser!=null){
          
            const request:historydbtablerequest = {token:authModule.currentUser.accessToken, 
               name:this.historydbname, begindate:this.begin, enddate:this.end, historydbname:this.historydbname, decimalpos:this.decimalpos,
            orderby:this.orderby, type:this.type,timeformat:btoa(this.timeformat), id:screensModule.screendevid};
            
            tagsService.gethistorydbtable(request,(data:string)=>{
             //console.log(data);
                  
             this.rows =JSON.parse(data);
             //console.log(this.rows)
           
             this.beginrow=0;
             this.drawHistoryTable();
           }, 
             (data:string)=>{
                 console.log(data);
              })
       }
    }
    public drawHistoryTable():void{
        super.initObject()
        this.x = 0
        this.y=0
        if (this.usetitle){
            const title = new TextView()
            title.width = this.width
            title.height = this.height/10
            title.text = this.title
            title.fontsize = this.fontsize
            title.posx = 0
            title.posy = 0
            title.textplacement = 0
            title.textcolor = '0x000000FF'
            title.useborder = false
            title.bordercolor = null
            title.linewidth = 0
            title.fill = false
            title.fillcolor = '0xFFFFFF00'
            title.underline = false
            title.initLayer(this.layer)
            title.drawObject()
            this.node.add(title.rotatednode)
            this.y+=this.height/10;
        }
      
            this.drawTitle(this.datecolumnwidth, 'Date')
            this.x+=this.datecolumnwidth
            if (this.type==0){
                this.drawTitle(this.timecolumnwidth, 'Time')
                this.x+=this.timecolumnwidth
            }
        if (this.historydb!=null){
             this.historydb.ingredients.forEach((ingredient)=>{
                this.drawTitle(this.othercolumnwidth, ingredient.ingredientname+(ingredient.unit.length==0?"":"("+ingredient.unit+")"))
                this.x+=this.othercolumnwidth
            })
        }
        this.y+=this.fontsize+5
        this.x=0;
        let numrow = 0;
        this.rows.forEach((row)=>{
            numrow++;
            if (numrow<this.beginrow)
                return;
            if (this.y+this.fontsize+5>this.height)
             return;
            
            this.drawRow(row)
        });
        this.node.on('mousedown touchstart',()=>{  
            historydbtabledialogboxModule.setHistoryTable(this)  
            historydbtabledialogboxModule.setHistoryDBnamestring(this.historydbname)
            historydbtabledialogboxModule.setDecimalpos(this.decimalpos)
            historydbtabledialogboxModule.setType(this.type)
            historydbtabledialogboxModule.setOrderby(this.orderby)
            historydbtabledialogboxModule.setTimeFormat(this.timeformat)
            historydbtabledialogboxModule.setHistoryTableDialogEnabled(true)
            historydbtabledialogboxModule.setIngredients(this.historydb.ingredients)
      });
        this.node.on('wheel', (e)=> {
            this.beginrow+=e.evt.deltaY/(3*this.fontsize+3)
            if(this.beginrow<0)
                this.beginrow=0
            if (this.beginrow>this.rows.length-2)
                this.beginrow=this.rows.length-2
            console.log("beginrow="+this.beginrow)
        
            setTimeout(()=>{
                this.drawHistoryTable()
            }, 200);
            //this.drawEventTable();
        })
       
    }

    public setField(field:string, value:Value){
        super.setField(field, value)
        const timems = Date.now()
        switch(field){
            case "title":
            case "timeformat": {this[field] = value.value;this.rotatednode=null;break;}

            case "fontsize":
            case "datecolumnwidth":
            case "timecolumnwidth":
            case "othercolumnwidth":
            case "decimalpos":  {this[field] = Math.round(value.value);this.rotatednode=null;break;}

            case "usetitle":
            case "autorefresh": {
                if(value.value == 0 || value.value == '0' || value.value==false || value.value.toLowerCase()=='false'){
                    this[field]= false
                    this.rotatednode=null;
                }else if(value.value == 1 || value.value == '1' || value.value==true || value.value.toLowerCase()=='true'){
                    this[field]= true 
                    this.rotatednode=null;
                } 
                break;}

                case "orderby":
                case "type":  {this[field] = Math.round(value.value);this.rotatednode=null;break;}

                case "begin": {
                    this[field] = timems-Math.round(value.value*60*1000);
                    break;}
    
                case "end":{
                    if (value.value==null || value.value == 0) this.end = Date.now();
                    this[field] = timems - Math.round(value.value*60*1000); 
                    break;
                }
                case "begindatetime": {
                    if (value.value==null) this.begin = 0;
                    this.begin = value.value;
                    break;
                }
                case "enddatetime": {
                    if (value.value==null) this.end = Date.now();
                    this.end = value.value;
                    break;
                }
                case "historydbname":
        }
        //this.drawObject();
    }

    public getField(field:string):Value{
        const value = super.getField(field)
        const timems = Date.now()
        switch(field){
            case "title":
            case "timeformat": {value.datatype=7; value.value = this[field];break}

            case "fontsize":
            case "datecolumnwidth":
            case "timecolumnwidth":
            case "othercolumnwidth":
            case "decimalpos":  {value.datatype=3; value.value = this[field];break}

            case "usetitle":
            case "autorefresh": {value.datatype=0; value.value = (this[field]);break}

            case "orderby":
            case "type":  {value.datatype=1; value.value = this[field];break}

            case "begin":
            case "end": {value.datatype=3; 
                if(this[field] != null)value.value = Math.round((timems-this[field])/60000); 
                break}
            case "begindatetime": {value.datatype=4; value.value = this.begin; break}
            case "enddatetime": {value.datatype=4; value.value = this.end; break}	

            //case "historydbname": 
        }
        return value;
    }
    
    private drawRow(historyrow:historyrow){
       // console.log(historyrow)
        if (this.y+this.fontsize+5>this.height)
             return;
        this.x=0
        const row = new Konva.Group()
        const color ="0x000000FF"
        const bgcolor = "0xFFFFFFFF"
        this.drawRowCell(row,this.datecolumnwidth,historyrow.date,color,bgcolor)
        this.x+=this.datecolumnwidth
        if (this.type==0){
            this.drawRowCell(row,this.timecolumnwidth,historyrow.time,color,bgcolor)
            this.x+=this.timecolumnwidth
        }
      //  console.log(reciperow)
        
      
        this.historydb.ingredients.forEach((ing)=>{    
                this.drawRowCell(row,this.othercolumnwidth,historyrow.ingredients[ing.dbname],color,bgcolor)
                this.x+=this.othercolumnwidth
        })
       
          this.node.add(row)
        this.y+=this.fontsize+5
    }
    private drawRowCell(node:Konva.Group,width:number, text:string, textcolor:string, bgcolor:string){
        this.drawCell(node, width, text, textcolor,bgcolor)
    }
    private drawTitle(width:number, text:string){
        this.drawCell(this.node,width,text,'0x000000FF', '0xBBBBBBFF')
    }
    private drawCell(node:Konva.Group, width:number, text:string, textcolor:string, fillcolor:string){
        if (this.x>this.width) return;
        const cell = new TextView()
        cell.width = (this.x+width)>this.width?this.width-this.x:width
        cell.height = this.fontsize+5
        cell.text = text
        cell.fontsize = this.fontsize
        cell.posx = this.x;
        cell.posy = this.y
        cell.textplacement = 1;
        cell.textcolor = textcolor
        cell.useborder = true
        cell.bordercolor = '0x000000FF'
        cell.linewidth = 1
        cell.fill = true
        cell.fillcolor = fillcolor
        cell.underline = false
        cell.initLayer(this.layer)
        cell.drawObject()
        node.add(cell.rotatednode)
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { IndicatorProperty } from './properties/indicatorproperty';

import Konva from "konva";

import { ObjectView } from './object';
import { Value } from '../value';

export class OvalLamp extends ObjectView{   
        text:string;
        textcolor:string;
        fillcolor:string;
        type3d:number;

    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1

        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
              indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
          const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
          if (fillcolorproperty!=null) 
              fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        
        const ellipseout = new Konva.Ellipse({
          x: this.width/2,
          y: this.height/2,
          radiusX: this.width/2,
          radiusY: this.height/2,
        });
        if (this.type3d==0){
          ellipseout.fillLinearGradientStartPoint({ x: 0, y: -this.height/2 });
          ellipseout.fillLinearGradientEndPoint({ x: 0, y: this.height/2 });
          ellipseout.fillLinearGradientColorStops([0, 'white', 
          1, '#444444'])
        }else{
          ellipseout.fill('#666666')
        }
        this.node.add(ellipseout)
          

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, -(this.height/2-this.height/25-this.width*3/50), 0, this.height/2-this.height/25-this.width*3/50);
        gradient.addColorStop(0, '#444444')
        gradient.addColorStop(1, 'white')
             
              
        const ellipseinner = new Konva.Ellipse({
          x: this.width/2,
          y: this.height/2,
          radiusX: this.width/2-this.width/25-this.width*3/100,
          radiusY: this.height/2-this.height/25-this.width*3/100,
          stroke: this.type3d==0? gradient: 'white',
          strokeWidth: this.width*3/50
        });
        if (indicator){
          ellipseinner.fillRadialGradientStartPoint({x:0, y:0});
          ellipseinner.fillRadialGradientEndRadius(this.height/2)
          ellipseinner.fillRadialGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2,0.85)]);
          ellipseinner.fillPriority('radial-graident')

        }else if(this.type3d==0){
          ellipseinner.fillLinearGradientStartPoint({ x: 0, y: -(this.height/2-this.height/25-this.width*3/100) });
          ellipseinner.fillLinearGradientEndPoint({ x: 0, y: this.height/2-this.height/25-this.width*3/100 });
          ellipseinner.fillLinearGradientColorStops([0, 'white', 
          0.5, fillcolor2,
          1, ColorUtils.darkColor(fillcolor2,0.85)]);
        }else{
          ellipseinner.fill(fillcolor2)
        }
        this.node.add(ellipseinner); 
          
        let text = this.text;
        const textprop = this.properties['textinput']
        if (textprop!=null){
          const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
          if (textinputproperty!=null)
              text = textinputproperty.getCurrentText(this.tagnames, this.text);
        }

        let textcolor = this.textcolor;
        const textcolorprop = this.properties['textcolor'];
        if (textcolorprop!=null){	
          const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
          if (textcolorproperty!=null) 
            textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
        }
            
        const Text = new Konva.Text({
          x: this.width*6/50,
          y: 0, 
          verticalAlign:"middle",
          width: this.width-this.width*12/50,
          height: this.height,
          text: text,
          fontSize: this.height*2/5,
          fontFamily: 'Roboto',
          fontStyle: "normal",
          align:'center',
          fill: ColorUtils.convertformat(textcolor),
        });

        if(this.type3d==0){
          Text.shadowColor('black'),
          Text.shadowBlur(0),
          Text.shadowOffset({ x: 1, y: 1 })
        }

        this.node.add(Text)                    
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "textcolor":
          case "fillcolor":
          case "text":  {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "textcolor":
          case "fillcolor":
          case "text":  {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }
   
}
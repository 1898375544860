import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { RotationIndicatorProperty } from "./properties/rotationindicatorproperty";
import Konva from "konva";
import { Fan } from './fan';


export class FanRound extends Fan{
    public drawObject():void{

		super.initObject();
    if(this.type3d != 0) this.type3d = 1 
		let fillcolor = '0xffffff00';
                 
    fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
  let speed = 0
  let indicator = false;
  const rotationprop = this.properties['rotationindicator']
  if(rotationprop!=null){
    const rotationIndicatorProperty: RotationIndicatorProperty = Object.assign(new RotationIndicatorProperty(), rotationprop)
    if(rotationIndicatorProperty!=null) {
      speed = rotationIndicatorProperty.speed;
      indicator = rotationIndicatorProperty.CheckIndicator(this.tagnames)
    }
  }
    const ellipse = new Konva.Ellipse({
        x: this.width/2,
        y: this.height/2,
        radiusX: this.width/2,
        radiusY: this.height/2
    })  
    if(this.type3d==0){
        ellipse.fillLinearGradientStartPoint({ x: 0, y: 0});
        ellipse.fillLinearGradientEndPoint({ x: 0, y: this.height/2});
        ellipse.fillLinearGradientColorStops([0, 'white',
            1, '#444444'])
    }else{
        ellipse.fill('#CCCCCC')
        ellipse.stroke('black')
        ellipse.strokeWidth(1)
    }  
    this.node.add(ellipse)

    const ellipse12 = new Konva.Ellipse({
        x: this.width/2,
        y: this.height/2,
        radiusX: this.width/2-this.width/25,
        radiusY: this.height/2-this.height/25
    })  
    if(this.type3d==0){
        ellipse12.fillLinearGradientStartPoint({ x: 0, y: 0});
        ellipse12.fillLinearGradientEndPoint({ x: 0, y: this.height/2-this.height/25});
        ellipse12.fillLinearGradientColorStops([0, '#444444',
            1, 'white']) 
        this.node.add(ellipse12)
    }

    const ellipse2 = new Konva.Ellipse({
        x: this.width/2,
        y: this.height/2,
        radiusX: this.width/2-this.width/10+this.width/50,
        radiusY: this.height/2-this.height/10+this.height/50,
        fill: 'black'
    })  
    this.node.add(ellipse2) 
    
       const node1 = new Konva.Group()
       for(let i=0; i<360; i+=45){
          const wing = new Fan().drawWing(node1, this.width*4/5, this.height*4/5, fillcolor2, this.type3d)
          const x =
            this.width/2+
            (this.width/2-this.width*2/25-this.width/2)* Math.cos(i*Math.PI/180) -
            (this.height/10-this.height/2)* Math.sin(i*Math.PI/180);
            const y =
            this.height/2+
            (this.width/2-this.width*2/25-this.width/2)* Math.sin(i*Math.PI/180) +
            (this.height/10-this.height/2)* Math.cos(i*Math.PI/180);
          wing.position({x:x, y: y})
          wing.rotation(i)
          node1.add(wing)
        }

        const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: this.width/10*4/5,
            stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
            strokeWidth: 1,
            fill: fillcolor2
        })
        node1.add(circle)

        this.node.add(node1)
        
        if (indicator){
           node1.position({x:this.width/2, y:this.height/2})
           node1.offset({
                x: this.width/2,
                 y: this.height/2,
           })
        const angle = this.rotation==0?360:-360;
        const anim = new Konva.Animation(function (frame) {
          //console.log(frame.time)
        const angleDiff =angle* (frame.time ) / speed;
        node1.rotation(angleDiff);
        }, this.layer);
      anim.start();
    }

}

}
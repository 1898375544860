import {CatmullRomSpline} from './catmullromspline';
import {Point} from './point';

export class CatmullRom{
    splineXValues: CatmullRomSpline;
    splineYValues: CatmullRomSpline;
    p0: Point;
    p1: Point;
    p2: Point;
    p3: Point;

    constructor(point0:Point,point1:Point, point2:Point, point3:Point){
        this.p0 = point0;
        this.p1 = point1;
        this.p2 = point2;
        this.p3 = point3;
        this.splineXValues = new CatmullRomSpline(this.p0.x, this.p1.x, this.p2.x, this.p3.x);
        this.splineYValues = new CatmullRomSpline(this.p0.y, this.p1.y, this.p2.y, this.p3.y);   
    }

    public q(T:number){ 
        return new Point(this.splineXValues.q(T), this.splineYValues.q(T))  
    }

}



import { SVGObject } from "../svgobject";

export class SVGTank16 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.79,1.438c1.058,1.16,4.886,2.02,9.459,2.02   c4.575,0,8.403-0.86,9.46-2.02" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="56.25" x2="112.5" y2="56.25">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M55.584,0C31.505,0,0,10.188,0.078,28.197L0,29.835v74.442c11.253,5.828,33.02,8.223,55.517,8.223   c25.442,0,49.63-1.256,56.983-9.259V28.197v-2.009C112,9.875,83.595,0,55.584,0z" />
            <radialGradient id="SVGID_2_" cx="58.6074" cy="9.0366" r="55.1094" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M112.5,24.418C110.543,11.236,86.699,0,57.608,0C27.511,0,0.554,13.058,0.073,26.842   c0,4.701,24.547,8.837,54.916,8.837c29.806,0,56.528-4.325,57.511-8.93V24.418z" />
        </g>
        <g id="Group_TankLadder">
            <path fill="#4C4C4C" d="M20.428,31.074v4.316h-6.618v-4.316h-2.014V112.5h2.014v-4.316h6.618v4.316h2.014V31.074H20.428z    M20.428,106.17h-6.618v-4.316h6.618V106.17z M20.428,99.553h-6.618v-4.316h6.618V99.553z M20.428,93.223h-6.618v-4.316h6.618   V93.223z M20.428,86.893h-6.618v-4.316h6.618V86.893z M20.428,80.275h-6.618v-4.316h6.618V80.275z M20.428,73.945h-6.618v-4.316   h6.618V73.945z M20.428,67.327h-6.618v-4.028h6.618V67.327z M20.428,60.998h-6.618v-4.316h6.618V60.998z M20.428,54.667h-6.618   v-4.316h6.618V54.667z M20.428,48.05h-6.618v-4.316h6.618V48.05z M20.428,41.72h-6.618v-4.315h6.618V41.72z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_TankLadder",[0.0, 0.5885416666666666, 0.0]);
		
        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'

import { FillColorProperty } from "./properties/fillcolorproperty";

import Konva from "konva";
import { TwoStateObject } from "./twostateobject";

export class ValveISA extends TwoStateObject{
  

    public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00';
        
               
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
            
        const pipe = new Konva.Line({
                points: [0, 0,
                this.width, this.height,
                this.width, 0,
                0, this.height,],
                closed: true,
                stroke: 'black',
                strokeWidth:1
              })
              
              if(this.type3d==0){
                
                    pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
                    pipe.fillLinearGradientEndPoint({ x: 0, y: this.height});
                    pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                    0.5, fillcolor2,
                    1, ColorUtils.darkColor(fillcolor2, 0.5)])
              }else{
                pipe.fill(fillcolor2)
              }
              this.node.add(pipe)
        
}
}
import { SVGObject } from "../svgobject";

export class SVGMaterial8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 43.582" enable-background="new 0 0 112.5 43.582" xml:space="preserve">
        <g id="Group_Rear_Part">
            <path fill="#4C4C4C" d="M96.734,8.446l4.955,3.604v19.369l-4.955,2.478V8.446z" />
            <path fill="#333333" d="M2.478,28.942h91.779v4.954H2.478V28.942z" />
        </g>
        <g id="Group_Stand">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M0,4.843h2.478v38.738H0V4.843z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,4.843h2.478v38.738h-2.478V4.843z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,33.896h74.888v9.685H2.478V33.896z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,36.149h74.888v4.955H2.478V36.149z" />
        </g>
        <g id="Group_Dryer">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="48.3677" y1="28.9421" x2="48.3677" y2="4.843">
                <stop offset="0" style="stop-color:#8C8C8C" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#8C8C8C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M2.478,4.843h91.779v24.1H2.478V4.843z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M18.131,8.446v15.766" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M22.86,13.176v13.289" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M60.473,9.572V22.86" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M73.762,6.081v9.572" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M70.158,15.653v10.812" />
        </g>
        <g id="Group_Motor_Stand">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M77.365,31.419h24.324v12.162H77.365V31.419z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M77.365,34.347h24.324v6.081H77.365V34.347z" />
        </g>
        <g id="Group_Inlet_Outlet">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M94.257,1.239H83.445v3.604h10.812V1.239z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M13.176,1.239H2.478v3.604h10.698V1.239z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M0,0h15.653v1.239H0V0z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M80.969,0h15.766v1.239H80.969V0z" />
        </g>
        <g id="Group_Axial">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="107.0947" y1="33.8962" x2="107.0947" y2="8.4465">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.5" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M101.689,8.446H112.5v25.45h-10.811V8.446" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="82.7695" y1="30.2927" x2="82.7695" y2="16.7795">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#D9D9D9" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M78.491,16.779h8.558v13.514h-8.558V16.779z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="92.4551" y1="30.2927" x2="92.4551" y2="16.7795">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#D9D9D9" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,16.779H97.86v13.514H87.049V16.779z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="99.7754" y1="30.2927" x2="99.7754" y2="16.7795">
                <stop offset="0" style="stop-color:#D9D9D9" />
                <stop offset="0.5" style="stop-color:#FAFAFA" />
                <stop offset="1" style="stop-color:#D9D9D9" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M97.86,16.779l3.829,2.478v8.559l-3.829,2.478V16.779z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,18.131H97.86v1.126H87.049V18.131z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,20.608H97.86v1.126H87.049V20.608z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,22.86H97.86v1.352H87.049V22.86z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,25.338H97.86v1.127H87.049V25.338z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M87.049,27.815H97.86v1.127H87.049V27.815z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M80.969,30.293H97.86v1.126H80.969V30.293z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.90625, 23.0, 0.5, 0.25, 191.0, 1.0, 0.90625, 23.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3958333333333333, 0.0, 0.5, 0.703125, 75.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.3020833333333333, 177.0, 0.5, 0.046875, 243.0, 1.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3020833333333333, 177.0, 0.5, 0.046875, 243.0, 1.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.3020833333333333, 177.0, 0.5, 0.046875, 243.0, 1.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Motor_Stand",[0.0, 0.40625, 151.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Inlet_Outlet",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Motor",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.7916666666666666, 0.0, 4.0, 0.7916666666666666, 0.0, 5.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
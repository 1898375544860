import { SVGObject } from "../svgobject";

export class SVGWater12 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 81.756" enable-background="new 0 0 112.5 81.756" xml:space="preserve">
        <g id="Group_Digester">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.583" y1="44.4404" x2="111.666" y2="44.4404">
                <stop offset="0.01" style="stop-color:#661900" />
                <stop offset="0.5" style="stop-color:#E5986D" />
                <stop offset="1" style="stop-color:#661900" />
            </linearGradient>
            <rect x="0.583" y="23.67" fill="url(#SVGID_1_)" width="111.083" height="41.541" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.6777" y1="70.085" x2="111.6406" y2="70.085">
                <stop offset="0.01" style="stop-color:#661900" />
                <stop offset="0.5" style="stop-color:#E5986D" />
                <stop offset="1" style="stop-color:#661900" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0.678,58.413c0,4.844,0,10.377,0,15.22c10.676,4.843,31.21,8.123,54.802,8.123   c24.634,0,45.94-3.575,56.161-8.771c0-4.642,0-9.28,0-13.918" />
            <path fill="#661900" d="M0.5,16.464c0,3.801,0,8.144,0,11.944c10.703,3.801,31.288,6.375,54.94,6.375   c24.697,0,46.054-2.807,56.302-6.885c0-3.642,0-7.282,0-10.923" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.5996" y1="22.6235" x2="111.8418" y2="22.6235">
                <stop offset="0.01" style="stop-color:#661900" />
                <stop offset="0.5" style="stop-color:#E5CEBF" />
                <stop offset="1" style="stop-color:#661900" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M0.6,13.464c0,3.801,0,8.144,0,11.944c10.703,3.801,31.288,6.375,54.94,6.375   c24.697,0,46.054-2.807,56.302-6.885c0-3.642,0-7.282,0-10.923" />
            <ellipse fill="#F2DBCC" stroke="#4C4C4C" stroke-width="0.25" cx="56.194" cy="13.964" rx="55.63" ry="6.982" />
            <ellipse fill="#826B5C" stroke="#4C4C4C" stroke-width="0.25" cx="56.193" cy="13.513" rx="46.283" ry="5.068" />
        </g>
        <g id="Group_Liquid">
            <ellipse fill="#596D8C" cx="56.419" cy="15.09" rx="43.355" ry="3.491" />
        </g>
        <g id="Group_Platform">
            <path fill="#826B5C" stroke="#4C4C4C" stroke-width="0.25" d="M72.41,53.153l9.234,2.477l24.999-3.604v-1.802l-24.999,3.04   l-9.234-2.477V53.153z" />
            <path fill="#E5CEBF" stroke="#4C4C4C" stroke-width="0.25" d="M72.41,50.9l9.234,2.364l24.999-2.928l-9.233-1.801L72.41,50.9z" />
        </g>
        <g id="Group_Stair_Rails">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M91.499,10.269c12.519-1.029,20.513-2.594,20.513-4.346   c0-3.111-25.008-5.623-55.855-5.623C25.308,0.3,0.3,2.812,0.3,5.923c0,3.101,25.008,5.612,55.856,5.612   c6.75,0,13.223-0.12,19.213-0.341" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M91.153,16.241c12.485-1.012,20.458-2.547,20.458-4.268   c0-3.057-24.94-5.523-55.705-5.523c-30.766,0-55.706,2.467-55.706,5.523c0,3.045,24.94,5.512,55.706,5.512   c6.732,0,13.187-0.119,19.162-0.336" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M91.116,13.241c12.508-1.012,20.495-2.547,20.495-4.268   c0-3.057-24.985-5.523-55.805-5.523C24.985,3.45,0,5.917,0,8.974c0,3.045,24.985,5.512,55.807,5.512   c6.744,0,13.211-0.119,19.196-0.336" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M45.158,0.45v6.982" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M67.229,0.45v6.982" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M89.865,1.689v6.87" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M111.937,5.923v7.928" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M43.469,20.833v-9.347" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M68.919,20.833v-9.347" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M95.045,19.369V10.36" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M0.451,6.869v6.982" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M22.523,1.689v6.87" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M17.343,19.369V10.36" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,50.675V8.221" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M85.811,7.77v42.455" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,11.148l5.855-0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,15.766l5.855-0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,20.383l5.855-0.338" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,25.112l5.855-0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,29.729l5.855-0.451" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,34.347l5.855-0.451" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,38.964l5.855-0.451" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,43.693l5.855-0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M79.955,48.311l5.855-0.45" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M106.644,50.45v-9.347" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M81.756,53.265v-9.233" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M72.41,48.648l9.346,2.364l24.888-2.928l-8.671-1.802" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M72.41,45.157l9.346,2.365l24.888-2.928l-8.671-1.689" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M72.41,41.666l9.346,2.365l24.888-2.928l-8.671-1.689" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.3229166666666667, 0.0, 0.5, 0.7291666666666666, 69.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.3229166666666667, 0.0, 0.5, 0.7291666666666666, 69.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.3229166666666667, 0.0, 0.5, 0.375, 159.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Platform",[0.0, 0.8489583333333334, 0.0, 1.0, 0.375, 159.0]);
		
        super.drawObject();
    }
}
import { Property } from "./property";
import { ColorRange } from "./range/colorrange";
import tag from "@/model/tag";

export class ColorProperty extends Property{
    value:string;
    type:number;
    truecolor:string;
    falsecolor:string;
    colorrange:ColorRange[];

    public getColor(tagnames:string[], defaultcolor:string):string{
        const tag:tag = this.getTag(tagnames);
		if (tag==null) return defaultcolor;
        let color:string = this.falsecolor;
      
        switch (this.type)
          {
              case 0: if (+this.value==+tag.tagvalue)
                              color = this.truecolor;
                      break;
              case 1: if (+tag.tagvalue>=+this.value)
                            color = this.truecolor;
                      break;
              case 2:if (+tag.tagvalue<=+this.value)
                             color = this.truecolor;
                      break;
              case 3: if (+tag.tagvalue>+this.value)
                            color = this.truecolor;
                      break;
              case 4: if (+tag.tagvalue<+this.value)
                        color = this.truecolor;
                      break;
              case 5: if (+tag.tagvalue!=+this.value)
                         color = this.truecolor;
                      break;
              case 6:
                {
                    color = defaultcolor;
                for (const mrange of this.colorrange)
                 {
                     if (mrange.from<=+tag.tagvalue && +tag.tagvalue<=mrange.to){
                    color = mrange.color;
                     break;
                 }
                }
          }
          break;
              default: break;
          }
          return color;
    }
}
import { SVGObject } from "../svgobject";

export class SVGMedical13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" id="Group_x5F_Symbol" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 111.262" xml:space="preserve" enable-background="new 0 0 112.5 111.262">
        <g id="Group_Symbol">
        <path d="M40.09,0h32.207l-0.111,111.262H39.978L40.09,0z" fill="#CC0000" />
        <path d="M0,41.892l16.104-28.041L112.5,69.595L96.283,97.41L0,41.892z" fill="#CC0000" />
        <path d="M96.283,13.852l16.217,28.04L16.104,97.41L0,69.595L96.283,13.852z" fill="#CC0000" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
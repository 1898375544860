import { SVGObject } from "../svgobject";

export class SVGHvac21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 68.875" enable-background="new 0 0 112.5 68.875" xml:space="preserve">
        <g id="Group_Container">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="99.1709" y1="77.3584" x2="13.3306" y2="-8.4819">
                <stop offset="0" style="stop-color:#595959" />
                <stop offset="0.62" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M4.847,68.875L0,63.915V4.847L4.847,0h102.805l4.848,4.847   v59.068l-4.848,4.96H4.847z" />
        </g>
        <g id="Group_Condenser_sub">
            <path fill="#2E2E2E" d="M2.367,4.847h107.766v59.068H2.367V4.847z" />
        </g>
        <g id="Group_Pipes_Coils">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-64.3867" y1="77.0928" x2="-59.3037" y2="77.0928" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M10.578,55.876H5.495V12.368h5.083V55.876z" />
            
                <radialGradient id="SVGID_3_" cx="32.9351" cy="-137.8579" r="5.8638" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 149.7173 45.8119)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M11.463,6.661c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C11.504,10.4,11.463,6.661,11.463,6.661z" />
            
                <radialGradient id="SVGID_4_" cx="-56.6528" cy="-137.8486" r="5.8638" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -126.832 -45.3799)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" d="M11.413,6.661c2.906,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.105-0.696-0.393-0.864-0.892-0.91   C11.372,10.4,11.413,6.661,11.413,6.661z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-71.1777" y1="77.1016" x2="-66.1362" y2="77.1016" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M17.369,55.637h-5.042V12.59h5.042V55.637z" />
            
                <radialGradient id="SVGID_6_" cx="-57.9204" cy="-99.7905" r="5.8682" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -81.7776 114.7199)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" d="M18.213,61.389c2.906,0,5.962-2.002,5.962-5.966l-5.053-0.013c-0.106,0.695-0.394,0.863-0.893,0.91   C18.172,57.648,18.213,61.389,18.213,61.389z" />
            
                <radialGradient id="SVGID_7_" cx="34.2026" cy="-99.7803" r="5.868" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 118.2435 20.9472)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M18.263,61.388c-2.906,0-5.962-2.001-5.962-5.965l5.054-0.013c0.105,0.695,0.393,0.863,0.892,0.91   C18.305,57.648,18.263,61.388,18.263,61.388z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-77.8867" y1="77.2178" x2="-72.8037" y2="77.2178" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M24.078,55.501h-5.083V12.493h5.083V55.501z" />
            
                <radialGradient id="SVGID_9_" cx="46.2397" cy="-137.7358" r="5.8682" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 163.0948 59.479)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" d="M24.963,6.786c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C25.004,10.525,24.963,6.786,24.963,6.786z" />
            
                <radialGradient id="SVGID_10_" cx="-69.9575" cy="-137.7261" r="5.8682" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -113.2085 -58.798)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" d="M24.913,6.786c2.906,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.105-0.696-0.393-0.864-0.892-0.91   C24.872,10.525,24.913,6.786,24.913,6.786z" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-84.6777" y1="76.9766" x2="-79.6362" y2="76.9766" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M30.869,55.762h-5.042V12.715h5.042V55.762z" />
            
                <radialGradient id="SVGID_12_" cx="-71.2246" cy="-99.9126" r="5.8638" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -68.4005 128.3864)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" d="M31.713,61.514c2.906,0,5.962-2.002,5.962-5.966l-5.053-0.013c-0.106,0.695-0.394,0.863-0.893,0.91   C31.672,57.773,31.713,61.514,31.713,61.514z" />
            
                <radialGradient id="SVGID_13_" cx="47.5073" cy="-99.9033" r="5.8682" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 131.8675 7.5296)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" d="M31.763,61.513c-2.906,0-5.962-2.001-5.962-5.965l5.054-0.013c0.105,0.695,0.393,0.863,0.892,0.91   C31.805,57.773,31.763,61.513,31.763,61.513z" />
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-91.3867" y1="77.0928" x2="-86.3037" y2="77.0928" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M37.578,55.626h-5.083V12.618h5.083V55.626z" />
            
                <radialGradient id="SVGID_15_" cx="59.5449" cy="-137.6128" r="5.86" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 176.4719 73.1476)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" d="M38.463,6.911c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C38.504,10.65,38.463,6.911,38.463,6.911z" />
            
                <radialGradient id="SVGID_16_" cx="-83.2617" cy="-137.6035" r="5.8682" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -99.5856 -72.2156)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" d="M38.413,6.911c2.906,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.105-0.696-0.393-0.864-0.892-0.91   C38.372,10.65,38.413,6.911,38.413,6.911z" />
            
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-98.1777" y1="76.8516" x2="-93.1362" y2="76.8516" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_17_)" d="M44.369,55.887h-5.042V12.84h5.042V55.887z" />
            
                <radialGradient id="SVGID_18_" cx="-84.5288" cy="-100.0361" r="5.8641" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -55.0249 142.0545)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" d="M45.213,61.639c2.906,0,5.962-2.002,5.962-5.966l-5.053-0.013c-0.106,0.695-0.394,0.863-0.893,0.91   C45.172,57.898,45.213,61.639,45.213,61.639z" />
            
                <radialGradient id="SVGID_19_" cx="60.8125" cy="-100.0259" r="5.8556" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 145.4915 -5.889)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_19_)" d="M45.263,61.638c-2.906,0-5.962-2.001-5.962-5.965l5.054-0.013c0.105,0.695,0.393,0.863,0.892,0.91   C45.305,57.898,45.263,61.638,45.263,61.638z" />
            
                <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="-105.0117" y1="76.8428" x2="-99.9287" y2="76.8428" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_20_)" d="M51.203,55.876H46.12V12.868h5.083V55.876z" />
            
                <radialGradient id="SVGID_21_" cx="72.9727" cy="-137.3667" r="5.8556" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 189.8497 87.0662)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M52.088,7.161c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C52.129,10.9,52.088,7.161,52.088,7.161z" />
            
                <radialGradient id="SVGID_22_" cx="-96.6895" cy="-137.3574" r="5.8638" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -85.7131 -85.6332)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_22_)" d="M52.038,7.161c2.906,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.105-0.696-0.393-0.864-0.892-0.91   C51.997,10.9,52.038,7.161,52.038,7.161z" />
            
                <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="-111.8027" y1="76.6016" x2="-106.7612" y2="76.6016" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_23_)" d="M57.994,56.137h-5.042V13.09h5.042V56.137z" />
            
                <radialGradient id="SVGID_24_" cx="-97.957" cy="-100.2817" r="5.8682" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -41.6462 155.9732)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M58.839,61.889c2.906,0,5.962-2.002,5.962-5.966l-5.054-0.013c-0.105,0.695-0.393,0.863-0.893,0.91   C58.798,58.148,58.839,61.889,58.839,61.889z" />
            
                <radialGradient id="SVGID_25_" cx="74.2402" cy="-100.2715" r="5.8556" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 159.3635 -19.3071)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_25_)" d="M58.888,61.888c-2.905,0-5.961-2.001-5.961-5.965l5.054-0.013c0.104,0.695,0.392,0.863,0.892,0.91   C58.93,58.148,58.888,61.888,58.888,61.888z" />
            
                <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="-118.6367" y1="76.7178" x2="-113.5537" y2="76.7178" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_26_)" d="M64.828,56.001h-5.083V12.993h5.083V56.001z" />
            
                <radialGradient id="SVGID_27_" cx="86.4004" cy="-137.2446" r="5.86" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 203.3521 100.8586)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_27_)" d="M65.713,7.286c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C65.754,11.025,65.713,7.286,65.713,7.286z" />
            
                <radialGradient id="SVGID_28_" cx="-110.1167" cy="-137.2349" r="5.8682" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -71.9652 -99.176)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_28_)" d="M65.663,7.286c2.905,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.104-0.696-0.393-0.864-0.892-0.91   C65.621,11.025,65.663,7.286,65.663,7.286z" />
            
                <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="-125.4277" y1="76.4766" x2="-120.3867" y2="76.4766" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_29_)" d="M71.619,56.262h-5.041V13.215h5.041V56.262z" />
            
                <radialGradient id="SVGID_30_" cx="-111.3843" cy="-100.4038" r="5.8638" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -28.1442 169.765)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_30_)" d="M72.463,62.014c2.906,0,5.963-2.002,5.963-5.966l-5.053-0.013c-0.106,0.695-0.395,0.863-0.893,0.91   C72.422,58.273,72.463,62.014,72.463,62.014z" />
            
                <radialGradient id="SVGID_31_" cx="87.667" cy="-100.3945" r="5.8682" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 173.1113 -32.849)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_31_)" d="M72.514,62.013c-2.906,0-5.963-2.001-5.963-5.965l5.055-0.013c0.104,0.695,0.393,0.863,0.891,0.91   C72.555,58.273,72.514,62.013,72.514,62.013z" />
            
                <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="-132.2617" y1="76.4678" x2="-127.1787" y2="76.4678" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_32_)" d="M78.453,56.251H73.37V13.243h5.083V56.251z" />
            
                <radialGradient id="SVGID_33_" cx="99.8281" cy="-136.999" r="5.86" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 216.7304 114.7766)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_33_)" d="M79.338,7.536c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C79.379,11.275,79.338,7.536,79.338,7.536z" />
            
                <radialGradient id="SVGID_34_" cx="-123.5444" cy="-136.9893" r="5.8682" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -58.0933 -112.5941)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_34_)" d="M79.288,7.536c2.905,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.104-0.696-0.393-0.864-0.892-0.91   C79.246,11.275,79.288,7.536,79.288,7.536z" />
            
                <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="-139.0527" y1="76.2266" x2="-134.0117" y2="76.2266" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_35_)" d="M85.244,56.512h-5.041V13.465h5.041V56.512z" />
            
                <radialGradient id="SVGID_36_" cx="-124.812" cy="-100.6499" r="5.8682" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -14.7665 183.6836)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_36_)" d="M86.088,62.264c2.906,0,5.963-2.002,5.963-5.966l-5.053-0.013c-0.106,0.695-0.395,0.863-0.893,0.91   C86.047,58.523,86.088,62.264,86.088,62.264z" />
            
                <radialGradient id="SVGID_37_" cx="101.0947" cy="-100.6396" r="5.8638" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 186.9827 -46.2675)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_37_)" d="M86.139,62.263c-2.906,0-5.963-2.001-5.963-5.965l5.055-0.013c0.104,0.695,0.393,0.863,0.891,0.91   C86.18,58.523,86.139,62.263,86.139,62.263z" />
            
                <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="-145.8867" y1="76.3428" x2="-140.8037" y2="76.3428" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_38_)" d="M92.078,56.376h-5.083V13.368h5.083V56.376z" />
            
                <radialGradient id="SVGID_39_" cx="113.2559" cy="-136.8755" r="5.8556" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 230.2313 128.5705)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_39_)" d="M92.963,7.661c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C93.004,11.4,92.963,7.661,92.963,7.661z" />
            
                <radialGradient id="SVGID_40_" cx="-136.9722" cy="-136.8662" r="5.8638" gradientTransform="matrix(4.435394e-008 -1.0179 -1.0052 -4.407553e-008 -44.3445 -126.137)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_40_)" d="M92.913,7.661c2.905,0,5.962,2.001,5.962,5.965l-5.054,0.013c-0.104-0.696-0.393-0.864-0.892-0.91   C92.871,11.4,92.913,7.661,92.913,7.661z" />
            
                <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="-152.6777" y1="76.1016" x2="-147.6367" y2="76.1016" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_41_)" d="M98.869,56.637h-5.041V13.59h5.041V56.637z" />
            
                <radialGradient id="SVGID_42_" cx="-138.2397" cy="-100.7729" r="5.8724" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -1.2651 197.4769)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_42_)" d="M99.713,62.389c2.906,0,5.963-2.002,5.963-5.966l-5.053-0.013c-0.106,0.695-0.395,0.863-0.893,0.91   C99.672,58.648,99.713,62.389,99.713,62.389z" />
            
                <radialGradient id="SVGID_43_" cx="114.5225" cy="-100.7627" r="5.8638" gradientTransform="matrix(-4.435394e-008 1.0179 1.0052 4.407553e-008 200.7316 -59.8104)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_43_)" d="M99.764,62.388c-2.906,0-5.963-2.001-5.963-5.965l5.055-0.013c0.104,0.695,0.393,0.863,0.891,0.91   C99.805,58.648,99.764,62.388,99.764,62.388z" />
            
                <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="-159.5117" y1="76.2178" x2="-154.4287" y2="76.2178" gradientTransform="matrix(-1 0 0 -1 -53.8086 111.2148)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_44_)" d="M105.703,56.501h-5.083V13.493h5.083V56.501z" />
            
                <radialGradient id="SVGID_45_" cx="126.6816" cy="-136.7534" r="5.8682" gradientTransform="matrix(-4.435394e-008 -1.0179 1.0052 -4.407553e-008 243.7318 142.3608)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_45_)" d="M106.588,7.786c-2.906,0-5.962,2.001-5.962,5.965l5.053,0.013c0.106-0.696,0.394-0.864,0.893-0.91   C106.629,11.525,106.588,7.786,106.588,7.786z" />
            
                <radialGradient id="SVGID_46_" cx="-44.4927" cy="-99.9126" r="5.8638" gradientTransform="matrix(4.435394e-008 1.0179 -1.0052 4.407553e-008 -95.5254 101.1761)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#993300" />
                <stop offset="0.2" style="stop-color:#A63300" />
                <stop offset="0.52" style="stop-color:#C23300" />
                <stop offset="0.87" style="stop-color:#A53300" />
                <stop offset="1" style="stop-color:#993300" />
            </radialGradient>
            <path fill="url(#SVGID_46_)" d="M4.588,61.514c2.906,0,5.962-2.002,5.962-5.966l-5.053-0.013c-0.106,0.695-0.394,0.863-0.893,0.91   C4.547,57.773,4.588,61.514,4.588,61.514z" />
            
                <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="-95.5117" y1="91.4043" x2="-90.4287" y2="91.4043" gradientTransform="matrix(0 1 -1 0 95.1289 151.9688)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_47_)" d="M4.783,56.457v5.083H2.665v-5.083H4.783z" />
            
                <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="-144.1367" y1="-12.7827" x2="-139.0537" y2="-12.7827" gradientTransform="matrix(0 1 -1 0 95.1289 151.9688)">
                <stop offset="0.01" style="stop-color:#993300" />
                <stop offset="0.5" style="stop-color:#C23300" />
                <stop offset="1" style="stop-color:#993300" />
            </linearGradient>
            <path fill="url(#SVGID_48_)" d="M109.408,7.832v5.083h-2.993V7.832H109.408z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6927083333333334, 0.0, 0.62, 0.609375, 99.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_41_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_42_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_43_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_44_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_45_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_46_",[0.0, 0.5260416666666666, 0.0, 0.2, 0.5572916666666666, 0.0, 0.52, 0.6302083333333334, 0.0, 0.87, 0.5572916666666666, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_47_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_48_",[0.01, 0.5260416666666666, 0.0, 0.5, 0.6302083333333334, 0.0, 1.0, 0.5260416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Condenser_sub",[0.0, 0.3541666666666667, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGWater21 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 65.541" enable-background="new 0 0 112.5 65.541" xml:space="preserve">
        <g id="Group_Tank">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="56.1946" x2="112.3867" y2="56.1946">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <ellipse fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" cx="56.194" cy="56.194" rx="56.193" ry="9.347" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="32.4827" x2="112.3867" y2="32.4827">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <rect y="8.771" fill="url(#SVGID_2_)" width="112.387" height="47.424" />
            <ellipse id="inner" fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" cx="56.501" cy="54.347" rx="51.749" ry="6.577" />
            <rect x="7.25" y="18.021" fill="#666666" stroke="#4C4C4C" stroke-width="0.25" width="96.75" height="34.75" />
            <ellipse fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" cx="56.195" cy="51.859" rx="46.846" ry="5.012" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0.6133" y1="13.8445" x2="112.3867" y2="13.8445">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <ellipse fill="url(#SVGID_3_)" cx="56.5" cy="13.844" rx="55.887" ry="9.347" />
            <ellipse fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" cx="56.194" cy="9.347" rx="56.193" ry="9.347" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M108.133,55.069v-37.5l-5.18,1.688v33.938   c1.645,0.796,3.29,1.396,4.936,1.954L108.133,55.069z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M4.703,54.819v-37.5l5.18,1.688v33.938   c-1.645,0.796-3.29,1.396-4.936,1.954L4.703,54.819z" />
        </g>
        <g id="Group_Diffuser">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="92.3545" y1="49.2629" x2="91.8485" y2="46.3931">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M99.1,45.158l0.563-0.113l0.451,0.113l0.563,0.338l0.338,0.45   v0.563v0.563l-0.338,0.45l-0.563,0.338l-0.451,0.113l-16.217,2.815l-0.563-2.815L99.1,45.158z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="83.2988" y1="46.2395" x2="82.784" y2="43.3197">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M94.145,41.329l0.563-0.112l0.563,0.112l0.45,0.226   l0.338,0.563l0.113,0.563l-0.113,0.563l-0.338,0.451l-0.45,0.338l-0.563,0.112l-24.437,4.392l-0.563-2.815L94.145,41.329z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="71.2637" y1="42.6213" x2="70.7687" y2="39.8141">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.36,37.276l0.563-0.113l0.45,0.113l0.563,0.225   l0.338,0.563v0.45v0.563l-0.338,0.563l-0.563,0.226l-0.45,0.112l-30.969,5.519l-0.451-2.815L85.36,37.276z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="52.5293" y1="40.217" x2="51.9543" y2="36.9558">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M63.626,34.91l0.563-0.112l0.563,0.112l0.451,0.226   l0.338,0.563l0.112,0.563l-0.112,0.563l-0.338,0.451L64.752,37.5l-0.563,0.113l-25,4.955l-0.563-2.815L63.626,34.91z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="34.875" y1="38.2415" x2="34.3634" y2="35.3399">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M44.257,33.672l0.563-0.113l0.563,0.113l0.451,0.338   l0.338,0.563l0.112,0.45l-0.112,0.563l-0.338,0.563l-0.451,0.225l-0.563,0.113l-21.509,3.716l-0.563-2.815L44.257,33.672z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="56.7734" y1="40.387" x2="55.7342" y2="46.2809">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M24.437,34.685l-1.014-0.112l-2.14,0.788l-0.788,2.14   l0.788,2.027l2.14,0.788l64.528,11.6l1.013,0.225l1.126-0.225l1.014-0.676l0.676-1.014l0.225-1.014l-0.225-1.126l-0.676-1.014   l-1.014-0.676l-1.126-0.225L24.437,34.685z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="18.2227" y1="41.2336" x2="17.7041" y2="38.2927">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M22.86,37.5h0.563l1.014,0.338l0.45,1.014l-0.45,1.126   l-1.014,0.338l-10.36,1.689l-0.45,0.112l-1.126-0.45l-0.45-1.014l0.45-1.014l1.126-0.45L22.86,37.5z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="25.8242" y1="45.0618" x2="25.3026" y2="42.1035">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M38.063,39.865l0.563-0.112l1.014,0.45l0.451,1.014   l-0.451,1.126l-1.014,0.338l-25.563,4.617L12.5,47.41l-1.014-0.45l-0.45-1.014l0.45-1.014l1.014-0.45L38.063,39.865z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="38.5566" y1="48.6506" x2="38.0235" y2="45.627">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M54.167,42.793l0.563-0.112l1.014,0.45l0.451,1.014   l-0.451,1.126l-1.014,0.338l-32.32,5.855l-0.45,0.113l-1.126-0.451l-0.451-1.013l0.451-1.126l1.126-0.451L54.167,42.793z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="57.1777" y1="50.8694" x2="56.6729" y2="48.0063">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M69.707,45.721l0.563-0.112l1.013,0.45l0.451,1.014   l-0.451,1.126l-1.013,0.338l-26.126,4.617l-0.563,0.112l-1.014-0.45l-0.45-1.014l0.45-1.013l1.014-0.451L69.707,45.721z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="74.4629" y1="52.3274" x2="73.9603" y2="49.4768">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M81.982,48.086l0.563-0.112l1.014,0.45l0.45,1.014   l-0.45,1.014l-1.014,0.45l-16.104,2.815l-0.563,0.112l-1.014-0.45l-0.451-1.014l0.451-1.014l1.014-0.45L81.982,48.086z" />
            <circle fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" cx="88.964" cy="49.1" r="2.815" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M67.342,52.365l-0.449-1.014l-1.014-0.45l-1.014,0.45l-0.451,1.014   l0.451,1.014l1.014,0.45l1.014-0.45L67.342,52.365z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M45.045,51.802l-0.45-1.013l-1.014-0.451l-1.014,0.451l-0.45,1.013   l0.45,1.014l1.014,0.45l1.014-0.45L45.045,51.802z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M23.311,50l-0.338-1.013l-1.014-0.338l-1.126,0.338L20.496,50   l0.338,1.126l1.126,0.338l1.014-0.338L23.311,50z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M13.964,45.947l-0.45-1.014l-1.014-0.45l-1.014,0.45l-0.45,1.014   l0.45,1.014l1.014,0.45l1.014-0.45L13.964,45.947z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M13.964,40.654l-0.338-1.014L12.5,39.19l-1.014,0.45l-0.338,1.014   l0.338,1.014l1.014,0.45l1.126-0.45L13.964,40.654z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Tank",[0.0, 0.40625, 151.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.5885416666666666, 0.0, 3.0, 0.20833333333333334, 201.0, 4.0, 0.8072916666666666, 49.0, 5.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_Diffuser",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0, 2.0, 0.40625, 151.0, 3.0, 0.40625, 151.0, 4.0, 0.40625, 151.0, 5.0, 0.40625, 151.0]);

        super.drawObject();
    }
}


import {defineComponent,ref} from 'vue'
import AuthModule from '../store/auth.module'
import { useI18n } from 'vue-i18n';
import user from '../model/user'
import message from '../model/message'

export default defineComponent({
   
  
  setup(){ 
      const loading = ref(false);
      const message = ref("");
      const email = ref(null);
      const {t} = useI18n({useScope:'global'}) 
     
      const visibility = ref(false);
    return {
      loading,
      message,
      email,
      visibility,
    t}
  },
 
  computed: {
    loggedIn() {
      return AuthModule.isLoggedIn;
    }
  },
    
  created() {
      if (this.loggedIn) {
        this.$router.push("/home");
      }
   },
   methods:{
    
    isValidEmail (val:string) {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || this.t('profile.invalidemail');
  },
     handleReset(){
       this.loading = true;
        let data:user = {} as user;
        data.email = this.email;
       AuthModule.reset(data).then(
        (response) => {
          this.loading = false;
          console.log(response);
          console.log(response.body)
          this.message = (response.body as message).message;
          if (response.statusCodeValue==200)
            this.$router.push("/login")
            
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
     
      
    }
     
   }
   
})

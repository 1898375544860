import { SVGObject } from "../svgobject";

export class SVGHvac23 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Frame">
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M0,112.5V0h112.5l-9.459,2.252H9.347V109.91L0,112.5z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,0v112.5H0l9.347-2.365h93.694V2.252L112.5,0z" />
            <path fill="#D8E5E5" d="M105.518,109.91l2.253-107.658L112.5,0l-2.365,109.91H105.518z" />
            <path fill="#999999" d="M112.5,0v112.5h-2.365V0.45L112.5,0z" />
            <path fill="#FFFFFF" d="M105.518,109.91V2.252h2.253L105.518,109.91z" />
            <path fill="#BFCCCC" d="M9.347,109.91V2.252H6.869L2.252,109.91H9.347z" />
            <path fill="#FFFFFF" d="M2.252,103.041L6.869,9.347L2.252,49.212V103.041z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M2.252,0v109.91" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M110.135,112.5V2.252" />
        </g>
        <g id="Group_Holder">
            <path fill="#525252" d="M0,25.563h2.252v6.306H0V25.563z" />
            <path fill="#525252" d="M110.135,25.563h2.365v6.306h-2.365V25.563z" />
            <path fill="#525252" d="M110.135,80.518h2.365V86.6h-2.365V80.518z" />
            <path fill="#525252" d="M0,80.518h2.252V86.6H0V80.518z" />
        </g>
        <g id="Group_Lower_Damper">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="62.2744" y1="114.6279" x2="46.0168" y2="53.9538">
                <stop offset="0" style="stop-color:#C9D6D6" />
                <stop offset="0.4" style="stop-color:#CCD9D9" />
                <stop offset="0.5" style="stop-color:#D7E4E4" />
                <stop offset="1" style="stop-color:#D8E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M105.518,100.788v2.253H6.869v-2.253l4.73-37.612h89.189L105.518,100.788z" />
            <path fill="#A5B2B2" d="M105.518,100.788v2.253H6.869v-2.253H105.518z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M105.518,100.788v2.253H6.869v-2.253l4.73-37.612h89.189   L105.518,100.788" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M11.599,77.365h89.189" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M9.347,98.311h93.694" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M11.599,65.653h89.189" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M11.599,79.617h89.189" />
        </g>
        <g id="Group_Upper_Damper">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="62.2744" y1="63.0508" x2="46.0168" y2="2.3767">
                <stop offset="0" style="stop-color:#C9D6D6" />
                <stop offset="0.4" style="stop-color:#CCD9D9" />
                <stop offset="0.5" style="stop-color:#D7E4E4" />
                <stop offset="1" style="stop-color:#D8E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M105.518,49.212v2.252H6.869v-2.252l4.73-37.613h89.189   L105.518,49.212z" />
            <path fill="#A5B2B2" d="M105.518,49.212v2.252H6.869v-2.252H105.518z" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M11.599,25.788h89.189" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M9.347,46.734h93.694" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M11.599,13.852h89.189" />
            <path fill="none" stroke="#336666" stroke-width="0.25" d="M11.599,28.041h89.189" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3645833333333333, 161.0, 0.4, 0.34375, 167.0, 0.5, 0.2552083333333333, 189.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.3645833333333333, 161.0, 0.4, 0.34375, 167.0, 0.5, 0.2552083333333333, 189.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.609375, 99.0, 1.0, 0.20833333333333334, 201.0, 2.0, 0.25, 191.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.0, 255.0, 5.0, 0.4427083333333333, 141.0, 6.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_Holder",[0.0, 0.6354166666666666, 0.0, 1.0, 0.6354166666666666, 0.0, 2.0, 0.6354166666666666, 0.0, 3.0, 0.6354166666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Lower_Damper",[0.0, 0.6510416666666666, 89.0]);
		this.fillGradient(this.fills,"Group_Upper_Damper",[0.0, 0.6510416666666666, 89.0]);
        super.drawObject();
    }
}
import { Tile } from "./tile";
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import historydatarequest from "@/model/requests/historydatarequest";
import authModule from "@/store/auth.module";
import TagsService from '@/services/tags.service';
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";
import { ColorRange } from "../properties/range/colorrange";

export class SparklineTile extends Tile{
    linecolor: string;
    linecolorpicker: string;
    unit:string;
    duration: number;
    decimalpos: number;
    colorrange: [];
    tagname:string;
    updatehistory = true;
    points: any[] = [];
    path:string;
    //timer:any;

  public deleteObject():void{
      super.deleteObject()
      this.updatehistory=true;
      console.log("clearTimout")
      //clearTimeout(this.timer)
    }

  public drawObject():void{
    super.initObject();
    this.setUpdate(true)
    //this.timer = setTimeout(()=>{this.drawObject()}, 1000);
   
    if(this.updatehistory){
      
      if (authModule.currentUser!=null){
        const request:historydatarequest =  {token:authModule.currentUser.accessToken, 
             name:this.path, begindate:Date.now()-this.duration*60000 , enddate: Date.now()};
        TagsService.gethistorydata(request,(data:string)=>{
        
            const historydata =JSON.parse(data);  
            this.points =  historydata;
            const group = this.drawImage(this.points)
            if (group!=null)
              this.node.add(group) 
            this.updatehistory = false
            //console.log('updatehistory this.duration this.points', this.duration, this.points)
         }, (data:string)=>{ console.log('data', data)}
        
      )}   
    }else{
      const tag:tag = tagsModele.getTag(this.path)
      if(tag !=null){
        this.points.push({x:Date.now(), y:Number(tag.tagvalue)})
        this.points= this.points.filter((point)=>{
          return point.x>Date.now()-this.duration*60000
        })
        const group = this.drawImage(this.points)
        this.node.add(group)
      }
    }
  }

  private drawImage(points):Konva.Group{
    //console.log("points", points)
    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }
    
    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      }
    });
     
    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
      x: size*.05,
      y: size*0.1 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(titleText)    

    const mindate = Math.min(...points.map(o => o.x))
   // console.log('mindate', mindate)
    const maxdate = Date.now()
   // console.log('maxdate', maxdate)
    let deltadate = maxdate - mindate;
    deltadate = deltadate==0? 1: deltadate;
   // console.log('deltadate', deltadate)

    const minvalue = Math.min(...points.map(o => o.y));
    const maxvalue = Math.max(...points.map(o => o.y));
    let deltavalue = maxvalue - minvalue;
    deltavalue = deltavalue==0? 1: deltavalue;
    
    const minX  = this.width*0.05;
    const maxX  = minX + this.width*.9;
    const minY  = this.height*0.5;
    const maxY  = minY + this.height*.45;

    const stepY = (maxY-minY)/5;

    let y1 = minY
    for (let i=0;i<5;i++) {
        const line = new Konva.Line({
            points: [minX,y1,maxX,y1],
            stroke: ColorUtils.darkColor(ColorUtils.convertformat(textcolor), 0.5),
            strokeWidth: 1,
          });
          group.add(line)
          y1+=stepY
    }

    const highText = new Konva.Text({
        x: this.width*0.05,
        y: minY- size * 0.0125-0.06*size,
        verticalAlign:"bottom",
        text: maxvalue.toFixed(0).toString(),
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(highText)

    const lowText = new Konva.Text({
        x: this.width*0.05,
        y: this.height - this.height*0.06 - 0.06*size,
        verticalAlign:"bottom",
        text: minvalue.toFixed(0).toString(),
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
    });  
    group.add(lowText)
        
    const curvePoints = []
    points.forEach((p)=>{
      const x = (p.x-mindate)*this.width*.9/(maxdate-mindate);
      let y = (maxvalue-p.y)*(this.height*.45-stepY)/(deltavalue);
      if (y<0) y=0;
      if (y>this.height*.45) y=this.height*.45
      curvePoints.push(this.width*.05+x, y+this.height*.5)
    })
   // console.log('curvePoints', curvePoints)
    if (curvePoints.length<2)
      return group;

    const curx = this.width*.9;
    let cury = 0;
    const tag:tag = tagsModele.getTag(this.path)
    if (tag!=null) {
      //if (maxvalue-minvalue>0)
      cury = (maxvalue-Number(tag.tagvalue))*(this.height*.45-stepY)/(deltavalue);
      curvePoints.push(this.width*.05+curx, this.height*.5+cury)
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, minY+height*.45 , 0, minY);

    
    if(this.colorrange !=null && this.colorrange.length > 0){	
      this.colorrange.forEach((col:ColorRange)=>{
        if(col.from>maxvalue && col.to>maxvalue) return
        if(col.from<minvalue && col.to<minvalue) return
        let from = (col.from - minvalue)/deltavalue
        from = from>1? 1: (from<0 ? 0: from);
        let to = (col.to - minvalue)/deltavalue
        to = to>1 ? 1 : (to<0 ? 0 : to);
        if (from==null || isNaN(from))
          from = 0;
        if (to==null || isNaN(to))
          to = 1;
        gradient.addColorStop(from, ColorUtils.convertformat(col.color))
        gradient.addColorStop(to, ColorUtils.convertformat(col.color))
      })
    }  

    const curve = new Konva.Line({
      points: curvePoints,
      stroke: (this.colorrange==null || this.colorrange.length == 0)? ColorUtils.convertformat(this.linecolor) : gradient,
      strokeWidth: size * 0.01,
    });

    const dot = new Konva.Circle({
      x: this.width*0.95,
      y: this.height*.5+cury,
      radius: size*.014,
    });
    const colorStops = []
    this.colorrange.forEach((col:ColorRange)=>{
     // console.log('col', col.color)
      if(col.from>maxvalue && col.to>maxvalue) return
      if(col.from<minvalue && col.to<minvalue) return
      let from = (col.from - minvalue)/deltavalue
      if(from != null){
        from = from>1? 1: (from<0 ? 0: from);
      }else from = 1
      let to = (col.to - minvalue)/deltavalue
      if(to != null){
        to = to>1 ? 1 : (to<0 ? 0 : to)
      }else to = 0
     
      colorStops.push(from, ColorUtils.convertformat(col.color), to, ColorUtils.convertformat(col.color))
    })
    if(this.colorrange ==null || this.colorrange.length == 0 || colorStops.length ==0){
      dot.fill(ColorUtils.convertformat(this.linecolor))
    }else{
     // console.log('colorStops', colorStops)
      dot.fillLinearGradientStartPoint({ x: 0, y: -(minY+height*.45-this.height*.5-cury)});
      dot.fillLinearGradientEndPoint({ x: 0, y: this.height*.5+cury-minY});
      dot.fillLinearGradientColorStops(colorStops)
    }	
    group.add(curve)
    group.add(dot)

    if (tag!=null) {
     // console.log('tag.tagvalue', parseFloat(parseFloat(tag.tagvalue).toFixed(this.decimalpos)).toLocaleString())
      const unitTextTransparent = new Konva.Text({
        x: 0,
        y: size*.35 - 0.24*size,
        verticalAlign: "bottom",
        text: this.unit,
        fontSize: 0.12*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left'
      }); 
      const unitText = new Konva.Text({
        x: this.width-unitTextTransparent.width()-size*.05,
        y: size*0.35 - 0.24*size,
        height: 0.24*size,
        verticalAlign: 'bottom',
        text: this.unit,
        fontSize: 0.12*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left'
      }); 
      const valueText = new Konva.Text({
        x: size*.05,
        y: size*0.35 - 0.24*size,
        width: this.width-unitTextTransparent.width()-size*.05*2,
        height: 0.24*size,
        verticalAlign: 'bottom',
        text: parseFloat(parseFloat(tag.tagvalue).toFixed(this.decimalpos)).toLocaleString(),
        fontSize: 0.24*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'right',
        offsetY:-size*0.024
      }); 
      group.add(unitText, valueText)
      const timeSpanText = this.createTimeSpanText((deltadate)/1000)
      const timeText = new Konva.Text({
        x: size*.05,
        y: this.height - this.height*0.08 - 0.04*size,
        width: this.width-size*.05*2,
        //height: 0.24*size,
        verticalAlign: 'bottom',
        text: timeSpanText,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'center',
      }); 
      group.add(timeText)
      
      return group
     }

  }

  private createTimeSpanText(timeSpan:number):string {
    const SEC_MONTH = 2_592_000;
    const SEC_DAY = 86_400;
    const SEC_HOUR = 3_600;
    const SEC_MINUTE = 60;
    let durationText = "\u2190 "
    
    if (timeSpan > SEC_MONTH) { // 1 Month (30 days)
        const months = Math.trunc(timeSpan / SEC_MONTH);
        const days   = timeSpan % SEC_MONTH;
        durationText += months + "M"
        if (days > 0) { durationText += Math.trunc(days) +"d"}
        durationText += " \u2192"
    } else if (timeSpan > SEC_DAY) { // 1 Day
        const days  = Math.trunc(timeSpan / SEC_DAY);
        const hours = (timeSpan - (days * SEC_DAY)) / SEC_HOUR;
        durationText += days + "d"
        if (hours > 0) {durationText +=Math.trunc(hours) +"h"}
        durationText += "\u2192"
    } else if (timeSpan > SEC_HOUR) { // 1 Hour
        const hours   = Math.trunc(timeSpan / SEC_HOUR);
        const minutes = (timeSpan - (hours * SEC_HOUR)) / SEC_MINUTE;
        durationText += hours + "h"
        if (minutes > 0) {durationText+=Math.trunc(minutes) +"m"}
        durationText += " \u2192"
    } else if (timeSpan > SEC_MINUTE) { // 1 Minute
        const  minutes = Math.trunc(timeSpan / SEC_MINUTE);
        const seconds = (timeSpan - (minutes * SEC_MINUTE));
        durationText += minutes +"m"
        if (seconds > 0) {durationText+= Math.trunc(seconds) +"s"}
        durationText += " \u2192"
    } else {
        const seconds = Math.trunc(timeSpan);
        durationText += seconds +"s" +" \u2192"
    }
   // console.log('durationText', durationText)
    return durationText;
  }
}

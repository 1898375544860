import { SVGObject } from "../svgobject";

export class SVGBoiler2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 80.064" enable-background="new 0 0 112.5 80.064" xml:space="preserve">
        <g id="Group_SafetyValves">
            <path fill="#333333" d="M89.525,1.013h-3.378v4.851h3.378V1.013z" />
            <path fill="#333333" d="M94.931,1.013h-3.604v4.851h3.604V1.013z" />
            <path fill="#333333" d="M100.111,1.013h-3.379v4.851h3.379V1.013z" />
        </g>
        <g id="Group_BoilerDoor">
            <path fill="#8B9CB6" d="M30.294,14.526h8.746v28.152h-8.746V14.526z" />
        </g>
        <g id="Group_BoilerBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="74.999" y1="18.3552" x2="74.999" y2="5.1794">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M37.501,5.179h74.997v13.176H37.501V5.179z" />
            <path fill="#A6BAD9" d="M37.501,11.199h74.997v68.865H37.501V11.199z" />
            <path fill="none" d="M37.501,5.179h74.997v74.885H37.501V5.179" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M37.501,37.949h74.997" />
            <path fill="none" stroke="#4C6699" stroke-width="0.25" d="M37.501,74.884h74.997" />
            <circle fill="none" stroke="#4C6699" stroke-width="0.25" cx="57.996" cy="42.678" r="1.802" />
            <circle fill="none" stroke="#4C6699" stroke-width="0.25" cx="107.768" cy="70.718" r="1.802" />
            <path d="M41.104,38.849l-0.451-0.9l0.451-0.901h1.126l0.45,0.901l-0.45,0.9H41.104z" />
        </g>
        <g id="Group_Burner">
            <path fill="#324C7F" d="M4.732,61.371h32.769v16.441H4.732V61.371z" />
            <path fill="#00194C" d="M11.714,69.028H25.79v5.181H11.714V69.028z" />
        </g>
        <g id="Group_Nameplate">
            <path fill="#E5E5E5" d="M40.429,17.905h2.252v4.278h-2.252V17.905z" />
        </g>
        <g id="Group_FuelBurner">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="18.811" y1="63.6228" x2="18.811" y2="51.9119">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M30.75,51.912H6.872v11.711H30.75" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="33.2778" y1="65.4246" x2="33.2778" y2="49.6599">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.51" style="stop-color:#A6BAD9" />
                <stop offset="0.6" style="stop-color:#93A7C6" />
                <stop offset="0.79" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M36.262,49.66h-5.968v15.765h5.968" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="36.2622" y1="67.9822" x2="36.2622" y2="47.408">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M37.501,47.408h-2.478v20.574h2.478V47.408" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M7.266,58.443c0,0-1.922,0.308-2.196,1.464   c-0.196,0.828,6.924,0.65,6.924,0.65s5.1-0.322,6.951,3.425c0.987,1.999,0.773,3.096-0.192,4.675s-2.421,4.426-2.421,4.426" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M7.063,54.188c0,0-5.813,8.094-3.344,12.094" />
            <path fill="#324C7F" d="M33.222,60.695h1.802v1.127h-1.802V60.695z" />
            <path fill="#324C7F" d="M33.222,48.984h1.802v1.126h-1.802V48.984z" />
            <path fill="#324C7F" d="M33.222,53.714h1.802v1.126h-1.802V53.714z" />
        </g>
        <g id="Group_ControlPanelRight">
            <path fill="#4D4D4D" d="M100.336,30.968v4.054h-7.207V3.265h0.676l0.676,0.485v30.145h4.504v-2.928H100.336z" />
            <path fill="#999898" d="M99.66,30.968v4.054h-7.207V3.265h1.352v30.63h4.504v-2.928H99.66z" />
            <path d="M101.8,25.562h-5.405v5.405h5.405V25.562z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M101.8,25.562v5.405h-5.633" />
            <path fill="#999999" d="M103.039,23.76h7.656v10.585h-7.656V23.76z" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.5" d="M96.395,31.24v-5.678h5.731" />
        </g>
        <g id="Group_ControlPanelLeft">
            <path fill="none" d="M30.294,14.526h7.207v28.152h-7.207V14.526" />
            <path fill="#324C7F" stroke="#4C4C4C" stroke-width="0.25" d="M33.222,65.425h1.802v1.127h-1.802V65.425z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.5" d="M34.573,66.552c0,0,0.61,3.874,0.176,5.63   c-0.884,3.578-10.008,4.319-13.689,4.504c-2.333,0.117-9.346,0-9.346,0" />
            <circle fill="#8E8E8E" cx="28.83" cy="72.181" r="4.392" />
            <path fill="#333333" d="M40.429,1.013h17.567V7.15H40.429V1.013z" />
            <path fill="#666666" d="M41.555,0h-8.783v5.18h8.783V0z" />
            <path fill="#666666" d="M35.699,5.179h-1.126v3.941h1.126V5.179z" />
            <path fill="#4D4D4D" d="M10.013,66.652V64.85h19.031l1.352,1.126H50.89V3.265h0.574l0.553,0.552v63.286H29.72l-1.126-1.127H11.139   v0.676H10.013z" />
            <path fill="#999898" d="M9.461,66.1v-1.802h19.031l1.352,1.126h20.494V3.265h1.126v63.286H29.168l-1.126-1.127H10.587V66.1H9.461z" />
            <path fill="#4C6699" d="M36.825,9.121h-3.604v1.464h3.604V9.121z" />
            <path fill="#CCCCCC" d="M40.429,25.562h6.306v5.405h-6.306V25.562z" />
            <path fill="#8B9CB6" d="M0.002,80.064L2.142,66.1h9.572v13.964H0.002z" />
            <path d="M9.799,69.028H4.732v5.181h5.067V69.028z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.5" d="M9.799,68.813v5.396H4.732" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.5" d="M4.734,74.454l-0.002-5.426h5.299" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4739583333333333, 0.0, 0.51, 0.5208333333333334, 121.0, 0.6, 0.671875, 83.0, 0.79, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.fills,"Group_SafetyValves",[0.0, 0.3958333333333333, 0.0, 1.0, 0.3958333333333333, 0.0, 2.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_BoilerDoor",[0.0, 0.7604166666666666, 61.0]);
		this.fillGradient(this.fills,"Group_BoilerBody",[0.0, 0.5208333333333334, 121.0]);
		this.fillGradient(this.fills,"Group_Burner",[0.0, 0.6510416666666666, 0.0, 1.0, 0.2552083333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Nameplate",[0.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.fills,"Group_FuelBurner",[0.0, 0.6510416666666666, 0.0, 1.0, 0.6510416666666666, 0.0, 2.0, 0.6510416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_ControlPanelRight",[0.0, 0.5989583333333334, 0.0, 1.0, 0.8125, 47.0, 2.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_ControlPanelLeft",[0.0, 0.6510416666666666, 0.0, 1.0, 0.890625, 27.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.7916666666666666, 0.0, 4.0, 0.7916666666666666, 0.0, 5.0, 0.5989583333333334, 0.0, 6.0, 0.8125, 47.0, 7.0, 0.8541666666666666, 0.0, 8.0, 0.40625, 151.0, 9.0, 0.7604166666666666, 61.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPump4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 58.334" enable-background="new 0 0 112.5 58.334" xml:space="preserve">
        <g id="Group_Pump_Chamber">
            
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="42.4697" y1="1.8018" x2="42.4697" y2="47.6362" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M74.917,10.698H10.023v45.834h64.894" />
        </g>
        <g id="Group_Rear">
            
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="5.2925" y1="1.8018" x2="5.2925" y2="47.6362" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.563,56.532h9.459V10.698H0.563v8.334h1.126v8.333H0.563   V56.532z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M1.689,19.032H0.563v8.333h1.126V19.032z" />
        </g>
        <g id="Group_Drivershaft_Flange">
            
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="87.7402" y1="1.8018" x2="87.7402" y2="47.6362" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M74.917,56.532h25.646V10.698H74.917" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M98.762,58.334h3.604l-0.226-6.081h-3.379l-1.352,1.352v3.379   L98.762,58.334" />
            <path fill="#E0E0E0" stroke="#4C4C4C" stroke-width="0.25" d="M98.762,16.555h3.604l-0.226-5.856h-3.379l-1.352,1.126v3.604   L98.762,16.555z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M81.419,39.302h-4.729v17.682h4.729V39.302z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="76.7461" y1="1.3506" x2="76.7461" y2="48.311" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M72.072,56.983v-46.96h9.347v29.279h-4.729v17.682H72.072z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M90.879,11.824h-8.334v6.532h8.334V11.824z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M90.879,19.708h-8.334v8.333h8.334V19.708z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M90.879,29.167h-8.334V37.5h8.334V29.167z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M90.879,38.74h-8.334v8.108h8.334V38.74z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M90.879,48.198h-8.334v7.207h8.334V48.198z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="94.8193" y1="0" x2="94.8193" y2="48.311" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M94.482,52.929h-5.406v5.405h10.136l-1.126-34.572h2.477   V10.023h-6.08V52.929L94.482,52.929z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M87.951,52.929h-2.479v5.405h2.479V52.929z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M89.076,53.604h-1.125v4.055h1.125V53.604z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M85.021,52.929h0.451v5.405h-0.451V52.929z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M84.348,54.056h0.676v2.928h-0.676V54.056z" />
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="76.126" y1="14.3022" x2="82.0957" y2="14.3022" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M82.096,43.469h-5.97v1.126h5.97V43.469z" />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="76.126" y1="9.5728" x2="82.0957" y2="9.5728" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M82.096,48.198h-5.97v1.126h5.97V48.198z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="76.126" y1="4.8418" x2="82.0957" y2="4.8418" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M82.096,52.929h-5.97v1.127h5.97V52.929z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M99.212,58.334l-1.126-34.572h2.477V9.573" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M101.689,13.626h-1.127v42.23h1.127V13.626z" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="97.4102" y1="25.5635" x2="102.3662" y2="25.5635" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M98.762,35.248h3.604l-0.226-4.955h-3.379l-1.352,0.901v2.928L98.762,35.248z" />
        </g>
        <g id="Group_Upstream_Pipe_Flange">
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="90.8789" y1="52.5479" x2="101.6895" y2="52.5479" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M90.879,10.573h10.811V1H90.879V10.573z" />
        </g>
        <g id="Group_Moulding_Flip">
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="4.4478" y1="23.7607" x2="4.4478" y2="25.5635" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,32.771H0v1.803h8.896V32.771z" />
            
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="4.4478" y1="26.915" x2="4.4478" y2="28.4912" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,29.843H0v1.576h8.896V29.843z" />
            
                <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="4.4478" y1="20.834" x2="4.4478" y2="22.6357" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,35.698H0V37.5h8.896V35.698z" />
        </g>
        <g id="Group_Stand">
            <path stroke="#4C4C4C" stroke-width="0.25" d="M49.324,58.334v-1.353H33.896v-8.332H32.77v8.334h-6.532v1.352L49.324,58.334   L49.324,58.334z" />
        </g>
        <g id="Group_Downstream_Pipe_Flange">
            
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="107.0947" y1="20.2705" x2="107.0947" y2="30.9692" gradientTransform="matrix(1 0 0 -1 0 58.334)">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M101.689,27.365H112.5v10.698h-10.811V27.365z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);

        super.drawObject();
    }
}
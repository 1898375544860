import { SVGObject } from "../svgobject";

export class SVGHvac7 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 110.246" enable-background="new 0 0 112.5 110.246" xml:space="preserve">
        <g id="Group_Stand">
            <path fill="#383838" d="M2.14,109.571h18.919v0.676H2.14V109.571z" />
            <path fill="#383838" d="M91.328,109.571h18.919v0.676H91.328V109.571z" />
        </g>
        <g id="Group_Base_Support">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="59.8916" y1="118.8469" x2="52.6094" y2="77.5475">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.38" style="stop-color:#7E7E7E" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#999999" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,86.823h112.5v22.747H0V86.823z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,86.823h112.5v22.747H0V86.823" />
        </g>
        <g id="Group_Thermal_Coils">
            <path fill="#CCCCCC" d="M0,39.752h46.734v47.071H0V39.752z" />
            <path fill="#333333" d="M2.14,39.752h42.342v47.071H2.14V39.752z" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,44.482h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,49.211h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,53.941h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,58.67h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,63.401h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,67.905h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,72.634h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,77.364h42.342" />
            <path fill="none" stroke="#CCCCCC" stroke-width="0.25" d="M2.14,82.094h42.342" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,39.752h46.734v47.071H0V39.752" />
            <path d="M46.734,29.842H30.293v0.676h16.441V29.842z" />
            <path d="M30.293,29.842L4.505,39.977l0.112,0.45l25.901-9.909L30.293,29.842z" />
        </g>
        <g id="Group_Control_Unit">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="83.3906" y1="91.1223" x2="71.1125" y2="21.4895">
                <stop offset="0" style="stop-color:#3D3D3D" />
                <stop offset="0.35" style="stop-color:#3F3F3F" />
                <stop offset="0.5" style="stop-color:#4C4C4C" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M46.734,25.788h61.035v61.035H46.734V25.788z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M46.734,25.788h61.035v61.035H46.734V25.788" />
            <path fill="#CCCCCC" d="M89.076,39.301h13.964v11.036H89.076V39.301z" />
            <path fill="#999999" d="M89.076,39.301h13.964v-2.928H89.076V39.301z" />
            <path fill="#333333" d="M89.076,73.76h13.964V50.337H89.076V73.76z" />
            <path fill="#666666" d="M58.445,45.608h17.793v30.63H58.445V45.608z" />
            <path fill="#333333" d="M60.924,71.508h2.252v2.252h-2.252V71.508z" />
            <path fill="#333333" d="M64.302,71.508h2.478v2.252h-2.478V71.508z" />
            <path fill="#333333" d="M67.905,71.508h2.253v2.252h-2.253V71.508z" />
            <path fill="#333333" d="M71.509,71.508h2.253v2.252h-2.253V71.508z" />
            <path fill="#333333" d="M60.924,67.905h2.252v2.478h-2.252V67.905z" />
            <path fill="#333333" d="M64.302,67.905h2.478v2.478h-2.478V67.905z" />
            <path fill="#333333" d="M67.905,67.905h2.253v2.478h-2.253V67.905z" />
            <path fill="#333333" d="M71.509,67.905h2.253v2.478h-2.253V67.905z" />
            <path fill="#333333" d="M60.924,64.527h2.252v2.252h-2.252V64.527z" />
            <path fill="#333333" d="M64.302,64.527h2.478v2.252h-2.478V64.527z" />
            <path fill="#333333" d="M67.905,64.527h2.253v2.252h-2.253V64.527z" />
            <path fill="#333333" d="M71.509,64.527h2.253v2.252h-2.253V64.527z" />
            <path fill="#333333" d="M60.924,60.923h2.252v2.478h-2.252V60.923z" />
            <path fill="#333333" d="M64.302,60.923h2.478v2.478h-2.478V60.923z" />
            <path fill="#333333" d="M67.905,60.923h2.253v2.478h-2.253V60.923z" />
            <path fill="#333333" d="M71.509,60.923h2.253v2.478h-2.253V60.923z" />
            <path fill="#333333" d="M60.924,50.337h12.838v3.604H60.924V50.337z" />
            <path fill="none" stroke="#B2B2B2" stroke-width="0.25" d="M58.445,76.238h17.568v-30.63" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M76.014,45.608H58.445v30.63" />
        </g>
        <g id="Group_Motor_Unit">
            <path d="M56.194,16.328H39.752v0.788h16.441V16.328z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="77.3643" y1="25.7883" x2="77.3643" y2="7.2073">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M56.194,25.788V7.207h42.341v18.581H56.194z" />
            <path fill="#B2B2B2" d="M74.887,0h18.919v14.076H74.887V0z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M74.887,14.076V0h18.919v14.076" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M58.445,7.207v18.581" />
            <path d="M74.887,2.365H39.752v0.451h35.134V2.365z" />
            <path d="M39.752,17.116V2.365h-0.676v14.752H39.752z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="50.3379" y1="3.4915" x2="50.3379" y2="1.6887">
                <stop offset="0" style="stop-color:#4C4C4C" />
                <stop offset="0.5" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#4C4C4C" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M45.608,3.491V1.689h4.729l4.729,0.676v0.451l-4.729,0.676H45.608" />
            <path fill="#3D3D3D" d="M98.417,24.516c0,0.637,0,0.904,0,1.231h7.672c0-9.598-3.304-17.377-7.38-17.377   c-0.039,0-0.076,0-0.114,0.001" />
            <path fill="#CCCCCC" d="M77.814,9.347h14.189v5.968H77.814V9.347z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.5885416666666666, 0.0, 0.38, 0.9791666666666666, 0.0, 0.5, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4739583333333333, 0.0, 0.35, 0.4895833333333333, 0.0, 0.5, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.5885416666666666, 0.0, 0.5, 0.8072916666666666, 49.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.5885416666666666, 0.0, 0.5, 0.8072916666666666, 49.0, 1.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.4322916666666667, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Thermal_Coils",[0.0, 0.40625, 151.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Control_Unit",[0.0, 0.40625, 151.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.3958333333333333, 0.0, 3.0, 0.7916666666666666, 0.0, 4.0, 0.3958333333333333, 0.0, 5.0, 0.3958333333333333, 0.0, 6.0, 0.3958333333333333, 0.0, 7.0, 0.3958333333333333, 0.0, 8.0, 0.3958333333333333, 0.0, 9.0, 0.3958333333333333, 0.0, 10.0, 0.3958333333333333, 0.0, 11.0, 0.3958333333333333, 0.0, 12.0, 0.3958333333333333, 0.0, 13.0, 0.3958333333333333, 0.0, 14.0, 0.3958333333333333, 0.0, 15.0, 0.3958333333333333, 0.0, 16.0, 0.3958333333333333, 0.0, 17.0, 0.3958333333333333, 0.0, 18.0, 0.3958333333333333, 0.0, 19.0, 0.3958333333333333, 0.0, 20.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Motor_Unit",[0.0, 0.609375, 99.0, 1.0, 0.4739583333333333, 0.0, 2.0, 0.40625, 151.0]);

        super.drawObject();
    }
}
import { FillingProperty } from "./fillingproperty";
import tag from "@/model/tag";

export class ValueProperty extends FillingProperty{

    decimalpos:number

    public getValue(tagnames:string[]):number {
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return 0
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
		if (percent<0) return 0;
		else if (percent>1) return 180;
		else return 180*percent;
    }

    public getTagValue(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return 0
        if(+tag.tagvalue>this.maximumvalue) return this.maximumvalue
        if(+tag.tagvalue<this.minimumvalue) return this.minimumvalue
        return +tag.tagvalue
    }
    public getValue90Deg(tagnames:string[]):number {
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return 0
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
		if (percent<0) return 0;
		else if (percent>1) return 90;
		else return 90*percent;
    }
    public getCurrent270degValue(tagnames:string[]):number {
        const tag:tag = this.getTag(tagnames); 
        if (tag==null) return 0
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
		if (percent<0) return 0;
		else if (percent>1) return 270;
		else return 270*percent;
    }
   
    public getPercent(tagnames:string[]):number{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return 0;
        const percent:number = (+tag.tagvalue-this.minimumvalue)/(this.maximumvalue-this.minimumvalue)
        if (percent<0) return 0
        if (percent>1) return 1
        return percent; 
    }

    public getValueAndNA(tagnames:string[]):any{
        const tag:tag = this.getTag(tagnames); 
        console.log('tag=',tag)
        if (tag==null) return 'N/A';
        if(+tag.tagvalue>this.maximumvalue) return this.maximumvalue
        if(+tag.tagvalue<this.minimumvalue) return this.minimumvalue
        return +tag.tagvalue
    }
    

    
    
}
import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { Trend } from '@/model/project/objects/trend';
import { format } from 'date-fns'


@Module({ name:"TrendDialogBox", dynamic:true, store})
class TrendDialogBoxModule extends VuexModule {
  public trend:Trend;
  public enabled:boolean;
  public beginstring:string;
  public endstring:string;
  public curves:any[]
  public historydb:string;
    @Mutation
    public setTrend(trend:Trend){
        if (trend==null) return;
        this.curves = []
      this.trend = trend;
      this.beginstring = format(trend.begin, "yyyy-MM-dd HH:mm")
      this.endstring = format(trend.end, "yyyy-MM-dd HH:mm")
      let i=0;
        trend.curves.forEach((curve)=>{
            i++;
            this.curves.push({id:i, name:curve.curvename, hide:curve.hide?false:true, tagname:curve.tagname})
            curve.id = i;
        })
        console.log(this.curves)
    }
    @Mutation
    public setTrendDialogEnabled(value:boolean){
      console.log(value)
      this.enabled = value
    }
    @Mutation
    public setHistoryDB(value:string){
      this.historydb = value;
    }
    get getTrendDialogEnable():boolean{   
      return this.enabled;
    }
    get getBegin():string{
        return this.beginstring
    }
    get getHistoryDB():string{
      return this.historydb;
    }
    get getEnd():string{
        return this.endstring
    }
    get getCurves():any[]{
        return this.curves
    }
    @Mutation
    public setBegin(value:string){
    
      this.beginstring = value;
    }
    @Mutation
    public setEnd(value:string){
    
     this.endstring = value
    }
    @Mutation
    public setCurves(value:any[]){
        this.curves = value
    }

    @Mutation
    public updateTrendTime(){
        if (this.trend==null) return;
        this.trend.begin = Date.parse(this.beginstring)
        this.trend.end = Date.parse(this.endstring)
        this.trend.curves.forEach((curve)=>{
          const cur  = this.curves.find(c=>c.id ===curve.id)
          if (!cur.hide)
            curve.hide = true;
        else
            curve.hide = false;
        });
        this.trend.drawObject()
    }
}

export default getModule(TrendDialogBoxModule);
import { SVGObject } from "../svgobject";

export class SVGFlex1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 65.203" enable-background="new 0 0 112.5 65.203" xml:space="preserve">
        <g id="Group_Short_Tube">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="55.856" y1="61.5996" x2="55.856" y2="5.6313">
                <stop offset="0.01" style="stop-color:#646566" />
                <stop offset="0.16" style="stop-color:#909091" />
                <stop offset="0.49" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#646566" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M111.712,5.631H0V61.6h111.712V5.631z" />
        </g>
        <g id="Group_Fins">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0" y1="5.6309" x2="111.7119" y2="5.6309">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,5.631h111.712" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="0" y1="61.5996" x2="111.7119" y2="61.5996">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M0,61.6h111.712" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="107.6582" y1="32.6016" x2="112.5" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M107.658,0h4.842v65.203h-4.842V0z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="98.5361" y1="32.6016" x2="102.9277" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M98.536,0h4.392v65.203h-4.392V0z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="89.0762" y1="32.6016" x2="93.8066" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M89.076,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="79.8418" y1="32.6016" x2="84.5723" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M79.842,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="70.3828" y1="32.6016" x2="75.1133" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M70.383,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="61.1484" y1="32.6016" x2="65.8789" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M61.148,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="51.9146" y1="32.6016" x2="56.4189" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M51.915,0h4.504v65.203h-4.504V0z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="42.4551" y1="32.6016" x2="47.1846" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M42.455,0h4.729v65.203h-4.729V0z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="33.2207" y1="32.6016" x2="37.7251" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M33.221,0h4.504v65.203h-4.504V0z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="23.7612" y1="32.6016" x2="28.4912" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M23.761,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="14.5269" y1="32.6016" x2="19.2568" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M14.527,0h4.73v65.203h-4.73V0z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="5.0674" y1="32.6016" x2="9.7974" y2="32.6016">
                <stop offset="0" style="stop-color:#999999" />
                <stop offset="0.4" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M5.067,0h4.73v65.203h-4.73V0z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.8072916666666666, 49.0, 0.4, 0.8072916666666666, 49.0, 1.0, 0.7916666666666666, 0.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGMotor1 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 42.904" enable-background="new 0 0 112.5 42.904" xml:space="preserve">
        <g id="Group_Shaft">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="100.7871" y1="23.9866" x2="100.7871" y2="19.4822">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.52" style="stop-color:#D9D9D9" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M112.499,19.482H89.076v4.505h23.423V19.482" />
        </g>
        <g id="Group_MotorBody">
            
                <radialGradient id="SVGID_2_" cx="11.0786" cy="21.3401" r="17.0015" gradientTransform="matrix(-4.371139e-008 -1 1.3 -5.682481e-008 -16.6635 32.4187)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M4.843,42.68l-4.842-4.73V4.392L4.843,0c0,0,17.313,5.067,17.313,21.34   C22.156,42.905,4.843,42.68,4.843,42.68z" />
            <radialGradient id="SVGID_3_" cx="84.4336" cy="21.7341" r="15.428" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M76.126,2.14c9.889,0,17.905,8.772,17.905,19.594c0,10.822-8.017,19.595-17.905,19.595   c-0.473,0-0.901-0.021-1.29-0.061l0.265-39.053C75.532,2.165,75.585,2.14,76.126,2.14z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="14.3022" y1="42.6799" x2="14.3022" y2="7.324219e-004">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.52" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M4.843,0h18.918v42.68H4.843V0z" />
        </g>
        <g id="Group_HeatSinkBackground">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M23.762,1.689h52.477v36.486H23.762V1.689z" />
        </g>
        <g id="Group_Base">
            <path fill="#7F7F7F" d="M23.762,37.499h52.477v5.405H23.762V37.499z" />
        </g>
        <g id="Group_HeatSink">
            <path fill="#B2B2B2" d="M23.762,32.995H78.49v1.351H23.762V32.995z" />
            <path fill="#B2B2B2" d="M23.762,29.392H78.49v1.351H23.762V29.392z" />
            <path fill="#B2B2B2" d="M23.762,25.788H78.49v1.351H23.762V25.788z" />
            <path fill="#B2B2B2" d="M23.762,22.409H78.49v1.126H23.762V22.409z" />
            <path fill="#B2B2B2" d="M23.762,18.806H78.49v1.126H23.762V18.806z" />
            <path fill="#B2B2B2" d="M23.762,15.202H78.49v1.352H23.762V15.202z" />
            <path fill="#B2B2B2" d="M23.762,11.599H78.49v1.352H23.762V11.599z" />
            <path fill="#B2B2B2" d="M23.762,7.995H78.49v1.352H23.762V7.995z" />
            <path fill="#B2B2B2" d="M23.762,4.392H78.49v1.352H23.762V4.392z" />
            <path fill="#B2B2B2" d="M23.762,1.014H78.49V2.14H23.762V1.014z" />
            <path fill="#B2B2B2" d="M23.762,36.599H78.49v1.351H23.762V36.599z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3958333333333333, 0.0, 0.52, 0.3020833333333333, 177.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3958333333333333, 0.0, 0.52, 0.40625, 151.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_HeatSinkBackground",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_HeatSink",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0, 4.0, 0.609375, 99.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0, 9.0, 0.609375, 99.0, 10.0, 0.609375, 99.0]);

        super.drawObject();
    }
}
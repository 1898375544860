import { SVGObject } from "../svgobject";

export class SVGMedical6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 73.426" xml:space="preserve" enable-background="new 0 0 112.5 73.426">
        <g id="Group_Frame">
            <path d="M110.924,29.731H4.054l-0.676-2.252h107.996L110.924,29.731z" fill="#324C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M96.734,56.082H18.018V53.83h78.716V56.082z" fill="#324C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M98.874,62.613V50.564l-0.45-3.266l-1.689-3.266L79.279,29.731   H57.433v1.689l20.721,0.113l16.554,13.513l1.576,3.041l0.451,3.04v11.486H98.874z" fill="#324C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M15.766,62.613V50.564l0.563-3.266l1.689-3.266l17.455-14.302   H57.32v1.689l-20.721,0.113L20.045,45.046l-1.577,3.041l-0.45,3.04v11.486H15.766z" fill="#324C4C" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M98.648,38.965H15.991v-9.234h2.365v6.982h78.041v-6.982h2.252   V38.965z" fill="#4C6666" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M98.648,57.32H15.991v-2.252h82.657V57.32z" fill="#7F9999" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M101.014,64.19V51.578l-0.563-3.491l-1.689-3.378L80.405,29.731   H57.433v1.802h21.733l17.343,14.189l1.689,3.267l0.563,3.266V64.19H101.014z" fill="#7F9999" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M13.739,64.19V51.578l0.563-3.491l1.689-3.378l18.355-14.978   H57.32v1.802H35.585L18.243,45.722l-1.689,3.267l-0.563,3.266V64.19H13.739z" fill="#7F9999" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Wheel">
            <circle cx="97.86" cy="64.753" r="6.532" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="16.779" cy="64.753" r="6.532" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="99.775" cy="66.443" r="6.87" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="14.865" cy="66.443" r="6.87" fill="#333333" stroke="#4C4C4C" stroke-width="0.25" />
            <circle cx="99.775" cy="66.442" r="5.067" fill="#B2CCCC" />
            <circle cx="14.865" cy="66.442" r="5.181" fill="#B2CCCC" />
        </g>
        <g id="Group_Bed">
            <path d="M5.743,25.227v-6.87h103.829v6.87H5.743z" />
            <path d="M36.712,22.861h72.86l1.126-1.126v-4.617l-1.126-1.126h-72.86   L5.743,11.938l-1.239,1.014l-0.676,4.504l1.014,1.352L36.712,22.861z" fill="#E0E5E5" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M4.504,17.569l32.208,4.167h73.423" fill="none" stroke="#7F9999" stroke-width="0.25" />
            <path d="M5.067,13.064l31.645,4.054h73.423" fill="none" stroke="#7F9999" stroke-width="0.25" />
            <path d="M36.148,18.357l0.563-2.365v2.365" fill="none" stroke="#7F9999" stroke-width="0.25" />
            <path d="M3.378,24.663V18.92l34.459,4.617h73.424v1.126H3.378z" fill="#B2CCCC" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_SideBars">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="83.7832" y1="35.8115" x2="86.0361" y2="35.8115">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M86.036,21.172v29.279h-2.253V21.172H86.036z" fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="74.5488" y1="31.1943" x2="76.8018" y2="31.1943">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M76.802,21.172v20.044h-2.253V21.172H76.802z" fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="65.4277" y1="31.1943" x2="67.6797" y2="31.1943">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M67.68,21.172v20.044h-2.252V21.172H67.68z" fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="31.1943" x2="58.4453" y2="31.1943">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M58.445,21.172v20.044h-2.251V21.172H58.445z" fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="47.0723" y1="31.1943" x2="49.3242" y2="31.1943">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M49.324,21.172v20.044h-2.252V21.172H49.324z" fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="37.8379" y1="31.1943" x2="40.0903" y2="31.1943">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M40.09,21.172v20.044h-2.252V21.172H40.09z" fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="28.7163" y1="35.8115" x2="30.9683" y2="35.8115">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M30.968,21.172v29.279h-2.252V21.172H30.968z" fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M83.221,23.537H86.6v8.559h-3.379V23.537z" fill="#98B2B2" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M28.041,23.537h3.491v8.559h-3.491V23.537z" fill="#98B2B2" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="57.376" y1="21.1724" x2="57.376" y2="18.9199">
                <stop offset="0" style="stop-color:#262626" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#141414" />
            </linearGradient>
            <path d="M86.036,21.172h-57.32V18.92h57.32V21.172z" fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="57.376" y1="43.4697" x2="57.376" y2="41.2168">
                <stop offset="0" style="stop-color:#262626" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#141414" />
            </linearGradient>
            <path d="M84.347,43.47H30.405v-2.253h53.941V43.47z" fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" />
            <path d="M84.347,43.582H30.405v-2.365h53.941V43.582" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
        </g>
        <g id="Group_Bars">
            <path d="M112.5,27.479H2.252v-2.815H112.5V27.479" fill="none" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-0.0469" y1="15.4287" x2="2.2688" y2="15.4287">
                <stop offset="0.06" style="stop-color:#212121" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#0D0D0D" />
            </linearGradient>
            <path d="M2.252,0.001v30.856H0V0.001H2.252z" fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="57.3604" y1="27.3896" x2="57.3915" y2="24.6502">
                <stop offset="0" style="stop-color:#262626" />
                <stop offset="0.53" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#141414" />
            </linearGradient>
            <path d="M112.5,27.479H2.252v-2.815H112.5V27.479z" fill="url(#SVGID_11_)" />
        </g>
        </svg>`
		super.fillGradients();
        super.drawObject();
    }
}
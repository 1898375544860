import { SVGObject } from "../svgobject";

export class SVGConveyor2 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 47.297" enable-background="new 0 0 112.5 47.297" xml:space="preserve">
        <g id="Group_Inner_Area">
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M26.126,46.509l-1.915,0.226l-1.464-1.465l-0.113-1.914l1.352-1.352   l84.346-41.103l1.914-0.225l1.352,1.351l0.226,1.915l-1.352,1.464L26.126,46.509z" />
        </g>
        <g id="Group_Wheel">
            <radialGradient id="SVGID_1_" cx="109.4023" cy="3.0967" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M111.711,3.829l-0.112-1.802l-1.352-1.239l-2.026,0.113   l-1.127,1.464l0.113,1.802l1.352,1.239h1.914L111.711,3.829z" />
            <radialGradient id="SVGID_2_" cx="104.9551" cy="5.3491" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M107.319,6.081l-0.112-1.915l-1.352-1.126h-2.027   l-1.238,1.464l0.225,1.802l1.352,1.352l1.802-0.113L107.319,6.081z" />
            <radialGradient id="SVGID_3_" cx="100.5059" cy="7.4326" r="2.3368" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M102.814,8.221V6.306l-1.463-1.239l-1.915,0.226l-1.239,1.351   l0.113,1.915l1.352,1.239l1.914-0.113L102.814,8.221z" />
            <radialGradient id="SVGID_4_" cx="96.1143" cy="9.6284" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M98.423,10.36l-0.112-1.802L96.846,7.32l-1.914,0.113   l-1.126,1.351l0.112,1.915l1.352,1.239l1.803-0.113L98.423,10.36z" />
            <radialGradient id="SVGID_5_" cx="91.666" cy="11.7676" r="2.3371" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M94.031,12.5l-0.113-1.802l-1.463-1.239L90.54,9.572   l-1.239,1.352l0.113,1.915l1.464,1.238l1.802-0.112L94.031,12.5z" />
            <radialGradient id="SVGID_6_" cx="87.2188" cy="13.9072" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M89.527,14.752l-0.113-1.914l-1.352-1.239l-2.027,0.112   l-1.125,1.464l0.112,1.802l1.351,1.238l1.915-0.112L89.527,14.752z" />
            <radialGradient id="SVGID_7_" cx="82.7695" cy="16.1035" r="2.365" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" stroke="#4C4C4C" stroke-width="0.25" d="M85.135,16.892l-0.112-1.914l-1.464-1.239l-1.914,0.112   l-1.239,1.464l0.112,1.802l1.465,1.351l1.801-0.113L85.135,16.892z" />
            <radialGradient id="SVGID_8_" cx="78.3223" cy="18.2993" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.25" d="M80.631,19.031l-0.113-1.914l-1.352-1.126l-1.914,0.113   l-1.238,1.464l0.112,1.802l1.464,1.239l1.802-0.113L80.631,19.031z" />
            <radialGradient id="SVGID_9_" cx="73.873" cy="20.439" r="2.3371" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" stroke="#4C4C4C" stroke-width="0.25" d="M76.238,21.171l-0.112-1.802l-1.464-1.239l-1.914,0.113   l-1.239,1.464l0.112,1.802l1.465,1.239l1.801-0.113L76.238,21.171z" />
            <radialGradient id="SVGID_10_" cx="69.4258" cy="22.5781" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_10_)" stroke="#4C4C4C" stroke-width="0.25" d="M71.734,23.423l-0.113-1.915L70.27,20.27l-1.914,0.113   l-1.238,1.464l0.112,1.802l1.352,1.238l1.914-0.112L71.734,23.423z" />
            <radialGradient id="SVGID_11_" cx="64.9766" cy="24.7744" r="2.3647" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_11_)" stroke="#4C4C4C" stroke-width="0.25" d="M67.342,25.563l-0.112-1.914l-1.464-1.239l-1.802,0.225   l-1.352,1.352l0.112,1.914l1.465,1.238l1.914-0.111L67.342,25.563z" />
            <radialGradient id="SVGID_12_" cx="60.5859" cy="26.9141" r="2.3647" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_12_)" stroke="#4C4C4C" stroke-width="0.25" d="M62.95,27.702L62.725,25.9l-1.351-1.352l-1.915,0.226   l-1.238,1.352l0.113,1.914l1.463,1.239l1.803-0.113L62.95,27.702z" />
            <radialGradient id="SVGID_13_" cx="56.0811" cy="29.1094" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_13_)" stroke="#4C4C4C" stroke-width="0.25" d="M58.445,29.842l-0.111-1.802l-1.465-1.238l-1.802,0.112   l-1.351,1.352l0.112,1.914l1.464,1.238l1.802-0.111L58.445,29.842z" />
            <radialGradient id="SVGID_14_" cx="51.6895" cy="31.25" r="2.3368" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_14_)" stroke="#4C4C4C" stroke-width="0.25" d="M54.054,32.094l-0.226-1.914l-1.351-1.238l-1.915,0.112   l-1.239,1.464l0.113,1.802l1.464,1.239l1.802-0.113L54.054,32.094z" />
            <radialGradient id="SVGID_15_" cx="47.2412" cy="33.3887" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_15_)" stroke="#4C4C4C" stroke-width="0.25" d="M49.55,34.234l-0.113-1.915l-1.352-1.239l-1.914,0.113   l-1.239,1.464l0.112,1.915l1.352,1.125h1.915L49.55,34.234z" />
            <radialGradient id="SVGID_16_" cx="42.8491" cy="35.5859" r="2.3366" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_16_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.158,36.373l-0.113-1.801l-1.464-1.352l-1.914,0.225   l-1.126,1.352v1.914l1.464,1.239l1.802-0.112L45.158,36.373z" />
            <radialGradient id="SVGID_17_" cx="38.3447" cy="37.7813" r="2.3086" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_17_)" stroke="#4C4C4C" stroke-width="0.25" d="M40.653,38.514l-0.113-1.803l-1.351-1.238l-1.915,0.112   l-1.239,1.352l0.113,1.915l1.464,1.238l1.689-0.113L40.653,38.514z" />
            <radialGradient id="SVGID_18_" cx="33.9531" cy="39.9209" r="2.3083" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_18_)" stroke="#4C4C4C" stroke-width="0.25" d="M36.261,40.766l-0.112-1.914l-1.352-1.239l-2.027,0.112   l-1.126,1.465l0.112,1.801l1.352,1.239l1.915-0.112L36.261,40.766z" />
            <radialGradient id="SVGID_19_" cx="29.5049" cy="42.1172" r="2.3645" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_19_)" stroke="#4C4C4C" stroke-width="0.25" d="M31.87,42.904l-0.113-1.914l-1.464-1.238l-1.914,0.112   l-1.239,1.464l0.112,1.803l1.464,1.351l1.802-0.226L31.87,42.904z" />
            <radialGradient id="SVGID_20_" cx="25.0566" cy="44.2559" r="2.3371" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_20_)" stroke="#4C4C4C" stroke-width="0.25" d="M27.365,45.045l-0.113-1.914l-1.351-1.239l-1.915,0.112   l-1.239,1.464v1.802l1.464,1.352l1.915-0.112L27.365,45.045z" />
        </g>
        <g id="Group_Axial">
            <radialGradient id="SVGID_21_" cx="109.4023" cy="3.0967" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_21_)" d="M110.585,3.491l-0.112-0.901l-0.676-0.675l-0.9,0.112l-0.676,0.676l0.112,0.901l0.675,0.676   l0.901-0.113L110.585,3.491z" />
            <radialGradient id="SVGID_22_" cx="104.8984" cy="5.2363" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_22_)" d="M106.08,5.63V4.729l-0.676-0.675l-1.013,0.225l-0.676,0.563l0.112,1.014l0.676,0.563h1.014   L106.08,5.63z" />
            <radialGradient id="SVGID_23_" cx="100.5059" cy="7.4326" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_23_)" d="M101.688,7.883l-0.112-1.014L100.9,6.306L100,6.419l-0.676,0.676l0.112,0.901l0.676,0.563h0.901   L101.688,7.883z" />
            <radialGradient id="SVGID_24_" cx="96.1143" cy="9.6279" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_24_)" d="M97.297,10.022l-0.113-1.014l-0.675-0.563l-1.014,0.113l-0.563,0.676v0.9l0.789,0.676l0.9-0.112   L97.297,10.022z" />
            <radialGradient id="SVGID_25_" cx="91.6104" cy="11.7676" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_25_)" d="M92.793,12.162v-0.901l-0.789-0.676l-0.9,0.113l-0.676,0.676l0.112,0.9l0.676,0.676h0.901   L92.793,12.162z" />
            <radialGradient id="SVGID_26_" cx="87.2178" cy="13.9639" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_26_)" d="M88.4,14.302l-0.112-0.901l-0.676-0.563h-1.014l-0.563,0.675v1.014l0.676,0.563h1.014L88.4,14.302z   " />
            <radialGradient id="SVGID_27_" cx="82.7695" cy="16.1035" r="1.239" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_27_)" d="M84.009,16.554l-0.112-1.013l-0.789-0.676l-0.9,0.113l-0.676,0.788l0.113,0.901l0.675,0.676   l0.901-0.113L84.009,16.554z" />
            <radialGradient id="SVGID_28_" cx="78.3223" cy="18.2432" r="1.1541" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_28_)" d="M79.504,18.693l-0.112-1.013l-0.676-0.563h-1.013l-0.563,0.788l0.112,0.901l0.676,0.563h0.9   L79.504,18.693z" />
            <radialGradient id="SVGID_29_" cx="73.8174" cy="20.439" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_29_)" d="M75,20.833v-0.901l-0.676-0.676l-1.014,0.113l-0.676,0.676l0.226,0.901l0.563,0.676l1.013-0.113   L75,20.833z" />
            <radialGradient id="SVGID_30_" cx="69.4258" cy="22.5781" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_30_)" d="M70.607,22.973v-0.901l-0.787-0.676l-0.901,0.113l-0.676,0.676l0.112,0.901l0.676,0.675l0.9-0.112   L70.607,22.973z" />
            <radialGradient id="SVGID_31_" cx="65.0342" cy="24.7744" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_31_)" d="M66.217,25.112l-0.113-0.901l-0.676-0.563h-1.014l-0.563,0.676v1.014L64.64,25.9h0.901   L66.217,25.112z" />
            <radialGradient id="SVGID_32_" cx="60.5293" cy="26.9141" r="1.1541" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_32_)" d="M61.711,27.365v-1.014l-0.675-0.563h-1.014l-0.676,0.788l0.112,0.9l0.676,0.563h0.901   L61.711,27.365z" />
            <radialGradient id="SVGID_33_" cx="56.1377" cy="29.1104" r="1.1826" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_33_)" d="M57.32,29.504l-0.113-0.9l-0.675-0.676l-0.901,0.112l-0.676,0.676l0.112,0.901l0.676,0.676   l0.901-0.113L57.32,29.504z" />
            <radialGradient id="SVGID_34_" cx="51.6328" cy="31.25" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_34_)" d="M52.815,31.644v-0.9l-0.676-0.676l-1.014,0.112l-0.675,0.676l0.112,0.9l0.676,0.676h0.901   L52.815,31.644z" />
            <radialGradient id="SVGID_35_" cx="47.2412" cy="33.4453" r="1.1549" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_35_)" d="M48.423,33.783l-0.112-0.9l-0.676-0.563h-0.901l-0.676,0.676l0.113,1.014l0.676,0.563h0.901   L48.423,33.783z" />
            <radialGradient id="SVGID_36_" cx="42.793" cy="35.5293" r="1.1541" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_36_)" d="M43.919,36.035v-1.014l-0.676-0.675l-1.013,0.112l-0.563,0.676v1.014l0.675,0.563h1.014   L43.919,36.035z" />
            <radialGradient id="SVGID_37_" cx="38.3447" cy="37.7813" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_37_)" d="M39.527,38.063v-0.9l-0.789-0.563h-0.901l-0.675,0.788l0.112,0.901l0.676,0.675l0.901-0.111   L39.527,38.063z" />
            <radialGradient id="SVGID_38_" cx="33.9531" cy="39.9209" r="1.1824" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_38_)" d="M35.135,40.314l-0.112-0.9l-0.676-0.676l-1.014,0.113l-0.563,0.675v1.014l0.676,0.563l1.013-0.113   L35.135,40.314z" />
            <radialGradient id="SVGID_39_" cx="29.4482" cy="42.0605" r="1.1821" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_39_)" d="M30.631,42.455v-0.901l-0.676-0.676l-1.013,0.112l-0.676,0.676l0.113,0.901l0.675,0.675h1.014   L30.631,42.455z" />
            <radialGradient id="SVGID_40_" cx="25.0566" cy="44.2568" r="1.1546" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.42" style="stop-color:#9E9E9E" />
                <stop offset="1" style="stop-color:#595959" />
            </radialGradient>
            <path fill="url(#SVGID_40_)" d="M26.239,44.594l-0.113-0.9l-0.676-0.563h-1.013l-0.563,0.675v1.014l0.789,0.563h0.901   L26.239,44.594z" />
        </g>
        <g id="Group_Conveyor_Belt">
            <path fill="#B2B2B2" d="M108.333,0.901L86.261,0.788L1.915,41.892l-1.352,1.351l22.072,0.113l1.352-1.352L108.333,0.901z" />
            <path fill="#666666" d="M22.748,45.27l1.464,1.465L2.14,46.621l-1.351-1.464L22.748,45.27z" />
            <path fill="#8C8C8C" d="M22.748,45.27l-0.113-1.914L0.563,43.242l0.226,1.915L22.748,45.27z" />
            <path fill="#999999" d="M108.333,0.901l1.914-0.225L88.176,0.563l-1.915,0.225L108.333,0.901z" />
            <path fill="#A6A6A6" stroke="#7F7F7F" stroke-width="0.25" d="M50,29.392l-21.959-0.226l-8.896,4.279l22.072,0.226L50,29.392z" />
            <path fill="#A6A6A6" stroke="#7F7F7F" stroke-width="0.25" d="M89.414,10.135l-22.072-0.112l-8.783,4.279l21.959,0.112   L89.414,10.135z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M24.212,46.734l-1.464-1.465l-0.113-1.914l1.352-1.352l84.346-41.103   l1.914-0.225L88.176,0.563l-1.915,0.225L1.915,41.892l-1.352,1.351l0.226,1.915l1.351,1.464L24.212,46.734" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.25, 191.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_21_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_22_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_23_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_24_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_25_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_26_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_27_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_28_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_29_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_30_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_31_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_32_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_33_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_34_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_35_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_36_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_37_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_38_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_39_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_40_",[0.0, 0.25, 191.0, 0.42, 0.765625, 59.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Inner_Area",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Conveyor_Belt",[0.0, 0.609375, 99.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.90625, 23.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.703125, 75.0, 5.0, 0.703125, 75.0]);

        super.drawObject();
    }
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42526836"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "text-weight-bold" }
const _hoisted_3 = { class: "text-weight-bold" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "cardsperrow"
}
const _hoisted_6 = {
  key: 0,
  class: "q-pa-xs col-xs-12 col-sm-6 col-md-2"
}
const _hoisted_7 = {
  key: 1,
  class: "q-pa-xs col-xs-12 col-sm-6 col-md-3"
}
const _hoisted_8 = {
  key: 2,
  class: "q-pa-xs col-xs-12 col-sm-6 col-md-4"
}
const _hoisted_9 = {
  key: 3,
  class: "q-pa-xs col-xs-12 col-sm-6 col-md-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_TagCard = _resolveComponent("TagCard")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.event,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.event) = $event)),
      position: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_bar, {
              dark: "",
              class: "bg-red text-white"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.eventtagname), 1),
                _createVNode(_component_q_space),
                _createVNode(_component_q_btn, {
                  icon: "close",
                  flat: "",
                  round: "",
                  dense: "",
                  onClick: _ctx.closeEventDialog
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_section, { class: "row items-center no-wrap" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  color: "red",
                  name: "notifications_active",
                  size: "md"
                }),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.eventmessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_table, {
      title: "",
      rows: _ctx.tags.filter(tag=>tag.usevalue),
      columns: _ctx.columns,
      "row-key": "name",
      filter: _ctx.filtergroup,
      grid: !_ctx.togglegrid,
      "hide-header": !_ctx.togglegrid,
      "rows-per-page-options": [4,12,20,40,100,0],
      "filter-method": _ctx.filterData,
      pagination: _ctx.pagination,
      "rows-per-page-label": _ctx.t('tags.rowsperpage'),
      "no-data-label": _ctx.t('tags.nodataavailable')
    }, {
      "top-left": _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_toggle, {
            class: "col",
            modelValue: _ctx.togglegrid,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.togglegrid) = $event)),
            dense: true,
            "left-label": "",
            label: _ctx.t('tags.gridtable')
          }, null, 8, ["modelValue", "label"]),
          (!_ctx.isItReallyMobile() && !_ctx.togglegrid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.t('tags.cardsperrow')) + " ", 1),
                _createVNode(_component_q_radio, {
                  onClick: _ctx.updateradio,
                  modelValue: _ctx.perrow,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perrow) = $event)),
                  val: "3",
                  label: "3"
                }, null, 8, ["onClick", "modelValue"]),
                _createVNode(_component_q_radio, {
                  onClick: _ctx.updateradio,
                  modelValue: _ctx.perrow,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.perrow) = $event)),
                  val: "4",
                  label: "4"
                }, null, 8, ["onClick", "modelValue"]),
                _createVNode(_component_q_radio, {
                  onClick: _ctx.updateradio,
                  modelValue: _ctx.perrow,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.perrow) = $event)),
                  val: "6",
                  label: "6"
                }, null, 8, ["onClick", "modelValue"]),
                _createVNode(_component_q_radio, {
                  onClick: _ctx.updateradio,
                  modelValue: _ctx.perrow,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.perrow) = $event)),
                  val: "0",
                  label: _ctx.t('tags.auto')
                }, null, 8, ["onClick", "modelValue", "label"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      "top-right": _withCtx(() => [
        _createVNode(_component_q_select, {
          modelValue: _ctx.filtergroup,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filtergroup) = $event)),
          style: {"width":"200px"},
          dense: true,
          "options-dense": true,
          options: _ctx.groups,
          label: _ctx.t('tags.filterbytag')
        }, null, 8, ["modelValue", "options", "label"])
      ]),
      "bottom-row": _withCtx(() => [
        _createVNode(_component_q_btn, {
          color: "grey-7",
          "icon-right": "archive",
          label: _ctx.t('tags.exporttocsv'),
          "no-caps": "",
          onClick: _ctx.exportTable
        }, null, 8, ["label", "onClick"])
      ]),
      item: _withCtx((props) => [
        (_ctx.perrow==6)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_TagCard, {
                id: props.row.id,
                viewtype: props.row.viewtype,
                usehistory: props.row.usehistory,
                useevents: props.row.useevents,
                name: props.row.name,
                description: props.row.description,
                tagvalue: props.row.tagvalue,
                unit: props.row.unit,
                datetime: props.row.datetime,
                icon: props.row.icon,
                color: props.row.colorstr,
                bgcolor: props.row.bgcolorstr,
                minvalue: props.row.minvalue,
                maxvalue: props.row.maxvalue,
                usevalue: props.row.usevalue,
                decimalpos: props.row.decimalpos,
                path: props.row.path
              }, null, 8, ["id", "viewtype", "usehistory", "useevents", "name", "description", "tagvalue", "unit", "datetime", "icon", "color", "bgcolor", "minvalue", "maxvalue", "usevalue", "decimalpos", "path"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.perrow==4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_TagCard, {
                id: props.row.id,
                viewtype: props.row.viewtype,
                usehistory: props.row.usehistory,
                useevents: props.row.useevents,
                name: props.row.name,
                description: props.row.description,
                tagvalue: props.row.tagvalue,
                unit: props.row.unit,
                datetime: props.row.datetime,
                icon: props.row.icon,
                color: props.row.colorstr,
                bgcolor: props.row.bgcolorstr,
                minvalue: props.row.minvalue,
                maxvalue: props.row.maxvalue,
                usevalue: props.row.usevalue,
                decimalpos: props.row.decimalpos,
                path: props.row.path
              }, null, 8, ["id", "viewtype", "usehistory", "useevents", "name", "description", "tagvalue", "unit", "datetime", "icon", "color", "bgcolor", "minvalue", "maxvalue", "usevalue", "decimalpos", "path"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.perrow==3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_TagCard, {
                id: props.row.id,
                viewtype: props.row.viewtype,
                usehistory: props.row.usehistory,
                useevents: props.row.useevents,
                name: props.row.name,
                description: props.row.description,
                tagvalue: props.row.tagvalue,
                unit: props.row.unit,
                datetime: props.row.datetime,
                icon: props.row.icon,
                color: props.row.colorstr,
                bgcolor: props.row.bgcolorstr,
                minvalue: props.row.minvalue,
                maxvalue: props.row.maxvalue,
                usevalue: props.row.usevalue,
                decimalpos: props.row.decimalpos,
                path: props.row.path
              }, null, 8, ["id", "viewtype", "usehistory", "useevents", "name", "description", "tagvalue", "unit", "datetime", "icon", "color", "bgcolor", "minvalue", "maxvalue", "usevalue", "decimalpos", "path"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.perrow==0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_TagCard, {
                id: props.row.id,
                viewtype: props.row.viewtype,
                usehistory: props.row.usehistory,
                useevents: props.row.useevents,
                name: props.row.name,
                description: props.row.description,
                tagvalue: props.row.tagvalue,
                unit: props.row.unit,
                datetime: props.row.datetime,
                icon: props.row.icon,
                color: props.row.colorstr,
                bgcolor: props.row.bgcolorstr,
                minvalue: props.row.minvalue,
                maxvalue: props.row.maxvalue,
                usevalue: props.row.usevalue,
                decimalpos: props.row.decimalpos,
                path: props.row.path
              }, null, 8, ["id", "viewtype", "usehistory", "useevents", "name", "description", "tagvalue", "unit", "datetime", "icon", "color", "bgcolor", "minvalue", "maxvalue", "usevalue", "decimalpos", "path"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["rows", "columns", "filter", "grid", "hide-header", "filter-method", "pagination", "rows-per-page-label", "no-data-label"])
  ]))
}
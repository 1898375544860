import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { MessageDialogBox } from '@/model/project/messagedialogbox';


@Module({ name:"MessageDialogBox", dynamic:true, store})
class MessageDialogBoxModule extends VuexModule {
  public messageDialogBox:MessageDialogBox;
    @Mutation
    public setMessageDialogBox(box:MessageDialogBox){
      this.messageDialogBox = box;
    }
    @Mutation
    public setMessageEnabled(value:boolean){
    
      if (this.messageDialogBox!=null)
        this.messageDialogBox.enable = value;
    }
    
    get getDialogEnable():boolean{
      if (this.messageDialogBox!=null)
        return this.messageDialogBox.enable;
      return false;
    }
    get getDialogTitle():string{
        if (this.messageDialogBox==null) return "";
        return this.messageDialogBox.title
    }
    get getDialogMessage():string{
        if (this.messageDialogBox==null) return "";
        return this.messageDialogBox.message
    }
}

export default getModule(MessageDialogBoxModule);
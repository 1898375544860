import { SVGObject } from "../svgobject";

export class SVGFood6 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 45.382 112.5" enable-background="new 0 0 45.382 112.5" xml:space="preserve">
        <g id="Group_Digital_Box">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="30.8765" y1="28.8745" x2="14.5037" y2="-6.2369">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#B2B2B2" />
                <stop offset="1" style="stop-color:#E0E0E0" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.1" d="M1.126,0.001h43.129v22.635H1.126V0.001z" />
        </g>
        <g id="Group_Support">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M39.3,22.636h2.479v75.448H39.3V22.636z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M3.491,22.636h2.478v75.448H3.491V22.636z" />
        </g>
        <g id="Group_Blue_Label">
            <path fill="#9EADCC" d="M18.806,8.222h7.883v3.604h-7.883V8.222z" />
        </g>
        <g id="Group_Red_Label">
            <path fill="#B23200" d="M2.478,19.033h4.617v2.477H2.478V19.033z" />
        </g>
        <g id="Group_Mixer_Unit">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="16.5537" y1="34.5728" x2="28.7153" y2="34.5728">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M28.715,23.762v21.621H16.554V23.762H28.715z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="20.1572" y1="34.5728" x2="25.1118" y2="34.5728">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M25.112,23.762v21.621h-4.955V23.762H25.112z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="29.8413" y1="34.5728" x2="34.5718" y2="34.5728">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M34.572,23.762v21.621h-4.73V23.762H34.572z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="10.6978" y1="34.5728" x2="15.4277" y2="34.5728">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M15.428,23.762v21.621h-4.73V23.762H15.428z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="7.0942" y1="23.1992" x2="37.7241" y2="23.1992">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M37.724,23.762v-1.126H7.094v1.126H37.724z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="16.5537" y1="58.6709" x2="28.7153" y2="58.6709">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M28.715,47.86v21.621H16.554V47.86H28.715z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="4.73" y1="46.6221" x2="40.6519" y2="46.6221">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" stroke="#4C4C4C" stroke-width="0.1" d="M4.73,47.86v-2.477h35.922v2.477H4.73" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="21.5083" y1="56.1938" x2="23.7612" y2="56.1938">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M23.761,47.86v16.667h-2.253V47.86H23.761z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.1" d="M35.697,68.131H9.572v-3.604h2.252v1.351h8.333v-1.351h4.955v1.351   h8.333v-1.351h2.252V68.131z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="20.8325" y1="66.3291" x2="24.436" y2="66.3291">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M24.436,64.527v3.604h-3.604v-3.604H24.436z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="10.1348" y1="66.3291" x2="11.1484" y2="66.3291">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M11.148,64.527v3.604h-1.014v-3.604H11.148z" />
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="33.896" y1="66.3291" x2="35.2466" y2="66.3291">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M35.247,64.527v3.604h-1.351v-3.604H35.247z" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="10.1348" y1="56.1938" x2="11.3735" y2="56.1938">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_13_)" d="M11.374,47.86v16.667h-1.239V47.86H11.374z" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="33.896" y1="56.1938" x2="35.2466" y2="56.1938">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_14_)" d="M35.247,47.86v16.667h-1.351V47.86H35.247z" />
        </g>
        <g id="Group_Mixer_sub">
            <path fill="#4D4D4D" d="M4.729,99.211h35.922v-1.127H4.729V99.211z" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="4.7295" y1="83.7832" x2="40.6519" y2="83.7832">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_15_)" d="M4.729,98.084V69.481h35.922v28.603H4.729" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M20.382,98.084h4.729v4.73h-4.729V98.084z" />
            <path fill="#4D4D4D" d="M21.508,75.338h2.253v3.604h-2.253V75.338z" />
            <path fill="#666666" d="M5.968,70.607h1.126v3.604H5.968V70.607z" />
            <path fill="#666666" d="M38.175,70.607H39.3v3.604h-1.125V70.607z" />
            <path fill="#B2B2B2" d="M16.554,70.607h1.351v3.604h-1.351V70.607z" />
            <path fill="#B2B2B2" d="M27.365,70.607h1.351v3.604h-1.351V70.607z" />
        </g>
        <g id="Group_Stand">
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M2.478,112.498v-2.59h1.914v-9.346H2.478v-2.479h6.193v2.479H6.644   v9.346h2.027v2.59H2.478z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M36.598,112.498v-2.59h2.026v-9.346h-2.026v-2.479h6.306v2.479   h-2.026v9.346h2.026v2.59H36.598z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M0,112.498v-2.59h2.478v-9.346H0v-2.479h7.094v2.479H4.729v9.346   h2.365v2.59H0z" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M38.175,112.498v-2.59h2.477v-9.346h-2.477v-2.479h7.207v2.479   h-2.479v9.346h2.479v2.59H38.175z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.609375, 99.0, 1.0, 0.25, 191.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Support",[0.0, 0.40625, 151.0, 1.0, 0.40625, 151.0]);
		this.fillGradient(this.fills,"Group_Blue_Label",[0.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Red_Label",[0.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_Mixer_sub",[0.0, 0.5989583333333334, 0.0, 1.0, 0.40625, 151.0, 2.0, 0.5989583333333334, 0.0, 3.0, 0.7916666666666666, 0.0, 4.0, 0.7916666666666666, 0.0, 5.0, 0.609375, 99.0, 6.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Stand",[0.0, 0.7916666666666666, 0.0, 1.0, 0.7916666666666666, 0.0, 2.0, 0.20833333333333334, 201.0, 3.0, 0.20833333333333334, 201.0]);

        super.drawObject();
    }
}
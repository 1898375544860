
import { useI18n } from 'vue-i18n';
import { defineComponent, ref} from 'vue'
import AuthModule from './store/auth.module'
import user from './model/user'
import { useRoute } from 'vue-router';
import useQuasar from 'quasar/src/composables/use-quasar.js';
import settingsItems from './utils/settingsItems'

export default defineComponent({
  
  setup(){  
    const {t} = useI18n({useScope:'global'})
    const route = useRoute()
    const q = useQuasar()
    const leftDrawerOpen = ref(false)
    let options = ref(settingsItems)

    
    
    return{
      route,
      t,
      q,
      leftDrawerOpen,
      options
    }
  },

 computed: {

  currentUser():user {
      console.log('computed')
      
      return AuthModule.currentUser;
    },
  
    showAdminBoard():boolean {
      if (this.currentUser && this.currentUser['role']&& this.route.name!='authenticate') {
        return this.currentUser['role']=='ADMIN';
      }
      return false;
    },
    showHomeBoard():boolean {
     
      if (this.currentUser && this.currentUser['role'] && this.route.name!='authenticate' ) {
        return true;
      }
      return false;
    },
    showScreens():boolean {
      if (this.currentUser && this.currentUser['role']&& this.route.name!='authenticate') {
        return true;
      }
      return false;
    },
    showProfile():boolean {
      if (this.currentUser && this.currentUser['role']&& this.route.name!='authenticate') {
        return (this.currentUser['role']=='MODERATOR' || this.currentUser.role=="ADMIN");
      }
      return false;
    },
    notAuthenticate():boolean{
      if (this.route.name=='authenticate')
        window.removeEventListener('beforeunload',this.leaving)    
      else
        window.addEventListener('beforeunload', this.leaving);
      if (this.route.name!='authenticate'){
        return true
      }
      return false;
    }
    
  }, 
  watch: {
        currentUser(newValue) {
            console.log(`yes, computed property changed: ${newValue}`);
        },

        /*q: {
            handler(newValue, oldValue) {
              console.log('handler newValue oldValue', newValue, oldValue)
                if(newValue.screen.width <700 && oldValue.screen.width <700){
                  return
                }else if(oldValue.screen.width >=700 && newValue.screen.width <700){
                  console.log("GO TO MOBILE this.route.name", this.route.name)
                  if(this.route.name === "usersettings") this.$router.push("home")
                  else this.$router.push(this.route.name)
                  
                }else if(oldValue.screen.width >=700 && newValue.screen.width >=700){
                  return
                }else if(oldValue.screen.width<700 && newValue.screen.width >=700){
                  console.log("GO TO DESKTOP this.route.name", this.route.name)
                  if(this.route.name === "usersettingsmobile") this.$router.push("home")
                  else this.$router.push(this.route.name)
                } 
            },
            deep: true
        }*/
    },
  mounted():void {
       //if (!this.currentUser)
        //this.$router.push('/login');
        console.log("mounted")
    },
  created():void{
    console.log("created")
    window.addEventListener('beforeunload', this.leaving);
  },
  methods: {
    
    toggleLeftDrawer ():void {
      this.leftDrawerOpen = !this.leftDrawerOpen
    },

    isItReallyMobile ():boolean {
      return this.q.screen.width <700;
    }, 

    leaving(e) {
  
        //e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";

      
      try {
        //localStorage.removeItem("doneLogout");
        AuthModule.clientdisconnect();
        //window.alert("Sure to leave?");
      } catch (e) {
        console.log(`Error while logging out: ${e}`);
      }
    },
    async logOut() {
      
      await AuthModule.signout();
      this.$router.push('/login');
      console.log('logout', this.currentUser)
    }
  }
})

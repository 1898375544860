import { SVGObject } from "../svgobject";

export class SVGSensor18 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 74.102" enable-background="new 0 0 112.5 74.102" xml:space="preserve">
        <g id="Group_Connector">
            <path fill="#1A1A1A" d="M7.207,54.168h11.374v1.014H7.207V54.168z" />
            <path fill="#1F1F1F" stroke="#4C4C4C" stroke-width="0.25" d="M20.045,67.569h1.352v4.617h-1.352V67.569z" />
        </g>
        <g id="Group_Adjusters">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="3.3779" y1="69.8772" x2="22.2969" y2="69.8772">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#666666" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.378,72.186v-4.617h18.919v4.617H3.378z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M19.032,71.286V68.47h1.013v2.815L19.032,71.286L19.032,71.286z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M17.229,71.286V68.47h0.901v2.815L17.229,71.286L17.229,71.286z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M3.941,71.286V68.47h0.901v2.815L3.941,71.286L3.941,71.286z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,71.286V68.47h0.901v2.815L5.743,71.286L5.743,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M7.658,71.286V68.47h0.901v2.815L7.658,71.286L7.658,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,71.286V68.47h1.014v2.815L9.459,71.286L9.459,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M11.374,71.286V68.47h0.901v2.815L11.374,71.286L11.374,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M13.401,71.286V68.47h1.014v2.815L13.401,71.286L13.401,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M15.315,71.286V68.47h0.901v2.815L15.315,71.286L15.315,71.286z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,67.119h3.266v5.519h-3.266V67.119z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="3.3779" y1="61.262" x2="22.2969" y2="61.262">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#666666" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M3.378,63.627v-4.729h18.919v4.729H3.378z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M20.946,71.286V68.47h0.901v2.815L20.946,71.286L20.946,71.286z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M20.946,62.615V59.8h0.901v2.814H20.946z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M19.032,62.615V59.8h1.013v2.814H19.032z" />
            <path fill="#191919" stroke="#4C4C4C" stroke-width="0.25" d="M17.229,62.615V59.8h0.901v2.814H17.229z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M3.941,62.615V59.8h0.901v2.814H3.941z" />
            <path fill="#4C4C4C" stroke="#4C4C4C" stroke-width="0.25" d="M5.743,62.615V59.8h0.901v2.814H5.743z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M7.658,62.615V59.8h0.901v2.814H7.658z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M9.459,62.615V59.8h1.014v2.814H9.459z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M11.374,62.615V59.8h0.901v2.814H11.374z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M13.401,62.615V59.8h1.014v2.814H13.401z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M15.315,62.615V59.8h0.901v2.814H15.315z" />
        </g>
        <g id="Group_Vision_Unit">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.167" y1="65.5979" x2="21.6221" y2="65.5979">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M4.167,67.569v-3.94h17.455v3.94H4.167z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="3.3779" y1="73.1428" x2="22.2969" y2="73.1428">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M22.297,72.186L20.496,74.1H5.293l-1.915-1.914H22.297z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="24.437" y1="71.9612" x2="24.437" y2="67.7942">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M22.748,67.794h3.378v4.167h-3.378V67.794z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="28.0962" y1="71.2864" x2="28.0962" y2="68.2454">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M26.126,68.245h3.941v3.041h-3.941V68.245z" />
        </g>
        <g id="Group_Boom_Lock">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="13.7388" y1="22.1292" x2="18.8062" y2="22.1292">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M13.739,24.776v-5.293h5.067v5.293H13.739" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="3.3779" y1="57.0393" x2="22.2969" y2="57.0393">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.51" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M3.378,58.898v-3.716h18.919v3.716H3.378" />
        </g>
        <g id="Group_Machine_Unit">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="66.2725" y1="36.4875" x2="66.2725" y2="29.7307">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#666666" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M20.045,29.731H112.5v6.757H20.045V29.731z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="5.0669" y1="39.4719" x2="20.7202" y2="39.4719">
                <stop offset="0.01" style="stop-color:#1F1F1F" />
                <stop offset="0.51" style="stop-color:#666666" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M5.067,54.168V24.776H20.72v29.393H5.067z" />
        </g>
        <g id="Group_Pipes">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,35.362l-3.828,0.789l-3.83,1.464L100,39.641l-2.814,1.238   l-2.815,1.239l-3.041,1.352l-3.152,1.464L84.91,46.51l-3.379,1.577l-3.49,1.577l-3.379,1.688l-3.49,1.577l-3.492,1.688l-3.49,1.576   l-3.379,1.689l-3.377,1.576l-3.267,1.463l-3.266,1.465l-2.928,1.353l-2.928,1.352l-5.292,2.252l-4.392,1.689l-3.378,1.125   l-1.239,0.338l-0.901,0.112v-0.225V68.92l3.716-0.788l3.941-1.464l4.842-2.027l2.703-1.238l2.928-1.237l2.928-1.353l3.266-1.465   l3.267-1.576l3.377-1.576l3.379-1.576l3.49-1.688l3.492-1.577l3.49-1.688l3.379-1.577l3.49-1.689l3.379-1.576l3.266-1.464   l3.152-1.465l3.041-1.463l2.814-1.239l5.293-2.252l4.393-1.688l3.379-1.126l1.238-0.337l1.014-0.113v0.225V35.362z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M15.315,19.483v-1.688l-0.113-1.352l-0.112-1.464l-0.338-1.688   l-0.226-1.803l-0.45-1.688l-0.45-1.689l-0.675-1.576l-0.789-1.465L11.374,3.83l-1.126-1.015L9.122,2.141L7.771,1.804H6.194   L4.392,2.367L3.378,2.817l-0.9,1.015L1.915,5.182L1.802,6.308v1.239l0.113,1.351l0.225,1.464l0.338,1.465l0.45,1.688l0.451,1.577   l0.563,1.689l0.563,1.688l0.563,1.689l0.563,1.688l0.563,1.689l0.225,0.789l0.225,0.675H6.532H5.067l-0.338-1.464l-0.45-1.239   l-0.451-1.464l-0.563-1.577L2.59,17.682l-0.676-1.688l-0.563-1.803l-0.563-1.802L0.338,10.7L0.113,8.898L0,8.11V7.321V6.533   l0.225-1.577l0.451-1.464l0.676-1.239L2.478,1.24l1.239-0.563l1.239-0.45l1.126-0.226h1.014h1.013L9.91,0.339l1.464,0.901   l1.239,1.238l1.126,1.464l0.901,1.689l0.338,0.901l0.338,1.014l0.338,1.013l0.225,0.901l0.338,2.026l0.338,1.914l0.225,1.803   l0.113,1.577v1.352l0.112,0.45v0.563v0.337H15.315z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.7916666666666666, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.7916666666666666, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.703125, 75.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.703125, 75.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.703125, 75.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.703125, 75.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.01, 0.5989583333333334, 0.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.7916666666666666, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.01, 0.23958333333333334, 0.0, 0.51, 0.7916666666666666, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Pipes",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0]);

        super.drawObject();
    }
}
import { VuexModule,getModule, Module, Mutation} from 'vuex-module-decorators';
import store from './index'
import { format } from 'date-fns'
import { HistoryTable } from '@/model/project/objects/historydbtable';
import { Ingredient } from '@/model/project/objects/properties/range/ingredient';

@Module({ name:"HistoryTableDialogBox", dynamic:true, store})
class HistoryTableDialogBoxModule extends VuexModule {
  public historytable:HistoryTable;
  public enabled:boolean;
  public beginstring:string;
  public endstring:string;
  public historydbname:string;
  public decimalpos:number;
  public orderby:number;
  public type:number;
  public timerformat:string;
  public ingredients:Ingredient[];
  

    @Mutation
    public setHistoryTable(historytable:HistoryTable){
        if (historytable==null) return;
      this.historytable = historytable;
      this.beginstring = format(historytable.begin, "yyyy-MM-dd HH:mm")
      this.endstring = format(historytable.end, "yyyy-MM-dd HH:mm")
    }
    @Mutation
    public setHistoryTableDialogEnabled(value:boolean){
      this.enabled = value
    }
   @Mutation
   public setHistoryDBnamestring(value:string){
    this.historydbname =value;
   }
   @Mutation
   public setDecimalpos(value:number){
    this.decimalpos =value;
   }
   @Mutation setOrderby(value:number){

    this.orderby = value;
    console.log(this.orderby)
   }
   @Mutation setType(value:number){
    this.type = value
   }
   @Mutation setTimeFormat(value:string){
    this.timerformat = value
   }
   @Mutation setIngredients(value:Ingredient[]){
      this.ingredients = value
   }
   get getIngredients():Ingredient[]{
      return this.ingredients
   }
    get getDecimalpos():number{
      return this.decimalpos
    }
    get getOrderby():number{
      return this.orderby
    }
    get getType():number{
      return this.type
    }
    get getTimeFormat():string{
      return this.timerformat
    }
    get getHistoryTableDialogEnable():boolean{   
      return this.enabled;
    }
    get getHistoryTableBegin():string{
        return this.beginstring
    }
    get getHistoryDBnamestring():string{
      return this.historytable.historydbname;
    }
    get getHistoryTableEnd():string{
        return this.endstring
    }

    @Mutation
    public setHistoryTableBegin(value:string){ 
      this.beginstring = value;
    }
    @Mutation
    public setHistoryTableEnd(value:string){
     this.endstring = value
    }
    

    @Mutation
    public updateHistoryTable(){
        if (this.historytable==null) return;
        this.historytable.begin = Date.parse(this.beginstring)
        this.historytable.end = Date.parse(this.endstring)
      
        this.historytable.drawObject()
    }
}

export default getModule(HistoryTableDialogBoxModule);
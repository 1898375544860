import { SVGObject } from "../svgobject";

export class SVGWater44 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 111.938" enable-background="new 0 0 112.5 111.938" xml:space="preserve">
        <g id="Group_FilterBox">
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M0.451,111.487h102.365V9.123H0.451V111.487z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M1.464,111.487h101.352v-2.252H1.464V111.487z" />
            <path fill="#6060E0" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,104.055l-9.122,7.432V9.123l9.122-9.122V104.055z" />
            <path fill="#6060E0" stroke="#4C4C4C" stroke-width="0.25" d="M112.162,104.505l-9.121,7.433V9.573l9.121-9.122V104.505z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M17.004,0.001h94.933l-9.122,9.122H0.451L17.004,0.001z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,104.055l-9.122,7.432v-2.252l9.122-7.432V104.055z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,104.505l-9.234,7.433v-2.252l9.234-7.434V104.505z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0,11.6h102.815V9.348H0V11.6z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M111.938,2.141l-9.122,9.459V9.348l9.122-9.347V2.141z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,2.704l-9.234,9.347V9.798l9.234-9.347V2.704z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0,43.019h102.815v-2.252H0V43.019z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.162,33.785l-9.347,9.234v-2.252l9.347-9.122V33.785z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,34.348l-9.234,9.122v-2.252l9.234-9.122V34.348z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0,76.803h102.815V74.55H0V76.803z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.162,68.469l-9.347,8.334V74.55l9.347-8.333V68.469z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M112.5,68.92l-9.234,8.445v-2.252l9.234-8.446V68.92z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0.113,12.05h103.153V9.798H0.113V12.05z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0.113,43.469h103.153v-2.252H0.113V43.469z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M0.113,77.366h103.153v-2.252H0.113V77.366z" />
            <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M1.689,111.938h101.352v-2.252H1.689V111.938z" />
        </g>
        <g id="Group_Lever">
            <path fill="#0000AD" stroke="#4C4C4C" stroke-width="0.25" d="M64.527,69.708l-4.055,2.479l-5.18,0.9l-4.842-0.9l-4.279-2.816   l-2.928-4.166l-1.014-5.18l1.014-5.181l2.928-3.942l50.225-35.36l1.014-0.563l0.9-0.225l1.352,0.225l0.9,0.563l0.789,0.901   l0.225,1.351l-0.225,1.239L64.527,69.708z" />
            <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M63.514,69.708l-3.941,2.479l-5.18,0.9l-5.293-0.9l-4.167-2.816   l-2.703-4.166l-1.013-5.18l1.013-5.181l2.703-3.942l50.226-35.36l0.9-0.563l1.352-0.225l1.126,0.225l1.126,0.563l0.676,0.901   l0.225,1.351l-0.449,1.239L63.514,69.708z" />
            <radialGradient id="SVGID_1_" cx="97.4102" cy="17.7935" r="1.4639" gradientUnits="userSpaceOnUse">
                <stop offset="0.5" style="stop-color:#0000FF" />
                <stop offset="1" style="stop-color:#0000C8" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" cx="97.41" cy="17.793" r="1.464" />
            <radialGradient id="SVGID_2_" cx="54.1665" cy="60.0229" r="13.1755" gradientUnits="userSpaceOnUse">
                <stop offset="0.5" style="stop-color:#0000FF" />
                <stop offset="1" style="stop-color:#0000C8" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" cx="54.167" cy="60.023" r="13.176" />
        </g>
        <g id="Group_ControlPanel">
            <g>
                <path fill="#A1A1FF" stroke="#4C4C4C" stroke-width="0.25" d="M2.365,52.816h28.266V24.663H2.365V52.816z" />
                <path fill="#6060E0" stroke="#4C4C4C" stroke-width="0.25" d="M33.334,50.901l-2.703,1.915V24.663l2.703-2.703V50.901z" />
                <path fill="#7F7FFF" stroke="#4C4C4C" stroke-width="0.25" d="M6.869,21.96h26.238l-2.477,2.703H2.365L6.869,21.96z" />
            </g>
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M23.874,42.005h1.464v1.689h-1.464V42.005z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M23.874,35.249h1.464v1.577h-1.464V35.249z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M23.874,28.379h1.464v1.464h-1.464V28.379z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M16.441,42.005h1.577v1.689h-1.577V42.005z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M16.441,35.249h1.577v1.577h-1.577V35.249z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M16.441,28.379h1.577v1.464h-1.577V28.379z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,42.005h1.464v1.689H8.896V42.005z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,35.249h1.464v1.577H8.896V35.249z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M8.896,28.379h1.464v1.464H8.896V28.379z" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,46.96l1.689,0.676l0.451,1.464l-0.451,1.802l-1.689,0.451   l-1.464-0.451L9.347,49.1l0.789-1.464L11.599,46.96z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M11.599,49.438l0.676-1.576" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.135,48.875h0.225" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M10.811,47.861v0.337" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.063,47.861l-0.338,0.337" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M13.288,49.438h-0.225" />
            <path fill="#FFFFFF" stroke="#4C4C4C" stroke-width="0.25" d="M20.496,46.96l-1.802,0.676L18.243,49.1l0.451,1.802l1.802,0.451   l1.464-0.451l0.676-1.802l-0.676-1.464L20.496,46.96z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M20.496,49.438l-0.789-1.576" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.959,48.875h-0.225" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.171,47.861l-0.226,0.337" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.032,47.861l0.225,0.337" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.468,49.438h0.563" />
        </g>
        <g id="Group_Button2">
            <path d="M5.405,34.123l0.676,0.225l0.563,0.676l-0.563,0.788l-0.676,0.225l-0.788-0.225l-0.225-0.788l0.225-0.676L5.405,34.123z" />
            <path fill="#FF0000" d="M5.405,34.123l0.676,0.45l0.338,0.451l-0.338,0.788l-0.676,0.225l-0.563-0.225l-0.225-0.788l0.225-0.451   L5.405,34.123z" />
        </g>
        <g id="Group_Button1">
            <path d="M5.405,37.275l0.676,0.226l0.563,0.788l-0.563,0.676l-0.676,0.338l-0.788-0.338l-0.225-0.676l0.225-0.788L5.405,37.275z" />
            <path fill="#00FF00" d="M5.405,37.275l0.676,0.226l0.338,0.788l-0.338,0.45l-0.676,0.226l-0.563-0.226l-0.225-0.45l0.225-0.788   L5.405,37.275z" />
        </g>
        <g id="Group_Supply">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M21.509,52.366v26.689l0.901,4.729l2.815,4.167l3.378,2.928l4.054,1.352   h31.306" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M23.874,52.366v25.676l1.013,4.279l2.478,3.716l3.266,2.702l3.716,1.239   h29.617" />
            <path fill="#4D4D4D" d="M59.572,98.086l12.611,2.027v1.463h4.393l-0.225-1.238h14.527l0.338,1.465l3.715-0.227v-1.801l-3.49-4.617   l-30.406-1.014L59.572,98.086z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="77.7207" y1="98.0864" x2="77.7207" y2="89.4146">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M68.561,89.415h18.32v8.672h-18.32V89.415z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="91.9424" y1="97.9741" x2="91.9424" y2="88.9653">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.5" style="stop-color:#ECECEC" />
                <stop offset="0.57" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M92.229,88.965h-5.076v9.009h5.076c2.488,0,4.504-2.017,4.504-4.505   C96.732,90.982,94.717,88.965,92.229,88.965z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="86.8799" y1="99.7759" x2="86.8799" y2="87.7261">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M87.725,87.726h-1.689v12.05h1.689V87.726z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="62.4639" y1="98.0864" x2="62.4639" y2="89.4146">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M65.582,89.415h-6.236v8.672h6.236V89.415z" />
            <path fill="#4D4D4D" d="M64.977,98.029h-4.504v0.62h4.504V98.029z" />
            <path fill="#4D4D4D" d="M64.977,89.002h-4.504v0.412h4.504V89.002z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="68.2432" y1="99.1001" x2="68.2432" y2="88.5142">
                <stop offset="0" style="stop-color:#263A59" />
                <stop offset="0.54" style="stop-color:#A6BAD9" />
                <stop offset="0.63" style="stop-color:#93A7C6" />
                <stop offset="0.81" style="stop-color:#627695" />
                <stop offset="1" style="stop-color:#263A59" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M69.369,88.514h-2.252V99.1h2.252V88.514z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="59.3467" y1="99.1001" x2="59.3467" y2="88.5142">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M58.221,88.514h2.252V99.1h-2.252V88.514z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="66.0469" y1="99.1001" x2="66.0469" y2="88.5142">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M64.977,88.514h2.141V99.1h-2.141V88.514z" />
        </g>
        <g id="Group_Group1">
            <path fill="#00FFFF" d="M80.743,55.631h17.343l2.702,2.703v9.346l-2.702,2.479H80.743l-2.702-2.479v-9.346L80.743,55.631z" />
            <polygon fill="#595959" points="82.309,56.802 96.543,56.802 98.795,58.965 98.795,66.711 96.543,68.875 82.309,68.875    80.146,66.711 80.146,58.965  " />
            <path fill="#FFFFFF" d="M96.059,55.631h-2.365l-2.477,2.703l0.225,1.689l-2.027,3.04v1.914l-2.365,2.702l-2.814,2.027l-0.789,0.451   h4.73l2.252-1.238l1.802-3.267V63.29l1.915-2.816l-0.451-2.139L96.059,55.631z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.5, 0.6614583333333334, 0.0, 1.0, 0.5104166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.5, 0.6614583333333334, 0.0, 1.0, 0.5104166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4739583333333333, 0.0, 0.54, 0.5208333333333334, 121.0, 0.63, 0.671875, 83.0, 0.81, 0.9427083333333334, 0.0, 1.0, 0.4739583333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.5, 0.16145833333333334, 214.0, 0.57, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_ControlPanel",[0.0, 0.0, 255.0, 1.0, 0.0, 255.0, 2.0, 0.0, 255.0, 3.0, 0.0, 255.0, 4.0, 0.0, 255.0, 5.0, 0.0, 255.0, 6.0, 0.0, 255.0, 7.0, 0.0, 255.0, 8.0, 0.0, 255.0, 9.0, 0.0, 255.0, 10.0, 0.0, 255.0]);
		this.fillGradient(this.fills,"Group_FilterBox",[0.0, 0.6822916666666666, 81.0, 1.0, 0.5, 127.0, 2.0, 0.921875, 19.0, 3.0, 0.921875, 19.0, 4.0, 0.6822916666666666, 81.0, 5.0, 0.5, 127.0, 6.0, 0.5, 127.0, 7.0, 0.5, 127.0, 8.0, 0.5, 127.0, 9.0, 0.5, 127.0, 10.0, 0.5, 127.0, 11.0, 0.5, 127.0, 12.0, 0.5, 127.0, 13.0, 0.5, 127.0, 14.0, 0.5, 127.0, 15.0, 0.5, 127.0, 16.0, 0.5, 127.0, 17.0, 0.5, 127.0, 18.0, 0.5, 127.0, 19.0, 0.5, 127.0]);
		this.fillGradient(this.fills,"Group_Lever",[0.0, 0.4427083333333333, 0.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.fills,"Group_Button2",[0.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Button1",[0.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Supply",[0.0, 0.5989583333333334, 0.0, 1.0, 0.5989583333333334, 0.0, 2.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Group1",[0.0, 0.671875, 83.0, 1.0, 0.6927083333333334, 0.0, 2.0, 0.0, 255.0]);

        super.drawObject();
    }
}
import { ColorProperty } from "../properties/colorproperty";
import { TextColorProperty } from "../properties/textcolorproperty";
import { StringUtils } from "../../utils/stringutils";
import Konva from "konva";
import {ColorUtils} from '@/model/project/utils/colorutils'
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";
import { BarChartTile } from "./barcharttile";


export class DonutChartTile extends BarChartTile{
    
  public drawObject():void{
    super.initObject();

    let bgcolor = '0xffffff00';         
    bgcolor = this.bgcolor;
    const colorprop = this.properties['color'];
    if (colorprop!=null){	
      const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
        if (colorproperty!=null) 
            bgcolor = colorproperty.getColor(this.tagnames, this.bgcolor)     
    }

    let textcolor = this.textcolor;
    const textcolorprop = this.properties==null?null:this.properties['textcolor'];
    if (textcolorprop!=null){	
      const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
      if (textcolorproperty!=null) 
        textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
    }

    let amount = 0;	
    if(this.sectors.length != 0){
      this.sectors.forEach((sec)=>{
        const tag:tag = tagsModele.getTag(sec['path'])
        let tagvalue = 0
        if (tag!=null && tag.tagvalue!=null)
            tagvalue = Number(tag.tagvalue)

        if (sec['path']==null || tagsModele.getTag(sec['path'])==null) return;
        if (!this.tagnames.includes(sec['path'])) this.tagnames.push(sec['path'])
        amount+=Math.abs(tagvalue)
      })
    }
    //console.log('amount', amount)
    
    if(this.sectors.length > 1){
      this.sectors.sort(function(a, b) {
        if (a==null || b==null) return 0;
        const keyA = tagsModele.getTag(a['path'])
        const keyB = tagsModele.getTag(b['path'])
        if (keyA==null || keyB==null || keyA.tagvalue==null || keyB.tagvalue==null) return 0
        if (Number(keyA.tagvalue) < Number(keyB.tagvalue)) return 1;
        if (Number(keyA.tagvalue) > Number(keyB.tagvalue)) return -1;
        return 0;
      });
    }

    const size = this.width < this.height ? this.width : this.height;
    const width = this.width;
    const height = this.height;
    const group = new Konva.Group({
      clipFunc: function (ctx) {
        ctx.strokeStyle = "red";
        ctx.lineTo(0.05*size, 0);
        ctx.lineTo(width-0.05*size, 0);
        ctx.quadraticCurveTo(width, 0, width, 0.05*size);
        ctx.lineTo(width, height-0.05*size)
        ctx.quadraticCurveTo(width, height, width-0.05*size, height);
        ctx.lineTo(0.05*size, height)
        ctx.quadraticCurveTo(0, height, 0, height-0.05*size);
        ctx.lineTo(0, 0.05*size)
        ctx.quadraticCurveTo(0, 0, 0.05*size, 0);
      }
    });
     
    const rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: ColorUtils.convertformat(bgcolor)
    });
    group.add(rect)

    const titleText = new Konva.Text({
      x: size*.05,
      y: size*0.1 - 0.06*size,
      width: this.width-size*.05*2,
      verticalAlign: 'bottom',
      text: this.title,
      fontSize: 0.06*size,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'left',
    }); 
    group.add(titleText) 

    if(this.description){
      const text2 = new Konva.Text({
        x: size*.05,
        y: this.height - size*0.05 - 0.06*size,
        width: this.width-size*.05*2,
        verticalAlign: 'bottom',
        text: this.description,
        fontSize: 0.06*size,
        fontFamily: StringUtils.getFontFamily(this.fonttype),
        fontStyle: StringUtils.getFontStyle(this.fonttype),
        fill: ColorUtils.convertformat(textcolor),
        align: 'left',
      }); 
      group.add(text2) 
    }

    const canvasSize = (this.description != null || this.uselegends) ? size * 0.5 : size * 0.6;
    const radius = canvasSize * 0.5;
    const barWidth  = size*.09;
    let innerRadius = canvasSize * 0.35;
    let outerRadius = canvasSize * 0.60;

    const centerX = this.uselegends? this.width*2/3 : this.width/2;
    const centerY = size*.25+ radius;
    
   if (amount==0 && this.sectors.length != 0){
      this.sectors.forEach((sec)=>{
        sec['angle'] = 360/this.sectors.length
      }) 	
    }else{
      this.sectors.forEach((sec)=>{
        let val = 0;
        if (sec['path']==null || tagsModele.getTag(sec['path'])==null){
          val = 0;
        }else{
          const tag:tag = tagsModele.getTag(sec['path'])
          if (tag!=null && tag.tagvalue!=null){
            const tagvalue = Number(tag.tagvalue)
            val = Math.abs(tagvalue)
            //console.log('val', val)
            sec['angle'] = val/amount*360
          }
        } 
      })
    }

    let startangle = -90;
    if(this.sectors.length==0){
        const sector = new Konva.Arc({
          x: centerX,
          y: centerY,
          innerRadius: radius-barWidth/2,
          outerRadius: radius+barWidth/2,
          angle: 360,
          fill: 'red',
          rotation: startangle,
        })
        group.add(sector)
      }
      //console.log('this.sectors', this.sectors)
    this.sectors.forEach((sec)=>{
        const angle = startangle + (sec['angle'] * 0.5);
        const radValue = angle * (Math.PI / 180)
        const cosValue = Math.cos(radValue);
        const sinValue = Math.sin(radValue);

        const sector = new Konva.Arc({
          x: centerX,
          y: centerY,
          innerRadius: radius-barWidth/2,
          outerRadius: radius+barWidth/2,
          angle: sec['angle'],
          fill: ColorUtils.convertformat(sec['color']), 
          rotation: startangle,
        })
        group.add(sector)
        //console.log('sec[angle]', sec['angle'])
       
        const tag:tag = tagsModele.getTag(sec['path'])
        const percentText = ((Number((tag==null || tag.tagvalue==null)?0:tag.tagvalue)/amount*100).toFixed(this.decimalpos)).toString()

        const perTextTransparent = new Konva.Text({
          x: 0,
          y: 0,
          text: percentText,
          fontSize: barWidth*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
        });

      
        if (angle<0){
          innerRadius = canvasSize*0.35 - perTextTransparent.width()*1.1 +barWidth/2;
        }else if (angle<88){
          innerRadius = canvasSize * 0.35-perTextTransparent.width()+barWidth/2;
        }else if (angle>=88 && angle<92){
          innerRadius = canvasSize * 0.35-perTextTransparent.height()*0.7+barWidth/2;
        }else if (angle>=92 && angle<180){
          innerRadius = canvasSize * 0.35-perTextTransparent.width()*0.9+barWidth/2;
        }else if (angle >=180 && angle<240){
          innerRadius = canvasSize * 0.35-perTextTransparent.width()*1.3+barWidth/2;
        }else{
          innerRadius = canvasSize * 0.35 - perTextTransparent.height()*1.4+barWidth/2;
        }
        //console.log('innerRadius', innerRadius)
        //console.log('angle', angle)

        const x =centerX+innerRadius * cosValue;
        const y = centerY+innerRadius * sinValue;

        const perText = new Konva.Text({
          x: x-perTextTransparent.width()/2,
          y: y-barWidth*.5,
          width: perTextTransparent.width(),
          verticalAlign: 'bottom',
          text: percentText,
          fontSize: barWidth*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
          align: 'center',
        }); 
        group.add(perText) 

        const valTextTransparent = new Konva.Text({
          x: 0,
          y: 0,
          verticalAlign: 'bottom',
          text: ((tag==null || tag.tagvalue==null)?0:(Number(tag.tagvalue)).toFixed(this.decimalpos)).toString(),
          fontSize: barWidth*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
        }); 

    
      if (angle<0){
        outerRadius = canvasSize*0.55+valTextTransparent.width()/4+barWidth/2
      }else if (angle<88){
        outerRadius = canvasSize*0.60+barWidth/2//-valTextTransparent.height()
      }else if (angle>=88 && angle<92){
        outerRadius = canvasSize*0.55+valTextTransparent.height()/2+barWidth/2
      }else if (angle>=92 && angle<180){
        outerRadius = canvasSize*0.56+valTextTransparent.width()/2+barWidth/2
      }else if (angle >=180 && angle<240){
        outerRadius = canvasSize*0.55+barWidth/2
      }else{
        outerRadius = canvasSize * 0.53+barWidth/2
      }

        const xval =centerX+ outerRadius * cosValue;
        const yval = centerY+outerRadius * sinValue;

        const valText = new Konva.Text({
          x: xval-valTextTransparent.width()/2,
          y: yval-barWidth*.5,
          width: valTextTransparent.width(),
          verticalAlign: 'bottom',
          text: ((tag==null || tag.tagvalue==null)?0:(Number(tag.tagvalue)).toFixed(this.decimalpos)).toString(),
          fontSize: barWidth*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(textcolor),
          align: 'center',
        }); 
        group.add(valText) 

        startangle +=sec['angle']
    })

    const amountText = new Konva.Text({
      x: centerX-barWidth,
      y: centerY+barWidth*.4-barWidth*.8,
      width: 2*barWidth,
      verticalAlign: 'bottom',
      text: (amount.toFixed(this.decimalpos)).toString(),
      fontSize: barWidth*.8,
      fontFamily: StringUtils.getFontFamily(this.fonttype),
      fontStyle: StringUtils.getFontStyle(this.fonttype),
      fill: ColorUtils.convertformat(textcolor),
      align: 'center',
    }); 
    group.add(amountText) 

    if (this.uselegends) {
       const stepY = size/2/this.sectors.length
       for (let i=0;i<this.sectors.length;i++){
        const sec = this.sectors[i];
        const circle = new Konva.Circle({
          x: stepY*0.2,
          y: this.height*.35+stepY*i,
          radius: stepY*.1,
          fill: ColorUtils.convertformat(sec.color)
        })
        group.add(circle)
        
        const label = new Konva.Text({
          x: stepY*.4,
          y: this.height*.35+stepY*i+ stepY*.1-barWidth*.5,
          verticalAlign: 'bottom',
          text: sec.sectorname,
          fontSize: barWidth*.5,
          fontFamily: StringUtils.getFontFamily(this.fonttype),
          fontStyle: StringUtils.getFontStyle(this.fonttype),
          fill: ColorUtils.convertformat(sec.color),
          align: 'left',
        }); 
        group.add(label) 
      } 
   } 
    this.node.add(group)
  }
}
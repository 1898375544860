import { SVGObject } from "../svgobject";

export class SVGSegpipe4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_90_Bend">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="53.5762" y1="56.542" x2="89.4488" y2="23.0902">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M59.716,62.624l42.957-25.353L75.367,9.707L50.422,53.159" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="44.3276" y1="51.6641" x2="56.9065" y2="4.719">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M37.749,49.901l12.29,3.089L75.688,9.751L38.016,0.101L37.749,49.901z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="62.0859" y1="72.4521" x2="109.144" y2="55.3244">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M59.808,62.858l3.062,11.746l49.366-0.261l-9.566-36.809L59.808,62.858z" />
            
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="114.4053" y1="42.0547" x2="149.6598" y2="77.3092" gradientTransform="matrix(-0.7071 -0.7071 0.7071 -0.7071 70.5068 160.501)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M38.695,49.782L0.047,49.868L0,0.012l38.65,0L38.695,49.782z" />
            
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-18.1787" y1="88.6797" x2="17.0758" y2="123.9342" gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 12.7803 17.0928)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.5" style="stop-color:#99CCCC" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M62.719,112l-0.087-38.648l49.856-0.047v38.65L62.719,112z" />
        </g>
        <g id="Group_Layer_2">
            
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="83.0498" y1="173.3848" x2="83.2751" y2="123.6099" gradientTransform="matrix(-1 0.0045 -0.0045 -1 121.5926 173.05)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_6_)" points="37.156,0.039 38.344,0.042 38.366,49.811 37.213,49.814  " />
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="120.2119" y1="173.5908" x2="120.4376" y2="123.732" gradientTransform="matrix(-1 0.0045 -0.0045 -1 121.5926 173.05)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="0.014,0 1.186,0.009 1.162,49.835 0.037,49.86  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="1.363" y1="49.849" x2="1.408" y2="0.021" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="38.62" y1="49.854" x2="38.666" y2="0.027" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-43.3926" y1="112.0342" x2="-43.1673" y2="62.2603" gradientTransform="matrix(-0.0045 -1 1 -0.0045 0.2312 68.1785)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="112.461,110.461 112.459,111.648 62.689,111.671 62.686,110.518  " />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-6.231" y1="112.2402" x2="-6.0053" y2="62.3823" gradientTransform="matrix(-0.0045 -1 1 -0.0045 0.2312 68.1785)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_9_)" points="112.5,73.318 112.491,74.49 62.664,74.467 62.641,73.342  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="62.651" y1="74.668" x2="112.479" y2="74.713" />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4" x1="62.646" y1="111.925" x2="112.473" y2="111.971" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="55.3311" y1="169.4854" x2="55.5593" y2="119.062" gradientTransform="matrix(-0.8683 -0.4961 0.4961 -0.8683 38.9791 184.0974)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_10_)" points="74.434,9.147 75.448,9.749 50.396,53.474 49.406,52.924  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4036" x1="50.956" y1="53.791" x2="75.26" y2="9.645" />
            
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="22.4736" y1="145.8936" x2="22.7018" y2="95.4693" gradientTransform="matrix(-0.5039 -0.8638 0.8638 -0.5039 -11.9141 130.3445)">
                <stop offset="0" style="stop-color:#336666" />
                <stop offset="0.53" style="stop-color:#C9E8E6" />
                <stop offset="1" style="stop-color:#336666" />
            </linearGradient>
            <polygon fill="url(#SVGID_11_)" points="102.447,36.831 103.025,37.859 59.467,63.201 58.884,62.229  " />
            <line fill="none" stroke="#4C4C4C" stroke-width="0.4036" x1="59.556" y1="63.384" x2="103.126" y2="38.06" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6614583333333334, 0.0, 0.5, 0.5364583333333334, 117.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.6614583333333334, 0.0, 0.53, 0.2708333333333333, 185.0, 1.0, 0.6614583333333334, 0.0]);

        super.drawObject();
    }
}
import { Property } from "./property";
import tag from "@/model/tag";


export class IndicatorProperty extends Property{
    value:string;
    type:number;

    public CheckIndicator(tagnames:string[]):boolean{
        const tag:tag = this.getTag(tagnames);
        if (tag==null) return true;
        let indicator = false;
      
        switch (this.type)
          {
              case 0: if (+this.value==+tag.tagvalue)
                              indicator = true;
                      break;
              case 1: if (+tag.tagvalue>=+this.value)
                indicator = true;
                      break;
              case 2:if (+tag.tagvalue<=+this.value)
                 indicator = true;
                      break;
              case 3: if (+tag.tagvalue>+this.value)
                indicator = true;
                      break;
              case 4: if (+tag.tagvalue<+this.value)
                indicator = true;
                      break;
              case 5: if (+tag.tagvalue!=+this.value)
               indicator = true;
                      break;
              
              default: break;
          }
          return indicator;
    }
}

import { SVGObject } from "../svgobject";

export class SVGTank45 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 76.688 112.5" enable-background="new 0 0 76.688 112.5" xml:space="preserve">
        <g id="Group_Tank">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="20.3826" y1="67.6797" x2="75.5613" y2="67.6797">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M75.561,25.113c0,0-7.638-2.252-23.873-2.252c-1.802,0-5.405,0-7.207,0   c-13.888,0-24.099,2.252-24.099,2.252v83.331c0,0,2.211,4.054,24.099,4.054c1.802,0,5.405,0,7.207,0   c18.659,0,23.873-4.054,23.873-4.054V25.113z" />
        </g>
        <g id="Group_Cable">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="19.2566" y1="86.1484" x2="76.6873" y2="86.1484">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M20.383,84.797c0,0,11.96,2.183,27.589,2.183c18.356,0,27.59-2.183,27.59-2.183v-0.901l1.126,0.45   v0.901c0,0-10.359,3.153-28.715,3.153c-18.356,0-28.716-3.153-28.716-3.153v-0.901l1.126-0.45V84.797z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="19.2566" y1="48.7617" x2="76.6873" y2="48.7617">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M20.383,50.113c0,0,17.377-2.361,27.044-2.361c13.583,0,28.135,2.361,28.135,2.361v0.901   l1.126-0.451v-0.901c0,0-10.928-3.153-28.716-3.153c-10.878,0-28.715,3.153-28.715,3.153v0.901l1.126,0.451V50.113z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="19.2566" y1="40.0586" x2="76.6873" y2="40.0586">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M20.383,43.356c0,0,9.969-2.856,27.59-5.008c15.6-1.905,27.589-0.397,27.589-0.397v0.675l1.126-0.45   v-0.901c0,0-5.866-1.014-7.844-1.126c-5.214-0.296-18.095,0.523-20.871,0.844c-4.567,0.529-14.527,2.394-18.131,3.097   c-5.779,1.128-10.585,2.815-10.585,2.815v0.901l1.126,0.225V43.356z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="19.2566" y1="31.7529" x2="76.6873" y2="31.7529">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M20.383,34.798c0,0,15.54-3.829,26.461-5.067c12.276-1.393,28.718-0.338,28.718-0.338v0.901   l1.126-0.451v-0.9c0,0-3.939-0.824-10.359-1.067c-5.727-0.217-13.602,0.103-19.651,0.708c-3.445,0.345-9.795,1.28-14.808,2.386   c-7.112,1.571-12.612,3.378-12.612,3.378v1.126l1.126,0.225V34.798z" />
            <path fill="#7F7F7F" d="M20.546,30.293H0v-1.126h20.546V30.293z" />
        </g>
        <g id="Group_Support">
            <path fill="#CCCCCC" d="M22.452,24.806c0.375-0.134,0.775-0.238,1.22-0.279L18.131,3.943l-2.14,1.239l5.643,19.8   C21.89,24.902,22.165,24.845,22.452,24.806z" />
            <path fill="#B2B2B2" d="M10.81,30.293l0.338-6.756h10.022l-0.788,1.576l2.478-0.45l-0.676-3.378H9.009l-1.576,9.685L10.81,30.293z" />
        </g>
        <g id="Group_TankBody">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="29.616" y1="69.876" x2="47.9724" y2="69.876">
                <stop offset="0" style="stop-color:#87C879" />
                <stop offset="0.33" style="stop-color:#85C677" />
                <stop offset="0.49" style="stop-color:#7DC170" />
                <stop offset="0.62" style="stop-color:#70B764" />
                <stop offset="0.73" style="stop-color:#5DAA54" />
                <stop offset="0.82" style="stop-color:#45983E" />
                <stop offset="0.91" style="stop-color:#278223" />
                <stop offset="0.99" style="stop-color:#046904" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M29.616,41.78h18.356v56.192H29.616V41.78z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="66.3279" y1="69.876" x2="47.322" y2="69.876">
                <stop offset="0" style="stop-color:#87C879" />
                <stop offset="0.28" style="stop-color:#65AF5B" />
                <stop offset="0.89" style="stop-color:#10710E" />
                <stop offset="1" style="stop-color:#006600" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M47.322,41.78h19.006v56.192H47.322V41.78z" />
            <rect x="29.498" y="41.773" fill="none" stroke="#6D6E71" stroke-width="0.5" width="36.934" height="56.213" />
        </g>
        <g id="Group_Mixer">
            <path fill="#333333" d="M28.04,19.708l2.252-0.45l1.577,5.405l-2.253,0.676L28.04,19.708z" />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="41.7253" y1="70.1611" x2="44.0369" y2="69.5418">
                <stop offset="0" style="stop-color:#333333" />
                <stop offset="0.06" style="stop-color:#464646" />
                <stop offset="0.27" style="stop-color:#808080" />
                <stop offset="0.43" style="stop-color:#A5A5A5" />
                <stop offset="0.51" style="stop-color:#B2B2B2" />
                <stop offset="0.67" style="stop-color:#8D8D8D" />
                <stop offset="1" style="stop-color:#333333" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M34.121,41.78h2.478l14.864,56.192h-2.252L34.121,41.78z" />
        </g>
        <g id="Group_Mixing_Blade">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="39.4958" y1="91.8613" x2="57.573" y2="91.8613">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M51.012,93.58l-2.253-1.576l-1.351,2.477c0,0-6.133,1.385-7.658,0.226   c-0.185-0.141-0.316-0.688-0.225-0.901c0.765-1.785,6.757-3.828,6.757-3.828l2.252,1.576l1.126-2.477c0,0,6.147-1.177,7.657,0   c0.183,0.143,0.317,0.688,0.226,0.9C56.801,91.688,51.012,93.58,51.012,93.58z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="34.3083" y1="72.1025" x2="52.1775" y2="72.1025">
                <stop offset="0.17" style="stop-color:#4D4D4D" />
                <stop offset="0.23" style="stop-color:#5A5A5A" />
                <stop offset="0.34" style="stop-color:#7D7D7D" />
                <stop offset="0.48" style="stop-color:#B2B2B2" />
                <stop offset="0.57" style="stop-color:#A8A8A8" />
                <stop offset="0.73" style="stop-color:#8B8B8B" />
                <stop offset="0.94" style="stop-color:#5C5C5C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M45.607,73.761l-2.253-1.351l-1.351,2.477c0,0-5.968,1.144-7.433,0   c-0.183-0.143-0.337-0.697-0.225-0.9c0.904-1.631,6.531-3.604,6.531-3.604l2.252,1.352l1.352-2.253c0,0,5.949-1.346,7.432-0.226   c0.186,0.141,0.339,0.698,0.226,0.901C51.234,71.789,45.607,73.761,45.607,73.761z" />
        </g>
        <g id="Group_Motor">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="20.3689" y1="12.4106" x2="33.5702" y2="8.8734">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_11_)" d="M31.193,0.001l4.729,17.68l-13.176,3.604l-4.729-17.68L31.193,0.001" />
        </g>
        <g id="Group_Sticker">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="23.4802" y1="10.6123" x2="29.5578" y2="8.9838">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_12_)" d="M28.722,5.908l1.629,6.039l-6.035,1.743l-1.63-6.039L28.722,5.908z" />
        </g>
        <g id="Group_RedSticker">
            <path fill="#E59898" d="M29.842,6.308l1.576-0.563l2.703,9.459l-1.802,0.451L29.842,6.308z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.8125, 47.0, 0.33, 0.828125, 43.0, 0.49, 0.8854166666666666, 29.0, 0.62, 0.9791666666666666, 5.0, 0.73, 0.8958333333333334, 0.0, 0.82, 0.7291666666666666, 0.0, 0.91, 0.5260416666666666, 0.0, 0.99, 0.2864583333333333, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.8125, 47.0, 0.28, 0.9479166666666666, 0.0, 0.89, 0.3645833333333333, 0.0, 1.0, 0.2604166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.3958333333333333, 0.0, 0.06, 0.5416666666666666, 0.0, 0.27, 1.0, 0.0, 0.43, 0.7135416666666666, 73.0, 0.51, 0.609375, 99.0, 0.67, 0.9010416666666666, 25.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.17, 0.5989583333333334, 0.0, 0.23, 0.6979166666666666, 0.0, 0.34, 0.9739583333333334, 0.0, 0.48, 0.609375, 99.0, 0.57, 0.6875, 79.0, 0.73, 0.9166666666666666, 21.0, 0.94, 0.7135416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
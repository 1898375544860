import { SVGObject } from "../svgobject";

export class SVGValve3 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 67 112.5" enable-background="new 0 0 67 112.5" xml:space="preserve">
        <g id="Group_ConnectingPipe">
            <path fill="#999999" d="M11.6,64.978h2.59v1.351H11.6V64.978z" />
            <path fill="#999999" d="M18.243,38.063H7.207v7.657h11.036V38.063z" />
            <path fill="#999999" d="M30.855,30.856h7.883v3.941h-7.883V30.856z" />
            <path fill="#999999" d="M24.099,38.063h2.027v10.022h-2.027V38.063z" />
            <path fill="#4C4C4C" d="M23.536,45.383V44.82h4.617V32.771h13.625v0.676h-12.95v11.937H23.536z" />
            <path fill="#4C4C4C" d="M18.243,39.415h8.559v1.352h-8.559V39.415z" />
            <path fill="#CCCCCC" d="M43.13,26.802h5.293v11.261H43.13V26.802z" />
            <path fill="#B2B2B2" d="M32.094,56.419h5.743v3.941h-5.743V56.419z" />
            <path fill="#B2B2B2" d="M7.207,54.392v2.027h1.352h8.333h1.352v-2.027H7.207z" />
            <path fill="#BABABA" d="M8.559,56.419c0,0,0-1.294,4.275-1.294c4.058,0,4.058,1.294,4.058,1.294v8.559H8.559V56.419z" />
            <path fill="#666666" d="M18.243,45.721H7.207v8.671h11.036V45.721z" />
            <circle fill="#666666" cx="45.72" cy="34.122" r="1.351" />
            <rect x="45.579" y="32.771" fill="#4C4C4C" width="0.281" height="2.702" />
        </g>
        <g id="Group_ActuatorStem">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="32.7695" y1="45.7178" x2="36.8242" y2="45.7178">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.49" style="stop-color:#ECECEC" />
                <stop offset="0.56" style="stop-color:#D9D9D9" />
                <stop offset="0.71" style="stop-color:#A8A8A8" />
                <stop offset="0.92" style="stop-color:#5A5A5A" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M32.77,34.798h4.055v21.839H32.77V34.798z" />
        </g>
        <g id="Group_Yoke2">
            <path fill="#FFEDCC" d="M13.514,38.063c0,0-4.633-7.018-6.193-9.347c-1.026-1.532-3.119-4.604-3.829-6.306   c-0.357-0.857-0.901-3.604-0.901-3.604S1.536,7.247,3.941,4.618c1.561-1.706,6.982-1.352,6.982-1.352h21.17V0.564H10.248   c0,0-4.622-0.358-6.869,1.126C0.448,3.627,0,7.434,0,7.434s0,6.771,0,12.725c0,1.006,0.787,2.931,1.239,3.829   c0.869,1.725,3.24,5.104,4.302,6.718c1.263,1.919,5.044,7.358,5.044,7.358H13.514z" />
            <path fill="#CC9933" d="M37.387,5.294V0.001h-5.293v3.941h1.352v1.352H37.387z" />
            <path fill="#CC9933" d="M30.855,0.001h-3.378v3.941h3.378V0.001z" />
            
                <rect x="33.111" y="3.166" transform="matrix(0.7036 -0.7106 0.7106 0.7036 7.727 25.1483)" fill="#4C4C4C" width="1.791" height="0.292" />
        </g>
        <g id="Group_BodyAndPort">
            <path fill="#4C4C4C" d="M30.855,60.36h7.883v1.351h-7.883V60.36z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="24.0986" y1="109.1777" x2="45.7197" y2="109.1777">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M24.099,105.855H45.72v6.644H24.099V105.855z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="24.0986" y1="71.416" x2="45.7197" y2="71.416">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M24.099,68.243H45.72v6.345H24.099V68.243z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="28.1533" y1="63.6816" x2="41.7783" y2="63.6816">
                <stop offset="0" style="stop-color:#1F1F1F" />
                <stop offset="0.45" style="stop-color:#999999" />
                <stop offset="0.62" style="stop-color:#868686" />
                <stop offset="0.77" style="stop-color:#737373" />
                <stop offset="1" style="stop-color:#1F1F1F" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M28.153,61.711h13.625v3.941H28.153V61.711z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="24.0986" y1="76.2949" x2="45.7197" y2="76.2949">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M24.099,73.648H45.72v5.293H24.099V73.648" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="34.9658" y1="77.5898" x2="34.9658" y2="107.0938">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M24.099,77.59v2.703l-1.238,1.238h-5.293l-1.352-1.238v24.211l1.352-1.352h5.293l1.238,1.352v2.59   H45.72v-2.59l1.352-1.352h5.293l1.352,1.352V80.293l-1.352,1.238h-5.293l-1.352-1.238V77.59H24.099z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="56.3613" y1="105.8555" x2="56.3613" y2="78.9414">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_7_)" points="53.716,78.941 53.716,85.923 53.716,96.508 53.716,105.855 59.008,105.855 59.008,96.508    59.008,85.923 59.008,78.941  " />
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="13.5698" y1="105.8555" x2="13.5698" y2="78.9414">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <polygon fill="url(#SVGID_8_)" points="10.924,78.941 10.924,85.923 10.924,96.508 10.924,105.855 16.216,105.855 16.216,96.508    16.216,85.923 16.216,78.941  " />
            
                <radialGradient id="SVGID_9_" cx="34.9102" cy="92.3418" r="9.459" gradientTransform="matrix(-4.371139e-008 -1 3 -1.311342e-007 -242.1152 127.252)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#ECECEC" />
                <stop offset="0.14" style="stop-color:#DDDDDD" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.75" style="stop-color:#7C7C7C" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </radialGradient>
            <path fill="url(#SVGID_9_)" d="M44.369,101.801c0,0-0.901-5.089-0.901-9.459c0-4.046,0.901-9.459,0.901-9.459   s-5.158,1.352-9.459,1.352c-4.074,0-9.46-1.352-9.46-1.352s1.014,4.079,1.014,9.239c0,5.007-1.014,9.679-1.014,9.679   s5.386-1.014,9.46-1.014C38.919,100.787,44.369,101.801,44.369,101.801z" />
        </g>
        <g id="Group_Yoke1">
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="18.8066" y1="47.0278" x2="51.0127" y2="47.0278">
                <stop offset="0" style="stop-color:#0000FF" />
                <stop offset="0.06" style="stop-color:#B9BAFF" />
                <stop offset="0.07" style="stop-color:#A4A5FF" />
                <stop offset="0.09" style="stop-color:#7071FF" />
                <stop offset="0.12" style="stop-color:#4A4BFF" />
                <stop offset="0.14" style="stop-color:#3334FF" />
                <stop offset="0.15" style="stop-color:#2A2BFF" />
                <stop offset="0.52" style="stop-color:#B9BAFF" />
                <stop offset="0.82" style="stop-color:#0000FF" />
                <stop offset="0.94" style="stop-color:#B9BAFF" />
                <stop offset="1" style="stop-color:#0000FF" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M18.807,30.856l1.351-2.815l2.928-2.14l10.36-0.089v3.692h-8.671l-2.59,2.027V62.95l3.266,2.027   h20.27l2.027-3.378V31.532l-2.703-2.027h-8.896v-3.567l11.091,0.02l2.575,2.481l1.198,2.418v33.445l-1.576,3.04l-2.703,0.901   h-2.364H24.099l-3.604-0.901l-1.688-3.04V30.856z" />
        </g>
        <g id="Group_ActuatorCase">
            <path fill="#0000CC" d="M29.504,26.802H40.09v4.054H29.504V26.802z" />
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="8.2207" y1="16.0479" x2="61.7109" y2="16.0479">
                <stop offset="0" style="stop-color:#000099" />
                <stop offset="0.45" style="stop-color:#0000FF" />
                <stop offset="0.56" style="stop-color:#0000EC" />
                <stop offset="0.76" style="stop-color:#0000BC" />
                <stop offset="0.77" style="stop-color:#0000BA" />
                <stop offset="1" style="stop-color:#000099" />
            </linearGradient>
            <polygon fill="url(#SVGID_11_)" points="59.008,5.294 10.36,5.294 8.221,7.884 8.221,24.663 10.924,26.802 59.571,26.802    61.711,24.1 61.711,7.884  " />
            <path fill="#4C4CFF" d="M3.941,20.156h2.59v-8.331h-2.59V20.156z" />
            <path fill="#4C4CFF" d="M62.949,20.156h2.703v-8.331h-2.703V20.156z" />
            <path fill="#4C4CFF" d="M33.445,20.156h2.703v-8.331h-2.703V20.156z" />
            <path fill="#4C4CFF" d="M13.514,20.156h2.702v-8.331h-2.702V20.156z" />
            <path fill="#4C4CFF" d="M22.86,20.156h2.59v-8.331h-2.59V20.156z" />
            <path fill="#4C4CFF" d="M44.369,20.156h2.702v-8.331h-2.702V20.156z" />
            <path fill="#4C4CFF" d="M53.716,20.156h2.702v-8.331h-2.702V20.156z" />
            <path fill="#0000E5" d="M2.59,13.177h64.414v2.703H2.59V13.177z" />
            <path fill="#0000CC" d="M2.59,15.879h64.414v2.928H2.59V15.879z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.49, 0.16145833333333334, 214.0, 0.56, 0.3020833333333333, 177.0, 0.71, 0.6875, 79.0, 0.92, 0.6979166666666666, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 0.0, 0.45, 0.8072916666666666, 49.0, 0.62, 0.953125, 11.0, 0.77, 0.8958333333333334, 0.0, 1.0, 0.23958333333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.22916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.16145833333333334, 214.0, 0.14, 0.2708333333333333, 185.0, 0.4, 0.5625, 111.0, 0.75, 0.9635416666666666, 0.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.6614583333333334, 0.0, 0.06, 0.375, 159.0, 0.07, 0.484375, 131.0, 0.09, 0.75, 63.0, 0.12, 0.953125, 11.0, 0.14, 0.9270833333333334, 0.0, 0.15, 0.8802083333333334, 0.0, 0.52, 0.375, 159.0, 0.82, 0.6614583333333334, 0.0, 0.94, 0.375, 159.0, 1.0, 0.6614583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.3958333333333333, 0.0, 0.45, 0.6614583333333334, 0.0, 0.56, 0.6041666666666666, 0.0, 0.76, 0.4791666666666667, 0.0, 0.77, 0.4791666666666667, 0.0, 1.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_ConnectingPipe",[0.0, 0.8072916666666666, 49.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0, 6.0, 0.40625, 151.0, 7.0, 0.609375, 99.0, 8.0, 0.609375, 99.0, 9.0, 0.546875, 115.0, 10.0, 0.7916666666666666, 0.0, 11.0, 0.7916666666666666, 0.0, 12.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_ActuatorCase",[0.0, 0.5260416666666666, 0.0, 1.0, 0.9479166666666666, 13.0, 2.0, 0.9479166666666666, 13.0, 3.0, 0.9479166666666666, 13.0, 4.0, 0.9479166666666666, 13.0, 5.0, 0.9479166666666666, 13.0, 6.0, 0.9479166666666666, 13.0, 7.0, 0.9479166666666666, 13.0, 8.0, 0.5885416666666666, 0.0, 9.0, 0.5260416666666666, 0.0]);

        super.drawObject();
    }
}
import { ObjectView } from "./object";
import Konva from "konva";
import { SuperGif } from '../utils/supergif/super-gif';

export class ImageView extends ObjectView{
    imagebase:string;
    supergif:SuperGif;
    imageObj:HTMLImageElement;
    public drawObject():void{
        super.initObject();
        if (this.imageObj==null)
          this.imageObj = new Image();
        const width = this.width;
        const height = this.height;
        const node = this.node;
        const byteCharacters = atob(this.imagebase);
       // console.log(byteCharacters)
          const gif = byteCharacters.includes("GIF");
          const svg = byteCharacters.includes("svg>");

          if (svg){
            console.log("SVG=true")
            const parser = new DOMParser();
            const doc = parser.parseFromString(byteCharacters, "image/svg+xml");
            doc.documentElement.setAttribute("width", this.width.toString())
            doc.documentElement.setAttribute("height", this.height.toString())
            doc.documentElement.setAttribute("preserveAspectRatio", "none");
            const svg = new XMLSerializer().serializeToString(doc)
            const url =  'data:image/svg+xml;base64,' + btoa(svg);

            Konva.Image.fromURL(url, image => {
                this.node.add(image);
                this.layer.draw();
            })
          }
         
          else {
            if (gif){
            if (this.supergif==null){
              const byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                   byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
              const byteArray = new Uint8Array(byteNumbers);
              const objectURL = URL.createObjectURL(new Blob([byteArray], {type: 'image/gif'}))
              this.imageObj.src = objectURL
              this.supergif = new SuperGif(this.imageObj,{});
         
             this.supergif.load(function(){
          
                 console.log("loaded")
              })
            }
              const animation = new Konva.Animation(() => {const a =1;}, this.layer);
              animation.start();
         }
          else{
            this.imageObj.src ="data:image/png;base64,"+this.imagebase;
           }
     // imageObj.onload = function () {
        const img = new Konva.Image({
          image:gif?this.supergif.getCanvas():this.imageObj,
          //image: imageObj,
          width: width,
          height: height,
        });

        // add the shape to the layer
        node.add(img);
      }
    //  };
    }
}
import { SVGObject } from "../svgobject";

export class SVGValve24 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 96.05" enable-background="new 0 0 112.5 96.05" xml:space="preserve">
        <g id="Group_Port2">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.4063" y1="95.8301" x2="5.4062" y2="15.0894">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect y="15.089" fill="url(#SVGID_1_)" width="10.813" height="80.741" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="25.7866" y1="39.3721" x2="15.7241" y2="76.9257">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M31.082,79.502l-4.167-39.075l-3.716-0.563l-12.387-4.504v38.962l14.526,5.181H31.082z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="29.2114" y1="40.4268" x2="29.2114" y2="79.5024">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M31.082,79.502l2.002-19.537l-6.168-19.538l-1.577,39.075H31.082z" />
        </g>
        <g id="Group_Port1">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="107.0371" y1="95.8301" x2="107.0371" y2="15.0894">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="101.575" y="15.089" fill="url(#SVGID_4_)" width="10.925" height="80.741" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="86.6025" y1="39.3721" x2="96.6651" y2="76.9262">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M81.306,79.502l4.166-39.075l3.716-0.563l12.388-4.504v38.962l-14.526,5.181H81.306z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="83.5244" y1="40.4268" x2="83.5244" y2="79.5024">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M81.306,79.502L80,59.965l5.472-19.538l1.577,39.075H81.306z" />
        </g>
        <g id="Group_Bolt2">
            
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="93.9971" y1="129.374" x2="93.9971" y2="123.999" gradientTransform="matrix(0 1 -1 0 201.957 -10.9082)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="72.583" y="80.934" fill="url(#SVGID_7_)" width="5.375" height="4.311" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="118.8682" y1="54.96" x2="118.8682" y2="49.543" gradientTransform="matrix(0 -1 1 0 -15.085 201.957)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="34.458" y="80.934" fill="url(#SVGID_8_)" width="5.417" height="4.311" />
            
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="119.3115" y1="55.8516" x2="119.3115" y2="48.6445" gradientTransform="matrix(0 -1 1 0 -15.085 201.957)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="33.56" y="80.934" fill="url(#SVGID_9_)" width="7.207" height="3.424" />
            
                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="93.5195" y1="130.3516" x2="93.5195" y2="123.1445" gradientTransform="matrix(0 1 -1 0 201.957 -10.9082)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="71.605" y="80.934" fill="url(#SVGID_10_)" width="7.207" height="3.355" />
        </g>
        <g id="Group_Valve">
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="81.3037" x2="56.1938" y2="14.7524">
                <stop offset="0" style="stop-color:#4D4D4D" />
                <stop offset="0.52" style="stop-color:#B3B3B3" />
                <stop offset="1" style="stop-color:#666666" />
            </linearGradient>
            <polygon fill="url(#SVGID_11_)" points="24.1,14.752 31.082,81.304 81.306,81.304 88.287,14.752  " />
        </g>
        <g id="Group_Ring">
            <path fill="#F0F0F0" d="M38.627,21.958l-3.604-1.126l-3.378,1.126l-1.464,3.717l1.464,3.604l3.378,1.238l3.604-1.238l1.576-3.604   L38.627,21.958z M37.5,28.039l-2.477,0.901l-2.14-0.901l-0.901-2.364l0.901-2.478l2.14-0.901l2.477,0.901l0.901,2.478L37.5,28.039z   " />
            <path fill="#F0F0F0" d="M80.742,21.958l-3.716-1.126l-3.266,1.126l-1.577,3.717l1.577,3.604l3.266,1.238l3.716-1.238l1.464-3.604   L80.742,21.958z M79.504,28.039l-2.478,0.901l-2.14-0.901l-0.9-2.364l0.9-2.478l2.14-0.901l2.478,0.901l0.9,2.478L79.504,28.039z" />
        </g>
        <g id="Group_Bolt1">
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="34.1201" x2="56.1938" y2="29.8408">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="40.203" y="29.841" fill="url(#SVGID_12_)" width="31.98" height="4.279" />
            <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="36.4844" x2="56.1938" y2="27.7012">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="41.667" y="27.701" fill="url(#SVGID_13_)" width="29.054" height="8.783" />
            <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="60.6396" x2="56.1938" y2="56.3604">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="40.203" y="56.36" fill="url(#SVGID_14_)" width="31.98" height="4.279" />
            <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="63.0039" x2="56.1938" y2="54.2207">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="41.667" y="54.221" fill="url(#SVGID_15_)" width="29.054" height="8.783" />
            <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="91.3252" x2="56.1938" y2="87.0459">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="40.203" y="87.046" fill="url(#SVGID_16_)" width="31.98" height="4.279" />
            <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="93.6895" x2="56.1938" y2="84.9063">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="41.667" y="84.906" fill="url(#SVGID_17_)" width="29.054" height="8.783" />
            <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="9.3467" x2="56.1938" y2="5.0674">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="40.203" y="5.067" fill="url(#SVGID_18_)" width="31.98" height="4.279" />
            <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="11.7109" x2="56.1938" y2="2.9277">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="41.667" y="2.928" fill="url(#SVGID_19_)" width="29.054" height="8.783" />
        </g>
        <g id="Group_Middle">
            <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="95.8301" x2="56.1938" y2="4.882813e-004">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.45" style="stop-color:#ECECEC" />
                <stop offset="0.55" style="stop-color:#D9D9D9" />
                <stop offset="0.76" style="stop-color:#A8A8A8" />
                <stop offset="0.77" style="stop-color:#A6A6A6" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <rect x="45.608" fill="url(#SVGID_20_)" width="21.171" height="95.83" />
            <rect x="55.725" fill="#4C4C4C" width="0.938" height="95.83" />
        </g>
        <g id="Group_Arrow">
            <path d="M48.649,43.241h0.563l1.577,3.604h11.148L61.6,42.228l3.377,5.519L61.6,53.151l0.337-4.505H50.789l-1.577,3.604h-0.563   l0.901-4.504L48.649,43.241z" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_10_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_11_",[0.0, 0.5989583333333334, 0.0, 0.52, 0.6041666666666666, 101.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_12_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_13_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_14_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_15_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_16_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_17_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_18_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_19_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_20_",[0.0, 0.4322916666666667, 0.0, 0.45, 0.16145833333333334, 214.0, 0.55, 0.3020833333333333, 177.0, 0.76, 0.6875, 79.0, 0.77, 0.703125, 75.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
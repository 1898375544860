import { SVGObject } from "../svgobject";

export class SVGFood24 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 56.195" enable-background="new 0 0 112.5 56.195" xml:space="preserve">
        <g id="Group_Detector">
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M14.077,13.853h1.351v1.576h-1.351V13.853z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M1.352,39.303h1.464v1.352H1.352V39.303z" />
        </g>
        <g id="Group_Metal_Detector">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="90.8828" y1="68.676" x2="44.0278" y2="-12.4792">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M22.41,0.001h90.09v56.193H22.41V0.001z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="59.833" y1="69.8157" x2="83.0389" y2="6.0579">
                <stop offset="0.01" style="stop-color:#999999" />
                <stop offset="0.5" style="stop-color:#F5F5F5" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M22.41,41.555l90.09-24.774v39.414H22.41V41.555z" />
        </g>
        <g id="Group_Scanner_Layer">
            <path fill="#F2F2F2" d="M36.599,13.853h61.711v28.378H36.599V13.853z" />
            <path fill="#A6A6A6" d="M36.599,42.006h61.937v2.928H36.599V42.006z" />
        </g>
        <g id="Group_Detector_Metal">
            <path fill="#CCCCCC" d="M42.229,19.292h50.45v17.5h-50.45V19.292z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M36.599,42.006h61.937V13.853" />
            <path fill="#B2B2B2" d="M87.275,33.672l5.63,2.703V19.484l-5.63,2.928V33.672z" />
            <path fill="#999999" d="M42.229,36.375l5.631-2.703V22.411l-5.631-2.928V36.375z" />
            <path fill="#B2B2B2" d="M42.229,36.375l5.631-2.703h39.415l5.63,2.703H42.229z" />
        </g>
        <g id="Group_Scanner_Front">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,44.934H0.113L17.004,11.15h2.478V44.934z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="2.6821" y1="49.887" x2="31.2936" y2="15.7891">
                <stop offset="0.01" style="stop-color:#4D4D4D" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#4D4D4D" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.1" d="M22.41,47.636H0L19.707,8.334h2.703V47.636z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.5, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.8072916666666666, 49.0, 0.5, 0.08333333333333333, 233.0, 1.0, 0.5052083333333334, 125.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.5989583333333334, 0.0, 0.5, 0.40625, 151.0, 1.0, 0.5989583333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Detector",[0.0, 0.9895833333333334, 0.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Scanner_Layer",[0.0, 0.11458333333333333, 226.0, 1.0, 0.703125, 75.0]);
		this.fillGradient(this.fills,"Group_Detector_Metal",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0, 2.0, 0.8072916666666666, 49.0, 3.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_Scanner_Front",[0.0, 0.40625, 151.0]);


        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGFood25 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 65.649 112.5" enable-background="new 0 0 65.649 112.5" xml:space="preserve">
        <g id="Group_Can">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="56.25" x2="65.6489" y2="56.25">
                <stop offset="0" style="stop-color:#686868" />
                <stop offset="0.35" style="stop-color:#E0E0E0" />
                <stop offset="0.65" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M45.38,112.047l-8.333,0.45h-8.558l-8.333-0.45l-7.432-0.901   l-6.306-1.351l-4.279-1.577L0,106.416V5.971l2.14-1.802l4.504-1.577l6.081-1.351l7.432-0.901l8.333-0.338h8.558l8.333,0.338   l7.657,0.901l6.081,1.351l4.278,1.577l2.252,1.802v100.445l-2.252,1.802l-4.278,1.577l-6.306,1.351L45.38,112.047" />
        </g>
        <g id="Group_Ridges">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M65.649,103.826l-2.252,1.914l-4.278,1.576l-6.306,1.352l-7.433,0.901   l-8.333,0.338h-8.558l-8.333-0.338l-7.432-0.901l-6.306-1.352L2.14,105.74L0,103.826" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M0,8.561l2.14-1.914L6.418,5.07l6.306-1.351l7.432-0.901l8.333-0.338   h8.558l8.333,0.338l7.433,0.901l6.306,1.351l4.278,1.577l2.252,1.914" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0,82.094l2.14,0.676l4.279,0.675l6.306,0.45l7.432,0.226l8.333,0.226   h8.558l8.333-0.226l7.433-0.226l6.306-0.45l4.278-0.675l2.252-0.676" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,76.463l1.915,0.45l4.504,0.45l6.081,0.226l7.432,0.45h8.333h8.558   h8.333l7.657-0.45l6.081-0.226l4.278-0.45l2.252-0.45" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,70.832l1.915,0.226l4.504,0.226l6.081,0.225l7.432,0.226h8.333   h8.558h8.333l7.657-0.226l6.081-0.225l4.278-0.226l2.252-0.226" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,65.202H2.14l4.504,0.226h6.081h7.432l8.333,0.225h8.558   l8.333-0.225h7.657h6.081l4.278-0.226h2.252" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,59.347H2.14h4.504h6.081h7.432h8.333h8.558h8.333h7.657h6.081   h4.278h2.252" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,53.716l1.915-0.225h4.504l6.081-0.225l7.432-0.226h8.333h8.558   h8.333l7.657,0.226l6.081,0.225h4.278l2.252,0.225" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,48.086l1.915-0.225l4.504-0.451l6.081-0.225l7.432-0.225   l8.333-0.225h8.558l8.333,0.225l7.657,0.225l6.081,0.225l4.278,0.451l2.252,0.225" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,42.456l1.915-0.45l4.504-0.676l6.081-0.225l7.432-0.451   l8.333-0.225h8.558l8.333,0.225l7.657,0.451l6.081,0.225l4.278,0.676l2.252,0.45" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0.225,36.825L2.14,36.15l4.504-0.676l6.081-0.676l7.432-0.225   l8.333-0.225h8.558l8.333,0.225l7.657,0.225l6.081,0.676l4.278,0.676l2.252,0.675" />
            <path fill="none" stroke="#666666" stroke-width="0.5" d="M0,31.195l2.14-0.901l4.279-0.9l6.306-0.676l7.432-0.451l8.333-0.225   h8.558l8.333,0.225l7.433,0.451l6.306,0.676l4.278,0.9l2.252,0.901" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8072916666666666, 0.0, 0.35, 0.25, 191.0, 0.65, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);


        super.drawObject();
    }
}
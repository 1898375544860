import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import Konva from "konva";
import { AnalogMeterRect } from './analogmeterrect';


export class AnalogMeterVertical extends AnalogMeterRect{


    node1:Konva.Group;

    public drawObject():void{
		super.initObject();
        this.drawObjectVertical(false);
    }
    protected drawObjectVertical(fill:boolean):void{
      this.node1  = new Konva.Group({
        x: 0,
        y: 0,
       });
        this.node1.removeChildren();
       // this.node1.destroy();
      
      let color:string = this.color;
      const lineprop = this.properties['color'];
      if (lineprop!=null){	
        const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
        if (linecolorproperty!=null) 
            color = linecolorproperty.getColor(this.tagnames, this.color)
      }	
      const color2 = ColorUtils.convertformat(color)

      let fillcolor = '0xffffff00';
          
      let value = 0   
      let valueproperty:ValueProperty
      const valueprop = this.properties['value'];
        if (valueprop!=null){	
            valueproperty =Object.assign(new ValueProperty(), valueprop);
            if (valueproperty!=null) 
                    value = valueproperty.getTagValue(this.tagnames)           
        }	

      fillcolor = this.fillcolor;
      const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
      const fillcolor2 = ColorUtils.convertformat(fillcolor)
            
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const gradient1 = ctx.createLinearGradient(0, 0, 0, this.height);
      gradient1.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.9))
      gradient1.addColorStop(1, ColorUtils.darkColor(fillcolor2,0.5))

      const gradient2 = ctx.createLinearGradient(0, 0, 0, this.height*15/20);
      gradient2.addColorStop(0, ColorUtils.darkColor(fillcolor2,0.5))
      gradient2.addColorStop(1, fillcolor2)

      const gradient3 = ctx.createLinearGradient(0, 0, 0, this.height*2/20);
      gradient3.addColorStop(0, 'black')
      gradient3.addColorStop(1, 'white')

      
      const rect = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          cornerRadius:this.height/20,
          stroke:  gradient1,
          strokeWidth: this.height/100,
          fill: fillcolor2
        });
        this.node1.add(rect)

      const rect2 = new Konva.Rect({
        x: this.width/40,
        y: this.height/40,
        width: this.width*38/40,
        height: this.height*15/20,
        cornerRadius:this.height/40,
        stroke:  gradient2,
        strokeWidth: this.height/100,
        fill: 'white'
      });
      this.node1.add(rect2)

      const rect4 = new Konva.Rect({
        x: this.width/5,
        y: this.height/10,
        width: this.width/10,
        height: this.height*12/20,
        cornerRadius:this.height/100,
        fill: 'black'
      });
      this.node1.add(rect4)

      let valuemin=0;
      let valuemax=240;
      let decimalpos=0;

        if (valueproperty!=null){
          valuemin = valueproperty.minimumvalue;
          valuemax = valueproperty.maximumvalue;
          decimalpos=valueproperty.decimalpos;
        }
      const valuestep = (valuemax-valuemin)/this.interval;

      
      for(let i=0; i<=this.interval; i++){
        const delta = i/this.interval
        const line1 = new Konva.Line({
            points: [this.width*4/10, 
                    this.height*14/20 - this.height*12/20*delta,
                    this.width*5/10,
                    this.height*14/20 - this.height*12/20*delta
                    ],
            strokeWidth: this.height/150,
            stroke: ColorUtils.darkColor(color2, 0.1)
          });
          this.node1.add(line1); 
                  for(let j=1; j<5; j++){
                    if (i==this.interval) break
                    const delta2 = this.height*12/20/this.interval*j/5
                    const line2 = new Konva.Line({
                        points: [this.width*4/10, 
                                (this.height*14/20 - this.height*12/20*delta)-delta2,
                                this.width*9/20,
                                (this.height*14/20 - this.height*12/20*delta)-delta2
                                ],
                        strokeWidth: this.height/200,
                        stroke: ColorUtils.darkColor(color2, 0.1)
                      });
                      this.node1.add(line2); 
                  }
        const valuepos = valuemin+valuestep*i;
              
        const valuetext = valuepos.toFixed(decimalpos)
        const Text = new Konva.Text({
        x: this.width*11/20,
        y: this.height*14/20 - this.height*12/20*delta-this.height/20/2,
        //verticalAlign:"top",
        width: this.width*3/5,
        height: this.height/20,
        text: valuetext,
        fontSize: this.height/20,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fill: ColorUtils.darkColor(color2, 0.1),
        //rotation: (this.angle==90 || this.angle ==270)? 270 : 0
        });  
        this.node1.add(Text)
			}
    
      const TextUnit = new Konva.Text({
        x: this.width/6,
        y: this.height*15/20 -this.height/18/2,
        //verticalAlign:"top",
        width: this.width*4/6,
        height: this.height/18,
        text: this.unit,
        fontSize: this.height/18,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fill: 'black',
        //align: 'center'
      });  
      this.node1.add(TextUnit)

      const percent = valueproperty.getPercent(this.tagnames);

    if(!fill){
      const x1ind=this.width*5/20;
			const y1ind=this.height*14/20 - this.height*12/20*percent;
			const x2ind=this.width*11/20;
			const y2ind=this.height*14/20 - this.height*12/20*percent;
      const lineind = new Konva.Line({
        points: [x1ind, y1ind, x2ind, y2ind],
        stroke: color2,
        strokeWidth: this.height/80
      })
      this.node1.add(lineind)
    }else{
      const heightfill = this.height*12/20*percent;
      const rectfill = new Konva.Rect({
        x: this.width/5,
        y: this.height/10+(this.height*12/20-heightfill),
        width: this.width/10,
        height: heightfill,
        cornerRadius:this.height/100
      })
      rectfill.fillLinearGradientStartPoint({ x: 0, y: 0});
      rectfill.fillLinearGradientEndPoint({ x: this.width/10, y: 0});
      rectfill.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2, 0.5),
        0.5, 'white',
        1, ColorUtils.darkColor(color2, 0.5)]) 
      this.node1.add(rectfill)
    }
            const TextLabel = new Konva.Text({
                x: this.width/6,
                y: this.height*17/20- this.height/16,
                verticalAlign:"bottom",
                width: this.width*4/6,
                height: this.height/15,
                text: this.text,
                fontSize: this.height/15,
                fontFamily: "Roboto",
                fontStyle: "normal",
                fill: 'black',
                align: 'center'
              });  
              this.node1.add(TextLabel)	

              if(this.usedigital){
                const rectdigit = new Konva.Rect({
                    x: this.width/10,
                    y: this.height*35/40,
                    width: this.width*8/10,
                    height: this.height*2/20,
                    cornerRadius:this.height/50,
                    stroke: gradient3,
                    strokeWidth: this.height/100,
                  });
                  rectdigit.fillLinearGradientStartPoint({ x: 0, y: 0 });
                  rectdigit.fillLinearGradientEndPoint({ x: 0, y: this.height*2/20 });
                  rectdigit.fillLinearGradientColorStops([0, 'white', 
                    0.25, 'black'])
                  this.node1.add(rectdigit)

                  const textValue = new Konva.Text({
                    x: this.width/6,
                    y: this.height*76/80 -this.height/18,
                    verticalAlign:"bottom",
                    width: this.width*4/6,
                    height: this.height/15,
                    text: value.toFixed(decimalpos),
                    fontSize: this.height/15,
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fill: 'white',
                    align: 'center'
              });  
              this.node1.add(textValue)
            }
            this.node.add(this.node1)
          }
    }
        
           
    

import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { IndicatorProperty } from './properties/indicatorproperty';
import { OvalLamp } from './ovallamp';
import Konva from "konva";

export class TriangleLamp extends OvalLamp{   
    

    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1
        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        const p1 = [
            0, this.height/2,
            this. width, 0,
            this.width, this.height,
            0, this.height/2
        ]
        
        const line1 = new Konva.Line({
            points:p1,
            closed: true,
          });
          if (this.type3d==0){
            line1.fillLinearGradientStartPoint({ x: 0, y: 0 });
            line1.fillLinearGradientEndPoint({ x: 0, y: this.height });
            line1.fillLinearGradientColorStops([0, 'white', 
            1, '#444444'])
          }
          else{
            line1.fill('#666666')
          }
          this.node.add(line1)

    
        const p2 = [
            this.width/25, this.height/2,
            this.width*24/25, this.height/25,
            this.width*24/25, this.height*24/25,
            this.width/25, this.height/2
        ]
            
        const line2 = new Konva.Line({
                points:p2,
                closed: true,
              });
            if(this.type3d==0){
                line2.fillLinearGradientStartPoint({ x: 0, y: this.height/25  });
                line2.fillLinearGradientEndPoint({ x: 0, y: this.height*24/25 });
                line2.fillLinearGradientColorStops([0, '#444444', 
                1, 'white']);
            }else{
              line2.fill('white')
            }
            this.node.add(line2) 
            
        const p3 = [
            this.width/10, this.height/2,
            this.width*9/10, this.height/10,
            this.width*9/10, this.height*9/10,
            this.width/10, this.height/2
        ]
        const line3 = new Konva.Line({
                points:p3,
                closed: true,
                });
              if(indicator){
                    if(this.type3d==0){
                        line3.fill(ColorUtils.darkColor(fillcolor2, 0.85))
                        
                    }else{
                        line3.fill(ColorUtils.darkColor(fillcolor2, 0.85))
                    }
                }else{
                    if(this.type3d==0){
                    line3.fillLinearGradientStartPoint({ x: 0, y: this.height/10  });
                    line3.fillLinearGradientEndPoint({ x: 0, y: this.height*9/10 });
                    line3.fillLinearGradientColorStops([0, 'white', 
                        0.5, fillcolor2,
                        1, ColorUtils.darkColor(fillcolor2,0.85)])
                    }else{
                        line3.fill(fillcolor2)
                    }
                }
            this.node.add(line3)

            const radius = this.height>this.width? this.width/5 : this.height/4
            const circle = new Konva.Circle({
                x: this.width*2/3,
                y: this.height/2,
                radius: radius,
              });
              if(indicator){
                
                circle.fillRadialGradientStartPoint({x:0, y:0});
                circle.fillRadialGradientEndRadius(radius)
                circle.fillRadialGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2,0.85)]);
                circle.fillPriority('radial-graident')
                this.node.add(circle); 
                
            }    

         
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
            
                const Text = new Konva.Text({
                    x: this.width/3,
                    y: 0, 
                    verticalAlign:"middle",
                    width: this.width-this.width/3-this.width/10,
                    height: this.height,
                    text: text,
                    fontSize: this.height*3/10,
                    fontFamily: 'Roboto',
                    fontStyle: "normal",
                    align:'center',
                    fill: ColorUtils.convertformat(textcolor),
                    
                  });

                  if(this.type3d==0){
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                             
    }
   
}
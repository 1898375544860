import tag from "@/model/tag"
import tagsModele from "@/store/tags.modele";

export class Property{
    tagname: string
    path: string

    public getTag(tagnames:string[]):tag{
        if (this.path==null)
            this.path = this.tagname
        if (!tagnames.includes(this.path))
            tagnames.push(this.path);
      return tagsModele.getTag(this.path);

    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { TextInputProperty } from "./properties/textinputproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { IndicatorProperty } from './properties/indicatorproperty';
import { OvalLamp } from './ovallamp';
import Konva from "konva";

export class RectangleLamp extends OvalLamp{   
    

    public drawObject():void{
        super.initObject();
        if(this.type3d != 0) this.type3d = 1
        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	

        let fillcolor = this.fillcolor;
        const fillprop = this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
                    fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
        }	
        const fillcolor2 = ColorUtils.convertformat(fillcolor);
       
        
        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, 'white', 
            1, '#444444'])
          }
          else{
            rectout.fill('#666666')
          }
          this.node.add(rectout)
          
            
          const rectmiddle = new Konva.Rect({
            x: this.width/25,
            y: this.height/25,
            width: this.width*23/25,
            height: this.height*23/25,
          });
                if(this.type3d==0){
                rectmiddle.fillLinearGradientStartPoint({ x: 0, y: this.height/25  });
                rectmiddle.fillLinearGradientEndPoint({ x: 0, y: this.height*24/25 });
                rectmiddle.fillLinearGradientColorStops([0, '#444444', 
                1, 'white']);
            }else{
              rectmiddle.fill('white')
            }
          this.node.add(rectmiddle); 

          const rectinner = new Konva.Rect({
            x: this.width/10,
            y: this.height/10,
            width: this.width*8/10,
            height: this.height*8/10,
          });
          if(indicator){
                rectinner.fill(ColorUtils.darkColor(fillcolor2,0.85))
            }else{
                if(this.type3d==0){
                    rectinner.fillLinearGradientStartPoint({ x: 0, y: this.height/10  });
                    rectinner.fillLinearGradientEndPoint({ x: 0, y: this.height*9/10 });
                    rectinner.fillLinearGradientColorStops([0, 'white', 
                        0.5, ColorUtils.darkColor(fillcolor2,0.85),
                        1, ColorUtils.darkColor(fillcolor2,0.5)])
                }else{
            rectinner.fill(fillcolor2)
          }
        }
          this.node.add(rectinner); 

          
          const radius = this.height>this.width? this.width*8/20 : this.height*8/20
          const circle = new Konva.Circle({
            x: this.width/2,
            y: this.height/2,
            radius: radius,
          });
          if (indicator){
                circle.fillRadialGradientStartPoint({x:0, y:0});
                circle.fillRadialGradientEndRadius(radius)
                circle.fillRadialGradientColorStops([0, 'white', 1, ColorUtils.darkColor(fillcolor2,0.85)]);
                circle.fillPriority('radial-graident')
          this.node.add(circle); 
          }
          
          let text = this.text;
          const textprop = this.properties['textinput']
          if (textprop!=null){
            const textinputproperty:TextInputProperty = Object.assign(new TextInputProperty(), textprop);
            if (textinputproperty!=null)
                text = textinputproperty.getCurrentText(this.tagnames, this.text);
          }
          let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
            
                const Text = new Konva.Text({
                    x: this.width*6/50,
                    y: 0, 
                    verticalAlign:"middle",
                    width: this.width-this.width*12/50,
                    height: this.height,
                    text: text,
                    fontSize: this.height*2/5,
                    fontFamily: 'Roboto',
                    fontStyle: "normal",
                    align:'center',
                    fill: ColorUtils.convertformat(textcolor),
                    
                  });

                  if(this.type3d==0){
                    Text.shadowColor('black'),
                    Text.shadowBlur(0),
                    Text.shadowOffset({ x: 1, y: 1 })
                  }

                  this.node.add(Text)
                             
    }
   
}
class SVGColorCorrection{
    offset:number;
    mul: number;
    add: number;

    constructor(offset:number,mul:number, add:number){
        this.offset = offset
        this.mul = mul
        this.add = add
    }
}
export default SVGColorCorrection
import { SVGObject } from "../svgobject";

export class SVGPaper5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 103.039" enable-background="new 0 0 112.5 103.039" xml:space="preserve">
        <g id="Group_Legs">
            <path fill="#665940" stroke="#4C4C4C" stroke-width="0.25" d="M5.068,50.788h7.207v48.648H5.068V50.788z" />
            <path fill="#665940" stroke="#4C4C4C" stroke-width="0.25" d="M100.111,50.788h6.982v48.648h-6.982V50.788z" />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1.8027" y1="76.9131" x2="9.3477" y2="76.9131">
                <stop offset="0" style="stop-color:#F2E5CC" />
                <stop offset="0.52" style="stop-color:#D9CCB3" />
                <stop offset="0.99" style="stop-color:#F2E5CC" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M1.803,50.788h7.545v52.251H1.803V50.788z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M0,48.986h9.347v1.802H0V48.986z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M103.04,48.986h9.46v1.802h-9.46V48.986z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M1.803,50.788h7.545v52.251H1.803V50.788" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="103.04" y1="76.9131" x2="110.585" y2="76.9131">
                <stop offset="0" style="stop-color:#F2E5CC" />
                <stop offset="0.52" style="stop-color:#D9CCB3" />
                <stop offset="0.99" style="stop-color:#F2E5CC" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M103.04,50.788h7.545v52.251h-7.545V50.788" />
        </g>
        <g id="Group_Base">
            <path fill="#333333" d="M9.348,50.788h93.692v5.18l-4.729,1.351H13.964l-4.617-1.351V50.788z" />
        </g>
        <g id="Group_Frame">
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M9.348,32.094h93.692v18.693l-4.729,4.73H13.964l-4.617-4.73V32.094z   " />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M32.771,32.544h46.847v22.973H32.771V32.544z" />
        </g>
        <g id="Group_Supporters">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="100.8896" y1="58.3447" x2="104.7302" y2="54.5042">
                <stop offset="0" style="stop-color:#F2E5CC" />
                <stop offset="0.52" style="stop-color:#D9CCB3" />
                <stop offset="0.99" style="stop-color:#F2E5CC" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M97.184,54.616l3.829-3.829l7.433,7.433l-3.941,3.828   L97.184,54.616z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="7.0835" y1="54.4229" x2="10.7814" y2="58.4909">
                <stop offset="0" style="stop-color:#F2E5CC" />
                <stop offset="0.52" style="stop-color:#D9CCB3" />
                <stop offset="0.99" style="stop-color:#F2E5CC" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M10.924,50.788l3.604,3.829l-7.319,7.433l-3.941-3.828   L10.924,50.788z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M97.184,54.616l3.829-3.829l7.433,7.433l-3.941,3.828L97.184,54.616" />
        </g>
        <g id="Group_Handle">
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="0.4146" x2="56.1938" y2="4.4169">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.52" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M84.347,4.392H28.041V0.451h56.306V4.392z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,4.392H28.041V0.451h56.306V4.392" />
            <path fill="#E5E5E5" stroke="#4C4C4C" stroke-width="0.25" d="M53.266,0h5.855v5.067h-5.855V0z" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="56.1934" y1="7.4258" x2="56.1934" y2="9.0035">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.52" style="stop-color:#FFFFFF" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,7.432h37.386v1.689H37.5V7.432z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,8.108h37.386" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M30.293,4.392h1.802v4.729h-1.802V4.392z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M80.293,4.392h1.801v4.729h-1.801V4.392z" />
        </g>
        <g id="Group_Body">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="56.1938" y1="55.6816" x2="56.1938" y2="9.2264">
                <stop offset="0" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#E5E5E5" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M79.615,9.009c12.938,0,23.425,10.558,23.425,23.582S92.553,56.172,79.615,56.172   c-12.935,0-23.422-10.558-23.422-23.582v0.042c0,13.023-10.487,23.582-23.423,23.582c-12.935,0-23.422-10.559-23.422-23.582   S19.835,9.05,32.77,9.05L79.615,9.009z" />
        </g>
        <g id="Group_InnerBody">
            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="92.7217" y1="51.7085" x2="65.148" y2="11.0843">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="1" style="stop-color:#D9D9D9" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M65.475,51.324c0,0,5.704,4.875,13.824,4.832c9.071-0.049,14.779-4.979,14.779-4.979V13.948   c0,0-2.946-2.017-5.351-3.058c-2.129-0.922-5.867-1.705-5.867-1.705L65.699,9.106L65.475,51.324z" />
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="46.8545" y1="52.0039" x2="20.9352" y2="12.4821">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="1" style="stop-color:#D9D9D9" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M46.734,9.122L30.125,9.226c0,0-3.25,0.35-6.271,1.668c-2.375,1.037-5.498,3.217-5.498,3.217v37.138   c0,0,6.348,4.944,14.098,4.944c8.889,0,14.73-4.957,14.73-4.957L46.734,9.122z" />
        </g>
        <g id="Group_OuterBody">
            <path fill="#B2B2B2" d="M18.582,18.581h27.702v1.352H18.582V18.581z" />
            <path fill="#B2B2B2" d="M66.104,18.581h27.702v1.352H66.104V18.581z" />
            <path fill="#B2B2B2" d="M66.104,44.256h27.702v1.351H66.104V44.256z" />
            <path fill="#B2B2B2" d="M18.582,44.256h27.702v1.351H18.582V44.256z" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M18.807,13.66v37.917" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M46.734,10.248v41.339" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M66.104,10.248v41.647" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M94.256,14.143v36.892" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M66.104,18.581h27.702" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M66.104,44.256h27.702" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M18.582,44.256h27.702" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M93.58,13.611v37.852" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M65.428,10.248v41.076" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M46.059,10.248v41.814" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M18.131,14.29v36.726" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M18.582,18.581h27.702" />
            <path fill="none" stroke="#7F7F7F" stroke-width="0.25" d="M25.261,10.248h61.973" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,33.723V9.122" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M79.615,8.966c12.938,0,23.425,10.568,23.425,23.603   S92.553,56.171,79.615,56.171c-12.934,0-23.422-10.566-23.422-23.602v0.042c0,13.035-10.487,23.603-23.423,23.603   c-12.936,0-23.423-10.568-23.423-23.603c0-13.036,10.487-23.604,23.423-23.604L79.615,8.966z" />
        </g>
        <g id="Group_LeftConnector">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M28.041,43.13H37.5V30.292h-9.459V43.13z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M37.5,30.292l2.252-8.108H25.789l2.252,8.108H37.5z" />
            <path fill="#D9CCB3" stroke="#4C4C4C" stroke-width="0.25" d="M29.167,22.185l1.126-2.478h4.73l1.126,2.478H29.167z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M31.194,20.833l-2.027-2.027v-2.928l2.027-1.915h2.928l2.027,1.915   v2.928l-2.027,2.027H31.194z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M32.095,19.256l-1.126-1.126v-1.352l1.126-1.126h1.352l1.126,1.126   v1.352l-1.126,1.126H32.095z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M25.789,39.751l5.18-22.297" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M39.752,39.751l-5.18-22.297" />
        </g>
        <g id="Group_RightConnector">
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M74.887,43.13h9.46V30.292h-9.46V43.13z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M84.347,30.292l2.252-8.108H72.635l2.252,8.108H84.347z" />
            <path fill="#D9CCB3" stroke="#4C4C4C" stroke-width="0.25" d="M76.014,22.185l1.352-2.478h4.729l1.127,2.478H76.014z" />
            <path fill="#F2E5CC" stroke="#4C4C4C" stroke-width="0.25" d="M78.266,20.833l-2.252-2.027v-2.928l2.252-1.915h2.928l2.027,1.915   v2.928l-2.027,2.027H78.266z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M78.941,19.256l-1.127-1.126v-1.352l1.127-1.126h1.352l1.125,1.126   v1.352l-1.125,1.126H78.941z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M72.635,39.751l5.18-22.297" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M86.599,39.751l-5.181-22.297" />
        </g>
        <g id="Group_LeftWheel">
            <path fill="#4C4C4C" d="M31.459,33.85c0.557-0.143,1.042-0.121,1.199-0.121c3.264,0,5.911,2.646,5.911,5.91   c0,3.265-2.647,5.911-5.911,5.911c-3.265,0-5.911-2.646-5.911-5.911C26.747,36.785,28.77,34.404,31.459,33.85l-0.436-2.275   c-3.762,0.757-6.594,4.08-6.594,8.064c0,4.545,3.684,8.229,8.228,8.229c4.544,0,8.229-3.683,8.229-8.229   c0-4.544-3.685-8.228-8.229-8.228c-0.11,0-1.142,0-1.634,0.163L31.459,33.85z" />
            <polygon fill="#4C4C4C" points="32.771,39.751 31.829,33.723 33.71,33.723  " />
            <polyline fill="#4C4C4C" points="33.71,33.723 32.771,39.751 31.822,33.607  " />
            <polyline fill="#4C4C4C" points="38.402,37.401 32.771,39.751 37.418,35.797  " />
            <polyline fill="#4C4C4C" points="37.472,43.642 32.771,39.751 38.434,42.024  " />
            <polyline fill="#4C4C4C" points="31.822,45.778 32.771,39.751 33.704,45.781  " />
            <polyline fill="#4C4C4C" points="27.043,41.853 32.771,39.751 27.956,43.498  " />
            <polyline fill="#4C4C4C" points="27.99,35.905 32.772,39.751 27.043,37.531  " />
        </g>
        <g id="Group_RightWheel">
            <path fill="#4C4C4C" d="M78.418,33.85c0.557-0.143,1.043-0.121,1.199-0.121c3.264,0,5.91,2.646,5.91,5.91   c0,3.266-2.646,5.911-5.91,5.911c-3.266,0-5.911-2.646-5.911-5.911C73.706,36.785,75.729,34.404,78.418,33.85l-0.436-2.275   c-3.762,0.757-6.594,4.08-6.594,8.064c0,4.545,3.684,8.229,8.229,8.229c4.543,0,8.229-3.684,8.229-8.229   c0-4.544-3.686-8.228-8.229-8.228c-0.111,0-1.143,0-1.635,0.163L78.418,33.85z" />
            <polygon fill="#4C4C4C" points="79.729,39.751 78.787,33.723 80.67,33.723  " />
            <polyline fill="#4C4C4C" points="80.67,33.723 79.73,39.751 78.781,33.607  " />
            <polyline fill="#4C4C4C" points="85.361,37.401 79.73,39.751 84.377,35.797  " />
            <polyline fill="#4C4C4C" points="84.431,43.642 79.73,39.751 85.393,42.024  " />
            <polyline fill="#4C4C4C" points="78.781,45.778 79.73,39.751 80.663,45.781  " />
            <polyline fill="#4C4C4C" points="74.002,41.853 79.73,39.751 74.915,43.498  " />
            <polyline fill="#4C4C4C" points="74.949,35.905 79.73,39.751 74.002,37.531  " />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.23958333333333334, 193.0, 0.52, 0.4375, 143.0, 0.99, 0.23958333333333334, 193.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.23958333333333334, 193.0, 0.52, 0.4375, 143.0, 0.99, 0.23958333333333334, 193.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.23958333333333334, 193.0, 0.52, 0.4375, 143.0, 0.99, 0.23958333333333334, 193.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.23958333333333334, 193.0, 0.52, 0.4375, 143.0, 0.99, 0.23958333333333334, 193.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.9895833333333334, 0.0, 0.52, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.9895833333333334, 0.0, 0.52, 0.0, 255.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.40625, 151.0, 1.0, 0.20833333333333334, 201.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.9895833333333334, 0.0, 1.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.gradients,"SVGID_9_",[0.0, 0.9895833333333334, 0.0, 1.0, 0.3020833333333333, 177.0]);
		this.fillGradient(this.fills,"Group_Legs",[0.0, 0.6614583333333334, 0.0, 1.0, 0.6614583333333334, 0.0, 2.0, 0.23958333333333334, 193.0, 3.0, 0.23958333333333334, 193.0]);
		this.fillGradient(this.fills,"Group_Base",[0.0, 0.3958333333333333, 0.0]);
		this.fillGradient(this.fills,"Group_Frame",[0.0, 0.40625, 151.0, 1.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_OuterBody",[0.0, 0.609375, 99.0, 1.0, 0.609375, 99.0, 2.0, 0.609375, 99.0, 3.0, 0.609375, 99.0]);
		this.fillGradient(this.fills,"Group_LeftConnector",[0.0, 0.8072916666666666, 49.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.4375, 143.0, 3.0, 0.23958333333333334, 193.0, 4.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_RightConnector",[0.0, 0.8072916666666666, 49.0, 1.0, 0.9895833333333334, 0.0, 2.0, 0.4375, 143.0, 3.0, 0.23958333333333334, 193.0, 4.0, 0.8072916666666666, 49.0]);
		this.fillGradient(this.fills,"Group_LeftWheel",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0, 6.0, 0.5885416666666666, 0.0, 7.0, 0.5885416666666666, 0.0]);
		this.fillGradient(this.fills,"Group_RightWheel",[0.0, 0.5885416666666666, 0.0, 1.0, 0.5885416666666666, 0.0, 2.0, 0.5885416666666666, 0.0, 3.0, 0.5885416666666666, 0.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.5885416666666666, 0.0, 6.0, 0.5885416666666666, 0.0, 7.0, 0.5885416666666666, 0.0]);

        super.drawObject();
    }
}
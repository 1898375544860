import { SVGObject } from "../svgobject";

export class SVGFinish20 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 75.787" enable-background="new 0 0 112.5 75.787" xml:space="preserve">
        <g id="Group_Axial_Rear">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="17.2534" y1="46.5088" x2="17.2534" y2="4.882813e-004">
                <stop offset="0" style="stop-color:#D2C5AC" />
                <stop offset="0.5" style="stop-color:#F2E8D6" />
                <stop offset="0.99" style="stop-color:#D1C4AB" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M34.125,40.519c-0.011-1.071,0.124-2,0.371-2.833   c-0.012-1.409-0.428-2.931-0.423-4.249c0.006-1.723,0.051-3.445,0.053-5.168c0.003-3.761,0.059-7.5,0.375-11.25   c0-0.007,0.003-0.012,0.003-0.019c0.046-3.837-0.479-7.642-0.378-11.481c0.005-0.198,0.039-0.377,0.063-0.563h-0.065V0h-14.64   v9.572H0.001v27.027h19.481v9.91h14.64v-4.955h0.12C34.176,41.227,34.129,40.886,34.125,40.519z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M19.482,36.599V9.572" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="26.8022" y1="46.5088" x2="26.8022" y2="4.882813e-004">
                <stop offset="0.01" style="stop-color:#BFB299" />
                <stop offset="0.5" style="stop-color:#F2E5CC" />
                <stop offset="1" style="stop-color:#BFB299" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.1" d="M21.96,0h9.685v46.509H21.96V0z" />
        </g>
        <g id="Group_Spray_Gun">
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="97.335" y1="46.5088" x2="97.335" y2="6.065369e-004">
                <stop offset="0" style="stop-color:#D2C5AC" />
                <stop offset="0.5" style="stop-color:#F2E8D6" />
                <stop offset="0.99" style="stop-color:#D1C4AB" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" stroke="#4C4C4C" stroke-width="0.25" d="M87.507,2.478c0.311,3.781-0.116,7.464-0.127,11.291   c-0.012,4.09,0.351,8.203,0.088,12.292c-0.026,0.407-0.08,0.784-0.144,1.151c0.041,0.243,0.075,0.486,0.122,0.728   c0.202,1.045,0.102,2.012-0.208,2.861c0.027,0.463,0.055,0.926,0.083,1.389c0.07,0.482,0.072,0.941,0.027,1.38   c0.127,3.489,0.165,6.938,0.244,10.436c0,0.009-0.001,0.017,0,0.025h19.839V2.478H87.507z" />
            <path fill="#333333" stroke="#4C4C4C" stroke-width="0.25" d="M109.909,26.914h2.59v9.685h-2.59V26.914z" />
            <path fill="#333333" d="M109.909,7.32h2.59v9.685h-2.59V7.32z" />
            <path d="M107.432,4.955h2.478v34.121h-2.478V4.955z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="101.4629" y1="44.0303" x2="101.4629" y2="2.478">
                <stop offset="0.01" style="stop-color:#B21900" />
                <stop offset="0.5" style="stop-color:#E54C00" />
                <stop offset="1" style="stop-color:#B21900" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M97.859,2.478h7.207V44.03h-7.207V2.478z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="92.9053" y1="44.0303" x2="92.9053" y2="2.478">
                <stop offset="0.01" style="stop-color:#BFB299" />
                <stop offset="0.5" style="stop-color:#F2E5CC" />
                <stop offset="1" style="stop-color:#BFB299" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.1" d="M90.428,2.478h4.955V44.03h-4.955V2.478z" />
        </g>
        <g id="Group_Sprayer">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="87.2842" y1="76.0283" x2="76.4518" y2="46.2665">
                <stop offset="0" style="stop-color:#B2B2B2" />
                <stop offset="0.4" style="stop-color:#B2B2B2" />
                <stop offset="0.5" style="stop-color:#BFBFBF" />
                <stop offset="1" style="stop-color:#BFBFBF" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M75.788,46.509H87.95v29.278H75.788V46.509z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M39.077,46.509h36.711v29.278H39.077V46.509z" />
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="90.0537" y1="45.7432" x2="73.6832" y2="0.7655">
                <stop offset="0" style="stop-color:#B8B8B8" />
                <stop offset="0.4" style="stop-color:#B8B8B8" />
                <stop offset="0.5" style="stop-color:#CCCCCC" />
                <stop offset="1" style="stop-color:#CCCCCC" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M75.788,0H87.95v46.509H75.788V0z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,0h4.729v9.572h-4.729V0z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M75.788,0H87.95v46.509H75.788V0" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,1.014h3.604v5.067h-3.604V1.014z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,8.559h3.604V6.081h-3.604V8.559z" />
            <path fill="#666666" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,36.599h4.729v9.91h-4.729V36.599z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,37.725h3.604v4.955h-3.604V37.725z" />
            <path fill="#999999" stroke="#4C4C4C" stroke-width="0.25" d="M83.221,45.157h3.604V42.68h-3.604V45.157z" />
            <path fill="#999999" d="M66.328,66.103h-0.225v9.685h-2.478v-9.685h-0.45l-2.928-1.802l-1.577-2.702V58.22l1.577-2.703l2.928-1.801   h0.45v-7.207h2.478v7.207h0.225l2.928,1.801l1.577,2.703v3.379l-1.577,2.702L66.328,66.103z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M75.788,46.509H87.95v29.278H75.788V46.509" />
            <path fill="none" stroke="#E5E5E5" stroke-width="0.5" d="M78.266,40.202V3.491l1.126,1.464v34.121h1.351V6.081" />
            <path fill="#999999" d="M78.266,52.589h7.432v3.604h-7.432V52.589z" />
            <path fill="#E5E5E5" d="M67.229,70.833l1.802-4.73h14.865l1.801,4.73H67.229z" />
            <path fill="#B2B2B2" d="M69.031,75.787l-1.802-4.954h18.468l-1.801,4.954H69.031z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M69.031,75.787l-1.802-4.954l1.802-4.73h14.865l1.801,4.73l-1.801,4.954   H69.031" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M66.104,75.787V46.509" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.626,75.787V46.509" />
            <radialGradient id="SVGID_8_" cx="64.752" cy="59.7969" r="6.0811" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E0E0E0" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </radialGradient>
            <circle fill="url(#SVGID_8_)" cx="64.752" cy="59.797" r="6.081" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M66.328,53.716l2.928,1.576l1.577,2.928v3.153l-1.577,2.928   l-2.928,1.577" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.176,65.878l-2.928-1.577l-1.577-2.928V58.22l1.577-2.928   l2.928-1.576" />
        </g>
        <g id="Group_Net">
            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="37.2749" y1="70.833" x2="37.2749" y2="59.7959">
                <stop offset="0" style="stop-color:#7F7F7F" />
                <stop offset="0.5" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#7F7F7F" />
            </linearGradient>
            <path fill="url(#SVGID_9_)" d="M39.077,70.833h-3.604V59.796h3.604V70.833z" />
            <path fill="#CCCCCC" stroke="#4C4C4C" stroke-width="0.25" d="M31.645,57.319h3.829v4.955h-3.829V57.319z" />
            <path fill="#7F7F7F" stroke="#4C4C4C" stroke-width="0.25" d="M31.645,73.31h3.829v-2.477h-3.829V73.31z" />
            <path fill="#B2B2B2" stroke="#4C4C4C" stroke-width="0.25" d="M31.645,70.833h3.829v-8.559h-3.829V70.833z" />
        </g>
        <g id="Group_Color_Box">
            <path fill="#003333" d="M39.077,46.509h36.711V0H39.077V46.509z" />
            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="36.5991" y1="41.5537" x2="36.5991" y2="4.9556">
                <stop offset="0" style="stop-color:#003333" />
                <stop offset="0.5" style="stop-color:#669999" />
                <stop offset="1" style="stop-color:#003333" />
            </linearGradient>
            <path fill="url(#SVGID_10_)" d="M34.122,4.955h4.955v36.599h-4.955V4.955z" />
            <path fill="#669999" d="M39.077,46.509l2.478-2.479V2.478h31.756L75.788,0H39.077V46.509z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M34.122,4.955h4.955v36.599h-4.955V4.955" />
            <path d="M75.788,0l-2.478,2.478V44.03H41.554l-2.478,2.479h36.711V0z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M39.077,46.509h36.711V0H39.077V46.509" />
            <path fill="none" stroke="#999999" stroke-width="0.5" d="M44.032,8.559h26.801v12.049H44.032V8.559" />
            <path fill="none" stroke="#999999" stroke-width="0.5" d="M46.509,13.401h21.846" />
            <path d="M59.797,23.085h3.829v3.829h-3.829V23.085z" />
        </g>
        </svg>`
		super.fillGradients();

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe16 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 98.359" enable-background="new 0 0 112.5 98.359" xml:space="preserve">

        <g id="Group_30_Deg_Pipe">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="105.9561" y1="45.7446" x2="14.8887" y2="61.8022" gradientTransform="matrix(0 1 1 0 9.0703 -9.0703)">
            <stop offset="0" style="stop-color:#646566" />
            <stop offset="0.51" style="stop-color:#CCCCCC" />
            <stop offset="0.99" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M111.938,20.229l-38.686-0.25L40.651,0.489L2.453,68.398c0,0,36.982,23.462,45.01,25.957  c8.03,2.497,13.626,4.016,17.519,4.016c3.892,0,46.957,0,46.957,0V20.229z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="89.125" y1="98.3594" x2="89.125" y2="19.6851">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M69.445,98.359H112.5V19.798l-46.75-0.113L69.445,98.359z" />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="18.2471" y1="78.8408" x2="57.9492" y2="10.0747">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M1.218,68.191l32.605,19.643l41.51-67.571L40.499,0L1.218,68.191z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="51.3818" y1="95.7305" x2="65.8333" y2="17.8847" gradientTransform="matrix(0.9682 0.2501 -0.2501 0.9682 16.7386 -13.2747)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M74.242,19.685L33.147,87.478c0,0,6.927,3.701,9.326,4.743c2.445,1.063,10.021,3.646,10.021,3.646  L74.242,19.685z" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="61.6211" y1="98.7549" x2="69.9151" y2="19.7074" gradientTransform="matrix(0.9998 0.0176 -0.0176 0.9998 0.9438 -1.7091)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.17" style="stop-color:#8F8F90" />
            <stop offset="0.49" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_5_)" d="M74.242,19.685L51.533,95.593c0,0,8.415,2.418,11.304,2.767c1.871,0.226,7.538,0,7.538,0  L74.242,19.685z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.7864583333333334, 0.0, 0.51, 0.40625, 151.0, 0.99, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.17, 0.8854166666666666, 29.0, 0.49, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);

        super.drawObject();
    }
}
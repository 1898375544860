import { SVGObject } from "../svgobject";

export class SVGChemical13 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 70.834" enable-background="new 0 0 112.5 70.834" xml:space="preserve">
        <g id="Group_MainFrame">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="26.7026" y1="-18.1794" x2="85.5596" y2="40.6775">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M5.682,19.656V2.841H106.58v16.816H5.682z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="9.8286" y1="-1.3069" x2="91.1846" y2="80.0491">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M0,70.22h101.013V8.522H89.765v44.881H16.816V8.522H0V70.22z" />
            <path fill="#666666" d="M101.013,70.22l11.249-5.682V0l-5.682,2.841v2.841l-5.567,2.84V70.22z" />
            <path fill="#999999" d="M16.816,22.497V8.522l5.681-2.84v13.975L16.816,22.497z" />
            <path fill="#666666" d="M16.816,53.403V25.338h5.681v25.225L16.816,53.403z" />
            <path fill="#999999" d="M16.816,53.403h72.948v-2.841H22.498L16.816,53.403z" />
            <path fill="#4C4C4C" d="M22.498,25.338h67.267v25.225H22.498V25.338z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M89.765,8.522V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M33.747,19.656V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M44.882,19.656V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M56.131,19.656V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M67.38,19.656V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M78.516,19.656V2.841" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M22.498,5.681V2.841" />
            <path fill="#231F20" d="M22.498,18.294h67.267v12.612H22.498V18.294z" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="34.3438" y1="4.9705" x2="72.2382" y2="42.8649">
                <stop offset="0" style="stop-color:#C8C8C8" />
                <stop offset="0.29" style="stop-color:#B1B1B1" />
                <stop offset="0.91" style="stop-color:#757575" />
                <stop offset="1" style="stop-color:#6C6C6C" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M16.816,22.497h72.948v2.841H16.816V22.497z" />
            <path fill="#F2F2F2" d="M16.816,22.497l5.681-2.84h67.267v2.84H16.816z" />
            <path fill="#F2F2F2" d="M89.765,8.522l5.682-2.84h11.134l-5.567,2.84H89.765z" />
            <path fill="#F2F2F2" d="M5.682,2.841L11.249,0h101.013l-5.682,2.841H5.682z" />
            <path fill="#F2F2F2" d="M0,8.522h16.816l5.681-2.84H5.682L0,8.522z" />
        </g>
        <g id="Group_Indicators">
            <path fill="#231F20" d="M5.682,50.562h5.567V16.817H5.682V50.562z" />
            <path fill="#666666" d="M5.682,50.562l2.84-1.477V16.817h-2.84V50.562z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="9.8853" y1="49.0857" x2="9.8853" y2="28.0657">
                <stop offset="0" style="stop-color:#E56565" />
                <stop offset="0.19" style="stop-color:#D75A5A" />
                <stop offset="0.57" style="stop-color:#B13C3D" />
                <stop offset="1" style="stop-color:#7E1416" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" d="M8.521,49.086h2.728V28.065H8.521V49.086z" />
        </g>
        <g id="Group_BlueBox">
            <path fill="#7572B5" d="M35.11,42.155h54.654v9.771H35.11V42.155z" />
            <path fill="#7572B5" d="M16.816,43.518h9.885v9.885h-9.885V43.518z" />
            <path fill="#605FAA" d="M35.11,42.155h54.654v-2.841H40.678L35.11,42.155z" />
            <path fill="#605FAA" d="M16.816,43.518h9.885l5.568-2.841l-9.772,0.113L16.816,43.518z" />
            <path fill="#363795" d="M26.702,53.403v-9.885l5.568-2.841v9.885L26.702,53.403z" />
        </g>
        <g id="Group_Squares">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M16.816,56.131h11.249V67.38H16.816V56.131" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M2.841,56.131h11.249V67.38H2.841V56.131" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.2522" d="M16.816,67.38V56.131h11.249" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.2522" d="M2.841,67.38V56.131h11.249" />
        </g>
        <g id="Group_Boxes">
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M98.171,25.338h-5.566V14.089h5.566V25.338" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M98.171,39.314h-5.566V28.065h5.566V39.314" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2522" d="M98.171,53.403h-5.566V42.155h5.566V53.403" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.2522" d="M98.171,14.089v11.249h-5.566" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.2522" d="M98.171,28.065v11.249h-5.566" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.2522" d="M98.171,42.155v11.248h-5.566" />
        </g>
        <g id="Group_Stripes">
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M92.604,67.38V56.131" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M95.446,66.697v-9.885" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M89.765,66.697v-9.885" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M88.4,65.334V58.29" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M96.808,65.334V58.29" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M93.968,67.38V56.131" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M91.128,67.38V56.131" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M87.037,62.493v-1.477" />
            <path fill="none" stroke="#4D4D4D" stroke-width="0.2522" d="M98.171,62.493v-1.477" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.4375, 143.0, 0.29, 0.6197916666666666, 97.0, 0.91, 0.9114583333333334, 0.0, 1.0, 0.8385416666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.8854166666666666, 29.0, 0.19, 0.9791666666666666, 5.0, 0.57, 0.7708333333333334, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.fills,"Group_MainFrame",[0.0, 0.7916666666666666, 0.0, 1.0, 0.8072916666666666, 49.0, 2.0, 0.7916666666666666, 0.0, 3.0, 0.8072916666666666, 49.0, 4.0, 0.5885416666666666, 0.0, 5.0, 0.25, 0.0, 6.0, 0.11458333333333333, 226.0, 7.0, 0.11458333333333333, 226.0, 8.0, 0.11458333333333333, 226.0, 9.0, 0.11458333333333333, 226.0]);
		this.fillGradient(this.fills,"Group_Indicators",[0.0, 0.25, 0.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.fills,"Group_BlueBox",[0.0, 0.9322916666666666, 17.0, 1.0, 0.9322916666666666, 17.0, 2.0, 0.9322916666666666, 0.0, 3.0, 0.9322916666666666, 0.0, 4.0, 0.6666666666666666, 0.0]);

        super.drawObject();
    }
}
import { SVGObject } from "../svgobject";

export class SVGPipe8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 83.254" enable-background="new 0 0 112.5 83.254" xml:space="preserve">
        <g id="Group_">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="41.4106" y1="13.0515" x2="41.4106" y2="55.5774" gradientTransform="matrix(1 0 0 -1 0 83.2534)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#909091" />
            <stop offset="0.53" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <polygon fill="url(#SVGID_1_)" points="0.3,27.677 80.297,27.677 82.521,27.845 82.521,70.202 0.3,70.202 " />
        <radialGradient id="SVGID_2_" cx="160.5811" cy="343.7078" r="10.7817" gradientTransform="matrix(2.9866 0.1891 -0.2179 2.5702 -322.9485 -859.7911)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#BEBEBE" />
            <stop offset="0.24" style="stop-color:#E0E0E0" />
            <stop offset="0.39" style="stop-color:#C3C3C3" />
            <stop offset="0.75" style="stop-color:#878782" />
            <stop offset="1" style="stop-color:#5A5A5A" />
        </radialGradient>
        <path fill="url(#SVGID_2_)" d="M80.043,27.667c15.766,0,32.338,9.355,32.338,27.884l-27.41,0.069  c-0.572-3.262-2.135-4.048-4.84-4.258C79.814,45.152,80.043,27.667,80.043,27.667z" />
        <radialGradient id="SVGID_3_" cx="156.4883" cy="287.4324" r="10.7817" gradientTransform="matrix(2.9866 -0.1917 -0.2179 -2.6041 -322.9485 834.6785)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#BEBEBE" />
            <stop offset="0.24" style="stop-color:#E0E0E0" />
            <stop offset="0.39" style="stop-color:#C3C3C3" />
            <stop offset="0.75" style="stop-color:#878782" />
            <stop offset="1" style="stop-color:#5A5A5A" />
        </radialGradient>
        <path fill="url(#SVGID_3_)" d="M80.082,82.829c15.766,0,32.336-9.479,32.336-28.252l-27.408-0.07  c-0.574,3.304-2.135,4.103-4.84,4.313C79.854,65.114,80.082,82.829,80.082,82.829z" />
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="47.7036" y1="41.3113" x2="47.7036" y2="82.0247" gradientTransform="matrix(-1 0 0 1 113.2695 0.7803)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.16" style="stop-color:#8E8F90" />
            <stop offset="0.5" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_4_)" d="M78.118,42.361c-0.195-0.071-0.377-0.174-0.559-0.27H50.125v40.713h30.881V44.283  C80.118,43.405,79.124,42.725,78.118,42.361z" />
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="31.4248" y1="40.802" x2="31.4248" y2="80.3671" gradientTransform="matrix(-1 0 0 1 113.2695 0.7803)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.21" style="stop-color:#909091" />
            <stop offset="0.52" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#646566" />
        </linearGradient>
        <path fill="url(#SVGID_5_)" d="M83.037,47.013c-0.785-1.562-1.994-2.782-3.295-3.791c-2.187,6.678-1.311,14.026-1.436,20.568  c2.535-0.147,5.168-0.465,6.213-3.348c1.742-4.598,0.812-8.641-0.852-12.484C83.43,47.675,83.215,47.364,83.037,47.013z" />
        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="69.9805" y1="65.7576" x2="111.6943" y2="65.7576" gradientTransform="matrix(-1 0 0 -1 113.2695 83.4653)">
            <stop offset="0.01" style="stop-color:#646566" />
            <stop offset="0.18" style="stop-color:#959595" />
            <stop offset="0.5" style="stop-color:#E0E0E0" />
            <stop offset="1" style="stop-color:#666666" />
        </linearGradient>
        <rect x="1.575" y="0" fill="url(#SVGID_6_)" width="41.714" height="35.415" />
        <radialGradient id="SVGID_7_" cx="150.0479" cy="294.2566" r="17.5053" gradientTransform="matrix(-2.794 -0.2001 0.2038 -2.719 407.406 867.7335)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#585859" />
            <stop offset="0.1" style="stop-color:#646566" />
            <stop offset="0.22" style="stop-color:#888888" />
            <stop offset="0.4" style="stop-color:#BEBEBE" />
            <stop offset="0.52" style="stop-color:#E0E0E0" />
            <stop offset="0.64" style="stop-color:#C0C0C1" />
            <stop offset="0.75" style="stop-color:#9C9C9D" />
            <stop offset="0.89" style="stop-color:#787878" />
            <stop offset="1" style="stop-color:#545454" />
        </radialGradient>
        <path fill="url(#SVGID_7_)" d="M50.731,82.804c-23.948,0-49.119-16.067-49.119-47.894l41.633-0.122  c0.871,5.603,3.244,6.951,7.352,7.315C51.08,52.776,50.731,82.804,50.731,82.804z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.875, 31.0, 0.53, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.515625, 123.0, 0.24, 0.25, 191.0, 0.39, 0.4739583333333333, 133.0, 0.75, 0.9635416666666666, 9.0, 1.0, 0.6979166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.515625, 123.0, 0.24, 0.25, 191.0, 0.39, 0.4739583333333333, 133.0, 0.75, 0.9635416666666666, 9.0, 1.0, 0.6979166666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.01, 0.7864583333333334, 0.0, 0.16, 0.8854166666666666, 29.0, 0.5, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.01, 0.7864583333333334, 0.0, 0.21, 0.875, 31.0, 0.52, 0.25, 191.0, 1.0, 0.7864583333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.01, 0.7864583333333334, 0.0, 0.18, 0.8385416666666666, 41.0, 0.5, 0.25, 191.0, 1.0, 0.7916666666666666, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.6822916666666666, 0.0, 0.1, 0.7864583333333334, 0.0, 0.22, 0.9375, 15.0, 0.4, 0.515625, 123.0, 0.52, 0.25, 191.0, 0.64, 0.5, 127.0, 0.75, 0.78125, 55.0, 0.89, 0.9322916666666666, 0.0, 1.0, 0.6510416666666666, 0.0]);

        super.drawObject();
    }
}
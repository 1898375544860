import { SVGObject } from "../svgobject";

export class SVGButton4 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Mounting_Bannet">
            <radialGradient id="SVGID_1_" cx="55.25" cy="55.5" r="56.25" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#737373" />
                <stop offset="0.82" style="stop-color:#999999" />
                <stop offset="1" style="stop-color:#383838" />
            </radialGradient>
            <circle fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.5" cx="55.25" cy="55.5" r="56.25" />
        </g>
        <g id="Group_Green_Push_Button2">
            <path fill="#00BA00" stroke="#000000" stroke-width="0.25" d="M19.032,56.194c0,20.524,16.638,37.162,37.162,37.162   s37.162-16.638,37.162-37.162S76.718,19.032,56.194,19.032S19.032,35.67,19.032,56.194" />
            <radialGradient id="SVGID_2_" cx="44.9302" cy="45.0962" r="18.4683" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#BFFFBF" />
                <stop offset="1" style="stop-color:#00BA00" />
            </radialGradient>
            <circle fill="url(#SVGID_2_)" cx="44.93" cy="45.096" r="18.468" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.8958333333333334, 0.0, 0.82, 0.8072916666666666, 49.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.34375, 167.0, 1.0, 0.4791666666666667, 0.0]);
		this.fillGradient(this.fills,"Group_Green_Push_Button2",[0.0, 0.4791666666666667, 0.0]);

        super.drawObject();
    }
}
import { Property } from "./property";
import tag from "@/model/tag";
import tagsModele from "@/store/tags.modele";
import { DialogBox } from "../../dialogbox";
import dialogboxModule from "@/store/dialogbox.module";
import { Project } from "../../project";
import popupscreenModule from "@/store/popupscreen.module";

export class ButtonControlProperty extends Property{
    functiontype:number;
    value:string;
    title:string;
    screenname:string;
    command:string;



    public setClick():void{
        
        
        const tag:tag = tagsModele.getTag(this.path); 
        if (tag==null && this.functiontype<6) return;
        switch (this.functiontype){
            case 0: tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"1",datetime:0});
                break;
            case 1: tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0", datetime:0});
                break;
            case 2: if (tag.tagvalue==='true' || tag.tagvalue==='1')
                        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"0",datetime:0});
                    else
                        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"1",datetime:0});
                break;
            case 3: tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:"1", datetime:0});
                break;
            case 4: tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:this.value, datetime:0});
                break;
            case 5:
                {
                    const dialogbox:DialogBox = new DialogBox();
                    dialogbox.enable = true;
                    dialogbox.title = this.title;
                    dialogbox.tagname = this.path;
                    dialogboxModule.setDialogBox(dialogbox);
                    break;
                }
            case 6: {
                this.parsearguments(this.command)
                Project.getScreen(this.screenname)
                break;
            } 
            case 7: {
                this.parsearguments(this.command)
                Project.getPopupScreen(this.screenname)
                break;
            }
            case 8:{
                this.parsearguments(this.command)
                popupscreenModule.setPopupEnabled(false);
                break;
            }
                    
            default:
                break;
        }
    }
    public setReleased():void{
        const tag:tag = tagsModele.getTag(this.path); 
        if (tag==null || this.functiontype!=3) return;
        tagsModele.writeTagAction({token:"",name: tag.name, tagvalue:"0", datetime:0});
    }
    
   private parsearguments(command:string){
        if (command==null || command.length==0) return;
        const args = command.split(";").map(el=>el.split("="))
        if (args.length==0) return;
        
        args.forEach((el)=>{
            if(el.length == 2 && el[0].trim() != null && el[1].trim() != null){
                Project.setGlobalArgument(el[0].trim(), el[1].trim())
            }
        })
    }



}
import { SVGObject } from "../svgobject";

export class SVGTank70 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 65.318 112.5" enable-background="new 0 0 65.318 112.5" xml:space="preserve">
        <g id="Group_TankBody">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="19.0728" y1="7.127" x2="45.9521" y2="7.127">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.56" style="stop-color:#D25252" />
                <stop offset="0.77" style="stop-color:#A62626" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M19.073,2.111h26.879v10.032H19.073V2.111" />
            
                <radialGradient id="SVGID_2_" cx="32.4561" cy="21.1211" r="24.4881" gradientTransform="matrix(1 0 0 1.2 0 -4.2242)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E56565" />
                <stop offset="0.19" style="stop-color:#D75A5A" />
                <stop offset="0.57" style="stop-color:#B13C3D" />
                <stop offset="1" style="stop-color:#7E1416" />
            </radialGradient>
            <path fill="url(#SVGID_2_)" d="M65.017,29.356c0,0,0,3.561-32.695,3.561c-32.484,0-32.426-3.561-32.426-3.561   c0.002-5.878,4.64-14.133,11.382-17.743c5.742-3.076,13.301-2.162,21.571-2.162c7.402,0,15.976-0.454,20.15,1.739   C60.622,15.191,65.017,22.953,65.017,29.356z" />
            
                <radialGradient id="SVGID_3_" cx="171.3564" cy="-47.4551" r="24.4886" gradientTransform="matrix(1 0 0 1.2 -138.959 145.8484)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#E56565" />
                <stop offset="0.19" style="stop-color:#D75A5A" />
                <stop offset="0.57" style="stop-color:#B13C3D" />
                <stop offset="1" style="stop-color:#7E1416" />
            </radialGradient>
            <path fill="url(#SVGID_3_)" d="M-0.163,80.664c0,0,0-3.56,32.695-3.56c32.484,0,32.425,3.56,32.425,3.56   c-0.002,5.881-4.639,14.134-11.381,17.748c-5.742,3.075-13.301,2.16-21.574,2.16c-7.4,0-15.974,0.454-20.149-1.738   C4.235,94.832-0.163,87.07-0.163,80.664z" />
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-0.104" y1="55.0908" x2="65.0166" y2="55.0908">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <rect x="-0.104" y="29.356" fill="url(#SVGID_4_)" width="65.121" height="51.469" />
        </g>
        <g id="Group_Flange">
            <path fill="#4D4D4D" d="M17.946,0.424h2.363v5.849h-2.363V0.424z" />
            <path fill="#4D4D4D" d="M22.673,0.424h2.246v5.849h-2.246V0.424z" />
            <path fill="#4D4D4D" d="M27.282,0.424h2.251v5.849h-2.251V0.424z" />
            <path fill="#4D4D4D" d="M31.894,0.424h2.363v5.849h-2.363V0.424z" />
            <path fill="#4D4D4D" d="M36.616,0.424h2.25v5.849h-2.25V0.424z" />
            <path fill="#4D4D4D" d="M41.228,0.424h2.361v5.849h-2.361V0.424z" />
            <path fill="#4D4D4D" d="M45.952,0.424H48.2v5.849h-2.248V0.424z" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="14.4614" y1="3.2925" x2="51.126" y2="3.2925">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" d="M14.461,1.549h36.665v3.486H14.461V1.549" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.2497" d="M14.461,3.236h36.665" />
        </g>
        <g id="Group_Base">
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="8.2905" y1="104.7207" x2="56.3174" y2="104.7207">
                <stop offset="0" style="stop-color:#7F0000" />
                <stop offset="0.45" style="stop-color:#E56565" />
                <stop offset="0.58" style="stop-color:#D25252" />
                <stop offset="0.84" style="stop-color:#A22222" />
                <stop offset="1" style="stop-color:#7F0000" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" d="M8.291,112.779h48.027V96.663H8.291V112.779" />
        </g>
        <g id="Group1">
            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="14.3481" y1="105.3867" x2="38.8326" y2="105.3867">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_7_)" d="M23.405,100.857h-4.527c-2.501,0-4.529,2.026-4.529,4.529c0,2.499,2.028,4.528,4.529,4.528h4.527   c2.5,0,4.529-2.029,4.529-4.528C27.934,102.884,25.905,100.857,23.405,100.857z" />
            
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-566.4688" y1="105.8047" x2="-541.9808" y2="105.8047" gradientTransform="matrix(-1 0 0 1 -517.5889 0)">
                <stop offset="0" style="stop-color:#383838" />
                <stop offset="0.01" style="stop-color:#3B3B3B" />
                <stop offset="0.25" style="stop-color:#757575" />
                <stop offset="0.42" style="stop-color:#989898" />
                <stop offset="0.51" style="stop-color:#A6A6A6" />
                <stop offset="0.69" style="stop-color:#818181" />
                <stop offset="1" style="stop-color:#383838" />
            </linearGradient>
            <path fill="url(#SVGID_8_)" d="M39.823,101.276h4.527c2.502,0,4.529,2.027,4.529,4.528s-2.027,4.529-4.529,4.529h-4.527   c-2.502,0-4.531-2.028-4.531-4.529S37.321,101.276,39.823,101.276z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.56, 0.9635416666666666, 0.0, 0.77, 0.625, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.0, 0.8854166666666666, 29.0, 0.19, 0.9791666666666666, 5.0, 0.57, 0.7708333333333334, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.0, 0.8854166666666666, 29.0, 0.19, 0.9791666666666666, 5.0, 0.57, 0.7708333333333334, 0.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.3229166666666667, 0.0, 0.45, 0.8854166666666666, 29.0, 0.58, 0.9635416666666666, 0.0, 0.84, 0.5885416666666666, 0.0, 1.0, 0.3229166666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_8_",[0.0, 0.4322916666666667, 0.0, 0.01, 0.4583333333333333, 0.0, 0.25, 0.9114583333333334, 0.0, 0.42, 0.8125, 47.0, 0.51, 0.703125, 75.0, 0.69, 0.9947916666666666, 1.0, 1.0, 0.4322916666666667, 0.0]);

        super.drawObject();
    }
}
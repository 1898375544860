import {ColorUtils} from '@/model/project/utils/colorutils'
import { IndicatorProperty } from './properties/indicatorproperty';
import Konva from "konva";
import { Indicator } from './indicator';

export class RectIndicator extends Indicator{   
   

    public drawObject():void{
        super.initObject();

        let indicator = false;
        const indicatorprop = this.properties['indicator'];
        if (indicatorprop!=null){	
            const indicatorproperty:IndicatorProperty =Object.assign(new IndicatorProperty(), indicatorprop);
            if (indicatorproperty!=null) 
                    indicator = indicatorproperty.CheckIndicator(this.tagnames);     
        }	


        const truecolor = ColorUtils.convertformat(this.truecolor);
        const falsecolor = ColorUtils.convertformat(this.falsecolor);

        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.height/10
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, 'white', 
            1, '#888888'])
          }
          else{
            rectout.fill('#888888')
          }
          this.node.add(rectout) 
        
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const gradientrect = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientrect.addColorStop(0, '#888888')
          gradientrect.addColorStop(1, 'white')

          const gradientline1 = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientline1.addColorStop(0, '#888888')
          gradientline1.addColorStop(1, 'white')

          const gradientline2 = ctx.createLinearGradient(0, this.height/10, 0, this.height/10+this.height*4/5);
          gradientline2.addColorStop(0, '#888888')
          gradientline2.addColorStop(1, 'white')

          const rectinner = new Konva.Rect({
            x: this.width/10,
            y: this.height/10,
            width: this.width*4/5,
            height: this.height*4/5,
            cornerRadius: this.height/10,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientrect: 'white'
          });
          
          this.node.add(rectinner)   

        const rect1 = new Konva.Rect({
            x: this.width*3/20,
            y: this.height*4/20,
            width: this.width*6/20,
            height: this.height*12/20,
            strokeWidth: this.height/40,
            stroke: this.type3d==0? gradientline1: 'white'
          });
          if(indicator){
          if (this.type3d==0){
            rect1.fillLinearGradientStartPoint({ x: 0, y: this.height*4/20 });
            rect1.fillLinearGradientEndPoint({ x: 0, y: this.height*16/20 });
            rect1.fillLinearGradientColorStops([0, ColorUtils.darkColor(falsecolor,0.75), 
            1, ColorUtils.darkColor(falsecolor,0.2)])
          }
          else{
            rect1.fill(ColorUtils.darkColor(falsecolor,0.25))
            rect1.stroke('white')
            rect1.strokeWidth(this.height/40)
          }
        }else{
            rect1.fill(falsecolor)
        }
          this.node.add(rect1)
  
        const rect2 = new Konva.Rect({
                x: this.width*11/20,
                y: this.height*4/20,
                width: this.width*6/20,
                height: this.height*12/20,
                strokeWidth: this.height/40,
                stroke: this.type3d==0? gradientline2: 'white'
              });
              if(indicator){
                  rect2.fill(truecolor)
                
              }else{
                if(this.type3d==0){
                rect2.fillLinearGradientStartPoint({ x: 0, y: this.height*4/20 });
                rect2.fillLinearGradientEndPoint({ x: 0, y: this.height*16/20 });
                rect2.fillLinearGradientColorStops([0, ColorUtils.darkColor(truecolor,0.75), 
                  1, ColorUtils.darkColor(truecolor,0.2)])
              }else{
                rect2.fill(ColorUtils.darkColor(truecolor,0.2))
              }
            }
            this.node.add(rect2) 

            const radius = this.height>this.width? this.width*5/40 : this.height*7/40
            if(indicator){
                const circle = new Konva.Circle({
                x: this.width*14/20,
                y: this.height/2,
                radius: radius,
            });
            circle.fillRadialGradientStartPoint({x:0, y:0});
                circle.fillRadialGradientEndRadius(radius)
                circle.fillRadialGradientColorStops([0, 'white', 1, truecolor]);
                circle.fillPriority('radial-graident')
                this.node.add(circle); 
        }
        else{
            const circle = new Konva.Circle({
                x: this.width*6/20,
                y: this.height/2,
                radius: radius,
            });
            circle.fillRadialGradientStartPoint({x:0, y:0});
                circle.fillRadialGradientEndRadius(radius)
                circle.fillRadialGradientColorStops([0, 'white', 1, falsecolor]);
                circle.fillPriority('radial-graident')
                this.node.add(circle); 

        }        

    }
   
}
import { VuexModule,getModule, Module, Action, Mutation } from 'vuex-module-decorators';
import ScreenService from '@/services/screen.service';
import store from './index'
import { plainToInstance } from 'class-transformer';
import {Screen} from '@/model/project/screen';
import screeninfo from '@/model/screeninfo';
import authModule from './auth.module';
import { Project } from '@/model/project/project';
import getscreenrequest from '@/model/requests/getscreenrequest';


@Module({ name:"Screens", dynamic:true, store})
class Screens extends VuexModule {

  public screen:Screen;
  public screens:Screen[]=[];
  public screensinfo:screeninfo[]=[]
  public screendevid:number

@Action
public setScreenDeviceID(id:number){
  this.screendevid = id;
}

@Mutation setScreenMutation(screen:Screen){
  console.log("screen.id=",screen.id)
      Project.setCurrentScreenName(screen.name)
       this.screen = screen;
 
  if (this.screen==null) return

       const screenfind = this.screens.find(t=>t.name ===screen.name);
       if (screenfind==null)
          this.screens.push(screen)
  }
@Mutation addScreenInfoMutation(screeninfo:screeninfo){
    const screeninfofind = this.screensinfo.find(t=>t.name ===screeninfo.name)
    if (screeninfofind==null)
      this.screensinfo.push(screeninfo)
    else{
      screeninfofind.saved = screeninfo.saved
    }
}
@Mutation
public clearAllScreensInfoMutation(){
 
  this.screensinfo.splice(0);
}
@Action
public clearScreens(){
  console.log("clearScreens")
  this.screens.splice(0);
}
@Action
public getAllScreenInfoAction(){
    this.clearAllScreensInfoMutation();
    if (!authModule.currentUser) return;
    ScreenService.getAllScreensInfo(authModule.currentUser.accessToken,(data:string)=>{
       console.log(data)
        const screeninfo:screeninfo = JSON.parse(data);   
       console.log('screeninfo='+screeninfo);   
       this.addScreenInfoMutation(screeninfo)      
    },(data:string)=>{
      console.log(data);
      this.clearAllScreensInfoMutation()
    });
} 
@Action
public deleteScreen(request:getscreenrequest):Promise<Screen>{
  request.id = this.screendevid
  return new Promise (()=>{ 
    ScreenService.deleteScreen(request,(data:string)=>{
       console.log(data)
    },(data:string)=>{
      console.log(data);
    });
  })
}
@Action
public getScreenToSaveAction(request:getscreenrequest):Promise<Screen>{
  request.id = this.screendevid
  return new Promise (()=>{ 
    ScreenService.getScreenToSave(request,(data:string)=>{
       console.log(data)
    },(data:string)=>{
      console.log(data);
    });
  })
}
@Action
public getScreenAction(request:getscreenrequest):Promise<Screen>{
  request.id = this.screendevid
  console.log("getScreenAction=", request)
  return new Promise ((resolve)=>{ 
    ScreenService.getScreen(request,(data:string)=>{
        const screen:Screen = plainToInstance(Screen, JSON.parse(data)); 
        screen.id = this.screendevid  

        console.log('getScreenAction', screen);
        this.setScreenMutation(screen);   
        resolve(screen)   
    },(data:string)=>{
      console.log(data);
    });
  })
}


 
get getScreenByName(){return function (name:string){
  console.log("getScreenByName")
  if (this.screens.length==0) return null
  console.log("getScreenByName this.screens screendeviceid", this.screens, this.screendevid)
  const screensFiltered = this.screens.filter(scr=>scr.id==this.screendevid)
  if(screensFiltered==null || screensFiltered.length==0) return null

  if (name==""){
    this.screen = screensFiltered[0]
    console.log("name=='' this.screen.id", this.screen.id)
  }else{
    this.screen = screensFiltered.find(t=>t.name ==name);
  }
   
  console.log("this.screen=",this.screen)
  return this.screen
}}

get getScreen():Screen { 
        return this.screen;
    }
get getAllScreensInfo():screeninfo[]{
      return this.screensinfo;
    }
get getScreenDeviceId():number{
      return this.screendevid
  }
}

export default getModule(Screens);
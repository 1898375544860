import { opcode } from "../opcode";
import { Stack } from "./stack";
import {Scope} from "./scope"
import { Value } from "../value";
import { interpretMathOp } from "./interpretMathOp";
import { interpretStandartCommands } from "./interpretStandartCommands";
import { Script } from "../script";
import tagsModele from "@/store/tags.modele";
import tag from "@/model/tag";
import { Screen } from "../screen";
import { ObjectView } from "../objects/object";


export class Interpreter{
    opcodes:opcode[];
    position:number;
    stack:Stack;
    scope:Scope;
    script:Script;
    screen:Screen;
    object:ObjectView;

    constructor(script:Script, screen:Screen, object:ObjectView){
        this.position=0;
        this.stack = new Stack();
        this.scope = new Scope();
        this.script = script
        this.screen = screen;
        this.object = object;
    }

    execute():void{
        //console.log("OPCODES=",this.opcodes)
        //console.log("position=",this.position)
        while(this.position<this.opcodes.length){
            const opcode:opcode = this.opcodes[this.position];
            switch (opcode.type){
                case "LOAD_CONST": {this.evalLoadConst(opcode);break;}
                case "STORE":{this.evalStore(opcode);break;}
                case "LOAD_FAST": {this.evalLoadFast(opcode);break;}
                case "BINARY_ADD":
				case "BINARY_REM":
				case "BINARY_MUL":
				case "BINARY_DIV":
				case "BINARY_GT":
				case "BINARY_GTE":
				case "BINARY_LT":
				case "BINARY_LTE":
				case "BINARY_EQ":
				case "BINARY_EXEQ":
				case "BINARY_OR":
				case "BINARY_XOR":
				case "BINARY_AND":
				case "BOOLEAN_OR":
				case "BOOLEAN_XOR":
				case "BOOLEAN_AND":
				case "MODULO":
				case "LEFT_SHIFT":
				case "RIGHT_SHIFT":{this.evalMathOp(opcode);break;}
                case "CALL":{this.evalCall(opcode);break;}
                case "POP_TOP":{this.evalPopTop(opcode);break;}
                case "JUMP_IF_TRUE": {this.evalJumpIfTrue(opcode); break;}
                case "JUMP_IF_FALSE": {this.evalJumpIfFalse(opcode); break;}
                case "JUMP": {this.evalJump(opcode); break;}
                case "INIT_ARRAY": {this.evalInitArray(opcode); break;}
                case "LOAD_MEMBER": {this.evalLoadMember(opcode); break}
                case "STORE_MEMBER": {this.evalStoreMember(opcode); break;}
                case "LOAD_TAG": {this.evalLoadTag(opcode); break;}
				case "STORE_TAG": {this.evalStoreTag(opcode); break;}	
                case "LOAD_OBJECT": {this.evalLoadObject(opcode); break;}	
				case "STORE_OBJECT": {this.evalStoreObject(opcode); break;}	
            }
            this.position++;
        }
    }
    evalLoadConst(opcode:opcode):void{
        const value:Value = new Value();
        value.datatype = opcode.datatype;
        if (opcode.path==null)
            value.setValue(opcode.value);
        else
            value.setValue(opcode.path)
        //console.log('evalLoadConst', value)
        this.stack.push(value)
    }

    evalStore(opcode:opcode):void{
        const name = opcode.value;
        const value = this.stack.pop();
        if (value==null) return;
        const vvalue = value.value;
        //console.log('evalStore vvalue', vvalue)
        let resvalue:Value = new Value();
       // console.log("STORE=",value)
        if (value instanceof Value){
                if (opcode.datatype!=8){//not Array
                        let dt = opcode.datatype;
                        if (dt<0) dt=value.datatype;
                        resvalue.datatype = dt;
                        resvalue.setValue(value.value)
                }else{
                    const ostorevalue = this.scope.get(name);
                    //console.log("ostorevalue",ostorevalue)
                    if (ostorevalue==null) {
                       
                        if (vvalue instanceof Array) {
                           resvalue.datatype = opcode.datatype;
                           resvalue.setValue(vvalue);
                        }
                    }else if(ostorevalue instanceof Value) {
                        const storevalue = ostorevalue.value;
                        //console.log("ostorevalue not null ostorevalue",ostorevalue)
                        //console.log("iostorevalue not null ostorevalue vvalue", vvalue)
                        if (vvalue instanceof Array && storevalue instanceof Array) {
                            //const values = (ArrayList<Value>)vvalue.getValue();				
                            //const storevalues = storevalue.;
                            
                            for (let index=0; index<storevalue.length;index++) {								
								if (vvalue.length>index) {
									const v:Value = vvalue[index];
									const storev:Value = storevalue[index];
                                    //console.log('v, storev', v, storev)
                                    if(storev.datatype == v.datatype){
                                        storev.datatype = v.datatype
                                        storev.setValue(v.value)
                                    }else if(storev.datatype !=7 && v.datatype==7){
                                        storev.value = '0'
                                    }else{
                                        storev.setValue(v.value)
                                    }
								}						
                            }
                            resvalue.datatype = opcode.datatype;
                            resvalue.setValue(storevalue);
                        }
                    }
                }
        }
        else resvalue = value;
        //console.log('evalStore resvalue', resvalue)
        this.scope.set(name,resvalue)
    }

    evalLoadFast(opcode:opcode){
        const value  = this.scope.get(opcode.value)
        if (value==null)
            this.stack.push(opcode.value)
        else
            this.stack.push(value)
    }
    evalMathOp(opcode:opcode){
        //console.log("evalMathOp")
        const leftobject = this.stack.pop();
        const rightobject = this.stack.pop();
      //  console.log('evalMathOp leftobject', leftobject, rightobject )
        if (leftobject instanceof Value && rightobject instanceof Value){
            let datatype = rightobject.datatype>leftobject.datatype?rightobject.datatype:leftobject.datatype
            if (leftobject.datatype==7 || rightobject.datatype==7) datatype = 7
            const sumres = interpretMathOp.mathop(leftobject, rightobject, opcode,datatype);
            //console.log("SUMRES=",sumres)
            this.stack.push(sumres)
        }
    }

    evalCall(opcode:opcode){
        const func = this.stack.pop();
        const numberofargs = Number(opcode.value)
        const args = []
        for (let i=0;i<numberofargs;i++){
                args.push(this.stack.pop())
        }
        interpretStandartCommands.setScript(this.script)
        interpretStandartCommands.setScreen(this.screen)
        const res:Value = interpretStandartCommands.call(func,args)
        //console.log("CALL VALUE=", res)
        this.stack.push(res)
    }

    evalPopTop(opcode:opcode){
        //console.log('evalPopTop')
        this.stack.pop()
    }

    evalJumpIfFalse(opcode:opcode){
        //console.log('evalJumpIfFalse')
        const obvalue:Value = this.stack.pop();
        //console.log('obvalue evalJumpIfFalse', obvalue, typeof(obvalue.value))
        
        if(obvalue instanceof Value && (obvalue.value == '0' || obvalue.value == 0||
            (typeof(obvalue.value) == 'string'? obvalue.value.toLowerCase()=='false' : obvalue.value == false))
            && obvalue.datatype == 0){
             //   console.log('this.position+=Number(opcode.value)-1');
                this.position+=Number(opcode.value)-1;
        } 
    }

    evalJumpIfTrue(opcode:opcode){
        //console.log('evalJumpIfTrue')
        const obvalue:Value = this.stack.pop();
        //console.log('obvalue evalJumpIfTrue', obvalue)
        if(obvalue instanceof Value && (obvalue.value == '1' || obvalue.value.toLowerCase()=='true' || obvalue.value==true)
              && obvalue.datatype == 0){
                this.position=Number(opcode.value)-1;
        }
    } 
            
    evalJump(opcode:opcode){
        this.position+=Number(opcode.value)-1;
    }

    evalInitArray(opcode:opcode){
        const numberofargs = Number(opcode.value);
        const valueresult:Value = new Value()
        const result = []

        for (let i = 0; i < numberofargs; i++) {
            const arvalue:Value = this.stack.pop();
            if(arvalue !=null) result.push(arvalue);
        }

        valueresult.datatype = 8;
        valueresult.value = result;
        this.stack.push(valueresult)
        //console.log('valueresult', valueresult)
    }

    evalLoadMember(opcode:opcode){
        const okeyvalue: Value = this.stack.pop();
        //console.log('opcode.value', opcode.value) // ints
		if (okeyvalue instanceof Value) {
            //console.log('okeyvalue', okeyvalue)
			const keyvalue = okeyvalue.value;
			const key = Number(keyvalue); //index number
			const oarray: Value = this.scope.get(opcode.value);
            //console.log('oarray', oarray)
           

			if (oarray instanceof Value) {
				if (oarray.datatype==8) {
					if (oarray.value instanceof Array) {
						const array:Array<Value> =oarray.value
                        //console.log('array', array, typeof(array))
						const ovalue: Value = array[key];
						if (ovalue instanceof Value) {
							this.stack.push(ovalue);
                            //console.log('ovalue', ovalue)
						}
                    }
                }
            }
        }
    }

    evalStoreMember(opcode:opcode){
        //console.log('EVALSTOREMEMBER OPCODE', opcode)
        const okeyvalue = this.stack.pop();
        if (okeyvalue instanceof Value) {
			const key = Number(okeyvalue.value);
			const oarray = this.scope.get(opcode.value);
         //    console.log('evalStoreMember oarray', oarray)
            if (oarray instanceof Value) {
				if (oarray.datatype==8) {
                    if (oarray.value instanceof Array) {
						const array =oarray.value
                        const ovalue = array[key];
                        if (ovalue instanceof Value) {
							const membervalue = ovalue;
							const value = this.stack.pop();
                            if (value instanceof Value) {
								const vvalue = value.value;
                                membervalue.datatype = ovalue.datatype
								membervalue.setValue(vvalue);
                               // console.log('membervalue', membervalue)
                            }
                        }
                    }
                }
            }
        }
    }

    evalLoadTag(opcode:opcode){
        console.log("evalLoadTag opcode", opcode)
        const tagname = opcode.path
        const tag:tag = tagsModele.getTag(tagname)
        const value:Value = new Value();
        console.log("evalLoadTag tag", tag)
        console.log('evalLoadTag script', this.script)
       
        if(this.script.tagnames.indexOf(tagname)=== -1){
            this.script.tagnames.push(tagname)
        }
        
       // console.log('evalLoadTag tag.tagvalue', tag.tagvalue)
        if(tag !=null){
            if(tag.datatype==8){
                const values:Array<Value> = []
                const array = tag.tagvalue.replace('[','').replace(']', '').split(',').map(Number)
                //console.log('array', array)
                array.forEach((el)=>{
                    const v:Value = new Value 
                    v.setValue(el)
                    v.datatype =5
                    values.push(v) 
                })
                value.setValue(values)
                value.datatype = 8
            }else if(tag.datatype==0){
                //console.log("evalLoadTag tag.tagvalue", tag.tagvalue)
                if(tag.tagvalue.toLowerCase()=='false'|| tag.tagvalue.toLowerCase()=='0'
                    || tag.tagvalue.toLowerCase()=='true'|| tag.tagvalue.toLowerCase()=='1'){
                       // console.log(" evalLoadTag value.datatype == 0 $$ boolean")
                        value.setValue(tag.tagvalue);
                        value.datatype = tag.datatype;
                }else return 
            }else{
                value.datatype = tag.datatype;
                value.setValue(tag.tagvalue);
            }
            //console.log('evalLoadConst value', value)
            this.stack.push(value)
        }  
    }

    evalStoreTag(opcode:opcode){
        //console.log("evalStoreTag opcode", opcode)
        const tagname = opcode.path
        const tag:tag = tagsModele.getTag(tagname)
        if(this.script.tagnames.indexOf(tagname)=== -1){
            this.script.tagnames.push(tagname)
        }
        const value = this.stack.pop();
        //console.log('evalStoreTag value', value)
        if(tag !=null){
            if (value instanceof Value) {
                const vvalue = value.value;
                const resvalue:Value = new Value();
                if (value.datatype == 8){
                    resvalue.setValue(vvalue);
                    resvalue.datatype = 8
                    const tagvalues = [] 
                    vvalue.forEach(element => {
                        tagvalues.push(Number(element.value))
                    });
                    //console.log('evalStoreTag tagvalues', JSON.stringify(tagvalues))
                    tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:JSON.stringify(tagvalues),datetime:0 })
                }else if(value.datatype == 0){
                    if(tag.tagvalue.toLowerCase()=='false'|| tag.tagvalue.toLowerCase()=='0'
                    || tag.tagvalue.toLowerCase()=='true'|| tag.tagvalue.toLowerCase()=='1'){
                        //console.log(" evalStoreTag value.datatype == 0 $$ boolean")
                        resvalue.setValue(vvalue);
                        resvalue.datatype = value.datatype
                        tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:resvalue.value,datetime:0 })
                        tag.tagvalue = resvalue.value;
                    }else{
                        //console.log("NOT BOOLEAN")
                    }
                }else{
                    resvalue.setValue(vvalue);
                    resvalue.datatype = value.datatype
                    tagsModele.writeTagAction({token:"",name: tag.path, tagvalue:resvalue.value,datetime:0 })
                    tag.tagvalue = resvalue.value;
                }
            }  
        }
    }

    evalLoadObject(opcode:opcode){
        console.log("evalLoadObject", this.object, opcode.value)
        const f = this.stack.pop();
		let field = null;
		if (f!=null && f instanceof Value) {
			field = f.value.toString();
		}
        const obj:ObjectView = opcode.value=='this'?this.object:this.screen==null?null:this.screen.getObjectByName(opcode.value)
        if (obj!=null){
            console.log("object=",obj)
            console.log("field=",field)
		const value = obj.getField(field);
        console.log("value=",value)
		this.stack.push(value);
        }
    }

    evalStoreObject(opcode:opcode){
        console.log("evalStoreObject", this.object)
        const obj:ObjectView = opcode.value=='this'?this.object:this.screen==null?null:this.screen.getObjectByName(opcode.value)
    
        const f = this.stack.pop();

        let field = null;
		if (f!=null && f instanceof Value) {
			field = f.value.toString();
		}
       
		const value = this.stack.pop();
		if (value!=null && value instanceof Value && obj!=null) {
    
			obj.setField(field, value);
		}
    }


}
import { ObjectView } from "./object";
import {ColorUtils} from '@/model/project/utils/colorutils'
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import { CounterControlProperty } from "./properties/countercontrolproperty";
import { TextColorProperty } from "./properties/textcolorproperty";
import { Value } from "../value";

export class Counter extends ObjectView{
    color:string;
    textcolor:string;
    type3d:number;

    public drawObject():void{
		super.initObject();

       
                let value = 0
                let minimum = 0
                let maximum = 240
                let decimalpos = 0
                let delta = 1
                
                const counterprop = this.properties['countercontrol']
                let counterproperty;
                if (counterprop != null){
                    counterproperty =Object.assign(new CounterControlProperty(), counterprop);
                    if(counterproperty !=null){
                    minimum = counterproperty.minimumvalue
                    maximum = counterproperty.maximumvalue
                    value = counterproperty.getCurrentValue(this.tagnames)
                    decimalpos = counterproperty.decimalpos
                    delta = counterproperty.delta
                    }
                 }

		let color:string = this.color;
		const lineprop = this.properties['color'];
		if (lineprop!=null){	
			const linecolorproperty:ColorProperty =Object.assign(new ColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
		}	
		const color2 = ColorUtils.convertformat(color);


    let textcolor = this.textcolor;
            const textcolorprop = this.properties['textcolor'];
            if (textcolorprop!=null){	
                const textcolorproperty:TextColorProperty =Object.assign(new TextColorProperty(), textcolorprop);
                if (textcolorproperty!=null) 
                    textcolor = textcolorproperty.getColor(this.tagnames, this.textcolor)
            }
    const textcolor2 = ColorUtils.convertformat(textcolor)
        
        const rectout = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            cornerRadius: this.width/2
          });
          if (this.type3d==0){
            rectout.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectout.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectout.fillLinearGradientColorStops([0, ColorUtils.darkColor(color2,0.75) , 
            0.5, 'white',
            1, ColorUtils.darkColor(color2,0.75)])
          }
          else{
            rectout.fill(color2)
          }
          this.node.add(rectout) 
           
        const rectinner = new Konva.Rect({
            x: this.width/4,
            y: 0,
            width: this.width*2/4,
            height: this.height,
          });
          if (this.type3d==0){
            rectinner.fillLinearGradientStartPoint({ x: 0, y: 0 });
            rectinner.fillLinearGradientEndPoint({ x: 0, y: this.height });
            rectinner.fillLinearGradientColorStops([0, 'white' , 
            1, ColorUtils.darkColor(color2,0.75)])
          }
          else{
            rectinner.fill(color2)
          }
          this.node.add(rectinner) 

    const rectsmall= new Konva.Rect({
        x: this.width*11/40,
        y: this.height/20,
        width: this.width*18/40,
        height: this.height*14/20,
      });
      if (this.type3d==0){
        rectsmall.fillLinearGradientStartPoint({ x: 0, y: this.height/20});
        rectsmall.fillLinearGradientEndPoint({ x: 0, y: this.height/4 });
        rectsmall.fillLinearGradientColorStops([0, 'white' , 
        1, ColorUtils.darkColor(color2, 0.1)])
      }
      else{
        rectsmall.fill(ColorUtils.darkColor(color2,0.1))
      }
      this.node.add(rectsmall) 

        const TextPlus = new Konva.Text({
            x: this.width*3/4+this.width/30,
            y: this.height/10, 
            //verticalAlign:"bottom",
            width: this.width/4,
            height: this.height,
            text: "+",
            fontSize: this.height,
            fontFamily: "Roboto",
            fontStyle: "normal",
            //align:'center',
            fill: ColorUtils.darkColor(color2,0.1),
          });
          this.node.add(TextPlus)

        const TextMinus = new Konva.Text({
            x: this.width/10,
            y: this.height/14, 
            //verticalAlign:"bottom",
            width: this.width/4,
            height: this.height,
            text: "-",
            fontSize: this.height,
            fontFamily: "Roboto",
            fontStyle: "normal",
            //align:'center',
            fill: ColorUtils.darkColor(color2,0.1),
          });
          this.node.add(TextMinus)

        const TextSet = new Konva.Text({
            x: this.width/4,
            y: this.height*16/20, 
            verticalAlign:"middle",
            width: this.width/2,
            height: this.height/5,
            text: "SET",
            fontSize: this.height/5,
            fontFamily: "Roboto",
            fontStyle: "normal",
            align:'center',
            fill: ColorUtils.darkColor(color2,0.1),
          });
          this.node.add(TextSet)

        /*const TextValue = new Konva.Text({
            x: this.width*11/40,
            y: this.height*15/20-this.height*13/20, 
            verticalAlign:"middle",
            width: this.width*18/40,
            height: this.height*13/20,
            text: value.toFixed(decimalpos),
            fontSize: this.height*13/20,
            fontFamily: "DS-Digital",
            fontStyle: "normal",
            align:'right',
            fill: textcolor2,
          });*/
          let TextValue = this.drawTextvalue( textcolor2, value, decimalpos)
          const node1:Konva.Group = new Konva.Group();
          node1.add(TextValue);
          this.node.add(node1)
          this.node.on('mouseup touchend', ()=> {
            const mousePos = this.node.getRelativePointerPosition()
            if(mousePos.x<this.width/4){value= value-delta<minimum? minimum : value-delta}
            else if(mousePos.x>this.width*3/4){value= value+delta>maximum? maximum: value+delta}
            else if(mousePos.x>this.width/4 && mousePos.x<this.width*3/4){counterproperty.setValue(value.toString())}
            node1.removeChildren();
            TextValue = this.drawTextvalue( textcolor2, value, decimalpos)
            node1.add(TextValue)
        });

  
          //if (usefunctions)
          //this.setFunctions(delta, textcolor2,value, decimalpos);
    }

    public setField(field:string, value:Value){
      super.setField(field, value)
      switch(field){
          case "color": {this[field] = value.value;this.rotatednode=null;break;}
          case "textcolor": {this[field] = value.value;this.rotatednode=null;break;}
          case "type3d": {this[field] = Math.round(value.value);this.rotatednode=null;break;}
      }
      //this.drawObject();
    }

    public getField(field:string):Value{
      const value = super.getField(field)
      switch(field){
          case "color": {value.datatype=7; value.value = this[field];break}
          case "textcolor": {value.datatype=7; value.value = this[field];break}
          case "type3d": {value.datatype=1; value.value = this[field];break}
      }
      return value;
    }
    
    private drawTextvalue( textcolor2:string, value:number, decimalpos: number):Konva.Text{ 
      const TextValue = new Konva.Text({
        x: this.width*11/40,
        y: this.height*15/20-this.height*13/20, 
        verticalAlign:"middle",
        width: this.width*18/40,
        height: this.height*13/20,
        text: value.toFixed(decimalpos),
        fontSize: this.height*13/20,
        fontFamily: "DS-Digital",
        fontStyle: "normal",
        align:'right',
        fill: textcolor2,
      });
      return TextValue
    }   
}
import { SVGObject } from "../svgobject";

export class SVGSensor8 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 75.002 112.5" enable-background="new 0 0 75.002 112.5" xml:space="preserve">
        <g id="Group_Transmitter_Support">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="61.459" y1="116.583" x2="13.4296" y2="68.5536">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" stroke="#4C4C4C" stroke-width="0.25" d="M4.618,107.771v-4.729h4.729V72.635l11.712-2.363H53.83   l11.711,2.363v30.406h4.729v4.729h-6.981v4.729h-7.095v-4.729h-37.5v4.729h-6.981v-4.729H4.618V107.771z" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M53.83,71.396v5.854" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M63.289,77.252v25.789H11.713" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.695,98.424v-2.365h37.5" />
            <path fill="none" stroke="#4C4C4C" stroke-width="0.25" d="M18.695,93.693v-2.364h37.5" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M56.194,96.059v2.365h-37.5" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M56.194,91.329v2.364h-37.5" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M53.83,77.252h9.459" />
            <path fill="none" stroke="#FFFFFF" stroke-width="0.25" d="M11.713,103.041V77.252h9.347v-5.855h32.77" />
        </g>
        <g id="Group_Holder">
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="21.0601" y1="55.5742" x2="53.8301" y2="55.5742">
                <stop offset="0.01" style="stop-color:#595959" />
                <stop offset="0.51" style="stop-color:#E0E0E0" />
                <stop offset="1" style="stop-color:#595959" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" stroke="#4C4C4C" stroke-width="0.25" d="M21.06,45.608h32.77v19.933H21.06V45.608" />
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="23.4248" y1="43.8623" x2="51.4648" y2="43.8623">
                <stop offset="0.01" style="stop-color:#262626" />
                <stop offset="0.51" style="stop-color:#B3B3B3" />
                <stop offset="1" style="stop-color:#262626" />
            </linearGradient>
            <path fill="url(#SVGID_3_)" d="M23.425,45.608h28.04v-3.491h-28.04V45.608z" />
        </g>
        <g id="Group_Blue_Layer">
            <path fill="#999EFF" stroke="#4C4C4C" stroke-width="0.25" d="M21.06,65.541h32.77v4.729H21.06V65.541z" />
        </g>
        <g id="Group_Port">
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="37.5015" y1="43.6948" x2="37.5015" y2="3.041">
                <stop offset="0" style="stop-color:#7F7FFF" />
                <stop offset="0.51" style="stop-color:#E5FCFF" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_4_)" stroke="#4C4C4C" stroke-width="0.25" d="M0.001,4.617h18.693V3.041h37.5v1.577h18.807v37.5H56.194   v1.577h-37.5v-1.577H0.001V4.617" />
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="56.1943" y1="42.1172" x2="56.1943" y2="4.6172">
                <stop offset="0" style="stop-color:#7F7FFF" />
                <stop offset="0.51" style="stop-color:#E5FCFF" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_5_)" stroke="#4C4C4C" stroke-width="0.25" d="M56.194,42.117v-37.5" />
            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="18.6948" y1="42.1172" x2="18.6948" y2="4.6172">
                <stop offset="0" style="stop-color:#7F7FFF" />
                <stop offset="0.51" style="stop-color:#E5FCFF" />
                <stop offset="1" style="stop-color:#7F7FFF" />
            </linearGradient>
            <path fill="url(#SVGID_6_)" stroke="#4C4C4C" stroke-width="0.25" d="M18.695,42.117v-37.5" />
        </g>
        <g id="Group_Trasmitting_Adjuster">
            <radialGradient id="SVGID_7_" cx="37.7266" cy="9.3477" r="9.3474" gradientUnits="userSpaceOnUse">
                <stop offset="0" style="stop-color:#292929" />
                <stop offset="0.33" style="stop-color:#5E5F65" />
                <stop offset="0.34" style="stop-color:#6B6D89" />
                <stop offset="0.35" style="stop-color:#777AA8" />
                <stop offset="0.36" style="stop-color:#8184C4" />
                <stop offset="0.38" style="stop-color:#898DDA" />
                <stop offset="0.39" style="stop-color:#8F94EB" />
                <stop offset="0.42" style="stop-color:#9499F6" />
                <stop offset="0.44" style="stop-color:#969BFD" />
                <stop offset="0.53" style="stop-color:#979CFF" />
                <stop offset="0.7" style="stop-color:#E5FCFF" />
                <stop offset="0.9" style="stop-color:#7F7FFF" />
                <stop offset="0.93" style="stop-color:#292929" />
            </radialGradient>
            <path fill="url(#SVGID_7_)" d="M31.082,15.991l-2.703-6.645l2.703-6.757L37.727,0l6.644,2.59l2.703,6.757l-2.703,6.645   l-6.644,2.703L31.082,15.991z" />
        </g>
        </svg>`
		super.fillGradients();
		this.fillGradient(this.gradients,"SVGID_1_",[0.01, 0.6927083333333334, 0.0, 0.51, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_2_",[0.01, 0.6927083333333334, 0.0, 0.51, 0.25, 191.0, 1.0, 0.6927083333333334, 0.0]);
		this.fillGradient(this.gradients,"SVGID_3_",[0.01, 0.2916666666666667, 0.0, 0.51, 0.6041666666666666, 101.0, 1.0, 0.2916666666666667, 0.0]);
		this.fillGradient(this.gradients,"SVGID_4_",[0.0, 0.6822916666666666, 81.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_5_",[0.0, 0.6822916666666666, 81.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_6_",[0.0, 0.6822916666666666, 81.0, 0.51, 0.08333333333333333, 233.0, 1.0, 0.6822916666666666, 81.0]);
		this.fillGradient(this.gradients,"SVGID_7_",[0.0, 0.3177083333333333, 0.0, 0.33, 0.75, 0.0, 0.34, 0.9114583333333334, 0.0, 0.35, 0.9375, 15.0, 0.36, 0.8125, 47.0, 0.38, 0.7135416666666666, 73.0, 0.39, 0.6354166666666666, 93.0, 0.42, 0.578125, 107.0, 0.44, 0.546875, 115.0, 0.53, 0.5364583333333334, 117.0, 0.7, 0.08333333333333333, 233.0, 0.9, 0.6822916666666666, 81.0, 0.93, 0.3177083333333333, 0.0]);

        super.drawObject();
    }
}
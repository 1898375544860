import {ColorUtils} from '@/model/project/utils/colorutils'
import { FillColorProperty } from "./properties/fillcolorproperty";
import { ValueProperty } from "./properties/valueproperty";
import { ColorProperty } from "./properties/colorproperty";
import Konva from "konva";
import { TwoStateObject } from "./twostateobject";
import { Value } from '../value';


export class ValvePosition extends TwoStateObject{
  indicatorcolor:string

  public drawObject():void{
		super.initObject();
    if(this.type3d != 0) this.type3d = 1 

	
		let fillcolor = '0xffffff00'; 
    fillcolor = this.fillcolor;
    const fillprop = this.properties==null? null : this.properties['fillcolor'];
        if (fillprop!=null){	
            const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
            if (fillcolorproperty!=null) 
              fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
    }	
    const fillcolor2 = ColorUtils.convertformat(fillcolor)

    let color = this.indicatorcolor; 
    
    const colorprop = this.properties==null? null : this.properties['color'];
        if (colorprop!=null){	
            const colorproperty:ColorProperty =Object.assign(new ColorProperty(), colorprop);
            if (colorproperty!=null) 
                    color = colorproperty.getColor(this.tagnames, this.fillcolor)     
    }	
    const color2 = ColorUtils.convertformat(color)    
       
    let value = 0   
    const valueprop = this.properties==null? null : this.properties['value'];
    if (valueprop!=null){	
        const valueproperty:ValueProperty =Object.assign(new ValueProperty(), valueprop);
        if (valueproperty!=null) 
          value = valueproperty.getValue(this.tagnames)     
    }	

    const pipe = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.width/10,
      height: this.height,
      stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
      strokeWidth:1
    })
    const pipe2 = new Konva.Rect({
      x: this.width*9/10,
      y: 0,
      width: this.width/10,
      height: this.height,
      stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
      strokeWidth:1
    })
    if(this.type3d==0){
      if(this.angle==0){
          pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
          0.15, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.25)])
          pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
              0.15, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.25)])
      }else if(this.angle==180){
          pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
          0.85, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
          pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
          0.85, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }else{
          pipe.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
          0.5, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
          pipe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe2.fillLinearGradientEndPoint({ x: 0, y: this.height});
          pipe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
          0.5, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }
    }else{
      pipe.fill(fillcolor2)
      pipe2.fill(fillcolor2)
    }
    this.node.add(pipe, pipe2)
            
    const stripe = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.width/50,
      height: this.height,
      stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
      strokeWidth:1  
    })
    const stripe2 = new Konva.Rect({
        x: this.width*4/50,
        y: 0,
        width: this.width/50,
        height: this.height,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1 
    })
    const stripe3 = new Konva.Rect({
        x: this.width*9/10,
        y:0,
        width: this.width/50,
        height: this.height,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1
    })
    const stripe4 = new Konva.Rect({
        x: this.width*49/50,
        y: 0,
        width: this.width/50,
        height: this.height,
        stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
        strokeWidth:1
    })
    if(this.type3d==0){
        if(this.angle==0){
            stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe.fillLinearGradientColorStops([0, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe2.fillLinearGradientColorStops([0, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe3.fillLinearGradientColorStops([0, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe4.fillLinearGradientColorStops([0, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }else if(this.angle==180){
            stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            1, 'white'])
            stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height*3/5});
            stripe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            1, 'white'])
            stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            1, 'white'])
            stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            1, 'white'])
        }else{
            stripe.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
            0.5, 'white',
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe2.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe2.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe3.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
            stripe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
            stripe4.fillLinearGradientEndPoint({ x: 0, y: this.height});
            stripe4.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
                0.5, 'white',
                1, ColorUtils.darkColor(fillcolor2, 0.5)])
        }
      }else{
        stripe.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        stripe2.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        stripe3.fill(ColorUtils.darkColor(fillcolor2, 0.5))
        stripe4.fill(ColorUtils.darkColor(fillcolor2, 0.5))
      }
      this.node.add(stripe, stripe2, stripe3, stripe4)

          
    const pipe3 = new Konva.Rect({
      x: this.width/10,
      y: this.height*2/10,
      width: this.width*8/10,
      height: this.height*6/10,
      stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
      strokeWidth:1
    })
    if(this.type3d==0){
      if(this.angle==0){
          pipe3.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*6/10});
          pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
          0.15, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.25)])
      }else if(this.angle==180){
          pipe3.fillLinearGradientStartPoint({ x: 0, y: 0});
          pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*6/10});
          pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.25),
          0.85, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }else{
          pipe3.fillLinearGradientStartPoint({ x: 0, y: 0});
          pipe3.fillLinearGradientEndPoint({ x: 0, y: this.height*6/10});
          pipe3.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.5),
          0.5, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }
    }else{
      pipe3.fill(fillcolor2)
    }
    this.node.add(pipe3)
          
    const pipe4 = new Konva.Rect({
      x: this.width*25/100,
      y: this.height/20,
      width: this.width*5/10,
      height: this.height*9/10,
      cornerRadius: this.width/20,
      stroke: this.type3d==0? null : ColorUtils.darkColor(fillcolor2, 0.1),
      strokeWidth:1
    })
    if(this.type3d==0){
          if(this.angle==0){
          pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe4.fillLinearGradientEndPoint({ x: 0, y: this.height*9/10});
          pipe4.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else if(this.angle==90){
          pipe4.fillLinearGradientStartPoint({ x: 0, y: 0 });
          pipe4.fillLinearGradientEndPoint({ x: this.width*5/10, y: 0});
          pipe4.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else if(this.angle==180){
          pipe4.fillLinearGradientStartPoint({ x: 0, y: this.height*9/10 });
          pipe4.fillLinearGradientEndPoint({ x: 0, y: 0});
          pipe4.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }else{
          pipe4.fillLinearGradientStartPoint({ x: this.width*5/10, y: 0 });
          pipe4.fillLinearGradientEndPoint({ x: 0, y: 0});
          pipe4.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
          }
    }else{
      pipe4.fill(fillcolor2)
    }
    this.node.add(pipe4)
    
    const rad = this.width*5/100>this.height*5/50 ? this.height*5/100 : this.width*5/200
    const r1 = new Konva.Circle({
      x: this.width*32/100,
      y: this.height*3/20,
      radius: rad,
      fill: ColorUtils.darkColor(fillcolor2, 0.5),
    });
    const r2 = new Konva.Circle({
      x: this.width*68/100,
      y: this.height*3/20,
      radius: rad,
      fill: ColorUtils.darkColor(fillcolor2, 0.5),
    });
    const r3 = new Konva.Circle({
      x: this.width*32/100,
      y: this.height*17/20,
      radius: rad,
      fill: ColorUtils.darkColor(fillcolor2, 0.85),
    });
    const r4 = new Konva.Circle({
        x: this.width*68/100,
        y: this.height*17/20,
        radius: rad,
        fill: ColorUtils.darkColor(fillcolor2, 0.85),
    });
    this.node.add(r1, r2, r3, r4)
          
    const radius = this.width*4/10 >this.height*15/20 ? this.height*15/40 : this.width*4/20
    const indoval = new Konva.Circle({
        x: this.width*5/10,
        y: this.height*5/10,
        radius: radius,
    });
    if(this.type3d==0){
      if(this.angle==0){
          indoval.fillLinearGradientStartPoint({ x: 0, y:-radius });
          indoval.fillLinearGradientEndPoint({ x: 0, y: radius});
          indoval.fillLinearGradientColorStops([0, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.1)])
      }else if(this.angle==90){
          indoval.fillLinearGradientStartPoint({ x: -radius, y: 0 });
          indoval.fillLinearGradientEndPoint({ x: radius, y: 0});
          indoval.fillLinearGradientColorStops([0, 'white',
              1, ColorUtils.darkColor(fillcolor2, 0.1)])
      }else if(this.angle==180){
          indoval.fillLinearGradientStartPoint({ x: 0, y: radius });
          indoval.fillLinearGradientEndPoint({ x: 0, y: -radius});
          indoval.fillLinearGradientColorStops([0, 'white',
              1, 'black'])
      }else{
          indoval.fillLinearGradientStartPoint({ x: radius, y: 0 });
          indoval.fillLinearGradientEndPoint({ x: -radius, y: 0});
          indoval.fillLinearGradientColorStops([0, 'white',
          1, ColorUtils.darkColor(fillcolor2, 0.1)])
      }
    }else{
      indoval.fill(fillcolor2)
    }
    this.node.add(indoval)
           
    const indoval2 = new Konva.Circle({
      x: this.width*5/10,
      y: this.height*5/10,
      radius: radius*19/20,
    });
    if(this.type3d==0){
      if(this.angle==0){
          indoval2.fillLinearGradientStartPoint({ x: 0, y: -radius*19/20});
          indoval2.fillLinearGradientEndPoint({ x: 0, y: radius*19/20});
          indoval2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1),
            1, 'white'])
      }else if(this.angle==90){
          indoval2.fillLinearGradientStartPoint({ x: -radius*19/20, y: 0 });
          indoval2.fillLinearGradientEndPoint({ x: radius*19/20, y: 0});
          indoval2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1),
              1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }else if(this.angle==180){
          indoval2.fillLinearGradientStartPoint({ x: 0, y: radius*19/20 });
          indoval2.fillLinearGradientEndPoint({ x: 0, y: -radius*19/20});
          indoval2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1),
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }else{
          indoval2.fillLinearGradientStartPoint({ x: radius*19/20, y: 0 });
          indoval2.fillLinearGradientEndPoint({ x: -radius*19/20, y: 0});
          indoval2.fillLinearGradientColorStops([0, ColorUtils.darkColor(fillcolor2, 0.1),
            1, ColorUtils.darkColor(fillcolor2, 0.5)])
      }
    }else{
      indoval2.fill(fillcolor2) 
    }
    this.node.add(indoval2)
             
    const indoval3 = new Konva.Circle({
        x: this.width*5/10,
        y: this.height*5/10,
        radius: radius*18/20,
    });
    if(this.type3d==0){
          if(this.angle==0){
            indoval3.fillLinearGradientStartPoint({ x: 0, y: -radius*19/20});
            indoval3.fillLinearGradientEndPoint({ x: 0, y: radius*19/20});
            indoval3.fillLinearGradientColorStops([0, fillcolor2,
                1,'white'])
          }else if(this.angle==90){
            indoval3.fillLinearGradientStartPoint({ x: -radius*18/20, y: 0});
            indoval3.fillLinearGradientEndPoint({ x: radius*18/20, y: 0});
            indoval3.fillLinearGradientColorStops([0, fillcolor2,
                1,'white'])
          }else if(this.angle==180){
            indoval3.fillLinearGradientStartPoint({ x: 0, y: radius*18/20});
            indoval3.fillLinearGradientEndPoint({ x: 0, y: -radius*18/20});
            indoval3.fillLinearGradientColorStops([0, fillcolor2,
                1,'white'])
          }else{
            indoval3.fillLinearGradientStartPoint({ x: radius*18/20, y: 0});
            indoval3.fillLinearGradientEndPoint({ x: -radius*18/20, y: 0});
            indoval3.fillLinearGradientColorStops([0, fillcolor2,
                1,'white'])
          }
    }else{
          indoval3.fill('white') 
    }
    this.node.add(indoval3)

    let arrowWidth = this.width/10
    let arrowHeight = radius*27/20
    const arrowHeight2 = radius*18/20
    if (arrowWidth<1)  arrowWidth = 1;
    if (arrowHeight<1) arrowHeight = 1;

    const arrow = new Konva.Line({
        points: [0, arrowHeight,
        arrowWidth/2, 0,
        arrowWidth, arrowHeight,
        0, arrowHeight],
        fill: color2,
        closed: true,
    }); 
    this.node.add(arrow)

    const anglearr = value-90;
		const radians = anglearr*Math.PI/180;

    const posx = this.width/2 -arrowWidth/2;
    const posy = this.height/2 -arrowHeight/2;
    const x =arrowWidth/2+posx-arrowWidth/2* Math.cos(radians) +(arrowHeight2)* Math.sin(radians);
    const y = posy+arrowHeight/2 -(arrowWidth/2)* Math.sin(radians) -(arrowHeight2)* Math.cos(radians);
    //const x= posx
    //const y=posy
    arrow.position({x:x,y:y})
    arrow.rotation(anglearr)

    const r5 = new Konva.Circle({
        x: this.width/2,
        y: this.height/2,
        radius: rad/2,
        fill: 'white',
    });
    this.node.add(r5)         
  } 

  public setField(field:string, value:Value){
    super.setField(field, value)
    switch(field){
        case "indicatorcolor": {this[field] = value.value;this.rotatednode=null;break;}
    }
    //this.drawObject();
  }

  public getField(field:string):Value{
    const value = super.getField(field)
    switch(field){
        case "indicatorcolor": {value.datatype=7; value.value = this[field];break}
    }
    return value;
  }
}
import { SVGObject } from "../svgobject";

export class SVGButton5 extends SVGObject{
    public drawObject():void{
		super.initObject();
        this.svg =`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"[]><svg version="1.1" id="_x23__x23_Blue_x5F_Pilot_x5F_Light2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 112.5 112.5" enable-background="new 0 0 112.5 112.5" xml:space="preserve">
        <g id="Group_Blue_Pilot_Light2">
        <path fill="#007FFF" stroke="#000000" stroke-width="0.5" d="M112.159,56.249c0-31.027-25.031-56.192-55.909-56.192  c-30.876,0-55.909,25.166-55.909,56.192c0,31.03,25.033,56.194,55.909,56.194C87.128,112.443,112.159,87.279,112.159,56.249z" />
        <radialGradient id="SVGID_1_" cx="47.9697" cy="39.9521" r="14.0757" gradientTransform="matrix(2.4946 0 0 2.2816 -80.674 -47.8871)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#A2D1FF" />
            <stop offset="1" style="stop-color:#007FFF" />
        </radialGradient>
        <ellipse fill="url(#SVGID_1_)" cx="38.993" cy="43.266" rx="35.114" ry="32.115" />
        </g>
        </svg>`
		super.fillGradients();
        this.fillGradient(this.gradients,"SVGID_1_",[0.0, 0.375, 159.0, 1.0, 0.9895833333333334, 0.0]);
		this.fillGradient(this.fills,"Group_Blue_Pilot_Light2",[0.0, 0.9895833333333334, 0.0]);

        super.drawObject();
    }
}
import {ColorUtils} from '@/model/project/utils/colorutils'
import { LineColorProperty } from "./properties/linecolorproperty";
import { FillColorProperty } from "./properties/fillcolorproperty";
import Konva from "konva";
import { Sector } from './sector';

export class Sector3D extends Sector{

    public drawObject():void{
		super.initObject();

		let color:string = this.color;
		const lineprop = this.properties['linecolor'];
		if (lineprop!=null){	
			const linecolorproperty:LineColorProperty =Object.assign(new LineColorProperty(), lineprop);
			if (linecolorproperty!=null) 
					color = linecolorproperty.getColor(this.tagnames, this.color)
			
		}	
		let fillcolor = '0xffffff00';
        if (this.fill){
                
            fillcolor = this.fillcolor;
            const fillprop = this.properties['fillcolor'];
            if (fillprop!=null){	
                const fillcolorproperty:FillColorProperty =Object.assign(new FillColorProperty(), fillprop);
                if (fillcolorproperty!=null) 
                        fillcolor = fillcolorproperty.getColor(this.tagnames, this.fillcolor)     
            }	
            
        }    
        if(this.width>this.height){
            const arc = new Konva.Arc({
            x: this.width / 2,
            y: this.height / 2,
            innerRadius: 0,
            outerRadius: this.height/2-this.linewidth/2,
            angle: -this.rotationangle,
            rotation: -this.startangle,
           clockwise:true,
            //fill: ColorUtils.convertformat(fillcolor),
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });
          const ang = Math.PI/180*this.startangle
          arc.fillLinearGradientStartPoint({ x: -this.height/2*Math.cos(ang), y: -this.height/2*Math.sin(ang) });
          arc.fillLinearGradientEndPoint({ x: this.height/2*Math.cos(ang), y: this.height/2*Math.sin(ang) });
          arc.fillLinearGradientColorStops([0, ColorUtils.convertformat(fillcolor), 0.5, 'white', 1, ColorUtils.convertformat(fillcolor)])
         const grouparc =new Konva.Group({
            x: -this.width/2*this.width/this.height+this.width/2-this.linewidth/2,
            y: 0,
            scaleX: this.width/this.height
        })
             grouparc.add(arc)
          this.node.add(grouparc)
        
    }
    else{
        const arc = new Konva.Arc({
            x: this.width / 2,
            y: this.height / 2,
            innerRadius: 0,
            outerRadius: this.width/2-this.linewidth/2,
            angle: -this.rotationangle,
            rotation: -this.startangle,
           clockwise:true,
           // fill: ColorUtils.convertformat(fillcolor),
            stroke: ColorUtils.convertformat(color),
            strokeWidth: this.linewidth,
          });
          const ang = Math.PI/180*this.startangle
          arc.fillLinearGradientStartPoint({ x: -this.width/2*Math.cos(ang), y: -this.width/2*Math.sin(ang) });
          arc.fillLinearGradientEndPoint({ x: this.width/2*Math.cos(ang), y: this.width/2*Math.sin(ang) });
          arc.fillLinearGradientColorStops([0, ColorUtils.convertformat(fillcolor), 0.5, 'white', 1, ColorUtils.convertformat(fillcolor)])
          const grouparc =new Konva.Group({
            x: 0,
            y: -this.height/2*this.height/this.width+this.height/2-this.linewidth/2,
            scaleY: this.height/this.width
        })
             grouparc.add(arc)
          this.node.add(grouparc)
    }
}
}